import React, { memo, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import Spinner from 'britive-ui-components/core/components/Spinner'
import { fetchTagAssignedUsers } from 'action_creators/tag'

const Wrapper = styled.ul`
  position: relative;
  padding-left: 8px;
`

const Title = styled.div`
  color: var(--mine-shaft);
  position: sticky;
  font-weight: 500;
  font-size: 18px;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  z-index: 2;
`

const Name = styled.li`
  color: var(--mine-shaft);
  font-weight: 400;
  font-size: 14px;
  margin: 0 0 4px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:last-of-type {
    margin-bottom: 0px;
  }
`

const styles = {
  loadingMessage: {
    marginLeft: 8,
  },
}

function TagUsers({ dispatch, drawerData: { tag } }) {
  const [tagUsers, setTagUsers] = useState([])
  const [isFetchingUsers, setIsFetchingUsers] = useState(false)

  useEffect(() => {
    async function getUsers() {
      setIsFetchingUsers(true)

      try {
        const response = await dispatch(
          fetchTagAssignedUsers({ tagId: tag.userTagId })
        )

        setTagUsers(response.value.data)
      } catch (error) {
        console.log(`error: `, error)
      }

      setIsFetchingUsers(false)
    }

    getUsers()
  }, [])

  const renderName = ({ name, userId, email, type }) => {
    const displayName = `${name} ${email && type === 'User' ? `(${email})` : ''}`

    return (
      <Name title={displayName} key={userId}>
        - {displayName}
      </Name>
    )
  }

  const renderUsers = () => {
    return tagUsers.length ? tagUsers.map(renderName) : <Name>No members</Name>
  }

  const renderSpinner = () => {
    return (
      <div>
        <Spinner />
        <span style={styles.loadingMessage}>Loading members</span>
      </div>
    )
  }

  if (!tag) {
    return null
  }

  return (
    <React.Fragment>
      <Title>Members of {tag.name}</Title>

      <Wrapper>{isFetchingUsers ? renderSpinner() : renderUsers()}</Wrapper>
    </React.Fragment>
  )
}

export default connect()(memo(TagUsers))
