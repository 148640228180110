import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import { isOCI } from 'utils/app'
import checkIcon from 'static/images/check-circle.svg'
import warningIcon from 'static/images/check-yellow.svg'

export const getTree = (
  environmentGroups,
  environments,
  checkedNodes = [],
  initialCheckedNodes = [],
  applicationRoot,
  defaultDirectory,
  environmentData,
  allowedScopes = [],
  isTouched,
  rightIconConfig,
  outOfSyncEnvironments
) => {
  if (!applicationRoot.id) {
    return {}
  }
  const clonedEnvironmentGroups = { ...environmentGroups }
  let newAppRoot = applicationRoot.id

  const defaultDirData = clonedEnvironmentGroups[defaultDirectory]
  if (!isEmpty(defaultDirData)) {
    if (!defaultDirectoryHasChildren(environmentGroups, defaultDirectory)) {
      return {}
    }

    // Check and remove the default directory from the environment group.
    // And find the child of the default directory to be used as the tree root.
    newAppRoot = defaultDirData.childGroupIds[0]
    delete clonedEnvironmentGroups[defaultDirectory]
  } else if (applicationRoot.name) {
    // Change the root node name if the default directory is not present.
    clonedEnvironmentGroups[newAppRoot].name = applicationRoot.name
  }

  clonedEnvironmentGroups[newAppRoot].icon = applicationRoot.appIcon

  const createdTree = createTree(
    newAppRoot,
    clonedEnvironmentGroups,
    environments,
    checkedNodes,
    initialCheckedNodes,
    environmentData,
    allowedScopes,
    isTouched,
    rightIconConfig,
    outOfSyncEnvironments
  )

  return createdTree
}

const createTree = (
  nodeId,
  environmentGroups,
  environments,
  checkedNodes,
  initialCheckedNodes,
  environmentData,
  allowedScopes,
  isTouched,
  rightIconConfig,
  outOfSyncEnvironments
) => {
  const childNodes = []
  let childGroupNodes = []
  let childEnvironmentNodes = []
  const isNodeHighlighted = shouldNodeBeHighlighted(
    isTouched,
    checkedNodes,
    initialCheckedNodes,
    nodeId
  )

  if (environmentGroups[nodeId]) {
    if (environmentGroups[nodeId]?.childGroupIds?.length) {
      childGroupNodes = environmentGroups[nodeId].childGroupIds.map(childId => {
        const children = createTree(
          childId,
          environmentGroups,
          environments,
          checkedNodes,
          initialCheckedNodes,
          environmentData,
          [...allowedScopes, ...(allowedScopes.includes(nodeId) ? [childId] : [])],
          isTouched,
          rightIconConfig,
          outOfSyncEnvironments
        )
        return children
      })
    }
    if (environmentGroups[nodeId]?.childEnvironmentIds?.length) {
      childEnvironmentNodes = environmentGroups[nodeId].childEnvironmentIds.map(
        childId => {
          const currentChild = environments[childId]
          return {
            label: isChildTypeDomain(currentChild)
              ? getDomainTypeLabel(currentChild)
              : get(environmentData, `${[childId]}.catalogAppDisplayName`) ||
                get(currentChild, 'name', ''),
            value: currentChild.id,
            checked: checkedNodes.includes(currentChild.id),
            highlighted: shouldNodeBeHighlighted(
              isTouched,
              checkedNodes,
              initialCheckedNodes,
              currentChild.id
            ),
            type: 'leaf',
            disabled: allowedScopes.length
              ? !(
                  allowedScopes.includes(currentChild.id) ||
                  allowedScopes.includes(currentChild.parentGroupId)
                )
              : false,
            createdDate: currentChild?.created?.date,
            ...getIntegratedIcons(
              childId,
              environmentData,
              currentChild.status,
              rightIconConfig,
              outOfSyncEnvironments
            ),
          }
        }
      )
    }

    childNodes.push(...childGroupNodes, ...childEnvironmentNodes)
  }

  return {
    label: environmentGroups[nodeId].name,
    value: environmentGroups[nodeId].id,
    expanded: true,
    checked: checkedNodes.includes(environmentGroups[nodeId].id),
    highlighted: isNodeHighlighted,
    type: 'node',
    nodes: childNodes,
    leftIcon: {
      icon: environmentGroups[nodeId]?.icon,
    },
    createdDate: environmentGroups[nodeId]?.created?.date,
    disabled: allowedScopes.length
      ? !(
          allowedScopes.includes(environmentGroups[nodeId].id) ||
          allowedScopes.includes(environmentGroups[nodeId].parentId)
        )
      : false,
  }
}

const getIntegratedIcons = (
  id,
  environmentData = {},
  status,
  rightIconConfig,
  outOfSyncEnvironments
) => {
  if (environmentData[id]) {
    const env = environmentData[id]
    const isEnvScanned = env.envScanStatus
    const isHierarchical = env.catalogApplication?.requiresHierarchicalModel
    const isEnvActive = status === 'active'

    if (
      !isEmpty(outOfSyncEnvironments) &&
      outOfSyncEnvironments?.includes(env.environmentId)
    ) {
      return {
        rightIcon: {
          icon: warningIcon,
          tooltip: 'Permission mismatch',
          onClick: () => rightIconConfig.onClick(env.environmentId),
        },
      }
    }
    if (rightIconConfig) {
      if (env[rightIconConfig.property]) {
        return {
          rightIcon: {
            icon: checkIcon,
            tooltip: rightIconConfig.tooltip,
          },
        }
      }
      return {}
    }
    if (!isHierarchical && isEnvScanned && isEnvActive) {
      return {
        rightIcon: {
          icon: checkIcon,
          tooltip: 'Integrated',
        },
      }
    }

    return {}
  }

  return {}
}

export const isDefaultDirectorySelected = (checkedNodes = [], defaultDirectory) => {
  return checkedNodes.includes(defaultDirectory)
}

export const getValuesFromTheSelectedNodes = (checkedNodes = []) => {
  return checkedNodes.map(node => node.value)
}

export const shouldDisableDefaultDirectory = (
  disabled,
  checkedNodes = [],
  defaultDirectory,
  allowedScopes
) => {
  if (disabled) {
    return true
  }

  if (allowedScopes.length) {
    return allowedScopes[0] !== defaultDirectory
  }

  if (isDefaultDirectorySelected(checkedNodes, defaultDirectory)) {
    return false
  }

  if (!checkedNodes.length) {
    return false
  }

  return true
}

export const shouldNodeBeHighlighted = (
  isTouched,
  checkedNodes,
  initialCheckedNodes,
  nodeId
) => {
  if (!isTouched) {
    return false
  }

  return initialCheckedNodes.includes(nodeId) !== checkedNodes.includes(nodeId)
}

export const defaultDirectoryHasChildren = (environmentGroups, defaultDirectory) => {
  const defaultDirData = environmentGroups[defaultDirectory]

  if (isEmpty(defaultDirData)) {
    return false
  }

  if (defaultDirData.childGroupIds?.length) {
    return true
  }

  return false
}

export const isChildTypeDomain = (child = {}) => {
  return get(child, 'type', '') === 'DOMAIN'
}

export const getDomainTypeLabel = (child = {}) => {
  return `(Domain) ${get(child, 'name', '')}`
}

export const moveRootLevelLeavesAtTop = appName => isOCI(appName)
