import React from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import Typography from 'britive-design-system/core/components/typography'
import Button from 'britive-design-system/core/components/button'
import Breadcrumbs from 'components/breadcrumbs'
import { buttonLabel, classNamePrefix, headerDetailsType, status } from './constants'
import checkWhite from 'static/images/check-white.svg'
import reject from 'static/images/reject.svg'
import cancelRequest from 'static/images/cancelRequest.svg'
import './index.scss'

export const PageHeader = ({
  title,
  description = '',
  type,
  requestStatus,
  onReject,
  onApprove,
  onWithdraw,
  trackingId,
}) => {
  const history = useHistory()

  const listUrl =
    type === headerDetailsType.APPROVALS ? '/my-approvals' : '/my-requests'

  const handleClose = () => {
    history.push(listUrl)
  }

  const renderBreadcrumnbs = () => (
    <Breadcrumbs>
      <nav aria-label="breadcrumb">
        <ol
          className="breadcrumb"
          data-testid="my-approval-my-requests-breadcrumb-list"
        >
          <li className="breadcrumb-item">
            <NavLink to={listUrl}>My {type}</NavLink>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {title}
          </li>
        </ol>
      </nav>
    </Breadcrumbs>
  )

  const renderCloseButton = () => (
    <Button
      size="medium"
      onClick={handleClose}
      value={buttonLabel.CLOSE}
      variant={requestStatus === status.PENDING ? 'secondary' : 'primary'}
    >
      {buttonLabel.CLOSE}
    </Button>
  )

  const renderApprovalButtons = () => (
    <div className={`${classNamePrefix}-buttons`}>
      {requestStatus === status.PENDING && (
        <>
          <Button size="medium" onClick={onApprove} value={buttonLabel.APPROVE}>
            <img
              src={checkWhite}
              className={`${classNamePrefix}-buttons-icon ${classNamePrefix}-buttons-icon--check`}
            />
            {buttonLabel.APPROVE}
          </Button>
          <Button
            size="medium"
            variant="secondary"
            onClick={onReject}
            value={buttonLabel.REJECT}
          >
            <img src={reject} className={`${classNamePrefix}-buttons-icon`} />
            {buttonLabel.REJECT}
          </Button>
        </>
      )}
      {renderCloseButton()}
    </div>
  )

  const renderRequestButtons = () => (
    <div className="my-approvals-my-requests-header-buttons">
      {requestStatus === status.PENDING && (
        <Button size="medium" onClick={onWithdraw} value={buttonLabel.WITHDRAW}>
          <img
            src={cancelRequest}
            className={`${classNamePrefix}-buttons-icon ${classNamePrefix}-buttons-icon--withdraw`}
          />
          {buttonLabel.WITHDRAW}
        </Button>
      )}
      {renderCloseButton()}
    </div>
  )

  return (
    <div className="my-approvals-my-requests-details-header">
      {renderBreadcrumnbs()}
      <div className="my-approvals-my-requests-trackingId">
        <Typography variant="label2">Request ID: {trackingId}</Typography>
      </div>
      <div className="my-approvals-my-requests-title">
        <Typography variant="heading5">{title}</Typography>
      </div>
      {description?.length ? (
        <Typography variant="label1">{description}</Typography>
      ) : null}
      {type === headerDetailsType.APPROVALS
        ? renderApprovalButtons()
        : renderRequestButtons()}
    </div>
  )
}
