import React, { useState } from 'react'
import Textfield from 'britive-design-system/core/components/textfield'
import search from 'static/images/search.svg'

const SearchBox = ({ onChange }) => {
  const [searchInput, setSearchInput] = useState('')

  const handleChange = ({ target }) => {
    const value = target?.value || ''
    setSearchInput(value)
    onChange(value)
  }

  return (
    <Textfield
      width="400px"
      placeholder="Search"
      onChange={handleChange}
      value={searchInput || ''}
      icon={<img src={search} />}
      iconPosition="left"
    />
  )
}

export default SearchBox
