import * as actions from 'actions'
import toast from 'utils/toast/index.js'

import { fetchIfLoggedIn } from '../utils.js'

export const getPermissionConstraintTypes = (
  papId,
  permissionName,
  permissionType
) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.GET_PERMISSION_CONSTRAINT_TYPES,
      method: 'get',
      path: `/paps/${papId}/permissions/${permissionName}/${permissionType}/supported-constraint-types`,
    })
  )
}

export const getPermissionConstraints = (
  papId,
  permissionName,
  permissionType,
  constraintType
) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.GET_PERMISSION_CONSTRAINTS,
      method: 'get',
      meta: { papId, permissionName, permissionType, constraintType },
      path: `/paps/${papId}/permissions/${permissionName}/${permissionType}/constraints/${constraintType}`,
    })
  )
}

export const deletePermissionConstraints = (
  papId,
  permissionName,
  permissionType,
  constraintType,
  constraintIndex,
  name
) => async dispatch => {
  try {
    await dispatch(
      fetchIfLoggedIn({
        actionType: actions.DELETE_PERMISSION_CONSTRAINTS,
        method: 'put',
        meta: {
          papId,
          permissionName,
          permissionType,
          constraintType,
          constraintIndex,
        },
        path: `/paps/${papId}/permissions/${permissionName}/${permissionType}/constraints/${constraintType}?operation=remove`,
        postBody: { name },
      })
    )
    toast({
      title: `Constraint ${name} has been deleted successfully.`,
      type: 'success',
      time: 'normal',
      id: name,
    })
  } catch {
    toast({
      title: `Constraint ${name} could not be deleted.`,
      type: 'error',
      time: 'normal',
      id: name,
    })
  }
}

export const addPermissionConstraints = (
  papId,
  permission_name,
  permission_type,
  constraint_type,
  constraint
) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.ADD_PERMISSION_CONSTRAINTS,
      method: 'put',
      meta: { papId, permission_name, permission_type, constraint_type, constraint },
      path: `/paps/${papId}/permissions/${permission_name}/${permission_type}/constraints/${constraint_type}?operation=add`,
      postBody: constraint,
    })
  )
}

export const validatePermissionConstraints = (
  papId,
  permission_name,
  permission_type,
  constraint_type,
  constraint
) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.VALIDATE_PERMISSION_CONSTRAINTS,
      method: 'put',
      meta: { papId, permission_name, permission_type, constraint_type, constraint },
      path: `/paps/${papId}/permissions/${permission_name}/${permission_type}/constraints/${constraint_type}?operation=validate`,
      postBody: constraint,
    })
  )
}

export const flushAddConstraintsState = () => {
  return {
    type: actions.ADD_PERMISSION_CONSTRAINTS_INITIAL,
  }
}
