import get from 'lodash/get'
import { FETCH_USERS_WITH_ACTIVE_SESSIONS } from '../../actions'

const initialState = {
  loading: false,
  error: null,
  data: [],
  credentials: {},
  hasMoreData: false,
  totalCount: 0,
}

export function activeSessions(state = initialState, action) {
  switch (action.type) {
    case `${FETCH_USERS_WITH_ACTIVE_SESSIONS}_PENDING`: {
      return {
        ...state,
        loading: true,
        error: null,
      }
    }

    case `${FETCH_USERS_WITH_ACTIVE_SESSIONS}_FULFILLED`: {
      const { count, page, data = [] } = get(action, 'payload.data', {})
      let updatedData = []

      if (page === 0) {
        updatedData = data
      } else {
        updatedData = [...state.data, ...data]
      }

      return {
        ...state,
        loading: false,
        error: null,
        data: updatedData,
        hasMoreData: updatedData.length < count,
        totalCount: count,
      }
    }

    case `${FETCH_USERS_WITH_ACTIVE_SESSIONS}_REJECTED`: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    }

    default:
      return { ...state }
  }
}
