import React, { useState } from 'react'
import Table from 'britive-design-system/core/components/table'
import Spinner from 'britive-design-system/core/components/spinner'
import { isEmpty } from 'lodash'

export default ({
  columns,
  tableData,
  isLoading,
  searchable = true,
  onSearch,
  placeholder,
  onSort,
  noHeaderBackground = false,
}) => {
  const [searchQuery, setSearchQuery] = useState(null)
  const [sortData, setSortData] = useState({
    order: 'unsorted',
    by: null,
  })

  const handleSorting = (sortOrder, sortBy) => {
    if (typeof onSort === 'function') {
      onSort(sortOrder, sortBy)
    }

    setSortData({
      order: sortOrder,
      by: sortBy,
    })
  }
  const filterTableData = (data = [], sort = {}, searchQuery) => {
    let filteredData = [...data]

    if (!isEmpty(sort) && sort.order !== 'unsorted') {
      filteredData.sort((a, b) => {
        const valueA = a[sort.by]?.toLowerCase()
        const valueB = b[sort.by]?.toLowerCase()

        // Compare full strings case-insensitively and numerically
        return (
          valueA.localeCompare(valueB, undefined, {
            numeric: true,
            sensitivity: 'base',
          }) * (sort.order === 'ascending' ? 1 : -1)
        )
      })
    }

    if (searchQuery) {
      return filteredData.filter(item =>
        item.name?.toLowerCase().includes(searchQuery.toLowerCase())
      )
    }

    return filteredData
  }

  return (
    <div>
      {isLoading && <Spinner size="medium" message={''} overlay />}
      <Table
        columns={columns}
        rows={filterTableData(tableData, sortData, searchQuery)}
        resizableColumns
        sortHandler={handleSorting}
        noHeaderBackground={noHeaderBackground}
        searchBar={
          searchable
            ? {
                onSearch: value => {
                  if (typeof onSearch === 'function') {
                    onSearch(value)
                  } else {
                    setSearchQuery(!value ? null : value)
                  }
                },
                placeholder: placeholder || 'Search',
              }
            : null
        }
      />
    </div>
  )
}
