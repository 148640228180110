import React, { memo, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'

import AccountMappingSection from './AccountMappingSection'
import UserAttributeMapping from './UserAttributeMapping'
import UserAccountRules from './UserAccountRules'

import { fetchUserAttributes } from 'action_creators/user'
import { updateMessageModal } from 'action_creators/message_modal'

import { preFetch } from 'utils/do_fetch'

const ACCOUNT_MAPPING_SECTION_INFO = {
  displayName: 'Account Mapping',
  properties: ['userAccountMappings', 'userAccountRules'],
  groups: [
    {
      displayName: 'Account Mapping',
      properties: ['userAccountMappings', 'userAccountRules'],
    },
  ],
}

function AccountMappingSectionContainer({
  appRoot,
  createNewMode,
  dispatch,
  fields,
  onCancel,
  onChange,
  onSubmit,
  saving,
  selectedAppAccounts,
  thisAppManage,
}) {
  const [isLoading, setIsLoading] = useState(false)
  const [isDeletingRules, setIsDeletingRules] = useState(false)
  const [userAttributes, setUserAttributes] = useState([])
  const [userAccountRules, setUserAccountRules] = useState({})
  const [originalUserAccountRules, setOriginalUserAccountRules] = useState({})
  const [rulesToDelete, setRulesToDelete] = useState([])
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const [propertyTypes, setPropertyTypes] = useState({
    userAccountMappings: {
      customComponent: UserAttributeMapping,
      description: 'Select User Attribute',
      enumValues: [...userAttributes, { name: '', description: '' }],
      name: 'userAccountMappings',
      privy: false,
      readOnly: false,
      required: true,
      type: 'java.lang.String',
      value: fields.userAccountMappings || get(appRoot, 'userAccountMappings', []),
    },
    userAccountRules: {
      customComponent: UserAccountRules,
      name: 'userAccountRules',
      privy: false,
      readOnly: false,
      required: true,
      value: userAccountRules,
    },
  })

  useEffect(() => {
    async function getData() {
      setIsLoading(true)

      await fetchAttributes()
      await fetchAccountRules()

      setIsLoading(false)
    }

    getData()
  }, [selectedAppAccounts])

  useEffect(() => {
    setPropertyTypes({
      ...propertyTypes,
      userAccountMappings: {
        ...propertyTypes.userAccountMappings,
        enumValues: [...userAttributes, { name: '', description: '' }],
        value: fields.userAccountMappings || get(appRoot, 'userAccountMappings', []),
      },
      userAccountRules: {
        ...propertyTypes.userAccountRules,
        value: userAccountRules,
        onClick: deleteRule,
      },
    })
  }, [userAttributes, userAccountRules, fields, appRoot])

  const fetchAttributes = async () => {
    const response = await dispatch(fetchUserAttributes('accountMapping'))

    setUserAttributes(response.value.data)
  }

  const fetchAccountRules = async () => {
    const appId = get(appRoot, 'appContainerId')

    if (!appId) {
      return null
    }

    const response = await preFetch({
      name: 'fetchAccountRules',
      path: `/apps/${appId}/user-account-links`,
      method: 'get',
    })

    const rules = {}

    response.data.forEach(rule => (rules[rule.userAccountLinkId] = rule))

    setUserAccountRules(rules)
    setOriginalUserAccountRules(rules)
  }

  const deleteRule = id => {
    const newTempUserAccountRules = { ...userAccountRules }
    delete newTempUserAccountRules[id]
    const newRulesToDelete = [...rulesToDelete]

    newRulesToDelete.push(id)

    setRulesToDelete(newRulesToDelete)
    setUserAccountRules(newTempUserAccountRules)
  }

  const submitHandler = () => {
    if (rulesToDelete.length > 0) {
      setIsConfirmationModalOpen(true)
    } else {
      onSubmit()
    }
  }

  const cancelHandler = () => {
    setUserAccountRules(originalUserAccountRules)
    setRulesToDelete([])
    onCancel()
  }

  const restoreRules = () => {
    setUserAccountRules(originalUserAccountRules)
    setRulesToDelete([])
    setIsConfirmationModalOpen(false)
    onSubmit()
  }

  const deleteRules = async () => {
    const { appContainerId: appId } = appRoot

    setIsDeletingRules(true)

    try {
      const deletionArray = []

      rulesToDelete.forEach(id => {
        deletionArray.push(
          preFetch({
            name: 'deleteUserRule',
            path: `/apps/${appId}/user-account-links/${id}`,
            method: 'delete',
          })
        )
      })

      await Promise.all(deletionArray)

      setIsConfirmationModalOpen(false)
      setIsDeletingRules(false)
      setRulesToDelete([])
    } catch (error) {
      const errorMessage = get(error, 'response.data.message', 'Unknown error.')
      dispatch(updateMessageModal({ body: errorMessage }))
    }

    await fetchAccountRules()

    onSubmit()
  }

  return (
    <AccountMappingSection
      createNewMode={createNewMode}
      deleteRules={deleteRules}
      fields={fields}
      rulesToDelete={rulesToDelete}
      userAccountRules={originalUserAccountRules}
      groups={ACCOUNT_MAPPING_SECTION_INFO.groups}
      isConfirmationModalOpen={isConfirmationModalOpen}
      isDeletingRules={isDeletingRules}
      isLoading={isLoading}
      onCancel={cancelHandler}
      onChange={onChange}
      onSubmit={submitHandler}
      properties={ACCOUNT_MAPPING_SECTION_INFO.properties}
      propertyTypes={propertyTypes}
      restoreRules={restoreRules}
      saving={saving}
      thisAppManage={thisAppManage}
    />
  )
}

const mapStateToProps = state => {
  return {
    selectedAppAccounts: state.admin.selectedAppAccounts,
  }
}

export default connect(mapStateToProps)(memo(AccountMappingSectionContainer))
