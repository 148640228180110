import { fetchIfLoggedIn } from './utils.js'

export const FETCH_ALL_TOKENS = 'API_KEYS/FETCH_ALL_TOKENS'
export const FETCH_API_KEYS = 'API_KEYS/FETCH_API_KEYS'
export const FETCH_API_KEY = 'API_KEYS/FETCH_API_KEY'
export const CREATE_API_KEY = 'API_KEYS/CREATE_API_KEY'
export const UPDATE_API_KEY = 'API_KEYS/UPDATE_API_KEY'
export const REVOKE_API_KEY = 'API_KEYS/REVOKE_API_KEY'

export const fetchAllTokens = () => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: FETCH_ALL_TOKENS,
      method: 'get',
      path: `/token/all`,
    })
  )
}

export const fetchAPIKeys = () => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: FETCH_API_KEYS,
      method: 'get',
      path: `/token`,
    })
  )
}

export const fetchAPIKey = id => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: FETCH_API_KEY,
      method: 'get',
      path: `/token/${id}`,
    })
  )
}

export const createAPIKey = (name, tokenExpirationDays) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: CREATE_API_KEY,
      method: 'post',
      path: `/token`,
      postBody: { name, tokenExpirationDays },
    })
  )
}

export const updateAPIKey = (id, name, tokenExpirationDays) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: UPDATE_API_KEY,
      method: 'put',
      path: `/token/${id}`,
      meta: { id, name, tokenExpirationDays },
      postBody: { name, tokenExpirationDays },
    })
  )
}

export const revokeAPIKey = keyId => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: REVOKE_API_KEY,
      method: 'delete',
      meta: { keyId },
      path: `/token/${keyId}`,
    })
  )
}
