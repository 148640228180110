import React from 'react'
import capitalize from 'lodash/capitalize'
import Tooltip from 'britive-design-system/core/components/tooltip'
import Table from '../../../AccessRequestSettings/AssignAssociationApprovers/components/Table'
import highRisk from 'static/images/high-risk.svg'
import info from 'static/images/infoIcon.svg'
import './index.scss'

const classNamePrefix = 'policy-list'

export const PolicyList = ({ data, setPermissionData, isLoading }) => {
  const renderHighRiskIcon = () => (
    <div>
      <Tooltip title="High Risk" position="left">
        <span title="">
          <img src={highRisk} className={`${classNamePrefix}-name-high-risk`} />
        </span>
      </Tooltip>
    </div>
  )

  const renderInfoIcon = row => (
    <div>
      <Tooltip title="View Details" position="left">
        <span title="" onClick={() => setPermissionData(row)}>
          <img src={info} className={`${classNamePrefix}-action-info`} />
        </span>
      </Tooltip>
    </div>
  )

  return (
    <Table
      tableData={data}
      isLoading={isLoading}
      columns={[
        {
          sortable: true,
          headerName: 'Name',
          field: 'name',
          width: '40%',
          renderColumn: row => {
            return (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {row.name}
                &nbsp;
                {row.privileged && renderHighRiskIcon()}
              </div>
            )
          },
        },
        {
          field: 'description',
          headerName: 'Description',
          sortable: true,
          width: '30%',
        },
        {
          field: 'type',
          headerName: 'Type',
          sortable: true,
          width: '20%',
          renderColumn: row => capitalize(row.type),
        },
        {
          headerName: 'Action',
          width: '10%',
          renderColumn: row => renderInfoIcon(row),
        },
      ]}
    />
  )
}
