import React, { memo, useEffect, useState } from 'react'
import get from 'lodash/get'

import ScanDetailsDrawer from './ScanDetailsDrawer'
import TableEllipsisCell from 'components/table/TableV2/TableEllipsisCell'

import * as api from 'services/api'

const STATUS_CLASSES = {
  Added: 'added',
  Removed: 'deleted',
}

function Status({ value }) {
  const className = STATUS_CLASSES[value]
  return <TableEllipsisCell className={className} value={value} />
}

function ScanDetailsDrawerContainer({
  isOpen,
  isHierarchical,
  selectedAppId,
  entityId,
  toggleDrawer,
  data,
  tabs = [],
  type,
}) {
  const [isLoading, setIsLoading] = useState(false)
  const [definitionDifferences, setDefinitionDifferences] = useState({})

  useEffect(() => {
    data && fetchChanges()
  }, [data])

  async function fetchChanges() {
    const { hasDefinitionsChanged, appPermissionId } = data

    if (hasDefinitionsChanged) {
      try {
        setIsLoading(true)
        const response = await api.fetchPermissionDefinitionDifferences({
          appId: selectedAppId,
          envId: entityId,
          permissionId: appPermissionId,
        })

        setDefinitionDifferences(get(response, 'data', {}))
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }
  }

  const getColumns = name => [
    {
      Header: 'Name',
      accessor: name,
      minWidth: 200,
    },
    ...(isHierarchical
      ? [
          {
            Header: 'Scope',
            accessor: 'inheritedScope',
            minWidth: 200,
            sortable: false,
          },
        ]
      : []),
    {
      Header: 'Scan Status',
      accessor: 'scanStatus',
      Cell: ({ value }) => Status({ value }),
      minWidth: 100,
    },
  ]

  const dropdownFilter = [
    {
      label: 'Scan Status',
      value: 'scanStatus',
      options: [
        { label: 'New', value: 'New' },
        { label: 'Updated', value: 'Updated' },
        { label: 'Deleted', value: 'Deleted' },
      ],
    },
  ]

  return (
    <ScanDetailsDrawer
      tabs={tabs}
      type={type}
      dropdownFilter={dropdownFilter}
      isOpen={isOpen}
      getColumns={getColumns}
      definitionDifferences={definitionDifferences}
      data={data}
      toggleDrawer={toggleDrawer}
      isLoading={isLoading}
    />
  )
}

export default memo(ScanDetailsDrawerContainer)
