import { preparePropertiesObjectForUI } from 'utils/prepare_properties'
import { simpleNormalizeData } from 'utils/normalize'

const tenentApp = rawData => {
  // This is overly complicated.
  // The API returns property details in a dynamic way. The update
  // must also match the dynamic structure of the details.

  // we're creating a new object called 'mappedProperties' here, that
  // puts all the property details in one place and has a 'parent' key
  // to rememember where it came from

  const { catalogApplication = {}, ...rest } = rawData
  const { uiSettings = [] } = catalogApplication
  // const { uiSettings = [], propertyTypes = [] } = catalogApplication

  let mappedUiProperties = {}
  if (uiSettings.sections && uiSettings.sections.length > 0) {
    mappedUiProperties = preparePropertiesObjectForUI(
      uiSettings.sections,
      catalogApplication
    )
  }
  // const displayNameProperty = propertyTypes.find(props => {
  //   return props.name === 'displayName'
  // })

  return {
    catalogApplication: {
      mappedProperties: mappedUiProperties,
      ...catalogApplication,
    },
    // displayName: displayNameProperty.value,
    ...rest,
  }
}

export default tenentApp

export const tenantAppPermissions = rawData => {
  return simpleNormalizeData(rawData, 'appPermissionId')
}

export const tenantAppAccounts = rawData => {
  return simpleNormalizeData(rawData, 'accountId')
}

export const tenantAppResources = rawData => {
  return simpleNormalizeData(rawData, 'id')
}
