import { fetchIfLoggedIn } from './utils.js'

export const FETCH_PRIVILEGES = 'FETCH_PRIVILEGES'

export const fetchPrivileges = () => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: FETCH_PRIVILEGES,
      method: 'get',
      path: `/users/allowed-actions`,
    })
  )
}
