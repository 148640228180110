export function isDuplicatePropertyInList(
  list,
  propertyName,
  newItem,
  updateItem = {}
) {
  return list?.some(item => {
    return (
      item[propertyName]?.toLowerCase() === newItem?.toLowerCase() &&
      (updateItem
        ? item[propertyName]?.toLowerCase() !==
          updateItem[propertyName]?.toLowerCase()
        : true)
    )
  })
}

export function getUpdatedList(list, key, toUpdateItem, newItem) {
  const updatedList = [...list]
  const indexToUpdate = updatedList.findIndex(
    item => item[key]?.toLowerCase() === toUpdateItem?.toLowerCase()
  )

  updatedList[indexToUpdate] = newItem

  return updatedList
}

export const characterCheck = value => {
  return value?.length > 128
}
