import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import Paps from './PapsContainer'
import ProfileDetails from './details'
import CreateProfile from './create'

import ApplicationRootsLoader from 'data_loaders/ApplicationRootsLoader'

function ProfilesRouter({
  match,
  appHeaderHeight,
  setBreadcrumbTrail,
  thisAppManage,
  requestAccess,
}) {
  const { appId } = match.params
  const mode = window.location.pathname.split('/').pop()
  return (
    <Switch>
      <Route
        exact
        path={
          mode === 'request' || mode === 'create' || mode === 'draft'
            ? `${match.url}/profiles/:profileId/details/:tab/:mode`
            : `${match.url}/profiles/:profileId/details/:tab`
        }
        render={props => {
          return (
            <ApplicationRootsLoader>
              <ProfileDetails
                {...props}
                setBreadcrumbTrail={setBreadcrumbTrail}
                appId={appId}
                appHeaderHeight={appHeaderHeight}
                thisAppManage={thisAppManage}
                requestAccess={requestAccess}
              />
            </ApplicationRootsLoader>
          )
        }}
      />
      {thisAppManage ? (
        <Route
          path={`${match.url}/profiles/create`}
          render={props => (
            <ApplicationRootsLoader>
              <CreateProfile
                {...props}
                setBreadcrumbTrail={setBreadcrumbTrail}
                appId={appId}
                appHeaderHeight={appHeaderHeight}
                requestAccess={requestAccess}
              />
            </ApplicationRootsLoader>
          )}
        />
      ) : (
        <Redirect
          from={`${match.url}/profiles/create`}
          to={`${match.url}/profiles`}
        />
      )}

      <Route
        exact
        path={`${match.url}/profiles`}
        render={() => (
          <Paps
            match={match}
            setBreadcrumbTrail={setBreadcrumbTrail}
            appHeaderHeight={appHeaderHeight}
            thisAppManage={thisAppManage}
            requestAccess={requestAccess}
          />
        )}
      />
    </Switch>
  )
}

export default ProfilesRouter
