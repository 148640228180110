import { fetchIfLoggedIn } from './utils.js'

export const GET_EXISTING_POLICIES = 'GET_EXISTING_POLICIES'
export const CREATE_ROLE = 'CREATE_ROLE'
export const VALIDATE_INLINE_POLICY = 'VALIDATE_INLINE_POLICY'
export const GET_EXISTING_POLICIES_LOAD_MORE = 'GET_EXISTING_POLICIES_LOAD_MORE'
export const PERMISSIONS_DETAILS = 'PERMISSIONS_DETAILS'

export const getExistingPolicies = (
  appId,
  params = {},
  oldSearchTerm
) => dispatch => {
  const profileRequest = window?.location?.pathname?.includes('/request-access')

  const apiParams = {
    page: params.page,
    size: params.size,
  }

  let shouldAppend = params.page !== 0
  if (!params.searchText && oldSearchTerm?.length && params.page !== 0) {
    apiParams.searchText = oldSearchTerm
  } else if (params.searchText && !oldSearchTerm) {
    apiParams.searchText = params.searchText
    shouldAppend = false
  } else if (params.searchText) {
    apiParams.searchText = params.searchText
    shouldAppend = false
  }

  return dispatch(
    fetchIfLoggedIn({
      actionType: shouldAppend
        ? GET_EXISTING_POLICIES_LOAD_MORE
        : GET_EXISTING_POLICIES,
      method: 'get',
      path: profileRequest
        ? `/profile-requests/apps/${appId}/allowedChildPermissions`
        : `/apps/${appId}/allowedChildPermissions`,
      meta: { searchText: params.searchText },
      params: apiParams,
    })
  )
}

export const createRole = (appId, values, profileRequest) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: CREATE_ROLE,
      method: 'post',
      path: profileRequest
        ? `/profile-requests/apps/${appId}/britive-managed/permissions`
        : `/apps/${appId}/britive-managed/permissions`,
      postBody: values,
    })
  )
}

export const validateInlinePolicy = (appId, values, profileRequest) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      method: 'post',
      path: profileRequest
        ? `/profile-requests/apps/${appId}/britive-managed/permissions/validate`
        : `/apps/${appId}/britive-managed/permissions/validate`,
      postBody: values,
    })
  )
}

export const getPermissionsDetails = (
  appId,
  permissionId,
  profileRequest
) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: PERMISSIONS_DETAILS,
      method: 'get',
      path: profileRequest
        ? `/profile-requests/apps/${appId}/britive-managed/permissions/${permissionId}`
        : `/apps/${appId}/britive-managed/permissions/${permissionId}`,
    })
  )
}
