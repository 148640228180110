import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Spinner from 'britive-design-system/core/components/spinner'
import Snackbar from 'britive-design-system/core/components/snackbar'
import Tooltip from 'britive-design-system/core/components/tooltip'
import { getSelectedUsers } from 'action_creators/user'
import Table from 'routes/admin/applications/Application/AccessRequestSettings/AssignAssociationApprovers/components/Table'
import { classNamePrefix } from './constants'
import { addFullNamesToTheUsers } from './utils'
import { getSecretStatus } from '../../utils'
import { secretModuleConstants } from '../../my-approvals/list/constants'
import check from 'static/images/check.svg'
import rejectDark from 'static/images/reject-dark.svg'
import noAction from 'static/images/no-action.svg'
import expired from 'static/images/expired.svg'
import pending from 'static/images/pending.svg'
import withdraw from 'static/images/withdraw.svg'
import { isEmpty } from 'lodash'

export const ApproverGroupUsers = () => {
  const dispatch = useDispatch()
  const approversList = useSelector(state => state.userSecretsReducer?.approversList)
  const { users, fetchingUsers } = useSelector(state => state.admin)

  useEffect(() => {
    if (!approversList.loading && !isEmpty(approversList.data)) {
      dispatch(getSelectedUsers(approversList.data))
    }
  }, [JSON.stringify(approversList)])

  const getIcon = status => {
    switch (status?.toLowerCase()) {
      case secretModuleConstants.pendingText:
        return pending

      case secretModuleConstants.approvedText:
        return check

      case secretModuleConstants.rejectedText:
        return rejectDark

      case secretModuleConstants.timeOutText:
        return expired

      case secretModuleConstants.cancelled:
        return withdraw

      default:
        return noAction
    }
  }

  const renderIcon = row => (
    <div>
      <Tooltip title={getSecretStatus(row.status)} position="left">
        <span title="">
          <img src={getIcon(row.status)} />
        </span>
      </Tooltip>
    </div>
  )

  if (approversList.loading || fetchingUsers) {
    return (
      <div className={`${classNamePrefix}-approvers-list-loading`}>
        <Spinner size={'medium'} message={'Loading Users..'} />
      </div>
    )
  }

  if (approversList.error?.length) {
    return <Snackbar errorList={[approversList.error]} />
  }

  return (
    <>
      {approversList?.data?.length !== 0 && users?.User.length !== 0 && (
        <Table
          columns={[
            {
              sortable: true,
              headerName: 'User',
              field: 'name',
            },
            {
              sortable: true,
              headerName: 'Status',
              field: 'status',
              renderColumn: renderIcon,
            },
          ]}
          tableData={addFullNamesToTheUsers(users?.User, approversList?.data)}
        />
      )}
    </>
  )
}
