import React, { memo, useState, useEffect } from 'react'
import axios from 'axios'
import isEmpty from 'lodash/isEmpty'
import capitalize from 'lodash/capitalize'
import Select from 'britive-design-system/core/components/select'
import Snackbar from 'britive-design-system/core/components/snackbar'
import ModalPopup from 'britive-design-system/core/components/modal-popup'
import Typography from 'britive-design-system/core/components/typography'
import doFetch, { getURL } from 'utils/do_fetch'
import { PolicyList } from './PolicyList'
import { PermissionDefinition } from './PermissionDefinition'
import arrowLeft from 'static/images/arrow-left.svg'
import './index.scss'
import { GroupTable } from './GroupTable'
import { getPermissionPath } from './utils'

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  groupWrapper: {
    padding: 4,
  },
  groupPermissionList: {
    paddingLeft: 24,
  },
}

const classNamePrefix = 'profile-permission-drawer'

function PermissionsDrawer({
  environmentList = [],
  appId,
  permission,
  onClose,
  forRequestBuilder = false,
  hideEnvironmentSelector = false,
  supportedPermissionTypes,
  rootEnvironmentGroup,
  isAws,
}) {
  const [policies, setPolicies] = useState([])
  const [isLoadingPermissionDetails, setIsLoadingPermissionDetails] = useState(false)
  const [permissionData, setPermissionData] = useState(null)
  const [environment, setEnvironment] = useState(null)
  const [groupData, setGroupData] = useState([])
  const [error, setError] = useState(null)

  useEffect(() => {
    if (hideEnvironmentSelector && environmentList.length) {
      const envId = environmentList[0].value
      if (permission.type === 'group') {
        getGroups(envId)
      } else {
        getRolesAndPolicy(envId)
      }
    }
  }, [])

  const getGroupPermissions = async (groupList = [], environmentId) => {
    const groupPermissionId = groupList[0]?.appPermissionId

    let path = `/apps/${appId}/environments/${environmentId}/groups/${groupPermissionId}/permissions`
    if (forRequestBuilder) {
      path = `/profile-requests${path}`
    }
    try {
      const groupPermissionData = await doFetch({ path, method: 'get' })
      setGroupData(groupPermissionData.data?.data)
      setError(null)
    } catch {
      setError('Unable to fetch the associated permissions.')
    } finally {
      setIsLoadingPermissionDetails(false)
    }
  }

  const getGroups = async environmentId => {
    let path = getPermissionPath(
      appId,
      environmentId,
      permission.name,
      forRequestBuilder,
      true
    )

    setIsLoadingPermissionDetails(true)
    try {
      const permissionDetails = await doFetch({ path, method: 'get' })
      getGroupPermissions(permissionDetails.data, environmentId)
      setError(null)
    } catch {
      setIsLoadingPermissionDetails(false)
      setError('Unable to fetch the associated permissions.')
    }
  }

  const getRolesAndPolicy = async environmentId => {
    let path = getPermissionPath(
      appId,
      environmentId,
      permission.name,
      forRequestBuilder
    )

    setIsLoadingPermissionDetails(true)
    const permissionDetails = await doFetch({ path, method: 'get' })
    const currentPermissionData = permissionDetails.data[0]

    if (!isAws) {
      setPermissionData(currentPermissionData)
      setIsLoadingPermissionDetails(false)
      return
    }

    const policiesInRole = currentPermissionData?.permissionDefinition
    if (policiesInRole?.length) {
      const apiList = []
      policiesInRole.forEach(policyInRole => {
        const rolePolicyPath = `${getURL()}${getPermissionPath(
          appId,
          environmentId,
          policyInRole,
          forRequestBuilder
        )}`
        apiList.push(
          axios.get(rolePolicyPath, {
            withCredentials: true,
            xsrfCookieName: 'csrfToken',
          })
        )
      })

      try {
        const results = await axios.all(apiList)
        const filteredPoliciesList = []
        results.forEach(result => {
          if (result?.data?.length) {
            filteredPoliciesList.push(
              ...result.data.filter(datum => policiesInRole.includes(datum.name))
            )
          }
        })
        setPolicies(filteredPoliciesList)
      } catch {
        //
      }
    }
    setIsLoadingPermissionDetails(false)
  }

  const handleEnvironmentChange = async (_, selectedEnvironment) => {
    setEnvironment(selectedEnvironment)
    if (permission.type === 'group') {
      getGroups(selectedEnvironment.value)
      return
    }

    try {
      getRolesAndPolicy(selectedEnvironment.value)
    } catch {
      setIsLoadingPermissionDetails(false)
      setError('Unable to fetch the associated permissions.')
    }
  }

  const handleModalClose = () => {
    onClose()
    setPermissionData(null)
  }

  const getModalTitle = () => {
    if (!isEmpty(permissionData) && !isEmpty(policies)) {
      return `${capitalize(permissionData?.type)}: ${permissionData?.name}`
    }

    return `${capitalize(permission?.type)}: ${permission.name}`
  }

  let modalButtons = [
    {
      text: 'Close',
      variant: 'secondary',
      onClick: handleModalClose,
      size: 'medium',
    },
  ]

  if (!isEmpty(permissionData) && !isEmpty(policies)) {
    modalButtons = [
      {
        text: (
          <img src={arrowLeft} className={`${classNamePrefix}-modal-back-button`} />
        ),
        variant: 'secondary',
        onClick: () => setPermissionData(null),
        size: 'medium',
      },
      ...modalButtons,
    ]
  }

  const shouldRenderEnironmentSelector = () => {
    if (hideEnvironmentSelector) {
      return false
    }

    if (isEmpty(permissionData)) {
      return true
    }

    return false
  }

  const renderEnvironmentSelector = () => {
    return (
      <>
        <Select
          options={environmentList.filter(env => env.value !== environment?.value)}
          onChange={handleEnvironmentChange}
          label="Select an environment to view permissions"
          getOptionLabel={option => option.title}
          value={environment}
          width="400px"
        />
        <br />
      </>
    )
  }

  const renderNoDataMessage = () => {
    if (
      !isAws &&
      permission.type !== 'group' &&
      isEmpty(permissionData) &&
      isEmpty(policies) &&
      !isLoadingPermissionDetails
    ) {
      return (
        <>
          <Typography variant="label1">No associated permission found.</Typography>
          <br />
        </>
      )
    }

    return null
  }

  const renderErrorSnackbar = () => {
    if (error?.length) {
      return (
        <>
          <Snackbar errorList={[error]} />
          <br />
        </>
      )
    }
  }

  return (
    <div key={`permission-drawer-${permission.name}`}>
      <ModalPopup
        width={720}
        title={getModalTitle()}
        onCancel={handleModalClose}
        buttons={modalButtons}
        classNamePrefix="profile-permission-drawer"
      >
        <div style={styles.wrapper}>
          {shouldRenderEnironmentSelector() && renderEnvironmentSelector()}
          {renderErrorSnackbar()}
          {renderNoDataMessage()}
          {isEmpty(permissionData) && (
            <PolicyList
              data={policies}
              setPermissionData={setPermissionData}
              isLoading={isLoadingPermissionDetails}
            />
          )}
          {!isEmpty(permissionData) && (
            <PermissionDefinition permission={permissionData} />
          )}
          {permission.type === 'group' && (
            <GroupTable
              data={groupData}
              isLoading={isLoadingPermissionDetails}
              environmentList={environmentList}
              appId={appId}
              supportedPermissionTypes={supportedPermissionTypes}
              hideEnvironmentSelector={hideEnvironmentSelector}
              rootEnvironmentGroup={rootEnvironmentGroup}
              isAws={isAws}
              forRequestBuilder={forRequestBuilder}
            />
          )}
        </div>
      </ModalPopup>
    </div>
  )
}

export default memo(PermissionsDrawer)
