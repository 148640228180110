import React, { memo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import get from 'lodash/get'

import Environment from './Environment'
import EntityIcon from './EntityIcon'

import useContainsSearchInput from 'hooks/useContainsSearchInput'

const Wrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
`

const Label = styled.div`
  align-items: center;
  display: flex;
  height: 28px;
  width: 100%;
  background: #fff;

  &.selected {
    background: #f7f7f7;
  }

  ${props =>
    props.enableHover
      ? `
    cursor: pointer;

    &:hover {
      background: #f7f7f7;
    }
  `
      : ''}

  .fa {
    margin-right: 8px;
  }

  .name {
    font-weight: 600;
  }
`

const RowSpacer = styled.div`
  align-items: center;
  display: flex;
  margin-left: ${props => props.level * 18 + 2}px;
  width: 100%;
`

const NameContainer = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  max-width: 400px;

  .name {
    font-weight: 600;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`

const Content = styled.div`
  ${props => (props.isCollapsed ? 'display: none;' : '')}
  width: 100%;
`

const Environments = styled.div``

const CollapseButton = styled.div`
  position: relative;
  cursor: pointer;
  height: 12px;
  margin-right: 4px;
  width: 12px;

  > i {
    position: absolute;
    left: 50%;
    line-height: 1px;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`

function EnvironmentGroupName({ group }) {
  return <span className="name">{group.name}</span>
}

function EnvironmentGroup(props) {
  const {
    applicationRoot,
    customRenderers,
    checkmarkDict,
    environmentGroup: group,
    environmentGroups,
    environmentScanResults,
    environments,
    environmentData,
    onClicks,
    parentSelected,
    selectedEntityId,
    parentContains,
    parentDisabled,
    isSearchable,
    level,
    isRoot,
    searchInput,
    searchTagDict,
    visualCuesData,
    visualCueDataType,
  } = props

  const [isCollapsed, setIsCollapsed] = useState(false)

  const {
    selfContains,
    childrenContain,
    calcContainsSearchInput,
  } = useContainsSearchInput()

  useEffect(() => {
    if (isSearchable) {
      calcContainsSearchInput({
        searchInput: searchInput.toLowerCase(),
        searchTagDict,
        id: group.id,
        name: group.name.toLowerCase(),
      })
    }
  }, [isSearchable, searchTagDict, searchInput])

  const constructVisualCuesData = id => {
    return visualCuesData && visualCuesData.length > 0
      ? visualCuesData.filter(entity => entity.environmentId === id)[0]
      : {}
  }

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed)
  }

  const hasChildren = () => {
    const { environmentGroup: group, environmentGroups, environments } = props

    const childrenGroups = group.childGroupIds.map(id => environmentGroups[id])

    const childrenEnvironments = group.childEnvironmentIds.map(
      id => environments[id]
    )

    return !!childrenGroups.length || !!childrenEnvironments.length
  }

  const parentCollapsed = props.isCollapsed
  const childrenGroups = group.childGroupIds.map(id => environmentGroups[id])
  const childrenEnvironments = group.childEnvironmentIds.map(id => environments[id])
  const groupActionsRenderer = get(customRenderers, 'groupActions')
  const groupCheckboxRenderer = get(customRenderers, 'groupCheckbox')
  const NameRenderer = get(customRenderers, 'groupName') || EnvironmentGroupName
  const entityObject = {
    entityType: 'EnvironmentGroup',
    entityId: group.id,
  }

  const onClick = get(onClicks, 'group')

  return (
    <Wrapper>
      {!isRoot &&
        (!isSearchable || selfContains || parentContains || childrenContain) && (
          <Label
            enableHover={onClick}
            className={selectedEntityId === group.id ? 'selected' : ''}
          >
            <RowSpacer level={level}>
              <CollapseButton onClick={toggleCollapse}>
                {hasChildren() && (
                  <React.Fragment>
                    {isCollapsed ? (
                      <i className="fa fa-plus fs:10" />
                    ) : (
                      <i className="fa fa-minus fs:10" />
                    )}
                  </React.Fragment>
                )}
              </CollapseButton>

              <NameContainer
                onClick={
                  onClick
                    ? e => {
                        e.stopPropagation()
                        onClick(entityObject)
                      }
                    : undefined
                }
              >
                {groupCheckboxRenderer &&
                  groupCheckboxRenderer(
                    entityObject,
                    parentSelected,
                    parentDisabled
                  )}

                <EntityIcon entities={environmentGroups} id={group.id} />

                <NameRenderer group={group} />
              </NameContainer>
            </RowSpacer>

            {groupActionsRenderer &&
              groupActionsRenderer(entityObject, null, parentCollapsed)}
          </Label>
        )}

      <Content isCollapsed={isCollapsed}>
        {childrenGroups.map(childGroup => (
          <EnvironmentGroup
            {...props}
            environmentGroup={childGroup}
            applicationRoot={applicationRoot}
            isCollapsed={isCollapsed || parentCollapsed}
            isRoot={false}
            isSearchable={isSearchable}
            key={childGroup.id}
            level={level + 1}
            parentContains={parentContains || selfContains}
            parentSelected={parentSelected || checkmarkDict[group.id]}
            searchTagDict={searchTagDict}
          />
        ))}

        <Environments>
          {childrenEnvironments.map((environment, index) => (
            <Environment
              customRenderers={customRenderers}
              applicationRoot={applicationRoot}
              environment={environment}
              environmentData={environmentData}
              environmentScanResults={environmentScanResults}
              onClicks={onClicks}
              isCollapsed={isCollapsed || parentCollapsed}
              isSearchable={isSearchable}
              key={get(environment, 'id', index)}
              level={level}
              parentContains={parentContains || selfContains}
              parentSelected={parentSelected || checkmarkDict[group.id]}
              parentDisabled={parentDisabled}
              searchInput={searchInput}
              searchTagDict={searchTagDict}
              selectedEntityId={selectedEntityId}
              visualCueData={constructVisualCuesData(environment.id)}
              visualCueDataType={visualCueDataType}
              isChecked={
                parentSelected ||
                checkmarkDict[group.id] ||
                checkmarkDict[environment.id]
              }
            />
          ))}
        </Environments>
      </Content>
    </Wrapper>
  )
}

EnvironmentGroup.propTypes = {
  environmentGroup: PropTypes.object,
  environmentGroups: PropTypes.object,
  environmentId: PropTypes.string,
  applicationEnvironmentData: PropTypes.object,
  environmentScanResults: PropTypes.object,
  applicationRoot: PropTypes.object,
  environments: PropTypes.object,
  formMode: PropTypes.string,
  isRoot: PropTypes.bool,
  level: PropTypes.number,
  visualCuesData: PropTypes.array,
  visualCueDataType: PropTypes.string,
}

export default memo(EnvironmentGroup)
