export const supportItems = [
  {
    name: 'Britive Support',
    link: 'http://support.britive.com/',
  },
  {
    name: 'Britive Documentation',
    link: 'https://docs.britive.com/',
  },
]
