import { put, takeLatest, call, all, fork } from 'redux-saga/effects'
import toast from 'utils/toast/index.js'
import { systemAnnouncementSuccess } from './action'
import { SystemAnnouncementDataTypes } from './constant'
import { fetchSystemAnnouncement } from './service'

function* systemAnnouncementWatcher() {
  yield takeLatest(
    SystemAnnouncementDataTypes.SYSTEM_ANNOUNCEMENT_REQUEST,
    getSystemAnnouncement
  )
}

function* getSystemAnnouncement() {
  try {
    const response = yield call(fetchSystemAnnouncement)
    yield put(systemAnnouncementSuccess(response))
  } catch ({ response }) {
    const reason = response?.data?.message || ''
    toast({
      title: reason,
      type: 'error',
      time: 'normal',
      id: reason,
    })
  }
}

export default all([fork(systemAnnouncementWatcher)])
