import { useState } from 'react'
import isEmpty from 'lodash/isEmpty'

const useTableSort = customHandlers => {
  const [currentSort, setCurrentSort] = useState({
    order: 'unsorted',
    by: null,
  })

  const getSortedData = data => {
    if (isEmpty(data)) {
      return []
    }

    let customHandler = customHandlers?.find(
      handler => handler.field === currentSort.by
    )
    const getValue = customHandler?.getValue || (row => row[currentSort.by])

    let sortedData = [...data]
    if (currentSort.order !== 'unsorted') {
      sortedData.sort((a, b) => {
        const valueA = getValue(a)
        const valueB = getValue(b)
        if (valueA > valueB) {
          return currentSort.order === 'ascending' ? 1 : -1
        }

        if (valueB > valueA) {
          return currentSort.order === 'ascending' ? -1 : 1
        }

        return 0
      })
    }

    return sortedData
  }

  const sortHandler = (order, by) => {
    setCurrentSort({
      order,
      by,
    })
  }

  return [sortHandler, getSortedData, currentSort]
}

export default useTableSort
