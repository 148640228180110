import React, { Fragment, PureComponent } from 'react'
import styled from 'styled-components'
import get from 'lodash/get'
import ReactTooltip from 'react-tooltip'

import EnvironmentGroups from 'components/EnvironmentGroups'
import EnvironmentGroupTooltip from './EnvironmentGroupTooltip'
import { BackgroundTooltip } from 'components/Tooltips'

const GroupActions = styled.div`
  padding: 0 4px;
  height: 28px;
  display: flex;
  background: #fff;
  right: -1px;
  position: sticky;
  align-items: center;

  .success {
    color: var(--success);
  }

  ${props => (props.hide ? 'display: none;' : '')}
`

const FailureIcon = styled.i`
  color: var(--error);
  margin-right: 4px;
  position: relative;
  width: 14px;
`
const SuccessIcon = styled.i`
  color: var(--success);
  margin-right: 4px;
  position: relative;
  width: 14px;
`

const CheckboxInput = styled.input`
  cursor: ${props => (props.disabled ? 'normal;' : 'pointer')};
`

const styles = {
  success: {
    color: 'green',
    marginRight: 4,
  },
  checkbox: {
    marginRight: 4,
  },
}

class Checkbox extends PureComponent {
  render() {
    const { entity, parentSelected, papScope } = this.props

    return (
      <CheckboxInput
        disabled
        checked={!!((papScope && papScope[entity.entityId]) || parentSelected)}
        key={new Date()}
        type="checkbox"
        style={styles.checkbox}
      />
    )
  }
}

function Environment({ entity, isCollapsed, integrityChecks }) {
  const failIconRef = React.useRef()
  const succeeded = integrityChecks
    ? get(integrityChecks[entity.entityId], 'succeeded', null)
    : null

  if (succeeded === null) {
    return null
  }

  return (
    <GroupActions hide={isCollapsed}>
      {succeeded ? (
        <SuccessIcon
          className="fas fa-check-circle"
          data-tip="Success"
          ref={failIconRef}
        />
      ) : (
        <FailureIcon
          data-tip={entity.entityId}
          ref={failIconRef}
          onMouseEnter={() => ReactTooltip.show(failIconRef.current)}
          onMouseLeave={() => ReactTooltip.hide(failIconRef.current)}
          className="fas fa-times-circle"
        />
      )}
    </GroupActions>
  )
}

export default class PapsEnvironmentGroups extends PureComponent {
  // TODO: isCollapsed should be an option
  renderEnvironment = (entity, groups, isCollapsed) => {
    const { integrityChecks } = this.props

    return (
      <Environment
        entity={entity}
        isCollapsed={isCollapsed}
        integrityChecks={integrityChecks}
      />
    )
  }

  renderCheckbox = (entity, parentSelected) => {
    const { scope } = this.props
    return (
      <Checkbox papScope={scope} parentSelected={parentSelected} entity={entity} />
    )
  }

  renderTooltipContent = content => {
    const { integrityChecks } = this.props

    return (
      <EnvironmentGroupTooltip content={content} integrityChecks={integrityChecks} />
    )
  }

  render() {
    const { scope, showIntegrityCheckStatus } = this.props

    return (
      <Fragment>
        <EnvironmentGroups
          checkmarkDict={scope}
          customRenderers={{
            rootGroupActions: showIntegrityCheckStatus
              ? this.renderEnvironment
              : null,
            environmentActions: showIntegrityCheckStatus
              ? this.renderEnvironment
              : null,
            groupActions: showIntegrityCheckStatus ? this.renderEnvironment : null,
            rootGroupCheckbox: this.renderCheckbox,
            environmentCheckbox: this.renderCheckbox,
            groupCheckbox: this.renderCheckbox,
          }}
          width="fit-content"
          minWidth="100%"
        />

        <BackgroundTooltip
          effect="solid"
          place="left"
          getContent={this.renderTooltipContent}
        />
      </Fragment>
    )
  }
}
