import React, { Fragment, memo } from 'react'

import SectionTab from '../../SectionTab'
import ConfirmationModal from 'components/ConfirmationModal'

function AccountMappingSection({
  createNewMode,
  fields,
  isLoading,
  isConfirmationModalOpen,
  isDeletingRules,
  onCancel,
  onChange,
  onSubmit,
  saving,
  restoreRules,
  deleteRules,
  rulesToDelete,
  userAccountRules,
  properties,
  propertyTypes,
  groups,
  thisAppManage,
}) {
  const renderConfirmationMessage = () => {
    return (
      <Fragment>
        <div>
          The following account rule(s) will be removed for all environments:
        </div>

        {rulesToDelete.map(id => {
          const user = userAccountRules[id]
          const name = `${user.firstName} ${user.lastName}`
          const accountName = user.accountUsername
          const message = `User "${name}" maps to account "${accountName}"`

          return (
            <div key={id} style={{ fontWeight: 'normal' }}>
              {message}
            </div>
          )
        })}
      </Fragment>
    )
  }

  return (
    <Fragment>
      <SectionTab
        isLoading={isLoading}
        properties={properties}
        groups={groups}
        propertyTypes={propertyTypes}
        onChange={onChange}
        onSubmit={onSubmit}
        fields={fields}
        createNewMode={createNewMode}
        saving={saving}
        onCancel={onCancel}
        thisAppManage={thisAppManage}
      />

      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        confirmationMessage={renderConfirmationMessage()}
        cancelProperties={{
          onClick: restoreRules,
          text: 'Cancel',
          inProgress: false,
        }}
        confirmProperties={{
          onClick: deleteRules,
          text: 'Ok',
          inProgress: isDeletingRules,
        }}
      />
    </Fragment>
  )
}

export default memo(AccountMappingSection)
