import React, { Fragment, memo } from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import capitalize from 'lodash/capitalize'
import FontAwesome from 'react-fontawesome'
import {
  ActionIconLabel,
  ActionIconGroup,
} from 'britive-ui-components/core/components/ActionIcon'
import Button from 'britive-ui-components/core/components/Button'

import Breadcrumbs from 'components/breadcrumbs'
import ActionDropdownButton from 'components/ActionDropdownButton'
import TableActionIcon from 'components/TableActionIcon'
import PageLoader from 'components/PageLoader'
import AppsActionModalWrapper from './AppsActionModalWrapper'
import TableSection from 'components/table/tableSectionV2'
import TableCheckboxCell from 'components/table/TableV2/TableCheckboxCell'
import TableEllipsisCell from 'components/table/TableV2/TableEllipsisCell'

import useMaxTableHeight from 'hooks/useMaxTableHeight'
import Typography from 'britive-design-system/core/components/typography'

import * as routing from 'services/routing'

const ACTIONS = ['Disable', 'Enable', 'Delete']

const CancelButton = styled(Button)`
  background-color: rgba(242, 38, 19, 1);
  border-color: rgba(242, 38, 19, 1);
  color: #fff !important;

  &:disabled {
    background-color: rgba(242, 38, 19, 0.7);
    border-color: transparent;
    color: rgba(0, 0, 0, 0.8) !important;
    cursor: not-allowed;
  }
`

const styles = {
  actionButton: {
    marginRight: 10,
    height: 35,
  },
  buttonsWrapper: {
    display: 'inline-flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  appImg: {
    maxHeight: '40px',
    width: '40px',
    marginRight: 16,
  },
  pageTitle: {
    marginBottom: 0,
  },
  tableLabel: {
    marginTop: 20,
  },
  cellSection: {
    color: '#067fdb',
    cursor: 'pointer',
  },
}

function AdminApps(props) {
  const maxTableHeight = useMaxTableHeight({
    heights: [
      20, //padding
      20, // padding
      36, // breadcrumbs
      35, // title
      36, // tableHeader
      22, // table margin top
    ],
    minHeight: 100,
  })

  const renderButtons = () => {
    const {
      areAnyAppsSelected,
      currentActionMode,
      clearCheckedItems,
      selectActionMode,
    } = props

    return (
      <div style={styles.buttonsWrapper}>
        {!currentActionMode && (
          <React.Fragment>
            <ActionDropdownButton
              onOptionClick={selectActionMode}
              actions={ACTIONS}
              itemName="Application"
            />

            <Button
              color="primary"
              style={styles.actionButton}
              onClick={routing.createApplicationList}
            >
              Create Application
            </Button>
          </React.Fragment>
        )}

        {currentActionMode && (
          <React.Fragment>
            <CancelButton
              color="delete"
              style={styles.actionButton}
              onClick={() => {
                clearCheckedItems()
                selectActionMode()
              }}
            >
              Cancel
            </CancelButton>

            <Button
              disabled={!areAnyAppsSelected}
              color="primary create-application"
              style={styles.actionButton}
              onClick={() => props.toggleModal(currentActionMode)}
            >
              {currentActionMode} Applications
            </Button>
          </React.Fragment>
        )}
      </div>
    )
  }

  const viewHandler = appId => {
    props?.history.push({
      pathname: props?.match.url + `/applications/${appId}/profiles`,
    })
  }

  const renderApplicationName = ({
    original: { iconUrl, appContainerId },
    value,
  }) => {
    return (
      <React.Fragment>
        <img src={iconUrl} alt="app logo" style={styles.appImg} />
        {requestAccess ? (
          <span
            style={styles.cellSection}
            onClick={() => viewHandler(appContainerId)}
          >
            <TableEllipsisCell value={value} />
          </span>
        ) : (
          <TableEllipsisCell value={value} />
        )}
      </React.Fragment>
    )
  }

  function renderActionIcons({ appId, appType }) {
    return (
      <ActionIconGroup>
        <TableActionIcon
          id="appsAdminDashboardLink"
          onClick={() => routing.appOverview({ appId, appType })}
          data-id={appId}
        >
          <FontAwesome name="sliders-h" size="lg" />
          <ActionIconLabel>Manage</ActionIconLabel>
        </TableActionIcon>
      </ActionIconGroup>
    )
  }

  const renderCheckbox = (isHeader, { original: data }) => {
    const {
      areAllAppsSelected,
      toggleAllCheckboxes,
      checkedItems,
      toggleCheckbox,
    } = props

    const checked = isHeader ? areAllAppsSelected : checkedItems[data.appContainerId]
    const onClick = () =>
      isHeader
        ? toggleAllCheckboxes('applicationName')
        : toggleCheckbox(data.appContainerId, data.applicationName)

    return <TableCheckboxCell data={data} onClick={onClick} checked={checked} />
  }

  const {
    checkedItems,
    clearCheckedItems,
    currentActionMode,
    disableApplication,
    enableApplication,
    deleteApplication,
    fetching,
    filterDataSet,
    formattedApps,
    isModalOpen,
    selectActionMode,
    setDataOverride,
    toggleModal,
    appManage,
    requestAccess,
  } = props

  let emptyTableMessage = requestAccess
    ? 'No applications available for profile requests.'
    : 'No applications defined. Click Add Application to add new applications to this account'

  if (currentActionMode) {
    emptyTableMessage = `Currently, there are no applications to ${currentActionMode.toLowerCase()}`
  }

  const adminColumns = [
    {
      Header: 'Application',
      accessor: 'applicationName',
      minWidth: 240,
      Cell: renderApplicationName,
    },
    { Header: 'Type', accessor: 'type', minWidth: 120 },
    { Header: 'Description', accessor: 'description', minWidth: 180 },
    {
      Header: 'Status',
      accessor: 'status',
      minWidth: 50,
      Cell: ({ value }) => <TableEllipsisCell value={capitalize(value)} />,
    },
    {
      Header: 'Environments',
      accessor: 'environmentsCount',
      minWidth: 70,
    },
    { Header: 'Profiles', accessor: 'profilesCount', minWidth: 40 },
    { Header: 'Users', accessor: 'usersCount', minWidth: 30 },
    {
      Header: 'Manage',
      minWidth: 50,
      resizable: false,
      sortable: false,
      Cell: data =>
        renderActionIcons({
          appId: data.original.appContainerId,
          appType: data.original?.type,
        }),
    },
  ]

  const requestAccessColumns = [
    {
      Header: 'Application',
      accessor: 'applicationName',
      minWidth: 240,
      Cell: renderApplicationName,
    },
    { Header: 'Type', accessor: 'type', minWidth: 120 },
    { Header: 'Description', accessor: 'description', minWidth: 180 },
  ]

  return (
    <Fragment>
      {requestAccess === undefined || requestAccess === false ? (
        <>
          <Breadcrumbs>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <NavLink to="/admin">System Administration</NavLink>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Tenant Apps
                </li>
              </ol>
            </nav>
          </Breadcrumbs>

          <div className="page-header-container">
            <h4 className="page-title" style={styles.pageTitle}>
              Tenant Applications
            </h4>

            {appManage && renderButtons()}
          </div>
        </>
      ) : (
        <>
          <div className="page-header-wrapper">
            <Typography variant="heading5">Access Builder - Applications</Typography>
          </div>
          <div style={styles.tableLabel}>
            <Typography variant="heading6">
              Choose an application to start
            </Typography>
          </div>
        </>
      )}

      {fetching ? (
        <PageLoader text="Loading Applications" />
      ) : (
        <div className="tenant_applications_v2">
          <TableSection
            initialColumn={{
              clearInitialColumnStatus: clearCheckedItems,
              InitialColumnComponent: props => renderCheckbox(false, props),
              InitialColumnHeaderComponent: props => renderCheckbox(true, props),
              showInitialColumn: !!currentActionMode,
              initialColumnWidth: 24,
            }}
            resizable={true}
            useCompoundFilter={true}
            filterDataSet={filterDataSet}
            compoundFilterCategories={[
              { label: 'Status', value: 'status' },
              { label: 'Type', value: 'type' },
            ]}
            columns={requestAccess ? requestAccessColumns : adminColumns}
            searchKeys={['applicationName', 'type', 'description', 'status']}
            identifier="appContainerId"
            selectedData={null}
            filterByOptions={[
              {
                label: 'Active',
                value: 'active',
              },
              {
                label: 'Inactive',
                value: 'inactive',
              },
            ]}
            filterByKey={'status'}
            title="Add Permissions"
            data={formattedApps ? formattedApps : []}
            emptyTableMessage={emptyTableMessage}
            autoLayout
            defaultSorted={[{ id: 'applicationName', desc: false }]}
            setDataOverride={setDataOverride}
            sortable
            maxHeight={`${maxTableHeight}px`}
            showPagination
          />
        </div>
      )}

      <AppsActionModalWrapper
        activeModalType={currentActionMode}
        selectModalType={selectActionMode}
        clearCheckedItems={clearCheckedItems}
        checkedItems={checkedItems}
        toggleModal={toggleModal}
        disableApplication={disableApplication}
        enableApplication={enableApplication}
        deleteApplication={deleteApplication}
        isModalOpen={isModalOpen}
        apps={formattedApps}
      />
    </Fragment>
  )
}

export default memo(AdminApps)
