import get from 'lodash/get'

import { fetchIfLoggedIn } from './utils.js'

export const UPDATE_ENVIRONMENT_GROUPS_STATE = 'UPDATE_ENVIRONMENT_GROUPS_STATE'
export const UPDATE_ENVIRONMENT = 'UPDATE_ENVIRONMENT'
export const UPDATE_ENVIRONMENT_GROUP = 'UPDATE_ENVIRONMENT_GROUP'
export const TEST_ENVIRONMENT = 'TEST_ENVIRONMENT'
export const SELECT_ENVIRONMENT_ID = 'SELECT_ENVIRONMENT_ID'
export const SELECT_ENVIRONMENT_GROUP_ID = 'SELECT_ENVIRONMENT_GROUP_ID'
export const FETCH_ENVIRONMENT_GROUPS = 'FETCH_ENVIRONMENT_GROUPS'
export const FETCH_ENVIRONMENTS = 'FETCH_ENVIRONMENTS'
export const FETCH_ACCESS_ENVIRONMENTS = 'FETCH_ACCESS_ENVIRONMENTS'
export const CREATE_ENVIRONMENT_ENTITY = 'CREATE_ENVIRONMENT_ENTITY'
export const SCAN_ENVIRONMENT = 'SCAN_ENVIRONMENT'
export const FETCH_ENVIRONMENT_MINIMAL = 'FETCH_ENVIRONMENT_MINIMAL'
export const FETCH_ENVIRONMENT_SCANS_REPORT = 'FETCH_ENVIRONMENT_SCANS_REPORT'
export const FETCH_ENVIRONMENT_SCAN_REPORT = 'FETCH_ENVIRONMENT_SCAN_REPORT'
export const DELETE_ENVIRONMENT = 'DELETE_ENVIRONMENT'
export const THIS_APP_MANAGE = 'THIS_APP_MANAGE'

export const selectEnvironmentId = id => ({
  type: SELECT_ENVIRONMENT_ID,
  payload: id,
})

export const selectEnvironmentGroupId = id => ({
  type: SELECT_ENVIRONMENT_GROUP_ID,
  payload: id,
})

export const updateEnvironmentGroupsState = appArray => {
  const environments = {}
  const environmentGroups = {}

  appArray.forEach(app => {
    const groups = get(app, 'rootEnvironmentGroup.environmentGroups')

    if (groups) {
      const groupsObject = {}

      groups.forEach(group => {
        groupsObject[group.id] = group
      })

      environmentGroups[app.appContainerId] = groupsObject
    }

    const envs = get(app, 'rootEnvironmentGroup.environments')

    if (envs) {
      const envsObject = {}

      envs.forEach(env => {
        envsObject[env.id] = env
      })

      environments[app.appContainerId] = envsObject
    }
  })

  return {
    type: UPDATE_ENVIRONMENT_GROUPS_STATE,
    payload: {
      environmentGroups,
      environments,
    },
  }
}

export const updateEnvironment = (
  applicationRootId,
  environmentId,
  data
) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: UPDATE_ENVIRONMENT,
      method: 'patch',
      path: `/apps/${applicationRootId}/environments/${environmentId}/properties`,
      postBody: data,
    })
  )
}

export const updateEnvironmentGroup = (
  applicationRootId,
  groupId,
  data
) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: UPDATE_ENVIRONMENT_GROUP,
      method: 'patch',
      meta: { applicationRootId, groupId },
      path: `/apps/${applicationRootId}/root-environment-group/groups/${groupId}`,
      postBody: data,
    })
  )
}

export const testEnvironment = (appId, environmentId) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: TEST_ENVIRONMENT,
      method: 'post',
      path: `/apps/${appId}/environments/${environmentId}/tests`,
    })
  )
}

export const fetchEnvironmentGroups = appId => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: FETCH_ENVIRONMENT_GROUPS,
      meta: { appId },
      method: 'get',
      path: `/apps/${appId}/root-environment-group`,
    })
  )
}

export const scanEnvironment = (appId, envId) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: SCAN_ENVIRONMENT,
      meta: { appId, envId },
      method: 'post',
      path: `/apps/${appId}/environments/${envId}/scans`,
    })
  )
}

export const fetchEnvironmentScanReport = ({ appId, envId }) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: FETCH_ENVIRONMENT_SCAN_REPORT,
      meta: { appId, envId },
      method: 'get',
      path: `/apps/${appId}/environments/${envId}/scans`,
    })
  )
}

export const fetchEnvironmentScansReport = ({ appId }) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: FETCH_ENVIRONMENT_SCANS_REPORT,
      meta: { appId },
      method: 'get',
      path: `/apps/${appId}/scans/env-status`,
    })
  )
}

export const fetchEnvironments = (appId, requestAccess) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: FETCH_ENVIRONMENTS,
      method: 'get',
      path: requestAccess
        ? `/profile-requests/apps/${appId}/environments`
        : `/apps/${appId}/environments`,
    })
  )
}

export const fetchEnvironmentsMinimal = appId => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: FETCH_ENVIRONMENT_MINIMAL,
      method: 'get',
      path: `/apps/${appId}/environments?view=minimal`,
    })
  )
}

export const fetchAccessEnvironments = appId => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: FETCH_ACCESS_ENVIRONMENTS,
      method: 'get',
      path: `/access/apps/${appId}/environments`,
    })
  )
}

export const createEnvironmentEntity = (appId, entityId, values) => dispatch => {
  const entityType = values.type
  const postBody = { ...values }

  if (entityType === 'environment') {
    postBody.parentGroupId = entityId
  } else {
    postBody.parentId = entityId
  }

  return dispatch(
    fetchIfLoggedIn({
      actionType: CREATE_ENVIRONMENT_ENTITY,
      method: 'post',
      path: `/apps/${appId}/root-environment-group/${entityType}s`,
      postBody,
    })
  )
}

export const deleteEnvironment = ({ appId, envId }) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: DELETE_ENVIRONMENT,
      method: 'delete',
      path: `/apps/${appId}/environments/${envId}`,
    })
  )
}

export const deleteEnvironmentGroup = ({ appId, envGroupId }) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: DELETE_ENVIRONMENT,
      method: 'delete',
      path: `/apps/${appId}/environment-groups/${envGroupId}`,
    })
  )
}

export const thisAppManageAction = response => ({
  type: THIS_APP_MANAGE,
  payload: response,
})
