import React, { memo, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import get from 'lodash/get'

import EntityIcon from './EntityIcon'

import useContainsSearchInput from 'hooks/useContainsSearchInput'
import { BackgroundTooltip as Tooltip } from 'components/Tooltips'
import VisualCuesPills from 'components/VisualCuesPills'
import { formatDateShortWithYear } from 'utils/format_date'

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  height: 28px;
  background: #fff;
  justify-content: space-between;

  &.selected {
    background: #f7f7f7;
  }

  .inactive {
    opacity: 0.6;
    font-style: italic;
  }

  .inactive-icon {
    margin-left: 5px;
    margin-bottom: 2px;
    color: orange;
    font-size: 12px;
    margin-right: 2px;
    height: 12px;
    margin-left: 4px;
  }

  > span {
    margin-right: 8px;
  }

  ${props =>
    props.enableHover
      ? `
    cursor: pointer;

    &:hover {
      background: #f7f7f7;
    }
  `
      : ''}
`
const styles = {
  icons: {
    alignItems: 'center',
    display: 'flex',
  },
  controlStatusIcon: {
    display: 'inline-flex',
    marginRight: 2,
  },
  success: {
    cursor: 'pointer',
    color: 'green',
    marginRight: 2,
    height: 12,
    marginLeft: 4,
  },
  successSel: { cursor: 'pointer', color: 'green', marginRight: 2, height: 12 },
}
const NameContainer = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  margin-left: ${props => 20 + props.level * 16}px;
  position: relative;
  max-width: 400px;

  .name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

function Environment({
  customRenderers,
  applicationRoot,
  environment,
  environmentData,
  environmentScanResults,
  isCollapsed,
  isSearchable,
  level,
  onClicks,
  parentContains,
  parentDisabled,
  parentSelected,
  searchInput,
  searchTagDict,
  selectedEntityId,
  visualCueData,
  visualCueDataType,
  isChecked,
}) {
  const { selfContains, calcContainsSearchInput } = useContainsSearchInput()
  const envId = get(environment, 'id')
  const environmentDatum = environmentData[envId]
  const environmentName =
    get(environmentDatum, 'catalogAppDisplayName') || get(environment, 'name') || ''

  const IconRenderer = ({ environment, status }) => {
    const envId = get(environment, 'id')
    const env = get(environmentData, [envId], {})
    const envScanned =
      env.envScanStatus ||
      get(environmentScanResults, [envId, 'status']) === 'Success'

    const isHierarchical =
      applicationRoot.catalogApplication.requiresHierarchicalModel
    const isActive = status === 'active'
    return (
      <Fragment>
        {!isHierarchical && envScanned && isActive && (
          <div style={styles.icons}>
            <i
              data-tip
              data-for={environment.id}
              className="fas fa-check-circle fs:12"
              style={styles.success}
            />

            <Tooltip place="bottom" effect="solid" id={environment.id}>
              Integrated
            </Tooltip>
          </div>
        )}
        {!isActive && (
          <div style={styles.icons}>
            <i
              data-tip
              data-for={environment.id}
              className="fas fa-exclamation-circle inactive-icon"
            />

            <Tooltip place="bottom" effect="solid" id={environment.id}>
              Disabled
            </Tooltip>
          </div>
        )}
      </Fragment>
    )
  }
  useEffect(() => {
    if (isSearchable) {
      calcContainsSearchInput({
        searchInput: searchInput.toLowerCase(),
        searchTagDict,
        id: envId,
        name: environmentName.toLowerCase(),
      })
    }
  }, [isSearchable, searchTagDict, searchInput])

  const VisualCue = ({ visualCueData, visualCueDataType }) => {
    const getBackgroundColor = key => {
      if (visualCueDataType && key) {
        if (visualCueDataType.toLowerCase() === 'realtime') {
          return visualCueData[`${key}`]
            ? visualCueData[`${key}`].toLowerCase()
            : 'red'
        } else if (visualCueDataType.toLowerCase() === 'historical') {
          const percentValue = visualCueData[`${key}`] ? visualCueData[`${key}`] : 0

          let returnValue
          switch (true) {
            case percentValue > 0 && percentValue < 100:
              returnValue = 'yellow'
              break
            case percentValue >= 100:
              returnValue = 'green'
              break
            default:
              returnValue = 'red'
          }

          return returnValue
        }
      }
      return 'red'
    }

    const getTooltipText = key => {
      switch (key) {
        case 'activityLatestTimestamp':
          return visualCueData[`${key}`]
            ? `Activity: ${formatDateShortWithYear({
                dateTime: visualCueData[`${key}`],
                showTimeZone: false,
              })}`
            : 'Activity: No Data Received'
        case 'configSnapshotLatestTimestamp':
          return visualCueData[`${key}`]
            ? `Config: ${formatDateShortWithYear({
                dateTime: visualCueData[`${key}`],
                showTimeZone: false,
              })}`
            : 'Config: No Data Received'
        case 'histPercentComplete':
          return visualCueData[`${key}`]
            ? `Historical: ${visualCueData[`${key}`]}%`
            : 'Historical: No Data Received'
        default:
          return ''
      }
    }

    return visualCueDataType === 'realtime' ? (
      <Fragment>
        <VisualCuesPills
          id={`${envId}-A`}
          label="A"
          backgroundColor={getBackgroundColor('activityFlag')}
          showTooltip={true}
          tooltipText={getTooltipText('activityLatestTimestamp')}
        />
        <VisualCuesPills
          id={`${envId}-C`}
          label="C"
          backgroundColor={getBackgroundColor('config_snapshot_flag')}
          showTooltip={true}
          tooltipText={getTooltipText('configSnapshotLatestTimestamp')}
        />
      </Fragment>
    ) : (
      <Fragment>
        <VisualCuesPills
          id={`${envId}-H`}
          label="H"
          backgroundColor={getBackgroundColor('histPercentComplete')}
          showTooltip={true}
          tooltipText={getTooltipText('histPercentComplete')}
        />
      </Fragment>
    )
  }

  const environmentActionsRenderer = get(customRenderers, 'environmentActions')
  const environmentCheckboxRenderer = get(customRenderers, 'environmentCheckbox')

  const entityObject = {
    entityType: 'Environment',
    entityId: envId,
  }

  const showSelected = envId === selectedEntityId

  if (!environment || (isSearchable && !parentContains && !selfContains)) {
    return null
  }

  const onClick = get(onClicks, 'environment')
  return (
    <Wrapper
      className={showSelected && 'selected'}
      onClick={
        onClick
          ? e => {
              e.stopPropagation()
              onClick(entityObject)
            }
          : undefined
      }
      enableHover={onClick}
    >
      <NameContainer level={level}>
        {environmentCheckboxRenderer &&
          environmentCheckboxRenderer(entityObject, parentSelected, parentDisabled)}

        <IconRenderer environment={environment} status={environment.status} />
        <EntityIcon entity={environment} />

        <span title={environmentName} className={`${environment.status} name`}>
          {environmentName}
        </span>

        {isChecked &&
        visualCueDataType &&
        visualCueData !== undefined &&
        visualCueData !== null ? (
          <VisualCue
            visualCueData={visualCueData}
            visualCueDataType={visualCueDataType}
          />
        ) : (
          ''
        )}
      </NameContainer>

      {environmentActionsRenderer &&
        environmentActionsRenderer(entityObject, null, isCollapsed)}
    </Wrapper>
  )
}

Environment.propTypes = {
  environment: PropTypes.object,
  environmentData: PropTypes.object,
  environmentScanResults: PropTypes.object,
  applicationRoot: PropTypes.object,
  formMode: PropTypes.string,
  level: PropTypes.number,
  selectedEnvironmentId: PropTypes.string,
  visualCueData: PropTypes.object,
  visualCueDataType: PropTypes.string,
  isChecked: PropTypes.bool,
}

export default memo(Environment)
