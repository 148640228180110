import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'

import PropertiesForm from '../PropertiesForm'
import InfoModals from '../InfoModals'

import {
  testEnvironment as testEnvironmentAction,
  updateEnvironment,
} from 'action_creators/environment'

import { getSelectedApplicationRootId } from 'selectors/application_root'

import {
  getSelectedEnvironmentData,
  getSelectedEnvironmentId,
} from 'selectors/environment'

import { preparePropertiesObjectForAPI } from 'utils/prepare_properties'
import * as text from 'translations/english_us'
import {
  IS_CORRECTLY_CONFIGURED,
  IS_NOT_CORRECTLY_CONFIGURED,
} from 'translations/english_us'

export class EnvironmentFormContainer extends PureComponent {
  state = {
    fields: {},
    successModalOpen: false,
    createNewMode: false,
    testModalResultsOpen: false,
    testMessage: '',
    testModalResultsSuccess: false,
    testInProgress: false,
    createNewStepNumber: 1,
    finalStep: false,
    saveSuccessful: false,
    dropdownOpen: false,
    saveBtnAction: 'sat',
    testPassed: false,
    defaultState: {},
  }

  componentDidUpdate(prevProps) {
    const { selectedEnvId: prevEnvId } = prevProps
    const { selectedEnvId: envId } = this.props
    if (prevEnvId !== envId) {
      this.setState({ fields: {} })
    }
  }

  toggleDropdown = () => {
    const { dropdownOpen } = this.state

    this.setState({
      dropdownOpen: !dropdownOpen,
    })
  }

  handleChange = event => {
    const { target } = event
    const parent = !!target.getAttribute && target.getAttribute('dataparent')
    const { fields = {} } = this.state

    // Not sure in what cases the attribute will ahve a data parent
    if (parent) {
      this.setState({
        fields: {
          ...fields,
          [parent]: {
            propertyTypes: {
              ...(fields[parent] ? fields[parent].propertyTypes : null),
              [target.name]:
                target.type === 'checkbox' ? target.checked : target.value,
            },
          },
        },
      })
    } else {
      this.setState({
        fields: {
          ...fields,
          propertyTypes: {
            ...fields.propertyTypes,
            [target.name]:
              target.type === 'checkbox' ? target.checked : target.value,
          },
        },
      })
    }
  }

  updateSaveBtnAction = saveBtnAction => {
    this.setState({ saveBtnAction })
  }

  updateFieldsState = fields => {
    this.setState({ fields, defaultState: fields })
  }

  saveEnvironmentUpdates = async (environmentId, saveAction) => {
    const { dispatch, selectedEnvId, selectedAppId } = this.props
    const { fields } = this.state
    const envId = selectedEnvId || environmentId

    const updatedProperties = preparePropertiesObjectForAPI(fields)

    if (!isEmpty(fields)) {
      this.setState({
        createNewMode: false,
      })

      await dispatch(updateEnvironment(selectedAppId, envId, updatedProperties))
    }

    this.setState({
      saveSuccessful: true,
      createNewMode: false,
    })

    if (saveAction === 'sat') {
      this.testEnvironment(selectedAppId, envId)
    }
  }

  testEnvironment = async (applicationRootId, environmentId) => {
    const { dispatch } = this.props

    this.setState({
      testInProgress: true,
    })

    try {
      const response = await dispatch(
        testEnvironmentAction(applicationRootId, environmentId)
      )

      const { success, message } = response.action.payload.data
      return this.setState({
        testModalResultsOpen: true,
        testModalResultsSuccess: success,
        testInProgress: false,
        testPassed: success,
        testMessage:
          message && message.length > 0
            ? message
            : `Environment ${
                success ? IS_CORRECTLY_CONFIGURED : IS_NOT_CORRECTLY_CONFIGURED
              }`,
      })
    } catch (error) {
      this.setState({
        testModalResultsOpen: true,
        testModalResultsSuccess: false,
        testInProgress: false,
        testPassed: false,
        testMessage: get(
          error,
          'response.data.message',
          `Environment ${IS_NOT_CORRECTLY_CONFIGURED}`
        ),
      })
    }
  }

  toggleModal = () => {
    this.setState({
      successModalOpen: false, // this modal can only be closed
      testModalResultsOpen: false, // this modal can only be closed
    })
  }

  onClickMoveNextTab = tabChild => {
    const { environment } = this.props

    const sections = get(environment, 'catalogApplication.uiSettings', [])

    // this is kinda bad
    tabChild.moveNextTab()

    this.setState({
      createNewStepNumber: this.state.createNewStepNumber + 1,
      finalStep: this.state.createNewStepNumber + 1 === sections.length,
    })
  }

  onCancel = () => {
    this.setState({ fields: this.state.defaultState })
  }

  render() {
    const {
      appHeaderHeight,
      environmentData,
      updateFormMode,
      saving,
      selectedEntityId,
      testing,
      thisAppManage,
    } = this.props

    const {
      createNewMode,
      fields,
      dropdownOpen,
      saveBtnAction,
      successModalOpen,
      testInProgress,
      testPassed,
      testModalResultsSuccess,
      testModalResultsOpen,
      testMessage,
    } = this.state

    const app = get(environmentData, 'catalogApplication', {})
    const propertyTypes = {}

    if (app.propertyTypes) {
      app.propertyTypes.forEach(type => {
        propertyTypes[type.name] = type
      })
    }

    return (
      <div>
        <PropertiesForm
          isEnvironment
          app={app}
          appHeaderHeight={appHeaderHeight}
          createNewMode={createNewMode}
          dropdownOpen={dropdownOpen}
          fields={fields}
          handleChange={this.handleChange}
          onCancel={this.onCancel}
          propertyTypes={propertyTypes}
          saveBtnAction={saveBtnAction}
          saveUpdates={this.saveEnvironmentUpdates}
          saving={saving || testing}
          selectedApplicationRoot={environmentData}
          selectedEntityId={selectedEntityId}
          testPassed={testPassed}
          testInProgress={testInProgress}
          testModalResultsSuccess={testModalResultsSuccess}
          toggle={this.toggle}
          updateSaveBtnAction={this.updateSaveBtnAction}
          updateFormMode={updateFormMode}
          onClickMoveNextTab={this.onClickMoveNextTab}
          thisAppManage={thisAppManage}
        />

        <InfoModals
          typeOfUnit={text.ENVIRONMENT}
          unitName={get(environmentData, 'catalogAppDisplayName')}
          successModalOpen={successModalOpen}
          testModalResultsOpen={testModalResultsOpen}
          testMessage={testMessage}
          testModalResultsSuccess={testModalResultsSuccess}
          toggleModal={this.toggleModal}
        />
      </div>
    )
  }
}

EnvironmentFormContainer.propTypes = {
  app: PropTypes.object,
  createNewMode: PropTypes.bool,
  createApp: PropTypes.func,
  dispatch: PropTypes.func,
  environmentData: PropTypes.object,
  environment: PropTypes.object,
  fields: PropTypes.object,
  handleChange: PropTypes.func,
  onCancel: PropTypes.func,
  onClickMoveNextTab: PropTypes.func,
  propertyTypes: PropTypes.object,
  saveBtnAction: PropTypes.string,
  saveUpdates: PropTypes.func,
  saving: PropTypes.bool,
  selectedAppId: PropTypes.string,
  selectedEnvId: PropTypes.string,
  testPassed: PropTypes.bool,
  testing: PropTypes.bool,
  toggle: PropTypes.func,
  updateFormMode: PropTypes.func,
  updateSaveBtnAction: PropTypes.func,
  updatingEnvironment: PropTypes.bool,
}

const mapStateToProps = state => ({
  environmentData: getSelectedEnvironmentData(state),
  saving: state.environment.updatingEnvironment,
  testing: state.environment.testingEnvironment,
  selectedAppId: getSelectedApplicationRootId(state),
  selectedEnvId: getSelectedEnvironmentId(state),
})

export default connect(mapStateToProps)(EnvironmentFormContainer)
