import React from 'react'
import classNames from 'classnames'
import styled from 'styled-components'

const Wrapper = styled.div`
  &.changed {
    font-weight: 500;

    &.deleted {
      color: #cc0000;
    }
  }

  span {
    color: #ff4500;
    font-size: 0.4rem;
    margin-right: 5px;
    position: relative;
    top: -2px;
  }
`

const UNCHANGED = 'Unchanged'
const DELETED = 'Deleted'

export default function ScanStatusCell({ value }) {
  const className = classNames(
    value !== UNCHANGED ? 'changed' : 'unchanged',
    value === DELETED ? 'deleted' : ''
  )

  return (
    <Wrapper className={className}>
      {value !== 'Unchanged' && <span className="fa fa-circle fa-xs" />}

      {value}
    </Wrapper>
  )
}
