import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Accordion from 'britive-design-system/core/components/accordion'
import SectionTitle from '../SectionTitle'
import { sectionTitle } from '../constants'
import NotificationMediums from 'components/NotificationMediumsSelector'
import DeleteConfirmationDialog from '../DeleteConfirmationDialog'
import { getUpdatedNotificationMediums } from 'components/NotificationMediumsSelector/utils'

const NotificationMediumsSection = ({
  notificationMediums = [],
  updateSettings,
  disableEditing = false,
}) => {
  const [itemToDelete, setItemToDelete] = useState(null)

  const handleDelete = () => {
    updateSettings({
      notificationMediums: notificationMediums.filter(
        notificationMedium => notificationMedium.id !== itemToDelete
      ),
    })
    setItemToDelete(null)
  }

  const handleAdd = async (notificationMedium, isNew) => {
    await updateSettings({
      notificationMediums: getUpdatedNotificationMediums(
        notificationMediums,
        notificationMedium,
        isNew
      ),
    })
  }

  return (
    <Accordion expanded title={<SectionTitle {...sectionTitle.notifications} />}>
      <NotificationMediums
        notificationMediums={notificationMediums}
        onAdd={handleAdd}
        onDelete={setItemToDelete}
        disableEditing={disableEditing}
      />
      {itemToDelete && (
        <DeleteConfirmationDialog
          entity="notification medium"
          parentEntity="access builder settings"
          onDelete={handleDelete}
          onCancel={() => setItemToDelete(null)}
        />
      )}
    </Accordion>
  )
}

NotificationMediumsSection.propTypes = {
  notificationMediums: PropTypes.array,
}

export default NotificationMediumsSection
