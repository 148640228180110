import React from 'react'

import Spinner from 'britive-ui-components/core/components/Spinner'

const styles = {
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    marginLeft: 8,
  },
}

export default function PageLoader({ text, style }) {
  return (
    <div style={{ ...styles.wrapper, ...style }}>
      <Spinner size="2x" />
      <span style={styles.text}>{text}</span>
    </div>
  )
}
