export const mfaFactor = {
  totp: 'TOTP',
}

export const mfaStatus = {
  unregistered: 'UNREGISTERED',
  registered: 'REGISTERED',
  challenge: 'CHALLENGE',
  challengeFailed: 'CHALLENGE_FAILED',
  authFailed: 'FAILED',
}

export const cancelRegistrationWarning = `
  Click on "Yes, Cancel" in order to cancel the device registration.
`
