import { SystemAnnouncementDataTypes } from './constant'

export const openSystemAnnouncement = openAnnouncement => ({
  type: SystemAnnouncementDataTypes.OPEN_SYSTEM_ANNOUNCEMENT,
  payload: openAnnouncement,
})

export const systemAnnouncementRequest = () => ({
  type: SystemAnnouncementDataTypes.SYSTEM_ANNOUNCEMENT_REQUEST,
})

export const systemAnnouncementSuccess = announcementData => ({
  type: SystemAnnouncementDataTypes.SYSTEM_ANNOUNCEMENT_SUCCESS,
  payload: announcementData,
})
