import React from 'react'
import Accordion from 'britive-design-system/core/components/accordion'
import { AssociationsTree } from './AssociationsTree'

export const AssociationAccordion = ({ onChange, associations }) => {
  return (
    <Accordion title="Associations" expanded>
      <div className="associations-accordion-body-wrapper">
        <AssociationsTree onChange={onChange} associations={associations} />
      </div>
    </Accordion>
  )
}
