import React from 'react'
import FontAwesome from 'react-fontawesome'
import styled from 'styled-components'

const DropdownIcon = styled(FontAwesome)`
  cursor: pointer;
  transform: rotate(90deg);

  &.open {
    transform: rotate(180deg);
  }
`

const width = { width: 24 }

export default function TableDropdownCell(props) {
  const { isPlaceholder, isExpanded } = props
  return (
    <div style={width}>
      {!isPlaceholder && (
        <DropdownIcon className={isExpanded ? 'open' : ''} name="chevron-up" />
      )}
    </div>
  )
}
