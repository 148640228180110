import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'
import Button from 'britive-ui-components/core/components/Button'
import PropTypes from 'prop-types'
import FormField from 'components/form_fields'
import styled from 'styled-components'

import Spinner from 'britive-ui-components/core/components/Spinner'
import SearchNavigation from './SearchNavigation'

const SearchWrapper = styled.div`
  margin-left: 10px;
  position: relative;

  > .form-group {
    margin-bottom: 0;

    input {
      height: 28px;
    }
  }
`

const RefreshWrapper = styled.div`
  height: 16px;
  margin-left: 8px;
  cursor: ${props => (props.isRefreshing ? 'not-allowed' : 'pointer')};
`

const styles = {
  inputStyle: {
    boxSizing: 'border-box',
    height: '28px',
    width: '164px',
    border: '2px solid #9BBFDB',
    borderRadius: '4px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 2px 8px 3px rgba(206,206,206,0.21)',
    marginLeft: 10,
  },
  optionsStyle: {
    height: '14px',
    width: '99px',
    color: '#6F6F6F',
    fontFamily: 'WorkSans',
    fontSize: '12px',
    lineHeight: '14px',
  },
  controlsContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  tableHeader: {
    flex: 1,
    boxShadow: `0 2px 12px 2px #cddfed`,
    backgroundColor: '#fff',
    padding: '4px 4px',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    margin: 0,
    width: '100%',
  },
  selectField: {
    height: '14px',
    width: '99px',
    fontSize: '12px',
    color: '#6F6F6F',
    fontFamily: 'WorkSans',
  },
  headerCheckbox: {
    marginRight: 15,
    whiteSpace: 'nowrap',
  },
  filterWrapper: {
    whiteSpace: 'nowrap',
  },
}

class TableHeader extends Component {
  state = {
    sortBy: 'sortBy',
    filterCategory: 'filterCategory',
    filterCategoryData: [],
    filterBy: 'filterBy',
    filterByRegistered: false,
    filterByRegistrable: false,
    filterByInUse: false,
    filterByPermissions: false,
    filterByPrivileged: false,
    filterByExternal: false,
    filterByMappedAttributes: false,
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.filterCategory !== prevState.filterCategory) {
      this.filteredCategoryUpdate()
    }

    // this is bad and not easily maintainable
    return prevState.sortBy !== this.state.sortBy
      ? this.props.sortTable(this.state.sortBy)
      : prevState.filterBy !== this.state.filterBy
      ? this.props.filterTable(this.state.filterBy)
      : this.state.filterByRegistered !== prevState.filterByRegistered
      ? this.props.filterByRegistered(this.state.filterByRegistered)
      : this.state.filterByRegistrable !== prevState.filterByRegistrable
      ? this.props.filterByRegistrable(this.state.filterByRegistrable)
      : this.state.filterByPrivileged !== prevState.filterByPrivileged
      ? this.props.filterByPrivileged(this.state.filterByPrivileged)
      : this.state.filterByInUse !== prevState.filterByInUse
      ? this.props.filterByInUse(this.state.filterByInUse)
      : this.state.filterByExternal !== prevState.filterByExternal
      ? this.props.filterByExternal(this.state.filterByExternal)
      : this.state.filterByMappedAttributes !== prevState.filterByMappedAttributes
      ? this.props.filterByMappedAttributes(this.state.filterByMappedAttributes)
      : this.state.filterByPermissions !== prevState.filterByPermissions
      ? this.props.changeFilterByPermissions(this.state.filterByPermissions)
      : null
  }

  filteredCategoryUpdate() {
    this.setState(
      {
        filterCategoryData: this.props.filterDataSet[this.state.filterCategory],
      },
      () => {
        this.props.changeFilterCategory(this.state.filterCategory)
      }
    )
  }

  renderSortOptions() {
    const { optionsStyle } = styles
    const { sortByOptions = [] } = this.props

    return sortByOptions.map(options => {
      return (
        <option style={optionsStyle} key={options.label} value={options.attr}>
          {options.label}
        </option>
      )
    })
  }

  renderFilterOptions(filterByOptions = []) {
    const { optionsStyle } = styles
    // const { filterByOptions = [] } = this.props
    return filterByOptions.map(options => {
      return (
        <option style={optionsStyle} key={options.label} value={options.value}>
          {options.label}
        </option>
      )
    })
  }

  render() {
    const {
      allowFilterByInUse,
      allowFilterByPermissions,
      allowFilterByRegistered,
      allowFilterByRegistrable,
      allowFilterByPrivileged,
      allowFilterByExternal,
      allowFilterByMappedAttributes,
      buttonAction,
      buttonColor,
      buttonTitle,
      changeOuterSearch,
      compoundFilterCategories,
      filter,
      filterByOptions,
      refreshHandler,
      isRefreshing,
      searchKeys,
      searchResultsInfo,
      searchTerm,
      showButton,
      tableRef,
      updateSearchResultsInfo,
      useCompoundFilter,
    } = this.props

    const {
      inputStyle,
      controlsContainer,
      filterWrapper,
      headerCheckbox,
      tableHeader,
      optionsStyle,
    } = styles

    // TODO: showButton is such a bad abstraction
    // don't do this

    return (
      <div style={{ marginBottom: 2 }} className="table-header-v2">
        {showButton ? (
          <Row md={12} style={{ marginBottom: 20, height: 36 }}>
            <Col style={controlsContainer}>
              <Button
                color={buttonColor}
                style={{ marginRight: 10, height: 35 }}
                onClick={() => buttonAction()}
              >
                {buttonTitle}
              </Button>
            </Col>
          </Row>
        ) : null}
        <Row style={tableHeader}>
          <Col md={12}>
            <div style={controlsContainer}>
              {allowFilterByPermissions ? (
                <div style={headerCheckbox}>
                  <input
                    type="checkbox"
                    name="filterByPermissions"
                    checked={this.state.filterByPermissions}
                    onClick={() =>
                      this.setState({
                        filterByPermissions: !this.state.filterByPermissions,
                      })
                    }
                  />{' '}
                  With Permissions Only
                </div>
              ) : null}
              {allowFilterByInUse ? (
                <div style={headerCheckbox}>
                  <input
                    type="checkbox"
                    name="filterByRegistered"
                    checked={this.state.filterByInUse}
                    onClick={() =>
                      this.setState({
                        filterByInUse: !this.state.filterByInUse,
                      })
                    }
                  />{' '}
                  Assigned
                </div>
              ) : null}
              {allowFilterByRegistered ? (
                <div style={headerCheckbox}>
                  <input
                    type="checkbox"
                    name="filterByRegistered"
                    checked={this.state.filterByRegistered}
                    onClick={() =>
                      this.setState({
                        filterByRegistered: !this.state.filterByRegistered,
                      })
                    }
                  />{' '}
                  Registered
                </div>
              ) : null}
              {allowFilterByRegistrable ? (
                <div style={headerCheckbox}>
                  <input
                    type="checkbox"
                    name="filterByRegistrable"
                    checked={this.state.filterByRegistrable}
                    onClick={() =>
                      this.setState({
                        filterByRegistrable: !this.state.filterByRegistrable,
                      })
                    }
                  />{' '}
                  Registrable
                </div>
              ) : null}
              {allowFilterByPrivileged ? (
                <div style={headerCheckbox}>
                  <input
                    type="checkbox"
                    name="filterByPrivileged"
                    checked={this.state.filterByPrivileged}
                    onClick={() =>
                      this.setState({
                        filterByPrivileged: !this.state.filterByPrivileged,
                      })
                    }
                  />{' '}
                  High Risk
                </div>
              ) : null}
              {allowFilterByExternal ? (
                <div style={headerCheckbox}>
                  <input
                    type="checkbox"
                    name="filterByExternal"
                    checked={this.state.filterByExternal}
                    readOnly
                    onClick={() =>
                      this.setState({
                        filterByExternal: !this.state.filterByExternal,
                      })
                    }
                  />{' '}
                  Externally Managed
                </div>
              ) : null}
              {allowFilterByMappedAttributes ? (
                <div style={headerCheckbox}>
                  <input
                    type="checkbox"
                    name="filterByMappedAttributes"
                    checked={this.state.filterByMappedAttributes}
                    onClick={() =>
                      this.setState({
                        filterByMappedAttributes: !this.state
                          .filterByMappedAttributes,
                      })
                    }
                  />{' '}
                  Mapped Attributes
                </div>
              ) : null}
              {useCompoundFilter ? (
                <div style={filterWrapper}>
                  <select
                    value={this.state.filterCategory}
                    style={inputStyle}
                    onChange={e => {
                      const value = e.target.value
                      this.setState({ filterBy: 'filterBy' }, () => {
                        this.setState({ filterCategory: value })
                      })
                    }}
                  >
                    <option style={optionsStyle} value="filterCategory" disabled>
                      Filter By
                    </option>
                    {this.renderFilterOptions(compoundFilterCategories)}
                  </select>

                  <select
                    value={this.state.filterBy}
                    style={inputStyle}
                    onChange={e => {
                      this.setState({ filterBy: e.target.value }, () => {
                        if (this.state.filterBy === 'all') {
                          this.setState({ filterCategory: 'filterCategory' })
                        }
                      })
                    }}
                  >
                    <option style={optionsStyle} value="all">
                      All
                    </option>
                    {this.renderFilterOptions(this.state.filterCategoryData)}
                  </select>
                </div>
              ) : filter ? (
                <div style={filterWrapper}>
                  <select
                    value={this.state.filterBy}
                    style={inputStyle}
                    onChange={e => {
                      this.setState({ filterBy: e.target.value })
                    }}
                  >
                    <option style={optionsStyle} value="filterBy" disabled>
                      Filter By Status
                    </option>
                    <option style={optionsStyle} value="all">
                      All
                    </option>
                    {this.renderFilterOptions(filterByOptions)}
                  </select>
                </div>
              ) : null}

              {searchKeys && (
                <SearchWrapper>
                  {searchResultsInfo.isNavigationOpen && (
                    <SearchNavigation
                      searchResultsInfo={searchResultsInfo}
                      updateSearchResultsInfo={updateSearchResultsInfo}
                      tableRef={tableRef}
                    />
                  )}
                  <FormField
                    id="search"
                    name="search"
                    placeholder="Search"
                    value={searchTerm}
                    hideLabel={true}
                    onChange={changeOuterSearch}
                  />
                </SearchWrapper>
              )}

              {refreshHandler && (
                <RefreshWrapper isRefreshing={isRefreshing}>
                  {isRefreshing ? (
                    <Spinner size="1x" />
                  ) : (
                    <span
                      className="fas fa-sync-alt fs:16"
                      onClick={refreshHandler}
                    />
                  )}
                </RefreshWrapper>
              )}
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

TableHeader.propTypes = {
  showButton: PropTypes.bool,
  toggleModal: PropTypes.func,
  buttonTitle: PropTypes.string,
  sortTable: PropTypes.func,
  filterTable: PropTypes.func,
  sortByOptions: PropTypes.array,
  filterByOptions: PropTypes.array,
  buttonAction: PropTypes.func,
  buttonColor: PropTypes.string,
  filterByRegistered: PropTypes.func,
  filterByInUse: PropTypes.func,
  allowFilterByRegistered: PropTypes.bool,
  allowFilterByPermissions: PropTypes.bool,
  allowFilterByPrivileged: PropTypes.bool,
  allowFilterByExternal: PropTypes.bool,
  allowFilterByMappedAttributes: PropTypes.bool,
  allowFilterByInUse: PropTypes.bool,
  useCompoundFilter: PropTypes.bool,
  compoundFilterCategories: PropTypes.array,
  filterDataSet: PropTypes.object,
  changeFilterCategory: PropTypes.func,
  changeFilterByPermissions: PropTypes.func,
  filter: PropTypes.bool,
}

export default TableHeader
