import { combineReducers } from 'redux'
import resourceLabels from './resourceLabels'
import createResourceLabel from './createResourceLabel'
import deleteResourceLabel from './deleteResourceLabel'
import profileManagement from './profile_management'
import brokers from './brokers'
import resourceTypes from './resourceTypes'
import createResourceType from './createResourceType'
import resources from './resources'
import resourceById from './resourceById'
import permissionsByResourceType from './permissionsByResourceType'
import permissionById from './permissionById'
import permissionByVersion from './permissionByVersion'
import myResources from './myResources'
import resourcePolicies from './resourcePolicies'
import poolsByResource from './poolsByResource'
import responseTemplates from './responseTemplates'

const serverAccessReducer = combineReducers({
  resourceLabels,
  createResourceLabel,
  deleteResourceLabel,
  profileManagement,
  brokers,
  resourceTypes,
  createResourceType,
  resources,
  resourceById,
  permissionsByResourceType,
  permissionById,
  permissionByVersion,
  myResources,
  resourcePolicies,
  poolsByResource,
  responseTemplates,
})

export default serverAccessReducer
