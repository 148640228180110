export const nextDataButtonText = 'Load More...'
export const noDataText = 'No data available'
export const typographyCaption = 'caption1'
export const textOnlyBtn = 'textOnly'
export const mediumBtn = 'medium'
export const spinnerSize = 'medium'
export const classes = {
  dataTableWrapper: 'vault-data-table-wrapper',
  dataTableBottomPanel: 'vault-data-table-bottom-panel',
  searchContainer: 'data-table-search-container',
  noBorder: 'no-border',
}
export const searchBarSize = '400px'
export const ascendingOrder = 'ascending'
export const descendingOrder = 'descending'
export const unsortedOrder = 'unsorted'
export const ascOrder = 'asc'
export const descOrder = 'desc'
export const NA_TEXT = 'NA'
