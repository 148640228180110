import {
  NOTIFICATION_CONNECTED,
  ALL_NOTIFICATIONS,
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATIONS,
  DELETE_NOTIFICATION,
  DELETE_ALL_NOTIFICATIONS,
  CHECK_OUT_APPROVAL_EXPIRED,
  CHECKED_OUT_NOTIFICATION,
  CHECK_OUT_REJECTED_NOTIFICATION,
  CHECK_OUT_FAILED_NOTIFICATION,
  CHECKED_IN_NOTIFICATION,
  CHECK_IN_FAILED_NOTIFICATION,
  REQUEST_APPROVED_NOTIFICATION,
} from 'actions'
import { fetchIfLoggedIn } from '../utils'
import { connectToSocket } from 'utils/socket'
import toast from 'utils/toast'
import { handleReportGeneration } from 'action_creators/report'
import serverAccess from './serverAccess'

const SERVER_ACCESS_PROFILE = 'resourceprofile'

const isServerAccessNotification = (consumer = '') => {
  return consumer === SERVER_ACCESS_PROFILE
}

export const connectToPushNotificationSocket = () => dispatch =>
  connectToSocket({
    onConnected: args => setTimeout(() => dispatch(onConnected(args)), 100),
    path: '/websocket/push/notifications',
    eventsCallback: [
      {
        event: 'allNotifications',
        callback: args => dispatch(onAllNotifications(args)),
      },
      {
        event: 'addNotifications',
        callback: args => dispatch(onAddNotification(args)),
      },
      {
        event: 'removeNotifications',
        callback: args => dispatch(onRemoveNotification(args)),
      },
      {
        event: 'checkedOut',
        callback: args => dispatch(onCheckedOutNotification(args)),
      },
      {
        event: 'requestRejected', //replaced => 'checkOutRejected' to 'requestRejected'
        callback: args => dispatch(onCheckOutRejectedNotification(args)),
      },
      {
        event: 'checkOutFailed',
        callback: args => dispatch(onCheckOutFailedNotification(args)),
      },
      {
        event: 'checkedIn',
        callback: args => dispatch(onCheckedInNotification(args)),
      },
      {
        event: 'checkInFailed',
        callback: args => dispatch(onCheckInFailedNotification(args)),
      },
      {
        event: 'requestExpired', //replaced => 'checkOutApprovalExpired' to 'requestExpired'
        callback: args => dispatch(onCheckOutApprovalExpired(args)),
      },
      {
        event: 'requestApproved', //new case
        callback: args => dispatch(onRequestApprovedNotification(args)),
      },
      {
        event: 'checkOutTimeOut', // server-access
        callback: args => dispatch(onCheckOutTimeOutNotification(args)),
      },
      {
        event: 'checkInTimeOut', // server-access
        callback: args => dispatch(onCheckInTimeOutNotification(args)),
      },
    ],
  })

export const onConnected = args => ({
  type: NOTIFICATION_CONNECTED,
  payload: args,
})

export const onAddNotification = notification => dispatch => {
  notification[0].consumer.startsWith('reports') &&
    dispatch(handleReportGeneration(notification[0]))
  dispatch({
    type: ADD_NOTIFICATION,
    payload: notification,
  })
}

export const onAllNotifications = notifications => ({
  type: ALL_NOTIFICATIONS,
  payload: notifications,
})

export const onRemoveNotification = notifications => ({
  type: REMOVE_NOTIFICATIONS,
  payload: notifications,
})

export const deleteNotification = notificationId => dispatch =>
  dispatch(
    fetchIfLoggedIn({
      actionType: DELETE_NOTIFICATION,
      meta: { notificationId },
      method: 'delete',
      path: `/v1/notification-service/notifications/${notificationId}`,
    })
  )

export const deleteAllNotifications = () => dispatch =>
  dispatch(
    fetchIfLoggedIn({
      actionType: DELETE_ALL_NOTIFICATIONS,
      method: 'delete',
      path: `/v1/notification-service/notifications`,
    })
  )

export const onCheckedOutNotification = notification => async (
  dispatch,
  getState
) => {
  if (isServerAccessNotification(notification?.consumer)) {
    serverAccess.onCheckedOutNotification(dispatch, getState, notification)
    return
  }

  const {
    appContainerId,
    environmentId,
    papId,
    accessType,
    currentExtensionIndex,
  } = notification
  const id = `${appContainerId}-${environmentId}-${papId}-${accessType}`
  const { userProfiles } = getState().userAppPaps
  const { papName } = userProfiles[papId]
  await dispatch({
    type: CHECKED_OUT_NOTIFICATION,
    payload: notification,
  })
  toast({
    title: `Profile '${papName}' has been ${
      parseInt(currentExtensionIndex) > 0 ? 'extended' : 'checked out'
    } successfully.`,
    type: 'success',
    time: 'normal',
    id: `${id}-${CHECKED_OUT_NOTIFICATION}`,
  })
}

export const onCheckOutRejectedNotification = notification => async (
  dispatch,
  getState
) => {
  if (isServerAccessNotification(notification?.consumer)) {
    serverAccess.onCheckOutRejectedNotification(dispatch, getState, notification)
    return
  }

  const { appContainerId, environmentId, papId, accessType } = notification
  const id = `${appContainerId}-${environmentId}-${papId}-${accessType}`
  const { userProfiles } = getState().userAppPaps
  const { papName } = userProfiles[papId]
  await dispatch({
    type: CHECK_OUT_REJECTED_NOTIFICATION,
    payload: notification,
  })
  toast({
    title: `Profile checkout for '${papName}' has been rejected.`,
    type: 'error',
    time: 'normal',
    id: `${id}-${CHECK_OUT_REJECTED_NOTIFICATION}`,
  })
}

export const onCheckOutFailedNotification = notification => async (
  dispatch,
  getState
) => {
  if (isServerAccessNotification(notification?.consumer)) {
    serverAccess.onCheckOutFailedNotification(dispatch, getState, notification)
    return
  }

  const {
    appContainerId,
    environmentId,
    papId,
    accessType,
    errorMessage,
  } = notification
  const id = `${appContainerId}-${environmentId}-${papId}-${accessType}`
  const { userProfiles } = getState().userAppPaps
  const { papName } = userProfiles[papId]
  await dispatch({
    type: CHECK_OUT_REJECTED_NOTIFICATION,
    payload: notification,
  })

  toast({
    title: `Profile checkout for '${papName}' has failed.`,
    type: 'error',
    time: errorMessage && errorMessage !== '' ? 'long' : 'normal',
    id: `${id}-${CHECK_OUT_FAILED_NOTIFICATION}`,
    ...(errorMessage && errorMessage !== '' && { description: errorMessage }),
  })
}

export const onCheckedInNotification = notification => async (
  dispatch,
  getState
) => {
  if (isServerAccessNotification(notification?.consumer)) {
    serverAccess.onCheckedInNotification(dispatch, getState, notification)
    return
  }

  const { appContainerId, environmentId, papId, accessType } = notification
  const id = `${appContainerId}-${environmentId}-${papId}-${accessType}`
  const { userProfiles } = getState().userAppPaps
  const { papName } = userProfiles[papId] || {}
  await dispatch({
    type: CHECKED_IN_NOTIFICATION,
    payload: notification,
  })
  toast({
    title: `Profile '${papName}' has been checked in successfully.`,
    type: 'success',
    time: 'normal',
    id: `${id}-${CHECKED_IN_NOTIFICATION}`,
  })
}

export const onCheckInFailedNotification = notification => async (
  dispatch,
  getState
) => {
  if (isServerAccessNotification(notification?.consumer)) {
    serverAccess.onCheckInFailedNotification(dispatch, getState, notification)
    return
  }

  const { appContainerId, environmentId, papId, accessType } = notification
  const id = `${appContainerId}-${environmentId}-${papId}-${accessType}`
  const { userProfiles } = getState().userAppPaps
  const { papName } = userProfiles[papId]
  await dispatch({
    type: CHECK_IN_FAILED_NOTIFICATION,
    payload: notification,
  })
  toast({
    title: `Profile checkin for '${papName}' has failed.`,
    type: 'error',
    time: 'normal',
    id: `${id}-${CHECK_IN_FAILED_NOTIFICATION}`,
  })
}

export const onCheckOutApprovalExpired = notification => async (
  dispatch,
  getState
) => {
  if (isServerAccessNotification(notification?.consumer)) {
    serverAccess.onCheckOutApprovalExpired(dispatch, getState, notification)
    return
  }

  const { appContainerId, environmentId, papId, accessType } = notification
  const id = `${appContainerId}-${environmentId}-${papId}-${accessType}`
  const { userProfiles } = getState().userAppPaps
  const { papName } = userProfiles[papId]
  await dispatch({
    type: CHECK_OUT_APPROVAL_EXPIRED,
    payload: notification,
  })
  toast({
    title: `Check out approval for profile '${papName}' has expired.`,
    type: 'success',
    time: 'normal',
    id: `${id}-${CHECK_OUT_APPROVAL_EXPIRED}`,
  })
}

export const onRequestApprovedNotification = notification => async (
  dispatch,
  getState
) => {
  if (isServerAccessNotification(notification?.consumer)) {
    serverAccess.onRequestApprovedNotification(dispatch, getState, notification)
    return
  }

  const { appContainerId, environmentId, papId, accessType } = notification
  const id = `${appContainerId}-${environmentId}-${papId}-${accessType}`
  const { userProfiles } = getState().userAppPaps
  const { papName } = userProfiles[papId]
  await dispatch({
    type: REQUEST_APPROVED_NOTIFICATION,
    payload: notification,
  })
  toast({
    title: `Profile request for '${papName}' has been approved.`,
    type: 'success',
    time: 'normal',
    id: `${id}-${REQUEST_APPROVED_NOTIFICATION}`,
  })
}

export const onCheckOutTimeOutNotification = notification => async (
  dispatch,
  getState
) => {
  if (isServerAccessNotification(notification?.consumer)) {
    serverAccess.onCheckOutTimeOutNotification(dispatch, getState, notification)
    return
  }

  // Handle non-serverAccess related here
}

export const onCheckInTimeOutNotification = notification => async (
  dispatch,
  getState
) => {
  if (isServerAccessNotification(notification?.consumer)) {
    serverAccess.onCheckInTimeOutNotification(dispatch, getState, notification)
    return
  }

  // Handle non-serverAccess related here
}
