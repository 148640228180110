import get from 'lodash/get'
import * as actions from 'actions'

const initialState = {
  loading: false,
  error: null,
  dataByKey: {},
  paginatedResourcesLabels: {},
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case `${actions.GET_RESOURCE_LABELS}_PENDING`: {
      return {
        ...state,
        loading: true,
        error: null,
      }
    }

    case `${actions.GET_RESOURCE_LABELS}_FULFILLED`: {
      const { count, page, data = [] } = get(action, 'payload.data', {})
      let updatedData = []

      if (page === 0 || isNaN(page)) {
        updatedData = data
      } else {
        updatedData = [...(state.paginatedResourcesLabels?.data || []), ...data]
      }

      return {
        ...state,
        loading: false,
        error: null,
        paginatedResourcesLabels: {
          data: updatedData,
          hasMoreData: updatedData.length < count,
        },
      }
    }

    case `${actions.GET_RESOURCE_LABELS}_REJECTED`: {
      return {
        ...state,
        loading: false,
        error: 'Unable to fetch the Resource Labels.',
      }
    }

    case `${actions.GET_RESOURCE_LABEL}_PENDING`: {
      return {
        ...state,
        loading: true,
        error: null,
      }
    }

    case `${actions.GET_RESOURCE_LABEL}_FULFILLED`: {
      const data = get(action, 'payload.data')

      return {
        ...state,
        loading: false,
        error: null,
        dataByKey: {
          ...state.dataByKey,
          [data?.keyId]: data,
        },
      }
    }

    case `${actions.GET_RESOURCE_LABEL}_REJECTED`: {
      return {
        ...state,
        loading: false,
        error: 'Unable to fetch the Resource Label.',
      }
    }

    case actions.REMOVE_RESOURCE_LABELS: {
      return initialState
    }

    default:
      return { ...state }
  }
}
