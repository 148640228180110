import React, { useEffect, useState } from 'react'
import Ingestion from './Ingestion'
import { preFetch } from 'utils/do_fetch'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

export default ({ appId, environments, thisAppManage }) => {
  const [values, setValues] = useState({})
  const [defaultValues, setDefaultValues] = useState({})
  const [useBritiveIntegrationRole, setUseBritiveIntegrationRole] = useState(true)
  const [editMode, setEditMode] = useState(false)
  const [fetching, setFetching] = useState(false)
  const [isRefreshing, setIsRefreshing] = useState(false)
  const [saving, setSaving] = useState(false)
  const [error, setError] = useState({})
  const [visualCuesData, setVisualCuesData] = useState([])

  const getData = async () => {
    setFetching(true)
    try {
      const response = await preFetch({
        path: `/apps/${appId}/ingestion`,
        method: 'get',
      })
      const scopeObject = {}
      const historicalScopeObject = {}
      let scopeArray = get(response, 'data.scope', [])
      let historicalScopeArray = get(response, 'data.historicalScope', [])
      if (scopeArray) {
        scopeArray.map(s => {
          scopeObject[s.value] = s
        })
      }
      if (historicalScopeArray) {
        historicalScopeArray.map(s => {
          historicalScopeObject[s.value] = s
        })
      }
      setValues({
        ...response.data,
        scope: scopeObject,
        historicalScope: historicalScopeObject,
      })
      setDefaultValues({
        ...response.data,
        scope: scopeObject,
        historicalScope: historicalScopeObject,
      })
      setUseBritiveIntegrationRole(isEmpty(values.ingestionIntegrationRole))
    } catch (e) {
      console.log(e)
    } finally {
      setFetching(false)
    }
  }

  const getVisualCuesData = async () => {
    setFetching(true)
    try {
      const environmentsIds =
        environments && environments.length > 0
          ? environments.map(environment => environment.id)
          : []
      const response = await preFetch({
        path: `/ada/ingestions/meta-data`,
        method: 'post',
        postBody: {
          appId: appId,
          environmentIds: environmentsIds,
        },
      })
      let visualCuesResponse = get(response, 'data', [])
      setVisualCuesData(
        visualCuesResponse && visualCuesResponse.length > 0 ? visualCuesResponse : []
      )
    } catch (e) {
      console.log(e)
    } finally {
      setFetching(false)
    }
  }

  const refreshApplication = async () => {
    try {
      setIsRefreshing(true)
      getData()
      getVisualCuesData()
    } catch (e) {
      console.log(e)
    } finally {
      setIsRefreshing(false)
    }
  }

  const onSave = async () => {
    setError({})
    if (!useBritiveIntegrationRole && isEmpty(values.ingestionIntegrationRole)) {
      setError({
        ingestionIntegrationRole: 'This field cannot be blank',
      })
      return
    }
    setSaving(true)
    try {
      const response = await preFetch({
        path: `/apps/${appId}/ingestion`,
        postBody: {
          ...values,
          scope: Object.values(values.scope),
          historicalScope: Object.values(values.historicalScope),
        },
        method: 'patch',
      })
      if (response.status === 201) {
        setDefaultValues(values)
        setEditMode(false)
      }
    } catch (e) {
      setError({
        save: get(
          e,
          'response.data.message',
          'Something went wrong. Please try again.'
        ),
      })
    } finally {
      setSaving(false)
    }
  }

  const onBritiveIntegrationRoleClick = event => {
    const usingBritiveIntegrationRole = event.target.checked
    setUseBritiveIntegrationRole(usingBritiveIntegrationRole)
    if (usingBritiveIntegrationRole) {
      setValues({
        ...values,
        ingestionIntegrationRole: null,
      })
    }
  }

  useEffect(() => {
    getData()
    getVisualCuesData()
  }, [])

  useEffect(() => {
    setError({})
  }, [values, editMode])

  useEffect(() => {
    !editMode && setValues(defaultValues)
    setUseBritiveIntegrationRole(isEmpty(defaultValues.ingestionIntegrationRole))
  }, [editMode])

  return (
    <Ingestion
      values={values}
      setValues={setValues}
      defaultValues={defaultValues}
      useBritiveIntegrationRole={useBritiveIntegrationRole}
      onBritiveIntegrationRoleClick={onBritiveIntegrationRoleClick}
      editMode={editMode}
      setEditMode={setEditMode}
      fetching={fetching}
      saving={saving}
      error={error}
      handleSave={onSave}
      visualCuesData={visualCuesData}
      refreshApplication={refreshApplication}
      isRefreshing={isRefreshing}
      thisAppManage={thisAppManage}
    />
  )
}
