import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import capitalize from 'lodash/capitalize'
import { Modal, ModalFooter, ModalHeader, ModalBody } from 'reactstrap'
import get from 'lodash/get'
import {
  ActionIconLabel,
  ActionIconGroup,
} from 'britive-ui-components/core/components/ActionIcon'
import Button from 'britive-ui-components/core/components/Button'
import Spinner from 'britive-ui-components/core/components/Spinner'

import TableEllipsisCell from 'components/table/TableV2/TableEllipsisCell'
import ConflictMessageModal from '../ConflictMessageModal'
import AddUsersTable from './AddUsersTable'
import TimePeriodModal from '../TimePeriodModal'
import TimePeriod from '../TimePeriod'
import TableActionIcon from 'components/TableActionIcon'

import { addUserToPap, editUserOfPap, removeUserFromPap } from 'action_creators/pap'

import user from 'classes/user.js'
import startCase from 'lodash/startCase'
import IntegrityCheck from '../../IntegrityCheck'
import Table from 'components/table/TableV2/Table'

const styles = {
  button: {
    position: 'absolute',
    top: -64,
    right: 0,
  },
  addModal: {
    maxWidth: 1600,
    width: '80%',
  },
  addModalDoneButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
}

class PapUsers extends Component {
  state = {
    availableUsers: [],
    isAddUsersOpen: false,
    availableUsersLoading: false,
    users: [],
    fetchingUsers: true,
    conflictModalProperties: {},
    isAddTimePeriodUserOpen: false,
    isEditTimePeriodUserOpen: false,
    isConflictMessageOpen: false,
    isAddingUser: {},
    isEditingUser: {},
    isRemovingUser: {},
    refresh: 0,
  }

  componentDidUpdate(prevProps) {
    return this.props.papUsers !== prevProps.papUsers
      ? this.setState({ users: this.props.papUsers.map(u => new user(u)) })
      : null
  }

  addUserCloseModal = () => {
    this.setState({
      isAddUsersOpen: false,
      refresh: this.state.refresh + 1,
    })
  }

  addUser = async ({ id, start, end }) => {
    const { dispatch } = this.props
    const { isAddingUser } = this.state
    const { profileId } = this.props

    const newIsAddingUser = { ...isAddingUser, [id]: true }

    this.setState({ isAddingUser: { ...newIsAddingUser } })
    let response
    try {
      response = await dispatch(
        addUserToPap({ papId: profileId, userId: id, start, end })
      )
    } catch (error) {
      this.handleUserConflict({ error })
    }

    delete newIsAddingUser[id]

    this.setState({ isAddingUser: newIsAddingUser })

    return response
  }

  handleUserConflict = ({ error }) => {
    let message = get(
      error,
      'response.data.message',
      'Unknown error. Please Try again'
    )

    const closeProperties = {
      message: null,
      actions: {},
      type: null,
      isOpen: false,
    }

    const closeAction = () => this.updateConflictMessageModal(closeProperties)
    let actions = { ok: closeAction }

    this.updateConflictMessageModal({
      isOpen: true,
      message,
      type: 'User',
      actions,
    })
  }

  updateConflictMessageModal = ({ message, type, actions, isOpen }) => {
    this.setState({
      conflictModalProperties: { message, type, actions },
      isConflictMessageOpen: isOpen,
    })
  }

  editUser = async ({ id, start, end }) => {
    const { dispatch } = this.props
    const { isEditingUser, refresh } = this.state
    const { profileId } = this.props
    const newIsEditingUser = { ...isEditingUser, [id]: true }

    try {
      this.setState({ isEditingUser: { ...newIsEditingUser } })
      const response = await dispatch(
        editUserOfPap({ papId: profileId, userId: id, start, end })
      )
      const users = [...this.state.users]
      users.splice(
        users.findIndex(user => user.userId === id),
        1,
        response.value.data
      )

      delete newIsEditingUser[id]

      this.setState({ isEditingUser: newIsEditingUser, refresh: refresh + 1 })

      return response
    } catch (error) {
      delete newIsEditingUser[id]

      this.setState({ isEditingUser: newIsEditingUser })

      throw error
    }
  }

  deleteUser = async userToDelete => {
    const { dispatch } = this.props
    const { userId } = userToDelete
    const { isRemovingUser } = this.state
    const { profileId: papId } = this.props

    const newIsRemovingUser = { ...isRemovingUser, [userId]: true }

    this.setState({ isRemovingUser: { ...newIsRemovingUser } })

    const response = await dispatch(removeUserFromPap({ userId, papId }))

    delete newIsRemovingUser[userId]

    this.setState({
      isRemovingUser: newIsRemovingUser,
      refresh: this.state.refresh + 1,
    })

    return response
  }

  toggleAddTimePeriodModal = user => {
    this.setState({
      isAddTimePeriodUserOpen: !this.state.isAddTimePeriodUserOpen,
      selectedUser: user,
    })
  }

  toggleEditTimePeriodModal = user => {
    this.setState({
      isEditTimePeriodUserOpen: !this.state.isEditTimePeriodUserOpen,
      selectedUser: user,
    })
  }

  toggleConflictMessageModal = () => {
    this.setState({
      isConflictMessageOpen: !this.state.isConflictMessageOpen,
    })
  }

  getTableColumns = () => {
    const { app, thisAppManage } = this.props

    let columns = [
      {
        Header: 'Name',
        fixed: 'left',
        accessor: 'name',
        minWidth: 240,
        Cell: ({ original: { name } }) => <TableEllipsisCell value={name} />,
      },
      {
        Header: 'Username',
        accessor: 'username',
        minWidth: 140,
      },
      {
        Header: 'Type',
        accessor: 'userType',
        minWidth: 120,
        Cell: ({ original: { userType } }) => startCase(userType),
      },
      ...(thisAppManage
        ? [
            {
              Header: 'Actions',
              accessor: 'actions',
              Cell: this.renderActionIcons,
              resizable: false,
              sortable: false,
              fixed: 'left',
              width: 80,
            },
          ]
        : []),
      {
        Header: 'Status',
        accessor: 'status',
        minWidth: 70,
        Cell: ({ value }) => <TableEllipsisCell value={capitalize(value)} />,
        sortable: false,
      },
      {
        Header: 'Mapped Account',
        sortable: false,
        minWidth: 260,
        Cell: ({ original: { mappedAccounts = [] } }) => (
          <TableEllipsisCell value={mappedAccounts.join(', ')} />
        ),
      },
      {
        Header: 'Time Period',
        sortable: false,
        minWidth: 140,
        Cell: TimePeriod,
      },
    ]

    if (app.catalogApplication.requiresAccountMapping) {
      columns.splice(3, 0, {
        Header: 'Integrity Check',
        Cell: this.renderIntegrityChecks,
        accessor: 'integrityCheck',
        minWidth: 120,
        sortable: false,
      })
    }

    return columns
  }

  renderIntegrityChecks = ({ original }) => {
    return (
      <IntegrityCheck
        checkStatus={original.checkStatus}
        message={original.message}
        name={original.name}
      />
    )
  }

  renderActionIcons = ({ original: user }) => {
    const { isRemovingUser } = this.state

    return (
      <ActionIconGroup>
        <Fragment>
          <TableActionIcon onClick={() => this.deleteUser(user)}>
            {isRemovingUser[user.userId] ? (
              <Spinner />
            ) : (
              <Fragment>
                <span className="fa fa-trash fs:14" />
                <ActionIconLabel>Delete</ActionIconLabel>
              </Fragment>
            )}
          </TableActionIcon>

          {get(user, 'accessPeriod.start') && (
            <TableActionIcon onClick={() => this.toggleEditTimePeriodModal(user)}>
              <span className="fa fa-clock fs:14" />
              <ActionIconLabel>Edit time period</ActionIconLabel>
            </TableActionIcon>
          )}
        </Fragment>
      </ActionIconGroup>
    )
  }

  render() {
    const { fetchPap, thisAppManage } = this.props
    const {
      isAddTimePeriodUserOpen,
      isAddingUser,
      isConflictMessageOpen,
      isEditTimePeriodUserOpen,
      isEditingUser,
      conflictModalProperties,
      selectedUser,
    } = this.state

    return (
      <Fragment>
        {thisAppManage && (
          <Button
            onClick={() => {
              // This is pretty bad
              this.setState({
                isAddUsersOpen: true,
              })
            }}
            color="primary"
            style={styles.button}
          >
            Add Identities
          </Button>
        )}

        <Table
          columns={this.getTableColumns()}
          identifier="userId"
          dataUrl={`/paps/${this.props.profileId}/users`}
          emptyTableMessage={`No identities assigned. ${
            thisAppManage
              ? 'Click Add Identities to add new identities to this profile'
              : ''
          }`}
          dropdownFilters={[
            {
              label: 'Type',
              value: 'type',
              options: [
                { label: 'User', value: 'User' },
                { label: 'Service Identity', value: 'ServiceIdentity' },
              ],
            },
          ]}
          refresh={this.state.refresh}
        />

        <Modal isOpen={this.state.isAddUsersOpen} size="lg" style={styles.addModal}>
          <ModalHeader toggle={this.addUserCloseModal}>
            Add Identities to Profiles
          </ModalHeader>

          <ModalBody>
            <AddUsersTable
              profileId={this.props.profileId}
              closeModal={this.addUserCloseModal}
              addUser={this.addUser}
              fetchPap={fetchPap}
              toggleTimePeriodModal={this.toggleAddTimePeriodModal}
              isAddingUser={isAddingUser}
              isAddTimePeriodUserOpen={isAddTimePeriodUserOpen}
            />
          </ModalBody>

          <ModalFooter>
            <div style={styles.addModalDoneButton}>
              <Button onClick={() => this.addUserCloseModal()} color="primary">
                Done
              </Button>
            </div>
          </ModalFooter>
        </Modal>

        <TimePeriodModal
          actionSubject={selectedUser}
          isOpen={isAddTimePeriodUserOpen || isEditTimePeriodUserOpen}
          getTitle={
            isAddTimePeriodUserOpen
              ? user => `Add Identity - ${user.username}`
              : user => `Change Time Period Access for Identity - ${user.username}`
          }
          toggleModal={
            isAddTimePeriodUserOpen
              ? this.toggleAddTimePeriodModal
              : this.toggleEditTimePeriodModal
          }
          pageName="papUser"
          isUpdating={{ ...isEditingUser, ...isAddingUser }}
          actionFunction={isAddTimePeriodUserOpen ? this.addUser : this.editUser}
          identifier="userId"
        />

        <ConflictMessageModal
          isOpen={isConflictMessageOpen}
          isUpdating={false}
          properties={conflictModalProperties}
          toggleConflictMessageModal={this.toggleConflictMessageModal}
        />
      </Fragment>
    )
  }
}

PapUsers.propTypes = {
  pap: PropTypes.object,
  getAvailableUsers: PropTypes.func,
  match: PropTypes.object,
}

const mapStateToProps = state => {
  return {
    papUsers: state.paps.papUsers,
    fetchingPapUsers: state.paps.fetchingPapUsers,
  }
}

export default connect(mapStateToProps)(withRouter(PapUsers))
