import React from 'react'
import { connect } from 'react-redux'
import { Spinner } from 'reactstrap'
import NotificationCard from 'britive-design-system/core/components/notification-card'
import Button from 'britive-design-system/core/components/button'
import consumers from 'constants/consumers'
import {
  deleteNotification,
  deleteAllNotifications,
} from 'action_creators/pushNotification'

export default connect()(
  ({
    notifications,
    fetchingNotifications,
    deletingAllNotifications,
    deletingNotifications,
    onClose,
    dispatch,
  }) => {
    const getSecondaryActionLabel = messageType => {
      switch (messageType) {
        case 'reports-async-success':
        case 'reports-async-failed':
          return 'Go To Reports'

        case 'existing-par-approval-request':
        case 'new-par-approval-request':
        case 'approval':
        case 'profile-approval':
          return 'Review Request'

        default:
          return 'View Request'
      }
    }
    return (
      <>
        <div className="notifications-header">
          <div className="notifications-header-container">
            <span className="header-text">Notifications</span>
            <span onClick={onClose}>
              <i className="fa fa-times" />
            </span>
          </div>
          {fetchingNotifications ? (
            <div className="loader">
              <Spinner />
              <span>Fetching notifications</span>
            </div>
          ) : notifications.length ? (
            <Button
              disabled={deletingAllNotifications}
              variant="secondary"
              size="small"
              onClick={() => dispatch(deleteAllNotifications())}
            >
              Dismiss All
            </Button>
          ) : (
            <span>There are no notifications.</span>
          )}
        </div>
        <div className="notification-cards">
          {notifications?.map(
            ({
              notificationId,
              timestamp,
              message,
              messageType,
              consumer,
              actionsPage,
            }) => {
              return (
                <div
                  key={notificationId}
                  style={{
                    borderLeft: `6px solid ${
                      messageType.includes('success')
                        ? '#24a147'
                        : messageType.includes('failed')
                        ? '#da1e28'
                        : 'transparent'
                    }`,
                  }}
                >
                  <NotificationCard
                    loading={
                      deletingAllNotifications ||
                      deletingNotifications.includes(notificationId)
                    }
                    loadingMessage="Dismissing notification"
                    notificationTime={timestamp}
                    notificationMessage={message}
                    notificationTitle={consumers[consumer]}
                    primaryActionLabel="Dismiss"
                    primaryActionHandler={() =>
                      dispatch(deleteNotification(notificationId))
                    }
                    // show secondary button only when there is actionPage
                    // and messagetype is not `secret-rotation
                    {...(actionsPage &&
                      messageType !== 'secret-rotation' && {
                        secondaryActionLabel: getSecondaryActionLabel(messageType),
                        secondaryActionHandler: () =>
                          window.location.assign(actionsPage),
                      })}
                  />
                </div>
              )
            }
          )}
        </div>
      </>
    )
  }
)
