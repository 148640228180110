import React, { memo } from 'react'

import { BackgroundTooltip as Tooltip } from 'components/Tooltips'

function ScanStatusTooltip({ id, scanResult, error }) {
  if (scanResult !== 'Error') {
    return null
  }

  return (
    <Tooltip effect="solid" id={id}>
      {error}
    </Tooltip>
  )
}

export default memo(ScanStatusTooltip)
