import React, { memo, useEffect } from 'react'
import { connect } from 'react-redux'
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap'

import Button from 'britive-ui-components/core/components/Button'

import { closeMessageModal } from 'action_creators/message_modal'

function GeneralErrorModal({
  body,
  dispatch,
  header,
  isOpen,
  pathname,
  afterCloseAction,
}) {
  useEffect(() => {
    if (isOpen) {
      closeModal()
    }
  }, [pathname])

  const closeModal = () => {
    dispatch(closeMessageModal())
    afterCloseAction && afterCloseAction()
  }

  if (!isOpen) {
    return null
  }

  return (
    <Modal isOpen={isOpen}>
      {header && <ModalHeader>{header}</ModalHeader>}

      {body && <ModalBody>{body}</ModalBody>}

      <ModalFooter>
        <Button onClick={closeModal} color="primary">
          OK
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const mapStateToProps = state => {
  return {
    isOpen: state.messageModal.isOpen,
    header: state.messageModal.header,
    body: state.messageModal.body,
    footer: state.messageModal.footer,
    afterCloseAction: state.messageModal.afterCloseAction,
    pathname: state.router.location.pathname,
  }
}

export default connect(mapStateToProps)(memo(GeneralErrorModal))
