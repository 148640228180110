import React, { Fragment, memo, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import startCase from 'lodash/startCase'

import TableEllipsisCell from 'components/table/TableV2/TableEllipsisCell'
import Table from 'components/table/TableV2/Table'

import ScanDetailsDrawer from '../ScanDetailsDrawer/ScanDetailsDrawerContainer'

const styles = {
  changeCell: {
    display: 'flex',
    alignItems: 'center',
  },
  changeText: {
    overflow: 'hidden',
    flexGrow: 1,
    textOverflow: 'ellipsis',
  },
  changesIconWrapper: {
    padding: '0 15px',
    display: 'flex',
  },
  changesIcon: {
    cursor: 'pointer',
  },
}

function Accounts({ dropdownFilter, selectedAppId, entityId, selectedApp }) {
  const [scanDetailsDrawerOpened, setScanDetailsDrawerOpened] = useState(false)
  const [selectedAccount, setSelectedAccount] = useState(null)
  const columns = [
    {
      Header: 'Name',
      accessor: 'nativeName',
      fixed: 'left',
      width: 300,
    },
    {
      Header: 'First Name',
      accessor: 'firstName',
      minWidth: 150,
    },
    {
      Header: 'Last Name',
      accessor: 'lastName',
      minWidth: 150,
    },
    {
      Header: 'Type',
      accessor: 'type',
      Cell: ({ value }) => startCase(value),
      minWidth: 80,
    },
    {
      Header: 'Scan Status',
      accessor: 'scanStatus',
      minWidth: 80,
    },
    {
      id: 'changed_status',
      Header: 'Change Type',
      Cell: renderChangeType,
      sortable: false,
      accessor: ({ scanStatus, hasMembershipsChanged }) => ({
        scanStatus,
        hasMembershipsChanged,
      }),
      minWidth: 200,
    },
  ]

  function getChangeStatus({ scanStatus }) {
    return scanStatus === 'New' || scanStatus === 'Deleted'
      ? scanStatus
      : 'User Permissions Changed'
  }

  function renderChangeType({ original: data }) {
    const iconRef = React.createRef()
    const changeStatus = getChangeStatus(data)

    return (
      <Fragment>
        <TableEllipsisCell style={styles.changeCell}>
          <span style={styles.changeText} title={changeStatus}>
            {changeStatus}
          </span>

          {!(changeStatus === 'New' || changeStatus === 'Deleted') && (
            <span style={styles.changesIconWrapper}>
              <span
                onClick={() => {
                  setSelectedAccount(data)
                  setScanDetailsDrawerOpened(true)
                }}
                className="fa fa-exchange-alt fs:18"
                data-tip
                ref={iconRef}
                style={styles.changesIcon}
                onMouseEnter={() => ReactTooltip.show(iconRef.current)}
                onMouseLeave={() => ReactTooltip.hide(iconRef.current)}
              />
            </span>
          )}
        </TableEllipsisCell>
      </Fragment>
    )
  }
  return (
    <Fragment>
      <Table
        columns={columns}
        identifier="appPermissionId"
        dataUrl={`/apps/${selectedAppId}/environments/${entityId}/accounts`}
        emptyTableMessage={'No Groups were found.'}
        params={{ showDelta: true }}
        dropdownFilters={dropdownFilter}
        margin={40}
      />
      <ScanDetailsDrawer
        isOpen={selectedAccount && scanDetailsDrawerOpened}
        isHierarchical={selectedApp.catalogApplication.requiresHierarchicalModel}
        toggleDrawer={() => setScanDetailsDrawerOpened(!scanDetailsDrawerOpened)}
        data={selectedAccount}
        selectedAppId={selectedAppId}
        entityId={entityId}
        type={'Accounts'}
        tabs={
          scanDetailsDrawerOpened
            ? [
                {
                  name: 'Permissions',
                  dataUrl: `/apps/${selectedAppId}/environments/${entityId}/accounts/${selectedAccount.accountId}/permissions`,
                  nameIdentifier: 'permissionName',
                },
                {
                  name: 'Groups',
                  dataUrl: `/apps/${selectedAppId}/environments/${entityId}/accounts/${selectedAccount.accountId}/groups`,
                  nameIdentifier: 'permissionName',
                },
              ]
            : []
        }
      />
    </Fragment>
  )
}

export default memo(Accounts)
