import React from 'react'
import { Link } from 'react-router-dom'
import Typography from 'britive-design-system/core/components/typography'
import { RequestDetails } from '../request-details'
import { classNamePrefix } from './constants'
import ProfileDetails from './profile-details'
import WarningIcon from 'static/images/warning-icon.svg'
import './index.scss'

export const AccessBuilderRequestInfo = ({
  requestData,
  hideRequester,
  requestId,
  history,
}) => {
  const currentPath = location.pathname.split('/').filter(path => !!path)
  if (currentPath.length === 3) {
    history.replace(
      `/${currentPath.join('/')}/applications/${
        requestData.context.appId
      }/profiles/${requestData.context.profileId}/details/profile`
    )
  }
  currentPath.length = 3
  return (
    <div className={`${classNamePrefix}-parent-container`}>
      {requestData.highRisk && (
        <div className={`${classNamePrefix}-warning-banner`}>
          <img src={WarningIcon} />
          <Typography variant="label1">This request includes</Typography>
          <Typography variant="label2">High Risk</Typography>
          <Link
            to={`/${currentPath.join('/')}/applications/${
              requestData.context.appId
            }/profiles/${requestData.context.profileId}/details/permissions`}
          >
            <span style={{ textDecoration: 'underline' }}>
              <Typography variant="label1">Permissions.</Typography>
            </span>
          </Link>
        </div>
      )}
      {requestData?.context?.showWarnings && (
        <div className={`${classNamePrefix}-warning-banner`}>
          <img src={WarningIcon} />
          <Typography variant="label1">
            This request includes Britive Managed
          </Typography>
          <Link
            to={`/${currentPath.join('/')}/applications/${
              requestData.context.appId
            }/profiles/${requestData.context.profileId}/details/permissions`}
          >
            <span style={{ textDecoration: 'underline' }}>
              <Typography variant="label1">Permissions</Typography>
            </span>
          </Link>
          <Typography variant="label1">with warnings/suggestions.</Typography>
        </div>
      )}
      <div className={`${classNamePrefix}-container`}>
        <RequestDetails
          requestId={requestId}
          approverGroups={requestData?.approverGroups}
          status={requestData?.overallStatus}
          requester={requestData?.context?.fullName}
          appName={requestData?.context?.appName}
          justification={requestData?.justification}
          hideRequester={hideRequester}
          iconUrl={requestData?.context?.iconUrl}
          expirationTimeForApproveRequest={
            requestData.expirationTimeForApproveRequest
          }
        />
        <ProfileDetails
          appId={requestData.context.appId}
          requestedPolicy={requestData.policies[0].policyName}
        />
      </div>
    </div>
  )
}
