import * as actions from 'actions'
export const customAttributesReducerKey = 'customAttributes'

const initialState = {
  fetchingCustomAttributes: false,
  fetchingCustomAttribute: false,
  fetchingCustomAttributeTypes: false,
  fetchedCustomAttributes: false,
  customAttributes: [],
  customAttribute: {},
  customAttributeTypes: {},
  addingCustomAttribute: false,
  addedCustomAttribute: {},
}

export function customAttributesReducer(state = initialState, action) {
  switch (action.type) {
    case `${actions.GET_CUSTOM_ATTRIBUTES}_PENDING`: {
      return {
        ...state,
        fetchingCustomAttributes: true,
        fetchedCustomAttributes: false,
      }
    }

    case `${actions.GET_CUSTOM_ATTRIBUTES}_FULFILLED`: {
      const customAttributes = action.payload.data

      return {
        ...state,
        fetchingCustomAttributes: false,
        fetchedCustomAttributes: true,
        customAttributes,
      }
    }

    case `${actions.GET_CUSTOM_ATTRIBUTES}_REJECTED`: {
      return {
        ...state,
        fetchingCustomAttributes: false,
        fetchedCustomAttributes: true,
        error: action.payload,
      }
    }

    case `${actions.GET_CUSTOM_ATTRIBUTE}_PENDING`: {
      return {
        ...state,
        fetchingCustomAttribute: true,
      }
    }

    case `${actions.GET_CUSTOM_ATTRIBUTE}_FULFILLED`: {
      const customAttribute = action.payload.data

      return {
        ...state,
        fetchingCustomAttribute: false,
        customAttribute,
      }
    }

    case `${actions.GET_CUSTOM_ATTRIBUTE}_REJECTED`: {
      return {
        ...state,
        fetchingCustomAttribute: false,
        error: action.payload,
      }
    }

    case `${actions.GET_CUSTOM_ATTRIBUTE_TYPES}_PENDING`: {
      return {
        ...state,
        fetchingCustomAttributeTypes: true,
      }
    }

    case `${actions.GET_CUSTOM_ATTRIBUTE_TYPES}_FULFILLED`: {
      const customAttributeTypes = action.payload.data

      return {
        ...state,
        fetchingCustomAttributeTypes: false,
        customAttributeTypes,
      }
    }

    case `${actions.GET_CUSTOM_ATTRIBUTE_TYPES}_REJECTED`: {
      return {
        ...state,
        fetchingCustomAttributeTypes: false,
        error: action.payload,
      }
    }

    case `${actions.ADD_CUSTOM_ATTRIBUTE}_PENDING`: {
      return {
        ...state,
        addingCustomAttribute: true,
      }
    }

    case `${actions.ADD_CUSTOM_ATTRIBUTE}_FULFILLED`: {
      const addedCustomAttribute = action.payload.data
      const customAttributes = [...state.customAttributes, addedCustomAttribute]
      return {
        ...state,
        addingCustomAttribute: false,
        addedCustomAttribute,
        customAttributes,
      }
    }

    case `${actions.ADD_CUSTOM_ATTRIBUTE}_REJECTED`: {
      return {
        ...state,
        addingCustomAttribute: false,
        error: action.payload,
      }
    }
    case `${actions.UPDATE_CUSTOM_ATTRIBUTE}_PENDING`: {
      return {
        ...state,
        addingCustomAttribute: true,
      }
    }

    case `${actions.UPDATE_CUSTOM_ATTRIBUTE}_FULFILLED`: {
      const addedCustomAttribute = action.payload.data
      return {
        ...state,
        addingCustomAttribute: false,
        addedCustomAttribute,
      }
    }

    case `${actions.UPDATE_CUSTOM_ATTRIBUTE}_REJECTED`: {
      return {
        ...state,
        addingCustomAttribute: false,
        error: action.payload,
      }
    }

    case `${actions.DELETE_CUSTOM_ATTRIBUTE}_PENDING`: {
      return {
        ...state,
        deletingCustomAttribute: true,
      }
    }

    case `${actions.DELETE_CUSTOM_ATTRIBUTE}_FULFILLED`: {
      const { id } = action.meta
      const oldCustomAttributes = [...state.customAttributes]
      const customAttributes = oldCustomAttributes.filter(
        customAttribute => customAttribute.id !== id
      )

      return {
        ...state,
        deletingCustomAttribute: false,
        customAttributes,
      }
    }

    case `${actions.DELETE_CUSTOM_ATTRIBUTE}_REJECTED`: {
      return {
        ...state,
        deletingCustomAttribute: false,
        error: action.payload,
      }
    }

    default:
      return { ...state }
  }
}
