import React, { Fragment, memo } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Button from 'britive-ui-components/core/components/Button'

import SectionTab from '../SectionTab'
import AccountMappingSection from './AccountMappingSection'
import ScheduledScanning from './ScheduledScanning'
import isKubernetes from 'utils/application/isKubernetes'
import isOpenshift from 'utils/application/isOpenshift'

import { APP_PROPERTIES_HEADER_ID, APP_PROPERTIES_TABS_ID } from '../../constants'

import * as routing from 'services/routing'

import * as text from 'translations/english_us'

const Wrapper = styled.div`
  ${props => (props.maxHeight ? `max-height: ${props.maxHeight}px;` : '')}

  input[type='checkbox']:disabled {
    cursor: not-allowed;
  }

  .actions-container-new-app {
    background: var(--white);
    position: sticky;
    bottom: 0;
    z-index: 1;
  }
`

function PropertiesApplicationForm(props) {
  const onNextClick = () => {
    const { onClickMoveNextTab, selectTab, tabsRef } = props
    const { activeTab, children } = tabsRef.props
    const currentTabIndex = children.findIndex(
      child => child.props.label === activeTab
    )
    const nextTab = children[currentTabIndex + 1].props.label
    selectTab(nextTab)
    onClickMoveNextTab()
  }

  const {
    app,
    appRoot,
    createNewMode,
    fields,
    isApplicationRoot,
    maxHeight,
    propertyTypes,
    section,
    subPropertiesMap,
    saving,

    // actions
    handleChange,
    onCancel,
    saveUpdates,
    selectedEntityId,
    thisAppManage,
  } = props

  return (
    <Wrapper maxHeight={maxHeight} key={selectedEntityId}>
      <SectionTab
        label="Application"
        properties={section.properties}
        groups={section.groups}
        propertyTypes={propertyTypes}
        onChange={handleChange}
        onSubmit={saveUpdates}
        fields={fields}
        subPropertiesMap={subPropertiesMap}
        createNewMode={createNewMode}
        saving={saving}
        onCancel={onCancel}
        thisAppManage={thisAppManage}
        appRoot={appRoot}
        isApplicationRoot={isApplicationRoot}
      />

      {isApplicationRoot &&
        !isKubernetes(appRoot?.catalogAppName || app?.catalogAppName) &&
        !isOpenshift(appRoot?.catalogAppName) && (
          <Fragment>
            <AccountMappingSection
              appRoot={appRoot}
              createNewMode={createNewMode}
              fields={fields}
              onCancel={onCancel}
              onChange={handleChange}
              onSubmit={saveUpdates}
              propertyTypes={propertyTypes}
              saving={saving}
              section={section}
              thisAppManage={thisAppManage}
            />

            <ScheduledScanning
              appRoot={appRoot}
              saving={saving}
              fields={fields}
              propertyTypes={propertyTypes}
              createNewMode={createNewMode}
              thisAppManage={thisAppManage}
            />
          </Fragment>
        )}

      {createNewMode && (
        <div className="actions-container-new-app">
          <div style={{ paddingTop: 8 }}>
            <Button onClick={onNextClick} style={{ marginRight: 5 }} color="primary">
              {text.NEXT}
            </Button>
            <Button
              style={{
                marginRight: 5,
              }}
              color="secondary"
              onClick={routing.appsList}
            >
              Cancel
            </Button>
          </div>
        </div>
      )}
    </Wrapper>
  )
}

PropertiesApplicationForm.propTypes = {
  app: PropTypes.object,
  createNewMode: PropTypes.bool,
  fields: PropTypes.object,
  handleChange: PropTypes.func,
  onCancel: PropTypes.func,
  onClickMoveNextTab: PropTypes.func,
  propertyTypes: PropTypes.object,
  saveUpdates: PropTypes.func,
  saving: PropTypes.bool,
  section: PropTypes.object,
  subPropertiesMap: PropTypes.object,
  tabsRef: PropTypes.object,
  maxHeight: PropTypes.number,
}

const mapStateToProps = state => {
  return {
    appPropertiesHeaderHeight:
      state.elementRef.elementInfo[APP_PROPERTIES_HEADER_ID],
    appPropertiesTabsHeight: state.elementRef.elementInfo[APP_PROPERTIES_TABS_ID],
  }
}

export default connect(mapStateToProps)(memo(PropertiesApplicationForm))
