import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'

import PropertiesForm from '../PropertiesForm'
import InfoModals from '../InfoModals'

import { updateEnvironmentGroup } from 'action_creators/environment'

import {
  getSelectedApplicationRootId,
  getSelectedApplicationRoot,
} from 'selectors/application_root'

import {
  getSelectedEnvironmentGroupData,
  getSelectedEnvironmentGroupId,
} from 'selectors/environment'

import * as text from 'translations/english_us'

export class EnvironmentGroupFormContainer extends PureComponent {
  state = {
    fields: {},
    successModalOpen: false,
    createNewMode: false,
    createNewStepNumber: 1,
    finalStep: false,
    saveSuccessful: false,
    saveBtnAction: 'sat',
    defaultState: {},
  }

  componentDidMount() {
    const { environmentGroupData: groupData } = this.props

    const { name, description } = groupData

    this.setState({
      fields: {
        name,
        description,
      },
    })
  }

  componentDidUpdate(prevProps) {
    const {
      selectedEnvGroupId: prevGroupId,
      environmentGroupData: prevGroupData,
    } = prevProps

    const {
      selectedEnvGroupId: groupId,
      environmentGroupData: groupData,
    } = this.props

    const { name, description } = groupData

    if (prevGroupId !== groupId || prevGroupData !== groupData) {
      this.setState({
        fields: {
          name,
          description,
        },
      })
    }
  }

  handleChange = event => {
    event.persist()
    const { target } = event
    const { fields = {} } = this.state

    this.setState({
      fields: {
        ...fields,
        [target.name]: target.value,
      },
    })
  }

  updateSaveBtnAction = saveBtnAction => {
    this.setState({ saveBtnAction })
  }

  updateFieldsState = fields => {
    this.setState({ fields, defaultState: fields })
  }

  saveUpdates = async envGroupId => {
    const { dispatch, selectedEnvGroupId, selectedAppId } = this.props
    const { fields } = this.state
    const groupId = selectedEnvGroupId || envGroupId

    if (!isEmpty(fields)) {
      this.setState({
        createNewMode: false,
      })

      await dispatch(updateEnvironmentGroup(selectedAppId, groupId, fields))
    }

    this.setState({
      saveSuccessful: true,
      createNewMode: false,
    })
  }

  toggleModal = () => {
    // TODO: refactor so there is only one state
    this.setState({
      successModalOpen: false, // this modal can only be closed
    })
  }

  onCancel = () => {
    this.setState({ fields: this.state.defaultState })
  }

  render() {
    const {
      appHeaderHeight,
      environmentGroupData,
      updateFormMode,
      saving,
      selectedApplicationRoot,
      selectedEntityId,
      thisAppManage,
    } = this.props

    const {
      supportsEnvironmentScanning,
    } = selectedApplicationRoot.catalogApplication

    const {
      createNewMode,
      fields,
      dropdownOpen,
      saveBtnAction,
      successModalOpen,
    } = this.state

    const fakeCatalogApp = {
      uiSettings: {
        sections: [
          {
            properties: ['name', 'description'],
            displayName: 'Environment Group',
          },
        ],
      },
    }

    const fakeAppRoot = {}

    // TODO: this is usper hacky because we decided not to make an official
    // entity for environment groups unlike environments
    // and applications :shrug:

    const propertyTypes = {
      description: {
        description: 'Environment Group Description',
        displayName: 'Environment Group Description',
        name: 'description',
        // TODO: should be readonly if you can't edit?
        privy: false,
        readOnly: supportsEnvironmentScanning,
        required: true,
        type: 'java.lang.String',
        value: fields.description,
      },
      name: {
        description: 'Environment Group Name',
        displayName: 'Environment Group Name',
        name: 'name',
        privy: false,
        // TODO: should be readonly if you can't edit?
        readOnly: supportsEnvironmentScanning,
        required: true,
        type: 'java.lang.String',
        value: fields.name,
      },
    }

    // TODO: remove isEnvironment after ui settings is proper on BE
    return (
      <div>
        <PropertiesForm
          appHeaderHeight={appHeaderHeight}
          isEnvironmentGroup
          app={fakeCatalogApp}
          createNewMode={createNewMode}
          dropdownOpen={dropdownOpen}
          fields={fields}
          handleChange={this.handleChange}
          onCancel={this.onCancel}
          propertyTypes={propertyTypes}
          saveBtnAction={saveBtnAction}
          saveUpdates={this.saveUpdates}
          saving={saving}
          selectedEntityId={selectedEntityId}
          selectedApplicationRoot={fakeAppRoot}
          toggle={this.toggle}
          updateSaveBtnAction={this.updateSaveBtnAction}
          updateFormMode={updateFormMode}
          onClickMoveNextTab={this.onClickMoveNextTab}
          thisAppManage={thisAppManage}
        />

        <InfoModals
          typeOfUnit={text.ENVIRONMENT}
          unitName={get(environmentGroupData, 'name')}
          successModalOpen={successModalOpen}
          toggleModal={this.toggleModal}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  environmentGroupData: getSelectedEnvironmentGroupData(state),
  // TODO: need selector
  saving: state.environment.updatingEnvironmentGroup,
  selectedAppId: getSelectedApplicationRootId(state),
  selectedApplicationRoot: getSelectedApplicationRoot(state),
  selectedEnvGroupId: getSelectedEnvironmentGroupId(state),
})

export default connect(mapStateToProps)(EnvironmentGroupFormContainer)
