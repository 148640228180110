import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import ModalPopup from 'britive-design-system/core/components/modal-popup'
import Spinner from 'britive-design-system/core/components/spinner'
import Snackbar from 'britive-design-system/core/components/snackbar'
import Typography from 'britive-design-system/core/components/typography'
import Table from 'britive-design-system/core/components/table'

import { fetchTagAssignedUsers } from 'action_creators/tag'
import useTableSort from 'hooks/useTableSort'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  .cell-with-icon {
    display: flex;
    justify-content: space-between;
    .cell-icon {
      cursor: pointer;
    }
  }
`

const TagMembersModal = ({ tag, onClose }) => {
  const dispatch = useDispatch()
  const [filteredData, setFilteredData] = useState(null)

  const { tagAssignedUsers, fetchingTagAssignedUsers, error } = useSelector(
    state => state.tags
  )
  const [sortHandler, getSortedData] = useTableSort()

  useEffect(() => {
    !tagAssignedUsers[tag.userTagId] &&
      tag.userCount > 0 &&
      dispatch(fetchTagAssignedUsers({ tagId: tag.userTagId }))
  }, [tag.userTagId])

  const buttons = [
    {
      text: 'Close',
      variant: 'secondary',
      onClick: onClose,
      size: 'medium',
    },
  ]

  const tableProps = {
    rows: getSortedData(filteredData || tagAssignedUsers[tag.userTagId]),
    columns: [
      {
        headerName: 'Name',
        field: 'name',
        sortable: true,
        width: 100,
      },
      {
        headerName: 'Username',
        field: 'username',
        sortable: true,
        width: 70,
      },
      {
        headerName: 'Email',
        field: 'email',
        sortable: true,
        width: 90,
      },
    ],
    resizableColumns: true,
    sortHandler,
    searchBar: {
      onSearch: value => {
        if (!value) {
          setFilteredData(null)
        }
        const filteredUsers = tagAssignedUsers[tag.userTagId]?.filter(
          ({ name, username, email }) => {
            return (
              name?.toLowerCase().includes(value.toLowerCase()) ||
              username.toLowerCase().includes(value.toLowerCase()) ||
              email.toLowerCase().includes(value.toLowerCase())
            )
          }
        )
        setFilteredData(filteredUsers)
      },
      placeholder: 'Search',
    },
  }

  return (
    <ModalPopup
      width={720}
      title={`Members of ${tag.name}`}
      buttons={buttons}
      onCancel={onClose}
    >
      <Container>
        {fetchingTagAssignedUsers && (
          <Spinner size={'medium'} message="Loading..." overlay />
        )}
        {error && (
          <Snackbar
            errorList={[
              `Something went wrong while fetching members of ${tag.name}!`,
            ]}
          />
        )}
        {tag.userCount === 0 ? (
          <Typography variant="heading6">No users found!</Typography>
        ) : (
          <Table {...tableProps} />
        )}
      </Container>
    </ModalPopup>
  )
}

export default TagMembersModal
