import * as actions from 'actions'

const initialState = {
  profilesListLoading: false,
  paginatedProfilesList: [],
}

export function profileListReducer(state = initialState, action) {
  switch (action.type) {
    case `${actions.PROFILE_MANAGEMENT_GET_PROFILES}_PENDING`: {
      return {
        ...state,
        profilesListLoading: true,
      }
    }

    case `${actions.PROFILE_MANAGEMENT_GET_PROFILES}_FULFILLED`: {
      const { data, count, page, size } = action.payload.data
      return {
        ...state,
        profilesListLoading: false,
        paginatedProfilesList: {
          data,
          count,
          page,
          size,
          searchText: action?.meta?.searchText,
        },
      }
    }

    case `${actions.PROFILE_MANAGEMENT_GET_PROFILES}_REJECTED`: {
      return {
        ...state,
        profilesListLoading: false,
      }
    }

    case `${actions.PROFILE_MANAGEMENT_GET_PROFILES_LOAD_MORE}_PENDING`: {
      return {
        ...state,
        profilesListLoading: true,
      }
    }

    case `${actions.PROFILE_MANAGEMENT_GET_PROFILES_LOAD_MORE}_FULFILLED`: {
      const { data, count, page, size } = action.payload.data
      return {
        ...state,
        profilesListLoading: false,
        paginatedProfilesList: {
          data: [...state.paginatedProfilesList.data, ...data],
          count,
          page,
          size,
          searchText: action?.meta?.searchText,
        },
      }
    }

    case `${actions.PROFILE_MANAGEMENT_GET_PROFILES_LOAD_MORE}_REJECTED`: {
      return {
        ...state,
        profilesListLoading: false,
      }
    }

    default:
      return { ...state }
  }
}
