import React, { Fragment } from 'react'
import { BackgroundTooltip as Tooltip } from 'components/Tooltips'
import styled from 'styled-components'

const IntegrityCheckIcons = {
  Incomplete: <i className="fas fa-exclamation-triangle warning" />,
  Success: <i className="fas fa-check-circle success" />,
  Failure: <i className="fas fa-times-circle error" />,
}

const IconWrapper = styled.span`
  font-size: 16px;
  margin-left: 4px;
  cursor: ${props => (props.allowClick ? 'pointer' : 'normal')};

  i {
    cursor: ${props => (props.allowClick ? 'pointer' : 'normal')};
  }
  .success {
    color: var(--success);
  }

  .error {
    color: var(--error);
  }

  .warning {
    color: var(--warning);
  }
`

export default ({ checkStatus, message, name, onClick, selected }) => (
  <Fragment>
    <IconWrapper
      data-for={`${name}-tooltip`}
      data-tip={message && message.length ? message : checkStatus}
      {...(onClick && {
        onClick: () => onClick(selected),
      })}
      allowClick={!!onClick}
    >
      {IntegrityCheckIcons[checkStatus] || checkStatus}
    </IconWrapper>
    <Tooltip place="top" effect="solid" id={`${name}-tooltip`} />
  </Fragment>
)
