import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Accordion from 'britive-design-system/core/components/accordion'
import SectionTitle from '../SectionTitle'
import { sectionTitle } from '../constants'
import DeleteConfirmationDialog from '../DeleteConfirmationDialog'
import MembersSelector from 'components/MembersSelector'

const RequestersSection = ({
  memberRules = [],
  updateSettings,
  disableEditing = false,
}) => {
  const [itemToDelete, setItemToDelete] = useState(null)
  const handleDelete = () => {
    updateSettings({
      memberRules: memberRules.filter(memberRule => memberRule.id !== itemToDelete),
    })
    setItemToDelete(null)
  }

  const handleAdd = async members => {
    await updateSettings({
      memberRules: members,
    })
  }

  return (
    <Accordion expanded title={<SectionTitle {...sectionTitle.requesters} />}>
      <MembersSelector
        members={memberRules}
        onAdd={handleAdd}
        onDelete={setItemToDelete}
        inclusivityOption
        disableEditing={disableEditing}
      />
      {itemToDelete && (
        <DeleteConfirmationDialog
          entity="requester"
          parentEntity="access builder settings"
          onDelete={() => handleDelete(itemToDelete)}
          onCancel={() => setItemToDelete(null)}
        />
      )}
    </Accordion>
  )
}

RequestersSection.propTypes = {
  notificationMediums: PropTypes.array,
}

export default RequestersSection
