import React from 'react'
import DataTable from 'britive-design-system/core/components/table'
import { BiMinusCircle } from 'react-icons/bi'
import isEmpty from 'lodash/isEmpty'
import useTableSort from 'hooks/useTableSort'
import editIcon from 'static/images/edit-icon.svg'

const TagsList = ({ tagsList, removeTag, editTag }) => {
  const [sortHandler, getSortedData] = useTableSort()

  const columns = [
    {
      headerName: 'Key',
      field: 'key',
      sortable: true,
      width: 35,
    },
    {
      headerName: 'Value',
      field: 'value',
      sortable: true,
      width: 35,
    },
    {
      headerName: 'Action',
      width: 30,
      renderColumn: row => {
        return (
          <div
            style={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <BiMinusCircle
              size="22"
              data-testid={`remove-${row.key}`}
              onClick={() => removeTag(row.key)}
            />

            <div onClick={() => editTag(row)}>
              <img src={editIcon} />
            </div>
          </div>
        )
      },
    },
  ]
  return (
    <>
      {!isEmpty(tagsList) && (
        <div>
          <DataTable
            rows={getSortedData(tagsList)}
            sortHandler={sortHandler}
            columns={columns}
            resizableColumns={true}
          />
        </div>
      )}
    </>
  )
}

export default TagsList
