import React, { Fragment, memo } from 'react'
import styled from 'styled-components'
import FontAwesome from 'react-fontawesome'
import ReactTooltip from 'react-tooltip'

import TableEllipsisCell from 'components/table/TableV2/TableEllipsisCell'
import ScanStatusCell from '../ScanStatusCell'
import PermissionsDrawer from '../PermissionsDrawer'
import AssignedDrawer from '../AssignedDrawer'
import AssignedList from '../AssignedList'
import { BackgroundTooltip as Tooltip } from 'components/Tooltips'

import { formatDateLongWithYear } from 'utils/format_date'
import Table from 'components/table/TableV2/Table'
import get from 'lodash/get'
import Spinner from 'britive-ui-components/core/components/Spinner'

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;

  .associatedWrapper {
    display: flex;
    height: 14px;
  }

  .fa-list {
    &.disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
    cursor: pointer;
    margin-left: 8px;
  }
`

const styles = {
  tableWrapper: {
    display: 'block',
    marginTop: -32,
    marginBottom: -20,
  },
  goToParentIcon: {
    cursor: 'pointer',
    marginLeft: 8,
  },
  goToParentWrapper: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
  },
  goToParentLabel: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    flexGrow: 1,
    whiteSpace: 'nowrap',
  },
  icon: {
    marginLeft: 4,
    marginRight: 10,
    width: 20,
    display: 'inline-block',
    height: 20,
    fontSize: 12,
    textAlign: 'center',
    color: '#9b3eec',
    fontStyle: 'normal',
    fontWeight: 600,
    cursor: 'pointer',
  },
}

function Groups(props) {
  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
      fixed: 'left',
      Cell: data => renderName(data),
      minWidth: 200,
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      Cell: data => renderActionIcons(data.original),
      resizable: false,
      fixed: 'left',
      sortable: false,
      width: 64,
    },
    {
      Header: 'Description',
      accessor: 'description',
      minWidth: 150,
    },
    {
      Header: 'Assigned',
      accessor: 'accounts',
      Cell: cellProps => (
        <AssignedList
          {...cellProps}
          onClick={() => {
            toggleAssignedDrawer({ permission: cellProps.original })
          }}
        />
      ),
      width: 150,
      sortable: false,
    },
    {
      Header: 'Created',
      accessor: 'created',
      Cell: ({ value }) => (
        <TableEllipsisCell value={formatDateLongWithYear({ dateTime: value })} />
      ),
      minWidth: 200,
    },
    {
      Header: 'Last Updated',
      accessor: 'modified',
      Cell: ({ value }) => (
        <TableEllipsisCell value={formatDateLongWithYear({ dateTime: value })} />
      ),
      minWidth: 200,
    },
    {
      Header: 'Scan Status',
      accessor: 'scanStatus',
      Cell: ScanStatusCell,
      minWidth: 150,
    },
  ]
  const renderName = ({ original: groupData }) => {
    const { name, privileged } = groupData
    return (
      <Fragment>
        <TableEllipsisCell>
          <span>{name}</span>
        </TableEllipsisCell>
        {privileged && (
          <span>
            <div
              data-for={`${name}-privileged`}
              data-tip="High Risk"
              style={styles.icon}
            >
              <i className="fas fa-bolt" />
            </div>
          </span>
        )}
        <Tooltip
          effect="solid"
          place="left"
          getContent={content => content}
          id={`${name}-privileged`}
        />
      </Fragment>
    )
  }

  const renderActionIcons = permission => {
    const { togglePermissionsDrawer } = props

    const { appPermissionId } = permission
    const myRef = React.createRef()

    return (
      <ActionsWrapper className={`registerToggleWrapper${appPermissionId}`}>
        <div
          data-tip={`${
            permission.permissions.length > 0 ? 'View' : 'No'
          } Associated Permissions`}
          onClick={() => togglePermissionsDrawer({ permission })}
          name="list"
          ref={myRef}
          className="associatedWrapper"
          onMouseEnter={() => ReactTooltip.show(myRef.current)}
          onMouseLeave={() => ReactTooltip.hide(myRef.current)}
        >
          <FontAwesome
            name="list"
            className={`${permission.permissions.length > 0 ? '' : ' disabled'}`}
          />
        </div>
      </ActionsWrapper>
    )
  }

  const {
    isAssignedDrawerOpen,
    isPermissionsDrawerOpen,
    requiresHierarchicalModel,
    selectedApp,
    selectedEnvId,
    selectedAppId,
    selectedPermission,
    selectedEnvironmentGroupId,
    togglePermissionsDrawer,
    toggleAssignedDrawer,
  } = props

  const entityId = selectedEnvId || selectedEnvironmentGroupId

  return entityId ? (
    <div className="group_table_v2">
      <Table
        columns={columns}
        identifier="appPermissionId"
        dataUrl={`/apps/${selectedAppId}/environments/${entityId}/groups`}
        emptyTableMessage={'No Groups were found.'}
        params={{ includeMembers: true }}
        dropdownFilters={[
          {
            label: 'Scan Status',
            value: 'scanStatus',
            options: [
              { label: 'New', value: 'New' },
              { label: 'Updated', value: 'Updated' },
              { label: 'Unchanged', value: 'Unchanged' },
              { label: 'Deleted', value: 'Deleted' },
            ],
          },
        ]}
        checkBoxFilters={[
          {
            name: 'assigned',
            value: true,
            displayText: 'Assigned',
          },
          {
            name: 'privileged',
            value: true,
            displayText: 'High Risk',
          },
        ]}
        margin={40}
      />
      <PermissionsDrawer
        rolePermission={selectedPermission}
        isOpen={isPermissionsDrawerOpen}
        isHierarchical={requiresHierarchicalModel}
        appId={selectedApp.appContainerId}
        entityId={entityId}
        toggleDrawer={permission => togglePermissionsDrawer({ permission })}
      />

      <AssignedDrawer
        isOpen={isAssignedDrawerOpen}
        isHierarchical={requiresHierarchicalModel}
        toggleDrawer={permission => toggleAssignedDrawer({ permission })}
        name={get(selectedPermission, 'name')}
        tabs={
          isAssignedDrawerOpen
            ? [
                {
                  name: 'Permissions',
                  dataUrl: `/apps/${selectedAppId}/environments/${entityId}/groups/${selectedPermission.appPermissionId}/permissions`,
                  nameIdentifier: 'permissionName',
                },
                {
                  name: 'Accounts',
                  dataUrl: `/apps/${selectedAppId}/environments/${entityId}/groups/${selectedPermission.appPermissionId}/accounts`,
                  nameIdentifier: 'accountName',
                },
              ]
            : []
        }
      />
    </div>
  ) : (
    <Spinner size="2x" />
  )
}

export default memo(Groups)
