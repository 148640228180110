import get from 'lodash/get'

import {
  FETCH_SCHEDULED_TASKS,
  FETCH_SCHEDULED_TASK,
  FETCH_SCHEDULED_TASK_STATUS,
} from 'action_creators/scheduled_task'

export const scheduledTaskReducerKey = 'scheduledTask'

const initialState = {
  scheduledTask: {},
  scheduledTasks: [],
  scheduledTaskStatus: [],
  fetchingScheduledTask: false,
  fetchingScheduledTasks: false,
  fetchingScheduledTaskStatus: false,
  error: null,
}

export function scheduledTaskReducer(state = initialState, action) {
  switch (action.type) {
    case `${FETCH_SCHEDULED_TASKS}_PENDING`:
      return {
        ...state,
        fetchingScheduledTasks: true,
        creatingScheduledTask: true,
        scheduledTasks: null,
        error: null,
      }

    case `${FETCH_SCHEDULED_TASKS}_FULFILLED`: {
      const scheduledTasks = get(action, 'payload.data', [])
      return {
        ...state,
        scheduledTasks,
        fetchingScheduledTasks: false,
      }
    }

    case `${FETCH_SCHEDULED_TASKS}_REJECTED`:
      return {
        ...state,
        fetchingScheduledTasks: false,
        error: action.payload,
      }

    case `${FETCH_SCHEDULED_TASK}_PENDING`:
      return {
        ...state,
        fetchingScheduledTask: true,
      }

    case `${FETCH_SCHEDULED_TASK}_FULFILLED`: {
      const scheduledTask = action.payload.data[0]
      return {
        ...state,
        scheduledTask,
        fetchingScheduledTask: false,
      }
    }

    case `${FETCH_SCHEDULED_TASK}_REJECTED`:
      return {
        ...state,
        fetchingScheduledTask: false,
        error: action.payload,
      }

    case `${FETCH_SCHEDULED_TASK_STATUS}_PENDING`:
      return {
        ...state,
        fetchingScheduledTaskStatus: true,
        error: null,
      }

    case `${FETCH_SCHEDULED_TASK_STATUS}_FULFILLED`: {
      const scheduledTaskStatus = action.payload.data
      return {
        ...state,
        scheduledTaskStatus,
        fetchingScheduledTaskStatus: false,
      }
    }

    case `${FETCH_SCHEDULED_TASK_STATUS}_REJECTED`:
      return {
        ...state,
        fetchingScheduledTaskStatus: false,
        error: action.payload,
      }

    default:
      return { ...state }
  }
}
