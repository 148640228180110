import React, { memo, useEffect } from 'react'

const styles = {
  checkbox: {
    cursor: 'pointer',
    marginRight: 4,
  },
  disabled: {
    marginRight: 4,
  },
  saveButton: {
    marginLeft: 8,
  },
}

function ScopeCheckbox({
  papScope,
  parentSelected,
  entity,
  updateScope,
  isDisabled,
  isEditing,
}) {
  useEffect(() => {
    if (parentSelected && !!papScope[entity.entityId]) {
      updateScope(entity)
    }
  }, [parentSelected, papScope])

  return (
    <input
      disabled={!isEditing || parentSelected || isDisabled}
      onChange={() => updateScope(entity)}
      checked={!!(papScope[entity.entityId] || parentSelected)}
      key={new Date()}
      type="checkbox"
      style={isDisabled || !isEditing ? styles.disabled : styles.checkbox}
    />
  )
}

export default memo(ScopeCheckbox)
