import React, { useState } from 'react'
import Button from 'britive-design-system/core/components/button'
import Checkbox from 'britive-design-system/core/components/checkbox'
import Dialog from 'britive-design-system/core/components/dialog'
import { selectPap } from 'routes/admin/state/actions'
import { validateProfileData } from 'action_creators/pap'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import Spinner from 'britive-design-system/core/components/spinner'
import Typography from 'britive-design-system/core/components/typography'
import toast from 'utils/toast/index.js'

const CloneProfile = ({
  requestAccess,
  appId,
  papId,
  supportsSessionAttributes,
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const checkBoxObject = {
    profile: true,
    permissions: true,
    associations: true,
    policies: true,
    ...(supportsSessionAttributes &&
      !requestAccess && {
        sessionAttributes: true,
      }),
  }

  const [checkState, setCheckState] = useState(checkBoxObject)
  const [isValidatingProfileData, setIsValidatingProfileData] = useState(false)
  const [listOfInvalidPolicies, setListOfInvalidPolicies] = useState([])

  const onChange = e => {
    if (e.target.name === 'permissions' && e.target.checked === true) {
      setCheckState({ ...checkState, associations: true, permissions: true })
    } else if (e.target.name === 'associations' && e.target.checked === false) {
      setCheckState({
        ...checkState,
        associations: false,
        permissions: false,
      })
    } else {
      setCheckState({ ...checkState, [e.target.name]: e.target.checked })
    }
  }

  const nextHandler = async () => {
    // If policies are selected, then only validate profile data for cloning
    if (checkState['policies']) {
      // To show the loader while data is being validated
      setIsValidatingProfileData(true)
      try {
        const {
          value: { data },
        } = await dispatch(validateProfileData(papId, requestAccess))
        // If data is valid
        const invalidPolicies = data?.policy?.invalid
        if (invalidPolicies?.length === 0) {
          showCloneDetails()
        } else {
          const invalidPolicyNames = invalidPolicies?.map(item => {
            return item.name
          })
          setListOfInvalidPolicies(invalidPolicyNames)
        }
        setIsValidatingProfileData(false)
      } catch ({ response }) {
        const reason = response?.data?.message || ''
        toast({
          title: reason,
          type: 'error',
          time: 'normal',
          id: reason,
        })
        setIsValidatingProfileData(false)
      }
    } else {
      showCloneDetails()
    }
  }

  const showCloneDetails = (ignoreErrors = true) => {
    let payload = `clone?cloneProfileId=${papId}&cloneOptions=`
    for (let key in checkState) {
      if (checkState[key]) {
        payload = payload + key + ','
      }
    }
    dispatch(selectPap(papId))
    let path =
      `${requestAccess ? '/request-access' : '/admin'}` +
      `/applications/${appId}/profiles/create`
    history.push({
      pathname: path,
      cloneProfile: true,
      ignoreErrors,
      payload,
      papId,
    })
  }
  const renderTitle = (title, description) => {
    return (
      <>
        {title} ( <i>{description}</i> )
      </>
    )
  }

  const renderLabel = value => {
    switch (value) {
      case 'profile':
        return renderTitle(
          'Profile Details',
          'Cloned by default. Clone basic profile details'
        )
      case 'permissions':
        return renderTitle(
          'Permissions',
          'Clone permissions assigned to this profile.'
        )
      case 'associations':
        return renderTitle(
          'Associations',
          'Clone any associated data linked to this profile.'
        )
      case 'policies':
        return renderTitle(
          'Policies',
          'Clone policy configurations for this profile.'
        )
      case 'sessionAttributes':
        return renderTitle(
          'Session Attributes',
          'Clone session-specific attributes for this profile.'
        )
      default:
        return null
    }
  }

  return (
    <>
      {isValidatingProfileData && (
        <Spinner size={'medium'} message="Validating..." overlay />
      )}
      <p>Select the items you want to clone for the new profile:</p>

      {Object.entries(checkState).map(([key, value]) => {
        return (
          <div key={key} style={{ marginTop: '20px' }}>
            <Checkbox
              name={key}
              label={renderLabel(key)}
              checked={value}
              onChange={onChange}
              disabled={key === 'profile'}
            />
          </div>
        )
      })}

      <div style={{ marginTop: '10%' }}>
        <Button size="medium" onClick={nextHandler}>
          {'Next'}
        </Button>
      </div>

      {listOfInvalidPolicies?.length > 0 && (
        <Dialog
          width={464}
          height={278}
          type="alert"
          title={'Clone Profile'}
          primaryButtonText={'Yes'}
          secondaryButtonText={'No'}
          onSubmit={() => showCloneDetails()}
          onCancel={() => setListOfInvalidPolicies([])}
        >
          <>
            <Typography variant="body">
              There is an issue with following policies:
            </Typography>
            {listOfInvalidPolicies.map((policy, idx) => {
              return (
                <Typography
                  key={`invalid-policy-${idx}-for-clone-profile`}
                  variant="body"
                >
                  {policy}
                </Typography>
              )
            })}
            <div style={{ marginTop: '20px' }}>
              <Typography variant="body">
                Skip listed policies and continue?
              </Typography>
            </div>
          </>
        </Dialog>
      )}
    </>
  )
}

export default CloneProfile
