import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import Button from 'britive-ui-components/core/components/Button'
import Spinner from 'britive-ui-components/core/components/Spinner'
import PropTypes from 'prop-types'
import FormField from 'components/form_fields'
import styled from 'styled-components'
import Autocomplete from 'britive-design-system/core/components/autocomplete'
const SearchWrapper = styled.div`
  margin-left: 10px;
  display: flex;
  align-items: center;
  ${({ filterLength }) => filterLength && 'border-left: 2px solid #ced4da'};
  padding-left: 0.6rem;

  > .bds-autocomplete-wrapper > .bds-autocomplete-container {
    height: 28px;
    border-radius: 0.25rem 0 0 0.25rem;
    height: 28px;
    min-height: unset;
    border: 1px solid #ced4da;
    border-right: none;
  }

  > .form-group {
    margin-bottom: 0;

    input {
      height: 28px;
      border-radius: ${({ hasColumnNamesFilter }) =>
        hasColumnNamesFilter ? '0 0.25rem 0.25rem 0' : '0.25rem'};
    }
  }
`

const RefreshWrapper = styled.div`
  height: 16px;
  margin-left: 8px;
  cursor: ${props => (props.isRefreshing ? 'not-allowed' : 'pointer')};
`

class TableHeader extends Component {
  state = {
    searchValue: '',
    inputTimeout: null,
    selectedDropdownFilter: null,
    selectedCheckboxFilters: null,
    columnFilter: null,
  }

  componentDidMount() {
    const { finalParams, columnNamesFilter, dropdownFilters } = this.props
    const updatedState = {}

    const selectedColumnFilter = columnNamesFilter?.find(
      columnName => !!finalParams[columnName.value]
    )

    if (!isEmpty(selectedColumnFilter)) {
      updatedState.columnFilter = selectedColumnFilter.value
      updatedState.searchValue = finalParams[selectedColumnFilter.value]
    } else if (finalParams?.searchText) {
      updatedState.searchValue = finalParams.searchText
    }

    if (finalParams?.filter && dropdownFilters?.length) {
      // Splitting the filter using 'eq' string as a separator.
      // The 0th index becomes the filter label, and the 1st becomes the value.
      const existingDropdownFilter = finalParams.filter?.split('eq')

      this.setState({
        selectedDropdownFilter: {
          [existingDropdownFilter[0]?.trim()]: existingDropdownFilter[1]?.trim(),
        },
      })
    }

    if (!isEmpty(updatedState)) {
      this.setState(updatedState)
    }
  }

  componentDidUpdate(prevProps) {
    const { selectedDropdownFilter, selectedCheckboxFilters } = this.props

    if (
      !isEqual(selectedDropdownFilter, prevProps.selectedDropdownFilter) &&
      !isEmpty(selectedDropdownFilter)
    ) {
      this.setState({ selectedDropdownFilter })
    }

    if (!isEqual(selectedCheckboxFilters, prevProps.selectedCheckboxFilters)) {
      this.setState({
        selectedCheckboxFilters: isEmpty(selectedCheckboxFilters)
          ? null
          : selectedCheckboxFilters,
      })
    }
  }

  renderFilterValues() {
    const { selectedDropdownFilter } = this.state
    let selectedFilter = null
    if (selectedDropdownFilter) {
      selectedFilter = this.props.dropdownFilters?.find(
        f => f.value === Object.keys(this.state.selectedDropdownFilter)[0]
      )?.options
    }
    return selectedFilter ? this.renderFilterOptions(selectedFilter) : null
  }

  renderFilterOptions(filterByOptions = []) {
    const { optionsStyle } = styles
    return filterByOptions.map(options => (
      <option style={optionsStyle} key={options.label} value={options.value}>
        {options.label}
      </option>
    ))
  }

  handleSearch = (searchValue, columnFilter) => {
    if (this.props.manual) {
      this.props.setLoading(true)
      clearTimeout(this.state.inputTimeout)
      const inputTimeout = setTimeout(() => {
        this.props.onSearch(searchValue, columnFilter)
      }, 1000)
      this.setState({ inputTimeout })
    }
  }

  getSelectedColumnNameFilter = () => {
    const { columnNamesFilter } = this.props
    const { columnFilter } = this.state

    const selectedColumnNameFilter = columnNamesFilter.find(
      columnName => columnName.value === columnFilter
    )

    if (isEmpty(selectedColumnNameFilter)) {
      return []
    }

    return [selectedColumnNameFilter]
  }

  render() {
    const {
      inputStyle,
      controlsContainer,
      filterWrapper,
      headerCheckbox,
      disabled,
      tableHeader,
      optionsStyle,
    } = styles

    const hasColumnNamesFilter = !!this.props.columnNamesFilter?.length

    return (
      <div style={{ marginBottom: 2 }}>
        {this.props.showButton ? (
          <Row md={12} style={{ marginBottom: 20, height: 36 }}>
            <Col style={controlsContainer}>
              <Button
                color={this.props.buttonColor}
                style={{ marginRight: 10, height: 35 }}
                onClick={() => this.props.buttonAction()}
              >
                {this.props.buttonTitle}
              </Button>
            </Col>
          </Row>
        ) : null}
        <Row style={tableHeader}>
          <Col md={12}>
            <div style={controlsContainer}>
              {this.props.params?.type !== 'ServiceIdentity' &&
                this.props.checkBoxFilters &&
                this.props.checkBoxFilters.map(filter => (
                  <label
                    style={{
                      ...headerCheckbox,
                      ...(this.props.loading && { ...disabled }),
                    }}
                    key={filter.name}
                  >
                    <input
                      type="checkbox"
                      name={filter.name}
                      disabled={this.props.loading}
                      onClick={({ target: { checked } }) => {
                        this.setState(
                          {
                            selectedCheckboxFilters: {
                              ...this.state.selectedCheckboxFilters,
                              [filter.name]: filter.value,
                            },
                          },
                          () => {
                            this.props.setCheckBoxFilters({
                              [filter.name]: checked || null,
                            })
                          }
                        )
                      }}
                      checked={
                        !!(
                          this.state.selectedCheckboxFilters &&
                          this.state.selectedCheckboxFilters[filter.name]
                        )
                      }
                    />{' '}
                    {filter.displayText}
                  </label>
                ))}
              {this.props.dropdownFilters && this.props.dropdownFilters.length > 0 && (
                <div
                  style={{
                    ...filterWrapper,
                    ...(this.props.loading && { ...disabled }),
                  }}
                >
                  <select
                    disabled={this.props.loading}
                    value={
                      this.state.selectedDropdownFilter
                        ? Object.keys(this.state.selectedDropdownFilter)[0]
                        : ''
                    }
                    style={inputStyle}
                    onChange={({ target: { value } }) => {
                      this.setState(
                        {
                          selectedDropdownFilter:
                            value === '' ? null : { [value]: '' },
                        },
                        () =>
                          this.props.selectedDropdownFilter &&
                          Object.keys(this.props.selectedDropdownFilter)[0] &&
                          this.props.setDropdownFilter(
                            this.state.selectedDropdownFilter
                          )
                      )
                    }}
                  >
                    <option style={optionsStyle} value={''} disabled>
                      Filter By
                    </option>
                    {this.renderFilterOptions(this.props.dropdownFilters)}
                  </select>

                  <select
                    disabled={this.props.loading}
                    value={
                      this.state.selectedDropdownFilter
                        ? Object.values(this.state.selectedDropdownFilter)[0]
                        : ''
                    }
                    style={inputStyle}
                    onChange={({ target: { value } }) => {
                      this.setState(
                        {
                          selectedDropdownFilter: {
                            [Object.keys(
                              this.state.selectedDropdownFilter
                            )[0]]: value,
                          },
                        },
                        () =>
                          this.props.setDropdownFilter(
                            this.state.selectedDropdownFilter
                          )
                      )
                    }}
                  >
                    <option style={optionsStyle} value={''}>
                      All
                    </option>
                    {this.renderFilterValues()}
                  </select>
                </div>
              )}

              <SearchWrapper
                hasColumnNamesFilter={hasColumnNamesFilter}
                filterLength={
                  this.props.dropdownFilters.length +
                  this.props.checkBoxFilters.length
                }
              >
                {hasColumnNamesFilter ? (
                  <Autocomplete
                    placeholder={'Choose a column'}
                    options={this.props.columnNamesFilter}
                    multi={false}
                    value={this.getSelectedColumnNameFilter()}
                    getOptionLabel={option => option?.label}
                    onInputChange={() => {
                      // if the existing column filter in not present, do not call the handleSearch.
                      if (!this.state.columnFilter) {
                        return
                      }

                      // set the column filter to null and call the handle search with the search text.
                      this.setState({ columnFilter: null })
                      if (this.state.searchValue) {
                        this.handleSearch(this.state.searchValue)
                      }
                    }}
                    onBlur={() => {}}
                    onChange={(_, selectedOption) => {
                      this.setState({ columnFilter: selectedOption?.value })
                      if (this.state.searchValue && selectedOption?.value) {
                        this.handleSearch(
                          this.state.searchValue,
                          selectedOption?.value
                        )
                      }
                    }}
                    width="200px"
                  />
                ) : null}
                <FormField
                  id="search"
                  name="search"
                  placeholder="Search"
                  value={this.state.searchValue}
                  hideLabel={true}
                  onChange={({ target: { value } }) => {
                    this.setState({ searchValue: value })
                    this.handleSearch(value, this.state.columnFilter)
                  }}
                />
              </SearchWrapper>
              {this.props.refreshHandler && (
                <RefreshWrapper isRefreshing={this.props.isRefreshing}>
                  {this.props.isRefreshing ? (
                    <Spinner size="1x" />
                  ) : (
                    <span
                      className="fas fa-sync-alt fs:16"
                      onClick={() => {
                        this.setState({
                          selectedDropdownFilter: null,
                          selectedCheckboxFilters: null,
                          searchValue: '',
                        })
                        this.props.refreshHandler()
                      }}
                    />
                  )}
                </RefreshWrapper>
              )}
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

TableHeader.propTypes = {
  showButton: PropTypes.bool,
  toggleModal: PropTypes.func,
  buttonTitle: PropTypes.string,
  sortTable: PropTypes.func,
  filterTable: PropTypes.func,
  searchTable: PropTypes.func,
  sortByOptions: PropTypes.array,
  filterByOptions: PropTypes.array,
  buttonAction: PropTypes.func,
  buttonColor: PropTypes.string,
  filterByRegistered: PropTypes.func,
  filterByInUse: PropTypes.func,
  allowFilterByRegistered: PropTypes.bool,
  allowFilterByPermissions: PropTypes.bool,
  allowFilterByInUse: PropTypes.bool,
  allowFilterByMapped: PropTypes.bool,
  useCompoundFilter: PropTypes.bool,
  compoundFilterCategories: PropTypes.array,
  filterDataSet: PropTypes.object,
  changeFilterCategory: PropTypes.func,
  changeFilterByMapped: PropTypes.func,
  changeFilterByPermissions: PropTypes.func,
  filter: PropTypes.bool,
  columnNamesFilter: PropTypes.array,
}

const styles = {
  inputStyle: {
    boxSizing: 'border-box',
    height: '28px',
    width: '164px',
    border: '2px solid #9BBFDB',
    borderRadius: '4px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 2px 8px 3px rgba(206,206,206,0.21)',
    marginLeft: 10,
  },
  optionsStyle: {
    height: '14px',
    width: '99px',
    color: '#6F6F6F',
    fontFamily: 'WorkSans',
    fontSize: '12px',
    lineHeight: '14px',
  },
  controlsContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  tableHeader: {
    flex: 1,
    boxShadow: `0 2px 12px 2px #cddfed`,
    backgroundColor: '#fff',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    padding: '4px 4px',
    margin: 0,
    width: '100%',
  },
  selectField: {
    height: '14px',
    width: '99px',
    fontSize: '12px',
    color: '#6F6F6F',
    fontFamily: 'WorkSans',
  },
  headerCheckbox: {
    margin: '5px 15px 0 0',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
  },
  filterWrapper: {
    whiteSpace: 'nowrap',
  },
  disabled: {
    cursor: 'not-allowed',
  },
}

export default TableHeader
