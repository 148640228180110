import React, { Fragment, useEffect, useState } from 'react'
import styled from 'styled-components'
import Spinner from 'britive-ui-components/core/components/Spinner'

import { preFetch } from 'utils/do_fetch'

const User = styled.li`
  padding: 5px;
  margin-left: -20px;
  list-style: none;

  &:before {
    content: '\f007';
    font-family: 'Font Awesome 5 Free';
    display: inline-block;
    margin-left: -1.5em;
    width: 1.5em;
    font-size: 12px;
    font-weight: 900;
    color: var(--medium-purple);
  }
`

const Heading = styled.h5`
  border-bottom: 2px solid var(--brand);
  padding: 5px 0;
  margin-right: 10px;
`

const Icon = styled.i`
  color: var(--medium-purple);
`

export default ({ papId, tagId }) => {
  const [fetching, setFetching] = useState(false)
  const [tag, setTag] = useState(null)
  const [error, setError] = useState(null)
  useEffect(() => {
    const fetchTags = async () => {
      if (papId) {
        setFetching(true)
        try {
          const response = await preFetch({
            path: `/paps/${papId}/tags/checks`,
            method: 'get',
          })
          setTag(response.data.find(d => d.userTagId === tagId))
        } catch (error) {
          setError(error.response.data.message)
        } finally {
          setFetching(false)
        }
      }
    }
    fetchTags()
  }, [papId])
  return (
    <Fragment>
      {error ? (
        error
      ) : (
        <Fragment>
          <Heading>
            <Icon className="fas fa-users" /> Users without mapped application
            accounts
          </Heading>
          {fetching ? (
            <Spinner />
          ) : (
            <Fragment>
              {tag && tag.checks.length ? (
                <ul>
                  {tag.checks.map(
                    user =>
                      user.checkStatus !== 'Success' && (
                        <User key={user.username}>
                          {user.firstName} {user.lastName} ({user.username})
                        </User>
                      )
                  )}
                </ul>
              ) : (
                'No users found'
              )}
            </Fragment>
          )}
        </Fragment>
      )}
    </Fragment>
  )
}
