import React, { Fragment, useEffect, useState } from 'react'
import styled from 'styled-components'
import Spinner from 'britive-ui-components/core/components/Spinner'
import { preFetch } from 'utils/do_fetch'

const User = styled.li`
  padding: 5px;
  margin-left: -20px;
  list-style: none;

  &:before {
    content: '\f007';
    font-family: 'Font Awesome 5 Free';
    display: inline-block;
    margin-left: -1.5em;
    width: 1.5em;
    font-size: 12px;
    font-weight: 900;
    color: var(--medium-purple);
  }
`

const Heading = styled.h6`
  border-bottom: 1px solid var(--brand);
  padding: 5px 0;
  margin-right: 10px;
`

const Icon = styled.i`
  color: var(--medium-purple);
`

export default ({ papId }) => {
  const [fetching, setFetching] = useState(false)
  const [users, setUsers] = useState(null)
  const [error, setError] = useState(null)
  useEffect(() => {
    const fetchUsers = async () => {
      if (papId) {
        setFetching(true)
        try {
          const response = await preFetch({
            path: `/paps/${papId}/accounts/checks`,
            method: 'get',
          })
          setUsers(response.data.filter(d => d.checkStatus !== 'Success'))
        } catch (error) {
          setError(error.response.data.message)
        } finally {
          setFetching(false)
        }
      }
    }
    fetchUsers()
  }, [papId])
  return (
    <Fragment>
      {error ? (
        error
      ) : (
        <Fragment>
          <Heading style={{ marginTop: 10 }}>
            <Icon className="fas fa-users" /> Users without mapped application
            accounts
          </Heading>
          {fetching || !users ? (
            <Spinner />
          ) : (
            <Fragment>
              {users.length ? (
                <ul>
                  {users.map(user => (
                    <User key={user.username}>
                      {user.firstName} {user.lastName} ({user.username})
                    </User>
                  ))}{' '}
                </ul>
              ) : (
                'No users found'
              )}
            </Fragment>
          )}
        </Fragment>
      )}
    </Fragment>
  )
}
