import Echarts from 'echarts-for-react'
import React from 'react'
import startCase from 'lodash/startCase'

const SummaryPie = ({ permission, colors }) => {
  const getSeries = () => {
    const total = {
      top: 0,
      bottom: 65,
      color: ['#e0e0e0'],
      type: 'pie',
      radius: ['65%', '66%'],
      data: [{ value: permission.total, name: permission.type }],
      animation: false,
      avoidLabelOverlap: true,
      label: {
        show: true,
        position: 'center',
        formatter: '{c}',
        fontSize: 32,
        color: '#313131',
      },
    }

    const highRisk = {
      top: 0,
      bottom: 65,
      type: 'pie',
      radius: ['66%', '80%'],
      data: [
        { value: permission.highRisk, name: 'High Risk' },
        { value: permission.total - permission.highRisk, name: permission.type },
      ],
      animation: false,
      itemStyle: {
        shadowBlur: 0.5,
        shadowOffsetX: 0.3,
        shadowOffsetY: 0.3,
        shadowColor: '#e4e4e4',
      },
      label: {
        show: false,
      },
    }

    const category = {
      top: 0,
      bottom: 65,
      type: 'pie',
      radius: ['86%', '100%'],
      data: [
        { value: permission.custom, name: 'Custom' },
        { value: permission.builtIn, name: 'Built In' },
      ],
      animation: false,
      itemStyle: {
        shadowBlur: 0.5,
        shadowOffsetX: 0.3,
        shadowOffsetY: 0.3,
        shadowColor: '#e4e4e4',
      },
      label: {
        show: false,
      },
    }

    return [total, highRisk, category]
  }

  const getLegends = () => {
    const legends = []
    Object.keys(permission).forEach(p => {
      if (!(p === 'type' || p === 'total' || permission[p] === 0)) {
        legends.unshift({
          name: startCase(p),
          value: permission[p],
        })
      }
    })
    return legends
  }

  return (
    <Echarts
      style={{ height: 200 }}
      option={{
        color: colors,
        legend: {
          x: 'center',
          y: 'bottom',
          selectedMode: false,
          itemHeight: 10,
          itemWidth: 10,
          padding: [5, 10],
          itemGap: 10,
          data: getLegends(),
          formatter: p => {
            const _p = getLegends().filter(l => l.name === p)[0]
            return `${p}: ${_p.value} (${(
              (_p.value / permission.total) *
              100
            ).toFixed(2)}%)`
          },
        },
        tooltip: {
          trigger: 'item',
          formatter: a => {
            if (a.name === permission.type) {
              return `${startCase(a.name)}: ${permission.total}`
            } else {
              return `${a.name} ${startCase(permission.type)}: ${a.value} (${
                a.percent
              }%)`
            }
          },
        },
        series: getSeries(permission),
      }}
    />
  )
}

export default SummaryPie
