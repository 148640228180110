import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import startCase from 'lodash/startCase'
import isEmpty from 'lodash/isEmpty'
import ModalPopup from 'britive-design-system/core/components/modal-popup'
import Button from 'britive-design-system/core/components/button'
import Spinner from 'britive-design-system/core/components/spinner'
import Typography from 'britive-design-system/core/components/typography'
import {
  getPermissionConstraints,
  getPermissionConstraintTypes,
} from 'action_creators/permissions_constraints'
import toast from 'utils/toast'
import {
  addPermissionConstraints,
  flushAddConstraintsState,
  validatePermissionConstraints,
} from 'action_creators/permissions_constraints'
import PapPermissionsConstraintsDrawer from './PapPermissionsConstraintsDrawer'
import Snackbar from 'britive-design-system/core/components/snackbar'
import ConstraintPanelContainer from './ConstraintPanelContainer'

const PermissionsConstraints = ({
  dispatch,
  match,
  permissionConstraintTypes,
  permissionConstraintsAdd,
}) => {
  const { appId, profileId, permissionName, permissionType } = match.params
  const {
    status: addStatus,
    error: addError,
    validationMessage,
  } = permissionConstraintsAdd
  const history = useHistory()
  const accessRequest = window?.location?.pathname?.includes('/request-access')

  const [isOpenModal, setIsOpenModal] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [selectedConstraintType, setSelectedConstraintType] = useState(null)
  const [constraints, setConstraints] = useState([])
  const [constraint, setConstraint] = useState(null)
  const [touchedFields, setTouchedFields] = useState({})
  const [isUpdating, setIsUpdating] = useState(false)

  useEffect(() => {
    dispatch(getPermissionConstraintTypes(profileId, permissionName, permissionType))

    return cancelModal
  }, [])

  const handleFieldTouch = field => {
    setTouchedFields(touchedFields => ({
      ...touchedFields,
      [field]: true,
    }))
  }

  const clearFormFields = () => {
    setConstraint(null)
    setTouchedFields({})
  }

  const isFormValid = () => {
    if (isEmpty(constraint)) {
      return false
    }
    if (selectedConstraintType === 'condition') {
      return constraint.title?.length && constraint.expression?.length
    } else {
      return constraint.name?.length
    }
  }

  const getConstraints = async constraintType => {
    await dispatch(
      getPermissionConstraints(
        profileId,
        permissionName,
        permissionType,
        constraintType
      )
    )
  }

  const cancelModal = () => {
    selectedConstraintType &&
      constraints.length > 0 &&
      getConstraints(selectedConstraintType)
    setIsOpenModal(false)
    setConstraints([])
    clearFormFields()
    dispatch(flushAddConstraintsState())
  }

  const openModal = (constraintType, constraint) => {
    setSelectedConstraintType(constraintType)
    setConstraint(constraint)
    setIsUpdating(!!constraint)
    setIsOpenModal(true)
  }

  const changeHandler = value => {
    setConstraint({
      ...constraint,
      ...value,
    })
  }

  const addHandler = async () => {
    if (isFormValid()) {
      await dispatch(
        addPermissionConstraints(
          profileId,
          permissionName,
          permissionType,
          selectedConstraintType,
          constraint
        )
      )
      setConstraints([...constraints, constraint])
      selectedConstraintType !== 'condition' && clearFormFields()
      toast({
        title: `${
          isUpdating ? 'Updated' : 'Added'
        } permission constraints successfully`,
        type: 'success',
        time: 'normal',
      })
    }
  }

  const validateHandler = async () => {
    if (!constraint?.expression?.length) {
      return
    }
    await dispatch(
      validatePermissionConstraints(
        profileId,
        permissionName,
        permissionType,
        selectedConstraintType,
        { expression: constraint?.expression }
      )
    )
  }

  const requiredModalActionButtons = [
    {
      text: isUpdating ? 'Update' : ' Add',
      variant: 'primary',
      onClick: addHandler,
      size: 'medium',
      disabled: !isFormValid(),
    },
    {
      text: 'Close',
      variant: 'secondary',
      onClick: cancelModal,
      size: 'medium',
    },
  ]

  const drawerProps = {
    constraint,
    constraints,
    onChange: changeHandler,
    selectedConstraintType,
    onValidate: validateHandler,
    validationMessage,
    addStatus,
    addError,
    touchedFields,
    handleFieldTouch,
  }

  return (
    <>
      <div className="page-header-wrapper">
        <Typography variant="heading7">{permissionName}</Typography>
        <Typography variant="pageTitle">Permission Constraints</Typography>
        <div className="page-header-buttons">
          {!isEditMode ? (
            <>
              <Button
                variant={'primary'}
                size={'medium'}
                onClick={() => setIsEditMode(true)}
              >
                {'Edit'}
              </Button>
              <Button
                variant={'secondary'}
                size={'medium'}
                onClick={() => {
                  if (accessRequest) {
                    history.push(
                      `/request-access/applications/${appId}/profiles/${profileId}/details/permissions/create`
                    )
                  } else {
                    history.push(
                      `/admin/applications/${appId}/profiles/${profileId}/details/permissions`
                    )
                  }
                }}
              >
                {'Close'}
              </Button>
            </>
          ) : (
            <Button
              variant={'primary'}
              size={'medium'}
              onClick={() => setIsEditMode(false)}
            >
              {'Done'}
            </Button>
          )}
        </div>
      </div>
      <div className="page-content-wrapper">
        {permissionConstraintTypes.status === 'LOADING' ? (
          <Spinner size={'medium'} message={'Loading...'} overlay />
        ) : (
          <>
            {!permissionConstraintTypes?.result ||
            permissionConstraintTypes?.result.length === 0 ? (
              <Snackbar
                errorList={['Constraints are not supported for this permission.']}
              />
            ) : (
              <ConstraintPanelContainer
                profileId={profileId}
                permissionName={permissionName}
                permissionType={permissionType}
                openModal={openModal}
                permissionConstraintTypes={permissionConstraintTypes.result}
                isEditMode={isEditMode}
                dispatch={dispatch}
              />
            )}
            {isOpenModal && (
              <ModalPopup
                width={612}
                title={`${isUpdating ? 'Update' : 'Add'} ${startCase(
                  selectedConstraintType
                )}`}
                buttons={requiredModalActionButtons}
                onCancel={cancelModal}
              >
                <PapPermissionsConstraintsDrawer {...drawerProps} />
              </ModalPopup>
            )}
          </>
        )}
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  permissionConstraintTypes: state.permissionsConstraints.permissionConstraintTypes,
  permissionConstraintsAdd: state.permissionsConstraints?.permissionConstraintsAdd,
})

export default connect(mapStateToProps)(PermissionsConstraints)
