import { fetchIfLoggedIn } from './utils.js'

export const FETCH_SESSIONS = 'ACTIVITY/FETCH_SESSIONS'
export const FETCH_SESSION_ACTIVITY = 'ACTIVITY/FETCH_SESSION_ACTIVITY'

export const fetchSessions = appId => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: FETCH_SESSIONS,
      method: 'get',
      meta: { appId },
      path: `/apps/${appId}/sessions`,
    })
  )
}

export const fetchSessionActivity = (
  appId,
  transactionId,
  params,
  isInitialFetch
) => dispatch => {
  let path = `/apps/${appId}/sessions/${transactionId}/activities`
  const page = params.page

  Object.keys(params).forEach((param, idx) => {
    path += idx === 0 ? '?' : '&'

    path += `${param}=${params[param]}`
  })

  return dispatch(
    fetchIfLoggedIn({
      actionType: FETCH_SESSION_ACTIVITY,
      method: 'get',
      meta: { transactionId, page, isInitialFetch },
      path,
    })
  )
}
