import isEmpty from 'lodash/isEmpty'
import uniqBy from 'lodash/uniqBy'
import { fetchIfLoggedIn } from '../utils.js'
import * as actions from 'actions'
import store from 'store'
import doFetch, { getURL, preFetch } from 'utils/do_fetch'
import { getIsAuthenticated } from 'utils/authentication'
import { notAuthenticated } from 'action_creators/account/index.js'
import Axios from 'axios'

export const FETCH_USERS_ASSOCIATED_WITH_PAPS = 'FETCH_USERS_ASSOCIATED_WITH_PAPS'
export const FETCH_USER_ATTRIBUTES = 'FETCH_USER_ATTRIBUTES'
export const UPDATE_USER_STATUS = 'UPDATE_USER_STATUS'
export const UPDATE_USER_PASSWORD = 'UPDATE_USER_PASSWORD'
export const DELETE_USER = 'DELETE_USER'
export const RESET_MFA_DEVICE = 'RESET_MFA_DEVICE'
export const DELETE_VERIFICATION_DEVICE = 'DELETE_VERIFICATION_DEVICE'
export const FETCH_CUSTOM_ATTRIBUTES_FOR_USER = 'FETCH_CUSTOM_ATTRIBUTES_FOR_USER'

export const getUsers = type => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.GET_USERS,
      method: 'get',
      path: `/users${type ? `?type=${type}` : ''}`,
      meta: { type },
    })
  )
}

export const getPaginatedUsers = (params = {}, oldSearchTerm) => dispatch => {
  const apiParams = {
    page: params.page,
    size: params.size,
  }

  let shouldAppend = params.page !== 0
  if (!params.searchText && oldSearchTerm?.length && params.page !== 0) {
    apiParams.searchText = oldSearchTerm
  } else if (params.searchText && !oldSearchTerm) {
    apiParams.searchText = params.searchText
    shouldAppend = false
  } else if (params.searchText) {
    apiParams.searchText = params.searchText
    shouldAppend = false
  }

  return dispatch(
    fetchIfLoggedIn({
      actionType: shouldAppend
        ? actions.GET_PAGINATED_USERS_LOAD_MORE
        : actions.GET_PAGINATED_USERS,
      method: 'get',
      path: '/users',
      meta: { searchText: params.searchText },
      params: {
        type: 'User',
        ...apiParams,
      },
    })
  )
}

export const getSelectedUsers = (users = []) => async dispatch => {
  if (isEmpty(users)) {
    return []
  }

  const usersToFetch = []
  uniqBy(users, 'id').forEach(user => {
    if (user?.name !== 'Deleted member') {
      usersToFetch.push(user.id)
    }
  })

  if (usersToFetch.length === 0) {
    return []
  }

  if (usersToFetch.length <= 50) {
    return dispatch(
      fetchIfLoggedIn({
        actionType: actions.GET_SELECTED_USERS,
        method: 'post',
        path: '/users/minimized-user-details',
        meta: { type: 'User' },
        postBody: usersToFetch,
      })
    )
  } else {
    const isLoggedIn = getIsAuthenticated()

    if (isLoggedIn) {
      const requests = []
      while (usersToFetch?.length > 0) {
        requests.push(
          Axios.post(
            `${getURL()}/users/minimized-user-details`,
            usersToFetch.splice(0, 50),
            {
              withCredentials: true,
              xsrfCookieName: 'csrfToken',
            }
          )
        )
      }

      try {
        dispatch({ type: `${actions.GET_SELECTED_USERS}_PENDING` })
        const responses = await Axios.all(requests)
        if (isEmpty(responses)) {
          return []
        }

        const consolidatedData = []
        responses.forEach(response =>
          consolidatedData.push(...(response.data || []))
        )

        return dispatch({
          type: `${actions.GET_SELECTED_USERS}_FULFILLED`,
          payload: {
            data: consolidatedData,
          },
          meta: {
            type: 'User',
          },
        })
      } catch {
        return dispatch({
          type: `${actions.GET_SELECTED_USERS}_REJECTED`,
          payload: 'Unable to fetch users.',
        })
      }
    } else {
      return dispatch(notAuthenticated())
    }
  }
}

export const fetchUserAttributes = filter => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: FETCH_USER_ATTRIBUTES,
      method: 'get',
      path: `/apps/user-attributes${filter ? `?filter=${filter}` : ''}`,
    })
  )
}

export const fetchUsersAssociatedWithPaps = () => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: FETCH_USERS_ASSOCIATED_WITH_PAPS,
      method: 'get',
      path: '/users/pap-associated-users',
    })
  )
}

export const getUser = () => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.GET_USER,
      method: 'get',
      path: '/access/users',
    })
  )
}

export const getUserMFAStatus = factor => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.GET_USER_MFA_STATUS,
      method: 'get',
      path: `/mfa/register/${factor}`,
    })
  )
}

export function getUserPure(response) {
  return {
    type: `${actions.GET_USER}_FULFILLED`,
    payload: response,
  }
}

export const addUser = (fields, type) => dispatch => {
  const postBody = {
    ...fields,
    status: 'active',
  }

  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.ADD_USER,
      meta: { type },
      method: 'post',
      path: `/users`,
      postBody,
    })
  )
}

export const changePassword = newPassword => {
  return {
    type: actions.CHANGE_PASSWORD,
    payload: newPassword,
  }
}

export const updateUser = updatedUser => async dispatch => {
  try {
    const account = store.getState().account
    if (account.fetched) {
      const response = await doFetch({
        method: 'patch',
        xsrfCookieName: 'csrfToken',
        withCredentials: true,
        path: '/self',
        postBody: updatedUser,
      })

      return response.data
        ? dispatch(upDateCurrentUser(updatedUser))
        : { type: actions.UPDATE_USER, payload: {} }
    }
  } catch (e) {
    console.warn('updatePaps error : ', e)
  }
}

export function upDateCurrentUser(user) {
  return {
    type: actions.UPDATE_USER,
    payload: user,
  }
}

export function getUserTenantApps() {
  if (getIsAuthenticated()) {
    const path = `/access/apps`
    const response = preFetch({
      name: 'getUserTenantApps',
      path: path,
      method: 'get',
    })

    return {
      type: actions.USER_TENANT_APPS,
      payload: response,
    }
  } else {
    return {
      type: actions.NOT_AUTHENTICATED,
    }
  }
}

export function getUserAppPaps(appId, envId) {
  if (getIsAuthenticated()) {
    const path = `/access/apps/${appId}/environments/${envId}/paps`
    const response = preFetch({
      name: 'getUserAppPaps',
      path: path,
      method: 'get',
    })

    return {
      type: actions.USER_TENANT_APP_PAPS,
      meta: { appId, envId },
      payload: response,
    }
  } else {
    return {
      type: actions.NOT_AUTHENTICATED,
    }
  }
}

export async function updatePaps(userId, papsList) {
  try {
    const account = store.getState().account
    if (account.fetched) {
      const request = await doFetch({
        method: 'patch',
        path: `/users/${userId}/paps`,
        withCredentials: true,
        postBody: {
          paps: papsList,
        },
      })
      const params = new URLSearchParams()
      params.append('paps', papsList)

      const response = request.patch(null, params)
      return {
        type: actions.UPDATE_PAPS,
        payload: response,
      }
    } else {
      return {
        type: actions.NOT_AUTHENTICATED,
      }
    }
  } catch (e) {
    console.warn('updatePaps error : ', e)
  }
}

export const disableUser = (userId, type) => dispatch =>
  dispatch(updateUserStatus(userId, 'disable', type))

export const enableUser = (userId, type) => dispatch =>
  dispatch(updateUserStatus(userId, 'enable', type))

export const updateUserStatus = (userId, status, type) => dispatch => {
  const urlEnd = status === 'disable' ? 'disabled-statuses' : 'enabled-statuses'

  return dispatch(
    fetchIfLoggedIn({
      actionType: UPDATE_USER_STATUS,
      meta: { userId, type },
      method: 'post',
      path: `/users/${userId}/${urlEnd}`,
    })
  )
}

export const deleteUser = (userId, type) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: DELETE_USER,
      meta: { userId, type },
      method: 'delete',
      path: `/users/${userId}`,
    })
  )
}

export const updateUserPassword = (userId, password) => dispatch => {
  const postBody = {
    password,
  }

  return dispatch(
    fetchIfLoggedIn({
      actionType: UPDATE_USER_PASSWORD,
      meta: { userId },
      method: 'post',
      path: `/users/${userId}/resetpassword`,
      postBody,
    })
  )
}

export const resetMFADevice = ({ userId }) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: RESET_MFA_DEVICE,
      method: 'patch',
      meta: { userId },
      path: `/users/${userId}/resetmfa`,
    })
  )
}

export const deleteVerificationDevice = ({ userId }) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: DELETE_VERIFICATION_DEVICE,
      method: 'delete',
      meta: { userId },
      path: `/mfa/admin/registrations/TOTP/users/${userId}`,
    })
  )
}

export const fetchCustomAttributesForUser = userId => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: FETCH_CUSTOM_ATTRIBUTES_FOR_USER,
      method: 'get',
      path: `/users/${userId}/custom-attributes`,
    })
  )
}

export const addProviderToServiceIdentity = (
  providerId,
  serviceIdentityId,
  tokenDuration,
  mappingAttributes
) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.ADD_PROVIDER_SERVICE_IDENTITY,
      method: 'post',
      path: `/workload/users/${serviceIdentityId}/identity-provider`,
      postBody: {
        idpId: providerId,
        tokenDuration: Number(tokenDuration),
        mappingAttributes,
      },
    })
  )
}
