import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import isEmpty from 'lodash/isEmpty'
import Pill from 'britive-design-system/core/components/pill'
import Typography from 'britive-design-system/core/components/typography'
import Tooltip from 'britive-design-system/core/components/tooltip'
import ModalPopup from 'britive-design-system/core/components/modal-popup'
import plusIcon from 'static/images/plus-blue.svg'
import './index.scss'

const CSS_CLASSNAME_PREFIX = 'pill-list-table-column'

const PillListTableColumn = ({
  data = [], // Array<String>
  maxWidth = 300,
  onViewAllClick, // Optional. If this is passed, the modal in this component will not be rendered.
  tooltipMsg = 'View all',
  modalTitle = 'View All',
}) => {
  const [pillWidths, setPillWidths] = useState([])
  const [showMore, setShowMore] = useState(false)
  const uniqueIdPrefix = `${data.join('-')}-${new Date().toISOString()}`

  useEffect(() => {
    if (isEmpty(data)) {
      setPillWidths([])
      return
    }
    // Temporary rendering the tags and saving the width of each in local state.
    const widthPromises = data.map((pill, index) => {
      return new Promise(resolve => {
        const containerId = `pill-list-temporary-container-${uniqueIdPrefix}-${index}-${pill}`
        const temporaryContainer = document.createElement('div')
        temporaryContainer.setAttribute('id', containerId)

        // Added styles to hide the DOM from the view
        temporaryContainer.style.visibility = 'hidden'
        temporaryContainer.style.position = 'absolute'
        temporaryContainer.style.width = 'auto'
        temporaryContainer.style.whiteSpace = 'nowrap'

        document.body.appendChild(temporaryContainer)
        ReactDOM.render(
          <Pill
            key={`${uniqueIdPrefix}-${index}-${pill}-temporary`}
            disabled
            readOnly
            label={pill}
          />,
          document.getElementById(containerId)
        )

        setTimeout(() => {
          const width = temporaryContainer.offsetWidth
          ReactDOM.unmountComponentAtNode(document.getElementById(containerId))

          resolve({
            pillName: pill,
            width: width,
          })
        }, 0)
      })
    })

    // Waiting for all width measurements to complete
    Promise.all(widthPromises).then(results => {
      setPillWidths(results)
    })
  }, [JSON.stringify(data)])

  const closeModal = () => {
    setShowMore(false)
  }

  const handleViewAll = () => {
    if (typeof onViewAllClick === 'function') {
      onViewAllClick()
      return
    }

    setShowMore(true)
  }

  // Not using the local state to prevent rerender, and prevent batch updates
  let currentWidth = 0
  let renderedPills = 0

  /**
   * 1. Render the pills if it is below the max width.
   * 2. If atleast one pill was rendered, show the remaining count as a view more button.
   * 3. If no pill was rendered due to width issues, show "View All" as a clickable item.
   */
  return (
    <div className={`${CSS_CLASSNAME_PREFIX}-wrapper`}>
      {pillWidths.map((pill, index) => {
        currentWidth += pill.width

        if (currentWidth < maxWidth) {
          renderedPills += 1
          return (
            <Pill
              key={`${uniqueIdPrefix}-${index}-${pill}`}
              disabled
              readOnly
              label={pill.pillName}
            />
          )
        }
      })}
      {currentWidth > maxWidth && renderedPills !== 0 && (
        <Tooltip title={tooltipMsg} position="right">
          <div
            className={`${CSS_CLASSNAME_PREFIX}-show-more`}
            onClick={handleViewAll}
          >
            <img src={plusIcon} />
            <Typography variant="label1">{data.length - renderedPills}</Typography>
          </div>
        </Tooltip>
      )}
      {!isEmpty(data) && renderedPills === 0 && (
        <Tooltip title={tooltipMsg} position="right">
          <div
            className={`${CSS_CLASSNAME_PREFIX}-show-more`}
            onClick={handleViewAll}
          >
            <Typography variant="label1">View All</Typography>
          </div>
        </Tooltip>
      )}
      {showMore && (
        <ModalPopup
          width={600}
          title={modalTitle}
          buttons={[
            {
              text: 'Close',
              variant: 'secondary',
              size: 'large',
              onClick: closeModal,
            },
          ]}
          onCancel={closeModal}
        >
          <div className={`${CSS_CLASSNAME_PREFIX}-modal-wrapper`}>
            {data.map((pill, index) => (
              <Pill
                key={`${uniqueIdPrefix}-${index}-${pill}-view-all-modal`}
                disabled
                readOnly
                label={pill}
              />
            ))}
          </div>
        </ModalPopup>
      )}
    </div>
  )
}

export default PillListTableColumn
