import React from 'react'
import { BackgroundTooltip as Tooltip } from 'components/Tooltips'
import uuid from 'uuid'

const styles = {
  cell: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}

export function TableEllipsisCellWithTooltip({
  elementType,
  children,
  value,
  style,
  ...rest
}) {
  const ElementType = elementType || 'div'
  const id = uuid()

  return (
    <ElementType
      {...rest}
      style={{ ...styles.cell, ...style }}
      data-tip
      data-for={id}
    >
      {value || children || 'n/a'}

      <Tooltip id={id} placement="left">
        {value || children || 'n/a'}
      </Tooltip>
    </ElementType>
  )
}

export default function TableEllipsisCell({
  elementType,
  value,
  style,
  children,
  innerRef,
  ...rest
}) {
  const ElementType = elementType || 'div'

  return (
    <ElementType
      {...rest}
      ref={innerRef}
      style={{ ...styles.cell, ...style }}
      title={value}
    >
      {value === undefined ? children : value !== null ? value : ''}
    </ElementType>
  )
}
