const user = rawUser => {
  const { paps, email } = rawUser
  const firstName = rawUser.firstName ? rawUser.firstName : ''
  const lastName = rawUser.lastName ? rawUser.lastName : ''
  const assignedPapIdList = paps ? paps.map(pap => pap.papId) : []
  return {
    assignedPapIdList,
    userDisplayName: `${firstName} ${lastName} (${email})`,
    ...rawUser,
  }
}

export default user
