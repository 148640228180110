import Cookies from 'js-cookie'
import { logout } from 'action_creators/account'

export default async function(dispatch, history) {
  const isSSOSession = Cookies.get('signInMethod') === 'sso'
  const ssoSignOut = sessionStorage.getItem('ssoSignOut')

  if (isSSOSession) {
    await dispatch(logout('sso'))
    window.location.assign(ssoSignOut)
  } else {
    await dispatch(logout())
    history.push('/login')
  }
}
