import React from 'react'
import Typography from 'britive-design-system/core/components/typography'
import './index.scss'

const classNamePrefix = 'policy-definition'

export const PermissionDefinition = ({ permission }) => {
  const renderDescription = () => (
    <>
      <br />
      <div className={`${classNamePrefix}-basic-data-datum`}>
        <Typography variant="label2">Description</Typography>
        <Typography variant="label1">{permission.description}</Typography>
      </div>
    </>
  )

  const renderDefinition = () => {
    if (permission?.permissionDefinition) {
      return (
        <div className={`${classNamePrefix}-json`}>
          <pre id="json">
            {JSON.stringify(permission.permissionDefinition, null, 2)}
          </pre>
        </div>
      )
    }

    return <Typography variant="label1">No associated permission found.</Typography>
  }

  return (
    <>
      <div className={`${classNamePrefix}-basic-data`}>
        <div className={`${classNamePrefix}-basic-data-datum`}>
          <Typography variant="label2">High Risk</Typography>
          <Typography variant="label1">
            {permission.isPrivileged ? 'Yes' : 'No'}
          </Typography>
        </div>
        <div className={`${classNamePrefix}-basic-data-datum`}>
          <Typography variant="label2">Type</Typography>
          <Typography variant="label1">{permission.type}</Typography>
        </div>
      </div>

      {permission.description && renderDescription()}

      <br />
      {renderDefinition()}
    </>
  )
}
