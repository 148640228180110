import { pluralize } from 'utils/pluralize'
import moment from 'moment'
import get from 'lodash/get'

export const getLang = () => {
  if (navigator?.languages) {
    return navigator.languages[0]
  }

  // Added to support old browsers.
  return navigator.language
}

export const getLocalDateTime = dateStr => {
  const date = new Date(dateStr)
  const formattedDate = date.toLocaleString(getLang(), {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  })

  return formattedDate?.toLowerCase().replace(',', '')
}

export const parseDate = dateTime => {
  if (dateTime instanceof Date) {
    return dateTime
  } else if (dateTime) {
    return new Date(dateTime)
  }

  return undefined
}

// Returns date in #/#/#### format
export const formatDate = (dateTime, dateOptions, timeOptions, showTime = true) => {
  const date = parseDate(dateTime)

  if (date) {
    const datePortion = date.toLocaleDateString('en-US', { ...dateOptions })
    const timePortion = date.toLocaleTimeString('en-US', { ...timeOptions })

    return `${datePortion}${showTime ? ', ' + timePortion : ''}`
  }

  return ''
}

// Returns date in January 01 format
export const formatDateLong = dateTime => {
  return formatDate(
    dateTime,
    {
      day: 'numeric',
      month: 'long',
      timeZone: 'UTC',
    },
    {}
  )
}

// Returns date in format: January 01, 2019
export const formatDateLongWithYear = ({ dateTime, timeZone }) => {
  const baseOption = {
    timeZone,
  }

  const dateOptions = {
    ...baseOption,
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  }

  const timeOptions = {
    ...baseOption,
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZoneName: 'short',
    hourCycle: 'h23',
  }

  return formatDate(dateTime, dateOptions, timeOptions)
}
// Returns date in format: January 01, 2019
export const formatDateLongWithoutTime = ({ dateTime, timeZone }) => {
  const baseOption = {
    timeZone,
  }

  const dateOptions = {
    ...baseOption,
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  }

  return formatDate(dateTime, dateOptions, {}, false)
}

export const formatDateShortWithYear = ({
  dateTime,
  timeZone,
  showTimeZone = true,
  hourCycle = 'h23',
}) => {
  const baseOption = {
    timeZone,
  }

  const dateOptions = {
    ...baseOption,
    dateStyle: 'short',
  }

  const timeOptions = {
    ...baseOption,
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: showTimeZone ? 'short' : undefined,
    hourCycle,
  }

  return formatDate(dateTime, dateOptions, timeOptions)
}

export const formatDateWithTime = dateTime => {
  const date = parseDate(dateTime)

  return date ? `${date.toLocaleDateString('en-US')} ${formatTime(dateTime)}` : ''
}

// Returns time in #:## AM/PM format
export const formatTime = dateTime => {
  const date = parseDate(dateTime)

  return date
    ? date.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hourCycle: 'h23',
      })
    : ''
}

export const secondsToHHMM = (seconds = 0) => {
  const h = Math.floor(seconds / 60)
  const m = seconds % 60

  return `${h}h ${m}m`
}

/*
 * @param {dateTimeish string} dateTime the date to work with
 * @param {Int} numberOfDays number of days to add
 * @returns dateTime instance of Date
 */
export const addDays = (dateTime, numberOfDays = 0) => {
  const date = parseDate(dateTime)
  const newdate = new Date(date)

  return newdate.setDate(newdate.getDate() + parseInt(numberOfDays, 10))
}

/**
 * Compares now to a time in the future
 * Will return H, M format until there is less than 1 minute left
 * then it will return number of seconds
 *
 * @param {dateTime} timeTo
 * @returns string
 */
export const formattedFromNow = timeTo => {
  const now = moment()
  const expiration = moment(timeTo)

  const diff = expiration.diff(now)
  const diffDuration = moment.duration(diff)
  let unformattedDays = diffDuration.days()
  let unformattedHours = diffDuration.hours()
  let unformattedMinutes = diffDuration.minutes()
  const daysRemaining = pluralize(unformattedDays, 'day')
  const hoursRemaining = pluralize(unformattedHours, 'hour')
  const minsRemaining = pluralize(unformattedMinutes, 'minute')

  const finalTimeArray = []
  if (unformattedDays > 0) finalTimeArray.push(daysRemaining)
  if (unformattedHours > 0) finalTimeArray.push(hoursRemaining)
  if (unformattedMinutes > 0) finalTimeArray.push(minsRemaining)

  return finalTimeArray.join(', ')
}
// TODO change format here
/**
 * Compares now to a time in the future
 * Will return H, M format until there is less than 1 minute left
 * then it will return number of seconds
 *
 * @param {dateTime} timeTo
 * @returns string
 */
export const formattedFromNowShort = (timeTo, timeFrom = Date.now()) => {
  const now = moment(timeFrom)
  const expiration = moment(timeTo)
  const diff = expiration.diff(now)

  const diffDuration = moment.duration(diff)
  let unformattedDays = diffDuration.days()
  let unformattedHours = diffDuration.hours()
  let unformattedMinutes = diffDuration.minutes()

  const result = []
  result.push(unformattedDays > 0 ? `${unformattedDays}d` : '')
  result.push(unformattedHours > 0 ? `${unformattedHours}h` : '')
  result.push(unformattedMinutes > 0 ? `${unformattedMinutes}m` : '')

  return result.join('') !== '' ? result.join('') : '<1m'
}

export const formatMilliseconds = time => {
  const msDuration = moment.duration(time)
  let timeArray = []
  if (msDuration.hours() > 0) {
    timeArray.push(pluralize(msDuration.hours(), 'hour'))
  }
  if (msDuration.minutes() > 0) {
    timeArray.push(pluralize(msDuration.minutes(), 'minute'))
  }
  if (msDuration.seconds() > 0) {
    timeArray.push(pluralize(msDuration.seconds(), 'second'))
  }

  return timeArray.join(', ')
}

export const parseSeconds = timeTo => {
  const now = moment()
  const expiration = moment(timeTo)
  const diff = expiration.diff(now)
  const diffDuration = moment.duration(diff)
  const diffSeconds = diffDuration.asSeconds()
  return parseInt(diffSeconds, 10) > 0 ? diffSeconds : 0
}

const dateFormat = 'MMMM DD, YYYY hh:mm A'

export const convertTimeToCurrentTimezone = UTCString =>
  moment(UTCString).format(dateFormat)

export const getApprovalDuration = approversDetails => {
  // extracting the data in the following way because the API weirdly
  // returns the number and the boolean values as a string, in an array.
  const approvalValidity = get(approversDetails, 'approvalValidFor[0]')
  const isApprovalInDays =
    get(approversDetails, 'approvalValidForInDays[0]') === 'true'

  if (approvalValidity && !isNaN(parseInt(approvalValidity))) {
    if (isApprovalInDays) {
      return `${approvalValidity} days`
    }

    // converting minutes to milliseconds for the formatMilliseconds function.
    return formatMilliseconds(parseInt(approvalValidity) * 60000)
  }

  return null
}

export const getSecondsFromNowUtc = timeTo => {
  const now = moment.utc()
  const expiration = moment(timeTo)
  return expiration.diff(now, 'seconds')
}

// Returns Duration in DD:HH:MM format
export const getTimeDurationFromSeconds = (seconds, includeDays = true) => {
  if (isNaN(seconds)) {
    return ''
  }
  seconds = parseInt(seconds, 10)

  let days = Math.floor(seconds / (24 * 60 * 60))
  if (includeDays) {
    seconds -= days * 24 * 60 * 60
  }

  let hours = Math.floor(seconds / (60 * 60))
  seconds -= hours * 60 * 60
  let minutes = Math.floor(seconds / 60)
  seconds -= minutes * 60

  if (days < 10) {
    days = `0${days}`
  }
  if (minutes < 10) {
    minutes = `0${minutes}`
  }
  if (hours < 10) {
    hours = `0${hours}`
  }

  return `${includeDays ? `${days}:` : ''}${hours}:${minutes}`
}

// Returns Seconds from Duration in DD:HH:MM format
export const getSecondsFromTimeDuration = (timeDuration, includeDays = true) => {
  const invalidFormatError = `Invalid format! Please enter the value in ${
    includeDays ? 'DD:' : ''
  }HH:MM format.`
  try {
    if (!includeDays) {
      timeDuration = `00:${timeDuration}`
    }

    if (timeDuration.length != 8) {
      throw new Error(invalidFormatError)
    }

    timeDuration = timeDuration.split(':')

    if (timeDuration.length != 3) {
      throw new Error(invalidFormatError)
    }

    if (timeDuration[1] > 23) {
      throw new Error('Hours should be less than 24!')
    }

    if (timeDuration[2] > 59) {
      throw new Error('Minutes should be less than 60!')
    }

    const seconds =
      timeDuration[0] * 24 * 60 * 60 +
      timeDuration[1] * 60 * 60 +
      timeDuration[2] * 60

    if (isNaN(seconds)) {
      throw new Error(invalidFormatError)
    }
    return seconds
  } catch (error) {
    throw new Error(error.message)
  }
}

export const getExpirationDateFromGivenDate = toDate => {
  const date = new Date(toDate)
  date.setDate(date.getDate() + 30)
  return formatDate(date, {}, {}, false)
}
