import get from 'lodash/get'

export const handleCLIRedirect = (response, history) => {
  const fromCli = sessionStorage.getItem('cli')
  if (fromCli && get(response, 'data.authenticationResult.success')) {
    history.push('/cli')
    return false
  }
  return true
}
