import React, { Fragment, useEffect, useRef, useState, memo } from 'react'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import get from 'lodash/get'

import { BackgroundTooltip as Tooltip } from 'components/Tooltips'

import { expandNavigation } from 'action_creators/navigation'

import { preFetch } from 'utils/do_fetch'
import { FEATURE_FLAGS } from 'reducers/feature_flag'
import { MENU_PRIVILEGES } from 'reducers/privileges'
import { getFeatureFlagById } from 'selectors/feature_flag'
import ApprovalIcon from 'static/images/approval.png'
import RequestIcon from 'static/images/request.png'
import ApprovalSelectedIcon from 'static/images/approval-selected.png'
import RequestSelectedIcon from 'static/images/request-selected.png'
import BuildAccessActive from 'static/images/build-access-active.png'
import BuildAccess from 'static/images/build-access.png'
import MyResourcesIcon from 'static/images/my-resources.svg'
import MyResourcesActiveIcon from 'static/images/my-resources-active.svg'

import './index.css'

const styles = {
  versionIconClosed: {
    color: '#6f6f6f',
    fontSize: 24,
    cursor: 'pointer',
  },
  versionIconOpen: {
    color: '#6f6f6f',
    fontSize: 24,
  },
  versionIconWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  version: {
    fontSize: 12,
    paddingRight: 16,
  },
  activeLink: {
    borderRight: '2px solid var(--brand)',
  },
}

function Navigation({
  activePath,
  openNavigation,
  dispatch,
  adaEnabled,
  secretManagerEnabled,
  serverAccessEnabled,
  showAdmin,
  queryEngineView,
  riskAnalyticsView,
  featureFlags,
}) {
  const [version, setVersion] = useState('')
  const navEl = useRef()

  useEffect(() => {
    async function fetchVersion() {
      try {
        const response = await preFetch({
          name: 'fetchVersion',
          path: '/version',
          method: 'get',
        })

        setVersion(get(response, 'data.pabMonoVersion', 'n/a'))
      } catch (e) {
        setVersion(get(e, 'response.data', 'n/a'))
      }
    }

    fetchVersion()
  }, [])

  useEffect(() => {
    if (openNavigation) {
      document.addEventListener('click', handleOutsideClick)

      return () => {
        document.removeEventListener('click', handleOutsideClick)
      }
    }
  }, [openNavigation])

  function handleOutsideClick(event) {
    const isClickInNav = navEl.current.contains(event.target)

    if (!isClickInNav) {
      closeNavigation()
    }
  }

  function closeNavigation() {
    dispatch(expandNavigation(!openNavigation))
  }

  return (
    <div
      ref={navEl}
      className={
        openNavigation
          ? 'expanded-navigation expanded'
          : 'expanded-navigation collapsed'
      }
    >
      <div className="nav-section">
        <ul className="nav nav-collapse">
          <li id="navigationMyAccessLink" className="menu-item">
            <NavLink
              to="/my-access"
              activeStyle={styles.activeLink}
              isActive={() => activePath.includes('/my-access')}
              onClick={closeNavigation}
            >
              <span>
                <i
                  className="fas fa-inbox"
                  style={
                    activePath.includes('/my-access')
                      ? { color: 'var(--brand)' }
                      : { color: '#6f6f6f' }
                  }
                />
              </span>
              <span>My Access</span>
            </NavLink>
          </li>
          <Fragment>
            {secretManagerEnabled && (
              <li id="navigationMySecretsLink" className="menu-item">
                <NavLink
                  to="/my-secrets"
                  activeStyle={styles.activeLink}
                  isActive={() => activePath.includes('/my-secrets')}
                  onClick={closeNavigation}
                >
                  <span>
                    <i
                      className="fas fa-key"
                      style={
                        activePath.includes('/my-secrets')
                          ? { color: 'var(--brand)' }
                          : { color: '#6f6f6f' }
                      }
                    />
                  </span>
                  <span>My Secrets</span>
                </NavLink>
              </li>
            )}
            <li id="navigationMyRequestsLink" className="menu-item">
              <NavLink
                to="/my-requests"
                activeStyle={styles.activeLink}
                isActive={() => activePath.includes('/my-requests')}
                onClick={closeNavigation}
              >
                <span>
                  <img
                    src={
                      activePath.includes('/my-requests')
                        ? RequestSelectedIcon
                        : RequestIcon
                    }
                    alt="my requests"
                  />
                </span>
                <span>My Requests</span>
              </NavLink>
            </li>
            <li id="navigationMyApprovalsLink" className="menu-item">
              <NavLink
                to="/my-approvals"
                activeStyle={styles.activeLink}
                isActive={() => activePath.includes('/my-approvals')}
                onClick={closeNavigation}
              >
                <span>
                  <img
                    src={
                      activePath.includes('/my-approvals')
                        ? ApprovalSelectedIcon
                        : ApprovalIcon
                    }
                    alt="my approvals"
                  />
                </span>
                <span>My Approvals</span>
              </NavLink>
            </li>
            {serverAccessEnabled && (
              <li id="navigationMyResourcesLink" className="menu-item">
                <NavLink
                  to="/my-resources"
                  activeStyle={styles.activeLink}
                  isActive={() => activePath.includes('/my-resources')}
                  onClick={closeNavigation}
                >
                  <span>
                    <img
                      src={
                        activePath.includes('/my-resources')
                          ? MyResourcesActiveIcon
                          : MyResourcesIcon
                      }
                      alt="my resources"
                    />
                  </span>
                  <span>My Resources</span>
                </NavLink>
              </li>
            )}
            {featureFlags?.accessRequest && (
              <li id="navigationAccessBuilderLink" className="menu-item">
                <NavLink
                  to="/request-access"
                  activeStyle={styles.activeLink}
                  isActive={() => activePath.includes('/request-access')}
                  onClick={closeNavigation}
                >
                  <span>
                    <img
                      src={
                        activePath.includes('/request-access')
                          ? BuildAccessActive
                          : BuildAccess
                      }
                      alt="access builder"
                    />
                  </span>
                  <span>Access Builder</span>
                </NavLink>
              </li>
            )}
            {showAdmin && (
              <li id="navigationAdminLink" className="menu-item">
                <NavLink
                  to="/admin"
                  activeStyle={styles.activeLink}
                  isActive={() => activePath.includes('/admin')}
                  onClick={closeNavigation}
                >
                  <span>
                    <i
                      style={
                        activePath.includes('/admin')
                          ? { color: 'var(--brand)' }
                          : { color: '#6f6f6f' }
                      }
                      className="fa fa-cog"
                    />
                  </span>
                  <span>Admin</span>
                </NavLink>
              </li>
            )}
            {adaEnabled && (
              <Fragment>
                {queryEngineView && (
                  <li id="navigationQueryEngine" className="menu-item">
                    <NavLink
                      to="/query-engine"
                      activeStyle={styles.activeLink}
                      isActive={() => activePath.includes('/query-engine')}
                      onClick={closeNavigation}
                    >
                      <span>
                        <i
                          style={
                            activePath.includes('/query-engine')
                              ? { color: 'var(--brand)' }
                              : { color: '#6f6f6f' }
                          }
                          className="fa fa-database"
                        />
                      </span>
                      <span>Query Engine</span>
                    </NavLink>
                  </li>
                )}
                {riskAnalyticsView && (
                  <li id="navigationAnalytics" className="menu-item">
                    <NavLink
                      to="/analytics"
                      activeStyle={styles.activeLink}
                      isActive={() => activePath.includes('/analytics')}
                      onClick={closeNavigation}
                    >
                      <span>
                        <i
                          style={
                            activePath.includes('/analytics')
                              ? { color: 'var(--brand)' }
                              : { color: '#6f6f6f' }
                          }
                          className="fas fa-chart-bar"
                        />
                      </span>
                      <span>Risk Analytics</span>
                    </NavLink>
                  </li>
                )}
              </Fragment>
            )}
          </Fragment>
        </ul>
      </div>

      <div className="nav-section">
        <ul className="nav nav-collapse">
          <Tooltip place="right" effect="solid" id="versionNumber">
            {version}
          </Tooltip>
          <li className="menu-item version">
            <span>
              <i data-for="versionNumber" data-tip className="fa fa-box-open" />
            </span>
            <span>{version}</span>
          </li>
        </ul>
      </div>
    </div>
  )
}

Navigation.propTypes = {
  activePath: PropTypes.string,
  openNavigation: PropTypes.bool,
}

const mapStateToProps = state => {
  const { privileges } = state.privileges
  return {
    activePath: state.router.location.pathname,
    openNavigation: state.navigation.openNavigation,
    adaEnabled: getFeatureFlagById({
      id: FEATURE_FLAGS.ada,
      state,
    }),
    secretManagerEnabled: getFeatureFlagById({
      id: FEATURE_FLAGS.secretmanager,
      state,
    }),
    serverAccessEnabled: getFeatureFlagById({
      id: FEATURE_FLAGS.serverAccess,
      state,
    }),
    showAdmin: state.privileges.showAdmin,
    queryEngineView: privileges.includes(MENU_PRIVILEGES.queryEngineView),
    riskAnalyticsView: privileges.includes(MENU_PRIVILEGES.riskAnalyticsView),
    featureFlags: state.features.featureFlags,
  }
}

export default connect(mapStateToProps)(memo(Navigation))
