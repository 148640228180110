import { fetchIfLoggedIn } from './utils.js'

export const FETCH_INITIAL_EVENTS = 'FETCH_INITIAL_EVENTS'
export const FETCH_EVENT_FIELDS = 'FETCH_EVENT_FIELDS'
export const FETCH_FILTER_OPERATORS = 'FETCH_FILTER_OPERATORS'
export const FETCH_MORE_EVENTS = 'FETCH_MORE_EVENTS'
export const FETCH_AUDIT_LOG_CSV = 'FETCH_AUDIT_LOG_CSV'

const LOGS_ENDPOINT = process.env.REACT_APP_API_LOGS_ENDPOINT

export const fetchEventFields = () => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: FETCH_EVENT_FIELDS,
      method: 'get',
      path: `${LOGS_ENDPOINT}/fields`,
    })
  )
}

export const fetchFilterOperators = () => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: FETCH_FILTER_OPERATORS,
      method: 'get',
      path: `${LOGS_ENDPOINT}/operators`,
    })
  )
}

export const fetchInitialEvents = (fields, advancedFilter) => async dispatch => {
  let queryParams = ''

  if (fields.to) {
    const utcTo = fields.to.toISOString()
    queryParams += `?to=${utcTo}`
  }

  if (fields.from) {
    const utcFrom = fields.from.toISOString()
    queryParams += `&from=${utcFrom}`
  }

  if (fields.size) {
    queryParams += `&size=${fields.size}`
  }

  if (fields.searchText) {
    queryParams += `&search=${fields.searchText}`
  }

  if (advancedFilter.length) {
    const filterString = advancedFilter
      .map(({ field, operator, filterValue }) => {
        return `${field}+${operator}+%22${filterValue.toLowerCase()}%22`
      })
      .join('+and+')

    queryParams += `&filter=${filterString}`
  }

  return dispatch(
    fetchIfLoggedIn({
      actionType: FETCH_INITIAL_EVENTS,
      method: 'get',
      // TODO: need to change this tenant thing
      path: `${LOGS_ENDPOINT}${queryParams}`,
    })
  )
}

export const fetchMoreEvents = (fields, advancedFilter, page) => async dispatch => {
  // TODO: put this filter build up into single function
  let queryParams = ''

  if (fields.to) {
    const utcTo = fields.to.toISOString()
    queryParams += `?to=${utcTo}`
  }

  if (fields.from) {
    const utcFrom = fields.from.toISOString()
    queryParams += `&from=${utcFrom}`
  }

  if (fields.size) {
    queryParams += `&size=${fields.size}`
  }

  if (fields.searchText) {
    queryParams += `&search=${fields.searchText}`
  }

  if (advancedFilter.length) {
    const filterString = advancedFilter
      .map(({ field, operator, filterValue }) => {
        return `${field}+${operator}+%22${filterValue.toLowerCase()}%22`
      })
      .join('+and+')

    queryParams += `&filter=${filterString}`
  }

  if (page) {
    queryParams += `&page=${page}`
  }

  return dispatch(
    fetchIfLoggedIn({
      actionType: FETCH_MORE_EVENTS,
      method: 'get',
      meta: { page },
      // TODO: need to change this tenant thing
      path: `${LOGS_ENDPOINT}${queryParams}`,
    })
  )
}

export const fetchAuditLogCSV = ({ fields, advancedFilter }) => async dispatch => {
  // TODO: put this filter build up into single function
  let path = `${LOGS_ENDPOINT}/csv`
  const queryParamsArray = []

  if (fields.to) {
    const utcTo = fields.to.toISOString()
    queryParamsArray.push(`to=${utcTo}`)
  }

  if (fields.from) {
    const utcFrom = fields.from.toISOString()
    queryParamsArray.push(`from=${utcFrom}`)
  }

  if (fields.searchText) {
    queryParamsArray.push(`search=${fields.searchText}`)
  }

  if (advancedFilter.length) {
    const filterString = advancedFilter
      .map(({ field, operator, filterValue }) => {
        return `${field}+${operator}+%22${filterValue.toLowerCase()}%22`
      })
      .join('+and+')

    queryParamsArray.push(`filter=${filterString}`)
  }

  if (queryParamsArray.length) {
    const queryParams = queryParamsArray.join('&')
    path = `${path}?${queryParams}`
  }

  return dispatch(
    fetchIfLoggedIn({
      actionType: FETCH_AUDIT_LOG_CSV,
      method: 'get',
      path,
    })
  )
}
