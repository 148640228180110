import React, { memo } from 'react'
import { connect } from 'react-redux'
import { withFormik } from 'formik'
import moment from 'moment-timezone'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import ScanDrawer from './ScanDrawer'

import { updateMessageModal } from 'action_creators/message_modal'

import { getSelectedApplicationRootGroupId } from 'selectors/environment'

import * as api from 'services/api'

import getNumberWithOrdinal from 'utils/getOrdinal'
import { convertUTCDataToLocalData } from '../utils'

export const HOURLY = 'Hourly'
export const DAILY = 'Daily'
export const WEEKLY = 'Weekly'
export const MONTHLY = 'Monthly'

const INTERVAL_OPTIONS = [HOURLY, DAILY, WEEKLY, MONTHLY]

export const HOURS = Array.from({ length: 23 }).map((_, idx) => ({
  value: idx + 1,
  label: idx + 1,
}))

export const DAYS = Array.from({ length: 31 }).map((_, idx) => ({
  value: idx + 1,
  label: getNumberWithOrdinal(idx + 1),
}))

export const DAYS_OF_THE_WEEK = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
].map((day, idx) => ({ value: idx ? idx : 7, label: day }))

const DEFAULT_TIME = '12:00'

function ScanDrawerContainer({
  app,
  fields,
  handleChange,
  handleSubmit,
  isOpen,
  isSubmitting,
  resetForm,
  selectedScanData,
  toggleDrawer,
  touched,
  values,
  errors,
  rootGroupId,
}) {
  function setName(event) {
    handleChange({
      target: {
        name: 'name',
        value: event.target.value,
      },
    })
  }

  function selectInterval(option) {
    handleChange({
      target: {
        name: 'frequencyInterval',
        value: option,
      },
    })
  }

  function selectTime(option) {
    handleChange({
      target: {
        name: 'startTime',
        value: option || DEFAULT_TIME,
      },
    })
  }

  function selectTimeFromInput(event) {
    // TODO: Probably not the best way to do this
    const value = get(event, 'target.value', DEFAULT_TIME)
    if (value) {
      let newDate = new Date(moment(value, 'HH:mm').format())

      if (!(newDate instanceof Date) || isNaN(newDate)) {
        return
      }

      handleChange({ target: { name: 'startTime', value: newDate } })
    }
  }

  function setOrgScan(event) {
    handleChange({
      target: {
        name: 'properties.orgScan',
        value: event.target.checked,
      },
    })
  }

  function updateScope(entity) {
    const newScope = { ...values.properties.scope }

    if (newScope[entity.entityId]) {
      delete newScope[entity.entityId]
    } else {
      const formattedEntity = {
        type: entity.entityType,
        value: entity.entityId,
      }
      newScope[entity.entityId] = formattedEntity
    }

    handleChange({
      target: {
        name: 'properties.scope',
        value: newScope,
      },
    })
  }

  function selectFrequencyType(event) {
    handleChange(event)

    handleChange({
      target: {
        name: 'frequencyInterval',
        value: null,
      },
    })
  }

  function onCancel() {
    resetForm()
    toggleDrawer()
  }

  const momentStartTime = moment(values.startTime, 'HH:mm')
  const timeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZone
  const timeZoneCode = momentStartTime.tz(timeZoneName).format('z')
  return (
    <ScanDrawer
      app={app}
      hourOptions={HOURS}
      dayOptions={DAYS}
      daysOfWeekOptions={DAYS_OF_THE_WEEK}
      errors={errors}
      fields={fields}
      frequencyInterval={values.frequencyInterval}
      frequencyType={values.frequencyType}
      onSubmit={handleSubmit}
      intervalTypes={INTERVAL_OPTIONS}
      isSubmitting={isSubmitting}
      isOpen={isOpen}
      isNewScan={!selectedScanData}
      onCancel={onCancel}
      setName={setName}
      selectFrequencyType={selectFrequencyType}
      selectInterval={selectInterval}
      selectTime={selectTime}
      selectTimeFromInput={selectTimeFromInput}
      startTime={values.startTime}
      setOrgScan={setOrgScan}
      timeZoneCode={timeZoneCode}
      touched={touched}
      toggleDrawer={toggleDrawer}
      updateScope={updateScope}
      values={values}
      rootGroupId={rootGroupId}
    />
  )
}

const enhancedForm = withFormik({
  mapPropsToValues: ({ app, selectedScanData }) => {
    const isHierarchicalApp = get(
      app,
      'catalogApplication.requiresHierarchicalModel'
    )
    const supportsScanning = get(
      app,
      'catalogApplication.supportsEnvironmentScanning'
    )

    if (selectedScanData) {
      const convertedData = convertUTCDataToLocalData(selectedScanData)

      const { name, startTime, frequencyType, frequencyInterval } = convertedData

      let properties = { ...convertedData.properties }

      properties.scope = properties.scope.reduce((acc, val) => {
        acc[val.value] = val

        return acc
      }, {})

      return {
        name,
        startTime,
        frequencyType,
        frequencyInterval,
        properties,
      }
    }

    return {
      name: '',
      startTime: DEFAULT_TIME,
      frequencyType: HOURLY,
      frequencyInterval: null,
      properties: {
        appId: app.appContainerId,
        scope: {},
        ...(!isHierarchicalApp && supportsScanning && { orgScan: true }),
      },
    }
  },

  validate: (values, props) => {
    const { app } = props
    const errors = {}
    if (
      [WEEKLY, MONTHLY].includes(values.frequencyType) &&
      !values.frequencyInterval
    ) {
      errors.frequencyInterval = 'Day is a required field'
    }

    if (values.frequencyType === HOURLY && !values.frequencyInterval) {
      errors.frequencyInterval = 'Hour is a required field'
    }

    const isHierarchicalApp = get(
      app,
      'catalogApplication.requiresHierarchicalModel'
    )
    const supportsScanning = get(
      app,
      'catalogApplication.supportsEnvironmentScanning'
    )

    if (
      isEmpty(values.properties.scope) &&
      !isHierarchicalApp &&
      (supportsScanning ? !values.properties.orgScan : true)
    ) {
      errors.scope = 'At least one environment must be selected'
    }

    if (isEmpty(values.name)) {
      errors.name = 'Name is a required field'
    }

    return errors
  },

  enableReinitialize: true,

  handleSubmit: async (
    values,
    { setFieldError, setSubmitting, setValues, resetForm, props }
  ) => {
    const {
      dispatch,
      scannerData,
      rootGroupId,
      selectedScanData,
      toggleDrawer,
      updateScheduledScan,
    } = props
    const { taskServiceId: serviceId } = scannerData
    let response

    const {
      frequencyInterval,
      startTime,
      properties: { scope },
      frequencyType,
    } = values

    const momentStartTime = moment(startTime, 'HH:mm')
    values.properties.scope = Object.values(scope)
    let utcTime = moment.utc(momentStartTime)
    if (frequencyInterval) {
      switch (frequencyType) {
        case HOURLY:
        case MONTHLY:
          values.frequencyInterval = frequencyInterval.value
          break
        case WEEKLY:
          momentStartTime.day(frequencyInterval.value)
          utcTime = moment.utc(momentStartTime)
          values.frequencyInterval = utcTime.day() === 0 ? 7 : utcTime.day()
          break
        default:
      }
    }
    values.startTime =
      frequencyType === HOURLY ? null : moment.utc(momentStartTime).format('HH:mm')

    if (scope[rootGroupId]) {
      values.properties.orgScan = true
    }

    const isNewScan = !selectedScanData

    try {
      const apiAction = isNewScan ? api.createTask : api.updateTask
      const taskId = get(selectedScanData, 'taskId')

      response = await apiAction({
        serviceId,
        postBody: values,
        taskId,
      })
    } catch (error) {
      setFieldError(
        'submission',
        get(error, 'response.data.message', 'Unknown problem with submission')
      )
      setSubmitting(false)

      return
    }

    const newValues = convertUTCDataToLocalData(response.data)

    setValues(newValues)
    toggleDrawer()

    dispatch(
      updateMessageModal({
        header: 'Scheduled Scans',
        body: isNewScan ? 'Scheduled scan successfully' : 'Edited scan successfully',
      })
    )

    updateScheduledScan({ isNewScan, data: response.data })
    resetForm()
  },

  displayName: 'CreateScheduledScan',
})

const mapStateToProps = state => ({
  rootGroupId: getSelectedApplicationRootGroupId(state),
})

export default connect(mapStateToProps)(enhancedForm(memo(ScanDrawerContainer)))
