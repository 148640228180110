import isEmpty from 'lodash/isEmpty'
import statusFormatter from 'utils/statusFormatter'
import { PROFILE_STATUS } from 'constants/myProfile'
import { actionButtonText, internalGroupKeys } from './constants'

export const isProfileAccessIndeterminate = profileStatus => {
  const formattedStatus = statusFormatter(profileStatus)
  if (
    formattedStatus === PROFILE_STATUS.CHECK_OUT_SUBMITTED ||
    formattedStatus === PROFILE_STATUS.CHECK_IN_SUBMITTED
  ) {
    return true
  }

  return false
}

export const isApprovalNeeded = profileStatus => {
  const formattedStatus = statusFormatter(profileStatus)
  if (
    formattedStatus === PROFILE_STATUS.APPROVAL_REQUIRED ||
    formattedStatus === PROFILE_STATUS.APPROVAL_PENDING
  ) {
    return true
  }

  return false
}

export const shouldDisableProfileAccess = profileStatus => {
  if (statusFormatter(profileStatus) === PROFILE_STATUS.CHECKED_OUT) {
    return false
  }

  return true
}

export const getActionButtonText = profileStatus => {
  const formattedStatus = statusFormatter(profileStatus)

  if (
    formattedStatus === PROFILE_STATUS.AVAILABLE ||
    formattedStatus === PROFILE_STATUS.CHECK_OUT_SUBMITTED ||
    formattedStatus === PROFILE_STATUS.CHECK_OUT_FAILED ||
    formattedStatus === PROFILE_STATUS.CHECK_OUT_TIMED_OUT
  ) {
    return actionButtonText.CHECKOUT
  }

  if (formattedStatus === PROFILE_STATUS.APPROVAL_REQUIRED) {
    return actionButtonText.REQUEST
  }

  if (formattedStatus === PROFILE_STATUS.APPROVAL_PENDING) {
    return actionButtonText.WITHDRAW
  }

  return actionButtonText.CHECKIN
}

export const getButtonVariant = buttonLabel => {
  if (
    buttonLabel === actionButtonText.CHECKOUT ||
    buttonLabel === actionButtonText.REQUEST
  ) {
    return 'primary'
  }

  return 'secondary'
}

export const getCredentialsAsString = credsData => {
  if (typeof credsData === 'string') {
    return credsData
  }

  return JSON.stringify(credsData)
}

export const getProfileGroups = (
  groupedProfiles = {},
  availableProfileResourceLabels,
  profileIndex,
  allSelectedResourceLabels = [],
  currentSelectedResourceLabel = 0
) => {
  /**
   * This function recursively generates the profile group in the following format.
   * _matches: Stores the unique indexes of the profiles matching the resource label.
   * _totalCount: Total count of the profiles under the group, including the children.
   * _labelKey: Resource label key
   *
   * {
   *  label1: {
   *    _matches: [number]
   *    _totalCount: number
   *    _labelKey: string
   *    label3: {
   *      _matches: [indexes]
   *      _totalCount: number
   *      label4: {
   *         _matches: [indexes]
   *        _totalCount: number
   *      }
   *    }
   *  }
   *  label2: {...}
   * }
   */
  const updatedGroupedProfiles = { ...groupedProfiles }

  ;(
    availableProfileResourceLabels[
      allSelectedResourceLabels[currentSelectedResourceLabel]
    ] || []
  ).forEach(labelValue => {
    if (isEmpty(updatedGroupedProfiles[labelValue])) {
      updatedGroupedProfiles[labelValue] = { _totalCount: 0 }
    }

    updatedGroupedProfiles[labelValue]._totalCount += 1

    const nextSelectedResourceLabel = currentSelectedResourceLabel + 1
    if (
      !isEmpty(allSelectedResourceLabels[nextSelectedResourceLabel]) &&
      !isEmpty(
        availableProfileResourceLabels[
          allSelectedResourceLabels[nextSelectedResourceLabel]
        ]
      )
    ) {
      updatedGroupedProfiles[labelValue] = {
        ...(updatedGroupedProfiles[labelValue] || {}),
        ...getProfileGroups(
          groupedProfiles[labelValue],
          availableProfileResourceLabels,
          profileIndex,
          allSelectedResourceLabels,
          nextSelectedResourceLabel
        ),
      }
    } else {
      updatedGroupedProfiles[labelValue]._matches = [
        ...(updatedGroupedProfiles[labelValue]._matches || []),
        profileIndex,
      ]
    }

    updatedGroupedProfiles[labelValue]._labelKey =
      allSelectedResourceLabels[currentSelectedResourceLabel]
  })

  return updatedGroupedProfiles
}

export const getGroupsToRender = groupData =>
  (Object.keys(groupData) || []).filter(
    l =>
      l !== internalGroupKeys.MATCHES &&
      l !== internalGroupKeys.TOTAL_COUNT &&
      l !== internalGroupKeys.LABEL_KEY
  )
