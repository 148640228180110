import { fetchIfLoggedIn } from '../utils.js'

export const FETCH_FIELDS = 'IP/FETCH_FIELDS'
export const FETCH_RULES = 'IP/FETCH_RULES'
export const EDIT_RULES = 'IP/EDIT_RULES'

export const fetchIPFields = () => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: FETCH_FIELDS,
      method: 'get',
      path: '/settings/firewall/fields',
    })
  )
}

export const fetchRules = () => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: FETCH_RULES,
      method: 'get',
      path: '/settings/firewall',
    })
  )
}

export const editRules = payload => async dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: FETCH_RULES,
      method: 'post',
      path: '/settings/firewall',
      postBody: payload,
    })
  )
}
