export const pageTitle = 'My Secrets'

export const MyRequestsDataTypes = {
  OPEN_NOTIFICATION: 'OPEN_NOTIFICATION',
  MY_REQUESTS_LIST_REQUEST: 'MY_REQUESTS_LIST_REQUEST',
  MY_REQUESTS_LIST_LOADING: 'MY_REQUESTS_LIST_LOADING',
  MY_REQUESTS_LIST_SUCCESS: 'MY_REQUESTS_LIST_SUCCESS',
  MY_REQUESTS_LIST_FAILURE: 'MY_REQUESTS_LIST_FAILURE',
  MY_REQUESTS_LIST_SEARCH_TERM_UPDATE: 'MY_REQUESTS_LIST_SEARCH_TERM_UPDATE',
  MY_REQUESTS_LIST_LOAD_MORE_REQUEST: 'MY_REQUESTS_LIST_LOAD_MORE_REQUEST',
  MY_REQUESTS_LIST_LOAD_MORE_LOADING: 'MY_REQUESTS_LIST_LOAD_MORE_LOADING',
  MY_REQUESTS_LIST_LOAD_MORE_SUCCESS: 'MY_REQUESTS_LIST_LOAD_MORE_SUCCESS',
  MY_REQUESTS_LIST_LOAD_MORE_FAILURE: 'MY_REQUESTS_LIST_LOAD_MORE_FAILURE',
}

export const UsersType = {
  USERS_REQUEST: 'USERS_REQUEST',
  USERS_LOADING: 'USERS_LOADING',
  USERS_SUCCESS: 'USERS_SUCCESS',
  USERS_FAILURE: 'USERS_FAILURE',
}

export const TagsType = {
  USER_TAGS_REQUEST: 'USER_TAGS_REQUEST',
  USER_TAGS_LOADING: 'USER_TAGS_LOADING',
  USER_TAGS_SUCCESS: 'USER_TAGS_SUCCESS',
  USER_TAGS_FAILURE: 'USER_TAGS_FAILURE',
}

export const WithdrawType = {
  MY_REQUESTS_WITHDRAW_REQUEST: 'MY_REQUESTS_WITHDRAW_REQUEST',
  MY_REQUESTS_WITHDRAW_LOADING: 'MY_REQUESTS_WITHDRAW_LOADING',
  MY_REQUESTS_WITHDRAW_SUCCESS: 'MY_REQUESTS_WITHDRAW_SUCCESS',
  MY_REQUESTS_WITHDRAW_FAILURE: 'MY_REQUESTS_WITHDRAW_FAILURE',
}

export const tableColumns = {
  requestedOn: 'Requested Date',
  requestType: 'Request type',
  resourceName: 'Resource',
  justification: 'Justification',
  approvers: 'Approver(s)',
  approvalStatus: 'Status',
  action: 'Action',
  type: 'Type',
  RequestId: 'Request ID',
}

export const translatedStrings = {
  noneValue: 'None',
  myRequestsSearchText: 'Search is not supported on this screen',
  loadingMessage: 'Loading...',
  withdrawRequest: 'Withdraw Request',
  withdrawRequestMessage:
    'You are about to withdraw the request. Do you want to proceed?',
  withdrawPrimaryBtn: 'Yes, Withdraw',
  approvalPendingText: 'Pending',
  noBtn: 'No',
  cancelText: 'Close',
  requestOn: 'Requested On',
  approvers: 'Approvers',
  status: 'Status',
  justification: 'Justification',
  profileCheckoutRequest: 'Profile checkout request',
  secretViewRequest: 'Secret View request',
  requestWithdrawn: 'Request successfully withdrawn.',
  manageRequestTooltip: 'Manage Request',
  viewRequestTooltip: 'View Request',
  noActionRequired: 'No Action Required.',
}

export const active = 'active'
export const classes = {
  listContainer: 'my-requests-list-container',
  tableContainer: 'my-requests-table-container',
  applicationIcon: 'application-icon',
  iconNameWrapper: 'application-icon-name-wrapper',
  actionItems: 'my-requests-action-items',
  infoIcon: 'my-requests-action-items-info',
  cancelIcon: 'my-requests-action-items-cancel',
  cancelIconDisabled: 'my-requests-action-items-cancel-disabled',
}

export const myRequestsTableTestId = 'my-requests-table'
export const searchDebounceTime = 300
export const defaultBtnSize = '24'
export const backToTopId = 'requests-list-back-to-top'
export const userRequestsListString = 'userRequestsList'
export const adminRequestsListString = 'adminRequestsList'
export const papConsumer = 'papservice'
export const resourceprofile = 'resourceprofile'
