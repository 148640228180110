import React from 'react'
import Echarts from 'echarts-for-react'
import { Col, Row } from 'reactstrap'
import startCase from 'lodash/startCase'

const SummaryBar = ({ permission, colors }) => {
  const getSeries = () => {
    const series = {
      name: permission.type,
      type: 'bar',
      data: [permission.total],
      barWidth: 10,
      color: '#e0e0e0',
      itemStyle: {
        shadowBlur: 0.5,
        shadowOffsetX: 0.3,
        shadowOffsetY: 0.3,
        shadowColor: '#e4e4e4',
      },
      barGap: '100%',
    }

    const series2 = getLegends().map(p => {
      return {
        name: p.name,
        type: 'bar',
        data: [p],
        barWidth: 10,
        itemStyle: {
          shadowBlur: 0.5,
          shadowOffsetX: 0.3,
          shadowOffsetY: 0.3,
          shadowColor: '#e4e4e4',
        },
        barGap: '100%',
      }
    })
    series2.unshift(series)
    return series2
  }

  const getLegends = () => {
    const legends = []
    Object.keys(permission).forEach(p => {
      if (!(p === 'type' || p === 'total' || permission[p] === 0)) {
        legends.unshift({
          name: startCase(p),
          value: permission[p],
        })
      }
    })
    return legends
  }

  return (
    <Row>
      <Col>
        <div className="titleNumber bar">{permission.total}</div>
        <Echarts
          style={{ height: 200 }}
          option={{
            color: colors,
            legend: {
              y: 'bottom',
              selectedMode: false,
              itemHeight: 10,
              itemWidth: 10,
              padding: 4,
              itemGap: 5,
              data: getLegends(),
              formatter: p => {
                const _p = getLegends().filter(l => l.name === p)[0]
                return `${p}: ${_p.value} (${(
                  (_p.value / permission.total) *
                  100
                ).toFixed(2)}%)`
              },
            },
            tooltip: {
              trigger: 'item',
              formatter: a => {
                if (a.name === permission.type) {
                  return `${startCase(a.name)}: ${permission.total}`
                } else {
                  return `${a.name} ${startCase(permission.type)}: ${a.value}`
                }
              },
            },
            grid: {
              bottom: 60,
              top: 0,
              left: 100,
              show: false,
            },
            xAxis: {
              type: 'category',
              show: false,
            },
            yAxis: {
              type: 'value',
              show: false,
              splitLine: {
                show: false,
              },
              minInterval: permission.total,
            },
            series: getSeries(),
          }}
        />
      </Col>
    </Row>
  )
}

export default SummaryBar
