import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Spinner from 'britive-ui-components/core/components/Spinner'
import { getIsAuthenticated } from 'utils/authentication'
import Fallback from 'components/fallback'

const ProtectedRoute = ({
  component: Component,
  isAuthenticated,
  isUserFetched,
  location,
  extraProps = {},
  loading = false,
  renderCondition = true,
  fallbackPath = '/',
  ...rest
}) => {
  const renderLocation = props => {
    if (isAuthenticated) {
      if (!isUserFetched || loading) {
        return <Spinner size="2x" />
      }
      return renderCondition ? (
        <Component {...props} {...extraProps} />
      ) : (
        <Fallback path={fallbackPath} />
      )
    }
    const path = location && location.pathname ? location.pathname : ''

    return (
      <Redirect
        to={{
          pathname: '/login',
          search: `?redirectUrl=${path}`,
          state: { from: props.location },
        }}
      />
    )
  }

  return <Route {...rest} render={renderLocation} />
}

ProtectedRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.elementType, PropTypes.func]),
  location: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  isAuthenticated: PropTypes.bool,
}

const mapStateToProps = state => ({
  isAuthenticated: getIsAuthenticated(),
  isUserFetched: state.user.fetched,
})

export default connect(mapStateToProps)(ProtectedRoute)
