import { doFetch } from '../../../../utils/do_fetch'

export const fetchSystemAnnouncement = async () => {
  const response = await doFetch({
    path: `/banner`,
    method: 'get',
  })

  return response.data
}
