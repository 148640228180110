import get from 'lodash/get'

import {
  CREATE_ENVIRONMENT_ENTITY,
  FETCH_ENVIRONMENT_GROUPS,
  FETCH_ENVIRONMENTS,
  FETCH_ACCESS_ENVIRONMENTS,
  FETCH_ENVIRONMENT_SCAN_REPORT,
  FETCH_ENVIRONMENT_SCANS_REPORT,
  SELECT_ENVIRONMENT_ID,
  SELECT_ENVIRONMENT_GROUP_ID,
  SCAN_ENVIRONMENT,
  TEST_ENVIRONMENT,
  UPDATE_ENVIRONMENT,
  UPDATE_ENVIRONMENT_GROUP,
  UPDATE_ENVIRONMENT_GROUPS_STATE,
  DELETE_ENVIRONMENT,
  FETCH_ENVIRONMENT_MINIMAL,
  THIS_APP_MANAGE,
} from 'action_creators/environment'

export const environmentReducerKey = 'environment'

const initialState = {
  environmentGroups: {},
  environments: {},
  environmentsMinimal: {},
  // need to figure out how to label this data
  environmentAppData: {},
  accessEnvironmentAppData: {},
  environmentScanResults: {},
  error: null,
  fetchingEnvironmentGroups: false,
  fetchingEnvironments: false,
  fetchingAccessEnvironments: false,
  fetchingEnvironmentScanReport: {},
  fetchingEnvironmentScansReport: false,
  deletingEnvironment: false,
  selectedEnvironmentId: null,
  selectedEnvironmentGroupId: null,
  updatingEnvironment: false,
  updatingEnvironmentGroup: false,
  createEnvironmentError: null,
  thisAppManage: false,
}

export function environmentReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_ENVIRONMENT_GROUPS_STATE: {
      const environmentGroups = {
        ...state.environmentGroups,
        ...action.payload.environmentGroups,
      }

      const environments = {
        ...state.environmentGroups,
        ...action.payload.environments,
      }

      return {
        ...state,
        environmentGroups,
        environments,
      }
    }

    case `${UPDATE_ENVIRONMENT}_PENDING`: {
      return {
        ...state,
        updatingEnvironment: true,
      }
    }

    case `${UPDATE_ENVIRONMENT}_FULFILLED`: {
      const appEnvironmentData = {
        ...state.environmentAppData[action.payload.data.appContainerId],
        [action.payload.data.environmentId]: action.payload.data,
      }
      const environmentAppData = {
        ...state.environmentAppData,
        [action.payload.data.appContainerId]: appEnvironmentData,
      }

      return {
        ...state,
        updatingEnvironment: false,
        environmentAppData,
      }
    }

    case `${UPDATE_ENVIRONMENT}_REJECTED`: {
      return {
        ...state,
        updatingEnvironment: false,
        error: action.payload,
      }
    }

    case `${UPDATE_ENVIRONMENT_GROUP}_PENDING`: {
      return {
        ...state,
        updatingEnvironmentGroup: true,
      }
    }

    case `${UPDATE_ENVIRONMENT_GROUP}_FULFILLED`: {
      const { applicationRootId, groupId } = action.meta

      const appGroupData = {
        ...state.environmentGroups[applicationRootId],
        [groupId]: action.payload.data,
      }

      const environmentGroups = {
        ...state.environmentGroups,
        [applicationRootId]: appGroupData,
      }

      return {
        ...state,
        updatingEnvironmentGroup: false,
        environmentGroups,
      }
    }

    case `${UPDATE_ENVIRONMENT_GROUP}_REJECTED`: {
      return {
        ...state,
        updatingEnvironmentGroup: false,
        error: action.payload,
      }
    }

    case `${TEST_ENVIRONMENT}_PENDING`: {
      return {
        ...state,
        testingEnvironment: true,
      }
    }

    case `${TEST_ENVIRONMENT}_FULFILLED`: {
      return {
        ...state,
        testingEnvironment: false,
      }
    }

    case `${TEST_ENVIRONMENT}_REJECTED`: {
      return {
        ...state,
        testingEnvironment: false,
        error: action.payload,
      }
    }

    case `${SCAN_ENVIRONMENT}_PENDING`: {
      const { appId, envId } = action.meta

      const newStatus = {
        ...state.fetchingEnvironmentScanReport[appId],
        [envId]: true,
      }

      return {
        ...state,
        fetchingEnvironmentScanReport: {
          ...state.fetchingEnvironmentScanReport,
          [appId]: newStatus,
        },
      }
    }

    case `${SCAN_ENVIRONMENT}_FULFILLED`: {
      const { data } = action.payload
      const { appId, envId } = action.meta

      const newStatus = {
        ...state.fetchingEnvironmentScanReport[appId],
        [envId]: false,
      }

      const newEnvironmentData = {
        ...state.environmentScanResults[appId],
        [envId]: data,
      }

      const environmentScanResults = {
        ...state.environmentScanResults,
        [appId]: newEnvironmentData,
      }

      return {
        ...state,
        environmentScanResults,
        fetchingEnvironmentScanReport: {
          ...state.fetchingEnvironmentScanReport,
          [appId]: newStatus,
        },
      }
    }

    case `${SCAN_ENVIRONMENT}_REJECTED`: {
      const { appId, envId } = action.meta

      const newStatus = {
        ...state.fetchingEnvironmentScanReport[appId],
        [envId]: false,
      }

      return {
        ...state,
        fetchingEnvironmentScanReport: {
          ...state.fetchingEnvironmentScanReport,
          [appId]: newStatus,
        },
        error: action.payload,
      }
    }

    case `${FETCH_ENVIRONMENT_GROUPS}_PENDING`:
      return {
        ...state,
        fetchingEnvironmentGroups: true,
      }

    case `${FETCH_ENVIRONMENT_GROUPS}_FULFILLED`: {
      const response = action.payload
      const { appId } = action.meta

      const environmentGroups = {
        ...state.environmentGroups,
        [appId]: {},
      }

      const environments = {
        ...state.environments,
        [appId]: {},
      }

      get(response, 'data.environmentGroups', []).forEach(group => {
        environmentGroups[appId][group.id] = group
      })

      get(response, 'data.environments', []).forEach(group => {
        environments[appId][group.id] = group
      })

      return {
        ...state,
        environmentGroups,
        environments,
        fetchingEnvironmentGroups: false,
      }
    }

    case `${FETCH_ENVIRONMENT_GROUPS}_REJECTED`: {
      return {
        ...state,
        fetchingEnvironmentGroups: false,
        error: action.payload,
      }
    }

    case `${FETCH_ENVIRONMENTS}_PENDING`:
      return {
        ...state,
        fetchingEnvironments: true,
      }

    case `${FETCH_ENVIRONMENTS}_FULFILLED`: {
      const response = action.payload
      const envsObject = {}
      let appId

      response.data.forEach(env => {
        if (!appId) {
          appId = env.appContainerId
        }

        envsObject[env.environmentId] = env
      })

      const environmentAppData = {
        ...state.environmentAppData,
        [appId]: envsObject,
      }

      return {
        ...state,
        environmentAppData,
        fetchingEnvironments: false,
      }
    }

    case `${FETCH_ENVIRONMENTS}_REJECTED`: {
      return {
        ...state,
        fetchingEnvironments: false,
        error: action.payload,
      }
    }

    case `${FETCH_ACCESS_ENVIRONMENTS}_PENDING`:
      return {
        ...state,
        fetchingAccessEnvironments: true,
      }

    case `${FETCH_ACCESS_ENVIRONMENTS}_FULFILLED`: {
      const response = action.payload
      const envsObject = {}
      let appId

      response.data.forEach(env => {
        if (!appId) {
          appId = env.appContainerId
        }

        envsObject[env.environmentId] = env
      })

      const accessEnvironmentAppData = {
        ...state.accessEnvironmentAppData,
        [appId]: envsObject,
      }

      return {
        ...state,
        accessEnvironmentAppData,
        fetchingAccessEnvironments: false,
      }
    }

    case `${FETCH_ACCESS_ENVIRONMENTS}_REJECTED`: {
      return {
        ...state,
        fetchingAccessEnvironments: false,
        error: action.payload,
      }
    }

    case `${FETCH_ENVIRONMENT_SCAN_REPORT}_PENDING`: {
      const { appId, envId } = action.meta

      const newStatus = {
        ...state.fetchingEnvironmentScanReport[appId],
        [envId]: true,
      }

      return {
        ...state,
        fetchingEnvironmentScanReport: {
          ...state.fetchingEnvironmentScanReport,
          [appId]: newStatus,
        },
      }
    }

    case `${FETCH_ENVIRONMENT_SCAN_REPORT}_FULFILLED`: {
      const { appId, envId } = action.meta
      const data = get(action, 'payload.data', [])

      const oldScanResults = state.environmentScanResults[appId] || {}

      const newScanResults = {
        ...oldScanResults,
        [envId]: data[0],
      }

      const environmentScanResults = {
        ...state.environmentScanResults,
        [appId]: newScanResults,
      }

      const newStatus = {
        ...state.fetchingEnvironmentScanReport[appId],
        [envId]: false,
      }

      return {
        ...state,
        environmentScanResults,
        fetchingEnvironmentScanReport: {
          ...state.fetchingEnvironmentScanReport,
          [appId]: newStatus,
        },
      }
    }

    case `${FETCH_ENVIRONMENT_SCAN_REPORT}_REJECTED`: {
      const { appId, envId } = action.meta

      const newStatus = {
        ...state.fetchingEnvironmentScanReport[appId],
        [envId]: false,
      }

      return {
        ...state,
        fetchingEnvironmentScanReport: {
          ...state.fetchingEnvironmentScanReport,
          [appId]: newStatus,
        },
        error: action.payload,
      }
    }

    case `${FETCH_ENVIRONMENT_SCANS_REPORT}_PENDING`: {
      return {
        ...state,
        fetchingEnvironmentScansReport: true,
      }
    }

    case `${FETCH_ENVIRONMENT_SCANS_REPORT}_FULFILLED`: {
      const { appId } = action.meta
      const data = get(action, 'payload.data', [])
      const newAppScanData = {}

      data.forEach(scan => {
        newAppScanData[scan.environmentId] = scan
      })

      const environmentScanResults = {
        ...state.environmentScanResults,
        [appId]: newAppScanData,
      }

      return {
        ...state,
        fetchingEnvironmentScansReport: false,
        environmentScanResults,
      }
    }

    case `${FETCH_ENVIRONMENT_SCANS_REPORT}_REJECTED`: {
      return {
        ...state,
        fetchingEnvironmentScansReport: false,
        error: action.payload,
      }
    }

    case `${FETCH_ENVIRONMENT_MINIMAL}_PENDING`: {
      return {
        ...state,
        fetchingEnvironments: true,
      }
    }

    case `${FETCH_ENVIRONMENT_MINIMAL}_FULFILLED`: {
      const environmentsMinimal = {}
      action.payload.data.forEach(d => {
        environmentsMinimal[d.id] = d
      })
      return {
        ...state,
        fetchingEnvironments: false,
        environmentsMinimal,
      }
    }

    case `${FETCH_ENVIRONMENT_MINIMAL}_REJECTED`: {
      return {
        ...state,
        fetchingEnvironments: false,
        error: action.payload,
      }
    }

    // TODO: Move to admin ui reducer
    case SELECT_ENVIRONMENT_ID:
      return {
        ...state,
        selectedEnvironmentId: action.payload,
      }

    // TODO: Move to admin ui reducer
    case SELECT_ENVIRONMENT_GROUP_ID:
      return {
        ...state,
        selectedEnvironmentGroupId: action.payload,
      }

    case THIS_APP_MANAGE:
      return {
        ...state,
        thisAppManage: action.payload,
      }

    case `${CREATE_ENVIRONMENT_ENTITY}_PENDING`: {
      return {
        ...state,
        createEnvironmentError: null,
      }
    }

    case `${CREATE_ENVIRONMENT_ENTITY}_REJECTED`: {
      return {
        ...state,
        createEnvironmentError: get(
          action,
          'payload.response.data.message',
          'Unknown Error'
        ),
      }
    }

    case `${DELETE_ENVIRONMENT}_PENDING`: {
      return {
        ...state,
        deletingEnvironment: true,
      }
    }

    case `${DELETE_ENVIRONMENT}_FULFILLED`: {
      return {
        ...state,
        deletingEnvironment: false,
      }
    }

    case `${DELETE_ENVIRONMENT}_REJECTED`: {
      return {
        ...state,
        deletingEnvironment: false,
        error: action.payload,
      }
    }

    default:
      return { ...state }
  }
}
