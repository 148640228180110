import React, { useState, useEffect } from 'react'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import TreeView from 'britive-design-system/core/components/tree-view'
import Typography from 'britive-design-system/core/components/typography'
import RadioGroup from 'britive-design-system/core/components/radio'
import './Associations.scss'
import {
  getTree,
  shouldDisableDefaultDirectory,
  isDefaultDirectorySelected,
  getValuesFromTheSelectedNodes,
  defaultDirectoryHasChildren,
  moveRootLevelLeavesAtTop,
} from './utils'
import {
  defaultAssociation,
  customGroups,
  associationSelectionLabel,
} from './constants'

const Associations = ({
  disabled = false,
  environmentGroups = {},
  environments = {},
  environmentData = {},
  defaultDirectory,
  applicationRoot,
  checkedNodes = [],
  onChange,
  hideCheckboxes = false,
  hideDropdownFilter = false,
  hideUnselectedAssociationType = false,
  singleSelect = false,
  allowOnlyEnvironmentSelection = false,
  showSelectionLabel = true,
  allowDisablingUnselectedAssociation = true,
  filterSelectedScopeAsDefault,
  allowedScopes,
  showCreatedDate = false,
  allowRootSelection = true,
  appName,
  rightIconConfig,
  outOfSyncEnvironments,
}) => {
  const [defaultDirectoryName, setDefaultDirectoryName] = useState(null)
  const [selectedAssociationsType, setSelectedAssociationsType] = useState(
    customGroups
  )
  const [updatedCheckedNodes, setUpdatedCheckedNodes] = useState([])
  const [initialCheckedNodes, setInitialCheckedNodes] = useState([])
  const [associationsNodes, setAssociationsNodes] = useState({})
  const [isSelectedDefault, setIsSelectedDefault] = useState(false)
  const [touched, setTouched] = useState(false)

  useEffect(() => {
    !touched &&
      setIsSelectedDefault(
        checkedNodes.length > 1 ||
          // check for azure
          (checkedNodes.length === 1 && checkedNodes[0] !== defaultDirectory)
      )
  }, [JSON.stringify(checkedNodes)])

  useEffect(() => {
    setDefaultDirectoryName(get(environmentGroups, `${defaultDirectory}.name`))
  }, [JSON.stringify(Object.keys(environmentGroups)), defaultDirectory])

  useEffect(() => {
    // This condition is added to prevent re-renders.
    if (checkedNodes.length === 0 || !isEqual(checkedNodes, updatedCheckedNodes)) {
      setUpdatedCheckedNodes([...checkedNodes])
      selectRadioOption()
    }
    updateAssociationsNodes()
  }, [JSON.stringify(checkedNodes)])

  useEffect(() => {
    selectRadioOption()
    updateAssociationsNodes()
  }, [JSON.stringify(allowedScopes)])

  const updateAssociationsNodes = () => {
    const nodes = getTree(
      environmentGroups,
      environments,
      checkedNodes,
      initialCheckedNodes,
      applicationRoot,
      defaultDirectory,
      environmentData,
      [
        ...(allowedScopes?.environments || []),
        ...(allowedScopes?.environmentGroups || []),
      ],
      touched,
      rightIconConfig,
      outOfSyncEnvironments
    )
    setAssociationsNodes(nodes)
  }

  const selectRadioOption = () => {
    if (!touched || disabled) {
      if (isDefaultDirectorySelected(checkedNodes, defaultDirectory)) {
        setSelectedAssociationsType(defaultAssociation)
      } else if (checkedNodes.length) {
        setSelectedAssociationsType(customGroups)
      } else {
        setSelectedAssociationsType(null)
      }
    }
  }

  const sendTheSelectedItems = selectedItems => {
    if (typeof onChange === 'function') {
      onChange(selectedItems)
    }
  }

  const handleNodeSelection = selectedNodes => {
    if (!touched) {
      // Setting the initial checked nodes to be used for comparison while highlighting
      // the edited ones. This block will be executed only once, since the touched value
      // changes to true later.
      setInitialCheckedNodes([...checkedNodes])
    }
    const newCheckedNodes = getValuesFromTheSelectedNodes(selectedNodes)
    setUpdatedCheckedNodes(newCheckedNodes)
    sendTheSelectedItems(newCheckedNodes)
    setTouched(true)
  }

  const handleRadioButtonSelection = value => {
    setSelectedAssociationsType(value)

    if (value === defaultAssociation) {
      sendTheSelectedItems([defaultDirectory])
    }
  }

  const addMarginToTheTree = defaultDirectoryName && !hideUnselectedAssociationType

  const hasDefaultDirAndTreeNodes = defaultDirectoryHasChildren(
    environmentGroups,
    defaultDirectory
  )

  const shouldRenderTree =
    (selectedAssociationsType === customGroups && hasDefaultDirAndTreeNodes) ||
    !defaultDirectoryName

  const shouldRenderAssociationTypeSelection =
    !hideUnselectedAssociationType && defaultDirectoryName

  const shouldRenderDefaultDirectoryName =
    hideUnselectedAssociationType && selectedAssociationsType === defaultAssociation

  const associationTypeOptions = [
    {
      label: defaultDirectoryName,
      value: defaultAssociation,
      disabled: allowDisablingUnselectedAssociation
        ? shouldDisableDefaultDirectory(
            disabled,
            updatedCheckedNodes,
            defaultDirectory,
            [...(allowedScopes?.environmentGroups || [])]
          )
        : allowDisablingUnselectedAssociation,
    },
  ]

  if (hasDefaultDirAndTreeNodes) {
    associationTypeOptions.push({
      label: customGroups,
      value: customGroups,
      disabled: allowDisablingUnselectedAssociation
        ? disabled ||
          (allowedScopes?.environmentGroups?.length &&
            allowedScopes?.environmentGroups[0] === defaultDirectory)
        : allowDisablingUnselectedAssociation,
    })
  }

  return (
    <>
      {shouldRenderDefaultDirectoryName && (
        <Typography variant="label1">{defaultDirectoryName}</Typography>
      )}

      {shouldRenderAssociationTypeSelection ? (
        <div
          className="associations-selection-wrapper"
          data-testid="associations-selection-wrapper"
        >
          <RadioGroup
            label={showSelectionLabel ? associationSelectionLabel : undefined}
            name={associationSelectionLabel}
            direction={'vertical'}
            defaultValue={selectedAssociationsType}
            options={associationTypeOptions}
            onChange={event => {
              event.preventDefault()
              handleRadioButtonSelection(event.target.value)
            }}
          />
        </div>
      ) : null}

      {shouldRenderTree ? (
        <div
          data-testid="associations-tree-container"
          className="associations-tree-container"
          style={{ marginLeft: addMarginToTheTree ? '43px' : 0 }}
        >
          <TreeView
            nodes={associationsNodes}
            readOnly={
              disabled ||
              (allowedScopes?.environmentGroups?.length &&
                allowedScopes?.environmentGroups[0] === defaultDirectory)
            }
            onChange={handleNodeSelection}
            isSelectedDefault={
              filterSelectedScopeAsDefault === undefined
                ? isSelectedDefault
                : filterSelectedScopeAsDefault
            }
            hideCheckboxes={hideCheckboxes}
            hideDropdownFilter={hideDropdownFilter}
            singleSelect={singleSelect}
            allowOnlyLeafSelection={allowOnlyEnvironmentSelection}
            allowRootSelection={allowRootSelection}
            showCreatedDate={showCreatedDate}
            rootLeafNodesFirst={moveRootLevelLeavesAtTop(appName)}
          />
        </div>
      ) : null}
    </>
  )
}

export default Associations
