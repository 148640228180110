import React from 'react'
import { useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import Accordion from 'britive-design-system/core/components/accordion'
import ProfileList from '../ProfileList'
import GroupTitle from './GroupTitle'
import { getGroupsToRender } from '../utils'
import { greyColorLevels, ungroupedLabel } from '../constants'

const CSS_CLASSNAME_PREFIX = 'my-resources-group'

const ProfileGroups = ({
  groupData = {},
  depth,
  isExpanded,
  ...profileListProps
}) => {
  const profiles = useSelector(state => state.serverAccess.myResources.profiles)

  const getGroupProfileRows = profilesToRender => {
    if (isEmpty(profilesToRender) || isEmpty(groupData) || isEmpty(profiles?.data)) {
      return []
    }

    const profilesByLabels = []
    profilesToRender.forEach(matchingProfileIndex => {
      if (!isEmpty(profiles.data[matchingProfileIndex])) {
        profilesByLabels.push(profiles.data[matchingProfileIndex])
      }
    })

    return profilesByLabels
  }
  const groupsToRender = getGroupsToRender(groupData)

  return groupsToRender.map((label, index) => {
    const profileToRender = getGroupProfileRows(groupData[label]?._matches)
    const subGroupsToRender = getGroupsToRender(groupData[label])

    return (
      <div
        key={`group-by-labels-accordion-${label}-${depth}-${index}`}
        className={`${CSS_CLASSNAME_PREFIX}-wrapper`}
        style={{ border: depth === 0 ? '1px solid #C6C6C6' : '' }}
      >
        <Accordion
          title={
            <GroupTitle
              label={label}
              count={groupData[label]?._totalCount}
              labelKey={groupData[label]?._labelKey}
            />
          }
          hideUnderline
          removeChildrenPadding
          expanded={isExpanded}
        >
          <div
            className={`${CSS_CLASSNAME_PREFIX}-children`}
            style={{ backgroundColor: greyColorLevels[depth] || '#fff' }}
          >
            {profileToRender?.length && isEmpty(subGroupsToRender) ? (
              <ProfileList rows={profileToRender} {...profileListProps} />
            ) : null}

            {!isEmpty(subGroupsToRender) ? (
              <ProfileGroups
                groupData={groupData[label]}
                depth={depth + 1}
                isExpanded={isExpanded}
                {...profileListProps}
              />
            ) : null}

            {profileToRender?.length && !isEmpty(subGroupsToRender) ? (
              <div
                key={`group-by-labels-accordion-${label}-${depth}-${index}-${ungroupedLabel}`}
                className={`${CSS_CLASSNAME_PREFIX}-wrapper`}
              >
                <Accordion
                  hideUnderline
                  removeChildrenPadding
                  expanded={isExpanded}
                  title={
                    <GroupTitle
                      label={ungroupedLabel}
                      count={groupData[label]?._matches?.length}
                      labelKey={groupData[label]?._labelKey}
                    />
                  }
                >
                  <div
                    className={`${CSS_CLASSNAME_PREFIX}-children`}
                    style={{
                      backgroundColor: greyColorLevels[depth] || '#fff',
                    }}
                  >
                    <ProfileList rows={profileToRender} {...profileListProps} />
                  </div>
                </Accordion>
              </div>
            ) : null}
          </div>
        </Accordion>
      </div>
    )
  })
}

export default ProfileGroups
