import get from 'lodash/get'
import clone from 'lodash/clone'
import isEmpty from 'lodash/isEmpty'
import * as actions from 'actions'

const initialState = {
  loading: false,
  error: null,
  data: [],
  count: 0,
  hasMoreData: false,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case `${actions.GET_RESPONSE_TEMPLATES}_PENDING`: {
      return {
        ...state,
        loading: true,
        error: null,
      }
    }

    case `${actions.GET_RESPONSE_TEMPLATES}_FULFILLED`: {
      const response = get(action, 'payload.data', {})
      const { count, page, data = [] } = response
      let updatedData = []

      if (page === 0) {
        updatedData = data
      } else {
        updatedData = [...state.data, ...data]
      }

      return {
        ...state,
        loading: false,
        error: null,
        data: updatedData,
        hasMoreData: updatedData.length < count,
      }
    }

    case `${actions.GET_RESPONSE_TEMPLATES}_REJECTED`: {
      return {
        ...state,
        loading: false,
        error: 'Unable to fetch the response templates.',
      }
    }

    case `${actions.DELETE_RESPONSE_TEMPLATE}_FULFILLED`: {
      const { templateId } = action.meta
      let updatedData = []
      if (!isEmpty(state?.data)) {
        updatedData = clone(state.data)
        const indexOfTemplate = updatedData.findIndex(
          template => template.templateId === templateId
        )
        updatedData.splice(indexOfTemplate, 1)
      }

      return {
        ...state,
        data: updatedData,
      }
    }

    case `${actions.CREATE_RESPONSE_TEMPLATE}_FULFILLED`: {
      const response = get(action, 'payload.data', {})

      let updatedData = []
      if (!isEmpty(state?.data)) {
        updatedData = clone(state.data)
      }

      updatedData.push(response)

      return {
        ...state,
        data: updatedData,
      }
    }

    case `${actions.UPDATE_RESPONSE_TEMPLATE}_FULFILLED`: {
      const { templateId } = action.meta
      const response = get(action, 'payload.data', {})

      let updatedData = []
      if (!isEmpty(state?.data)) {
        updatedData = clone(state.data)
        const indexOfTemplate = updatedData.findIndex(
          template => template.templateId === templateId
        )
        updatedData[indexOfTemplate] = {
          ...response,
          templateId,
        }
      }

      return {
        ...state,
        data: updatedData,
      }
    }

    default:
      return { ...state }
  }
}
