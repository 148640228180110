import React, { memo, useMemo } from 'react'

import Drawer from 'components/Drawer'
import TableSection from 'components/table/tableSectionV2'

import useMaxTableHeight from 'hooks/useMaxTableHeight'

import { getHeaderSpacing } from 'utils/elementMeasurement'

function MaxDataDetails({ data, detailColumns, isOpen, title, toggleDrawer }) {
  const maxTableHeight = useMaxTableHeight({
    heights: [136],
    minHeight: 100,
  })

  const formattedData = useMemo(() => {
    const newData = []

    data.forEach((user, userIdx) => {
      user.details.forEach((detail, detailIdx) => {
        const searchId = `user${userIdx}detail${detailIdx}`

        delete user.scope

        newData.push({
          ...detail,
          ...user,
          searchId,
        })
      })
    })

    return newData
  }, [data])

  const filterDataSet = useMemo(() => {
    let types = new Set()
    let typesArr = []

    formattedData.forEach(({ permissionType }) => {
      types.add(permissionType)
    })

    types.forEach(type => {
      typesArr.push({ label: type, value: type })
    })

    return { permissionType: typesArr }
  }, [formattedData])

  const searchKeys = useMemo(() => {
    return detailColumns.map(column => column.accessor)
  }, [detailColumns])

  return (
    <Drawer
      isOpen={isOpen}
      top={`${getHeaderSpacing()}px`}
      id="id"
      width="60%"
      toggleDrawer={toggleDrawer}
      zIndex={6}
    >
      <div>
        <h5>{title} Details</h5>

        <div>
          <TableSection
            highlightSearchResults
            useCompoundFilter={true}
            searchKeys={searchKeys}
            compoundFilterCategories={[{ label: 'Type', value: 'permissionType' }]}
            filterDataSet={filterDataSet}
            columns={detailColumns}
            data={formattedData}
            identifier="searchId"
            emptyTableMessage="No details were found."
            maxHeight={`${maxTableHeight}px`}
            sortable
            defaultSorted={[{ id: 'firstName', desc: false }]}
          />
        </div>
      </div>
    </Drawer>
  )
}

export default memo(MaxDataDetails)
