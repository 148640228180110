import React from 'react'
import { useSelector } from 'react-redux'
import Spinner from 'britive-design-system/core/components/spinner'
import ApprovalsView from './ApprovalsView'
import { classes, translatedStrings, statusLabel } from './constants'
import { getSecretStatus, getActionLabel } from '../../utils'
import {
  getApprovalsDetailsById,
  detailsApproveRequest,
  detailsRejectRequest,
} from '../../../../action_creators/my-approvals'
import './myApprovalsDetails.scss'

const MyApprovalsDetails = ({ history, approvalId, redirectPath }) => {
  const approvalsDetails = useSelector(
    state => state.userSecretsReducer?.myApprovalsDetails
  )

  return (
    <div>
      {approvalsDetails?.loading ? (
        <Spinner
          size={'medium'}
          message={translatedStrings.loading}
          overlay={true}
        />
      ) : null}
      <ApprovalsView
        id={approvalId}
        history={history}
        approvalsDetails={approvalsDetails}
        getSecretStatus={getSecretStatus}
        getActionLabel={getActionLabel}
        translatedStrings={translatedStrings}
        getApprovalsDetailsById={getApprovalsDetailsById}
        approveRequest={detailsApproveRequest}
        rejectRequest={detailsRejectRequest}
        classes={classes}
        statusLabel={statusLabel}
        redirectPath={redirectPath}
      />
    </div>
  )
}

export default MyApprovalsDetails
