import { SET_FILTER_COUNT } from 'actions'

export const filterCountKey = 'filtersCountValue'

export const filterCountReducer = (
  state = {
    favorite: 0,
    frequentlyUsed: 0,
    all: 0,
    checkedOut: 0,
  },
  action
) => {
  switch (action.type) {
    case SET_FILTER_COUNT: {
      const { tab, value } = action.payload

      return {
        ...state,
        [tab]: value,
      }
    }

    default:
      return { ...state }
  }
}
