import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'

import Accordion from 'britive-design-system/core/components/accordion'
import Button from 'britive-design-system/core/components/button'
import Tooltip from 'britive-design-system/core/components/tooltip'
import ModalPopup from 'britive-design-system/core/components/modal-popup'
import TextfieldInlineEdit from 'britive-design-system/core/components/textfield-inline-edit'
import DeleteConfirmationDialog from '../DeleteConfirmationDialog'
import Table from './../AssignAssociationApprovers/components/Table'

import listIcon from 'static/images/list-tree.svg'
import editIcon from 'static/images/edit-icon.svg'
import deleteIcon from 'static/images/delete-icon.svg'
import ApproversGroupList from '../AssignAssociationApprovers/components/ApproversGroupList'
import ApproversGroupDetailsModal from './ApproversGroupDetailsModal'
import { AssociationsTree } from '../AssignAssociationApprovers/components/AssociationsTree'
import SectionTitle from '../SectionTitle'
import { sectionTitle } from '../constants'
import { ApproversGroups } from '../AssignAssociationApprovers/components/ApproversGroups'
import { ApproversGroupDetails } from '../AssignAssociationApprovers/components/ApproversGroupDetails'
import { AddUserList } from '../AssignAssociationApprovers/components/AddUserList'
import { AddTagsList } from '../AssignAssociationApprovers/components/AddTagsList'
import TagMembersModal from 'components/MembersSelector/TagMembersModal'
import { useSelector, useDispatch } from 'react-redux'
import Spinner from 'britive-design-system/core/components/spinner'
import Snackbar from 'britive-design-system/core/components/snackbar'
import toast from 'utils/toast'
import {
  updateApproversGroup as updateApproversGroupSettings,
  resetAssociationApproversSettings,
  fetchApproversGroups,
  fetchAccessRequestSettings,
  deleteApproversGroups,
} from 'action_creators/access-request'

import {
  getApproversGroupData,
  filterUsersIdsFromMembers,
  filterTagIdsFromMembers,
  getApproversDataToUpdate,
  isApproversGroupDataValid,
  getModalTitle,
  characterCheck,
} from '../AssignAssociationApprovers/utils'
import { isEmpty, get } from 'lodash'
import styled from 'styled-components'
import { approverGroupCondition, modalView } from './constants'
import {
  getSecondsFromTimeDuration,
  getTimeDurationFromSeconds,
} from 'utils/format_date'
import { isAWSWithoutIDC } from '../../Profiles/components/pap-profile/utils'
import classNames from 'classnames'
import deleteIconDisabled from 'static/images/delete-icon-disabled.svg'
import editIconDisabled from 'static/images/edit-icon-disabled.svg'

const AddAssignmentBtn = styled.div`
  cursor: not-allowed;
  opacity: 70%;
  &:hover {
    background-color: var(--brand);
  }
`

const AssociationApprovers = ({
  associationApprovers,
  approvalTimeout,
  profileExpirationTimeout,
  updateSettings,
  app,
  disableEditing = false,
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [selectedAssociationApprovers, setSelectedAssociationApprovers] = useState(
    []
  )
  const [selectedApproversGroupData, setSelectedApproversGroupData] = useState(null)
  const [currentApprovalTimeout, setCurrentApprovalTimeout] = useState('')
  const [
    currentProfileExpirationTimeout,
    setCurrentProfileExpirationTimeout,
  ] = useState('')

  const [itemToDelete, setItemToDelete] = useState(null)

  const [selectedEntity, setSelectedEntity] = useState(null)

  const approversGroupsList = useSelector(
    state =>
      state?.accessRequestReducer?.approversGroups?.data?.approversGroupSummary
  )

  const { loading: approverGroupsLoading } = useSelector(
    state => state.accessRequestReducer?.approverGroups
  )

  const { data: approversGroups, loading: approversGroupsLoading } = useSelector(
    state => state.accessRequestReducer?.approversGroups
  )

  const { deletingApproversGroups } = useSelector(
    state => state?.accessRequestReducer
  )

  // modal states
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isApproversGroupsModalOpen, setIsApproversGroupsModalOpen] = useState(false)
  const [modalBodyView, setModalBodyView] = useState(null)

  // Storing the changes done for the users and tags modal.
  const [selectedUsers, setSelectedUsers] = useState([])
  const [selectedTags, setSelectedTags] = useState([])
  const [tagToShowMembers, setTagToShowMembers] = useState(null)
  const [selectedApproversGroups, setSelectedApproversGroups] = useState([])

  // local states to store the changed fields
  const [updatedApprovers, setUpdatedApprovers] = useState({
    value: [],
    touched: false,
  })
  const [errorMessage, setErrorMessage] = useState({
    commonSettings: null,
    approversGroup: null,
    profileExpirationTimeout: null,
    approvalTimeout: null,
  })

  useEffect(() => {
    setSelectedAssociationApprovers(associationApprovers)
  }, [associationApprovers])

  useEffect(() => {
    setCurrentApprovalTimeout(approvalTimeout || '')
  }, [approvalTimeout])

  useEffect(() => {
    setCurrentProfileExpirationTimeout(
      getTimeDurationFromSeconds(
        profileExpirationTimeout * 60,
        !isAWSWithoutIDC(app?.catalogAppName)
      ) || ''
    )
  }, [profileExpirationTimeout])

  useEffect(() => {
    dispatch(fetchApproversGroups(app?.appContainerId))
    return () => {
      setUpdatedApprovers({
        value: [],
        touched: false,
      })
      dispatch(resetAssociationApproversSettings())
    }
  }, [])

  const updateApproversGroup = newApproversList => {
    setUpdatedApprovers({
      value: newApproversList || [],
      touched: true,
    })
  }

  const handleDeleteApprover = async approverId => {
    try {
      await dispatch(deleteApproversGroups(app?.appContainerId, approverId))
      dispatch(fetchApproversGroups(app.appContainerId))
      toast({
        title: 'Successfully removed association approver group',
        type: 'success',
        time: 'normal',
      })
    } catch (e) {
      toast({
        title: e.response?.data?.message,
        type: 'error',
        time: 'normal',
      })
    }
  }

  const handleDelete = id => {
    updateSettings({
      associationApprovers: selectedAssociationApprovers.filter(
        associationApprover => associationApprover.id !== id
      ),
    })
    setItemToDelete(null)
  }

  const rows = selectedAssociationApprovers
  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      sortable: true,
    },
    {
      headerName: 'Associations',
      width: '200px',
      sortable: true,
      field: 'associations',
      renderColumn: row => {
        return (
          <div className="cell-with-icon">
            <span>{row?.associations?.length}</span>
            <span
              className="cell-icon"
              onClick={() =>
                setSelectedEntity({ name: 'associations', entity: row })
              }
            >
              <img src={listIcon} />
            </span>
          </div>
        )
      },
    },
    {
      headerName: 'Approver Groups',
      width: '200px',
      sortable: true,
      field: 'approversGroups',
      renderColumn: row => {
        return (
          <div className="cell-with-icon">
            <span>{row?.approversGroups?.length}</span>
            <span
              className="cell-icon"
              onClick={() =>
                setSelectedEntity({ name: 'approversGroups', entity: row })
              }
            >
              <img src={listIcon} />
            </span>
          </div>
        )
      },
    },
    {
      headerName: 'Action',
      width: '120px',
      renderColumn: row => {
        const deleteIconClasses = {
          'action-icons-container': true,
          'disabled-icon': disableEditing,
        }
        return (
          <div className={classNames({ ...deleteIconClasses })}>
            <Tooltip title={'Edit'} position="left">
              <div
                className="action-icon"
                onClick={() => {
                  history.push(`association-approvers/${row.id}`)
                }}
              >
                <div>
                  <img src={disableEditing ? editIconDisabled : editIcon} />
                </div>
              </div>
            </Tooltip>
            <Tooltip title={'Delete'} position="left">
              <div
                className="action-icon"
                onClick={() => {
                  setItemToDelete(row.id)
                }}
              >
                <div>
                  <img src={disableEditing ? deleteIconDisabled : deleteIcon} />
                </div>
              </div>
            </Tooltip>
          </div>
        )
      },
    },
  ]

  const membersModalActionButtons = [
    {
      text: 'Close',
      variant: 'secondary',
      onClick: () => {
        setSelectedEntity(null)
      },
      size: 'medium',
    },
  ]
  const resetErrors = () =>
    setErrorMessage({
      commonSettings: null,
      approversGroup: null,
      profileExpirationTimeout: null,
      approvalTimeout: null,
    })

  const openModalToViewMembers = approverGroup => {
    const selectedGroupData = getApproversGroupData(
      approverGroup.id,
      selectedEntity.entity[selectedEntity.name]
    )
    setSelectedApproversGroupData(selectedGroupData)
  }

  const handleApproversGroupSave = async () => {
    const dataToUpdate = getApproversDataToUpdate(
      selectedApproversGroupData,
      approversGroups?.approversGroupSummary
    )

    const dataError = isApproversGroupDataValid(
      selectedApproversGroupData,
      dataToUpdate
    )

    if (dataError?.length) {
      setErrorMessage(prev => ({
        ...prev,
        approversGroup: dataError,
      }))
      return
    }

    try {
      resetErrors()
      await dispatch(
        updateApproversGroupSettings(
          dataToUpdate,
          app.appContainerId,
          selectedApproversGroupData.id
        )
      )
      closeModal()
      dispatch(fetchApproversGroups(app?.appContainerId))
      dispatch(fetchAccessRequestSettings(app?.appContainerId))
      toast({
        title: `Successfully ${
          selectedApproversGroupData.id ? 'updated' : 'added'
        } association approver group`,
        type: 'success',
        time: 'normal',
      })
    } catch (error) {
      const errorMessage = get(error, 'response.data.message', 'Unknown error')
      setErrorMessage(prev => ({
        ...prev,
        approversGroup: errorMessage || 'Unable to save the Approver Group.',
      }))
    }
  }

  const closeModal = () => {
    // resetting everything to initial state
    setIsModalOpen(false)
    setSelectedApproversGroupData(null)
    setSelectedUsers([])
    setSelectedTags([])
    resetErrors()
  }

  const openModal = approverGroupId => {
    if (approverGroupId) {
      const selectedGroupData = getApproversGroupData(
        approverGroupId,
        approversGroups?.approversGroupSummary
      )
      setSelectedApproversGroupData(selectedGroupData)
    } else {
      // setting the default value of the condition.
      setSelectedApproversGroupData({ condition: approverGroupCondition.all })
    }
    switchToConsolidatedView()
    resetErrors()
    setIsModalOpen(true)
  }

  const openModalAndViewMembers = selectedData => {
    setSelectedApproversGroupData(selectedData)
    setModalBodyView(modalView.membersOnly)
    setIsModalOpen(true)
  }

  const openModalToSelectApprovers = () => {
    setIsApproversGroupsModalOpen(true)
    setModalBodyView(modalView.selectApproversGroup)
  }

  const switchToConsolidatedView = () => setModalBodyView(modalView.consolidated)

  const onUserOrTagSave = () => {
    setSelectedApproversGroupData({
      ...selectedApproversGroupData,
      members: [
        ...(selectedApproversGroupData?.members || []),
        ...selectedUsers,
        ...selectedTags,
      ],
    })

    switchToConsolidatedView()
    setSelectedUsers([])
    setSelectedTags([])
  }

  const onMembersDelete = memberId => {
    setSelectedApproversGroupData({
      ...selectedApproversGroupData,
      members: selectedApproversGroupData?.members.filter(
        memberData => memberData.id !== memberId
      ),
    })
  }

  const handleApprovalTimeoutFocus = e => {
    if (!currentApprovalTimeout) {
      e.target.value = '00:00:00'
    }
  }

  const handleApprovalTimeoutBlur = e => {
    if (e.target.value === '00:00:00') {
      e.target.value = null
    }
  }

  const handleProfileExpirationFocus = e => {
    if (!currentProfileExpirationTimeout) {
      e.target.value = !isAWSWithoutIDC(app?.catalogAppName) ? '00:00:00' : '00:00'
    }
  }

  const handleProfileExpirationBlur = e => {
    if (e.target.value === '00:00:00' || e.target.value === '00:00') {
      e.target.value = null
    }
  }

  const secondaryButton = {
    text: 'Cancel',
    variant: 'secondary',
    onClick:
      modalBodyView === modalView.consolidated ||
      modalBodyView === modalView.membersOnly
        ? closeModal
        : switchToConsolidatedView,
    size: 'medium',
  }

  let modalButtons = [
    {
      text: modalBodyView === modalView.consolidated ? 'Save' : 'Add',
      variant: 'primary',
      onClick:
        modalBodyView === modalView.consolidated
          ? handleApproversGroupSave
          : onUserOrTagSave,
      size: 'medium',
      disabled:
        modalBodyView === modalView.consolidated
          ? characterCheck(selectedApproversGroupData?.name) ||
            isEmpty(
              getApproversDataToUpdate(
                selectedApproversGroupData || {},
                approversGroups?.approversGroupSummary
              )
            )
          : false,
    },
    secondaryButton,
  ]

  let modalButtonsForApproversGroupsSelection = [
    {
      text: 'Add',
      variant: 'primary',
      onClick: () => {
        updateApproversGroup([...updatedApprovers.value, ...selectedApproversGroups])
        setSelectedApproversGroups([])
        closeModal()
      },
      size: 'medium',
      disabled: !selectedApproversGroups.length,
    },
    {
      ...secondaryButton,
      onClick: () => {
        setIsApproversGroupsModalOpen(false)
        setSelectedApproversGroupData({})
        setSelectedUsers([])
        setSelectedTags([])
        resetErrors()
      },
    },
  ]

  if (modalBodyView === modalView.membersOnly) {
    modalButtons = [secondaryButton]
  }

  const renderErrorSnackbar = error => {
    if (error?.length) {
      return (
        <>
          <Snackbar errorList={[error]} />
          <br />
        </>
      )
    }
  }

  const deleteConfirmationDialog = {
    title: 'Delete Approver Group?',
    message: 'This will delete the Approver Group permanently.',
    primaryButtonText: 'Yes, Delete',
    secondaryButtonText: 'No',
  }

  return (
    <Accordion
      expanded
      title={<SectionTitle {...sectionTitle.associationApprovers} />}
    >
      {(approverGroupsLoading ||
        approversGroupsLoading ||
        deletingApproversGroups) && (
        <Spinner size="medium" message="Loading..." overlay />
      )}
      {renderErrorSnackbar(errorMessage.commonSettings)}
      <div className="section-container">
        <ApproversGroups
          rows={approversGroups?.approversGroupSummary}
          onDelete={handleDeleteApprover}
          onEditApproverGroup={openModal}
          onViewApproverGroupMember={openModalAndViewMembers}
          onSelectApproverGroup={openModalToSelectApprovers}
          deleteConfirmationDialog={deleteConfirmationDialog}
          deleteIconText="Delete"
          showEditIcon
          addApproverButton
          disableEditing={disableEditing}
        />
        {isEmpty(approversGroupsList) ? (
          <AddAssignmentBtn>
            <Button
              size="large"
              tooltipText="Add at least one Approver Group"
              tooltipPosition="right"
              disabled={disableEditing}
            >
              Add Assignment
            </Button>
          </AddAssignmentBtn>
        ) : (
          <Button
            size={'large'}
            onClick={() => history.push('association-approvers')}
            disabled={disableEditing}
          >
            Add Assignment
          </Button>
        )}
        {!!selectedAssociationApprovers?.length && (
          <div className="table-container">
            <Table columns={columns} tableData={rows} searchable={false} />
          </div>
        )}
        <TextfieldInlineEdit
          value={currentApprovalTimeout}
          label="Approval Timeout"
          secondaryLabel="*"
          helperText="In DD:HH:MM format"
          width="208px"
          onFocus={handleApprovalTimeoutFocus}
          onBlur={handleApprovalTimeoutBlur}
          error={errorMessage.approvalTimeout}
          errorMsg={errorMessage.approvalTimeout}
          disabled={disableEditing}
          onChange={value => {
            setErrorMessage(prev => ({
              ...prev,
              approvalTimeout: null,
            }))
            try {
              getSecondsFromTimeDuration(value)
              updateSettings({ approvalTimeOut: value })
            } catch (e) {
              setErrorMessage(prev => ({
                ...prev,
                approvalTimeout: e.message,
              }))
            }
          }}
        />
        <TextfieldInlineEdit
          value={currentProfileExpirationTimeout}
          label="Maximum allowed profile expiration timeout"
          secondaryLabel="*"
          helperText={`In ${
            !isAWSWithoutIDC(app?.catalogAppName) ? 'DD:' : ''
          }HH:MM format`}
          width="208px"
          onFocus={handleProfileExpirationFocus}
          onBlur={handleProfileExpirationBlur}
          error={errorMessage.profileExpirationTimeout}
          errorMsg={errorMessage.profileExpirationTimeout}
          disabled={disableEditing}
          onChange={value => {
            setErrorMessage(prev => ({
              ...prev,
              profileExpirationTimeout: null,
            }))
            try {
              updateSettings({
                profileExpirationTimeout:
                  getSecondsFromTimeDuration(
                    value,
                    !isAWSWithoutIDC(app?.catalogAppName)
                  ) / 60,
              })
            } catch (e) {
              setErrorMessage(prev => ({
                ...prev,
                profileExpirationTimeout: e.message,
              }))
            }
          }}
        />
      </div>
      {selectedEntity && (
        <ModalPopup
          width={720}
          title={`${
            selectedEntity.name === 'associations' ? 'Associations' : 'Approvers'
          } of ${selectedEntity.entity.name}`}
          buttons={membersModalActionButtons}
          onCancel={() => {
            setSelectedEntity(null)
            setSelectedApproversGroupData(null)
          }}
        >
          {selectedEntity?.name === 'approversGroups' && (
            <>
              <ApproversGroupList
                rows={selectedEntity.entity[selectedEntity.name]}
                onViewMembers={openModalToViewMembers}
              />
              {selectedApproversGroupData && (
                <ApproversGroupDetailsModal
                  approversGroup={selectedApproversGroupData}
                  onClose={() => setSelectedApproversGroupData(null)}
                />
              )}
            </>
          )}
          {selectedEntity?.name === 'associations' && (
            <AssociationsTree
              associations={selectedEntity.entity[selectedEntity.name]}
              hideCheckboxes
              hideDropdownFilter
              hideUnselectedAssociationType
            />
          )}
        </ModalPopup>
      )}
      {itemToDelete && (
        <DeleteConfirmationDialog
          entity="association approver"
          parentEntity="access builder settings"
          onDelete={() => handleDelete(itemToDelete)}
          onCancel={() => setItemToDelete(null)}
        />
      )}
      {isApproversGroupsModalOpen && (
        <ModalPopup
          width={720}
          title="Select Approver Groups"
          buttons={modalButtonsForApproversGroupsSelection}
          onCancel={closeModal}
        >
          <div className="association-group-modal-body">
            <ApproversGroupList
              rows={approversGroups?.approversGroupSummary || []}
              onViewMembers={openModalAndViewMembers}
              onChange={setSelectedApproversGroups}
              selectable
              selectedApproversGroup={selectedApproversGroups}
            />
          </div>
        </ModalPopup>
      )}

      {isModalOpen && (
        <ModalPopup
          width={720}
          title={getModalTitle(modalBodyView, selectedApproversGroupData)}
          buttons={modalButtons}
          onCancel={closeModal}
        >
          <div className="association-group-modal-body">
            {renderErrorSnackbar(errorMessage.approversGroup)}
            {(modalBodyView === modalView.consolidated ||
              modalBodyView === modalView.membersOnly) && (
              <ApproversGroupDetails
                data={selectedApproversGroupData}
                onChange={setSelectedApproversGroupData}
                onlyMembers={modalBodyView === modalView.membersOnly}
                onMembersDelete={onMembersDelete}
                onViewMembers={setTagToShowMembers}
                onAdd={view => {
                  setModalBodyView(view)
                }}
              />
            )}

            {modalBodyView === modalView.users && (
              <AddUserList
                currentIds={filterUsersIdsFromMembers(
                  selectedApproversGroupData.members
                )}
                selectedUsers={selectedUsers}
                onChange={newUsers => setSelectedUsers(newUsers)}
              />
            )}
            {modalBodyView === modalView.tags && (
              <AddTagsList
                currentIds={filterTagIdsFromMembers(
                  selectedApproversGroupData.members
                )}
                selectedTags={selectedTags}
                onChange={newTags => setSelectedTags(newTags)}
                onViewMembers={setTagToShowMembers}
              />
            )}
          </div>
        </ModalPopup>
      )}
      {tagToShowMembers && (
        <TagMembersModal
          tag={tagToShowMembers}
          onClose={() => setTagToShowMembers(null)}
        />
      )}
    </Accordion>
  )
}

AssociationApprovers.propTypes = {
  associationapprovers: PropTypes.array,
  approvalTimeout: PropTypes.string,
  profileExpirationTimeout: PropTypes.string,
  updateSettings: PropTypes.func.isRequired,
}

export default AssociationApprovers
