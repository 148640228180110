import React, { memo } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

import Button from 'britive-ui-components/core/components/Button'

function ConflictMessageModal({
  isOpen,
  properties: { message, actions, type },
  isUpdating,
}) {
  if (!isOpen) {
    return null
  }

  return (
    <Modal isOpen={isOpen} centered>
      <ModalHeader>{type} Association Error</ModalHeader>

      <ModalBody>{message}</ModalBody>

      <ModalFooter>
        {actions.cancel && <Button onClick={actions.cancel}>Cancel</Button>}

        {actions.ok && (
          <Button onClick={actions.ok} color="primary" spinner={isUpdating}>
            OK
          </Button>
        )}
      </ModalFooter>
    </Modal>
  )
}

export default memo(ConflictMessageModal)
