import React, { Fragment, memo, useState } from 'react'
import FontAwesome from 'react-fontawesome'
import get from 'lodash/get'
import {
  ActionIconLabel,
  ActionIconGroup,
} from 'britive-ui-components/core/components/ActionIcon'
import Button from 'britive-ui-components/core/components/Button'
import Spinner from 'britive-ui-components/core/components/Spinner'

import TableActionIcon from 'components/TableActionIcon'
import TimePeriodModal from '../TimePeriodModal'
import ConflictMessageModal from '../ConflictMessageModal'
import TimePeriod from '../TimePeriod'
import AddTagModal from './AddTagModal'
import IntegrityCheck from '../../IntegrityCheck'
import { getHeaderSpacing } from 'utils/elementMeasurement'
import Drawer from 'components/Drawer/DrawerContainer'
import TagsIntegrityCheck from './TagsIntegrityCheck'
import Table from 'components/table/TableV2/Table'

const styles = {
  button: {
    position: 'absolute',
    top: -64,
    right: 0,
  },
  tagNameWrapper: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  tagName: {
    flexGrow: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginRight: 8,
  },
  tagUsersIcon: {
    cursor: 'pointer',
  },
}

function PapTags({
  addTag,
  editTag,
  app,
  conflictModalProperties,
  isAddTagModalOpen,
  isAddTimePeriodTagOpen,
  isAddingTagToPap,
  isConflictMessageOpen,
  isEditTimePeriodTagOpen,
  isRemovingTagFromPap,
  papId,
  removeTag,
  selectedTag,
  toggleAddTagModal,
  toggleAddTimePeriodModal,
  toggleConflictMessageModal,
  toggleEditTimePeriodModal,
  toggleDrawer,
  refresh,
  thisAppManage,
}) {
  const [isIntegrityCheckDrawerOpen, setIsIntegrityCheckDrawerOpen] = useState(false)
  const [selectedTagId, setSelectedTagId] = useState(null)
  function getTableColumns() {
    let columns = [
      {
        Header: 'Tag',
        minWidth: 200,
        Cell: renderTagName,
        accessor: 'name',
        fixed: 'left',
      },
      ...(thisAppManage
        ? [
            {
              Header: 'Actions',
              accessor: 'actions',
              Cell: renderActionIcons,
              resizable: false,
              sortable: false,
              fixed: 'left',
              width: 100,
            },
          ]
        : []),
      { Header: 'Description', accessor: 'description', minWidth: 200 },
      { Header: 'Status', accessor: 'status', minWidth: 80 },
      { Header: 'Members', accessor: 'userCount', minWidth: 80, sortable: false },
      {
        Header: 'Time Period',
        minWidth: 120,
        Cell: TimePeriod,
        sortable: false,
      },
    ]

    if (app.catalogApplication.requiresAccountMapping) {
      columns.splice(3, 0, {
        Header: 'Integrity Check',
        Cell: renderIntegrityChecks,
        accessor: 'checkStatus',
        minWidth: 125,
        sortable: false,
      })
    }

    return columns
  }

  function toggleIntegrityCheckDrawer() {
    setIsIntegrityCheckDrawerOpen(!isIntegrityCheckDrawerOpen)
  }
  function renderIntegrityChecks({ original }) {
    return (
      <IntegrityCheck
        checkStatus={original.checkStatus}
        message={original.message}
        name={original.name}
        selected={original.userTagId}
        onClick={tag => {
          toggleIntegrityCheckDrawer()
          setSelectedTagId(tag)
        }}
      />
    )
  }
  function renderTagName({ original: data }) {
    return (
      <div style={styles.tagNameWrapper}>
        <span style={styles.tagName}>{data.name}</span>

        <FontAwesome
          name="user-friends"
          style={styles.tagUsersIcon}
          onClick={() => {
            toggleDrawer({
              type: 'TagUsers',
              tag: data,
              drawerId: `tagDrawer${data.userTagId}`,
            })
          }}
        />
      </div>
    )
  }

  function renderActionIcons({ original: tag }) {
    const isRemoving = isRemovingTagFromPap[tag.userTagId]

    return (
      <ActionIconGroup>
        <TableActionIcon onClick={() => removeTag({ papId, tagId: tag.userTagId })}>
          {isRemoving ? (
            <Spinner />
          ) : (
            <Fragment>
              <span className="fa fa-trash fs:14" />
              <ActionIconLabel>Delete</ActionIconLabel>
            </Fragment>
          )}
        </TableActionIcon>

        {get(tag, 'accessPeriod.start') && (
          <TableActionIcon
            onClick={() => !isRemoving && toggleEditTimePeriodModal(tag)}
          >
            <span className="fa fa-clock fs:14" />
            <ActionIconLabel>Edit time period</ActionIconLabel>
          </TableActionIcon>
        )}
      </ActionIconGroup>
    )
  }

  return (
    <Fragment>
      {thisAppManage && (
        <Button onClick={toggleAddTagModal} color="primary" style={styles.button}>
          Add Tags
        </Button>
      )}

      <Table
        columns={getTableColumns()}
        identifier="userTagId"
        dataUrl={`/paps/${papId}/user-tags`}
        emptyTableMessage={`No tags assigned. ${
          thisAppManage ? 'Click Add Tags to assign new tags to this profile.' : ''
        }`}
        refresh={refresh}
      />

      <Drawer
        isOpen={isIntegrityCheckDrawerOpen}
        top={`${getHeaderSpacing()}px`}
        toggleDrawer={toggleIntegrityCheckDrawer}
      >
        <TagsIntegrityCheck papId={papId} tagId={selectedTagId} />
      </Drawer>
      <AddTagModal
        papId={papId}
        isOpen={isAddTagModalOpen}
        isAddingTagToPap={isAddingTagToPap}
        isAddTimePeriodTagOpen={isAddTimePeriodTagOpen}
        toggleAddTagModal={toggleAddTagModal}
        toggleAddTimePeriodModal={toggleAddTimePeriodModal}
        filterDataSet={{}}
        addTag={addTag}
      />

      <TimePeriodModal
        actionSubject={selectedTag}
        isOpen={isAddTimePeriodTagOpen || isEditTimePeriodTagOpen}
        getTitle={
          isAddTimePeriodTagOpen
            ? tag => `Add Tag - ${tag.name}`
            : tag => `Change Time Period Access for Tag - ${tag.name}`
        }
        toggleModal={
          isAddTimePeriodTagOpen
            ? toggleAddTimePeriodModal
            : toggleEditTimePeriodModal
        }
        actionFunction={isAddTimePeriodTagOpen ? addTag : editTag}
        isUpdating={isAddingTagToPap}
        pageName="papTag"
        identifier="userTagId"
      />

      <ConflictMessageModal
        isOpen={isConflictMessageOpen}
        isUpdating={isAddingTagToPap[get(selectedTag, 'userTagId')]}
        properties={conflictModalProperties}
        toggleConflictMessageModal={toggleConflictMessageModal}
      />
    </Fragment>
  )
}

export default memo(PapTags)
