import { UPDATE_NAVIGATION, EXPAND_NAVIGATION } from 'actions'

export function updateNavigation(new_path) {
  return {
    type: UPDATE_NAVIGATION,
    payload: new_path,
  }
}

export function expandNavigation(option) {
  return {
    type: EXPAND_NAVIGATION,
    payload: option,
  }
}
