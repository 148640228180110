import React, { useCallback, useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import startCase from 'lodash/startCase'

import ApplicationRouter from './ApplicationRouter'
import Breadcrumbs from 'components/breadcrumbs'
import PageLoader from 'components/PageLoader'
import isKubernetes from 'utils/application/isKubernetes'
import isOpenshift from 'utils/application/isOpenshift'
import AppTreeSelect from './AppTreeSelect'

import { getElementHeight } from 'utils/elementMeasurement'

import * as text from 'translations/english_us'
import './application.scss'

const Wrapper = styled.div`
  margin: -20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Sidebar = styled.div`
  display: inline-flex;
  position: sticky;
  height: calc(100vh - 48px);
  top: 0;
  width: 25px;
  overflow: hidden;
  transition: width 0.1s;

  .arrow {
    flex: 1;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;

    > i {
      background: var(--brand-light);
      height: 40px;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0 25px 25px 0;
      width: 25px;
      margin-left: 0;
    }
  }

  &.expanded {
    width: 212px;
    > .arrow > i {
      border-radius: 25px 0 0 25px;
      margin-left: -25px;
    }
  }
`

const MenuContainer = styled.div`
  background: var(--gallery);
  flex: 1;

  > ul {
    list-style: none;
    padding: 0;
    margin-top: 20px;
    display: none;
  }

  > ul > li {
    padding: 10px;
  }

  &.expanded {
    > ul {
      display: block;
      width: 190px;
    }

    > ul > li:hover {
      background: var(--periwinkle-gray);
    }

    > ul > li > a {
      color: var(--brand-light);
    }
  }
`

const Content = styled.div`
  display: inline-flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  height: calc(100vh - 48px);

  .tree-select {
    min-width: 640px;
  }
`

const AppHeader = styled.div`
  padding: 8px;
  position: sticky;
  top: 0;
  z-index: 5;
  background: var(--black-squeeze);

  .logoWrapper {
    display: inline-flex;
    align-items: center;
    width: 100%;
    padding: 0 28px 0 10px;

    > span {
      max-width: 300px;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-right: 16px;
      white-space: nowrap;
    }
  }

  .appLogo {
    max-height: 32px;
    widht: 32px;
    margin-right: 16px;
  }

  .ant-select-tree-treenode-disabled {
    > .ant-select-tree-node-content-wrapper,
    > .ant-select-tree-node-content-wrapper > span {
      color: rgba(0, 0, 0, 0.65);
      cursor: default;
    }
  }
`

const styles = {
  activeLink: {
    fontWeight: 'bold',
    color: 'var(--endeavour)',
  },
}

function Application({
  activitiesForAppEnabled,
  activitiesFeatureEnabled,
  appIcon,
  appName,
  app,
  environmentGroups,
  environmentData,
  isLoaded,
  isTemplate,
  entityType,
  environments,
  environmentScanResults,
  match,
  breadcrumbTrail,
  isHierarchical,
  rootGroupId,
  showTreeSelect,
  selectedEntityId,
  selectedEntity,
  selectEntity,
  setBreadcrumbTrail,
  supportsScanning,
  tabsList,
  refreshApplication,
  isRefreshing,
  thisAppManage,
  requestAccess,
  applicationTemplate,
}) {
  const [collapse, setCollapse] = useState(false)
  const [appHeaderHeight, setAppHeaderHeight] = useState(0)
  const appHeaderEl = useRef(null)

  const isk8s = isKubernetes(app?.catalogAppName)
  const isOpenShift = isOpenshift(app?.catalogAppName)
  const allowedTabsForK8AndOpenshift = ['properties', 'profiles']

  const appHeaderRef = useCallback(node => {
    if (node !== null) {
      setAppHeaderHeight(getElementHeight(node))
      appHeaderEl.current = node
    }
  })

  const handleNavLinkClick = (e, isDisabled) => {
    if (isDisabled) {
      e.preventDefault()
    }
  }

  function renderBreadcrumbs() {
    return (
      <Breadcrumbs>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              {requestAccess ? (
                <NavLink to="/request-access">{'Access Builder'}</NavLink>
              ) : (
                <NavLink to="/admin">{text.SYSTEM_ADMINISTRATION}</NavLink>
              )}
            </li>

            {!requestAccess && (
              <li className="breadcrumb-item">
                <NavLink to="/admin/tenant-apps">{text.TENANT_APPS}</NavLink>
              </li>
            )}

            {breadcrumbTrail.map((page, idx) => {
              if (idx === breadcrumbTrail.length - 1) {
                return (
                  <li
                    className="breadcrumb-item active"
                    aria-current="page"
                    key={page.title}
                  >
                    {page.title}
                  </li>
                )
              }

              return (
                <li key={page.title} className="breadcrumb-item">
                  <NavLink to={`${match.url}/${page.path}`}>{page.title}</NavLink>
                </li>
              )
            })}
          </ol>
        </nav>
      </Breadcrumbs>
    )
  }

  const renderAppHeader = () => (
    <AppHeader
      ref={appHeaderRef}
      style={{ backgroundColor: requestAccess && '#e6eff6' }}
    >
      {renderBreadcrumbs()}

      <div className="logoWrapper">
        <img className="appLogo" src={appIcon} alt="app logo" />
        {isTemplate ? (
          <span title="Create Application">Create Application</span>
        ) : (
          <span title={appName}>{appName}</span>
        )}

        {showTreeSelect && (
          <AppTreeSelect
            app={app}
            environments={environments}
            environmentScanResults={environmentScanResults}
            entityType={entityType}
            environmentData={environmentData}
            environmentGroups={environmentGroups}
            rootGroupId={rootGroupId}
            selectedEntity={selectedEntity}
            selectEntity={selectEntity}
            isHierarchical={isHierarchical}
            supportsScanning={supportsScanning}
            refreshApplication={refreshApplication}
            isRefreshing={isRefreshing}
            applicationTemplate={applicationTemplate}
          />
        )}
      </div>
    </AppHeader>
  )

  if (!isTemplate && !isLoaded) {
    return <PageLoader text="Loading Application" />
  }

  const expandedClass = !collapse ? 'expanded' : ''
  const isAssociationApproversSettingsActive = window.location.href.includes(
    'association-approvers'
  )

  return (
    <Wrapper>
      {!requestAccess && !isAssociationApproversSettingsActive && (
        <Sidebar className={expandedClass}>
          <MenuContainer className={expandedClass}>
            <ul>
              {(isk8s || isOpenShift ? allowedTabsForK8AndOpenshift : tabsList).map(
                tab => {
                  const disableTab =
                    isk8s && !allowedTabsForK8AndOpenshift.includes(tab)
                  return (
                    <li
                      key={tab}
                      className={disableTab ? 'disabled-application-tab-li' : ''}
                    >
                      <NavLink
                        className={disableTab ? 'disabled-application-tab' : ''}
                        onClick={e => handleNavLinkClick(e, disableTab)}
                        to={`${match.url}/${tab}`}
                        activeStyle={styles.activeLink}
                        isActive={(_, location) => {
                          if (tab === 'properties') {
                            return (
                              location.pathname.includes(`${match.url}/${tab}`) ||
                              location.pathname.includes(
                                '/admin/applications/create-properties'
                              )
                            )
                          }

                          return location.pathname.includes(`${match.url}/${tab}`)
                        }}
                      >
                        {tab === 'access-request-settings'
                          ? 'Access Builder Settings'
                          : startCase(tab)}
                      </NavLink>
                    </li>
                  )
                }
              )}

              {activitiesFeatureEnabled && activitiesForAppEnabled && !isk8s && (
                <li>
                  <NavLink
                    to={`${match.url}/activity`}
                    activeStyle={styles.activeLink}
                  >
                    Activity
                  </NavLink>
                </li>
              )}
            </ul>
          </MenuContainer>

          <div className="arrow" onClick={() => setCollapse(!collapse)}>
            <i className={'fas fa-chevron-' + (!collapse ? 'left' : 'right')} />
          </div>
        </Sidebar>
      )}

      <Content>
        {!isAssociationApproversSettingsActive && renderAppHeader()}

        <ApplicationRouter
          match={match}
          appHeaderHeight={appHeaderHeight}
          setBreadcrumbTrail={setBreadcrumbTrail}
          entityType={entityType}
          selectEntity={selectEntity}
          selectedEntityId={selectedEntityId}
          app={app}
          thisAppManage={thisAppManage}
          requestAccess={requestAccess}
        />
      </Content>
    </Wrapper>
  )
}
export default Application
