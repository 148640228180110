import React, { memo } from 'react'
import uuid from 'uuid'

import TableSection from 'components/table/tableSectionV2'
import RenderActionButton from './RenderActionButton'

import useMaxTableHeight from 'hooks/useMaxTableHeight'
import Table from 'components/table/TableV2/Table'
import isAzureApp from 'utils/application/isAzure'
import isGcpApp from 'utils/application/isGcp'

function MapUserTable({
  innerRef,
  mapUser,
  unmapUser,
  users,
  mapping,
  heightNodes,
  isUpdatingUser,
  dataUrl,
  params,
  refresh,
  catalogAppName,
  accountType,
}) {
  const isGCP = isGcpApp(catalogAppName)
  const isAzure = isAzureApp(catalogAppName)
  const maxTableHeight =
    useMaxTableHeight({
      heightNodes,
      // substitute for margin adjustment
      // maybe need a better way here
      heights: [80],
    }) / 2
  const mapUserColumns = [
    { Header: 'First Name', accessor: 'firstName' },
    { Header: 'Last Name', accessor: 'lastName' },
    { Header: 'Email', accessor: 'email' },
  ]
  const getLabel = () =>
    (isGCP && accountType === 'serviceAccount') ||
    (isAzure && accountType === 'Service Principal')
      ? 'Service Identity'
      : 'Username'
  const getUserColumns = () =>
    (!isGCP || (isGCP && accountType !== 'serviceAccount')) &&
    (!isAzure || (isAzure && accountType !== 'Service Principal'))
      ? mapUserColumns
      : []
  return (
    <div ref={innerRef} style={{ margin: 5 }}>
      {mapping ? (
        <Table
          maxHeight={maxTableHeight}
          columns={[
            ...getUserColumns(),
            {
              Header: getLabel(),
              accessor: 'username',
            },
            {
              Header: 'Actions',
              accessor: 'actions',
              resizable: false,
              sortable: false,
              width: 75,
              Cell: data => (
                <RenderActionButton
                  key={uuid()}
                  mapUser={mapUser}
                  unmapUser={unmapUser}
                  user={data.original}
                  mappingUser={mapping}
                  isUpdatingUser={isUpdatingUser[data.original.userId]}
                />
              ),
            },
          ]}
          identifier="name"
          dataUrl={dataUrl}
          params={params}
          emptyTableMessage={'No available users were found'}
          refresh={refresh}
          noUrlQueryParams
        />
      ) : (
        <TableSection
          data={users}
          searchKeys={['firstName', 'lastName', 'email', 'username']}
          identifier="userId"
          columns={[
            ...getUserColumns(),
            {
              Header: getLabel(),
              accessor: 'username',
            },
            {
              Header: 'Actions',
              accessor: 'actions',
              resizable: false,
              sortable: false,
              width: 75,
              Cell: data => (
                <RenderActionButton
                  key={uuid()}
                  mapUser={mapUser}
                  unmapUser={unmapUser}
                  user={data.original}
                  mappingUser={mapping}
                  isUpdatingUser={isUpdatingUser[data.original.userId]}
                />
              ),
            },
          ]}
          emptyTableMessage="No User found."
          showPagination
          sortable
          defaultSorted={[{ id: 'firstName', desc: false }]}
          maxHeight={maxTableHeight}
        />
      )}
    </div>
  )
}

export default memo(MapUserTable)
