import { createSelector } from 'reselect'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'

import { getStateSliceFor } from './index'
import { getSelectedApplicationRootId } from './application_root'

import { environmentReducerKey as reducerKey } from 'reducers/environment'

/** Returns the state slice for application root. */
const extractFrom = state => getStateSliceFor({ state, reducerKey })

export const getEnvironmentGroups = state => extractFrom(state).environmentGroups
export const getEnvironments = state => extractFrom(state).environments
export const getFetchingEnvironments = state =>
  extractFrom(state).fetchingEnvironments
export const getEnvironmentScanResults = state =>
  extractFrom(state).environmentScanResults
export const getEnvironmentAppData = state => extractFrom(state).environmentAppData
export const getAccessEnvironmentAppData = state =>
  extractFrom(state).accessEnvironmentAppData
export const getSelectedEnvironmentId = state =>
  extractFrom(state).selectedEnvironmentId
export const getSelectedEnvironmentGroupId = state =>
  extractFrom(state).selectedEnvironmentGroupId
export const getFetchingEnvironmentScansReport = state =>
  extractFrom(state).fetchingEnvironmentScansReport

/** Returns the selected application root data */
export const getSelectedApplicationRootGroupId = createSelector(
  state => getSelectedApplicationRootId(state),
  state => getEnvironmentGroups(state),
  (appId, groups) => {
    if (appId && !isEmpty(groups)) {
      const appGroups = get(groups, appId, {})

      const group = Object.values(appGroups).find(group => !group.nativeParentId)
      return get(group, 'id', null)
    }

    return null
  }
)

/** Returns the environments for selected application */
export const getApplicationEnvironments = createSelector(
  state => getSelectedApplicationRootId(state),
  state => getEnvironments(state),
  (appId, enviroments) => {
    if (appId && !isEmpty(enviroments)) {
      return enviroments[appId] || {}
    }

    return {}
  }
)

/** Returns the environment data for selected application */
export const getSelectedApplicationEnvironments = createSelector(
  state => getSelectedApplicationRootId(state),
  state => getEnvironmentAppData(state),
  (appId, enviromentAppData) => {
    if (appId && !isEmpty(enviromentAppData)) {
      return enviromentAppData[appId] || {}
    }

    return {}
  }
)

/** Returns the access environments for selected application */
export const getSelectedAccessApplicationAccessEnvironments = createSelector(
  state => getSelectedApplicationRootId(state),
  state => getAccessEnvironmentAppData(state),
  (appId, accessEnvironmentAppData) => {
    if (appId && !isEmpty(accessEnvironmentAppData)) {
      return accessEnvironmentAppData[appId] || {}
    }

    return {}
  }
)

/** Returns the environment groups for selected application */
export const getSelectedApplicationEnvironmentGroups = createSelector(
  state => getSelectedApplicationRootId(state),
  state => getEnvironmentGroups(state),
  (appId, groupData) => {
    if (appId && !isEmpty(groupData)) {
      return groupData[appId] || {}
    }

    return {}
  }
)

/** Returns the selected environment app data */
export const getSelectedEnvironmentData = createSelector(
  state => getSelectedApplicationEnvironments(state),
  state => getSelectedEnvironmentId(state),
  (applicationEnvironments, envId) => {
    if (envId && !isEmpty(applicationEnvironments)) {
      return applicationEnvironments[envId] || {}
    }

    return {}
  }
)

/** Returns the selected environment group data */
export const getSelectedEnvironmentGroupData = createSelector(
  state => getSelectedApplicationEnvironmentGroups(state),
  state => getSelectedEnvironmentGroupId(state),
  (appEnvGroups, groupId) => {
    if (groupId && !isEmpty(appEnvGroups)) {
      return appEnvGroups[groupId] || {}
    }

    return {}
  }
)

/** Returns the environment properties objects for the selected application */
export const getSelectedApplicationEnvironmentProperties = createSelector(
  state => getSelectedApplicationRootId(state),
  state => getEnvironments(state),
  (appId, environments) => {
    if (appId && !isEmpty(environments)) {
      return environments[appId] || {}
    }

    return {}
  }
)

/** Returns the selected environment properties for an environment*/
export const getSelectedEnvironmentProperties = createSelector(
  state => getSelectedApplicationEnvironmentProperties(state),
  state => getEnvironments(state),
  (environments, envId) => {
    if (envId && !isEmpty(environments)) {
      return environments[envId] || {}
    }

    return {}
  }
)

/** Returns the environment scan results for an application */
export const getSelectedApplicationEnvironmentScanResults = createSelector(
  state => getSelectedApplicationRootId(state),
  state => getEnvironmentScanResults(state),
  (appId, results) => {
    if (appId && !isEmpty(results)) {
      return results[appId] || {}
    }

    return {}
  }
)

/** Returns boolean for if you are scanning an environment in app */
export const getIsScanningAppEnvironments = createSelector(
  ({ state }) => getFetchingEnvironmentScansReport(state),
  isFetchingScanReports => {
    return isFetchingScanReports
  }
)
