// REFERENCE: https://stackoverflow.com/questions/1349404/generate-random-string-characters-in-javascript#answer-27747377

// dec2hex :: Integer -> String
// i.e. 0-255 -> '00'-'ff'
function dec2hex(dec) {
  return ('0' + dec.toString(16)).substr(-2)
}

// generateId :: Integer -> String
export default function generateRandomString(len) {
  var arr = new Uint8Array((len || 40) / 2)
  const cryptoObj = window.crypto || window.msCrypto
  cryptoObj.getRandomValues(arr)
  return Array.from(arr, dec2hex).join('')
}

export function base64URLEncode(str) {
  return str
    .toString('base64')
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '')
}
