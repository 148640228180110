import {
  CREATE_PAP,
  DELETE_PAP,
  UPDATE_PERMISSION,
  FETCH_PAPS_FOR_APP,
  FETCH_PAP,
  FETCH_PAP_ADDITIONAL_SETTINGS,
  FETCH_PAP_USERS,
  FETCH_SCOPES_FOR_PAP,
  FETCH_PAP_PERMISSIONS,
  UPDATE_PAP_SCOPE,
  UPDATE_PAP_RESOURCES_SCOPE,
  UPDATE_PAP,
  UPDATE_PAP_STATUS,
  UPDATE_PAP_ADDITIONAL_SETTINGS,
  RESET_PAP_PERMISSIONS_DATA,
  FETCH_PERMISSION_FINDINGS,
} from 'action_creators/pap'

export const papsReducerKey = 'paps'

const initialState = {
  pap: null,
  papAdditionalSettings: {},
  appPaps: {},
  papPermissions: {},
  papUsers: {},
  papScopes: {},
  error: null,
  fetchingPaps: false,
  fetchingAppPaps: false,
  fetchingPap: false,
  fetchingPapAdditionalSettings: false,
  fetchingPapScopes: false,
  fetchingPapUsers: false,
  fetchingPapPermissions: false,
  creatingPap: false,
  deletingPap: false,
  updatingPap: false,
  updatingPapAdditionalSettings: false,
  updatingScope: false,
  fetchingAvailableTags: false,
  fetchingAssignedTags: false,
  updatingPermission: false,
  updatePapAdditionalSettingsError: '',
  isFetchingFindings: false,
  permissionFindings: {},
}

export function papsReducer(state = initialState, action) {
  switch (action.type) {
    case `${FETCH_PAPS_FOR_APP}_PENDING`:
      return {
        ...state,
        fetchingAppPaps: true,
      }

    case `${FETCH_PAPS_FOR_APP}_FULFILLED`: {
      const appPaps = {}
      action.payload.data.forEach(pap => {
        appPaps[pap.papId] = pap
      })
      return {
        ...state,
        appPaps,
        fetchingAppPaps: false,
      }
    }

    case `${FETCH_PAPS_FOR_APP}_REJECTED`:
      return {
        ...state,
        appPaps: {},
        fetchingAppPaps: false,
        error: action.payload,
      }

    case `${FETCH_PAP}_PENDING`:
      return {
        ...state,
        pap: null,
        fetchingPap: true,
        fetchingPaps: true,
      }

    case `${FETCH_PAP}_FULFILLED`: {
      const paps = { ...state.paps }
      const { data } = action.payload

      paps[data.papId] = data

      return {
        ...state,
        paps,
        pap: data,
        fetchingPap: false,
        fetchingPaps: false,
      }
    }

    case `${FETCH_PAP}_REJECTED`:
      return {
        ...state,
        fetchingPap: false,
        fetchingPaps: false,
        error: action.payload,
      }

    case `${FETCH_PAP_ADDITIONAL_SETTINGS}_PENDING`:
      return {
        ...state,
        papAdditionalSettings: null,
        fetchingPapAdditionalSettings: true,
      }

    case `${FETCH_PAP_ADDITIONAL_SETTINGS}_FULFILLED`: {
      return {
        ...state,
        papAdditionalSettings: action.payload.data?.additionalSettings,
        fetchingPapAdditionalSettings: false,
      }
    }

    case `${FETCH_PAP_ADDITIONAL_SETTINGS}_REJECTED`:
      return {
        ...state,
        fetchingPapAdditionalSettings: false,
        error: action.payload,
      }

    case `${FETCH_PAP_PERMISSIONS}_PENDING`: {
      const papPermissions = { ...state.papPermissions }

      papPermissions[action.meta.papId] = []
      return {
        ...state,
        papPermissions,
        fetchingPapPermissions: {
          ...state.fetchingPapPermissions,
          [action.meta.papId]: true,
        },
      }
    }
    case `${FETCH_PAP_PERMISSIONS}_FULFILLED`: {
      const papPermissions = { ...state.papPermissions }
      const { data } = action.payload

      papPermissions[action.meta.papId] = data

      return {
        ...state,
        papPermissions,
        fetchingPapPermissions: {
          ...state.fetchingPapPermissions,
          [action.meta.papId]: false,
        },
      }
    }

    case `${FETCH_PAP_PERMISSIONS}_REJECTED`:
      return {
        ...state,
        fetchingPapPermissions: {
          ...state.fetchingPapPermissions,
          [action.meta.papId]: false,
        },
        error: action.payload,
      }

    case RESET_PAP_PERMISSIONS_DATA: {
      const papPermissions = { ...state.papPermissions }
      papPermissions[action.payload.papId] = []

      return {
        ...state,
        papPermissions,
      }
    }

    case `${FETCH_PAP_USERS}_PENDING`:
      return {
        ...state,
        fetchingPapUsers: {
          ...state.fetchingPapUsers,
          [action.meta.papId]: true,
        },
      }

    case `${FETCH_PAP_USERS}_FULFILLED`: {
      const papUsers = { ...state.papUsers }
      const { data } = action.payload

      papUsers[action.meta.papId] = data

      return {
        ...state,
        papUsers,
        fetchingPapUsers: {
          ...state.fetchingPapUsers,
          [action.meta.papId]: false,
        },
      }
    }

    case `${FETCH_PAP_USERS}_REJECTED`:
      return {
        ...state,
        fetchingPapUsers: {
          ...state.fetchingPapUsers,
          [action.meta.papId]: false,
        },
        error: action.payload,
      }

    case `${FETCH_SCOPES_FOR_PAP}_PENDING`:
      return {
        ...state,
        fetchingPapScopes: true,
      }

    case `${FETCH_SCOPES_FOR_PAP}_FULFILLED`: {
      const papScopes = { ...state.papScopes }
      const { data } = action.payload

      papScopes[action.meta.papId] = data

      return {
        ...state,
        papScopes,
        fetchingPapScopes: false,
      }
    }

    case `${FETCH_SCOPES_FOR_PAP}_REJECTED`:
      return {
        ...state,
        fetchingPapScopes: false,
        error: action.payload,
      }

    case `${CREATE_PAP}_PENDING`:
      return {
        ...state,
        creatingPap: true,
      }

    case `${CREATE_PAP}_FULFILLED`: {
      const appPap = action.payload.data

      const appPaps = {
        ...state.appPaps,
        [appPap.papId]: appPap,
      }

      return {
        ...state,
        appPaps,
        creatingPap: false,
      }
    }

    case `${CREATE_PAP}_REJECTED`:
      return {
        ...state,
        creatingPap: false,
        error: action.payload,
      }

    case `${DELETE_PAP}_PENDING`:
      return {
        ...state,
        deletingPap: true,
      }

    case `${DELETE_PAP}_FULFILLED`: {
      const { papId } = action.meta
      const appPaps = {
        ...state.appPaps,
      }
      delete appPaps[papId]

      return {
        ...state,
        appPaps,
        deletingPap: false,
      }
    }

    case `${DELETE_PAP}_REJECTED`:
      return {
        ...state,
        deletingPap: false,
        error: action.payload,
      }

    case `${UPDATE_PAP_SCOPE}_PENDING`:
      return {
        ...state,
        updatingScope: true,
      }

    case `${UPDATE_PAP_SCOPE}_FULFILLED`: {
      const { papId } = action.meta
      return {
        ...state,
        papScopes: {
          ...state.papScopes,
          [papId]: action.payload.data,
        },
        updatingScope: false,
      }
    }

    case `${UPDATE_PAP_SCOPE}_REJECTED`:
      return {
        ...state,
        updatingScope: false,
        error: action.payload,
      }

    case `${UPDATE_PAP_RESOURCES_SCOPE}_PENDING`:
      return {
        ...state,
        updatingScope: true,
      }

    case `${UPDATE_PAP_RESOURCES_SCOPE}_FULFILLED`: {
      return {
        ...state,
        updatingScope: false,
      }
    }

    case `${UPDATE_PAP_RESOURCES_SCOPE}_REJECTED`:
      return {
        ...state,
        updatingScope: false,
        error: action.payload,
      }

    case `${UPDATE_PAP}_PENDING`:
      return {
        ...state,
        updatingPap: true,
      }

    case `${UPDATE_PAP}_FULFILLED`: {
      const appPaps = {
        ...state.appPaps,
        [action.payload.data.papId]: action.payload.data,
      }

      return {
        ...state,
        appPaps,
        updatingPap: false,
      }
    }

    case `${UPDATE_PAP}_REJECTED`:
      return {
        ...state,
        updatingPap: false,
        error: action.payload,
      }

    case `${UPDATE_PAP_ADDITIONAL_SETTINGS}_PENDING`:
      return {
        ...state,
        updatingPapAdditionalSettings: true,
        updatePapAdditionalSettingsError: null,
      }

    case `${UPDATE_PAP_ADDITIONAL_SETTINGS}_FULFILLED`: {
      return {
        ...state,
        papAdditionalSettings: action.payload.data,
        updatingPapAdditionalSettings: false,
        updatePapAdditionalSettingsError: null,
      }
    }

    case `${UPDATE_PAP_ADDITIONAL_SETTINGS}_REJECTED`:
      return {
        ...state,
        updatingPapAdditionalSettings: false,
        updatePapAdditionalSettingsError:
          action.payload?.response?.data?.message ||
          'Unable to update the Additional Settings.',
      }

    case `${UPDATE_PAP_STATUS}_PENDING`:
      return {
        ...state,
        updatingPap: true,
      }

    case `${UPDATE_PAP_STATUS}_FULFILLED`: {
      const { papId, status } = action.payload.data

      const newPap = {
        ...state.appPaps[papId],
        status,
      }

      const appPaps = {
        ...state.appPaps,
        [papId]: newPap,
      }

      return {
        ...state,
        appPaps,
        updatingPap: false,
      }
    }

    case `${UPDATE_PAP_STATUS}_REJECTED`:
      return {
        ...state,
        updatingPap: false,
        error: action.payload,
      }

    case `${UPDATE_PERMISSION}_PENDING`:
      return {
        ...state,
        updatingPermission: true,
      }

    case `${UPDATE_PERMISSION}_FULFILLED`: {
      const { papId, op, permission } = action.meta

      let permissions = [...state.papPermissions[papId]]
      if (op === 'remove') {
        permissions = permissions.filter(p => p.name !== permission.name)
      } else {
        permissions.push(action.payload.data)
      }
      return {
        ...state,
        papPermissions: {
          ...state.papPermissions,
          [papId]: permissions,
        },
        updatingPermission: false,
      }
    }

    case `${UPDATE_PERMISSION}_REJECTED`:
      return {
        ...state,
        updatingPermission: false,
        error: action.payload,
      }
    case `${FETCH_PERMISSION_FINDINGS}_PENDING`: {
      return {
        ...state,
        isFetchingFindings: true,
      }
    }
    case `${FETCH_PERMISSION_FINDINGS}_FULFILLED`: {
      return {
        ...state,
        isFetchingFindings: false,
        permissionFindings: action.payload?.data?.findings,
      }
    }
    case `${FETCH_PERMISSION_FINDINGS}_REJECTED`:
      return {
        ...state,
        isFetchingFindings: false,
        permissionFindings: {},
      }
    default:
      return { ...state }
  }
}
