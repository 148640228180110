import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import * as actions from 'actions'

const initialState = {
  loading: false,
  error: null,
  data: [],
  credentials: {},
  hasMoreData: false,
  totalCount: 0,
  params: {},
}

const getUpdatedProfilesListAndCreds = (state, updatedProfileData) => {
  const profileIndex = state.data.findIndex(
    profile =>
      profile.profileId === updatedProfileData.profileId &&
      profile.resourceId === updatedProfileData.resourceId
  )

  const updatedData = [...state.data]
  if (profileIndex > -1) {
    updatedData[profileIndex] = {
      ...state.data[profileIndex],
      transactionId: updatedProfileData.transactionId,
      status: updatedProfileData.status,
      expirationDuration: updatedProfileData.expirationDuration,
      favoriteId:
        updatedProfileData?.favoriteId || state.data[profileIndex]?.favoriteId,
    }
  }

  const updatedCredentials = { ...state.credentials }
  if (
    !isEmpty(
      get(
        updatedCredentials,
        `${updatedProfileData.profileId}-${updatedProfileData.resourceId}`
      )
    )
  ) {
    delete updatedCredentials[
      `${updatedProfileData.profileId}-${updatedProfileData.resourceId}`
    ]
  }

  return {
    data: updatedData,
    credentials: updatedCredentials,
  }
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case `${actions.MY_RESOURCES_GET_PROFILES}_PENDING`: {
      return {
        ...state,
        loading: true,
        error: null,
      }
    }

    case `${actions.MY_RESOURCES_GET_PROFILES}_FULFILLED`: {
      const { params } = action.meta
      const response = get(action, 'payload.data', {})
      const { count, page, data = [] } = response
      let updatedData = []

      if (page === 0) {
        updatedData = data
      } else {
        updatedData = [...state.data, ...data]
      }

      return {
        ...state,
        loading: false,
        error: null,
        data: updatedData,
        hasMoreData: updatedData.length < count,
        totalCount: count,
        params,
      }
    }

    case `${actions.MY_RESOURCES_GET_PROFILES}_REJECTED`: {
      return {
        ...state,
        loading: false,
        error: 'Unable fetch the Broker Pools.',
      }
    }

    case `${actions.MY_RESOURCES_CHECKOUT_PROFILE}_FULFILLED`: {
      return {
        ...state,
        ...getUpdatedProfilesListAndCreds(state, action.payload.data),
      }
    }

    case `${actions.MY_RESOURCES_CHECKIN_PROFILE}_FULFILLED`: {
      return {
        ...state,
        ...getUpdatedProfilesListAndCreds(state, action.payload.data),
      }
    }

    case `${actions.MY_RESOURCES_SUBMIT_CHECKOUT_REQUEST}_FULFILLED`: {
      const { profileId, resourceId } = action.meta
      const profileIndex = state.data.findIndex(
        profile =>
          profile.profileId === profileId && profile.resourceId === resourceId
      )

      const updatedItem = {
        ...state.data[profileIndex],
        status: 'Pending',
      }

      return {
        ...state,
        ...getUpdatedProfilesListAndCreds(state, updatedItem),
      }
    }

    case `${actions.MY_RESOURCES_WITHDRAW_APPROVAL_REQUEST}_FULFILLED`: {
      const { profileId, resourceId } = action.meta
      const profileIndex = state.data.findIndex(
        profile =>
          profile.profileId === profileId && profile.resourceId === resourceId
      )

      const updatedItem = {
        ...state.data[profileIndex],
        status: 'ApprovalRequired',
      }

      return {
        ...state,
        ...getUpdatedProfilesListAndCreds(state, updatedItem),
      }
    }

    case `${actions.MY_RESOURCES_ADD_FAVORITE}_FULFILLED`: {
      const { data: response } = action.payload
      const profileIndex = state.data.findIndex(
        profile =>
          profile.profileId === response?.profileId &&
          profile.resourceId === response?.resourceId
      )

      const updatedItem = {
        ...state.data[profileIndex],
        favoriteId: response?.favoriteId,
      }

      return {
        ...state,
        ...getUpdatedProfilesListAndCreds(state, updatedItem),
      }
    }

    case `${actions.MY_RESOURCES_DELETE_FAVORITE}_FULFILLED`: {
      const { profileId, resourceId } = action.meta
      const profileIndex = state.data.findIndex(
        profile =>
          profile.profileId === profileId && profile.resourceId === resourceId
      )

      const updatedItem = {
        ...state.data[profileIndex],
        favoriteId: null,
      }

      return {
        ...state,
        ...getUpdatedProfilesListAndCreds(state, updatedItem),
      }
    }

    case actions.MY_RESOURCES_CHECKED_OUT_NOTIFICATION: {
      return {
        ...state,
        ...getUpdatedProfilesListAndCreds(state, action.payload),
      }
    }

    case actions.MY_RESOURCES_CHECK_OUT_FAILED_NOTIFICATION: {
      return {
        ...state,
        ...getUpdatedProfilesListAndCreds(state, action.payload),
      }
    }

    case actions.MY_RESOURCES_CHECKED_IN_NOTIFICATION: {
      return {
        ...state,
        ...getUpdatedProfilesListAndCreds(state, action.payload),
      }
    }

    case actions.MY_RESOURCES_CHECK_IN_FAILED_NOTIFICATION: {
      return {
        ...state,
        ...getUpdatedProfilesListAndCreds(state, action.payload),
      }
    }

    case actions.MY_RESOURCES_CHECK_OUT_REJECTED_NOTIFICATION: {
      return {
        ...state,
        ...getUpdatedProfilesListAndCreds(state, action.payload),
      }
    }

    case actions.MY_RESOURCES_CHECK_IN_TIMEOUT_NOTIFICATION: {
      return {
        ...state,
        ...getUpdatedProfilesListAndCreds(state, action.payload),
      }
    }

    case actions.MY_RESOURCES_CHECK_OUT_TIMEOUT_NOTIFICATION: {
      return {
        ...state,
        ...getUpdatedProfilesListAndCreds(state, action.payload),
      }
    }

    case actions.MY_RESOURCES_REQUEST_APPROVED_NOTIFICATION: {
      return {
        ...state,
        ...getUpdatedProfilesListAndCreds(state, action.payload),
      }
    }

    case actions.MY_RESOURCES_CHECK_OUT_APPROVAL_EXPIRED: {
      return {
        ...state,
        ...getUpdatedProfilesListAndCreds(state, action.payload),
      }
    }

    case `${actions.MY_RESOURCES_GET_CREDENTIALS}_FULFILLED`: {
      const { profileId, resourceId } = action.meta

      return {
        ...state,
        credentials: {
          ...state.credentials,
          [`${profileId}-${resourceId}`]: action.payload.data,
        },
      }
    }

    default:
      return { ...state }
  }
}
