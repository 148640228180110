import React from 'react'
import styled from 'styled-components'
import Tooltip from 'britive-design-system/core/components/tooltip'
import infoIcon from 'static/images/infoIcon.svg'

const TooltipWrapper = styled.div`
  display: inline-block;

  img {
    height: 16px;
  }
`

const SectionTitle = ({ title, isMandatory = false, tooltipMessage }) => {
  return (
    <>
      {title}
      {isMandatory ? '*' : ''}&nbsp;
      <TooltipWrapper>
        <Tooltip title={tooltipMessage} position="top">
          <img src={infoIcon} style={{ height: '16px' }} />
        </Tooltip>
      </TooltipWrapper>
    </>
  )
}

export default SectionTitle
