import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import startCase from 'lodash/startCase'

import ModalPopup from 'britive-design-system/core/components/modal-popup'
import Snackbar from 'britive-design-system/core/components/snackbar'
import Spinner from 'britive-design-system/core/components/spinner'
import Select from 'britive-design-system/core/components/select'
import Typography from 'britive-design-system/core/components/typography'
import Textfield from 'britive-design-system/core/components/textfield'

import { fetchNotificationMediums } from 'action_creators/notification-mediums'
import styled from 'styled-components'
import doFetch from 'utils/do_fetch'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  .field-container {
    h6 {
      margin-bottom: 8px;
    }
  }
`

const NotificationMediumSelector = ({
  onAdd,
  onCancel,
  error,
  selectedNotificationMediums,
  currentEditingMedium = {},
}) => {
  const dispatch = useDispatch()

  const [notificationMedium, setNotificationMedium] = useState(null)
  const [validatingChannels, setValidatingChannels] = useState(false)
  const [validationError, setValidationError] = useState(null)

  const {
    data: notificationMediums,
    loading,
    error: notificationMediumFetchError,
  } = useSelector(state => state.notificationMediumsReducer?.notificationMediums)

  const isEditing = !isEmpty(currentEditingMedium)

  useEffect(() => {
    isEmpty(notificationMediums) && dispatch(fetchNotificationMediums())
  }, [])

  useEffect(() => {
    if (isEditing) {
      setNotificationMedium(currentEditingMedium)
    }
  }, [JSON.stringify(currentEditingMedium)])

  const options = useMemo(
    () =>
      notificationMediums?.filter(
        notificationMedium =>
          !selectedNotificationMediums?.some(
            existingNotificationMedium =>
              notificationMedium.name === existingNotificationMedium.name
          ) && notificationMedium.type !== 'webhook'
      ) || [],
    [selectedNotificationMediums, notificationMediums]
  )

  const handleAddNotification = async () => {
    if (!notificationMedium.channels?.length) {
      onAdd(notificationMedium)
      return
    }
    setValidatingChannels(true)
    try {
      await doFetch({
        path: `/v1/notification-service/notificationmediums/${notificationMedium.id}/channels/validate`,
        method: 'post',
        postBody: notificationMedium.channels,
      })
      setValidatingChannels(false)
      onAdd(notificationMedium)
    } catch (error) {
      setValidationError(
        error.response?.data?.message ||
          'Channels validation failed! Please try again.'
      )
      setValidatingChannels(false)
    }
  }

  const props = {
    options,
    value: notificationMedium,
    label: 'Notification Medium',
    width: '548px',
    placeholder: options.length
      ? 'Select a notification medium'
      : 'No notification medium found',
    disabled: options.length === 0,
    onChange: (event, value) => {
      setNotificationMedium(value)
    },
    getOptionLabel: function(option) {
      return option.name
    },
    disablePortal: true,
  }

  const buttons = [
    {
      text: isEditing ? 'Save' : 'Add',
      variant: 'primary',
      onClick: handleAddNotification,
      size: 'medium',
      disabled: !notificationMedium,
    },
    {
      text: 'Close',
      variant: 'secondary',
      onClick: onCancel,
      size: 'medium',
    },
  ]

  return (
    <ModalPopup
      width={720}
      title={isEditing ? currentEditingMedium?.name : 'Add Notification'}
      buttons={buttons}
      onCancel={onCancel}
    >
      <Container>
        {(loading || validatingChannels) && (
          <Spinner
            size={'medium'}
            message={loading ? 'Loading...' : 'Validating Channels...'}
            overlay
          />
        )}
        {error?.length && <Snackbar errorList={error} />}
        {(notificationMediumFetchError || validationError) && (
          <Snackbar errorList={[notificationMediumFetchError, validationError]} />
        )}
        {!isEditing && (
          <div className="field-container">
            <Select {...props} />
          </div>
        )}
        {notificationMedium && (
          <>
            <div className="field-container">
              <Typography variant="heading7">Application</Typography>
              <Typography variant="label1">
                {startCase(notificationMedium.type)}
              </Typography>
            </div>
            <div className="field-container">
              <Typography variant="heading7">Description</Typography>
              <Typography variant="label1">
                {notificationMedium.description}
              </Typography>
            </div>
            {notificationMedium.type === 'slack' && (
              <Textfield
                type="text"
                helperText="Comma separated list of channels"
                label="Channel List"
                value={notificationMedium?.channels?.join(',') || ''}
                onChange={e => {
                  e.persist()
                  setNotificationMedium({
                    ...notificationMedium,
                    channels: e.target.value.length
                      ? e.target.value.split(',').map(channel => channel.trim())
                      : null,
                  })
                }}
              />
            )}
          </>
        )}
      </Container>
    </ModalPopup>
  )
}

export default NotificationMediumSelector
