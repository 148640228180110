import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import * as dataType from './../../constants'
import FormField, {
  RadioButtons,
  CheckBoxBoolean,
  UploadFile,
} from 'components/form_fields'
import UserAttributeSchema from 'components/UserAttributeSchema'
import {
  getTimeDurationFromSeconds,
  getSecondsFromTimeDuration,
} from 'utils/format_date'
import {
  isAWSWithoutIDC,
  getAppName,
} from '../../../Profiles/components/pap-profile/utils'

class EditableProperty extends PureComponent {
  // State added for handling secret type
  // wouldn't need it if API alterted me
  // if a value had been set for the secret
  // type instead of just returning '*' for
  // the value
  state = { isDirty: false, currentValue: '', error: '' }

  getValue = () => {
    const { value } = this.props
    return value ? value : ''
  }

  getSecretValue = () => {
    const { inputValue } = this.props
    const { isDirty } = this.state

    if (isDirty) {
      return inputValue ? inputValue : ''
    } else {
      return ''
    }
  }

  onSecretChange = e => {
    const { onChange } = this.props
    const { isDirty } = this.state

    if (!isDirty) {
      this.setState({ isDirty: true })
    }

    onChange(e)
  }

  onTimeDurationChange = e => {
    this.setState({ error: '' })
    this.setState({ currentValue: e.target.value })
  }

  onTimeDurationBlur = e => {
    this.setState({ error: '' })
    const { onChange, appRoot } = this.props
    try {
      e.target.value = getSecondsFromTimeDuration(
        this.state.currentValue,
        !isAWSWithoutIDC(getAppName(appRoot))
      )
      onChange(e)
    } catch (error) {
      this.setState({ error: error.message })
    }
  }

  componentDidMount() {
    const { value, type, appRoot } = this.props
    if (type === dataType.INPUT_TIME_DURATION) {
      this.setState({
        currentValue: getTimeDurationFromSeconds(
          value,
          !isAWSWithoutIDC(getAppName(appRoot))
        ),
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { value, type, appRoot } = this.props
    if (prevProps.value !== value && type === dataType.INPUT_TIME_DURATION) {
      this.setState({
        currentValue: getTimeDurationFromSeconds(
          value,
          !isAWSWithoutIDC(getAppName(appRoot))
        ),
      })
    }
  }

  render() {
    const {
      name,
      displayName,
      type,
      defaultValue,
      inputValue,
      editable = true,
      value,
      fields,
      onChange,
      enumValues,
      enumNameMap,
      parent,
      subPropertiesMap,
      propertyTypes,
      appRoot,
      error,
    } = this.props
    const { isDirty } = this.state
    switch (type) {
      case dataType.INPUT_STRING:
        return (
          <FormField
            hideLabel={false}
            type={
              name === 'password' || name === 'securityToken' ? 'password' : 'text'
            }
            name={name}
            label={displayName}
            className="col-5"
            placeholder={displayName}
            disabled={!editable}
            value={this.getValue()}
            onChange={onChange}
            dataparent={parent}
            error={error}
          />
        )

      case dataType.INPUT_TIME_DURATION:
        return (
          <FormField
            hideLabel={false}
            type="text"
            name={name}
            label={`${displayName} (In ${
              !isAWSWithoutIDC(getAppName(appRoot)) ? 'DD:' : ''
            }HH:MM format)`}
            className="col-5"
            placeholder={displayName}
            disabled={!editable}
            value={this.state.currentValue}
            onChange={this.onTimeDurationChange}
            dataparent={parent}
            onBlur={this.onTimeDurationBlur}
            error={this.state.error}
          />
        )

      case dataType.INPUT_PASSWORD:
        return (
          <FormField
            hideLabel={false}
            label={displayName}
            name={name}
            type="password"
            className="col-5"
            disabled={!editable}
            value={this.getSecretValue()}
            onChange={this.onSecretChange}
            dataparent={parent}
            placeholder={value === '*' && !isDirty ? 'hidden' : name}
          />
        )
      case dataType.INTEGER:
        return (
          <FormField
            hideLabel={false}
            label={displayName}
            name={name}
            type="number"
            className="col-5"
            disabled={!editable}
            value={this.getValue()}
            onChange={onChange}
            dataparent={parent}
          />
        )

      case dataType.RADIO:
        return (
          <RadioButtons
            name={name}
            label={displayName}
            onChange={onChange}
            value={value ? value : defaultValue}
            enumValues={enumValues}
            enumNameMap={enumNameMap}
            disabled={!editable}
            className="form-check-property"
            dataparent={parent}
            fields={fields}
            subPropertiesMap={subPropertiesMap}
            propertiesMap={subPropertiesMap && subPropertiesMap[name]}
            propertyTypes={propertyTypes}
          />
        )
      case dataType.BOOLEAN:
        return (
          <CheckBoxBoolean
            name={name}
            label={displayName}
            onChange={onChange}
            className="form-group form-check-property"
            checked={inputValue === undefined ? value : inputValue}
            disabled={!editable}
            dataparent={parent}
          />
        )
      case dataType.FILE:
      case dataType.SECRET_FILE:
        return (
          <UploadFile
            hideLabel={false}
            name={name}
            label={displayName}
            className="col-4"
            placeholder={name}
            disabled={!editable}
            value={this.getValue()}
            onChange={onChange}
            dataparent={parent}
          />
        )
      case dataType.USER_ATTRIBUTE_SCHEMA:
        return (
          <UserAttributeSchema
            name={name}
            label={displayName}
            placeholder={displayName}
            disabled={!editable}
            value={this.getValue()}
            onChange={onChange}
          />
        )
      default:
        return null
    }
  }
}

EditableProperty.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  editable: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func,
  fields: PropTypes.object,
  subPropertiesMap: PropTypes.object,
  enumValues: PropTypes.array,
  parent: PropTypes.string,
  displayName: PropTypes.string,
}

export default EditableProperty
