import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import ModalPopup from 'britive-design-system/core/components/modal-popup'
import Textfield from 'britive-design-system/core/components/textfield'
import Snackbar from 'britive-design-system/core/components/snackbar'
import Spinner from 'britive-design-system/core/components/spinner'
import Typography from 'britive-design-system/core/components/typography'
import isNumeric from 'utils/isNumeric'
import { mfaFactor } from '../../routes/user_settings/constants'
import { authenticateStepUpMFA } from 'action_creators/user/userMfa'
import './index.scss'

const CSS_CLASSNAME_PREFIX = 'step-up'

const StepUpOTPModal = ({ mfaAuthenticate, onClose }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [mfaOtp, setMfaOtp] = useState('')

  // Checking if the otp contains only numeric values.
  const isValidOtp = () => isNumeric(mfaOtp)

  const validateOtp = async () => {
    dispatch(authenticateStepUpMFA(mfaFactor.totp, mfaOtp))
  }
  return (
    <>
      <ModalPopup
        width={480}
        title={'Step-up Verification'}
        buttons={[
          {
            text: 'Proceed to Checkout',
            variant: 'primary',
            size: 'medium',
            disabled: mfaOtp?.length < 4 || !isValidOtp(),
            onClick: validateOtp,
          },
          {
            text: 'Cancel',
            variant: 'secondary',
            size: 'medium',
            onClick: () => onClose(false),
          },
        ]}
        onCancel={() => onClose(false)}
      >
        <div>
          {mfaAuthenticate.error?.length && (
            <>
              <Snackbar errorList={[mfaAuthenticate.error]} />
              <br />
            </>
          )}
          <Typography variant="label1">
            {`Please enter the OTP from your Authenticator app.`}
          </Typography>
          <br />
          <Textfield
            label={''}
            value={mfaOtp}
            width="170px"
            onChange={e => setMfaOtp(e.target.value)}
            error={mfaOtp?.length ? !isValidOtp() : false}
            errorMsg={mfaOtp?.length && !isValidOtp() ? 'Invalid OTP' : ''}
          />
          <br />
          <br />
          <div className={`${CSS_CLASSNAME_PREFIX}-checkout-step-up-modal-footer`}>
            <Typography variant="label1">
              {`Not registered your MFA? Please register it under the `}
              <div
                className={`${CSS_CLASSNAME_PREFIX}-checkout-step-up-modal-footer-user-setting-link`}
                onClick={() => history.push('/user-settings')}
              >
                User Settings
              </div>
            </Typography>
          </div>
        </div>
      </ModalPopup>

      {mfaAuthenticate.isAuthenticating ? (
        <Spinner size="medium" message="" overlay />
      ) : null}
    </>
  )
}

export default StepUpOTPModal
