import React, { memo, useEffect, useState } from 'react'

import Snackbar from 'britive-design-system/core/components/snackbar'
import BritiveLogo from 'components/BritiveLogo'

import './../login.scss'

const CLI = () => {
  const [fromCli, setFromCli] = useState(null)
  useEffect(() => {
    setFromCli(sessionStorage.getItem('cli'))
    sessionStorage.removeItem('cli')
  }, [])

  return (
    <div className="login-container">
      <div className="section">
        <BritiveLogo height={32} />
        {fromCli ? (
          <div className="cli-message">
            ✔ CLI is ready. You can close the browser now.
          </div>
        ) : (
          <Snackbar errorList={['Something went wrong. Please try again!']} />
        )}
      </div>
      <div className="section">
        <aside className="login-side-image" />
      </div>
    </div>
  )
}

export default memo(CLI)
