import React, { PureComponent } from 'react'
import styled from 'styled-components'
import FontAwesome from 'react-fontawesome'
import get from 'lodash/get'
import classNames from 'classnames'

const BLACKLIST_KEYS = {
  // id: true,
  // timestamp: true,
  // tenantId: true,
}

const BLACKLIST_DATA_KEYS = {}

const Wrapper = styled.div`
  border-top: 1px solid black;
  background: var(--gallery);
  padding: 8px;
`

const DropdownIcon = styled(FontAwesome)`
  cursor: pointer;
  margin-right: 4px;
  font-size: 12px;
  transform: rotate(90deg);

  &.open {
    transform: rotate(180deg);
  }

  &.hidden {
    visibility: hidden;
  }
`

const DataTable = styled.table`
  background: var(--gallery);
  margin-bottom: 4px;

  td {
    padding: 0 4px;
    white-space: nowrap;
    background: var(--gallery);

    &.lastColumn {
      padding: 2px 4px;
      width: 99%;
      max-width: 400px;
      white-space: normal;
      word-wrap: break-word;
    }
  }
`
const DataWrapper = styled.div`
  margin-bottom: 8px;
`

class ActivityDropdownItem extends PureComponent {
  state = { isOpen: false }

  toggleDropdown = () => {
    const { isOpen } = this.state

    this.setState({ isOpen: !isOpen })
  }

  formatDataValue = value => {
    if (typeof value === 'boolean') {
      return value.toString()
    }

    if (!value) {
      return null
    }

    if (typeof value === 'object') {
      const objectString = JSON.stringify(value)
      return objectString === '{}' ? null : objectString
    }

    return value
  }

  renderData = (rowData, dataName) => {
    const { isOpen } = this.state
    const value = get(rowData, `[${dataName}]`)
    const isObject = typeof value === 'object'
    const className = classNames({ open: isOpen, hidden: !isObject })

    if (!isObject) {
      return (
        <DataWrapper>
          <span>
            {dataName}: {this.formatDataValue(value)}
          </span>
        </DataWrapper>
      )
    } else {
      return (
        <DataWrapper>
          <div>
            <DropdownIcon
              className={className}
              onClick={this.toggleDropdown}
              name="chevron-up"
            />
            {dataName}
          </div>

          {isOpen && (
            <DataTable>
              <tbody>
                {!get(rowData, `[${dataName}]`) ? (
                  <tr>
                    <td className="lastColumn">No Data</td>
                  </tr>
                ) : (
                  Object.keys(value).map(dataKey => {
                    const formattedDataValue = this.formatDataValue(
                      get(rowData, `[${dataName}][${dataKey}]`)
                    )

                    if (BLACKLIST_DATA_KEYS[dataKey] || !formattedDataValue) {
                      return null
                    }

                    return (
                      <tr key={dataKey}>
                        <td>--</td>
                        <td>{dataKey}</td>

                        <td className="lastColumn">{formattedDataValue}</td>
                      </tr>
                    )
                  })
                )}
              </tbody>
            </DataTable>
          )}
        </DataWrapper>
      )
    }
  }

  render() {
    const { dataName, data } = this.props

    if (BLACKLIST_KEYS[dataName]) {
      return null
    }

    return <div key={dataName}>{this.renderData(data, dataName)}</div>
  }
}

export default function AuditDropdown({ data }) {
  return (
    <Wrapper>
      {Object.keys(data).map(key => (
        <ActivityDropdownItem data={data} key={key} dataName={key} />
      ))}
    </Wrapper>
  )
}
