import React, { useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import Button from 'britive-design-system/core/components/button'
import Textfield from 'britive-design-system/core/components/textfield'
import Tooltip from 'britive-design-system/core/components/tooltip'
import RadioGroup from 'britive-design-system/core/components/radio'
import deleteIcon from 'static/images/delete-icon.svg'
import { approverGroupCondition, modalView } from '../constants'
import { MembersCountCell } from './MembersCountCell'
import { addFullNamesToTheUsers, getTagMembers, characterCheck } from '../utils'
import Table from './Table'
import { isEmpty } from 'lodash'

export const ApproversGroupDetails = ({
  data,
  onAdd,
  onChange,
  onlyMembers = false,
  onMembersDelete,
  onViewMembers,
}) => {
  const [rows, setRows] = useState([])

  const { tags, fetchingTags } = useSelector(state => state.tags)
  const { users, fetchingUsers } = useSelector(state => state.admin)

  useEffect(() => {
    setRows(data?.members || [])
  }, [JSON.stringify(data)])

  const handleNameChange = value => {
    onChange({
      ...data,
      name: value,
    })
  }

  const handleApprovalConditionChange = value => {
    onChange({
      ...data,
      condition: value,
    })
  }

  const tableData = useMemo(() => addFullNamesToTheUsers(users.User, rows), [
    rows,
    users,
  ])

  const tableCoumns = [
    {
      field: 'name',
      headerName: 'Name',
      sortable: true,
    },
    {
      headerName: 'Type',
      field: 'memberType',
      sortable: true,
    },
    {
      headerName: 'Members',
      renderColumn: row => {
        if (row.memberType === 'User') {
          return 'N/A'
        }

        let tagMembers = getTagMembers(tags, row.id)

        if (isEmpty(tagMembers)) {
          tagMembers = {
            ...row,
            userTagId: row?.id,
          }
        }

        return (
          <MembersCountCell
            count={tagMembers?.userCount}
            onIconClick={() => onViewMembers(tagMembers)}
          />
        )
      },
    },
  ]

  if (!onlyMembers) {
    tableCoumns.push({
      headerName: 'Action',
      width: '120px',
      renderColumn: row => {
        return (
          <div className="action-icons-container">
            <div className="action-icon" onClick={() => onMembersDelete(row.id)}>
              <Tooltip title={'Delete'} position="left">
                <div>
                  <img src={deleteIcon} />
                </div>
              </Tooltip>
            </div>
          </div>
        )
      },
    })
  }

  const renderButtons = () => (
    <div className="popup-add-users-tags-buttons">
      <Button onClick={() => onAdd(modalView.users)}>Select Users</Button>
      <Button onClick={() => onAdd(modalView.tags)}>Select Tags</Button>
    </div>
  )

  const renderTable = () => {
    if (rows.length) {
      return (
        <Table
          key={data?.id}
          columns={tableCoumns}
          tableData={tableData}
          isLoading={fetchingTags || fetchingUsers}
        />
      )
    }
  }

  if (onlyMembers) {
    return <>{renderTable()}</>
  }

  return (
    <div key={data?.id || 'new-association-group-details'}>
      <Textfield
        label="Name"
        value={data?.name}
        onChange={e => handleNameChange(e.target.value)}
        width="256px"
        error={characterCheck(data?.name)}
        errorMsg="Name must be between 1 and 128 characters"
      />
      <br />
      <RadioGroup
        label="Approval Condition"
        name="approval-condition"
        defaultValue={data?.condition || approverGroupCondition.all}
        direction="horizontal"
        options={[
          {
            label: 'All members',
            value: approverGroupCondition.all,
          },
          {
            label: 'Any member',
            value: approverGroupCondition.any,
          },
        ]}
        onChange={e => handleApprovalConditionChange(e.target.value)}
      />
      <br />
      {renderButtons()}
      {renderTable()}
    </div>
  )
}
