import React from 'react'
import { connect } from 'react-redux'
import MyRequests from './MyRequests'
import {
  requestMyRequestsList,
  requestMyRequestsListLoadMore,
  requestUpdateMyRequestsListSearchTerm,
  withdrawRequest,
} from '../../../action_creators/my-requests'

const mapStateToProps = state => {
  const {
    myRequestsList: myRequestsListState,
  } = state?.userSecretsReducer?.myRequestReducer
  return {
    myRequestsListState,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getMyRequestsListData: () => dispatch(requestMyRequestsList()),
    updateRequestsListSearchTerm: search => {
      dispatch(requestUpdateMyRequestsListSearchTerm(search))
    },
    getMyRequestsListDataLoadMore: () => dispatch(requestMyRequestsListLoadMore()),
    withdrawRequest: requestId => dispatch(withdrawRequest(requestId)),
  }
}

const MyRequestsConnectedWithStore = connect(
  mapStateToProps,
  mapDispatchToProps
)(MyRequests)

// eslint-disable-next-line react/display-name
const MyRequestsContainer = ({ match, history }) => {
  return <MyRequestsConnectedWithStore match={match} history={history} />
}

export default MyRequestsContainer
