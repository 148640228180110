import { MAIN_HEADER_ID } from 'components/app_chrome/header'
import { CONTAINER_CONTENT_ID } from 'app/main_layout'

export function getHeaderSpacing() {
  const header = document.getElementById(MAIN_HEADER_ID)

  return parseInt(header.offsetHeight)
}

export function getElementHeight(element) {
  const contentStyles = window.getComputedStyle(element)
  const { height } = element.getBoundingClientRect()

  const mTop = contentStyles.getPropertyValue('margin-top').split('px')[0]
  const pTop = contentStyles.getPropertyValue('padding-top').split('px')[0]
  const mBottom = contentStyles.getPropertyValue('margin-bottom').split('px')[0]
  const pBottom = contentStyles.getPropertyValue('padding-bottom').split('px')[0]

  return (
    height + parseInt(mTop) + parseInt(pTop) + parseInt(mBottom) + parseInt(pBottom)
  )
}

export function getElementWidth(element) {
  const contentStyles = window.getComputedStyle(element)
  const { width } = element.getBoundingClientRect()
  const marginLeft = contentStyles.getPropertyValue('margin-right').split('px')[0]
  const marginRight = contentStyles.getPropertyValue('margin-left').split('px')[0]

  return width + parseInt(marginLeft) + parseInt(marginRight)
}

export function getHeaderContainerSpacing() {
  const content = document.getElementById(CONTAINER_CONTENT_ID)
  const contentStyles = window.getComputedStyle(content)
  const marginTop = contentStyles.getPropertyValue('margin-top').split('px')[0]
  const marginBottom = contentStyles.getPropertyValue('margin-bottom').split('px')[0]

  return getHeaderSpacing() + parseInt(marginTop) + parseInt(marginBottom)
}

export function getNodeFullHeight({ node }) {
  let nodeHeight = node.offsetHeight

  nodeHeight += parseInt(
    window.getComputedStyle(node).getPropertyValue('margin-top')
  )

  nodeHeight += parseInt(
    window.getComputedStyle(node).getPropertyValue('margin-bottom')
  )

  return nodeHeight
}
