import { preFetchV2 } from 'utils/do_fetch'

export function fetchAccountChanges({ appId, envId }) {
  return preFetchV2({
    name: 'fetchAccountChanges',
    path: `/apps/${appId}/environments/${envId}/accounts/memberships-last-scan-delta`,
    method: 'get',
  })
}

export function fetchPermissionChanges({ appId, envId }) {
  return preFetchV2({
    name: 'fetchPermissionChanges',
    path: `/apps/${appId}/environments/${envId}/permissions/memberships-last-scan-delta`,
    method: 'get',
  })
}

export function fetchGroupChanges({ appId, envId }) {
  return preFetchV2({
    name: 'fetchGroupChanges',
    path: `/apps/${appId}/environments/${envId}/groups/memberships-last-scan-delta`,
    method: 'get',
  })
}

export function fetchPermissionDefinitions({ appId, envId, permissionId }) {
  return preFetchV2({
    name: 'fetchPermissionDefinitions',
    path: `/apps/${appId}/environments/${envId}/permissions/${permissionId}/definitions`,
    method: 'get',
  })
}

export function fetchPermissionDefinitionDifferences({
  appId,
  envId,
  permissionId,
}) {
  return preFetchV2({
    name: 'fetchPermissionDefinitions',
    path: `/apps/${appId}/environments/${envId}/permissions/${permissionId}/definitions/differences`,
    method: 'get',
  })
}
