export const preparePropertiesObjectForAPI = properties => {
  return mapProperties(properties, {})
}

// recursively find 'propertyTypes object'
const mapProperties = (properties, mappedProps) => {
  const BLACK_LIST = ['userAccountMappings']

  for (const prop in properties) {
    if (BLACK_LIST.includes(prop)) {
      continue
    }

    if ({}.hasOwnProperty.call(properties, prop)) {
      if (properties[prop]['propertyTypes']) {
        // process again
        let props = mapProperties(properties[prop], mappedProps)
        mappedProps = {
          ...mappedProps,
          [prop]: props,
        }
      } else {
        // root
        let propertyArray = []
        for (const prop2 in properties[prop]) {
          if ({}.hasOwnProperty.call(properties[prop], prop2)) {
            let aa = {
              name: prop2,
              value: properties[prop][prop2],
              defaultValue: properties[prop][prop2],
            }
            propertyArray.push(aa)
          }
        }
        mappedProps = {
          ...mappedProps,
          [prop]: propertyArray,
        }
      }
    }
  }
  return mappedProps
}

export const preparePropertiesObjectForUI = (properties, catalogApplication) => {
  let propertiesDetailsForUI = {}
  const propsList = fullPropertiesArray(properties, [])
  propsList.forEach(propName => {
    propertiesDetailsForUI[propName] = getPropertyTypeDetails(
      catalogApplication,
      propName
    )
  })

  // there is an issue with true / false being marked as "true" and "false"
  // unfortunately the browser interprets "false" as truthy.
  // this will fix that :
  propertiesDetailsForUI = accuratelyMarkTruthyValues(propertiesDetailsForUI)
  return propertiesDetailsForUI
}

// recursively find all of the properties
const fullPropertiesArray = (properties, mappedProps) => {
  for (const prop in properties) {
    if ({}.hasOwnProperty.call(properties, prop)) {
      if (properties[prop]['properties']) {
        let currentLevelProperties = properties[prop]['properties']
        for (let i = 0; i < currentLevelProperties.length; i++) {
          mappedProps.push(currentLevelProperties[i])
        }
      } else {
        return fullPropertiesArray(properties[prop]['groups'], mappedProps)
      }
    }
  }
  return mappedProps
}

const getPropertyTypeDetails = (catalogApplication, propertyName) => {
  if (catalogApplication && catalogApplication.propertyTypes && propertyName) {
    let propertyDetails = {}

    // attempt to find it in the `propertyTypes` object in the root first:
    propertyDetails = catalogApplication.propertyTypes.find(
      prop => prop.name === propertyName
    )
    // if we're unable to find the details in the root search the whole
    // catalog object for a propertyTypes key

    if (!propertyDetails) {
      for (const prop in catalogApplication) {
        if ({}.hasOwnProperty.call(catalogApplication, prop)) {
          let entry = catalogApplication[prop]

          // if it contains propertyTypes search it for the propName
          if (entry && entry.propertyTypes) {
            propertyDetails = entry.propertyTypes.find(
              prop => prop.name === propertyName
            )
            // if a match is found, save the location where it came from as parent
            if (propertyDetails) {
              propertyDetails['parent'] = prop
              return propertyDetails
            }
          }
        }
      }
    }
    const propertyTypeDetails = {
      ...propertyDetails,
    }

    return propertyTypeDetails
  }
}

// hopefully we can remove this soon
const accuratelyMarkTruthyValues = propMap => {
  for (const prop in propMap) {
    if ({}.hasOwnProperty.call(propMap, prop)) {
      let entry = propMap[prop]
      if (entry.type === 'java.lang.Boolean') {
        entry.defaultValue = entry.defaultValue !== 'false'
      }
    }
  }

  return propMap
}
