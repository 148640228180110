import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { BsChevronUp, BsChevronDown, BsTrash } from 'react-icons/bs'
import startCase from 'lodash/startCase'
import isEmpty from 'lodash/isEmpty'
import Button from 'britive-design-system/core/components/button'
import Spinner from 'britive-design-system/core/components/spinner'
import Typography from 'britive-design-system/core/components/typography'
import DataTable from 'secret-manager-ui/core/components/data-table/DataTable'
import { deletePermissionConstraints } from 'action_creators/permissions_constraints'

const ConstraintPanel = ({
  profileId,
  permissionName,
  permissionType,
  constraintType,
  dispatch,
  openModal,
  permissionConstraints,
  getConstraints,
  isEditMode,
  viewOnly,
}) => {
  const [isExpanded, setIsExpanded] = useState(true)
  const [columns, setColumns] = useState([])
  const [isDeleting, setisDeleting] = useState(false)

  const deleteConstraint = async (name, index) => {
    setisDeleting(true)
    await dispatch(
      deletePermissionConstraints(
        profileId,
        permissionName,
        permissionType,
        constraintType,
        index,
        name
      )
    )
    await getConstraints(constraintType)
    setisDeleting(false)
  }

  const getDynamicField = () => {
    const data = permissionConstraints?.result
    const finalColumnsArrayObj = []

    const largerFields = ['name', 'expression', 'description']
    for (const property in data[0]) {
      const newObj = {
        field: property,
        headerName: startCase(property),
        width: largerFields.includes(property) ? 300 : 100,
        horizontalAlignment: 'left',
        sortable: data.length > 1,
      }
      finalColumnsArrayObj.push(newObj)
    }

    setColumns(finalColumnsArrayObj)
  }

  useEffect(() => {
    getConstraints(constraintType)
  }, [isExpanded])

  useEffect(() => {
    if (permissionConstraints?.result?.length > 0) {
      getDynamicField()
    }
  }, [permissionConstraints, isEditMode])

  const ConstraintTable = ({ isEditMode }) => {
    const finalColumns = [...columns]
    if (isEditMode) {
      finalColumns.push({
        headerName: 'Action',
        width: 100,
        horizontalAlignment: 'left',
        renderColumn: row => (
          <span style={{ cursor: 'pointer' }}>
            <BsTrash size={24} onClick={() => deleteConstraint(row.name)} />
          </span>
        ),
      })
    }
    return (
      <DataTable
        inLineSort={true}
        rows={permissionConstraints?.result}
        columns={finalColumns}
        loading={permissionConstraints?.status === 'LOADING'}
        loadingMessage="Loading..."
        loadMoreHandler={() => {}}
        loadMore={false}
      />
    )
  }

  return (
    <>
      <div style={{ cursor: 'pointer' }} onClick={() => setIsExpanded(!isExpanded)}>
        <Typography variant="heading5">
          {isExpanded ? <BsChevronUp /> : <BsChevronDown />}
          <span style={{ marginLeft: 12 }}>{startCase(constraintType)}</span>
        </Typography>
      </div>
      <hr />
      {isExpanded && (
        <>
          {isEditMode && (
            <Button
              variant={'secondary'}
              size={'medium'}
              onClick={() =>
                openModal(
                  constraintType,
                  constraintType === 'condition' &&
                    permissionConstraints?.result &&
                    permissionConstraints?.result[0]
                )
              }
            >
              {`${
                constraintType === 'condition' &&
                !isEmpty(columns) &&
                permissionConstraints?.result.length > 0
                  ? 'Update'
                  : 'Add'
              } ${startCase(constraintType)}`}
            </Button>
          )}
          <div
            style={{
              marginTop: 16,
              marginBottom: 32,
              maxHeight: 240,
              overflow: 'scroll',
            }}
          >
            {permissionConstraints?.status === 'LOADING' && !isDeleting ? (
              <Spinner size={'medium'} message={'Loading...'} />
            ) : !isEmpty(columns) && permissionConstraints?.result.length > 0 ? (
              <ConstraintTable isEditMode={isEditMode} />
            ) : (
              <div style={{ width: '100%', textAlign: 'center' }}>
                No {startCase(constraintType)} constraints. <br />
                {!viewOnly && !isEditMode && `Please Edit to add a constraint`}
              </div>
            )}
          </div>
        </>
      )}
      {isDeleting && <Spinner size={'medium'} overlay />}
    </>
  )
}

const mapStateToProps = (state, props) => ({
  permissionConstraints:
    state.permissionsConstraints.permissionConstraints[props.constraintType],
})

export default connect(mapStateToProps)(ConstraintPanel)
