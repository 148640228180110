export const COLUMN_NAME = {
  FAVORITE_PROFILE: 'favoriteProfile',
  ENVIRONMENT_NAME: 'environmentName',
  SHOW_ENVIRONMENT_ID: 'showEnvironmentId',
  ENVIRONMENT_ID: 'environmentId',
  APPLICATION_NAME: 'applicationName',
  APPLICATION_TYPE: 'applicationType',
  PROFILE_NAME: 'profileName',
  PROFILE_LAST_USED: 'profileLastUsed',
  PROFILE_STATUS: 'profileStatus',
  PROFILE_ACTION: 'profileAction',
  PROFILE_USAGE: 'profileUsage',
  ACCESS_TIME_REMAINING: 'accessTimeRemaining',
}

export const PROFILE_STATUS = {
  CHECKED_OUT: 'Checked Out',
  CHECK_OUT_SUBMITTED: 'Check Out Submitted',
  APPROVAL_REQUIRED: 'Approval Required',
  CHECK_OUT_FAILED: 'Check Out Failed',
  CHECK_IN_SUBMITTED: 'Check In Submitted',
  CHECK_IN_FAILED: 'Check In Failed',
  APPROVAL_PENDING: 'Approval Pending',
  AVAILABLE: 'Available',
  RESTRICTED: 'Restricted',
  UNKNOWN: 'Unknown',
  CHECK_OUT_TIMED_OUT: 'Check Out Timed Out',
  CHECK_IN_TIMED_OUT: 'Check In Timed Out',
}

export const stepUpErrorCode = 'PE-0028'
