import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import debounce from 'lodash/debounce'
import Button from 'britive-design-system/core/components/button'
import Table from './Table'
import Checkbox from 'britive-design-system/core/components/checkbox'
import Typography from 'britive-design-system/core/components/typography'
import { MembersCountCell } from './MembersCountCell'
import { fetchPaginatedTags } from 'action_creators/tag'
import getTotalPageCount from 'utils/getTotalPageCount'

export const AddTagsList = ({
  currentIds,
  selectedTags = [],
  onChange,
  onViewMembers,
}) => {
  const dispatch = useDispatch()
  const { paginatedTags = {}, fetchingPaginatedTags } = useSelector(
    state => state.tags
  )
  const filteredTagsData = paginatedTags?.data?.filter(
    item => !currentIds.includes(item.userTagId)
  )

  useEffect(() => {
    if (isEmpty(paginatedTags?.data) || paginatedTags?.searchText) {
      fetchData({
        page: 0,
        size: 100,
      })
    }
  }, [])

  const fetchData = params => {
    dispatch(fetchPaginatedTags(params, paginatedTags?.searchText))
  }

  const handleLoadMore = () => {
    fetchData({
      page: paginatedTags?.page + 1,
      size: 100,
    })
  }

  const handleSearch = value => {
    fetchData({
      page: 0,
      size: 100,
      searchText: value?.length ? value : '',
    })
  }

  return (
    <>
      <Table
        onSearch={debounce(handleSearch, 300)}
        tableData={filteredTagsData}
        isLoading={fetchingPaginatedTags}
        columns={[
          {
            headerName: 'Name',
            field: 'name',
            renderColumn: row => {
              const itemIndex = selectedTags.findIndex(
                tag => tag.id === row.userTagId
              )
              const isChecked = itemIndex > -1
              return (
                <div style={{ display: 'flex' }}>
                  <Checkbox
                    checked={isChecked}
                    onChange={e => {
                      const isItemChecked = e.target.checked
                      if (isItemChecked && !isChecked) {
                        onChange([
                          ...selectedTags,
                          {
                            id: row.userTagId,
                            name: row.name,
                            memberType: 'Tag',
                            userCount: row.userCount,
                          },
                        ])
                      } else if (isChecked) {
                        const clonedSelectedItems = [...selectedTags]
                        clonedSelectedItems.splice(itemIndex, 1)
                        onChange(clonedSelectedItems)
                      }
                    }}
                  />
                  &nbsp;
                  {row.name}
                </div>
              )
            },
          },
          {
            field: 'userCount',
            headerName: 'Members',
            renderColumn: row => (
              <MembersCountCell
                count={row?.userCount}
                onIconClick={() => onViewMembers(row)}
              />
            ),
          },
        ]}
      />
      {!filteredTagsData?.length && !fetchingPaginatedTags && (
        <div style={{ marginTop: 20 }}>
          <Typography variant="heading6">No tags found!</Typography>
        </div>
      )}
      {!fetchingPaginatedTags &&
        paginatedTags?.data?.length > 0 &&
        paginatedTags?.page <
          getTotalPageCount(paginatedTags?.count, paginatedTags?.size) && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '20px',
            }}
          >
            <Button variant="textOnly" size="medium" onClick={handleLoadMore}>
              Load More
            </Button>
          </div>
        )}
    </>
  )
}
