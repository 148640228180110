import { combineReducers } from 'redux'
import myResourcesProfiles from './myResourcesProfiles'
import availableTemplates from './availableTemplates'

const myResources = combineReducers({
  profiles: myResourcesProfiles,
  availableTemplates,
})

export default myResources
