import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

import Button from 'britive-ui-components/core/components/Button'

import * as text from 'translations/english_us'

export default class InfoModals extends PureComponent {
  render() {
    const {
      successModalOpen,
      saveSuccessful,
      saveError,
      testModalResultsOpen,
      testModalResultsSuccess,
      testMessage,
      scanModalResultsOpen,
      scanModalData,
      typeOfUnit,
      unitName,

      // actions
      toggleModal,
    } = this.props

    return (
      <Fragment>
        {/* Why not have a dynamic modal where the content changes*/}
        {/* TODO: refactor */}
        <Modal isOpen={successModalOpen}>
          <ModalHeader>{saveSuccessful ? text.SUCCESS : text.FAIL}</ModalHeader>

          <ModalBody>
            <p>
              {typeOfUnit} {unitName}{' '}
              {saveSuccessful
                ? text.IS_SUCCESSFULLY_SAVED
                : text.IS_NOT_SUCCESSFULLY_SAVED}
              .
            </p>

            {saveError && <p>Additional Info: {saveError}</p>}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={toggleModal}>
              {text.OK}
            </Button>{' '}
          </ModalFooter>
        </Modal>

        <Modal isOpen={testModalResultsOpen}>
          <ModalHeader>
            {testModalResultsSuccess ? text.SUCCESS : text.TEST_FAILURE}
          </ModalHeader>

          <ModalBody>
            <p className={testModalResultsSuccess ? '' : 'text-danger'}>
              {testMessage}
            </p>
          </ModalBody>

          <ModalFooter>
            <Button color="primary" onClick={toggleModal}>
              {text.OK}
            </Button>{' '}
          </ModalFooter>
        </Modal>

        <Modal isOpen={scanModalResultsOpen}>
          {scanModalData && (
            <Fragment>
              <ModalHeader>{scanModalData.header}</ModalHeader>

              <ModalBody>
                <p>{scanModalData.message}</p>
              </ModalBody>

              <ModalFooter>
                <Button color="primary" onClick={scanModalData.onClose}>
                  {text.OK}
                </Button>{' '}
              </ModalFooter>
            </Fragment>
          )}
        </Modal>
      </Fragment>
    )
  }
}

InfoModals.propTypes = {
  scanModalResultsOpen: PropTypes.bool,
  scanModalResultsSuccess: PropTypes.bool,
  successModalOpen: PropTypes.bool,
  testModalResultsOpen: PropTypes.bool,
  testModalResultsSuccess: PropTypes.bool,
  toggleModal: PropTypes.func,
  typeOfUnit: PropTypes.string,
  unitName: PropTypes.string,
}
