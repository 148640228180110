import { put, takeLatest, call, all, fork, select } from 'redux-saga/effects'
import toast from 'utils/toast/index.js'
import isEmpty from 'lodash/isEmpty'
import {
  failureMyRequestsListLoadMore,
  loadingMyRequestsListLoadMore,
  successMyRequestsListLoadMore,
  loadingUsersList,
  successUsersList,
  failureUsersList,
  loadingTagsList,
  successTagsList,
  failureTagsList,
  withdrawRequestLoading,
  withdrawRequestSuccess,
  withdrawRequestFailure,
  requestMyRequestsList,
} from '../../../action_creators/my-requests'
import {
  MyRequestsDataTypes,
  UsersType,
  TagsType,
  active,
  WithdrawType,
  translatedStrings,
} from './constants'
import { fetchMyRequestsList, fetchTags, withdrawRequest } from './service'
import { fetchUsers } from '../my-approvals/list/service'

function* myRequestsListWatcher() {
  yield takeLatest(
    MyRequestsDataTypes.MY_REQUESTS_LIST_REQUEST,
    myRequestsListWorker
  )
}

export function* myRequestsListWorker({ payload = {} }) {
  const state = yield select()
  const searchTerm = state =>
    state?.userSecretsReducer?.myRequestReducer?.myRequestsList?.searchTerm
  payload.search = searchTerm(state)

  try {
    yield put({ type: MyRequestsDataTypes.MY_REQUESTS_LIST_LOADING })

    const response = yield call(fetchMyRequestsList, payload)
    const { data } = response

    yield put({
      type: MyRequestsDataTypes.MY_REQUESTS_LIST_SUCCESS,
      payload: data,
    })
  } catch ({ response }) {
    const reason = (response && response?.data && response?.data?.message) || ''
    yield put({
      type: MyRequestsDataTypes.MY_REQUESTS_LIST_FAILURE,
      payload: reason,
    })
  }
}

function* myRequestsListLoadMoreWatcher() {
  yield takeLatest(
    MyRequestsDataTypes.MY_REQUESTS_LIST_LOAD_MORE_REQUEST,
    myRequestsListLoadMoreWorker
  )
}

export function* myRequestsListLoadMoreWorker({ payload = {} }) {
  const state = yield select()
  const searchTerm = state =>
    state?.userSecretsReducer?.myRequestReducer?.myRequestsList?.searchTerm
  const nextPageToken = state =>
    state?.userSecretsReducer?.myRequestReducer?.myRequestsList?.pagination?.next
  payload.search = searchTerm(state)
  payload.next = nextPageToken(state)

  try {
    yield put(loadingMyRequestsListLoadMore())

    const response = yield call(fetchMyRequestsList, payload)
    const { data } = response

    yield put(successMyRequestsListLoadMore(data))
  } catch ({ response }) {
    const reason = response?.data?.message ?? ''
    yield put(failureMyRequestsListLoadMore(reason))
  }
}

function* usersDataWatcher() {
  yield takeLatest(UsersType.USERS_REQUEST, usersDataWorker)
}

function* usersDataWorker() {
  try {
    yield put(loadingUsersList())
    const response = yield call(fetchUsers)

    yield put(
      successUsersList(
        (response || []).filter(user => user?.status?.toLowerCase() === active)
      )
    )
  } catch ({ response }) {
    const reason = response?.data?.message || ''
    toast({
      title: reason,
      type: 'error',
      time: 'normal',
      id: reason,
    })
    yield put(failureUsersList(reason))
  }
}

function* tagsDataWatcher() {
  yield takeLatest(TagsType.USER_TAGS_REQUEST, tagsDataWorker)
}

function* tagsDataWorker() {
  try {
    yield put(loadingTagsList())
    const response = yield call(fetchTags)

    yield put(
      successTagsList(
        (response || []).filter(tag => tag.status.toLowerCase() === active)
      )
    )
  } catch ({ response }) {
    const reason = response?.data?.message || ''
    toast({
      title: reason,
      type: 'error',
      time: 'normal',
      id: reason,
    })
    yield put(failureTagsList(reason))
  }
}

function* withdrawRequestWatcher() {
  yield takeLatest(WithdrawType.MY_REQUESTS_WITHDRAW_REQUEST, withdrawRequestWorker)
}

function* withdrawRequestWorker({ payload = {} }) {
  const { history, redirectPath } = payload
  try {
    yield put(withdrawRequestLoading())
    const response = yield call(withdrawRequest, payload?.requestId)
    yield put(withdrawRequestSuccess(response))
    toast({
      title: translatedStrings.requestWithdrawn,
      type: 'success',
      time: 'normal',
      id: translatedStrings.requestWithdrawn,
    })
    yield put(requestMyRequestsList(payload?.consumer))
    if (!isEmpty(history) && redirectPath) {
      history.push(redirectPath)
    }
  } catch ({ response }) {
    const reason = response?.data?.message || ''
    toast({
      title: reason,
      type: 'error',
      time: 'normal',
      id: reason,
    })
    yield put(withdrawRequestFailure())
  }
}

export default all([
  fork(myRequestsListWatcher),
  fork(myRequestsListLoadMoreWatcher),
  fork(usersDataWatcher),
  fork(tagsDataWatcher),
  fork(withdrawRequestWatcher),
])
