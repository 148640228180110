export default string => {
  const copyEl = document.createElement('input')

  try {
    document.body.appendChild(copyEl)
    copyEl.setAttribute('id', 'copy-element')
    document.getElementById('copy-element').value = string

    // iOS special handling.
    // Note: iOS versions prior to 10 do NOT support copy to clipboard using Javascript.
    // This solution below only works for iOS >= 10.
    // https://stackoverflow.com/questions/34045777/copy-to-clipboard-using-javascript-in-ios
    if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
      // must be editable with readonly to stop iOS keyboard opening
      copyEl.contentEditable = true
      copyEl.readOnly = true

      // create a selectable range
      var range = document.createRange()
      range.selectNodeContents(copyEl)

      // select the range
      var selection = window.getSelection()
      selection.removeAllRanges()
      selection.addRange(range)
      copyEl.setSelectionRange(0, 999999)
    } else {
      copyEl.select()
    }

    document.execCommand('copy')
  } finally {
    document.body.removeChild(copyEl)
  }
}
