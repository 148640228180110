import React from 'react'
import startCase from 'lodash/startCase'
import isEmpty from 'lodash/isEmpty'
import DataTable from 'secret-manager-ui/core/components/data-table/DataTable'
import Spinner from 'britive-design-system/core/components/spinner'
import Textfield from 'britive-design-system/core/components/textfield'
import Snackbar from 'britive-design-system/core/components/snackbar'
import Typography from 'britive-design-system/core/components/typography'
import Textarea from 'britive-design-system/core/components/textarea'
import TextareaLines from 'britive-design-system/core/components/textarea-lines'
import Button from 'britive-design-system/core/components/button'

const emptyFieldMessage = 'This field cannot be empty'
const PapPermissionsConstraintsDrawer = ({
  constraint,
  constraints,
  onChange,
  onValidate,
  selectedConstraintType,
  validationMessage,
  addStatus,
  addError,
  touchedFields,
  handleFieldTouch,
}) => {
  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: '100%',
      horizontalAlignment: 'left',
      sortable: false,
    },
  ]

  return (
    <>
      {addStatus === 'LOADING' && (
        <Spinner size={'medium'} message={'Loading...'} overlay={true} />
      )}
      {addStatus === 'ERROR' && (
        <div style={{ marginBottom: '32px' }}>
          <Snackbar errorList={[addError?.message || '']} />
        </div>
      )}
      {selectedConstraintType === 'condition' ? (
        <>
          <Textfield
            value={constraint?.title || ''}
            onChange={e => onChange({ title: e.target.value })}
            onBlur={() => handleFieldTouch('title')}
            label={`Enter ${startCase(selectedConstraintType)} Title`}
            error={touchedFields.title && !constraint?.title?.length}
            errorMsg={emptyFieldMessage}
          />
          <br />

          <Textarea
            value={constraint?.description || ''}
            height="80px"
            onChange={e => onChange({ description: e.target.value })}
            label="Enter Description"
          />
          <br />

          <Typography variant="heading6">Condition Editor</Typography>
          <br />
          <Typography variant="heading7">
            Paste and edit condition expression here
          </Typography>
          <br />
          <Button
            variant={'secondary'}
            size={'medium'}
            onClick={onValidate}
            disabled={!constraint?.expression?.length}
          >
            Run Linter
          </Button>
          <TextareaLines
            value={constraint?.expression || ''}
            height="280px"
            onChange={e => onChange({ expression: e.target.value })}
            onBlur={() => handleFieldTouch('expression')}
            error={touchedFields.expression && !constraint?.expression?.length}
            lineMessages={[validationMessage]}
            errorMsg={emptyFieldMessage}
          />
        </>
      ) : (
        <>
          <Textfield
            value={constraint?.name || ''}
            onChange={e => onChange({ name: e.target.value })}
            onBlur={() => handleFieldTouch('name')}
            label={`Enter ${startCase(selectedConstraintType)} Name`}
            error={touchedFields.name && !constraint?.name?.length}
            errorMsg={emptyFieldMessage}
          />
          <br />
          {!isEmpty(constraints) && (
            <>
              <div style={{ marginBottom: '16px' }}>
                <Typography variant="heading6">Recently Added</Typography>
              </div>
              <DataTable
                inLineSort={true}
                rows={constraints}
                columns={columns}
                loading={false}
                loadingMessage="Loading..."
                loadMore={false}
              />
            </>
          )}
        </>
      )}
    </>
  )
}

export default PapPermissionsConstraintsDrawer
