import * as api from 'services/api'
import toast from 'utils/toast/index.js'
import { fetchIfLoggedIn } from './utils.js'

export const FETCH_REPORT_TYPES = 'FETCH_REPORT_TYPES'
export const FETCH_REPORT_DATA = 'FETCH_REPORT_DATA'
export const FETCH_REPORT_CSV = 'FETCH_REPORT_CSV'
export const FETCH_JOB_STATUS = 'FETCH_JOB_STATUS'
export const GENERATE_REPORT = 'GENERATE_REPORT'
export const REPORT_GENERATION = 'REPORT_GENERATION'

export const fetchReportTypes = type => dispatch => {
  return dispatch({
    type: FETCH_REPORT_TYPES,
    payload: api.fetchReportTypes(type),
  })
}

export const fetchReportData = ({
  reportId,
  page,
  pageSize,
  filter,
  sort,
  type,
}) => dispatch => {
  return dispatch({
    type: FETCH_REPORT_DATA,
    meta: { reportId, type },
    payload: api.fetchReportData({
      reportId,
      page,
      pageSize,
      filter,
      sort,
    }),
  })
}

export const fetchReportCSV = ({ reportId, filter, sort, isAsync }) => dispatch => {
  let path = `/reports/${reportId}/csv`

  const params = []

  if (filter) {
    params.push(`filter=${filter}`)
  }

  if (sort) {
    params.push(`${sort}`)
  }

  if (isAsync) {
    params.push(`async=${isAsync}`)
  }

  if (params.length) {
    path += `?${params.join('&')}`
  }

  return dispatch(
    fetchIfLoggedIn({
      actionType: FETCH_REPORT_CSV,
      meta: { reportId },
      method: 'get',
      path,
    })
  )
}

export const fetchJobStatus = () => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: FETCH_JOB_STATUS,
      path: `/reports/job-status`,
      method: 'get',
    })
  )
}

export const generateReport = (reportId, userFullName) => async dispatch => {
  toast({
    title: `We are generating your report. You'll be notified when it is ready.`,
    type: 'info',
    time: 'normal',
    id: `${reportId}-generate-info`,
  })
  try {
    await dispatch(
      fetchIfLoggedIn({
        meta: { userFullName },
        actionType: GENERATE_REPORT,
        path: `/reports/${reportId}/job`,
        method: 'post',
      })
    )
  } catch (e) {
    toast({
      title: `Report could not be generated. Please try again.`,
      type: 'error',
      time: 'long',
      id: `${reportId}-generate-error`,
    })
  }
}

export const handleReportGeneration = notification => ({
  type: REPORT_GENERATION,
  payload: notification,
})
