import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import Spinner from 'britive-design-system/core/components/spinner'
import { withdrawRequest } from 'action_creators/my-requests'
import { getApprovalsDetailsById } from 'action_creators/my-approvals'
import { papConsumer, translatedStrings, resourceprofile } from '../constants'
import { PageHeader } from '../../components/page-header'
import { headerDetailsType } from '../../components/page-header/constants'
import { getABRequestsHeaderTitle, isAccessBuilderRequest } from '../../utils'
import { RequestDetails } from './RequestDetails'
import { AccessBuilderRequestInfo } from '../../components/access-builder-request-info'
import './RequestView.scss'

export const RequestView = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  let requestId = useParams().id

  const requestDetails = useSelector(
    state => state.userSecretsReducer?.myApprovalsDetails
  )

  const withdrawRequestStatus = useSelector(
    state => state.userSecretsReducer?.myRequestReducer?.withdrawRequest
  )

  const showAccessBuilderData = isAccessBuilderRequest(
    requestDetails?.data?.consumer
  )

  useEffect(() => {
    dispatch(getApprovalsDetailsById(requestId))
  }, [])

  const getHeaderTitle = () => {
    if (showAccessBuilderData) {
      return getABRequestsHeaderTitle(requestDetails?.data)
    }

    if (
      requestDetails?.data?.consumer === papConsumer ||
      requestDetails?.data?.consumer === resourceprofile
    ) {
      return `${translatedStrings.profileCheckoutRequest}: ${requestDetails?.data?.resourceName}`
    }

    return `${translatedStrings.secretViewRequest}: ${requestDetails?.data?.resourceName}`
  }

  const onWithdrawClick = () => {
    dispatch(withdrawRequest(requestId, history, '/my-requests'))
  }

  if (requestDetails.loading || withdrawRequestStatus?.loading) {
    return <Spinner size={'medium'} overlay={true} />
  }

  return (
    <div className="my-secrets-app-container">
      <PageHeader
        title={getHeaderTitle()}
        trackingId={requestDetails?.data?.trackingId}
        type={headerDetailsType.REQUESTS}
        requestStatus={requestDetails?.data?.status}
        onWithdraw={onWithdrawClick}
        description={requestDetails?.data?.context?.profileDescription}
      />
      {showAccessBuilderData ? (
        requestDetails?.data.requestId === requestId && (
          <AccessBuilderRequestInfo
            requestData={requestDetails?.data}
            requestId={requestId}
            history={history}
            hideRequester
          />
        )
      ) : (
        <RequestDetails requestDetails={requestDetails.data} />
      )}
    </div>
  )
}
