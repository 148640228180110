import React, { Fragment, memo, useRef } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

import Button from 'britive-ui-components/core/components/Button'
import MapUserTable from './MapUserTable'
import ConfirmationModal from 'components/ConfirmationModal'

import * as text from 'translations/english_us'

function MapUserModal({
  isUpdatingUser,
  appId,
  envId,
  account,
  addRule,
  clearSelectedAccount,
  confirmationMessage,
  isAddingRule,
  isConfirmationModalOpen,
  isOpen,
  mapUser,
  mappedUsers,
  mappingUser,
  selectedAccountName,
  toggleMapUserModal,
  toggleConfirmationModal,
  unmapUser,
  refresh,
  catalogAppName,
  accountType,
}) {
  const headerRef = useRef(null)
  const footerRef = useRef(null)
  const instructionRef = useRef(null)
  const mapUserRef = useRef(null)
  const unmapUserRef = useRef(null)

  if (!isOpen) {
    return null
  }

  return (
    <Fragment>
      <Modal size="lg" isOpen={isOpen} className="map_user_tablev2">
        <div ref={headerRef}>
          <ModalHeader toggle={toggleMapUserModal}>Map Identity</ModalHeader>
        </div>

        <ModalBody>
          <p ref={instructionRef}>
            Select the identities you would like to have access to:{' '}
            <b>{selectedAccountName}</b>
          </p>
          <Fragment>
            {mappedUsers.length > 0 ? (
              <MapUserTable
                users={mappedUsers}
                mapping={false}
                mapUser={mapUser}
                unmapUser={unmapUser}
                heightNodes={[headerRef, footerRef, instructionRef]}
                innerRef={unmapUserRef}
                type="unmapUser"
                isUpdatingUser={isUpdatingUser}
                catalogAppName={catalogAppName}
                accountType={accountType}
              />
            ) : null}

            <MapUserTable
              dataUrl={`/apps/${appId}/environments/${envId}/accounts/${account.accountId}/users`}
              params={{ query: 'available' }}
              innerRef={mapUserRef}
              heightNodes={[headerRef, footerRef, instructionRef]}
              mapping
              mapUser={mapUser}
              unmapUser={unmapUser}
              isUpdatingUser={isUpdatingUser}
              refresh={refresh}
              catalogAppName={catalogAppName}
              accountType={accountType}
            />
          </Fragment>
        </ModalBody>

        <div ref={footerRef}>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => {
                toggleMapUserModal()
                clearSelectedAccount()
              }}
            >
              {text.OK}
            </Button>
          </ModalFooter>
        </div>
      </Modal>

      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        closeModal={toggleConfirmationModal}
        confirmationMessage={confirmationMessage}
        cancelProperties={{
          onClick: () => addRule({ applyToAllEnvs: false }),
          text: 'No, apply only to this environment',
          inProgress: mappingUser,
        }}
        confirmProperties={{
          onClick: () => addRule({ applyToAllEnvs: true }),
          text: 'Yes',
          inProgress: isAddingRule,
        }}
      />
    </Fragment>
  )
}

export default memo(MapUserModal)
