import Caution from 'static/images/announcement-error.svg'
import Warning from 'static/images/announcement-warning.svg'
import Info from 'static/images/announcement-info.svg'

export const SystemAnnouncementDataTypes = {
  OPEN_SYSTEM_ANNOUNCEMENT: 'OPEN_SYSTEM_ANNOUNCEMENT',
  SYSTEM_ANNOUNCEMENT_REQUEST: 'SYSTEM_ANNOUNCEMENT_REQUEST',
  SYSTEM_ANNOUNCEMENT_SUCCESS: 'SYSTEM_ANNOUNCEMENT_SUCCESS',
}

export const classes = {
  INFOBackground: 'announcement-info-msg',
  CAUTIONBackground: 'announcement-error-msg',
  WARNINGBackground: 'announcement-warning-msg',
}

export const getMessageTypeImage = {
  CAUTION: Caution,
  WARNING: Warning,
  INFO: Info,
}
