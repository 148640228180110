import React, { Fragment, useEffect } from 'react'
import styled from 'styled-components'

import {
  Modal,
  ModalHeader as ModalHeaderUnstyled,
  ModalBody,
  ModalFooter,
} from 'reactstrap'

import camelCase from 'lodash/camelCase'
import Button from 'britive-ui-components/core/components/Button'
import Spinner from 'britive-ui-components/core/components/Spinner'

import FormField, { CheckBoxBoolean } from 'components/form_fields'

import { useField, useFormikContext } from 'formik'

const ModalHeader = styled(ModalHeaderUnstyled)`
  button.close {
    display: none;
  }
`

const styles = {
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  error: {
    color: 'red',
  },
  submissionError: {
    color: 'red',
    marginLeft: 8,
  },
}
const MappingNameField = props => {
  const {
    values: { attributeSchemaId, mappingName },
    setFieldValue,
  } = useFormikContext()
  const [field, meta] = useField(props)

  const { app, attributes, handleChange, name, selectedSessionAttribute } = props
  useEffect(() => {
    if (
      selectedSessionAttribute &&
      Object.values(selectedSessionAttribute)[0].attributeSchemaId ===
        attributeSchemaId
    ) {
      setFieldValue(
        'mappingName',
        Object.values(selectedSessionAttribute)[0].mappingName
      )
    } else if (attributeSchemaId) {
      setFieldValue('mappingName', camelCase(attributes[attributeSchemaId].name))
    }
  }, [attributeSchemaId, setFieldValue, name])

  useEffect(() => {
    if (app.catalogApplication?.supportsSessionAttributesStaticOnly) {
      setFieldValue('sessionAttributeType', 'Static')
    }
  }, [app?.catalogApplication?.supportsSessionAttributesStaticOnly])

  return (
    <FormField
      type="text"
      id="mappingName"
      name="mappingName"
      placeholder="Mapping Name"
      value={mappingName}
      onChange={handleChange}
      hideLabel={false}
      {...field}
    >
      <div style={styles.error}>
        {!!meta.touched && !!meta.error && <div>{meta.error}</div>}
      </div>
    </FormField>
  )
}

const AddSessionAttributes = ({
  app,
  availableAttributes,
  selectedSessionAttribute,
  isModalOpen,
  toggleModal,
  isSubmitting,
  handleSubmit,
  errors,
  values,
  handleChange,
  touched,
  fetchingAttributes,
}) => {
  const attributes = selectedSessionAttribute
    ? Object.values(selectedSessionAttribute)[0].attribute
    : availableAttributes

  return (
    <Fragment>
      <Modal centered isOpen={isModalOpen}>
        <ModalHeader toggle={toggleModal}>
          {selectedSessionAttribute ? 'Update' : 'Add'} Session Attribute
        </ModalHeader>
        {fetchingAttributes ? (
          <Spinner />
        ) : (
          <Fragment>
            <ModalBody>
              <form onSubmit={handleSubmit} style={styles.form}>
                <div className="form-group">
                  <label htmlFor="sessionAttributeType">Attribute Type</label>
                  <select
                    value={values.sessionAttributeType}
                    onChange={handleChange}
                    className="form-control"
                    id="sessionAttributeType"
                    name="sessionAttributeType"
                    disabled={
                      selectedSessionAttribute ||
                      app.catalogApplication?.supportsSessionAttributesStaticOnly
                    }
                  >
                    <option value="Identity">Identity</option>
                    <option value="Static">Static</option>
                  </select>
                </div>
                {values.sessionAttributeType === 'Identity' && (
                  <div className="form-group">
                    <label htmlFor="attributeSchemaId">Attribute</label>
                    <select
                      value={values.attributeSchemaId}
                      onChange={handleChange}
                      className="form-control"
                      id="attributeSchemaId"
                      name="attributeSchemaId"
                      disabled={selectedSessionAttribute}
                    >
                      <option value="">Select an attribute</option>
                      {Object.values(attributes)
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map(attribute => (
                          <option key={attribute.id} value={attribute.id}>
                            {attribute.displayName || attribute.name}
                          </option>
                        ))}
                    </select>
                    <div style={styles.error}>
                      {errors.attributeSchemaId &&
                        touched.attributeSchemaId &&
                        errors.attributeSchemaId}
                    </div>
                  </div>
                )}
                <MappingNameField
                  app={app}
                  handleChange={handleChange}
                  attributes={attributes}
                  selectedSessionAttribute={selectedSessionAttribute}
                  name="mappingName"
                />
                {values.sessionAttributeType === 'Static' && (
                  <FormField
                    type="text"
                    id="attributeValue"
                    name="attributeValue"
                    placeholder="Attribute Value"
                    value={values.attributeValue}
                    onChange={handleChange}
                    hideLabel={false}
                  >
                    <div style={styles.error}>
                      {errors.attributeValue &&
                        touched.attributeValue &&
                        errors.attributeValue}
                    </div>
                  </FormField>
                )}
                {!app.catalogApplication?.supportsSessionAttributesStaticOnly && (
                  <div className="form-group">
                    <CheckBoxBoolean
                      id="transitive"
                      name="transitive"
                      label="Transitive"
                      value={values.transitive}
                      checked={values.transitive}
                      onChange={handleChange}
                      hideLabel={false}
                    />
                  </div>
                )}
              </form>

              <div style={styles.error}>
                {errors.submission && touched.attributeSchemaId && errors.submission}
              </div>
            </ModalBody>

            <ModalFooter>
              <Button
                color="secondary"
                onClick={toggleModal}
                disabled={isSubmitting}
              >
                Cancel
              </Button>

              <Button color="primary" spinner={isSubmitting} onClick={handleSubmit}>
                {selectedSessionAttribute ? 'Update' : 'Add'} Session Attribute
              </Button>
            </ModalFooter>
          </Fragment>
        )}
      </Modal>
    </Fragment>
  )
}

export default AddSessionAttributes
