import React, { memo, useEffect, useRef, useState, Fragment } from 'react'
import styled from 'styled-components'
import get from 'lodash/get'
import ReactTooltip from 'react-tooltip'
import { components } from 'react-select'
import { BasicSelect } from 'components/Selects'

import { BackgroundTooltip as Tooltip } from 'components/Tooltips'
import Associations from 'components/Associations'
import { isChildTypeDomain } from 'components/Associations/utils'
import { Node } from 'britive-design-system/core/components/tree-view'

import useOnScrollListener from 'hooks/useOnScrollListener'
import Spinner from 'britive-ui-components/core/components/Spinner'
import checkIcon from 'static/images/check.svg'

const Wrapper = styled.div`
  min-width: 500px;
  flex: ${props => (props.isOpen ? 1 : 'unset')};
  transition: flex 0.25s;
  display: flex;
  align-items: center;
`

const RefreshWrapper = styled.div`
  margin-left: 10px;
  box-shadow: 0 0 2px 1px var(--brand-light);
  border-radius: 1px;
  height: 32px;
  font-size: 16px;
  padding: 6px 10px;
  color: var(--brand);
  cursor: pointer;
  background: var(--periwinkle-gray);
  cursor: ${props => (props.isRefreshing ? 'not-allowed' : 'pointer')};
`

const ValueWrapper = styled.div`
  align-items: center;
  display: flex;
  padding: 4px 8px;
  min-height: 34px;

  .inactive {
    font-style: italic;
    opacity: 0.6;
  }
`

const MenuWrapper = styled.div`
  top: 100%;
  background-color: hsl(0, 0%, 100%);
  border-radius: 4px;
  box-shadow: 0 10px 20px 0 var(--brand-light);
  margin-bottom: 8px;
  margin-top: 8px;
  overflow: auto;
  position: absolute;
  width: 100%;
  max-height: 40vh;
  z-index: 4;
  box-sizing: border-box;
  padding: 10px;
`

const Select = styled(BasicSelect)`
  min-height: unset;
  width: 100%;
`

const styles = {
  icons: {
    alignItems: 'center',
    display: 'flex',
    order: -1,
  },
}

function AppTreeSelectContainer({
  environmentData,
  environmentScanResults,
  environmentGroups,
  environments,
  entityType,
  selectEntity,
  selectedEntity,
  rootGroupId,
  isHierarchical,
  supportsScanning,
  refreshApplication,
  isRefreshing,
  app,
  applicationTemplate,
}) {
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const wrapperRef = useRef()

  useEffect(() => {
    document.addEventListener('click', onBodyClick)

    return () => {
      document.removeEventListener('click', onBodyClick)
    }
  }, [menuIsOpen])

  useOnScrollListener({
    elRef: wrapperRef,
    onScroll: () => ReactTooltip.hide(),
  })

  function onBodyClick(event) {
    // checking for svg and path to prevent the menu from closing
    // when clicked on the directional icons.
    if (
      menuIsOpen &&
      event?.target?.nodeName !== 'svg' &&
      event?.target?.nodeName !== 'path' &&
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target)
    ) {
      toggleMenu()
    }
  }

  function toggleMenu() {
    setMenuIsOpen(!menuIsOpen)
  }

  const onEntityClick = entity => {
    selectEntity(entity)
    toggleMenu()
  }

  const getRootName = () => {
    // So many different sources of truth for the root name
    // this needs to be improved on the BE somehow
    let rootName =
      get(app, 'catalogAppDisplayName') || get(applicationTemplate, 'catalogAppName')

    if (get(app, 'catalogApplication.requiresHierarchicalModel')) {
      rootName = get(environmentGroups, [rootGroupId], {}).name || rootName
    }

    return rootName
  }

  function renderMenu() {
    return (
      <MenuWrapper ref={wrapperRef}>
        <Associations
          environmentGroups={environmentGroups}
          environments={environments}
          environmentData={environmentData}
          defaultDirectory={app?.appContainerId}
          applicationRoot={{
            id: rootGroupId,
            name: getRootName(),
            appIcon: app?.iconUrl,
          }}
          checkedNodes={[selectedEntity.value]}
          onChange={selectedNode => onEntityClick({ entityId: selectedNode[0] })}
          singleSelect
          allowOnlyEnvironmentSelection={!(!supportsScanning || isHierarchical)}
          hideDropdownFilter
          filterSelectedScopeAsDefault={false}
          allowDisablingUnselectedAssociation={false}
          showSelectionLabel={false}
          showCreatedDate
          allowRootSelection={true}
          appName={get(app, 'catalogAppName')}
        />
      </MenuWrapper>
    )
  }

  function renderControl(controlProps) {
    const { selectProps } = controlProps
    const value = get(selectProps, 'value') || {}
    const entity =
      environments[value.value] ||
      environmentGroups[value.value] ||
      environmentGroups[rootGroupId]
    let envScanned
    let status
    let labelPrefix = ''

    let treeNodeType = 'node'
    if (entityType === 'environment') {
      treeNodeType = 'leaf'
      const env = get(environmentData, [value.value], {})
      envScanned =
        env.envScanStatus ||
        get(environmentScanResults, [value.value, 'status']) === 'Success'
      status = entity.status

      if (isChildTypeDomain(environments[value.value])) {
        labelPrefix = '(Domain)'
      }
    }

    function onMouseDown(event) {
      controlProps.innerProps.onMouseDown(event)
      if (!menuIsOpen) {
        toggleMenu()
      }
    }

    const isIntegrated = !isHierarchical && envScanned && status === 'active'

    const selectedNodeDefinition = {
      _id: value.value,
      type: treeNodeType,
      label: `${labelPrefix?.length ? labelPrefix : ''} ${value.label}`,
      rightIcon: {
        icon: isIntegrated && checkIcon,
        tooltip: isIntegrated && 'Integrated',
      },
    }

    if (entityType === 'application') {
      selectedNodeDefinition.leftIcon = {
        icon: app?.iconUrl,
      }
    }

    return (
      <components.Control
        {...controlProps}
        isFocused={menuIsOpen}
        innerProps={{ ...controlProps.innerProps, onMouseDown }}
      >
        <ValueWrapper>
          {status === 'inactive' && (
            <div style={styles.icons}>
              <i
                data-tip
                data-for={value.value}
                className="fas fa-exclamation-circle inactive-icon icon"
              />
              <Tooltip place="bottom" effect="solid" id={value.value}>
                Disabled
              </Tooltip>
            </div>
          )}
          <Node
            node={selectedNodeDefinition}
            hideCheckboxes
            removeNodePadding
            handleCheckboxClick={() => {}}
          />
        </ValueWrapper>

        <div onClick={toggleMenu}>
          <components.IndicatorsContainer {...controlProps} />
        </div>
      </components.Control>
    )
  }

  function renderArrow(props) {
    return <components.DropdownIndicator {...props} isFocused={menuIsOpen} />
  }

  return (
    <Wrapper isOpen={menuIsOpen}>
      {selectedEntity && (
        <Fragment>
          <Select
            openMenuOnClick={false}
            menuIsOpen={menuIsOpen}
            backspaceRemovesValue={false}
            value={selectedEntity}
            isSearchable={false}
            captureMenuScroll
            components={{
              Menu: renderMenu,
              Control: renderControl,
              DropdownIndicator: renderArrow,
            }}
          />
          {!isHierarchical && (
            <RefreshWrapper
              isRefreshing={isRefreshing}
              onClick={refreshApplication}
              title="Refresh Environment Tree"
            >
              {isRefreshing ? (
                <Spinner size="1x" />
              ) : (
                <span className="fas fa-sync-alt fs:16" />
              )}
            </RefreshWrapper>
          )}
        </Fragment>
      )}
    </Wrapper>
  )
}

export default memo(AppTreeSelectContainer)
