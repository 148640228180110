import React from 'react'
import FontAwesome from 'react-fontawesome'
import Typography from 'britive-design-system/core/components/typography'
import { EDIT_ASSOCIATION_WARN } from './constants'

export const AssociationsEditWarning = () => {
  return (
    <>
      <br />
      <div className="profile-details-pap-associations-edit-warning-container">
        <FontAwesome
          name="exclamation-triangle"
          className="profile-details-pap-associations-edit-warning-icon"
        />
        <Typography variant="label1">{EDIT_ASSOCIATION_WARN}</Typography>
      </div>
    </>
  )
}
