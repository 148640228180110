import React, { memo, Fragment } from 'react'
import moment from 'moment'
import styled from 'styled-components'
import get from 'lodash/get'

import Button from 'britive-ui-components/core/components/Button'

import UnstyledDrawer from 'components/Drawer'
import FrequencyRadioButtons from './FrequencyRadioButtons'
import ScopeSelect from './ScopeSelect'

import { getHeaderSpacing } from 'utils/elementMeasurement'
import FormField, { CheckBoxBoolean } from 'components/form_fields'

const Drawer = styled(UnstyledDrawer)`
  padding: 8px 16px;

  .wrapper {
    margin-bottom: 20px;

    .input-field {
      margin-bottom: 12px;
    }
  }
`

const Heading = styled.h5`
  border-bottom: 2px solid var(--brand);
  padding: 5px 0;
  margin-right: 10px;
  position: sticky;
  top: 0;
  z-index: 3;
  background: #fff;
`

const Icon = styled.i`
  color: var(--medium-purple);
`

const ButtonWrapper = styled.div`
  position: sticky;
  background: #fff;
  bottom: 0;
  padding-top: 10px;
  height: 50px;

  .scanSave {
    margin-left: 8px;
  }
`

const Error = styled.div`
  color: red;
  margin-top: -5px;
`

function ScanDrawer({
  app,
  hourOptions,
  dayOptions,
  daysOfWeekOptions,
  errors,
  fields,
  frequencyInterval,
  frequencyType,
  intervalTypes,
  isNewScan,
  isOpen,
  isSubmitting,
  onCancel,
  onSubmit,
  setName,
  selectFrequencyType,
  selectInterval,
  selectTime,
  selectTimeFromInput,
  startTime,
  setOrgScan,
  timeZoneCode,
  touched,
  toggleDrawer,
  updateScope,
  values,
  rootGroupId,
}) {
  if (!isOpen) {
    return null
  }

  const isHierarchicalApp = get(app, 'catalogApplication.requiresHierarchicalModel')
  const supportsScanning = get(app, 'catalogApplication.supportsEnvironmentScanning')
  const isRootSelected = values.properties.scope[rootGroupId]

  return (
    <Drawer
      isOpen={isOpen}
      top={`${getHeaderSpacing()}px`}
      id="createScanDrawer"
      width="560px"
      toggleDrawer={toggleDrawer}
      disableXScroll
    >
      <Heading>
        <Icon className="fas fa-clock" /> {isNewScan ? 'Add' : 'Edit'} Scheduled Scan
      </Heading>

      <div className="wrapper">
        <h6>Name</h6>
        <FormField
          placeholder="Scan Name"
          label="Scan Name"
          hideLabel
          value={values.name}
          onChange={setName}
          className={'input-field'}
        />
        {errors.name && <Error>{errors.name}</Error>}
        <FrequencyRadioButtons
          intervalTypes={intervalTypes}
          frequencyType={frequencyType}
          frequencyInterval={frequencyInterval}
          startTime={new Date(moment(startTime, 'HH:mm'))}
          selectInterval={selectInterval}
          selectTime={selectTime}
          selectTimeFromInput={selectTimeFromInput}
          selectFrequencyType={selectFrequencyType}
          hourOptions={hourOptions}
          dayOptions={dayOptions}
          daysOfWeekOptions={daysOfWeekOptions}
          timeZoneCode={timeZoneCode}
          touched={touched}
          errors={errors}
        />

        {!isHierarchicalApp && supportsScanning && (
          <Fragment>
            <CheckBoxBoolean
              name="orgScan"
              label="Scan Organization Tree"
              onChange={setOrgScan}
              className="mb-2"
              checked={isRootSelected || values.properties.orgScan}
              disabled={isRootSelected}
            />
          </Fragment>
        )}

        {!isHierarchicalApp && (
          <ScopeSelect
            errors={errors}
            touched={touched}
            fields={fields}
            values={values}
            updateScope={updateScope}
          />
        )}
      </div>

      <ButtonWrapper>
        <Button onClick={onCancel}>Cancel</Button>

        <Button
          onClick={onSubmit}
          color="primary"
          className="scanSave"
          spinner={isSubmitting}
        >
          Save
        </Button>

        <Error>{errors.submission}</Error>
      </ButtonWrapper>
    </Drawer>
  )
}

export default memo(ScanDrawer)
