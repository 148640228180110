import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { fetchSessionAttributes, deleteSessionAttribute } from 'action_creators/pap'
import PapSessionAttributes from './PapSessionAttributes'
import { getCustomAttributes } from 'action_creators/custom_attribute'
import isEmpty from 'lodash/isEmpty'
import toast from 'utils/toast'
import messages from 'constants/messages'

const PapSessionAttributesContainer = ({
  app,
  profileId,
  dispatch,
  thisAppManage,
}) => {
  const [sessionAttributes, setSessionAttributes] = useState({})
  const [attributes, setAttributes] = useState({})
  const [availableAttributes, setAvailableAttributes] = useState({})
  const [fetchingSessionAttributes, setFetchingSessionAttributes] = useState(true)
  const [fetchingAttributes, setFetchingAttributes] = useState(true)
  const [deletingSessionAttributes, setDeletingSessionAttributes] = useState({})
  const [error, setError] = useState(null)

  const getAttributes = async () => {
    setFetchingAttributes(true)
    setError(null)
    try {
      const response = await dispatch(getCustomAttributes('multiValued eq false'))
      const attributesObject = {}
      response.value.data.forEach(
        attribute => (attributesObject[attribute.id] = attribute)
      )
      setAttributes(attributesObject)
    } catch (e) {
      setError(e.response.data.message)
    } finally {
      setFetchingAttributes(false)
    }
  }

  const getSessionAttributes = async () => {
    setFetchingSessionAttributes(true)
    setError(null)
    try {
      const response = await dispatch(fetchSessionAttributes(profileId))
      const sessionAttributesObject = {}
      response.value.data.forEach(
        attribute => (sessionAttributesObject[attribute.id] = attribute)
      )
      setSessionAttributes(sessionAttributesObject)
    } catch (e) {
      setError(e.response.data.message)
    } finally {
      setFetchingSessionAttributes(false)
    }
  }
  useEffect(() => {
    getAttributes()
    getSessionAttributes()
  }, [])

  useEffect(() => {
    const availableAttributeObject = { ...attributes }
    if (!isEmpty(sessionAttributes)) {
      Object.values(sessionAttributes).forEach(
        attribute => delete availableAttributeObject[attribute.attributeSchemaId]
      )
    }
    setAvailableAttributes(availableAttributeObject)
  }, [sessionAttributes, attributes])

  const updateSessionAttributes = attribute => {
    setError(null)
    setSessionAttributes({
      ...sessionAttributes,
      [attribute.id]: attribute,
    })
  }

  const handleDeleteSessionAttribute = async id => {
    setError(null)
    setDeletingSessionAttributes({
      ...deletingSessionAttributes,
      [id]: true,
    })
    try {
      const response = await dispatch(deleteSessionAttribute(id, profileId))
      if (response.value.status === 204) {
        const sessionAttributesObject = { ...sessionAttributes }
        delete sessionAttributesObject[id]
        await setSessionAttributes(sessionAttributesObject)

        toast({
          title: messages.GENERIC_DELETE_RECORD_SUCCESS,
          type: 'success',
          time: 'normal',
          id: `session-attribute-remove-success-${id}`,
        })
      }
    } catch (e) {
      setError(e.response.data.message)
      setDeletingSessionAttributes({
        ...deletingSessionAttributes,
        [id]: false,
      })
      setTimeout(() => setError(null), 5000)
    }
  }

  return (
    <PapSessionAttributes
      app={app}
      error={error}
      setError={setError}
      fetching={fetchingAttributes || fetchingSessionAttributes}
      attributes={attributes}
      availableAttributes={availableAttributes}
      sessionAttributes={sessionAttributes}
      updateSessionAttributes={updateSessionAttributes}
      handleDeleteSessionAttribute={handleDeleteSessionAttribute}
      deletingSessionAttributes={deletingSessionAttributes}
      profileId={profileId}
      thisAppManage={thisAppManage}
    />
  )
}

export default connect()(PapSessionAttributesContainer)
