import { combineReducers } from 'redux'
import brokerPools from './brokerPools'
import brokersByPool from './brokersByPool'
import brokersList from './brokersList'
import createBrokerPool from './createBrokerPool'
import deleteBrokerPool from './deleteBrokerPool'
import createBrokerPoolToken from './createBrokerPoolToken'
import updateBrokerPoolToken from './updateBrokerPoolToken'
import deleteBrokerPoolToken from './deleteBrokerPoolToken'
import brokerPoolTokens from './brokerPoolTokens'
import brokerPoolLabels from './brokerPoolLabels'
import downloadableBrokers from './downloadableBrokers'

const brokers = combineReducers({
  brokerPools,
  brokersByPool,
  brokersList,
  createBrokerPool,
  deleteBrokerPool,
  createBrokerPoolToken,
  updateBrokerPoolToken,
  deleteBrokerPoolToken,
  brokerPoolTokens,
  brokerPoolLabels,
  downloadableBrokers,
})

export default brokers
