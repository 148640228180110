export const sectionTitle = {
  associationApprovers: {
    title: 'Association Approvers',
    isMandatory: true,
    tooltipMessage:
      'Select associations to be allowed for access builder requests, and assign approval groups to them.',
  },
  notifications: {
    title: 'Notifications',
    isMandatory: true,
    tooltipMessage:
      'Select notification media to be used for access builder request approvals.',
  },
  requesters: {
    title: 'Requesters',
    isMandatory: false,
    tooltipMessage:
      'Select Users/Tags that should be allowed/disallowed to make access builder requests.',
  },
}
