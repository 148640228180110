import { useEffect, useState } from 'react'

import useWindowSize from 'hooks/useWindowSize'

import {
  getHeaderContainerSpacing,
  getNodeFullHeight,
} from 'utils/elementMeasurement'

// Hook
export default function useMaxTableHeight({
  heightNodes = [],
  heights = [],
  minHeight = 300,
}) {
  const [maxHeight, setMaxHeight] = useState(0)
  const { innerHeight } = useWindowSize()

  useEffect(() => {
    let refsHeight = 0

    heights.forEach(height => {
      refsHeight += height
    })

    heightNodes.forEach(ref => {
      const node = ref.current
      if (node) {
        refsHeight += getNodeFullHeight({ node })
      }
    })

    const leftoverHeight = innerHeight - refsHeight - getHeaderContainerSpacing()

    setMaxHeight(Math.max(leftoverHeight, minHeight))
  }, [heightNodes, heights, innerHeight])

  return maxHeight
}
