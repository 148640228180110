import React, { useState, useEffect } from 'react'
import DialogPopup from 'britive-design-system/core/components/dialog'
import Typography from 'britive-design-system/core/components/typography'
import Tabs from 'britive-design-system/core/components/tabs'
import toast from 'utils/toast'
import { isEmpty } from 'lodash'
import { getCredentialsAsString } from '../utils'

const CSS_CLASSNAME_PREFIX = 'my-resources'
const DEFAULT_PRIMARY_BTN_TEXT = 'Copy Credentials'
const CLICKED_PRIMARY_BTN_TEXT = 'Copied!'

const AccessCredentialsDialog = ({
  templates = [],
  credentials,
  onClose,
  onTemplateSelection,
}) => {
  const [primaryBtnText, setPrimaryBtnText] = useState(DEFAULT_PRIMARY_BTN_TEXT)
  const [activeTab, setActiveTab] = useState(0)

  useEffect(() => {
    if (!isEmpty(templates)) {
      onTemplateSelection()
    }
  }, [])

  const onTabClick = index => {
    setActiveTab(index)

    if (index === 0) {
      onTemplateSelection()
      return
    }

    onTemplateSelection(templates[index - 1])
  }

  const handleCopyCreds = async () => {
    try {
      await navigator.clipboard.writeText(getCredentialsAsString(credentials))
      setPrimaryBtnText(CLICKED_PRIMARY_BTN_TEXT)
      setTimeout(() => {
        setPrimaryBtnText(DEFAULT_PRIMARY_BTN_TEXT)
      }, 2000)
    } catch {
      toast({
        title: `Failed to copy the credentials.`,
        type: 'error',
        time: 'normal',
        id: 'my-resources-copy-creds-failure',
      })
    }
  }

  return (
    <DialogPopup
      type="general"
      width="auto"
      height="auto"
      title={'Access Credentials'}
      primaryButtonText={primaryBtnText}
      primaryButtonDisabled={primaryBtnText === CLICKED_PRIMARY_BTN_TEXT}
      secondaryButtonText={'Close'}
      onSubmit={handleCopyCreds}
      onCancel={onClose}
    >
      <>
        <div role="message">
          <Typography variant="body">
            Use the following to access resources programmatically. You can retrieve
            credentials as often as needed.
          </Typography>
        </div>
        <br />
        {!isEmpty(templates) && (
          <>
            <Tabs
              value={activeTab}
              handleChange={onTabClick}
              items={[
                { title: 'Original Credentials' },
                ...templates?.map(template => ({ title: template })),
              ]}
              variant="auto"
            />
            <br />
          </>
        )}
        <div className={`${CSS_CLASSNAME_PREFIX}-dialog-access-creds`}>
          {getCredentialsAsString(credentials)}
        </div>
      </>
    </DialogPopup>
  )
}

export default AccessCredentialsDialog
