import moment from 'moment-timezone'
import { WEEKLY, MONTHLY, DAYS, DAYS_OF_THE_WEEK } from './ScanDrawer'

export function convertLocalDataToUTCData(values) {
  const date = moment(values.startTime, 'HH:mm')
  const updatedValues = { ...values }
  updatedValues.startTime = moment.utc(date).format('HH:mm')

  switch (values.frequencyType) {
    case WEEKLY: {
      const updatedDate = date.day(values.frequencyInterval.value)
      const utcDate = moment.utc(updatedDate)
      const day = utcDate.day()
      updatedValues.frequencyInterval = day ? day : 7
      break
    }
    case MONTHLY: {
      const newDate = date.date(values.frequencyInterval.value)
      const utcTime = moment.utc(newDate)
      const localDate = newDate.date()
      const localMonth = newDate.month()
      const utcDate = utcTime.date()
      const utcMonth = utcTime.month()
      const diff = utcMonth - localMonth || utcDate - localDate
      const calculatedInterval = values.frequencyInterval.value + diff
      let newInterval = calculatedInterval

      if (calculatedInterval < 0) {
        newInterval = 30
      }

      if (calculatedInterval > 31) {
        newInterval = 1
      }

      updatedValues.frequencyInterval =
        DAYS[newInterval ? newInterval - 1 : 30].value
      break
    }
    default:
  }

  return updatedValues
}

export function convertUTCDataToLocalData(values) {
  const newValues = { ...values }
  const { startTime, frequencyInterval } = newValues
  const newDate = moment.utc(startTime, 'HH:mm')
  const localTime = moment(newDate.toDate()).local()
  newValues.startTime = localTime.format('HH:mm')

  switch (values.frequencyType) {
    case WEEKLY: {
      const day = frequencyInterval === 7 ? 0 : frequencyInterval
      const newDate = moment.utc(startTime, 'HH:mm').day(day)
      const localTime = moment(newDate.toDate()).local()
      const dayOfWeek = localTime.day()
      newValues.frequencyInterval = DAYS_OF_THE_WEEK[dayOfWeek]
      break
    }
    case MONTHLY: {
      const newDate = moment.utc(startTime, 'HH:mm').date(frequencyInterval)
      const localTime = moment(newDate.toDate()).local()
      const localDate = localTime.date()
      const localMonth = localTime.month()
      const utcDate = newDate.date()
      const utcMonth = newDate.month()
      const diff = utcMonth - localMonth || utcDate - localDate
      const calculatedInterval = frequencyInterval - diff
      let newInterval = calculatedInterval

      if (calculatedInterval < 0) {
        newInterval = 30
      }

      if (calculatedInterval > 31) {
        newInterval = 1
      }

      newValues.frequencyInterval = DAYS[newInterval ? newInterval - 1 : 30]
      break
    }
    default:
  }

  return newValues
}
