import get from 'lodash/get'

export const showAdditionalSettings = (catalogAppName = '') => {
  const allowedAppNames = ['okta', 'gcp']

  return allowedAppNames.includes(catalogAppName.toLowerCase())
}

export const getDefaultServiceAccountId = app => {
  const propertyTypes = get(app, 'catalogApplication.propertyTypes', [])

  const propertyData = propertyTypes.find(
    property => property.name === 'projectIdForServiceAccount'
  )

  return propertyData?.value || ''
}

export const isGCP = (appName = '') => appName.toLowerCase() === 'gcp'

export const isOkta = (appName = '') => appName.toLowerCase() === 'okta'

export const isAWS = (appName = '') => appName.toLowerCase().startsWith('aws')
export const isAWSIDC = (appName = '') =>
  appName.toLowerCase() === 'aws identity center'

export const isAWSWithoutIDC = appName => isAWS(appName) && !isAWSIDC(appName)
export const getAppName = appRoot => appRoot?.catalogAppName || appRoot?.name
