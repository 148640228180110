import get from 'lodash/get'
import * as actions from 'actions'

const initialState = {
  loading: false,
  error: null,
  data: [],
  dataByKey: {},
  paginatedResourcesTypes: [],
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case `${actions.GET_RESOURCE_TYPES}_PENDING`: {
      return {
        ...state,
        loading: true,
        error: null,
      }
    }

    case `${actions.GET_RESOURCE_TYPES}_FULFILLED`: {
      const { data, count, page, size } = action.payload.data

      return {
        ...state,
        loading: false,
        error: null,
        paginatedResourcesTypes: {
          data,
          count,
          page,
          size,
          searchText: action?.meta?.searchText,
        },
      }
    }

    case `${actions.GET_RESOURCE_TYPES}_REJECTED`: {
      return {
        ...state,
        loading: false,
        error: 'Unable to fetch the Resource TYPEs.',
      }
    }
    case `${actions.GET_RESOURCE_TYPES_LOAD_MORE}_PENDING`: {
      return {
        ...state,
        loading: true,
        error: null,
      }
    }

    case `${actions.GET_RESOURCE_TYPES_LOAD_MORE}_FULFILLED`: {
      const { data, count, page, size } = action.payload.data

      return {
        ...state,
        loading: false,
        paginatedResourcesTypes: {
          data: [...state.paginatedResourcesTypes?.data, ...data],
          count,
          page,
          size,
          searchText: action?.meta?.searchText,
        },
      }
    }

    case `${actions.GET_RESOURCE_TYPES_LOAD_MORE}_REJECTED`: {
      return {
        ...state,
        loading: false,
      }
    }

    case `${actions.GET_RESOURCE_TYPE}_PENDING`: {
      return {
        ...state,
        loading: true,
        error: null,
      }
    }

    case `${actions.GET_RESOURCE_TYPE}_FULFILLED`: {
      const data = get(action, 'payload.data')

      return {
        ...state,
        loading: false,
        error: null,
        dataByKey: {
          ...state.dataByKey,
          [data?.resourceTypeId]: data,
        },
      }
    }

    case `${actions.GET_RESOURCE_TYPE}_REJECTED`: {
      return {
        ...state,
        loading: false,
        error: 'Unable to fetch the Resource TYPE.',
      }
    }

    case actions.REMOVE_RESOURCE_TYPES: {
      return initialState
    }

    default:
      return { ...state }
  }
}
