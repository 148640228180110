import React, { memo } from 'react'

import Drawer from 'components/Drawer'

import { getHeaderSpacing } from 'utils/elementMeasurement'
import { Tabs } from 'britive-ui-components/core/components/Tabs'
import Table from 'components/table/TableV2/Table'

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  groupWrapper: {
    padding: 4,
  },
  title: {
    position: 'sticky',
    zIndex: 4,
    top: 0,
    margin: '10px 0',
    background: '#fff',
  },
  groupPermissionList: {
    paddingLeft: 24,
  },
}

function AssignedDrawer({ isOpen, isHierarchical, toggleDrawer, name, tabs }) {
  if (!isOpen) {
    return null
  }

  const getColumns = name => [
    {
      Header: 'Name',
      accessor: name,
      minWidth: 200,
    },
    ...(isHierarchical
      ? [
          {
            Header: 'Scope',
            accessor: 'inheritedScope',
            minWidth: 100,
          },
        ]
      : []),
  ]

  return (
    <Drawer
      isOpen={isOpen}
      top={`${getHeaderSpacing()}px`}
      id="id"
      width="630px"
      toggleDrawer={() => toggleDrawer(null)}
      zIndex={6}
      disableXScroll
      disableYScroll
    >
      <div style={styles.wrapper}>
        <h5 style={styles.title}>Assigned to {name}</h5>

        <div style={{ zIndex: 4 }}>
          <Tabs>
            {tabs.map(tab => (
              <div label={tab.name} key={tab.name}>
                <Table
                  dataUrl={tab.dataUrl}
                  columns={getColumns(tab.nameIdentifier)}
                  identifier={tab.nameIdentifier}
                  emptyTableMessage="No data was found."
                  noUrlQueryParams
                />
              </div>
            ))}
          </Tabs>
        </div>
      </div>
    </Drawer>
  )
}

export default memo(AssignedDrawer)
