import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Button from 'britive-design-system/core/components/button'
import Typography from 'britive-design-system/core/components/typography'

import Form from './Form'

const SSO = ({ initSSO, errorMessage, history }) => {
  useEffect(() => {
    initSSO()
  }, [])

  async function goToLogin(event) {
    event.preventDefault()
    history.push('/login')
  }

  const location = useLocation()
  const errorToBeDisplayed = location?.state?.fromSso ? null : errorMessage

  return (
    <Form
      className="login-form"
      onSubmit={initSSO}
      title="Log in with SSO"
      errorMessage={errorToBeDisplayed}
    >
      {errorToBeDisplayed ? (
        <>
          <div className="field">
            <a className="text-link" onClick={goToLogin}>
              Back to login
            </a>
          </div>
          <Button size="large" variant="primary">
            Try Again
          </Button>
        </>
      ) : (
        <div className="sso">
          <Typography variant="body">Please wait while you are logged in</Typography>
        </div>
      )}
    </Form>
  )
}

export default SSO
