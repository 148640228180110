import React, { useState } from 'react'
import Button from 'britive-design-system/core/components/button'
import DeleteConfirmationDialog from '../../DeleteConfirmationDialog'
import ApproversGroupList from './ApproversGroupList'

export const ApproversGroups = ({
  rows = [],
  onDelete,
  onEditApproverGroup,
  onViewApproverGroupMember,
  onSelectApproverGroup,
  deleteConfirmationDialog,
  deleteIconText,
  showEditIcon = false,
  searchable = false,
  addApproverButton = false,
  selectApproverButton = false,
  disableEditing = false,
}) => {
  const [itemToDelete, setItemToDelete] = useState(null)

  const handleDeleteApprover = approverId => {
    onDelete(approverId)
    setItemToDelete(null)
  }

  return (
    <>
      <div className="buttons-container">
        {addApproverButton && (
          <Button
            size={'large'}
            onClick={() => onEditApproverGroup()}
            disabled={disableEditing}
          >
            Add Approver Group
          </Button>
        )}
        {selectApproverButton && (
          <Button
            size={'large'}
            onClick={() => onSelectApproverGroup()}
            disabled={disableEditing}
          >
            Select Approver Group
          </Button>
        )}
      </div>
      {selectApproverButton && <br />}
      {rows.length !== 0 && (
        <ApproversGroupList
          rows={rows}
          onViewMembers={onViewApproverGroupMember}
          onEdit={onEditApproverGroup}
          onDelete={setItemToDelete}
          showActionColumn
          deleteIconText={deleteIconText}
          showEditIcon={showEditIcon}
          searchable={searchable}
          disableEditing={disableEditing}
        />
      )}
      {itemToDelete && (
        <DeleteConfirmationDialog
          deleteConfirmationDialog={deleteConfirmationDialog}
          onDelete={() => handleDeleteApprover(itemToDelete)}
          onCancel={() => setItemToDelete(null)}
        />
      )}
    </>
  )
}
