import {
  DELETE_ACCESS_KEYS,
  FETCH_ACCESS_KEYS,
  FETCH_ACCESS_URL,
} from 'action_creators/access_key'

export const accessKeyReducerKey = 'accessKey'

const initialState = {
  keys: {},
  urls: {},
  isFetchingKeys: {},
  isFetchedKeys: {},
  isFetchingURLs: {},
  error: null,
}

export function accessKeyReducer(state = initialState, action) {
  switch (action.type) {
    case `${FETCH_ACCESS_KEYS}_PENDING`: {
      const { transactionId } = action.meta
      const isFetchingKeys = {
        ...state.isFetchingKeys,
        [transactionId]: true,
      }

      const isFetchedKeys = {
        ...state.isFetchedKeys,
        [transactionId]: false,
      }

      return {
        ...state,
        isFetchedKeys,
        isFetchingKeys,
        error: null,
      }
    }

    case `${FETCH_ACCESS_KEYS}_FULFILLED`: {
      const { transactionId } = action.meta

      const keys = {
        ...state.keys,
        [transactionId]: action.payload.data,
      }

      const isFetchingKeys = {
        ...state.isFetchingKeys,
        [transactionId]: false,
      }

      const isFetchedKeys = {
        ...state.isFetchedKeys,
        [transactionId]: false,
      }

      return {
        ...state,
        isFetchingKeys,
        isFetchedKeys,
        keys,
      }
    }

    case `${FETCH_ACCESS_KEYS}_REJECTED`: {
      const { transactionId } = action.meta

      const isFetchingKeys = {
        ...state.isFetchingKeys,
        [transactionId]: false,
      }

      const isFetchedKeys = {
        ...state.isFetchedKeys,
        [transactionId]: true,
      }

      return {
        ...state,
        isFetchingKeys,
        isFetchedKeys,
        error: action.payload,
      }
    }

    case `${FETCH_ACCESS_URL}_PENDING`: {
      const { transactionId } = action.meta

      const isFetchingURLs = {
        ...state.isFetchingURLs,
        [transactionId]: true,
      }

      return {
        ...state,
        isFetchingURLs,
      }
    }

    case `${FETCH_ACCESS_URL}_FULFILLED`: {
      const { transactionId } = action.meta

      const urls = {
        ...state.urls,
        [transactionId]: action.payload.data,
      }

      const isFetchingURLs = {
        ...state.isFetchingURLs,
        [transactionId]: false,
      }

      return {
        ...state,
        isFetchingURLs,
        urls,
      }
    }

    case `${FETCH_ACCESS_URL}_REJECTED`: {
      const { transactionId } = action.meta

      const isFetchingURLs = {
        ...state.isFetchingURLs,
        [transactionId]: false,
      }

      return {
        ...state,
        isFetchingURLs,
        error: action.payload,
      }
    }

    case DELETE_ACCESS_KEYS: {
      const keys = { ...state.keys }

      delete keys[action.payload]

      return {
        ...state,
        keys,
      }
    }

    default:
      return { ...state }
  }
}
