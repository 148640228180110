import React, { useEffect } from 'react'
import { Row, Col } from 'reactstrap'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import Spinner from 'britive-design-system/core/components/spinner'
import UserSettings from './components/userDetails'
import ChangePassword from './components/changePassword'
import { connect } from 'react-redux'
import RegisterVerificationDevice from './components/RegisterVerificationDevice'
import { mfaFactor } from './constants'
import { getUserMFAStatus } from 'action_creators/user'
import {
  registerUserMFA,
  validateUserMFARegistration,
  resetValidateUserMFARegistration,
} from 'action_creators/user/userMfa'

const Wrapper = styled.div`
  .row {
    margin-right: 0;
    margin-left: 0;

    > div {
      padding-right: 0;
      padding-left: 0;
    }
  }
`

const Settings = ({ user, mfaRegister, mfaValidate }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    getMFAStatus()
  }, [])

  useEffect(() => {
    if (mfaValidate?.success) {
      getMFAStatus()
    }
  }, [JSON.stringify(mfaValidate)])

  const getMFAStatus = () => {
    dispatch(getUserMFAStatus(mfaFactor.totp))
  }

  const generateQRCode = () => {
    dispatch(registerUserMFA(mfaFactor.totp))
  }

  const validate = otp => {
    dispatch(validateUserMFARegistration(mfaFactor.totp, otp))
  }

  const handleCancelRegistration = () => {
    dispatch(resetValidateUserMFARegistration())
  }

  return (
    <Wrapper>
      <Row>
        <Col style={{ marginRight: 50 }}>
          <UserSettings user={user} />
        </Col>
        <Col>
          {user.canChangeOrResetPassword && <ChangePassword />}
          <RegisterVerificationDevice
            registrationStatus={user.mfa?.data?.status}
            mfaRegister={mfaRegister}
            mfaValidate={mfaValidate}
            onRegister={generateQRCode}
            onVerify={validate}
            onCancel={handleCancelRegistration}
          />
        </Col>
      </Row>
      {user.mfa.fetching || mfaRegister.fetching || mfaValidate.fetching ? (
        <Spinner size="medium" message="" overlay />
      ) : null}
    </Wrapper>
  )
}

function mapStateToProps(state) {
  return {
    user: state.user,
    mfaRegister: state.userMfa?.register,
    mfaValidate: state.userMfa?.validate,
  }
}

export default connect(mapStateToProps)(Settings)
