import React, { memo, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'

import MapUserModal from './MapUserModal'

import usePrevious from 'hooks/usePrevious'

import { updateMessageModal } from 'action_creators/message_modal'
import { updateTenantAppAccount } from 'action_creators/tenant_app'

import { preFetch } from 'utils/do_fetch'

function MapUserModalContainer(props) {
  const [finishedInitialLoad, setFinishedInitialLoad] = useState(false)
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const [isAddingRule, setIsAddingRule] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)

  const { loading, isOpen } = props
  const prevProps = usePrevious({ loading, isOpen })

  useEffect(() => {
    if (!prevProps) {
      return
    }

    const { loading: prevLoading, isOpen: prevIsOpen } = prevProps

    if (props.isOpen && prevLoading && !props.loading) {
      setFinishedInitialLoad(true)
    }

    if (prevIsOpen && !isOpen) {
      setFinishedInitialLoad(false)
    }
  }, [prevProps, loading, isOpen])

  const addRule = async ({ applyToAllEnvs }) => {
    const {
      account,
      appId,
      dispatch,
      envId,
      mapUser,
      setRefresh,
      updateUserLists,
    } = props
    const { accountId } = account
    const { userId } = selectedUser

    try {
      if (applyToAllEnvs) {
        setIsAddingRule(true)

        const response = await preFetch({
          name: 'addRule',
          path: `/apps/${appId}/environments/${envId}/accounts/${accountId}/users/${userId}`,
          method: 'post',
          postBody: {
            saveToAllEnvs: true,
          },
        })
        updateUserLists()
        setRefresh()
        dispatch(updateTenantAppAccount({ account: response.data, userId }))

        setIsAddingRule(false)
      } else {
        await mapUser(userId)
      }
    } catch (error) {
      const errorMessage = get(
        error,
        'response.data.message',
        'Unknown Error. Try again later.'
      )

      dispatch(updateMessageModal({ body: errorMessage }))
      setIsAddingRule(false)
    }

    toggleConfirmationModal()
  }

  const mapUser = async user => {
    const { mapUser, requiresHierarchicalModel } = props
    const { userId } = user
    setSelectedUser(user)

    if (!requiresHierarchicalModel) {
      toggleConfirmationModal()
    } else {
      await mapUser(userId)
    }
  }

  function toggleConfirmationModal() {
    if (isConfirmationModalOpen) {
      setSelectedUser(null)
    }

    setIsConfirmationModalOpen(!isConfirmationModalOpen)
  }

  function getConfirmationMessage() {
    const { account } = props

    if (!account || !selectedUser) {
      return ''
    }

    const { nativeName } = account
    const { firstName, lastName } = selectedUser
    const name = [firstName, lastName].join(' ')

    return `
      Do you want to map <${name}> to account <${nativeName}> across all environments in this application?
    `
  }

  return (
    <MapUserModal
      {...props}
      addRule={addRule}
      finishedInitialLoad={finishedInitialLoad}
      isAddingRule={isAddingRule}
      isConfirmationModalOpen={isConfirmationModalOpen}
      mapUser={mapUser}
      selectedUser={selectedUser}
      toggleConfirmationModal={toggleConfirmationModal}
      confirmationMessage={getConfirmationMessage()}
      mappingUser={selectedUser && props.updatingUserStatus[selectedUser.userId]}
      catalogAppName={props?.catalogAppName}
      accountType={props?.accountType}
    />
  )
}

const mapStateToProps = state => {
  return {
    updatingUserStatus: state.admin.updatingUsers,
  }
}

export default connect(mapStateToProps)(memo(MapUserModalContainer))
