import React, { Fragment, memo } from 'react'
import FontAwesome from 'react-fontawesome'
import styled from 'styled-components'

import Spinner from 'britive-ui-components/core/components/Spinner'
import TableView from 'components/table/TableV2/TableView'
import TableEllipsisCell from 'components/table/TableV2/TableEllipsisCell'

import useMaxTableHeight from 'hooks/useMaxTableHeight'

import { formatDateLongWithYear } from 'utils/format_date'

const COLUMNS = [
  { Header: 'Session ID', accessor: 'sessionId', minWidth: 150 },
  { Header: 'Time', accessor: 'checkedOutTime', Cell: FormattedTime, minWidth: 150 },
  { Header: 'Environment', accessor: 'environmentName', minWidth: 150 },
  { Header: 'Actor', accessor: 'userFullName', minWidth: 150 },
]

const Wrapper = styled.div`
  padding: 8px;
`

const styles = {
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: 10,
  },
  flex: { display: 'flex' },
  table: { width: '100%' },
  activityIcon: {
    cursor: 'pointer',
  },
  initialColumnWidth: {
    width: 20,
  },
}

function FormattedTime({ value: checkedOutTime }) {
  if (!checkedOutTime) {
    return null
  }

  return (
    <TableEllipsisCell
      value={formatDateLongWithYear({ dateTime: checkedOutTime })}
    />
  )
}

function AppActivity(props) {
  const maxTableHeight = useMaxTableHeight({
    heights: [
      // TODO: shortcut magic numbers here
      // need to do this programmatically
      props.appHeaderHeight,
      16,
    ],
    minHeight: 100,
  })

  const renderActivityIcon = (isPlaceholder, data) => {
    const { goToSessionActivity } = props

    return (
      <div style={styles.initialColumnWidth}>
        {!isPlaceholder && (
          <FontAwesome
            onClick={() => goToSessionActivity(data.sessionId)}
            name="list"
            style={styles.activityIcon}
          />
        )}
      </div>
    )
  }

  const { app, isFetchingSessions, sessions } = props

  if (!app) {
    return null
  }

  if (!app.rootEnvironmentGroup) {
    return (
      <Wrapper id="appDashboardContainer">
        Please configure and scan application.
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      {isFetchingSessions ? (
        <Fragment>
          <Spinner size="2x" />
          <span>Loading Sessions</span>
        </Fragment>
      ) : (
        <div>
          <TableView
            initialColumn={{
              InitialColumnComponent: data =>
                renderActivityIcon(false, data.original),
              InitialColumnHeaderComponent: data =>
                renderActivityIcon(true, data.original),
              showInitialColumn: true,
              initialColumnWidth: 24,
            }}
            maxHeight={`${maxTableHeight}px`}
            sortable
            columns={COLUMNS}
            data={sessions}
            emptyTableMessage="No Sessions For This Application"
            defaultSorted={[{ id: 'timestamp', desc: true }]}
            style={styles.table}
          />
        </div>
      )}
    </Wrapper>
  )
}

export default memo(AppActivity)
