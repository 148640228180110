import React, { PureComponent } from 'react'
import get from 'lodash/get'

import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'

import Button from 'britive-ui-components/core/components/Button'

import * as routing from 'services/routing'

import * as text from 'translations/english_us'
import { APPLICATION_ROOT_FORM } from './SettingsFormContainer'
import isKubernetes from 'utils/application/isKubernetes'
import isOpenshift from 'utils/application/isOpenshift'

const styles = {
  buttonWrapper: {
    position: 'sticky',
    bottom: 0,
    background: 'var(--white)',
  },
  buttonRow: {
    marginTop: -5,
    paddingTop: 8,
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  buttonRightMargin: {
    marginRight: 5,
  },
  dropdownToggle: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
}

export default class SettingsFormButtons extends PureComponent {
  render() {
    const {
      catalogApp,
      createNewMode,
      dropdownOpen,
      formMode,
      isApplicationRoot,
      isNextDisabled,
      saving,
      scanInProgress,
      saveBtnAction,
      selectedApplicationRoot,
      testInProgress,
      testPassed,

      // actions
      scanApplicationRoot,
      nextClickHandler,
      saveClickHandler,
      toggle,
      updateSaveBtnAction,
    } = this.props

    const appContainerId = get(selectedApplicationRoot, 'appContainerId')
    const supportsEnvironmentScanning = catalogApp.supportsEnvironmentScanning
    const satText =
      supportsEnvironmentScanning || !isApplicationRoot ? 'Save and Test' : 'Save'

    if (formMode !== APPLICATION_ROOT_FORM) {
      return null
    }

    return (
      <div style={styles.buttonWrapper}>
        <div className="actions-container-new-app" style={styles.buttonRow}>
          <div style={styles.flexRow}>
            {' '}
            {createNewMode && (
              <Button
                style={styles.buttonRightMargin}
                color="secondary"
                onClick={routing.appsList}
              >
                Cancel
              </Button>
            )}
            <div style={styles.flexRow}>
              <Button
                attachleftbutton={createNewMode}
                color="primary"
                spinner={testInProgress || saving}
                disabled={scanInProgress}
                onClick={saveClickHandler}
                style={{ marginRight: !createNewMode && 5 }}
              >
                {saveBtnAction === 'sfl' ? 'Save for Later' : satText}
              </Button>

              {createNewMode && (
                <Dropdown
                  isOpen={dropdownOpen}
                  toggle={toggle}
                  spinner={testInProgress || saving ? true : undefined}
                  style={styles.buttonRightMargin}
                >
                  <DropdownToggle
                    style={styles.dropdownToggle}
                    color="success"
                    caret
                  />

                  <DropdownMenu>
                    <DropdownItem onClick={() => updateSaveBtnAction('sfl')}>
                      Save for Later
                    </DropdownItem>
                    <DropdownItem onClick={() => updateSaveBtnAction('sat')}>
                      {satText}
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              )}

              {scanApplicationRoot && (
                <Button
                  attachleftbutton={createNewMode}
                  disabled={!testPassed || saving || testInProgress}
                  spinner={scanInProgress}
                  color="primary"
                  onClick={() => scanApplicationRoot(appContainerId)}
                  style={styles.buttonRightMargin}
                >
                  {text.SCAN}
                </Button>
              )}
            </div>
            {!isKubernetes(catalogApp?.name) && !isOpenshift(catalogApp?.name) && (
              <Button
                style={{ marginLeft: createNewMode ? undefined : 5 }}
                disabled={isNextDisabled}
                color="primary"
                onClick={nextClickHandler}
              >
                {isApplicationRoot ? text.DONE : text.NEXT}
              </Button>
            )}
          </div>
        </div>
      </div>
    )
  }
}
