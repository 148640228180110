import * as actions from 'actions'

const initialState = {
  deleting: false,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case `${actions.DELETE_BROKER_POOL_TOKEN}_PENDING`: {
      return {
        deleting: true,
      }
    }

    case `${actions.DELETE_BROKER_POOL_TOKEN}_FULFILLED`: {
      return {
        deleting: false,
      }
    }

    case `${actions.DELETE_BROKER_POOL_TOKEN}_REJECTED`: {
      return {
        deleting: false,
      }
    }

    default:
      return { ...state }
  }
}
