import React, { PureComponent } from 'react'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import styled from 'styled-components'
import Spinner from 'britive-ui-components/core/components/Spinner'
import { Tabs } from 'britive-ui-components/core/components/Tabs'
import ResourcesContainer from './ResourcesContainer'
import FormButtons from './FormButtons'
import Associations from 'components/Associations'
import { isEqual } from 'lodash'
import { AssociationsEditWarning } from './AssociationsEditWarning'

const Wrapper = styled.div`
  .tabs > .tab-list {
    margin-top: -12px;
  }
`

export default class PapAssociations extends PureComponent {
  state = {
    initialCheckedNodes: [],
    updatedCheckedNodes: [],
    associationsSaveCounter: 0,
  }

  componentDidUpdate(prevProps) {
    const { papGroupScope, papResourceScope } = this.props

    if (
      !isEqual(prevProps.papGroupScope, papGroupScope) ||
      !isEqual(prevProps.papResourceScope, papResourceScope)
    ) {
      const checkedNodes = Object.keys({ ...papGroupScope, ...papResourceScope })
      this.setState({
        initialCheckedNodes: checkedNodes,
        updatedCheckedNodes: checkedNodes,
      })
    }
  }

  getRootName = () => {
    const {
      applicationRoot,
      environmentGroups,
      rootGroupId,
      applicationTemplate,
    } = this.props

    // So many different sources of truth for the root name
    // this needs to be improved on the BE somehow
    let rootName =
      get(applicationRoot, 'catalogAppDisplayName') ||
      get(applicationTemplate, 'catalogAppName')

    if (get(applicationRoot, 'catalogApplication.requiresHierarchicalModel')) {
      rootName = get(environmentGroups, [rootGroupId], {}).name || rootName
    }

    return rootName
  }

  getEntityForTheSelectedItems = () => {
    const { environmentGroups, environments } = this.props
    const updatedScope = {}

    this.state.updatedCheckedNodes.forEach(itemKey => {
      if (environments[itemKey]) {
        updatedScope[itemKey] = {
          type: 'Environment',
          value: itemKey,
        }
      } else if (environmentGroups[itemKey]) {
        updatedScope[itemKey] = {
          type: 'EnvironmentGroup',
          value: itemKey,
        }
      }
    })

    return updatedScope
  }

  revertCheckedNodes = () => {
    this.setState({ updatedCheckedNodes: this.state.initialCheckedNodes })
  }

  renderAssociations = () => {
    const {
      cancelEdit,
      isEditing,
      isSaving,
      saveScope,
      toggleEditState,
      allowAssociationsInProfiles,
      thisAppManage,
      applicationRoot,
      environmentGroups,
      environments,
      isFetchingEnvironments,
      rootGroupId,
      environmentData,
      requestAccess,
      allowedScopes,
      permissions,
    } = this.props

    if (isFetchingEnvironments) {
      return null
    }
    const editAssociations = window.location.pathname.includes(
      '/details/associations/create'
    )

    return (
      <>
        {thisAppManage && (editAssociations || !requestAccess) && (
          <FormButtons
            isSaving={isSaving}
            isEditing={isEditing}
            cancelEdit={() => {
              this.revertCheckedNodes()
              cancelEdit()
            }}
            saveScope={async () => {
              try {
                await saveScope(this.getEntityForTheSelectedItems())
                this.setState({
                  associationsSaveCounter: this.state.associationsSaveCounter + 1,
                })
              } catch {
                //
              }
            }}
            toggleEditState={toggleEditState}
            allowAssociationsInProfiles={allowAssociationsInProfiles}
          />
        )}
        {editAssociations && isEditing && !isEmpty(permissions) && (
          <AssociationsEditWarning />
        )}
        <Associations
          key={`associations-${this.state.associationsSaveCounter}`}
          disabled={!isEditing}
          environmentGroups={environmentGroups}
          environments={environments}
          environmentData={environmentData}
          defaultDirectory={applicationRoot.appContainerId}
          applicationRoot={{
            id: rootGroupId,
            name: this.getRootName(),
            appIcon: applicationRoot?.iconUrl,
          }}
          checkedNodes={this.state.updatedCheckedNodes}
          onChange={updatedCheckedNodes => this.setState({ updatedCheckedNodes })}
          allowedScopes={requestAccess ? allowedScopes.data : {}}
          appName={get(applicationRoot, 'catalogAppName')}
        />
      </>
    )
  }

  render() {
    const {
      activeTab,
      cancelEdit,
      isEditing,
      isSaving,
      isRootGroupSelected,
      maxHeight,
      papGroupScope,
      papResourceScope,
      saveScope,
      selectTab,
      setResourceFormScope,
      supportsResources,
      toggleEditState,
      fetchingPapScopes,
      requestAccess,
      allowedScopes,
    } = this.props

    const adjustedMaxHeight = maxHeight + 16 - 24
    const wrapperHeight = supportsResources
      ? adjustedMaxHeight - 40
      : adjustedMaxHeight

    return (
      <Wrapper>
        {fetchingPapScopes || allowedScopes.loading ? (
          <Spinner size="2x" />
        ) : supportsResources && !requestAccess ? (
          <Tabs activeTab={activeTab} onSelectTab={selectTab}>
            <div label="Management Groups">{this.renderAssociations()}</div>
            <div label="Resources">
              <ResourcesContainer
                isEditing={isEditing}
                isSaving={isSaving}
                maxHeight={wrapperHeight + 32}
                isRootGroupSelected={isRootGroupSelected}
                toggleEditState={toggleEditState}
                cancelEdit={cancelEdit}
                papGroupScope={papGroupScope}
                saveScope={() => saveScope(this.getEntityForTheSelectedItems())}
                papResourceFormScope={papResourceScope}
                setResourceFormScope={setResourceFormScope}
              />
            </div>
          </Tabs>
        ) : (
          this.renderAssociations()
        )}
      </Wrapper>
    )
  }
}
