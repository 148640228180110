import React, { useState, useEffect } from 'react'
import Tooltip from 'britive-design-system/core/components/tooltip'
import Typography from 'britive-design-system/core/components/typography'
import Terminal from 'static/images/terminal-access.svg'
import TerminalActive from 'static/images/terminal-access-active.svg'
import TerminalIndeterminate from 'static/images/terminal-access-indeterminate.svg'
import { formattedFromNowShort, formattedFromNow } from 'utils/format_date'
import { isProfileAccessIndeterminate, shouldDisableProfileAccess } from '../utils'

const CSS_CLASSNAME_PREFIX = 'my-resources'

const AccessCell = ({ row, onProfileAccess }) => {
  const [remainingTime, setRemainingTime] = useState({
    short: '',
    full: '',
  })

  const isDisabled = shouldDisableProfileAccess(row.status)
  const isIndeterminate = isProfileAccessIndeterminate(row.status)

  useEffect(() => {
    let interval

    if (!isDisabled) {
      updateTimeRemaining()
      interval = setInterval(updateTimeRemaining, 30 * 1000)
    }

    return () => {
      clearInterval(interval)
    }
  }, [JSON.stringify(row), isDisabled])

  const updateTimeRemaining = () => {
    setRemainingTime({
      short: formattedFromNowShort(row.expirationDuration),
      full: formattedFromNow(row.expirationDuration),
    })
  }

  return (
    <div
      className={`${CSS_CLASSNAME_PREFIX}-column-access-icon${
        isDisabled ? '-disabled' : '-enabled'
      }`}
      onClick={() => {
        if (isDisabled) {
          return
        }

        onProfileAccess(row?.transactionId, row.profileId, row.resourceId)
      }}
    >
      <img
        src={
          isIndeterminate
            ? TerminalIndeterminate
            : isDisabled
            ? Terminal
            : TerminalActive
        }
      />
      {!isDisabled && (
        <Tooltip title={`${remainingTime.full} remaining`} position="top">
          <div
            className={`${CSS_CLASSNAME_PREFIX}-column-access-icon-time-remaining`}
          >
            <Typography variant="helper1">{remainingTime.short}</Typography>
          </div>
        </Tooltip>
      )}
    </div>
  )
}

export default AccessCell
