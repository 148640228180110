import get from 'lodash/get'
import * as actions from 'actions'

const initialState = {
  loading: false,
  error: null,
  data: {},
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case `${actions.GET_MFA_SETTINGS}_PENDING`: {
      return {
        ...state,
        loading: true,
        error: null,
        data: {},
      }
    }

    case `${actions.GET_MFA_SETTINGS}_FULFILLED`: {
      const data = get(action, 'payload.data')

      return {
        ...state,
        loading: false,
        error: null,
        data,
      }
    }

    case `${actions.GET_MFA_SETTINGS}_REJECTED`: {
      return {
        ...state,
        loading: false,
        error: 'Unable fetch the MFA Settings. Please try again.',
        data: {},
      }
    }

    default:
      return { ...state }
  }
}
