import React, { useEffect, useState } from 'react'
import { isEmpty, isObject } from 'lodash'
import { connect, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import Tooltip from 'britive-design-system/core/components/tooltip'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

import BritiveLogo from 'components/BritiveLogo'

import { expandNavigation } from 'action_creators/navigation'
import {
  systemAnnouncementRequest,
  openSystemAnnouncement,
} from './announcement/action'
import useWindowSize from 'hooks/useWindowSize'

import * as text from 'translations/english_us'
import Notifications from './notifications'

import useFlashTitle from 'hooks/useFlashTitle'
import AnnouncementImg from '../../../static/images/img_announcement.svg'
import QuestionMarkImg from '../../../static/images/question-mark.svg'
import NotificationImg from '../../../static/images/notification.svg'

import './index.scss'
import { supportItems } from './constant'

export const MAIN_HEADER_ID = 'main-header'

const HeaderSection = styled.header`
  .my-approvals,
  .logout {
    display: none;
  }
  @media (max-width: 768px) {
    ${({ source }) =>
      source === 'notification'
        ? `
      .nav-toggle {
          display: none;
      }

      .notifications-dropdown {
          display: none;
      }

      .my-approvals, .logout {
        display: block;
        font-size: 16px;
        font-weight: 600;
        text-decoration: underline;
      }

      .my-approvals {
        margin-left: 8px;
      }

      .logout {
        margin-right: 8px;
        width: 105px;
        text-align: right;
      }
    `
        : ''}
  }
`

const DropdownContainers = styled.div`
  display: flex;
  align-items: center;
  padding: 0 16px;
  gap: 25px;
  img {
    height: 32px;
  }

  ${({ source }) =>
    source === 'notification'
      ? `
    @media (max-width: 768px) {
      display: none;
    }
  `
      : ''}

  & .dropdown-toggle {
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:after {
      display: none;
    }
  }

  & .badge {
    background: #f55056;
    color: #ffffff;
    padding: 4px 8px;
    position: absolute;
    margin: -16px 0 0 17px;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 500;
  }

  & .notifications {
    width: 448px;
    padding: 0;
    box-sizing: border-box;

    & .notifications-header {
      padding: 16px 24px;
      display: flex;
      justify-content: space-between;
      flex-flow: column;
      min-height: 96px;
      border: 1px solid #d9d9d9;
      box-sizing: border-box;

      & .notifications-header-container {
        display: flex;
        justify-content: space-between;

        & span:nth-child(2) {
          cursor: pointer;
        }
      }

      & .header-text {
        font-size: 20px;
        font-weight: 500;
      }

      & .loader {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin: 8px;
        height: 64px;
      }

      & .bds-btn {
        width: fit-content;
      }
    }

    & .notification-cards {
      max-height: 480px;
      overflow: scroll;
      border-left: 1px solid #d8d8d8;
      border-rightt: 1px solid #d8d8d8;
      box-sizing: border-box;

      & .bds-notification-card {
        border-bottom: 1px solid #d8d8d8;
      }
    }
  }

  & .name-text {
    display: flex;
    height: 36px;
    width: 36px;
    color: #ffffff;
    background: #0461a7;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    border-radius: 18px;
    justify-content: center;
    align-items: center;
  }
`

const Header = ({
  expandNavigation,
  openNavigation,
  user,
  history,
  logout,
  notifications,
  notificationsCount,
  deletingAllNotifications,
  deletingNotifications,
  fetchingNotifications,
  systemAnnouncementRequest,
  systemAnnouncement,
  showAdmin,
}) => {
  const [notificationDropdownOpen, setNotificationDropdownOpen] = useState(false)
  const [helpDropdownOpen, setHelpDropdownOpen] = useState(false)
  const [userDropdownOpen, setUserDropdownOpen] = useState(false)
  const announcementMessage = localStorage.getItem('announcementMessage')
  const source = sessionStorage.getItem('source')
  const { innerWidth } = useWindowSize()

  const dispatch = useDispatch()

  const [setFlashTitle, startTitleFlash] = useFlashTitle()

  useEffect(() => {
    systemAnnouncementRequest()
    const intervalForRequest = setInterval(systemAnnouncementRequest, 600000)
    return () => {
      clearInterval(intervalForRequest)
    }
  }, [])

  useEffect(() => {
    setFlashTitle('🟢 New Notification')
    notificationsCount > 0 && startTitleFlash()
  }, [notificationsCount])

  useEffect(() => {
    if (innerWidth <= 768 && source === 'notification') {
      expandNavigation(false)
      setNotificationDropdownOpen(false)
      setUserDropdownOpen(false)
    }
  }, [innerWidth])

  useEffect(() => {
    if (
      isObject(systemAnnouncement?.data) &&
      !isEmpty(systemAnnouncement?.data) &&
      ((announcementMessage &&
        JSON.stringify(systemAnnouncement?.data) !== announcementMessage) ||
        !announcementMessage)
    ) {
      localStorage.clear('announcementMessage')
      dispatch(openSystemAnnouncement(true))
      localStorage.setItem(
        'announcementMessage',
        JSON.stringify(systemAnnouncement?.data)
      )
    } else if (isEmpty(systemAnnouncement?.data)) {
      dispatch(openSystemAnnouncement(false))
    }
  }, [JSON.stringify(systemAnnouncement)])

  const displayedName =
    user?.firstName && user?.lastName
      ? `${user.firstName[0]}${user.lastName[0]}`
      : user?.email
      ? user?.email[0]
      : ''

  const handleAnnouncementOpen = () => {
    const isAnnouncementOpen = systemAnnouncement?.open
    dispatch(openSystemAnnouncement(!isAnnouncementOpen))
    localStorage.setItem(
      'announcementMessage',
      JSON.stringify(systemAnnouncement?.data)
    )
  }

  return (
    <HeaderSection id={MAIN_HEADER_ID} className="main-header" source={source}>
      <div className="nav-toggle" onClick={() => expandNavigation(!openNavigation)}>
        <i className="fa fa-bars" aria-hidden="true" />
      </div>
      <div className="my-approvals">
        <NavLink to="/my-approvals">My Approvals</NavLink>
      </div>
      <BritiveLogo height={24} />
      <div className="logout" onClick={logout}>
        Logout
      </div>
      <DropdownContainers source={source}>
        {isObject(systemAnnouncement?.data) && !isEmpty(systemAnnouncement?.data) && (
          <div className="dropdown-toggle" onClick={handleAnnouncementOpen}>
            <Tooltip title="System Announcement" position="bottom">
              <img width="38" src={AnnouncementImg} />
            </Tooltip>
          </div>
        )}
        <Dropdown
          className="notifications-dropdown"
          isOpen={notificationDropdownOpen}
          toggle={() => setNotificationDropdownOpen(prev => !prev)}
        >
          <DropdownToggle
            tag="div"
            data-toggle="dropdown"
            aria-expanded={notificationDropdownOpen}
            className="dropdown-toggle"
          >
            <Tooltip title="Notifications" position="bottom">
              <img width="31" src={NotificationImg} />
            </Tooltip>
            {notifications.length > 0 && (
              <span className="badge">{notifications.length}</span>
            )}
          </DropdownToggle>
          <DropdownMenu right className="notifications">
            <Notifications
              notifications={notifications}
              dispatch={dispatch}
              onClose={() => setNotificationDropdownOpen(false)}
              fetchingNotifications={fetchingNotifications}
              deletingAllNotifications={deletingAllNotifications}
              deletingNotifications={deletingNotifications}
            />
          </DropdownMenu>
        </Dropdown>
        {showAdmin && (
          <Dropdown
            isOpen={helpDropdownOpen}
            toggle={() => setHelpDropdownOpen(prev => !prev)}
          >
            <DropdownToggle tag="div" className="dropdown-toggle">
              <Tooltip title="Help & Support" position="bottom">
                <img width="33" src={QuestionMarkImg} />
              </Tooltip>
            </DropdownToggle>
            <DropdownMenu right>
              <>
                {supportItems.map((supportItem, idx) => {
                  return (
                    <span key={`dropdown-menu-item-${idx}`}>
                      <DropdownItem className="profile-link">
                        <a target="_blank" rel="noreferrer" href={supportItem.link}>
                          {supportItem.name}
                        </a>
                      </DropdownItem>
                      {idx < supportItems.length - 1 && <DropdownItem divider />}
                    </span>
                  )
                })}
              </>
            </DropdownMenu>
          </Dropdown>
        )}
        <Dropdown
          isOpen={userDropdownOpen}
          toggle={() => {
            setUserDropdownOpen(prev => !prev)
          }}
        >
          <DropdownToggle tag="div" className="dropdown-toggle">
            <span className="name-text" title={displayedName}>
              {displayedName}
            </span>
          </DropdownToggle>
          <DropdownMenu right>
            {!user.mustChangePassword ? (
              <>
                <DropdownItem
                  className="profile-link"
                  onClick={() => history.push('/user-settings')}
                >
                  <span>Settings</span>
                </DropdownItem>
                <DropdownItem divider />
              </>
            ) : null}
            <DropdownItem className="profile-link" onClick={logout}>
              <span>{text.LOGOUT}</span>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </DropdownContainers>
    </HeaderSection>
  )
}

Header.propTypes = {
  logout: PropTypes.func,
  history: PropTypes.object,
  user: PropTypes.object,
  expandNavigation: PropTypes.func,
  openNavigation: PropTypes.bool,
}

const mapStateToProps = state => {
  return {
    user: state.user,
    openNavigation: state.navigation.openNavigation,
    notifications: state.pushNotification.notifications,
    notificationsCount: state.pushNotification.notificationsCount,
    deletingAllNotifications: state.pushNotification.deletingAllNotifications,
    deletingNotifications: state.pushNotification.deletingNotifications,
    fetchingNotifications: state.pushNotification.fetchingNotifications,
    systemAnnouncement: state.systemAnnouncementReducer,
    showAdmin: state.privileges.showAdmin,
  }
}

export default connect(mapStateToProps, {
  expandNavigation,
  systemAnnouncementRequest,
})(withRouter(Header))
