import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import startCase from 'lodash/startCase'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import styled from 'styled-components'
import toast from 'utils/toast/index.js'

import {
  ActionIconGroup as UnstyledActionIconGroup,
  ActionIconLabel,
} from 'britive-ui-components/core/components/ActionIcon'
import Spinner from 'britive-ui-components/core/components/Spinner'

import TableEllipsisCell, {
  TableEllipsisCellWithTooltip,
} from 'components/table/TableV2/TableEllipsisCell'
import TableActionIcon from 'components/TableActionIcon'
import ScanStatusCell from '../ScanStatusCell'
import MapUserModal from './MapUserModal'
import ForceActionModal from './ForceActionModal'
import { BackgroundTooltip as Tooltip } from 'components/Tooltips'

import { preFetch } from 'utils/do_fetch'
import { formatDateLongWithYear } from 'utils/format_date'
import Table from 'components/table/TableV2/Table'
import AssignedDrawer from '../AssignedDrawer'
import AssignedList from '../AssignedList'

const ActionIconGroup = styled(UnstyledActionIconGroup)`
  display: flex;
  align-items: center;
`

const MappedUserWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;

  &:last-of-type {
    margin-bottom: 0px;
  }

  div {
    max-width: unset;
  }

  > .iconWrapper {
    width: 20px;
  }

  .icon {
    margin-left: 4px;
    border: 2px solid var(--mine-shaft);
    width: 16px;
    height: 16px;
    border-radius: 50%;
    font-size: 10px;
    text-align: center;
    color: var(--mine-shaft);
    font-style: normal;
    line-height: 14px;
    font-weight: 600;
  }
`

const USER_CHECKED_IN_PAP_CODE = 'E1002'

const styles = {
  account: {
    flexDirection: 'column',
    overflow: 'hidden',
  },
  actionButton: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  actionWrapper: {
    width: '100%',
  },
  tableWrapper: {
    display: 'block',
    marginTop: -32,
    marginBottom: -20,
  },
  icon: {
    marginLeft: 4,
    marginRight: 10,
    width: 20,
    display: 'inline-block',
    height: 20,
    fontSize: 12,
    textAlign: 'center',
    color: '#9b3eec',
    fontStyle: 'normal',
    fontWeight: 600,
    cursor: 'pointer',
  },
}

// Super bloated component
// TODO: separate into container and pure component
class AdminTenantAppDataAccounts extends PureComponent {
  state = {
    isMapUserModalOpen: false,
    isPermissionDrawerOpen: false,
    availableUsers: [],
    mappedUsers: [],
    accounts: [],
    filteredAccounts: [],
    searchTerm: '',
    filterBy: 'all',
    user: {},
    isForceActionModalOpen: false,
    isForceActionInProgress: false,
    accountInUseBy: [],
    loading: false,
    isUpdatingUser: {},
    mappingOperations: [],
    unMappingOperations: [],
    selectedAccount: null,
    // All errors will eventually have error codes
    errorCode: null,
    errorMessage: '',
    refresh: 0,
    refreshModalTables: 0,
  }

  async componentDidUpdate(prevProps, prevState) {
    const { isMapUserModalOpen, selectedAccount } = this.state
    if (
      isMapUserModalOpen &&
      (!prevState.selectedAccount ||
        (selectedAccount &&
          prevState.selectedAccount.accountId !== selectedAccount.accountId))
    ) {
      await this.updateUserLists()
    }
  }

  updateUserLists = async () => {
    const { selectedEnvId, selectedEnvironmentGroupId, selectedApp } = this.props
    const { selectedAccount } = this.state
    const envId = selectedEnvId || selectedEnvironmentGroupId
    const applicationRootId =
      this.props.match.params.applicationRootId || get(selectedApp, 'appContainerId')

    this.setState({ loading: true })
    if (selectedAccount) {
      const mappedUsers = await preFetch({
        path: `/apps/${applicationRootId}/environments/${envId}/accounts/${selectedAccount.accountId}/users`,
        method: 'GET',
      })

      this.setState({
        mappedUsers: mappedUsers.data,
        loading: false,
      })
    }
  }

  getAccountTableColumns = () => {
    return [
      {
        Header: 'Name',
        accessor: 'nativeName',
        fixed: 'left',
        Cell: this.renderName,
        width: 150,
      },
      {
        Header: 'First Name',
        accessor: 'firstName',
        minWidth: 150,
      },
      {
        Header: 'Last Name',
        accessor: 'lastName',
        minWidth: 150,
      },
      {
        Header: 'Account Type',
        accessor: 'type',
        minWidth: 120,
        Cell: ({ value }) => <TableEllipsisCell value={startCase(value)} />,
      },
      {
        Header: 'Created',
        accessor: 'created',
        Cell: ({ value }) => (
          <TableEllipsisCell value={formatDateLongWithYear({ dateTime: value })} />
        ),
        minWidth: 120,
      },
      {
        Header: 'Last Updated',
        accessor: 'modified',
        Cell: ({ value }) => (
          <TableEllipsisCell value={formatDateLongWithYear({ dateTime: value })} />
        ),
        minWidth: 120,
      },
      {
        Header: 'Last Sign-In',
        accessor: 'lastSignInDate',
        Cell: ({ value }) => (
          <TableEllipsisCell value={formatDateLongWithYear({ dateTime: value })} />
        ),
        minWidth: 120,
      },
      {
        Header: 'Permissions',
        accessor: 'permissions',
        sortable: false,
        minWidth: 150,
        Cell: cellProps => (
          <AssignedList
            {...cellProps}
            onClick={() => {
              this.togglePermissionsDrawer({ account: cellProps.original })
            }}
          />
        ),
      },
      {
        Header: 'Mapped Identities',
        accessor: 'mappedUsers',
        Cell: data => this.renderUsedByList(data),
        minWidth: 150,
        sortable: false,
      },
      {
        Header: 'Scan Status',
        accessor: 'scanStatus',
        Cell: ScanStatusCell,
        minWidth: 150,
      },
      ...(this.props.thisAppManage
        ? [
            {
              Header: 'Actions',
              accessor: 'actions',
              fixed: 'left',
              sortable: false,
              Cell: data => this.renderActionIcons(data.original),
              resizable: false,
              minWidth: 64,
            },
          ]
        : []),
    ]
  }

  onClickMapUser = account => {
    this.setState({
      selectedAccount: account,
    })

    this.toggleMapUserModal()
  }

  toggleMapUserModal = () => {
    const { isMapUserModalOpen, refresh } = this.state
    this.setState({
      isMapUserModalOpen: !isMapUserModalOpen,
      ...(isMapUserModalOpen && {
        refresh: refresh + 1,
      }),
    })
  }

  togglePermissionsDrawer = ({ account = null }) => {
    this.setState({
      selectedAccount: account,
      isPermissionDrawerOpen: !!account,
    })
  }

  mapUser = async userId => {
    const {
      selectedEnvId,
      selectedEnvironmentGroupId,
      mapUserToAccount,
    } = this.props
    const envId = selectedEnvId || selectedEnvironmentGroupId
    const { selectedAccount, isUpdatingUser, refreshModalTables } = this.state

    if (selectedAccount) {
      this.setState({
        isUpdatingUser: {
          ...isUpdatingUser,
          [userId]: true,
        },
      })
      try {
        const response = await mapUserToAccount({
          accountId: selectedAccount.accountId,
          envId,
          userId,
        })

        this.setState({
          mappedUsers: response.value.data,
          refreshModalTables: refreshModalTables + 1,
          isUpdatingUser: {
            ...isUpdatingUser,
            [userId]: false,
          },
        })
      } catch (error) {
        this.setState({
          isUpdatingUser: {
            ...isUpdatingUser,
            [userId]: false,
          },
        })
        toast({
          title: get(error, 'response.data.message'),
          type: 'error',
          time: 'normal',
        })
      }
    }
  }

  unmapUser = async userId => {
    const {
      selectedEnvId,
      selectedEnvironmentGroupId,
      unmapUserFromAccount,
    } = this.props
    const { selectedAccount, isUpdatingUser, refreshModalTables } = this.state
    const envId = selectedEnvId || selectedEnvironmentGroupId

    if (selectedAccount) {
      this.setState({
        isUpdatingUser: {
          ...isUpdatingUser,
          [userId]: true,
        },
      })
      try {
        const response = await unmapUserFromAccount({
          envId,
          accountId: selectedAccount.accountId,
          userId,
          forceCheckinPaps: false,
        })

        this.setState({
          mappedUsers: response.value.data,
          refreshModalTables: refreshModalTables + 1,
          isUpdatingUser: {
            ...isUpdatingUser,
            [userId]: false,
          },
        })
      } catch (error) {
        const errorMessage = get(error, 'response.data.message')
        const errorCode = get(error, 'response.data.errorCode')

        if (errorCode === USER_CHECKED_IN_PAP_CODE) {
          this.setState({
            isForceActionModalOpen: true,
            forceUnmapUserId: userId,
            errorMessage,
            errorCode,
          })
        }
      }
    }
  }

  forceUnmapUser = async () => {
    const {
      selectedEnvId,
      selectedEnvironmentGroupId,
      unmapUserFromAccount,
    } = this.props
    const { selectedAccount, forceUnmapUserId, refreshModalTables } = this.state
    const envId = selectedEnvId || selectedEnvironmentGroupId

    this.setState({ isForceActionInProgress: true })
    if (selectedAccount) {
      try {
        const response = await unmapUserFromAccount({
          envId,
          accountId: selectedAccount.accountId,
          userId: forceUnmapUserId,
          forceCheckinPaps: true,
        })

        this.setState({
          mappedUsers: response.value.data,
          refreshModalTables: refreshModalTables + 1,
        })
      } catch (error) {
        console.log(error)
      }
    }

    this.clearForceActionModal()
  }

  clearForceActionModal = () => {
    this.setState({
      isForceActionModalOpen: false,
      isForceActionInProgress: false,
      forceUnmapUserId: null,
      errorMessage: '',
      errorCode: null,
    })
  }

  renderActionIcons = account => {
    const { selectedApp } = this.props

    const requiresHierarchicalModel = get(
      selectedApp,
      'catalogApplication.requiresHierarchicalModel'
    )

    return (
      <div style={styles.actionWrapper}>
        {account.scanStatus === 'Deleted' ? null : (
          <ActionIconGroup>
            <TableActionIcon
              isDisabled={requiresHierarchicalModel && !account.registrable}
              onClick={() => this.onClickMapUser(account)}
            >
              <span className="fa fa-user fs:14" />
              <ActionIconLabel> Map/Unmap </ActionIconLabel>
            </TableActionIcon>{' '}
          </ActionIconGroup>
        )}
      </div>
    )
  }

  renderName = ({ original: accountData }) => {
    const { nativeName, privileged } = accountData
    return (
      <Fragment>
        <TableEllipsisCell style={styles.changeCell}>
          <span style={styles.changeText}>{nativeName}</span>
        </TableEllipsisCell>
        {privileged && (
          <span>
            <div
              data-for={`${nativeName}-privileged`}
              data-tip="High Risk"
              style={styles.icon}
            >
              <i className="fas fa-bolt" />
            </div>
          </span>
        )}
        <Tooltip
          effect="solid"
          place="top"
          getContent={content => content}
          id={`${nativeName}-privileged`}
        />
      </Fragment>
    )
  }

  renderUsedByList = ({ value: mappedList, original: accountData }) => {
    if (mappedList) {
      if (mappedList.length === 0) {
        return ''
      } else {
        const accountId = get(accountData, 'accountId')
        const idString = accountId ? `${accountId.toString()}MappedUsers` : ''

        return (
          <div style={styles.account}>
            <div>
              {mappedList.map(user => (
                <MappedUserWrapper key={`${idString}${user.userId}`}>
                  <TableEllipsisCellWithTooltip
                    value={user.username}
                    elementType="div"
                  />

                  {user.mapMethod === 'Manual' && (
                    <div className="iconWrapper">
                      <div
                        className="icon"
                        data-for={idString}
                        data-tip="Manually mapped"
                      >
                        M
                      </div>
                    </div>
                  )}
                </MappedUserWrapper>
              ))}
            </div>

            <Tooltip
              effect="solid"
              place="bottom"
              getContent={content => content}
              id={idString}
            />
          </div>
        )
      }
    }

    return 'none'
  }

  render() {
    const {
      selectedAppId,
      selectedEnvId,
      selectedEnvironmentGroupId,
      selectedApp,
      match,
    } = this.props

    const {
      loading,
      isPermissionDrawerOpen,
      selectedAccount,
      refresh,
      refreshModalTables,
      filterBy,
      filteredAccounts,
      isMapUserModalOpen,
      isUpdatingUser,
      searchTerm,
      isForceActionInProgress,
      isForceActionModalOpen,
      errorMessage,
      mappedUsers,
    } = this.state

    const isHierarchical = get(
      selectedApp,
      'catalogApplication.requiresHierarchicalModel'
    )

    const entityId = selectedEnvId || selectedEnvironmentGroupId

    return entityId ? (
      <div className="accounts_table_v2">
        <Table
          columns={this.getAccountTableColumns()}
          identifier="accountId"
          dataUrl={`/apps/${selectedAppId}/environments/${entityId}/accounts`}
          emptyTableMessage={'No Accounts were found.'}
          params={{ includeMembers: true }}
          dropdownFilters={[
            {
              label: 'Scan Status',
              value: 'scanStatus',
              options: [
                { label: 'New', value: 'New' },
                { label: 'Updated', value: 'Updated' },
                { label: 'Unchanged', value: 'Unchanged' },
                { label: 'Deleted', value: 'Deleted' },
              ],
            },
            {
              label: 'Account Mapping',
              value: 'mappedStatus',
              options: [
                { label: 'Mapped', value: 'mapped' },
                { label: 'Unmapped', value: 'unmapped' },
              ],
            },
          ]}
          checkBoxFilters={[
            {
              name: 'assigned',
              value: true,
              displayText: 'With Permissions Only',
            },
            {
              name: 'privileged',
              value: true,
              displayText: 'High Risk',
            },
          ]}
          refresh={refresh}
          margin={40}
        />
        <MapUserModal
          clearSelectedAccount={() => this.setState({ selectedAccount: null })}
          filterBy={filterBy}
          filteredAccounts={filteredAccounts}
          isOpen={!!isMapUserModalOpen}
          envId={entityId}
          account={selectedAccount}
          appId={
            match.params.applicationRootId || get(selectedApp, 'appContainerId')
          }
          loading={loading}
          isUpdatingUser={isUpdatingUser}
          mapUser={this.mapUser}
          mappedUsers={mappedUsers}
          searchTerm={searchTerm}
          requiresHierarchicalModel={get(
            selectedApp,
            'catalogApplication.requiresHierarchicalModel'
          )}
          toggleMapUserModal={this.toggleMapUserModal}
          updateUserLists={this.updateUserLists}
          unmapUser={this.unmapUser}
          refresh={refreshModalTables}
          setRefresh={() =>
            this.setState({ refreshModalTables: refreshModalTables + 1 })
          }
          catalogAppName={selectedApp.catalogAppName}
          accountType={selectedAccount?.type}
        />

        <ForceActionModal
          isOpen={isForceActionModalOpen}
          errorMessage={errorMessage}
          isForceActionInProgress={isForceActionInProgress}
          onContinueClickHandler={this.forceUnmapUser}
          onCancelClickHandler={this.clearForceActionModal}
        />

        <AssignedDrawer
          isOpen={isPermissionDrawerOpen}
          isHierarchical={isHierarchical}
          toggleDrawer={account => this.togglePermissionsDrawer({ account })}
          name={get(selectedAccount, 'nativeName')}
          tabs={
            isPermissionDrawerOpen && selectedAccount
              ? [
                  {
                    name: 'Permissions',
                    dataUrl: `/apps/${selectedAppId}/environments/${entityId}/accounts/${selectedAccount.accountId}/permissions`,
                    nameIdentifier: 'permissionName',
                  },
                  {
                    name: 'Groups',
                    dataUrl: `/apps/${selectedAppId}/environments/${entityId}/accounts/${selectedAccount.accountId}/groups`,
                    nameIdentifier: 'permissionName',
                  },
                ]
              : []
          }
        />
      </div>
    ) : (
      <Spinner size="2x" />
    )
  }
}

AdminTenantAppDataAccounts.propTypes = {
  fetching: PropTypes.bool,
  accounts: PropTypes.object,
  updateAppAccounts: PropTypes.func,
  mapUserToAccount: PropTypes.func,
  unmapUserFromAccount: PropTypes.func,
  users: PropTypes.array,
  match: PropTypes.object,
  unmapTenantAppAccountToUser: PropTypes.func,
  selectedEnvironment: PropTypes.object,
}

export default connect()(withRouter(AdminTenantAppDataAccounts))
