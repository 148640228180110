import React, { PureComponent } from 'react'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import get from 'lodash/get'
import { withRouter } from 'react-router-dom'
import { FEATURE_FLAGS } from 'reducers/feature_flag'
import { getFeatureFlagById } from 'selectors/feature_flag'

import Properties from './Properties'

import { setElementRef } from 'action_creators/element_ref'
import {
  createApplicationRoot,
  updateApplicationRoot,
  updateUserAccountMappings,
  scanApplicationRoot,
  selectApplicationRootId,
  selectApplicationTemplate,
  testApplicationRoot,
} from 'action_creators/application_root'

import {
  fetchEnvironmentScansReport,
  fetchEnvironments,
} from 'action_creators/environment'

import {
  getSelectedApplicationRoot,
  getSelectedApplicationRootId,
  getSelectedApplicationTemplate,
} from 'selectors/application_root'

import {
  getEnvironments,
  getSelectedApplicationRootGroupId,
  getSelectedEnvironmentGroupId,
  getSelectedEnvironmentId,
} from 'selectors/environment'

import * as routing from 'services/routing'

import isEmpty from 'lodash/isEmpty'
import { preparePropertiesObjectForAPI } from 'utils/prepare_properties'
import {
  IS_CORRECTLY_CONFIGURED,
  IS_NOT_CORRECTLY_CONFIGURED,
} from 'translations/english_us'

const PROPERTIES_REQUIRING_REFRESH = ['showAwsAccountNumber']

class AdminTenantPropertiesContainer extends PureComponent {
  state = {
    createNewMode: true,
    createNewStepNumber: 1,
    defaultState: null,
    dropdownOpen: false, // move this to SettingsForm
    fields: {},
    finalStep: false,
    isCreationModalOpen: false,
    isDeletionModalOpen: false,
    saveBtnAction: 'sat',
    saveError: null,
    saveSuccessful: false,
    scanInProgress: false,
    scanMessage: '',
    scanModalResultsOpen: false,
    scanModalData: {},
    scanPassed: false,
    successModalOpen: false,
    testInProgress: false,
    testModalResultsOpen: false,
    testModalResultsSuccess: false,
    testPassed: false,
    testMessage: '',
  }

  async componentDidMount() {
    const {
      dispatch,
      selectedApplicationTemplate,
      setBreadcrumbTrail,
      selectedApplicationRoot,
      applicationRouterMatch: match,
      history,
    } = this.props

    const { appId } = match.params
    const { location } = history
    const testResults = get(location, 'state.testResults')

    if (appId) {
      const { catalogAppDisplayName: appName } = selectedApplicationRoot
      setBreadcrumbTrail([{ title: `${appName} Properties` }])
      this.setState({ createNewMode: false })

      if (testResults) {
        return this.setState({ ...testResults })
      }
    } else if (selectedApplicationTemplate) {
      setBreadcrumbTrail([{ title: 'Create Application' }])
    } else {
      routing.appsList()
      dispatch(selectApplicationRootId(null))
    }
  }

  componentDidUpdate(prevProps) {
    const { selectedApplicationRoot: appRoot } = this.props
    const { selectedApplicationRoot: prevAppRoot } = prevProps

    if (
      get(prevAppRoot, 'rootEnvironmentGroup') !==
      get(appRoot, 'rootEnvironmentGroup')
    ) {
      const passed = !isEmpty(get(appRoot, 'rootEnvironmentGroup', {}))

      this.setState({
        scanPassed: passed,
        testPassed: passed,
      })
    }
  }

  toggleCreationModal = () => {
    this.setState({ isCreationModalOpen: !this.state.isCreationModalOpen })
  }

  toggleDeletionModal = () => {
    this.setState({ isDeletionModalOpen: !this.state.isDeletionModalOpen })
  }

  toggle = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen,
    }))
  }

  handleChange = event => {
    const { target } = event
    const parent = !!target.getAttribute && target.getAttribute('dataparent')
    const { fields = {} } = this.state

    if (event.isNotPropertyType) {
      this.setState({
        fields: {
          ...fields,
          [event.valueKey]: target.value,
        },
      })

      return
    }

    if (parent) {
      this.setState({
        fields: {
          ...fields,
          [parent]: {
            propertyTypes: {
              ...(fields[parent] ? fields[parent].propertyTypes : null),
              [target.name]:
                target.type === 'checkbox' ? target.checked : target.value,
            },
          },
        },
      })
    } else {
      this.setState({
        fields: {
          ...fields,
          propertyTypes: {
            ...fields.propertyTypes,
            [target.name]:
              target.type === 'checkbox' ? target.checked : target.value,
          },
        },
      })
    }
  }

  updateSaveBtnAction = saveBtnAction => {
    this.setState({ saveBtnAction })
  }

  updateFieldsState = fields => {
    this.setState({ fields, defaultState: fields })
  }

  createApplicationRoot = async () => {
    const { dispatch, selectedApplicationTemplate } = this.props
    const { fields } = this.state
    const { catalogAppId: id } = selectedApplicationTemplate

    try {
      const fieldsName = get(fields, 'propertyTypes.displayName')
      const { action } = await dispatch(
        createApplicationRoot({
          catalogAppId: id,
          name: fieldsName || selectedApplicationTemplate.catalogAppName,
        })
      )
      if (fieldsName) {
        delete fields.propertyTypes.displayName
      }
      this.setState({ fields: { ...fields } })
      const { appContainerId: applicationRootId } = action.payload.data
      dispatch(selectApplicationRootId(applicationRootId))
      await this.saveApplicationRootUpdates(
        applicationRootId,
        this.state.saveBtnAction
      )
      if (this.state.saveSuccessful) {
        const {
          testPassed,
          testModalResultsOpen,
          testModalResultsSuccess,
          testInProgress,
          testMessage,
        } = this.state
        routing.appProperties({
          appId: applicationRootId,
          state: {
            testResults: {
              testPassed,
              testModalResultsOpen,
              testModalResultsSuccess,
              testInProgress,
              testMessage,
            },
            tab: 'Settings',
          },
        })
        dispatch(selectApplicationTemplate(null))
        return Promise.resolve(applicationRootId)
      }
    } catch (error) {
      this.setState({
        successModalOpen: true,
        saveSuccessful: false,
        saveError: get(error, 'response.data.message', 'Something went wrong!'),
      })
    }
  }

  saveApplicationRootUpdates = async (applicationRootId, saveAction) => {
    const {
      dispatch,
      selectedApplicationRootId,
      selectedApplicationRoot,
    } = this.props
    const { fields } = this.state

    try {
      const updatedProperties = preparePropertiesObjectForAPI(fields)
      const rootId = applicationRootId || selectedApplicationRootId

      if (!isEmpty(fields)) {
        const shouldRefreshApp = this.shouldRefreshApp()

        const userAccountMappings = (
          fields.userAccountMappings ||
          selectedApplicationRoot.userAccountMappings ||
          []
        ).map(({ name, description }) => ({ name, description }))

        await dispatch(
          updateUserAccountMappings(rootId, {
            userAccountMappings,
          })
        )

        await dispatch(updateApplicationRoot(rootId, updatedProperties))

        if (shouldRefreshApp) {
          await dispatch(fetchEnvironments(rootId))
        }

        if (saveAction === 'sat') {
          await this.testApplicationRoot(applicationRootId)
        } else if (saveAction === 'sfl') {
          routing.appsList()
        }
      } else {
        if (saveAction === 'sat') {
          await this.testApplicationRoot(rootId)
        } else if (saveAction === 'sfl') {
          routing.appsList()
        }
      }
      this.setState(
        {
          saveSuccessful: true,
          createNewMode: false,
        },
        () => Promise.resolve()
      )
    } catch (error) {
      this.setState({
        successModalOpen: true,
        saveSuccessful: false,
        saveError: get(error, 'response.data.message', 'Something went wrong!'),
        fields: this.state.defaultState,
      })
    }
  }

  testApplicationRoot = async (id, preCheck) => {
    const {
      dispatch,
      selectedApplicationRoot: { catalogApplication },
    } = this.props

    const shouldTest = catalogApplication.supportsEnvironmentScanning

    if (!shouldTest) {
      return this.setState({
        successModalOpen: true,
        saveSuccessful: true,
        saveError: null,
      })
    }

    this.setState({
      testInProgress: !preCheck,
    })

    try {
      const response = await dispatch(testApplicationRoot(id))
      const { success, message } = response.action.payload.data

      return this.setState({
        testModalResultsOpen: !preCheck,
        testModalResultsSuccess: success,
        testInProgress: false,
        testPassed: success,
        testMessage:
          message && message.length > 0
            ? message
            : `Application ${
                success ? IS_CORRECTLY_CONFIGURED : IS_NOT_CORRECTLY_CONFIGURED
              }`,
      })
    } catch (error) {
      this.setState({
        testModalResultsOpen: !preCheck,
        testModalResultsSuccess: false,
        testInProgress: false,
        testPassed: false,
        testMessage: get(
          error,
          'response.data.message',
          `Application ${IS_NOT_CORRECTLY_CONFIGURED}`
        ),
      })
    }
  }

  scanApplicationRoot = async (id, preCheck) => {
    const { dispatch, history, selectedApplicationRoot: app } = this.props

    this.setState({ scanInProgress: !preCheck })

    try {
      const response = await dispatch(scanApplicationRoot(id))
      const isHierarchical = get(app, 'catalogApplication.requiresHierarchicalModel')
      const { status, error } = response.action.payload.data
      this.setState({
        scanModalResultsOpen: true,
        scanModalData: {
          header: status ? `Scan ${status}` : '',
          success: !error,
          message: error
            ? error
            : `The scan is submitted.${
                isHierarchical
                  ? ''
                  : ' Please refresh the environment tree after the scan is completed to view changes.'
              }`,
          onClose: () =>
            error
              ? this.toggleModal()
              : history.push(`/admin/applications/${id}/scans`),
        },
      })
    } catch (error) {
      const errorMessage = get(error, 'response.data.message')
      this.setState({
        scanModalResultsOpen: true,
        scanModalData: {
          header: 'Scan Error',
          success: false,
          message: errorMessage
            ? errorMessage
            : 'Something went wrong while submitting the scan. Please try again!.',
          onClose: this.toggleModal,
        },
      })
    }
  }

  fetchScanReports = async () => {
    const { dispatch, selectedApplicationRoot: app } = this.props
    const appId = get(app, 'appContainerId')

    if (!appId) {
      return
    }

    return await dispatch(fetchEnvironmentScansReport({ appId }))
  }

  toggleModal = () => {
    this.setState({
      successModalOpen: false, // this modal can only be closed
      testModalResultsOpen: false, // this modal can only be closed
      scanModalResultsOpen: false, // this modal can only be closed
    })
  }

  onClickMoveNextTab = () => {
    const { selectedApplicationRoot } = this.props

    const sections = get(
      selectedApplicationRoot,
      'catalogApplication.uiSettings',
      []
    )

    this.setState({
      createNewStepNumber: this.state.createNewStepNumber + 1,
      finalStep: this.state.createNewStepNumber + 1 === sections.length,
    })
  }

  onCancel = () => {
    this.setState({ fields: this.state.defaultState })
  }

  setElementRef = ({ name, height }) => {
    const { dispatch } = this.props

    dispatch(setElementRef({ name, height }))
  }

  shouldRefreshApp = () => {
    const { fields } = this.state
    const { selectedApplicationRoot: app } = this.props
    const oldPropertyArray = get(app, 'catalogApplication.propertyTypes', [])

    return PROPERTIES_REQUIRING_REFRESH.some(property => {
      const oldProperty = oldPropertyArray.find(prop => prop.name === property)
      const oldPropertyValue = oldProperty && oldProperty.value
      const newPropertyValue = get(fields, ['propertyTypes', property])

      return newPropertyValue !== undefined && oldPropertyValue !== newPropertyValue
    })
  }

  render() {
    const {
      accessToken,
      appHeaderHeight,
      history,
      fetching,
      saving,
      applicationRouterMatch: match,
      selectedApplicationRoot,
      selectedApplicationTemplate,
      selectedApplicationRootGroupId,
      selectedEnvironmentId,
      selectedEnvironmentGroupId,
      selectedEntityId,
      selectEntity,
      entityType,
      adaEnabled,
      thisAppManage,
    } = this.props

    const {
      createNewMode,
      dropdownOpen,
      fields,
      isCreationModalOpen,
      isDeletionModalOpen,
      saveBtnAction,
      saveSuccessful,
      saveError,
      successModalOpen,
      scanInProgress,
      scanModalData,
      scanModalResultsOpen,
      scanPassed,
      testInProgress,
      testModalResultsSuccess,
      testMessage,
      testModalResultsOpen,
      testPassed,
    } = this.state

    const isHierarchical = get(
      selectedApplicationRoot,
      'catalogApplication.requiresHierarchicalModel'
    )

    const supportsScanning = get(
      selectedApplicationRoot,
      'catalogApplication.supportsEnvironmentScanning'
    )

    // TODO: don't need to pass all these props
    // need an intermediary container component
    return (
      <Properties
        appHeaderHeight={appHeaderHeight}
        accessToken={accessToken}
        createApp={this.createApplicationRoot}
        createNewMode={createNewMode}
        dropdownOpen={dropdownOpen}
        entityType={entityType}
        fetching={fetching}
        fields={fields}
        handleChange={this.handleChange}
        history={history}
        isApplicationRoot={
          (!selectedEnvironmentGroupId && !selectedEnvironmentId) || isHierarchical
        }
        isCreationModalOpen={isCreationModalOpen}
        isDeletionModalOpen={isDeletionModalOpen}
        isEnvironment={!!selectedEnvironmentId}
        isEnvironmentGroup={!!selectedEnvironmentGroupId}
        location={history.location}
        match={match}
        onCancel={this.onCancel}
        onClickMoveNextTab={this.onClickMoveNextTab}
        saveBtnAction={saveBtnAction}
        saveUpdates={this.saveApplicationRootUpdates}
        saving={saving}
        scanApplicationRoot={this.scanApplicationRoot}
        scanInProgress={scanInProgress}
        scanModalResultsOpen={scanModalResultsOpen}
        scanModalData={scanModalData}
        scanPassed={scanPassed}
        selectedApplicationRoot={selectedApplicationRoot}
        selectedApplicationTemplate={selectedApplicationTemplate}
        selectedEnvironmentId={selectedEnvironmentId}
        selectedEnvironmentGroupId={selectedEnvironmentGroupId}
        selectedEntityId={selectedEntityId}
        selectedApplicationRootGroupId={selectedApplicationRootGroupId}
        selectEntity={selectEntity}
        successModalOpen={successModalOpen}
        saveSuccessful={saveSuccessful}
        saveError={saveError}
        supportsScanning={supportsScanning}
        testModalResultsOpen={testModalResultsOpen}
        testModalResultsSuccess={testModalResultsSuccess}
        testMessage={testMessage}
        testInProgress={testInProgress}
        testPassed={testPassed}
        toggle={this.toggle}
        toggleModal={this.toggleModal}
        toggleCreationModal={this.toggleCreationModal}
        toggleDeletionModal={this.toggleDeletionModal}
        updateFieldsState={this.updateFieldsState}
        updateSaveBtnAction={this.updateSaveBtnAction}
        setElementRef={this.setElementRef}
        adaEnabled={adaEnabled}
        thisAppManage={thisAppManage}
      />
    )
  }
}

AdminTenantPropertiesContainer.propTypes = {
  accessToken: PropTypes.string,
  account: PropTypes.object,
  selectedApplicationRootId: PropTypes.string,
  dispatch: PropTypes.func,
  fetching: PropTypes.bool,
  location: PropTypes.object,
  match: PropTypes.object,
  saving: PropTypes.bool,
  selectedApplicationRoot: PropTypes.object,
  adaEnabled: PropTypes.bool,
}

const mapStateToProps = (state, { match }) => {
  return {
    account: state.account,
    applicationRouterMatch: match,
    // TODO: make selector
    fetching: state.applicationRoot.fetchingApplicationRoot,
    selectedApplicationRoot: getSelectedApplicationRoot(state),
    selectedApplicationRootId: getSelectedApplicationRootId(state),
    selectedApplicationTemplate: getSelectedApplicationTemplate(state),
    selectedApplicationRootGroupId: getSelectedApplicationRootGroupId(state),
    selectedEnvironmentId: getSelectedEnvironmentId(state),
    selectedEnvironmentGroupId: getSelectedEnvironmentGroupId(state),
    environments: getEnvironments(state),
    // TODO: make selector
    saving:
      state.applicationRoot.updatingApplicationRoot ||
      state.applicationRoot.creatingApplicationRoot,
    accessToken: state.account.accessToken,
    adaEnabled: getFeatureFlagById({
      id: FEATURE_FLAGS.ada,
      state,
    }),
  }
}

const withConnect = connect(mapStateToProps)

export default compose(withConnect, withRouter)(AdminTenantPropertiesContainer)
