import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Route, Switch, withRouter } from 'react-router-dom'
import Cookies from 'js-cookie'
import queryString from 'query-string'

import Spinner from 'britive-design-system/core/components/spinner'
import BritiveLogo from 'components/BritiveLogo'
import {
  authenticateUser,
  sendOneTimePasswordEmail,
  setIsInitialAuthing,
  updatePassword,
} from 'action_creators/account'
import { updateAuthenticationInfo } from 'action_creators/account'
import { getUserPure } from 'action_creators/user'
import doFetch, { preFetch } from 'utils/do_fetch'
import generateRandomString, { base64URLEncode } from 'utils/generateRandomString'
import {
  MFA_SETUP,
  RESET_PASSWORD,
  SOFTWARE_TOKEN_MFA,
  VALIDATE_USER,
} from 'constants/cognitoAuthentication'

import LoginForm from './components/LoginForm'
import MFAForm from './components/MFAForm.js'
import ResetPasswordForm from './components/ResetPasswordForm'
import sha256 from 'crypto-js/sha256'
import Base64 from 'crypto-js/enc-base64'
import ChangePasswordForm from './components/ChangePasswordForm'
import { handleCLIRedirect } from 'utils/handle-cli-redirect'
import SSO from './components/SSO'

const initialValues = {
  username: '',
  password: '',
  oneTimePassword: '',
  newPassword: '',
  confirmPassword: '',
  currentPassword: '',
  mfaOtp: '',
}
const LoginRoutes = ({
  errorMessage,
  dispatch,
  history,
  onSubmit,
  accessToken,
  refreshToken,
  challenge,
  resetChallenge,
  challengeParameters,
  identityProviders,
  isInitialAuthing,
  identityProviderType,
}) => {
  const [fields, setFields] = useState(initialValues)

  const [error, setError] = useState(null)
  const [isEmailSentModalOpen, setIsEmailSentModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setError(errorMessage)
  }, [errorMessage])

  useEffect(() => {
    const pathnames = ['/login', '/login/reset-password']
    pathnames.includes(location?.pathname) && setFields(initialValues)
    setError(null)
  }, [location?.pathname])

  const redirectAfterLogin = () =>
    window.location.assign(location?.state?.from?.pathname || '/')

  useEffect(() => {
    setError(null)
  }, [challenge])

  const handleLoginSubmit = async event => {
    setError(null)
    event.preventDefault()
    setLoading(true)
    if (fields.username && (fields.password || challenge === VALIDATE_USER)) {
      try {
        await onSubmit(fields)
        setTimeout(
          () => {
            setLoading(false)
          },
          challenge === VALIDATE_USER ? 0 : 2000
        )
      } catch (error) {
        setError(error?.response?.data?.message)
        setLoading(false)
      }
    }
  }

  const handleFieldChange = event => {
    const { name, value } = event.target
    setFields(fields => ({
      ...fields,
      [name]: value,
    }))
    setError(null)
  }

  const sendOTPEmail = async event => {
    event.preventDefault()
    setError(null)
    setLoading(true)
    try {
      await dispatch(sendOneTimePasswordEmail(fields.username))
      setIsEmailSentModalOpen(true)
    } catch (error) {
      setError(error?.response?.data?.message)
    } finally {
      setLoading(false)
    }
  }

  const changePassword = async (event, challenge) => {
    setError(null)
    event.preventDefault()
    const { newPassword, oneTimePassword, username, currentPassword } = fields
    let response
    setLoading(true)
    const authParameters = {
      username,
      session: challengeParameters?.session,
      password: newPassword,
      challenge,
      ...(challenge === RESET_PASSWORD
        ? { code: oneTimePassword }
        : { currentPassword }),
    }

    try {
      response = await dispatch(updatePassword(authParameters))

      await dispatch(updateAuthenticationInfo(response.value))

      if (
        ![SOFTWARE_TOKEN_MFA, MFA_SETUP].includes(
          response?.value?.data?.authenticationResult?.challengeParameters?.challenge
        )
      ) {
        const userResponse = await preFetch({
          name: 'getUser',
          path: '/access/users',
          method: 'get',
        })

        await dispatch(getUserPure(userResponse))
        Cookies.set('signInMethod', 'login')
        redirectAfterLogin()
        return
      }

      history.push('/login/mfa', {
        from: location?.state?.from,
      })
    } catch (error) {
      setError(error?.response?.data?.message)
    } finally {
      setLoading(false)
    }
  }

  const submitMFAOTP = async event => {
    setError(null)
    event.preventDefault()
    setLoading(true)
    try {
      const fromCli = sessionStorage.getItem('cli')
      const response = await dispatch(
        authenticateUser({
          authParameters: {
            username: fields.username,
            code: fields.mfaOtp,
            challenge: challengeParameters.challenge,
            session: challengeParameters.session,
            ...(challengeParameters.challenge === MFA_SETUP && {
              AccessToken: accessToken,
              RefreshToken: refreshToken,
            }),
            ...(fromCli && { cliToken: fromCli, type: 'CLI' }),
          },
        })
      )

      Cookies.set('signInMethod', 'login')

      if (handleCLIRedirect(response, history)) {
        if (response) {
          await dispatch(updateAuthenticationInfo(response))
        }

        dispatch(setIsInitialAuthing(true))
        redirectAfterLogin()
      }
    } catch (error) {
      setError(error?.response?.data?.message)
      setLoading(false)
    }
    dispatch(setIsInitialAuthing(false))
  }

  const initSSO = async event => {
    setError(null)
    event?.preventDefault()
    const { search, origin } = location
    setLoading(true)
    try {
      const response = await doFetch({
        path: `/auth/sso-url${search}`,
        method: 'GET',
        allow401: true,
      })

      // logs to debug PAB-14204
      console.log('sso-url response')
      console.log(response)

      if (!response) {
        history.push('/login')
        return
      }

      const { loginUrl, logoutUrl } = response.data
      const isIdpTypeBritive = identityProviderType.includes('Britive')
      const cliToken = sessionStorage.getItem('cli')
      const params = queryString.parse(location.search)

      let britiveIdpLoginUrl = loginUrl

      if (isIdpTypeBritive) {
        if (cliToken) {
          britiveIdpLoginUrl = `${loginUrl}&token=${cliToken}`
        } else if (params?.redirectUrl) {
          britiveIdpLoginUrl = `${loginUrl}&redirectUrl=${params?.redirectUrl}`
        }
      }

      sessionStorage.setItem('ssoSignIn', britiveIdpLoginUrl)

      isIdpTypeBritive
        ? sessionStorage.setItem('ssoSignOut', logoutUrl)
        : sessionStorage.setItem(
            'ssoSignOut',
            `${response.data.logoutUrl}&redirect_uri=${origin}/login&logout_uri=${origin}/login`
          )

      const redirectURI = `${origin}/login`

      const randomString = base64URLEncode(generateRandomString(64))

      // logs to debug PAB-14204
      console.log('set code_verifier', randomString)
      sessionStorage.setItem('codeVerifier', randomString)
      const hashedString = base64URLEncode(Base64.stringify(sha256(randomString)))

      let url = `${loginUrl}&redirect_uri=${redirectURI}`
      url += `&code_challenge=${hashedString}`
      url += `&code_challenge_method=S256`

      // logs to debug PAB-14204
      console.log('url', url)
      window.location.assign(isIdpTypeBritive ? britiveIdpLoginUrl : url)
    } catch (error) {
      setError(error?.response?.data?.message)
      setLoading(false)
    }
  }

  const ssoURL = sessionStorage.getItem('ssoSignIn')

  return (
    <div className="login-container">
      <div className="section">
        <BritiveLogo height={32} />
        {isInitialAuthing ? (
          <>
            <div className="loader"></div>
            <Spinner size="medium" message="Loading" />
          </>
        ) : (
          <div className="form-container">
            {loading && (
              <>
                <div className="loader"></div>
                <Spinner size="medium" />
              </>
            )}
            <Switch>
              <Route
                exact
                path="/login/sso"
                render={props => (
                  <SSO {...props} errorMessage={error} initSSO={initSSO} />
                )}
              />
              <Route
                exact
                path="/login/reset-password"
                render={props => (
                  <ResetPasswordForm
                    {...props}
                    loading={loading}
                    fields={fields}
                    handleChange={handleFieldChange}
                    sendOTPEmail={sendOTPEmail}
                    errorMessage={error}
                    isEmailSentModalOpen={isEmailSentModalOpen}
                    changePassword={changePassword}
                    setIsEmailSentModalOpen={setIsEmailSentModalOpen}
                  />
                )}
              />

              <Route
                exact
                path="/login/change-password"
                render={props => (
                  <ChangePasswordForm
                    {...props}
                    loading={loading}
                    fields={fields}
                    handleChange={handleFieldChange}
                    errorMessage={error}
                    changePassword={changePassword}
                  />
                )}
              />

              <Route
                exact
                path="/login/mfa"
                render={props => (
                  <MFAForm
                    {...props}
                    loading={loading}
                    submitMFAOTP={submitMFAOTP}
                    errorMessage={error}
                    fields={fields}
                    handleChange={handleFieldChange}
                  />
                )}
              />

              <Route
                exact
                path=""
                render={props => (
                  <LoginForm
                    {...props}
                    loading={loading}
                    fields={fields}
                    challenge={challenge}
                    resetChallenge={resetChallenge}
                    ssoURL={ssoURL}
                    errorMessage={error}
                    handleSubmit={handleLoginSubmit}
                    handleChange={handleFieldChange}
                    identityProviders={identityProviders}
                  />
                )}
              />
            </Switch>
          </div>
        )}
      </div>
      <div className="section">
        <aside className="login-side-image" />
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  challengeParameters: state?.account?.challengeParameters,
  isInitialAuthing: state?.account?.isInitialAuthing,
  accessToken: state?.account?.accessToken,
  refreshToken: state?.account?.refreshToken,
  identityProviderType: state?.account.identityProviderType,
})

export default withRouter(connect(mapStateToProps)(LoginRoutes))
