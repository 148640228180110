import * as actions from 'actions'
import get from 'lodash/get'
import orderBy from 'lodash/orderBy'
import { FETCH_SHARED_PAP_STATUS_V1 } from 'action_creators/access'

import toast from 'utils/toast'
import { createStandaloneToast } from '@chakra-ui/react'

export default function reducer(
  state = {
    fetching: {},
    fetched: false,
    error: null,
    tenantAppPaps: {},
    notifications: [],
    papUpdaterAccessStatuses: {},
    fetchingAccessExpiration: false,
    fetchedAccessExpiration: false,

    //user apps
    userApps: {},
    fetchingUserApps: false,
    fetchedUserApps: false,

    userEnvironments: {},
    fetchingUserEnvironments: false,

    userProfiles: {},
    fetchingUserProfiles: false,

    allProfiles: {},
    fetchingAllProfiles: false,
    allProfilesCount: null,

    favoriteProfiles: {},
    fetchingFavoriteProfiles: false,
    favoriteProfilesCount: null,

    frequentlyUsedProfiles: {},
    fetchingFrequentlyUsedProfiles: false,
    frequentlyUsedProfilesCount: null,

    checkedOutProfiles: {},
    fetchingCheckedOutProfiles: false,
    checkedOutProfilesCount: null,

    fetchingSharedPapStatus: true,
    isChangingFavoriteProfiles: new Set(),

    isCheckingOutProfiles: new Set(),
    isCheckingInProfiles: new Set(),
    timeoutToast: {},

    sharedPapStatus: {},
    extensionQueue: [],
    extendingExpiration: false,
    extensionCancelledProfiles: [],
  },
  action
) {
  switch (action.type) {
    /**
     * USER TENANT APP PAPS
     */
    case `${actions.USER_TENANT_APP_PAPS}_PENDING`: {
      const { appId, envId } = action.meta
      const fetching = { ...state.fetching }

      const oldAppFetching = fetching[appId]
      const newAppFetching = oldAppFetching ? { ...oldAppFetching } : {}
      newAppFetching[envId] = true
      fetching[appId] = newAppFetching

      return {
        ...state,
        fetching,
      }
    }

    case `${actions.USER_TENANT_APP_PAPS}_FULFILLED`: {
      const { appId, envId } = action.meta
      const tenantAppPaps = { ...state.tenantAppPaps }
      const oldAppPaps = tenantAppPaps[appId]
      const newAppPaps = oldAppPaps ? { ...oldAppPaps } : {}
      const newEnvApps = {}

      action.payload.data.forEach(pap => {
        const { appContainerId, papId } = pap

        if (appContainerId === appId) {
          newEnvApps[papId] = pap
        }
      })

      newAppPaps[envId] = newEnvApps

      tenantAppPaps[appId] = newAppPaps

      const fetching = { ...state.fetching }

      const oldAppFetching = fetching[appId]
      const newAppFetching = oldAppFetching ? { ...oldAppFetching } : {}
      newAppFetching[envId] = false
      fetching[appId] = newAppFetching

      return {
        ...state,
        fetching,
        fetched: true,
        tenantAppPaps,
      }
    }

    case `${actions.USER_TENANT_APP_PAPS}_REJECTED`: {
      const { appId, envId } = action.meta
      const fetching = { ...state.fetching }

      const oldAppFetching = fetching[appId]
      const newAppFetching = oldAppFetching ? { ...oldAppFetching } : {}
      newAppFetching[envId] = false
      fetching[appId] = newAppFetching

      return {
        ...state,
        fetching,
        fetched: null,
        error: action.payload,
      }
    }

    /**
     * CHECK OUT
     */

    case `${actions.ACCESS_CHECK_OUT_PAP}_PENDING`: {
      const {
        environmentId,
        papId,
        appContainerId,
        accessType,
        papName,
      } = action.meta
      const id = `${appContainerId}-${environmentId}-${papId}-${accessType}`
      let timeoutToast = { ...state.timeoutToast }
      timeoutToast[id] = setTimeout(() => {
        toast({
          title: `Profile checkout for '${papName}' in progress. Please wait.`,
          type: 'info',
          time: 'infinite',
          id,
        })
      }, 10000)
      return {
        ...state,
        timeoutToast,
        isCheckingOutProfiles: new Set(state.isCheckingOutProfiles).add(id),
      }
    }

    case `${actions.ACCESS_CHECK_OUT_PAP}_FULFILLED`: {
      const { environmentId, papId, appContainerId, accessType } = action.meta
      const id = `${appContainerId}-${environmentId}-${papId}-${accessType}`
      const checkedOutProfiles = { ...state.checkedOutProfiles }
      const isCheckingOutProfiles = new Set(state.isCheckingOutProfiles)
      isCheckingOutProfiles.delete(id)
      checkedOutProfiles[id] = action.payload.data
      const frequentlyUsedProfiles = { ...state.frequentlyUsedProfiles }
      let frequentlyUsedProfilesCount = state.frequentlyUsedProfilesCount
      if (frequentlyUsedProfilesCount < 10) {
        frequentlyUsedProfiles[id] = action.payload.data
      }
      const timeoutToast = { ...state.timeoutToast }
      clearTimeout(timeoutToast[id])
      const standAloneToast = createStandaloneToast()
      standAloneToast.close(id)
      delete timeoutToast[id]
      return {
        ...state,
        checkedOutProfiles,
        checkedOutProfilesCount: Object.keys(checkedOutProfiles).length,
        frequentlyUsedProfiles,
        frequentlyUsedProfilesCount: Object.keys(frequentlyUsedProfiles).length,
        isCheckingOutProfiles,
        timeoutToast,
      }
    }

    case `${actions.ACCESS_CHECK_OUT_PAP}_REJECTED`: {
      const { environmentId, papId, appContainerId, accessType } = action.meta
      const id = `${appContainerId}-${environmentId}-${papId}-${accessType}`
      const isCheckingOutProfiles = new Set(state.isCheckingOutProfiles)
      isCheckingOutProfiles.delete(id)
      const timeoutToast = { ...state.timeoutToast }
      clearTimeout(timeoutToast[id])
      const standAloneToast = createStandaloneToast()
      standAloneToast.close(id)
      delete timeoutToast[id]
      return {
        ...state,
        isCheckingOutProfiles,
        timeoutToast,
      }
    }

    case `${actions.ACCESS_CHECK_IN_PAP}_PENDING`: {
      const { transactionId } = action.meta
      return {
        ...state,
        isCheckingInProfiles: new Set(state.isCheckingInProfiles).add(transactionId),
      }
    }

    case `${actions.ACCESS_CHECK_IN_PAP}_FULFILLED`: {
      const {
        environmentId,
        papId,
        appContainerId,
        accessType,
        transactionId,
        type,
      } = action.meta
      const id = `${appContainerId}-${environmentId}-${papId}-${accessType}`
      const checkedOutProfiles = { ...state.checkedOutProfiles }
      const isCheckingInProfiles = new Set(state.isCheckingInProfiles)
      const profile = {
        ...state[`${type}Profiles`][id],
      }
      Object.keys(checkedOutProfiles).forEach(profileId => {
        if (checkedOutProfiles[profileId].transactionId === transactionId) {
          delete checkedOutProfiles[profileId]
        }
      })
      isCheckingInProfiles.delete(transactionId)
      return {
        ...state,
        isCheckingInProfiles,
        checkedOutProfiles,
        checkedOutProfilesCount: Object.keys(checkedOutProfiles).length,
        ...(type !== 'checkedOut' && {
          [`${type}Profiles`]: {
            ...state[`${type}Profiles`],
            [id]: profile,
          },
        }),
      }
    }

    case `${actions.ACCESS_CHECK_IN_PAP}_REJECTED`: {
      const { transactionId } = action.meta
      const isCheckingInProfiles = new Set(state.isCheckingInProfiles)
      isCheckingInProfiles.delete(transactionId)
      return {
        ...state,
        isCheckingInProfiles,
      }
    }

    case `${actions.GET_CHECKED_OUT_PROFILES}_FULFILLED`: {
      const checkedOutProfilesList = get(action, 'payload.data', [])
      const checkedOutProfiles = {}
      checkedOutProfilesList.forEach(profile => {
        const { appContainerId, environmentId, papId, accessType } = profile
        checkedOutProfiles[
          `${appContainerId}-${environmentId}-${papId}-${accessType}`
        ] = profile
      })
      return {
        ...state,
        checkedOutProfiles,
        checkedOutProfilesCount: checkedOutProfilesList.length,
        fetchingCheckedOutProfiles: false,
      }
    }

    case `${actions.GET_CHECKED_OUT_PROFILES}_REJECTED`: {
      return {
        ...state,
        fetchingCheckedOutProfiles: false,
        error: action.payload,
      }
    }

    case `${FETCH_SHARED_PAP_STATUS_V1}_PENDING`: {
      return {
        ...state,
        fetchingSharedPapStatus: true,
      }
    }

    case `${FETCH_SHARED_PAP_STATUS_V1}_FULFILLED`: {
      const sharedPapStatusList = get(action, 'payload.data', [])
      const sharedPapStatus = {}
      sharedPapStatusList.forEach(status => {
        const { appContainerId, environmentId } = status
        sharedPapStatus[`${appContainerId}-${environmentId}`] = status
      })
      return {
        ...state,
        sharedPapStatus,
        fetchingSharedPapStatus: false,
      }
    }

    case `${FETCH_SHARED_PAP_STATUS_V1}_REJECTED`: {
      return {
        ...state,
        fetchingSharedPapStatus: false,
        error: action.payload,
      }
    }

    case `${actions.GET_USER_APPS_V1}_PENDING`: {
      return {
        ...state,
        error: null,
        fetchingUserApps: true,
        fetchedUserApps: false,
      }
    }

    case `${actions.GET_USER_APPS_V1}_FULFILLED`: {
      const userAppList = action.payload.data
      const userApps = {}
      userAppList.forEach(userApp => {
        userApps[userApp.appContainerId] = userApp
      })
      return {
        ...state,
        userApps,
        fetchingUserApps: false,
        fetchedUserApps: true,
      }
    }

    case `${actions.GET_USER_APPS_V1}_REJECTED`: {
      return {
        ...state,
        error: action.payload,
        fetchingUserApps: false,
        fetchedUserApps: false,
      }
    }

    case `${actions.GET_USER_ENVIRONMENTS_V1}_PENDING`: {
      return {
        ...state,
        error: null,
        fetchingUserEnvironments: true,
      }
    }

    case `${actions.GET_USER_ENVIRONMENTS_V1}_FULFILLED`: {
      const userEnvironmentList = get(action, 'payload.data', [])
      const userEnvironments = {}
      userEnvironmentList.forEach(userEnvironment => {
        userEnvironments[userEnvironment.environmentId] = userEnvironment
      })
      return {
        ...state,
        userEnvironments,
        fetchingUserEnvironments: false,
      }
    }

    case `${actions.GET_USER_ENVIRONMENTS_V1}_REJECTED`: {
      return {
        ...state,
        error: action.payload,
        fetchingUserEnvironments: false,
      }
    }

    case `${actions.GET_USER_PROFILES_V1}_PENDING`: {
      return {
        ...state,
        error: null,
        fetchingUserProfiles: true,
      }
    }

    case `${actions.GET_USER_PROFILES_V1}_FULFILLED`: {
      const userProfileList = get(action, 'payload.data', [])
      const userProfiles = {}
      userProfileList.forEach(
        userProfile => (userProfiles[userProfile.papId] = userProfile)
      )
      return {
        ...state,
        userProfiles,
        fetchingUserProfiles: false,
      }
    }

    case `${actions.GET_USER_PROFILES_V1}_REJECTED`: {
      return {
        ...state,
        error: action.payload,
        fetchingUserProfiles: false,
      }
    }

    case `${actions.GET_ALL_PROFILES_V1}_PENDING`: {
      return {
        ...state,
        error: null,
        fetchingAllProfiles: true,
      }
    }

    case `${actions.GET_ALL_PROFILES_V1}_FULFILLED`: {
      const allProfileList = get(action, 'payload.data', [])
      const allProfiles = {}
      const distinctProfiles = new Set()
      allProfileList.forEach(allProfile => {
        const { appContainerId, environmentId, papId, accessType } = allProfile
        //deleting unwanted property frequency
        delete allProfile.frequency
        allProfiles[
          `${appContainerId}-${environmentId}-${papId}-${accessType}`
        ] = allProfile
        distinctProfiles.add(`${appContainerId}-${environmentId}-${papId}`)
      })
      return {
        ...state,
        allProfiles,
        fetchingAllProfiles: false,
        allProfilesCount: distinctProfiles.size,
      }
    }

    case `${actions.GET_ALL_PROFILES_V1}_REJECTED`: {
      return {
        ...state,
        error: action.payload,
        fetchingAllProfiles: false,
      }
    }

    case `${actions.GET_FREQUENTLY_USED_PROFILES_V1}_PENDING`: {
      return {
        ...state,
        error: null,
        fetchingFrequentlyUsedProfiles: !action.meta.postCheckout,
      }
    }

    case `${actions.GET_FREQUENTLY_USED_PROFILES_V1}_FULFILLED`: {
      const frequentlyUsedProfileList = get(action, 'payload.data', [])
      const frequentlyUsedProfiles = {}
      orderBy(frequentlyUsedProfileList, [
        'papId',
        'environmentId',
        'appContainerId',
      ]).forEach(frequentlyUsedProfile => {
        const {
          appContainerId,
          environmentId,
          papId,
          accessType,
        } = frequentlyUsedProfile
        frequentlyUsedProfiles[
          `${appContainerId}-${environmentId}-${papId}-${accessType}`
        ] = frequentlyUsedProfile
      })
      return {
        ...state,
        frequentlyUsedProfiles,
        fetchingFrequentlyUsedProfiles: false,
        frequentlyUsedProfilesCount: frequentlyUsedProfileList.length,
      }
    }

    case `${actions.GET_FREQUENTLY_USED_PROFILES_V1}_REJECTED`: {
      return {
        ...state,
        error: action.payload,
        fetchingFrequentlyUsedProfiles: false,
      }
    }

    case `${actions.GET_FAVORITE_PROFILES_V1}_PENDING`: {
      return {
        ...state,
        error: null,
        fetchingFavoriteProfiles: true,
      }
    }

    case `${actions.GET_FAVORITE_PROFILES_V1}_FULFILLED`: {
      const favoriteProfileList = get(action, 'payload.data', [])
      const favoriteProfiles = {}
      favoriteProfileList.forEach(favoriteProfile => {
        const { appContainerId, environmentId, papId, accessType } = favoriteProfile
        favoriteProfiles[
          `${appContainerId}-${environmentId}-${papId}-${accessType}`
        ] = favoriteProfile
      })
      return {
        ...state,
        favoriteProfiles,
        fetchingFavoriteProfiles: false,
        favoriteProfilesCount: favoriteProfileList.length,
      }
    }

    case `${actions.GET_FAVORITE_PROFILES_V1}_REJECTED`: {
      return {
        ...state,
        error: action.payload,
        fetchingFavoriteProfiles: false,
      }
    }

    case `${actions.MARK_FAVORITE_V1}_PENDING`:
    case `${actions.DELETE_FAVORITE_V1}_PENDING`: {
      const { environmentId, papId, appContainerId, accessType } = action.meta
      return {
        ...state,
        isChangingFavoriteProfiles: new Set(state.isChangingFavoriteProfiles).add(
          `${appContainerId}-${environmentId}-${papId}-${accessType}`
        ),
      }
    }

    case `${actions.MARK_FAVORITE_V1}_FULFILLED`: {
      const { appContainerId, environmentId, papId, accessType } = action.meta
      const id = `${appContainerId}-${environmentId}-${papId}-${accessType}`
      const favoriteProfiles = { ...state.favoriteProfiles }
      const isChangingFavoriteProfiles = new Set(state.isChangingFavoriteProfiles)
      isChangingFavoriteProfiles.delete(id)
      favoriteProfiles[id] = action.payload.data
      return {
        ...state,
        favoriteProfilesCount: Object.keys(favoriteProfiles).length,
        isChangingFavoriteProfiles,
        favoriteProfiles,
      }
    }

    case `${actions.DELETE_FAVORITE_V1}_FULFILLED`: {
      const { appContainerId, environmentId, papId, accessType } = action.meta
      const id = `${appContainerId}-${environmentId}-${papId}-${accessType}`
      const favoriteProfiles = { ...state.favoriteProfiles }
      const isChangingFavoriteProfiles = new Set(state.isChangingFavoriteProfiles)
      delete favoriteProfiles[id]
      isChangingFavoriteProfiles.delete(id)
      return {
        ...state,
        favoriteProfilesCount: Object.keys(favoriteProfiles).length,
        isChangingFavoriteProfiles,
        favoriteProfiles,
      }
    }

    case `${actions.MARK_FAVORITE_V1}_REJECTED`:
    case `${actions.DELETE_FAVORITE_V1}_REJECTED`: {
      const { environmentId, papId, appContainerId, accessType } = action.meta
      const isChangingFavoriteProfiles = new Set(state.isChangingFavoriteProfiles)
      isChangingFavoriteProfiles.delete(
        `${appContainerId}-${environmentId}-${papId}-${accessType}`
      )
      return {
        ...state,
        isChangingFavoriteProfiles,
      }
    }

    default:
      return { ...state }
  }
}
