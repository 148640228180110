import React, { Component } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'

import Table from './table'
import TableHeader from './tableHeader'

// the way this was setup was not as a reusable component
// for different use cases. pretty bad

class TableSection extends Component {
  state = {
    outerSort: 'sortBy',
    outerFilter: 'filterBy',
    outerSearch: '',
    filterByRegistered: false,
    filterByInUse: false,
    filterByMapped: false,
    filterByPermissions: false,
    filterCategory: 'filterCategory',
    selectedCategoryData: null,
    searching: false,
  }

  componentDidUpdate(_, prevState) {
    const { initialColumn } = this.props
    const clearInitialColumnStatus = get(initialColumn, 'clearInitialColumnStatus')

    if (
      prevState.outerSearch !== this.state.outerSearch ||
      prevState.outerFilter !== this.state.outerFilter
    ) {
      clearInitialColumnStatus && clearInitialColumnStatus()
    }
  }

  changeOuterTableSort(sortBy) {
    this.setState({ outerSort: sortBy })
  }

  changeOuterTableFilter(filterBy) {
    this.setState({ outerFilter: filterBy })
  }

  changeOuterSearch(term) {
    this.setState({ outerSearch: term })
  }

  changeFilterByRegistered(value) {
    this.setState({ filterByRegistered: value })
  }

  changeFilterByInUse(value) {
    this.setState({ filterByInUse: value })
  }

  changeFilterCategory(value) {
    this.setState({ filterCategory: value })
  }

  changeFilterByMapped(value) {
    this.setState({ filterByMapped: value })
  }

  changeFilterByPermissions(value) {
    this.setState({ filterByPermissions: value })
  }

  render() {
    return (
      <div
        style={{
          marginTop: this.props.marginTop === undefined ? 20 : this.props.marginTop,
        }}
      >
        <TableHeader
          manual={this.props.manual}
          onSearch={this.props.onSearch}
          allowFilterByInUse={this.props.allowFilterByInUse}
          allowFilterByMapped={this.props.allowFilterByMapped}
          allowFilterByPermissions={this.props.allowFilterByPermissions}
          allowFilterByRegistered={this.props.allowFilterByRegistered}
          buttonAction={this.props.buttonAction}
          buttonColor={this.props.buttonColor}
          buttonTitle={this.props.buttonTitle}
          changeFilterByMapped={this.changeFilterByMapped.bind(this)}
          changeFilterByPermissions={this.changeFilterByPermissions.bind(this)}
          changeFilterCategory={this.changeFilterCategory.bind(this)}
          dropdownFilters={this.props.dropdownFilters}
          checkBoxFilters={this.props.checkBoxFilters}
          setDropdownFilter={this.props.setDropdownFilter}
          setCheckBoxFilters={this.props.setCheckBoxFilters}
          selectedDropdownFilter={this.props.selectedDropdownFilter}
          selectedCheckboxFilters={this.props.selectedCheckboxFilters}
          filterByInUse={this.changeFilterByInUse.bind(this)}
          filterByOptions={this.props.filterByOptions}
          filterByRegistered={this.changeFilterByRegistered.bind(this)}
          filterDataSet={this.props.filterDataSet}
          filterTable={this.changeOuterTableFilter.bind(this)}
          searchTable={this.changeOuterSearch.bind(this)}
          showButton={this.props.showButton}
          sortByOptions={this.props.sortByOptions}
          sortTable={this.changeOuterTableSort.bind(this)}
          useCompoundFilter={this.props.useCompoundFilter}
          refreshHandler={this.props.refreshHandler}
          isRefreshing={this.props.isRefreshing}
          loading={this.props.loading}
          setLoading={this.props.setLoading}
          params={this.props.params}
          columnNamesFilter={this.props.columnNamesFilter}
          finalParams={this.props.finalParams}
        />
        {this.props.loading ? (
          <Table
            columns={this.props.columns}
            initialColumn={this.props.initialColumn}
            data={[]}
            loading
            emptyTableMessage={'Loading'}
            pageSize={this.props.pageSize}
            maxHeight={this.props.maxHeight}
          />
        ) : (
          <Table
            columns={this.props.columns}
            data={this.props.data}
            pages={this.props.pages}
            page={this.props.page}
            pageSize={this.props.pageSize}
            manual={this.props.manual}
            multiSort={this.props.multiSort}
            sorted={this.props.sorted}
            loading={this.props.loading}
            onPageChange={this.props.onPageChange}
            onPageSizeChange={this.props.onPageSizeChange}
            onSortedChange={this.props.onSortedChange}
            defaultSorted={this.props.defaultSorted}
            disableHover={this.props.disableHover}
            emptyTableMessage={this.props.emptyTableMessage}
            filterBy={this.state.outerFilter}
            filterByInUse={this.state.filterByInUse}
            filterByKey={this.props.filterByKey}
            filterByMapped={this.state.filterByMapped}
            filterByPermissions={this.state.filterByPermissions}
            filterByRegistered={this.state.filterByRegistered}
            filterCategory={this.state.filterCategory}
            hasBorderBottom={this.props.hasBorderBottom}
            hideHeader={this.props.hideHeader}
            initialColumn={this.props.initialColumn}
            keyValue1={this.props.keyValue1}
            keyValue2={this.props.keyValue2}
            keyValue={this.props.keyValue}
            maxHeight={this.props.maxHeight}
            onScroll={this.props.onScroll}
            resizable={this.props.resizable}
            searchTerm={this.state.outerSearch}
            selectedCategoryData={this.state.selectedCategoryData}
            setDataOverride={this.props.setDataOverride}
            showPagination={this.props.showPagination}
            sortBy={this.state.outerSort}
            sortable={this.props.sortable}
            rowOnClickHandler={this.props.rowOnClickHandler}
          />
        )}
      </div>
    )
  }
}

TableSection.propTypes = {
  actions: PropTypes.func,
  data: PropTypes.array,
  keyValue: PropTypes.string,
  keyValue1: PropTypes.any,
  keyValue2: PropTypes.any,
  columns: PropTypes.array,
  sortBy: PropTypes.string,
  filterByKey: PropTypes.string,
  selectedData: PropTypes.array,
  buttonTitle: PropTypes.string,
  sortByOptions: PropTypes.array,
  buttonAction: PropTypes.func,
  buttonColor: PropTypes.string,
  filterByOptions: PropTypes.array,
  showButton: PropTypes.bool,
  emptyTableMessage: PropTypes.string,
  allowFilterByRegistered: PropTypes.bool,
  allowFilterByPermissions: PropTypes.bool,
  allowFilterByInUse: PropTypes.bool,
  allowFilterByMapped: PropTypes.bool,
  useCompoundFilter: PropTypes.bool,
  compoundFilterCategories: PropTypes.array,
  filterDataSet: PropTypes.object,
  filter: PropTypes.bool,
  columnNamesFilter: PropTypes.array,
}

export default TableSection
