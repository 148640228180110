import get from 'lodash/get'
import * as actions from 'actions'

const initialState = {
  loading: false,
  error: null,
  data: [],
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case `${actions.GET_BROKERS}_PENDING`: {
      return {
        ...state,
        loading: true,
        error: null,
      }
    }

    case `${actions.GET_BROKERS}_FULFILLED`: {
      const data = get(action, 'payload.data.data')
      const lastEvaluatedKey = get(action, 'payload.data.lastEvaluatedKey')

      return {
        ...state,
        loading: false,
        error: null,
        data: action?.meta?.lastEvaluatedKey ? [...state.data, ...data] : data,
        lastEvaluatedKey,
        searchText: action?.meta?.searchText,
      }
    }

    case `${actions.GET_BROKERS}_REJECTED`: {
      return {
        ...state,
        loading: false,
        error: 'Unable fetch the Brokers.',
      }
    }

    default:
      return { ...state }
  }
}
