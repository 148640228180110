import doFetch from 'utils/do_fetch'
import { PAGE_TOKEN } from '../my-approvals/list/constants'
import { getQueryParamsFromUrl } from '../my-approvals/list/service'

export const fetchMyRequestsList = async (payload = {}) => {
  const params = {}
  if (payload.search) {
    params.filter = `status%20eq%20${payload.search}`
  }
  if (payload.next) {
    params.pageToken = getQueryParamsFromUrl(payload.next, PAGE_TOKEN)
  }

  let queryParams = 'requestType=myRequests'

  const listData = await doFetch({
    path: `/v1/approvals/?${queryParams}`,
    method: 'get',
    params,
  })
  return listData
}

export const fetchTags = async () => {
  const response = await doFetch({
    path: `/user-tags`,
    method: 'get',
  })

  return response.data
}

export const withdrawRequest = async requestId => {
  const response = await doFetch({
    path: `/v1/approvals/${requestId}`,
    method: 'delete',
  })

  return response.data
}
