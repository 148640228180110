import React, { useEffect, useState, useMemo } from 'react'
import queryString from 'query-string'
import Button from 'britive-design-system/core/components/button'
import Textfield from 'britive-design-system/core/components/textfield'
import Typography from 'britive-design-system/core/components/typography'

import { AUTHENTICATION, VALIDATE_USER } from 'constants/cognitoAuthentication'
import Form from './Form'

const LoginForm = ({
  errorMessage,
  fields,
  handleChange,
  handleSubmit,
  challenge,
  resetChallenge,
  location,
  history,
  identityProviders,
}) => {
  const source = sessionStorage.getItem('source')

  const [showPasswordField, setShowPasswordField] = useState(false)

  useEffect(() => {
    setShowPasswordField(challenge === AUTHENTICATION)
  }, [challenge])

  useEffect(() => {
    setShowPasswordField(false)
    resetChallenge()
  }, [])

  const isLoginButtonEnabled = useMemo(
    () =>
      !(challenge === VALIDATE_USER && fields.username === '') &&
      !(challenge === AUTHENTICATION && fields.password.length < 1),
    [challenge, fields]
  )

  return (
    <Form
      className="login-form"
      onSubmit={handleSubmit}
      title="Login to your account"
      errorMessage={errorMessage}
    >
      {identityProviders?.length === 1 && (
        <>
          <Button
            type="button"
            size="large"
            onClick={e => {
              e.preventDefault()
              const params = queryString.parse(location.search)
              if (params?.redirectUrl) {
                localStorage.setItem('redirectUrl', params.redirectUrl)
                history.push(
                  `/login/sso?idp=${identityProviders[0]}&redirectUrl=${params?.redirectUrl}`
                )
              } else {
                history.push(`/login/sso?idp=${identityProviders[0]}`)
              }
            }}
          >
            Login with SSO
          </Button>
          <div className="divider">or</div>
        </>
      )}

      <div className="field">
        <Textfield
          id="username"
          name="username"
          placeholder="Username or email"
          autoComplete="on"
          value={fields.username}
          onChange={handleChange}
          readOnly={showPasswordField && identityProviders?.length > 1}
          label="Username or email"
        />
        {identityProviders?.length > 1 && showPasswordField && (
          <a
            className="text-link"
            onClick={() => {
              resetChallenge()
              setShowPasswordField(false)
            }}
          >
            Not you?
          </a>
        )}
      </div>

      {(identityProviders.length <= 1 || showPasswordField) && (
        <div className="field">
          <Textfield
            type="password"
            id="password"
            name="password"
            placeholder="Password"
            value={fields.password}
            onChange={handleChange}
            label="Password"
          />
          <a
            className="text-link"
            onClick={() =>
              history.push('/login/reset-password', {
                from: location?.state?.from,
              })
            }
          >
            Forgot password?
          </a>
        </div>
      )}

      <Button size="large" disabled={!isLoginButtonEnabled}>
        Login
      </Button>
      {source === 'notification' && (
        <div className="mobile-login-info">
          <Typography variant="body">
            Only approvals can be retrieved as the application is being accessed on a
            mobile device.
          </Typography>
        </div>
      )}
    </Form>
  )
}

export default LoginForm
