import styled from 'styled-components'
import ActionIcon from 'britive-ui-components/core/components/ActionIcon'

const TableActionIcon = styled(ActionIcon)`
  &.action-icon-container {
    padding: 8px 15px 10px;

    > .action-icon-label {
      bottom: -6px;
    }
  }
`

export default TableActionIcon
