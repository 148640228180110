import { fetchIfLoggedIn } from '../utils.js'
import * as actions from 'actions'

export const FETCH_ACCESS_REQUEST_SETTINGS = 'FETCH_ACCESS_REQUEST_SETTINGS'
export const UPDATE_ACCESS_REQUEST_SETTINGS = 'UPDATE_ACCESS_REQUEST_SETTINGS'
export const RESET_ASSOCIATION_APPROVERS_SETTINGS =
  'RESET_ASSOCIATION_APPROVERS_SETTINGS'
export const UPDATE_ASSOCIATION_APPROVERS_SETTINGS =
  'UPDATE_ASSOCIATION_APPROVERS_SETTINGS'
export const UPDATE_APPROVERS_GROUPS = 'UPDATE_APPROVERS_GROUPS'
export const FETCH_APPROVERS_GROUPS = 'FETCH_APPROVERS_GROUPS'
export const FETCH_ALLOWED_SCOPES_FOR_ACCESS_REQUEST =
  'FETCH_ALLOWED_SCOPES_FOR_ACCESS_REQUEST'
export const DELETE_APPROVERS_GROUPS = 'DELETE_APPROVERS_GROUPS'

export const fetchAccessRequestSettings = appContainerId => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: FETCH_ACCESS_REQUEST_SETTINGS,
      method: 'get',
      path: `/apps/${appContainerId}/access-request-settings`,
    })
  )
}

export const updateAccessRequestSettings = (
  appContainerId,
  method,
  field
) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: UPDATE_ACCESS_REQUEST_SETTINGS,
      meta: { field },
      method,
      path: `/apps/${appContainerId}/access-request-settings`,
      postBody: field,
    })
  )
}

export const updateAssociationApproversSettings = (
  associationApproverSettings,
  appContainerId,
  associationApproverId
) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: UPDATE_ASSOCIATION_APPROVERS_SETTINGS,
      meta: { associationApproverSettings },
      method: associationApproverId ? 'patch' : 'post',
      path: `/apps/${appContainerId}/association-approvers/${associationApproverId ||
        ''}`,
      postBody: associationApproverSettings,
    })
  )
}

export const resetAssociationApproversSettings = () => dispatch => {
  return dispatch({
    type: RESET_ASSOCIATION_APPROVERS_SETTINGS,
  })
}

export const accessRequestSubmitProfile = (appId, papId, payload) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.ACCESS_REQUEST_SUBMIT_PROFILE,
      method: 'post',
      path: `/profile-requests/apps/${appId}/paps/${papId}`,
      postBody: payload,
    })
  )
}

export const accessRequestDeleteDraftProfile = papId => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.ACCESS_REQUEST_DELETE_PROFILE,
      method: 'delete',
      path: `/profile-requests/paps/${papId}`,
    })
  )
}

export const updateApproversGroup = (
  approverGroups,
  appContainerId,
  approverGroupId
) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: UPDATE_APPROVERS_GROUPS,
      meta: { approverGroups },
      method: approverGroupId ? 'patch' : 'post',
      path: `/apps/${appContainerId}/approvers-groups/${approverGroupId || ''}`,
      postBody: approverGroups,
    })
  )
}

export const fetchApproversGroups = appContainerId => async dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: FETCH_APPROVERS_GROUPS,
      method: 'get',
      path: `/apps/${appContainerId}/approvers-groups`,
    })
  )
}

export const fetchAllowedScopesForAccessRequest = appId => async dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: FETCH_ALLOWED_SCOPES_FOR_ACCESS_REQUEST,
      method: 'get',
      path: `/profile-requests/apps/${appId}/filtered-environments`,
    })
  )
}

export const deleteApproversGroups = (
  appContainerId,
  approverId
) => async dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: DELETE_APPROVERS_GROUPS,
      method: 'delete',
      path: `/apps/${appContainerId}/approvers-groups/${approverId}`,
    })
  )
}
