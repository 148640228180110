import {
  FETCH_ACCESS_REQUEST_SETTINGS,
  RESET_ASSOCIATION_APPROVERS_SETTINGS,
  UPDATE_ACCESS_REQUEST_SETTINGS,
  UPDATE_ASSOCIATION_APPROVERS_SETTINGS,
  UPDATE_APPROVERS_GROUPS,
  FETCH_ALLOWED_SCOPES_FOR_ACCESS_REQUEST,
  FETCH_APPROVERS_GROUPS,
  DELETE_APPROVERS_GROUPS,
} from 'action_creators/access-request'

const initialState = {
  accessRequestSettings: {
    loading: false,
    data: {},
    error: null,
  },
  associationApprover: {
    loading: false,
    error: null,
  },
  approverGroups: {
    loading: false,
    error: null,
    data: null,
  },
  approversGroups: {
    loading: false,
    error: null,
    data: null,
  },
  users: {
    loading: false,
    error: null,
    data: [],
  },
  tags: {
    loading: false,
    error: null,
    data: [],
  },
  allowedScopesForAccessRequest: {
    loading: false,
    error: null,
    data: {},
  },
  deletingApproversGroups: false,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case `${FETCH_ACCESS_REQUEST_SETTINGS}_PENDING`: {
      return {
        ...state,
        accessRequestSettings: {
          ...state.accessRequestSettings,
          data: {},
          error: null,
          loading: true,
        },
      }
    }

    case `${FETCH_ACCESS_REQUEST_SETTINGS}_FULFILLED`: {
      return {
        ...state,
        accessRequestSettings: {
          ...state.accessRequestSettings,
          loading: false,
          data: action.payload.data,
        },
      }
    }

    case `${FETCH_ACCESS_REQUEST_SETTINGS}_REJECTED`: {
      const response = action.payload?.response
      return {
        ...state,
        accessRequestSettings: {
          ...state.accessRequestSettings,
          loading: false,
          data: {},
          error:
            response?.status === 400
              ? null
              : response?.data?.message ||
                'Access Builder settings could not be fetched.',
        },
      }
    }

    case `${UPDATE_ACCESS_REQUEST_SETTINGS}_PENDING`: {
      return {
        ...state,
        accessRequestSettings: {
          ...state.accessRequestSettings,
          loading: true,
          error: null,
        },
      }
    }

    case `${UPDATE_ACCESS_REQUEST_SETTINGS}_FULFILLED`: {
      const { field } = action.meta
      return {
        ...state,
        accessRequestSettings: {
          ...state.accessRequestSettings,
          data: {
            ...state.accessRequestSettings.data,
            ...field,
          },
          loading: false,
        },
      }
    }

    case `${UPDATE_ACCESS_REQUEST_SETTINGS}_REJECTED`: {
      return {
        ...state,
        accessRequestSettings: {
          ...state.accessRequestSettings,
          loading: false,
          error:
            action.payload?.response?.data?.message ||
            'Access Builder settings could not be updated.',
        },
      }
    }

    case `${UPDATE_ASSOCIATION_APPROVERS_SETTINGS}_PENDING`: {
      return {
        ...state,
        associationApprover: {
          loading: true,
          error: null,
        },
      }
    }

    case `${UPDATE_ASSOCIATION_APPROVERS_SETTINGS}_FULFILLED`: {
      return {
        ...state,
        associationApprover: {
          loading: false,
          error: null,
        },
      }
    }

    case `${UPDATE_ASSOCIATION_APPROVERS_SETTINGS}_REJECTED`: {
      return {
        ...state,
        associationApprover: {
          loading: false,
          error:
            action.payload?.response?.data?.message ||
            'Association Approver could not be updated.',
        },
      }
    }

    case `${UPDATE_APPROVERS_GROUPS}_PENDING`: {
      return {
        ...state,
        approverGroups: {
          loading: true,
          error: null,
          data: null,
        },
      }
    }

    case `${UPDATE_APPROVERS_GROUPS}_FULFILLED`: {
      return {
        ...state,
        approverGroups: {
          loading: false,
          error: null,
          data: action.payload?.data,
        },
      }
    }

    case `${UPDATE_APPROVERS_GROUPS}_REJECTED`: {
      return {
        ...state,
        approverGroups: {
          loading: false,
          data: null,
          error:
            action.payload?.response?.data?.message ||
            'Approver Groups could not be saved.',
        },
      }
    }

    case `${FETCH_APPROVERS_GROUPS}_PENDING`: {
      return {
        ...state,
        approversGroups: {
          loading: true,
          error: null,
          data: null,
        },
      }
    }

    case `${FETCH_APPROVERS_GROUPS}_FULFILLED`: {
      return {
        ...state,
        approversGroups: {
          loading: false,
          error: null,
          data: action.payload?.data,
        },
      }
    }

    case `${FETCH_APPROVERS_GROUPS}_REJECTED`: {
      return {
        ...state,
        approversGroups: {
          loading: false,
          data: null,
          error:
            action.payload?.response?.data?.message ||
            'Approver Groups could not be fetched.',
        },
      }
    }

    case `${DELETE_APPROVERS_GROUPS}_PENDING`: {
      return {
        ...state,
        deletingApproversGroups: true,
      }
    }

    case `${DELETE_APPROVERS_GROUPS}_FULFILLED`: {
      return {
        ...state,
        deletingApproversGroups: false,
      }
    }

    case `${DELETE_APPROVERS_GROUPS}_REJECTED`: {
      return {
        ...state,
        deletingApproversGroups: false,
      }
    }

    case RESET_ASSOCIATION_APPROVERS_SETTINGS: {
      return {
        ...state,
        associationApprover: {
          loading: false,
          error: null,
        },
        approverGroups: {
          loading: false,
          error: null,
          data: null,
        },
      }
    }

    case `${FETCH_ALLOWED_SCOPES_FOR_ACCESS_REQUEST}_PENDING`: {
      return {
        ...state,
        allowedScopesForAccessRequest: {
          loading: true,
          error: null,
          data: {},
        },
      }
    }

    case `${FETCH_ALLOWED_SCOPES_FOR_ACCESS_REQUEST}_FULFILLED`: {
      return {
        ...state,
        allowedScopesForAccessRequest: {
          loading: false,
          error: null,
          data: action.payload.data,
        },
      }
    }

    case `${FETCH_ALLOWED_SCOPES_FOR_ACCESS_REQUEST}_REJECTED`: {
      return {
        ...state,
        allowedScopesForAccessRequest: {
          loading: false,
          error: action.payload.data,
          data: {},
        },
      }
    }

    default:
      return { ...state }
  }
}
