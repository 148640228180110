import * as actions from 'actions'
import isEmpty from 'lodash/isEmpty'
import isJSON from 'utils/isJSON'

export const permissionsConstraintsReducerKey = 'permissionsConstraints'

const preparevalidationMessage = (data, constraint) => {
  let validationMessage = {}
  let error = null
  const validationResultObject = JSON.parse(data?.message || '')
  if (!isEmpty(validationResultObject)) {
    const validation = validationResultObject?.lintResults[0]
    if (validation.severity === 'ERROR') {
      const splittedMessage = validation.debugMessage.split(':')
      validationMessage = {
        line: parseInt(splittedMessage[2]),
        message: splittedMessage.splice(4).join(':'),
        type: 'error',
      }
      error = new Error('There is a syntax error in the condition editor')
    } else if (validation.severity === 'WARNING') {
      let line = 0
      let locationOffset = validation.locationOffset
      const splittedExpression = constraint.expression.split('\n')

      // calculating line number from offset
      while (locationOffset > 0) {
        locationOffset -= splittedExpression[line].length
        line++
      }

      validationMessage = {
        line,
        message: validation.debugMessage,
        type: 'warning',
      }
    }
  }
  return { validationMessage, error }
}
export function permissionsConstraintsReducer(
  state = {
    permissionConstraintTypes: {
      status: 'INITIAL',
      error: null,
      result: [],
    },

    permissionConstraints: {},
    permissionConstraintsAdd: {
      status: 'INITIAL',
      error: null,
      validationMessage: {},
    },
  },
  action
) {
  switch (action.type) {
    /**
     *  Get Constraint Types: {
     */

    case `${actions.GET_PERMISSION_CONSTRAINT_TYPES}_PENDING`: {
      return {
        ...state,
        permissionConstraintTypes: {
          status: 'LOADING',
          error: null,
          result: [],
        },
      }
    }

    case `${actions.GET_PERMISSION_CONSTRAINT_TYPES}_FULFILLED`: {
      const permissionConstraints = {}
      const result = [...action.payload?.data]
      result.forEach(r => {
        permissionConstraints[r] = {
          status: 'SUCCESS',
          error: null,
          result: [],
        }
      })
      return {
        ...state,
        permissionConstraintTypes: {
          status: 'SUCCESS',
          error: null,
          result,
        },
        permissionConstraints,
      }
    }

    case `${actions.GET_PERMISSION_CONSTRAINT_TYPES}_REJECTED`: {
      return {
        ...state,
        permissionConstraintTypes: {
          status: 'ERROR',
          error: action.payload?.data || null,
          result: [],
        },
      }
    }

    /**
     *  Get Permission Constraints: {
     */

    case `${actions.GET_PERMISSION_CONSTRAINTS}_PENDING`: {
      const { constraintType } = action.meta
      return {
        ...state,
        permissionConstraints: {
          ...state.permissionConstraints,
          [constraintType]: {
            status: 'LOADING',
            error: null,
            result: [],
          },
        },
      }
    }

    case `${actions.GET_PERMISSION_CONSTRAINTS}_FULFILLED`: {
      const { constraintType } = action.meta
      return {
        ...state,
        permissionConstraints: {
          ...state.permissionConstraints,
          [constraintType]: {
            status: 'SUCCESS',
            error: null,
            result: action.payload?.data?.result,
          },
        },
      }
    }

    case `${actions.GET_PERMISSION_CONSTRAINTS}_REJECTED`: {
      const { constraintType } = action.meta
      return {
        ...state,
        permissionConstraints: {
          ...state.permissionConstraints,
          [constraintType]: {
            status: 'ERROR',
            error: action.payload?.response?.data || null,
          },
        },
      }
    }

    /**
     *  Delete Constraints
     */

    case `${actions.DELETE_PERMISSION_CONSTRAINTS}_FULFILLED`: {
      const { constraintType, constraintIndex } = action.meta
      const permissionConstraints = { ...state.permissionConstraints }
      permissionConstraints[constraintType].result.splice(constraintIndex, 1)
      return {
        ...state,
        permissionConstraints,
      }
    }

    /**
     *  Add permission Constraints
     */

    case `${actions.ADD_PERMISSION_CONSTRAINTS}_PENDING`: {
      return {
        ...state,
        permissionConstraintsAdd: {
          status: 'LOADING',
          error: null,
          validationMessage: {},
        },
      }
    }

    case `${actions.ADD_PERMISSION_CONSTRAINTS}_FULFILLED`: {
      return {
        ...state,
        permissionConstraintsAdd: {
          status: 'SUCCESS',
          error: null,
          validationMessage: {},
        },
      }
    }

    case `${actions.ADD_PERMISSION_CONSTRAINTS}_REJECTED`: {
      const data = action.payload?.response?.data
      let error = data
      const { constraint } = action.meta
      let validationMessage = []
      if (isJSON(data?.message)) {
        const result = preparevalidationMessage(data, constraint)
        if (result) {
          if (result.error) {
            error = result.error
          }
          validationMessage = result.validationMessage
        }
      }
      return {
        ...state,
        permissionConstraintsAdd: {
          status: 'ERROR',
          error,
          validationMessage,
        },
      }
    }

    /**
     *  Validate permission Constraints
     */

    case `${actions.VALIDATE_PERMISSION_CONSTRAINTS}_PENDING`: {
      return {
        ...state,
        permissionConstraintsAdd: {
          status: 'LOADING',
          error: null,
          validationMessage: {},
        },
      }
    }

    case `${actions.VALIDATE_PERMISSION_CONSTRAINTS}_FULFILLED`: {
      const data = action.payload?.data
      const { constraint } = action.meta
      const { error, validationMessage } = preparevalidationMessage(data, constraint)
      return {
        ...state,
        permissionConstraintsAdd: {
          status: 'SUCCESS',
          error,
          validationMessage,
        },
      }
    }

    case `${actions.VALIDATE_PERMISSION_CONSTRAINTS}_REJECTED`: {
      return {
        ...state,
        permissionConstraintsAdd: {
          status: 'ERROR',
          error: action.payload?.response?.data || null,
          validationMessage: {},
        },
      }
    }

    case `${actions.ADD_PERMISSION_CONSTRAINTS_INITIAL}`: {
      return {
        ...state,
        permissionConstraintsAdd: {
          status: 'INITIAL',
          error: null,
        },
      }
    }

    default:
      return { ...state }
  }
}
