import React, { Fragment, memo } from 'react'
import styled from 'styled-components'

import Button from 'britive-ui-components/core/components/Button'

import ScheduledScans from './ScheduledScans'
import ScanDrawer from './ScanDrawer'
import PageLoader from 'components/PageLoader'
import { CheckBoxBoolean } from 'components/form_fields'

const Wrapper = styled.div`
  background-color: #fff;
  box-shadow: 0 2px 8px 3px rgba(206, 206, 206, 0.21);
  padding: 10px 30px;
  margin-bottom: 15px;
`

function ScheduledScanning(props) {
  const {
    updateScheduledScan,
    app,
    createNewMode,
    deletingStatus,
    deleteScan,
    editable,
    environmentData,
    environments,
    environmentGroups,
    fields,
    isLoading,
    isScanDrawerOpen,
    isScanEnabled,
    onCancel,
    onChange,
    onSubmit,
    saving,
    scannerData,
    scheduledScans,
    selectedScanData,
    toggleEditMode,
    toggleScanDrawer,
    rootGroupId,
    thisAppManage,
  } = props

  function renderButtons() {
    return (
      <div className="action-container">
        {editable ? (
          <Fragment>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Button
                disabled={isScanDrawerOpen}
                color="secondary"
                className="mr-2"
                onClick={onCancel}
              >
                Cancel
              </Button>

              <Button
                attachleftbutton={true}
                color="primary"
                onClick={onSubmit}
                spinner={saving}
                disabled={isScanDrawerOpen}
              >
                Save
              </Button>
            </div>
          </Fragment>
        ) : (
          <Button color="primary" onClick={toggleEditMode}>
            Edit
          </Button>
        )}
      </div>
    )
  }

  if (createNewMode) {
    return null
  }

  if (isLoading) {
    return (
      <Wrapper>
        <h5>Scheduled Scans</h5>

        <PageLoader />
      </Wrapper>
    )
  }

  if (!isLoading && !scannerData) {
    return (
      <Wrapper>
        <h5>Scheduled Scans</h5>

        <span>Scanning Service Not Available</span>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <h5>Scheduled Scans</h5>

      <CheckBoxBoolean
        name="isScheduledScansEnabled"
        label="Scheduled Scans Enabled"
        onChange={onChange}
        className="pl-4 mb-2"
        checked={isScanEnabled}
        disabled={!editable || isScanDrawerOpen}
        style={{ cursor: editable && !isScanDrawerOpen ? 'pointer' : undefined }}
      />

      <ScheduledScans
        app={app}
        editable={editable}
        deletingStatus={deletingStatus}
        data={scheduledScans}
        environmentData={environmentData}
        environments={environments}
        environmentGroups={environmentGroups}
        toggleScanDrawer={toggleScanDrawer}
        deleteScan={deleteScan}
        selectedScanData={selectedScanData}
        rootGroupId={rootGroupId}
      />

      {thisAppManage && renderButtons()}

      <ScanDrawer
        app={app}
        fields={fields}
        updateScheduledScan={updateScheduledScan}
        scannerData={scannerData}
        isOpen={isScanDrawerOpen}
        toggleDrawer={toggleScanDrawer}
        selectedScanData={selectedScanData}
        editable={editable}
      />
    </Wrapper>
  )
}

export default memo(ScheduledScanning)
