import React, { memo, useEffect, useState, Fragment } from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import ScanModal from './ScanModal'

import useCheckboxState from 'hooks/useCheckboxState'

import {
  fetchEnvironmentsMinimal,
  scanEnvironment,
} from 'action_creators/environment'
import { updateMessageModal } from 'action_creators/message_modal'
import { fetchEnvironmentScansReport } from 'action_creators/environment'

import {
  getSelectedApplicationEnvironmentScanResults,
  getFetchingEnvironmentScansReport,
  getSelectedApplicationRootGroupId,
  getEnvironments,
  getIsScanningAppEnvironments,
  getSelectedApplicationEnvironments,
} from 'selectors/environment'
import { getSelectedApplicationRoot } from 'selectors/application_root'
import { getEnvironmentsToScan } from 'routes/admin/state/selectors'

const DATA_IDENTIFIER = 'id'

function ScanModalContainer(props) {
  const [formattedEnvironments, setFormattedEnvironments] = useState([])
  const [isScanning, setIsScanning] = useState(false)
  const [startScanning, setStartScanning] = useState(false)
  const [scanSubmittedEnv, setScanSubmittedEnv] = useState([])

  const {
    toggleAllCheckboxes,
    toggleCheckbox,
    checkItemsStatus,
    checkedItems,
    clearCheckedItems,
    setDataOverride,
  } = useCheckboxState(DATA_IDENTIFIER, formattedEnvironments)

  useEffect(() => {
    async function getEnvironmentData() {
      const { dispatch, applicationRoot } = props
      const { appContainerId: appId } = applicationRoot
      const requiresHierarchicalModel = get(
        applicationRoot,
        'catalogApplication.requiresHierarchicalModel'
      )
      !requiresHierarchicalModel && dispatch(fetchEnvironmentsMinimal(appId))
      await dispatch(fetchEnvironmentScansReport({ appId }))
    }

    getEnvironmentData()
  }, [])

  useEffect(() => {
    formatEnvironments()
  }, [props.environmentScanResults, props.environmentsMinimal])

  useEffect(() => {
    setStartScanning(props.scanningEnv)
  }, [props.scanningEnv])

  useEffect(() => {
    let isCancelled = false
    async function scanEnvironments() {
      const {
        applicationRoot,
        refreshScanData,
        dispatch,
        rootGroupId,
        isScanModalOpen,
        setScanningEnv,
      } = props
      const { appContainerId } = applicationRoot
      let responses = []
      setIsScanning(true)
      let scanSubmitted = []

      const requiresHierarchicalModel = get(
        applicationRoot,
        'catalogApplication.requiresHierarchicalModel'
      )

      const selectedEnvironmentIds = requiresHierarchicalModel
        ? [rootGroupId]
        : Object.keys(checkedItems).filter(id => checkedItems[id])
      const BATCH_COUNT = 5

      // Batching the scans here, sending out BATCH_COUNT of scans at a time and
      // waiting for the response of all before sending out the next batch
      try {
        for (let i = 0; i < selectedEnvironmentIds.length; i = i + BATCH_COUNT) {
          if (isCancelled) {
            break
          }
          const batchSelectedEnvironmentIds = selectedEnvironmentIds.slice(
            i,
            i + BATCH_COUNT
          )
          const promiseArray = []
          batchSelectedEnvironmentIds.map(async id => {
            promiseArray.push(dispatch(scanEnvironment(appContainerId, id)))
            scanSubmitted.push(id)
          })
          const batchResponses = await Promise.allSettled(promiseArray)
          responses = [...responses, ...batchResponses]
          setScanSubmittedEnv(scanSubmitted)
        }
      } catch (error) {
        const errorMessage = get(error, 'response.data.message', 'Unknown error.')

        dispatch(
          updateMessageModal({
            body: errorMessage,
            header: 'Scan Error',
          })
        )
      }

      const hasRejections = responses.some(
        response => response.status === 'rejected'
      )
      if (hasRejections) {
        dispatch(
          updateMessageModal({
            body: (
              <ul style={{ paddingLeft: requiresHierarchicalModel ? 0 : 20 }}>
                {responses.map((response, idx) => {
                  if (response.status !== 'rejected') {
                    return null
                  }

                  if (requiresHierarchicalModel) {
                    return (
                      <span key={idx}>
                        {get(
                          response,
                          'reason.response.data.message',
                          'Unknown error'
                        )}
                      </span>
                    )
                  } else {
                    const name = formattedEnvironments.find(env => {
                      return (
                        env.id ===
                        (requiresHierarchicalModel
                          ? rootGroupId
                          : Object.keys(checkedItems)[idx])
                      )
                    }).name

                    return (
                      <li key={`rejections${idx}`}>
                        <b>{name}</b>:{' '}
                        {get(
                          response,
                          'reason.response.data.message',
                          'Unknown error'
                        )}
                      </li>
                    )
                  }
                })}
              </ul>
            ),
            header: 'Scan Error',
          })
        )
      }

      await refreshScanData()
      setIsScanning(false)
      isScanModalOpen ? toggleModal() : setScanningEnv(false)
    }

    startScanning && scanEnvironments()

    return () => {
      isCancelled = true
    }
  }, [startScanning])

  // TODO: This needs to be optimized
  function formatEnvironments() {
    const {
      applicationRoot,
      environments,
      environmentsMinimal,
      environmentScanResults: scanResults,
      rootGroupId,
      environmentsData,
    } = props
    const { appContainerId } = applicationRoot

    const requiresHierarchicalModel = get(
      applicationRoot,
      'catalogApplication.requiresHierarchicalModel'
    )
    let envArray

    if (requiresHierarchicalModel) {
      const root = {
        ...applicationRoot,
        id: rootGroupId,
      }

      envArray = [root]
    } else {
      envArray =
        !isEmpty(environments) && !isEmpty(environments[appContainerId])
          ? Object.values(environments[appContainerId])
          : []
    }

    const formattedEnvironments = envArray
      .filter(env => env.status === 'active')
      .map(env => {
        const name = get(environmentsData[env.id], 'catalogAppDisplayName', env.name)
        const scanData = get(scanResults, [env.id], {})

        return {
          ...env,
          name,
          ...(!requiresHierarchicalModel &&
            !isEmpty(environmentsMinimal) &&
            !isEmpty(environmentsMinimal[env.id]) && {
              description: environmentsMinimal[env.id].description,
            }),
          scanTimestamp: scanData.timestamp,
          scanSubmitted: scanSubmittedEnv.indexOf(env.id) !== -1,
        }
      })

    setFormattedEnvironments(formattedEnvironments)
  }

  function scanClickHandler() {
    setStartScanning(true)
  }

  function selectedCount() {
    return Object.values(checkedItems).filter(item => item).length
  }

  const { toggleModal } = props

  return (
    <Fragment>
      {props.isScanModalOpen ? (
        <ScanModal
          scanClickHandler={scanClickHandler}
          selectedCount={selectedCount()}
          areAllEnvironmentsSelected={checkItemsStatus('every')}
          areAnyEnvironmentsSelected={checkItemsStatus('some')}
          checkedItems={checkedItems}
          clearCheckedItems={clearCheckedItems}
          environments={formattedEnvironments}
          isScanning={isScanning}
          setDataOverride={setDataOverride}
          toggleAllCheckboxes={toggleAllCheckboxes}
          toggleCheckbox={toggleCheckbox}
          toggleModal={toggleModal}
          fetching={
            props.fetchingEnvironmentScanResults || props.fetchingEnvironments
          }
        />
      ) : null}
    </Fragment>
  )
}

const mapStateToProps = state => {
  const applicationRoot = getSelectedApplicationRoot(state)

  return {
    environments: getEnvironments(state),
    environmentsMinimal: state.environment.environmentsMinimal,
    fetchingEnvironments: state.environment.fetchingEnvironments,
    environmentScanResults: getSelectedApplicationEnvironmentScanResults(state),
    fetchingEnvironmentScanResults: getFetchingEnvironmentScansReport(state),
    environmentsData: getSelectedApplicationEnvironments(state),
    environmentsToScan: getEnvironmentsToScan(state),
    rootGroupId: getSelectedApplicationRootGroupId(state),
    isScanningAppEnvironments: getIsScanningAppEnvironments({ state }),
    applicationRoot,
  }
}

export default connect(mapStateToProps)(memo(ScanModalContainer))
