export const approvalPageTitle = 'My Approvals'
export const approvalMessage = 'SECRETS_MODULE.APPROVAL_MESSAGE'
export const profileApprovalMessage = 'SECRETS_MODULE.PROFILE_APPROVAL_MESSAGE'
export const approvalLoadingMessage = 'My Approvals Loading'

export const mediumHeightDialogPopUp = 365

export const classes = {
  notificationContainer: 'notification-container',
  spinnerContainer: 'spinner-container',
  listContainer: 'my-approvals-list-container',
  tableContainer: 'my-approvals-table-container',
  iconNameWrapper: 'application-icon-name-wrapper',
  applicationIcon: 'application-icon',
  myApprovalsActionItems: 'my-approvals-action-items',
  myApprovalsActionChecked: 'my-approvals-action-checked',
  myApprovalsActionCrossed: 'my-approvals-action-crossed',
  disabledIcon: 'disabled-icon',
  approvalsManageIcon: 'my-approvals-manage-icon',
  approveIcon: 'my-approvals-approve-icon',
  rejectIcon: 'my-approvals-reject-icon',
}

export const MyApprovalsDataTypes = {
  OPEN_NOTIFICATION: 'OPEN_NOTIFICATION',
  MY_APPROVALS_LIST_REQUEST: 'MY_APPROVALS_LIST_REQUEST',
  MY_APPROVALS_LIST_LOADING: 'MY_APPROVALS_LIST_LOADING',
  MY_APPROVALS_LIST_SUCCESS: 'MY_APPROVALS_LIST_SUCCESS',
  MY_APPROVALS_LIST_FAILURE: 'MY_APPROVALS_LIST_FAILURE',
  MY_APPROVALS_LIST_SEARCH_TERM_UPDATE: 'MY_APPROVALS_LIST_SEARCH_TERM_UPDATE',
  MY_APPROVALS_LIST_LOAD_MORE_REQUEST: 'MY_APPROVALS_LIST_LOAD_MORE_REQUEST',
  MY_APPROVALS_LIST_LOAD_MORE_LOADING: 'MY_APPROVALS_LIST_LOAD_MORE_LOADING',
  MY_APPROVALS_LIST_LOAD_MORE_SUCCESS: 'MY_APPROVALS_LIST_LOAD_MORE_SUCCESS',
  MY_APPROVALS_LIST_LOAD_MORE_FAILURE: 'MY_APPROVALS_LIST_LOAD_MORE_FAILURE',
}

export const UsersType = {
  APPROVALS_USERS_REQUEST: 'APPROVALS_USERS_REQUEST',
  APPROVALS_USERS_LOADING: 'APPROVALS_USERS_LOADING',
  APPROVALS_USERS_SUCCESS: 'APPROVALS_USERS_SUCCESS',
  APPROVALS_USERS_FAILURE: 'APPROVALS_USERS_FAILURE',
}

export const ApproveRequestDataTypes = {
  APPROVE_REQUEST_REQUEST: 'APPROVE_REQUEST_REQUEST',
  APPROVE_REQUEST_DETAILS_REQUEST: 'APPROVE_REQUEST_DETAILS_REQUEST',
}

export const RejectRequestDataTypes = {
  REJECT_REQUEST_REQUEST: 'REJECT_REQUEST_REQUEST',
  REJECT_REQUEST_DETAILS_REQUEST: 'REJECT_REQUEST_DETAILS_REQUEST',
}

export const tableColumns = {
  requestedOn: 'Requested Date',
  requestedBy: 'Requester',
  actionType: 'Action',
  resource: 'Resource',
  approvalStatus: 'Status',
  approverGroup: 'Approver Group',
  action: 'Action',
  justification: 'Justification',
  type: 'Type',
  requestId: 'Request ID',
}

export const translatedStrings = {
  noneValue: 'None',
  cancelLabel: 'Cancel',
  yesApprove: 'Yes, Approve',
  yesReject: 'Yes, Reject',
  forLabel: 'for',
  myApprovalsSearchText: 'Search is not supported on this screen',
  loadingMessage: 'Loading...',
  approveRequestTitle: 'Approve Request?',
  rejectRequestTitle: 'Reject Request?',
  approveMessage: 'permission will be granted to',
  rejectMessage: 'permission will be denied to',
  manageRequestTooltip: 'Manage Request',
  viewRequestTooltip: 'View Request',
  approveRequestTooltip: 'Approve Request',
  rejectRequestTooltip: 'Reject Request',
  comments: 'Comments',
  optional: 'Optional',
  approvalPendingText: 'Pending',
  approvalRequiredText: 'Approval Required',
  approvalDeniedText: 'Access Denied',
  cancelledText: 'Cancelled',
  availableText: 'Available',
  alreadyApproved: 'Request is already approved',
  alreadyRejected: 'Request is already rejected',
  alreadyExpired: 'Request Expired',
  viewSecret: 'View Secret',
  accessProfile: 'Access Profile',
  rejectedStatus: 'Rejected',
  approvedStatus: 'Approved',
  timeOutStatus: 'Expired',
  withdrawnText: 'Withdrawn',
  noneText: 'None',
  profileCheckout: 'Profile Checkout',
  secret: 'Secret',
  accessBuilder: 'Access Builder',
  createProfile: 'Create Profile',
  profileAccess: 'Profile Access',
  requestApproved: 'The Request is approved',
  requestRejected: 'The Request is rejected',
  requestNotApproved: 'Could not approve the request.',
  requestNotRejected: 'Could not reject the request.',
  noActionRequired: 'No Action Required',
  accessResource: 'Access Resource',
}

export const active = 'active'

export const secretModuleConstants = {
  pendingText: 'pending',
  approvalRequiredText: 'approvalrequired',
  denyText: 'deny',
  allowText: 'allow',
  accessDeniedText: 'access denied',
  rejectedText: 'rejected',
  cancelledText: 'cancelled',
  timeOutText: 'timeout',
  secretReadActionName: 'sm.secret.read',
  profileAccessActionName: 'papservice.profile.access',
  approvedText: 'approved',
  requestExpiredText: 'request expired',
  withdrawnText: 'withdrawn',
  papService: 'papservice',
  secretManager: 'secretmanager',
  accessBuilder: 'accessrequest',
  noActionRequired: 'no_action_required',
  newRequest: 'REQUEST_NEW',
  existingRequest: 'REQUEST_EXISTING_FOR_MYSELF',
  resourceprofile: 'resourceprofile',
  resourceProfileCheckout: 'resource.profile.checkout',
}

export const myApprovalsTableTestId = 'my-approvals-table'
export const searchDebounceTime = 300
export const defaultBtnSize = '24'
export const STATUS_PENDING = 'Pending'
export const backToTopId = 'approvals-list-back-to-top'
export const userApprovalsListString = 'userApprovalsList'
export const adminApprovalsListString = 'adminApprovalsList'
export const PAGE_TOKEN = 'pageToken'
