export const headerDetailsType = {
  APPROVALS: 'Approvals',
  REQUESTS: 'Requests',
}

export const status = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  TIMEOUT: 'TIMEOUT',
}

export const buttonLabel = {
  APPROVE: 'Approve',
  REJECT: 'Reject',
  CLOSE: 'Close',
  WITHDRAW: 'Withdraw',
}

export const classNamePrefix = 'my-approvals-my-requests-header'
