import React, { memo, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'

import EnvironmentGroups from './EnvironmentGroups'

import {
  getSelectedApplicationRoot,
  getSelectedApplicationTemplate,
} from 'selectors/application_root'

import {
  getFetchingEnvironments,
  getSelectedEnvironmentId,
  getSelectedApplicationEnvironments,
  getApplicationEnvironments,
  getSelectedApplicationEnvironmentGroups,
  getSelectedApplicationRootGroupId,
  getSelectedApplicationEnvironmentScanResults,
} from 'selectors/environment'

export function EnvironmentGroupsContainer({
  environmentData,
  applicationRoot,
  applicationTemplate,
  checkmarkDict,
  customRenderers,
  environmentGroups,
  environmentId,
  environments,
  environmentScanResults,
  fields,
  isFetchingEnvironments,
  isSearchable,
  isSelectable,
  onClicks,
  maxHeight,
  maxWidth,
  minHeight,
  minWidth,
  rootGroupId,
  selectedEntityId,
  width,
  label,
  visualCuesData,
  visualCueDataType,
  isRefreshing,
  refreshApplication,
}) {
  const [searchInput, setSearchInput] = useState('')
  const [isCalcingSearchTagDict, setIsCalcingSearchTagDict] = useState(false)

  // searchTagDict is an dict that defines all the searchable names
  // for each entity (group or environment)
  // used for filtering down entities
  const [searchTagDict, setSearchTagDict] = useState({})

  useEffect(() => isSearchable && createSearchTagDict(), [
    environments,
    environmentGroups,
    environmentData,
  ])

  // TODO: So many different sources of truth for the root name
  // this needs to be improved on the BE somehow
  const getRootName = () => {
    let rootName =
      get(fields, 'propertyTypes.displayName') ||
      get(applicationRoot, 'catalogAppDisplayName') ||
      get(applicationTemplate, 'catalogAppName')

    if (get(applicationRoot, 'catalogApplication.requiresHierarchicalModel')) {
      rootName = get(environmentGroups, [rootGroupId], {}).name || rootName
    }

    return rootName
  }

  const createSearchTagDict = () => {
    const searchTagDict = {}
    setIsCalcingSearchTagDict(false)

    Object.values(environmentGroups).forEach(({ name, parentId, id: entityId }) => {
      let entityName = name

      if (!parentId) {
        entityName = getRootName()
      }

      startTagCreation({ entityName, entityId, parentId, searchTagDict })
    })

    Object.values(environments).forEach(
      ({ name: envName, id: entityId, parentGroupId: parentId }) => {
        let entityName = envName

        if (getShowAccountNumber()) {
          entityName = get(environmentData, `${entityId}.catalogAppDisplayName`)
        }
        // technically I don't need this tag creation at the environment level
        // can just be null for the tags
        startTagCreation({ entityName, entityId, parentId, searchTagDict })
      }
    )

    setSearchTagDict(searchTagDict)
    setIsCalcingSearchTagDict(false)
  }

  const startTagCreation = ({ entityName, entityId, parentId, searchTagDict }) => {
    const newTags = new Set([])
    searchTagDict[entityId] = newTags

    if (parentId) {
      searchTags({ parentId, searchTagDict, carryOverTags: newTags, entityName })
    }
  }

  const searchTags = ({ entityName, parentId, carryOverTags, searchTagDict }) => {
    const supportsResources = applicationRoot.catalogApplication.supportsResources
    const currentGroup = environmentGroups[parentId] || {}
    const oldTags = searchTagDict[parentId]
    const oldChildrenTags = oldTags || new Set([])

    const newTags = new Set([...oldChildrenTags, ...carryOverTags, entityName])
    searchTagDict[parentId] =
      // If supports resources, the root group will not have any children
      // so the tags should be empty
      supportsResources && parentId === rootGroupId ? new Set([]) : newTags

    if (currentGroup.parentId) {
      searchTags({
        parentId: currentGroup.parentId,
        carryOverTags: newTags,
        searchTagDict,
        entityName: currentGroup.name,
      })
    }
  }

  const getRoots = () => {
    const root = environmentGroups[rootGroupId]
    const roots = { root }
    const supportsResources = get(
      applicationRoot,
      'catalogApplication.supportsResources'
    )

    if (supportsResources && root) {
      roots.subRoot = environmentGroups[root.childGroupIds[0]]
    }

    return roots
  }

  const getShowAccountNumber = () => {
    // TODO: this function is an argument for using models for
    // certain data
    const appProperties = get(
      applicationRoot,
      'catalogApplication.propertyTypes',
      []
    )

    const showAccountNumberInfo = appProperties.find(
      prop => prop.name === 'showAwsAccountNumber'
    )

    return showAccountNumberInfo && showAccountNumberInfo.value
  }

  return (
    <EnvironmentGroups
      applicationRoot={applicationRoot}
      checkmarkDict={checkmarkDict || {}}
      customRenderers={customRenderers}
      onClicks={onClicks}
      environmentData={environmentData}
      environmentScanResults={environmentScanResults}
      environmentGroups={environmentGroups}
      environmentId={environmentId}
      environments={environments}
      isCalcingSearchTagDict={isCalcingSearchTagDict}
      isFetchingEnvironments={isFetchingEnvironments}
      isSearchable={isSearchable}
      isSelectable={isSelectable}
      maxHeight={maxHeight}
      maxWidth={maxWidth}
      minHeight={minHeight}
      minWidth={minWidth}
      roots={getRoots()}
      rootName={getRootName()}
      searchInput={searchInput}
      searchTagDict={searchTagDict}
      selectedEntityId={selectedEntityId}
      setSearchInput={setSearchInput}
      width={width}
      label={label}
      visualCuesData={visualCuesData}
      visualCueDataType={visualCueDataType}
      isRefreshing={isRefreshing}
      refreshApplication={refreshApplication}
    />
  )
}

const mapStateToProps = state => ({
  applicationRoot: getSelectedApplicationRoot(state),
  applicationTemplate: getSelectedApplicationTemplate(state),
  rootGroupId: getSelectedApplicationRootGroupId(state),
  environmentGroups: getSelectedApplicationEnvironmentGroups(state),
  environments: getApplicationEnvironments(state),
  environmentScanResults: getSelectedApplicationEnvironmentScanResults(state),
  environmentId: getSelectedEnvironmentId(state),
  isFetchingEnvironments: getFetchingEnvironments(state),
  environmentData: getSelectedApplicationEnvironments(state),
})

export default connect(mapStateToProps)(memo(EnvironmentGroupsContainer))
