import { FETCH_PRIVILEGES } from 'action_creators/privileges'

export const ADMIN_PRIVILEGES = {
  appList: 'apps.app.list',
  appManage: 'apps.app.manage',
  userList: 'identity.user.view',
  userManage: 'identity.user.manage',
  auditList: 'diagnostics.audit.list',
  notificationList: 'workflows.notification.list',
  notificationManage: 'workflows.notification.manage',
  securityList: 'securityadmin.security.list',
  securityManage: 'securityadmin.security.manage',
  reportList: 'reports.report.list',
  permissionList: 'authz.permission.list',
  passwordPolicyList: 'sm.passwordpolicy.list',
  viewBannerSettings: 'settings.banner.view',
  manageBannerSettings: 'settings.banner.manage',
}

export const MENU_PRIVILEGES = {
  queryEngineView: 'ada.queryengine.view',
  riskAnalyticsView: 'ada.riskanalytics.view',
}

export const privilegesReducerKey = 'privileges'

let initialState = {
  fetchingPrivileges: false,
  fetchedPrivileges: false,
  privileges: [],
  showAdmin: false,
}

export const privilegesReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_PRIVILEGES}_PENDING`: {
      return {
        ...state,
        error: null,
        fetchingPrivileges: true,
        fetchedPrivileges: false,
      }
    }

    case `${FETCH_PRIVILEGES}_FULFILLED`: {
      const privileges = action.payload.data
      const showAdmin = Object.values(ADMIN_PRIVILEGES).some(privilege =>
        privileges.includes(privilege)
      )
      return {
        ...state,
        privileges,
        showAdmin,
        fetchingPrivileges: false,
        fetchedPrivileges: true,
      }
    }

    case `${FETCH_PRIVILEGES}_REJECTED`: {
      return {
        ...state,
        fetchingPrivileges: false,
        fetchedPrivileges: false,
        error: action.payload,
      }
    }

    default:
      return state
  }
}
