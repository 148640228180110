import React from 'react'
import Typography from 'britive-design-system/core/components/typography'
import ApprovalsList from './approval-list/ApprovalsList'
import { getSecretStatus, getActionLabel, getConsumerType } from '../../utils'
import {
  translatedStrings,
  tableColumns,
  userApprovalsListString,
} from './constants'

const MyApprovalsList = ({
  history,
  match,
  getMyApprovalsListData,
  myApprovalsListState,
  updateApprovalsListSearchTerm,
  requestToApprove,
  requestToReject,
  getMyApprovalsListDataLoadMore,
}) => {
  return (
    <div>
      <div className="page-header-wrapper">
        <Typography variant="heading5">My Approvals</Typography>
      </div>
      <ApprovalsList
        history={history}
        match={match}
        getMyApprovalsListData={getMyApprovalsListData}
        myApprovalsListState={myApprovalsListState}
        updateApprovalsListSearchTerm={updateApprovalsListSearchTerm}
        requestToApprove={requestToApprove}
        requestToReject={requestToReject}
        getMyApprovalsListDataLoadMore={getMyApprovalsListDataLoadMore}
        getApprovalStatus={getSecretStatus}
        getConsumerType={getConsumerType}
        getActionLabel={getActionLabel}
        translatedStrings={translatedStrings}
        tableColumns={tableColumns}
        approvalsListString={userApprovalsListString}
      />
    </div>
  )
}

export default MyApprovalsList
