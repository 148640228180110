import React from 'react'
import MyApprovalsDetails from './MyApprovalsDetails'
import '../../approvalRequests.scss'

// eslint-disable-next-line react/display-name
const MyApprovalsDetailsContainer = ({
  history,
  match,
  redirectPath,
  AdditionalDetails,
}) => {
  return (
    <div className="my-secrets-app-container">
      <MyApprovalsDetails
        history={history}
        approvalId={match?.params?.id}
        redirectPath={redirectPath}
        AdditionalDetails={AdditionalDetails}
      />
    </div>
  )
}

export default MyApprovalsDetailsContainer
