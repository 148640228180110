import { PureComponent } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'

import { getFetchingAllApplicationRoots } from 'selectors/application_root'

class ApplicationRootsLoader extends PureComponent {
  //Note: looks no need to do Apps api call here, there is no any impact on profiles. removing it

  render() {
    const { children } = this.props

    return children
  }
}

const mapStateToProps = state => ({
  fetching: getFetchingAllApplicationRoots(state),
})

const withConnect = connect(mapStateToProps)

export default compose(withConnect, withRouter)(ApplicationRootsLoader)
