import React, { Fragment, memo, useEffect, useState } from 'react'
import capitalize from 'lodash/capitalize'
import ReactTooltip from 'react-tooltip'

import TableEllipsisCell from 'components/table/TableV2/TableEllipsisCell'
import Table from 'components/table/TableV2/Table'
import ScanDetailsDrawer from '../ScanDetailsDrawer/ScanDetailsDrawerContainer'

const styles = {
  changeCell: {
    display: 'flex',
    alignItems: 'center',
  },
  changeText: {
    overflow: 'hidden',
    flexGrow: 1,
    textOverflow: 'ellipsis',
  },
  changesIconWrapper: {
    padding: '0 15px',
    display: 'flex',
  },
  changesIcon: {
    cursor: 'pointer',
  },
}

function Groups({ dropdownFilter, selectedAppId, entityId, selectedApp }) {
  const isHierarchical = selectedApp.catalogApplication.requiresHierarchicalModel
  const [scanDetailsDrawerOpened, setScanDetailsDrawerOpened] = useState(false)
  const [selectedGroup, setSelectedGroup] = useState(null)
  useEffect(() => {
    ReactTooltip.rebuild()
  }, [])

  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
      fixed: 'left',
      minWidth: 200,
    },
    {
      Header: 'Description',
      accessor: 'description',
      minWidth: 200,
    },
    ...(isHierarchical
      ? [
          {
            Header: 'Scope',
            accessor: 'inheritedScope',
            minWidth: 100,
            sortable: false,
          },
        ]
      : []),
    {
      Header: 'Status',
      accessor: 'scanStatus',
      minWidth: 80,
    },
    {
      id: 'changed_status',
      Header: 'Change Type',
      Cell: renderChangeType,
      sortable: false,
      accessor: ({
        scanStatus,
        hasMembershipsChanged,
        hasDefinitionsChanged,
        type,
      }) => ({
        scanStatus,
        hasMembershipsChanged,
        hasDefinitionsChanged,
        type,
      }),
      minWidth: 200,
    },
  ]

  function getChangeStatus({
    scanStatus,
    hasMembershipsChanged,
    hasDefinitionsChanged,
    type,
  }) {
    const formattedType = capitalize(type)
    let changeStatus

    if (
      (!hasMembershipsChanged && !hasDefinitionsChanged) ||
      scanStatus === 'New' ||
      scanStatus === 'Deleted'
    ) {
      changeStatus = scanStatus
    } else if (hasMembershipsChanged && hasDefinitionsChanged) {
      changeStatus = `${formattedType} Definition and Membership Changed`
    } else {
      if (hasMembershipsChanged) {
        changeStatus = `${formattedType} Membership Changed`
      }
      if (hasDefinitionsChanged) {
        changeStatus = `${formattedType} Definition Changed`
      }
    }
    return changeStatus
  }

  function renderChangeType({ original: data }) {
    const { hasMembershipsChanged, hasDefinitionsChanged } = data
    const iconRef = React.createRef()

    const changeStatus = getChangeStatus(data)

    return (
      <Fragment>
        <TableEllipsisCell style={styles.changeCell}>
          <span style={styles.changeText} title={changeStatus}>
            {changeStatus}
          </span>
        </TableEllipsisCell>

        {(hasMembershipsChanged || hasDefinitionsChanged) &&
          !(changeStatus === 'New' || changeStatus === 'Deleted') && (
            <span style={styles.changesIconWrapper}>
              <span
                onClick={() => {
                  setSelectedGroup(data)
                  setScanDetailsDrawerOpened(true)
                }}
                className="fa fa-exchange-alt fs:18"
                data-tip
                ref={iconRef}
                style={styles.changesIcon}
                onMouseEnter={() => ReactTooltip.show(iconRef.current)}
                onMouseLeave={() => ReactTooltip.hide(iconRef.current)}
              />
            </span>
          )}
      </Fragment>
    )
  }

  return (
    <Fragment>
      <Table
        columns={columns}
        identifier="appPermissionId"
        dataUrl={`/apps/${selectedAppId}/environments/${entityId}/groups`}
        emptyTableMessage={'No Groups were found.'}
        params={{ showDelta: true }}
        dropdownFilters={dropdownFilter}
        margin={40}
      />
      <ScanDetailsDrawer
        isOpen={selectedGroup && scanDetailsDrawerOpened}
        isHierarchical={isHierarchical}
        toggleDrawer={() => setScanDetailsDrawerOpened(!scanDetailsDrawerOpened)}
        data={selectedGroup}
        selectedAppId={selectedAppId}
        entityId={entityId}
        tabs={
          scanDetailsDrawerOpened
            ? [
                {
                  name: 'Permissions',
                  dataUrl: `/apps/${selectedAppId}/environments/${entityId}/groups/${selectedGroup.appPermissionId}/permissions`,
                  nameIdentifier: 'permissionName',
                },
                {
                  name: 'Accounts',
                  dataUrl: `/apps/${selectedAppId}/environments/${entityId}/groups/${selectedGroup.appPermissionId}/accounts`,
                  nameIdentifier: 'accountName',
                },
              ]
            : []
        }
      />
    </Fragment>
  )
}

export default memo(Groups)
