import React from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'
import ApplicationList from './Applications'
import ProfileList from './ProfileList'
import ProtectedRoute from 'routes/protected_route'
import PolicyManagement from 'secret-manager-ui/core/components/profile-policy'
import PapPermissionsConstraints from '../admin/applications/Application/Profiles/details/PapPermissions/PapPermissionsConstraints'
import CreateRole from '../admin/applications/Application/CreatePermissions'

const PolicyManagementWithRouter = withRouter(PolicyManagement)

const RequestAccess = ({ match }) => {
  const request =
    window.location.pathname.includes('/policies/request') ||
    window.location.pathname.includes('/policies/create') ||
    window.location.pathname.includes('/policies/draft')
  const mode = window.location.pathname.includes('/details/policies/create')
  return (
    <Switch>
      <ProtectedRoute
        path={
          request
            ? `${match.url}/applications/:appId/profiles/:profileId/details/policies/:mode/manage`
            : `${match.url}/applications/:appId/profiles/:profileId/details/policies/manage`
        }
        component={PolicyManagementWithRouter}
        extraProps={{
          appManage: !!mode,
        }}
        fallbackPath={`${match.url}/applications/:appId/profiles/:profileId/details/policies`}
      />
      <ProtectedRoute
        path={`${match.url}/applications/:appId/profiles/:profileId/details/permissions/:mode/:permissionName/:permissionType/constraints`}
        component={PapPermissionsConstraints}
        fallbackPath={`${match.url}/applications/:appId/profiles/:profileId/details/permissions`}
      />
      <Route
        exact
        path={`${match.url}/applications/:appId/profiles/:profileId/details/permissions/:mode/create-role`}
        component={CreateRole}
      />
      <Route exact path={match.url} component={ApplicationList} />
      <Route path={`${match.url}/applications/:appId`} component={ProfileList} />
      {/* {TODO: Add routes here} */}
    </Switch>
  )
}

export default RequestAccess
