import React from 'react'
import Accordion from 'britive-design-system/core/components/accordion'
import TableSection from 'components/table/tableSectionV2'
import TableEllipsisCell from 'components/table/TableV2/TableEllipsisCell'
import privilegedIcon from 'static/images/table-icons/lightening.png'
import './ApprovalPermission.scss'

const ApprovalPermission = ({ approvalViewPermissions, permissionsSection }) => {
  if (approvalViewPermissions.length === 0) {
    return null
  }

  return (
    <div className="my-access-approval-view-permission" ref={permissionsSection}>
      <Accordion expanded title="Permissions">
        <TableSection
          resizable={true}
          sortable
          data={approvalViewPermissions}
          columns={[
            {
              Header: 'Name',
              accessor: 'name',
              Cell: ({ value, original }) => (
                <TableEllipsisCell>
                  {value}
                  {original.privileged ? (
                    <span className="privileged-icon">
                      <img src={privilegedIcon} />
                    </span>
                  ) : null}
                </TableEllipsisCell>
              ),
            },
            {
              Header: 'Description',
              accessor: 'description',
              Cell: ({ value }) => <TableEllipsisCell value={value} />,
            },
            {
              Header: 'Type',
              accessor: 'type',
              Cell: ({ value }) => <TableEllipsisCell value={value} />,
            },
          ]}
          defaultPageSize={10}
          emptyTableMessage="No Permissions found."
        />
      </Accordion>
    </div>
  )
}

export default ApprovalPermission
