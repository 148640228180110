import React from 'react'
import { NavLink } from 'react-router-dom'
import * as text from 'translations/english_us'
import Breadcrumbs from 'components/breadcrumbs'

export const AssociationApproversBreadcrumbs = ({ appName, match }) => {
  return (
    <Breadcrumbs>
      <nav aria-label="breadcrumb">
        <ol
          className="breadcrumb"
          data-testid="association-approvers-breadcrumb-list"
        >
          <li className="breadcrumb-item">
            <NavLink to="/admin">{text.SYSTEM_ADMINISTRATION}</NavLink>
          </li>

          <li className="breadcrumb-item">
            <NavLink to="/admin/tenant-apps">{text.TENANT_APPS}</NavLink>
          </li>

          <li className="breadcrumb-item">
            <NavLink to={`${match.url}/access-request-settings`}>
              {`${appName} Access Builder Settings`}
            </NavLink>
          </li>

          <li className="breadcrumb-item active" aria-current="page">
            Assign Association Approvers
          </li>
        </ol>
      </nav>
    </Breadcrumbs>
  )
}
