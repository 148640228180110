import React, { memo, useRef, useState, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import Echarts from 'echarts-for-react'
import moment from 'moment'

import Card from '../Card'
import PageLoader from 'components/PageLoader'
import isEmpty from 'lodash/isEmpty'

// TODO: Not sure if i like styling in one styled component
const Wrapper = styled(Card)`
  height: 775px;
  width: 100%;
  margin-top: 8px;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-top: 0;

  .headerWrapper {
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 4;
    padding-top: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
  }

  .header {
    color: var(--endeavour);
  }

  .tableHeader {
    display: flex;
    justify-content: space-between;
  }

  .tableWrapper {
    height: 205px;
  }

  &.loader {
    padding-top: 8px;
  }

  &.noData {
    padding-top: 8px;
    height: unset;
  }

  > h5 {
    color: var(--endeavour);
  }

  h6 {
    cursor: pointer;
    color: var(--mine-shaft);

    span {
      color: var(--boston-blue);
    }

    .arrow {
      &.isOpen {
        transform: rotate(180deg);
      }

      transition: transform 0.1s linear;
    }
  }

  .break {
    margin: 24px 0 8px;
    border-top: 3px solid #2a98f8;
    height: 10px;
    float: left;
    width: 100%;
  }
  .noData {
    width: 100%;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;

    h6 {
      align-self: flex-start;
    }
  }
`

const StatsWrapper = styled.div`
  .each-stat {
    align-items: center;
    justify-content: center;
    border-radius: 1px;
    box-shadow: 0 0 4px 1px var(--periwinkle-gray);
    border-top: 2px solid var(--brand);
    padding: 10px;
    flex-direction: column;
    margin: 10px 0;

    .chart-container {
      height: 200px;
      width: 100%;
    }
    .titleNumber {
      color: ${props => props.color};
      font-size: 40px;
      font-weight: 500;
    }

    .titleLabel {
    }
  }
`

const colors = ['#F44336', '#2196F3', '#FFC107', '#4CAF50', '#9C27B0']

function Insights({ data, isLoading }) {
  const echart = useRef(null)
  const [values, setValues] = useState(null)

  const totalValues = useMemo(() => {
    if (isEmpty(values)) {
      return null
    }
    const { startValue, endValue } = values
    let accounts = 0,
      permissions = 0,
      definitions = 0
    data.slice(startValue, endValue + 1).forEach(d => {
      accounts += d.newAccountsCreated
      permissions += d.newPermissionsCreated
      definitions += d.permissionDefinitionChanges
    })
    const totalValues = {
      'Accounts Created': accounts,
      'Permissions Created': permissions,
      'Permission Definitions Changed': definitions,
    }
    return totalValues
  }, [values])

  useEffect(() => {
    if (data) {
      setValues({ startValue: 0, endValue: data.length - 1, start: 0, end: 100 })
    }
  }, [data])

  if (isLoading) {
    return (
      <Wrapper className="loader">
        <PageLoader text="Loading Insight Stats" />
      </Wrapper>
    )
  }

  if (!data || isEmpty(values)) {
    return <Wrapper className="noData">No Insight Data Available</Wrapper>
  }

  return (
    <StatsWrapper>
      <div className="each-stat">
        <h6 className="titleLabel">Insight</h6>
        <Echarts
          ref={echart}
          onEvents={{
            dataZoom: () => {
              const o = echart.current.getEchartsInstance().getOption()
              const { startValue, endValue, start, end } = o.dataZoom[1]
              setValues({ startValue, endValue, start, end })
            },
          }}
          style={{ height: 240 }}
          option={{
            color: colors,
            legend: {
              data: [
                'Accounts Created',
                'Permissions Created',
                'Permission Definitions Changed',
              ],
              y: 'top',
              show: true,
              formatter: d => `${d}: ${totalValues[d]}`,
              textStyle: { fontSize: 13 },
            },
            tooltip: {
              trigger: 'axis',
            },
            grid: {
              bottom: 60,
              top: 40,
              left: 50,
              right: 50,
              show: false,
            },
            dataZoom: [
              {
                start: values.start,
                end: values.end,
                id: 'slider',
                realtime: false,
                type: 'slider',
                bottom: 10,
                height: 10,
                borderColor: 'transparent',
                backgroundColor: '#e2e2e2',
                handleIcon:
                  'M10.7,11.9H9.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4h1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7v-1.2h6.6z M13.3,22H6.7v-1.2h6.6z M13.3,19.6H6.7v-1.2h6.6z', // jshint ignore:line
                handleSize: 20,
                handleStyle: {
                  shadowBlur: 6,
                  shadowOffsetX: 1,
                  shadowOffsetY: 2,
                  shadowColor: '#aaa',
                },
              },
              {
                type: 'inside',
                realtime: true,
                start: values.start,
                end: values.end,
                id: 'inside',
              },
            ],
            xAxis: {
              name: 'Date',
              axisLabel: {
                rotate: 0,
              },
              splitLine: {
                show: false,
              },
              data: data.map(d =>
                moment(d.scanTime)
                  .format('MM/DD hh:mm')
                  .replace(' ', '\n')
              ),
            },
            yAxis: {
              name: 'Count',
              minInterval: 1,
              type: 'value',
              splitLine: {
                show: false,
              },
            },
            series: [
              {
                data: data.map(d => d.newAccountsCreated),
                type: 'line',
                smooth: true,
                lineStyle: {
                  width: 1,
                  shadowColor: '#efefef',
                  type: 'solid',
                  shadowBlur: 3,
                },
                name: 'Accounts Created',
              },
              {
                data: data.map(d => d.newPermissionsCreated),
                type: 'line',
                smooth: true,
                lineStyle: {
                  width: 1,
                  shadowColor: colors[1],
                  type: 'solid',
                  shadowBlur: 3,
                },
                name: 'Permissions Created',
              },
              {
                data: data.map(d => d.permissionDefinitionChanges),
                type: 'line',
                smooth: true,
                lineStyle: {
                  width: 1,
                  shadowColor: colors[2],
                  type: 'solid',
                  shadowBlur: 3,
                },
                name: 'Permission Definitions Changed',
              },
            ],
          }}
        />
      </div>
    </StatsWrapper>
  )
}

export default memo(Insights)
