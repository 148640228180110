import React, { useEffect, useState, useRef } from 'react'
import DataTable from '../../../components/data-table/DataTable'
import BackToTop from 'britive-design-system/core/components/backToTop'
import Tooltip from 'britive-design-system/core/components/tooltip'
import {
  classes,
  myApprovalsTableTestId,
  backToTopId,
  adminApprovalsListString,
  secretModuleConstants,
} from '../constants'
import {
  convertTimeToCurrentTimezone,
  sortApprovalsAndRequestByDate,
} from '../../../utils'
import InfoIcon from 'static/images/infoIcon.svg'
import MyResourcesIcon from 'static/images/my-resources.svg'
import './ApprovalsList.scss'

const ApprovalsList = ({
  history,
  match,
  getMyApprovalsListData,
  myApprovalsListState,
  updateApprovalsListSearchTerm,
  getMyApprovalsListDataLoadMore,
  getApprovalStatus,
  getActionLabel,
  getConsumerType,
  translatedStrings,
  tableColumns,
  approvalsListString,
}) => {
  const [myApprovalsList, setMyApprovalsList] = useState([])
  const { loading, result, pagination } = myApprovalsListState
  const tableContainer = useRef(null)

  useEffect(() => {
    getMyApprovalsListData()
    updateApprovalsListSearchTerm('')
  }, [])

  useEffect(() => {
    if (myApprovalsListState?.searchTerm) {
      getMyApprovalsListData()
    }
  }, [myApprovalsListState?.searchTerm])

  useEffect(() => {
    const approvals =
      result &&
      result.map(approval => ({
        ...approval,
        requestId: approval?.requestId || translatedStrings.noneValue,
        createdAt:
          convertTimeToCurrentTimezone(approval?.createdAt) ||
          translatedStrings.noneValue,
        userId: approval?.userId || translatedStrings.noneValue,
        action: getActionLabel(approval?.action) || translatedStrings.noneValue,
        resource:
          approval?.resourceName ||
          approval?.resource ||
          translatedStrings.noneValue,
        justification: approval?.justification || translatedStrings.noneValue,
        //status is translated because it will help in sorting.
        status: getApprovalStatus(approval?.status) || translatedStrings.noneValue,
        type: approval?.consumer || translatedStrings.noneValue,
        approverGroupName: approval?.context?.approverGroupName || 'N/A',
      }))
    setMyApprovalsList(sortApprovalsAndRequestByDate(approvals))
  }, [result])

  const viewHandler = request => {
    history.push(`${match.url}/view/${request.requestId}`)
    updateApprovalsListSearchTerm('')
  }

  const columns = [
    {
      field: 'trackingId',
      headerName: tableColumns.requestId,
      width: 80,
      sortable: true,
    },
    {
      field: 'createdAt',
      headerName: tableColumns.requestedOn,
      width: 120,
      sortable: true,
      isDate: true,
    },
    {
      headerName: tableColumns.type,
      width: 100,
      renderColumn: row => {
        const { type } = row
        return <span>{getConsumerType(type, row.context?.type)}</span>
      },
    },
    {
      field: 'resource',
      headerName: tableColumns.resource,
      width: 160,
      sortable: true,
      renderColumn: row => {
        const { context, resourceName, consumer } = row
        const iconUrl = context?.iconUrl
        return (
          <div className={classes.iconNameWrapper}>
            {iconUrl ? (
              <img className={classes.applicationIcon} src={iconUrl} />
            ) : consumer === secretModuleConstants.secretManager ? (
              <i className="fas fa-key" />
            ) : consumer === secretModuleConstants.resourceprofile ? (
              <img className={classes.applicationIcon} src={MyResourcesIcon} />
            ) : (
              ''
            )}
            <span>{resourceName}</span>
          </div>
        )
      },
    },
    {
      field: 'approverGroupName',
      headerName: tableColumns.approverGroup,
      width: 100,
      sortable: true,
    },
    {
      field: 'justification',
      headerName: tableColumns.justification,
      width: 180,
      sortable: true,
    },
    {
      field: 'userId',
      headerName: tableColumns.requestedBy,
      width: 100,
      sortable: true,
    },
    {
      field: 'status',
      headerName: tableColumns.approvalStatus,
      width: 80,
      sortable: true,
    },
    {
      headerName: tableColumns.action,
      width: 60,
      renderColumn: row => {
        const { status } = row
        const isApprovalPending = status === translatedStrings.approvalPendingText
        return (
          <div className={classes.myApprovalsActionItems}>
            <Tooltip
              title={
                isApprovalPending
                  ? translatedStrings.manageRequestTooltip
                  : translatedStrings.viewRequestTooltip
              }
              position="left"
            >
              <div>
                <img
                  className={classes.infoIcon}
                  src={InfoIcon}
                  alt="info"
                  onClick={() => viewHandler(row)}
                />
              </div>
            </Tooltip>
          </div>
        )
      },
    },
  ]

  const loadMoreHandler = () => {
    getMyApprovalsListDataLoadMore()
  }

  const findHeight = () =>
    // we need to remove 200px/173px(height of title and breadcrumbs, which is different in admin and user side)
    // from page height to accommodate approvals list
    approvalsListString === adminApprovalsListString
      ? 'calc(100vh - 200px)'
      : 'calc(100vh - 173px)'

  return (
    <>
      <div
        className={classes.listContainer}
        style={{ height: findHeight() }}
        ref={tableContainer}
      >
        <div data-testid={myApprovalsTableTestId} className={classes.tableContainer}>
          <DataTable
            inLineSort={true}
            rows={myApprovalsList}
            columns={columns}
            loading={loading}
            loadingMessage={translatedStrings.loadingMessage}
            loadMoreHandler={loadMoreHandler}
            loadMore={!loading && Boolean(pagination?.next)}
          />
        </div>
      </div>
      <BackToTop id={backToTopId} parentRef={tableContainer} />
    </>
  )
}

export default ApprovalsList
