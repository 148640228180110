import styled from 'styled-components'

const DashboardCard = styled.div`
  //background: var(--white);
  //box-shadow: 0 0 12px 2px var(--periwinkle-gray);
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  padding: 8px;

  h5 {
    color: var(--dove-gray);
  }
`

export default DashboardCard
