import * as actions from 'actions'

const initialState = {
  loading: false,
  error: null,
  data: {},
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case `${actions.GET_PERMISSION_BY_VERSION}_PENDING`: {
      return {
        ...state,
        loading: true,
        error: null,
      }
    }

    case `${actions.GET_PERMISSION_BY_VERSION}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload?.data || {},
      }
    }

    case `${actions.GET_PERMISSION_BY_VERSION}_REJECTED`: {
      return {
        ...state,
        loading: false,
        error: 'Unable fetch the permission.',
      }
    }

    default:
      return { ...state }
  }
}
