import { fetchIfLoggedIn } from './utils.js'

export const FETCH_NOTIFICATIONS = 'NOTIFICATION/FETCH_NOTIFICATIONS'
export const FETCH_NOTIFICATION = 'NOTIFICATION/FETCH_NOTIFICATION'
export const FETCH_NOTIFICATION_RULES = 'NOTIFICATION/FETCH_NOTIFICATION_RULES'
export const FETCH_AVAILABLE_NOTIFICATION_APPS =
  'NOTIFICATION/FETCH_AVAILABLE_NOTIFICATION_APPS'
export const FETCH_AVAILABLE_NOTIFICATION_USERS =
  'NOTIFICATION/FETCH_AVAILABLE_NOTIFICATION_USERS'
export const FETCH_AVAILABLE_NOTIFICATION_TAGS =
  'NOTIFICATION/FETCH_AVAILABLE_NOTIFICATION_TAGS'
export const CREATE_NOTIFICATION = 'NOTIFICATION/CREATE_NOTIFICATION'
export const UPDATE_NOTIFICATION = 'NOTIFICATION/UPDATE_NOTIFICATION'
export const REMOVE_NOTIFICATION = 'NOTIFICATION/REMOVE_NOTIFICATION'
export const ENABLE_NOTIFICATION = 'NOTIFICATION/ENABLE_NOTIFICATION'
export const DISABLE_NOTIFICATION = 'NOTIFICATION/DISABLE_NOTIFICATION'

export const fetchNotifications = () => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: FETCH_NOTIFICATIONS,
      method: 'get',
      path: '/notifications',
    })
  )
}

export const createNotification = values => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: CREATE_NOTIFICATION,
      method: 'post',
      path: '/notifications',
      postBody: values,
    })
  )
}

export const updateNotification = values => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: UPDATE_NOTIFICATION,
      method: 'put',
      path: '/notifications',
      postBody: values,
    })
  )
}

export const fetchNotification = ({ id }) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: FETCH_NOTIFICATION,
      method: 'get',
      path: `/notifications/${id}`,
    })
  )
}

export const fetchNotificationRules = () => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: FETCH_NOTIFICATION_RULES,
      method: 'get',
      path: '/notifications/supported-rules',
    })
  )
}

export const fetchAvailableNotificationApps = ({ id }) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: FETCH_AVAILABLE_NOTIFICATION_APPS,
      method: 'get',
      path: `/notifications/${id}/available-apps`,
    })
  )
}

export const fetchAvailableNotificationUsers = ({ id }) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: FETCH_AVAILABLE_NOTIFICATION_USERS,
      method: 'get',
      path: `/notifications/${id}/available-users`,
    })
  )
}

export const fetchAvailableNotificationTags = ({ id }) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: FETCH_AVAILABLE_NOTIFICATION_TAGS,
      method: 'get',
      path: `/notifications/${id}/available-user-tags`,
    })
  )
}

export const removeNotification = ({ id }) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: REMOVE_NOTIFICATION,
      method: 'DELETE',
      meta: { notificationId: id },
      path: `/notifications/${id}`,
    })
  )
}

export const enableNotification = ({ id }) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: ENABLE_NOTIFICATION,
      method: 'post',
      meta: { notificationId: id },
      path: `/notifications/${id}/enabled-statuses`,
    })
  )
}

export const disableNotifcation = ({ id }) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: DISABLE_NOTIFICATION,
      method: 'post',
      meta: { notificationId: id },
      path: `/notifications/${id}/disabled-statuses`,
    })
  )
}
