export const actionButtonText = {
  CHECKIN: 'Check In',
  CHECKOUT: 'Check Out',
  REQUEST: 'Request',
  WITHDRAW: 'Withdraw',
}

export const searchQueryParam = 'searchText'
export const typeQueryParam = 'type'

export const locatStorageKeyPrefix = 'MY_RESOURCES_TABLE'
export const localStorageKey = {
  quickFilter: `${locatStorageKeyPrefix}_QUICK_FILTER`,
  groupByLabels: `${locatStorageKeyPrefix}_GROUP_BY_LABELS`,
}

export const ungroupedLabel = '[Others]'

export const availableProfilesTypeFilters = {
  FREQUENTLY_USED: 'frequently-used',
  FAVORITES: 'favorites',
}

export const internalGroupKeys = {
  MATCHES: '_matches',
  TOTAL_COUNT: '_totalCount',
  LABEL_KEY: '_labelKey',
}

export const greyColorLevels = ['#f1f1f1', '#e3e3e3', '#e6e6e6']
