import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import debounce from 'lodash/debounce'
import * as actions from 'actions'
import Checkbox from 'britive-design-system/core/components/checkbox'
import Button from 'britive-design-system/core/components/button'
import Typography from 'britive-design-system/core/components/typography'
import Table from './Table'
import { getPaginatedUsers } from 'action_creators/user'
import getTotalPageCount from 'utils/getTotalPageCount'

export const AddUserList = ({ currentIds, selectedUsers, onChange }) => {
  const dispatch = useDispatch()
  const { paginatedUsers = {}, fetchingPaginatedUsers } = useSelector(
    state => state.admin
  )
  const { users: minimumUserData = {} } = useSelector(state => state.admin)
  const filteredUsersData = paginatedUsers?.data?.filter(
    item => !currentIds.includes(item.userId)
  )

  useEffect(() => {
    if (isEmpty(paginatedUsers?.data) || paginatedUsers?.searchText) {
      fetchData({
        page: 0,
        size: 100,
      })
    }
  }, [])

  const fetchData = params => {
    dispatch(getPaginatedUsers(params, paginatedUsers?.searchText))
  }

  const handleLoadMore = () => {
    fetchData({
      page: paginatedUsers?.page + 1,
      size: 100,
    })
  }

  const handleSearch = value => {
    fetchData({
      page: 0,
      size: 100,
      searchText: value?.length ? value : '',
    })
  }

  return (
    <>
      <Table
        isLoading={fetchingPaginatedUsers}
        onSearch={debounce(handleSearch, 300)}
        tableData={filteredUsersData}
        columns={[
          {
            headerName: 'Name',
            field: 'name',
            renderColumn: row => {
              const itemIndex = selectedUsers.findIndex(
                user => user.id === row.userId
              )
              const isChecked = itemIndex > -1
              return (
                <div style={{ display: 'flex' }}>
                  <Checkbox
                    checked={isChecked}
                    onChange={e => {
                      const isItemChecked = e.target.checked
                      if (isItemChecked && !isChecked) {
                        const currentUserData = {
                          id: row.userId,
                          name: row.name,
                          memberType: 'User',
                          firstName: row.firstName,
                          lastName: row.lastName,
                        }
                        const updatedUser = [
                          ...(minimumUserData.User || []),
                          currentUserData,
                        ]
                        dispatch({
                          type: `${actions.GET_SELECTED_USERS}_FULFILLED`,
                          meta: { type: 'User' },
                          payload: {
                            data: updatedUser,
                          },
                        })
                        onChange([...selectedUsers, currentUserData])
                      } else if (isChecked) {
                        const clonedSelectedItems = [...selectedUsers]
                        clonedSelectedItems.splice(itemIndex, 1)
                        onChange(clonedSelectedItems)
                      }
                    }}
                  />
                  &nbsp;
                  {row.name}
                </div>
              )
            },
          },
          {
            field: 'username',
            headerName: 'Username',
          },
          {
            field: 'email',
            headerName: 'Email',
          },
        ]}
      />
      {!filteredUsersData?.length && !fetchingPaginatedUsers && (
        <div style={{ marginTop: 20 }}>
          <Typography variant="heading6">No users found!</Typography>
        </div>
      )}
      {!fetchingPaginatedUsers &&
        paginatedUsers?.data?.length > 0 &&
        paginatedUsers?.page <
          getTotalPageCount(paginatedUsers?.count, paginatedUsers?.size) && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '20px',
            }}
          >
            <Button variant="textOnly" size="medium" onClick={handleLoadMore}>
              Load More
            </Button>
          </div>
        )}
    </>
  )
}
