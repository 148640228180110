import React, { memo } from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'

import Permissions from './Permissions'

import { getSelectedApplicationRootId } from 'selectors/application_root'

import startCase from 'lodash/startCase'

function PermissionsContainer({ appId, envId, selectedApp }) {
  const permissionTypes = Object.keys(
    get(selectedApp, 'catalogApplication.supportedPermissionTypes', {})
  )
    .filter(p => p !== 'Group')
    .map(p => ({ label: startCase(p), value: p }))

  const dropdownFilter = [
    {
      label: 'Scan Status',
      value: 'scanStatus',
      options: [
        { label: 'New', value: 'New' },
        { label: 'Updated', value: 'Updated' },
        { label: 'Deleted', value: 'Deleted' },
      ],
    },
    { label: 'Type', value: 'type', options: permissionTypes },
  ]
  return (
    <Permissions
      dropdownFilter={dropdownFilter}
      selectedAppId={appId}
      entityId={envId}
      selectedApp={selectedApp}
    />
  )
}

const mapStateToProps = state => {
  return {
    appId: getSelectedApplicationRootId(state),
  }
}

export default connect(mapStateToProps)(memo(PermissionsContainer))
