import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import axios from 'axios'
import Cookies from 'js-cookie'
import sha256 from 'crypto-js/sha256'
import Base64 from 'crypto-js/enc-base64'

import LoginRoutes from './routes'

import { getUser, getUserPure } from 'action_creators/user'
import {
  authenticateUser,
  updateAuthenticationInfo,
  authError,
  setIsInitialAuthing,
  fetchIdentityProvidersList,
  getIdentityProviderType,
} from 'action_creators/account'

import { getIsAuthenticated } from 'utils/authentication'

import { preFetch, doFetch } from 'utils/do_fetch'

import {
  AUTHENTICATION,
  NEW_PASSWORD_REQUIRED,
  MFA_SETUP,
  SOFTWARE_TOKEN_MFA,
  VALIDATE_TOKEN,
  VALIDATE_USER,
  SSO,
  VALIDATE_COOKIE,
} from 'constants/cognitoAuthentication'

import { handleCLIRedirect } from 'utils/handle-cli-redirect'
import generateRandomString, { base64URLEncode } from 'utils/generateRandomString'
import isEmpty from 'lodash/isEmpty'
import './login.scss'

const REDIRECT_DICT = {
  [NEW_PASSWORD_REQUIRED]: '/login/change-password',
  [MFA_SETUP]: '/login/mfa',
  [SOFTWARE_TOKEN_MFA]: '/login/mfa',
}

class Login extends PureComponent {
  state = {
    challenge: VALIDATE_USER,
  }

  async componentDidMount() {
    const {
      getUserPure,
      updateAuthenticationInfo,
      location,
      setIsInitialAuthing,
      authError: authenticateError,
      history,
      isAuthenticated,
    } = this.props
    const fromCli = sessionStorage.getItem('cli')

    setIsInitialAuthing(true)
    if (isAuthenticated && fromCli) {
      try {
        const authResponse = await doFetch({
          path: '/auth',
          method: 'POST',
          postBody: {
            authParameters: {
              challenge: VALIDATE_COOKIE,
              cliToken: fromCli,
              type: 'CLI',
            },
          },
        })

        updateAuthenticationInfo(authResponse)

        // This case is specifically added to redirect User after SSO authentication.
        const redirectUrl = localStorage.getItem('redirectUrl')
        if (redirectUrl) {
          localStorage.removeItem('redirectUrl')
          history.push(redirectUrl)
        }

        if (handleCLIRedirect(authResponse, history)) {
          const userResponse = await preFetch({
            name: 'getUser',
            path: '/access/users',
            method: 'get',
          })

          return await getUserPure(userResponse)
        }
      } catch (error) {
        this.props.fetchIdentityProviders()
        this.props.fetchIdentityProviderType()
        authenticateError(error?.response?.data?.message)
      }
    } else {
      const values = queryString.parse(location.search)

      const codeVerifier = sessionStorage.getItem('codeVerifier')

      // logs to debug PAB-14204
      console.log('code_verifier', codeVerifier)
      console.log('code', values.code)

      if (values.code && codeVerifier) {
        const ssoUrl = sessionStorage.getItem('ssoSignIn')

        // logs to debug PAB-14204
        console.log('url', ssoUrl)

        const [domain, queryParams] = ssoUrl.split('/authorize')
        const { client_id: clientId } = queryString.parse(queryParams)
        const origin = window.location.origin.toString()

        const redirectURI = `${origin}/login`

        const bodyFormData = new URLSearchParams({
          grant_type: 'authorization_code',
          client_id: clientId,
          code: values.code,
          code_verifier: codeVerifier,
          redirect_uri: redirectURI,
        })

        try {
          // logs to debug PAB-14204
          console.log('fetching token')

          const response = await axios.post(`${domain}/token`, bodyFormData, {
            headers: {
              'content-type': 'application/x-www-form-urlencoded',
            },
            withCredentials: true,
            xsrfCookieName: 'csrfToken',
          })

          Cookies.set('signInMethod', 'sso')

          const {
            access_token: accessToken,
            refresh_token: refreshToken,
            id_token: idToken,
          } = response.data

          const authResponse = await doFetch({
            path: '/auth',
            method: 'POST',
            postBody: {
              authParameters: {
                AccessToken: accessToken,
                RefreshToken: refreshToken,
                IdToken: idToken,
                challenge: VALIDATE_TOKEN,
                ...(fromCli && { cliToken: fromCli, type: 'CLI' }),
              },
            },
          })

          sessionStorage.removeItem('codeVerifier')
          updateAuthenticationInfo(authResponse)

          // This case is specifically added to redirect User after SSO authentication.
          const redirectUrl = localStorage.getItem('redirectUrl')
          if (redirectUrl) {
            localStorage.removeItem('redirectUrl')
            history.push(redirectUrl)
          }

          if (handleCLIRedirect(authResponse, history)) {
            const userResponse = await preFetch({
              name: 'getUser',
              path: '/access/users',
              method: 'get',
            })

            return await getUserPure(userResponse)
          }
        } catch (error) {
          authenticateError(error?.response?.data?.message)
        }
      } else {
        if (location?.pathname !== '/login') {
          setIsInitialAuthing(false)
          return
        }
        this.props.fetchIdentityProviders()
        this.props.fetchIdentityProviderType()
      }
    }
  }

  async componentDidUpdate(prevProps) {
    const {
      challengeParameters,
      challenge,
      isAuthenticated,
      history,
      identityProvidersList,
      location,
    } = this.props

    const from = location.state?.from || { pathname: '/' }

    if (!prevProps.isAuthenticated && isAuthenticated) {
      history.push(`${from.pathname}${from.search || ''}`)
    }

    if (prevProps.challengeParameters !== challengeParameters && challenge) {
      const redirectUrl = REDIRECT_DICT[challenge]

      if (redirectUrl) {
        history.push(redirectUrl, { from })
      }
    }

    if (prevProps.identityProvidersList.length !== identityProvidersList.length) {
      this.resetChallenge()
    }
  }

  fetchUserData = async () => {
    return await preFetch({
      path: '/access/users',
      method: 'get',
    })
  }

  onSubmit = async fields => {
    const { challenge } = this.state
    const { authenticateUser, location, history } = this.props
    const fromCli = sessionStorage.getItem('cli')
    const query = location.query
    const authResponse = await authenticateUser({
      authParameters: {
        username: fields.username,
        ...(challenge === AUTHENTICATION && { password: fields.password }),
        challenge,
        ...(fromCli && { cliToken: fromCli, type: 'CLI' }),
      },
    })
    const challengeParameters =
      authResponse?.data?.authenticationResult?.challengeParameters

    if (!isEmpty(challengeParameters)) {
      if (challengeParameters.challenge === AUTHENTICATION) {
        this.setState({ challenge: AUTHENTICATION })
        return
      }

      if (challengeParameters.challenge === SSO) {
        const params = queryString.parse(location.search)
        if (params?.redirectUrl) {
          localStorage.setItem('redirectUrl', params.redirectUrl)
        }
        this.handleSSOLogin(challengeParameters)
        return
      }
    }
    if (
      ![NEW_PASSWORD_REQUIRED, SOFTWARE_TOKEN_MFA, MFA_SETUP].includes(
        challengeParameters.challenge
      )
    ) {
      Cookies.set('signInMethod', 'login')
      if (handleCLIRedirect(authResponse, history)) {
        window.location.assign(query?.redirectUrl || '/')
      }
    }
  }

  handleSSOLogin = ({ loginUrl, logoutUrl }) => {
    const { identityProviderType, location } = this.props
    const isIdpTypeBritive = identityProviderType.includes('Britive')
    const params = queryString.parse(location.search)

    let britiveIdpLoginUrl = params?.redirectUrl
      ? `${loginUrl}&redirectUrl=${params?.redirectUrl}`
      : loginUrl

    setIsInitialAuthing(true)
    try {
      sessionStorage.setItem('ssoSignIn', loginUrl)
      const randomString = base64URLEncode(generateRandomString(64))

      // logs to debug PAB-14204
      console.log('set code_verifier', randomString)
      sessionStorage.setItem('codeVerifier', randomString)
      const hashedString = base64URLEncode(Base64.stringify(sha256(randomString)))
      const origin = window.location.origin.toString()
      const redirectURI = `${origin}/login`

      let url = `${loginUrl}&redirect_uri=${redirectURI}`
      url += `&code_challenge=${hashedString}`
      url += `&code_challenge_method=S256`

      // logs to debug PAB-14204
      console.log('url', url)

      isIdpTypeBritive
        ? sessionStorage.setItem('ssoSignOut', logoutUrl)
        : sessionStorage.setItem(
            'ssoSignOut',
            `${logoutUrl}&redirect_uri=${redirectURI}&logout_uri=${redirectURI}`
          )

      window.location.assign(isIdpTypeBritive ? britiveIdpLoginUrl : url)
    } catch (error) {
      this.props.authError(error.message)
    }
  }

  resetChallenge = () => {
    this.setState({
      challenge:
        this.props.identityProvidersList.length <= 1
          ? AUTHENTICATION
          : VALIDATE_USER,
    })
  }

  render() {
    const { isAuthenticated, errorMessage, identityProvidersList } = this.props
    const { challenge } = this.state
    const fromCli = sessionStorage.getItem('cli')

    return (
      <>
        {!isAuthenticated || location?.pathname === '/login/sso' ? (
          <LoginRoutes
            challenge={challenge}
            resetChallenge={this.resetChallenge}
            onSubmit={this.onSubmit}
            identityProviders={identityProvidersList}
            errorMessage={errorMessage}
          />
        ) : (
          <>{!fromCli && <Redirect to="/" />}</>
        )}
      </>
    )
  }
}

Login.propTypes = {
  authenticate: PropTypes.func,
  getUser: PropTypes.func,
  fetching: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  location: PropTypes.object,
  match: PropTypes.object,
  errorMessage: PropTypes.string,
  getCurrentTenant: PropTypes.func,
  history: PropTypes.object,
  user: PropTypes.object,
  getUserPure: PropTypes.func,
  authError: PropTypes.func,
  statusCode: PropTypes.number,
}

const mapDispatchToProps = dispatch => {
  return {
    updateAuthenticationInfo: authResponse => {
      return dispatch(updateAuthenticationInfo(authResponse))
    },
    authenticateUser: params => {
      return dispatch(authenticateUser(params))
    },
    getUser: () => {
      dispatch(getUser())
    },
    getUserPure: response => {
      dispatch(getUserPure(response))
    },
    authError: message => {
      dispatch(authError(message))
    },
    setIsInitialAuthing: bool => {
      dispatch(setIsInitialAuthing(bool))
    },
    fetchIdentityProviders: bool => {
      dispatch(fetchIdentityProvidersList(bool))
    },
    fetchIdentityProviderType: () => {
      dispatch(getIdentityProviderType())
    },
  }
}

const mapStateToProps = state => {
  const { account, user, userAppPaps } = state

  return {
    fetching: account.fetching,
    isAuthenticated: getIsAuthenticated(),
    challengeParameters: account?.challengeParameters,
    challenge: account?.challengeParameters?.challenge,
    account: account,
    error: account.error,
    errorMessage: account.errorMessage,
    user,
    statusCode: userAppPaps?.error?.response?.status,
    identityProvidersList: account.identityProvidersList,
    identityProviderType: account.identityProviderType,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
