import React, { memo, useEffect, Fragment, useState } from 'react'
import capitalize from 'lodash/capitalize'
import ReactTooltip from 'react-tooltip'

import TableEllipsisCell from 'components/table/TableV2/TableEllipsisCell'
import startCase from 'lodash/startCase'
import Table from 'components/table/TableV2/Table'
import ScanDetailsDrawer from '../ScanDetailsDrawer'
import ManagedRole from 'static/images/britive.png'
import highRisk from 'static/images/high-risk.svg'
import { BackgroundTooltip as Tooltip } from 'components/Tooltips'

const styles = {
  changeCell: {
    display: 'flex',
    alignItems: 'center',
  },
  changeText: {
    overflow: 'hidden',
    flexGrow: 1,
    textOverflow: 'ellipsis',
  },
  changesIconWrapper: {
    padding: '0 15px',
    display: 'flex',
  },
  changesIcon: {
    cursor: 'pointer',
  },
  icon: {
    marginLeft: 4,
    marginRight: 4,
    width: 20,
    display: 'inline-block',
    height: 20,
    fontSize: 12,
    textAlign: 'center',
    color: '#9b3eec',
    fontStyle: 'normal',
    fontWeight: 600,
    cursor: 'pointer',
  },
}

function Permissions({ dropdownFilter, selectedAppId, entityId, selectedApp }) {
  const isHierarchical = selectedApp.catalogApplication.requiresHierarchicalModel
  const [scanDetailsDrawerOpened, setScanDetailsDrawerOpened] = useState(false)
  const [selectedPermission, setSelectedPermission] = useState(null)
  useEffect(() => {
    ReactTooltip.rebuild()
  }, [])

  const classNamePrefix = 'policy-list'

  const renderName = ({ original: permissionData }) => {
    const { name, privileged, source } = permissionData
    return (
      <Fragment>
        <TableEllipsisCell>
          <span>{name}</span>
        </TableEllipsisCell>
        {privileged && (
          <div
            data-for={`${name}-privileged`}
            data-tip="High Risk"
            style={styles.icon}
          >
            <img src={highRisk} style={{ height: '18px' }} />
          </div>
        )}
        {source === 'britive' && (
          <div
            data-for={`${name}-source`}
            data-tip="Britive Managed Permission"
            style={styles.icon}
          >
            <img src={ManagedRole} className={`${classNamePrefix}-name-high-risk`} />
          </div>
        )}
        <Tooltip
          effect="solid"
          place="left"
          getContent={content => content}
          id={`${name}-privileged`}
        />
        <Tooltip
          effect="solid"
          place="left"
          getContent={content => content}
          id={`${name}-source`}
        />
      </Fragment>
    )
  }

  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
      fixed: 'left',
      minWidth: 200,
      Cell: renderName,
    },
    {
      Header: 'Description',
      accessor: 'description',
      minWidth: 200,
    },
    {
      Header: 'Type',
      accessor: 'type',
      minWidth: 80,
      Cell: ({ value }) => startCase(value),
    },
    ...(isHierarchical
      ? [
          {
            Header: 'Scope',
            accessor: 'inheritedScope',
            minWidth: 100,
            sortable: false,
          },
        ]
      : []),
    {
      Header: 'Scan Status',
      accessor: 'scanStatus',
      minWidth: 100,
    },
    {
      id: 'changed_status',
      Header: 'Change Type',
      Cell: renderChangeType,
      sortable: false,
      accessor: ({
        scanStatus,
        hasMembershipsChanged,
        hasDefinitionsChanged,
        type,
      }) => ({
        scanStatus,
        hasMembershipsChanged,
        hasDefinitionsChanged,
        type,
      }),
      minWidth: 200,
    },
  ]

  function getChangeStatus({
    scanStatus,
    hasMembershipsChanged,
    hasDefinitionsChanged,
    type,
  }) {
    const formattedType = capitalize(type)
    let changeStatus

    if (
      (!hasMembershipsChanged && !hasDefinitionsChanged) ||
      scanStatus === 'New' ||
      scanStatus === 'Deleted'
    ) {
      changeStatus = scanStatus
    } else if (hasMembershipsChanged && hasDefinitionsChanged) {
      changeStatus = `${formattedType} Definition and Membership Changed`
    } else {
      if (hasMembershipsChanged) {
        changeStatus = `${formattedType} Membership Changed`
      }
      if (hasDefinitionsChanged) {
        changeStatus = `${formattedType} Definition Changed`
      }
    }
    return changeStatus
  }

  function renderChangeType({ original: data }) {
    const { hasMembershipsChanged, hasDefinitionsChanged } = data
    const iconRef = React.createRef()

    const changeStatus = getChangeStatus(data)

    return (
      <Fragment>
        <TableEllipsisCell style={styles.changeCell}>
          <span style={styles.changeText} title={changeStatus}>
            {changeStatus}
          </span>
        </TableEllipsisCell>

        {(hasMembershipsChanged || hasDefinitionsChanged) &&
          !(changeStatus === 'New' || changeStatus === 'Deleted') && (
            <span style={styles.changesIconWrapper}>
              <span
                onClick={() => {
                  setSelectedPermission(data)
                  setScanDetailsDrawerOpened(true)
                }}
                className="fa fa-exchange-alt fs:18"
                data-tip
                ref={iconRef}
                style={styles.changesIcon}
                onMouseEnter={() => ReactTooltip.show(iconRef.current)}
                onMouseLeave={() => ReactTooltip.hide(iconRef.current)}
              />
            </span>
          )}
      </Fragment>
    )
  }

  return (
    <Fragment>
      <Table
        columns={columns}
        identifier="appPermissionId"
        dataUrl={`/apps/${selectedAppId}/environments/${entityId}/permissions`}
        emptyTableMessage={'No Permissions were found.'}
        params={{ showDelta: true }}
        dropdownFilters={dropdownFilter}
        margin={40}
        checkBoxFilters={[
          {
            name: 'managed',
            value: true,
            displayText: 'Britive Managed',
          },
          {
            name: 'privileged',
            value: true,
            displayText: 'High Risk',
          },
        ]}
      />
      <ScanDetailsDrawer
        isOpen={selectedPermission && scanDetailsDrawerOpened}
        isHierarchical={isHierarchical}
        toggleDrawer={() => setScanDetailsDrawerOpened(!scanDetailsDrawerOpened)}
        data={selectedPermission}
        selectedAppId={selectedAppId}
        entityId={entityId}
        tabs={
          scanDetailsDrawerOpened
            ? [
                {
                  name: 'Groups',
                  dataUrl: `/apps/${selectedAppId}/environments/${entityId}/permissions/${selectedPermission.appPermissionId}/groups`,
                  nameIdentifier: 'permissionName',
                },
                {
                  name: 'Accounts',
                  dataUrl: `/apps/${selectedAppId}/environments/${entityId}/permissions/${selectedPermission.appPermissionId}/accounts`,
                  nameIdentifier: 'accountName',
                },
              ]
            : []
        }
      />
    </Fragment>
  )
}

export default memo(Permissions)
