import { preFetchV2 } from 'utils/do_fetch'

export function fetchReportData({
  appId,
  envId,
  reportId,
  page,
  pageSize,
  interval,
  filter,
  sort,
}) {
  let path = `/reports/${reportId}`
  const params = []

  if (filter) {
    params.push(`filter=${filter}`)
  }

  if (sort) {
    params.push(`${sort}`)
  }

  if (appId) {
    params.push(`appContainerId=${appId}`)
  }

  if (envId) {
    params.push(`environmentId=${envId}`)
  }

  if (interval !== undefined) {
    params.push(`intervalInDays=${interval}`)
  }

  if (page !== undefined) {
    params.push(`page=${page}`)
  }

  if (pageSize) {
    params.push(`size=${pageSize}`)
  }

  if (params.length) {
    path += `?${params.join('&')}`
  }

  return preFetchV2({
    name: 'fetchReportData',
    path,
    method: 'get',
  })
}

export function fetchReportDataForEnv({ appId, envId, reportId }) {
  let path = `/apps/${appId}/environments/${envId}/reports/${reportId}`

  return preFetchV2({
    name: 'fetchReportDataForEnv',
    path,
    method: 'get',
  })
}

export function fetchReportTypes(type) {
  return preFetchV2({
    name: 'fetchReports',
    path: `/reports?type=${type}`,
    method: 'get',
  })
}
