import get from 'lodash/get'

import { FETCH_SESSIONS, FETCH_SESSION_ACTIVITY } from 'action_creators/activity'

export const activityReducerKey = 'activity'

const initialState = {
  sessions: {},
  sessionActivity: {},
  isFetchingSessions: {},
  isFetchingActivities: {},
  activityMetaInfo: {},
  error: null,
}

export function activityReducer(state = initialState, action) {
  switch (action.type) {
    case `${FETCH_SESSIONS}_PENDING`: {
      const { appId } = action.meta

      const isFetchingSessions = {
        ...state.isFetchingSessions,
        [appId]: true,
      }

      return {
        ...state,
        isFetchingSessions,
      }
    }

    case `${FETCH_SESSIONS}_FULFILLED`: {
      const { appId } = action.meta

      const sessions = {
        ...state.sessions,
        [appId]: action.payload.data,
      }

      const isFetchingSessions = {
        ...state.isFetchingSessions,
        [appId]: false,
      }

      return {
        ...state,
        isFetchingSessions,
        sessions,
        error: null,
      }
    }

    case `${FETCH_SESSIONS}_REJECTED`: {
      const { appId } = action.meta

      const isFetchingSessions = {
        ...state.isFetchingSessions,
        [appId]: false,
      }

      return {
        ...state,
        isFetchingSessions,
        error: action.payload,
      }
    }

    case `${FETCH_SESSION_ACTIVITY}_PENDING`: {
      const { transactionId } = action.meta

      const isFetchingSessionActivity = {
        ...state.isFetchingSessionActivity,
        [transactionId]: true,
      }

      return {
        ...state,
        isFetchingSessionActivity,
      }
    }

    case `${FETCH_SESSION_ACTIVITY}_FULFILLED`: {
      const { page, transactionId, isInitialFetch } = action.meta

      const previousActivity = isInitialFetch
        ? []
        : state.sessionActivity[transactionId] || []

      const sessionActivity = {
        ...state.sessionActivity,
        [transactionId]: [...previousActivity, ...action.payload.data],
      }

      const isFetchingSessionActivity = {
        ...state.isFetchingSessionActivity,
        [transactionId]: false,
      }

      const nextPageFullURL = get(action.payload, 'headers.next-page', '')
      const nextPageURL = nextPageFullURL.split('/api')[1]

      const activityMetaInfo = {
        ...state.activityMetaInfo,
        [transactionId]: { nextPageURL, page },
      }

      return {
        ...state,
        isFetchingSessionActivity,
        sessionActivity,
        activityMetaInfo,
        error: null,
      }
    }

    case `${FETCH_SESSION_ACTIVITY}_REJECTED`: {
      const { transactionId } = action.meta

      const activityMetaInfo = {
        ...state.activityMetaInfo,
        [transactionId]: { nextPageURL: null, page: 0 },
      }

      const isFetchingSessionActivity = {
        ...state.isFetchingSessionActivity,
        [transactionId]: false,
      }

      return {
        ...state,
        isFetchingSessionActivity,
        activityMetaInfo,
        error: action.payload,
      }
    }

    default:
      return { ...state }
  }
}
