import { createSelector } from 'reselect'

import { getStateSliceFor } from './index'

import { activityReducerKey as reducerKey } from 'reducers/activity'

import { getSelectedApplicationRootId } from 'selectors/application_root'

/** Returns the state slice for activity. */
const extractFrom = state => getStateSliceFor({ state, reducerKey })

export const getSessions = state => extractFrom(state).sessions
export const getSessionActivity = state => extractFrom(state).sessionActivity
export const getIsFetchingSessions = state => extractFrom(state).isFetchingSessions
export const getIsFetchingSessionActivity = state =>
  extractFrom(state).isFetchingSessionActivity

export const getActivityMetaInfo = state => extractFrom(state).activityMetaInfo

export const getSelectedApplicationSessions = createSelector(
  state => getSelectedApplicationRootId(state),
  state => getSessions(state),
  (appId, sessions) => {
    if (appId && sessions) {
      return sessions[appId] || []
    }

    return null
  }
)

export const getSelectedSessionActivity = createSelector(
  ({ state }) => getSessionActivity(state),
  ({ transactionId }) => transactionId,
  (activity, transactionId) => {
    if (transactionId && activity) {
      return activity[transactionId] || []
    }

    return null
  }
)

export const getSessionFetchingStatus = createSelector(
  state => getSelectedApplicationRootId(state),
  state => getIsFetchingSessions(state),
  (appId, statuses) => {
    if (appId && statuses) {
      return statuses[appId] || false
    }

    return false
  }
)

export const getSessionActivityFetchingStatus = createSelector(
  ({ state }) => getIsFetchingSessionActivity(state),
  ({ transactionId }) => transactionId,
  (statuses, transactionId) => {
    if (transactionId && statuses) {
      return statuses[transactionId] || false
    }

    return false
  }
)

export const getSessionActivityMetaInfo = createSelector(
  ({ state }) => getActivityMetaInfo(state),
  ({ transactionId }) => transactionId,
  (metaInfo, transactionId) => {
    if (transactionId && metaInfo) {
      return metaInfo[transactionId] || {}
    }

    return {}
  }
)
