import React, { PureComponent, Fragment } from 'react'
import { connect } from 'react-redux'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import {
  getApplicationEnvironments,
  getSelectedApplicationEnvironmentGroups,
  getSelectedEnvironmentData,
  getSelectedEnvironmentGroupData,
} from 'selectors/environment'

import Button from 'britive-ui-components/core/components/Button'
import styled from 'styled-components'
import FormField from 'components/form_fields'
import {
  deleteEnvironment,
  deleteEnvironmentGroup,
  fetchEnvironmentGroups,
} from 'action_creators/environment'

const ModalWrapper = styled.div`
  > .modal-header {
    padding-top: 8px;
    padding-bottom: 8px;

    h5 {
      font-size: 24px;
    }
  }

  p {
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 0;
    overflow: hidden;
  }

  .sub-message {
    margin: 10px 0;

    .form-group {
      margin: 0;
    }
  }

  .warning-section {
    padding: 10px;
    background: #fffeee;
    border: 1px solid #fed52a;
    border-radius: 3px;
  }

  .warning {
    color: #fed52a;
  }
  .error {
    color: var(--error);
  }
`

class EnvironmentDeleteModalWrapper extends PureComponent {
  state = {
    entityName: '',
    confirm: false,
    error: '',
    deleted: false,
  }

  validateConfirm = event => {
    this.setState({
      confirm: event.target.value.toLowerCase() === 'confirm',
    })
  }

  onDeleteClick = async () => {
    this.setState({
      error: '',
    })

    const { dispatch, entityType, appId, selectedEntityId } = this.props

    const response = await (entityType === 'environment'
      ? dispatch(deleteEnvironment({ appId, envId: selectedEntityId }))
      : dispatch(deleteEnvironmentGroup({ appId, envGroupId: selectedEntityId })))

    if (response.value.status === 200) {
      this.setState({ deleted: true })
    } else {
      this.setState({
        error: 'Could not delete. Please try again later.',
      })
    }
  }

  onOkClick = async () => {
    const {
      dispatch,
      toggleModal,
      appId,
      selectEntity,
      entityType,
      selectedEntityId,
      applicationEnvironments,
      selectedApplicationEnvironmentGroups,
    } = this.props
    const entityParentId =
      entityType === 'environment'
        ? applicationEnvironments[selectedEntityId].parentGroupId
        : selectedApplicationEnvironmentGroups[selectedEntityId].parentId

    selectEntity({ entityId: entityParentId })
    toggleModal()
    this.setState({
      entityName: '',
      confirm: false,
      error: '',
      deleted: false,
    })
    await dispatch(fetchEnvironmentGroups(appId))
  }

  render() {
    const {
      isModalOpen,
      entityType,
      environmentData,
      environmentGroupData,
      toggleModal,
      deletingEnvironment,
    } = this.props

    const { confirm, error, deleted } = this.state
    if (!isModalOpen) {
      return null
    }

    return (
      <Modal isOpen={isModalOpen} size="lg">
        <ModalWrapper>
          <ModalHeader>
            Delete Environment{' '}
            {entityType === 'environment'
              ? `${environmentData.catalogAppDisplayName}`
              : `Group ${environmentGroupData.name}`}{' '}
          </ModalHeader>
          <ModalBody>
            <p className="sub-message">
              {entityType === 'environment' ? (
                <span>
                  Environment{' '}
                  <span className="font-weight-bold">
                    {environmentData.catalogAppDisplayName}
                  </span>
                </span>
              ) : (
                <span>
                  Environment group{' '}
                  <span className="font-weight-bold">
                    {environmentGroupData.name}
                  </span>
                </span>
              )}{' '}
              {deleted
                ? 'has been deleted'
                : `
              will be deleted. All data associated to this environment${
                entityType === 'environment' ? '' : ' group'
              } will also be deleted.`}
            </p>
            {!deleted && (
              <Fragment>
                <p className="sub-message warning-section">
                  <i className="fas fa-exclamation-triangle warning" /> This action
                  is irreversible.
                </p>
                <p className="sub-message">
                  <FormField
                    id="confirm"
                    name="confirm"
                    hideLabel={false}
                    label="Type 'confirm' to confirm"
                    placeholder="Type 'confirm' to confirm"
                    onChange={this.validateConfirm}
                  />
                </p>
              </Fragment>
            )}
            {error !== '' && <p className="sub-message error">{error}</p>}
          </ModalBody>
          <ModalFooter>
            {deleted ? (
              <Button onClick={this.onOkClick}>Ok</Button>
            ) : (
              <Fragment>
                <Button onClick={toggleModal}>Cancel</Button>

                <Button
                  color="primary"
                  disabled={!confirm || deletingEnvironment}
                  onClick={this.onDeleteClick}
                  spinner={deletingEnvironment}
                >
                  Confirm
                </Button>
              </Fragment>
            )}
          </ModalFooter>
        </ModalWrapper>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  environmentData: getSelectedEnvironmentData(state),
  environmentGroupData: getSelectedEnvironmentGroupData(state),
  deletingEnvironment: state.environment.deletingEnvironment,
  applicationEnvironments: getApplicationEnvironments(state),
  selectedApplicationEnvironmentGroups: getSelectedApplicationEnvironmentGroups(
    state
  ),
})

export default connect(mapStateToProps)(EnvironmentDeleteModalWrapper)
