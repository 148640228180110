import React, {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import get from 'lodash/get'

import Spinner from 'britive-ui-components/core/components/Spinner'
import { Tabs } from 'britive-ui-components/core/components/Tabs'

import PropertiesApplicationForm from './PropertiesApplicationForm'
import SettingsForm from '../SettingsForm'

import { getNodeFullHeight } from 'utils/elementMeasurement'

import useMaxTableHeight from 'hooks/useMaxTableHeight'

import * as text from 'translations/english_us'

import { APP_PROPERTIES_TABS_ID } from '../../constants'
import Ingestion from './Ingestion'

const Wrapper = styled.div`
  .tab-content {
    padding: 0 !important;
    margin: -8px 0 0 0 !important;
  }
`

function AdminTenantPropertiesForm(props) {
  const [activeTab, setActiveTab] = useState('Application')
  const tabsRef = useRef()

  const maxHeight = useMaxTableHeight({
    heights: [
      // TODO: shortcut magic numbers here
      props.appHeaderHeight,
      63, // tabs
    ],
    minHeight: 100,
  })

  const measuredRef = useCallback(node => {
    if (props.setElementRef && node !== null) {
      // I don't love this, but...
      const tabsNode = node.children[0].children[0]
      const height = getNodeFullHeight({ node: tabsNode })
      props.setElementRef({ name: APP_PROPERTIES_TABS_ID, height })
    }
  }, [])

  useEffect(() => {
    const {
      app,
      createNewMode,
      isEnvironment,
      isEnvironmentGroup,
      location,
      history,
    } = props

    const isHierarchical = get(app, 'requiresHierarchicalModel')

    if (get(location, 'state.tab')) {
      setActiveTab('Settings')
      history.replace(location.pathname, {})
      return
    }

    if (isEnvironment) {
      setActiveTab('Environment')
      return
    }

    if (isEnvironmentGroup && !isHierarchical) {
      setActiveTab('Environment Group')
      return
    }

    !isHierarchical && !createNewMode && setActiveTab('Application')
  }, [props.selectedEntityId])

  const selectTab = activeTab => {
    setActiveTab(activeTab)
  }

  const renderSectionTabs = () => {
    const {
      app,
      appRoot,
      createNewMode,
      dropdownOpen,
      fields,
      isApplicationRoot,
      isEnvironment,
      propertyTypes,
      selectedApplicationRootGroupId,
      selectedEnvironmentId,
      selectedEnvironmentGroupId,
      selectedEntityId,
      scanInProgress,
      scanPassed,
      saveBtnAction,
      saving,
      selectedApplicationRoot,
      testInProgress,
      testPassed,
      adaEnabled,

      // actions
      createApp,
      handleChange,
      onCancel,
      onClickMoveNextTab,
      saveUpdates,
      scanApplicationRoot,
      toggle,
      updateSaveBtnAction,
      updateFormMode,
      match,
      thisAppManage,
    } = props

    const { uiSettings = {} } = app
    const { sections = [], subPropertiesMap } = uiSettings

    const tabs = sections.map((section, idx) => {
      const { displayName } = section

      // TODO: Will need to update this when
      // ui settings from BE are updated
      if (displayName === 'Settings' || displayName === 'Environments') {
        const updatedDisplayName = 'Settings'
        return (
          <div
            id={`propertiesForm${displayName}`}
            label={updatedDisplayName}
            key={updatedDisplayName}
          >
            <SettingsForm
              catalogApp={app}
              activeTab={activeTab}
              createApp={createApp}
              createNewMode={createNewMode}
              dropdownOpen={dropdownOpen}
              fields={fields}
              handleChange={handleChange}
              isApplicationRoot={isApplicationRoot}
              key={idx}
              onCancel={onCancel}
              maxHeight={maxHeight}
              propertyTypes={propertyTypes}
              selectedEntityId={selectedEntityId}
              saveBtnAction={saveBtnAction}
              saveUpdates={saveUpdates}
              saving={saving}
              scanInProgress={scanInProgress}
              testInProgress={testInProgress}
              scanPassed={scanPassed}
              section={section}
              subPropertiesMap={subPropertiesMap}
              selectedApplicationRoot={selectedApplicationRoot}
              scanApplicationRoot={scanApplicationRoot}
              selectedApplicationRootGroupId={selectedApplicationRootGroupId}
              selectedEnvironmentId={selectedEnvironmentId}
              selectedEnvironmentGroupId={selectedEnvironmentGroupId}
              testPassed={testPassed}
              toggle={toggle}
              updateFormMode={updateFormMode}
              updateSaveBtnAction={updateSaveBtnAction}
              thisAppManage={thisAppManage}
            />
          </div>
        )
      }

      if (
        displayName === 'Application' ||
        displayName === 'Environment' ||
        displayName === 'Environment Group'
      ) {
        return (
          <div
            label={displayName}
            key={displayName}
            id={`propertiesForm${displayName}`}
          >
            <PropertiesApplicationForm
              app={app}
              appRoot={appRoot}
              isApplicationRoot={isApplicationRoot}
              createNewMode={createNewMode}
              fields={fields}
              handleChange={handleChange}
              isEnvironment={isEnvironment}
              key={idx}
              onCancel={onCancel}
              onClickMoveNextTab={onClickMoveNextTab}
              maxHeight={maxHeight}
              propertyTypes={propertyTypes}
              saveUpdates={saveUpdates}
              selectedEntityId={selectedEntityId}
              selectTab={selectTab}
              saving={saving}
              section={section}
              subPropertiesMap={subPropertiesMap}
              tabsRef={tabsRef.current}
              thisAppManage={thisAppManage}
            />
          </div>
        )
      }
      return null
    })

    adaEnabled &&
      isApplicationRoot &&
      (app.name === 'AWS' || app.name === 'AWS Standalone') &&
      tabs.push(
        <div id="propertiesFormIngestion" label="Ingestion">
          <Ingestion
            appId={match.params.appId}
            environments={
              appRoot &&
              appRoot.rootEnvironmentGroup &&
              appRoot.rootEnvironmentGroup.environments
                ? appRoot.rootEnvironmentGroup.environments
                : []
            }
            thisAppManage={thisAppManage}
          />
        </div>
      )
    return tabs
  }

  // const { fetching, app, selectedEntityId, createNewMode } = props
  const { fetching, app } = props
  const { uiSettings = {} } = app
  const { sections = [] } = uiSettings

  if (fetching && !sections.length) {
    return (
      <Fragment>
        <Spinner size="2x" /> {text.LOADING_PROPERTIES}
      </Fragment>
    )
  }

  const tabsArray = renderSectionTabs()

  return (
    <Wrapper ref={measuredRef}>
      <Tabs activeTab={activeTab} onSelectTab={selectTab} ref={tabsRef}>
        {tabsArray}
      </Tabs>
    </Wrapper>
  )
}

AdminTenantPropertiesForm.propTypes = {
  app: PropTypes.object,
  createNewMode: PropTypes.bool,
  createApp: PropTypes.func,
  dropdownOpen: PropTypes.bool,
  fields: PropTypes.object,
  handleChange: PropTypes.func,
  isApplicationRoot: PropTypes.bool,
  onCancel: PropTypes.func,
  onClickMoveNextTab: PropTypes.func,
  propertyTypes: PropTypes.object,
  saveBtnAction: PropTypes.string,
  saveUpdates: PropTypes.func,
  saving: PropTypes.bool,
  scanInProgress: PropTypes.bool,
  selectedApplicationRoot: PropTypes.object,
  scanApplicationRoot: PropTypes.func,
  scanPassed: PropTypes.bool,
  testPassed: PropTypes.bool,
  testInProgress: PropTypes.bool,
  toggle: PropTypes.func,
  updateFormMode: PropTypes.func,
  updateSaveBtnAction: PropTypes.func,
  adaEnabled: PropTypes.bool,
}

export default memo(AdminTenantPropertiesForm)
