import React, { memo } from 'react'

import EnvironmentGroups from 'components/EnvironmentGroups'
import ScopeCheckbox from './ScopeCheckbox'

const ScopeSelect = ({
  scope,
  onChange,
  disabled,
  label,
  historicalScope,
  defaultValues,
  visualCuesData,
  visualCueDataType,
  refreshApplication,
  isRefreshing,
}) => {
  const updateScope = entity => {
    const newScope = { ...scope }
    if (newScope[entity.entityId]) {
      delete newScope[entity.entityId]
    } else {
      const formattedEntity = {
        type: entity.entityType,
        value: entity.entityId,
      }
      newScope[entity.entityId] = formattedEntity
    }

    onChange(newScope)
  }
  const renderCheckbox = (entity, parentSelected, isDisabled) => {
    return (
      <ScopeCheckbox
        isDisabled={isDisabled || disabled}
        updateScope={updateScope}
        papScope={scope}
        parentSelected={parentSelected}
        entity={entity}
        historicalScope={historicalScope}
        defaultValues={defaultValues}
      />
    )
  }

  return (
    <EnvironmentGroups
      checkmarkDict={scope}
      label={label}
      isSelectable
      customRenderers={{
        rootGroupCheckbox: renderCheckbox,
        environmentCheckbox: renderCheckbox,
        groupCheckbox: renderCheckbox,
      }}
      width="fit-content"
      minWidth="100%"
      minHeight="100%"
      maxHeight="320px"
      isSearchable
      visualCuesData={visualCuesData}
      visualCueDataType={visualCueDataType}
      refreshApplication={refreshApplication}
      isRefreshing={isRefreshing}
    />
  )
}

export default memo(ScopeSelect)
