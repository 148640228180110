import React, { memo, useEffect, useState } from 'react'

const styles = {
  checkbox: {
    cursor: 'pointer',
    marginRight: 4,
  },
  disabled: {
    marginRight: 4,
  },
  saveButton: {
    marginLeft: 8,
  },
}

const ScopeCheckbox = ({
  papScope,
  parentSelected,
  entity,
  updateScope,
  isDisabled,
  historicalScope,
  defaultValues,
}) => {
  const [historicDisabled, setHistoricDisabled] = useState(false)
  useEffect(() => {
    if (parentSelected && !!papScope[entity.entityId]) {
      updateScope(entity)
    }
  }, [parentSelected])

  useEffect(() => {
    if (historicalScope) {
      setHistoricDisabled(!!historicalScope[entity.entityId])
    } else if (!historicalScope) {
      setHistoricDisabled(defaultValues && defaultValues[entity.entityId])
    }
  }, [historicalScope])

  return (
    <input
      disabled={parentSelected || isDisabled || historicDisabled}
      onChange={() => updateScope(entity)}
      checked={
        !!((papScope && entity && papScope[entity.entityId]) || parentSelected)
      }
      key={new Date()}
      type="checkbox"
      style={isDisabled || parentSelected ? styles.disabled : styles.checkbox}
    />
  )
}

export default memo(ScopeCheckbox)
