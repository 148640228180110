import get from 'lodash/get'
import * as actions from 'actions'

const initialState = {
  loading: false,
  error: null,
  data: {},
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case `${actions.GET_RESPONSE_TEMPLATE}_PENDING`: {
      return {
        ...state,
        loading: true,
        error: null,
      }
    }

    case `${actions.GET_RESPONSE_TEMPLATE}_FULFILLED`: {
      const response = get(action, 'payload.data', {})

      return {
        loading: false,
        error: null,
        data: response,
      }
    }

    case `${actions.GET_RESPONSE_TEMPLATE}_REJECTED`: {
      return {
        ...state,
        loading: false,
        error: 'Unable to fetch the response template.',
      }
    }

    default:
      return { ...state }
  }
}
