import React from 'react'
import ModalPopup from 'britive-design-system/core/components/modal-popup'
import TableEllipsisCell from 'components/table/TableV2/TableEllipsisCell'
import TableSection from 'components/table/tableSectionV2'

const EnvironmentList = ({ onClose, environmentList }) => {
  return (
    <ModalPopup
      width={900}
      title={'Environments'}
      buttons={[
        {
          text: 'Close',
          variant: 'secondary',
          onClick: onClose,
          size: 'large',
        },
      ]}
      onCancel={onClose}
    >
      <TableSection
        resizable={true}
        sortable
        data={environmentList}
        columns={[
          {
            Header: 'Name',
            accessor: 'catalogAppDisplayName',
            Cell: ({ value }) => <TableEllipsisCell value={value} />,
          },
          {
            Header: 'Description',
            accessor: 'catalogApplication.description',
            Cell: ({ value }) => <TableEllipsisCell value={value} />,
          },
        ]}
        defaultPageSize={10}
        emptyTableMessage="No Environments present."
      />
    </ModalPopup>
  )
}

export default EnvironmentList
