import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import DataTable from 'secret-manager-ui/core/components/data-table/DataTable'
import ModalPopup from 'britive-design-system/core/components/modal-popup'
import Button from 'britive-design-system/core/components/button'
import Spinner from 'britive-design-system/core/components/spinner'
import { BiPlusCircle } from 'react-icons/bi'
import { debounce, isEmpty } from 'lodash'
import infoIcon from 'static/images/infoIcon.svg'
import toast from 'utils/toast'
import capitalize from 'lodash/capitalize'
import Tooltip from 'britive-design-system/core/components/tooltip'
import arrowLeft from 'static/images/arrow-left.svg'
import highRisk from 'static/images/high-risk.svg'
import { PermissionDefinition } from '../../Profiles/components/permission-drawer/PermissionDefinition'
import { getExistingPolicies } from 'action_creators/managed_role'
import getTotalPageCount from 'utils/getTotalPageCount'

const styles = {
  actionIcons: {
    display: 'flex',
    gap: '5px',
    cursor: 'pointer',
    alignItems: 'center',
  },
  loadMore: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
  },
}

const ExistingPoliciesList = ({ addPolicy, selectedPolicies }) => {
  const dispatch = useDispatch()
  const { appId } = useParams()
  const [isPolicyDetailModalOpen, setIsPolicyDetailsModalOpen] = useState({
    isOpen: false,
    permissionDefinition: '',
    name: '',
    privileged: false,
    type: 'Policy',
    description: '',
  })

  const { fetchingExistingPolicies, existingPolicies = {} } = useSelector(
    state => state.managedRoleReducer
  )

  const fetchData = params => {
    dispatch(getExistingPolicies(appId, params, existingPolicies?.searchText))
  }

  useEffect(() => {
    if (isEmpty(existingPolicies?.data) || existingPolicies?.searchText) {
      fetchData({
        page: 0,
        size: 100,
      })
    }
  }, [])

  const handleLoadMore = () => {
    fetchData({
      page: existingPolicies?.page + 1,
      size: 100,
    })
  }

  const handleSearch = value => {
    fetchData({
      page: 0,
      size: 100,
      searchText: value?.length ? value : '',
    })
  }

  const renderHighRiskIcon = () => (
    <div>
      <Tooltip title="High Risk" position="left">
        <span title="">
          <img src={highRisk} style={{ height: '18px' }} />
        </span>
      </Tooltip>
    </div>
  )

  const columns = [
    {
      headerName: 'Policy Name',
      field: 'name',
      sortable: true,
      width: 100,
      renderColumn: row => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {row?.name}
            &nbsp;
            {row?.privileged && renderHighRiskIcon()}
          </div>
        )
      },
    },
    {
      headerName: 'Type',
      field: 'type',
      sortable: true,
      width: 30,
      renderColumn: row => capitalize(row.type),
    },
    {
      headerName: 'Description',
      field: 'description',
      sortable: true,
      width: 40,
    },
    {
      headerName: 'Action',
      width: 30,
      renderColumn: row => {
        return (
          <div style={styles.actionIcons}>
            <BiPlusCircle
              size="22"
              data-testid={`add-${row.name}`}
              onClick={() => {
                addPolicy([
                  ...selectedPolicies,
                  {
                    name: row.name,
                    type: row.type,
                    privileged: row.privileged,
                    permissionDefinition: row.permissionDefinition,
                    description: row?.description,
                  },
                ])
                toast({
                  title: 'Existing policy added successfully.',
                  type: 'success',
                  time: 'normal',
                })
              }}
            />
            <div>
              <Tooltip title="View Details" position="left">
                <span
                  onClick={() =>
                    setIsPolicyDetailsModalOpen({
                      isOpen: true,
                      permissionDefinition: row?.permissionDefinition,
                      name: row?.name,
                      privileged: row?.privileged,
                      type: 'Policy',
                      description: row?.description,
                    })
                  }
                  data-testid={`info-${row.name}`}
                >
                  <img src={infoIcon} style={{ height: '21px' }} />
                </span>
              </Tooltip>
            </div>
          </div>
        )
      },
    },
  ]

  const closeModal = () => {
    // resetting everything to initial state
    setIsPolicyDetailsModalOpen({
      isOpen: false,
      permissionDefinition: '',
      name: '',
      privileged: false,
      description: '',
    })
  }

  const modalButtons = [
    {
      text: <img src={arrowLeft} style={{ height: '18px' }} />,
      variant: 'secondary',
      onClick: closeModal,
      size: 'medium',
    },
    {
      text: 'Close',
      variant: 'secondary',
      onClick: closeModal,
      size: 'medium',
    },
  ]
  return (
    <>
      {fetchingExistingPolicies && (
        <Spinner size={'medium'} message={'Loading...'} overlay />
      )}
      <DataTable
        rows={existingPolicies?.data?.filter(
          ({ name }) => !selectedPolicies?.some(e => e.name === name)
        )}
        columns={columns}
        resizableColumns={true}
        inLineSort={true}
        searchBar={{
          onSearch: debounce(handleSearch, 300),
          placeholder: 'Search',
        }}
      />
      {!fetchingExistingPolicies &&
        !isEmpty(existingPolicies?.data) &&
        existingPolicies?.page <
          getTotalPageCount(existingPolicies?.count, existingPolicies?.size) && (
          <div style={styles.loadMore}>
            <Button variant="textOnly" size="medium" onClick={handleLoadMore}>
              Load More
            </Button>
          </div>
        )}
      {isPolicyDetailModalOpen.isOpen && (
        <ModalPopup
          width={790}
          title={`Policy: ${isPolicyDetailModalOpen.name}`}
          onCancel={closeModal}
          buttons={modalButtons}
        >
          <PermissionDefinition permission={isPolicyDetailModalOpen} />
        </ModalPopup>
      )}
    </>
  )
}

export default ExistingPoliciesList
