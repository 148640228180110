import React, { useState } from 'react'
import ModalPopup from 'britive-design-system/core/components/modal-popup'
import { ApproversGroupDetails } from '../AssignAssociationApprovers/components/ApproversGroupDetails'
import TagMembersModal from 'components/MembersSelector/TagMembersModal'

const ApproversGroupDetailsModal = ({ onClose, approversGroup }) => {
  const [selectedTag, setSelectedTag] = useState(null)
  const buttons = [
    {
      text: 'Close',
      variant: 'secondary',
      onClick: onClose,
      size: 'medium',
    },
  ]
  return (
    <ModalPopup
      width={720}
      title={`${approversGroup.name}`}
      buttons={buttons}
      onCancel={onClose}
    >
      <ApproversGroupDetails
        data={approversGroup}
        onlyMembers
        onViewMembers={setSelectedTag}
      />
      {selectedTag && (
        <TagMembersModal tag={selectedTag} onClose={() => setSelectedTag(null)} />
      )}
    </ModalPopup>
  )
}

export default ApproversGroupDetailsModal
