import React from 'react'
import styled from 'styled-components'

import Table from 'components/table/TableV2/TableView'
import { NoBackgroundTooltip as Tooltip } from 'components/Tooltips'
import TableActionIcon from 'components/TableActionIcon'
import FieldWrapper from '../FieldWrapper'

const TableWrapper = styled(FieldWrapper)`
  width: 100%;

  > div {
    width: 100%;
  }
`

const DeleteActionWrapper = styled(TableActionIcon)`
  padding: 0 15px !important;

  > .action-icon-label {
    bottom: -14px !important;
  }
`

const Rule = styled.div`
  overflow: hidden;
  white-space: normal;
`

function UserAccountRules(props) {
  function getColumns() {
    const { editable } = props

    const columns = [
      {
        Header: 'Message',
        Cell: renderMessage,
      },
    ]

    if (editable) {
      columns.push({
        Header: 'Actions',
        Cell: renderAction,
        width: 50,
      })
    }

    return columns
  }

  function renderMessage({ original: data }) {
    const name = `${data.firstName} ${data.lastName}`
    const message = `User "${name}" maps to account "${data.accountUsername}"`

    return <Rule>{message}</Rule>
  }

  function renderAction({ original: data }) {
    const id = data.userAccountLinkId

    return (
      <div>
        <DeleteActionWrapper onClick={() => props.onClick(id)}>
          <span data-for={id} data-tip className="fa fa-trash fs:14" />

          <Tooltip place="bottom" effect="solid" marginY={'0'} id={id}>
            Delete
          </Tooltip>
        </DeleteActionWrapper>
      </div>
    )
  }

  const { editable } = props

  const data = Object.values(props.value)

  return (
    <TableWrapper>
      <Table
        columns={getColumns()}
        data={data}
        disableHover={!editable || data.length === 0}
        hideHeader
        maxHeight={160}
        emptyTableMessage="No account mapping rules for users were found"
      />
    </TableWrapper>
  )
}

export default UserAccountRules
