import {
  GET_EXISTING_POLICIES,
  CREATE_ROLE,
  GET_EXISTING_POLICIES_LOAD_MORE,
  PERMISSIONS_DETAILS,
} from 'action_creators/managed_role'

const initialState = {
  existingPolicies: [],
  error: null,
  fetchingExistingPolicies: false,
  creatingRole: false,
  creatingRoleError: null,
  fetchingPermissionsDetails: false,
  permissionsDetails: {},
}

export default function managedRoleReducer(state = initialState, action) {
  switch (action.type) {
    case `${GET_EXISTING_POLICIES}_PENDING`: {
      return {
        ...state,
        fetchingExistingPolicies: true,
        error: null,
      }
    }
    case `${GET_EXISTING_POLICIES}_FULFILLED`: {
      const { data, count, page, size } = action.payload.data
      return {
        ...state,
        fetchingExistingPolicies: false,
        error: null,
        existingPolicies: {
          data,
          count,
          page,
          size,
          searchText: action?.meta?.searchText,
        },
      }
    }
    case `${GET_EXISTING_POLICIES}_REJECTED`: {
      return {
        ...state,
        fetchingExistingPolicies: false,
        error: action.payload,
      }
    }

    case `${GET_EXISTING_POLICIES_LOAD_MORE}_PENDING`: {
      return {
        ...state,
        fetchingExistingPolicies: true,
        error: null,
      }
    }
    case `${GET_EXISTING_POLICIES_LOAD_MORE}_FULFILLED`: {
      const { data, count, page, size } = action.payload.data
      return {
        ...state,
        fetchingExistingPolicies: false,
        error: null,
        existingPolicies: {
          data: [...state.existingPolicies.data, ...data],
          count,
          page,
          size,
          searchText: action?.meta?.searchText,
        },
      }
    }
    case `${GET_EXISTING_POLICIES_LOAD_MORE}_REJECTED`: {
      return {
        ...state,
        fetchingExistingPolicies: false,
        error: action.payload,
      }
    }

    case `${CREATE_ROLE}_PENDING`: {
      return {
        ...state,
        creatingRole: true,
        error: null,
      }
    }
    case `${CREATE_ROLE}_FULFILLED`: {
      return {
        ...state,
        creatingRole: false,
        error: null,
      }
    }
    case `${CREATE_ROLE}_REJECTED`: {
      return {
        ...state,
        creatingRole: false,
        error: action.payload,
      }
    }

    case `${PERMISSIONS_DETAILS}_PENDING`: {
      return {
        ...state,
        fetchingPermissionsDetails: true,
        error: null,
      }
    }
    case `${PERMISSIONS_DETAILS}_FULFILLED`: {
      return {
        ...state,
        fetchingPermissionsDetails: false,
        permissionsDetails: action.payload.data,
        error: null,
      }
    }
    case `${PERMISSIONS_DETAILS}_REJECTED`: {
      return {
        ...state,
        fetchingPermissionsDetails: false,
        error: action.payload,
      }
    }

    default:
      return { ...state }
  }
}
