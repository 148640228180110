import React, { useState } from 'react'
import Table from 'britive-design-system/core/components/table'
import Spinner from 'britive-design-system/core/components/spinner'
import { filterTableData } from '../utils'

export default ({ columns, tableData, isLoading, searchable = true, onSearch }) => {
  const [searchQuery, setSearchQuery] = useState(null)
  const [sortData, setSortData] = useState({
    order: 'unsorted',
    by: null,
  })

  const handleSorting = (sortOrder, sortBy) => {
    setSortData({
      order: sortOrder,
      by: sortBy,
    })
  }

  return (
    <div>
      {isLoading && <Spinner size="medium" message={''} overlay />}
      <Table
        columns={columns}
        rows={filterTableData(tableData, sortData, searchQuery)}
        resizableColumns
        sortHandler={handleSorting}
        searchBar={
          searchable
            ? {
                onSearch: value => {
                  if (typeof onSearch === 'function') {
                    onSearch(value)
                  } else {
                    setSearchQuery(!value ? null : value)
                  }
                },
                placeholder: 'Search',
              }
            : null
        }
      />
    </div>
  )
}
