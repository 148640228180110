import React, { Fragment, memo, useState } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import {
  ActionIconLabel,
  ActionIconGroup,
} from 'britive-ui-components/core/components/ActionIcon'
import TableActionIcon from 'components/TableActionIcon'
import TableEllipsisCell from 'components/table/TableV2/TableEllipsisCell'
import { BackgroundTooltip as Tooltip } from 'components/Tooltips'
import Spinner from 'britive-ui-components/core/components/Spinner'
import Table from 'components/table/TableV2/Table'
import startCase from 'lodash/startCase'
import toast from 'utils/toast'
import ManagedRole from 'static/images/britive.png'

const styles = {
  icon: {
    marginLeft: 4,
    marginRight: 4,
    width: 20,
    display: 'inline-block',
    height: 20,
    fontSize: 12,
    textAlign: 'center',
    color: '#9b3eec',
    fontStyle: 'normal',
    fontWeight: 600,
    cursor: 'pointer',
  },
}
function AddPermissionTable(props) {
  const [isAddingPermission, setIsAddingPermission] = useState(false)
  const [refresh, setRefresh] = useState(0)
  const filterData = [
    {
      name: 'privileged',
      value: true,
      displayText: 'High Risk',
    },
  ]
  const renderActionIcons = permission => {
    const addPermissionsToTags = async () => {
      await addPermissions(permission)
      setRefresh(refresh + 1)
      toast({
        title: `Successfully added permission to profile`,
        type: 'success',
        time: 'normal',
      })
    }
    return (
      <ActionIconGroup>
        <TableActionIcon onClick={addPermissionsToTags}>
          {permission.name === isAddingPermission ? (
            <Spinner />
          ) : (
            <span className="fa fa-plus-circle fs:18" />
          )}
          <ActionIconLabel>Add</ActionIconLabel>
        </TableActionIcon>
        {props.renderPermissionDrawerIcon(permission)}
      </ActionIconGroup>
    )
  }

  const addPermissions = async permission => {
    const { addPermission } = props

    if (isAddingPermission) {
      return
    }

    setIsAddingPermission(permission.name)

    await addPermission(permission)

    setIsAddingPermission(false)
  }

  const classNamePrefix = 'policy-list'

  const renderName = ({ original: permissionData }) => {
    const { name, privileged, source } = permissionData
    return (
      <Fragment>
        <TableEllipsisCell>
          <span>{name}</span>
        </TableEllipsisCell>
        {privileged && (
          <span>
            <div
              data-for={`${name}-privileged`}
              data-tip="High Risk"
              style={styles.icon}
            >
              <i className="fas fa-bolt" />
            </div>
          </span>
        )}
        {source === 'britive' && (
          <div
            data-for={`${name}-source`}
            data-tip="Britive Managed Permission"
            style={styles.icon}
          >
            <img src={ManagedRole} className={`${classNamePrefix}-name-high-risk`} />
          </div>
        )}
        <Tooltip
          effect="solid"
          place={'left'}
          getContent={content => content}
          id={`${name}-privileged`}
        />
        <Tooltip
          effect="solid"
          place={'left'}
          getContent={content => content}
          id={`${name}-source`}
        />
      </Fragment>
    )
  }

  return (
    <Table
      columns={[
        { Header: 'Name', accessor: 'name', Cell: renderName },
        { Header: 'Type', accessor: 'type', Cell: ({ value }) => startCase(value) },
        { Header: 'Description', accessor: 'description' },
        {
          Header: 'Actions',
          accessor: 'actions',
          resizable: false,
          Cell: data => renderActionIcons(data.original),
          sortable: false,
        },
      ]}
      identifier="name"
      dataUrl={
        props.requestAccess
          ? `/profile-requests/paps/${props.profileId}/permissions`
          : `/paps/${props.profileId}/permissions`
      }
      params={{ query: 'available' }}
      emptyTableMessage={'No available permissions were found'}
      checkBoxFilters={
        props.isAws
          ? [
              {
                name: 'managed',
                value: true,
                displayText: 'Britive Managed',
              },
              ...filterData,
            ]
          : [...filterData]
      }
      dropdownFilters={[
        {
          label: 'Type',
          value: 'type',
          options: props.permissionTypes,
        },
      ]}
      refresh={refresh}
      noUrlQueryParams
    />
  )
}

AddPermissionTable.propTypes = {
  availablePermissions: PropTypes.array,
  match: PropTypes.object,
  updatePermissions: PropTypes.func,
  updateConstraints: PropTypes.func,
  showConstraintsModal: PropTypes.func,
}

export default withRouter(memo(AddPermissionTable))
