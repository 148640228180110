import React, { Fragment, memo, useMemo } from 'react'
import styled from 'styled-components'
import numeral from 'numeral'
import moment from 'moment-timezone'
import get from 'lodash/get'
import pluralize from 'pluralize'
import ReactTooltip from 'react-tooltip'
import {
  ActionIconLabel,
  ActionIconGroup,
} from 'britive-ui-components/core/components/ActionIcon'
import Button from 'britive-ui-components/core/components/Button'
import Spinner from 'britive-ui-components/core/components/Spinner'

import TableActionIcon from 'components/TableActionIcon'
import Table from 'components/table/TableV2/TableView'
import { TableEllipsisCellWithTooltip as TableEllipsisCell } from 'components/table/TableV2/TableEllipsisCell'

import { convertUTCDataToLocalData } from './utils'

import { HOURLY, DAILY, WEEKLY, MONTHLY } from './ScanDrawer'

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 22.5px;
  position: relative;
  width: 100%;

  .createScanButton {
    position: absolute;
    align-self: flex-end;
    margin-bottom: 12px;
    width: 200px;
    top: -38px;
  }

  > div {
    width: 100%;
  }
`

function ScheduledScans(props) {
  const columns = useMemo(() => {
    const { editable } = props

    const columns = [
      {
        Header: 'Name',
        accessor: 'name',
        minWidth: 100,
        Cell: ({ original: { name } }) => <TableEllipsisCell value={name} />,
      },
      {
        Header: 'Scan Description',
        accessor: 'sortData.frequencyType',
        minWidth: 300,
        Cell: renderDescription,
      },
      {
        accessor: 'sortData.frequencyInterval',
        show: false,
      },
      {
        accessor: 'sortData.startTime',
        show: false,
      },
    ]

    if (editable) {
      columns.push({
        Header: 'Actions',
        Cell: renderAction,
        width: 120,
      })
    }

    return columns
  }, [props.editable, props.data, props.deletingStatus])

  function renderDescription({ original: data }) {
    const {
      app,
      environments,
      environmentGroups,
      environmentData,
      rootGroupId,
    } = props

    const { scope, orgScan } = data.properties
    const isHierarchical = app.catalogApplication.requiresHierarchicalModel

    let description = ''

    if (isHierarchical) {
      description += 'Application '
    } else {
      const allEnvironments = scope && scope.length && scope[0].value === rootGroupId
      const groups = []
      const envs = []

      scope.forEach(item => {
        if (item.type === 'Environment') {
          envs.push(item)
        } else {
          groups.push(item)
        }
      })

      if (orgScan) {
        description += 'Organization'
      }

      if (allEnvironments) {
        if (orgScan) {
          description += ' and all environments'
        } else {
          description += 'All environments'
        }
      } else {
        const envDescription = []
        if (orgScan && (envs.length || groups.length)) {
          description += ' and '
        }
        if (envs.length) {
          envDescription.push(
            `${pluralize(
              description.length ? 'environment' : 'Environment',
              envs.length
            )} (${envs
              .map(
                item =>
                  get(environmentData, [item.value, 'catalogAppDisplayName']) ||
                  get(environments, [item.value, 'name'], '')
              )
              .join(', ')}) `
          )
        }

        if (groups.length) {
          const subject =
            envs.length || description.length ? 'environments' : 'Environments'
          envDescription.push(
            `${subject} in the following ${pluralize(
              'group',
              groups.length
            )} (${groups
              .map(item => get(environmentGroups, [item.value, 'name']))
              .join(', ')}) `
          )
        }

        description += envDescription.join(' and ')
      }
    }

    description += ' will be scanned '

    const { frequencyType, frequencyInterval } = data
    const newValues = convertUTCDataToLocalData(data)
    const interval = get(newValues.frequencyInterval, 'label')
    const momentTime = moment.utc(data.startTime, 'HH:mm').local()
    const timeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZone
    const timeZoneCode = moment()
      .tz(timeZoneName)
      .format('z')
    const formattedTime = `${momentTime.format('HH:mm')} ${timeZoneCode}`

    switch (frequencyType) {
      case HOURLY:
        description += `every ${frequencyInterval} ${pluralize(
          'hour',
          frequencyInterval
        )}`
        break
      case DAILY:
        description += `daily at ${formattedTime}`
        break
      case WEEKLY:
        description += `weekly on ${interval} `
        description += `at ${formattedTime}`
        break
      case MONTHLY:
        description += `monthly on the ${numeral(interval).format('0o')} `
        description += `at ${formattedTime}`
        break
      default:
    }

    return <TableEllipsisCell value={description} />
  }

  function renderAction({ original: scanData }) {
    const { deleteScan, toggleScanDrawer, deletingStatus } = props
    const isDeleting = deletingStatus[scanData.taskId]

    return (
      <ActionIconGroup>
        <TableActionIcon onClick={() => toggleScanDrawer({ scanData })}>
          <i className="fa fa-edit fs:14" />
          <ActionIconLabel>Edit</ActionIconLabel>
        </TableActionIcon>

        <TableActionIcon
          onClick={
            isDeleting ? undefined : () => deleteScan({ scanId: scanData.taskId })
          }
          isDisabled={isDeleting}
        >
          {isDeleting ? (
            <Spinner />
          ) : (
            <Fragment>
              <i className="fa fa-trash fs:14" />
              <ActionIconLabel>Delete</ActionIconLabel>
            </Fragment>
          )}
        </TableActionIcon>
      </ActionIconGroup>
    )
  }

  const { editable, data, toggleScanDrawer, selectedScanData } = props

  return (
    <TableWrapper>
      {editable && (
        <Button
          className="createScanButton"
          color="primary"
          onClick={toggleScanDrawer}
        >
          Schedule a Scan
        </Button>
      )}

      <Table
        columns={columns}
        data={data}
        identifier="taskId"
        onScroll={() => ReactTooltip.hide()}
        selectedId={get(selectedScanData, 'taskId')}
        disableHover={!editable || data.length === 0}
        hideHeader
        maxHeight={160}
        emptyTableMessage="No scheduled scans"
        defaultSorted={[
          { id: 'sortData.frequencyType', desc: false },
          { id: 'sortData.frequencyInterval', desc: false },
          { id: 'sortData.startTime', desc: false },
        ]}
      />
    </TableWrapper>
  )
}

// defaultSorted={[{ id: 'modified', desc: true }]}
export default memo(ScheduledScans)
