import { isEmpty } from 'lodash'
import isEqual from 'lodash/isEqual'
import toast from 'utils/toast'
import { deletedMember, modalView } from './constants'

export const getCheckedNodes = (associations = []) => {
  if (associations?.length) {
    return associations.map(association => association.id)
  }

  return []
}

export const showToast = (message, type = 'error') => {
  toast({
    title: message,
    type,
    time: 'normal',
    id: `assign-association-approver-toast`,
  })
}

export const isDataValid = (groupName, associations, approvers) => {
  if (!groupName) {
    return 'Please enter a Group Name.'
  }

  if (associations.length === 0) {
    return 'Please select an association.'
  }

  if (approvers.length === 0) {
    return 'Please select an approver group.'
  }

  return null
}

export const getDataToUpdate = (
  updatedGroupName,
  updatedAssociations,
  updatedApprovers
) => {
  const updatedData = {}

  if (updatedGroupName.touched && updatedGroupName.value) {
    updatedData.name = updatedGroupName.value
  }

  if (updatedAssociations.touched && updatedAssociations.value?.length) {
    updatedData.associations = updatedAssociations.value
  }

  if (updatedApprovers.touched && updatedApprovers.value?.length) {
    updatedData.approversGroups = updatedApprovers.value.map(approver => ({
      id: approver.id,
    }))
  }

  return updatedData
}

export const getApproversGroupData = (id, allApproversGroupData = []) => {
  return allApproversGroupData.find(data => data.id === id)
}

export const filterUsersIdsFromMembers = (allMembers = []) => {
  return allMembers
    .filter(member => member.memberType === 'User')
    .map(user => user.id)
}

export const filterTagIdsFromMembers = (allMembers = []) => {
  return allMembers.filter(member => member.memberType === 'Tag').map(tag => tag.id)
}

export const filterTableData = (data = [], sort = {}, searchQuery) => {
  let filteredData = [...data]

  if (!isEmpty(sort) && sort.order !== 'unsorted') {
    filteredData.sort((a, b) => {
      const valueA = a[sort.by]?.toLowerCase()
      const valueB = b[sort.by]?.toLowerCase()

      // Compare full strings case-insensitively and numerically
      return (
        valueA.localeCompare(valueB, undefined, {
          numeric: true,
          sensitivity: 'base',
        }) * (sort.order === 'ascending' ? 1 : -1)
      )
    })
  }

  if (searchQuery) {
    return filteredData.filter(item =>
      item.name?.toLowerCase().includes(searchQuery.toLowerCase())
    )
  }

  return filteredData
}

export const isApproversGroupDataValid = (
  selectedApproversGroupData,
  dataToUpdate
) => {
  if (isEmpty(dataToUpdate)) {
    return 'Please perform some changes before saving.'
  }

  if (!selectedApproversGroupData.id) {
    if (!selectedApproversGroupData.name) {
      return 'Name is a mandatory field.'
    }

    if (isEmpty(selectedApproversGroupData.members)) {
      return 'Members are required to save the approver group.'
    }
  }

  return null
}

export const getApproversDataToUpdate = (
  selectedApproversGroupData,
  exitingApproversGroupData
) => {
  if (selectedApproversGroupData?.id) {
    const previousData = exitingApproversGroupData?.find(
      approverData => approverData?.id === selectedApproversGroupData?.id
    )

    const dataToUpdate = {}
    if (previousData?.name !== selectedApproversGroupData?.name) {
      dataToUpdate.name = selectedApproversGroupData?.name
    }

    if (previousData?.condition !== selectedApproversGroupData?.condition) {
      dataToUpdate.condition = selectedApproversGroupData.condition
    }

    if (!isEqual(previousData?.members, selectedApproversGroupData?.members)) {
      dataToUpdate.members = selectedApproversGroupData?.members
    }

    return dataToUpdate
  }

  if (
    !selectedApproversGroupData?.name ||
    isEmpty(selectedApproversGroupData?.members)
  ) {
    return {}
  }

  return selectedApproversGroupData
}

export const getFinalApproversGroup = (
  selectedApproversGroupData,
  exitingApproversGroupData
) => {
  let finalApproversGroup = [...exitingApproversGroupData]

  const index = exitingApproversGroupData.findIndex(
    approverData => approverData.id === selectedApproversGroupData.id
  )

  finalApproversGroup[index] = {
    id: exitingApproversGroupData[index].id,
    ...selectedApproversGroupData,
  }

  return finalApproversGroup
}

export const getTagMembers = (tags = [], tagId) => {
  return tags.find(tag => tag.userTagId === tagId)
}

export const getUserDetails = (users = [], userId) => {
  return users.find(user => user.id === userId)
}

export const addFullNamesToTheUsers = (users = [], rows = []) => {
  const updatedRows = []

  rows.forEach(row => {
    if (row.memberType === 'User') {
      let fullName = ''

      if (row.name.toLowerCase() === deletedMember) {
        fullName = '<Deleted User>'
      } else {
        const userDetails = getUserDetails(users, row.id)
        fullName = `${userDetails?.firstName || ''} ${userDetails?.lastName || ''}`
      }

      updatedRows.push({
        ...row,
        name: fullName,
      })
    } else {
      updatedRows.push(row)
    }
  })

  return updatedRows
}

export const getModalTitle = (modalBodyView, approverGroup) => {
  switch (modalBodyView) {
    case modalView.consolidated:
      return `${approverGroup.id ? 'Edit' : 'Add'} Approver Group`

    case modalView.membersOnly:
      return `${approverGroup.name} Members`

    case modalView.users:
      return 'Select Users'

    case modalView.tags:
      return 'Select Tags'

    case modalView.selectApproversGroup:
      return 'Select Approver Group'

    default:
      return null
  }
}

export const characterCheck = value => {
  return value?.length > 128
}
