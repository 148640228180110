import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import ViewUserDetail from './viewUserDetail'
import EditUserDetail from './editUserDetails'

import '../index.css'

class UserDetails extends Component {
  state = {
    editMode: false,
  }

  toggleEditMode() {
    return this.setState({
      editMode: !this.state.editMode,
    })
  }

  render() {
    const { user, dispatch } = this.props
    const { containerHeader } = styles

    return (
      <div
        style={{
          marginBottom: 20,
          // boxShadow: `0 2px 12px 2px #cddfed`
        }}
      >
        <Row>
          <Col
            style={{
              // boxShadow: `0 2px 12px 2px #cddfed`,
              backgroundColor: 'white',
            }}
            md={12}
          >
            <Row style={containerHeader}>
              <Col
                style={{
                  padding: 5,
                }}
                md={9}
              >
                Personal Information{' '}
              </Col>{' '}
              <Col
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              />{' '}
            </Row>{' '}
            {this.state.editMode ? (
              <EditUserDetail
                toggle={this.toggleEditMode.bind(this)}
                user={user}
                self={true}
                dispatch={dispatch}
              />
            ) : (
              <ViewUserDetail
                user={user}
                disableEdit
                toggleEdit={this.toggleEditMode.bind(this)}
              />
            )}{' '}
          </Col>{' '}
        </Row>{' '}
      </div>
    )
  }
}

UserDetails.propTypes = {
  user: PropTypes.object,
}

const styles = {
  containerHeader: {
    backgroundColor: 'var(--brand)',
    color: '#FFFFFF',
    fontWeight: 'bold',
    height: 30,
  },
  editButton: {
    backgroundColor: '#FFFFFF',
    height: 24,
    color: 'var(--brand)',
    width: 65,
  },
}

export default connect()(UserDetails)
