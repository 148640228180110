import * as actions from 'actions'
import get from 'lodash/get'
import { mfaStatus } from '../../routes/user_settings/constants'

export default function reducer(
  state = {
    register: {
      fetching: false,
      error: null,
      data: {},
    },
    validate: {
      fetching: false,
      error: null,
      success: false,
    },
    authenticate: {
      isAuthenticating: false,
      error: null,
      success: false,
      entityId: null,
    },
  },
  action
) {
  switch (action.type) {
    case `${actions.REGISTER_USER_MFA}_PENDING`: {
      return {
        ...state,
        register: {
          fetching: true,
          error: null,
          data: {},
        },
      }
    }

    case `${actions.REGISTER_USER_MFA}_FULFILLED`: {
      const data = get(action, 'payload.data', {})

      return {
        ...state,
        register: {
          fetching: false,
          error: null,
          data,
        },
      }
    }

    case `${actions.REGISTER_USER_MFA}_REJECTED`: {
      return {
        ...state,
        register: {
          fetching: false,
          error: 'Something went wrong while registering the MFA device.',
          data: {},
        },
      }
    }

    case `${actions.VALIDATE_USER_MFA}_PENDING`: {
      return {
        ...state,
        validate: {
          fetching: true,
          error: null,
          success: false,
        },
      }
    }

    case `${actions.VALIDATE_USER_MFA}_FULFILLED`: {
      const response = get(action, 'payload.data', {})
      const failedToValidate = response?.status === mfaStatus.challengeFailed
      return {
        ...state,
        validate: {
          fetching: false,
          error: failedToValidate ? 'Invalid OTP' : null,
          success: failedToValidate ? false : true,
        },
      }
    }

    case `${actions.VALIDATE_USER_MFA}_REJECTED`: {
      return {
        ...state,
        validate: {
          fetching: false,
          error: 'Unable to Validate the device.',
          success: false,
        },
      }
    }

    case `${actions.VALIDATE_USER_MFA}_RESET`: {
      return {
        ...state,
        validate: {
          fetching: false,
          error: null,
          success: false,
        },
      }
    }

    case `${actions.AUTHENTICATE_STEP_UP_MFA}_PENDING`: {
      return {
        ...state,
        authenticate: {
          isAuthenticating: true,
          error: null,
          success: false,
          entityId: null,
        },
      }
    }

    case `${actions.AUTHENTICATE_STEP_UP_MFA}_FULFILLED`: {
      const response = get(action, 'payload.data', {})
      const { entityId } = action.meta
      const failedToValidate = response?.result === mfaStatus.authFailed
      return {
        ...state,
        authenticate: {
          isAuthenticating: false,
          error: failedToValidate ? 'Unable to Verify the user.' : null,
          success: failedToValidate ? false : true,
          entityId,
        },
      }
    }

    case `${actions.AUTHENTICATE_STEP_UP_MFA}_REJECTED`: {
      return {
        ...state,
        authenticate: {
          isAuthenticating: false,
          error: 'Unable to Verify the user.',
          success: false,
          entityId: null,
        },
      }
    }

    case `${actions.AUTHENTICATE_STEP_UP_MFA}_RESET`: {
      return {
        ...state,
        authenticate: {
          isAuthenticating: false,
          error: null,
          success: false,
          entityId: null,
        },
      }
    }

    default:
      return { ...state }
  }
}
