import * as actions from 'actions'

const initialState = {
  loading: false,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case `${actions.CREATE_RESOURCE_LABEL}_PENDING`: {
      return {
        loading: true,
      }
    }

    case `${actions.CREATE_RESOURCE_LABEL}_FULFILLED`: {
      return {
        loading: false,
      }
    }

    case `${actions.CREATE_RESOURCE_LABEL}_REJECTED`: {
      return {
        loading: false,
      }
    }

    default:
      return { ...state }
  }
}
