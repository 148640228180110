import * as actions from 'actions'

import { fetchIfLoggedIn } from '../utils.js'

export const FETCH_SHARED_PAP_STATUS = 'FETCH_SHARED_PAP_STATUS'
export const FETCH_SHARED_PAP_STATUS_V1 = 'FETCH_SHARED_PAP_STATUS_V1'
export const FETCH_APPROVERS = 'FETCH_APPROVERS'
export const SHOW_APPROVAL_MODAL = 'SHOW_APPROVAL_MODAL'
export const HIDE_PPROVAL_MODAL = 'HIDE_APPROVAL_MODAL'
export const ADD_TO_EXTENSION_QUEUE = 'ADD_TO_EXTENSION_QUEUE'
export const CANCEL_EXTENSION = 'CANCEL_EXTENSION'
export const ADD_INTERNAL_TIMER = 'ADD_INTERNAL_TIMER'
export const GET_MY_ACCESS_ASSOCIATIONS = 'GET_MY_ACCESS_ASSOCIATIONS'

export const getUserApps = () => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.GET_USER_APPS,
      method: 'get',
      path: `/access/apps?type=UI`,
    })
  )
}

export const getUserAppsV1 = () => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.GET_USER_APPS_V1,
      method: 'get',
      path: `/access/apps?type=UI`,
    })
  )
}

export const getUserEnvironments = () => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.GET_USER_ENVIRONMENTS,
      method: 'get',
      path: `/access/environments?type=UI`,
    })
  )
}

export const getUserEnvironmentsV1 = () => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.GET_USER_ENVIRONMENTS_V1,
      method: 'get',
      path: `/access/environments?type=UI`,
    })
  )
}

export const getUserProfiles = () => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.GET_USER_PROFILES,
      method: 'get',
      path: `/access/profiles?type=UI`,
    })
  )
}

export const getUserProfilesV1 = () => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.GET_USER_PROFILES_V1,
      method: 'get',
      path: `/access/profiles?type=UI`,
    })
  )
}

export const getAllProfiles = () => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.GET_ALL_PROFILES,
      method: 'get',
      path: `/access?type=UI`,
    })
  )
}

export const getAllProfilesV1 = () => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.GET_ALL_PROFILES_V1,
      method: 'get',
      path: `/access?type=UI`,
    })
  )
}

export const getFrequentlyUsedProfiles = postCheckout => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.GET_FREQUENTLY_USED_PROFILES,
      meta: { postCheckout },
      method: 'get',
      path: `/access/frequently-used`,
    })
  )
}

export const getFrequentlyUsedProfilesV1 = postCheckout => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.GET_FREQUENTLY_USED_PROFILES_V1,
      meta: { postCheckout },
      method: 'get',
      path: `/access/frequently-used`,
    })
  )
}

export const getFavoriteProfiles = () => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.GET_FAVORITE_PROFILES,
      method: 'get',
      path: `/access/favorites?accessTypeRequired=false`,
    })
  )
}

export const getFavoriteProfilesV1 = () => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.GET_FAVORITE_PROFILES_V1,
      method: 'get',
      path: `/access/favorites`,
    })
  )
}

export const markPapFavorite = (
  appContainerId,
  environmentId,
  papId,
  accessType
) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.MARK_FAVORITE,
      meta: {
        appContainerId,
        environmentId,
        papId,
        accessType,
      },
      postBody: {
        appContainerId,
        environmentId,
        papId,
        accessType,
      },
      method: 'post',
      path: '/access/favorites',
    })
  )
}

export const markPapFavoriteV1 = (
  appContainerId,
  environmentId,
  papId,
  accessType
) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.MARK_FAVORITE_V1,
      meta: {
        appContainerId,
        environmentId,
        papId,
        accessType,
      },
      postBody: {
        appContainerId,
        environmentId,
        papId,
        accessType,
      },
      method: 'post',
      path: '/access/favorites',
    })
  )
}

export const deletePapFavorite = (
  favoriteId,
  appContainerId,
  environmentId,
  papId,
  accessType
) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.DELETE_FAVORITE,
      meta: {
        favoriteId,
        appContainerId,
        environmentId,
        accessType,
        papId,
      },
      method: 'delete',
      path: `/access/favorites/${favoriteId}`,
    })
  )
}

export const deletePapFavoriteV1 = (
  favoriteId,
  appContainerId,
  environmentId,
  papId,
  accessType
) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.DELETE_FAVORITE_V1,
      meta: {
        favoriteId,
        appContainerId,
        environmentId,
        accessType,
        papId,
      },
      method: 'delete',
      path: `/access/favorites/${favoriteId}`,
    })
  )
}

export const papCheckIn = (
  transactionId,
  accessStatusId,
  papId,
  environmentId,
  appContainerId,
  accessType,
  type
) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.ACCESS_CHECK_IN_PAP,
      meta: {
        environmentId,
        papId,
        appContainerId,
        accessType,
        transactionId,
        type,
      },
      method: 'put',
      path: `/access/${transactionId}?type=API`,
    })
  )
}

export const submitCheckIn = (
  transactionId,
  papId,
  environmentId,
  appContainerId,
  accessType,
  type
) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.ACCESS_CHECK_IN_SUBMIT,
      meta: {
        environmentId,
        papId,
        appContainerId,
        accessType,
        transactionId,
        type,
      },
      method: 'put',
      path: `/access/${transactionId}?type=API`,
    })
  )
}

export const papCheckOut = (
  papId,
  environmentId,
  appContainerId,
  accessType,
  papName
) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.ACCESS_CHECK_OUT_PAP,
      method: 'post',
      meta: { environmentId, papId, appContainerId, accessType, papName },
      path: `/access/${papId}/environments/${environmentId}?accessType=${accessType}`,
    })
  )
}

export const submitCheckOut = (
  papId,
  environmentId,
  appContainerId,
  accessType,
  papName,
  postBody = {}
) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.ACCESS_CHECK_OUT_SUBMIT,
      method: 'post',
      meta: { environmentId, papId, appContainerId, accessType, papName },
      path: `/access/${papId}/environments/${environmentId}?accessType=${accessType}`,
      postBody,
    })
  )
}

export const submitApprovalRequired = (
  papId,
  environmentId,
  appContainerId,
  accessType,
  papName,
  postBody = {}
) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.APPROVAL_REQUEST_SUBMIT,
      method: 'post',
      meta: { environmentId, papId, appContainerId, accessType, papName },
      path: `/access/${papId}/environments/${environmentId}/approvalRequest`,
      postBody,
    })
  )
}

export const submitWithdrawRequest = (
  resourceId,
  papId,
  environmentId,
  appContainerId,
  accessType,
  papName,
  consumer = 'papservice'
) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.REQUEST_CANCELLED_NOTIFICATION, // actionType added here
      meta: { environmentId, papId, appContainerId, accessType, papName },
      method: 'delete',
      path: `/v1/approvals/consumer/${consumer}/resource?resourceId=${resourceId}`,
    })
  )
}

export const getCheckedOutProfiles = isBackgroundPoller => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.GET_CHECKED_OUT_PROFILES,
      method: 'get',
      meta: { isBackgroundPoller },
      path: '/access/app-access-status',
    })
  )
}

export const getAppAccessStatus = () => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.GET_APP_ACCESS_STATUS,
      method: 'get',
      path: '/access/app-access-status',
    })
  )
}

export const fetchSharedPapStatusV1 = () => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: FETCH_SHARED_PAP_STATUS_V1,
      method: 'get',
      path: `/access/app-shared-account-info`,
    })
  )
}

export const fetchSharedPapStatus = () => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: FETCH_SHARED_PAP_STATUS,
      method: 'get',
      path: `/access/app-shared-account-info`,
    })
  )
}

export const extendExpiration = transactionId => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.EXTEND_EXPIRATION,
      method: 'patch',
      path: `/access/extensions/${transactionId}`,
    })
  )
}

export const fetchApprovers = (
  resourceId,
  consumer = 'papservice',
  action = 'papservice.profile.access'
) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: FETCH_APPROVERS,
      method: 'post',
      path: '/v1/policy-admin/policies/approvers',
      postBody: {
        consumer,
        resource: `${resourceId}/*`,
        action,
      },
    })
  )
}

export const submitRequest = (
  papId,
  environmentId,
  appContainerId,
  accessType,
  papName,
  postBody = {}
) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.ACCESS_REQUEST_SUBMIT,
      method: 'post',
      meta: { environmentId, papId, appContainerId, accessType, papName },
      path: `/access/${papId}/environments/${environmentId}/approvalRequest`,
      postBody,
    })
  )
}

export const withdrawRequest = (
  appContainerId,
  environmentId,
  papId,
  papName
) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.REQUEST_CANCELLED_NOTIFICATION,
      meta: {
        appContainerId,
        environmentId,
        papId,
        papName,
      },
      method: 'delete',
      path: `/v1/approvals/consumer/papservice/resource?resourceId=${papId}/${environmentId}`,
    })
  )
}

export const cancelExtension = payload => {
  return {
    type: CANCEL_EXTENSION,
    payload,
  }
}

export const showApprovalModal = payload => {
  return {
    type: SHOW_APPROVAL_MODAL,
    payload,
  }
}

export const hideApprovalModal = payload => {
  return {
    type: HIDE_PPROVAL_MODAL,
    payload,
  }
}

export const addToExtensionQueue = payload => {
  return {
    type: ADD_TO_EXTENSION_QUEUE,
    payload,
  }
}

export const addInternalTimer = payload => {
  return {
    type: ADD_INTERNAL_TIMER,
    payload,
  }
}

export const getAssociations = profileId => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: GET_MY_ACCESS_ASSOCIATIONS,
      method: 'get',
      meta: profileId,
      path: `/access/pap-scopes/${profileId}`,
    })
  )
}
