import { useEffect } from 'react'

// Hook
export default function useOnScrollListener({ elRef, onScroll }) {
  useEffect(() => {
    const ref = elRef.current

    if (ref) {
      ref.addEventListener('scroll', onScroll)

      return () => {
        ref.removeEventListener('scroll', onScroll)
      }
    }
  }, [elRef.current])

  return null
}
