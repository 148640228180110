export const classes = {
  approvalsDetailsButton: 'my-secrets-my-approvals-details-button',
  approvalsDetailsForm: 'my-secrets-my-approvals-details-form',
  marginTop32: 'approvals-details-margin-top-32',
  marginTop20: 'approvals-details-margin-top-20',
  marginTop8: 'approvals-details-margin-top-8',
  cancelBtn: 'user-cancel-btn',
  detailsLabel: 'details-label',
}

export const classesMobile = {
  approvalRequestSuccessContainer: 'approval-request-success-container',
  approvalRequestSeccessContent: 'approval-request-success-content',
  checkIcon: 'check-icon',
  successMessage: 'success-message',
  approved: 'approved',
  rejected: 'rejected',
  detailsTextIndent: 'my-secrets-my-approvals-details-text-indent',
}

export const MyApprovalsDetailsDataTypes = {
  MY_APPROVALS_DETAILS_REQUEST: 'MY_APPROVALS_DETAILS_REQUEST',
  MY_APPROVALS_DETAILS_LOADING: 'MY_APPROVALS_DETAILS_LOADING',
  MY_APPROVALS_DETAILS_SUCCESS: 'MY_APPROVALS_DETAILS_SUCCESS',
  MY_APPROVALS_DETAILS_FAILURE: 'MY_APPROVALS_DETAILS_FAILURE',
  MY_APPROVALS_APPROVERS_LIST_REQUEST: 'MY_APPROVALS_APPROVERS_LIST_REQUEST',
  MY_APPROVALS_APPROVERS_LIST_LOADING: 'MY_APPROVALS_APPROVERS_LIST_LOADING',
  MY_APPROVALS_APPROVERS_LIST_SUCCESS: 'MY_APPROVALS_APPROVERS_LIST_SUCCESS',
  MY_APPROVALS_APPROVERS_LIST_FAILURE: 'MY_APPROVALS_APPROVERS_LIST_FAILURE',
}

export const ApproveRequestDataTypes = {
  APPROVE_REQUEST_DETAILS_REQUEST: 'APPROVE_REQUEST_DETAILS_REQUEST',
}

export const RejectRequestDataTypes = {
  REJECT_REQUEST_DETAILS_REQUEST: 'REJECT_REQUEST_DETAILS_REQUEST',
}

export const buttonType = {
  approve: 'Approve',
  reject: 'Reject',
  close: 'Close',
}

export const translatedStrings = {
  pageTitle: 'Approval',
  requestedBy: 'Requested By',
  actionType: 'Action',
  resource: 'Resource',
  resourceName: 'Resource Name',
  approvalStatus: 'Approval status',
  fetchingApprovals: 'Loading...',
  requestDate: 'Request Date',
  requestValidity: 'Request Validity',
  justification: 'Justification',
  approvedBy: 'Approved By',
  approvedOn: 'Approved On',
  rejectedBy: 'Rejected By',
  rejectedOn: 'Rejected On',
  expiredOn: 'Expired On',
  noneValue: 'None',
  cancelLabel: 'Cancel',
  yesApprove: 'Yes, Approve',
  yesReject: 'Yes, Reject',
  forLabel: 'for',
  myApprovalsSearchText: 'Search is not supported on this screen',
  loadingMessage: 'Loading...',
  approveRequestTitle: 'Approve Request?',
  rejectRequestTitle: 'Reject Request?',
  approveMessage: 'permission will be granted to',
  rejectMessage: 'permission will be denied to',
  manageRequestTooltip: 'Manage Request',
  viewRequestTooltip: 'View Request',
  approveRequestTooltip: 'Approve Request',
  rejectRequestTooltip: 'Reject Request',
  comments: 'Comments',
  optional: 'Optional',
  approvalPendingText: 'Approval Pending',
  approvalRequiredText: 'Approval Required',
  approvalDeniedText: 'Access Denied',
  availableText: 'Available',
  alreadyApproved: 'Request is already approved',
  alreadyRejected: 'Request is already rejected',
  alreadyExpired: 'Request Expired',
  viewSecret: 'View Secret',
  accessProfile: 'Access Profile',
  rejectedStatus: 'Rejected',
  approvedStatus: 'Approved',
  timeOutStatus: 'Request Expired',
  cancelled: 'Request Withdrawn',
  noneText: 'None',
  accessRequest: 'Access Builder',
  secret: 'Secret',
  requestApproved: 'The Request is approved',
  requestRejected: 'The Request is rejected',
  requestNotApproved: 'Could not approve the request.',
  requestNotRejected: 'Could not reject the request.',
  noActionRequired: 'No Action Required',
}

export const statusLabel = {
  expired: 'This request has expired due to no action from approvers',
  approved: 'This request is approved by {{userName}}',
  expiredAfterApproval: 'Validity expired',
  rejected: 'This request is rejected by {{userName}}',
}

export const status = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  TIMEOUT: 'TIMEOUT',
}
export const defaultBtnSize = '24'
export const YES_PARAM = 'yes'
export const NO_PARAM = 'no'
export const mySecretMyApprovalPath = 'my-approvals'
export const HeaderConstants = {
  HEADER_PROPS: 'HEADER_PROPS',
}
export const mediumHeightDialogPopUp = 365
