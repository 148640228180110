import { fetchIfLoggedIn } from './utils.js'

export const FETCH_SAML_SETTINGS = 'SAML_CONFIG/FETCH_SAML_SETTINGS'
export const DOWNLOAD_SAML_METADATA = 'SAML_CONFIG/DOWNLOAD_SAML_METADATA'
export const DOWNLOAD_SAML_CERTIFICATE = 'SAML_CONFIG/DOWNLOAD_SAML_CERTIFICATE'

export const fetchSAMLSettings = () => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: FETCH_SAML_SETTINGS,
      method: 'get',
      path: `/saml/settings`,
    })
  )
}

export const downloadSAMLMetadata = id => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: DOWNLOAD_SAML_METADATA,
      method: 'get',
      path: `/saml/metadata/${id}`,
    })
  )
}

export const downloadSAMLCertificate = id => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: DOWNLOAD_SAML_CERTIFICATE,
      method: 'get',
      path: `/saml/certificate/${id}`,
    })
  )
}
