import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import PasswordPolicyCheck from './PasswordPolicyCheck'

import { fetchPasswordPolicy } from 'action_creators/account'

import {
  MINIMUM_LENGTH,
  REQUIRE_UPPERCASE,
  REQUIRE_LOWERCASE,
  REQUIRE_NUMBERS,
  REQUIRE_SYMBOLS,
  PASSWORD_CONFIRM_MATCH,
  DIFFERENT_FROM_LAST_PASSWORD,
  UPPER_CASE_TEST,
  LOWER_CASE_TEST,
  NUMBER_TEST,
  SYMBOL_TEST,
} from './constants'

export const policyLabels = {
  [MINIMUM_LENGTH]: limit => `at least ${limit} characters`,
  [REQUIRE_UPPERCASE]: 'contain at least 1 uppercase',
  [REQUIRE_LOWERCASE]: 'contain at least 1 lowercase',
  [REQUIRE_NUMBERS]: 'contain at least 1 digit',
  [REQUIRE_SYMBOLS]: 'contain at least 1 symbol',
  [PASSWORD_CONFIRM_MATCH]: 'new passwords must match',
  [DIFFERENT_FROM_LAST_PASSWORD]: 'must be different from current password',
}

const validationFunctions = {
  [MINIMUM_LENGTH]: (password, length) => password.length >= parseInt(length),
  [REQUIRE_UPPERCASE]: password => UPPER_CASE_TEST.test(password),
  [REQUIRE_LOWERCASE]: password => LOWER_CASE_TEST.test(password),
  [REQUIRE_NUMBERS]: password => NUMBER_TEST.test(password),
  [REQUIRE_SYMBOLS]: password => SYMBOL_TEST.test(password),
}

class PasswordPolicyCheckContainer extends PureComponent {
  state = { policyStatuses: {} }
  _isMounted = false

  async componentDidMount() {
    this._isMounted = true
    const { dispatch } = this.props

    await dispatch(fetchPasswordPolicy())
    this._isMounted && this.updatePolicyStatuses()
  }

  componentDidUpdate(prevProps) {
    const { newPassword, confirmPassword, currentPassword, display } = this.props

    if (
      !display &&
      (prevProps.newPassword !== newPassword ||
        prevProps.confirmPassword !== confirmPassword ||
        prevProps.currentPassword !== currentPassword)
    ) {
      this.updatePolicyStatuses()
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  updatePolicyStatuses = () => {
    const {
      passwordPolicies,
      afterPolicyCheckUpdate,
      newPassword,
      confirmPassword,
      currentPassword,
      checkAgainstConfirmPassword,
      checkAgainstLastPassword,
      display,
    } = this.props

    const newStatuses = {}
    let checkPassed = true

    if (display) {
      Object.keys(passwordPolicies).forEach(policy => {
        newStatuses[policy] = false
      })
    } else {
      Object.keys(passwordPolicies).forEach(policy => {
        const policyValue = passwordPolicies[policy]
        const validationFunction = validationFunctions[policy]

        if (policyValue !== 'false' && validationFunction) {
          const passed = validationFunction(newPassword, policyValue)

          newStatuses[policy] = passed

          if (!passed && checkPassed) {
            checkPassed = passed
          }
        }
      })

      if (checkAgainstConfirmPassword) {
        // TODO: refactor this. had to change back and forth because of
        // forced password design indecision
        const passwordConfirmMatches =
          newPassword && confirmPassword && newPassword === confirmPassword

        newStatuses[PASSWORD_CONFIRM_MATCH] = passwordConfirmMatches

        checkPassed = !checkPassed ? checkPassed : passwordConfirmMatches
      }

      if (checkAgainstLastPassword) {
        const differentFromLastPassword =
          newPassword && currentPassword && newPassword !== currentPassword

        newStatuses[DIFFERENT_FROM_LAST_PASSWORD] = differentFromLastPassword

        checkPassed = !checkPassed ? checkPassed : differentFromLastPassword
      }
    }
    this.setState({ policyStatuses: newStatuses })
    afterPolicyCheckUpdate && afterPolicyCheckUpdate(checkPassed)
  }

  render() {
    const {
      className,
      passwordPolicies,
      display,
      fetchingPasswordPolicy,
    } = this.props
    const { policyStatuses } = this.state

    return (
      <PasswordPolicyCheck
        policies={passwordPolicies}
        statuses={policyStatuses}
        className={className}
        display={display}
        fetchingPasswordPolicy={fetchingPasswordPolicy}
      />
    )
  }
}

const mapStateToProps = state => ({
  // TODO: make selector
  passwordPolicies: state.account.passwordPolicy,
  fetchingPasswordPolicy: state.account.fetchingPasswordPolicy,
})

export default connect(mapStateToProps)(PasswordPolicyCheckContainer)
