import React, { Fragment, useState } from 'react'
import TableSection from 'components/table/tableSectionV2'
import useMaxTableHeight from 'hooks/useMaxTableHeight'
import AddSessionAttributes from './AddSessionAttributes'
import FontAwesome from 'react-fontawesome'
import {
  ActionIconLabel,
  ActionIconGroup,
} from 'britive-ui-components/core/components/ActionIcon'
import Button from 'britive-ui-components/core/components/Button'
import Spinner from 'britive-ui-components/core/components/Spinner'
import TableActionIcon from 'components/TableActionIcon'
import ErrorMessage from 'components/error_message'

const styles = {
  button: {
    position: 'absolute',
    top: -64,
    right: 0,
  },
}

export default ({
  app,
  error,
  setError,
  fetching,
  attributes,
  availableAttributes,
  sessionAttributes,
  profileId,
  updateSessionAttributes,
  handleDeleteSessionAttribute,
  deletingSessionAttributes,
  thisAppManage,
}) => {
  const maxTableHeight = useMaxTableHeight({
    heights: [
      20, //padding
      20, // padding
      36, // breadcrumbs
      35, // title
      36, // tableHeader
      22, // table margin top
    ],
    minHeight: 100,
  })
  const [isAddModalOpen, setIsModalOpen] = useState(false)
  const [selectedSessionAttribute, setSelectedSessionAttribute] = useState(null)
  const renderActionIcons = ({ original: sessionAttribute }) => {
    const deleting = deletingSessionAttributes[sessionAttribute.id]
    return (
      <ActionIconGroup>
        <TableActionIcon
          onClick={() => handleDeleteSessionAttribute(sessionAttribute.id)}
        >
          {deleting ? (
            <Spinner />
          ) : (
            <Fragment>
              <span className="fa fa-trash fs:14" />
              <ActionIconLabel>Delete</ActionIconLabel>
            </Fragment>
          )}
        </TableActionIcon>
        <TableActionIcon
          isDisabled={deleting}
          onClick={() => {
            setSelectedSessionAttribute({
              [sessionAttribute.id]: {
                ...sessionAttribute,
                attribute: {
                  [sessionAttribute.attributeSchemaId]:
                    attributes[sessionAttribute.attributeSchemaId],
                },
              },
            })
            setIsModalOpen(true)
          }}
        >
          <FontAwesome name="sliders-h" size="lg" />
          <ActionIconLabel>Manage</ActionIconLabel>
        </TableActionIcon>
      </ActionIconGroup>
    )
  }
  return (
    <Fragment>
      {fetching ? (
        <Spinner size={'2x'} label={'Loading Session Attributes'} />
      ) : (
        <Fragment>
          {thisAppManage && (
            <Button
              onClick={() => {
                setIsModalOpen(true)
                setError(null)
              }}
              color="primary"
              style={styles.button}
            >
              Add Session Attribute
            </Button>
          )}
          {error && <ErrorMessage text={error} />}
          <TableSection
            resizable={true}
            sortable
            searchKeys={['mappingName']}
            identifier="id"
            columns={[
              {
                Header: 'Mapping Name',
                accessor: 'mappingName',
                minWidth: 200,
              },
              {
                Header: 'Attribute Type',
                accessor: 'sessionAttributeType',
                minWidth: 100,
              },
              {
                Header: 'Attribute',
                accessor: 'attributeSchemaId',
                minWidth: 200,
                Cell: ({ value }) => (value ? attributes[value].name : ''),
                sortMethod: (a, b) => {
                  const aValue = a ? attributes[a].name : ''
                  const bValue = b ? attributes[b].name : ''
                  return aValue > bValue ? 1 : -1
                },
              },
              {
                Header: 'Attribute Value',
                accessor: 'attributeValue',
                minWidth: 150,
              },
              {
                Header: 'Transitive',
                accessor: 'transitive',
                minWidth: 100,
                Cell: ({ value }) => (value ? 'Yes' : 'No'),
              },
              ...(thisAppManage
                ? [
                    {
                      Header: 'Actions',
                      sortable: false,
                      resizable: false,
                      width: 120,
                      Cell: renderActionIcons,
                    },
                  ]
                : []),
            ]}
            data={Object.values(sessionAttributes)}
            emptyTableMessage={`No session attributes available.`}
            maxHeight={`${maxTableHeight}px`}
          />
        </Fragment>
      )}
      {isAddModalOpen && (
        <AddSessionAttributes
          app={app}
          isModalOpen={isAddModalOpen}
          toggleModal={() => {
            setIsModalOpen(!isAddModalOpen)
            setSelectedSessionAttribute(null)
          }}
          profileId={profileId}
          availableAttributes={availableAttributes}
          selectedSessionAttribute={selectedSessionAttribute}
          updateSessionAttributes={updateSessionAttributes}
        />
      )}
    </Fragment>
  )
}
