import { isEmpty, uniqBy } from 'lodash'
import { fetchIfLoggedIn } from './utils.js'
import { getIsAuthenticated } from 'utils/authentication'
import { notAuthenticated } from 'action_creators/account/index.js'
import Axios from 'axios'
import { getURL } from 'utils/do_fetch/index.js'

export const FETCH_TAGS = 'TAGS/FETCH_TAGS'
export const FETCH_SELECTED_TAGS = 'TAGS/FETCH_SELECTED_TAGS'
export const FETCH_PAGINATED_TAGS = 'TAGS/FETCH_PAGINATED_TAGS'
export const FETCH_PAGINATED_TAGS_LOAD_MORE = 'TAGS/FETCH_PAGINATED_TAGS_LOAD_MORE'
export const FETCH_TAGS_ASSOCIATED_WITH_PAPS = 'TAGS/FETCH_TAGS_ASSOCIATED_WITH_PAPS'
export const FETCH_TAG = 'TAGS/FETCH_TAG'
export const FETCH_TAG_ASSIGNED_USERS = 'TAGS/FETCH_TAG_ASSIGNED_USERS'
export const FETCH_TAG_AVAILABLE_USERS = 'TAGS/FETCH_TAG_AVAILABLE_USERS'
export const ADD_TAG_USER = 'TAGS/ADD_TAG_USER'
export const REMOVE_TAG_USER = 'TAGS/REMOVE_TAG_USER'
export const UPDATE_TAG = 'TAGS/UPDATE_TAG'
export const CREATE_TAG = 'TAGS/CREATE_TAG'
export const DELETE_TAG = 'TAGS/DELETE_TAG'
export const DISABLE_TAG = 'TAGS/DISABLE_TAG'
export const ENABLE_TAG = 'TAGS/ENABLE_TAG'
export const INVALIDATE_TAG_ASSIGNED_USERS = 'TAGS/INVALIDATE_TAG_ASSIGNED_USERS'

export const fetchTags = () => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: FETCH_TAGS,
      method: 'get',
      path: '/user-tags',
    })
  )
}

export const fetchPaginatedTags = (params = {}, oldSearchTerm) => dispatch => {
  const apiParams = {
    page: params.page,
    size: params.size,
  }

  let shouldAppend = params.page !== 0
  if (!params.searchText && oldSearchTerm?.length && params.page !== 0) {
    apiParams.searchText = oldSearchTerm
  } else if (params.searchText && !oldSearchTerm) {
    apiParams.searchText = params.searchText
    shouldAppend = false
  } else if (params.searchText) {
    apiParams.searchText = params.searchText
    shouldAppend = false
  }

  return dispatch(
    fetchIfLoggedIn({
      actionType: shouldAppend
        ? FETCH_PAGINATED_TAGS_LOAD_MORE
        : FETCH_PAGINATED_TAGS,
      method: 'get',
      path: '/user-tags',
      meta: { searchText: params.searchText },
      params: apiParams,
    })
  )
}

export const fetchSelectedTags = (tags = []) => async dispatch => {
  if (isEmpty(tags)) {
    return []
  }

  const tagsToFetch = []
  uniqBy(tags, 'id').forEach(tag => {
    if (tag?.name !== 'Deleted Tag') {
      tagsToFetch.push(tag.id)
    }
  })

  if (tagsToFetch.length === 0) {
    return []
  }

  if (tagsToFetch.length <= 50) {
    return dispatch(
      fetchIfLoggedIn({
        actionType: FETCH_SELECTED_TAGS,
        method: 'post',
        path: '/user-tags/minimized-tag-details',
        postBody: tagsToFetch,
      })
    )
  } else {
    const isLoggedIn = getIsAuthenticated()

    if (isLoggedIn) {
      const requests = []
      while (tagsToFetch?.length > 0) {
        requests.push(
          Axios.post(
            `${getURL()}/user-tags/minimized-tag-details`,
            tagsToFetch.splice(0, 50),
            {
              withCredentials: true,
              xsrfCookieName: 'csrfToken',
            }
          )
        )
      }

      try {
        dispatch({ type: `${FETCH_SELECTED_TAGS}_PENDING` })
        const responses = await Axios.all(requests)
        if (isEmpty(responses)) {
          return []
        }

        const consolidatedData = []
        responses.forEach(response =>
          consolidatedData.push(...(response.data || []))
        )

        return dispatch({
          type: `${FETCH_SELECTED_TAGS}_FULFILLED`,
          payload: {
            data: consolidatedData,
          },
        })
      } catch {
        return dispatch({
          type: `${FETCH_SELECTED_TAGS}_REJECTED`,
          payload: 'Unable to fetch tags.',
        })
      }
    } else {
      return dispatch(notAuthenticated())
    }
  }
}

export const fetchTagsAssociatedWithPaps = () => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: FETCH_TAGS_ASSOCIATED_WITH_PAPS,
      method: 'get',
      path: '/user-tags/pap-associated-tags',
    })
  )
}

export const fetchTag = ({ tagId }) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: FETCH_TAG,
      method: 'get',
      path: `/user-tags/${tagId}`,
    })
  )
}

export const createTag = values => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: CREATE_TAG,
      method: 'post',
      path: '/user-tags',
      postBody: values,
    })
  )
}

export const updateTag = ({ postBody }) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: UPDATE_TAG,
      method: 'patch',
      path: `/user-tags`,
      postBody,
    })
  )
}

export const deleteTag = tagId => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: DELETE_TAG,
      method: 'delete',
      meta: { tagId },
      path: `/user-tags/${tagId}`,
    })
  )
}

export const enableTag = tagId => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: ENABLE_TAG,
      method: 'POST',
      path: `/user-tags/${tagId}/enabled-statuses`,
    })
  )
}

export const disableTag = tagId => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: DISABLE_TAG,
      method: 'POST',
      path: `/user-tags/${tagId}/disabled-statuses`,
    })
  )
}

export const fetchTagAssignedUsers = ({ tagId }) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: FETCH_TAG_ASSIGNED_USERS,
      method: 'get',
      meta: { tagId },
      path: `/user-tags/${tagId}/users?filter=assigned&fetchDynamicallyAssignedUsers=true`,
    })
  )
}

export const fetchTagAvailableUsers = ({ tagId }) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: FETCH_TAG_AVAILABLE_USERS,
      method: 'get',
      meta: { tagId },
      path: `/user-tags/${tagId}/users?filter=available`,
    })
  )
}

export const addTagUser = ({ tagId, userId, forceRemove }) => dispatch => {
  let path = `/user-tags/${tagId}/users/${userId}`

  if (forceRemove) {
    path += '?forceRemoveAnyDirectUserProfileAssociation=true'
  }

  return dispatch(
    fetchIfLoggedIn({
      actionType: ADD_TAG_USER,
      method: 'post',
      meta: { tagId, userId },
      path,
    })
  )
}

export const removeTagUser = ({ tagId, userId }) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: REMOVE_TAG_USER,
      method: 'delete',
      meta: { tagId, userId },
      path: `/user-tags/${tagId}/users/${userId}`,
    })
  )
}

export const addAttributeCriteria = (flag, tagId, postBody) => dispatch => {
  let method = flag ? 'post' : 'patch'
  return dispatch(
    fetchIfLoggedIn({
      method: method,
      path: `/user-tags/${tagId}/attribute-criteria`,
      postBody,
    })
  )
}

export const invalidateTagsAssignedUsers = () => dispatch => {
  dispatch({
    type: INVALIDATE_TAG_ASSIGNED_USERS,
  })
}
