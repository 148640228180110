import { createSelector } from 'reselect'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'

import { getStateSliceFor } from 'selectors'
import { getPaps, getPapScopes, getPapPermissions } from 'selectors/pap'
import { adminUIReducerKey as reducerKey } from './reducer'

/** Returns the state slice for application root. */
const extractFrom = state => getStateSliceFor({ state, reducerKey })

export const getPapsManagerEntity = state => extractFrom(state).papsManagerEntity
export const getSelectedPapId = state => extractFrom(state).selectedPapId
export const getEnvironmentsToScan = state => extractFrom(state).environmentsToScan

/** Returns the selected pap's scope **/
export const getSelectedPapScope = createSelector(
  state => getPapScopes(state),
  state => getSelectedPapId(state),
  (papScopes, papId) => {
    if (!isEmpty(papScopes) && papId && papScopes[papId]) {
      const scopes = papScopes[papId]

      if (get(scopes, 'length')) {
        const scopeObject = {}

        scopes.forEach(entity => {
          scopeObject[entity.value] = entity
        })

        return scopeObject
      } else {
        return {}
      }
    }

    return {}
  }
)

/** Returns the selected pap's resource scope **/
export const getSelectedPapResourceScope = createSelector(
  state => getPapScopes(state),
  state => getSelectedPapId(state),
  (papScopes, papId) => {
    if (!isEmpty(papScopes) && papId && papScopes[papId]) {
      const scopes = papScopes[papId]

      if (get(scopes, 'length')) {
        const scopeObject = {}

        scopes.forEach(entity => {
          // TODO: need to make application resource a constant
          // or make two separate scope properties for a pap
          if (entity.type === 'ApplicationResource') {
            scopeObject[entity.value] = entity
          }
        })

        return scopeObject
      } else {
        return {}
      }
    }

    return {}
  }
)

/** Returns the selected pap group scope **/
export const getSelectedPapGroupScope = createSelector(
  state => getPapScopes(state),
  state => getSelectedPapId(state),
  (papScopes, papId) => {
    if (!isEmpty(papScopes) && papId && papScopes[papId]) {
      const scopes = papScopes[papId]

      if (get(scopes, 'length')) {
        const scopeObject = {}

        scopes.forEach(entity => {
          // TODO: need to make application resource a constant
          // or make two separate scope properties for a pap
          if (entity.type !== 'ApplicationResource') {
            scopeObject[entity.value] = entity
          }
        })

        return scopeObject
      } else {
        return {}
      }
    }

    return {}
  }
)

/** Returns the selected pap **/
export const getSelectedPapPermissions = createSelector(
  state => getPapPermissions(state),
  state => getSelectedPapId(state),
  (permissions, papId) => {
    if (!isEmpty(permissions) && papId) {
      return permissions[papId] || []
    }

    return []
  }
)

/** Returns the selected pap integrity checks **/
export const getSelectedPapIntegrityChecks = createSelector(
  state => getPaps(state),
  state => getSelectedPapId(state),
  (paps, papId) => {
    if (!isEmpty(paps) && papId) {
      if (!paps[papId]) {
        return {}
      }

      const integrityChecks = paps[papId].integrityChecks
      const integrityChecksObject = {}

      integrityChecks.forEach(({ permissionName, environmentId, succeeded }) => {
        if (!integrityChecksObject[permissionName]) {
          integrityChecksObject[permissionName] = { all: true }
        }

        integrityChecksObject[permissionName][environmentId] = succeeded

        if (integrityChecksObject[permissionName].all === true) {
          integrityChecksObject[permissionName].all = succeeded
        }
      })

      return integrityChecksObject || {}
    }

    return {}
  }
)
