import { createSelector } from 'reselect'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import orderBy from 'lodash/orderBy'
import uniq from 'lodash/uniq'

import { getStateSliceFor } from './index'

import { papsReducerKey as reducerKey } from 'reducers/pap'

import {
  getSelectedApplicationRoot,
  getSelectedApplicationRootId,
} from 'selectors/application_root'

import { getSelectedApplicationRootGroupId } from 'selectors/environment'

/** Returns the state slice for application root. */
const extractFrom = state => getStateSliceFor({ state, reducerKey })

export const getPaps = state => extractFrom(state).paps
export const getPapScopes = state => extractFrom(state).papScopes
export const getPapPermissions = state => extractFrom(state).papPermissions
export const getFetchingPaps = state => extractFrom(state).fetchingPaps
export const getFetchingPapPermissionsState = state =>
  extractFrom(state).fetchingPapPermissions
export const getUpdatingScope = state => extractFrom(state).updatingScope
export const getFetchingPapScopes = state => extractFrom(state).fetchingPapScopes

export const getFetchingPapPermissions = createSelector(
  state => getFetchingPapPermissionsState(state),
  fetchingStatuses => {
    if (fetchingStatuses) {
      return Object.values(fetchingStatuses).some(status => status)
    }

    return false
  }
)

/** Returns the selected entity's paps */
// TODO: Look this over again and see if can be optimized
export const getSelectedAppsPaps = createSelector(
  state => getPaps(state),
  state => getPapScopes(state),
  state => getSelectedApplicationRoot(state),
  state => getPapPermissions(state),
  state => getSelectedApplicationRootGroupId(state),
  (paps, scopes, app, permissions, rootGroupId) => {
    if (!isEmpty(scopes) && !isEmpty(paps) && app) {
      const requiresHierarchicalModel = get(
        app,
        'catalogApplication.requiresHierarchicalModel'
      )
      const scope = get(scopes, `[${app.appContainerId}]`, [])
      const newScopeArray = []

      scope.forEach(id => {
        if (!paps[id]) {
          return null
        }

        const pap = {
          ...paps[id],
          permissions: get(permissions, id, []),
        }

        const integrityChecks = {}
        let integrityChecksPassed = true

        // TODO: refactor to make this easier to understand
        pap.integrityChecks.forEach(integrityCheck => {
          const { environmentId, scope } = integrityCheck

          // only include in integrity checks if the
          // pap has the environment
          const hasEnvironment = pap.environments.some(env => {
            const id = env.id || env.environmentId
            const parentGroupId = env.parentGroupId
            return id === environmentId || id === scope || scope === parentGroupId
          })

          let integrityCheckInScope

          if (requiresHierarchicalModel) {
            integrityCheckInScope = pap.scope.some(papScope => {
              return papScope.value === scope || papScope.value === environmentId
            })
          }

          if (hasEnvironment || integrityCheckInScope) {
            const [passed, updatedIntegrityCheck] = generateIntegrityCheck({
              integrityCheck,
              integrityChecks: integrityChecks,
              identifier: scope || environmentId,
              integrityChecksPassed: integrityChecksPassed,
              getItemLabel: check => check.permissionName,
            })

            integrityChecksPassed = passed
            integrityChecks[scope || environmentId] = updatedIntegrityCheck
          }
        })

        pap.integrityChecks = integrityChecks
        pap.integrityChecksPassed = integrityChecksPassed

        const users = {}

        if (pap.users) {
          pap.users.forEach(user => {
            users[user.userId] = user
          })
        }

        const userIntegrityChecks = {}
        let userIntegrityChecksPassed = true

        pap.accountIntegrityChecks.forEach(integrityCheck => {
          const { environmentId, userId } = integrityCheck
          const hasUser = pap.users.find(user => user.userId === userId)
          // only include in integrity checks if the
          // pap has the environment
          const hasEnvironment = pap.environments.some(env => {
            const id = env.id || env.environmentId
            return id === environmentId || env.parentGroupId === environmentId
          })

          let integrityCheckInScope

          if (requiresHierarchicalModel) {
            integrityCheckInScope = environmentId === rootGroupId
          }

          if (hasUser && (hasEnvironment || integrityCheckInScope)) {
            const [passed, updatedIntegrityCheck] = generateIntegrityCheck({
              integrityCheck,
              integrityChecks: userIntegrityChecks,
              identifier: environmentId,
              integrityChecksPassed: userIntegrityChecksPassed,
              getItemLabel: check => {
                const username = get(users, [check.userId, 'username'], '')
                const userEmail = get(users, [check.userId, 'email'], '')

                return `${username} (${userEmail})`
              },
            })

            userIntegrityChecksPassed = passed
            userIntegrityChecks[environmentId] = updatedIntegrityCheck
          }
        })

        pap.userIntegrityChecks = userIntegrityChecks
        pap.userIntegrityChecksPassed = userIntegrityChecksPassed

        const tagIntegrityChecks = {}
        let tagIntegrityChecksPassed = true

        pap.userTagIntegrityChecks.forEach(integrityCheck => {
          const { name } = integrityCheck
          const accountIntegrityChecks = integrityCheck.accountIntegrityChecks || []

          accountIntegrityChecks.forEach(accountCheck => {
            const { environmentId } = accountCheck

            // only include in integrity checks if the
            // pap has the environment
            const hasEnvironment = pap.environments.some(env => {
              const id = env.id || env.environmentId
              return id === environmentId || env.parentGroupId === environmentId
            })

            let integrityCheckInScope

            if (requiresHierarchicalModel) {
              integrityCheckInScope = environmentId === rootGroupId
            }

            if (hasEnvironment || integrityCheckInScope) {
              const [passed, updatedIntegrityCheck] = generateIntegrityCheck({
                integrityCheck: accountCheck,
                integrityChecks: tagIntegrityChecks,
                identifier: environmentId,
                integrityChecksPassed: tagIntegrityChecksPassed,
                defaultMessage: 'Failure with the following user tags',
                getItemLabel: () => {
                  // TODO: This is a stop gap for missing info from BE
                  // Need user info for the members in the tag
                  return name
                },
              })

              tagIntegrityChecksPassed = passed
              const oldIntegrityCheck = tagIntegrityChecks[environmentId]
              if (oldIntegrityCheck) {
                Object.keys(updatedIntegrityCheck.failures).forEach(key => {
                  updatedIntegrityCheck.failures[key] = uniq([
                    ...(oldIntegrityCheck.failures[key] || []),
                    ...(updatedIntegrityCheck.failures[key] || []),
                  ])
                })

                updatedIntegrityCheck.succeeded = oldIntegrityCheck.succeeded
                  ? updatedIntegrityCheck.succeeded
                  : oldIntegrityCheck.succeeded
              }

              tagIntegrityChecks[environmentId] = updatedIntegrityCheck
            }
          })
        })

        pap.userTagIntegrityChecks = tagIntegrityChecks
        pap.userTagIntegrityChecksPassed = tagIntegrityChecksPassed

        newScopeArray.push(pap)
      })

      return newScopeArray
    }

    return []
  }
)

/** Returns the selected entity's paps */
export const getEnvironmentAccessList = createSelector(
  ({ state }) => getSelectedApplicationRootId(state),
  ({ environmentId }) => environmentId,
  ({ state }) => get(state, `userAppPaps.tenantAppPaps`, {}),
  ({ state }) => get(state, 'userAppPaps.appAccessStatus.accessStatusList', []),
  (appId, envId, allPaps, statusList) => {
    const paps = get(allPaps, [appId, envId], {})

    const accessList = []

    Object.values(paps).forEach(pap => {
      const accessedPaps = orderBy(
        statusList.filter(
          item => item.papId === pap.papId && envId === item.environmentId
        ),
        ['accessStatusId'],
        ['desc']
      )

      if (accessedPaps.length) {
        accessList.push(accessedPaps[0])
      }
    })

    return accessList
  }
)

/** Returns the selected entity's paps */
export const getIsFetchingUserAppPaps = createSelector(
  ({ state }) => get(state, 'userAppPaps.fetching', {}),
  ({ appId }) => appId,
  (appPapsFetching, appId) => {
    const appFetching = appPapsFetching[appId]

    if (appFetching) {
      return Object.values(appFetching).some(app => app)
    }

    return false
  }
)

const generateIntegrityCheck = ({
  integrityChecksPassed,
  identifier,
  integrityChecks,
  integrityCheck,
  getItemLabel,
  defaultMessage,
}) => {
  const { succeeded, message } = integrityCheck
  let newIntegrityCheckPassed = integrityChecksPassed
  const oldIntegrityCheck = integrityChecks[identifier]

  if (!succeeded && integrityChecksPassed) {
    newIntegrityCheckPassed = false
  }

  if (succeeded) {
    let updatedIntegrityCheck = oldIntegrityCheck

    if (!updatedIntegrityCheck) {
      updatedIntegrityCheck = {
        failures: {},
        succeeded,
      }
    }

    return [newIntegrityCheckPassed, updatedIntegrityCheck]
  }

  let newIntegrityCheck
  let messageKey = message || 'Failure with'

  if (defaultMessage) {
    messageKey = defaultMessage
  }

  const itemLabel = getItemLabel(integrityCheck)

  if (oldIntegrityCheck) {
    const oldFailures = oldIntegrityCheck.failures
    const oldMessageArray = oldFailures[message]

    const newMessageArray = oldMessageArray
      ? [...oldMessageArray, itemLabel]
      : [itemLabel]

    const newFailures = {
      ...oldFailures,
      [messageKey]: newMessageArray,
    }

    newIntegrityCheck = {
      ...oldIntegrityCheck,
      failures: newFailures,
      succeeded,
    }
  } else {
    newIntegrityCheck = {
      failures: {
        [messageKey]: [itemLabel],
      },
      succeeded,
    }
  }

  return [newIntegrityCheckPassed, newIntegrityCheck]
}
