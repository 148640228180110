import React, { memo, useEffect, useState } from 'react'
import { connect } from 'react-redux'

import SummaryStats from './SummaryStats'

import * as api from 'services/api'

import { getSelectedApplicationRootId } from 'selectors/application_root'

import {
  getSelectedEnvironmentId,
  getSelectedEnvironmentGroupId,
} from 'selectors/environment'

const REPORT_ID = '5C3fWw6XQFRt9aEena8B'

function SummaryStatsContainer({ appId, environmentId, environmentGroupId }) {
  const [reportData, setReportData] = useState(null)
  const [isError, setIsError] = useState(null)

  useEffect(() => {
    async function getReportData() {
      setIsError(false)
      setReportData(null)

      const targetId = environmentId || environmentGroupId

      if (!targetId) {
        return
      }

      try {
        const response = await api.fetchReportDataForEnv({
          appId,
          envId: targetId,
          reportId: REPORT_ID,
        })
        setReportData(response.data.data[0])
      } catch (error) {
        setIsError(true)
        console.log(error)
      }
    }

    getReportData()
  }, [appId, environmentId, environmentGroupId])

  return (
    <SummaryStats
      summaryData={reportData}
      appId={appId}
      isError={isError}
      environmentId={environmentId}
    />
  )
}

const mapStateToProps = state => ({
  appId: getSelectedApplicationRootId(state),
  environmentId: getSelectedEnvironmentId(state),
  environmentGroupId: getSelectedEnvironmentGroupId(state),
})

export default connect(mapStateToProps)(memo(SummaryStatsContainer))
