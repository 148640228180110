import React, {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import Mark from 'mark.js'
import styled from 'styled-components'
import Spinner from 'britive-ui-components/core/components/Spinner'
import { Tabs } from 'britive-ui-components/core/components/Tabs'
import Drawer from 'components/Drawer'

import useMaxTableHeight from 'hooks/useMaxTableHeight'

import { getHeaderSpacing } from 'utils/elementMeasurement'
import Table from 'components/table/TableV2/Table'

const HighlightWrapper = styled.div`
  .added {
    background: none;
    color: green;
  }

  .deleted {
    background: none;
    color: red;
  }

  pre {
    background: var(--gallery);
    padding: 4px;
    margin-right: 8px;
  }

  .text {
    display: flex;
  }
`

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    position: 'sticky',
    zIndex: 4,
    top: 0,
    background: '#fff',
  },
}

function ScanDetailsDrawer({
  data,
  tabs,
  type,
  isOpen,
  isLoading,
  getColumns,
  toggleDrawer,
  dropdownFilter,
  definitionDifferences,
}) {
  const availableHeight = useMaxTableHeight({
    heights: [
      88, // title & padding
      56, // prev current title
    ],
  })

  const definitionsRef = useRef()
  const [addedMarkInstance, setAddedMarkInstance] = useState(null)
  const [deletedMarkInstance, setDeletedMarkInstance] = useState(null)

  useEffect(() => {
    highlightText()
  }, [definitionDifferences, addedMarkInstance, deletedMarkInstance])

  const jsonRef = useCallback(node => {
    if (node !== null) {
      setupHighlightInstance()
    }
  }, [])

  function highlightText() {
    if (addedMarkInstance) {
      addedMarkInstance.unmark({
        done: () => {
          addedMarkInstance.mark(definitionDifferences.added || [], {
            separateWordSearch: false,
            exclude: [],
            className: 'added',
          })
        },
      })
    }

    if (deletedMarkInstance) {
      deletedMarkInstance.unmark({
        done: () => {
          deletedMarkInstance.mark(definitionDifferences.deleted || [], {
            separateWordSearch: false,
            exclude: [],
            className: 'deleted',
          })
        },
      })
    }
  }

  function setupHighlightInstance() {
    const diffEl = definitionsRef.current

    if (diffEl) {
      const currentPermissions = diffEl.querySelector('.current')
      const prevPermissions = diffEl.querySelector('.previous')
      const addedMarkInstance = new Mark(currentPermissions, {
        acrossElements: true,
        caseSensitive: false,
      })

      const deletedMarkInstance = new Mark(prevPermissions, {
        acrossElements: true,
        caseSensitive: false,
      })

      setAddedMarkInstance(addedMarkInstance)
      setDeletedMarkInstance(deletedMarkInstance)
    }
  }

  function renderDrawerContent() {
    const { hasDefinitionsChanged, hasMembershipsChanged } = data
    const availablePermissionsHeight =
      (availableHeight - (hasMembershipsChanged ? 300 : 0)) / 2
    const availableTableHeight =
      availableHeight - (hasDefinitionsChanged ? availablePermissionsHeight : 0)
    return (
      <HighlightWrapper ref={jsonRef}>
        <div ref={definitionsRef}>
          {hasDefinitionsChanged && (
            <Fragment>
              {definitionDifferences.After && (
                <Fragment>
                  <span>After: </span>
                  <pre
                    className="current"
                    style={{ maxHeight: availablePermissionsHeight }}
                  >
                    {definitionDifferences.After.map((s, index) => (
                      <span
                        key={index}
                        className={'text' + (s.startsWith('+++') ? ' added' : '')}
                      >
                        {s.replace('+++', '')}
                      </span>
                    ))}
                  </pre>
                </Fragment>
              )}
              {definitionDifferences.Before && (
                <Fragment>
                  <span>Before: </span>
                  <pre
                    className="previous"
                    style={{ maxHeight: availablePermissionsHeight }}
                  >
                    {definitionDifferences.Before.map((s, index) => (
                      <span
                        key={index}
                        className={'text' + (s.startsWith('---') ? ' deleted' : '')}
                      >
                        {s.replace('---', '')}
                      </span>
                    ))}
                  </pre>
                </Fragment>
              )}
            </Fragment>
          )}
        </div>

        {(hasMembershipsChanged || type === 'Accounts') && (
          <div style={{ zIndex: 4, maxHeight: availableTableHeight }}>
            <Tabs>
              {tabs.map(tab => (
                <div label={tab.name} key={tab.name}>
                  <Table
                    dataUrl={tab.dataUrl}
                    columns={getColumns(tab.nameIdentifier)}
                    identifier={tab.nameIdentifier}
                    params={{ showDelta: true }}
                    dropdownFilter={dropdownFilter}
                    emptyTableMessage="No data was found."
                    maxHeight={availableTableHeight - 50}
                    noUrlQueryParams
                  />
                </div>
              ))}
            </Tabs>
          </div>
        )}
      </HighlightWrapper>
    )
  }

  if (!isOpen) {
    return null
  }

  const { nativeName, name } = data

  return (
    <Drawer
      isOpen={isOpen}
      top={`${getHeaderSpacing()}px`}
      id="scanDetailsChanges"
      toggleDrawer={toggleDrawer}
      width={'50%'}
    >
      <div style={styles.wrapper}>
        <h5 style={styles.title}>Changes for: {nativeName || name}</h5>

        {isLoading ? <Spinner size="2x" /> : renderDrawerContent()}
      </div>
    </Drawer>
  )
}

export default memo(ScanDetailsDrawer)
