import React, { memo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import styled from 'styled-components'

import Button from 'britive-ui-components/core/components/Button'

import InfoModals from './components/InfoModals'
import PropertiesForm from './components/PropertiesForm'
import EnvironmentForm from './components/EnvironmentForm'
import EnvironmentGroupForm from './components/EnvironmentGroupForm'
import EnvironmentCreationModal from './components/EnvironmentCreationModal'

import * as text from 'translations/english_us'

import './Properties.css'
import EnvironmentDeleteModalWrapper from './components/EnvironmentDeleteModalWrapper'

const Wrapper = styled.div`
  padding: 8px;
  position: relative;
  .tabs > .tab-list {
    margin-bottom: 10px;
  }
`

const CreateWrapper = styled.div`
  position: absolute;
  z-index: 4;
  right: 14px;
  top: 8px;
`

function AdminTenantProperties(props) {
  const [propertyTypes, setPropertyTypes] = useState({})

  useEffect(() => {
    const {
      selectedApplicationRoot,
      selectedApplicationTemplate,
      updateFieldsState,
    } = props

    const app =
      get(selectedApplicationRoot, 'catalogApplication') ||
      selectedApplicationTemplate

    const newPropertyTypes = {}
    const { propertyTypes = [] } = app

    propertyTypes.map(type => {
      return (newPropertyTypes[type.name] = type)
    })

    if (!fields.propertyTypes || !fields.propertyTypes.appAccessMethod) {
      let updatedFields

      if (!fields.propertyTypes) {
        updatedFields = {
          propertyTypes: {},
        }
      }

      // TODO: Broke 4/8. BE stop sending appAccessMethod for AWS 2.0
      if (get(newPropertyTypes, 'appAccessMethod.value')) {
        updatedFields = {
          propertyTypes: {
            appAccessMethod: get(newPropertyTypes, 'appAccessMethod.value', null),
          },
        }
      }

      if (updatedFields) {
        updateFieldsState(updatedFields)
      }
    }

    setPropertyTypes(newPropertyTypes)
  }, [props.selectedApplicationRoot, props.selectedApplicationTemplate])

  function renderAppProperties() {
    const {
      appHeaderHeight,
      isApplicationRoot,
      selectedApplicationTemplate,
      selectedApplicationRoot,
      location,
      isEnvironmentGroup,
      history,
      isEnvironment,
      selectedEnvironmentId,
      selectedEnvironmentGroupId,
      thisAppManage,
    } = props

    const app =
      get(selectedApplicationRoot, 'catalogApplication') ||
      selectedApplicationTemplate

    const selectedEntityId =
      selectedEnvironmentId ||
      selectedEnvironmentGroupId ||
      get(selectedApplicationRoot, 'appContainerId')

    if (app && app.propertyTypes) {
      switch (true) {
        case isApplicationRoot:
          return (
            <PropertiesForm
              {...props}
              adaEnabled={adaEnabled}
              appHeaderHeight={appHeaderHeight}
              app={app}
              appRoot={selectedApplicationRoot}
              location={location}
              history={history}
              propertyTypes={propertyTypes}
              selectedApplicationRoot={selectedApplicationRoot}
              selectedEntityId={selectedEntityId}
              thisAppManage={thisAppManage}
            />
          )
        case isEnvironment:
          // TODO: attach history in environment form container
          return (
            <EnvironmentForm
              {...props}
              appHeaderHeight={appHeaderHeight}
              app={app}
              appRoot={selectedApplicationRoot}
              location={location}
              propertyTypes={propertyTypes}
              selectedApplicationRoot={selectedApplicationRoot}
              selectedEntityId={selectedEntityId}
              thisAppManage={thisAppManage}
            />
          )

        case isEnvironmentGroup:
          return (
            <EnvironmentGroupForm
              {...props}
              appHeaderHeight={appHeaderHeight}
              app={app}
              appRoot={selectedApplicationRoot}
              location={location}
              propertyTypes={propertyTypes}
              selectedApplicationRoot={selectedApplicationRoot}
              selectedEntityId={selectedEntityId}
            />
          )
        default:
          return (
            <PropertiesForm
              {...props}
              app={app}
              appHeaderHeight={appHeaderHeight}
              appRoot={selectedApplicationRoot}
              location={location}
              propertyTypes={propertyTypes}
              selectedApplicationRoot={selectedApplicationRoot}
              thisAppManage={thisAppManage}
            />
          )
      }
    }
  }

  const {
    createNewMode,
    entityType,
    fields,
    selectedApplicationRoot,
    successModalOpen,
    saveSuccessful,
    saveError,
    isCreationModalOpen,
    isDeletionModalOpen,
    toggleCreationModal,
    toggleDeletionModal,
    testModalResultsOpen,
    testModalResultsSuccess,
    testMessage,
    selectedApplicationRootGroupId,
    scanModalResultsOpen,
    scanModalData,
    supportsScanning,
    selectedEntityId,
    selectEntity,
    adaEnabled,
    // actions
    toggleModal,
    thisAppManage,
  } = props

  const appId = get(selectedApplicationRoot, 'appContainerId')
  return (
    <Wrapper>
      <CreateWrapper>
        {thisAppManage &&
          !createNewMode &&
          !supportsScanning &&
          entityType !== 'application' && (
            <Button onClick={toggleDeletionModal} style={{ marginRight: 10 }}>
              Delete
            </Button>
          )}

        {thisAppManage &&
          !createNewMode &&
          !supportsScanning &&
          entityType !== 'environment' && (
            <Button onClick={toggleCreationModal} color="primary">
              Create Entity
            </Button>
          )}
      </CreateWrapper>

      {renderAppProperties()}

      <InfoModals
        typeOfUnit={text.APPLICATION}
        unitName={get(selectedApplicationRoot, 'catalogAppDisplayName')}
        successModalOpen={successModalOpen}
        saveSuccessful={saveSuccessful}
        saveError={saveError}
        testModalResultsOpen={testModalResultsOpen}
        testModalResultsSuccess={testModalResultsSuccess}
        testMessage={testMessage}
        scanModalResultsOpen={scanModalResultsOpen}
        scanModalData={scanModalData}
        toggleModal={toggleModal}
      />

      <EnvironmentCreationModal
        entityId={
          entityType === 'application'
            ? selectedApplicationRootGroupId
            : selectedEntityId
        }
        appId={appId}
        isModalOpen={isCreationModalOpen}
        toggleModal={toggleCreationModal}
      />

      <EnvironmentDeleteModalWrapper
        toggleModal={toggleDeletionModal}
        isModalOpen={isDeletionModalOpen}
        selectedEntityId={selectedEntityId}
        entityType={entityType}
        appId={appId}
        selectEntity={selectEntity}
        cleselectedApplicationRoot={selectedApplicationRoot}
      />
    </Wrapper>
  )
}

AdminTenantProperties.propTypes = {
  createNewMode: PropTypes.bool,
  createApp: PropTypes.func,
  dropdownOpen: PropTypes.bool,
  fetching: PropTypes.bool,
  fields: PropTypes.object,
  handleChange: PropTypes.func,
  isApplicationRoot: PropTypes.bool,
  location: PropTypes.object,
  match: PropTypes.object,
  onCancel: PropTypes.func,
  onClickMoveNextTab: PropTypes.func,
  saveBtnAction: PropTypes.string,
  saveUpdates: PropTypes.func,
  saving: PropTypes.bool,
  scanApplicationRoot: PropTypes.func,
  scanInProgress: PropTypes.bool,
  selectedApplicationRoot: PropTypes.object,
  successModalOpen: PropTypes.bool,
  scanModalData: PropTypes.object,
  testModalResultsOpen: PropTypes.bool,
  testModalResultsSuccess: PropTypes.bool,
  testMessage: PropTypes.string,
  scanModalResultsOpen: PropTypes.bool,
  scanModalResultsSuccess: PropTypes.bool,
  scanPassed: PropTypes.bool,
  testPassed: PropTypes.bool,
  testInProgress: PropTypes.bool,
  toggleModal: PropTypes.func,
  toggle: PropTypes.func,
  updateFieldsState: PropTypes.func,
  updateSaveBtnAction: PropTypes.func,
  adaEnabled: PropTypes.bool,
}

export default memo(AdminTenantProperties)
