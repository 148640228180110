import React, { useState, useEffect } from 'react'
import NotificationCard from 'britive-design-system/core/components/notification-card'
import Spinner from 'britive-design-system/core/components/spinner'
import { translatedStrings, approvalLoadingMessage, classes } from '../constants'
import { capitalize } from 'lodash'
import './MyApprovalsMobileView.scss'
import {
  convertTimeToCurrentTimezone,
  getConsumerType,
  sortApprovalsAndRequestByDate,
} from '../../../utils'
import { papConsumer } from '../../../my-requests/request-modal/constants'

const MyApprovalsMobileView = ({
  getMyApprovalsListData,
  fetchUsers,
  usersData,
  usersLoading,
  myApprovalsListState,
  match,
  history,
}) => {
  const [myApprovalsList, setMyApprovalsList] = useState([])
  const { loading, result } = myApprovalsListState

  useEffect(() => {
    fetchUsers()
    getMyApprovalsListData()
  }, [])

  useEffect(() => {
    const approvals = result?.map(approval => ({
      requestId: approval?.requestId || translatedStrings.noneValue,
      createdAt:
        convertTimeToCurrentTimezone(approval?.createdAt) ||
        translatedStrings.noneValue,
      userId: approval?.userId || translatedStrings.noneValue,
      action: approval?.action || translatedStrings.noneValue,
      resource: approval?.resource || translatedStrings.noneValue,
      status: approval?.status || translatedStrings.noneValue,
      type: approval?.consumer || translatedStrings.noneValue,
    }))
    setMyApprovalsList(sortApprovalsAndRequestByDate(approvals))
  }, [result, usersData])

  const getCardMessage = (userId, resource, action, type) => {
    return `${userId} has requested ${action?.split('.')[2]} access for ${
      type === papConsumer ? 'profile' : 'secret'
    } ${resource}.`
  }

  return (
    <>
      {loading || usersLoading ? (
        <div className={classes.spinnerContainer}>
          <Spinner size="medium" message={approvalLoadingMessage} />
        </div>
      ) : (
        myApprovalsList?.map(
          ({ requestId, createdAt, status, type, userId, resource, action }) => (
            <div key={requestId} className={classes.notificationContainer}>
              <NotificationCard
                loading={loading || usersLoading}
                loadingMessage="Loading Approvals"
                notificationTime={createdAt}
                notificationMessage={getCardMessage(userId, resource, action, type)}
                notificationTitle={getConsumerType(type)}
                primaryActionLabel={status === 'PENDING' ? 'Review' : 'View'}
                primaryActionHandler={() =>
                  history.push(`${match.url}/view/${requestId}`)
                }
                secondaryActionLabel={capitalize(status)}
                secondaryActionHandler={() =>
                  history.push(`${match.url}/view/${requestId}`)
                }
              />
            </div>
          )
        )
      )}
    </>
  )
}

export default MyApprovalsMobileView
