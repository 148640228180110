import React, { memo, useMemo } from 'react'
import styled from 'styled-components'
import get from 'lodash/get'
import FontAwesome from 'react-fontawesome'
import capitalize from 'lodash/capitalize'

import TableEllipsisCell from 'components/table/TableV2/TableEllipsisCell'
import { BackgroundTooltip as Tooltip } from 'components/Tooltips'

const PERMISSION_TYPE_COLORS = {
  role: 'var(--endeavour)',
  profile: 'var(--royal-blue)',
  policy: 'var(--shamrock)',
  InlinePolicy: 'var(--malibu)',
  group: 'var(--bittersweet)',
}

const ListTableCell = styled(TableEllipsisCell)`
  div {
    max-width: unset;
    white-space: nowrap;
  }

  &:last-of-type {
    margin-bottom: 0px;
  }
`

const InheritanceInfo = styled.span`
  margin-left: 2px;

  > span {
    color: green;
    margin-right: 2px;
    font-size: 10px;
  }
`

const AssignedWrapper = styled(ListTableCell)`
  cursor: pointer;
  height: 20px;
  overflow: hidden;

  .assignedListItem {
    display: inline;

    &:after {
      content: ', ';
    }

    &.noComma:after {
      content: '';
    }
  }

  > .iconWrapper {
    display: inline;
  }

  .icon {
    margin-left: 4px;
    margin-right: 1px;
    display: inline-block;
    border: 2px solid var(--mine-shaft);
    padding: 1px 2px;
    border-radius: 50%;
    font-size: 10px;
    text-align: center;
    color: var(--mine-shaft);
    font-style: normal;
    line-height: 10px;
    font-weight: 600;
  }
`

function AssignedList({ original: data, onClick }) {
  const assignedCount = useMemo(() => {
    const { accounts, groups, permissions } = data
    return (
      get(accounts, 'length', 0) +
      get(groups, 'length', 0) +
      get(permissions, 'length', 0)
    )
  }, [data.accounts, data.groups, data.permissions])

  const { accounts, groups, permissions } = data
  let list = []
  if (accounts) {
    list = [...list, ...accounts]
  }
  if (groups) {
    list = [...list, ...groups]
  }
  if (permissions) {
    list = [...list, ...permissions]
  }
  if (list) {
    list = list.sort((a, b) =>
      (a.nativeName || a.name).localeCompare(b.nativeName || b.name)
    )
    if (assignedCount === 0) {
      return ''
    }
  }
  const tooltipId = `${data.appPermissionId}Tooltip`

  return (
    <AssignedWrapper onClick={onClick} style={{ textOverflow: 'ellipsis' }}>
      {list.map((item, idx) => {
        let { nativeName, name, inherited, type } = item
        let displayName = nativeName || name
        const lastItem = idx === list.length - 1
        return (
          <div
            className={`assignedListItem ${lastItem ? 'noComma' : ''}`}
            key={displayName}
          >
            {displayName}

            {inherited && (
              <InheritanceInfo>
                (<FontAwesome name="level-down-alt" />
                {inherited})
              </InheritanceInfo>
            )}

            {type && (
              <span className="iconWrapper">
                <div
                  data-for={tooltipId}
                  data-tip={capitalize(type)}
                  className="icon"
                  style={{
                    color: PERMISSION_TYPE_COLORS[type] || 'var(--medium-purple)',
                    borderColor:
                      PERMISSION_TYPE_COLORS[type] || 'var(--medium-purple)',
                  }}
                >
                  {capitalize(type[0])}
                </div>
              </span>
            )}
          </div>
        )
      })}

      <Tooltip
        effect="solid"
        place="bottom"
        getContent={content => content}
        id={tooltipId}
      />
    </AssignedWrapper>
  )
}

export default memo(AssignedList)
