import isEmpty from 'lodash/isEmpty'

export const allowCopyingValue = propertyObj => {
  return ['clientId', 'oidcIssuerUrl', 'redirectUrl', 'scope'].includes(
    propertyObj.name
  )
}

export const isLoginUrlPresentForOpenshift = fields => {
  const propertyTypes = fields?.propertyTypes || {}

  if ('appAccessMethod_static_loginUrl' in propertyTypes) {
    return !isEmpty(propertyTypes?.appAccessMethod_static_loginUrl?.trim())
  }

  return true
}
