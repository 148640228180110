import React from 'react'
import { Route, Switch } from 'react-router-dom'

import ScanStatus from './ScanStatus'
import ScanDetails from './ScanDetails'
import OrgScanDetails from './OrgScanDetails'

function ScanningRouter(props) {
  const { match, setBreadcrumbTrail, appHeaderHeight } = props

  return (
    <Switch>
      <Route
        exact
        path={`${match.url}/scans`}
        render={() => (
          <ScanStatus
            {...props}
            appHeaderHeight={appHeaderHeight}
            setBreadcrumbTrail={setBreadcrumbTrail}
          />
        )}
      />

      <Route
        exact
        path={`${match.url}/scans/org`}
        render={localProps => (
          <OrgScanDetails
            {...props}
            {...localProps}
            appHeaderHeight={appHeaderHeight}
            setBreadcrumbTrail={setBreadcrumbTrail}
          />
        )}
      />

      <Route
        path={`${match.url}/scans/:envId`}
        render={props => (
          <ScanDetails
            {...props}
            appHeaderHeight={appHeaderHeight}
            setBreadcrumbTrail={setBreadcrumbTrail}
          />
        )}
      />
    </Switch>
  )
}

export default ScanningRouter
