import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import './index.css'

class Breadcrumbs extends PureComponent {
  render() {
    return <Fragment>{this.props.children}</Fragment>
  }
}

Breadcrumbs.propTypes = {
  children: PropTypes.element,
}

export default Breadcrumbs
