import React, { Fragment } from 'react'
import { FormGroup, Label, Input } from 'reactstrap'
import styled from 'styled-components'
import DatePicker from 'react-datepicker'
import pluralize from 'pluralize'

import { BasicSelect } from 'components/Selects'

import { WEEKLY, MONTHLY, HOURLY } from './ScanDrawerContainer'

const Select = styled(BasicSelect)`
  min-height: unset;
  width: 136px;
`

const Wrapper = styled.div`
  margin: 10px 0;

  > fieldset {
    margin-left: 8px;
    margin-bottom: 16px;
  }

  .form-check {
    display: flex;
    align-items: center;

    &.active {
      z-index: 2;
    }
  }

  .form-check-label {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 32px;

    > input {
      margin-top: -1.5px;
    }
  }

  .react-datepicker-wrapper {
    margin-left: 8px;

    input {
      text-align: center;
      width: 80px;
    }
  }

  .react-datepicker__triangle {
    margin-left: -4px;
  }

  .react-datepicker__time-container {
    width: 80px !important;
  }

  .react-datepicker__time-box {
    width: 80px !important;
  }

  .frequencyLabel {
    display: inline-flex;
    min-width: 100px;
  }

  .detailWrapper {
    display: flex;
    align-items: center;

    > span {
      margin: 0 10px;
    }
  }
`

const Error = styled.div`
  color: red;
  margin-top: -5px;
`

function FrequencyRadioButtons(props) {
  const {
    intervalTypes,
    frequencyType,
    frequencyInterval,
    startTime,
    hourOptions,
    dayOptions,
    daysOfWeekOptions,
    selectFrequencyType,
    selectInterval,
    selectTime,
    selectTimeFromInput,
    timeZoneCode,
    touched,
    errors,
  } = props

  return (
    <Wrapper>
      <h6>Frequency</h6>
      {intervalTypes.map(option => {
        const isChecked = option === frequencyType
        return (
          <FormGroup
            check
            key={option}
            className={`${option}Frequency${isChecked ? ' active' : ''}`}
          >
            <Label check>
              <Input
                type="radio"
                name="frequencyType"
                value={option}
                checked={isChecked}
                onChange={selectFrequencyType}
                disabled={false}
              />

              <span className="frequencyLabel">{option}</span>
            </Label>

            <Fragment>
              {isChecked && (
                <div className="detailWrapper">
                  {frequencyType === HOURLY && (
                    <Fragment>
                      <span>Every</span>
                      <Select
                        options={hourOptions}
                        value={frequencyInterval}
                        onChange={selectInterval}
                        isSearchable={false}
                        placeholder="Hour"
                      />
                      <span>
                        {pluralize(
                          'hour',
                          frequencyInterval ? frequencyInterval.value : 1
                        )}
                      </span>
                    </Fragment>
                  )}

                  {frequencyType === WEEKLY && (
                    <Fragment>
                      <Select
                        options={daysOfWeekOptions}
                        value={frequencyInterval}
                        onChange={selectInterval}
                        isSearchable={false}
                        placeholder="Day of Week"
                      />

                      <span>at</span>
                    </Fragment>
                  )}

                  {frequencyType === MONTHLY && (
                    <Fragment>
                      <Select
                        options={dayOptions}
                        value={frequencyInterval}
                        onChange={selectInterval}
                        isSearchable={false}
                        placeholder="Day of Month"
                      />

                      <span>Day</span>
                    </Fragment>
                  )}

                  {frequencyType !== HOURLY && (
                    <Fragment>
                      <DatePicker
                        selected={startTime}
                        onChange={selectTime}
                        onBlur={selectTimeFromInput}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        popperProps={{
                          placement: 'bottom',
                          modifiers: {
                            flip: {
                              enabled: false,
                            },
                            preventOverflow: {
                              escapeWithReference: true,
                            },
                          },
                        }}
                        dateFormat="HH:mm"
                        timeFormat="HH:mm"
                        placeholder="HH:MM"
                      />

                      <span>{timeZoneCode}</span>
                    </Fragment>
                  )}
                </div>
              )}
            </Fragment>
          </FormGroup>
        )
      })}
      {errors.frequencyInterval && (
        <Error>{touched.frequencyInterval && errors.frequencyInterval}</Error>
      )}
    </Wrapper>
  )
}

export default FrequencyRadioButtons
