import * as actions from 'actions'
export const papPoliciesReducerKey = 'papPolicy'

const initialState = {
  fetchingPapPolicies: false,
  deletingPapPolicy: false,
  changingPapPolicyStatus: false,
  papPolicies: [],
  scimProviders: [],
  ssoProviders: [],
  scimAttributes: [],
  scimUserMapping: [],
  isUploadingMetadata: false,
  isCreatingSCIMToken: false,
  isDeletingSCIMToken: false,
  scimToken: null,
  error: null,
  cacheData: null,
  submitProfileLoading: false,
  submitProfileError: null,
  deleteProfileLoading: false,
  deleteProfileError: null,
}

export function papPoliciesReducer(state = initialState, action) {
  switch (action.type) {
    case `${actions.GET_POLICIES}_PENDING`: {
      return {
        ...state,
        fetchingPapPolicies: true,
      }
    }

    case `${actions.GET_POLICIES}_FULFILLED`: {
      const papPolicies = action.payload.data

      return {
        ...state,
        fetchingPapPolicies: false,
        papPolicies,
      }
    }

    case `${actions.GET_POLICIES}_REJECTED`: {
      return {
        ...state,
        fetchingPapPolicies: false,
        error: action.payload,
      }
    }

    case `${actions.CHANGE_PAP_POLICY_STATUS}_PENDING`: {
      return {
        ...state,
        changingPapPolicyStatus: true,
      }
    }

    case `${actions.CHANGE_PAP_POLICY_STATUS}_FULFILLED`: {
      const { id, isActive } = action.meta
      const oldPapPolicies = [...state.papPolicies]
      const papPolicies = oldPapPolicies.map(papPolicy => {
        if (papPolicy.id === id) {
          papPolicy = {
            ...papPolicy,
            isActive,
          }
        }
        return papPolicy
      })
      return {
        ...state,
        changingPapPolicyStatus: false,
        papPolicies,
      }
    }

    case `${actions.CHANGE_PAP_POLICY_STATUS}_REJECTED`: {
      return {
        ...state,
        changingPapPolicyStatus: false,
        error: action.payload,
      }
    }

    case `${actions.DELETE_PAP_POLICY}_PENDING`: {
      return {
        ...state,
        deletingPapPolicy: true,
      }
    }

    case `${actions.DELETE_PAP_POLICY}_FULFILLED`: {
      const { id } = action.meta
      const oldPapPolicies = [...state.papPolicies]
      const papPolicies = oldPapPolicies.filter(papPolicy => papPolicy.id !== id)
      return {
        ...state,
        deletingPapPolicy: false,
        papPolicies,
      }
    }

    case `${actions.DELETE_PAP_POLICY}_REJECTED`: {
      return {
        ...state,
        deletingPapPolicy: false,
        error: action.payload,
      }
    }

    case `${actions.CACHE_PAP_POLICY_DATA}_FULFILLED`: {
      return {
        ...state,
        cacheData: action.payload,
      }
    }

    case `${actions.ACCESS_REQUEST_SUBMIT_PROFILE}_PENDING`: {
      return {
        ...state,
        submitProfileLoading: true,
      }
    }

    case `${actions.ACCESS_REQUEST_SUBMIT_PROFILE}_FULFILLED`: {
      return {
        ...state,
        submitProfileLoading: false,
        submitProfileError: null,
      }
    }

    case `${actions.ACCESS_REQUEST_SUBMIT_PROFILE}_REJECTED`: {
      return {
        ...state,
        submitProfileLoading: false,
        submitProfileError: action.payload,
      }
    }

    case `${actions.ACCESS_REQUEST_DELETE_PROFILE}_PENDING`: {
      return {
        ...state,
        deleteProfileLoading: true,
        deleteProfileError: null,
      }
    }

    case `${actions.ACCESS_REQUEST_DELETE_PROFILE}_FULFILLED`: {
      return {
        ...state,
        deleteProfileLoading: false,
        deleteProfileError: null,
      }
    }

    case `${actions.ACCESS_REQUEST_DELETE_PROFILE}_REJECTED`: {
      return {
        ...state,
        deleteProfileLoading: false,
        deleteProfileError: action.payload,
      }
    }

    default:
      return { ...state }
  }
}
