import get from 'lodash/get'
import * as actions from 'actions'

const initialState = {
  loading: false,
  error: null,
  data: [],
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case `${actions.FETCH_DOWNLOADABLE_BROKERS}_PENDING`: {
      return {
        ...state,
        loading: true,
        error: null,
      }
    }

    case `${actions.FETCH_DOWNLOADABLE_BROKERS}_FULFILLED`: {
      const data = get(action, 'payload.data.britive-broker', {})

      return {
        ...state,
        loading: false,
        error: null,
        data,
      }
    }

    case `${actions.FETCH_DOWNLOADABLE_BROKERS}_REJECTED`: {
      return {
        ...state,
        loading: false,
        error: 'Unable fetch the Brokers.',
      }
    }

    default:
      return { ...state }
  }
}
