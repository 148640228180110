const { useEffect, useState } = require('react')

const TITLE_FLASH_TIME = 1500
const singletonFlastTitle = {
  documentTitle: document.title || 'Britive',
  timeout: null,
  start: function(titleToBeFlashed) {
    if (!titleToBeFlashed) {
      clearTimeout(this.timeout)
      return
    }
    this.timeout = setTimeout(() => {
      let currentTitle = document.title
      document.title = titleToBeFlashed
      this.start(currentTitle)
    }, TITLE_FLASH_TIME)
  },
  stop: function() {
    if (this?.timeout) {
      clearTimeout(this.timeout)
      document.title = this.documentTitle
    }
  },
}

const useFlashTitle = () => {
  const [newTitle, setNewTitle] = useState('')
  const [isWindowFocus, setIsWindowFocus] = useState(true)

  useEffect(() => {
    const handleWindowFocus = event => {
      event.preventDefault()
      setIsWindowFocus(true)
    }

    const handleWindowBlur = event => {
      event.preventDefault()
      setIsWindowFocus(false)
    }

    window.addEventListener('blur', handleWindowBlur)
    window.addEventListener('focus', handleWindowFocus)
    return () => {
      window.removeEventListener('blur', handleWindowBlur)
      window.removeEventListener('focus', handleWindowFocus)
    }
  }, [])

  useEffect(() => {
    if (isWindowFocus) {
      singletonFlastTitle.stop()
    }
  }, [isWindowFocus])

  const startTitleFlash = () => {
    if (!isWindowFocus) {
      singletonFlastTitle.stop()
      singletonFlastTitle.start(newTitle)
    }
  }
  return [setNewTitle, startTitleFlash]
}

export default useFlashTitle
