// *** NOT USING THIS CURRENTLY. ADDED IT THEN DETERMINED
// NOT BEST FOR USE CASE. MAY COME IN HANDY IN THE FUTURE.
import { SET_ELEMENT_REF } from 'action_creators/element_ref'

export const elementRefReducerKey = 'elementRef'

const initialState = {
  elementInfo: {},
}

export function elementRefReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ELEMENT_REF: {
      const elementInfo = { ...state.elementInfo }
      const { name, height } = action.payload

      elementInfo[name] = height

      return {
        ...state,
        elementInfo,
      }
    }

    default:
      return { ...state }
  }
}
