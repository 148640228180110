import React from 'react'
import Tooltip from 'britive-design-system/core/components/tooltip'
import listIcon from 'static/images/list-tree.svg'

export const MembersCountCell = ({ count, onIconClick }) => (
  <div className="cell-with-icon">
    <span title="" data-testid="members-count-value">
      {count}
    </span>
    <Tooltip title={'View Members'} position="left">
      <span
        title=""
        data-testid="members-count-icon"
        className="cell-icon"
        onClick={onIconClick}
      >
        <img src={listIcon} />
      </span>
    </Tooltip>
  </div>
)
