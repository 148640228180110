import { SET_OPEN } from 'actions'

export const dropdownKey = 'dropdownValue'

export const dropdownReducer = (
  state = {
    profileName: false,
  },
  action
) => {
  switch (action.type) {
    case SET_OPEN: {
      const { isOpen, columnName } = action.payload

      return {
        ...state,
        [columnName]: isOpen,
      }
    }

    default:
      return { ...state }
  }
}
