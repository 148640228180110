import React, { Fragment, memo } from 'react'
import Button from 'britive-design-system/core/components/button'
import Spinner from 'britive-ui-components/core/components/Spinner'
import EditPencil from 'static/images/edit-pencil.svg'

const styles = {
  buttons: {
    display: 'flex',
    gap: '8px',
    marginTop: '4px',
  },
}

function FormButtons(props) {
  const {
    isEditing,
    isSaving,
    cancelEdit,
    saveScope,
    toggleEditState,
    updatingScope,
    allowAssociationsInProfiles,
  } = props

  switch (true) {
    case updatingScope || isEditing: {
      return (
        <div style={styles.buttons}>
          <Button variant="primary" disabled={isSaving} onClick={saveScope}>
            {isSaving ? <Spinner /> : null}&nbsp;Save
          </Button>
          <Button variant="secondary" disabled={isSaving} onClick={cancelEdit}>
            Cancel
          </Button>
        </div>
      )
    }

    default: {
      return (
        <Fragment>
          {allowAssociationsInProfiles && (
            <div style={styles.buttons}>
              <Button variant="primary" disabled={false} onClick={toggleEditState}>
                <img src={EditPencil} />
                &nbsp;&nbsp;Edit
              </Button>
            </div>
          )}
        </Fragment>
      )
    }
  }
}

export default memo(FormButtons)
