import React, { Fragment } from 'react'

import Button from 'britive-ui-components/core/components/Button'
import { withRouter } from 'react-router-dom'
import { getIsAuthenticated } from 'utils/authentication'

function Fallback({ history, path }) {
  if (!getIsAuthenticated()) {
    history.push('/login')
  }
  if (path) {
    history.push(path)
  }
  return (
    <Fragment>
      <div className="page-header-container">
        <h4 className="page-title">
          <i className="fas fa-exclamation-circle" style={{ color: '#ff5555' }} />{' '}
          Page not found
        </h4>
      </div>
      <div style={{ fontSize: 20, padding: 10 }}>
        The page you are looking for is not found
      </div>
      <div style={{ padding: 10 }}>
        <Button color="primary" onClick={() => history.push('/admin')}>
          Go Back
        </Button>
      </div>
    </Fragment>
  )
}

export default withRouter(Fallback)
