import React, { memo } from 'react'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import styled from 'styled-components'

const FailureWrapper = styled.div`
  margin-bottom: 8px;
  max-width: 400px;

  &:last-of-type {
    margin-bottom: 0;
  }

  ul {
    margin-bottom: 0;
  }
`

function EnvironmentGroupTooltip({ content, integrityChecks }) {
  if (!content) {
    return null
  }

  if (content === 'Success') {
    return 'Success'
  }

  const integrityCheckInfo = integrityChecks[content]
  const failures = get(integrityCheckInfo, 'failures', {})

  if (get(integrityCheckInfo, 'message')) {
    return integrityCheckInfo.message
  }

  if (isEmpty(failures)) {
    return 'Additional information unavailable'
  }

  return (
    <FailureWrapper>
      <ul>
        {failures.map(failure => (
          <li key={failure}>
            {failure.showPermissionName && <b>{failure.permissionName}:</b>}{' '}
            {failure.message}
          </li>
        ))}
      </ul>
    </FailureWrapper>
  )
}

export default memo(EnvironmentGroupTooltip)
