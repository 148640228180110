import {
  NOTIFICATION_CONNECTED,
  ALL_NOTIFICATIONS,
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATIONS,
  DELETE_NOTIFICATION,
  DELETE_ALL_NOTIFICATIONS,
} from 'actions'

export const pushNotificationReducerKey = 'pushNotification'

export function pushNotificationReducer(
  state = {
    fetchingNotifications: true,
    deletingNotifications: [],
    deletingAllNotifications: false,
    error: null,
    notifications: [],
    notificationsCount: 0,
  },
  action
) {
  switch (action.type) {
    case NOTIFICATION_CONNECTED: {
      return {
        ...state,
        fetchingNotifications: false,
      }
    }

    case ALL_NOTIFICATIONS: {
      return {
        ...state,
        notifications: action.payload,
      }
    }

    case ADD_NOTIFICATION: {
      return {
        ...state,
        notificationsCount: state.notificationsCount + 1,
        notifications: [...action.payload, ...state.notifications],
      }
    }

    case REMOVE_NOTIFICATIONS: {
      return {
        ...state,
        notifications: [...state.notifications].filter(
          notification => !action.payload.includes(notification.notificationId)
        ),
      }
    }

    case `${DELETE_NOTIFICATION}_PENDING`: {
      return {
        ...state,
        deletingNotifications: [
          action.meta.notificationId,
          ...state.deletingNotifications,
        ],
      }
    }

    case `${DELETE_NOTIFICATION}_FULFILLED`: {
      return {
        ...state,
        notifications: [...state.notifications].filter(notification => {
          return notification.notificationId !== action.meta.notificationId
        }),
        deletingNotifications: [...state.deletingNotifications].filter(
          notificationId => notificationId !== action.meta.notificationId
        ),
      }
    }

    case `${DELETE_NOTIFICATION}_REJECTED`: {
      return {
        ...state,
        deletingNotifications: [...state.deletingNotifications].filter(
          notificationId => notificationId !== action.meta.notificationId
        ),
      }
    }

    case `${DELETE_ALL_NOTIFICATIONS}_PENDING`: {
      return {
        ...state,
        deletingAllNotifications: true,
      }
    }

    case `${DELETE_ALL_NOTIFICATIONS}_FULFILLED`: {
      return {
        ...state,
        notifications: [],
        deletingNotifications: [],
        deletingAllNotifications: false,
      }
    }

    case `${DELETE_ALL_NOTIFICATIONS}_REJECTED`: {
      return {
        ...state,
        deletingNotifications: [],
        deletingAllNotifications: false,
      }
    }

    default:
      return { ...state }
  }
}
