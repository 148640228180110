import React, { useState } from 'react'
import Button from 'britive-design-system/core/components/button'
import Typography from 'britive-design-system/core/components/typography'
import * as routing from 'services/routing'
import Textarea from 'britive-design-system/core/components/textarea'
import { isEmpty, get } from 'lodash'
import {
  accessRequestSubmitProfile,
  accessRequestDeleteDraftProfile,
} from 'action_creators/access-request'
import Spinner from 'britive-design-system/core/components/spinner'
import { useSelector } from 'react-redux'
import toast from 'utils/toast'
import DialogPopup from 'britive-design-system/core/components/dialog'
import ModalPopup from 'britive-design-system/core/components/modal-popup'
import CloneProfile from '../components/clone-profile/CloneProfile'

const ProfileHeaderButtons = ({
  onRequest,
  appId,
  papId,
  onStepperChange,
  step,
  selectedPolicy,
  dispatch,
}) => {
  const styles = {
    requestButtonHeader: {
      background: '#e6eff6',
      paddingBottom: '10px',
    },
    requestButton: {
      paddingLeft: '10px',
    },
    buttonGroup: {
      display: 'flex',
      gap: '10px',
      paddingTop: '10px',
    },
    formHeader: {
      padding: '25px 0 25px 10px',
      display: 'flex',
      gap: '5%',
    },
    stepper: {
      display: 'flex',
      alignItems: 'center',
      gap: '20px',
    },
    circle: {
      borderRadius: '50%',
      width: '36px',
      height: '36px',
      padding: '8px',
      border: '1px solid #a8a8a8',
      textAlign: 'center',
    },
    stepOne: {
      backgroundColor: step === 1 && '#067fdb',
      color: step === 1 && '#f4f4f4',
    },
    stepTwo: {
      backgroundColor: step === 2 && '#067fdb',
      color: step === 2 && '#f4f4f4',
    },
    justification: {
      paddingTop: '15px',
      paddingLeft: '10px',
    },
  }
  const mode = window.location.pathname.split('/').pop()
  const [justification, setJustification] = useState('')
  const [error, setError] = useState(false)
  const [cloneProfileModalOpen, setCloneProfileModalOpen] = useState(false)
  const { submitProfileLoading, deleteProfileLoading } = useSelector(
    state => state.papPolicy
  )
  const [discardModalOpen, setDiscardModalOpen] = useState(false)

  const onJustificationChange = e => {
    setJustification(e.target.value)
    setError(false)
  }

  const profileFormStepper = () => {
    return (
      <>
        <div style={styles.formHeader}>
          <div style={styles.stepper}>
            <div
              style={{
                ...styles.circle,
                ...styles.stepOne,
              }}
            >
              1
            </div>
            <Typography variant="heading6">
              {mode === 'create' ? 'Setup a Profile' : 'Select a Policy'}
            </Typography>
          </div>
          <div style={styles.stepper}>
            <div
              style={{
                ...styles.circle,
                ...styles.stepTwo,
              }}
            >
              2
            </div>
            <Typography variant="heading6">Enter Justification</Typography>
          </div>
        </div>
        {step === 2 && justificationHandler()}
      </>
    )
  }

  const justificationHandler = () => {
    return (
      <div style={styles.justification}>
        <Textarea
          label="Justification"
          height="15vh"
          width="35%"
          value={justification}
          onChange={onJustificationChange}
          error={error}
          errorMsg="Justification is required."
        />
      </div>
    )
  }

  const submitHandler = async () => {
    if (isEmpty(justification)) {
      setError(true)
      return
    }

    let payload = {
      justification: justification,
      type: mode === 'create' ? 'REQUEST_NEW' : 'REQUEST_EXISTING_FOR_MYSELF',
      policies: selectedPolicy,
    }
    try {
      await dispatch(accessRequestSubmitProfile(appId, papId, payload))
      toast({
        title: `Profile Request submitted successfully.`,
        type: 'success',
        time: 'normal',
      })
      routing.requestAccessAppProfilesList({ appId })
    } catch (error) {
      toast({
        title: `Error while Submitting the profile.`,
        description: get(error, 'response.data.message', null),
        type: 'error',
        time: 'normal',
      })
    }
  }

  const clickHandler = () => {
    const urlMode = mode === 'draft' ? 'create' : 'request'
    onRequest('Policies', urlMode)
  }

  const discardHandler = async () => {
    try {
      await dispatch(accessRequestDeleteDraftProfile(papId))
      toast({
        title: `Draft Profile deleted successfully.`,
        type: 'success',
        time: 'normal',
      })
      routing.requestAccessAppProfilesList({ appId })
    } catch (error) {
      toast({
        title: `Error while Deleting the profile.`,
        description: get(error, 'response.data.message', null),
        type: 'error',
        time: 'normal',
      })
    }
  }

  return (
    <>
      {submitProfileLoading || deleteProfileLoading ? (
        <span>
          <Spinner size="medium" message="Loading" overlay={true} />
        </span>
      ) : (
        <>
          <div style={styles.requestButtonHeader}>
            <div style={styles.requestButton}>
              {mode === 'request' || mode === 'create' ? (
                <>
                  <Typography variant="heading5">
                    {mode === 'create' ? 'Create Profile' : 'Request Profile'}
                  </Typography>
                  <div style={styles.buttonGroup}>
                    {step === 2 ? (
                      <Button size="medium" onClick={() => submitHandler()}>
                        {'Submit'}
                      </Button>
                    ) : (
                      <Button
                        size="medium"
                        onClick={() => onStepperChange(2)}
                        disabled={isEmpty(selectedPolicy) ? true : false}
                      >
                        {'Next'}
                      </Button>
                    )}
                    {step === 2 && (
                      <Button
                        variant="secondary"
                        size="medium"
                        onClick={() => onStepperChange(1)}
                      >
                        {'Back'}
                      </Button>
                    )}
                    {mode === 'create' ? (
                      <Button
                        variant="secondary"
                        size="medium"
                        onClick={() => setDiscardModalOpen(true)}
                      >
                        {'Discard'}
                      </Button>
                    ) : (
                      <Button
                        variant="secondary"
                        size="medium"
                        onClick={() =>
                          routing.requestAccessAppProfilesList({ appId })
                        }
                      >
                        {'Cancel'}
                      </Button>
                    )}
                    {mode === 'create' && (
                      <Button
                        variant="secondary"
                        size="medium"
                        onClick={() =>
                          routing.requestAccessAppProfilesList({ appId })
                        }
                      >
                        {'Save as Draft'}
                      </Button>
                    )}
                  </div>
                </>
              ) : (
                <div style={styles.buttonGroup}>
                  <Button size="medium" onClick={() => clickHandler()}>
                    {mode === 'draft' ? 'Edit' : 'Request'}
                  </Button>
                  <Button
                    variant="secondary"
                    size="medium"
                    onClick={() => setCloneProfileModalOpen(true)}
                  >
                    {'Clone'}
                  </Button>
                  <Button
                    variant="secondary"
                    size="medium"
                    onClick={() => routing.requestAccessAppProfilesList({ appId })}
                  >
                    {'Close'}
                  </Button>
                </div>
              )}
            </div>
          </div>
          {(mode === 'request' || mode === 'create') && profileFormStepper()}
          {discardModalOpen && (
            <DialogPopup
              type="alert"
              title={'Discard Profile?'}
              message={'Are you sure you want to to discard this profile?'}
              primaryButtonText={'Yes, Discard'}
              secondaryButtonText={'No'}
              onSubmit={() => {
                discardHandler()
                setDiscardModalOpen(false)
              }}
              onCancel={() => setDiscardModalOpen(false)}
            />
          )}
          {cloneProfileModalOpen && (
            <ModalPopup
              width={512}
              title="Clone Profile"
              onCancel={() => setCloneProfileModalOpen(false)}
            >
              <CloneProfile requestAccess={true} papId={papId} appId={appId} />
            </ModalPopup>
          )}
        </>
      )}
    </>
  )
}

export default ProfileHeaderButtons
