import * as actions from 'actions'

const initialState = {
  policyListLoading: false,
  policyListData: [],
  policyDeleteLoading: false,
}

export function policyReducer(state = initialState, action) {
  switch (action.type) {
    case `${actions.PROFILE_MANAGEMENT_GET_POLICIES}_PENDING`: {
      return {
        ...state,
        policyListLoading: true,
      }
    }

    case `${actions.PROFILE_MANAGEMENT_GET_POLICIES}_FULFILLED`: {
      return {
        ...state,
        policyListLoading: false,
        policyListData: action.payload?.data,
      }
    }

    case `${actions.PROFILE_MANAGEMENT_GET_POLICIES}_REJECTED`: {
      return {
        ...state,
        policyListLoading: false,
      }
    }

    case `${actions.PROFILE_MANAGEMENT_DELETE_POLICY}_PENDING`: {
      return {
        ...state,
        policyDeleteLoading: true,
      }
    }

    case `${actions.PROFILE_MANAGEMENT_DELETE_POLICY}_FULFILLED`: {
      return {
        ...state,
        policyDeleteLoading: false,
      }
    }

    case `${actions.PROFILE_MANAGEMENT_DELETE_POLICY}_REJECTED`: {
      return {
        ...state,
        policyDeleteLoading: false,
      }
    }

    default:
      return { ...state }
  }
}
