import React from 'react'
import { Route, Switch } from 'react-router-dom'

import Activity from './Activity'
import Dashboard from './Dashboard'
import Properties from './Properties'
import Data from './Data'
import Scanning from './Scanning'
import Profiles from './Profiles'
import AccessRequestSettings from './AccessRequestSettings'
import AssignAssociationApprovers from './AccessRequestSettings/AssignAssociationApprovers'
import ManagedPermissions from './ManagedPermissions/Router'

function ApplicationRouter(props) {
  const { match } = props

  return (
    <Switch>
      <Route
        path={`${match.url}/overview`}
        render={() => <Dashboard {...props} />}
      />

      <Route
        path={`${match.url}/properties`}
        render={() => <Properties {...props} />}
      />

      <Route
        path={`/admin/applications/create-properties`}
        render={() => <Properties {...props} />}
      />

      <Route path={`${match.url}/scans`} render={() => <Scanning {...props} />} />

      <Route path={`${match.url}/profiles`} render={() => <Profiles {...props} />} />

      <Route path={`${match.url}/data`} render={() => <Data {...props} />} />

      <Route path={`${match.url}/activity`} render={() => <Activity {...props} />} />

      <Route
        path={`${match.url}/access-request-settings`}
        render={() => <AccessRequestSettings {...props} />}
      />

      <Route
        path={`${match.url}/managed-permissions`}
        render={() => <ManagedPermissions {...props} />}
      />

      <Route
        path={`${match.url}/association-approvers/:associationApproversId?`}
        render={() => <AssignAssociationApprovers {...props} />}
      />
    </Switch>
  )
}

export default ApplicationRouter
