import { createSelector } from 'reselect'
import get from 'lodash/get'

import { getStateSliceFor } from './index'

import { featureFlagReducerKey as reducerKey } from 'reducers/feature_flag'

/** Returns the state slice for activity. */
const extractFrom = state => getStateSliceFor({ state, reducerKey })

const getFeatureFlags = state => extractFrom(state).featureFlags

export const getFeatureFlagById = createSelector(
  ({ state }) => getFeatureFlags(state),
  ({ id }) => id,
  (flags, id) => get(flags, `[${id}]`, false)
)
