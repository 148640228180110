import React from 'react'
import { createStandaloneToast, Box, CloseButton } from '@chakra-ui/react'
import { getIsAuthenticated } from '../authentication'

const toast = ({ title, description, type, time, id }) => {
  const colors = {
    error: {
      bg: '#e15c5c',
      font: '#ffffff',
    },
    warning: {
      bg: '#f4cd47',
      font: '#000000',
    },
    success: {
      bg: '#4dcb64',
      font: '#000000',
    },
    info: {
      bg: '#0091ff',
      font: '#ffffff',
    },
  }

  const duration = {
    short: 1000,
    normal: 3000,
    long: 6000,
    longer: 10000,
    infinite: null,
  }

  if (getIsAuthenticated()) {
    const standAloneToast = createStandaloneToast()
    const current = standAloneToast({
      duration: duration[time],
      isClosable: true,
      position: 'top',
      ...(id && { id }),
      render: () => (
        <Box bg={colors[type].bg} p="5px" width={'480px'} color={colors[type].font}>
          <Box d={'flex'} flexDirection={'column'}>
            <Box d="flex" justifyContent="space-between" alignItems="center">
              <Box fontWeight={'400'} fontSize={'13px'}>
                {title}
              </Box>
              <CloseButton
                size="sm"
                zIndex="1000"
                bg="transparent"
                border="none"
                onClick={() => standAloneToast.close(current)}
              />
            </Box>
            {description && (
              <Box mt={'10px'} fontSize={'12px'}>
                {description}
              </Box>
            )}
          </Box>
        </Box>
      ),
    })
    return {
      standAloneToast,
      current,
    }
  }
}

export default toast
