import React, { useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import capitalize from 'lodash/capitalize'
import Tooltip from 'britive-design-system/core/components/tooltip'
import Typography from 'britive-design-system/core/components/typography'
import Table from '../../../AccessRequestSettings/AssignAssociationApprovers/components/Table'
import highRisk from 'static/images/high-risk.svg'
import info from 'static/images/infoIcon.svg'
import './index.scss'
import PermissionDrawer from './index'

const classNamePrefix = 'policy-list'

export const GroupTable = ({
  data = [],
  isLoading,
  environmentList,
  appId,
  supportedPermissionTypes,
  hideEnvironmentSelector,
  rootEnvironmentGroup,
  isAws,
  forRequestBuilder,
}) => {
  const [selectedPermission, setSelectedPermission] = useState(null)
  const [permissionScope, setPermissionScope] = useState(null)

  const handleInfoClick = row => {
    if (
      row?.inheritedScope &&
      !isEmpty(rootEnvironmentGroup) &&
      !isEmpty(Object.values(rootEnvironmentGroup))
    ) {
      const availableScopes = Object.values(rootEnvironmentGroup).flat()
      const scopeData = availableScopes.find(
        scope => scope.name === row.inheritedScope
      )

      if (!isEmpty(scopeData)) {
        setPermissionScope([
          {
            title: scopeData.name,
            value: scopeData.id,
          },
        ])
      } else {
        setPermissionScope(null)
      }
    } else {
      setPermissionScope(null)
    }

    setSelectedPermission(row)
  }

  const renderHighRiskIcon = () => (
    <div>
      <Tooltip title="High Risk" position="left">
        <span title="">
          <img src={highRisk} className={`${classNamePrefix}-name-high-risk`} />
        </span>
      </Tooltip>
    </div>
  )

  const renderInfoIcon = row => (
    <div>
      <Tooltip title="View Details" position="left">
        <span title="" onClick={() => handleInfoClick(row)}>
          <img src={info} className={`${classNamePrefix}-action-info`} />
        </span>
      </Tooltip>
    </div>
  )

  if (isEmpty(data) && !isLoading) {
    return (
      <Typography variant="label1">
        Permission data not present for the selected group.
      </Typography>
    )
  }

  return (
    <>
      <Table
        tableData={data}
        isLoading={isLoading}
        columns={[
          {
            field: 'permissionName',
            headerName: 'Name',
            sortable: true,
            renderColumn: row => {
              return (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {row.permissionName}
                  &nbsp;
                  {row.privileged && renderHighRiskIcon()}
                </div>
              )
            },
          },
          {
            field: 'inheritedScope',
            headerName: 'Scope',
            sortable: true,
          },
          {
            field: 'permissionType',
            headerName: 'Type',
            sortable: true,
            renderColumn: row => capitalize(row.permissionType),
          },
          {
            headerName: 'Action',
            width: '10%',
            renderColumn: row => renderInfoIcon(row),
          },
        ]}
      />

      {!isEmpty(selectedPermission) && (
        <PermissionDrawer
          environmentList={
            !isEmpty(permissionScope) ? permissionScope : environmentList
          }
          appId={appId}
          permission={{
            name: selectedPermission.permissionName,
            type: selectedPermission.permissionType?.toLowerCase(),
          }}
          supportedPermissionTypes={supportedPermissionTypes}
          hideEnvironmentSelector={hideEnvironmentSelector}
          rootEnvironmentGroups={rootEnvironmentGroup}
          onClose={() => setSelectedPermission(null)}
          isAws={isAws}
          forRequestBuilder={forRequestBuilder}
        />
      )}
    </>
  )
}
