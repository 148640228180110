import React, { PureComponent } from 'react'

import ActionModal from 'components/ActionModal'

class AppsActionModalWrapper extends PureComponent {
  state = {
    actionsStarted: false,
    isUpdating: false,
    actionResults: {},
    allFinished: false,
    currentModalType: null,
    initialItemsList: [],
  }

  componentDidMount() {
    this.setState({ initialItemsList: this.props.apps })
  }

  componentDidUpdate(prevProps, prevState) {
    const { actionResults, actionsStarted } = this.state
    const { activeModalType, apps } = this.props

    if (prevProps.apps.length === 0 && prevProps.apps !== apps) {
      this.setState({ initialItemsList: apps })
    }

    if (actionsStarted && prevState.actionResults !== actionResults) {
      this.handleActionResults()
    }

    if (activeModalType && prevState.currentModalType !== activeModalType) {
      this.setState({ currentModalType: activeModalType })
    }
  }

  handleActionResults = async () => {
    const { actionResults } = this.state
    const { selectModalType } = this.props

    const allFinished = Object.values(actionResults).every(result =>
      ['successful', 'failure'].includes(result)
    )

    this.setState({ allFinished, isUpdating: !allFinished })

    selectModalType()
  }

  toggleModal = () => {
    const { toggleModal, clearCheckedItems } = this.props
    const { isUpdating } = this.state

    if (!isUpdating) {
      toggleModal()
      clearCheckedItems()

      this.setState({
        allFinished: false,
        actionResults: {},
        actionsStarted: false,
      })
    }
  }

  modalAction = async () => {
    const {
      activeModalType,
      checkedItems,
      disableApplication,
      enableApplication,
      deleteApplication,
    } = this.props

    const actions = {
      Disable: disableApplication,
      Enable: enableApplication,
      Delete: deleteApplication,
    }

    const modalAction = actions[activeModalType]

    this.setState({ isUpdating: true })

    Object.keys(checkedItems).forEach(async itemId => {
      if (!checkedItems[itemId]) {
        return
      }

      let status

      this.setState({
        actionResults: { ...this.state.actionResults, [itemId]: 'pending' },
      })

      try {
        const response = await modalAction(itemId)
        status = response.value.status === 200 ? 'successful' : 'failure'
      } catch (e) {
        status = 'failure'
      }

      this.setState({
        actionResults: { ...this.state.actionResults, [itemId]: status },
      })
    })

    this.setState({ actionsStarted: true })
  }

  render() {
    const { checkedItems, isModalOpen } = this.props

    const {
      actionResults,
      isUpdating,
      allFinished,
      currentModalType,
      initialItemsList,
    } = this.state

    if (!isModalOpen) {
      return null
    }

    return (
      <ActionModal
        allFinished={allFinished}
        actionResults={actionResults}
        checkedItems={checkedItems}
        currentModalType={currentModalType}
        identifier="appContainerId"
        isModalOpen={isModalOpen}
        isUpdating={isUpdating}
        itemName="application"
        itemNameProperty="applicationName"
        items={initialItemsList}
        modalAction={this.modalAction}
        toggleModal={this.toggleModal}
        askForConfirmation={currentModalType === 'Delete'}
      />
    )
  }
}

export default AppsActionModalWrapper
