import * as actions from 'actions'
import {
  FETCH_PASSWORD_POLICY,
  UPDATE_AUTHENTICATION_INFO,
  UPDATE_CHALLENGE_PARAMETERS,
  SET_IS_INITIAL_AUTHING,
  GET_IDENTITY_PROVIDERS_LIST,
  GET_IDENTITY_PROVIDER_TYPE,
} from 'action_creators/account'

import * as text from 'translations/english_us'

const initialState = {
  fetching: false,
  fetched: false,
  fetchingPasswordPolicy: false,
  passwordPolicy: {},
  error: null,
  errorMessage: '',
  isInitialAuthing: false,

  identityProvidersList: [],
  identityProviderType: [],
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case `${actions.AUTHENTICATE}_PENDING`: {
      return {
        ...state,
        fetching: true,
        error: null,
        errorMessage: '',
      }
    }

    case UPDATE_AUTHENTICATION_INFO: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        errorMessage: '',
        // TODO: this is kinda bad
        ...(action.payload ? action.payload.data.authenticationResult : {}),
      }
    }

    case `${actions.AUTHENTICATE}_REJECTED`: {
      const errorMessage =
        action.payload || `Unknown error. ${text.PLEASE_TRY_AGAIN}`

      return {
        ...state,
        fetching: null,
        fetched: null,
        errorMessage,
      }
    }

    case `${FETCH_PASSWORD_POLICY}_PENDING`: {
      return {
        ...state,
        fetchingPasswordPolicy: true,
        error: null,
        errorMessage: '',
      }
    }

    case `${FETCH_PASSWORD_POLICY}_FULFILLED`: {
      if (!action.payload) {
        return { ...state }
      }

      // TODO: make this a utility or make it returned as an object
      const cleanPolicy = action.payload.data.replace(/[{}']+/g, '')
      const policyArray = cleanPolicy.split(',')
      const passwordPolicy = {}

      policyArray.forEach(policy => {
        const splitPolicy = policy.split(': ')
        const policyName = splitPolicy[0]
        const policyValue = splitPolicy[1]
        passwordPolicy[policyName] = policyValue
      })

      return {
        ...state,
        fetchingPasswordPolicy: false,
        error: null,
        errorMessage: '',
        passwordPolicy,
      }
    }

    case `${FETCH_PASSWORD_POLICY}_REJECTED`: {
      return {
        ...state,
        fetchingPasswordPolicy: false,
        // errorMessage: 'Error retrieving password policy',
      }
    }

    case `${GET_IDENTITY_PROVIDERS_LIST}_PENDING`: {
      return {
        ...state,
        isInitialAuthing: true,
      }
    }

    case `${GET_IDENTITY_PROVIDERS_LIST}_FULFILLED`: {
      return {
        ...state,
        identityProvidersList: action.payload.data,
        isInitialAuthing: false,
      }
    }

    case `${GET_IDENTITY_PROVIDERS_LIST}_REJECTED`: {
      return {
        ...state,
        isInitialAuthing: false,
        errorMessage: `${action.payload || 'Unknown error.'} Please refresh!`,
      }
    }

    case `${GET_IDENTITY_PROVIDER_TYPE}_PENDING`: {
      return {
        ...state,
        isInitialAuthing: true,
      }
    }

    case `${GET_IDENTITY_PROVIDER_TYPE}_FULFILLED`: {
      return {
        ...state,
        identityProviderType: action.payload.data,
        isInitialAuthing: false,
      }
    }

    case `${GET_IDENTITY_PROVIDER_TYPE}_REJECTED`: {
      return {
        ...state,
        isInitialAuthing: false,
        errorMessage: `${action.payload || 'Unknown error.'} Please refresh!`,
      }
    }

    case actions.GET_TENANT_FROM_URL: {
      return {
        ...state,
        tenant: action.tenant,
      }
    }

    case UPDATE_CHALLENGE_PARAMETERS: {
      return {
        ...state,
        challengeParameters: action.payload,
        accessToken: null,
        refreshToken: null,
      }
    }

    case SET_IS_INITIAL_AUTHING: {
      return {
        ...state,
        isInitialAuthing: action.payload,
      }
    }

    default:
      return { ...state }
  }
}
