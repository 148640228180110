export const AUTHENTICATION = 'AUTHENTICATION'
export const NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED'
export const LOGOUT = 'LOGOUT'
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
export const RESET_PASSWORD = 'FORGOT_PASSWORD_RESPONSE'
export const MFA_SETUP = 'MFA_SETUP'
export const SOFTWARE_TOKEN_MFA = 'SOFTWARE_TOKEN_MFA'
export const VALIDATE_TOKEN = 'VALIDATE_TOKEN'
export const VALIDATE_COOKIE = 'VALIDATE_COOKIE'
export const VALIDATE_USER = 'VALIDATE_USER'
export const SSO = 'SSO'
