import React, { memo, useEffect } from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'

import Dashboard from './Dashboard'
import { getSelectedApplicationRoot } from 'selectors/application_root'

function DashboardContainer(props) {
  useEffect(() => {
    const { appName, setBreadcrumbTrail } = props
    setBreadcrumbTrail([{ title: `${appName} Overview` }])
  }, [])

  const { app, appHeaderHeight, entityType } = props
  const supportsScanning = get(app, 'catalogApplication.supportsEnvironmentScanning')

  return (
    <Dashboard
      app={app}
      appHeaderHeight={appHeaderHeight}
      entityType={entityType}
      supportsScanning={supportsScanning}
    />
  )
}

const mapStateToProps = state => {
  const app = getSelectedApplicationRoot(state)
  const appName = get(app, 'catalogAppDisplayName', '')
  return {
    appName,
    app,
  }
}

export default connect(mapStateToProps)(memo(DashboardContainer))
