import React from 'react'
import { connect } from 'react-redux'
import MyApprovals from './MyApprovals'
import {
  requestMyApprovalsList,
  requestUpdateMyApprovalsListSearchTerm,
  approveRequest,
  rejectRequest,
  requestMyApprovalsListLoadMore,
} from '../../../../action_creators/my-approvals'

const mapStateToProps = state => {
  const { myApprovalsList: myApprovalsListState = {} } = state?.userSecretsReducer
  return {
    myApprovalsListState,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getMyApprovalsListData: () => dispatch(requestMyApprovalsList()),
    updateApprovalsListSearchTerm: search => {
      dispatch(requestUpdateMyApprovalsListSearchTerm(search))
    },
    requestToApprove: (approvalId, value, commandText) => {
      dispatch(approveRequest(approvalId, value, commandText))
    },
    requestToReject: (approvalId, value, commandText) => {
      dispatch(rejectRequest(approvalId, value, commandText))
    },
    getMyApprovalsListDataLoadMore: () => dispatch(requestMyApprovalsListLoadMore()),
  }
}

const MyApprovalsListConnectedWithStore = connect(
  mapStateToProps,
  mapDispatchToProps
)(MyApprovals)

// eslint-disable-next-line react/display-name
const MyApprovalsListContainer = ({ match, history }) => {
  return <MyApprovalsListConnectedWithStore match={match} history={history} />
}

export default MyApprovalsListContainer
