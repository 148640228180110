import * as actions from 'actions'
import configuration from 'classes/configuration.js'

export default function reducer(
  state = {
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case `${actions.CONFIG}_PENDING`: {
      return {
        ...state,
        fetching: true,
      }
    }

    case `${actions.CONFIG}_FULFILLED`: {
      const config = new configuration(action.payload ? action.payload.data : {})
      return {
        ...state,
        fetching: false,
        fetched: true,
        ...config,
      }
    }

    case `${actions.CONFIG}_REJECTED`: {
      return {
        ...state,
        fetching: null,
        fetched: null,
        error: action.payload,
      }
    }

    default:
      return { ...state }
  }
}
