import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { FormGroup, Label, Input, Button } from 'reactstrap'
import styled from 'styled-components'
import Icon from 'britive-ui-components/core/components/Icons'
import Spinner from 'britive-ui-components/core/components/Spinner'
import isEmpty from 'lodash/isEmpty'
import EditableProperty from 'routes/admin/applications/Application/Properties/components/editable-property'

const styles = {
  relativePosition: { position: 'relative' },
  clearInputWrapper: {
    cursor: 'pointer',
    position: 'absolute',
    right: 6,
    top: '50%',
    transform: 'translateY(-50%)',
  },
  clearableInput: {
    paddingRight: 24,
  },
  error: {
    color: 'red',
    padding: 2,
  },
}

const RefreshWrapper = styled.div`
  margin-left: 10px;
  box-shadow: 0 0 2px 1px var(--brand-light);
  border-radius: 1px;
  height: 32px;
  font-size: 16px;
  padding: 6px 10px;
  color: var(--brand);
  cursor: pointer;
  background: var(--periwinkle-gray);
  cursor: ${props => (props.isRefreshing ? 'not-allowed' : 'pointer')};
`
const InputContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  position: relative;
`

const FormField = props => {
  const {
    id,
    name,
    placeholder,
    label = placeholder,
    value,
    onChange,
    type = 'text',
    hideLabel = true,
    hidePlaceholder = false,
    className,
    isFieldInvalid,
    children,
    autoComplete,
    isClearable,
    showClearButton,
    onClearButtonClick,
    innerRef,
    visualCueDataType,
    isRefreshing,
    refreshApplication,
    error,
    ...rest
  } = props

  const formClasses = classNames('form-group', className)
  const inputClasses = classNames('form-control', {
    'is-invalid': isFieldInvalid,
  })

  return (
    <div className={formClasses}>
      <label htmlFor={id} className={hideLabel ? 'sr-only' : ''}>
        {label}
      </label>
      <InputContainer>
        <input
          autoComplete={autoComplete === 'off' ? 'new-password' : ''}
          type={type}
          className={inputClasses}
          id={id}
          name={name}
          placeholder={!hidePlaceholder ? `${placeholder}` : ''}
          value={value}
          onChange={onChange}
          style={isClearable ? styles.clearableInput : undefined}
          ref={innerRef}
          {...rest}
        />
        {visualCueDataType && (
          <RefreshWrapper
            isRefreshing={isRefreshing}
            onClick={refreshApplication}
            title="Refresh Environment Tree"
          >
            {isRefreshing ? (
              <Spinner size="1x" />
            ) : (
              <span className="fas fa-sync-alt fs:16" />
            )}
          </RefreshWrapper>
        )}
        {isClearable && showClearButton && (
          <span style={styles.clearInputWrapper} onClick={onClearButtonClick}>
            <Icon title="" name="close" color="#000" width="10" height="10" />
          </span>
        )}
      </InputContainer>
      {!!error?.length && <span style={styles.error}>{error}</span>}
      {!!children && children}
    </div>
  )
}

FormField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  hideLabel: PropTypes.bool,
  hidePlaceholder: PropTypes.bool,
  className: PropTypes.string,
  isFieldInvalid: PropTypes.bool,
}

export default FormField

export const UploadFile = props => {
  const {
    id,
    name,
    placeholder,
    label = placeholder,
    value,
    onChange,
    hideLabel = true,
    className,
    disabled,
  } = props

  const formClasses = classNames('form-group', className)

  const [inputValue, setInputValue] = useState('')
  const [reading, setReading] = useState(false)

  useEffect(() => {
    setInputValue(value)
  }, [value])

  const onFileUploadClick = async () => {
    const input = document.createElement('input')
    input.type = 'file'
    input.id = 'file'
    input.click()
    input.addEventListener('change', () => {
      setReading(true)
      const fileReader = new FileReader()
      fileReader.onloadend = () => {
        const content = fileReader.result.toString()
        setInputValue(content)
        onChange({ target: { name, value: content } })
        setReading(false)
      }
      fileReader.readAsText(input.files[0])
    })
  }

  return (
    <div className={formClasses}>
      <label htmlFor={id} className={hideLabel ? 'sr-only' : ''}>
        {label}
      </label>
      <div style={styles.relativePosition} onClick={onFileUploadClick}>
        <input type="hidden" value={inputValue} name={name} />
        {inputValue !== '' ? 'Uploaded ' : ''}
        <Button disabled={disabled || reading}>
          {reading ? <Spinner /> : <i className="fas fa-upload" />} Upload
        </Button>
      </div>
    </div>
  )
}

FormField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  hideLabel: PropTypes.bool,
  hidePlaceholder: PropTypes.bool,
  className: PropTypes.string,
  isFieldInvalid: PropTypes.bool,
}

export const RadioButtons = props => {
  const {
    enumValues,
    enumNameMap,
    name,
    defaultValue,
    onChange,
    value,
    disabled,
    className,
    fields,
    propertiesMap,
    subPropertiesMap,
    propertyTypes,
    ...rest
  } = props
  return (
    <FormGroup tag="fieldset">
      {enumValues.map(choice => (
        <FormGroup check key={choice} className={className}>
          <Label check>
            <Input
              type="radio"
              name={name}
              value={choice}
              checked={choice === defaultValue || choice === value}
              onChange={onChange}
              disabled={disabled}
              {...rest}
            />{' '}
            {enumNameMap[choice]}
          </Label>

          {!isEmpty(propertiesMap) && (choice === defaultValue || choice === value) && (
            <FormGroup className={'panel'}>
              {propertiesMap[choice].map(p => (
                <EditableProperty
                  key={propertyTypes[p].name}
                  {...propertyTypes[p]}
                  onChange={onChange}
                  displayName={propertyTypes[p].description}
                  hidelabel={false}
                  value={
                    fields.propertyTypes[propertyTypes[p].name] ||
                    fields.propertyTypes[propertyTypes[p].name] === ''
                      ? fields.propertyTypes[propertyTypes[p].name]
                      : propertyTypes[p].value
                  }
                  fields={fields}
                  inputValue={''}
                  editable={!disabled}
                  subPropertiesMap={subPropertiesMap}
                  propertyTypes={propertyTypes}
                />
              ))}
            </FormGroup>
          )}
        </FormGroup>
      ))}
    </FormGroup>
  )
}

RadioButtons.propTypes = {
  enumValues: PropTypes.array,
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  displayName: PropTypes.string,
  fields: PropTypes.object,
  propertiesMap: PropTypes.object,
  subPropertiesMap: PropTypes.object,
  propertyTypes: PropTypes.object,
}

export const RadioButtonsBoolean = props => {
  return <RadioButtons enumValues={['true', 'false']} {...props} />
}

export const CheckBoxBoolean = props => {
  const {
    name,
    label = name,
    hideLabel = false,
    onChange,
    checked,
    disabled,
    className,
    ...rest
  } = props
  return (
    <FormGroup check className={className}>
      <Label check>
        <Input
          type="checkbox"
          name={name}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          value={checked}
          {...rest}
        />
        {!hideLabel && <span>{label}</span>}
      </Label>
    </FormGroup>
  )
}

CheckBoxBoolean.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  hideLabel: PropTypes.bool,
  className: PropTypes.string,
}
