import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from 'actions'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import Typography from 'britive-design-system/core/components/typography'
import Spinner from 'britive-design-system/core/components/spinner'
import Button from 'britive-design-system/core/components/button'
import toast from 'utils/toast'
import { useLocalStorage } from 'usehooks-ts'
import ProfileList from './ProfileList'
import AccessCredentialsDialog from './components/AccessCredentialsDialog'
import PageFilters from './components/PageFilters'
import StepUpOTPModal from 'components/StepUpOTPModal'
import ProfileGroups from './components/ProfileGroups'
import { stepUpErrorCode } from 'constants/myProfile'
import {
  searchQueryParam,
  typeQueryParam,
  localStorageKey,
  ungroupedLabel,
} from './constants'
import { getProfileGroups } from './utils'
import {
  getMyResourcesProfiles,
  checkoutMyResourcesProfile,
  checkinMyResourcesProfile,
  getMyResourcesProfileCreds,
  addMyResourcesFavorite,
  deleteMyResourcesFavorite,
  withdrawApprovalRequest,
  getAvailableTemplates,
} from 'action_creators/serverAccess/myResources'
import './index.scss'

const CSS_CLASSNAME_PREFIX = 'my-resources'

const MyResources = () => {
  const [activeQuickFilter, setActiveQuickFilter] = useLocalStorage(
    localStorageKey.quickFilter
  )
  const [groupByLabels, setGroupByLabels] = useLocalStorage(
    localStorageKey.groupByLabels,
    []
  )

  const [isLoading, setIsLoading] = useState(false)
  const [accessCredsData, setAccessCredsData] = useState(null)
  const [params, setParams] = useState({
    size: 50,
    page: 0,
    type: activeQuickFilter,
  })
  const [showStepUpModal, setShowStepUpModal] = useState(false)
  const [profileCreds, setProfileCreds] = useState(null)
  const [groupData, setGroupData] = useState({})
  const [allGroupedProfiles, setAllGroupedProfiles] = useState([])
  const [isExpanded, setIsExpanded] = useState(false)

  const dispatch = useDispatch()
  const profiles = useSelector(state => state.serverAccess.myResources.profiles)
  const availableTemplates = useSelector(
    state => state.serverAccess.myResources.availableTemplates
  )

  const resourceLabels = useSelector(state => state.serverAccess.resourceLabels)
  const mfaAuthenticate = useSelector(state => state.userMfa?.authenticate)

  useEffect(() => {
    fetchMyResources()
  }, [JSON.stringify(params)])

  useEffect(() => {
    if (!isEmpty(groupByLabels)) {
      handleGroupByFilter(groupByLabels)
    }
  }, [profiles?.data?.length, JSON.stringify(profiles?.params)])

  const resetAccessCreds = () => {
    setAccessCredsData(null)
  }

  const resetProfileCreds = () => {
    setProfileCreds(null)
  }
  const onClose = () => {
    setProfileCreds(null)
    setShowStepUpModal(false)
  }

  useEffect(() => {
    if (mfaAuthenticate?.success) {
      setShowStepUpModal(false)
      checkoutProfile(profileCreds?.profileId, profileCreds?.resourceId)
    }
  }, [JSON.stringify(mfaAuthenticate)])

  const checkoutProfile = async (profileId, resourceId) => {
    dispatch({ type: `${actions.AUTHENTICATE_STEP_UP_MFA}_RESET` })
    setProfileCreds({
      profileId,
      resourceId,
    })
    try {
      setIsLoading(true)
      await dispatch(checkoutMyResourcesProfile(profileId, resourceId))
      toast({
        title: 'Profile submitted for checkout',
        type: 'success',
        id: 'my-resources-profile-checkout-success',
      })
      resetProfileCreds()
    } catch (error) {
      if (error?.response?.data?.errorCode === stepUpErrorCode) {
        setShowStepUpModal(true)
      } else {
        toast({
          title: get(
            error,
            'response.data.message',
            'Something went wrong while trying to submit checkout'
          ),
          type: 'error',
          id: 'my-resources-profile-checkout-failure',
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  const checkinProfile = async transactionId => {
    try {
      setIsLoading(true)
      await dispatch(checkinMyResourcesProfile(transactionId))
      toast({
        title: 'Profile submitted for checkin',
        type: 'success',
        id: 'my-resources-profile-checkin-success',
      })
    } catch (error) {
      toast({
        title: get(
          error,
          'response.data.message',
          'Something went wrong while trying to submit checkin'
        ),
        type: 'error',
        id: 'my-resources-profile-checkin-failure',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const fetchAvailableTemplates = async (transactionId, profileId, resourceId) => {
    try {
      setAccessCredsData({
        transactionId,
        profileId,
        resourceId,
      })
      const response = await dispatch(getAvailableTemplates(transactionId))

      if (isEmpty(response?.value?.data)) {
        await accessProfileCreds(transactionId, profileId, resourceId)
      }
    } catch (error) {
      toast({
        title: get(
          error,
          'response.data.message',
          'Something went wrong while trying to fetch the templates'
        ),
        type: 'error',
        id: 'my-resources-profile-templates-failure',
      })
    }
  }

  const accessProfileCreds = async (
    transactionId,
    profileId,
    resourceId,
    selectedTemplate
  ) => {
    try {
      setIsLoading(true)
      await dispatch(
        getMyResourcesProfileCreds(
          transactionId,
          profileId,
          resourceId,
          selectedTemplate
        )
      )
    } catch (error) {
      toast({
        title: get(
          error,
          'response.data.message',
          'Something went wrong while trying to fetch the profile credentials'
        ),
        type: 'error',
        id: `my-resources-profile-credentials-failure-${selectedTemplate}`,
      })
    } finally {
      setIsLoading(false)
    }
  }

  const withdrawRequest = async (profileId, resourceId) => {
    try {
      setIsLoading(true)
      await dispatch(withdrawApprovalRequest(profileId, resourceId))
      toast({
        title: 'Profile request withdrawn',
        type: 'success',
        id: 'my-resources-profile-withdraw-success',
      })
    } catch (error) {
      toast({
        title: get(
          error,
          'response.data.message',
          'Something went wrong while trying to withdraw request'
        ),
        type: 'error',
        id: 'my-resources-profile-withdraw-failure',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const fetchMyResources = async () => {
    try {
      await dispatch(getMyResourcesProfiles(params))
    } catch (error) {
      toast({
        title: get(
          error,
          'response.data.message',
          'Something went wrong while trying to load profiles'
        ),
        type: 'error',
        id: 'my-resources-profile-search-failure',
      })
    }
  }

  const addFavorite = async (profileId, resourceId) => {
    try {
      setIsLoading(true)
      await dispatch(addMyResourcesFavorite(profileId, resourceId))
    } catch (error) {
      toast({
        title: get(
          error,
          'response.data.message',
          'Something went wrong while trying to add as a favorite'
        ),
        type: 'error',
        id: 'my-resources-profile-add-favorite-failure',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const removeFavorite = async (favoriteId, profileId, resourceId) => {
    try {
      setIsLoading(true)
      await dispatch(deleteMyResourcesFavorite(favoriteId, profileId, resourceId))
    } catch (error) {
      toast({
        title: get(
          error,
          'response.data.message',
          'Something went wrong while trying to remove as a favorite'
        ),
        type: 'error',
        id: 'my-resources-profile-remove-favorite-failure',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const updateParams = (key, value) => {
    const updatedParams = { ...params }
    if (isEmpty(value)) {
      delete updatedParams[key]
    } else {
      updatedParams[key] = value
    }

    // the page should reset on every sort, search and filter change.
    updatedParams.page = 0
    setParams(updatedParams)
  }

  const onSearch = value => {
    updateParams(searchQueryParam, value)
  }

  const handleLoadMore = () => {
    setParams({
      ...params,
      page: params.page + 1,
    })
  }

  const handleFilterButtonClick = clickedType => {
    if (params.type === clickedType) {
      setActiveQuickFilter()
      updateParams(typeQueryParam, null)
      return
    }

    setActiveQuickFilter(clickedType)
    updateParams(typeQueryParam, clickedType)
  }

  const getAllProfileGroups = () => {
    if (isEmpty(profiles?.data)) {
      return []
    }

    const updatedGroups = { ...groupData }

    if (isEmpty(groupData)) {
      // generating an array of index for the profiles data
      const matchedIndexes = Array.from(Array(profiles.data.length).keys())
      updatedGroups[ungroupedLabel] = {
        _matches: matchedIndexes,
        _totalCount: matchedIndexes.length,
      }

      return updatedGroups
    }

    // For Adding Others group
    const ungroupedProfiles = []

    for (
      var curentIndex = 0;
      curentIndex < profiles.data?.length || 0;
      curentIndex++
    ) {
      if (!allGroupedProfiles.includes(curentIndex)) {
        ungroupedProfiles.push(curentIndex)
      }
    }

    if (!isEmpty(ungroupedProfiles)) {
      updatedGroups[ungroupedLabel] = {
        _matches: ungroupedProfiles,
        _totalCount: ungroupedProfiles.length,
      }
    }

    return updatedGroups
  }

  const handleGroupByFilter = selectedItems => {
    setGroupByLabels(selectedItems)
    if (isEmpty(selectedItems) && isEmpty(groupData)) {
      setGroupData({})
      return
    }

    let clonedLabelItems = []
    let resourceLabelGroups = []

    profiles?.data.forEach((profile, index) => {
      if (!isEmpty(profile.resourceLabels[selectedItems[0]])) {
        clonedLabelItems.push(index)

        resourceLabelGroups = getProfileGroups(
          resourceLabelGroups,
          profile.resourceLabels,
          index,
          selectedItems
        )
      }
    })

    setGroupData(resourceLabelGroups)
    setAllGroupedProfiles(clonedLabelItems)
  }

  const profileListProps = {
    onCheckout: checkoutProfile,
    onCheckin: checkinProfile,
    onWithdraw: withdrawRequest,
    onProfileAccess: fetchAvailableTemplates,
    addFavorite: addFavorite,
    removeFavorite: removeFavorite,
  }

  return (
    <>
      <div className="page-header-wrapper">
        <Typography variant="heading5">My Resources</Typography>
      </div>
      <div className="page-content-wrapper">
        {(profiles.loading ||
          isLoading ||
          availableTemplates.loading ||
          resourceLabels.loading) && <Spinner size="medium" message={``} overlay />}
        <PageFilters
          activeQuickFilter={params?.type}
          onQuickFilter={handleFilterButtonClick}
          onSearch={onSearch}
          onGroupBy={handleGroupByFilter}
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
        />
        {isEmpty(groupByLabels) ? (
          <ProfileList rows={profiles?.data || []} {...profileListProps} />
        ) : (
          <>
            <br />
            <ProfileGroups
              key={isExpanded}
              groupData={getAllProfileGroups()}
              depth={0}
              isExpanded={isExpanded}
              {...profileListProps}
            />
          </>
        )}
        {isEmpty(profiles?.data) && !profiles.loading && (
          <div className={`${CSS_CLASSNAME_PREFIX}-no-items-message`}>
            <Typography variant="heading6">No Profiles found.</Typography>
          </div>
        )}
        <br />
        <br />
        {profiles.hasMoreData && (
          <div className={`${CSS_CLASSNAME_PREFIX}-load-more`}>
            <Button variant="textOnly" size="medium" onClick={handleLoadMore}>
              Load More ({profiles?.data?.length} of {profiles.totalCount})
            </Button>
          </div>
        )}
        {!availableTemplates.loading && !isEmpty(accessCredsData) && (
          <AccessCredentialsDialog
            templates={availableTemplates.data}
            credentials={get(
              profiles,
              `credentials[${accessCredsData?.profileId}-${accessCredsData?.resourceId}]`,
              ''
            )}
            onClose={resetAccessCreds}
            onTemplateSelection={selectedTemplate =>
              accessProfileCreds(
                accessCredsData.transactionId,
                accessCredsData.profileId,
                accessCredsData.resourceId,
                selectedTemplate
              )
            }
          />
        )}
      </div>
      {showStepUpModal && (
        <StepUpOTPModal onClose={onClose} mfaAuthenticate={mfaAuthenticate} />
      )}
    </>
  )
}

export default MyResources
