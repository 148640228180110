import React from 'react'

import Spinner from 'britive-ui-components/core/components/Spinner'
const AccessDenied = React.lazy(() => import('./AccessDenied'))

const AccessDeniedLoader = ({ match }) => {
  return (
    <React.Suspense fallback={<Spinner size="2x" />}>
      <AccessDenied match={match} />
    </React.Suspense>
  )
}

export default AccessDeniedLoader
