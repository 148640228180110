import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Typography from 'britive-design-system/core/components/typography'
import Button from 'britive-design-system/core/components/button'
import Textfield from 'britive-design-system/core/components/textfield'
import Textarea from 'britive-design-system/core/components/textarea'
import Spinner from 'britive-design-system/core/components/spinner'
import ModalPopup from 'britive-design-system/core/components/modal-popup'
import DialogPopup from 'britive-design-system/core/components/dialog'

import { formFields } from './constants'
import { isEmpty } from 'lodash'
import ExistingPoliciesList from './components/ExistingPoliciesList'
import SelectedPoliciesList from './components/SelectedPoliciesList'
import TagsList from './components/TagsList'
import CreateTag from './components/CreateTags'
import { isDuplicatePropertyInList, getUpdatedList, characterCheck } from './utils'

import { createRole, validateInlinePolicy } from 'action_creators/managed_role'
import { updatePermission } from 'action_creators/pap'
import toast from 'utils/toast'
import FindingsComponent from './components/FindingsComponent'
import './index.scss'

const styles = {
  formContainer: {
    marginTop: '24px',
  },
  buttonsWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
  },
}

const CreateRole = () => {
  const history = useHistory()
  const { appId, profileId } = useParams()
  const dispatch = useDispatch()
  const profileRequest = window?.location?.pathname?.includes('/request-access')

  const { creatingRole } = useSelector(state => state.managedRoleReducer)

  const { updatingPermission } = useSelector(state => state.paps)

  const [isPoliciesModalOpen, setIsPoliciesModalOpen] = useState(false)
  const [selectedPolicies, setSelectedPolicies] = useState([])
  const [formData, setFormData] = useState({
    name: {
      value: '',
    },
    description: {
      value: '',
    },
  })
  const [isInlinePolicyModalOpen, setIsInlinePolicyModalOpen] = useState(false)
  const [isValidatingJSON, setIsValidatingJSON] = useState(false)
  const [policyName, setPolicyName] = useState('')
  const [json, setJson] = useState('')
  const [validationWarnings, setValidationWarnings] = useState({
    isOpen: false,
    findings: [],
    containsError: false,
    showDialog: false,
  })
  const [tagsList, setTagsList] = useState([])
  const [isTagsModalOpen, setIsTagsModalOpen] = useState(false)
  const [updateTag, setUpdateTag] = useState(null)
  const [updateInlinePolicy, setUpdateInlinePolicy] = useState(null)

  useEffect(() => {
    if (!isEmpty(updateInlinePolicy)) {
      const { name, permissionDefinition } = updateInlinePolicy
      setPolicyName(name)
      setJson(JSON.stringify(permissionDefinition, null, 2))
    }
  }, [updateInlinePolicy])

  const handleFormFieldsChange = (event, field) => {
    setFormData({
      ...formData,
      [field.toLowerCase()]: {
        value: event.target.value,
      },
    })
  }

  const closeModal = () => {
    // resetting everything to initial state
    setIsPoliciesModalOpen(false)
    setIsTagsModalOpen(false)
    setUpdateTag(null)
  }

  const doneHandler = () => {
    setIsPoliciesModalOpen(false)
    setIsTagsModalOpen(false)
    setUpdateTag(null)
  }

  const validationWarningsEmpty = () => {
    setValidationWarnings({
      isOpen: false,
      findings: [],
      containsError: false,
      showDialog: false,
    })
  }

  const modalButtons = [
    {
      text: 'Done',
      variant: 'primary',
      onClick: doneHandler,
      size: 'medium',
    },
  ]

  const addPolicy = list => {
    setSelectedPolicies(list)
    setUpdateInlinePolicy(null)
  }

  const removePolicy = name => {
    const filteredPolicies = selectedPolicies?.filter(item => item.name !== name)
    setSelectedPolicies(filteredPolicies)
    toast({
      title: 'Policy removed successfully.',
      type: 'success',
      time: 'normal',
    })
  }

  const editInlinePolicy = obj => {
    setUpdateInlinePolicy(obj)
    setIsInlinePolicyModalOpen(true)
  }

  const addHandler = list => {
    setTagsList(list)
    setUpdateTag(null)
  }

  const removeTag = key => {
    const filteredTags = tagsList?.filter(item => item.key !== key)
    setTagsList(filteredTags)
    toast({
      title: 'Tag removed successfully.',
      type: 'success',
      time: 'normal',
    })
  }

  const editTag = obj => {
    setUpdateTag(obj)
    setIsTagsModalOpen(true)
  }

  const onSave = async () => {
    try {
      const permission = {
        name: formData?.name?.value,
        description: formData?.description?.value,
        type: 'role',
        childPermissions: selectedPolicies?.map(item => {
          if (item?.type === 'policy' && item?.permissionDefinition) {
            const clonedItem = { ...item }
            delete clonedItem.permissionDefinition
            return clonedItem
          }
          return item
        }),
        tags: tagsList,
      }
      const response = await dispatch(createRole(appId, permission, profileRequest))
      const permissionObj = {
        checkStatus: 'Success',
        papId: profileId,
        privileged: response?.value?.data?.isPrivileged,
        type: response?.value?.data?.type,
        name: response?.value?.data?.name,
        description: response?.value?.data?.description,
        profilePermissionConstraintsSummaryDTO: null,
        source: 'britive',
        message: response?.value?.data?.message,
      }

      await dispatch(
        updatePermission(appId, profileId, permissionObj, 'add', profileRequest)
      )
      toast({
        title: 'Permission created successfully.',
        type: 'success',
        time: 'normal',
      })
      history.goBack()
    } catch (e) {
      toast({
        title: e?.response?.data?.message || 'Unable to Create Role',
        type: 'error',
        time: 'normal',
      })
    }
  }

  const inlinePolicyHandler = () => {
    if (hasSpecialCharacters(policyName)) {
      toast({
        title:
          'Policy name contains invalid characters. Allowed characters are: alphanumeric and special characters:[_+=,.@-]',
        type: 'error',
        time: 'normal',
      })
      return
    }
    const isValid = onValidate()
    isValid.then(res => {
      const findings = res?.value?.data?.findings
      if (isEmpty(findings)) {
        saveInlinePolicy()
      } else if (!findings?.some(finding => finding?.type === 'ERROR')) {
        setValidationWarnings(prevState => ({
          ...prevState,
          showDialog: true,
        }))
      } else if (findings?.some(finding => finding?.type === 'ERROR')) {
        toast({
          title:
            'Error in policy definition. Please resolve the error before saving.',
          type: 'error',
          time: 'normal',
        })
      }
    })
  }

  const saveInlinePolicy = () => {
    try {
      JSON.parse(json)
    } catch (error) {
      toast({
        title: 'Invalid JSON',
        type: 'error',
        time: 'normal',
      })
      return
    }
    const obj = {
      name: policyName,
      type: 'InlinePolicy',
      permissionDefinition: JSON.parse(json),
    }
    if (
      isDuplicatePropertyInList(
        selectedPolicies,
        'name',
        policyName,
        updateInlinePolicy
      )
    ) {
      toast({
        title: 'Duplicate policy name found. Cannot add/update policy.',
        type: 'error',
        time: 'normal',
      })
    } else {
      toast({
        title: isEmpty(updateInlinePolicy)
          ? 'Inline policy added successfully.'
          : 'Inline policy updated successfully.',
        type: 'success',
        time: 'normal',
      })

      if (isEmpty(updateInlinePolicy)) {
        addPolicy([...selectedPolicies, obj])
      } else {
        const updatedPolicies = getUpdatedList(
          selectedPolicies,
          'name',
          updateInlinePolicy?.name,
          obj
        )
        setSelectedPolicies(updatedPolicies)
      }
      closeInlinePolicyModal()
    }
  }

  const closeInlinePolicyModal = () => {
    setIsInlinePolicyModalOpen(false)
    setPolicyName('')
    setJson('')
    validationWarningsEmpty()
    setUpdateInlinePolicy(null)
  }

  const inlinePolicyModalButtons = [
    {
      text: 'Save',
      variant: 'primary',
      onClick: inlinePolicyHandler,
      size: 'medium',
      disabled: isEmpty(policyName) || isEmpty(json) || characterCheck(policyName),
    },
    {
      text: 'Cancel',
      variant: 'secondary',
      onClick: closeInlinePolicyModal,
      size: 'medium',
    },
  ]

  const onValidate = async formValidateBtn => {
    setIsValidatingJSON(true)
    try {
      const response = await dispatch(
        validateInlinePolicy(appId, json, profileRequest)
      )
      const findings = response?.value?.data?.findings
      massageWarnings(findings)

      if (formValidateBtn && isEmpty(findings)) {
        toast({
          title: 'Permission definition is well-formed.',
          type: 'success',
          time: 'normal',
        })
      }

      return response
    } catch (e) {
      toast({
        title: e?.response?.data?.message,
        type: 'error',
        time: 'normal',
        description: e?.response?.data?.details,
      })
    } finally {
      setIsValidatingJSON(false)
    }
  }

  const massageWarnings = findings => {
    if (!isEmpty(findings)) {
      setValidationWarnings({
        isOpen: true,
        findings: findings,
        containsError: findings?.some(finding => finding?.type === 'ERROR'),
        showDialog: false,
      })
    } else {
      validationWarningsEmpty()
    }
  }

  const hasSpecialCharacters = input => {
    const specialCharsRegex = /[^a-zA-Z0-9_+=,.@-]/
    return specialCharsRegex.test(input)
  }

  return (
    <div>
      {(creatingRole || updatingPermission || isValidatingJSON) && (
        <Spinner size={'medium'} message={'Loading...'} overlay />
      )}

      <div className="page-header-wrapper">
        <Typography variant="heading4">Create Role</Typography>
        <div style={styles.buttonsWrapper}>
          <Button
            size="medium"
            variant="primary"
            onClick={() => onSave()}
            disabled={isEmpty(formData?.name?.value)}
          >
            Save
          </Button>
          <Button size="medium" variant="secondary" onClick={() => history.goBack()}>
            Cancel
          </Button>
        </div>
      </div>
      <div style={styles.formContainer}>
        <Textfield
          label={formFields.NAME}
          value={formData.name.value}
          onChange={e => handleFormFieldsChange(e, formFields.NAME)}
          width={'40%'}
        />
        <br />
        <Textarea
          label={formFields.DESCRIPTION}
          value={formData.description.value}
          onChange={e => handleFormFieldsChange(e, formFields.DESCRIPTION)}
          width={'40%'}
        />
        <br />
        <div>
          <Typography variant="label2">Permissions</Typography>
          <br />
          <div style={styles.buttonsWrapper}>
            <Button
              size="medium"
              variant="primary"
              onClick={() => setIsPoliciesModalOpen(true)}
            >
              Select Existing Policy
            </Button>
            <Button
              size="medium"
              variant="primary"
              onClick={() => setIsInlinePolicyModalOpen(true)}
            >
              Create Inline Policy
            </Button>
          </div>
          <br />
          <SelectedPoliciesList
            selectedPolicies={selectedPolicies}
            removePolicy={removePolicy}
            editInlinePolicy={editInlinePolicy}
          />
        </div>
        <br />
        <div>
          <Typography variant="label2">Tags (optional)</Typography>
          <br />
          <div style={styles.buttonsWrapper}>
            <Button
              size="medium"
              variant="primary"
              onClick={() => setIsTagsModalOpen(true)}
            >
              Add New Tag
            </Button>
          </div>
          <br />
          <div className="tag-list-container">
            <TagsList tagsList={tagsList} removeTag={removeTag} editTag={editTag} />
          </div>
        </div>
      </div>
      {isPoliciesModalOpen && (
        <ModalPopup
          width={790}
          title={'Select Policies'}
          buttons={modalButtons}
          onCancel={closeModal}
        >
          <ExistingPoliciesList
            addPolicy={addPolicy}
            selectedPolicies={selectedPolicies}
          />
        </ModalPopup>
      )}
      {isInlinePolicyModalOpen && (
        <ModalPopup
          width={790}
          title={'Create Policy'}
          buttons={inlinePolicyModalButtons}
          onCancel={closeInlinePolicyModal}
          data-testid="modal-popup"
        >
          <div>
            {validationWarnings?.isOpen && (
              <FindingsComponent findings={validationWarnings?.findings} />
            )}
            {validationWarnings?.showDialog && (
              <DialogPopup
                type="general"
                title={'Confirmation'}
                message={
                  'Policy definition contains warnings/suggestions, do you want to proceed?'
                }
                primaryButtonText={'Yes'}
                secondaryButtonText={'No'}
                onSubmit={() => saveInlinePolicy()}
                onCancel={() =>
                  setValidationWarnings(prevState => ({
                    ...prevState,
                    showDialog: false,
                  }))
                }
              />
            )}
            <br />
            <Textfield
              label={'Name'}
              value={policyName}
              onChange={e => setPolicyName(e.target.value)}
              width={420}
              error={characterCheck(policyName)}
              errorMsg="Name must be between 1 and 128 characters"
            />
            <br />
            <Textarea
              label={'Policy code (in JSON)'}
              value={json}
              onChange={e => setJson(e.target.value)}
              height={'200px'}
            />
            <br />
            <Button
              size="medium"
              variant="secondary"
              onClick={() => onValidate(true)}
              disabled={isEmpty(json)}
            >
              Validate
            </Button>
            <br />
          </div>
        </ModalPopup>
      )}
      {isTagsModalOpen && (
        <ModalPopup
          width={790}
          title={'Add New Tag'}
          buttons={modalButtons}
          onCancel={closeModal}
        >
          <CreateTag
            addHandler={addHandler}
            tagsList={tagsList}
            updatedTags={updateTag}
          />
        </ModalPopup>
      )}
      <br />
    </div>
  )
}

export default CreateRole
