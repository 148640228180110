import { getPermissionConstraints } from 'action_creators/permissions_constraints'
import React from 'react'
import ConstraintPanel from './ConstraintPanel'

const ConstraintPanelContainer = ({
  profileId,
  permissionName,
  permissionType,
  openModal,
  permissionConstraintTypes,
  isEditMode,
  dispatch,
  viewOnly = false,
}) => {
  const getConstraints = async constraintType => {
    await dispatch(
      getPermissionConstraints(
        profileId,
        permissionName,
        permissionType,
        constraintType
      )
    )
  }
  return (
    <div style={{ minWidth: '640px' }}>
      {permissionConstraintTypes?.map(constraintType => (
        <ConstraintPanel
          key={constraintType}
          profileId={profileId}
          constraintType={constraintType}
          permissionName={permissionName}
          permissionType={permissionType}
          openModal={openModal}
          getConstraints={getConstraints}
          isEditMode={isEditMode}
          viewOnly={viewOnly}
        />
      ))}
    </div>
  )
}

export default ConstraintPanelContainer
