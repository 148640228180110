import doFetch from 'utils/do_fetch'

export const fetchMyApprovalsDetailsList = async (payload = {}) => {
  const listData = await doFetch({
    path: `/v1/approvals/${payload}`,
    method: 'get',
  })
  return listData
}

export const fetchApproversList = async (payload = {}) => {
  const listData = await doFetch({
    path: `/v1/approvals/${payload.id}/approver-groups/${payload.groupId}`,
    method: 'get',
  })

  return listData
}
