import get from 'lodash/get'

import {
  FETCH_INITIAL_EVENTS,
  FETCH_EVENT_FIELDS,
  FETCH_FILTER_OPERATORS,
  FETCH_MORE_EVENTS,
} from 'action_creators/audit_log'

export const auditLogReducerKey = 'auditLog'

const initialState = {
  events: [],
  isFetchingInitialEvents: false,
  filterOperators: {},
  eventFields: {},
  nextPageURL: null,
  page: 0,
  error: null,
}

export function auditLogReducer(state = initialState, action) {
  switch (action.type) {
    case `${FETCH_INITIAL_EVENTS}_PENDING`: {
      return {
        ...state,
        isFetchingInitialEvents: true,
      }
    }

    case `${FETCH_INITIAL_EVENTS}_FULFILLED`: {
      // TODO: make a cache with string of arguments as key?
      const events = action.payload.data.map((event, idx) => {
        return {
          ...event,
          id: `0${idx}${event.timestamp}`,
        }
      })

      const nextPageFullURL = get(action.payload, 'headers.next-page', '')
      const nextPageURL = nextPageFullURL.split('/api')[1]

      return {
        ...state,
        events,
        nextPageURL,
        page: 0,
        isFetchingInitialEvents: false,
      }
    }

    case `${FETCH_INITIAL_EVENTS}_REJECTED`: {
      return {
        ...state,
        isFetchingInitialEvents: false,
        error: action.payload,
        page: 0,
        events: [],
        nextPageURL: null,
      }
    }

    case `${FETCH_MORE_EVENTS}_PENDING`: {
      return {
        ...state,
        isFetchingMoreEvents: true,
      }
    }

    case `${FETCH_MORE_EVENTS}_FULFILLED`: {
      // TODO: make a cache with string of arguments as key?
      const { page } = action.meta
      const events = action.payload.data.map((event, idx) => {
        return {
          ...event,
          id: `${page}${idx}${event.timestamp}`,
        }
      })

      const nextPageFullURL = get(action.payload, 'headers.next-page', '')
      const nextPageURL = nextPageFullURL.split('/api')[1]

      return {
        ...state,
        events: [...state.events, ...events],
        page: action.meta.page,
        nextPageURL,
        isFetchingMoreEvents: false,
      }
    }

    case `${FETCH_MORE_EVENTS}_REJECTED`: {
      return {
        ...state,
        isFetchingMoreEvents: false,
        error: action.payload,
      }
    }

    case `${FETCH_FILTER_OPERATORS}_FULFILLED`: {
      return {
        ...state,
        filterOperators: action.payload.data,
      }
    }

    case `${FETCH_EVENT_FIELDS}_FULFILLED`: {
      return {
        ...state,
        eventFields: action.payload.data,
      }
    }

    default:
      return { ...state }
  }
}
