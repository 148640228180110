import React from 'react'
import PropTypes from 'prop-types'
import ApplicationContainer from '../../admin/applications/Application/ApplicationContainer'

const ProfileList = ({ match }) => {
  return (
    <ApplicationContainer history={history} match={match} requestAccess={true} />
  )
}

ProfileList.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
}

export default ProfileList
