import { fetchIfLoggedIn } from './utils.js'

export const FETCH_SCHEDULED_TASKS = 'FETCH_SCHEDULED_TASKS'
export const FETCH_SCHEDULED_TASK = 'FETCH_SCHEDULED_TASK'
export const FETCH_SCHEDULED_TASK_STATUS = 'FETCH_SCHEDULED_TASK_STATUS'

export const fetchScheduledTasks = () => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: FETCH_SCHEDULED_TASKS,
      method: 'get',
      path: '/tasks/services/tasks',
    })
  )
}

export const fetchScheduledTask = ({ taskServiceId, taskId }) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: FETCH_SCHEDULED_TASK,
      method: 'get',
      path: `/tasks/services/${taskServiceId}/tasks/${taskId}`,
    })
  )
}

export const fetchScheduledTaskStatus = taskId => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: FETCH_SCHEDULED_TASK_STATUS,
      method: 'get',
      path: `/tasks/services/tasks/${taskId}/statuses`,
    })
  )
}
