import React, { useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import ModalPopup from 'britive-design-system/core/components/modal-popup'
import Textfield from 'britive-design-system/core/components/textfield'
import Select from 'britive-design-system/core/components/select'
import Spinner from 'britive-ui-components/core/components/Spinner'
import {
  availablePermissionTypes,
  fieldsLabel,
  defaultStateValues,
} from './constants'

export const AddPermissionForK8s = ({ onClose, papId, onAdd }) => {
  const [isAdding, setIsAdding] = useState(false)
  const [permissionName, setPermissionName] = useState(defaultStateValues.name)
  const [permissionType, setPermissionType] = useState(defaultStateValues.type)

  const resetLocalState = () => {
    setPermissionName(defaultStateValues.name)
    setPermissionType(defaultStateValues.type)
    setIsAdding(false)
  }

  const validateForm = () => {
    let hasError = false

    if (!permissionName.value?.length) {
      setPermissionName({
        ...permissionName,
        error: 'Name is a mandatory field.',
      })
      hasError = true
    }

    if (isEmpty(permissionType.value)) {
      setPermissionType({
        ...permissionType,
        error: 'Type is a mandatory field.',
      })
      hasError = true
    }

    return hasError
  }

  const addPermission = async () => {
    if (validateForm()) {
      return
    }

    const permissionData = {
      papId,
      name: permissionName.value,
      type: permissionType.value?.value,
    }

    setIsAdding(true)
    await onAdd(permissionData)
    resetLocalState()
  }

  return (
    <ModalPopup
      width={600}
      title="Add Permission"
      buttons={[
        {
          text: isAdding ? <Spinner /> : 'Add',
          variant: 'primary',
          onClick: addPermission,
          size: 'large',
          disabled: isAdding,
        },
        {
          text: 'Close',
          variant: 'secondary',
          onClick: onClose,
          size: 'large',
          disabled: isAdding,
        },
      ]}
      onCancel={onClose}
    >
      <Textfield
        type="text"
        value={permissionName.value}
        label={fieldsLabel.name}
        width="400px"
        error={!!permissionName.error}
        errorMsg={permissionName.error}
        onChange={e =>
          setPermissionName({
            value: e.target.value,
            error: e.target.value?.length ? null : 'This is a required field',
          })
        }
      />
      <br />
      <Select
        label={fieldsLabel.type}
        value={permissionType.value}
        options={availablePermissionTypes}
        getOptionLabel={option => option.title}
        width="400px"
        error={!!permissionType.error}
        errorMessage={permissionType.error}
        onChange={(_, selectedOption) =>
          setPermissionType({
            value: selectedOption,
            error: null,
          })
        }
      />
    </ModalPopup>
  )
}
