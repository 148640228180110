import get from 'lodash/get'
import * as actions from 'actions'

const initialState = {
  loading: false,
  error: null,
  data: {},
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case `${actions.GET_BROKERS_BY_POOL}_PENDING`: {
      return {
        ...state,
        loading: true,
        error: null,
      }
    }

    case `${actions.GET_BROKERS_BY_POOL}_FULFILLED`: {
      const { poolId } = action.meta
      const data = get(action, 'payload.data.data')
      const lastEvaluatedKey = get(action, 'payload.data.lastEvaluatedKey')
      return {
        ...state,
        loading: false,
        error: null,
        lastEvaluatedKey,
        searchText: action?.meta?.searchText,
        data: action?.meta?.lastEvaluatedKey
          ? {
              ...state.data,
              [poolId]: [...(state.data[poolId] || []), ...data],
            }
          : {
              ...state.data,
              [poolId]: data,
            },
      }
    }

    case `${actions.GET_BROKERS_BY_POOL}_REJECTED`: {
      return {
        ...state,
        loading: false,
        error: 'Unable fetch the Brokers.',
      }
    }

    default:
      return { ...state }
  }
}
