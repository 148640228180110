import React from 'react'
import { connect } from 'react-redux'
import MyApprovalsMobileView from './MyApprovalsMobileView'
import {
  requestMyApprovalsList,
  requestUsersList,
} from '../../../../../action_creators/my-approvals'

const mapStateToProps = state => {
  const { myApprovalsList: myApprovalsListState } = state?.userSecretsReducer
  const {
    data: usersData,
    loading: usersLoading,
  } = state?.userSecretsReducer?.usersList
  return {
    myApprovalsListState,
    usersData: usersData?.result || [],
    usersLoading,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getMyApprovalsListData: () => dispatch(requestMyApprovalsList()),
    fetchUsers: () => dispatch(requestUsersList()),
  }
}

const MyApprovalsMobileViewWithStore = connect(
  mapStateToProps,
  mapDispatchToProps
)(MyApprovalsMobileView)

// eslint-disable-next-line react/display-name
const MySecretsMyApprovalsListMobileContainer = ({ history, match }) => {
  return <MyApprovalsMobileViewWithStore history={history} match={match} />
}

export default MySecretsMyApprovalsListMobileContainer
