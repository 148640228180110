import React, { useState } from 'react'

import Button from 'britive-design-system/core/components/button'
import Textfield from 'britive-design-system/core/components/textfield'
import Typography from 'britive-design-system/core/components/typography'
import DialogPopup from 'britive-design-system/core/components/dialog'

import PasswordPolicyCheck from 'components/PasswordPolicyCheck'
import { RESET_PASSWORD } from 'constants/cognitoAuthentication'

import Form from './Form'

const OTP_COPY = `
  An OTP will be emailed to you on clicking the "Continue" button below. 
  Please use this OTP to reset your password in the following screen.
`

const ResetPasswordForm = ({
  fields,
  handleChange,
  errorMessage,
  isEmailSentModalOpen,
  sendOTPEmail,
  changePassword,
  setIsEmailSentModalOpen,
  history,
}) => {
  const [showResetPasswordForm, setShowResetPassordForm] = useState(false)
  const [isPasswordValid, setIsPasswordValid] = useState(false)

  const formValid = () => {
    return isPasswordValid && fields.oneTimePassword
  }

  const updatePasswordValidity = value => {
    setIsPasswordValid(value)
  }
  return (
    <Form
      className="login-form"
      onSubmit={
        showResetPasswordForm
          ? e => {
              changePassword(e, RESET_PASSWORD)
            }
          : sendOTPEmail
      }
      errorMessage={errorMessage}
      title="Reset your password"
    >
      <div className="field">
        <Textfield
          id="username"
          name="username"
          placeholder="Username or email"
          value={fields.username}
          onChange={handleChange}
          readOnly={showResetPasswordForm}
          label="Username or email"
        />
        {showResetPasswordForm ? (
          <a className="text-link" onClick={() => setShowResetPassordForm(false)}>
            Not you?
          </a>
        ) : (
          <a className="text-link" onClick={() => history.push('/login')}>
            Back to login
          </a>
        )}
      </div>

      {!showResetPasswordForm ? (
        <>
          <Typography variant="body">{OTP_COPY}</Typography>

          <Button size="large" disabled={!fields.username}>
            Continue
          </Button>
        </>
      ) : (
        <>
          <div className="field">
            <Textfield
              id="oneTimePassword"
              name="oneTimePassword"
              placeholder="OTP"
              label="OTP"
              hideLabel={false}
              value={fields.oneTimePassword}
              onChange={handleChange}
            />
            <a className="text-link" onClick={sendOTPEmail}>
              Resend OTP
            </a>
          </div>

          <Textfield
            id="newPassword"
            autoComplete="off"
            name="newPassword"
            placeholder="New password"
            label="New password"
            hideLabel={false}
            value={fields.newPassword}
            type="password"
            onChange={handleChange}
          />

          <Textfield
            id="confirmPassword"
            name="confirmPassword"
            placeholder="Verify new password"
            label="Verify new password"
            hideLabel={false}
            type="password"
            value={fields.confirmPassword}
            onChange={handleChange}
          />

          <PasswordPolicyCheck
            newPassword={fields.newPassword}
            confirmPassword={fields.confirmPassword}
            checkAgainstConfirmPassword
            afterPolicyCheckUpdate={updatePasswordValidity}
          />

          <Button disabled={!formValid()} size="large">
            Reset Password
          </Button>
        </>
      )}
      {isEmailSentModalOpen && (
        <DialogPopup
          type="general"
          title="Email sent"
          message="An email with the OTP has been sent to your registered email address."
          primaryButtonText="Ok"
          onSubmit={() => {
            setShowResetPassordForm(true)
            setIsEmailSentModalOpen(false)
          }}
        />
      )}
    </Form>
  )
}

export default ResetPasswordForm
