import React from 'react'
import debounce from 'lodash/debounce'
import ButtonGroup from 'britive-design-system/core/components/buttonGroup'
import Button from 'britive-design-system/core/components/button'
import Star from 'static/images/star.svg'
import StarWhite from 'static/images/star-white.svg'
import Clock from 'static/images/clock.svg'
import ClockWhite from 'static/images/clock-white.svg'
import SearchBox from './SearchBox'
import GroupByFilters from './GroupByFilters'
import { availableProfilesTypeFilters } from '../constants'

const CSS_CLASSNAME_PREFIX = 'my-resources'

const PageFilters = ({
  activeQuickFilter,
  onQuickFilter,
  onSearch,
  onGroupBy,
  isExpanded,
  setIsExpanded,
}) => {
  return (
    <>
      <div className={`${CSS_CLASSNAME_PREFIX}-profiles-type-filters`}>
        <ButtonGroup size="large">
          <Button
            variant={
              activeQuickFilter === availableProfilesTypeFilters.FAVORITES
                ? 'primary'
                : 'secondary'
            }
            onClick={() => onQuickFilter(availableProfilesTypeFilters.FAVORITES)}
          >
            <img
              src={
                activeQuickFilter === availableProfilesTypeFilters.FAVORITES
                  ? StarWhite
                  : Star
              }
            />
          </Button>
          <Button
            variant={
              activeQuickFilter === availableProfilesTypeFilters.FREQUENTLY_USED
                ? 'primary'
                : 'secondary'
            }
            onClick={() =>
              onQuickFilter(availableProfilesTypeFilters.FREQUENTLY_USED)
            }
          >
            <img
              src={
                activeQuickFilter === availableProfilesTypeFilters.FREQUENTLY_USED
                  ? ClockWhite
                  : Clock
              }
            />
          </Button>
        </ButtonGroup>
        <SearchBox onChange={debounce(onSearch, 300)} />
      </div>
      <GroupByFilters
        onChange={onGroupBy}
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
      />
    </>
  )
}

export default PageFilters
