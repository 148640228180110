import * as actions from 'actions'

const initialState = {
  getPermissionsLoading: false,
  deletePermissionLoading: false,
  permissionsList: [],
  availablePermissionsLoading: false,
  availablePermissions: [],
  updatePermissionsLoading: false,
  systemDefinedValuesLoading: false,
  systemDefinedValues: [],
  updatePermissionVariablesLoading: false,
}

export function permissionsReducer(state = initialState, action) {
  switch (action.type) {
    case `${actions.PROFILE_MANAGEMENT_GET_PERMISSIONS}_PENDING`: {
      return {
        ...state,
        getPermissionsLoading: true,
      }
    }

    case `${actions.PROFILE_MANAGEMENT_GET_PERMISSIONS}_FULFILLED`: {
      return {
        ...state,
        getPermissionsLoading: false,
        permissionsList: action.payload?.data,
      }
    }

    case `${actions.PROFILE_MANAGEMENT_GET_PERMISSIONS}_REJECTED`: {
      return {
        ...state,
        getPermissionsLoading: false,
      }
    }

    case `${actions.PROFILE_MANAGEMENT_DELETE_PERMISSION}_PENDING`: {
      return {
        ...state,
        deletePermissionLoading: true,
      }
    }

    case `${actions.PROFILE_MANAGEMENT_DELETE_PERMISSION}_FULFILLED`: {
      return {
        ...state,
        deletePermissionLoading: false,
      }
    }

    case `${actions.PROFILE_MANAGEMENT_DELETE_PERMISSION}_REJECTED`: {
      return {
        ...state,
        deletePermissionLoading: false,
      }
    }

    case `${actions.PROFILE_MANAGEMENT_GET_AVAILABLE_PERMISSIONS}_PENDING`: {
      return {
        ...state,
        availablePermissionsLoading: true,
      }
    }

    case `${actions.PROFILE_MANAGEMENT_GET_AVAILABLE_PERMISSIONS}_FULFILLED`: {
      return {
        ...state,
        availablePermissionsLoading: false,
        availablePermissions: action.payload?.data,
      }
    }

    case `${actions.PROFILE_MANAGEMENT_GET_AVAILABLE_PERMISSIONS}_REJECTED`: {
      return {
        ...state,
        availablePermissionsLoading: false,
      }
    }

    case `${actions.PROFILE_MANAGEMENT_UPDATE_PERMISSIONS_VALUES}_PENDING`: {
      return {
        ...state,
        updatePermissionsLoading: true,
      }
    }

    case `${actions.PROFILE_MANAGEMENT_UPDATE_PERMISSIONS_VALUES}_FULFILLED`: {
      return {
        ...state,
        updatePermissionsLoading: false,
        availablePermissions: action.payload?.data,
      }
    }

    case `${actions.PROFILE_MANAGEMENT_UPDATE_PERMISSIONS_VALUES}_REJECTED`: {
      return {
        ...state,
        updatePermissionsLoading: false,
      }
    }

    case `${actions.PROFILE_MANAGEMENT_GET_SYSTEM_DEFINED_VALUES}_PENDING`: {
      return {
        ...state,
        systemDefinedValuesLoading: true,
      }
    }

    case `${actions.PROFILE_MANAGEMENT_GET_SYSTEM_DEFINED_VALUES}_FULFILLED`: {
      return {
        ...state,
        systemDefinedValuesLoading: false,
        systemDefinedValues: action.payload?.data,
      }
    }

    case `${actions.PROFILE_MANAGEMENT_GET_SYSTEM_DEFINED_VALUES}_REJECTED`: {
      return {
        ...state,
        systemDefinedValuesLoading: false,
      }
    }
    case `${actions.PROFILE_MANAGEMENT_UPDATE_PERMISSIONS_VARIABLES}_PENDING`: {
      return {
        ...state,
        updatePermissionVariablesLoading: true,
      }
    }

    case `${actions.PROFILE_MANAGEMENT_UPDATE_PERMISSIONS_VARIABLES}_FULFILLED`: {
      return {
        ...state,
        updatePermissionVariablesLoading: false,
      }
    }

    case `${actions.PROFILE_MANAGEMENT_UPDATE_PERMISSIONS_VARIABLES}_REJECTED`: {
      return {
        ...state,
        updatePermissionVariablesLoading: false,
      }
    }

    default:
      return { ...state }
  }
}
