import * as actions from 'actions'

const initialState = {
  loading: false,
  error: null,
  data: [],
  updateLoading: false,
}

export function profileDetailsReducer(state = initialState, action) {
  switch (action.type) {
    case `${actions.PROFILE_MANAGEMENT_PROFILE_DETAILS}_PENDING`: {
      return {
        ...state,
        loading: true,
        error: null,
      }
    }

    case `${actions.PROFILE_MANAGEMENT_PROFILE_DETAILS}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload?.data,
      }
    }

    case `${actions.PROFILE_MANAGEMENT_PROFILE_DETAILS}_REJECTED`: {
      return {
        ...state,
        loading: false,
        error: 'Unable fetch the Profile Details',
      }
    }
    case `${actions.PROFILE_MANAGEMENT_UPDATE_PROFILE}_PENDING`: {
      return {
        ...state,
        updateLoading: true,
      }
    }

    case `${actions.PROFILE_MANAGEMENT_UPDATE_PROFILE}_FULFILLED`: {
      return {
        ...state,
        updateLoading: false,
      }
    }

    case `${actions.PROFILE_MANAGEMENT_UPDATE_PROFILE}_REJECTED`: {
      return {
        ...state,
        updateLoading: false,
      }
    }

    default:
      return { ...state }
  }
}
