import React from 'react'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'

const POSITIONS = ['top', 'right', 'bottom', 'left']

const StyledBackgroundTooltip = styled(ReactTooltip)`
  background-color: #f6f6f6 !important;
  outline: rgba(205, 223, 237, 0.3) solid 0.5px;
  color: #6f6f6f !important;
  cursor: default;
  opacity: 1 !important;
  pointer-events: all;
  max-width: 50%;
  white-space: normal;

  ${POSITIONS.map(
    position => `
    &.place-${position} {
      &:before {
        border-${position}-color: rgba(205, 223, 237, 0.3) !important;
        border-${position}-style: solid !important;
        border-${position}-width: 8px !important;
      }
      &:after {
        border-${position}-color: #fff !important;
        border-${position}-style: solid !important;
        border-${position}-width: 6px !important;
      }
    }
  `
  )}
`

const StyledNoBackgroundTooltip = styled(ReactTooltip)`
  ${props => {
    const margin = props.marginY || 4
    return `
    margin-top: ${margin}px !important;
    margin-bottom: ${margin}px !important;
    `
  }}
  padding: 0;
  background: none !important;
  font-size: 10px;
  color: #000 !important;
  width: fit-content;
  height: fit-content;

  ${POSITIONS.map(
    position => `
    &.place-${position} {
      &:before {
        display: none;
      }
      &:after {
        display: none;
      }
    }
  `
  )}
`

export function BackgroundTooltip(props) {
  return <StyledBackgroundTooltip {...props} suppressClassNameWarning />
}

export function NoBackgroundTooltip(props) {
  return <StyledNoBackgroundTooltip {...props} suppressClassNameWarning />
}
