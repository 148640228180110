import React, { useEffect } from 'react'
import get from 'lodash/get'
import { connect } from 'react-redux'
import { useDispatch } from 'react-redux'
import ModalPopup from 'britive-design-system/core/components/modal-popup'
import Spinner from 'britive-design-system/core/components/spinner'
import { fetchScopesForPap } from 'action_creators/pap'
import { getSelectedApplicationRoot } from 'selectors/application_root'
import Associations from 'components/Associations'
import {
  getApplicationEnvironments,
  getSelectedApplicationEnvironmentGroups,
  getSelectedApplicationRootGroupId,
  getSelectedApplicationEnvironments,
} from 'selectors/environment'
import { getFetchingPapScopes, getPapScopes } from 'selectors/pap'
import './associationPopup.scss'

function AssociationPopup({
  profileName,
  profileId,
  onClose,
  requestAccess,
  ...props
}) {
  const dispatch = useDispatch()
  const {
    fetchingPapScopes,
    applicationRoot,
    rootGroupId,
    environments,
    environmentData,
    environmentGroups,
    scopes,
  } = props

  useEffect(() => {
    dispatch(fetchScopesForPap({ papId: profileId, requestAccess }))
  }, [])

  const handleOnClose = () => {
    onClose(false)
  }

  const getScopeIds = (scopes = []) => {
    return scopes.map(scope => scope.value)
  }

  return (
    <ModalPopup
      width={600}
      title={`${profileName} Scope`}
      onCancel={handleOnClose}
      buttons={[
        {
          text: 'Close',
          variant: 'secondary',
          onClick: handleOnClose,
          size: 'large',
        },
      ]}
    >
      <div className="profile-association-wrapper">
        {fetchingPapScopes ? (
          <Spinner overlayOnContainer size="medium" message="Loading..." />
        ) : (
          <Associations
            hideDropdownFilter
            hideCheckboxes
            hideUnselectedAssociationType
            environmentGroups={environmentGroups}
            environments={environments}
            environmentData={environmentData}
            defaultDirectory={applicationRoot?.appContainerId}
            applicationRoot={{
              id: rootGroupId,
              name: get(applicationRoot, 'catalogAppName'),
              appIcon: applicationRoot?.iconUrl,
            }}
            appName={get(applicationRoot, 'catalogAppName')}
            checkedNodes={getScopeIds(scopes[profileId])}
          />
        )}
      </div>
    </ModalPopup>
  )
}
const mapStateToProps = state => {
  return {
    rootGroupId: getSelectedApplicationRootGroupId(state),
    fetchingPapScopes: getFetchingPapScopes(state),
    applicationRoot: getSelectedApplicationRoot(state),
    environmentGroups: getSelectedApplicationEnvironmentGroups(state),
    environments: getApplicationEnvironments(state),
    environmentData: getSelectedApplicationEnvironments(state),
    scopes: getPapScopes(state),
  }
}

export default connect(mapStateToProps)(AssociationPopup)
