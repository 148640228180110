import * as actions from 'actions'
import user from 'classes/user.js'
import get from 'lodash/get'
import { mfaFactor, mfaStatus } from 'routes/user_settings/constants'

export default function reducer(
  state = {
    fetching: false,
    fetched: false,
    error: null,
    mfa: {
      fetching: false,
      error: null,
      data: {},
    },
  },
  action
) {
  switch (action.type) {
    case `${actions.GET_USER}_PENDING`: {
      return {
        ...state,
        fetching: true,
      }
    }

    case `${actions.GET_USER}_FULFILLED`: {
      const userObject = new user(action.payload ? action.payload.data : {})

      return {
        ...state,
        fetching: false,
        fetched: true,
        ...userObject,
      }
    }

    case `${actions.GET_USER}_REJECTED`: {
      return {
        ...state,
        fetching: null,
        fetched: null,
        error: action.payload,
      }
    }

    case actions.UPDATE_USER: {
      return {
        ...state,
        ...action.payload,
      }
    }

    case `${actions.GET_USER_MFA_STATUS}_PENDING`: {
      return {
        ...state,
        mfa: {
          fetching: true,
          error: null,
          data: {},
        },
      }
    }

    case `${actions.GET_USER_MFA_STATUS}_FULFILLED`: {
      return {
        ...state,
        mfa: {
          fetching: false,
          error: null,
          data: get(action, 'payload.data', {}),
        },
      }
    }

    case `${actions.GET_USER_MFA_STATUS}_REJECTED`: {
      const responseStatus = get(action, 'payload.response.status')
      if (responseStatus === 404) {
        // API returning 404 implies the user has not yet registered the MFA device.
        return {
          ...state,
          mfa: {
            fetching: false,
            error: null,
            data: {
              status: mfaStatus.unregistered,
              factor: mfaFactor.totp,
            },
          },
        }
      }

      return {
        ...state,
        mfa: {
          fetching: false,
          error: 'Unable to fetch MFA status.',
          data: {},
        },
      }
    }

    default:
      return { ...state }
  }
}
