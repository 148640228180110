import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'

import ActionModal from 'components/ActionModal'

import { deletePapPolicy, changePolicyStatus } from 'action_creators/papPolicy'

function PapPolicyActionModalWrapper({
  papPolicies,
  activeModalType,
  selectModalType,
  toggleModal,
  clearCheckedItems,
  dispatch,
  profileId,
  checkedItems,
  isModalOpen,
}) {
  const [actionStarted, setActionStarted] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  const [actionResults, setActionResults] = useState({})
  const [allFinished, setAllFinished] = useState(false)
  const [currentModalType, setCurrentModalType] = useState('')
  const [initialItemsList, setInitialItemsList] = useState([])

  const handleActionResults = () => {
    const allFinished = Object.values(actionResults).every(result =>
      ['successful', 'failure'].includes(result)
    )
    setAllFinished(allFinished)
    setIsUpdating(!allFinished)
    selectModalType()
  }

  const toggleThisModal = () => {
    if (!isUpdating) {
      toggleModal()
      clearCheckedItems()
      setAllFinished(false)
      setActionResults({})
      setActionStarted(false)
    }
  }

  const actions = useMemo(
    () => ({
      Delete: id => deletePapPolicy(profileId, id),
      Enable: id => changePolicyStatus(profileId, id, true),
      Disable: id => changePolicyStatus(profileId, id, false),
    }),
    []
  )

  const modalAction = () => {
    const modalAction = actions[activeModalType]
    setIsUpdating(true)
    Object.keys(checkedItems).forEach(async itemId => {
      if (!checkedItems[itemId]) {
        return
      }
      let status
      setActionResults(actionResults => ({ ...actionResults, [itemId]: 'pending' }))
      try {
        const response = await dispatch(modalAction(itemId))
        status =
          response.value.status === 200 || response.value.status === 204
            ? 'successful'
            : 'failure'
      } catch (e) {
        status = 'failure'
      }
      setActionResults(actionResults => ({ ...actionResults, [itemId]: status }))
    })
    toggleThisModal()
    setActionStarted(true)
  }

  useEffect(() => {
    setInitialItemsList(papPolicies)
  }, [papPolicies])

  useEffect(() => {
    actionStarted && handleActionResults()
  }, [actionResults])

  useEffect(() => {
    activeModalType && setCurrentModalType(activeModalType)
  }, [activeModalType])

  if (!isModalOpen) {
    return null
  }

  return (
    <ActionModal
      actionResults={actionResults}
      allFinished={allFinished}
      checkedItems={checkedItems}
      currentModalType={currentModalType}
      identifier="id"
      isModalOpen={isModalOpen}
      isUpdating={isUpdating}
      itemName="Policy"
      itemNameProperty="name"
      items={initialItemsList}
      modalAction={modalAction}
      toggleModal={toggleThisModal}
    />
  )
}

export default connect()(PapPolicyActionModalWrapper)
