import React, { useEffect, useState, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import Typography from 'britive-design-system/core/components/typography'
import Textfield from 'britive-design-system/core/components/textfield'
import Button from 'britive-design-system/core/components/button'
import Spinner from 'britive-design-system/core/components/spinner'
import Snackbar from 'britive-design-system/core/components/snackbar'
import ModalPopup from 'britive-design-system/core/components/modal-popup'
import { useSelector, useDispatch } from 'react-redux'
import { AssociationAccordion } from './components/AssociationAccordion'
import { AssociationApproversBreadcrumbs } from './components/AssociationApproversBreadcrumbs'
import { ApproversGroups } from './components/ApproversGroups'
import { ApproversGroupDetails } from './components/ApproversGroupDetails'
import TagMembersModal from 'components/MembersSelector/TagMembersModal'
import { approverGroupCondition, modalView, backToTopId } from './constants'
import { AddUserList } from './components/AddUserList'
import { AddTagsList } from './components/AddTagsList'
import {
  updateAssociationApproversSettings,
  updateApproversGroup as updateApproversGroupSettings,
  resetAssociationApproversSettings,
  fetchApproversGroups,
} from 'action_creators/access-request'
import {
  getApproversGroupData,
  getDataToUpdate,
  isDataValid,
  showToast,
  filterUsersIdsFromMembers,
  filterTagIdsFromMembers,
  getApproversDataToUpdate,
  isApproversGroupDataValid,
  getModalTitle,
  characterCheck,
} from './utils'
import './index.scss'
import BackToTop from 'britive-design-system/core/components/backToTop'
import ApproversGroupList from './components/ApproversGroupList'
import Accordion from 'britive-design-system/core/components/accordion'
import get from 'lodash/get'

const AssignAssociationApprovers = ({ match, app }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const routeParams = useParams()
  const associationRef = useRef(null)

  const { data: accessRequestSettings } = useSelector(
    state => state.accessRequestReducer?.accessRequestSettings
  )

  const { loading: approverGroupsLoading } = useSelector(
    state => state.accessRequestReducer?.approverGroups
  )

  const { loading: associationApproverSaving } = useSelector(
    state => state.accessRequestReducer?.associationApprover
  )

  const { data: approversGroups } = useSelector(
    state => state.accessRequestReducer?.approversGroups
  )

  const associationApproversId = routeParams?.associationApproversId

  // modal states
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isApproversGroupsModalOpen, setIsApproversGroupsModalOpen] = useState(false)
  const [modalBodyView, setModalBodyView] = useState(null)

  // Storing the changes done for the users and tags modal.
  const [selectedUsers, setSelectedUsers] = useState([])
  const [selectedTags, setSelectedTags] = useState([])
  const [tagToShowMembers, setTagToShowMembers] = useState(null)
  const [selectedApproversGroups, setSelectedApproversGroups] = useState([])

  // Keeps the approvers group currently selected to edit.
  const [selectedApproversGroupData, setSelectedApproversGroupData] = useState({})

  // local states to store the changed fields
  const [updatedAssociations, setUpdatedAssociations] = useState({
    value: [],
    touched: false,
  })
  const [updatedApprovers, setUpdatedApprovers] = useState({
    value: [],
    touched: false,
  })
  const [updatedGroupName, setUpdatedGroupName] = useState({
    value: null,
    error: null,
    touched: false,
  })
  const [errorMessage, setErrorMessage] = useState({
    commonSettings: null,
    approversGroup: null,
  })

  const goBackToTheSettings = () => {
    history.push(`${match.url}/access-request-settings`)
  }

  if (associationApproversId && isEmpty(accessRequestSettings)) {
    goBackToTheSettings()
  }

  useEffect(() => {
    dispatch(fetchApproversGroups(app.appContainerId))
    if (associationApproversId && accessRequestSettings?.associationApprovers) {
      const associationApprover = accessRequestSettings?.associationApprovers.find(
        approver => approver.id === associationApproversId
      )

      setUpdatedAssociations({
        value: associationApprover.associations,
        touched: false,
      })
      setUpdatedApprovers({
        value: associationApprover.approversGroups,
        touched: false,
      })
      setUpdatedGroupName({
        value: associationApprover.name,
        touched: false,
        error: null,
      })
    }

    return () => {
      setUpdatedGroupName({
        value: null,
        touched: false,
        error: null,
      })
      setUpdatedAssociations({
        value: [],
        touched: false,
      })
      setUpdatedApprovers({
        value: [],
        touched: false,
      })
      dispatch(resetAssociationApproversSettings())
    }
  }, [JSON.stringify(accessRequestSettings?.associationApprovers)])

  const handleGroupNameChange = value => {
    setUpdatedGroupName({
      value: value,
      error: !value
        ? 'Group Name is a mandatory field.'
        : characterCheck(value)
        ? 'Name must be between 1 and 128 characters'
        : null,
      touched: true,
    })
  }

  const handleAssociationSelection = newAssociations => {
    setUpdatedAssociations({
      value: newAssociations,
      touched: true,
    })
  }

  const updateApproversGroup = newApproversList => {
    setUpdatedApprovers({
      value: newApproversList,
      touched: true,
    })
  }

  const handleDeleteApprover = approverId => {
    const newApproversList = updatedApprovers?.value.filter(
      approver => approver?.id !== approverId
    )
    setUpdatedApprovers({
      value: newApproversList,
      touched: true,
    })
  }

  const resetErrors = () =>
    setErrorMessage({
      commonSettings: null,
      approversGroup: null,
    })

  const handleSave = async () => {
    const updatedData = getDataToUpdate(
      updatedGroupName,
      updatedAssociations,
      updatedApprovers
    )

    const dataError = isDataValid(
      updatedGroupName.value,
      updatedAssociations.value,
      updatedApprovers.value
    )

    if (dataError?.length) {
      setErrorMessage({
        commonSettings: dataError,
        approversGroup: null,
      })
      return
    }

    try {
      resetErrors()
      await dispatch(
        updateAssociationApproversSettings(
          updatedData,
          app.appContainerId,
          associationApproversId
        )
      )
      goBackToTheSettings()
      showToast('Association Approvers updated.', 'success')
    } catch (error) {
      const errorMessage = get(error, 'response.data.message', 'Unknown error')
      setErrorMessage({
        commonSettings: errorMessage || 'Unable to save the Association Approvers.',
        approversGroup: null,
      })
    }
  }

  const handleApproversGroupSave = async () => {
    const dataToUpdate = getApproversDataToUpdate(
      selectedApproversGroupData,
      updatedApprovers.value
    )

    const dataError = isApproversGroupDataValid(
      selectedApproversGroupData,
      dataToUpdate
    )

    if (dataError?.length) {
      setErrorMessage({
        commonSettings: null,
        approversGroup: dataError,
      })
      return
    }

    try {
      resetErrors()
      await dispatch(
        updateApproversGroupSettings(
          dataToUpdate,
          app.appContainerId,
          selectedApproversGroupData.id
        )
      )
      closeModal()
    } catch (error) {
      const errorMessage = get(error, 'response.data.message', 'Unknown error')
      setErrorMessage({
        commonSettings: null,
        approversGroup: errorMessage || 'Unable to save the Approver Group.',
      })
    }
  }

  const closeModal = () => {
    // resetting everything to initial state
    setIsModalOpen(false)
    setIsApproversGroupsModalOpen(false)
    setSelectedApproversGroupData({})
    setSelectedUsers([])
    setSelectedTags([])
    resetErrors()
  }

  const openModal = approverGroupId => {
    if (approverGroupId) {
      const selectedGroupData = getApproversGroupData(
        approverGroupId,
        updatedApprovers.value
      )
      setSelectedApproversGroupData(selectedGroupData)
    } else {
      // setting the default value of the condition.
      setSelectedApproversGroupData({ condition: approverGroupCondition.all })
    }
    switchToConsolidatedView()
    resetErrors()
    setIsModalOpen(true)
  }

  const openModalToViewMembers = selectedData => {
    setSelectedApproversGroupData(selectedData)
    setModalBodyView(modalView.membersOnly)
    setIsModalOpen(true)
  }

  const openModalToSelectApprovers = () => {
    setIsApproversGroupsModalOpen(true)
    setModalBodyView(modalView.selectApproversGroup)
  }

  const switchToConsolidatedView = () => setModalBodyView(modalView.consolidated)

  const onUserOrTagSave = () => {
    setSelectedApproversGroupData({
      ...selectedApproversGroupData,
      members: [
        ...(selectedApproversGroupData?.members || []),
        ...selectedUsers,
        ...selectedTags,
      ],
    })

    switchToConsolidatedView()
    setSelectedUsers([])
    setSelectedTags([])
  }

  const onMembersDelete = memberId => {
    setSelectedApproversGroupData({
      ...selectedApproversGroupData,
      members: selectedApproversGroupData?.members.filter(
        memberData => memberData.id !== memberId
      ),
    })
  }

  const secondaryButton = {
    text: 'Cancel',
    variant: 'secondary',
    onClick:
      modalBodyView === modalView.consolidated ||
      modalBodyView === modalView.membersOnly
        ? closeModal
        : switchToConsolidatedView,
    size: 'medium',
  }

  let modalButtons = [
    {
      text: modalBodyView === modalView.consolidated ? 'Save' : 'Add',
      variant: 'primary',
      onClick:
        modalBodyView === modalView.consolidated
          ? handleApproversGroupSave
          : onUserOrTagSave,
      size: 'medium',
      disabled:
        modalBodyView === modalView.consolidated
          ? characterCheck(selectedApproversGroupData?.name) ||
            isEmpty(
              getApproversDataToUpdate(
                selectedApproversGroupData || {},
                updatedApprovers.value
              )
            )
          : false,
    },
    secondaryButton,
  ]

  let modalButtonsForApproversGroupsSelection = [
    {
      text: 'Add',
      variant: 'primary',
      onClick: () => {
        updateApproversGroup([...updatedApprovers.value, ...selectedApproversGroups])
        setSelectedApproversGroups([])
        closeModal()
      },
      size: 'medium',
      disabled: !selectedApproversGroups.length,
    },
    {
      ...secondaryButton,
      onClick: () => {
        setIsApproversGroupsModalOpen(false)
        setSelectedApproversGroupData({})
        setSelectedUsers([])
        setSelectedTags([])
        resetErrors()
      },
    },
  ]

  if (modalBodyView === modalView.membersOnly) {
    modalButtons = [secondaryButton]
  }

  const renderErrorSnackbar = error => {
    if (error?.length) {
      return (
        <>
          <Snackbar errorList={[error]} />
          <br />
        </>
      )
    }
  }

  const renderButtons = () => (
    <div className="page-header-buttons">
      <Button
        size="medium"
        variant="primary"
        onClick={handleSave}
        disabled={
          !isEmpty(updatedGroupName.error) ||
          isEmpty(
            getDataToUpdate(updatedGroupName, updatedAssociations, updatedApprovers)
          )
        }
      >
        Save
      </Button>
      <Button size="medium" variant="secondary" onClick={goBackToTheSettings}>
        Cancel
      </Button>
    </div>
  )
  const deleteConfirmationDialog = {
    title: 'Remove Approver Group?',
    message: 'The approver group will be removed from the association approver.',
    primaryButtonText: 'Yes, Remove',
    secondaryButtonText: 'No',
  }

  return (
    <div
      className="assign-association-approvers-container assign-association-back-to-top"
      ref={associationRef}
    >
      {(associationApproverSaving || approverGroupsLoading) && (
        <Spinner size={'medium'} message={'Loading...'} overlay />
      )}
      <div className="page-header-wrapper">
        <AssociationApproversBreadcrumbs
          match={match}
          appName={app?.catalogAppDisplayName}
        />
        <Typography variant="heading5">Assign Association Approvers</Typography>
        {renderButtons()}
      </div>
      <br />
      {renderErrorSnackbar(errorMessage.commonSettings)}
      <div>
        <div>
          <Textfield
            label="Name"
            value={updatedGroupName.value}
            onChange={e => handleGroupNameChange(e.target.value)}
            width="256px"
            error={!!updatedGroupName.error}
            errorMsg={updatedGroupName.error}
          />
          <AssociationAccordion
            associations={updatedAssociations.value}
            onChange={handleAssociationSelection}
          />
          <Accordion title="Approvers" expanded>
            <ApproversGroups
              rows={updatedApprovers?.value}
              onDelete={handleDeleteApprover}
              onEditApproverGroup={openModal}
              onViewApproverGroupMember={openModalToViewMembers}
              onSelectApproverGroup={openModalToSelectApprovers}
              deleteConfirmationDialog={deleteConfirmationDialog}
              deleteIconText={'Remove'}
              searchable={true}
              selectApproverButton={true}
            />
          </Accordion>
        </div>
        <BackToTop id={backToTopId} parentRef={associationRef} />
      </div>
      {isApproversGroupsModalOpen && (
        <ModalPopup
          width={720}
          title="Select Approver Groups"
          buttons={modalButtonsForApproversGroupsSelection}
          onCancel={closeModal}
        >
          <div className="association-group-modal-body">
            <ApproversGroupList
              rows={
                approversGroups?.approversGroupSummary.filter(
                  ag => !updatedApprovers.value.find(u => u.id === ag.id)
                ) || []
              }
              onViewMembers={openModalToViewMembers}
              onChange={setSelectedApproversGroups}
              selectable
              selectedApproversGroup={selectedApproversGroups}
              noDataMessage={'No more approver groups to select.'}
            />
          </div>
        </ModalPopup>
      )}

      {isModalOpen && (
        <ModalPopup
          width={720}
          title={getModalTitle(modalBodyView, selectedApproversGroupData)}
          buttons={modalButtons}
          onCancel={closeModal}
        >
          <div className="association-group-modal-body">
            {renderErrorSnackbar(errorMessage.approversGroup)}
            {(modalBodyView === modalView.consolidated ||
              modalBodyView === modalView.membersOnly) && (
              <ApproversGroupDetails
                data={selectedApproversGroupData}
                onChange={setSelectedApproversGroupData}
                onlyMembers={modalBodyView === modalView.membersOnly}
                onMembersDelete={onMembersDelete}
                onViewMembers={setTagToShowMembers}
                onAdd={view => {
                  setModalBodyView(view)
                }}
              />
            )}

            {modalBodyView === modalView.users && (
              <AddUserList
                currentIds={filterUsersIdsFromMembers(
                  selectedApproversGroupData.members
                )}
                selectedUsers={selectedUsers}
                onChange={newUsers => setSelectedUsers(newUsers)}
              />
            )}
            {modalBodyView === modalView.tags && (
              <AddTagsList
                currentIds={filterTagIdsFromMembers(
                  selectedApproversGroupData.members
                )}
                selectedTags={selectedTags}
                onChange={newTags => setSelectedTags(newTags)}
                onViewMembers={setTagToShowMembers}
              />
            )}
          </div>
        </ModalPopup>
      )}
      {tagToShowMembers && (
        <TagMembersModal
          tag={tagToShowMembers}
          onClose={() => setTagToShowMembers(null)}
        />
      )}
    </div>
  )
}

export default AssignAssociationApprovers
