import React from 'react'
import Typography from 'britive-design-system/core/components/typography'
import { tableColumns, translatedStrings, userRequestsListString } from './constants'
import RequestsList from './request-list/RequestsList'
import { getSecretStatus, getActionLabel } from '../utils'

const MyRequestList = ({
  getMyRequestsListData,
  myRequestsListState,
  updateRequestsListSearchTerm,
  getMyRequestsListDataLoadMore,
  withdrawRequest,
  match,
}) => {
  return (
    <div>
      <div className="page-header-wrapper">
        <Typography variant="heading5">My Requests</Typography>
      </div>
      <RequestsList
        getMyRequestsListData={getMyRequestsListData}
        myRequestsListState={myRequestsListState}
        updateRequestsListSearchTerm={updateRequestsListSearchTerm}
        getMyRequestsListDataLoadMore={getMyRequestsListDataLoadMore}
        translatedStrings={translatedStrings}
        getApprovalStatus={getSecretStatus}
        getActionLabel={getActionLabel}
        tableColumns={tableColumns}
        requestsListString={userRequestsListString}
        withdrawRequest={withdrawRequest}
        match={match}
      />
    </div>
  )
}

export default MyRequestList
