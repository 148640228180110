import * as actions from 'actions'

const initialState = {
  policyListLoading: false,
  policyDeleteLoading: false,
  paginatedResource: [],
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case `${actions.GET_RESOURCES_POLICIES}_PENDING`: {
      return {
        ...state,
        policyListLoading: true,
      }
    }

    case `${actions.GET_RESOURCES_POLICIES}_FULFILLED`: {
      const { data } = action.payload
      return {
        ...state,
        policyListLoading: false,
        paginatedResource: {
          data,
        },
      }
    }

    case `${actions.GET_RESOURCES_POLICIES}_REJECTED`: {
      return {
        ...state,
        policyListLoading: false,
      }
    }

    case `${actions.DELETE_RESOURCE_POLICIES}_PENDING`: {
      return {
        ...state,
        policyDeleteLoading: true,
      }
    }

    case `${actions.DELETE_RESOURCE_POLICIES}_FULFILLED`: {
      return {
        ...state,
        policyDeleteLoading: false,
      }
    }

    case `${actions.DELETE_RESOURCE_POLICIES}_REJECTED`: {
      return {
        ...state,
        policyDeleteLoading: false,
      }
    }

    default:
      return { ...state }
  }
}
