import * as actions from 'actions'

export default function reducer(
  state = { path: '/my-access', openNavigation: false },
  action
) {
  switch (action.type) {
    case actions.UPDATE_NAVIGATION:
      return { ...state, path: action.payload }
    case actions.EXPAND_NAVIGATION:
      return { ...state, openNavigation: action.payload }
    default: {
      return state
    }
  }
}
