import React, { memo, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import withFixedColumns from 'react-table-hoc-fixed-columns'
import ReactTable, { ReactTableDefaults } from 'react-table-6'
import get from 'lodash/get'
import PaginationComponent from './PaginationComponent'
import Spinner from 'britive-ui-components/core/components/Spinner'
import TableEllipsisCell from './TableEllipsisCell'

import 'react-table-6/react-table.css'
import 'react-table-hoc-fixed-columns/lib/styles.css'

const ReactTableFixedColumns = withFixedColumns(ReactTable)

// TODO: This is a magic number that prevents pagination
// not sure what prop will do this in cleaner way
const DO_NOT_PAGINATE_NUMBER = 1000000
export const DEFAULT_PAGE_SIZE = 20
const pageSizeOptions = [DEFAULT_PAGE_SIZE, 50, 100]

const Wrapper = styled.div`
  .-pagination > .-next, .-pagination > .-previous {    
    flex: 0.5 1!important;
    & > .-btn {
      color: #fff;
      background: var(--brand);
      opacity: 0.9;
      font-weight: 500;
    }
    
    .-btn:not([disabled]):hover {
      color: #fff;
      opacity: 1;
      background: var(--brand);
    }
    
    & > .-btn[disabled] {
      background: var(--gallery);
      color: var(--silver);
      opacity: 1;
    }
  }

  .-pagination > .-center {
    flex: 1!important;
  }

  ${props =>
    props.hideHeader
      ? `
        .rt-thead.-header {
          display: none;
        }
      `
      : ''}

  .rt-table .rt-thead .rt-tr {
    background: var(--brand);

    .rt-th {
      background: var(--brand);
      color: #fff;
      font-weight: 500;
      padding: 4px;
      text-align: left;
      border-right: 1px solid #fff;
      
      .rt-resizer {
        width: 1px;
        right: 0  
      }

      &.-cursor-pointer:before{
        font-family: "Font Awesome 5 Free"; 
        font-weight: 900; 
        content: "\f0dc";
        float: right;
        font-size: 8px;
        margin: 2px 0;
        font-size: 16px;
      }
      
      &.-sort-asc, &.-sort-desc {
        box-shadow: none;
      }
      
      &.-sort-asc:before {
        content: "\f0de";
      }
      
      &.-sort-desc:before {
        content: "\f0dd";
      }
    }
  }

  .rt-tbody {
    height: ${props => props.bodyHeight};
  }

  .rt-table .rt-tbody .rt-tr-group {
    border-bottom: 0;
  }

  .rt-table .rt-tbody .rt-tr {
    ${props => (props.hasBorderBottom ? `border-bottom: solid #f3f3f3;` : '')}

    ${props =>
      props.hasNoData
        ? ''
        : `
          &.hasAction {
            cursor: pointer;
          }`}

      ${props =>
        props.disableHover
          ? ''
          : `
          &:hover .rt-td {
            background: var(--gallery);
          }
        `}

    > .rt-td {
      padding: 4px;
      color: rgb(62, 62, 62);
      display: flex;
      align-items: center;
      overflow: visible;
    }

    &.selected > .rt-td {
      background: var(--gallery);
    }
  }

  .rt-noData {
    text-align: center;
    width: 100%;
    margin-top: 0;
    top: ${props => (props.showPagination ? '40' : '70')}% !important;
    background: none !important;
    padding: 6px !important;
    ${props => {
      return props.loading
        ? `
          position: relative !important;
          left: 0 !important;
          top: 0 !important;
          height: ${props.maxHeight};
          transform: none !important;
          `
        : ''
    }}
  }

  .rt-table .rt-tfoot {
    position: static !important;

    .rt-tr {
      position: relative;

      .rt-td {
        padding: 0;
        border: none !important;
        border-right: none !important;
        height: 28px;
      }
    }
  }

  ${props =>
    props.noData &&
    `.rt-td {
      border-right: none !important;
    }`}

  mark {
    padding: 0;
    background-color: #ff0;
  }
`

function Cell({ value }) {
  return <TableEllipsisCell value={value} />
}

function TableView(props) {
  const reactTable = useRef(null)

  useEffect(() => {
    const { onScroll } = props
    const tableClassName = reactTable.current.uniqClassName

    const table = document.querySelector(`.${tableClassName} .rt-table`)

    if (table) {
      table.addEventListener('scroll', onScroll)
    }

    return () => {
      const { onScroll } = props
      const tableClassName = reactTable.current.uniqClassName

      const table = document.querySelector(`.${tableClassName} .rt-table`)

      if (table) {
        table.removeEventListener('scroll', onScroll)
      }
    }
  }, [])

  const columns = useMemo(() => {
    const { initialColumn, columns, searchKeys = [] } = props
    let newColumns = [...columns]

    const searchKeyObject = searchKeys.reduce((acc, key) => {
      acc[key] = true
      return acc
    }, {})

    if (get(initialColumn, 'showInitialColumn')) {
      newColumns.unshift({
        Cell: initialColumn.InitialColumnComponent,
        Header: initialColumn.InitialColumnHeaderComponent,
        accessor: 'checkboxCell',
        resizable: false,
        sortable: false,
        width: initialColumn.initialColumnWidth,
        fixed: initialColumn.fixed,
        className: 'excludeSearch',
      })
    }

    return newColumns.map(column => {
      if (!searchKeyObject[column.accessor]) {
        const className = column.className
        return {
          ...column,
          className: `${className ? `${className} ` : ''}excludeSearch`,
        }
      }

      return column
    })
  }, [props.initialColumn, props.columns, props.searchKeys])

  const trProps = useMemo(() => {
    return (state, rowInfo) => {
      const { identifier, rowOnClickHandler, rowHasAction, selectedId } = props
      const hasAction = rowHasAction ? rowHasAction(rowInfo) : rowOnClickHandler

      return {
        onClick: hasAction
          ? event => rowOnClickHandler({ event, rowInfo })
          : undefined,
        className: [
          selectedId && get(rowInfo, `original[${identifier}]`, '') === selectedId
            ? 'selected'
            : '',
          hasAction ? 'hasAction' : '',
        ].join(' '),
      }
    }
  }, [props.selectedId])

  const {
    data,
    emptyTableMessage,
    DropdownComponent,
    resizable,
    maxHeight,
    hideHeader = false,
    disableHover = false,
    hasBorderBottom = true,
    showPagination = false,
    sortable = false,
    filterable = false,
    filtered,
    defaultFilterMethod = () => {},
    getProps,
    manual = false,
    defaultSorted,
    multiSort = false,
    sorted,
    defaultSortMethod,
    innerRef,
    highlightText,
    loading,
    fetchData,
    page,
    pageSize,
    pages,
    onPageChange,
    onPageSizeChange,
    onSortedChange,
    expanded,
    customPagination,
  } = props
  const [expandedRows, setExpandedRows] = useState(null)

  useEffect(() => {
    setExpandedRows(expanded)
  }, [expanded])

  //key is used to force component rerender due to inability of react-table
  //react on changes of some properties after table is rendered
  return (
    <Wrapper
      key={`${showPagination}`}
      hasBorderBottom={hasBorderBottom}
      hasNoData={!data || !data.length}
      showPagination={showPagination}
      hideHeader={hideHeader}
      disableHover={disableHover}
      ref={innerRef}
      maxHeight={maxHeight}
      loading={loading}
      bodyHeight={data.length === 0 ? maxHeight : 'auto'}
      noData={data.length === 0}
    >
      <ReactTableFixedColumns
        data={data}
        columns={columns}
        column={{
          ...ReactTableDefaults.column,
          Cell,
        }}
        ref={reactTable}
        minRows={1}
        sortable={sortable}
        filtered={filtered}
        filterable={filterable}
        defaultFilterMethod={defaultFilterMethod}
        defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : DO_NOT_PAGINATE_NUMBER}
        page={page}
        pageSize={pageSize}
        minWidth={200}
        getTrProps={trProps}
        getProps={getProps}
        style={{ maxHeight: maxHeight || undefined }}
        showPagination={showPagination}
        noDataText={emptyTableMessage}
        resizable={resizable}
        defaultSorted={defaultSorted}
        multiSort={multiSort}
        sorted={sorted}
        loading={loading}
        manual={manual}
        onFetchData={fetchData}
        pages={pages}
        pageSizeOptions={pageSizeOptions}
        expanded={expandedRows}
        defaultSortMethod={defaultSortMethod}
        loadingText={<Spinner size={'2x'} label="loading" />}
        SubComponent={
          DropdownComponent
            ? ({ original }) => <DropdownComponent data={original} />
            : undefined
        }
        onPageChange={page => {
          if (highlightText) {
            highlightText()
          }
          setExpandedRows({})
          manual && onPageChange(page)
        }}
        onSortedChange={(newSorted, column, shiftKey) => {
          setExpandedRows({})
          manual && onSortedChange(newSorted, column, shiftKey)
        }}
        {...(manual &&
          customPagination && {
            PaginationComponent: PaginationComponent,
          })}
        {...(manual && {
          onPageSizeChange: onPageSizeChange,
        })}
      />
    </Wrapper>
  )
}

export default memo(TableView)
