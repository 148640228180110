import React, { memo, useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import FontAwesome from 'react-fontawesome'
import classNames from 'classnames'
import get from 'lodash/get'

const ANIMATION_TIME = 0.5

const slideIn = right => keyframes`
  from {
    right: -${right};
  }

  to {
    right: 0;
  }
`

const slideOut = right => keyframes`
  from {
    right: 0;
  }

  to {
    right: -${right};
  }
`

const Wrapper = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  width: ${props => props.width};
  position: fixed;
  top: ${props => props.top};
  right: 0;
  height: calc(100vh - ${props => props.top});
  padding: 15px 15px 15px 25px;
  animation: ${props => slideIn(props.width)} ${ANIMATION_TIME}s linear;
  z-index: ${props => props.zIndex};
  box-shadow: 0 0 40px 5px var(--brand-light);

  &.isClosed {
    right: -${props => props.width};
    animation: ${props => slideOut(props.width)} ${ANIMATION_TIME}s linear;
  }
`

const ContentWrapper = styled.div`
  overflow-y: scroll;
  overflow-x: scroll;

  &.disableXScroll {
    overflow-x: hidden;
  }

  &.disableYScroll {
    overflow-y: hidden;
  }

  flex-grow: 1;
`

const CloseBar = styled.div`
  text-align: right;

  > span {
    cursor: pointer;
  }
`

// TODO: make default props

function DrawerContainer({
  children,
  disableXScroll,
  disableYScroll,
  innerRef,
  isOpen,
  toggleDrawer,
  top = 0,
  width = '33%',
  zIndex = 1000,
  ...rest
}) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  useEffect(() => {
    setIsDrawerOpen(isOpen)
  }, [])

  useEffect(() => {
    if (isDrawerOpen && !isOpen) {
      window.setTimeout(() => setIsDrawerOpen(isOpen), ANIMATION_TIME * 1000)
    }

    if (!isDrawerOpen && isOpen) {
      setIsDrawerOpen(isOpen)
    }
  }, [isOpen, isDrawerOpen])

  if (!isDrawerOpen) {
    return null
  }

  let wrapperClasses = !isOpen ? 'isClosed' : ''
  wrapperClasses += get(rest, 'className', '')

  const contentWrapperClasses = classNames({
    disableXScroll,
    disableYScroll,
  })

  return (
    <Wrapper
      {...rest}
      className={wrapperClasses}
      width={width}
      top={top}
      zIndex={zIndex}
    >
      <CloseBar>
        <FontAwesome name="times" onClick={toggleDrawer} size="lg" />
      </CloseBar>

      <ContentWrapper ref={innerRef} className={contentWrapperClasses}>
        {children}
      </ContentWrapper>
    </Wrapper>
  )
}

export default memo(DrawerContainer)
