const catalogApp = rawData => {
  const { name, iconPath, propertyTypes, ...rest } = rawData
  const iconUrlObj = propertyTypes.find(type => {
    return type.name === 'iconUrl'
  })

  // The iconUrl will either be in the root of the object
  // or in the propertyTypes array as name : iconUrl
  // then we want the value or the defaultValue
  const iconUrl = iconPath
    ? iconPath
    : iconUrlObj.value
    ? iconUrlObj.value
    : iconUrlObj.defaultValue

  return {
    catalogAppName: name,
    iconUrl: iconUrl,
    propertyTypes,
    ...rest,
  }
}

export default catalogApp
