/* eslint-disable no-extra-boolean-cast */
import React, { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import Typography from 'britive-design-system/core/components/typography'
import DialogPopup from 'britive-design-system/core/components/dialog'
import TextField from 'britive-design-system/core/components/textfield'
import Accordion from 'britive-design-system/core/components/accordion'
import { approveRejectBy } from './approveRejectBy'
import { approveRejectExpiredDate } from './approveRejectExpiredDate'
import { YES_PARAM, NO_PARAM, status, mediumHeightDialogPopUp } from './constants'
import { isEmpty } from 'lodash'
import {
  papConsumer,
  resourceprofile,
} from 'routes/my-requests-my-approvals/my-requests/request-modal/constants'
import ApprovalPermission from './approval-permission/ApprovalPermissions'
import { headerDetailsType } from '../../components/page-header/constants'
import { PageHeader } from '../../components/page-header'
import { AccessBuilderRequestInfo } from '../../components/access-builder-request-info'
import {
  convertTimeToCurrentTimezone,
  getABRequestsHeaderTitle,
  isAccessBuilderRequest,
} from '../../utils'
import { doFetch } from 'utils/do_fetch'
import FontAwesome from 'react-fontawesome'

const ApprovalsView = ({
  history,
  approvalsDetails,
  getSecretStatus,
  getActionLabel,
  translatedStrings,
  getApprovalsDetailsById,
  approveRequest,
  rejectRequest,
  classes,
  statusLabel,
  id,
  redirectPath,
}) => {
  const dispatch = useDispatch()
  const [showApproveDialog, setShowApproveDialog] = useState(false)
  const [showRejectDialog, setShowRejectDialog] = useState(false)
  const [userName, setUsername] = useState('')
  const [actionName, setActionName] = useState('')
  const [commandText, setCommandText] = useState('')

  let approvalId = id || useParams().id
  const accessBuilderRequest = isAccessBuilderRequest(
    approvalsDetails?.data?.consumer
  )

  useEffect(() => {
    dispatch(getApprovalsDetailsById(approvalId))
  }, [])

  useEffect(() => {
    setActionName(getActionLabel(approvalsDetails?.data?.action))
  }, [approvalsDetails?.data?.action])

  useEffect(() => {
    setUsername(approvalsDetails?.data?.userId)
  }, [approvalsDetails?.data?.userId])

  const [highRiskCount, setHighRiskCount] = useState(0)
  const permissionsSection = useRef(null)
  const [approvalViewPermissions, setApprovalViewPermissions] = useState([])

  useEffect(() => {
    const resource = approvalsDetails?.data?.resource
    if (resource && resource.includes('/')) {
      const [papId, envId] = resource.split('/')

      if (papId && envId) {
        const getApprovalPermissions = async () => {
          const response = await doFetch({
            path: `/access/environments/${envId}/profiles/${papId}/permissions`,
            method: 'get',
          })

          setApprovalViewPermissions(response?.data || [])
        }

        try {
          getApprovalPermissions()
        } catch {
          setApprovalViewPermissions([])
        }

        return () => {
          setApprovalViewPermissions([])
          setHighRiskCount(0)
        }
      }
    }
  }, [approvalsDetails?.data?.resource])

  useEffect(() => {
    setHighRiskCount(0)
    if (approvalViewPermissions.length > 0) {
      approvalViewPermissions.forEach(permission => {
        if (permission.privileged) {
          setHighRiskCount(highRiskCount + 1)
        }
      })
    }
  }, [JSON.stringify(approvalViewPermissions)])

  const getApprovalHeaderPageTitle = () => {
    if (accessBuilderRequest) {
      return getABRequestsHeaderTitle(approvalsDetails?.data)
    }

    return `${translatedStrings.pageTitle}: ${getActionLabel(
      approvalsDetails?.data?.action
    ) || ''}`
  }

  const renderPageHeader = () => (
    <PageHeader
      title={getApprovalHeaderPageTitle()}
      trackingId={approvalsDetails?.data?.trackingId}
      type={headerDetailsType.APPROVALS}
      requestStatus={approvalsDetails?.data?.status}
      onReject={() => setShowRejectDialog(true)}
      onApprove={() => setShowApproveDialog(true)}
      description={approvalsDetails?.data?.context?.profileDescription}
    />
  )

  const approveDialog = () => {
    return (
      <div>
        <DialogPopup
          type="alert"
          height={mediumHeightDialogPopUp}
          title={translatedStrings.approveRequestTitle}
          message={`${actionName} permission will be granted to ${userName} for ${approvalsDetails
            ?.data?.resourceName || approvalsDetails?.data?.resource}`}
          primaryButtonText={translatedStrings.yesApprove}
          secondaryButtonText={translatedStrings.cancelLabel}
          onSubmit={() => {
            setShowApproveDialog(false)
            //Api call for Approve.
            dispatch(
              approveRequest(
                approvalId,
                YES_PARAM,
                history,
                commandText,
                true,
                redirectPath
              )
            )
            setCommandText('')
          }}
          onCancel={() => {
            setShowApproveDialog(false)
            setCommandText('')
          }}
        >
          <TextField
            label={translatedStrings.comments}
            helperText={translatedStrings.optional}
            value={commandText || ''}
            onChange={e => setCommandText(e.target.value)}
          />
        </DialogPopup>
      </div>
    )
  }

  const rejectDialog = () => {
    return (
      <div>
        <DialogPopup
          type="alert"
          height={mediumHeightDialogPopUp}
          title={translatedStrings.rejectRequestTitle}
          message={`${actionName} permission will be denied to ${userName} for ${approvalsDetails
            ?.data?.resourceName || approvalsDetails?.data?.resource}`}
          primaryButtonText={translatedStrings.yesReject}
          secondaryButtonText={translatedStrings.cancelLabel}
          onSubmit={() => {
            setShowRejectDialog(false)
            //Api call for Reject.
            dispatch(
              rejectRequest(
                approvalId,
                NO_PARAM,
                history,
                commandText,
                true,
                redirectPath
              )
            )
            setCommandText('')
          }}
          onCancel={() => {
            setShowRejectDialog(false)
            setCommandText('')
          }}
        >
          <TextField
            label={translatedStrings.comments}
            helperText={translatedStrings.optional}
            value={commandText || ''}
            onChange={e => setCommandText(e.target.value)}
          />
        </DialogPopup>
      </div>
    )
  }
  const statusMsg = label => {
    return (
      <>
        <Typography variant="label2">{label}</Typography>
      </>
    )
  }

  const renderHighRiskMessage = () => (
    <div className="my-access-approval-view-permission-warning">
      <FontAwesome
        className="my-access-approval-view-permission-warning-icon"
        name="exclamation-triangle"
      />
      {`This profile includes`}&nbsp;
      <span className="high-risk-count">{highRiskCount} High Risk</span>&nbsp;
      {`Permissions.`}
      <span
        className="scroll-to-permissions"
        onClick={() => {
          window.scrollTo({
            top: permissionsSection.current.offsetTop,
            behavior: 'smooth',
          })
        }}
      >
        {'View Permissions'}
      </span>
    </div>
  )

  const myApprovalsDetails = () => {
    if (accessBuilderRequest && approvalId === approvalsDetails?.data.requestId) {
      // We need to send the parentId of the request for the my approvals page.
      return (
        <AccessBuilderRequestInfo
          requestData={approvalsDetails?.data}
          requestId={approvalsDetails?.data?.parentId}
          history={history}
        />
      )
    }

    return (
      <div className={classes.approvalsDetailsForm}>
        {approvalsDetails?.data?.consumer === papConsumer &&
          highRiskCount > 0 &&
          renderHighRiskMessage()}
        <Accordion expanded title="General">
          {approvalsDetails?.data?.status === status.TIMEOUT &&
            (Boolean(approvalsDetails?.data?.actionBy)
              ? statusMsg(statusLabel.expiredAfterApproval)
              : statusMsg(statusLabel.expired))}
          {approvalsDetails?.data?.status === status.APPROVED &&
            statusMsg(
              `This request is approved by ${approvalsDetails?.data?.actionBy}`
            )}
          {approvalsDetails?.data?.status === status.REJECTED &&
            statusMsg(
              `This request is rejected by ${approvalsDetails?.data?.actionBy}`
            )}

          <div className={classes?.marginTop32}>
            <Typography variant="label2">{translatedStrings.requestDate}</Typography>
            <div className={classes?.marginTop8}>
              <Typography variant="label1">
                {convertTimeToCurrentTimezone(approvalsDetails?.data?.createdAt) ||
                  translatedStrings.noneValue}
              </Typography>
            </div>
          </div>

          <div className={classes?.marginTop32}>
            <Typography variant="label2">
              {translatedStrings.requestValidity}
            </Typography>
            <div className={classes?.marginTop8}>
              <Typography variant="label1">
                {convertTimeToCurrentTimezone(
                  approvalsDetails?.data?.expirationTimeForApproveRequest
                ) || translatedStrings.noneValue}
              </Typography>
            </div>
          </div>

          <div className={classes?.marginTop32}>
            <Typography variant="label2">{translatedStrings.requestedBy}</Typography>
            <div className={classes?.marginTop8}>
              <Typography variant="label1">
                {userName || translatedStrings.noneValue}
              </Typography>
            </div>
          </div>

          <div className={classes?.marginTop32}>
            <Typography variant="label2">{translatedStrings.actionType}</Typography>
            <div className={classes?.marginTop8}>
              <Typography variant="label1">
                {actionName || translatedStrings.noneValue}
              </Typography>
            </div>
          </div>

          {approvalsDetails?.data?.consumer === resourceprofile && (
            <div className={classes?.marginTop32}>
              <Typography variant="label2">{'Profile Name'}</Typography>
              <div className={classes?.marginTop8}>
                <Typography variant="label1">
                  {approvalsDetails?.data?.context?.profileName ||
                    translatedStrings.noneValue}
                </Typography>
              </div>
            </div>
          )}

          <div className={classes?.marginTop32}>
            <Typography variant="label2">
              {approvalsDetails?.data?.consumer === resourceprofile
                ? translatedStrings.resourceName
                : translatedStrings.resource}
            </Typography>
            <div className={classes?.marginTop8}>
              <Typography variant="label1">
                {approvalsDetails?.data?.consumer === resourceprofile
                  ? approvalsDetails?.data?.resourceName.replace(/^[^:]*:/, '')
                  : approvalsDetails?.data?.resourceName ||
                    approvalsDetails?.data?.resource ||
                    translatedStrings.noneValue}
              </Typography>
            </div>
          </div>

          {approvalsDetails?.data?.consumer === resourceprofile && (
            <div className={classes?.marginTop32}>
              <Typography variant="label2">{'Permission Name'}</Typography>
              <div className={classes?.marginTop8}>
                <Typography variant="label1">
                  {approvalsDetails?.data?.context?.permissionName ||
                    translatedStrings.noneValue}
                </Typography>
              </div>
            </div>
          )}

          <div className={classes?.marginTop32}>
            <Typography variant="label2">
              {translatedStrings.justification}
            </Typography>
            <div className={classes?.marginTop8}>
              <Typography variant="label1">
                {approvalsDetails?.data?.justification ||
                  translatedStrings.noneValue}
              </Typography>
            </div>
          </div>

          <div className={classes?.marginTop32}>
            <Typography variant="label2">
              {translatedStrings.approvalStatus}
            </Typography>
            <div className={classes?.marginTop8}>
              <Typography variant="label1">
                {getSecretStatus(approvalsDetails?.data?.status) ||
                  translatedStrings.noneValue}
              </Typography>
            </div>
          </div>

          {approvalsDetails?.data?.status === status.APPROVED &&
            approveRejectBy(
              translatedStrings.approvedBy,
              approvalsDetails?.data?.actionBy,
              classes.marginTop32,
              classes.marginTop8
            )}
          {approvalsDetails?.data?.status === status.REJECTED &&
            approveRejectBy(
              translatedStrings.rejectedBy,
              approvalsDetails?.data?.actionBy,
              classes.marginTop32,
              classes.marginTop8
            )}

          {approvalsDetails?.data?.status === status.APPROVED &&
            approveRejectExpiredDate(
              translatedStrings.approvedOn,
              convertTimeToCurrentTimezone(approvalsDetails?.data?.updatedAt),
              classes.marginTop32,
              classes.marginTop8
            )}
          {approvalsDetails?.data?.status === status.REJECTED &&
            approveRejectExpiredDate(
              translatedStrings.rejectedOn,
              convertTimeToCurrentTimezone(approvalsDetails?.data?.updatedAt),
              classes.marginTop32,
              classes.marginTop8
            )}
          {approvalsDetails?.data?.status === status.TIMEOUT &&
            approveRejectExpiredDate(
              translatedStrings.expiredOn,
              convertTimeToCurrentTimezone(
                Boolean(approvalsDetails?.data?.actionBy)
                  ? approvalsDetails?.data?.expirationTimeApproval
                  : approvalsDetails?.data?.expirationTimeForApproveRequest
              ),
              classes.marginTop32,
              classes.marginTop8
            )}

          {approvalsDetails?.data?.status !== status.TIMEOUT &&
            approvalsDetails?.data?.status !== status.PENDING && (
              <div className={classes?.marginTop32}>
                <Typography variant="label2">
                  {translatedStrings.comments}
                </Typography>
                <div className={classes?.marginTop8}>
                  <Typography variant="label1">
                    {approvalsDetails?.data?.approverComment ||
                      translatedStrings.noneValue}
                  </Typography>
                </div>
              </div>
            )}
        </Accordion>
        {approvalsDetails?.data?.consumer === papConsumer ? (
          <ApprovalPermission
            approvalViewPermissions={approvalViewPermissions}
            permissionsSection={permissionsSection}
          />
        ) : null}
      </div>
    )
  }

  return (
    <>
      {!approvalsDetails?.loading &&
        !isEmpty(approvalsDetails?.data) &&
        renderPageHeader()}
      {!approvalsDetails?.loading &&
        !isEmpty(approvalsDetails?.data) &&
        myApprovalsDetails()}
      {showApproveDialog && approveDialog()}
      {showRejectDialog && rejectDialog()}
    </>
  )
}

export default ApprovalsView
