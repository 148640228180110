import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import Typography from 'britive-design-system/core/components/typography'
import ModalPopup from 'britive-design-system/core/components/modal-popup'
import Tooltip from 'britive-design-system/core/components/tooltip'
import { getApproversList } from '../../../../action_creators/my-approvals'
import { getSecretStatus } from '../../utils'
import { ApproverGroupUsers } from './UserList'
import { approversModalMessage, classNamePrefix } from './constants'
import listTree from 'static/images/list-tree.svg'

export const ApproversList = ({ approverGroups, requestId }) => {
  const dispatch = useDispatch()
  const [showMembers, setShowMembers] = useState(false)
  const [modalTitle, setModalTitle] = useState(null)
  const [approvalCondition, setApprovalCondition] = useState(null)

  const closeModal = () => {
    setShowMembers(false)
    setModalTitle(null)
    setApprovalCondition(null)
  }

  const openModal = row => {
    dispatch(getApproversList(requestId, row.approverGroupId))
    setModalTitle(row.approverGroupName)
    setApprovalCondition(row.approvalCondition?.toLowerCase())
    setShowMembers(true)
  }

  const renderApprover = (row, index) => (
    <div
      key={`request-details-approver-row-${index}`}
      className={`${classNamePrefix}-content-approver-row`}
    >
      <Typography variant="label1">{row.approverGroupName}</Typography>
      <div className={`${classNamePrefix}-content-approver-row-icon-wrapper`}>
        <Tooltip title={`View ${row.approverGroupName}`} position="right">
          <span
            title=""
            data-testid="request-details-approver-row-icon"
            onClick={() => openModal(row)}
          >
            <img src={listTree} />
          </span>
        </Tooltip>
      </div>
      <Typography variant="label1">{getSecretStatus(row.status)}</Typography>
    </div>
  )

  return (
    <>
      <div
        className={`${classNamePrefix}-content-approvers`}
        data-testid={`${classNamePrefix}-content-approvers`}
      >
        {approverGroups?.map(renderApprover)}
      </div>
      {showMembers && (
        <ModalPopup
          width={720}
          title={modalTitle}
          buttons={[
            {
              text: 'Close',
              variant: 'secondary',
              onClick: closeModal,
              size: 'medium',
            },
          ]}
          onCancel={closeModal}
        >
          <Typography variant="label1">
            {approversModalMessage[approvalCondition]}
          </Typography>
          <br />
          <ApproverGroupUsers key={requestId} />
        </ModalPopup>
      )}
    </>
  )
}
