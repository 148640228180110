import get from 'lodash/get'

import { fetchIfLoggedIn } from './utils.js'

import { updateEnvironmentGroupsState } from './environment'

export const CREATE_APPLICATION_ROOT = 'CREATE_APPLICATION_ROOT'
export const UPDATE_APPLICATION_ROOT = 'UPDATE_APPLICATION_ROOT'
export const UPDATE_APPLICATION_ROOT_STATUS = 'UPDATE_APPLICATION_ROOT_STATUS'
export const DELETE_APPLICATION_ROOT = 'DELETE_APPLICATION_ROOT'
export const TEST_APPLICATION_ROOT = 'TEST_APPLICATION_ROOT'
export const SCAN_APPLICATION_ROOT = 'SCAN_APPLICATION_ROOT'
export const SELECT_APPLICATION_ROOT_ID = 'SELECT_APPLICATION_ROOT_ID'
export const SELECT_APPLICATION_TEMPLATE = 'SELECT_APPLICATION_TEMPLATE'
export const FETCH_APPLICATION_ROOT = 'FETCH_APPLICATION_ROOT'
export const FETCH_ALL_APPLICATION_ROOTS = 'FETCH_ALL_APPLICATION_ROOTS'
export const UPDATE_USER_ACCOUNT_MAPPINGS = 'UPDATE_USER_ACCOUNT_MAPPINGS'

export const selectApplicationRootId = appContainerId => ({
  type: SELECT_APPLICATION_ROOT_ID,
  payload: appContainerId,
})

export const selectApplicationTemplate = template => ({
  type: SELECT_APPLICATION_TEMPLATE,
  payload: template,
})

export const createApplicationRoot = ({ catalogAppId, name }) => dispatch => {
  const postBody = {
    catalogAppId,
    catalogAppDisplayName: name,
  }

  return dispatch(
    fetchIfLoggedIn({
      actionType: CREATE_APPLICATION_ROOT,
      method: 'post',
      path: '/apps',
      postBody,
    })
  )
}

export const updateApplicationRoot = (applicationRootId, data) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: UPDATE_APPLICATION_ROOT,
      method: 'patch',
      path: `/apps/${applicationRootId}/properties`,
      postBody: data,
    })
  )
}

export const fetchApplicationRoot = (
  applicationRootId,
  requestAccess
) => async dispatch => {
  const response = await dispatch(
    fetchIfLoggedIn({
      actionType: FETCH_APPLICATION_ROOT,
      method: 'get',
      path: requestAccess
        ? `/profile-requests/apps/${applicationRootId}`
        : `/apps/${applicationRootId}`,
    })
  )

  const data = get(response, 'value.data')

  if (data) {
    dispatch(updateEnvironmentGroupsState([data]))
  }

  return response
}

export const fetchAllApplicationRoots = () => async dispatch => {
  const response = await dispatch(
    fetchIfLoggedIn({
      actionType: FETCH_ALL_APPLICATION_ROOTS,
      method: 'get',
      path: '/apps',
    })
  )

  const data = get(response, 'value.data', {})

  if (data.length) {
    dispatch(updateEnvironmentGroupsState(data))
  }
}

export const testApplicationRoot = appId => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: TEST_APPLICATION_ROOT,
      method: 'get',
      path: `/apps/${appId}/test`,
    })
  )
}

export const scanApplicationRoot = appId => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: SCAN_APPLICATION_ROOT,
      method: 'post',
      path: `/apps/${appId}/scan`,
    })
  )
}

export const disableApplicationRoot = appId => dispatch =>
  dispatch(updateApplicationRootStatus(appId, 'inactive'))

export const enableApplicationRoot = appId => dispatch =>
  dispatch(updateApplicationRootStatus(appId, 'active'))

export const updateApplicationRootStatus = (appId, status) => dispatch => {
  const postBody = { status }

  return dispatch(
    fetchIfLoggedIn({
      actionType: UPDATE_APPLICATION_ROOT_STATUS,
      method: 'patch',
      path: `/apps/${appId}`,
      postBody,
    })
  )
}

export const deleteApplicationRoot = appId => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: DELETE_APPLICATION_ROOT,
      meta: { appId },
      method: 'DELETE',
      path: `/apps?appContainerId=${appId}`,
    })
  )
}

export const updateUserAccountMappings = (applicationRootId, data) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: UPDATE_USER_ACCOUNT_MAPPINGS,
      method: 'post',
      path: `/apps/${applicationRootId}/user-account-mappings`,
      postBody: data,
    })
  )
}
