import {
  MyRequestsDataTypes,
  UsersType,
  TagsType,
  WithdrawType,
} from '../../routes/my-requests-my-approvals/my-requests/constants'

export const requestMyRequestsList = () => {
  return {
    type: MyRequestsDataTypes.MY_REQUESTS_LIST_REQUEST,
  }
}

export const requestUpdateMyRequestsListSearchTerm = search => {
  return {
    type: MyRequestsDataTypes.MY_REQUESTS_LIST_SEARCH_TERM_UPDATE,
    payload: search,
  }
}

export const requestMyRequestsListLoadMore = () => {
  return {
    type: MyRequestsDataTypes.MY_REQUESTS_LIST_LOAD_MORE_REQUEST,
  }
}

export const loadingMyRequestsListLoadMore = () => {
  return {
    type: MyRequestsDataTypes.MY_REQUESTS_LIST_LOAD_MORE_LOADING,
  }
}

export const successMyRequestsListLoadMore = data => {
  return {
    type: MyRequestsDataTypes.MY_REQUESTS_LIST_LOAD_MORE_SUCCESS,
    payload: data,
  }
}

export const failureMyRequestsListLoadMore = reason => {
  return {
    type: MyRequestsDataTypes.MY_REQUESTS_LIST_LOAD_MORE_FAILURE,
    payload: reason,
  }
}

export const requestUsersList = () => ({
  type: UsersType.USERS_REQUEST,
})

export const loadingUsersList = () => ({
  type: UsersType.USERS_LOADING,
})

export const successUsersList = data => ({
  type: UsersType.USERS_SUCCESS,
  payload: data,
})

export const failureUsersList = reason => ({
  type: UsersType.USERS_FAILURE,
  payload: reason,
})

export const requestTagsList = () => {
  return {
    type: TagsType.USER_TAGS_REQUEST,
  }
}

export const loadingTagsList = () => ({
  type: TagsType.USER_TAGS_LOADING,
})

export const successTagsList = data => ({
  type: TagsType.USER_TAGS_SUCCESS,
  payload: data,
})

export const failureTagsList = reason => ({
  type: TagsType.USER_TAGS_FAILURE,
  payload: reason,
})

export const withdrawRequest = (requestId, history = {}, redirectPath = '') => ({
  type: WithdrawType.MY_REQUESTS_WITHDRAW_REQUEST,
  payload: {
    requestId,
    history,
    redirectPath,
  },
})

export const withdrawRequestLoading = () => ({
  type: WithdrawType.MY_REQUESTS_WITHDRAW_LOADING,
})

export const withdrawRequestSuccess = response => ({
  type: WithdrawType.MY_REQUESTS_WITHDRAW_SUCCESS,
  response,
})

export const withdrawRequestFailure = () => ({
  type: WithdrawType.MY_REQUESTS_WITHDRAW_FAILURE,
})
