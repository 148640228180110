import React, { memo, useEffect, useState } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import get from 'lodash/get'

import Button from 'britive-ui-components/core/components/Button'
import DateInput from 'components/DateInput'

const styles = {
  firstInput: {
    marginBottom: 16,
  },
}

function TimePeriodModal({
  actionFunction,
  actionSubject,
  getTitle,
  identifier,
  isOpen,
  isUpdating,
  pageName,
  toggleModal,
}) {
  const [fields, setFields] = useState({})
  const [error, setError] = useState('')

  useEffect(() => {
    let start
    let end

    if (get(actionSubject, 'accessPeriod', start)) {
      start = new Date(actionSubject.accessPeriod.start)
      end = new Date(actionSubject.accessPeriod.end)
    } else {
      start = new Date()
      end = new Date()
      end.setDate(start.getDate() + 7)
    }

    setFields({ start, end })
  }, [isOpen])

  const updateDate = (type, dateObj) => {
    if (!dateObj) {
      return
    }

    const newFields = { ...fields }

    if (type === 'start' && dateObj > fields.end) {
      newFields.end = dateAdjust({ startDate: dateObj, days: 7 })
    }

    newFields[type] = dateObj

    setFields(newFields)
  }

  const updateFromInput = async (type, event) => {
    const newFields = { ...fields }

    let newDate = new Date(event.target.value)

    if (!(newDate instanceof Date) || isNaN(newDate)) {
      return
    }

    if (type === 'start' && newDate > fields.end) {
      newFields.end = dateAdjust({ startDate: newDate, days: 7 })
    }

    newFields[type] = newDate

    setFields(newFields)
  }

  const submitHandler = async () => {
    try {
      await actionFunction({
        id: actionSubject[identifier],
        start: fields.start,
        end: fields.end,
        item: actionSubject,
      })
    } catch (error) {
      setError(get(error, 'response.data.message', 'Unknown error. Try again'))
      return
    }

    closeHandler()
  }

  const closeHandler = () => {
    setError('')
    toggleModal(null)
  }

  const dateAdjust = ({ startDate, days }) => {
    const end = new Date(startDate.valueOf())
    end.setDate(end.getDate() + days)
    return end
  }

  if (!actionSubject) {
    return null
  }

  return (
    <Modal isOpen={isOpen} centered>
      <ModalHeader toggle={() => toggleModal(null)}>
        {getTitle(actionSubject)}
      </ModalHeader>

      <ModalBody>
        <span className="text-danger">{error}</span>

        <DateInput
          type="start"
          label="Assignment Starts"
          updateDate={updateDate}
          updateFromInput={updateFromInput}
          value={fields.start}
          minDate={new Date()}
          pageName={pageName}
          style={styles.firstInput}
        />

        <DateInput
          type="end"
          label="Assignment Ends"
          updateDate={updateDate}
          updateFromInput={updateFromInput}
          value={fields.end}
          minDate={fields.start}
          pageName={pageName}
        />
      </ModalBody>

      <ModalFooter>
        <Button onClick={closeHandler}>Cancel</Button>

        <Button
          onClick={submitHandler}
          spinner={isUpdating[actionSubject[identifier]]}
          color="primary"
        >
          OK
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default memo(TimePeriodModal)
