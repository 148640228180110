import * as actions from 'actions'

import { fetchIfLoggedIn } from '../utils.js'

import doFetch, { preFetch } from 'utils/do_fetch'
import { getIsAuthenticated } from 'utils/authentication'

export const FETCH_TENANT_APP_RESOURCES = 'FETCH_TENANT_APP_RESOURCES'

export function getAllTenantApps() {
  if (getIsAuthenticated()) {
    const response = preFetch({
      name: 'getAllTenantApps',
      path: '/tenants/apps',
      method: 'get',
    })
    return {
      type: actions.GET_ALL_APPS,
      payload: response,
    }
  } else {
    return {
      type: actions.NOT_AUTHENTICATED,
    }
  }
}

export function getTenantApp(appId, envId) {
  if (getIsAuthenticated()) {
    const response = preFetch({
      name: 'getEnvironment',
      path: `/apps/${appId}/environments/${envId}`,
      method: 'get',
    })
    return {
      type: actions.GET_TENANT_APP,
      payload: response,
    }
  } else {
    return {
      type: actions.NOT_AUTHENTICATED,
    }
  }
}

export function updateTenantApp(response) {
  return {
    type: actions.UPDATE_TENANT_APP,
    payload: response,
  }
}

export function createTenantApp(catalogAppId) {
  const postBody = {
    catalogAppId,
  }

  if (getIsAuthenticated()) {
    const response = preFetch({
      name: 'createTenantApp',
      path: `/tenants/apps`,
      method: 'post',
      postBody,
    })
    return {
      type: actions.CREATE_TENANT_APP,
      payload: response,
    }
  } else {
    return {
      type: actions.NOT_AUTHENTICATED,
    }
  }
}

export function testTenantApp(tenantAppId) {
  if (getIsAuthenticated()) {
    const response = preFetch({
      name: 'testTenantApp',
      path: `/tenants/apps/${tenantAppId}/test`,
      method: 'get',
    })
    return {
      type: actions.TEST_TENANT_APP,
      payload: response,
    }
  } else {
    return {
      type: actions.NOT_AUTHENTICATED,
    }
  }
}

export function scanTenantApp(tenantAppId) {
  if (getIsAuthenticated()) {
    const response = preFetch({
      name: 'scanTenantApp',
      path: `/tenants/apps/${tenantAppId}/scan`,
      method: 'get',
    })
    return {
      type: actions.SCAN_TENANT_APP,
      payload: response,
    }
  } else {
    return {
      type: actions.NOT_AUTHENTICATED,
    }
  }
}

export function getTenantAppPermissions({ appId, envId, type = 'permission' }) {
  if (getIsAuthenticated()) {
    const response = preFetch({
      name: 'getEnvironmentPermissions',
      path: `/apps/${appId}/environments/${envId}/permissions?type=${type}`,
      method: 'get',
    })
    return {
      type: actions.GET_TENANT_APP_PERMISSIONS,
      meta: { envId },
      payload: response,
    }
  } else {
    return {
      type: actions.NOT_AUTHENTICATED,
    }
  }
}

export const togglePrivileged = (
  applicationId,
  environmentId,
  permissionId,
  privileged
) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.TOGGLE_PRIVILEGED,
      method: 'patch',
      meta: { permissionId },
      path: `/apps/${applicationId}/environments/${environmentId}/permissions/${permissionId}/high-risk/${privileged}`,
    })
  )
}

export function unregisterTenantAppPermissions(payload) {
  return {
    type: actions.UNREGISTER_PERMISSIONS,
    payload,
  }
}

export function getTenantAppAccounts(appId, envId) {
  if (getIsAuthenticated()) {
    const response = preFetch({
      name: 'getEnvironmentAccounts',
      path: `/apps/${appId}/environments/${envId}/accounts`,
      method: 'get',
    })
    return {
      type: actions.GET_TENANT_APP_ACCOUNTS,
      meta: { envId },
      payload: response,
    }
  } else {
    return {
      type: actions.NOT_AUTHENTICATED,
    }
  }
}

export const updateTenantAppAccounts = ({
  appId,
  envId,
  operation,
  accountIds,
  forceUnmapUser,
}) => dispatch => {
  const postBody = {
    op: operation,
    ids: accountIds,
  }

  let path = `/apps/${appId}/environments/${envId}/accounts`

  // this query param name is sorta confusing
  // we are forcing the unregistering of an account
  // not the unmapping of users
  if (forceUnmapUser !== undefined) {
    path += `?forceRemoveUserMapping=${forceUnmapUser}`
  }

  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.UPDATE_TENANT_APP_ACCOUNTS,
      method: 'post',
      path,
      postBody,
    })
  )
}

export const updateTenantAppAccount = ({ account, userId }) => ({
  type: `${actions.MAP_ACCOUNT_TO_USER}_FULFILLED`,
  meta: { userId },
  payload: { data: account },
})

export function createTenantPap(tenantAppId, papProperties) {
  const {
    expirationDuration,
    extensionDuration,
    notificationPriorToExpiration,
    ...rest
  } = papProperties
  const postBody = {
    tenantAppId,
    expirationDuration: expirationDuration * 60000,
    extensionDuration: extensionDuration * 60000,
    notificationPriorToExpiration: notificationPriorToExpiration * 60000,
    ...rest,
  }

  if (getIsAuthenticated()) {
    const response = preFetch({
      name: 'createTenantPap',
      path: `/paps`,
      method: 'post',
      postBody,
    })
    return {
      type: actions.CREATE_TENANT_PAP,
      payload: response,
    }
  } else {
    return {
      type: actions.NOT_AUTHENTICATED,
    }
  }
}

export const fetchTenantAppResources = (appId, envId) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: FETCH_TENANT_APP_RESOURCES,
      method: 'get',
      meta: { envId },
      path: `/apps/${appId}/environments/${envId}/resources`,
    })
  )
}

export function getAllTenantPaps() {
  if (getIsAuthenticated()) {
    const response = preFetch({
      name: 'getAllTenantPaps',
      path: '/paps?mapByTenantAppId',
      method: 'get',
    })
    return {
      type: actions.GET_ALL_PAPS,
      payload: response,
    }
  } else {
    return {
      type: actions.NOT_AUTHENTICATED,
    }
  }
}

export function getPapConfig(papId) {
  if (getIsAuthenticated()) {
    const response = preFetch({
      name: 'getPapConfig',
      path: `/paps/${papId}`,
      method: 'get',
    })
    return {
      type: actions.GET_PAP_CONFIG,
      payload: response,
    }
  } else {
    return {
      type: actions.NOT_AUTHENTICATED,
    }
  }
}

export function getPapsForApp(applicationId) {
  return {
    type: actions.GET_PAPS_FOR_APP,
    payload: {
      applicationId,
    },
  }
}

export async function updateUsersOfPap(papId, userList) {
  try {
    if (getIsAuthenticated()) {
      const response = await doFetch({
        method: 'patch',
        path: `/paps/${papId}/users`,
        withCredentials: true,
        xsrfCookieName: 'csrfToken',
        postBody: userList,
      })
      return {
        type: actions.UPDATE_USERS_OF_PAP,
        payload: response,
      }
    } else {
      return {
        type: actions.NOT_AUTHENTICATED,
      }
    }
  } catch (e) {
    console.warn('updateUsersOfPap error : ', e)
  }
}

//mapTenantAppAccountToUser
export const mapTenantAppAccountToUser = ({
  appId,
  envId,
  accountId,
  userId,
}) => dispatch => {
  try {
    if (getIsAuthenticated()) {
      const path = `/apps/${appId}/environments/${envId}/accounts/${accountId}/users`

      const response = preFetch({
        path,
        method: 'POST',
        postBody: [userId],
      })

      return dispatch({
        type: actions.MAP_ACCOUNT_TO_USER,
        meta: { userId },
        payload: response,
      })
    } else {
      return dispatch({
        type: actions.NOT_AUTHENTICATED,
      })
    }
  } catch (e) {
    console.warn('updateUsersOfPap error : ', e)
  }
}

export const unmapTenantAppAccountFromUser = ({
  appId,
  envId,
  accountId,
  userId,
  forceCheckinPaps,
}) => dispatch => {
  try {
    if (getIsAuthenticated()) {
      let path = `/apps/${appId}/environments/${envId}/accounts/${accountId}/users/${userId}`

      // I think this query param name is misleading
      if (forceCheckinPaps !== undefined) {
        path += `?forceCheckinPaps=${forceCheckinPaps}`
      }

      const response = preFetch({ path, method: 'DELETE' })

      return dispatch({
        type: actions.UNMAP_ACCOUNT_FROM_USER,
        meta: { userId },
        payload: response,
      })
    } else {
      return dispatch({
        type: actions.NOT_AUTHENTICATED,
      })
    }
  } catch (e) {
    console.warn('updateUsersOfPap error : ', e)
  }
}

export function getAvailablePapPermissions(appId, papId) {
  if (getIsAuthenticated()) {
    const response = preFetch({
      name: 'getAvailablePapPermissions',
      path: `/paps/${papId}/permissions?filter=available`,
      method: 'get',
    })
    return {
      type: actions.GET_AVAILABLE_PAP_PERMISSIONS,
      payload: response,
    }
  } else {
    return {
      type: actions.NOT_AUTHENTICATED,
    }
  }
}
