import {
  FETCH_REPORT_TYPES,
  FETCH_REPORT_DATA,
  FETCH_JOB_STATUS,
  GENERATE_REPORT,
  REPORT_GENERATION,
} from 'action_creators/report'

export const reportReducerKey = 'report'

const initialState = {
  reportTypes: [],
  reportData: {},
  reportNextPageURLs: {},
  fetchingReportTypes: false,
  fetchingReportDataStatus: {},
  failedReports: {},
  fetchingJobStatus: false,
  jobStatus: {},
}

// TODO: I do not like this redux promise pattern
// So many action types.
// also may want to separate data reducers from ui reducers
// not sure about this yet
// try something else out (thunk, sagas, etc.)
// Putting a bunch of state in this reducer because
// of time constraints

export function reportReducer(state = initialState, action) {
  switch (action.type) {
    case `${FETCH_REPORT_TYPES}_PENDING`: {
      return {
        ...state,
        fetchingReportTypes: true,
      }
    }

    case `${FETCH_REPORT_TYPES}_FULFILLED`: {
      return {
        ...state,
        fetchingReportTypes: false,
        reportTypes: action.payload.data,
      }
    }

    case `${FETCH_REPORT_TYPES}_REJECTED`: {
      return {
        ...state,
        fetchingReportTypes: false,
        error: action.payload,
      }
    }

    case `${FETCH_REPORT_DATA}_PENDING`: {
      const fetchingReportDataStatus = {
        ...state.fetchingReportDataStatus,
        [action.meta.reportId]: true,
        [action.meta.type]: true,
      }

      const failedReports = {
        ...state.failedReports,
      }

      delete failedReports[action.meta.reportId]

      return {
        ...state,
        failedReports,
        fetchingReportDataStatus,
      }
    }

    case `${FETCH_REPORT_DATA}_FULFILLED`: {
      const fetchingReportDataStatus = {
        ...state.fetchingReportDataStatus,
        [action.meta.reportId]: false,
        [action.meta.type]: false,
      }

      const reportData = {
        ...state.reportData,
        [action.meta.reportId]: action.payload.data.data,
      }
      return {
        ...state,
        fetchingReportDataStatus: fetchingReportDataStatus,
        reportData,
      }
    }

    case `${FETCH_REPORT_DATA}_REJECTED`: {
      return {
        ...state,
        fetchingReportDataStatus: {
          ...state.fetchingReportDataStatus,
          [action.meta.reportId]: false,
          [action.meta.type]: false,
        },
        error: action.payload,
      }
    }

    case `${FETCH_JOB_STATUS}_PENDING`: {
      return {
        ...state,
        fetchingJobStatus: true,
      }
    }

    case `${FETCH_JOB_STATUS}_FULFILLED`: {
      const jobStatus = {}
      action.payload.data.forEach(status => {
        jobStatus[status.reportId] = status
      })
      return {
        ...state,
        fetchingJobStatus: false,
        jobStatus,
      }
    }

    case `${FETCH_JOB_STATUS}_REJECTED`: {
      return {
        ...state,
        fetchingJobStatus: false,
        error: action.payload,
      }
    }

    case `${GENERATE_REPORT}_FULFILLED`: {
      const jobStatus = { ...state.jobStatus }
      const data = action.payload.data
      jobStatus[data.reportId] = {
        ...data,
        userFullName: action.meta.userFullName,
      }
      return {
        ...state,
        fetchingJobStatus: false,
        jobStatus,
      }
    }

    case `${REPORT_GENERATION}`: {
      const jobStatus = { ...state.jobStatus }
      const reportId = action.payload.consumer.split('-')[1]
      jobStatus[reportId] = {
        ...jobStatus[reportId],
        status: action.payload.messageType.includes('success')
          ? 'SUCCESS'
          : 'FAILED',
      }
      return {
        ...state,
        fetchingJobStatus: false,
        jobStatus,
      }
    }

    default:
      return { ...state }
  }
}
