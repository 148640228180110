import React from 'react'
import styled from 'styled-components'

const ToggleWrapper = styled.div`
  align-items: 'center';
  display: 'flex';
  justify-content: 'center';

  .toggle-body {
    background: #d1eafd;
    border-radius: 8px;
    width: 32px;
    position: relative;
    height: 16px;

    .toggle-button {
      background: #1a98f8;
      border-radius: 50%;
      color: #fff;
      cursor: pointer;
      display: inline-block;
      font-size: 8px;
      font-weight: bold;
      height: 12px;
      position: absolute;
      top: 2px;
      left: ${props => (props.on ? '2' : '18')}px;
      width: 12px;
      transition: left 0.15s linear;

      span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -45%);
      }

      svg {
        position: absolute;
        top: 20%;
        left: 20%;
      }
    }
  }
`

const Toggle = ({ on, onChar, offChar, onClick, className }) => (
  <ToggleWrapper on={on} className={className}>
    <div className="toggle-body" onClick={onClick}>
      <div className="toggle-button">
        <span>{on ? onChar : offChar}</span>
      </div>
    </div>
  </ToggleWrapper>
)

export default Toggle
