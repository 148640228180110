import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import get from 'lodash/get'

import {
  fetchApplicationRoot,
  selectApplicationRootId,
} from 'action_creators/application_root'

import { fetchEnvironments } from 'action_creators/environment'
import { updateMessageModal } from 'action_creators/message_modal'

import * as routing from 'services/routing'

function useApplicationLoader({ appId, path, requestAccess }) {
  const dispatch = useDispatch()
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    async function getData() {
      dispatch(selectApplicationRootId(appId))

      try {
        const response = await dispatch(fetchApplicationRoot(appId, requestAccess))

        if (!get(response, 'data.catalogApplication.requiresHierarchicalModel')) {
          await dispatch(fetchEnvironments(appId, requestAccess))
        }

        setIsLoaded(true)
      } catch (error) {
        const errorMessage = get(error, 'response.data.message', 'Unknown error')

        dispatch(
          updateMessageModal({
            body: errorMessage,
            afterCloseAction: routing.appsList,
          })
        )
      }
    }

    getData()
  }, [path])

  return { isLoaded }
}

export default useApplicationLoader
