import React from 'react'
import Typography from 'britive-design-system/core/components/typography'
import { translatedStrings } from '../constants'
import { convertTimeToCurrentTimezone, getSecretStatus } from '../../utils'
import { classes } from '../request-modal/constants'

export const RequestDetails = ({ requestDetails }) => {
  const getApprovers = () => {
    const { approvers } = requestDetails
    return (approvers?.userIds || []).concat(approvers?.tags || []).join(', ')
  }

  return (
    <div className={classes.profileCheckoutRequestLayout}>
      <div className={classes.profileCheckoutRequestSpacing}>
        <div className={classes.profileCheckoutRequestSpacing}>
          <div className={classes.profileCheckoutRequestLabel}>
            <Typography variant="label2">{translatedStrings.requestOn}</Typography>
          </div>
          <div className={classes.profileCheckoutRequestValue}>
            <Typography variant="label1">
              {convertTimeToCurrentTimezone(requestDetails.createdAt)}
            </Typography>
          </div>
        </div>
      </div>

      <div className={classes.profileCheckoutRequestSpacing}>
        <div className={classes.profileCheckoutRequestLabel}>
          <Typography variant="label2">{translatedStrings.approvers}</Typography>
        </div>
        <div className={classes.profileCheckoutRequestValue}>
          <Typography variant="label1">{getApprovers()}</Typography>
        </div>
      </div>
      <div className={classes.profileCheckoutRequestSpacing}>
        <div className={classes.profileCheckoutRequestLabel}>
          <Typography variant="label2">{translatedStrings.status}</Typography>
        </div>
        <div className={classes.profileCheckoutRequestValue}>
          <Typography variant="label1">
            {getSecretStatus(requestDetails.status)}
          </Typography>
        </div>
      </div>
      <div className={classes.profileCheckoutRequestSpacing}>
        <div className={classes.profileCheckoutRequestLabel}>
          <Typography variant="label2">{translatedStrings.justification}</Typography>
        </div>
        <div className={classes.profileCheckoutRequestValue}>
          <Typography variant="label1">{requestDetails.justification}</Typography>
        </div>
      </div>
    </div>
  )
}
