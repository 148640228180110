import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import camelCase from 'lodash/camelCase'
import Button from 'britive-ui-components/core/components/Button'
import Spinner from 'britive-ui-components/core/components/Spinner'
import BDSButton from 'britive-design-system/core/components/button'
import EditableProperty from './../editable-property'
import { allowCopyingValue, isLoginUrlPresentForOpenshift } from './utils'
import copyToClipboard from 'utils/copyToClipboard'
import copyIcon from 'static/images/copy.png'
import isOpenshift from 'utils/application/isOpenshift'

class PropertySection extends PureComponent {
  state = {
    inEditMode: false,
    saveBtnAction: 'sfl',
    formErrors: {},
  }

  componentDidUpdate(prevProps) {
    // this is awful
    if (!prevProps.saving && this.props.saving) {
      this.setState({ inEditMode: false })
    }
  }

  toggleEditMode = (cancelling = null) => {
    cancelling && this.props.onCancel()
    this.setState({
      inEditMode: !this.state.inEditMode,
    })
  }

  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen,
    }))
  }

  getPropertyType(name) {
    const { propertyTypes = {} } = this.props
    if (propertyTypes && name) {
      const property = propertyTypes[name]
      return property
    }
  }

  getInputValue = (propertyObj, fieldsFromState) => {
    const { name, parent } = propertyObj
    if (propertyObj && fieldsFromState) {
      if (propertyObj.parent) {
        return get(fieldsFromState, `[${parent}]['propertyTypes'][${name}]`)
      } else if (fieldsFromState.propertyTypes) {
        return fieldsFromState.propertyTypes[name]
      }
    }
  }

  onClickSave = (test = false) => {
    this.toggleEditMode()
    this.props.onSubmit(null, false, test)
  }

  renderField(idx, propertyObj, inputValue) {
    const {
      createNewMode,
      propertyTypes,
      subPropertiesMap,
      fields,
      onChange,
      appRoot,
    } = this.props
    const { inEditMode } = this.state
    const isEditable = !propertyObj.readOnly && (inEditMode || createNewMode)
    const Component = propertyObj.customComponent || EditableProperty

    return (
      <div key={idx} className="row">
        {/* value prop is determined first by inputValue (from state),
          then value (coming from API as saved), then defaultValue */}
        <Component
          {...propertyObj}
          value={inputValue || inputValue === '' ? inputValue : propertyObj.value}
          inputValue={inputValue}
          onChange={onChange}
          editable={isEditable}
          propertyTypes={propertyTypes}
          fields={fields}
          subPropertiesMap={subPropertiesMap}
          appRoot={appRoot}
          error={this.state.formErrors[propertyObj.name]}
        />
        {allowCopyingValue(propertyObj) && (
          <div style={{ paddingTop: '28px' }}>
            <BDSButton
              variant="textOnly"
              size="large"
              tooltipText={`Copy ${propertyObj?.description}`}
              tooltipPosition="top"
              onClick={() => copyToClipboard(propertyObj.value)}
            >
              <img src={copyIcon} />
            </BDSButton>
          </div>
        )}
      </div>
    )
  }

  render() {
    const {
      label,
      properties,
      isLoading,
      sectionLabel,
      createNewMode,
      thisAppManage,
      isApplicationRoot,
      appRoot,
    } = this.props
    const { propertyTypes } = this.props.fields

    if (isLoading) {
      return (
        <div className="bt-property-group-section">
          <h5> {label} </h5>

          <Spinner size="2x" />
        </div>
      )
    }

    return (
      <div className="bt-property-group-section">
        <h5> {label} </h5>{' '}
        {properties.map((propName, idx) => {
          let propertyObj = this.getPropertyType(propName)
          // Ask Damian what this was meant to do
          if (propertyObj && !propertyObj.privy) {
            const inputValue = this.getInputValue(propertyObj, this.props.fields)
            propertyObj.displayName = propertyObj.description
            if (
              // This stuff is pretty bad
              propertyObj.name.includes('static') ||
              propertyObj.name.includes('native')
            ) {
              if (
                propertyTypes &&
                propertyTypes.appAccessMethod === 'staticMethod' &&
                propertyObj.name.includes('static')
              ) {
                return this.renderField(idx, propertyObj, inputValue)
              }

              if (
                propertyTypes &&
                propertyTypes.appAccessMethod === 'nativeMethod' &&
                propertyObj.name.includes('native')
              ) {
                return this.renderField(idx, propertyObj, inputValue)
              }

              if (propertyObj.name.includes('static_loginUrl')) {
                return this.renderField(idx, propertyObj, inputValue)
              }
            } else {
              return this.renderField(idx, propertyObj, inputValue)
            }
          }

          return null
        })}{' '}
        {!createNewMode && thisAppManage && (
          <div className="action-container">
            {' '}
            {this.state.inEditMode ? (
              <Fragment>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <Button
                    color="secondary"
                    className={`mr-2 ${camelCase(sectionLabel)}Cancel`}
                    onClick={() => this.toggleEditMode(true)}
                  >
                    Cancel{' '}
                  </Button>{' '}
                  <Button
                    attachleftbutton={true}
                    className={`${camelCase(sectionLabel)}Save`}
                    color="primary"
                    onClick={() => {
                      if (
                        isApplicationRoot &&
                        isOpenshift(appRoot?.catalogAppName) &&
                        !isLoginUrlPresentForOpenshift(this.props.fields)
                      ) {
                        this.setState({
                          formErrors: {
                            appAccessMethod_static_loginUrl:
                              'Login URL is a mandatory field.',
                          },
                        })
                        return
                      }

                      this.setState({
                        formErrors: {},
                      })

                      this.state.saveBtnAction === 'sat'
                        ? this.onClickSave(true)
                        : this.onClickSave()
                    }}
                    spinner={this.props.saving}
                  >
                    {this.state.saveBtnAction === 'sfl' ? 'Save' : 'Save and Test'}{' '}
                  </Button>{' '}
                </div>{' '}
              </Fragment>
            ) : (
              <Button
                className={`${camelCase(sectionLabel)}Edit`}
                color="primary"
                onClick={() => this.toggleEditMode()}
              >
                Edit{' '}
              </Button>
            )}{' '}
          </div>
        )}{' '}
      </div>
    )
  }
}

PropertySection.propTypes = {
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  fields: PropTypes.object,
  label: PropTypes.string,
  properties: PropTypes.array,
  propertyTypes: PropTypes.object,
  subPropertiesMap: PropTypes.object,
  createNewMode: PropTypes.bool,
  saving: PropTypes.bool,
  onCancel: PropTypes.func,
}

export default PropertySection
