import React, { memo } from 'react'
import styled from 'styled-components'

import useMaxTableHeight from 'hooks/useMaxTableHeight'
import ReactTooltip from 'react-tooltip'
import TableSection from 'components/table/tableSectionV2'

const Wrapper = styled.div`
  position: relative;
  padding: 8px;

  .tab-content {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  > .tabs .tab-list {
    margin-bottom: 0;
  }
`

function OrgScanDetails({ filterDataSet, data }) {
  const maxTableHeight = useMaxTableHeight({
    heights: [
      40, // interior tabs
      16, // padding
      36, // table header
      12,
      12,
    ],
    minHeight: 100,
  })

  return (
    <Wrapper>
      <TableSection
        highlightSearchResults
        useCompoundFilter
        compoundFilterCategories={[
          { label: 'Type', value: 'type' },
          { label: 'Status', value: 'status' },
        ]}
        filterDataSet={filterDataSet}
        columns={[
          {
            Header: 'Name',
            accessor: 'name',
            minWidth: 400,
          },
          {
            Header: 'Type',
            accessor: 'type',
            minWidth: 300,
          },
          {
            Header: 'Status',
            accessor: 'status',
            minWidth: 200,
          },
        ]}
        data={data}
        identifier="name"
        searchKeys={['name', 'type', 'status']}
        emptyTableMessage="No Details were found."
        maxHeight={`${maxTableHeight}px`}
        marginTop={0}
        onScroll={() => ReactTooltip.hide()}
        sortable
        showPagination
        defaultSorted={[{ id: 'name', desc: false }]}
      />
    </Wrapper>
  )
}

export default memo(OrgScanDetails)
