import { combineReducers } from 'redux'
import { profileListReducer } from './profileListReducer'
import { profileDeleteReducer } from './profileDeleteReducer.js'
import { profileToggleReducer } from './profileToggleReducer.js'
import { createProfileReducer } from './createProfileReducer.js'
import { policyReducer } from './policyReducer'
import { associationsReducer } from './associationsReducer'
import { profileDetailsReducer } from './profileDetailsReducer'
import { permissionsReducer } from './permissionsReducer'

const profileManagement = combineReducers({
  profileListReducer,
  profileDeleteReducer,
  profileToggleReducer,
  createProfileReducer,
  policyReducer,
  associationsReducer,
  profileDetailsReducer,
  permissionsReducer,
})

export default profileManagement
