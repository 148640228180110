import React, { memo } from 'react'
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader as UnstyledHeader,
} from 'reactstrap'
import styled from 'styled-components'

import Button from 'britive-ui-components/core/components/Button'

const ModalHeader = styled(UnstyledHeader)`
  border: none;
  height: 16px;
  padding: 8px 8px 0 0;
  width: 100%;
  z-index: 1;

  button {
    padding: 0 !important;
    margin: 0 !important;
  }
`

function ConfirmationModal({
  confirmationMessage,
  isOpen,
  cancelProperties,
  confirmProperties,
  closeModal,
}) {
  return (
    <Modal isOpen={isOpen}>
      {closeModal && <ModalHeader toggle={closeModal} />}

      <ModalBody>{confirmationMessage}</ModalBody>

      <ModalFooter>
        <Button
          color="secondary"
          onClick={cancelProperties.onClick}
          spinner={cancelProperties.inProgress}
          disabled={confirmProperties.inProgress}
        >
          {cancelProperties.text}
        </Button>

        <Button
          disabled={cancelProperties.inProgress}
          spinner={confirmProperties.inProgress}
          color="primary"
          onClick={confirmProperties.onClick}
        >
          {confirmProperties.text}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default memo(ConfirmationModal)
