import React, { useState, memo } from 'react'
import { connect } from 'react-redux'
import { withRouter, Redirect } from 'react-router-dom'
import { compose } from 'redux'
import get from 'lodash/get'
import QRCode from 'react-qr-code'
import Button from 'britive-design-system/core/components/button'
import Textfield from 'britive-design-system/core/components/textfield'
import Typography from 'britive-design-system/core/components/typography'
import DialogPopup from 'britive-design-system/core/components/dialog'

import { NOT_AUTHENTICATED } from 'actions'

import { MFA_SETUP, SOFTWARE_TOKEN_MFA } from 'constants/cognitoAuthentication'

import Form from './Form'

function MFAForm({
  challengeParameters,
  dispatch,
  submitMFAOTP,
  errorMessage,
  fields,
  handleChange,
  history,
}) {
  const [showAuthenticatorCode, setShowAuthenticatorCode] = useState(false)

  async function goToLogin(event) {
    event.preventDefault()

    handleChange({ target: { name: 'password', value: '' } })

    dispatch({
      type: NOT_AUTHENTICATED,
    })

    history.push('/login')
  }

  const { challenge, QrCodeUrl, secret } = challengeParameters

  if (![SOFTWARE_TOKEN_MFA, MFA_SETUP].includes(challenge)) {
    return <Redirect to="/login" />
  }

  return (
    <Form
      className="login-form"
      onSubmit={submitMFAOTP}
      title={challenge === MFA_SETUP ? 'Setup MFA' : 'Submit your OTP'}
      errorMessage={errorMessage}
    >
      {challenge === MFA_SETUP && (
        <>
          <div className="mfa">
            <Typography variant="body">
              Please download the Google Authenticator App from Google Play Store
              <i className="fab fa-google-play icon" />
              or Apple App Store
              <i className="fab fa-app-store-ios icon" />
              and register your device using the QR code shown.
            </Typography>
            <div className="qr-code">
              <QRCode value={QrCodeUrl} size={200} />
              <a
                className="text-link"
                onClick={() => setShowAuthenticatorCode(true)}
              >
                Unable to scan?
              </a>
            </div>
          </div>
          <Typography variant="body">
            After completing device registration, please enter the OTP displayed on
            the Google Authenticator app and click on the {`"Submit"`} button below.
          </Typography>
        </>
      )}
      {showAuthenticatorCode && (
        <DialogPopup
          width={480}
          type="general"
          title="Register using key"
          message="Register using the following key"
          primaryButtonText="Done"
          onSubmit={() => setShowAuthenticatorCode(false)}
          onCancel={() => setShowAuthenticatorCode(false)}
        >
          <div className="mfa-secret">
            <Typography variant="label2">{secret}</Typography>
          </div>
        </DialogPopup>
      )}
      <div className="field">
        <Textfield
          id="mfaOtp"
          name="mfaOtp"
          placeholder="OTP"
          label="OTP"
          value={fields.mfaOtp}
          onChange={handleChange}
        />
        <a className="text-link" onClick={goToLogin}>
          Back to login
        </a>
      </div>
      <Button size="large" variant="primary">
        Submit
      </Button>
    </Form>
  )
}

const mapStateToProps = state => ({
  challengeParameters: get(state, 'account.challengeParameters', {}),
  accessToken: get(state, 'account.accessToken'),
  refreshToken: get(state, 'account.refreshToken'),
  username: get(state, 'account.username'),
})

const withConnect = connect(mapStateToProps)

export default compose(withConnect, withRouter, memo)(MFAForm)
