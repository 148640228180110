const pap = rawData => {
  const { users } = rawData
  const assignedUserIdList = users.map(user => user.userId)
  return {
    assignedUserIdList,
    ...rawData,
  }
}

export default pap
