import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from 'britive-design-system/core/components/button'
import Typography from 'britive-design-system/core/components/typography'
import Spinner from 'britive-design-system/core/components/spinner'
import {
  getApprovalsDetailsById,
  detailsApproveRequest,
  detailsRejectRequest,
} from '../../../../../action_creators/my-approvals'
import { approveRejectBy } from '../approveRejectBy'
import { approveRejectExpiredDate } from '../approveRejectExpiredDate'
import {
  classes,
  translatedStrings,
  status,
  statusLabel,
  YES_PARAM,
  buttonType,
  NO_PARAM,
} from '../constants'
import {
  convertTimeToCurrentTimezone,
  getActionLabel,
  getSecretStatus,
} from '../../../utils'
import { isEmpty } from 'lodash'
import '../myApprovalsDetails.scss'

const MyApprovalsDetailsMobile = ({ history, match }) => {
  const dispatch = useDispatch()
  const approvalsDetails = useSelector(
    state => state.userSecretsReducer?.myApprovalsDetails
  )

  const [userName, setUsername] = useState('')
  const { id: approvalId } = match?.params
  const [actionName, setActionName] = useState('')

  useEffect(() => {
    dispatch(getApprovalsDetailsById(approvalId))
  }, [])

  useEffect(() => {
    setActionName(getActionLabel(approvalsDetails?.data?.action))
  }, [approvalsDetails?.data?.action, userName])

  useEffect(() => {
    setUsername(approvalsDetails?.data?.userId)
  }, [approvalsDetails?.data?.userId])

  const headerLabel = `${translatedStrings.pageTitle} : ${getActionLabel(
    approvalsDetails?.data?.action
  )}`

  const statusMsg = label => {
    return (
      <>
        <Typography variant="label2">{label}</Typography>
      </>
    )
  }

  const myApprovalsDetails = () => {
    return (
      <div className={classes.approvalsDetailsForm}>
        <div className={`${classes?.marginTop8} ${classes?.detailsLabel}`}>
          <Typography variant="heading5">{headerLabel}</Typography>
        </div>

        {approvalsDetails?.data?.status === status.TIMEOUT &&
          statusMsg(statusLabel.expired)}
        {approvalsDetails?.data?.status === status.APPROVED &&
          statusMsg(
            `This request is approved by ${approvalsDetails?.data?.actionBy}`
          )}
        {approvalsDetails?.data?.status === status.REJECTED &&
          statusMsg(
            `This request is rejected by ${approvalsDetails?.data?.actionBy}`
          )}
        <div className={classes?.marginTop20}>
          <Typography variant="heading6">
            {' '}
            {translatedStrings.requestDate}{' '}
          </Typography>
          <div className={`${classes?.marginTop8}`}>
            <Typography variant="label1">
              {convertTimeToCurrentTimezone(approvalsDetails?.data?.createdAt) ||
                translatedStrings.noneValue}
            </Typography>
          </div>
        </div>

        <div className={classes?.marginTop20}>
          <Typography variant="heading6">{translatedStrings.resource}</Typography>
          <div className={`${classes?.marginTop8}`}>
            <Typography variant="label1">
              {approvalsDetails?.data?.resource || translatedStrings.noneValue}
            </Typography>
          </div>
        </div>

        <div className={classes?.marginTop20}>
          <Typography variant="heading6">
            {' '}
            {translatedStrings.actionType}{' '}
          </Typography>
          <div className={`${classes?.marginTop8}`}>
            <Typography variant="label1">
              {actionName || translatedStrings.noneValue}
            </Typography>
          </div>
        </div>

        <div className={classes?.marginTop20}>
          <Typography variant="heading6">
            {' '}
            {translatedStrings.requestedBy}{' '}
          </Typography>
          <div className={`${classes?.marginTop8}`}>
            <Typography variant="label1">
              {userName || translatedStrings.noneValue}
            </Typography>
          </div>
        </div>

        <div className={classes?.marginTop20}>
          <Typography variant="heading6">
            {' '}
            {translatedStrings.justification}{' '}
          </Typography>
          <div className={`${classes?.marginTop8}`}>
            <Typography variant="label1">
              {approvalsDetails?.data?.justification || translatedStrings.noneValue}
            </Typography>
          </div>
        </div>

        <div className={classes?.marginTop20}>
          <Typography variant="heading6">
            {' '}
            {translatedStrings.approvalStatus}{' '}
          </Typography>
          <div className={`${classes?.marginTop8}`}>
            <Typography variant="label1">
              {getSecretStatus(approvalsDetails?.data?.status) ||
                translatedStrings.noneValue}
            </Typography>
          </div>
        </div>

        {approvalsDetails?.data?.status === status.APPROVED &&
          approveRejectBy(
            translatedStrings.approvedBy,
            approvalsDetails?.data?.actionBy,
            classes.marginTop20,
            `${classes?.marginTop8}`
          )}
        {approvalsDetails?.data?.status === status.REJECTED &&
          approveRejectBy(
            translatedStrings.rejectedBy,
            approvalsDetails?.data?.actionBy,
            classes.marginTop20,
            `${classes.marginTop8}`
          )}

        {approvalsDetails?.data?.status === status.APPROVED &&
          approveRejectExpiredDate(
            translatedStrings.approvedOn,
            convertTimeToCurrentTimezone(approvalsDetails?.data?.updatedAt),
            classes.marginTop20,
            `${classes.marginTop8}`
          )}
        {approvalsDetails?.data?.status === status.REJECTED &&
          approveRejectExpiredDate(
            translatedStrings.rejectedOn,
            convertTimeToCurrentTimezone(approvalsDetails?.data?.updatedAt),
            classes.marginTop20,
            `${classes.marginTop8}`
          )}
        {approvalsDetails?.data?.status === status.TIMEOUT &&
          approveRejectExpiredDate(
            translatedStrings.expiredOn,
            convertTimeToCurrentTimezone(
              approvalsDetails?.data?.expirationTimeForApproveRequest
            ),
            classes.marginTop20,
            `${classes.marginTop8}`
          )}

        {approvalsDetails?.data?.status !== status.TIMEOUT &&
          approvalsDetails?.data?.status !== status.PENDING && (
            <div className={classes?.marginTop20}>
              <Typography variant="heading6">
                {' '}
                {translatedStrings.comments}{' '}
              </Typography>
              <div className={`${classes?.marginTop8}`}>
                <Typography variant="label1">
                  {approvalsDetails?.data?.approverComment ||
                    translatedStrings.noneValue}
                </Typography>
              </div>
            </div>
          )}

        <div className={classes.approvalsDetailsButton}>
          {approvalsDetails?.data?.status === status.PENDING && (
            <Button
              size="medium"
              onClick={() => {
                dispatch(
                  detailsApproveRequest(
                    approvalId,
                    YES_PARAM,
                    history,
                    '',
                    false,
                    '/my-approvals/acknowledge/approved'
                  )
                )
              }}
            >
              {buttonType.approve}
            </Button>
          )}
          {approvalsDetails?.data?.status === status.PENDING && (
            <div className={classes.cancelBtn}>
              <Button
                size="medium"
                variant="secondary"
                onClick={() => {
                  dispatch(
                    detailsRejectRequest(
                      approvalId,
                      NO_PARAM,
                      history,
                      '',
                      false,
                      '/my-approvals/acknowledge/rejected'
                    )
                  )
                }}
              >
                {buttonType.reject}
              </Button>
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <>
      {approvalsDetails?.loading ? (
        <Spinner
          size={'medium'}
          message={translatedStrings.fetchingApprovals}
          overlay={true}
        />
      ) : null}
      {!approvalsDetails?.loading &&
        !isEmpty(approvalsDetails.data) &&
        myApprovalsDetails()}
    </>
  )
}

export default MyApprovalsDetailsMobile
