import React, { useEffect, memo } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import QueryEngine from 'ada-query-engine'
import Analytics from 'ada-analytics'
import MySecrets from 'secret-manager-ui/core/packages/my-secrets'

import 'ada-query-engine/dist/index.css'
import 'ada-query-engine/dist/assets/css/common.css'

import Header from 'components/app_chrome/header'
import Navigation from 'components/app_chrome/navigation'
import PAPUpdater from 'components/PAPUpdater'
import Fallback from 'components/fallback'
import MessageModal from './MessageModal'

import Home from 'routes/home'
import Login from 'routes/login'
import Logout from 'routes/logout'
import CLI from 'routes/login/cli'
import MyAccess from 'routes/my-access'
import UserSettings from 'routes/user_settings'
import Admin from 'routes/admin'

import { fetchFeatureFlags } from 'action_creators/feature_flag'
import { FEATURE_FLAGS } from 'reducers/feature_flag'
import { getFeatureFlagById } from 'selectors/feature_flag'
import { fetchPrivileges } from 'action_creators/privileges'
import { MENU_PRIVILEGES } from 'reducers/privileges'

import { getIsAuthenticated } from 'utils/authentication'
import queryString from 'query-string'
import AccessDenied from '../routes/access-denied'
import ProtectedRoute from 'routes/protected_route'
import { connectToPushNotificationSocket } from 'action_creators/pushNotification'
import { disconnectFromSocket } from 'utils/socket'
import useWindowSize from 'hooks/useWindowSize'
import MyApprovals from '../routes/my-requests-my-approvals/my-approvals/list'
import MyApprovalsMobileView from '../routes/my-requests-my-approvals/my-approvals/list/mobile'
import MyApprovalsDetails from '../routes/my-requests-my-approvals/my-approvals/view'
import MyApprovalsDetailsMobile from '../routes/my-requests-my-approvals/my-approvals/view/mobile'
import MyRequests from '../routes/my-requests-my-approvals/my-requests'
import MyRequestsView from '../routes/my-requests-my-approvals/my-requests/view'
import ApprovalRequestAcknowledgement from '../routes/my-requests-my-approvals/my-approvals/view/mobile/ApprovalRequestAcknowledgement'
import RequestAccess from 'routes/RequestAccess/routes'
import ProfilePolicyManagement from 'secret-manager-ui/core/components/profile-policy'
import Announcement from 'components/app_chrome/header/announcement'
import MyResources from 'routes/my-resources'

const QueryEngineWithRouter = withRouter(QueryEngine)
const AnalyticsWithRouter = withRouter(Analytics)
const MySecretsWithRouter = withRouter(MySecrets)
const ProfilePolicyManagementWithRouter = withRouter(ProfilePolicyManagement)

export const CONTAINER_CONTENT_ID = 'container-content'

function MainLayout({
  dispatch,
  history,
  isAuthenticated,
  mustChangePassword,
  openNavigation,
  router,
  location,
  adaEnabled,
  secretManagerEnabled,
  fetchingFeatureFlags,
  fetchingPrivileges,
  showAdmin,
  queryEngineView,
  riskAnalyticsView,
  systemAnnouncement,
}) {
  const { innerWidth } = useWindowSize()

  useEffect(() => {
    if (isAuthenticated) {
      if (!fromCli && !location.pathname.includes('/sso')) {
        dispatch(fetchFeatureFlags())
        dispatch(fetchPrivileges())
        const socket = dispatch(connectToPushNotificationSocket())
        return () => {
          disconnectFromSocket(socket)
        }
      }
    }
  }, [isAuthenticated])

  useEffect(() => {
    const params = queryString.parse(location.search)
    const { appId, spInitiatedSSO, source } = params
    source && sessionStorage.setItem('source', source)
    if (appId) {
      sessionStorage.setItem('appId', appId)
      sessionStorage.setItem('spInitiatedAppId', appId)
      if (spInitiatedSSO) {
        sessionStorage.setItem('spInitiatedSSO', spInitiatedSSO)
        delete params.appId
        delete params.spInitiatedSSO
        sessionStorage.setItem('SAMLParams', queryString.stringify(params))
      }
    }
  }, [])

  const onLogoutClick = async () => {
    history.push('/logout')
  }

  const values = queryString.parse(location.search)
  const fromCli = values.token || sessionStorage.getItem('cli')
  if (values.token) {
    sessionStorage.setItem('cli', values.token)
  }

  const source = sessionStorage.getItem('source')
  return (
    <div className="main-layout">
      {location.pathname !== '/cli' &&
        !location.pathname.includes('/sso') &&
        !fromCli &&
        isAuthenticated &&
        !mustChangePassword && <Header logout={onLogoutClick} />}
      <div
        className={`container-fluid app-container${
          isAuthenticated ? ' landing-screen' : ''
        }`}
      >
        {location.pathname !== '/cli' && !fromCli && isAuthenticated && (
          <Navigation />
        )}

        <main
          id={CONTAINER_CONTENT_ID}
          className={`container-content${openNavigation ? ' expanded' : ''}`}
          role="main"
        >
          {systemAnnouncement?.open && (
            <Announcement systemAnnouncement={systemAnnouncement} />
          )}
          {innerWidth <= 768 && source === 'notification' ? (
            <Switch location={router.location}>
              <Redirect exact from="/" to="/my-approvals" />
              <Redirect
                exact
                from="/sso"
                to={{
                  ...location,
                  pathname: '/login/sso',
                  state: { fromSso: true },
                }}
              />
              <Route path="/login" component={Login} />
              <Route path="/logout" component={Logout} />
              <ProtectedRoute
                path="/my-secrets"
                component={MySecretsWithRouter}
                extraProps={{ source }}
              />
              <ProtectedRoute
                exact
                path="/my-approvals"
                component={MyApprovalsMobileView}
                extraProps={{ source }}
              />
              <ProtectedRoute
                path="/my-approvals/view/:id"
                component={MyApprovalsDetailsMobile}
                extraProps={{ source }}
              />
              <ProtectedRoute
                path="/my-approvals/acknowledge/:status"
                component={ApprovalRequestAcknowledgement}
              />
              <Redirect from="/my-access/my-approvals" to="/my-approvals" />
              <Redirect
                from="/my-access/my-approvals/view/:id"
                to="/my-approvals/view/:id"
              />
              <Redirect
                from="/my-access/my-approvals/acknowledge/:status"
                to="/my-approvals/acknowledge/:status"
              />
              <Redirect from="/my-secrets/my-approvals" to="/my-approvals" />
              <Redirect
                from="/my-secrets/my-approvals/view/:id"
                to="/my-approvals/view/:id"
              />
              <Redirect
                from="/my-secrets/acknowledge/:status"
                to="/my-approvals/acknowledge/:status"
              />
              <Redirect from="/*" to="/my-approvals" />
            </Switch>
          ) : (
            <Switch location={router.location}>
              <Redirect exact from="/" to="/my-access" />
              <Redirect
                exact
                from="/sso"
                to={{
                  ...location,
                  pathname: '/login/sso',
                  state: { fromSso: true },
                }}
              />
              <Route path="/login" component={Login} />
              <Route path="/logout" component={Logout} />
              <Route exact path="/" component={Home} />
              <Route path="/cli" component={CLI} />
              <Route path="/access-denied" component={AccessDenied} />
              <ProtectedRoute
                path={`/my-approvals/view/:id/applications/:appId/profiles/:profileId/details/policies/manage`}
                component={ProfilePolicyManagementWithRouter}
                extraProps={{
                  appManage: false,
                }}
                fallbackPath={`/my-approvals/view/:id/applications/:appId/profiles/:profileId/details/profile`}
              />
              <ProtectedRoute
                path="/my-approvals/view/:id"
                component={MyApprovalsDetails}
                extraProps={{
                  redirectPath: '/my-approvals',
                }}
              />
              <ProtectedRoute
                loading={fetchingFeatureFlags}
                path="/my-access/:tab?"
                component={MyAccess}
              />{' '}
              <ProtectedRoute
                path="/my-secrets"
                loading={fetchingFeatureFlags}
                renderCondition={secretManagerEnabled}
                component={MySecretsWithRouter}
              />
              <ProtectedRoute
                loading={fetchingFeatureFlags}
                path="/my-approvals"
                component={MyApprovals}
              />
              <ProtectedRoute
                loading={fetchingFeatureFlags}
                path="/my-resources"
                component={MyResources}
              />
              <ProtectedRoute
                path={`/my-requests/view/:id/applications/:appId/profiles/:profileId/details/policies/manage`}
                component={ProfilePolicyManagementWithRouter}
                extraProps={{
                  appManage: false,
                }}
                fallbackPath={`/my-requests/view/:id/applications/:appId/profiles/:profileId/details/profile`}
              />
              <ProtectedRoute
                loading={fetchingFeatureFlags}
                path="/my-requests/view/:id"
                component={MyRequestsView}
              />
              <ProtectedRoute
                loading={fetchingFeatureFlags}
                path="/my-requests"
                component={MyRequests}
              />
              <ProtectedRoute path="/user-settings" component={UserSettings} />
              <ProtectedRoute
                path="/admin"
                loading={fetchingPrivileges || fetchingFeatureFlags}
                renderCondition={showAdmin}
                component={Admin}
              />
              <ProtectedRoute
                path="/query-engine"
                loading={fetchingPrivileges || fetchingFeatureFlags}
                renderCondition={adaEnabled && queryEngineView}
                component={QueryEngineWithRouter}
              />
              <ProtectedRoute
                path="/analytics"
                loading={fetchingPrivileges || fetchingFeatureFlags}
                renderCondition={adaEnabled && riskAnalyticsView}
                component={AnalyticsWithRouter}
              />
              <ProtectedRoute
                path="/request-access"
                loading={fetchingPrivileges || fetchingFeatureFlags}
                component={RequestAccess}
              />
              <ProtectedRoute path="/*" component={Fallback} />
              <Redirect from="/my-access/my-requests" to="/my-requests" />
              <Redirect from="/my-access/my-approvals" to="/my-approvals" />
              <Redirect
                from="/my-access/my-approvals/view/:id"
                to="/my-approvals/view/:id"
              />
              <Redirect from="/my-secrets/my-requests" to="/my-requests" />
              <Redirect from="/my-secrets/my-approvals" to="/my-approvals" />
              <Redirect
                from="/my-secrets/my-approvals/view/:id"
                to="/my-approvals/view/:id"
              />
            </Switch>
          )}
        </main>
      </div>
      <MessageModal />
      {!fromCli && isAuthenticated && <PAPUpdater />}
    </div>
  )
}

MainLayout.propTypes = {
  isAuthenticated: PropTypes.bool,
  getUserDetails: PropTypes.func,
  expirationPollInterval: PropTypes.number,
  mustChangePassword: PropTypes.bool,
}

const mapStateToProps = state => {
  const {
    user: { mustChangePassword },
  } = state

  const { privileges } = state.privileges

  return {
    isAuthenticated: getIsAuthenticated(),
    mustChangePassword,
    openNavigation: state.navigation.openNavigation,
    router: state.router,
    adaEnabled: getFeatureFlagById({
      id: FEATURE_FLAGS.ada,
      state,
    }),
    secretManagerEnabled: getFeatureFlagById({
      id: FEATURE_FLAGS.secretmanager,
      state,
    }),
    fetchingFeatureFlags: state.features.fetchingFeatureFlags,
    fetchingPrivileges: state.privileges.fetchingPrivileges,
    fetchedPrivileges: state.privileges.fetchedPrivileges,
    showAdmin: state.privileges.showAdmin,
    queryEngineView: privileges.includes(MENU_PRIVILEGES.queryEngineView),
    riskAnalyticsView: privileges.includes(MENU_PRIVILEGES.riskAnalyticsView),
    featureFlags: state.features.featureFlags,
    systemAnnouncement: state.systemAnnouncementReducer,
  }
}

export default connect(mapStateToProps)(withRouter(memo(MainLayout)))
