import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import get from 'lodash/get'
import FontAwesome from 'react-fontawesome'
import Spinner from 'britive-ui-components/core/components/Spinner'
import TableActionIcon from 'components/TableActionIcon'
import {
  ActionIconLabel,
  ActionIconGroup as UnstyledActionIconGroup,
} from 'britive-ui-components/core/components/ActionIcon'
import { BackgroundTooltip as Tooltip } from 'components/Tooltips'

const ActionIconGroup = styled(UnstyledActionIconGroup)`
  display: flex;
  align-items: center;
`

const styles = {
  actionButton: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  tooltipIconWrapper: {
    cursor: 'default',
  },
}

class RenderActionButton extends Component {
  render() {
    const {
      mappingUser,
      userId,
      mapUser,
      unmapUser,
      isUpdatingUser,
      user,
    } = this.props

    if (user.mapMethod === 'Automatic') {
      const tooltipId = `${userId}Automatic`

      return (
        <div key={userId}>
          <TableActionIcon style={styles.tooltipIconWrapper}>
            <FontAwesome name="ban" data-tip data-for={tooltipId} />
            <Tooltip id={tooltipId}>
              This account has been automatically mapped by the system therefore
              cannot be removed manually.
            </Tooltip>
          </TableActionIcon>
        </div>
      )
    }

    return (
      <div key={userId}>
        {mappingUser ? (
          <ActionIconGroup>
            <TableActionIcon onClick={() => mapUser(user)}>
              {isUpdatingUser ? (
                <Spinner />
              ) : (
                <div style={styles.actionButton}>
                  <span className="fa fa-plus-circle fs:14" />
                  <ActionIconLabel> Map Identity </ActionIconLabel>{' '}
                </div>
              )}
            </TableActionIcon>
          </ActionIconGroup>
        ) : (
          <ActionIconGroup>
            <TableActionIcon onClick={() => unmapUser(userId)}>
              {isUpdatingUser ? (
                <Spinner />
              ) : (
                <div style={styles.actionButton}>
                  <span className="fa fa-minus-circle fs:14" />
                  <ActionIconLabel> Unmap Identity </ActionIconLabel>{' '}
                </div>
              )}
            </TableActionIcon>
          </ActionIconGroup>
        )}
      </div>
    )
  }
}

RenderActionButton.propTypes = {
  mappingUser: PropTypes.bool,
  user: PropTypes.object,
  loading: PropTypes.bool,
  mapUser: PropTypes.func,
  unmapUser: PropTypes.func,
}

const mapStateToProps = (state, { user }) => {
  const userId = get(user, 'userId')
  return { userId, user }
}

export default connect(mapStateToProps)(RenderActionButton)
