import React, { memo } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import styled from 'styled-components'

import SectionTab from '../SectionTab'
import SettingsFormButtons from './SettingsFormButtons'
import EnvironmentForm from '../EnvironmentForm'
import EnvironmentGroupForm from '../EnvironmentGroupForm'
import Button from 'britive-ui-components/core/components/Button'

import {
  APPLICATION_ROOT_FORM,
  ENVIRONMENT_FORM,
  ENVIRONMENT_GROUP_FORM,
  // ENVIRONMENT_LIST,
} from './SettingsFormContainer'

const Wrapper = styled.div`
  max-height: ${props => props.maxHeight}px;
  overflow-y: scroll;
  overflow-x: hidden;
`

const styles = {
  flex: {
    display: 'flex',
  },
  contentWrapper: {
    width: '100%',
    position: 'relative',
  },
  applicationContentWrapper: {
    width: '100%',
    position: 'relative',
  },
  emptyMessageWrapper: {
    backgroundColor: '#fff',
    boxShadow: '0 2px 8px 3px rgba(206,206,206,0.21)',
    padding: '10px 30px',
    marginBottom: 15,
  },
}

export function SettingsForm(props) {
  const shouldDisplayEmptyMessage = () => {
    const { section } = props
    return get(section, 'groups[0].properties[0]') === 'applicationPropertiesMessage'
  }

  const renderMainContent = () => {
    const {
      createNewMode,
      fields,
      formMode,
      handleChange,
      onCancel,
      propertyTypes,
      subPropertiesMap,
      saveUpdates,
      saving,
      section,
      updateFormMode,
      thisAppManage,
      catalogApp,
    } = props

    switch (true) {
      case ENVIRONMENT_FORM === formMode:
        return <EnvironmentForm updateFormMode={updateFormMode} />

      case ENVIRONMENT_GROUP_FORM === formMode:
        return <EnvironmentGroupForm updateFormMode={updateFormMode} />

      case APPLICATION_ROOT_FORM === formMode && shouldDisplayEmptyMessage(): {
        const message = get(
          propertyTypes,
          'applicationPropertiesMessage.value',
          'No form'
        )

        return <div style={styles.emptyMessageWrapper}>{message}</div>
      }

      case APPLICATION_ROOT_FORM === formMode:
      default:
        return (
          <SectionTab
            properties={section.properties}
            groups={section.groups}
            propertyTypes={propertyTypes}
            subPropertiesMap={subPropertiesMap}
            onChange={handleChange}
            onSubmit={saveUpdates}
            fields={fields}
            createNewMode={createNewMode}
            saving={saving}
            onCancel={onCancel}
            thisAppManage={thisAppManage}
            appRoot={catalogApp}
          />
        )
    }
  }

  const {
    catalogApp,
    createNewMode,
    dropdownOpen,
    formMode,
    isApplicationRoot,
    isNextDisabled,
    maxHeight,
    saving,
    saveBtnAction,
    scanInProgress,
    selectedApplicationRoot,
    selectedEntityId,
    testInProgress,
    testPassed,
    selectedEnvironmentId,
    nextClickHandler,
    saveClickHandler,
    scanApplicationRoot,
    toggle,
    updateSaveBtnAction,
    thisAppManage,
    initiateKeyPairRotation,
    rotatingKeyPair,
  } = props

  return (
    <Wrapper key={selectedEntityId} maxHeight={maxHeight}>
      <div style={styles.flex}>
        <div
          style={
            isApplicationRoot
              ? styles.applicationContentWrapper
              : styles.contentWrapper
          }
        >
          {renderMainContent()}

          {catalogApp?.requiresKeyPair && !!selectedEnvironmentId && (
            <div className="bt-property-group-section">
              <h5>Key Pair Rotation</h5>
              <Button
                color="primary"
                disabled={rotatingKeyPair}
                spinner={rotatingKeyPair}
                onClick={() => initiateKeyPairRotation(selectedEnvironmentId)}
              >
                Rotate
              </Button>
            </div>
          )}

          {(createNewMode || (!createNewMode && thisAppManage)) && (
            <SettingsFormButtons
              catalogApp={catalogApp}
              createNewMode={createNewMode}
              dropdownOpen={dropdownOpen}
              formMode={formMode}
              isApplicationRoot={isApplicationRoot}
              isNextDisabled={isNextDisabled}
              saving={saving}
              scanInProgress={scanInProgress}
              saveBtnAction={saveBtnAction}
              selectedApplicationRoot={selectedApplicationRoot}
              testInProgress={testInProgress}
              testPassed={testPassed}
              scanApplicationRoot={scanApplicationRoot}
              nextClickHandler={nextClickHandler}
              saveClickHandler={saveClickHandler}
              toggle={toggle}
              updateSaveBtnAction={updateSaveBtnAction}
            />
          )}
        </div>
      </div>
    </Wrapper>
  )
}

SettingsForm.propTypes = {
  createNewMode: PropTypes.bool,
  createApp: PropTypes.func,
  dropdownOpen: PropTypes.bool,
  fields: PropTypes.object,
  formMode: PropTypes.string,
  handleChange: PropTypes.func,
  isNextDisabled: PropTypes.bool,
  isApplicationRoot: PropTypes.bool,
  nextClickHandler: PropTypes.func,
  onCancel: PropTypes.func,
  propertyTypes: PropTypes.object,
  saveBtnAction: PropTypes.string,
  saveClickHandler: PropTypes.func,
  saveUpdates: PropTypes.func,
  saving: PropTypes.bool,
  scanInProgress: PropTypes.bool,
  scanPassed: PropTypes.bool,
  section: PropTypes.object,
  selectedApplicationRoot: PropTypes.object,
  scanApplicationRoot: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  scanClickHandler: PropTypes.func,
  testInProgress: PropTypes.bool,
  testPassed: PropTypes.bool,
  toggle: PropTypes.func,
  updateSaveBtnAction: PropTypes.func,
  updateFormMode: PropTypes.func,
}

export default memo(SettingsForm)
