import React, { Component, Fragment } from 'react'
import { Row, Col } from 'reactstrap'
import { connect } from 'react-redux'
import FormField, { RadioButtons } from 'components/form_fields'
import PropTypes from 'prop-types'
import {
  fetchCustomAttributesForUser,
  upDateCurrentUser,
} from 'action_creators/user'
import doFetch from 'utils/do_fetch'
import Button from 'britive-ui-components/core/components/Button'
import Spinner from 'britive-ui-components/core/components/Spinner'
import ErrorMessage from 'components/error_message'
import { getUsers } from 'action_creators/user'
import { getIdentityProviders } from 'action_creators/identity_provider'
import isEmpty from 'lodash/isEmpty'
import styled from 'styled-components'

import { AddRule, DeleteRule } from 'components/AddDeleteIcons'
import DateInput from 'components/DateInput'

const Wrapper = styled.div`
  .detail-container {
    padding: 10px 0px;
    > div {
      &:first-child {
        margin-top: 10px;
        font-weight: 600;
        text-transform: capitalize;
      }
      > div {
        display: flex;
      }
    }
    .form-group,
    .form-control {
      margin: 1px 0 !important;
      width: 320px;
    }
    input {
      width: 320px;
    }
  }
`

const FieldWrapper = styled.div`
  width: 100%;
`

const FIELD_DICT = {
  userEmail: 'email',
}

class UserDetails extends Component {
  state = {
    fields: {
      User: {
        firstName: '',
        lastName: '',
        mobile: '',
        phone: '',
        email: '',
        username: '',
        roleName: '',
        password: '',
        identityProvider: '',
      },
      ServiceIdentity: {
        name: '',
        description: '',
        roleName: '',
      },
    },
    attributes: {},
    requesting: false,
    errorMsg: '',
  }

  constructor(props) {
    super(props)
    props.user.type === 'User' && props.dispatch(getIdentityProviders())
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange = event => {
    let { name, value, type } = event.target
    const formattedName = FIELD_DICT[name] || name
    const {
      user: { type: userType },
    } = this.props

    switch (type) {
      case 'email':
        value = value.replace(/\s/g, '')
        event.target.value = value
    }

    this.setState(({ fields }) => ({
      fields: {
        [userType]: {
          ...fields[userType],
          [formattedName]: value,
        },
      },
    }))
  }

  async componentDidMount() {
    const { user, customAttributes = [], dispatch } = this.props
    if (user.type === 'User') {
      let attributes = user.attributes
      let formattedAttributes = {}
      if (!attributes) {
        try {
          const response = await dispatch(fetchCustomAttributesForUser(user.userId))
          attributes = response.data.data
        } catch (e) {
          console.log(e)
          attributes = []
        }
      }

      attributes.map(attribute => {
        if (formattedAttributes[attribute.attributeId]) {
          formattedAttributes[attribute.attributeId].push(attribute)
        } else {
          formattedAttributes[attribute.attributeId] = [attribute]
        }
      })
      customAttributes.map(attribute => {
        if (!attribute.builtIn && !formattedAttributes[attribute.id]) {
          formattedAttributes[attribute.id] = [
            {
              attributeName: attribute.name,
              attributeId: attribute.id,
              attributeValue: '',
            },
          ]
        }
      })
      this.setState({
        fields: {
          User: {
            firstName: user.firstName ? user.firstName : '',
            lastName: user.lastName ? user.lastName : '',
            mobile: user.mobile ? user.mobile : '',
            phone: user.phone ? user.phone : '',
            roleName: user.roleName || '',
            email: user.email || '',
            username: user.username || '',
            identityProvider: user.identityProvider
              ? user.identityProvider.id
              : null,
          },
        },
        attributes: formattedAttributes,
      })
    } else {
      this.setState({
        fields: {
          ServiceIdentity: {
            roleName: user.roleName || '',
            name: user.name || '',
            description: user.description || '',
          },
        },
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props
    if (prevProps.user !== user) {
      if (user.type === 'User') {
        this.setState({
          fields: {
            User: {
              firstName: user.firstName ? user.firstName : '',
              lastName: user.lastName ? user.lastName : '',
              mobile: user.mobile ? user.mobile : '',
              phone: user.phone ? user.phone : '',
              roleName: user.roleName || '',
              email: user.email || '',
              username: user.username || '',
              identityProvider: user.identityProvider
                ? user.identityProvider.id
                : '',
            },
          },
        })
      } else {
        this.setState({
          fields: {
            ServiceIdentity: {
              roleName: user.roleName || '',
              description: user.description || '',
              name: user.name || '',
            },
          },
        })
      }
    }
  }

  async sendUpdate() {
    this.setState({ requesting: true, errorMsg: '' })

    try {
      const path = this.props.self ? `/self` : `/users/${this.props.user.userId}`

      const { attributes } = this.state
      const { user, identityProviders } = this.props

      const fields = {
        ...this.state.fields[user.type],
      }

      let formattedAttributes = []

      Object.keys(attributes).forEach(attributeId => {
        return attributes[attributeId].forEach(attribute => {
          attribute.attributeValue !== '' && formattedAttributes.push(attribute)
        })
      })

      const _user = {
        ...fields,
        type: user.type,
        ...(user.type === 'User' && {
          identityProvider: {
            id: fields.identityProvider,
          },
          attributes: formattedAttributes,
        }),
      }

      await doFetch({
        method: 'patch',
        withCredentials: true,
        xsrfCookieName: 'csrfToken',
        path,
        postBody: _user,
      })
        .then(response => {
          if (response.status === 204) {
            _user.identityProvider = identityProviders.filter(
              identityProvider => identityProvider.id === fields.identityProvider
            )[0]
            this.props.self
              ? this.updateSuccess({ ..._user, userId: user.userId })
              : this.updateTenantUser({
                  ..._user,
                  canChangeOrResetPassword: user.canChangeOrResetPassword,
                  userId: user.userId,
                })
          } else {
            this.setState({
              requesting: false,
              errorMsg: 'Unable to update user at this time.',
            })
          }
        })
        .catch(error => {
          this.setState({
            requesting: false,
            errorMsg: error.response.data.message,
          })
        })
    } catch (e) {
      console.warn('updatePaps error : ', e)
    } finally {
      this.setState({ requesting: false })
    }
  }

  updateTenantUser(data) {
    this.props.updateThisUser(data)
    this.props.toggle()
  }

  updateSuccess(data) {
    this.setState({ requesting: false, errorMsg: '' })
    // wtf is this upDate casing. ffs.
    this.props.upDateCurrentUser({
      ...data,
    })
    this.props.toggle()
  }

  renderMultiValueActions = (attribute, lastIndex, index) => {
    return (
      <div style={styles.buttonWrapper}>
        <AddRule
          onClick={() => this.addInputField(attribute)}
          disabled={!lastIndex}
        />
        <DeleteRule
          onClick={() => this.removeInputField(attribute, lastIndex, index)}
        />
      </div>
    )
  }

  addInputField = attribute => {
    const { attributes } = this.state
    attributes[attribute.id].push({
      attributeId: attribute.id,
      attributeName: attribute.name,
      attributeValue: '',
    })
    this.setState({
      attributes,
    })
  }

  removeInputField = (attribute, lastIndex, index) => {
    const { attributes } = this.state
    index === 0 && lastIndex
      ? (attributes[attribute.id][0].attributeValue = '')
      : attributes[attribute.id].splice(index, 1)
    this.setState({
      attributes,
    })
  }

  renderSelectedInputs = (dataType, id, name, value, index, disabled) => {
    switch (dataType) {
      case 'Date':
        return (
          <DateInput
            type={`${name}${index}`}
            value={this.dateFromString(value)}
            name={`${name}${index}`}
            id={`${name}${index}`}
            updateDate={(type, dateObj) =>
              this.onChange(dateObj.toString(), id, index)
            }
            updateFromInput={(type, event) =>
              this.onChange(event.target.value, id, index)
            }
            dateFormat="MM/dd/yyyy"
            showTimeSelect={false}
            placeholder={name}
            disabled={disabled}
            showYearDropdown
          />
        )
      case 'String':
        return (
          <FormField
            name={`${name}${index}`}
            placeholder={name}
            value={value}
            onChange={event => this.onChange(event.target.value, id, index)}
            disabled={disabled}
          />
        )
      case 'Number':
        return (
          <FormField
            type="number"
            name={`${name}${index}`}
            placeholder={name}
            value={value}
            onChange={event => this.onChange(event.target.value, id, index)}
            disabled={disabled}
          />
        )
      case 'Boolean':
        return (
          <RadioButtons
            name={`${name}${index}`}
            onChange={event => this.onChange(event.target.value, id, index)}
            value={value}
            enumValues={['true', 'false']}
            enumNameMap={{ true: 'Yes', false: 'No' }}
            disabled={disabled}
          />
        )
      default:
        return ''
    }
  }

  onChange = (value, id, index) => {
    const { attributes } = this.state
    if ((value || value === '') && attributes[id][index].attributeValue !== value) {
      attributes[id][index].attributeValue = value
      this.setState({
        attributes,
      })
    }
  }

  dateFromString(dateString) {
    if (dateString !== '') {
      let newDate = new Date(dateString)
      if (!(newDate instanceof Date) || isNaN(newDate)) {
        return ''
      }
      return newDate
    }
    return ''
  }

  render() {
    const { errorMsg, requesting, attributes } = this.state
    const {
      fetchingIdentityProviders,
      user,
      identityProviders,
      toggle,
      customAttributes = [],
    } = this.props
    const { external } = user
    const fields = this.state.fields[user.type]
    return (
      <Wrapper>
        {user.type === 'User' && fetchingIdentityProviders ? (
          <Spinner size="2x" />
        ) : (
          fields && (
            <Fragment>
              {user.type === 'User' ? (
                <Fragment>
                  <Row className="detail-container">
                    <Col sm="4" lg="2">
                      First name
                    </Col>
                    <Col sm="8" lg="10">
                      <FormField
                        id="firstName"
                        name="firstName"
                        placeholder="First name"
                        value={fields.firstName}
                        hideLabel={true}
                        disabled={external}
                        onChange={this.handleChange}
                      />
                    </Col>
                  </Row>
                  <Row className="detail-container">
                    <Col sm="4" lg="2">
                      Last name
                    </Col>
                    <Col sm="8" lg="10">
                      <FormField
                        id="lastName"
                        name="lastName"
                        placeholder="Last name"
                        value={fields.lastName}
                        hideLabel={true}
                        disabled={external}
                        onChange={this.handleChange}
                      />
                    </Col>
                  </Row>
                  <Row className="detail-container">
                    <Col sm="4" lg="2">
                      Primary email
                    </Col>
                    <Col sm="8" lg="10">
                      <FormField
                        id="userEmail"
                        name="userEmail"
                        placeholder="Primary Email"
                        type="email"
                        defaultValue={fields.email}
                        hideLabel={true}
                        disabled={external}
                        onInput={this.handleChange}
                      />
                    </Col>
                  </Row>

                  <Row className="detail-container">
                    <Col sm="4" lg="2">
                      Username
                    </Col>
                    <Col sm="8" lg="10">
                      <FormField
                        id="username"
                        name="username"
                        placeholder="Username"
                        value={fields.username}
                        hideLabel={true}
                        disabled={external}
                        onChange={this.handleChange}
                      />
                    </Col>
                  </Row>

                  <Row className="detail-container">
                    <Col sm="4" lg="2">
                      Mobile number
                    </Col>
                    <Col sm="8" lg="10">
                      <FormField
                        id="mobile"
                        name="mobile"
                        placeholder="Mobile number"
                        value={fields.mobile}
                        hideLabel={true}
                        disabled={external}
                        onChange={this.handleChange}
                      />
                    </Col>
                  </Row>
                  <Row className="detail-container">
                    <Col sm="4" lg="2">
                      Phone number
                    </Col>
                    <Col sm="8" lg="10">
                      <FormField
                        id="phone"
                        name="phone"
                        placeholder="Phone number"
                        value={fields.phone}
                        hideLabel={true}
                        disabled={external}
                        onChange={this.handleChange}
                      />
                    </Col>
                  </Row>
                  <Row className="detail-container">
                    <Col sm="4" lg="2">
                      Identity Provider
                    </Col>
                    <Col sm="8" lg="10">
                      <div className="form-group">
                        <select
                          value={fields.identityProvider}
                          onChange={this.handleChange}
                          className="form-control"
                          id="identityProvider"
                          name="identityProvider"
                          disabled={external}
                        >
                          {identityProviders.map(identityProvider => (
                            <option
                              value={identityProvider.id}
                              key={identityProvider.id}
                            >
                              {identityProvider.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </Col>
                  </Row>
                  {!isEmpty(attributes) &&
                    customAttributes.map(
                      customAttribute =>
                        !customAttribute.builtIn && (
                          <Row className="detail-container" key={customAttribute.id}>
                            <Col sm="4" lg="2">
                              {customAttribute.name}
                            </Col>
                            <Col sm="8" lg="10">
                              {attributes[customAttribute.id].map(
                                (attribute, index) => (
                                  <FieldWrapper
                                    key={`${customAttribute.id}${index}`}
                                  >
                                    {this.renderSelectedInputs(
                                      customAttribute.dataType,
                                      customAttribute.id,
                                      customAttribute.name,
                                      attribute.attributeValue,
                                      index,
                                      external
                                    )}
                                    {!external &&
                                      customAttribute.multiValued &&
                                      this.renderMultiValueActions(
                                        customAttribute,
                                        index + 1 ===
                                          attributes[customAttribute.id].length,
                                        index
                                      )}
                                  </FieldWrapper>
                                )
                              )}
                            </Col>
                          </Row>
                        )
                    )}
                </Fragment>
              ) : (
                <Fragment>
                  <Row className="detail-container">
                    <Col sm="4" lg="2">
                      Name
                    </Col>
                    <Col sm="8" lg="10">
                      <FormField
                        id="name"
                        name="name"
                        placeholder="Name"
                        value={fields.name}
                        hideLabel={true}
                        disabled={external}
                        onChange={this.handleChange}
                      />
                    </Col>
                  </Row>
                  <Row className="detail-container">
                    <Col sm="4" lg="2">
                      Description
                    </Col>
                    <Col sm="8" lg="10">
                      <FormField
                        id="description"
                        name="description"
                        placeholder="Description"
                        value={fields.description}
                        hideLabel={true}
                        disabled={external}
                        onChange={this.handleChange}
                      />
                    </Col>
                  </Row>
                </Fragment>
              )}
              {errorMsg === '' ? null : (
                <Row className="detail-container">
                  <p />
                  <ErrorMessage text={errorMsg} />
                </Row>
              )}
              <Row>
                {requesting ? (
                  <Button
                    style={{ marginLeft: 10 }}
                    color="primary"
                    disabled={true}
                    spinner={true}
                  >
                    Updating
                  </Button>
                ) : (
                  <Fragment>
                    <Button
                      style={{ marginLeft: 10 }}
                      color="primary"
                      onClick={() => this.sendUpdate()}
                    >
                      Update
                    </Button>

                    <Button
                      style={{ marginLeft: 15 }}
                      color="secondary"
                      onClick={() => toggle()}
                    >
                      Cancel
                    </Button>
                  </Fragment>
                )}
              </Row>
            </Fragment>
          )
        )}
      </Wrapper>
    )
  }
}

UserDetails.propTypes = {
  user: PropTypes.object,
  upDateCurrentUser: PropTypes.func,
  toggle: PropTypes.func,
  loggedInUser: PropTypes.object,
  account: PropTypes.object,
  self: PropTypes.bool,
  getUsers: PropTypes.func,
  updateThisUser: PropTypes.func,
}

const styles = {
  buttonWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: 44,
    justifyContent: 'flex-end',
    marginBottom: 13,
    marginTop: 13,
  },
}

function mapStateToProps(state) {
  return {
    loggedInUser: state.user,
    account: state.account,
    identityProviders: state.identityProviders.identityProviders,
    fetchingIdentityProviders: state.identityProviders.fetchingIdentityProviders,
    customAttributes: state.customAttributes.customAttributes,
  }
}

export default connect(mapStateToProps, { upDateCurrentUser, getUsers })(UserDetails)
