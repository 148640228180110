import { createSelector } from 'reselect'

import { getStateSliceFor } from './index'

import { applicationRootReducerKey as reducerKey } from 'reducers/application_root'

/** Returns the state slice for application root. */
const extractFrom = state => getStateSliceFor({ state, reducerKey })

export const getFetchingAllApplicationRoots = state =>
  extractFrom(state).fetchingAllApplicationRoots

export const getFetchingApplicationRoot = state =>
  extractFrom(state).fetchingApplicationRoot

export const getSelectedApplicationRootId = state =>
  extractFrom(state).selectedApplicationRootId

export const getSelectedApplicationTemplate = state =>
  extractFrom(state).selectedApplicationTemplate

export const getApplicationRoots = state => extractFrom(state).applicationRoots

/** Returns the loaded application roots as an array */
export const getApplicationRootsArray = createSelector(
  state => getApplicationRoots(state),
  applicationRoots => {
    const rootsArray = Object.values(applicationRoots)

    return rootsArray.map(app => {
      const {
        catalogApplication: { propertyTypes },
      } = app

      // TODO: This is so bad!
      const descriptionData = propertyTypes.find(
        ({ name }) => name === 'description'
      )

      // TODO: This is so bad!
      return {
        ...app,
        description: descriptionData.value,
        defaultDescription: descriptionData.defaultValue,
      }
    })
  }
)

/** Returns the selected application root data */
export const getSelectedApplicationRoot = createSelector(
  state => getSelectedApplicationRootId(state),
  state => getApplicationRoots(state),
  (applicationRootId, applicationRoots) => {
    if (applicationRootId) {
      const appRoot = applicationRoots[applicationRootId]
      if (appRoot) {
        const {
          catalogApplication: { name },
        } = appRoot

        return {
          ...appRoot,
          activitiesEnabled: name === 'AWS',
        }
      } else {
        return null
      }
    }

    return null
  }
)

/** Returns the all applications as options */
export const getApplicationFilterOptions = createSelector(
  state => getApplicationRoots(state),
  applicationRoots => {
    if (applicationRoots) {
      return Object.values(applicationRoots).map(root => {
        return {
          value: root.appContainerId,
          label: root.catalogAppDisplayName,
        }
      })
    }

    return []
  }
)
