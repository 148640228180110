import React, { PureComponent } from 'react'

import { policyLabels } from './PasswordPolicyCheckContainer'
import Spinner from 'britive-ui-components/core/components/Spinner'

const styles = {
  success: {
    color: '#24A148',
    padding: 2,
  },
  error: {
    color: '#F1C21B',
    padding: 2,
  },
  policy: {
    marginBottom: 4,
  },
  wrapper: {
    marginTop: 10,
  },
}

function PassedIcon() {
  return <i className="fas fa-check" style={styles.success} />
}

function FailedIcon() {
  return <i className="fas fa-exclamation-triangle" style={styles.error} />
}

export default class PasswordPolicyCheck extends PureComponent {
  render() {
    const {
      className = '',
      policies,
      statuses,
      display,
      fetchingPasswordPolicy,
    } = this.props

    return (
      <div
        className={className}
        style={styles.wrapper}
        data-testid="password-policy-check"
      >
        {fetchingPasswordPolicy ? (
          <Spinner />
        ) : (
          Object.keys(statuses).map(statusName => {
            const label = policyLabels[statusName]
            const passed = statuses[statusName]

            return (
              <div key={statusName} style={styles.policy}>
                {!display && (passed ? <PassedIcon /> : <FailedIcon />)}
                <span>
                  {' '}
                  {typeof label === 'function' ? label(policies[statusName]) : label}
                </span>
              </div>
            )
          })
        )}
      </div>
    )
  }
}
