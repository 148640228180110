import React from 'react'
import get from 'lodash/get'

import TableEllipsisCell from 'components/table/TableV2/TableEllipsisCell'

import { formatDateShortWithYear } from 'utils/format_date'

export default function TimePeriod({ original: data }) {
  const accessPeriod = data.accessPeriod
  let value = 'Indefinite'

  if (get(accessPeriod, 'start')) {
    const start = formatDateShortWithYear({
      dateTime: accessPeriod.start,
      showTimeZone: false,
    })
    const end = formatDateShortWithYear({ dateTime: accessPeriod.end })
    value = `${start} - ${end}`
  }

  return <TableEllipsisCell value={value} />
}
