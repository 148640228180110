export const AUTHENTICATION_ERROR =
  'Your username and/or password was not recognized.'
export const SESSION_HAS_ENDED = 'Your session has ended.'
export const PLEASE_TRY_AGAIN = 'Please try again.'
export const LOGOUT = 'Logout'
export const ADD = 'Add'
export const SAVE = 'Save'
export const CANCEL = 'Cancel'
export const CLOSE = 'Close'
export const OK = 'OK'
export const TEST = 'Test'
export const NEXT = 'Next'
export const DONE = 'Done'
export const LOADING = 'Loading'

export const SYSTEM_ADMINISTRATION = 'System Administration'
export const TENANT_APPS = 'Tenant Apps'
export const ADD_APPLICATION = 'Add Application'
export const LOADING_APPLICATIONS = 'Loading Applications'

export const APPLICATION = 'Application'
export const ENVIRONMENT = 'Environment'
export const VERSION = 'Version'
export const ACTIONS = 'Actions'
export const PAPS = 'Paps'
export const PROPERTIES = 'Properties'
export const SCAN = 'Scan'
export const WORKFLOWS = 'Workflows'

export const ACCESS_PROFILES_FOR = 'Access Paps for'
export const LOADING_PAPS = 'Loading Paps'

export const IS_SUCCESSFULLY_ADDED = 'is successfully added'
export const IS_SUCCESSFULLY_SAVED = 'is successfully saved'
export const IS_NOT_SUCCESSFULLY_SAVED = 'is not successfully saved'

export const PAP_NAME = 'Pap Name'
export const EXPIRATION_DURATION = 'Expiration Duration'
export const STATUS = 'Status'

export const MANAGE_USERS = 'Manage Users'

export const IDENTITY_MANAGEMENT = 'Identity Management'
export const IDENTITY_MANAGEMENT_DESCRIPTION = 'Manage users, tags, and their roles.'

export const APPLICATION_AND_ACCESS_PROFILE_MANAGEMENT =
  'Application and Access Profile Management'
export const APPLICATION_AND_ACCESS_PROFILE_MANAGEMENT_DESCRIPTION =
  'Manage Application and Privileged Access Paps.'

export const DEACTIVATE = 'Deactivate'
export const MANAGE_PROFILES = 'Manage Paps'
export const MANAGE_PROPERTIES = 'Manage Properties'

export const FIRST_NAME = 'First Name'
export const LAST_NAME = 'Last Name'
export const EMAIL = 'Email'
export const USER_NAME = 'User Name'
export const USER_ROLE = 'User Role'
export const USER = 'User'
export const ADMIN = 'Admin'
export const PASSWORD = 'Password'
export const ADD_USER = 'Add User'

export const PROPERTIES_FOR = 'Properties for'
export const LOADING_PROPERTIES = 'Loading Properties'
export const SUCCESS = 'Success'
export const FAIL = 'Fail'
export const TEST_FAILURE = 'Test Failure'

export const IS_CORRECTLY_CONFIGURED = 'is correctly configured'
export const IS_NOT_CORRECTLY_CONFIGURED = 'is not correctly configured'

export const DATA_FOR = 'Data for'
export const LOADING_APP = 'Loading App'
export const SCAN_FAILURE = 'Scan Failure'
export const SCAN_COMPLETED_SUCCESSFULLY = 'Scan completed successfully'
export const SCAN_UNSUCCESSFUL = 'Scan unsuccessful'
export const TOKEN_CREATE_WARNING =
  'The API token inherits the same privileges as the creator ({{ fullName }}). Exercise caution while allowing identities to use this token.'
