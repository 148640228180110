import React, { Fragment, memo, useEffect, useState } from 'react'
import styled from 'styled-components'
import startCase from 'lodash/startCase'
import isEmpty from 'lodash/isEmpty'
import pluralize from 'pluralize'
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap'

import Button from 'britive-ui-components/core/components/Button'

import useMaxTableHeight from 'hooks/useMaxTableHeight'
import FormField from './form_fields'

const ModalWrapper = styled.div`
  > .modal-header {
    padding-top: 8px;
    padding-bottom: 8px;

    h5 {
      font-size: 24px;
    }
  }

  > .modal-body {
    ${props => (props.maxHeight ? `max-height: ${props.maxHeight}px;` : '')}
    overflow-y: scroll;
    oveflow-x: hidden;
  }

  p {
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .sub-message {
    margin: 10px 0;
    white-space: normal;

    .form-group {
      margin: 0;
    }
  }

  .warning-section {
    padding: 10px;
    background: #fffeee;
    border: 1px solid #fed52a;
    border-radius: 3px;
  }

  .warning {
    color: #fed52a;
  }
`

function ActionModal(props) {
  const [confirm, setConfirm] = useState(false)

  useEffect(() => {
    document.body.style.overflow = 'hidden !important'

    return () => {
      document.body.removeAttribute('style')
    }
  }, [])

  const maxTableHeight = useMaxTableHeight({
    heights: [64],
  })

  const renderModalHeader = () => {
    const { currentModalType, itemName } = props

    return (
      <ModalHeader>
        {currentModalType} {startCase(pluralize(itemName))}
      </ModalHeader>
    )
  }

  const renderPending = () => {
    const {
      currentModalType,
      checkedItems,
      isUpdating,
      toggleModal,
      modalAction,
      itemName,
      pendingSubMessage,
      askForConfirmation,
      renderItemFunction,
      warningMessage = 'This action is irreversible.',
    } = props

    return (
      <ModalWrapper maxHeight={maxTableHeight}>
        {renderModalHeader()}

        <ModalBody>
          <h5>
            The following {pluralize(itemName)} will be{' '}
            {currentModalType.toLowerCase()}d:
          </h5>

          {renderItemFunction
            ? Object.keys(checkedItems).map(item => renderItemFunction(item))
            : Object.keys(checkedItems).map(item => {
                const value = checkedItems[item]
                return (
                  <p key={item} title={value}>
                    {value}
                  </p>
                )
              })}
          {pendingSubMessage && <p className="sub-message">{pendingSubMessage}</p>}
          {askForConfirmation && (
            <Fragment>
              <p className="sub-message warning-section">
                <i className="fas fa-exclamation-triangle warning" />
                {` ${warningMessage}`}
              </p>
              <p className="sub-message">
                <FormField
                  id="confirm"
                  name="confirm"
                  hideLabel={false}
                  label="Type 'confirm' to confirm"
                  placeholder="Type 'confirm' to confirm"
                  onChange={event => setConfirm(event.target.value === 'confirm')}
                />
              </p>
            </Fragment>
          )}
        </ModalBody>

        <ModalFooter>
          <Button color="secondary" onClick={toggleModal} disabled={isUpdating}>
            Cancel
          </Button>

          <Button
            color="primary"
            disabled={askForConfirmation && !confirm}
            onClick={modalAction}
            spinner={isUpdating}
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalWrapper>
    )
  }

  const renderCompletion = () => {
    const {
      actionResults,
      currentModalType,
      itemName,
      renderItemFunction,
      toggleModal,
      checkedItems,
    } = props

    const modalTypeFormatted = currentModalType.toLowerCase()
    const successfulItems = {}
    const failureItems = {}

    Object.keys(checkedItems).map(item => {
      const status = actionResults[item]
      if (status === 'successful') {
        successfulItems[item] = checkedItems[item]
      } else if (status === 'failure') {
        failureItems[item] = checkedItems[item]
      }
    })

    return (
      <ModalWrapper maxHeight={maxTableHeight}>
        {renderModalHeader()}

        <ModalBody>
          {!isEmpty(successfulItems) && (
            <Fragment>
              <h5>
                The following {pluralize(itemName)} were {modalTypeFormatted}d:
              </h5>

              {renderItemFunction
                ? Object.keys(successfulItems).map(item => renderItemFunction(item))
                : Object.keys(successfulItems).map(item => {
                    const value = successfulItems[item]
                    return (
                      <p key={item} title={value}>
                        {value}
                      </p>
                    )
                  })}
            </Fragment>
          )}

          {!isEmpty(failureItems) && (
            <div>
              <h5>
                The following {pluralize(itemName)} could not be {modalTypeFormatted}
                d:
              </h5>

              {renderItemFunction
                ? Object.keys(failureItems).map(item => renderItemFunction(item))
                : Object.keys(failureItems).map(item => {
                    const value = failureItems[item]
                    return (
                      <p key={item} title={value}>
                        {value}
                      </p>
                    )
                  })}
            </div>
          )}
        </ModalBody>

        <ModalFooter>
          <Button color="primary" onClick={toggleModal}>
            Ok
          </Button>
        </ModalFooter>
      </ModalWrapper>
    )
  }

  const { allFinished, isModalOpen } = props

  if (!isModalOpen) {
    return null
  }

  return (
    <Modal isOpen={isModalOpen} size="lg">
      {allFinished ? renderCompletion() : renderPending()}
    </Modal>
  )
}

export default memo(ActionModal)
