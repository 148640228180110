import isEmpty from 'lodash/isEmpty'

export const showPermissionManageIcon = appName => {
  return !['okta', 'servicenow', 'salesforce', 'kubernetes', 'openshift'].includes(
    appName.toLowerCase()
  )
}

export const getEnvironmentListForSelector = (
  environmentData = [],
  environmentNames = []
) => {
  const environmentList = []
  if (environmentData.length > 0 && environmentNames.length > 0) {
    environmentNames.forEach(envName => {
      const currentEnvData = environmentData.find(
        envData => envData.catalogAppDisplayName === envName
      )

      if (!isEmpty(currentEnvData)) {
        environmentList.push({
          title: currentEnvData.catalogAppDisplayName,
          value: currentEnvData.environmentId,
        })
      }
    })
  }

  return environmentList
}

export const getPermissionPath = (
  appId,
  envId,
  name,
  forAccessBuilder,
  isGroup = false
) => {
  let generatedPath = `/apps/${appId}/environments/${envId}/${
    isGroup ? 'groups' : 'permissions'
  }?filter=name eq ${name}`

  if (forAccessBuilder) {
    generatedPath = `/profile-requests${generatedPath}`
  }

  return generatedPath
}
