import React, { useEffect, useMemo, useState } from 'react'
import keyBy from 'lodash/keyBy'

import Typography from 'britive-design-system/core/components/typography'
import Table from 'britive-design-system/core/components/table'
import Button from 'britive-design-system/core/components/button'
import Tooltip from 'britive-design-system/core/components/tooltip'
import Spinner from 'britive-design-system/core/components/spinner'

import listIcon from 'static/images/list-tree.svg'
import deleteIcon from 'static/images/delete-icon.svg'
import RequestersModal from './MembersModal'
import TagMembersModal from './TagMembersModal'
import { useSelector } from 'react-redux'
import useTableSort from 'hooks/useTableSort'
import classNames from 'classnames'
import deleteIconDisabled from 'static/images/delete-icon-disabled.svg'
import { isEmpty } from 'lodash'

const MembersSelector = ({
  isEditMode = true,
  members = [],
  onAdd,
  onDelete,
  inclusivityOption,
  noDataText = null,
  disableEditing = false,
  disabled,
}) => {
  const [selectedMembers, setSelectedMembers] = useState([])
  const [selectedType, setSelectedType] = useState(null)
  const [selectedTag, setSelectedTag] = useState(null)
  const [error, setError] = useState(null)

  const { tags, fetchingTags } = useSelector(state => state.tags)
  const { users, fetchingUsers } = useSelector(state => state.admin)

  const tagsObject = useMemo(() => keyBy(tags || [], 'userTagId'), [tags])

  const usersObject = useMemo(() => keyBy(users.User || [], 'username'), [users])

  useEffect(() => {
    setSelectedMembers(members)
  }, [members])

  const closeDrawer = () => {
    setSelectedType(null)
    setError(null)
  }

  const handleAdd = async members => {
    setError(null)
    try {
      await onAdd([
        ...(Array.isArray(selectedMembers) ? selectedMembers : []),
        ...members,
      ])
      closeDrawer()
    } catch (error) {
      setError(error?.response?.data?.message)
    }
  }

  const getUserFullName = row => {
    let userData = usersObject[row.name]
    if (isEmpty(userData)) {
      userData = row
    }

    return `${userData?.firstName || ''} ${userData?.lastName || ''}`
  }

  const getTag = (currentTag = {}) => {
    if (currentTag?.userCount >= 0 && currentTag?.userTagId && currentTag?.name) {
      return currentTag
    }

    return tagsObject[currentTag.id]
  }

  const getTagUserCount = row => {
    let tagData = tagsObject[row.id]
    if (isEmpty(tagData)) {
      tagData = row
    }

    return tagData.userCount
  }

  const MembersTable = () => {
    const [sortHandler, getSortedData] = useTableSort([
      {
        field: 'name',
        getValue: row => {
          const isUser = row.memberType === 'User'
          if (isUser && row.name.toLowerCase() === 'deleted member') {
            return '<Deleted User>'
          }

          return isUser ? getUserFullName(usersObject[row.name]) : row.name
        },
      },
      {
        field: 'members',
        getValue: row => tagsObject[row.id]?.userCount || -1,
      },
    ])

    const tableProps = {
      rows: getSortedData(selectedMembers),
      sortHandler,
      resizableColumns: true,
      columns: [
        {
          field: 'name',
          width: 200,
          headerName: 'Name',
          sortable: true,
          renderColumn: row => {
            const isUser = row.memberType === 'User'
            if (isUser && row.name.toLowerCase() === 'deleted member') {
              return '<Deleted User>'
            }

            return isUser ? getUserFullName(row) : row.name
          },
        },
        {
          field: 'memberType',
          width: 80,
          headerName: 'Type',
          sortable: true,
        },
        {
          headerName: 'Members',
          field: 'members',
          width: 100,
          sortable: true,
          renderColumn: row => {
            if (row?.memberType === 'Tag') {
              return (
                <div className="cell-with-icon">
                  <span>{getTagUserCount(row)}</span>
                  <span className="cell-icon" onClick={() => setSelectedTag(row)}>
                    <img src={listIcon} />
                  </span>
                </div>
              )
            } else {
              return 'N/A'
            }
          },
        },
      ],
    }

    if (inclusivityOption) {
      tableProps.columns.splice(2, 0, {
        field: 'condition',
        width: 100,
        headerName: 'Condition',
        sortable: true,
      })
    }

    if (isEditMode) {
      tableProps.columns.push({
        headerName: 'Action',
        width: 80,
        renderColumn: row => {
          const deleteIconClasses = {
            'disabled-icon': disableEditing,
          }
          return (
            <div className={classNames({ ...deleteIconClasses })}>
              <Tooltip title={'Delete'} position="left">
                <div
                  className="action-icon"
                  onClick={() => {
                    onDelete(row.id)
                  }}
                >
                  <div>
                    <img src={disableEditing ? deleteIconDisabled : deleteIcon} />
                  </div>
                </div>
              </Tooltip>
            </div>
          )
        },
      })
    }

    return selectedMembers?.length && !disabled ? (
      <Table {...tableProps} />
    ) : (
      noDataText && <Typography variant="label1">{noDataText}</Typography>
    )
  }

  if (fetchingTags || fetchingUsers) {
    return <Spinner size="medium" message="Loading..." overlay />
  }

  return (
    <>
      <div className="section-container">
        {isEditMode && (
          <div className="buttons-container">
            <Button
              size={'large'}
              onClick={() => {
                setSelectedType('users')
              }}
              disabled={!!selectedType || disableEditing || disabled}
            >
              Select Users
            </Button>
            <Button
              size={'large'}
              onClick={() => {
                setSelectedType('tags')
              }}
              disabled={!!selectedType || disableEditing || disabled}
            >
              Select Tags
            </Button>
          </div>
        )}
        <div className="table-container">
          <MembersTable />
        </div>
      </div>
      {selectedType && (
        <RequestersModal
          type={selectedType}
          onCancel={closeDrawer}
          onAdd={handleAdd}
          addError={error}
          memberRules={members}
          inclusivityOption={inclusivityOption}
        />
      )}
      {selectedTag && (
        <TagMembersModal
          tag={getTag(selectedTag)}
          onClose={() => setSelectedTag(null)}
        />
      )}
    </>
  )
}

export default MembersSelector
