import React from 'react'
import { components } from 'react-select'
import get from 'lodash/get'

function CustomOption(props) {
  const isSelected = props.data.name === get(props, 'selectProps.value.name')

  return <components.Option {...props} isSelected={isSelected} />
}

export default CustomOption
