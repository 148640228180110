import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import EnvironmentGroups from './EnvironmentGroups'
import { preFetch } from 'utils/do_fetch'
import Spinner from 'britive-ui-components/core/components/Spinner'

const Wrapper = styled.div`
  position: relative;
`

const Title = styled.div`
  color: var(--mine-shaft);
  position: sticky;
  font-weight: 500;
  font-size: 18px;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  z-index: 2;
`

export default ({ papId, id, title, isHierarchical, permission }) => {
  const [fetching, setFetching] = useState(true)
  const [integrityChecks, setIntegrityChecks] = useState(null)
  const [scope, setScope] = useState(null)
  //const [error, setError] = useState(null)
  useEffect(() => {
    const fetchPermissionIntegrityChecks = async () => {
      if (papId) {
        try {
          const response = await preFetch({
            path: `/paps/${papId}/permissions/checks`,
            method: 'get',
          })

          let data = permission
            ? response.data.filter(p => p.permissionName === permission)
            : response.data
          const integrityChecks = {}
          data.forEach(datum => {
            let key = isHierarchical ? datum.scope : datum.environmentId
            if (!integrityChecks[key]) {
              integrityChecks[key] = {
                failures: [
                  ...data
                    .filter(
                      d =>
                        d.checkStatus === 'Failure' &&
                        key === (isHierarchical ? d.scope : d.environmentId)
                    )
                    .map(({ permissionName, message }) => ({
                      permissionName,
                      message,
                      showPermissionName: !permission,
                    })),
                ],
                succeeded: !data.some(
                  d =>
                    d.checkStatus === 'Failure' &&
                    key === (isHierarchical ? d.scope : d.environmentId)
                ),
              }
            }
          })
          setIntegrityChecks(integrityChecks)
          const _scope = await preFetch({
            path: `/paps/${papId}/scopes`,
            method: 'get',
          })
          const scopeObject = {}
          _scope.data.map(d => {
            scopeObject[d.value] = d
          })
          setScope(scopeObject)
        } catch (error) {
          //setError(error)
        } finally {
          setFetching(false)
        }
      }
    }
    fetchPermissionIntegrityChecks()
  }, [papId])

  return (
    <React.Fragment>
      <Title>{title && title}</Title>
      {fetching ? (
        <Spinner />
      ) : (
        <Wrapper id={id}>
          <EnvironmentGroups
            wrapperId={id}
            integrityChecks={integrityChecks}
            scope={scope}
            showIntegrityCheckStatus={true}
          />
        </Wrapper>
      )}
    </React.Fragment>
  )
}
