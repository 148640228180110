import React, { memo, useMemo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { ADMIN_PRIVILEGES } from 'reducers/privileges'
import { withRouter } from 'react-router-dom'
import startCase from 'lodash/startCase'

import Apps from './Apps'

import useCheckboxState from 'hooks/useCheckboxState'

import { selectPapsManagerEntity } from 'routes/admin/state/actions'

import {
  deleteApplicationRoot,
  disableApplicationRoot,
  enableApplicationRoot,
} from 'action_creators/application_root'

import {
  getApplicationRootsArray,
  getFetchingAllApplicationRoots,
} from 'selectors/application_root'

import * as api from 'services/api'

const DATA_IDENTIFIER = 'appContainerId'

function AdminAppsContainer({ dispatch, appManage, requestAccess, history, match }) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [currentActionMode, setCurrentActionMode] = useState(null)
  const [filteredApps, setFilteredApps] = useState([])
  const [apps, setApps] = useState([])
  const [isFetchingApps, setIsFetchingApps] = useState(false)

  const {
    toggleAllCheckboxes,
    toggleCheckbox,
    checkItemsStatus,
    checkedItems,
    clearCheckedItems,
    setDataOverride,
  } = useCheckboxState(DATA_IDENTIFIER, filteredApps)

  useEffect(() => {
    dispatch(selectPapsManagerEntity(null))
  }, [])

  useEffect(() => {
    async function getApps() {
      setIsFetchingApps(true)
      try {
        const response = await api.getApps({ extended: true, requestAccess })
        setApps(response.data)
      } catch (error) {
        console.log(error)
      }

      setIsFetchingApps(false)
    }

    getApps()
  }, [])

  useEffect(() => {
    filterApps()
  }, [apps, currentActionMode])

  const filterDataSet = useMemo(() => {
    const statusValues = {}
    const typesValues = {}

    filteredApps.forEach(app => {
      const { status, type } = app

      !statusValues[status] &&
        (statusValues[status] = { label: startCase(status), value: status })

      !typesValues[type] && (typesValues[type] = { label: type, value: type })
    })

    return {
      status: Object.values(statusValues),
      type: Object.values(typesValues),
    }
  }, [filteredApps])

  function filterApps() {
    let filteredApps = apps

    if (['Disable', 'Enable'].includes(currentActionMode)) {
      const filter = currentActionMode === 'Disable' ? 'active' : 'inactive'

      filteredApps = filteredApps.filter(app => app.status === filter)
    }

    setFilteredApps(filteredApps)
  }

  function selectActionMode(currentActionMode = null) {
    setCurrentActionMode(currentActionMode)
  }

  function toggleModal() {
    setIsModalOpen(!isModalOpen)
  }

  async function deleteApplication(appId) {
    const response = await dispatch(deleteApplicationRoot(appId))

    await setApps(apps => apps.filter(app => app.appContainerId !== appId))

    return response
  }

  async function enableApplication(appId) {
    const response = await dispatch(enableApplicationRoot(appId))

    const updatedApps = [...apps]
    const appToUpdate = updatedApps.find(app => app.appContainerId === appId)
    appToUpdate.status = 'active'
    setApps(updatedApps)

    return response
  }

  async function disableApplication(appId) {
    const response = await dispatch(disableApplicationRoot(appId))

    const updatedApps = [...apps]
    const appToUpdate = updatedApps.find(app => app.appContainerId === appId)
    appToUpdate.status = 'inactive'
    setApps(updatedApps)

    return response
  }

  return (
    <Apps
      areAllAppsSelected={checkItemsStatus('every')}
      areAnyAppsSelected={checkItemsStatus('some')}
      checkedItems={checkedItems}
      clearCheckedItems={clearCheckedItems}
      currentActionMode={currentActionMode}
      deleteApplication={deleteApplication}
      disableApplication={disableApplication}
      enableApplication={enableApplication}
      dataIdentifier={DATA_IDENTIFIER}
      fetching={isFetchingApps}
      filterDataSet={filterDataSet}
      formattedApps={filteredApps}
      isModalOpen={isModalOpen}
      selectActionMode={selectActionMode}
      setDataOverride={setDataOverride}
      toggleAllCheckboxes={toggleAllCheckboxes}
      toggleCheckbox={toggleCheckbox}
      toggleModal={toggleModal}
      appManage={appManage}
      requestAccess={requestAccess}
      history={history}
      match={match}
    />
  )
}

AdminAppsContainer.propTypes = {
  apps: PropTypes.array,
  dispatch: PropTypes.func,
  fetching: PropTypes.bool,
  history: PropTypes.object,
  match: PropTypes.object,
}

const mapStateToProps = state => ({
  fetching: getFetchingAllApplicationRoots(state),
  apps: getApplicationRootsArray(state),
  appManage: state.privileges.privileges.includes(ADMIN_PRIVILEGES.appManage),
})

const withConnect = connect(mapStateToProps)

export default compose(withConnect, withRouter)(memo(AdminAppsContainer))
