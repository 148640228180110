import React, { memo, useEffect, useState } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import get from 'lodash/get'

import ScanStatus from './ScanStatus'

import { getSelectedApplicationRoot } from 'selectors/application_root'
import { scanApplicationRoot } from 'action_creators/application_root'
import { fetchEnvironmentGroups } from 'action_creators/environment'
import {
  getFetchingEnvironments,
  getEnvironments,
  getSelectedApplicationEnvironments,
} from 'selectors/environment'

import {
  APP_PROPERTIES_HEADER_ID,
  APP_PROPERTIES_TABS_ID,
} from '../../Properties/constants'
import { updateMessageModal } from 'action_creators/message_modal'

function ScanStatusContainer(props) {
  const [isScanModalOpen, setIsScanModalOpen] = useState(false)
  const [drawerData, setDrawerData] = useState(null)
  const [scanningEnv, setScanningEnv] = useState(false)
  const [scanningOrg, setScanningOrg] = useState(false)
  const [refresh, setRefresh] = useState(0)

  useEffect(() => {
    const { applicationRoot, setBreadcrumbTrail } = props
    const { catalogAppDisplayName: appName } = applicationRoot

    setBreadcrumbTrail([{ title: `${appName} Scan Summary` }])
  }, [])

  function refreshApplication() {
    const { dispatch, applicationRoot } = props
    const appId = get(applicationRoot, 'appContainerId')

    if (!appId) {
      return
    }
    dispatch(fetchEnvironmentGroups(appId))
  }

  function toggleDrawer(data) {
    setDrawerData(data)
  }

  function toggleScanModal() {
    setIsScanModalOpen(!isScanModalOpen)
  }

  async function scanOrganization() {
    const {
      dispatch,
      applicationRoot: {
        appContainerId: appId,
        catalogApplication: { requiresHierarchicalModel: isHierarchical },
      },
    } = props
    setScanningOrg(true)

    try {
      const response = await dispatch(scanApplicationRoot(appId))
      const { status, error } = response.action.payload.data

      dispatch(
        updateMessageModal({
          body: error
            ? error
            : `The scan is submitted.${
                isHierarchical
                  ? ''
                  : ' Please refresh the environment tree after the scan is completed to view changes.'
              }`,
          header: `Scan ${status}`,
        })
      )
    } catch (error) {
      dispatch(
        updateMessageModal({
          body:
            get(error, 'response.data.message') ||
            'There was an error in the scan. Please try again.',
          header: 'Scan Error',
        })
      )
    } finally {
      setRefresh(refresh + 1)
      setScanningOrg(false)
    }
  }

  async function scanHierarchical() {
    if (await scanOrganization()) {
      setScanningEnv(true)
      refreshApplication()
    }
  }

  const {
    environments,
    applicationRoot: app,
    appPropertiesHeaderHeight,
    appPropertiesTabsHeight,
    appHeaderHeight,
    environmentsData,
    thisAppManage,
  } = props

  const envGroups = get(app, 'rootEnvironmentGroup.environmentGroups', []).reduce(
    (acc, grp) => {
      acc[grp.id] = grp
      return acc
    },
    {}
  )

  return (
    <ScanStatus
      appHeaderHeight={appHeaderHeight}
      app={app}
      appPropertiesHeaderHeight={appPropertiesHeaderHeight}
      appPropertiesTabsHeight={appPropertiesTabsHeight}
      environments={environments}
      environmentsData={environmentsData}
      dropdownFilters={[
        {
          value: 'status',
          label: 'Status',
          options: [
            { label: 'Success', value: 'Success' },
            { label: 'Error', value: 'Error' },
            { label: 'In Progress', value: 'InProgress' },
            { label: 'Submitted', value: 'Submitted' },
          ],
        },
        {
          value: 'scanType',
          label: 'Scan Type',
          options: [
            { label: 'Environment', value: 'Environment' },
            { label: 'Organization', value: 'Organization' },
          ],
        },
      ]}
      setRefresh={setRefresh}
      isScanModalOpen={isScanModalOpen}
      toggleDrawer={toggleDrawer}
      toggleScanModal={toggleScanModal}
      drawerData={drawerData}
      envGroups={envGroups}
      scanOrganization={scanOrganization}
      scanningOrg={scanningOrg}
      scanHierarchical={scanHierarchical}
      scanningEnv={scanningEnv}
      setScanningEnv={setScanningEnv}
      refresh={refresh}
      thisAppManage={thisAppManage}
    />
  )
}

const mapStateToProps = state => {
  const applicationRoot = getSelectedApplicationRoot(state)

  return {
    applicationRoot,
    environments: getEnvironments(state)[applicationRoot.appContainerId],
    environmentsData: getSelectedApplicationEnvironments(state),
    isFetchingEnvironments: getFetchingEnvironments(state),
    appPropertiesHeaderHeight:
      state.elementRef.elementInfo[APP_PROPERTIES_HEADER_ID],
    appPropertiesTabsHeight: state.elementRef.elementInfo[APP_PROPERTIES_TABS_ID],
  }
}

const withConnect = connect(mapStateToProps)

export default compose(withConnect, memo)(ScanStatusContainer)
