import React, { memo, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'

import MaxData from './MaxData'

import * as api from 'services/api'

import { getSelectedApplicationRootId } from 'selectors/application_root'

import {
  getSelectedEnvironmentId,
  getSelectedEnvironmentGroupId,
} from 'selectors/environment'

const FILTER_OPTIONS = [
  { value: 'top5', label: 'Top 5' },
  { value: 'top10', label: 'Top 10' },
]

function MaxDataContainer({
  appId,
  columns,
  data,
  detailColumns,
  environmentGroupId,
  environmentId,
  reportId,
  reportName,
}) {
  const [reportData, setReportData] = useState(null)
  const [filter, setFilter] = useState(FILTER_OPTIONS[0])
  const [isDetailsDrawerOpen, setIsDetailsDrawerOpen] = useState(false)

  useEffect(() => {
    async function getReportData() {
      setReportData(null)
      const targetId = environmentId || environmentGroupId

      try {
        const response = await api.fetchReportDataForEnv({
          appId,
          envId: targetId,
          reportId,
        })

        setReportData(response.data.data)
      } catch (error) {
        setReportData(data || [])
        console.log(error)
      }
    }

    getReportData()
  }, [appId, environmentId])

  function toggleDetailsDrawer() {
    setIsDetailsDrawerOpen(!isDetailsDrawerOpen)
  }

  const filteredData = useMemo(() => {
    if (!reportData) {
      return null
    }

    if (filter.value === 'top5') {
      return reportData.slice(0, 5)
    }

    return reportData.slice(0, 10)
  }, [reportData, filter])

  return (
    <MaxData
      columns={columns}
      data={filteredData}
      detailColumns={detailColumns}
      filter={filter}
      filterOptions={FILTER_OPTIONS}
      isDetailsDrawerOpen={isDetailsDrawerOpen}
      reportName={reportName}
      setFilter={setFilter}
      toggleDetailsDrawer={toggleDetailsDrawer}
    />
  )
}

const mapStateToProps = state => ({
  appId: getSelectedApplicationRootId(state),
  environmentId: getSelectedEnvironmentId(state),
  environmentGroupId: getSelectedEnvironmentGroupId(state),
})

export default connect(mapStateToProps)(memo(MaxDataContainer))
