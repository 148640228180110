import React, { memo } from 'react'
import styled from 'styled-components'

import MaxDataDetails from './MaxDataDetails'
import Card from '../Card'
import TableView from 'components/table/TableV2/TableView'
import PageLoader from 'components/PageLoader'
import { BasicSelect } from 'components/Selects'

const Wrapper = styled(Card)`
  height: 253px;
  margin-top: 8px;
  width: 100%;
  padding-top: 0;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;

  &.loading {
    padding-top: 8px;
  }

  .headerWrapper {
    background: inherit;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 8px;
    margin-bottom: 8px;
    position: sticky;
    top: 0;
    z-index: 4;

    h5 {
      margin-bottom: 0;
      color: var(--endeavour);
    }
  }
`

const StatsWrapper = styled.div`
  .each-stat {
    align-items: center;
    justify-content: center;
    border-radius: 1px;
    box-shadow: 0 0 4px 1px var(--periwinkle-gray);
    border-top: 2px solid var(--brand);
    padding: 10px;
    flex-direction: column;
    margin: 10px 0;
    height: 287.5px;

    .titleNumber {
      color: ${props => props.color};
      font-size: 40px;
      font-weight: 500;
    }

    .headerWrapper {
      background: inherit;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      position: sticky;
      z-index: 4;

      h6 {
        margin-bottom: 0;
      }
    }
  }
`

const ActionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Select = styled(BasicSelect)`
  min-height: unset;
  width: 160px;

  ${props => (props.addMargin ? 'margin-right: 8px;' : '')}
`

function MaxData({
  columns,
  data,
  detailColumns,
  filter,
  filterOptions,
  isDetailsDrawerOpen,
  reportName,
  setFilter,
  toggleDetailsDrawer,
}) {
  if (!data) {
    return (
      <Wrapper className="loading">
        <PageLoader className="loader" text={`Loading ${reportName}`} />
      </Wrapper>
    )
  }

  return (
    <StatsWrapper>
      <div className="each-stat data_table_v2">
        <div className="headerWrapper">
          <h6 className="titleLabel">{reportName}</h6>
          <ActionWrapper>
            <Select
              options={filterOptions}
              value={filter}
              onChange={setFilter}
              isSearchable={false}
            />
          </ActionWrapper>
        </div>
        <TableView
          columns={columns}
          data={data}
          emptyTableMessage="No available data"
          sortable
          maxHeight="188px"
          rowOnClickHandler={toggleDetailsDrawer}
        />

        <MaxDataDetails
          detailColumns={detailColumns}
          data={data}
          title={reportName}
          isOpen={isDetailsDrawerOpen}
          toggleDrawer={toggleDetailsDrawer}
        />
      </div>
    </StatsWrapper>
  )
}

export default memo(MaxData)
