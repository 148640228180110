import {
  MyApprovalsDataTypes,
  ApproveRequestDataTypes,
  RejectRequestDataTypes,
  UsersType,
} from '../../routes/my-requests-my-approvals/my-approvals/list/constants'
import {
  MyApprovalsDetailsDataTypes,
  mySecretMyApprovalPath,
} from '../../routes/my-requests-my-approvals/my-approvals/view/constants'

export const requestMyApprovalsList = consumer => {
  return {
    type: MyApprovalsDataTypes.MY_APPROVALS_LIST_REQUEST,
    payload: { consumer },
  }
}

export const requestUpdateMyApprovalsListSearchTerm = search => {
  return {
    type: MyApprovalsDataTypes.MY_APPROVALS_LIST_SEARCH_TERM_UPDATE,
    payload: search,
  }
}

export const requestMyApprovalsListLoadMore = () => {
  return {
    type: MyApprovalsDataTypes.MY_APPROVALS_LIST_LOAD_MORE_REQUEST,
  }
}

export const loadingMyApprovalsListLoadMore = () => {
  return {
    type: MyApprovalsDataTypes.MY_APPROVALS_LIST_LOAD_MORE_LOADING,
  }
}

export const successMyApprovalsListLoadMore = data => {
  return {
    type: MyApprovalsDataTypes.MY_APPROVALS_LIST_LOAD_MORE_SUCCESS,
    payload: data,
  }
}

export const failureMyApprovalsListLoadMore = reason => {
  return {
    type: MyApprovalsDataTypes.MY_APPROVALS_LIST_LOAD_MORE_FAILURE,
    payload: reason,
  }
}

export const approveRequest = (approvalId, value, commandText, consumer) => {
  return {
    type: ApproveRequestDataTypes.APPROVE_REQUEST_REQUEST,
    payload: { approvalId, value, commandText, consumer },
  }
}

export const rejectRequest = (approvalId, value, commandText, consumer) => {
  return {
    type: RejectRequestDataTypes.REJECT_REQUEST_REQUEST,
    payload: { approvalId, value, commandText, consumer },
  }
}

export const requestUsersList = () => ({
  type: UsersType.APPROVALS_USERS_REQUEST,
})

export const loadingUsersList = () => ({
  type: UsersType.APPROVALS_USERS_LOADING,
})

export const successUsersList = data => ({
  type: UsersType.APPROVALS_USERS_SUCCESS,
  payload: data,
})

export const failureUsersList = reason => ({
  type: UsersType.APPROVALS_USERS_FAILURE,
  payload: reason,
})

export const getApprovalsDetailsById = id => ({
  type: MyApprovalsDetailsDataTypes.MY_APPROVALS_DETAILS_REQUEST,
  payload: id,
})

export const detailsApproveRequest = (
  approvalId,
  value,
  history,
  commandText,
  showNotification = true,
  redirectPath = mySecretMyApprovalPath
) => {
  return {
    type: ApproveRequestDataTypes.APPROVE_REQUEST_DETAILS_REQUEST,
    payload: {
      approvalId,
      value,
      history,
      commandText,
      redirectPath,
      showNotification,
    },
  }
}

export const detailsRejectRequest = (
  approvalId,
  value,
  history,
  commandText,
  showNotification = true,
  redirectPath = mySecretMyApprovalPath
) => {
  return {
    type: RejectRequestDataTypes.REJECT_REQUEST_DETAILS_REQUEST,
    payload: {
      approvalId,
      value,
      history,
      commandText,
      redirectPath,
      showNotification,
    },
  }
}

export const getApproversList = (id, groupId) => ({
  type: MyApprovalsDetailsDataTypes.MY_APPROVALS_APPROVERS_LIST_REQUEST,
  payload: {
    id,
    groupId,
  },
})
