import React from 'react'
import Tooltip from 'britive-design-system/core/components/tooltip'
import Checkbox from 'britive-design-system/core/components/checkbox'
import Table from './Table'
import editIcon from 'static/images/edit-icon.svg'
import deleteIcon from 'static/images/delete-icon.svg'
import { MembersCountCell } from './MembersCountCell'
import classNames from 'classnames'
import deleteIconDisabled from 'static/images/delete-icon-disabled.svg'
import editIconDisabled from 'static/images/edit-icon-disabled.svg'
import Typography from 'britive-design-system/core/components/typography'

const ApproversGroupList = ({
  rows,
  onEdit,
  onDelete,
  onViewMembers,
  showActionColumn,
  onChange,
  selectable,
  selectedApproversGroup = [],
  deleteIconText,
  showEditIcon,
  searchable,
  disableEditing = false,
  noDataMessage = '',
}) => {
  const tableCoumns = [
    {
      field: 'name',
      headerName: 'Name',
      sortable: true,
      ...(selectable
        ? {
            renderColumn: row => {
              const itemIndex = selectedApproversGroup.findIndex(
                ag => ag.id === row.id
              )
              const isChecked = itemIndex > -1
              return (
                <div style={{ display: 'flex' }}>
                  <Checkbox
                    checked={isChecked}
                    onChange={e => {
                      const isItemChecked = e.target.checked
                      if (isItemChecked && !isChecked) {
                        onChange([...selectedApproversGroup, row])
                      } else if (isChecked) {
                        const clonedSelectedItems = [...selectedApproversGroup]
                        clonedSelectedItems.splice(itemIndex, 1)
                        onChange(clonedSelectedItems)
                      }
                    }}
                  />
                  &nbsp;
                  {row.name}
                </div>
              )
            },
          }
        : {}),
    },
    {
      field: 'condition',
      headerName: 'Condition',
      sortable: true,
      renderColumn: row => {
        return (
          <span>
            {row.condition}&nbsp;
            {row.condition === 'Any' ? 'member' : 'members'}
          </span>
        )
      },
    },
    {
      field: 'members',
      headerName: 'Members',
      sortable: true,
      renderColumn: row => (
        <MembersCountCell
          count={row?.members?.length}
          onIconClick={() => onViewMembers(row)}
        />
      ),
    },
  ]

  if (showActionColumn) {
    tableCoumns.push({
      headerName: 'Action',
      width: '120px',
      renderColumn: row => {
        const deleteIconClasses = {
          'action-icons-container': true,
          'disabled-icon': disableEditing,
        }
        return (
          <div className={classNames({ ...deleteIconClasses })}>
            {showEditIcon && (
              <Tooltip title={'Edit'} position="left">
                <div
                  className="action-icon"
                  onClick={() => onEdit(row.id)}
                  data-testid="approvers-group-table-edit"
                >
                  <div>
                    <img src={disableEditing ? editIconDisabled : editIcon} />
                  </div>
                </div>
              </Tooltip>
            )}
            <Tooltip title={deleteIconText} position="left">
              <div
                className="action-icon"
                data-testid="approvers-group-table-delete"
                onClick={() => {
                  onDelete(row.id)
                }}
              >
                <div>
                  <img src={disableEditing ? deleteIconDisabled : deleteIcon} />
                </div>
              </div>
            </Tooltip>
          </div>
        )
      },
    })
  }
  return (
    <>
      {rows?.length === 0 ? (
        <Typography variant="heading5">
          {noDataMessage || 'Approvers Group not present.'}
        </Typography>
      ) : (
        <Table columns={tableCoumns} tableData={rows} searchable={searchable} />
      )}
    </>
  )
}

export default ApproversGroupList
