import { put, takeLatest, call, all, fork } from 'redux-saga/effects'
import toast from 'utils/toast/index.js'
import {
  MyApprovalsDetailsDataTypes,
  ApproveRequestDataTypes,
  RejectRequestDataTypes,
  translatedStrings,
} from './constants'
import { fetchMyApprovalsDetailsList, fetchApproversList } from './service'
import { approveRejectRequest } from '../list/service'

function* myApprovalsDetailsWatcher() {
  yield takeLatest(
    MyApprovalsDetailsDataTypes.MY_APPROVALS_DETAILS_REQUEST,
    myApprovalsDetailsWorker
  )
}

export function* myApprovalsDetailsWorker({ payload = {} }) {
  try {
    yield put({ type: MyApprovalsDetailsDataTypes.MY_APPROVALS_DETAILS_LOADING })

    const response = yield call(fetchMyApprovalsDetailsList, payload)
    const { data } = response

    yield put({
      type: MyApprovalsDetailsDataTypes.MY_APPROVALS_DETAILS_SUCCESS,
      payload: data,
    })
  } catch ({ response }) {
    const reason = (response && response?.data && response?.data?.message) || ''
    toast({
      title: reason,
      type: 'error',
      time: 'normal',
      id: reason,
    })
    yield put({
      type: MyApprovalsDetailsDataTypes.MY_APPROVALS_DETAILS_FAILURE,
      payload: reason,
    })
  }
}

function* detailsApproveRequestWatcher() {
  yield takeLatest(
    ApproveRequestDataTypes.APPROVE_REQUEST_DETAILS_REQUEST,
    detailsAapproveRequestWorker
  )
}

export function* detailsAapproveRequestWorker({ payload = {} }) {
  const { history, redirectPath, showNotification } = payload
  try {
    yield call(approveRejectRequest, payload)

    if (showNotification) {
      toast({
        title: translatedStrings.requestApproved,
        type: 'success',
        time: 'normal',
        id: translatedStrings.requestApproved,
      })
    }
    // redirect to approvals list on reject request success
    history.push(redirectPath)
  } catch ({ response }) {
    const reason = (response && response?.data && response?.data?.message) || ''
    toast({
      title: `${translatedStrings.requestNotApproved} ${reason}.`,
      type: 'error',
      time: 'normal',
      id: reason,
    })
  }
}

function* detailsRejectRequestWatcher() {
  yield takeLatest(
    RejectRequestDataTypes.REJECT_REQUEST_DETAILS_REQUEST,
    detailsRejectRequestWorker
  )
}

export function* detailsRejectRequestWorker({ payload = {} }) {
  const { history, redirectPath, showNotification } = payload
  try {
    yield call(approveRejectRequest, payload)

    if (showNotification) {
      toast({
        title: translatedStrings.requestRejected,
        type: 'success',
        time: 'normal',
        id: translatedStrings.requestRejected,
      })
    }
    // redirect to approvals list on reject request success
    history.push(redirectPath)
  } catch ({ response }) {
    const reason = response?.data?.message || ''
    toast({
      title: `${translatedStrings.requestNotRejected} ${reason}.`,
      type: 'error',
      time: 'normal',
      id: reason,
    })
  }
}

function* approversListWatcher() {
  yield takeLatest(
    MyApprovalsDetailsDataTypes.MY_APPROVALS_APPROVERS_LIST_REQUEST,
    approversListWorker
  )
}

export function* approversListWorker({ payload = {} }) {
  try {
    yield put({
      type: MyApprovalsDetailsDataTypes.MY_APPROVALS_APPROVERS_LIST_LOADING,
    })

    const response = yield call(fetchApproversList, payload)
    const { data } = response

    yield put({
      type: MyApprovalsDetailsDataTypes.MY_APPROVALS_APPROVERS_LIST_SUCCESS,
      payload: data,
    })
  } catch ({ response }) {
    const reason = (response && response?.data && response?.data?.message) || ''
    toast({
      title: reason,
      type: 'error',
      time: 'normal',
      id: reason,
    })
    yield put({
      type: MyApprovalsDetailsDataTypes.MY_APPROVALS_APPROVERS_LIST_FAILURE,
      payload: reason,
    })
  }
}

export default all([
  fork(myApprovalsDetailsWatcher),
  fork(detailsApproveRequestWatcher),
  fork(detailsRejectRequestWatcher),
  fork(approversListWatcher),
])
