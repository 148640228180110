import React, { memo, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import { ADMIN_PRIVILEGES } from 'reducers/privileges'

import PapPolicy from './PapPolicy'

import { getPapPolicies } from 'action_creators/papPolicy'
import useCheckboxState from 'hooks/useCheckboxState'

const DATA_IDENTIFIER = 'id'

function PapPolicyContainer({
  papPolicies,
  history,
  match,
  fetching,
  dispatch,
  appManage,
  requestAccess,
  onSelectPolicy,
  selectedPolicy,
  deletingPapPolicy,
  requestedPolicy,
  thisAppManage,
}) {
  const { profileId } = match.params
  useEffect(() => {
    dispatch(getPapPolicies(profileId, requestAccess))
  }, [])

  const [isActionModalOpen, setIsActionModalOpen] = useState(false)
  const [currentActionMode, setCurrentActionMode] = useState(null)
  const [filteredPapPolicies, setFilteredPapPolicies] = useState([])

  const {
    toggleAllCheckboxes,
    toggleCheckbox,
    checkItemsStatus,
    checkedItems,
    clearCheckedItems,
    setDataOverride,
  } = useCheckboxState(DATA_IDENTIFIER, filterPapPolicies)

  useEffect(() => {
    filterPapPolicies()
  }, [papPolicies, currentActionMode])

  function filterPapPolicies() {
    let filteredPapPolicies = papPolicies
    if (requestedPolicy) {
      filteredPapPolicies = filteredPapPolicies.filter(
        ({ name }) => name === requestedPolicy
      )
    } else if (currentActionMode === 'Enable') {
      filteredPapPolicies = filteredPapPolicies.filter(
        ({ isActive, isDraft }) => !isActive && !isDraft
      )
    } else if (currentActionMode === 'Disable') {
      filteredPapPolicies = filteredPapPolicies.filter(({ isActive }) => isActive)
    } else if (
      requestAccess &&
      match?.params?.mode !== 'create' &&
      match?.params?.mode !== 'draft'
    ) {
      filteredPapPolicies = filteredPapPolicies.filter(
        ({ isActive, isDraft }) => isActive && !isDraft
      )
    }

    setFilteredPapPolicies(filteredPapPolicies)
  }

  function selectActionMode(currentActionMode = null) {
    setCurrentActionMode(currentActionMode)
  }

  function toggleActionModal(currentActionMode) {
    setIsActionModalOpen(!isActionModalOpen)
    setCurrentActionMode(currentActionMode)
  }

  function showDetailsClick(papPolicyId) {
    history.push(`${match.url}/manage/view/${papPolicyId}`)
  }

  function showDetails(papPolicyId) {
    history.push(`${match.url}/manage/view/${papPolicyId}`)
  }

  function addPapPolicy() {
    history.push(`${match.url}/manage`)
  }

  return (
    <PapPolicy
      addPapPolicy={addPapPolicy}
      areAllPapPoliciesSelected={checkItemsStatus('every')}
      isAnyPapPoliciesSelected={checkItemsStatus('some')}
      checkedItems={checkedItems}
      clearCheckedItems={clearCheckedItems}
      currentActionMode={currentActionMode}
      dataIdentifier={DATA_IDENTIFIER}
      fetching={fetching}
      showDetailsClick={showDetailsClick}
      papPolicies={filteredPapPolicies}
      isActionModalOpen={isActionModalOpen}
      selectActionMode={selectActionMode}
      toggleActionModal={toggleActionModal}
      setDataOverride={setDataOverride}
      toggleAllCheckboxes={toggleAllCheckboxes}
      toggleCheckbox={toggleCheckbox}
      appManage={appManage}
      profileId={profileId}
      requestAccess={requestAccess}
      match={match}
      showDetails={showDetails}
      onSelectPolicy={onSelectPolicy}
      selectedPolicy={selectedPolicy}
      dispatch={dispatch}
      deletingPapPolicy={deletingPapPolicy}
      thisAppManage={thisAppManage}
    />
  )
}

const mapStateToProps = state => {
  return {
    fetching: state.papPolicy.fetchingPapPolicies,
    papPolicies: state.papPolicy.papPolicies,
    appManage: state.privileges.privileges.includes(ADMIN_PRIVILEGES.appManage),
    deletingPapPolicy: state.papPolicy.deletingPapPolicy,
  }
}

const withConnect = connect(mapStateToProps)

export default compose(withConnect, withRouter)(memo(PapPolicyContainer))
