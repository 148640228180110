import {
  FETCH_AUDITLOG_WEBHOOK,
  FETCH_WEBHOOK_BY_NOTIFICATION_MEDIUM_ID,
  UPDATE_WEBHOOK_BY_NOTIFICATION_MEDIUM_ID,
  DELETE_WEBHOOK_BY_NOTIFICATION_MEDIUM_ID,
  REMOVE_SELECTED_WEBHOOK_DATA,
} from '../actions'

export const auditLogWebhookReducerKey = 'auditLogWebhook'

const initialState = {
  fetchingWebhook: false,
  fetchingWebhookByMediumId: false,
  error: null,
  webhookData: null,
  webhookByMediumId: null,
}

export function auditLogWebhookReducer(state = initialState, action) {
  switch (action.type) {
    case `${FETCH_AUDITLOG_WEBHOOK}_PENDING`: {
      return {
        ...state,
        fetchingWebhook: true,
      }
    }

    case `${FETCH_AUDITLOG_WEBHOOK}_FULFILLED`: {
      return {
        ...state,
        webhookData: action.payload.data,
        fetchingWebhook: false,
      }
    }

    case `${FETCH_AUDITLOG_WEBHOOK}_REJECTED`: {
      return {
        ...state,
        fetchingWebhook: false,
        error: action.payload,
      }
    }

    case `${UPDATE_WEBHOOK_BY_NOTIFICATION_MEDIUM_ID}_PENDING`: {
      return {
        ...state,
        fetchingWebhookByMediumId: true,
      }
    }

    case `${UPDATE_WEBHOOK_BY_NOTIFICATION_MEDIUM_ID}_FULFILLED`: {
      return {
        ...state,
        fetchingWebhookByMediumId: false,
      }
    }

    case `${UPDATE_WEBHOOK_BY_NOTIFICATION_MEDIUM_ID}_REJECTED`: {
      return {
        ...state,
        fetchingWebhookByMediumId: false,
        error: action.payload,
      }
    }

    case `${DELETE_WEBHOOK_BY_NOTIFICATION_MEDIUM_ID}_PENDING`: {
      return {
        ...state,
        fetchingWebhook: true,
      }
    }

    case `${DELETE_WEBHOOK_BY_NOTIFICATION_MEDIUM_ID}_FULFILLED`: {
      return {
        ...state,
        fetchingWebhook: false,
      }
    }

    case `${DELETE_WEBHOOK_BY_NOTIFICATION_MEDIUM_ID}_REJECTED`: {
      return {
        ...state,
        fetchingWebhook: false,
        error: action.payload,
      }
    }

    case `${FETCH_WEBHOOK_BY_NOTIFICATION_MEDIUM_ID}_PENDING`: {
      return {
        ...state,
        fetchingWebhookByMediumId: true,
      }
    }

    case `${FETCH_WEBHOOK_BY_NOTIFICATION_MEDIUM_ID}_FULFILLED`: {
      return {
        ...state,
        webhookByMediumId: action.payload.data,
        fetchingWebhookByMediumId: false,
      }
    }

    case `${FETCH_WEBHOOK_BY_NOTIFICATION_MEDIUM_ID}_REJECTED`: {
      return {
        ...state,
        fetchingWebhookByMediumId: false,
        error: action.payload,
      }
    }

    case `${REMOVE_SELECTED_WEBHOOK_DATA}`: {
      return {
        ...state,
        webhookByMediumId: null,
      }
    }

    default:
      return { ...state }
  }
}
