export const UPDATE_MESSAGE_MODAL = 'UPDATE_MESSAGE_MODAL'
export const CLOSE_MESSAGE_MODAL = 'CLOSE_MESSAGE_MODAL'

export const updateMessageModal = ({ body, header, afterCloseAction }) => ({
  type: UPDATE_MESSAGE_MODAL,
  payload: {
    body,
    header,
    afterCloseAction,
  },
})

export const closeMessageModal = () => ({
  type: CLOSE_MESSAGE_MODAL,
})
