import {
  FETCH_API_KEYS,
  CREATE_API_KEY,
  UPDATE_API_KEY,
  REVOKE_API_KEY,
  FETCH_ALL_TOKENS,
  FETCH_API_KEY,
} from 'action_creators/api_key'

export const apiKeyReducerKey = 'apiKey'

const initialState = {
  tokens: [],
  isFetchingTokens: false,
  isCreatingTokens: false,
  apiKeys: [],
  isFetchingAPIKeys: false,
  isCreatingAPIKey: false,
  isUpdatingAPIKey: false,
  apiKeyUpdated: false,
  isRevokingAPIStatus: {},
  creationError: null,
  error: null,
}

export function apiKeyReducer(state = initialState, action) {
  switch (action.type) {
    case `${FETCH_ALL_TOKENS}_PENDING`: {
      return {
        ...state,
        isFetchingTokens: true,
        error: null,
      }
    }

    case `${FETCH_ALL_TOKENS}_FULFILLED`: {
      return {
        ...state,
        isFetchingTokens: false,
        tokens: action.payload.data,
        error: null,
      }
    }

    case `${FETCH_ALL_TOKENS}_REJECTED`: {
      return {
        ...state,
        isFetchingTokens: false,
        error: action.payload,
      }
    }

    case `${FETCH_API_KEYS}_PENDING`: {
      return {
        ...state,
        isFetchingAPIKeys: true,
        error: null,
      }
    }

    case `${FETCH_API_KEYS}_FULFILLED`: {
      return {
        ...state,
        isFetchingAPIKeys: false,
        apiKeys: action.payload.data,
        error: null,
      }
    }

    case `${FETCH_API_KEYS}_REJECTED`: {
      return {
        ...state,
        isFetchingAPIKeys: false,
        error: action.payload,
      }
    }

    case `${FETCH_API_KEY}_PENDING`: {
      return {
        ...state,
        isFetchingAPIKey: true,
        error: null,
      }
    }

    case `${FETCH_API_KEY}_FULFILLED`: {
      const apiKey = action.payload.data
      const apiKeys = [...state.apiKeys]

      const index = apiKeys.findIndex(a => a.id === apiKey.id)
      apiKeys[index] = apiKey
      return {
        ...state,
        isFetchingAPIKey: false,
        apiKeys,
        error: null,
      }
    }

    case `${FETCH_API_KEY}_REJECTED`: {
      return {
        ...state,
        isFetchingAPIKey: false,
        error: action.payload,
      }
    }

    case `${REVOKE_API_KEY}_PENDING`: {
      const { keyId } = action.meta

      const isRevokingAPIStatus = { ...state.isRevokingAPIStatus }
      isRevokingAPIStatus[keyId] = true

      return {
        ...state,
        isRevokingAPIStatus,
        error: null,
      }
    }

    case `${REVOKE_API_KEY}_FULFILLED`: {
      const { keyId } = action.meta

      const isRevokingAPIStatus = { ...state.isRevokingAPIStatus }
      isRevokingAPIStatus[keyId] = false

      const apiKeys = state.apiKeys.filter(key => key.id !== keyId)

      return {
        ...state,
        isRevokingAPIStatus,
        apiKeys,
        error: null,
      }
    }

    case `${REVOKE_API_KEY}_REJECTED`: {
      const { keyId } = action.meta

      const isRevokingAPIStatus = { ...state.isRevokingAPIStatus }
      isRevokingAPIStatus[keyId] = false

      return {
        ...state,
        isRevokingAPIStatus,
        error: action.payload,
      }
    }

    case `${CREATE_API_KEY}_PENDING`: {
      return {
        ...state,
        isCreatingAPIKey: true,
        creationError: null,
      }
    }

    case `${CREATE_API_KEY}_FULFILLED`: {
      return {
        ...state,
        isCreatingAPIKey: false,
        apiKeys: [...state.apiKeys, action.payload.data],
        creationError: null,
      }
    }

    case `${CREATE_API_KEY}_REJECTED`: {
      return {
        ...state,
        isCreatingAPIKey: false,
        creationError: action.payload.response.data.message,
      }
    }

    case `${UPDATE_API_KEY}_PENDING`: {
      return {
        ...state,
        isUpdatingAPIKey: true,
        apiKeyUpdated: false,
        creationError: null,
      }
    }

    case `${UPDATE_API_KEY}_FULFILLED`: {
      const { id, name, tokenExpirationDays } = action.meta
      const apiKeys = [...state.apiKeys]

      const index = apiKeys.findIndex(a => a.id === id)
      apiKeys[index] = {
        ...apiKeys[index],
        id,
        name,
        tokenExpirationDays,
      }

      return {
        ...state,
        isUpdatingAPIKey: false,
        apiKeys,
        apiKeyUpdated: true,
        creationError: null,
      }
    }

    case `${UPDATE_API_KEY}_REJECTED`: {
      return {
        ...state,
        isUpdatingAPIKey: false,
        apiKeyUpdated: false,
        creationError: action.payload.response.data.message,
      }
    }

    default:
      return { ...state }
  }
}
