import React, { Suspense } from 'react'

import Spinner from 'britive-ui-components/core/components/Spinner'
import { ChakraProvider } from '@chakra-ui/react'
import theme from '../../theme'
const MyAccess = React.lazy(() => import('./MyAccessRouter'))

const MyAccessLoader = props => {
  return (
    <Suspense fallback={<Spinner size="2x" />}>
      <ChakraProvider theme={theme}>
        <MyAccess {...props} />
      </ChakraProvider>
    </Suspense>
  )
}

export default MyAccessLoader
