import React, { memo, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import isEmpty from 'lodash/isEmpty'

import PapAssociations from './PapAssociations'

import {
  fetchScopesForPap,
  updatePapResourcesScope,
  updatePapScope,
  resetPapPermission,
} from 'action_creators/pap'
import { updateMessageModal } from 'action_creators/message_modal'

import {
  getSelectedApplicationRootId,
  getSelectedApplicationRoot,
  getSelectedApplicationTemplate,
} from 'selectors/application_root'
import {
  getFetchingEnvironments,
  getApplicationEnvironments,
  getSelectedApplicationEnvironmentGroups,
  getSelectedApplicationRootGroupId,
  getSelectedApplicationEnvironments,
} from 'selectors/environment'
import { getFetchingPapScopes, getUpdatingScope } from 'selectors/pap'
import {
  getSelectedPapScope,
  getSelectedPapGroupScope,
  getSelectedPapResourceScope,
  getSelectedPapId,
  getSelectedPapPermissions,
} from 'routes/admin/state/selectors'
import toast from 'utils/toast'
import messages from 'constants/messages'
import { fetchAllowedScopesForAccessRequest } from 'action_creators/access-request'
import './index.scss'

function PapAssociationsContainer(props) {
  const [isEditing, setIsEditing] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [groupFormScope, setGroupFormScope] = useState({})
  const [resourceFormScope, setResourceFormScope] = useState({})
  const [activeTab, setActiveTab] = useState(null)

  useEffect(() => {
    props.requestAccess &&
      props.dispatch(fetchAllowedScopesForAccessRequest(props.appId))
    props.dispatch(fetchScopesForPap({ papId: props.papId, requestAccess }))
  }, [props.papId])

  useEffect(() => {
    updateGroupFormScope(props.papGroupScope)
  }, [props.papGroupScope])

  const updateGroupFormScope = newScope => {
    setGroupFormScope({ ...newScope })
  }

  const updateScope = newScope => {
    setGroupFormScope(newScope)
  }

  const saveScope = async updatedGroupScope => {
    updateScope(updatedGroupScope)
    const { appId, dispatch, papId } = props
    const nonResourceScope = {}

    setIsSaving(true)

    Object.keys(updatedGroupScope).forEach(key => {
      const scope = updatedGroupScope[key]

      if (scope.type !== 'ApplicationResource') {
        nonResourceScope[key] = updatedGroupScope[key]
      }
    })

    let nonGroupScope

    const supportsResources = app.catalogApplication.supportsResources

    if (supportsResources) {
      nonGroupScope = {}

      Object.keys(resourceFormScope).forEach(key => {
        const scope = resourceFormScope[key]

        if (scope.type === 'ApplicationResource') {
          nonGroupScope[key] = resourceFormScope[key]
        }
      })
    }

    let scope = { ...updatedGroupScope }

    if (supportsResources) {
      scope = { ...scope, ...resourceFormScope }
    }

    if (isEmpty(scope)) {
      dispatch(
        updateMessageModal({
          body:
            'This profile has no associations. Please select at least one association.',
        })
      )

      setIsSaving(false)

      return
    }

    const createAssociations = window.location.pathname.includes(
      '/details/associations/create'
    )

    try {
      if (supportsResources && !requestAccess) {
        await dispatch(
          updatePapResourcesScope({ appId, papId, scope: nonGroupScope })
        )
      }

      await dispatch(updatePapScope(appId, papId, nonResourceScope, requestAccess))

      // Reset the redux permission data after save, if the user is creating a profile.
      // This is done to handle the PAB-15106. Additional changes in PapAssociations.js
      if (createAssociations && !isEmpty(props.permissions)) {
        dispatch(resetPapPermission(props.papId))
      }

      toggleEditState()

      toast({
        title: messages.GENERIC_SAVE_SUCCESS,
        type: 'success',
        time: 'normal',
        id: `associations-${papId}`,
      })
    } catch (error) {
      dispatch(updateMessageModal({ body: error.response.data.message }))
      throw new Error(error)
    } finally {
      setIsSaving(false)
    }
  }

  const cancelEdit = () => {
    setGroupFormScope(props.papScope)
    setResourceFormScope(props.papResourceScope)
    toggleEditState()
  }

  const toggleEditState = () => {
    setIsEditing(!isEditing)
  }

  const selectTab = activeTab => {
    setActiveTab(activeTab)
  }

  const isRootGroupSelected = () => {
    const { rootGroupId } = props

    return Object.values(groupFormScope).some(scope => scope.value === rootGroupId)
  }

  const {
    app,
    papId,
    maxHeight,
    updatingScope,
    fetchingPapScopes,
    thisAppManage,
    rootGroupId,
    applicationRoot,
    applicationTemplate,
    environmentGroups,
    environments,
    isFetchingEnvironments,
    environmentData,
    requestAccess,
    allowedScopes,
    permissions,
  } = props
  const { supportsResources, allowAssociationsInProfiles } = app.catalogApplication

  return (
    <PapAssociations
      activeTab={activeTab}
      cancelEdit={cancelEdit}
      isEditing={isEditing}
      isSaving={isSaving}
      maxHeight={maxHeight}
      papId={papId}
      papGroupScope={groupFormScope}
      isRootGroupSelected={isRootGroupSelected()}
      papResourceScope={resourceFormScope}
      saveScope={saveScope}
      selectTab={selectTab}
      setResourceFormScope={setResourceFormScope}
      supportsResources={supportsResources}
      toggleEditState={toggleEditState}
      fetchingPapScopes={fetchingPapScopes}
      updateScope={updateScope}
      updatingScope={updatingScope}
      allowAssociationsInProfiles={allowAssociationsInProfiles}
      thisAppManage={thisAppManage}
      rootGroupId={rootGroupId}
      applicationRoot={applicationRoot}
      applicationTemplate={applicationTemplate}
      environmentGroups={environmentGroups}
      environments={environments}
      isFetchingEnvironments={isFetchingEnvironments}
      environmentData={environmentData}
      requestAccess={requestAccess}
      allowedScopes={allowedScopes}
      permissions={permissions}
    />
  )
}

const mapStateToProps = state => {
  return {
    appId: getSelectedApplicationRootId(state),
    app: getSelectedApplicationRoot(state),
    papId: getSelectedPapId(state),
    papScope: getSelectedPapScope(state),
    papGroupScope: getSelectedPapGroupScope(state),
    papResourceScope: getSelectedPapResourceScope(state),
    rootGroupId: getSelectedApplicationRootGroupId(state),
    updatingScope: getUpdatingScope(state),
    fetchingPapScopes: getFetchingPapScopes(state),
    applicationRoot: getSelectedApplicationRoot(state),
    applicationTemplate: getSelectedApplicationTemplate(state),
    environmentGroups: getSelectedApplicationEnvironmentGroups(state),
    environments: getApplicationEnvironments(state),
    isFetchingEnvironments: getFetchingEnvironments(state),
    environmentData: getSelectedApplicationEnvironments(state),
    allowedScopes: state.accessRequestReducer.allowedScopesForAccessRequest,
    permissions: getSelectedPapPermissions(state),
  }
}

export default connect(mapStateToProps)(memo(PapAssociationsContainer))
