export const simpleNormalizeData = (data, idAttribute) => {
  let returnData = {}
  data.forEach(item => {
    returnData[item[idAttribute]] = item
  })
  return returnData
}

/**
 * This creates a map which lets us do this :
 *
 * const peopleMap = arrayToMap(peopleArray, "id")
 *
 * const person = peopleMap.has(888) ? peopleMap.get(888) : peopleMap.get(101)
 *
 * In addition to `has` and `get` Maps also has `forEach`
 *
 */
export const arrayToMap = (array, keyField) =>
  new Map(array.map(item => [item[keyField], item]))

/**
 * This method is to parse the string template with dynamic values
 * @param strTemplate :string - string template e.g 'User name is {{displayname}}'
 * @param values      :object - object containing values e.g { displayname: 'john doe' }
 * @return            :string - parsed string e.g 'User name is john doe'
 */
export const stringTemplateParser = (strTemplate, values) => {
  const templateMatcher = /{{\s?([^{}\s]*)\s?}}/g
  return strTemplate.replace(templateMatcher, (_, key) => values[key] || '')
}
