import { preFetchV2 } from 'utils/do_fetch'

export function getApps({ extended, requestAccess }) {
  let path = requestAccess ? '/profile-requests/apps' : '/apps'

  if (extended) {
    path += '?view=extended'
  }

  return preFetchV2({
    name: 'getApps',
    path,
    method: 'get',
  })
}
