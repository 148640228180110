import { fetchIfLoggedIn } from '../utils.js'
import * as actions from 'actions'

export const registerUserMFA = factor => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.REGISTER_USER_MFA,
      method: 'post',
      path: `/mfa/register/${factor}`,
      postBody: {
        action: 'GENERATE_SECRET',
      },
    })
  )
}

export const validateUserMFARegistration = (factor, otp) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.VALIDATE_USER_MFA,
      method: 'post',
      path: `/mfa/register/${factor}`,
      postBody: {
        action: 'VALIDATE_OTP',
        otp,
      },
    })
  )
}

export const authenticateStepUpMFA = (factor, otp, entityId) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.AUTHENTICATE_STEP_UP_MFA,
      method: 'post',
      path: `/step-up/authenticate/${factor}`,
      meta: { entityId },
      postBody: {
        otp,
      },
    })
  )
}

export const resetValidateUserMFARegistration = () => {
  return {
    type: `${actions.VALIDATE_USER_MFA}_RESET`,
  }
}
