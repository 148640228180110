import React, { memo, Fragment } from 'react'
import styled from 'styled-components'
import startCase from 'lodash/startCase'
import pluralize from 'pluralize'

import Drawer from 'components/Drawer'

import { getHeaderSpacing } from 'utils/elementMeasurement'

const Heading = styled.h5`
  border-bottom: 2px solid var(--brand);
  padding: 5px 0;
  margin-right: 10px;
`

const Icon = styled.i`
  color: var(--medium-purple);
`

const DataText = styled.div`
  ${props => (props.indent ? 'margin-left: 12px;' : '')}

  .dataLabel {
    font-weight: ${props => (props.isMain ? 600 : 400)};
  }
`

const styles = {
  summaryWrapper: {
    marginBottom: 16,
  },
}

function DataLine({ isMain, data, indent }) {
  return (
    <DataText indent={indent} isMain={isMain}>
      <span className="dataLabel">{data.label}: </span>
      <span>{data.value}</span>
    </DataText>
  )
}

function SummarySection({ mainData, childData, indentChildren }) {
  return (
    <div style={styles.summaryWrapper}>
      <DataLine isMain data={mainData} />

      {childData.map(data => {
        return <DataLine key={data.label} data={data} indent={indentChildren} />
      })}
    </div>
  )
}

function ScanStatusDrawer({ drawerData, toggleDrawer }) {
  const isOpen = drawerData

  if (!isOpen) {
    return null
  }

  const {
    totalAccountsCollected,
    newAccountsDetected,
    accountsDeleted,
    accountsMapped,
    accountsUnresolved,
    totalPermissionsCollected,
    newPermissionsDetected,
    permissionsDeleted,
    totalGroupsCollected,
    newGroupsDetected,
    groupsDeleted,
    changesToAccounts,
    permissionsAddedToAccounts,
    permissionsRemovedFromAccounts,
    groupsAddedToAccounts,
    groupsRemovedFromAccounts,
    changesToPermissions,
    accountsAddedToPermissions,
    accountsRemovedFromPermissions,
    groupsAddedToPermissions,
    groupsRemovedFromPermissions,
    permissionDefinitionsUpdated,
    changesToGroups,
    permissionsAddedToGroups,
    permissionsRemovedFromGroups,
    accountsAddedToGroups,
    accountsRemovedFromGroups,
    groupsAddedToGroups,
    groupsRemovedFromGroups,
    scanType,
    orgScanDataSummary,
  } = drawerData

  return (
    <Drawer
      isOpen={isOpen}
      top={`${getHeaderSpacing()}px`}
      toggleDrawer={() => toggleDrawer(null)}
    >
      <Heading>
        <Icon className="fas fa-list-alt" /> Scan Data Summary
      </Heading>
      {scanType === 'Organization' ? (
        <Fragment>
          {Object.keys(orgScanDataSummary).map(d => {
            const collected = orgScanDataSummary[d].collected
            delete orgScanDataSummary[d].collected
            return (
              <SummarySection
                key={d}
                mainData={{
                  label: startCase(pluralize(d)),
                  value: collected,
                }}
                childData={Object.keys(orgScanDataSummary[d]).map(o => ({
                  label: startCase(o),
                  value: orgScanDataSummary[d][o].length,
                }))}
              />
            )
          })}
        </Fragment>
      ) : (
        <Fragment>
          <SummarySection
            mainData={{
              label: 'Accounts',
              value: totalAccountsCollected,
            }}
            childData={[
              { label: 'New', value: newAccountsDetected },
              { label: 'Deleted', value: accountsDeleted },
              { label: 'Mapped', value: accountsMapped },
              { label: 'Unresolved', value: accountsUnresolved },
            ]}
          />

          <SummarySection
            mainData={{
              label: 'Permissions',
              value: totalPermissionsCollected,
            }}
            childData={[
              { label: 'New', value: newPermissionsDetected },
              { label: 'Deleted', value: permissionsDeleted },
            ]}
          />

          <SummarySection
            mainData={{
              label: 'Groups',
              value: totalGroupsCollected,
            }}
            childData={[
              { label: 'New', value: newGroupsDetected },
              { label: 'Deleted', value: groupsDeleted },
            ]}
          />

          <SummarySection
            mainData={{
              label: 'Changes to Accounts',
              value: changesToAccounts,
            }}
            childData={[
              { label: 'Permissions added', value: permissionsAddedToAccounts },
              {
                label: 'Permissions removed',
                value: permissionsRemovedFromAccounts,
              },
              { label: 'Groups added', value: groupsAddedToAccounts },
              { label: 'Groups removed', value: groupsRemovedFromAccounts },
            ]}
          />

          <SummarySection
            mainData={{
              label: 'Changes to Permissions',
              value: changesToPermissions,
            }}
            childData={[
              { label: 'Accounts added', value: accountsAddedToPermissions },
              { label: 'Accounts removed', value: accountsRemovedFromPermissions },
              { label: 'Groups added', value: groupsAddedToPermissions },
              { label: 'Groups removed', value: groupsRemovedFromPermissions },
              {
                label: 'Permissions definitions updated',
                value: permissionDefinitionsUpdated,
              },
            ]}
          />

          <SummarySection
            mainData={{
              label: 'Changes to Groups',
              value: changesToGroups,
            }}
            childData={[
              { label: 'Permissions added', value: permissionsAddedToGroups },
              { label: 'Permissions removed', value: permissionsRemovedFromGroups },
              { label: 'Accounts added', value: accountsAddedToGroups },
              { label: 'Accounts removed', value: accountsRemovedFromGroups },
              { label: 'Groups added', value: groupsAddedToGroups },
              { label: 'Groups removed', value: groupsRemovedFromGroups },
            ]}
          />
        </Fragment>
      )}
    </Drawer>
  )
}

export default memo(ScanStatusDrawer)
