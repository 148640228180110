import React from 'react'
import Typography from 'britive-design-system/core/components/typography'
import ModalPopup from 'britive-design-system/core/components/modal-popup'
import cancelRequestIcon from '../../../../static/images/cancelIcon.svg'
import { classes, papConsumer } from './constants'
import './RequestModal.scss'
import { convertTimeToCurrentTimezone } from '../../utils'

export const RequestModal = ({
  requestDetails,
  onModalClose,
  consumer,
  setOpenWithdrawDialog,
  setToBeWithdrawnRequestId,
  translatedStrings,
}) => {
  const {
    requestId,
    createdAt,
    approvers,
    resource,
    status,
    justification,
  } = requestDetails
  const requiredRequestModalActionButtons = [
    {
      text: translatedStrings.cancelText,
      variant: 'secondary',
      onClick: onModalClose,
      size: 'large',
    },
  ]

  status === translatedStrings.approvalPendingText &&
    requiredRequestModalActionButtons.unshift({
      text: translatedStrings.withdrawRequest,
      variant: 'primary',
      onClick: () => {
        if (status === translatedStrings.approvalPendingText) {
          setToBeWithdrawnRequestId(requestId)
          setOpenWithdrawDialog(true)
        }
      },
      size: 'large',
      leftSVGIcon: cancelRequestIcon,
      disabled: status !== translatedStrings.approvalPendingText,
    })

  /**
   * This is an example of how to use the PageHeader component.
   * Need to be worked on while building the my requests page
   * 
    import { PageHeader } from '../../components/page-header'
    import { headerDetailsType } from '../../components/page-header/constants'
    
    <PageHeader
      title={"Profile title"}
      type={headerDetailsType.REQUESTS}
      requestStatus={translatedStrings.status}
      description=""
      onWithdraw={() => {}}
    />
   */

  return (
    <ModalPopup
      width={512}
      title={
        consumer === papConsumer
          ? translatedStrings.profileCheckoutRequest
          : translatedStrings.secretViewRequest
      }
      buttons={requiredRequestModalActionButtons}
      onCancel={onModalClose}
    >
      <div className={classes.profileCheckoutRequestLayout}>
        <div className={classes.profileCheckoutRequestSpacing}>
          <div className={classes.profileCheckoutRequestSpacing}>
            <Typography variant="heading4">{resource}</Typography>
          </div>
        </div>
        <div className={classes.profileCheckoutRequestSpacing}>
          <div className={classes.profileCheckoutRequestSpacing}>
            <div className={classes.profileCheckoutRequestLabel}>
              <Typography variant="label2">{translatedStrings.requestOn}</Typography>
            </div>
            <div className={classes.profileCheckoutRequestValue}>
              <Typography variant="label1">
                {convertTimeToCurrentTimezone(createdAt)}
              </Typography>
            </div>
          </div>
        </div>

        <div className={classes.profileCheckoutRequestSpacing}>
          <div className={classes.profileCheckoutRequestLabel}>
            <Typography variant="label2">{translatedStrings.approvers}</Typography>
          </div>
          <div className={classes.profileCheckoutRequestValue}>
            <Typography variant="label1">{approvers}</Typography>
          </div>
        </div>
        <div className={classes.profileCheckoutRequestSpacing}>
          <div className={classes.profileCheckoutRequestLabel}>
            <Typography variant="label2">{translatedStrings.status}</Typography>
          </div>
          <div className={classes.profileCheckoutRequestValue}>
            <Typography variant="label1">{status}</Typography>
          </div>
        </div>
        <div className={classes.profileCheckoutRequestSpacing}>
          <div className={classes.profileCheckoutRequestLabel}>
            <Typography variant="label2">
              {translatedStrings.justification}
            </Typography>
          </div>
          <div className={classes.profileCheckoutRequestValue}>
            <Typography variant="label1">{justification}</Typography>
          </div>
        </div>
      </div>
    </ModalPopup>
  )
}
