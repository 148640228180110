import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { fetchUserAttributes } from 'action_creators/user'

const UserAttributeSchema = ({
  dispatch,
  name,
  label,
  disabled,
  value,
  onChange,
}) => {
  const [userAttributes, setUserAttributes] = useState([])

  const fetchAttributes = async () => {
    const response = await dispatch(fetchUserAttributes('multiValued eq false'))

    setUserAttributes(response.value.data)
  }
  useEffect(() => {
    fetchAttributes()
  }, [])

  return (
    <div className="form-group col-5">
      <label htmlFor="sessionAttributeType">{label}</label>
      <select
        value={value}
        onChange={onChange}
        className="form-control"
        id={name}
        name={name}
        disabled={disabled}
      >
        <option value="">None</option>
        {userAttributes.map(userAttribute => (
          <option key={userAttribute.id} value={userAttribute.id}>
            {userAttribute.name}
          </option>
        ))}
      </select>
    </div>
  )
}

export default connect()(UserAttributeSchema)
