import React, { memo, useEffect, useState } from 'react'
import FontAwesome from 'react-fontawesome'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: absolute;
  z-index: 1;
  background: white;
  border-radius: 4px;
  box-shadow: 0 0 12px 2px #cddfed;
  top: -28px;
  right: 0;
  white-space: nowrap;
  padding: 2px 4px;
`

const Icon = styled(FontAwesome)`
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  margin-left: 4px;
`

const styles = {
  display: {
    width: 90,
    display: 'inline-flex',
    textAlign: 'left',
  },
}

const HEADER_HEIGHT = 36
const ROW_LEFT_PADDING = 8

function SearchNavigation({ searchResultsInfo, updateSearchResultsInfo, tableRef }) {
  const [searchIndex, setSearchIndex] = useState(0)

  useEffect(() => {
    const { searchCount } = searchResultsInfo
    const newSearchIndex = searchCount > 0 ? 1 : 0
    setSearchIndex(newSearchIndex)
    if (newSearchIndex) {
      focusSearchResult({ targetIndex: 1, oldTargetIndex: null })
    }
  }, [searchResultsInfo])

  function onNextClick() {
    const { searchCount } = searchResultsInfo
    const newSearchIndex = searchCount > searchIndex ? searchIndex + 1 : 1

    focusSearchResult({ targetIndex: newSearchIndex, oldTargetIndex: searchIndex })
  }

  function onPreviousClick() {
    const { searchCount } = searchResultsInfo
    const newSearchIndex = searchIndex === 1 ? searchCount : searchIndex - 1

    focusSearchResult({ targetIndex: newSearchIndex, oldTargetIndex: searchIndex })
  }

  function focusSearchResult({ targetIndex, oldTargetIndex }) {
    setSearchIndex(targetIndex)

    const tableScrollEl = tableRef.current.getElementsByClassName('rt-table')[0]

    if (tableScrollEl) {
      const marks = tableScrollEl.getElementsByTagName('mark')
      const nextEl = marks[targetIndex - 1]
      const oldEl = oldTargetIndex && marks[oldTargetIndex - 1]

      if (oldEl) {
        oldEl.style.backgroundColor = '#ff0'
      }

      if (nextEl) {
        nextEl.style.backgroundColor = '#ff8000'

        const bodyRect = tableScrollEl.getBoundingClientRect()
        const elemRect = nextEl.getBoundingClientRect()
        const offsetY = elemRect.top - bodyRect.top

        const fixedColumsWidth = Array.prototype.reduce.call(
          tableScrollEl.getElementsByClassName('rthfc-th-fixed'),
          (acc, el) => parseInt(el.offsetWidth) + acc,
          0
        )

        const offsetX = elemRect.left - bodyRect.left

        const inFixedColumns = nextEl.closest
          ? nextEl.closest('.rthfc-td-fixed-left')
          : offsetX > 0 &&
            offsetX + tableScrollEl.scrollLeft <
              fixedColumsWidth - tableScrollEl.scrollLeft

        const isYVisible =
          bodyRect.height - HEADER_HEIGHT > offsetY && offsetY > HEADER_HEIGHT

        const isXVisible =
          bodyRect.width - ROW_LEFT_PADDING > offsetX &&
          offsetX > ROW_LEFT_PADDING + fixedColumsWidth

        if (!isYVisible) {
          tableScrollEl.scrollTop = tableScrollEl.scrollTop + offsetY - HEADER_HEIGHT
        }

        if (!isXVisible && !inFixedColumns) {
          tableScrollEl.scrollLeft =
            tableScrollEl.scrollLeft + offsetX - ROW_LEFT_PADDING - fixedColumsWidth
        }
      }
    }
  }

  function closeSearchNavigation() {
    setSearchIndex(0)
    updateSearchResultsInfo({
      isNavigationOpen: false,
    })
  }

  function isThereResults() {
    return searchCount > 0
  }

  const { searchCount } = searchResultsInfo
  const hasResults = isThereResults()
  const { display } = styles

  return (
    <Wrapper>
      <span style={display}>
        {searchIndex} / {searchCount}
      </span>

      <Icon
        disabled={!hasResults}
        onClick={hasResults ? onPreviousClick : undefined}
        name="chevron-up"
      />

      <Icon
        disabled={!hasResults}
        onClick={hasResults ? onNextClick : undefined}
        name="chevron-down"
      />

      <Icon onClick={closeSearchNavigation} name="times" />
    </Wrapper>
  )
}

export default memo(SearchNavigation)
