import React, { memo, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'

import ScanDetails from './ScanDetails'

import {
  getSelectedApplicationRootId,
  getSelectedApplicationRoot,
} from 'selectors/application_root'

import {
  getSelectedApplicationEnvironments,
  getSelectedApplicationEnvironmentGroups,
} from 'selectors/environment'
import isBritiveApp from 'utils/application/isBritiveApp'

export const PERMISSIONS = 'Permissions'
export const GROUPS = 'Groups'
export const ACCOUNTS = 'Accounts'

function ScanDetailsContainer(props) {
  const catalogApplicationName = props?.app?.catalogApplication?.name
  const currentActiveTab = !isBritiveApp(catalogApplicationName)
    ? PERMISSIONS
    : !['SalesForce', 'Snowflake', 'Snowflake Standalone'].includes(
        props?.app?.catalogAppName
      )
    ? GROUPS
    : ACCOUNTS
  const [activeTab, setActiveTab] = useState(currentActiveTab)
  const [drawerDetails, setDrawerDetails] = useState(null)

  useEffect(() => {
    const {
      app,
      setBreadcrumbTrail,
      match,
      environmentData,
      environmentGroups,
      location,
    } = props

    const { envId } = match.params
    const { catalogAppDisplayName: appName } = app
    const environment = environmentData[envId]
    const group = get(environmentGroups, [envId])

    const name = get(environment, 'catalogAppDisplayName') || get(group, 'name', '')

    const backPath = get(location, 'state.fromDashboard')
      ? { title: `${appName} Overview`, path: 'overview' }
      : { title: `${appName} Scan Summary`, path: 'scans' }

    setBreadcrumbTrail([backPath, { title: `${name} Last Scan Details` }])
  }, [])

  function selectTab(props) {
    closeDrawer()
    setActiveTab(props)
  }

  function selectDrawer(drawerDetails) {
    let newDrawerDetails = drawerDetails

    if (drawerDetails.appPermissionId && Math.random() > 0) {
      newDrawerDetails = {
        ...drawerDetails,
        isDefinitionChanges: true,
      }
    }

    setDrawerDetails(newDrawerDetails)
  }

  function closeDrawer() {
    setDrawerDetails(null)
  }

  const { appId, appHeaderHeight, match, app } = props

  return (
    <ScanDetails
      appId={appId}
      appHeaderHeight={appHeaderHeight}
      envId={match.params.envId}
      activeTab={activeTab}
      selectTab={selectTab}
      drawerDetails={drawerDetails}
      closeDrawer={closeDrawer}
      setDrawerDetails={selectDrawer}
      selectedApp={app}
    />
  )
}

const mapStateToProps = state => {
  return {
    app: getSelectedApplicationRoot(state),
    appId: getSelectedApplicationRootId(state),
    environmentGroups: getSelectedApplicationEnvironmentGroups(state),
    environmentData: getSelectedApplicationEnvironments(state),
  }
}

export default connect(mapStateToProps)(memo(ScanDetailsContainer))
