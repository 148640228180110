import React, { Fragment, memo } from 'react'
import { withRouter } from 'react-router-dom'

import Table from 'components/table/TableV2/Table'

function Resources(props) {
  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
      fixed: 'left',
      width: 300,
    },
    {
      Header: 'Description',
      accessor: 'description',
      minWidth: 300,
    },
    {
      Header: 'Type',
      accessor: 'type',
      minWidth: 240,
    },
    {
      Header: 'Parent',
      accessor: 'parentName',
      minWidth: 200,
    },
    {
      Header: 'Scan Status',
      accessor: 'scanStatus',
      minWidth: 120,
    },
  ]

  const { selectedAppId, selectedEnvId } = props

  return (
    <Fragment>
      <Table
        columns={columns}
        identifier="id"
        dataUrl={`/apps/${selectedAppId}/environments/${selectedEnvId}/resources`}
        emptyTableMessage={'No Resources were found.'}
        dropdownFilters={[
          {
            label: 'Scan Status',
            value: 'scanStatus',
            options: [
              { label: 'New', value: 'New' },
              { label: 'Updated', value: 'Updated' },
              { label: 'Unchanged', value: 'Unchanged' },
              { label: 'Deleted', value: 'Deleted' },
            ],
          },
        ]}
        margin={40}
        noUrlQueryParams
      />
    </Fragment>
  )
}

export default memo(withRouter(Resources))
