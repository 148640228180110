import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { preFetch } from 'utils/do_fetch'
import TableSection from '../tableSection'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import useMaxTableHeight from 'hooks/useMaxTableHeight'

export default ({
  columns,
  dataUrl,
  params = {},
  emptyTableMessage,
  clearCheckedItems,
  currentActionMode,
  actionFilter = {},
  renderCheckbox,
  showCheckBox = false,
  checkBoxFilters = [],
  dropdownFilters = [],
  spinner,
  preDataFetch,
  identifier,
  showRefreshButton = false,
  setDataList,
  setDataOverride,
  margin = 20,
  refresh = 0,
  rowOnClickHandler,
  maxHeight,
  columnNamesFilter,
  getApiParams,
  noUrlQueryParams = false,
  initialColumn,
}) => {
  const maxTableHeight = useMaxTableHeight({
    heights: [170, margin],
    minHeight: 100,
  })
  const [data, setData] = useState(null)
  const [softRefreshing, setSoftRefreshing] = useState(false)
  const [filters, setFilters] = useState(null)
  const [checkBoxFilter, setCheckBoxFilter] = useState(null)
  const [dropdownFilter, setDropdownFilter] = useState(null)
  const [loading, setLoading] = useState(false)
  const [selectedDropdownFilter, setSelectedDropdownFilter] = useState(null)
  const [selectedCheckboxFilters, setSelectedCheckboxFilters] = useState(null)
  const [actionFilterProperty, setActionFilterProperty] = useState(null)
  const [finalParamsForModal, setFinalParamsForModal] = useState({})
  const history = useHistory()
  const locationData = useLocation()

  const setFinalParams = noUrlQueryParams
    ? setFinalParamsForModal
    : finalParamsArg => {
        history.replace({
          search: queryString.stringify(finalParamsArg),
          state: {},
        })
      }

  const finalParams = noUrlQueryParams
    ? finalParamsForModal
    : queryString.parse(locationData.search, {
        parseNumbers: true,
        parseBooleans: true,
      })

  const getData = async () => {
    setLoading(true)
    try {
      if (typeof getApiParams === 'function') {
        getApiParams(locationData.search)
      }

      const response = await preFetch({
        path: `${dataUrl}?${Object.keys({ ...finalParams, ...params })
          .filter(param => finalParams[param] !== null && finalParams[param] !== '')
          .map(param => `${param}=${finalParams[param]}`)
          .join('&')}`,
        method: 'get',
      })

      if (response?.data?.data) {
        setData(response.data)
        setDataList && setDataList(response.data.data)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
      setSoftRefreshing(false)
    }
  }

  const softRefresh = () => {
    setSoftRefreshing(true)
    getData()
  }

  const init = () => {
    preDataFetch && preDataFetch()
    setFinalParams({
      ...params,
      page: 0,
      size: finalParams?.size || 20,
    })
    Object.keys(finalParams).length === 2 + Object.keys(params).length &&
      finalParams.page === 0 &&
      !!finalParams.size &&
      softRefresh()
  }

  //preparing request parameters
  useEffect(() => {
    if (!isEmpty(finalParams) && !isEmpty(filters)) {
      if (actionFilterProperty && finalParams[actionFilterProperty]) {
        setActionFilterProperty(null)
      }
      let filter = Object.keys(filters)
        .filter(f => filters[f] !== null && filters[f] !== '')
        .map(f => `${f} eq ${filters[f]}`)
        .join(' and ')

      setFinalParams({
        ...finalParams,
        page: 0,
        filter,
      })
    }
  }, [JSON.stringify(filters)])

  useEffect(() => {
    isEmpty(finalParams) ? init() : getData()
  }, [JSON.stringify(finalParams)])

  useEffect(() => {
    if (refresh > 0) {
      preDataFetch && preDataFetch()
      softRefresh()
    }
  }, [refresh])

  useEffect(() => {
    if (!currentActionMode) {
      setFilters({})
      return
    }
    if (actionFilter[currentActionMode]) {
      if (!isEmpty(actionFilter[currentActionMode])) {
        setFilters({
          ...filters,
          ...actionFilter[currentActionMode],
        })
      }
      setActionFilterProperty(Object.keys(actionFilter[currentActionMode])[0])
    } else {
      actionFilterProperty
        ? setFilters({
            ...filters,
            [actionFilterProperty]: null,
          })
        : setSoftRefreshing(true)
    }
  }, [currentActionMode])

  //combining filters
  useEffect(() => {
    setFilters({
      ...checkBoxFilter,
      ...dropdownFilter,
    })
  }, [checkBoxFilter, dropdownFilter])

  useEffect(() => {
    let filter = get(data, 'filter', null)
    if (filter) {
      filter = filter.split(' and ')
      const filterObject = {}
      filter.forEach(f => {
        const eachFilter = f.split(' eq ')
        filterObject[eachFilter[0]] = eachFilter[1]
      })
      const ddFilter = {}
      dropdownFilters.forEach(f => {
        if (filterObject[f.value] && f.value !== actionFilterProperty) {
          ddFilter[f.value] = filterObject[f.value]
        }
      })
      const cbFilter = {}
      checkBoxFilters.forEach(f => {
        if (!isEmpty(filterObject[f.name]) && filterObject[f.name] !== 'false') {
          cbFilter[f.name] = filterObject[f.name]
        }
      })
      setSelectedDropdownFilter(ddFilter)
      setSelectedCheckboxFilters(cbFilter)
    } else {
      setSelectedCheckboxFilters(null)
      setSelectedDropdownFilter(null)
    }
  }, [data])

  const onPageChange = page => {
    setFinalParams(
      {
        ...finalParams,
        page,
      },
      true
    )
  }

  const onPageSizeChange = size => {
    setFinalParams(
      {
        ...finalParams,
        page: 0,
        size,
      },
      true
    )
  }

  const onSortedChange = newSorted => {
    const sort = newSorted.length
      ? newSorted.map(({ id, desc }) => `${id},${desc ? 'desc' : 'asc'}`).join('&')
      : null
    setFinalParams(
      {
        ...finalParams,
        page: 0,
        sort,
      },
      true
    )
  }

  const onSearch = (searchText, columnFilter) => {
    let searchParams = {}
    let existingFinalParams = { ...finalParams }

    // Removing the previous column filter.
    if (columnNamesFilter?.length > 0) {
      columnNamesFilter.forEach(colFilter => {
        if (existingFinalParams[colFilter.value]) {
          delete existingFinalParams[colFilter.value]
        }
      })
    }

    if (columnFilter && searchText) {
      if (existingFinalParams.searchText) {
        delete existingFinalParams.searchText
      }
      searchParams = { [columnFilter]: searchText }
    } else {
      searchParams = { searchText }
    }

    setFinalParams(
      {
        ...existingFinalParams,
        page: 0,
        ...searchParams,
      },
      true
    )
  }

  const onCheckBoxFilterChange = filter => {
    if (filter) {
      setCheckBoxFilter({
        ...checkBoxFilter,
        ...filter,
      })
    }
  }

  const pages = useMemo(
    () => (!isEmpty(data) ? Math.floor((data.count - 1) / data.size + 1) : -1),
    [data]
  )

  const sorted = useMemo(() => {
    const sort = get(data, 'sort', null)
    return sort
      ? [
          {
            id: sort.split(':')[0],
            desc: sort.split(':')[1].includes('DESC'),
          },
        ]
      : []
  }, [data])

  return (
    <TableSection
      {...(showCheckBox && {
        initialColumn: initialColumn || {
          clearInitialColumnStatus: clearCheckedItems,
          InitialColumnComponent: props => renderCheckbox(false, props),
          InitialColumnHeaderComponent: props => renderCheckbox(true, props),
          showInitialColumn: !!currentActionMode,
          initialColumnWidth: 24,
          fixed: 'left',
        },
      })}
      columns={columns}
      loading={loading || softRefreshing || spinner}
      data={get(data, 'data', [])}
      resizable={true}
      page={get(data, 'page', 0)}
      pages={pages}
      pageSize={get(data, 'size', 20)}
      manual
      multiSort={false}
      sorted={sorted}
      identifier={identifier}
      keyValue={identifier}
      emptyTableMessage={emptyTableMessage}
      marginTop={0}
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
      onSortedChange={onSortedChange}
      onSearch={onSearch}
      dropdownFilters={dropdownFilters}
      checkBoxFilters={checkBoxFilters}
      setCheckBoxFilters={onCheckBoxFilterChange}
      setDropdownFilter={setDropdownFilter}
      setDataOverride={setDataOverride}
      rowOnClickHandler={rowOnClickHandler}
      selectedDropdownFilter={selectedDropdownFilter}
      selectedCheckboxFilters={selectedCheckboxFilters}
      sortable
      showPagination
      {...(showRefreshButton && {
        refreshHandler: init,
      })}
      setLoading={setLoading}
      maxHeight={`${maxHeight || maxTableHeight}px`}
      params={params}
      columnNamesFilter={columnNamesFilter}
      finalParams={finalParams}
    />
  )
}
