import React, { useEffect, Fragment } from 'react'
import get from 'lodash/get'
import { getCustomAttributes } from 'action_creators/custom_attribute'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import Button from 'britive-ui-components/core/components/Button'

const Wrapper = styled.div`
  padding: 10px 0;
`

const Table = styled.table`
  background: #fff;
  margin-bottom: 16px;

  tr {
    border-top: 1px solid #f3f3f3;
    border-bottom: 1px solid #f3f3f3;
    border-right: 1px solid #f3f3f3;
  }

  td {
    padding: 10px;
    border-left: 1px solid #f3f3f3;
    min-width: 320px;

    > span {
      width: 100%;
      display: flex;
    }

    &:first-child {
      text-transform: capitalize;
      font-weight: 600;
    }
  }
`

function ViewUserDetails({
  user,
  currentUserId,
  dispatch,
  customAttributes,
  togglePasswordModal,
  toggleMFAModal,
  disableEdit = false,
  toggleEdit,
  userManage,
  toggleInternalUserDeleteMFAModal,
  cognitoLocalUsers,
}) {
  useEffect(() => {
    user.type === 'User' && dispatch(getCustomAttributes())
  }, [])

  return (
    <Wrapper>
      <div>
        <Table>
          <tbody>
            {user.type === 'ServiceIdentity' ? (
              <Fragment>
                <tr>
                  <td>Name</td>
                  <td>{user.name}</td>
                </tr>
                <tr>
                  <td>Description</td>
                  <td>{user.description}</td>
                </tr>
              </Fragment>
            ) : (
              <Fragment>
                <tr>
                  <td>First Name</td>
                  <td>{user.firstName}</td>
                </tr>
                <tr>
                  <td>Last Name</td>
                  <td>{user.lastName}</td>
                </tr>
                <tr>
                  <td>Primary email</td>
                  <td>{user.email}</td>
                </tr>
                <tr>
                  <td>Username</td>
                  <td>{user.username}</td>
                </tr>
                <tr>
                  <td>Mobile number</td>
                  <td>{user.mobile}</td>
                </tr>
                <tr>
                  <td>Phone number</td>
                  <td>{user.phone}</td>
                </tr>
                <tr>
                  <td>Identity Provider</td>
                  <td>{get(user, 'identityProvider.name')}</td>
                </tr>
                {customAttributes.map(
                  customAttribute =>
                    !customAttribute.builtIn && (
                      <tr key={customAttribute.id}>
                        <td>{customAttribute.name}</td>
                        <td>
                          {get(user, 'attributes', []).map(
                            (attribute, index) =>
                              attribute.attributeId === customAttribute.id && (
                                <span key={`${customAttribute.id}${index}`}>
                                  {customAttribute.dataType === 'Boolean'
                                    ? attribute.attributeValue === 'true'
                                      ? 'Yes'
                                      : 'No'
                                    : attribute.attributeValue}
                                </span>
                              )
                          )}
                        </td>
                      </tr>
                    )
                )}
              </Fragment>
            )}
          </tbody>
        </Table>
        {userManage && (
          <Fragment>
            {!disableEdit && (
              <Button
                onClick={toggleEdit}
                style={styles.buttonMargin}
                color="primary"
              >
                Edit
              </Button>
            )}

            {user.type === 'User' && user.userId !== currentUserId && (
              <>
                {user.canChangeOrResetPassword && (
                  <Button
                    onClick={togglePasswordModal}
                    style={styles.buttonMargin}
                    color="primary"
                  >
                    Reset Password
                  </Button>
                )}
                {user.external && (
                  <Button
                    onClick={toggleMFAModal}
                    style={styles.buttonMargin}
                    color="primary"
                  >
                    Delete Step-up Verification Device
                  </Button>
                )}
                {!user.external && (
                  <Button
                    onClick={toggleInternalUserDeleteMFAModal}
                    style={styles.buttonMargin}
                    color="primary"
                  >
                    {!cognitoLocalUsers
                      ? ' Delete MFA Device'
                      : 'Delete MFA Devices'}
                  </Button>
                )}
              </>
            )}
          </Fragment>
        )}
      </div>
    </Wrapper>
  )
}

const styles = {
  buttonMargin: {
    marginRight: 20,
  },
}
const mapStateToProps = state => {
  const { customAttributes, user, features } = state
  return {
    fetching: customAttributes.fetchingCustomAttributes,
    customAttributes: customAttributes.customAttributes,
    currentUserId: user.userId,
    cognitoLocalUsers: features?.featureFlags?.cognitoLocalUsers,
  }
}

const withConnect = connect(mapStateToProps)

export default compose(withConnect, withRouter)(ViewUserDetails)
