import React, { memo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import styled from 'styled-components'
import { Tabs } from 'britive-ui-components/core/components/Tabs'
import PapProfile from './../components/pap-profile'
import useMaxTableHeight from 'hooks/useMaxTableHeight'

import { selectApplicationRootId } from 'action_creators/application_root'
import { createPap } from 'action_creators/pap'

import { getSelectedApplicationRoot } from 'selectors/application_root'
import { getSelectedApplicationRootGroupId } from 'selectors/environment'

import * as routing from 'services/routing'
import { history } from 'store'
import { isAWSIDC } from '../components/pap-profile/utils'

const ENTITY_TYPES = {
  environmentGroupId: 'EnvironmentGroup',
  environmentId: 'Environment',
}

const Wrapper = styled.div`
  padding: 8px;

  .tab-content {
    padding: 0 !important;
  }
`

function CreatePap(props) {
  const cloneProfile = history?.location?.cloneProfile
  const ignoreErrors = history?.location?.ignoreErrors
  const maxHeight = useMaxTableHeight({
    heights: [props.appHeaderHeight, 56],
    minHeight: 100,
  })

  useEffect(() => {
    const { dispatch, appId, selectedApplicationRoot, setBreadcrumbTrail } = props
    const { catalogAppDisplayName: appName } = selectedApplicationRoot

    dispatch(selectApplicationRootId(appId))
    setBreadcrumbTrail([
      { title: `${appName} Profiles`, path: 'profiles' },
      { title: `${cloneProfile ? 'Clone' : 'Create'} Profile` },
    ])
  }, [])

  const onCancel = () => {
    const { appId, requestAccess } = props
    if (requestAccess) {
      routing.requestAccessAppProfilesList({ appId })
    } else {
      routing.appProfiles({ appId })
    }
  }

  const onCreate = async fields => {
    const {
      appId,
      dispatch,
      selectedApplicationRootGroupId,
      requestAccess,
      selectedApplicationRoot,
    } = props
    const cloneProfilePayload = history?.location?.payload || null

    const scope =
      requestAccess || cloneProfilePayload
        ? []
        : [
            {
              type: ENTITY_TYPES.environmentGroupId,
              value: selectedApplicationRootGroupId,
            },
          ]

    const response = await dispatch(
      createPap(
        appId,
        scope,
        fields,
        requestAccess,
        cloneProfilePayload,
        ignoreErrors
      )
    )

    let {
      value: { data },
    } = response

    data = data?.success ? data.success : data

    if (requestAccess) {
      routing.requestAccessProfileDetails({
        appId,
        profileId: data.papId,
        tab: 'associations',
        mode: 'create',
      })
    } else {
      routing.profileDetails({
        appId,
        profileId: data.papId,
        tab: isAWSIDC(selectedApplicationRoot.catalogAppName)
          ? 'permissions'
          : 'associations',
      })
    }
  }

  const { selectedApplicationRoot: app, requestAccess } = props

  if (!app) {
    return null
  }

  return (
    <Wrapper>
      <Tabs>
        {[
          <div key="profile" label="Profile">
            <PapProfile
              createMode
              onCancel={onCancel}
              onCreate={onCreate}
              app={app}
              maxHeight={maxHeight}
              requestAccess={requestAccess}
              profileId={history?.location?.papId}
              isClonedProfile
            />
          </div>,
        ]}
      </Tabs>
    </Wrapper>
  )
}

CreatePap.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
}

const mapStateToProps = state => {
  return {
    selectedApplicationRoot: getSelectedApplicationRoot(state),
    selectedApplicationRootGroupId: getSelectedApplicationRootGroupId(state),
  }
}

const withConnect = connect(mapStateToProps)

export default compose(withConnect)(memo(CreatePap))
