import React, { memo, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import 'sanitize.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'css/index.css'

import MainLayout from './main_layout'

import { setIsInitialAuthing } from 'action_creators/account'
import { getUser } from 'action_creators/user'
import { getIsAuthenticated } from 'utils/authentication'

// TODO: move authentication stuff into own hook/component?
export function Root({ dispatch, location, history, isAuthenticated }) {
  useEffect(() => {
    async function authenticate() {
      dispatch(setIsInitialAuthing(true))

      await dispatch(getUser())
      history.replace({
        pathname: location.pathname,
        search: location.search,
      })

      dispatch(setIsInitialAuthing(false))
    }

    if (isAuthenticated) {
      authenticate()
    }
  }, [])

  return <MainLayout />
}

const mapStateToProps = state => ({
  userFetched: state.user.fetched,
  authInfoFetched: state.account.fetched,
  location: state.router.location,
  isAuthenticated: getIsAuthenticated(),
})

export default connect(mapStateToProps)(withRouter(memo(Root)))
