import React, { useState } from 'react'
import FontAwesome from 'react-fontawesome'
import isEmpty from 'lodash/isEmpty'
import Table from '../admin/ResourcesManager/Table'
import Button from 'britive-design-system/core/components/button'
import statusFormatter from 'utils/statusFormatter'
import Star from 'static/images/star.svg'
import StarSelected from 'static/images/star-selected.svg'
import { PROFILE_STATUS } from 'constants/myProfile'
import PillListTableColumn from 'components/PillListTableColumn'
import ResourcesApprovalModal from './components/ResourcesApprovalModal'
import AccessCell from './columns/AccessCell'
import {
  isProfileAccessIndeterminate,
  getActionButtonText,
  getButtonVariant,
  isApprovalNeeded,
} from './utils'

const CSS_CLASSNAME_PREFIX = 'my-resources'

const ProfileList = ({
  rows = [],
  onCheckout,
  onCheckin,
  onWithdraw,
  onProfileAccess,
  addFavorite,
  removeFavorite,
}) => {
  const [approvalModalData, setApprovalModalData] = useState({})

  const handleActionButtonClick = row => {
    if (
      statusFormatter(row.status) === PROFILE_STATUS.AVAILABLE ||
      statusFormatter(row.status) === PROFILE_STATUS.CHECK_OUT_FAILED ||
      statusFormatter(row.status) === PROFILE_STATUS.CHECK_OUT_TIMED_OUT
    ) {
      onCheckout(row.profileId, row.resourceId)
      return
    }

    if (statusFormatter(row.status) === PROFILE_STATUS.APPROVAL_REQUIRED) {
      setApprovalModalData(row)
      return
    }

    if (statusFormatter(row.status) === PROFILE_STATUS.APPROVAL_PENDING) {
      onWithdraw(row.profileId, row.resourceId)
      return
    }

    onCheckin(row.transactionId)
  }

  const columns = [
    {
      headerName: '',
      width: '45px',
      renderColumn: row => (
        <div
          className={`${CSS_CLASSNAME_PREFIX}-column-favorite`}
          onClick={() => {
            if (row.favoriteId) {
              removeFavorite(row.favoriteId, row.profileId, row.resourceId)
              return
            }

            addFavorite(row.profileId, row.resourceId)
          }}
        >
          <img src={row.favoriteId ? StarSelected : Star} />
        </div>
      ),
    },
    {
      field: 'resourceName',
      headerName: 'Resource Name',
      sortable: true,
    },
    {
      headerName: 'Resource Labels',
      width: '500px',
      renderColumn: row => {
        const pillList = Object.keys(row.resourceLabels || []).map(key => {
          return `${key}: ${(row.resourceLabels[key] || [])?.join(', ')}`
        })

        return (
          <PillListTableColumn
            data={pillList}
            maxWidth={400}
            modalTitle="Resource Labels"
          />
        )
      },
    },
    {
      field: 'profileName',
      headerName: 'Profile Name',
      sortable: true,
    },
    {
      headerName: 'Access',
      width: '100px',
      renderColumn: row => {
        return <AccessCell row={row} onProfileAccess={onProfileAccess} />
      },
    },
    {
      headerName: 'Status',
      width: '200px',
      renderColumn: row => statusFormatter(row.status),
    },
    {
      headerName: 'Action',
      width: '150px',
      renderColumn: row => {
        const isDisabled = isProfileAccessIndeterminate(row.status)
        const approvalRequired = isApprovalNeeded(row.status)
        const buttonLabel = getActionButtonText(row.status)
        const formattedStatus = statusFormatter(row.status)
        return (
          <div className={`${CSS_CLASSNAME_PREFIX}-column-action`}>
            <Button
              disabled={isDisabled}
              name="my-resource-action-button"
              variant={getButtonVariant(buttonLabel)}
              onClick={() => handleActionButtonClick(row)}
            >
              {approvalRequired ? (
                <FontAwesome
                  name={
                    formattedStatus === PROFILE_STATUS.APPROVAL_REQUIRED
                      ? 'user'
                      : 'ban'
                  }
                />
              ) : (
                <FontAwesome
                  name={isDisabled ? 'spinner' : 'code'}
                  spin={isDisabled}
                />
              )}
              &nbsp;&nbsp;
              {buttonLabel}
            </Button>
          </div>
        )
      },
    },
  ]

  return (
    <>
      <Table
        resizableColumns
        columns={columns}
        tableData={rows}
        searchable={false}
        noHeaderBackground
      />
      {!isEmpty(approvalModalData) && (
        <ResourcesApprovalModal
          onClose={() => setApprovalModalData({})}
          profileId={approvalModalData.profileId}
          resourceId={approvalModalData.resourceId}
          resourceName={approvalModalData.resourceName}
        />
      )}
    </>
  )
}

export default ProfileList
