export const fieldsLabel = {
  name: 'Name',
  type: 'Type',
}

export const defaultStateValues = {
  name: {
    value: '',
    error: null,
  },
  type: {
    value: {},
    error: null,
  },
}

export const availablePermissionTypes = [{ value: 'group', title: 'Group' }]
