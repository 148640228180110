export const getUserDetails = (users = [], userId) => {
  return users.find(user => user.id === userId)
}

export const addFullNamesToTheUsers = (users = [], rows = []) => {
  const updatedRows = []

  rows.forEach(row => {
    const userDetails = getUserDetails(users, row.id)
    updatedRows.push({
      ...row,
      name: `${userDetails?.firstName || ''} ${userDetails?.lastName || ''}`,
    })
  })

  return updatedRows
}
