import React from 'react'
import DatePicker from 'react-datepicker'
import capitalize from 'lodash/capitalize'
import styled from 'styled-components'

import 'react-datepicker/dist/react-datepicker.css'

const DateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 24px;

  input {
    font-size: 15px;
    padding: 5.625px 11.25px;
    border: 1px solid #ced4da;
    border-radius: 3.75px;
  }

  .react-datepicker__time-list-item {
    padding: 5px 0 !important;
  }

  .react-datepicker-popper {
    z-index: 4;
  }
`

export default ({
  label,
  maxDate,
  minDate,
  pageName,
  type,
  updateDate,
  updateFromInput,
  value,
  onCalendarClose,
  dateFormat = 'MM/dd/yyyy HH:mm:ss',
  timeFormat = 'HH:mm:ss',
  showTimeSelect = true,
  placeholder = '',
  disabled = false,
  showYearDropdown = false,
  ...rest
}) => {
  const id = `${pageName}${capitalize(type)}`

  return (
    <DateWrapper {...rest}>
      {label && <label htmlFor={id}>{label || capitalize(type)}</label>}

      <DatePicker
        id={id}
        selected={value}
        onChange={dateObj => {
          updateDate(type, dateObj)
        }}
        onSelect={dateObj => {
          updateDate(type, dateObj)
        }}
        onBlur={e => updateFromInput(type, e)}
        maxDate={maxDate}
        minDate={minDate}
        showTimeSelect={showTimeSelect}
        disabledKeyboardNavigation
        dateFormat={dateFormat}
        timeFormat={timeFormat}
        placeholderText={placeholder !== '' ? placeholder : ''}
        disabled={disabled}
        {...(onCalendarClose && { onCalendarClose })}
        {...(updateDate && { onCalendarClose })}
        {...(showYearDropdown && {
          showYearDropdown: true,
          yearDropdownItemNumber: 80,
          scrollableYearDropdown: true,
        })}
      />
    </DateWrapper>
  )
}
