import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocalStorage } from 'usehooks-ts'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import debounce from 'lodash/debounce'
import Autocomplete from 'britive-design-system/core/components/autocomplete'
import Pill from 'britive-design-system/core/components/pill'
import { getResourceLabels } from 'action_creators/serverAccess'
import toast from 'utils/toast'
import { localStorageKey } from '../constants'

const MAX_LABEL = 3
const CSS_CLASSNAME_PREFIX = 'my-resources-filter-group-by'

const GroupByFilters = ({ onChange, isExpanded, setIsExpanded }) => {
  const [existingLabels] = useLocalStorage(localStorageKey.groupByLabels, [])

  const [selectedLabels, setSelectedLabels] = useState(existingLabels)
  const dispatch = useDispatch()
  const resourceLabels = useSelector(state => state.serverAccess.resourceLabels)

  useEffect(() => {
    fetchResourceLabels()
  }, [])

  const fetchResourceLabels = async (params = {}) => {
    try {
      await dispatch(getResourceLabels({ page: 0, ...params }))
    } catch (error) {
      const errorMessage = get(
        error,
        'response.data.message',
        'Unable to fetch resource labels'
      )
      toast({
        title: errorMessage,
        type: 'error',
      })
    }
  }

  const handleLabelSearch = (_, input) => {
    const params = {}

    if (!isEmpty(input)) {
      // If an input is provided by the user, find the matching response template.
      params.searchText = input
    }

    fetchResourceLabels(params)
  }

  const removeLabel = indexToRemove => {
    let updatedSelections = []
    if (selectedLabels.length > 1) {
      updatedSelections = [...selectedLabels]
      updatedSelections.splice(indexToRemove, 1)
    }

    setSelectedLabels(updatedSelections)
    onChange(updatedSelections)
  }

  const clearGroups = () => {
    setSelectedLabels([])
    onChange([])
  }

  const getOptions = () => {
    if (isEmpty(resourceLabels?.paginatedResourcesLabels?.data)) {
      return []
    }

    if (isEmpty(selectedLabels)) {
      return resourceLabels?.paginatedResourcesLabels?.data
    }

    return resourceLabels.paginatedResourcesLabels.data.filter(
      resourceLabel => !selectedLabels.includes(resourceLabel.keyName)
    )
  }

  const handleLabelSelection = (_, value) => {
    const updatedSelections = [...selectedLabels]
    updatedSelections.push(value.keyName)
    setSelectedLabels(updatedSelections)
    onChange(updatedSelections)
  }

  return (
    <div className={`${CSS_CLASSNAME_PREFIX}-wrapper`}>
      <div className={`${CSS_CLASSNAME_PREFIX}-selector`}>
        <Autocomplete
          key={JSON.stringify(selectedLabels)}
          height="30px"
          width="250px"
          filterCurrentValueFromOptions
          label="Group By Resource Labels"
          helperText="You can select maximum of 3 labels."
          showOptionsOnBlur
          placeholder="Select resource label"
          value={[]}
          disabled={selectedLabels.length >= MAX_LABEL}
          options={getOptions()}
          onInputChange={debounce(handleLabelSearch, 300)}
          onChange={handleLabelSelection}
          getOptionLabel={option => {
            return option?.keyName
          }}
        />
        &nbsp;
        <div className={`${CSS_CLASSNAME_PREFIX}-selected-groups`}>
          {selectedLabels.map((label, index) => (
            <Pill
              key={`selected-group-label-${label}-${index}`}
              label={label}
              onDelete={() => removeLabel(index)}
            />
          ))}
          {selectedLabels.length ? (
            <div
              className={`${CSS_CLASSNAME_PREFIX}-clear-groups`}
              onClick={clearGroups}
            >
              <span>Clear Grouping</span>
            </div>
          ) : null}
        </div>
      </div>
      {selectedLabels.length ? (
        <div
          className={`${CSS_CLASSNAME_PREFIX}-expand-all`}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <span>{isExpanded ? 'Collapse' : 'Expand'} All Groups</span>
        </div>
      ) : null}
    </div>
  )
}

export default GroupByFilters
