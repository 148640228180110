import { FETCH_SAML_SETTINGS } from 'action_creators/saml_configuration'

export const samlConfigurationReducerKey = 'samlConfiguration'

const initialState = {
  samlSettings: [],
  isFetchingSAMLSettings: false,
  fetchedSAMLSettings: false,
  error: null,
}

export function samlConfigurationReducer(state = initialState, action) {
  switch (action.type) {
    case `${FETCH_SAML_SETTINGS}_PENDING`: {
      return {
        ...state,
        isFetchingSAMLSettings: true,
        error: null,
      }
    }

    case `${FETCH_SAML_SETTINGS}_FULFILLED`: {
      return {
        ...state,
        isFetchingSAMLSettings: false,
        samlSettings: action.payload.data,
        fetchedSAMLSettings: true,
        error: null,
      }
    }

    case `${FETCH_SAML_SETTINGS}_REJECTED`: {
      return {
        ...state,
        isFetchingSAMLSettings: false,
        fetchedSAMLSettings: true,
        error: action.payload.data,
      }
    }

    default:
      return { ...state }
  }
}
