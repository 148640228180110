import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import ActionModal from 'components/ActionModal'

import { deletePap, disablePap, enablePap } from 'action_creators/pap'

import { getSelectedApplicationRoot } from 'selectors/application_root'

class PapsActionModalWrapper extends PureComponent {
  state = {
    actionsStarted: false,
    isUpdating: false,
    actionResults: {},
    allFinished: false,
    currentModalType: null,
    initialItemsList: [],
  }

  componentDidMount() {
    this.setState({ initialItemsList: this.props.paps })
  }

  componentDidUpdate(prevProps, prevState) {
    const { actionResults, actionsStarted } = this.state
    const { activeModalType, paps } = this.props

    if (prevProps.paps.length === 0 && prevProps.paps !== paps) {
      this.setState({ initialItemsList: paps })
    }

    if (actionsStarted && prevState.actionResults !== actionResults) {
      this.handleActionResults()
    }

    if (activeModalType && prevState.currentModalType !== activeModalType) {
      this.setState({ currentModalType: activeModalType })
    }
  }

  handleActionResults = async () => {
    const { actionResults } = this.state

    const allFinished = Object.values(actionResults).every(result =>
      ['successful', 'failure'].includes(result)
    )

    this.setState({ allFinished, isUpdating: !allFinished })
  }

  toggleModal = () => {
    const { toggleModal, clearCheckedItems, selectModalType } = this.props
    const { isUpdating } = this.state

    if (!isUpdating) {
      toggleModal()
      clearCheckedItems()
      selectModalType()

      this.setState({
        allFinished: false,
        actionResults: {},
        actionsStarted: false,
      })
    }
  }

  modalAction = async () => {
    const {
      activeModalType,
      checkedItems,
      dispatch,
      selectedApplicationRoot,
    } = this.props

    const actions = {
      Disable: disablePap,
      Enable: enablePap,
      Delete: deletePap,
    }

    const { appContainerId: appId } = selectedApplicationRoot
    const modalAction = actions[activeModalType]

    this.setState({ isUpdating: true })

    await Object.keys(checkedItems).forEach(async itemId => {
      if (!checkedItems[itemId]) {
        return
      }

      let status

      this.setState({
        actionResults: { ...this.state.actionResults, [itemId]: 'pending' },
      })

      try {
        const response = await dispatch(modalAction(appId, itemId))
        status = response.value.status === 200 ? 'successful' : 'failure'
      } catch (e) {
        status = 'failure'
      }

      this.setState({
        actionResults: { ...this.state.actionResults, [itemId]: status },
      })
    })

    this.setState({ actionsStarted: true })
  }

  render() {
    const { checkedItems, isModalOpen } = this.props
    const {
      actionResults,
      isUpdating,
      allFinished,
      currentModalType,
      initialItemsList,
    } = this.state

    if (!isModalOpen) {
      return null
    }

    return (
      <ActionModal
        allFinished={allFinished}
        actionResults={actionResults}
        checkedItems={checkedItems}
        currentModalType={currentModalType}
        identifier="papId"
        isModalOpen={isModalOpen}
        isUpdating={isUpdating}
        itemName="profile"
        itemNameProperty="name"
        items={initialItemsList}
        modalAction={this.modalAction}
        toggleModal={this.toggleModal}
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    selectedApplicationRoot: getSelectedApplicationRoot(state),
  }
}

export default connect(mapStateToProps)(PapsActionModalWrapper)
