import React, { PureComponent } from 'react'
import styled from 'styled-components'
import capitalize from 'lodash/capitalize'
import FontAwesome from 'react-fontawesome'
import get from 'lodash/get'
import {
  ActionIconLabel,
  ActionIconGroup,
} from 'britive-ui-components/core/components/ActionIcon'
import Button from 'britive-ui-components/core/components/Button'
import BDSTooltip from 'britive-design-system/core/components/tooltip'
import ModalPopup from 'britive-design-system/core/components/modal-popup'

import ProfileEnvironmentGroupsWrapper from './components/ProfileEnvironmentGroupsWrapper'
import ActionDropdownButton from 'components/ActionDropdownButton'
import PapsActionModalWrapper from './components/PapsActionModalWrapper'
import PageLoader from 'components/PageLoader'
import Drawer from 'components/Drawer'
import { BackgroundTooltip as Tooltip } from 'components/Tooltips'
import TableActionIcon from 'components/TableActionIcon'
import TableCheckboxCell from 'components/table/TableV2/TableCheckboxCell'
import TableEllipsisCell from 'components/table/TableV2/TableEllipsisCell'

import {
  formatDateWithTime,
  formatMilliseconds,
  getTimeDurationFromSeconds,
  getExpirationDateFromGivenDate,
} from 'utils/format_date'
import { getHeaderSpacing } from 'utils/elementMeasurement'
import IntegrityCheck from './IntegrityCheck'
import Table from 'components/table/TableV2/Table'
import EnvironmentList from './EnvironmentList'
import ButtonPrimary from 'britive-design-system/core/components/button'
import InfoIcon from 'static/images/file-info.png'
import listTree from 'static/images/list-tree.svg'
import PapPermissions from './details/PapPermissions'
import { isAWSIDC, isAWSWithoutIDC } from './components/pap-profile/utils'
import { IoCopyOutline } from 'react-icons/io5'
import CloneProfile from './components/clone-profile/CloneProfile'
import infoIcon from 'static/images/infoIcon.svg'
import AssociationPopup from './AssociationPopup'

const ACTIONS = ['Disable', 'Enable', 'Delete']

const Wrapper = styled.div`
  padding: 8px;
  position: relative;
`

const CancelButton = styled(Button)`
  background-color: rgba(242, 38, 19, 1);
  border-color: rgba(242, 38, 19, 1);
  color: #fff !important;

  &:disabled {
    background-color: rgba(242, 38, 19, 0.7);
    border-color: transparent;
    color: rgba(0, 0, 0, 0.8) !important;
    cursor: not-allowed;
  }
`
const NameWrapper = styled.div`
  align-items: center;
  display: flex;
  overflow: hidden;
`

const SearchIconWrapper = styled.span`
  color: #067fdb;
`

const DraftInfoIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

const styles = {
  flex: { display: 'flex' },
  icons: { alignItems: 'center', display: 'flex' },
  infoIcon: { marginLeft: 10 },
  success: { color: 'green', marginRight: 4 },
  buttonsWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
    position: 'absolute',
    right: 0,
    top: -44,
    zIndex: 5,
  },
  dropdownButton: {
    marginRight: 10,
  },
  actionButton: {
    marginRight: 10,
    height: 35,
  },
  btnWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    cursor: 'pointer',
  },
}

export default class Paps extends PureComponent {
  state = {
    showEnvironmentList: false,
    environmentList: [],
    showPermissionDrawer: false,
    selectedPap: null,
    cloneProfileModalOpen: false,
    papId: null,
    profilesSearchParams: '',
    openAssociationPopup: false,
  }

  toggleEnvironmentList = (isHierarchical, environments = []) => {
    if (isHierarchical) {
      this.setState({ openAssociationPopup: true, showEnvironmentList: false })
    } else if (environments.length > 0) {
      const environmentList = []
      Object.values(this.props.environmentData).forEach(envData => {
        if (environments.includes(envData.catalogAppDisplayName)) {
          environmentList.push(envData)
        }
      })
      this.setState({
        showEnvironmentList: true,
        environmentList,
        openAssociationPopup: false,
      })
    } else {
      this.setState({
        showEnvironmentList: false,
        environmentList: [],
        openAssociationPopup: false,
      })
    }
  }

  resetPermissionData = () => {
    this.setState({
      showPermissionDrawer: false,
      selectedPap: null,
    })
  }

  toggleAssociationPopup = flag => {
    this.setState({
      openAssociationPopup: flag,
    })
  }

  renderName = data => {
    const tooltipId = `papId-${data.original.papId}`
    const name = data.value

    return (
      <NameWrapper>
        <TableEllipsisCell value={name} />

        <FontAwesome
          name="info-circle"
          style={styles.infoIcon}
          data-tip
          data-for={tooltipId}
        />

        <Tooltip place="right" effect="solid" id={tooltipId}>
          {data.original.description || 'Description not available'}
        </Tooltip>
      </NameWrapper>
    )
  }

  renderIntegrityChecks = ({ original }) => {
    return (
      <IntegrityCheck
        checkStatus={original.checkStatus}
        message={original.message}
        name={original.name}
        onClick={this.props.toggleDrawer}
        selected={original.papId}
      />
    )
  }

  renderCheckbox = (isHeader, { original: data }) => {
    const {
      areAllPapsSelected,
      toggleAllPapCheckboxes,
      checkedPaps,
      togglePapCheckbox,
    } = this.props

    const checked = isHeader ? areAllPapsSelected : checkedPaps[data.papId]
    const onClick = () =>
      isHeader
        ? toggleAllPapCheckboxes('name')
        : togglePapCheckbox(data.papId, data.name)

    return <TableCheckboxCell data={data} onClick={onClick} checked={checked} />
  }

  renderTable = () => {
    const {
      currentActionMode,
      clearCheckedItems,
      app,
      setDataList,
      setDataOverride,
      dropdownFilters,
      requestAccess,
      selectedPermission,
    } = this.props
    const isHierarchical = app.catalogApplication?.requiresHierarchicalModel

    const columns = [
      {
        Header: 'Profile Name',
        accessor: 'name',
        Cell: this.renderName,
        fixed: 'left',
        minWidth: 160,
      },
      {
        Header: `Expiration (${
          !isAWSWithoutIDC(app?.catalogAppName) ? 'DD:' : ''
        }HH:MM)`,
        accessor: 'expirationDuration',
        Cell: ({ value }) =>
          value ? (
            <TableEllipsisCell
              value={getTimeDurationFromSeconds(
                value / 1000,
                !isAWSWithoutIDC(app?.catalogAppName)
              )}
            />
          ) : (
            ''
          ),
        minWidth: 120,
        show: !selectedPermission,
      },
      {
        Header: 'Associations',
        accessor: 'environments',
        Cell: ({ original: { environments, papId, name } }) => {
          return (
            <ActionIconGroup>
              <TableActionIcon
                id="appEnvironmentCount"
                onClick={() => {
                  this.toggleEnvironmentList(isHierarchical, environments)
                  this.setState({
                    selectedPap: { papId, name },
                  })
                }}
              >
                <SearchIconWrapper>
                  <FontAwesome name="search" />
                  &nbsp;
                  {!isHierarchical ? environments.length : null}
                </SearchIconWrapper>
              </TableActionIcon>
            </ActionIconGroup>
          )
        },
        minWidth: 100,
        sortable: false,
        show: !isAWSIDC(app.catalogAppName),
      },
      {
        Header: 'Integrity Check',
        Cell: this.renderIntegrityChecks,
        accessor: 'checkStatus',
        minWidth: 100,
        sortable: false,
        show: !selectedPermission,
      },
      {
        Header: 'Last Accessed',
        Cell: ({ original: { lastAccessed } }) =>
          lastAccessed ? formatDateWithTime(lastAccessed) : 'Not Accessed',
        accessor: 'lastAccessed',
        minWidth: 120,
        show: !selectedPermission,
      },
      {
        Header: 'Last Modified By',
        accessor: 'modifiedBy',
        minWidth: 100,
        sortable: false,
        show: !selectedPermission,
      },
      {
        Header: 'Origin',
        accessor: 'origin',
        minWidth: 60,
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }) => <TableEllipsisCell value={capitalize(value)} />,
        minWidth: 50,
      },
      {
        Header: 'Approval Status',
        accessor: 'approvalStatus',
        Cell: ({ value }) => <TableEllipsisCell value={capitalize(value)} />,
        minWidth: 80,
        show: window?.location?.pathname?.includes('/managed-permissions'),
      },
      {
        Header: 'Action',
        Cell: this.renderActionIcons,
        accessor: 'actions',
        resizable: false,
        sortable: false,
        minWidth: 60,
        show: !selectedPermission,
      },
    ]

    const requestAccessColumns = [
      {
        Header: 'Profile Name',
        accessor: 'name',
        Cell: this.renderName,
        fixed: 'left',
        minWidth: 160,
      },
      {
        Header: 'Expiration Duration',
        accessor: 'expirationDuration',
        Cell: ({ value }) => <TableEllipsisCell value={formatMilliseconds(value)} />,
        minWidth: 120,
      },
      {
        Header: 'Associations',
        accessor: 'environments',
        Cell: ({ original: { environments, papId, name } }) => {
          return (
            <ActionIconGroup>
              <TableActionIcon
                id="appEnvironmentCount"
                onClick={() => {
                  this.toggleEnvironmentList(isHierarchical, environments)
                  this.setState({
                    selectedPap: { papId, name },
                  })
                }}
              >
                <SearchIconWrapper>
                  <FontAwesome name="search" />
                  &nbsp;
                  {!isHierarchical ? environments.length : null}
                </SearchIconWrapper>
              </TableActionIcon>
            </ActionIconGroup>
          )
        },
        minWidth: 100,
        sortable: false,
      },
      {
        Header: 'Permissions',
        accessor: 'permissionsCount',
        Cell: ({ original: { permissionsCount, papId, name } }) => {
          return (
            <>
              {permissionsCount}
              &nbsp;
              <div style={{ cursor: 'pointer' }}>
                <BDSTooltip title="View Permissions" position="left">
                  <span
                    title=""
                    onClick={() =>
                      this.setState({
                        showPermissionDrawer: true,
                        selectedPap: { papId, name },
                      })
                    }
                  >
                    <img src={listTree} />
                  </span>
                </BDSTooltip>
              </div>
            </>
          )
        },
        minWidth: 100,
      },
      {
        Header: 'Status',
        accessor: 'papStatus',
        Cell: ({ original: pap, value }) => {
          const isDraft = pap?.papStatus === 'Draft'
          return (
            <DraftInfoIcon>
              <TableEllipsisCell value={capitalize(value)} />
              {isDraft && (
                <BDSTooltip
                  title={`This draft profile will remain in the system until
                  ${getExpirationDateFromGivenDate(pap?.modifiedDate)}.`}
                  position="left"
                >
                  <img src={infoIcon} style={{ height: '18px' }} />
                </BDSTooltip>
              )}
            </DraftInfoIcon>
          )
        },
        minWidth: 60,
      },
      {
        Header: 'Action',
        Cell: this.renderActionIcons,
        accessor: 'actions',
        resizable: false,
        sortable: false,
        minWidth: 60,
      },
    ]

    let emptyTableMessage = requestAccess
      ? 'No profiles available under this application.'
      : 'Currently, there are no access profiles for this application'

    if (currentActionMode) {
      emptyTableMessage = `Currently, there are no access profiles to ${currentActionMode.toLowerCase()}`
    }

    return (
      <div style={{ display: 'block', flexGrow: 1 }}>
        <Table
          setDataList={setDataList}
          setDataOverride={setDataOverride}
          columns={requestAccess ? requestAccessColumns : columns}
          identifier="papId"
          showCheckBox
          renderCheckbox={this.renderCheckbox}
          dataUrl={
            requestAccess
              ? `/profile-requests/apps/${app.appContainerId}/paps`
              : `/apps/${app.appContainerId}/paps`
          }
          params={{
            view: 'summary',
            ...(selectedPermission ? { roleName: selectedPermission } : {}),
          }}
          emptyTableMessage={emptyTableMessage}
          clearCheckedItems={clearCheckedItems}
          currentActionMode={currentActionMode}
          dropdownFilters={dropdownFilters}
          actionFilter={{
            Disable: { status: 'active' },
            Enable: { status: 'inactive' },
            Delete: {},
          }}
          columnNamesFilter={[
            { label: 'Associations', value: 'environment' },
            { label: 'Profile Name', value: 'profileName' },
          ]}
          getApiParams={profilesSearchParams =>
            this.setState({ profilesSearchParams })
          }
          noUrlQueryParams={selectedPermission}
        />
      </div>
    )
  }

  renderButtons = () => {
    const {
      currentActionMode,
      clearCheckedItems,
      selectActionMode,
      requestAccess,
    } = this.props

    return (
      <div style={styles.buttonsWrapper}>
        {!currentActionMode && (
          <React.Fragment>
            {!requestAccess && (
              <ActionDropdownButton
                onOptionClick={selectActionMode}
                actions={ACTIONS}
                itemName="Profile"
              />
            )}

            <Button
              id="appProfilesCreate"
              color="primary"
              style={styles.actionButton}
              onClick={this.props.goToCreatePapPage}
            >
              Create Profile
            </Button>
          </React.Fragment>
        )}

        {currentActionMode && (
          <React.Fragment>
            <CancelButton
              color="delete"
              style={styles.actionButton}
              onClick={() => {
                clearCheckedItems()
                selectActionMode()
              }}
            >
              Cancel
            </CancelButton>

            <Button
              disabled={!this.props.areAnyPapsSelected}
              color="primary create-profile"
              style={styles.actionButton}
              onClick={() => this.props.toggleModal(currentActionMode)}
            >
              {currentActionMode} Profiles
            </Button>
          </React.Fragment>
        )}
      </div>
    )
  }

  renderActionIcons = ({ original: pap }) => {
    const { showDetails, requestAccess, thisAppManage } = this.props
    const { profilesSearchParams } = this.state
    const isDraft = pap?.papStatus === 'Draft'
    const profilesDetailsState = {
      searchParams: profilesSearchParams,
    }

    return (
      <>
        {requestAccess ? (
          <div style={styles.btnWrapper}>
            <ButtonPrimary
              size="small"
              onClick={() =>
                showDetails(
                  pap,
                  profilesDetailsState,
                  isDraft ? 'create' : 'request'
                )
              }
            >
              {isDraft ? 'Edit' : 'Request'}
            </ButtonPrimary>
            <BDSTooltip title={'View profile'} position="bottom-left">
              <div
                onClick={() =>
                  showDetails(pap, profilesDetailsState, isDraft && 'draft')
                }
              >
                <img src={InfoIcon} width="30" />
              </div>
            </BDSTooltip>
            <BDSTooltip title={'Clone profile'} position="bottom-left">
              <div>
                <IoCopyOutline
                  size="24"
                  onClick={() =>
                    this.setState({
                      cloneProfileModalOpen: true,
                      papId: pap?.papId,
                    })
                  }
                />
              </div>
            </BDSTooltip>
          </div>
        ) : (
          <ActionIconGroup>
            <TableActionIcon
              id="appProfilesDetailsLink"
              onClick={() => showDetails(pap, profilesDetailsState)}
              data-id={pap.papId}
            >
              <FontAwesome name="sliders-h" size="lg" />
              <ActionIconLabel>Manage</ActionIconLabel>
            </TableActionIcon>
            <BDSTooltip title={'Clone profile'} position="bottom-left">
              <div style={styles.btnWrapper}>
                <IoCopyOutline
                  size="24"
                  color={!thisAppManage ? '#c6c6c6' : ''}
                  onClick={() =>
                    thisAppManage &&
                    this.setState({
                      cloneProfileModalOpen: true,
                      papId: pap?.papId,
                    })
                  }
                />
              </div>
            </BDSTooltip>
          </ActionIconGroup>
        )}
      </>
    )
  }

  render() {
    const {
      app,
      checkedPaps,
      currentActionMode,
      selectActionMode,
      isModalOpen,
      fetching,
      clearCheckedItems,
      toggleModal,
      toggleDrawer,
      isDrawerOpen,
      papId,
      dataList,
      thisAppManage,
      requestAccess,
      environmentData,
      match,
    } = this.props

    if (!app) {
      return null
    }

    const supportsScanning = get(
      app,
      'catalogApplication.supportsEnvironmentScanning'
    )

    if (!app.rootEnvironmentGroup) {
      return (
        <Wrapper>
          {supportsScanning
            ? 'Please configure and scan application.'
            : 'Please create and configure an environment'}
        </Wrapper>
      )
    }

    if (!app.rootEnvironmentGroup) {
      return (
        <Wrapper id="appDashboardContainer">
          Please configure and scan application.
        </Wrapper>
      )
    }

    return (
      <Wrapper>
        {thisAppManage && this.renderButtons()}

        {fetching ? (
          <PageLoader text="Loading Access Profiles" />
        ) : (
          <div>{this.renderTable()}</div>
        )}

        <PapsActionModalWrapper
          activeModalType={currentActionMode}
          selectModalType={selectActionMode}
          clearCheckedItems={clearCheckedItems}
          checkedItems={checkedPaps}
          toggleModal={toggleModal}
          isModalOpen={isModalOpen}
          paps={dataList}
        />

        <Drawer
          isOpen={isDrawerOpen}
          top={`${getHeaderSpacing()}px`}
          toggleDrawer={toggleDrawer}
          width="550px"
        >
          <ProfileEnvironmentGroupsWrapper
            showUsers={get(app, 'catalogApplication.requiresAccountMapping')}
            useTabs={true}
            paps={dataList}
            papId={papId}
            isHierarchical={get(app, 'catalogApplication.requiresHierarchicalModel')}
            requiresAccountMapping={get(
              app,
              'catalogApplication.requiresAccountMapping'
            )}
          />
        </Drawer>
        {this.state.selectedPap !== null && this.state.openAssociationPopup ? (
          <AssociationPopup
            onClose={flag => this.toggleAssociationPopup(flag)}
            profileId={this.state.selectedPap?.papId}
            profileName={this.state.selectedPap?.name}
            requestAccess={requestAccess}
          />
        ) : (
          this.state.showEnvironmentList && (
            <EnvironmentList
              onClose={() =>
                this.toggleEnvironmentList(
                  get(app, 'catalogApplication.requiresHierarchicalModel')
                )
              }
              environmentList={this.state.environmentList}
            />
          )
        )}
        {this.state.showPermissionDrawer && (
          <ModalPopup
            width={720}
            title={`${this.state.selectedPap.name} Permissions`}
            onCancel={this.resetPermissionData}
          >
            <PapPermissions
              profileId={this.state.selectedPap.papId}
              papName={this.state.selectedPap.name}
              app={app}
              environments={environmentData}
              thisAppManage={thisAppManage}
              requestAccess={requestAccess}
              onlyViewPermissions={true}
            />
          </ModalPopup>
        )}
        {this.state.cloneProfileModalOpen && (
          <ModalPopup
            width={512}
            title="Clone Profile"
            onCancel={() =>
              this.setState({
                cloneProfileModalOpen: false,
                papId: null,
              })
            }
          >
            <CloneProfile
              requestAccess={requestAccess}
              appId={match?.params?.appId}
              papId={this.state.papId}
              supportsSessionAttributes={
                app?.catalogApplication?.supportsSessionAttributes
              }
            />
          </ModalPopup>
        )}
      </Wrapper>
    )
  }
}
