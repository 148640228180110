import { fetchIfLoggedIn } from '../utils.js'

export const FETCH_NOTIFICATION_MEDIUMS = 'FETCH_NOTIFICATION_MEDIUMS'

export const fetchNotificationMediums = () => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: FETCH_NOTIFICATION_MEDIUMS,
      method: 'get',
      path: '/v1/notification-service/notificationmediums',
    })
  )
}
