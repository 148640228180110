import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import PropertySection from '../property-section'

class SectionTab extends PureComponent {
  render() {
    const {
      groups,
      section,
      label,
      properties,
      isLoading,
      fields,
      onChange,
      onSubmit,
      createNewMode,
      propertyTypes,
      saving,
      onCancel,
      subPropertiesMap,
      thisAppManage,
      appRoot,
      isApplicationRoot,
    } = this.props

    if (groups) {
      return groups.map((group, idx) => {
        // if a group doesn't have any properties associated, we don't show the section
        if (group.properties && group.properties.length > 0) {
          return (
            <PropertySection
              key={idx}
              label={group.displayName}
              isLoading={isLoading}
              properties={group.properties}
              propertyTypes={propertyTypes}
              fields={fields}
              subPropertiesMap={subPropertiesMap}
              onChange={onChange}
              onSubmit={onSubmit}
              sectionLabel={group.displayName || label}
              createNewMode={createNewMode}
              saving={saving}
              onCancel={onCancel}
              thisAppManage={thisAppManage}
              appRoot={appRoot}
              isApplicationRoot={isApplicationRoot}
            />
          )
        } else {
          return null
        }
      })
    } else {
      return (
        <PropertySection
          section={section}
          isLoading={isLoading}
          properties={properties}
          propertyTypes={propertyTypes}
          sectionLabel={label}
          fields={fields}
          subPropertiesMap={subPropertiesMap}
          onChange={onChange}
          onSubmit={onSubmit}
          createNewMode={createNewMode}
          saving={saving}
          onCancel={onCancel}
          thisAppManage={thisAppManage}
          appRoot={appRoot}
          isApplicationRoot={isApplicationRoot}
        />
      )
    }
  }
}

SectionTab.propTypes = {
  groups: PropTypes.array,
  properties: PropTypes.array,
  propertyTypes: PropTypes.object,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  fields: PropTypes.object,
  subPropertiesMap: PropTypes.object,
  createNewMode: PropTypes.bool,
  saving: PropTypes.bool,
  onCancel: PropTypes.func,
}

export default SectionTab
