import React, { PureComponent } from 'react'
import styled from 'styled-components'
import camelCase from 'lodash/camelCase'
import pluralize from 'pluralize'

import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'

const Wrapper = styled.div`
  margin-right: 10px;

  .dropdown-toggle {
    min-width: 150px;

    &::after {
      margin-left: 1em;
    }
  }

  .dropdown-menu > button {
    color: #fff;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;

    &:hover {
      color: #000;
    }
  }
`

const styles = {
  dropdownButton: {
    background: 'var(--brand)',
    fontSize: 14,
    textTransform: 'uppercase',
    fontWeight: 600,
    borderColor: 'var(--brand)',
    boxShadow: 'unset',
  },
  dropdownMenu: {
    background: 'var(--brand)',
    borderColor: 'var(--brand)',
  },
}

export default class DropdownButton extends PureComponent {
  state = { isDropdownOpen: false }

  toggleDropdown = () => {
    this.setState({ isDropdownOpen: !this.state.isDropdownOpen })
  }

  render() {
    const { actions, itemName, onOptionClick } = this.props
    const { isDropdownOpen } = this.state

    return (
      <Wrapper>
        <Dropdown isOpen={isDropdownOpen} toggle={this.toggleDropdown}>
          <DropdownToggle
            color="success"
            caret
            style={styles.dropdownButton}
            className={`${camelCase(itemName)}ActionDropdown`}
          >
            More Actions
          </DropdownToggle>

          <DropdownMenu style={styles.dropdownMenu}>
            {actions.map(action => {
              return (
                <DropdownItem
                  key={action}
                  className={`${camelCase(itemName)}${action}Option`}
                  onClick={() => onOptionClick(action)}
                >
                  {action} {pluralize(itemName)}
                </DropdownItem>
              )
            })}
          </DropdownMenu>
        </Dropdown>
      </Wrapper>
    )
  }
}
