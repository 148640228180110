import React, { useState, useEffect, Fragment } from 'react'
import styled from 'styled-components'
import { ActionIconLabel } from 'britive-ui-components/core/components/ActionIcon'
import Spinner from 'britive-ui-components/core/components/Spinner'
import Button from 'britive-ui-components/core/components/Button'
import TableSection from 'components/table/tableSectionV2'
import TableCheckboxCell from 'components/table/TableV2/TableCheckboxCell'
import TableActionIcon from 'components/TableActionIcon'
import ActionDropdownButton from 'components/ActionDropdownButton'
import PapPolicyActionModalWrapper from './PapPolicyActionModalWrapper'
import ButtonPrimary from 'britive-design-system/core/components/button'
import InfoIcon from '../../../../../../../static/images/infoIcon.svg'
import { isEmpty, get } from 'lodash'
import editIcon from 'static/images/edit-icon.svg'
import deleteIcon from 'static/images/delete-icon.svg'
import { deletePapPolicy } from 'action_creators/papPolicy'
import toast from 'utils/toast'

const CancelButton = styled(Button)`
  background-color: rgba(242, 38, 19, 1);
  border-color: rgba(242, 38, 19, 1);
  color: #fff !important;

  &:disabled {
    background-color: rgba(242, 38, 19, 0.7);
    border-color: transparent;
    color: rgba(0, 0, 0, 0.8) !important;
    cursor: not-allowed;
  }
`

const ACTIONS = ['Delete', 'Enable', 'Disable']

const styles = {
  actionButton: {
    marginRight: 10,
    height: 35,
  },
  buttonsWrapper: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    right: 0,
    top: -64,
  },
  tableWrapper: {
    display: 'block',
  },
  infoButton: {
    cursor: 'pointer',
    marginRight: 10,
  },
}

function PapPolicy({
  showDetailsClick,
  clearCheckedItems,
  currentActionMode,
  selectActionMode,
  toggleActionModal,
  fetching,
  checkedItems,
  dataIdentifier,
  papPolicies,
  isActionModalOpen,
  setDataOverride,
  isAnyPapPoliciesSelected,
  areAllPapPoliciesSelected,
  toggleAllCheckboxes,
  toggleCheckbox,
  addPapPolicy,
  profileId,
  requestAccess,
  match,
  showDetails,
  onSelectPolicy,
  selectedPolicy,
  dispatch,
  deletingPapPolicy,
  thisAppManage,
}) {
  const [selectedId, setSelectedId] = useState(null)

  function renderButtons() {
    return (
      <div style={styles.buttonsWrapper}>
        {!currentActionMode && (
          <React.Fragment>
            <ActionDropdownButton
              onOptionClick={selectActionMode}
              actions={ACTIONS}
              itemName="Policy"
            />

            <Button
              color="primary"
              style={styles.actionButton}
              onClick={addPapPolicy}
            >
              Add Policy
            </Button>
          </React.Fragment>
        )}
        {currentActionMode && (
          <React.Fragment>
            <CancelButton
              color="delete"
              className="usersAdminCancelAction"
              style={styles.actionButton}
              onClick={() => {
                clearCheckedItems()
                selectActionMode()
              }}
            >
              Cancel
            </CancelButton>

            <Button
              disabled={!isAnyPapPoliciesSelected}
              color="primary"
              style={styles.actionButton}
              onClick={() => toggleActionModal(currentActionMode)}
              className="usersAdminSubmitAction"
            >
              {currentActionMode} Policies
            </Button>
          </React.Fragment>
        )}
      </div>
    )
  }

  const renderAddPolicyButton = () => {
    return (
      <div style={styles.buttonsWrapper}>
        <Button color="primary" style={styles.actionButton} onClick={addPapPolicy}>
          Add Policy
        </Button>
      </div>
    )
  }

  const renderActionIcons = papPolicy => {
    return (
      <TableActionIcon onClick={() => showDetailsClick(papPolicy.id)}>
        <Fragment>
          <span className="fa fa-sliders-h fs:14" />
          <ActionIconLabel>Manage</ActionIconLabel>
        </Fragment>
      </TableActionIcon>
    )
  }

  const deletePolicy = async (papId, papPolicyId) => {
    try {
      await dispatch(deletePapPolicy(papId, papPolicyId, requestAccess))
      toast({
        title: `Policy deleted successfully.`,
        type: 'success',
        time: 'normal',
      })
    } catch (error) {
      toast({
        title: `Error while deleting the policy.`,
        description: get(error, 'response.data.message', null),
        type: 'error',
        time: 'normal',
      })
    }
  }

  const renderActionIconsForRequestAccess = papPolicy => {
    let mode = match?.params?.mode
    let isSelected =
      !isEmpty(selectedPolicy) && selectedPolicy[0].policyId === papPolicy?.id
    return (
      <>
        <img
          src={mode === 'create' ? editIcon : InfoIcon}
          alt="info"
          style={styles.infoButton}
          onClick={() => showDetails(papPolicy.id)}
        />
        {mode === 'create' && (
          <img
            src={deleteIcon}
            alt="delete"
            style={styles.infoButton}
            onClick={() => deletePolicy(profileId, papPolicy.id)}
          />
        )}
        {mode === 'request' && (
          <ButtonPrimary
            variant={isSelected ? 'primary' : 'secondary'}
            size="small"
            onClick={() => setSelectedId(papPolicy?.id)}
          >
            {isSelected ? 'Selected' : 'Select'}
          </ButtonPrimary>
        )}
      </>
    )
  }

  useEffect(() => {
    if (selectedId) {
      let policies = [
        {
          policyId: selectedId,
        },
      ]
      onSelectPolicy(policies)
    }
  }, [selectedId])

  useEffect(() => {
    let mode = match?.params?.mode
    if (mode === 'create') {
      let policies = papPolicies?.map(item => ({ policyId: item.id }))
      onSelectPolicy(policies)
    }
  }, [papPolicies])

  function renderCheckbox(isHeader, { original: data }) {
    const checked = isHeader
      ? areAllPapPoliciesSelected
      : checkedItems[data[dataIdentifier]]

    const onClick = () =>
      isHeader
        ? toggleAllCheckboxes('name')
        : toggleCheckbox(data[dataIdentifier], data.name)

    return <TableCheckboxCell data={data} onClick={onClick} checked={checked} />
  }

  const renderStatus = data => {
    return data.isActive ? 'Enabled' : data.isDraft ? 'Draft' : 'Disabled'
  }
  const create = window.location.pathname.includes('/policies/create')
  return (
    <Fragment>
      {fetching || deletingPapPolicy ? (
        <span>
          <Spinner size="2x" />
          {deletingPapPolicy ? 'Deleting Policy' : 'Loading Policies'}
        </span>
      ) : (
        <Fragment>
          {thisAppManage && !requestAccess && renderButtons()}
          {requestAccess && create && renderAddPolicyButton()}

          <div style={styles.tableWrapper}>
            <TableSection
              resizable={true}
              sortable
              initialColumn={{
                clearInitialColumnStatus: clearCheckedItems,
                InitialColumnComponent: props => renderCheckbox(false, props),
                InitialColumnHeaderComponent: props => renderCheckbox(true, props),
                showInitialColumn: !!currentActionMode,
                initialColumnWidth: 24,
              }}
              columns={[
                { Header: 'Policy Name', accessor: 'name', minWidth: 200 },
                { Header: 'Description', accessor: 'description', minWidth: 400 },
                ...(!requestAccess
                  ? [
                      {
                        Header: 'Status',
                        accessor: 'type',
                        minWidth: 100,
                        Cell: data => renderStatus(data.original),
                      },
                    ]
                  : []),
                {
                  Header: requestAccess ? 'Action' : 'Manage',
                  accessor: 'actions',
                  minWidth: 50,
                  resizable: false,
                  sortable: false,
                  Cell: data =>
                    requestAccess
                      ? renderActionIconsForRequestAccess(data.original)
                      : renderActionIcons(data.original),
                },
              ]}
              data={papPolicies || []}
              identifier={dataIdentifier}
              dataIdentifier={dataIdentifier}
              emptyTableMessage="No Policies available."
              searchKeys={['name', 'description']}
              defaultSorted={[{ id: 'name', desc: false }]}
              setDataOverride={setDataOverride}
            />

            {isActionModalOpen && (
              <PapPolicyActionModalWrapper
                activeModalType={currentActionMode}
                selectModalType={selectActionMode}
                clearCheckedItems={clearCheckedItems}
                checkedItems={checkedItems}
                toggleModal={toggleActionModal}
                isModalOpen={isActionModalOpen}
                papPolicies={papPolicies}
                profileId={profileId}
              />
            )}
          </div>
        </Fragment>
      )}
    </Fragment>
  )
}

export default PapPolicy
