import {
  CLEAR_ENVIRONMENTS_TO_SCAN,
  SELECT_PAPS_MANAGER_ENTITY,
  SELECT_PAP,
  SELECT_ALL_ENVIRONTMENTS_TO_SCAN,
  TOGGLE_ENVIRONMENT_TO_SCAN,
} from './actions'

export const adminUIReducerKey = 'adminUI'

const initialState = {
  papsManagerEntity: null, //This is the entity whose paps are being managed
  selectedPapId: null,
  environmentsToScan: {},
}

export function adminUIReducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_PAPS_MANAGER_ENTITY:
      return {
        ...state,
        papsManagerEntity: action.payload,
      }
    case SELECT_PAP:
      return {
        ...state,
        selectedPapId: action.payload,
      }
    case TOGGLE_ENVIRONMENT_TO_SCAN: {
      const environmentsToScan = { ...state.environmentsToScan }
      const oldStatus = environmentsToScan[action.payload]

      if (oldStatus) {
        delete environmentsToScan[action.payload]
      } else {
        environmentsToScan[action.payload] = true
      }

      return {
        ...state,
        environmentsToScan,
      }
    }
    case CLEAR_ENVIRONMENTS_TO_SCAN: {
      return {
        ...state,
        environmentsToScan: {},
      }
    }
    case SELECT_ALL_ENVIRONTMENTS_TO_SCAN: {
      const environmentsToScan = {}

      action.payload.forEach(env => {
        environmentsToScan[env.id] = true
      })

      return {
        ...state,
        environmentsToScan,
      }
    }
    default:
      return { ...state }
  }
}
