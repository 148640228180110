import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'

import Spinner from 'britive-design-system/core/components/spinner'
import Switch from 'britive-design-system/core/components/switch'
import Typography from 'britive-design-system/core/components/typography'

import {
  fetchAccessRequestSettings,
  updateAccessRequestSettings,
} from 'action_creators/access-request'
import { invalidateTagsAssignedUsers } from 'action_creators/tag'

import AssociationApprovers from './AssociationApprovers'
import toast from 'utils/toast'
import { getSelectedUsers } from 'action_creators/user'
import { fetchSelectedTags } from 'action_creators/tag'
import BackToTop from 'britive-design-system/core/components/backToTop'
import './AssignAssociationApprovers/index.scss'
import NotificationMediumsSection from './NotificationMediumsSection'
import RequestersSection from './RequestersSection'
import getUsersAndTagsFromMembers from 'utils/getUsersAndTagsFromMembers'
import { getUsersAndTagsFromApproversGroup } from './utils'

const AccessRequestContainer = styled.div`
  padding: 8px;
  padding-top: 0;
  font-family: 'OpenSansRegular';
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 60%;
  min-width: 618px;

  .bds-switch {
    width: 80px;
  }

  .section-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  }

  .table-container {
    max-height: 280px;
    overflow: auto;
  }

  .sub-text {
    color: #8d8d8d;
  }

  .action-icons-container {
    display: flex;
    justify-content: flex-start;
    gap: 8px;
  }

  .disabled-icon {
    pointer-events: none;
  }

  .action-icon {
    cursor: pointer;
  }

  .cell-with-icon {
    display: flex;
    justify-content: space-between;
    .cell-icon {
      cursor: pointer;
    }
  }

  .buttons-container {
    display: flex;
    gap: 16px;
  }
`

const AccessRequestSettings = ({ app, setBreadcrumbTrail }) => {
  const dispatch = useDispatch()
  const backToTopId = 'access-request-container-back-to-top'
  const ARequestRef = useRef(null)

  const { loading: approverGroupsLoading, data: approversGroups } = useSelector(
    state => state.accessRequestReducer?.approversGroups
  )

  useEffect(() => {
    const { catalogAppDisplayName } = app
    setBreadcrumbTrail([
      { title: `${catalogAppDisplayName} Access Builder Settings` },
    ])
    dispatch(fetchAccessRequestSettings(app.appContainerId))

    return () => dispatch(invalidateTagsAssignedUsers())
  }, [])

  const { data: accessRequestSettings, loading } = useSelector(
    state => state.accessRequestReducer?.accessRequestSettings
  )
  const thisAppManage = useSelector(state => state?.environment?.thisAppManage)

  useEffect(() => {
    if (
      !approverGroupsLoading &&
      !loading &&
      !isEmpty(accessRequestSettings) &&
      !isEmpty(approversGroups)
    ) {
      const memberUsersAndTags = getUsersAndTagsFromMembers(
        accessRequestSettings.memberRules
      )
      const approversGroupUsersAndTags = getUsersAndTagsFromApproversGroup(
        approversGroups.approversGroupSummary
      )

      const usersToFetch = [
        ...memberUsersAndTags.users,
        ...approversGroupUsersAndTags.users,
      ]

      const tagsToFetch = [
        ...memberUsersAndTags.tags,
        ...approversGroupUsersAndTags.tags,
      ]

      dispatch(getSelectedUsers(usersToFetch))
      dispatch(fetchSelectedTags(tagsToFetch))
    }
  }, [
    JSON.stringify(accessRequestSettings),
    JSON.stringify(approversGroups),
    approverGroupsLoading,
    loading,
  ])

  const updateSettings = async field => {
    try {
      await dispatch(
        updateAccessRequestSettings(
          app.appContainerId,
          isEmpty(accessRequestSettings) ? 'post' : 'patch',
          field
        )
      )
      toast({
        title: 'Access Builder settings updated successfully!',
        type: 'success',
        time: 'normal',
        id: `access-request-settings-${Object.keys(field)[0]}`,
      })
    } catch (e) {
      toast({
        title: e.response?.data?.message,
        type: 'error',
        time: 'normal',
      })
    }
  }

  const handleAccessRequestToggle = checked => {
    updateSettings({ allowAccessRequest: !checked })
  }

  return (
    <>
      <div ref={ARequestRef} className="assign-association-back-to-top">
        <AccessRequestContainer>
          {loading && <Spinner size={'medium'} message={'Loading...'} overlay />}
          <Typography variant="heading7">Allow Access Builder</Typography>
          <Typography variant="label1">
            Complete the required fields to enable the Access Builder feature.
          </Typography>

          <Switch
            label={accessRequestSettings?.allowAccessRequest ? 'On' : 'Off'}
            checked={accessRequestSettings?.allowAccessRequest}
            onToggle={handleAccessRequestToggle}
            disabled={!thisAppManage}
          />

          <AssociationApprovers
            associationApprovers={accessRequestSettings?.associationApprovers}
            approvalTimeout={accessRequestSettings?.approvalTimeOut}
            profileExpirationTimeout={
              accessRequestSettings?.profileExpirationTimeout
            }
            updateSettings={updateSettings}
            app={app}
            disableEditing={!thisAppManage}
          />

          <NotificationMediumsSection
            notificationMediums={accessRequestSettings?.notificationMediums}
            updateSettings={updateSettings}
            disableEditing={!thisAppManage}
          />

          <RequestersSection
            memberRules={accessRequestSettings?.memberRules}
            updateSettings={updateSettings}
            disableEditing={!thisAppManage}
          />
        </AccessRequestContainer>
      </div>
      <BackToTop id={backToTopId} parentRef={ARequestRef} />
    </>
  )
}

AccessRequestSettings.propTypes = {
  app: PropTypes.shape({
    appContainerId: PropTypes.string.isRequired,
    catalogAppDisplayName: PropTypes.string.isRequired,
  }),
  setBreadcrumbTrail: PropTypes.func.isRequired,
}

export default AccessRequestSettings
