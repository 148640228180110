import React, { memo, useEffect, useState } from 'react'
import { connect } from 'react-redux'

import Insights from './Insights'

import * as api from 'services/api'

import { getSelectedApplicationRootId } from 'selectors/application_root'

import {
  getSelectedEnvironmentId,
  getSelectedEnvironmentGroupId,
} from 'selectors/environment'

const REPORT_ID = 'eimqUz0fnXlxrhx90PcB'

function InsightsContainer({ appId, environmentId, environmentGroupId }) {
  const [reportData, setReportData] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    async function getReportData() {
      setReportData(null)
      setIsLoading(true)
      const targetId = environmentId || environmentGroupId

      try {
        const response = await api.fetchReportDataForEnv({
          appId,
          envId: targetId,
          reportId: REPORT_ID,
        })

        setReportData(response.data.data)
      } catch (error) {
        console.log(error)
      }

      setIsLoading(false)
    }

    getReportData()
  }, [appId, environmentId])

  return <Insights isLoading={isLoading} data={reportData} />
}

const mapStateToProps = state => ({
  appId: getSelectedApplicationRootId(state),
  environmentId: getSelectedEnvironmentId(state),
  environmentGroupId: getSelectedEnvironmentGroupId(state),
})

export default connect(mapStateToProps)(memo(InsightsContainer))
