import React from 'react'
import { Col, Row } from 'reactstrap'
import FormField, { CheckBoxBoolean } from 'components/form_fields'
import { getDefaultServiceAccountId } from './utils'

export const GCPAdditionalSettings = ({
  app,
  errors,
  fields,
  onInputChange,
  styles,
  onCheckboxClick,
  editAdditionalSettings,
}) => {
  return (
    <>
      <Col md={2}>
        <strong>Project ID for creating Service Accounts</strong>
      </Col>
      <Col md={7}>
        <Row style={{ marginBottom: '5px' }}>
          <Col md={12}>
            <CheckBoxBoolean
              name="useDefaultProjectIdForServiceAccount"
              label="Use App Default Project ID"
              onChange={onCheckboxClick}
              className="form-group"
              checked={fields.useDefaultProjectIdForServiceAccount}
              disabled={!editAdditionalSettings}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormField
              id="projectIdForServiceAccount"
              name="projectIdForServiceAccount"
              placeholder=""
              value={
                fields.useDefaultProjectIdForServiceAccount
                  ? getDefaultServiceAccountId(app)
                  : fields.projectIdForServiceAccount
              }
              hideLabel={true}
              onChange={onInputChange}
              disabled={
                editAdditionalSettings
                  ? fields.useDefaultProjectIdForServiceAccount
                  : true
              }
            />
            {errors.projectIdForServiceAccount && (
              <span
                style={styles.error}
                data-testid="gcp-additional-settings-input-error"
              >
                {errors.projectIdForServiceAccount}
              </span>
            )}
          </Col>
        </Row>
      </Col>
    </>
  )
}
