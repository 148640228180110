import React, { memo } from 'react'
import FontAwesome from 'react-fontawesome'
import get from 'lodash/get'

import ORGANIZATION from 'static/images/cloud_entity_icons/gcp/organization.svg'
import PROJECT from 'static/images/cloud_entity_icons/gcp/project.svg'
import FOLDER from 'static/images/cloud_entity_icons/gcp/folder.svg'
import DIRECTORY from 'static/images/cloud_entity_icons/azure/directory.svg'
import MANAGEMENT_GROUP from 'static/images/cloud_entity_icons/azure/management_group.svg'
import SUBSCRIPTION from 'static/images/cloud_entity_icons/azure/subscription.svg'
import RESOURCE_GROUP from 'static/images/cloud_entity_icons/azure/resource_group.svg'
import RESOURCE from 'static/images/cloud_entity_icons/azure/resource.svg'
import COMPARTMENT from 'static/images/cloud_entity_icons/oci/compartment.svg'

const styles = {
  basic: {
    height: 12,
    width: 16,
    marginRight: 6,
    marginLeft: 5,
  },
  azure: {
    height: 21,
    width: 17,
    marginRight: 4,
    marginLeft: 6,
  },
  gcp: {
    height: 16,
    width: 16,
    marginBottom: 4,
    marginRight: 4,
    marginLeft: 6,
  },
  folder: {
    color: '#F0E68C',
  },
  monitor: {
    color: '#236B8E',
    fontSize: 12,
  },
  globe: {
    color: '#50A6C2',
  },
}

const { basic, azure, gcp, folder, monitor, globe } = styles

const folderStyling = { ...basic, ...folder }
const environment = { ...basic, ...monitor }
const organization = { ...basic, ...globe }

const IMAGE_DICT = {
  Organization: (
    <FontAwesome name="globe-americas" alt="organization" style={organization} />
  ),
  OrganizationalUnit: <FontAwesome name="folder" style={folderStyling} alt="ou" />,
  EnvironmentGroup: <FontAwesome name="folder" style={folderStyling} alt="group" />,
  Environment: <FontAwesome name="desktop" style={environment} alt="env" />,
  environment: <FontAwesome name="desktop" style={environment} alt="env" />,
  group: <FontAwesome name="folder" style={folderStyling} alt="group" />,
  Account: <FontAwesome name="desktop" style={environment} alt="account" />,
  AWSAccount: <FontAwesome name="desktop" style={environment} alt="aws account" />,
  Directory: <img src={DIRECTORY} alt="directory" style={azure} />,
  ManagementGroup: <img src={MANAGEMENT_GROUP} alt="mgmt group" style={azure} />,
  Subscription: <img src={SUBSCRIPTION} alt="subscription" style={azure} />,
  ResourceGroup: <img src={RESOURCE_GROUP} alt="resource group" style={azure} />,
  Resource: <img src={RESOURCE} alt="resource" style={azure} />,
  folders: <img src={FOLDER} alt="folder" style={gcp} />,
  projects: <img src={PROJECT} alt="project" style={gcp} />,
  organizations: <img src={ORGANIZATION} alt="organization" style={gcp} />,
  tenancy: <img src={ORGANIZATION} alt="organization" style={gcp} />,
  compartment: <img src={COMPARTMENT} alt="compartment" style={gcp} />,
}

function EntityIcon({ entity, entities = {}, id }) {
  let entityType

  if (entity) {
    entityType = entity.type
  } else {
    entityType = get(entities[id], 'type', {})
  }

  const image = IMAGE_DICT[entityType]

  return image || null
}

export default memo(EntityIcon)
