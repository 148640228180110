import * as actions from 'actions'
export const identityProvidersReducerKey = 'identityProviders'

const initialState = {
  fetchingIdentityProviders: false,
  fetchingIdentityProvider: false,
  fetchingSCIMProviders: false,
  fetchingSSOProviders: false,
  fetchingSCIMAttributes: false,
  fetchedSCIMAttributes: false,
  deletingIdentityProvider: false,
  updatingSCIMUserMapping: false,
  identityProviders: [],
  identityProvider: {},
  scimProviders: [],
  ssoProviders: [],
  scimAttributes: [],
  scimUserMapping: [],
  addingIdentityProvider: false,
  addedIdentityProvider: {},
  isUploadingMetadata: false,
  isCreatingSCIMToken: false,
  isDeletingSCIMToken: false,
  scimToken: null,
  error: null,
}

export function identityProvidersReducer(state = initialState, action) {
  switch (action.type) {
    case `${actions.GET_IDENTITY_PROVIDERS}_PENDING`: {
      return {
        ...state,
        fetchingIdentityProviders: true,
      }
    }

    case `${actions.GET_IDENTITY_PROVIDERS}_FULFILLED`: {
      const identityProviders = action.payload.data

      return {
        ...state,
        fetchingIdentityProviders: false,
        identityProviders,
      }
    }

    case `${actions.GET_IDENTITY_PROVIDERS}_REJECTED`: {
      return {
        ...state,
        fetchingIdentityProviders: false,
        error: action.payload,
      }
    }

    case `${actions.GET_OIDC_IDENTITY_PROVIDERS}_PENDING`: {
      return {
        ...state,
        fetchingOidcIdentityProviders: true,
      }
    }

    case `${actions.GET_OIDC_IDENTITY_PROVIDERS}_FULFILLED`: {
      const oidcIdentityProviders = action.payload.data

      return {
        ...state,
        fetchingOidcIdentityProviders: false,
        oidcIdentityProviders,
      }
    }

    case `${actions.GET_OIDC_IDENTITY_PROVIDERS}_REJECTED`: {
      return {
        ...state,
        fetchingOidcIdentityProviders: false,
        error: action.payload,
      }
    }

    case `${actions.GET_IDENTITY_PROVIDER}_PENDING`: {
      return {
        ...state,
        fetchingIdentityProvider: true,
      }
    }

    case `${actions.GET_IDENTITY_PROVIDER}_FULFILLED`: {
      const identityProvider = action.payload.data

      return {
        ...state,
        fetchingIdentityProvider: false,
        identityProvider,
      }
    }

    case `${actions.GET_IDENTITY_PROVIDER}_REJECTED`: {
      return {
        ...state,
        fetchingIdentityProvider: false,
        error: action.payload,
      }
    }

    case `${actions.GET_SCIM_PROVIDERS}_PENDING`: {
      return {
        ...state,
        fetchingSCIMProviders: true,
      }
    }

    case `${actions.GET_SCIM_PROVIDERS}_FULFILLED`: {
      const scimProviders = action.payload.data

      return {
        ...state,
        fetchingSCIMProviders: false,
        scimProviders,
      }
    }

    case `${actions.GET_SCIM_PROVIDERS}_REJECTED`: {
      return {
        ...state,
        fetchingSCIMProviders: false,
        error: action.payload,
      }
    }

    case `${actions.GET_SSO_PROVIDERS}_PENDING`: {
      return {
        ...state,
        fetchingSSOProviders: true,
      }
    }

    case `${actions.GET_SSO_PROVIDERS}_FULFILLED`: {
      const ssoProviders = action.payload.data

      return {
        ...state,
        fetchingSSOProviders: false,
        ssoProviders,
      }
    }

    case `${actions.GET_SSO_PROVIDERS}_REJECTED`: {
      return {
        ...state,
        fetchingSSOProviders: false,
        error: action.payload,
      }
    }

    case `${actions.ADD_IDENTITY_PROVIDER}_PENDING`: {
      return {
        ...state,
        addingIdentityProvider: true,
      }
    }

    case `${actions.ADD_IDENTITY_PROVIDER}_FULFILLED`: {
      const addedIdentityProvider = action.payload.data
      return {
        ...state,
        addingIdentityProvider: false,
        addedIdentityProvider,
      }
    }

    case `${actions.ADD_IDENTITY_PROVIDER}_REJECTED`: {
      return {
        ...state,
        addingIdentityProvider: false,
        error: action.payload,
      }
    }
    case `${actions.UPDATE_IDENTITY_PROVIDER}_PENDING`: {
      return {
        ...state,
        addingIdentityProvider: true,
      }
    }

    case `${actions.UPDATE_IDENTITY_PROVIDER}_FULFILLED`: {
      const addedIdentityProvider = action.payload.data
      return {
        ...state,
        addingIdentityProvider: false,
        addedIdentityProvider,
      }
    }

    case `${actions.UPDATE_IDENTITY_PROVIDER}_REJECTED`: {
      return {
        ...state,
        addingIdentityProvider: false,
        error: action.payload,
      }
    }

    case `${actions.DELETE_IDENTITY_PROVIDER}_PENDING`: {
      return {
        ...state,
        deletingIdentityProvider: true,
      }
    }

    case `${actions.DELETE_IDENTITY_PROVIDER}_FULFILLED`: {
      const { id, isWorkloadProvider } = action.meta

      if (isWorkloadProvider) {
        const oldOIDCIdentityProviders = [...state.oidcIdentityProviders]
        const oidcIdentityProviders = oldOIDCIdentityProviders.filter(
          identityProvider => identityProvider.id !== Number(id)
        )

        return {
          ...state,
          deletingIdentityProvider: false,
          oidcIdentityProviders,
        }
      }

      const oldIdentityProviders = [...state.identityProviders]
      const identityProviders = oldIdentityProviders.filter(
        identityProvider => identityProvider.id !== id
      )

      return {
        ...state,
        deletingIdentityProvider: false,
        identityProviders,
      }
    }

    case `${actions.DELETE_IDENTITY_PROVIDER}_REJECTED`: {
      return {
        ...state,
        deletingIdentityProvider: false,
        error: action.payload,
      }
    }

    case `${actions.UPLOAD_SAML_METADATA}_PENDING`: {
      return {
        ...state,
        isUploadingMetadata: true,
        metadataInfo: {},
        error: null,
      }
    }

    case `${actions.UPLOAD_SAML_METADATA}_FULFILLED`: {
      return {
        ...state,
        isUploadingMetadata: false,
        metadataInfo: action.payload.data,
        error: null,
      }
    }

    case `${actions.UPLOAD_SAML_METADATA}_REJECTED`: {
      return {
        ...state,
        isUploadingMetadata: false,
        error: action.payload.data,
      }
    }

    case `${actions.CREATE_SCIM_TOKEN}_PENDING`: {
      return {
        ...state,
        isCreatingSCIMToken: true,
      }
    }

    case `${actions.CREATE_SCIM_TOKEN}_FULFILLED`: {
      return {
        ...state,
        isCreatingSCIMToken: false,
        scimToken: action.payload.data,
        error: null,
      }
    }

    case `${actions.CREATE_SCIM_TOKEN}_REJECTED`: {
      return {
        ...state,
        isCreatingSCIMToken: false,
        error: action.payload.data,
      }
    }

    case `${actions.UPDATE_SCIM_TOKEN}_PENDING`: {
      return {
        ...state,
        isCreatingSCIMToken: true,
      }
    }

    case `${actions.UPDATE_SCIM_TOKEN}_FULFILLED`: {
      return {
        ...state,
        isCreatingSCIMToken: false,
        error: null,
      }
    }

    case `${actions.UPDATE_SCIM_TOKEN}_REJECTED`: {
      return {
        ...state,
        isCreatingSCIMToken: false,
        error: action.payload.data,
      }
    }

    case `${actions.REVOKE_SCIM_TOKEN}_PENDING`: {
      return {
        ...state,
        isDeletingSCIMToken: true,
      }
    }

    case `${actions.REVOKE_SCIM_TOKEN}_FULFILLED`: {
      return {
        ...state,
        isDeletingSCIMToken: false,
        error: null,
      }
    }

    case `${actions.REVOKE_SCIM_TOKEN}_REJECTED`: {
      return {
        ...state,
        isDeletingSCIMToken: false,
        error: action.payload.data,
      }
    }

    case `${actions.GET_SCIM_ATTRIBUTES}_PENDING`: {
      return {
        ...state,
        fetchingSCIMAttributes: true,
        fetchedSCIMAttributes: false,
      }
    }

    case `${actions.GET_SCIM_ATTRIBUTES}_FULFILLED`: {
      const scimAttributes = action.payload.data
      return {
        ...state,
        fetchingSCIMAttributes: false,
        fetchedSCIMAttributes: true,
        scimAttributes,
      }
    }

    case `${actions.GET_SCIM_ATTRIBUTES}_REJECTED`: {
      return {
        ...state,
        fetchingSCIMAttributes: false,
        fetchedSCIMAttributes: false,
        error: action.payload,
      }
    }

    case `${actions.UPDATE_SCIM_USER_MAPPING}_PENDING`: {
      return {
        ...state,
        updatingSCIMUserMapping: true,
      }
    }

    case `${actions.UPDATE_SCIM_USER_MAPPING}_FULFILLED`: {
      const scimUserMapping = action.payload.data

      return {
        ...state,
        updatingSCIMUserMapping: false,
        scimUserMapping,
      }
    }

    case `${actions.UPDATE_SCIM_USER_MAPPING}_REJECTED`: {
      return {
        ...state,
        updatingSCIMUserMapping: false,
        error: action.payload,
      }
    }

    default:
      return { ...state }
  }
}
