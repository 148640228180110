import { history } from 'store'
import isKubernetes from 'utils/application/isKubernetes'
import isOpenshift from 'utils/application/isOpenshift'

export function appOverview({ appId, appType }) {
  history.push(
    `/admin/applications/${appId}/${
      isKubernetes(appType) || isOpenshift(appType) ? 'properties' : 'overview'
    }`
  )
}

export function createApplication() {
  history.push(`/admin/applications/create-properties`)
}

export function appData({ appId }) {
  history.push(`/admin/applications/${appId}/data`)
}

export function appProperties({ appId, state = {} }) {
  history.push(`/admin/applications/${appId}/properties`, state)
}

export function createApplicationList() {
  history.push(`/admin/applications/create`)
}

export function appsList() {
  history.push('/admin/tenant-apps')
}

export function appProfiles({ appId }) {
  history.push(`/admin/applications/${appId}/profiles`)
}

export function profileDetails({ appId, profileId, tab, state = {} }) {
  history.push({
    pathname: `/admin/applications/${appId}/profiles/${profileId}/details/${tab}`,
    state,
  })
}

export function createProfile({ appId }) {
  history.push(`/admin/applications/${appId}/profiles/create`)
}

export function requestAccessCreateProfile({ appId }) {
  history.push(`/request-access/applications/${appId}/profiles/create`)
}

export function appSessionActivity({ appId, transactionId }) {
  history.push(`/admin/applications/${appId}/activity/${transactionId}`)
}

export function appScanHistory({ appId }) {
  history.push(`/admin/applications/${appId}/scans`)
}

export function appOrgScanDetails({ appId, orgScanDataSummary }) {
  history.push({
    pathname: `/admin/applications/${appId}/scans/org`,
    state: { orgScanDataSummary },
  })
}

export function appScanDetails({ appId, envId }) {
  history.push(`/admin/applications/${appId}/scans/${envId}`)
}

export function requestAccessProfileDetails({ appId, profileId, tab, mode }) {
  if (mode) {
    if (mode === 'create' || mode === 'draft') {
      history.push(
        `/request-access/applications/${appId}/profiles/${profileId}/details/${tab}/${mode}`
      )
    } else {
      history.push(
        `/request-access/applications/${appId}/profiles/${profileId}/details/${tab}/request`
      )
    }
  } else {
    history.push(
      `/request-access/applications/${appId}/profiles/${profileId}/details/${tab}`
    )
  }
}

export function requestAccessAppProfilesList({ appId }) {
  history.push(`/request-access/applications/${appId}/profiles`)
}

export function approvalRequestDetails({
  source,
  requestId,
  appId,
  profileId,
  tab,
}) {
  history.push(
    `/${source}/view/${requestId}/applications/${appId}/profiles/${profileId}/details/${tab}`
  )
}
