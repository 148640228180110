import React, { memo } from 'react'
import styled from 'styled-components'

import EnvironmentGroups from 'components/EnvironmentGroups'
import ScopeCheckbox from './ScopeCheckbox'

const EnvironmentWrapper = styled.div`
  flex-grow: 1;

  .groups-wrapper {
    padding: 0 5px;

    > div {
      z-index: 0;
    }
  }
`

const Error = styled.div`
  color: red;
  margin-top: -5px;
`

function ScopeSelect({ touched, values, fields, updateScope, errors }) {
  function renderCheckbox(entity, parentSelected, isDisabled) {
    return (
      <ScopeCheckbox
        isDisabled={isDisabled}
        updateScope={updateScope}
        papScope={values.properties.scope}
        parentSelected={parentSelected}
        entity={entity}
      />
    )
  }

  return (
    <EnvironmentWrapper>
      <h6>Select Environments</h6>
      {errors.scope && <Error>{touched.properties && errors.scope}</Error>}
      <div className="groups-wrapper">
        <EnvironmentGroups
          fields={fields}
          checkmarkDict={{ ...values.properties.scope }}
          customRenderers={{
            rootGroupCheckbox: renderCheckbox,
            environmentCheckbox: renderCheckbox,
            groupCheckbox: renderCheckbox,
          }}
          width="fit-content"
          minWidth="100%"
          minHeight="100%"
          isSearchable
        />
      </div>
    </EnvironmentWrapper>
  )
}

export default memo(ScopeSelect)
