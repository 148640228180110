import React, { memo, useCallback } from 'react'
import styled from 'styled-components'
import startCase from 'lodash/startCase'
import pluralize from 'pluralize'
import ReactTooltip from 'react-tooltip'
import { Col, Container, Row } from 'reactstrap'

import SummaryStats from './components/SummaryStats'
import Insights from './components/Insights'
import MaxData from './components/MaxData'
import { TableEllipsisCellWithTooltip } from 'components/table/TableV2/TableEllipsisCell'

import useMaxTableHeight from 'hooks/useMaxTableHeight'

const USER_COLUMNS = [
  { Header: 'Username', accessor: 'nativeUsername' },
  { Header: 'Permissions', accessor: 'permissions', Cell: Permissions },
  { Header: 'Total', accessor: 'totalPermissions' },
]

const USER_DETAIL_COLUMNS = [
  { Header: 'First Name', accessor: 'firstName' },
  { Header: 'Last Name', accessor: 'lastName' },
  { Header: 'Username', accessor: 'nativeUsername' },
  { Header: 'Permission Name', accessor: 'permissionName', minWidth: 300 },
  { Header: 'Type', accessor: 'permissionType', width: 100 },
]

const PERMISSION_COLUMNS = [
  { Header: 'Permission', accessor: 'permissionName' },
  { Header: 'Type', accessor: 'permissionType' },
  { Header: 'Members', accessor: 'totalMembers' },
]

const PERMISSION_DETAIL_COLUMNS = [
  { Header: 'Permission', accessor: 'permissionName', minWidth: 180 },
  { Header: 'Description', accessor: 'permissionDescription' },
  { Header: 'Type', accessor: 'permissionType', width: 100 },
  { Header: 'Member Name', accessor: 'nativeUsername' },
  { Header: 'Member Type', accessor: 'type' },
]

const SCOPE_COLUMN = { Header: 'Scope', accessor: 'scope', minWidth: 250 }

const Wrapper = styled.div`
  padding: 8px;
  overflow-y: scroll;
  overflow-x: hidden;
`

function Permissions({ original: data }) {
  const permissionArray = data.permissions.map(
    ({ permissionType, countOfPermissions }) => {
      return `${countOfPermissions} ${pluralize(
        startCase(permissionType),
        countOfPermissions
      )}`
    }
  )

  return <TableEllipsisCellWithTooltip value={permissionArray.join(', ')} />
}

function Dashboard({ app, appHeaderHeight, supportsScanning, entityType }) {
  const maxHeight = useMaxTableHeight({
    heights: [appHeaderHeight],
    minHeight: 100,
  })

  function hideTooltip() {
    ReactTooltip.hide()
  }

  const wrapperRef = useCallback(node => {
    if (node !== null) {
      node.addEventListener('scroll', hideTooltip)

      return () => {
        node.removeEventListener('scroll', hideTooltip)
      }
    }
  }, [])

  if (!app.rootEnvironmentGroup) {
    return (
      <Wrapper id="appDashboardContainer">
        {supportsScanning
          ? 'Please configure and scan application.'
          : 'Please create and configure an environment'}
      </Wrapper>
    )
  }

  if (
    (supportsScanning && entityType === 'application') ||
    (!supportsScanning && ['application', 'environmentGroup'].includes(entityType))
  ) {
    return (
      <Wrapper id="appDashboardContainer">Please select an environment.</Wrapper>
    )
  }

  let userDetailColumns = USER_DETAIL_COLUMNS
  let permissionDetailColumns = PERMISSION_DETAIL_COLUMNS
  if (app.catalogApplication.requiresHierarchicalModel) {
    userDetailColumns = [...USER_DETAIL_COLUMNS, SCOPE_COLUMN]
    permissionDetailColumns = [...PERMISSION_DETAIL_COLUMNS, SCOPE_COLUMN]
  }

  return (
    <Wrapper id="appDashboardContainer" style={{ maxHeight }} ref={wrapperRef}>
      <SummaryStats />
      <Container fluid={true}>
        <Row>
          <Col>
            <Insights />
          </Col>
          <Col>
            <MaxData
              reportId="xMtK3mj7SKz5fzXCUJRz"
              reportName="Accounts With Delete Access"
              columns={USER_COLUMNS}
              detailColumns={userDetailColumns}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <MaxData
              reportId="ZQ0d9J3ceWrhpPvegrYC"
              reportName="High Risk Accounts"
              columns={USER_COLUMNS}
              detailColumns={userDetailColumns}
            />
          </Col>
          <Col>
            <MaxData
              reportId="a6t0HdlDQ9YWkGjzXSSE"
              reportName="High Risk Permissions"
              columns={PERMISSION_COLUMNS}
              detailColumns={permissionDetailColumns}
            />
          </Col>
        </Row>
      </Container>
    </Wrapper>
  )
}

export default memo(Dashboard)
