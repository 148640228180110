import 'react-app-polyfill/ie11' // For IE 11 support
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import React from 'react'
import ReactDOM from 'react-dom'
import { Helmet } from 'react-helmet'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'

import App from './app'

import ErrorBoundary from 'components/error_boundary'
import setupTenant from 'utils/setupTenant'

import store, { history } from './store'

import { APP_TITLE } from 'constants/htmlHead'

setupTenant()

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ErrorBoundary>
        <div>
          <Helmet defer={false}>
            <title>{APP_TITLE}</title>
          </Helmet>
          <App />
        </div>
      </ErrorBoundary>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
)
