import { extendTheme } from '@chakra-ui/react'

// Let's say you want to add custom colors
const theme = extendTheme({
  fonts: {
    body: 'WorkSans',
  },
  colors: {
    brand: {
      900: '#1661a6',
      700: '#289af5',
      600: '#5896cb',
      500: '#58aff5',
      300: '#a9d0f0',
      200: '#dbeeff',
      100: '#eef9fe',
      50: '#eff5fa',
    },
  },
})

export default theme
