import get from 'lodash/get'

import * as actions from 'actions'
import tenantApp from 'classes/tenant_app.js'

export default function reducer(
  state = {
    fetching: false,
    fetched: false,
    error: null,
    tenantApps: {},
  },
  action
) {
  switch (action.type) {
    case `${actions.USER_TENANT_APPS}_PENDING`: {
      return {
        ...state,
        fetching: true,
      }
    }

    case `${actions.USER_TENANT_APPS}_FULFILLED`: {
      const appList = {}

      get(action, 'payload.data', []).forEach(appData => {
        appList[appData.appContainerId] = new tenantApp(appData)
      })

      return {
        ...state,
        fetching: false,
        fetched: true,
        tenantApps: appList,
      }
    }

    case `${actions.USER_TENANT_APPS}_REJECTED`: {
      return {
        ...state,
        fetching: null,
        fetched: null,
        error: action.payload,
      }
    }

    default:
      return { ...state }
  }
}
