import get from 'lodash/get'
import * as actions from 'actions'

const initialState = {
  loading: false,
  error: null,
  data: {},
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case `${actions.GET_RESOURCE_BY_ID}_PENDING`: {
      return {
        ...state,
        loading: true,
        error: null,
      }
    }

    case `${actions.GET_RESOURCE_BY_ID}_FULFILLED`: {
      const { resourceId } = action.meta
      const data = get(action, 'payload.data')

      return {
        ...state,
        loading: false,
        error: null,
        data: {
          ...state.data,
          [resourceId]: data,
        },
      }
    }

    case `${actions.GET_RESOURCE_BY_ID}_REJECTED`: {
      return {
        ...state,
        loading: false,
        error: 'Unable fetch the Brokers.',
      }
    }

    case actions.REMOVE_CACHED_RESOURCE_BY_ID: {
      return initialState
    }

    default:
      return { ...state }
  }
}
