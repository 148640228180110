import * as actions from 'actions'

const initialState = {
  associationsListLoading: false,
  associationsListData: [],
  addAssociationsLoading: false,
}

export function associationsReducer(state = initialState, action) {
  switch (action.type) {
    case `${actions.PROFILE_MANAGEMENT_GET_ASSOCIATIONS}_PENDING`: {
      return {
        ...state,
        associationsListLoading: true,
      }
    }

    case `${actions.PROFILE_MANAGEMENT_GET_ASSOCIATIONS}_FULFILLED`: {
      const associationsList = Object.entries(
        action.payload?.data?.associations || {}
      ).map(([key, values]) => ({
        key,
        values,
      }))

      return {
        ...state,
        associationsListLoading: false,
        associationsListData: associationsList,
      }
    }

    case `${actions.PROFILE_MANAGEMENT_GET_ASSOCIATIONS}_REJECTED`: {
      return {
        ...state,
        associationsListLoading: false,
      }
    }

    case `${actions.PROFILE_MANAGEMENT_ADD_ASSOCIATIONS}_PENDING`: {
      return {
        ...state,
        addAssociationsLoading: true,
      }
    }

    case `${actions.PROFILE_MANAGEMENT_ADD_ASSOCIATIONS}_FULFILLED`: {
      return {
        ...state,
        addAssociationsLoading: false,
      }
    }

    case `${actions.PROFILE_MANAGEMENT_ADD_ASSOCIATIONS}_REJECTED`: {
      return {
        ...state,
        addAssociationsLoading: false,
      }
    }

    default:
      return { ...state }
  }
}
