import React from 'react'
import { useParams } from 'react-router-dom'
import { capitalize } from 'lodash'
import { BsCheckCircle, BsXCircle } from 'react-icons/bs'
import Typography from 'britive-design-system/core/components/typography/typography'
import { classesMobile } from '../constants'
import './ApprovalRequestAcknowledgement.scss'

const ApprovalRequestAcknowledgement = ({ history }) => {
  const { status } = useParams()

  return (
    <div className={classesMobile.approvalRequestSuccessContainer}>
      <div
        className={classesMobile.approvalRequestSeccessContent}
        onClick={() => {
          history.push('/my-approvals')
        }}
      >
        {status === 'approved' ? (
          <span className={`${classesMobile.checkIcon} ${classesMobile.approved}`}>
            <BsCheckCircle />
          </span>
        ) : null}
        {status === 'rejected' ? (
          <span className={`${classesMobile.checkIcon} ${classesMobile.rejected}`}>
            <BsXCircle />
          </span>
        ) : null}
        <span className={classesMobile.successMessage}>
          <Typography variant="label2">Request is {capitalize(status)}</Typography>
        </span>
      </div>
    </div>
  )
}

export default ApprovalRequestAcknowledgement
