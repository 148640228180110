import React, { PureComponent, Fragment } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import styled from 'styled-components'
import {
  fetchPap,
  fetchPapAdditionalSettings,
  updateAdditionalSettings,
  updatePap,
} from 'action_creators/pap'
import {
  Container,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from 'reactstrap'

import Button from 'britive-ui-components/core/components/Button'
import Spinner from 'britive-ui-components/core/components/Spinner'

import FormField, { CheckBoxBoolean, RadioButtons } from 'components/form_fields'
import {
  getTimeDurationFromSeconds,
  getSecondsFromTimeDuration,
} from 'utils/format_date'

import './index.css'
import { isAWSWithoutIDC, isGCP, isOkta, showAdditionalSettings } from './utils'
import { GCPAdditionalSettings } from './GCPAdditionalSettings'

const Wrapper = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: ${props => props.maxHeight}px;
`

const ADMIN_COMMUNICATION_ENUM = {
  DEFAULT: 'Default',
  true: 'Yes',
  false: 'No',
}

class PapProfile extends PureComponent {
  constructor(props) {
    super(props)

    const fields = {
      name: '',
      description: '',
      expirationDuration: 60,
      notificationPriorToExpiration: '5',
      extendable: false,
      extensionDuration: 30,
      extensionLimit: '1',
      status: 'active',
      destinationUrl: '',
      useDefaultAppUrl: true,
      excludeAdminFromAdminRelatedCommunication: 'DEFAULT',
    }
    this.state = {
      editGeneral: Boolean(props.createMode),
      editExpiration: Boolean(props.createMode),
      editAdditionalSettings: Boolean(props.createMode),
      fields,
      errors: {},
      creationError: null,
      lastSaved: fields,
      expirationTimeoutDuration: '',
    }
  }

  async componentDidMount() {
    const { dispatch, profileId, app, requestAccess } = this.props
    if (profileId) {
      await dispatch(fetchPap(profileId, true, requestAccess))
      if (app.catalogApplication.supportsProfilePermissionConstraints) {
        dispatch(fetchPapAdditionalSettings(profileId, requestAccess))
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {
      app,
      pap = {},
      papAdditionalSettings = {},
      setBreadcrumbTrail,
      isClonedProfile,
    } = this.props
    if (
      (!isEmpty(pap) && !isEqual(prevProps.pap, pap)) ||
      (!isEmpty(papAdditionalSettings) &&
        !isEqual(prevProps.papAdditionalSettings, papAdditionalSettings))
    ) {
      !isClonedProfile &&
        setBreadcrumbTrail([
          { title: `${app.catalogAppDisplayName} Profiles`, path: 'profiles' },
          { title: `${pap.name} Profile Details` },
        ])
      const expirationSettings = get(
        app,
        'catalogApplication.papExpirationSettings',
        {}
      )

      const appProperties = get(app, 'catalogApplication.propertyTypes', [])

      const maxSessionDurationForProfiles =
        appProperties.find(prop => prop.name === 'maxSessionDurationForProfiles')
          ?.value || expirationSettings.maximumSessionTime

      const fields = {
        name: isClonedProfile ? `copy_of_${pap.name}` : pap.name || '',
        description: pap.description || '',
        expirationDuration: pap.expirationDuration || maxSessionDurationForProfiles,
        notificationPriorToExpiration: Number.isInteger(
          pap.notificationPriorToExpiration
        )
          ? this.minutesToMilliseconds(pap.notificationPriorToExpiration, true)
          : '5',
        extendable: pap.extendable || false,
        extensionDuration: Number.isInteger(pap.extensionDuration)
          ? this.minutesToMilliseconds(pap.extensionDuration, true)
          : Math.min(expirationSettings.sessionTimeDuringExtension / 60, 30),
        extensionLimit: pap.extensionLimit || '1',
        status: isClonedProfile ? 'active' : pap.status ? undefined : 'active',
        destinationUrl: pap.destinationUrl,
        useDefaultAppUrl: pap.useDefaultAppUrl,
        excludeAdminFromAdminRelatedCommunication:
          papAdditionalSettings?.excludeAdminFromAdminRelatedCommunication ||
          'DEFAULT',
        projectIdForServiceAccount:
          papAdditionalSettings?.projectIdForServiceAccount,
        useDefaultProjectIdForServiceAccount: isEmpty(
          papAdditionalSettings?.projectIdForServiceAccount
        ),
      }
      this.setState({
        fields,
        lastSaved: fields,
        expirationTimeoutDuration: getTimeDurationFromSeconds(
          fields.expirationDuration / 1000,
          !isAWSWithoutIDC(app.catalogAppName)
        ),
      })
    }
  }

  onUpdatePap = (appId, papId, properties) => {
    const { dispatch, requestAccess } = this.props
    return dispatch(updatePap(appId, papId, properties, requestAccess))
  }

  onUpdateAdditionalSettings = async () => {
    const { dispatch, pap, requestAccess, app } = this.props
    const {
      fields: {
        excludeAdminFromAdminRelatedCommunication,
        projectIdForServiceAccount,
        useDefaultProjectIdForServiceAccount,
      },
    } = this.state
    const settingsToUpdate = {}

    if (isGCP(app.catalogAppName)) {
      settingsToUpdate.projectIdForServiceAccount = useDefaultProjectIdForServiceAccount
        ? ''
        : projectIdForServiceAccount
    }

    if (isOkta(app.catalogAppName)) {
      settingsToUpdate.excludeAdminFromAdminRelatedCommunication = excludeAdminFromAdminRelatedCommunication
    }

    try {
      await dispatch(
        updateAdditionalSettings(pap.papId, settingsToUpdate, requestAccess)
      )
      const updatedFields = {
        editAdditionalSettings: false,
        fields: {
          ...this.state.fields,
          useDefaultProjectIdForServiceAccount:
            useDefaultProjectIdForServiceAccount || !projectIdForServiceAccount,
        },
        errors: {
          projectIdForServiceAccount: '',
          excludeAdminFromAdminRelatedCommunication: '',
        },
      }
      this.setState(updatedFields)
      this.updateLastSaved()
    } catch {
      this.setState({
        errors: {
          projectIdForServiceAccount: this.props.updatePapAdditionalSettingsError,
          excludeAdminFromAdminRelatedCommunication: this.props
            .updatePapAdditionalSettingsError,
        },
      })
    }
  }

  updateLastSaved() {
    return this.setState({ lastSaved: this.state.fields })
  }

  minutesToMilliseconds(value, invert = false) {
    return invert ? value / 60000 : value * 60000
  }

  clearError = () => {
    this.setState({ creationError: null })
  }

  isFormValid = section => {
    const { app } = this.props
    const {
      name,
      expirationDuration,
      notificationPriorToExpiration,
      extendable,
      extensionDuration,
      extensionLimit,
      useDefaultAppUrl,
      destinationUrl,
    } = this.state.fields
    const errors = {}

    const expirationSettings = get(
      app,
      'catalogApplication.papExpirationSettings',
      {}
    )

    this.setState({
      errors,
    })

    if (section === 'general' || section === 'all') {
      if (name.trim() === '') {
        errors.name = 'Name is required'
      }
      if (!useDefaultAppUrl && (!destinationUrl || destinationUrl.trim() === '')) {
        errors.destinationUrl = 'Console URL is required'
      }
    }

    if (section === 'expiration' || section === 'all') {
      try {
        getSecondsFromTimeDuration(
          this.state.expirationTimeoutDuration,
          !isAWSWithoutIDC(app.catalogAppName)
        )

        if (!expirationDuration) {
          errors.expirationDuration = 'Expiration duration is required'
        }

        const appProperties = get(app, 'catalogApplication.propertyTypes', [])

        const maxLimit =
          appProperties.find(prop => prop.name === 'maxSessionDurationForProfiles')
            ?.value || expirationSettings.maximumSessionTime

        const minLimit = expirationSettings.minimumSessionTime || 0

        const maxExtensionLimit =
          (expirationSettings.sessionTimeDuringExtension || 1800) / 60.0

        const extensionLimitMax =
          expirationSettings.numberOfSessionExtensionsAllowed || 1

        if (
          expirationDuration > maxLimit * 1000 ||
          expirationDuration < minLimit * 1000
        ) {
          errors.expirationDuration = `Expiration duration must be between ${getTimeDurationFromSeconds(
            minLimit,
            !isAWSWithoutIDC(app.catalogAppName)
          )} and ${getTimeDurationFromSeconds(
            maxLimit,
            !isAWSWithoutIDC(app.catalogAppName)
          )}`
        }

        if (notificationPriorToExpiration === '') {
          errors.notificationPriorToExpiration =
            'Notification prior to expiration is required'
        }

        if (
          parseInt(notificationPriorToExpiration) >= parseInt(expirationDuration) ||
          parseInt(notificationPriorToExpiration) <= 0
        ) {
          errors.notificationPriorToExpiration =
            'Notification must be at least 1 minute and less than the expiration timeout'
        }

        if (extendable) {
          if (extensionDuration === '') {
            errors.extensionDuration = 'Extension duration is required'
          }

          if (extensionDuration > maxExtensionLimit || extensionDuration < 1) {
            errors.extensionDuration = `Extension duration must be between 1 and ${maxExtensionLimit} minutes`
          }

          if (extensionLimit === '') {
            errors.extensionLimit = 'Extension limit is required'
          }

          if (extensionLimit > extensionLimitMax || extensionLimit < 1) {
            errors.extensionLimit = `Extension limit can be from 1 to ${extensionLimitMax}`
          }
        }
      } catch (error) {
        errors.expirationDuration = error.message
      }
    }

    this.setState({
      errors,
    })

    return isEmpty(errors)
  }

  handleChange = event => {
    const { name, value, type, checked } = event.target

    this.setState({
      fields: {
        ...this.state.fields,
        [name]: type === 'checkbox' ? checked : value,
      },
    })
  }

  onTimeDurationChange = e => {
    this.setState({
      expirationTimeoutDuration: e.target.value,
      errors: {
        expirationDuration: '',
      },
    })
  }

  onTimeDurationFocus = e => {
    if (!this.state.expirationTimeoutDuration) {
      e.target.value = !isAWSWithoutIDC(this.props.app.catalogAppName)
        ? '00:00:00'
        : '00:00'
    }
  }

  onTimeDurationBlur = e => {
    if (e.target.value === '00:00:00' || e.target.value === '00:00') {
      e.target.value = null
    }

    this.setState({
      errors: {
        expirationDuration: '',
      },
    })
    try {
      const event = { ...e }
      event.target.value =
        getSecondsFromTimeDuration(
          this.state.expirationTimeoutDuration,
          !isAWSWithoutIDC(this.props.app.catalogAppName)
        ) * 1000
      this.handleChange(event)
    } catch (error) {
      this.setState({
        errors: {
          expirationDuration: error.message,
        },
      })
    }
  }

  submitProfile = async () => {
    const { onCreate } = this.props
    if (this.isFormValid('all')) {
      try {
        await onCreate(this.state.fields)
      } catch (error) {
        const message = get(
          error,
          'response.data.message',
          'Unknown error. Try again'
        )
        this.setState({ creationError: message })
      }
    }
  }

  cancelProfile = () => {
    const { onCancel } = this.props
    onCancel()
  }

  isFieldValid = fieldName => {
    return !this.state.errors[fieldName]
  }

  updatePapState(state, value) {
    this.setState({
      [state]: value,
    })
  }

  async onUpdateSubmit(stateKey, updatedStateValue) {
    const { app, pap } = this.props

    try {
      await this.onUpdatePap(app.appContainerId, pap.papId, this.state.fields)
    } catch (error) {
      const message = get(error, 'response.data.message', 'Unknown error. Try again')

      this.setState({ creationError: message })
      return
    }

    this.updatePapState(stateKey, updatedStateValue)
    return this.updateLastSaved()
  }
  onUseBritiveDestinationUrlClick = event => {
    const useDefaultAppUrl = event.target.checked
    const { fields } = this.state
    this.setState({
      fields: {
        ...fields,
        useDefaultAppUrl,
        destinationUrl: useDefaultAppUrl ? null : fields.destinationUrl,
      },
      errors: {
        destinationUrl: '',
      },
    })
  }

  onUseAppDefaultProjectIdClick = event => {
    const useDefaultProjectId = event.target.checked
    const { fields } = this.state
    this.setState({
      fields: {
        ...fields,
        useDefaultProjectIdForServiceAccount: useDefaultProjectId,
      },
      errors: {
        projectIdForServiceAccount: '',
      },
    })
  }

  render() {
    const { container, buttonContainer, mainButtonContainer } = styles
    const {
      createMode,
      app,
      maxHeight,
      fetchingPap,
      thisAppManage,
      fetchingPapAdditionalSettings,
      creatingPap,
      updatingPap,
      updatingPapAdditionalSettings,
      requestAccess,
    } = this.props

    const {
      editGeneral,
      editExpiration,
      editAdditionalSettings,
      fields,
      errors,
      lastSaved,
      creationError,
    } = this.state

    const expirationSettings = get(
      app,
      'catalogApplication.papExpirationSettings',
      {}
    )
    const supportsDestinationUrl = get(
      app,
      'catalogApplication.supportsDestinationUrl',
      {}
    )

    const allowExtenstion = get(expirationSettings, 'supportsSessionExtension', true)
    const addPolicy = window.location.pathname.includes('/details/profile/create')

    return (
      <Wrapper maxHeight={maxHeight}>
        {fetchingPap ||
        (app.catalogApplication.supportsProfilePermissionConstraints &&
          fetchingPapAdditionalSettings) ? (
          <Spinner sixe="2x" />
        ) : (
          <Fragment>
            <Container style={container}>
              <Row>
                <Col style={{ marginBottom: '20px' }}>
                  <h5>General</h5>
                </Col>
              </Row>
              <Row className="input-row">
                <Col md={2}>
                  <strong>Name</strong>
                  <span className="req">*</span>
                </Col>
                <Col md={6}>
                  <FormField
                    disabled={!editGeneral}
                    id="name"
                    name="name"
                    placeholder="Name"
                    value={fields.name}
                    hideLabel={true}
                    onChange={this.handleChange.bind(this)}
                    isFieldInvalid={!this.isFieldValid('name')}
                  />
                  {errors.name && <span style={styles.error}>{errors.name}</span>}
                </Col>
              </Row>
              <Row className="input-row">
                <Col md={2}>
                  <strong>Description</strong>
                </Col>
                <Col md={6}>
                  <FormField
                    disabled={!editGeneral}
                    id="description"
                    name="description"
                    placeholder="Description"
                    value={fields.description}
                    hideLabel={true}
                    onChange={this.handleChange}
                  />
                  {errors.description && (
                    <span style={styles.error}>{errors.description}</span>
                  )}
                </Col>
              </Row>
              {supportsDestinationUrl && (
                <>
                  <Row className="input-row">
                    <Col md={2} />
                    <Col md={6}>
                      <CheckBoxBoolean
                        name="useBritiveDestinationUrl"
                        label="Use Default App Console URL"
                        onChange={this.onUseBritiveDestinationUrlClick}
                        className="form-group"
                        checked={fields.useDefaultAppUrl}
                        disabled={!editGeneral}
                      />
                    </Col>
                  </Row>
                  <Row className="input-row">
                    <Col md={2}>
                      <strong>Console URL</strong>
                    </Col>
                    <Col md={6}>
                      <FormField
                        disabled={fields.useDefaultAppUrl || !editGeneral}
                        id="destinationUrl"
                        name="destinationUrl"
                        placeholder="Default App Console URL"
                        value={
                          fields.useDefaultAppUrl
                            ? app.defaultAppUrl ?? ''
                            : fields.destinationUrl ?? ''
                        }
                        hideLabel={true}
                        onChange={this.handleChange}
                      />
                      {errors.destinationUrl && (
                        <span style={styles.error}>{errors.destinationUrl}</span>
                      )}
                    </Col>
                  </Row>
                </>
              )}
              {thisAppManage &&
                (createMode ? null : (
                  <div style={buttonContainer}>
                    <div>
                      {editGeneral ? (
                        <Fragment>
                          <Button
                            color="default"
                            onClick={() => {
                              return this.setState({
                                editGeneral: false,
                                fields: {
                                  ...fields,
                                  name: lastSaved.name,
                                  description: lastSaved.description,
                                  destinationUrl: lastSaved.destinationUrl,
                                  useDefaultAppUrl: lastSaved.useDefaultAppUrl,
                                },
                                errors: {},
                              })
                            }}
                            className={`mr-4 papProfileDetailsCancel`}
                            disabled={updatingPap}
                          >
                            Cancel
                          </Button>
                          <Button
                            color="primary"
                            className="papProfileDetailsSave"
                            onClick={async () => {
                              if (this.isFormValid('general')) {
                                await this.onUpdateSubmit('editGeneral', false)
                              }
                            }}
                            disabled={updatingPap}
                            spinner={updatingPap}
                          >
                            Save
                          </Button>
                        </Fragment>
                      ) : (
                        (addPolicy || !requestAccess) && (
                          <Button
                            onClick={() => this.setState({ editGeneral: true })}
                            className="papProfileDetailsEdit"
                          >
                            Edit
                          </Button>
                        )
                      )}
                    </div>
                  </div>
                ))}
            </Container>
            <Container style={container}>
              <Row>
                <Col style={{ marginBottom: '20px' }}>
                  <h5>Expiration</h5>
                </Col>
              </Row>
              <Row className="input-row">
                <Col md={2}>
                  <strong>Expiration Timeout</strong>
                  <span className="req">*</span>
                </Col>
                <Col md={3}>
                  <FormField
                    className="small"
                    disabled={!editExpiration}
                    id="expirationDuration"
                    name="expirationDuration"
                    placeholder=""
                    value={this.state.expirationTimeoutDuration}
                    hideLabel
                    onChange={this.onTimeDurationChange}
                    onBlur={this.onTimeDurationBlur}
                    onFocus={this.onTimeDurationFocus}
                    type="text"
                    isFieldInvalid={!this.isFieldValid('expirationDuration')}
                  />
                  {errors.expirationDuration && (
                    <span style={styles.error}>{errors.expirationDuration}</span>
                  )}
                </Col>
                <Col md={4}>
                  In {!isAWSWithoutIDC(app.catalogAppName) ? 'DD:' : ''}HH:MM format
                </Col>
              </Row>

              {allowExtenstion && (
                <Fragment>
                  <Row className="input-row">
                    <Col md={2}>
                      <strong>Expiration Extension</strong>
                    </Col>
                    <Col md={9}>
                      <CheckBoxBoolean
                        name="extendable"
                        onChange={this.handleChange}
                        className="d:inline-block pl-4"
                        checked={fields.extendable}
                        disabled={!editExpiration}
                        label="Allow Extension"
                      />{' '}
                    </Col>
                  </Row>
                  {fields.extendable && (
                    <Fragment>
                      <Row className="input-row">
                        <Col md={2}>
                          <strong>Expiration Notification</strong>
                          <span className="req">*</span>
                        </Col>
                        <Col md={3}>
                          <FormField
                            className="small"
                            disabled={editExpiration ? !fields.extendable : true}
                            id="notificationPriorToExpiration"
                            name="notificationPriorToExpiration"
                            placeholder=""
                            value={fields.notificationPriorToExpiration.toString()}
                            hideLabel={true}
                            onChange={this.handleChange}
                            type="Number"
                            isFieldInvalid={
                              !this.isFieldValid('notificationPriorToExpiration')
                            }
                          />
                          {errors.notificationPriorToExpiration && (
                            <span style={styles.error}>
                              {errors.notificationPriorToExpiration}
                            </span>
                          )}
                        </Col>

                        <Col md={4}>Minutes prior to Expiration</Col>
                      </Row>
                      <Row className="input-row">
                        <Col md={2}>
                          <strong>Expiration Extension</strong>
                          <span className="req">*</span>
                        </Col>
                        <Col md={3}>
                          <FormField
                            className="small"
                            disabled={editExpiration ? !fields.extendable : true}
                            id="extensionDuration"
                            name="extensionDuration"
                            placeholder=""
                            value={fields.extensionDuration.toString()}
                            hideLabel={true}
                            onChange={this.handleChange}
                            type="Number"
                            isFieldInvalid={!this.isFieldValid('extensionDuration')}
                          />
                          {errors.extensionDuration && (
                            <span style={styles.error}>
                              {errors.extensionDuration}
                            </span>
                          )}
                        </Col>
                        <Col md={4}>Minutes</Col>
                      </Row>
                      <Row className="input-row">
                        <Col md={2}>
                          <strong>Extension Repeats</strong>
                          <span className="req">*</span>
                        </Col>
                        <Col md={3}>
                          <FormField
                            className="small"
                            disabled={editExpiration ? !fields.extendable : true}
                            id="extensionLimit"
                            name="extensionLimit"
                            placeholder=""
                            value={fields.extensionLimit.toString()}
                            hideLabel={true}
                            onChange={this.handleChange}
                            type="Number"
                            isFieldInvalid={!this.isFieldValid('extensionLimit')}
                          />
                          {errors.extensionLimit && (
                            <span style={styles.error}>{errors.extensionLimit}</span>
                          )}
                        </Col>
                        <Col md={4}>Times</Col>
                      </Row>
                    </Fragment>
                  )}
                </Fragment>
              )}
              {thisAppManage &&
                (createMode ? null : (
                  <div style={buttonContainer}>
                    <div>
                      {editExpiration ? (
                        <Fragment>
                          <Button
                            color="default"
                            onClick={() =>
                              this.setState({
                                editExpiration: false,
                                fields: {
                                  ...fields,
                                  expirationDuration: lastSaved.expirationDuration,
                                  extendable: lastSaved.extendable,
                                  extensionDuration: lastSaved.extensionDuration,
                                  extensionLimit: lastSaved.extensionLimit,
                                  notificationPriorToExpiration:
                                    lastSaved.notificationPriorToExpiration,
                                },
                                errors: {},
                              })
                            }
                            className="mr-4"
                            disabled={updatingPap}
                          >
                            Cancel
                          </Button>
                          <Button
                            color="primary"
                            onClick={async () => {
                              if (this.isFormValid('expiration')) {
                                await this.onUpdateSubmit('editExpiration', false)
                              }
                            }}
                            disabled={updatingPap}
                            spinner={updatingPap}
                          >
                            Save
                          </Button>
                        </Fragment>
                      ) : (
                        (addPolicy || !requestAccess) && (
                          <Button
                            onClick={() => this.setState({ editExpiration: true })}
                          >
                            Edit
                          </Button>
                        )
                      )}
                    </div>
                  </div>
                ))}
            </Container>

            {createMode && (
              <div style={mainButtonContainer}>
                <div>
                  <Button
                    color="default"
                    onClick={this.cancelProfile}
                    className={`mr-4 papProfileDetailsCancel`}
                    disabled={creatingPap}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    onClick={this.submitProfile}
                    className="papProfileDetailsSave"
                    disabled={creatingPap}
                    spinner={creatingPap}
                  >
                    Done
                  </Button>
                </div>
              </div>
            )}
            {showAdditionalSettings(app.catalogAppName) && !createMode && (
              <Container style={container}>
                <Row>
                  <Col style={{ marginBottom: '20px' }}>
                    <h5>Additional Settings</h5>
                  </Col>
                </Row>
                <Row className="input-row">
                  {isOkta(app.catalogAppName) && (
                    <>
                      <Col md={3}>
                        <strong>
                          Exclude admin from admin related communication
                        </strong>
                      </Col>
                      <Col md={6}>
                        <RadioButtons
                          name="excludeAdminFromAdminRelatedCommunication"
                          onChange={this.handleChange}
                          disabled={!editAdditionalSettings}
                          value={fields.excludeAdminFromAdminRelatedCommunication}
                          enumValues={Object.keys(ADMIN_COMMUNICATION_ENUM)}
                          enumNameMap={ADMIN_COMMUNICATION_ENUM}
                        />
                        {errors.name && (
                          <span style={styles.error}>{errors.name}</span>
                        )}
                      </Col>
                    </>
                  )}
                  {isGCP(app.catalogAppName) && (
                    <GCPAdditionalSettings
                      app={app}
                      errors={errors}
                      fields={this.state.fields}
                      onInputChange={this.handleChange}
                      onCheckboxClick={this.onUseAppDefaultProjectIdClick}
                      styles={styles}
                      editAdditionalSettings={editAdditionalSettings}
                    />
                  )}
                </Row>
                {thisAppManage && (
                  <div style={buttonContainer}>
                    <div>
                      {editAdditionalSettings ? (
                        <Fragment>
                          <Button
                            color="default"
                            onClick={() =>
                              this.setState({
                                editAdditionalSettings: false,
                                fields: {
                                  ...fields,
                                  excludeAdminFromAdminRelatedCommunication:
                                    lastSaved.excludeAdminFromAdminRelatedCommunication,
                                  projectIdForServiceAccount:
                                    lastSaved.projectIdForServiceAccount,
                                  useDefaultProjectIdForServiceAccount:
                                    lastSaved.useDefaultProjectIdForServiceAccount,
                                },
                                errors: {},
                              })
                            }
                            className="mr-4"
                          >
                            Cancel
                          </Button>
                          <Button
                            color="primary"
                            onClick={this.onUpdateAdditionalSettings}
                            disabled={updatingPapAdditionalSettings}
                            spinner={updatingPapAdditionalSettings}
                          >
                            Save
                          </Button>
                        </Fragment>
                      ) : (
                        <Button
                          onClick={() =>
                            this.setState({ editAdditionalSettings: true })
                          }
                        >
                          Edit
                        </Button>
                      )}
                    </div>
                  </div>
                )}
              </Container>
            )}

            <Modal isOpen={!isEmpty(creationError)}>
              <ModalHeader toggle={this.clearError} style={styles.errorHeader} />

              <ModalBody style={styles.errorBody}>
                <p style={styles.errorBodyTitle}>Error:</p>
                {creationError}
              </ModalBody>

              <ModalFooter>
                <Button color="primary" onClick={this.clearError}>
                  Ok
                </Button>
              </ModalFooter>
            </Modal>
          </Fragment>
        )}
      </Wrapper>
    )
  }
}

PapProfile.propTypes = {
  createMode: PropTypes.bool,
  onCreate: PropTypes.func,
  onCancel: PropTypes.func,
  pap: PropTypes.object,
}

const styles = {
  container: {
    backgroundColor: '#FFF',
    padding: 20,
    marginBottom: 20,
    marginLeft: 0,
    marginRight: 0,
    maxWidth: 'none',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  mainButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  error: {
    color: '#dc3545',
    position: 'absolute',
    whiteSpace: 'nowrap',
  },
  errorHeader: {
    borderBottom: 'none',
    padding: '4px 15px 0',
  },
  errorBody: {
    padding: '0 15px 15px',
  },
  errorBodyTitle: {
    fontWeight: 500,
  },
}

const mapStateToProps = state => {
  return {
    pap: state.paps.pap,
    fetchingPap: state.paps.fetchingPap,
    papAdditionalSettings: state.paps.papAdditionalSettings,
    updatePapAdditionalSettingsError: state.paps.updatePapAdditionalSettingsError,
    fetchingPapAdditionalSettings: state.paps.fetchingPapAdditionalSettings,
    creatingPap: state.paps.creatingPap,
    updatingPap: state.paps.updatingPap,
    updatingPapAdditionalSettings: state.paps.updatingPapAdditionalSettings,
  }
}

const withConnect = connect(mapStateToProps)

export default compose(withConnect)(PapProfile)
