import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import get from 'lodash/get'
import startCase from 'lodash/startCase'
import {
  ActionIconLabel,
  ActionIconGroup,
} from 'britive-ui-components/core/components/ActionIcon'
import Button from 'britive-ui-components/core/components/Button'
import Spinner from 'britive-ui-components/core/components/Spinner'
import Typography from 'britive-design-system/core/components/typography'
import ModalPopup from 'britive-design-system/core/components/modal-popup'
import Tooltip from 'britive-design-system/core/components/tooltip'

import TableActionIcon from 'components/TableActionIcon'
import AddPermissionTable from './add-permission-table'
import { getSelectedApplicationEnvironments } from 'selectors/environment'

import { fetchPapPermissions, updatePermission } from 'action_creators/pap'
import IntegrityCheck from '../../IntegrityCheck'
import { getSelectedPapPermissions } from 'routes/admin/state/selectors'
import { getFetchingPapPermissions } from 'selectors/pap'
import { getHeaderSpacing } from 'utils/elementMeasurement'
import Drawer from 'components/Drawer/DrawerContainer'
import EnvironmentGroupsWrapper from '../../components/EnvironmentGroupsWrapper'
import styled from 'styled-components'
import Table from 'components/table/TableV2/Table'
import toast from 'utils/toast'
import messages from 'constants/messages'
import FontAwesome from 'react-fontawesome'
import ConstraintPanelContainer from './ConstraintPanelContainer'
import doFetch from 'utils/do_fetch'
import PermissionsDrawer from '../../components/permission-drawer'
import {
  getEnvironmentListForSelector,
  showPermissionManageIcon,
} from '../../components/permission-drawer/utils'
import { AddPermissionForK8s } from './add-permission-k8s/index'
import TableWithUtils from '../../../AccessRequestSettings/AssignAssociationApprovers/components/Table'
import infoIcon from 'static/images/infoIcon.svg'
import highRisk from 'static/images/high-risk.svg'
import ManagedRole from 'static/images/britive.png'
import './index.scss'
import isKubernetes from 'utils/application/isKubernetes'
import isOpenshift from 'utils/application/isOpenshift'

import PermissionsDetails from '../../../ManagedPermissions/Details/details'
import DialogPopup from 'britive-design-system/core/components/dialog'
import FindingsComponent from './Findings'
import WarningIcon from 'static/images/warning-icon.svg'
import { isAWS } from '../../components/pap-profile/utils'
const classNamePrefix = 'policy-list'

const Heading = styled.h5`
  border-bottom: 2px solid var(--brand);
  padding: 5px 0;
  margin-right: 10px;
`

const Icon = styled.i`
  color: var(--medium-purple);
`

const SearchIconWrapper = styled.span`
  color: #067fdb;
`

const styles = {
  buttonsWrapper: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: -108,
    right: 0,
    gap: '10px',
  },
  addModal: {
    maxWidth: 1600,
    width: '80%',
  },
  addModalDoneButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: '10px',
  },
}

class PapPermissions extends PureComponent {
  state = {
    availablePermissions: [],
    isAddPermissionModalOpen: false,
    isK8sAddPermissionModalOpen: false,
    availablePermissionsLoading: false,
    permissions: [],
    deletingPermission: {},
    isIntegrityCheckDrawerOpen: false,
    selectedPermissionName: '',
    refresh: 0,
    newPermissionCount: 0,
    viewConstraints: false,
    permissionForConstraint: null,
    isPermissionDrawerOpen: false,
    environmentList: [],
    fetchingPermissionDetails: {
      loading: false,
      name: null,
    },
    selectedPermission: {},
    findingDialog: {
      isOpen: false,
    },
  }

  componentDidMount() {
    const { app, dispatch, profileId, requestAccess } = this.props
    dispatch(fetchPapPermissions(app.appContainerId, profileId, requestAccess))
  }

  componentDidUpdate(prevProps) {
    return this.props.permissions !== prevProps.permissions
      ? this.setState({ permissions: this.props.permissions })
      : null
  }

  addPermission = async permission => {
    const { app, dispatch, profileId, requestAccess } = this.props

    try {
      await dispatch(
        updatePermission(
          app.appContainerId,
          profileId,
          permission,
          'add',
          requestAccess
        )
      )
      this.setState({ newPermissionCount: this.state.newPermissionCount + 1 })

      if (
        isKubernetes(this.props.app?.catalogAppName) ||
        isOpenshift(this.props.app?.catalogAppName)
      ) {
        toast({
          title: `Permission ${permission?.name} added for the profile.`,
          type: 'success',
          time: 'normal',
          id: `k8s-permission-add-success-${profileId}`,
        })
      }
    } catch (error) {
      toast({
        title: error.response?.data?.message,
        type: 'error',
        time: 'normal',
        id: `permission-add-failure-${app.appContainerId}`,
      })
    }
  }

  deletePermission = async permission => {
    const { app, dispatch, profileId, requestAccess } = this.props
    const { deletingPermission, refresh } = this.state
    this.setState({
      deletingPermission: {
        ...deletingPermission,
        [permission.name]: true,
      },
    })

    try {
      await dispatch(
        updatePermission(
          app.appContainerId,
          profileId,
          permission,
          'remove',
          requestAccess
        )
      )
      this.setState({
        deletingPermission: {
          ...deletingPermission,
          [permission.name]: false,
        },
        refresh: refresh + 1,
      })

      toast({
        title: messages.GENERIC_DELETE_RECORD_SUCCESS,
        type: 'success',
        time: 'normal',
        id: `permissions-tab-remove-permission-modal-success-${permission?.name}`,
      })
    } catch {
      toast({
        title: messages.GENERIC_DELETE_RECORD_FAILURE,
        type: 'error',
        time: 'normal',
        id: `permissions-tab-remove-permission-modal-failure-${permission?.name}`,
      })
    }
  }

  onClickAddPermission = () => {
    if (
      isKubernetes(this.props.app?.catalogAppName) ||
      isOpenshift(this.props.app?.catalogAppName)
    ) {
      this.setState({
        isK8sAddPermissionModalOpen: true,
        newPermissionCount: 0,
      })

      return
    }

    this.setState({
      isAddPermissionModalOpen: true,
      newPermissionCount: 0,
    })
  }

  toggleIntegrityCheckDrawer = () => {
    this.setState({
      isIntegrityCheckDrawerOpen: !this.state.isIntegrityCheckDrawerOpen,
    })
  }

  addPermissionCloseModal = () => {
    const { newPermissionCount, refresh } = this.state
    if (
      isKubernetes(this.props.app?.catalogAppName) ||
      isOpenshift(this.props.app?.catalogAppName)
    ) {
      this.setState({
        isK8sAddPermissionModalOpen: false,
        refresh: refresh + 1,
      })
    } else {
      this.setState({
        isAddPermissionModalOpen: false,
        refresh: refresh + 1,
      })
    }

    if (newPermissionCount > 0) {
      toast({
        title: messages.GENERIC_SAVE_SUCCESS,
        type: 'success',
        time: 'normal',
        id: `permissions-tab-add-permission-modal-toast`,
      })
    }
  }

  setSelectedPermissionName = selectedPermissionName => {
    this.setState({ selectedPermissionName })
  }

  toggleAssociatedPermissionsDrawer = async ({ name, type }) => {
    const {
      app,
      pap,
      environmentData,
      papName,
      requestAccess,
      profileId,
    } = this.props

    if (app.catalogApplication?.requiresHierarchicalModel) {
      const envData = Object.values(environmentData)[0]
      this.setState({
        environmentList: [
          {
            title: envData.catalogAppDisplayName,
            value: envData.environmentId,
          },
        ],
        isPermissionDrawerOpen: true,
        selectedPermission: {
          name,
          type,
        },
      })
    } else if (app?.appContainerId && (pap?.name || papName)) {
      this.setState({ fetchingPermissionDetails: { loading: true, name } })
      let path = `/paps/${profileId}?view=includeEnvironments`
      if (requestAccess) {
        path = `/profile-requests${path}`
      }
      const permissionDetails = await doFetch({ path, method: 'get' })
      if (permissionDetails?.data?.environments.length > 0) {
        this.setState({
          environmentList: getEnvironmentListForSelector(
            Object.values(environmentData),
            permissionDetails.data?.environments
          ),
          fetchingPermissionDetails: { loading: false, name: null },
          isPermissionDrawerOpen: true,
          selectedPermission: {
            name,
            type,
          },
        })
      } else {
        this.setState({
          environmentList: [],
          fetchingPermissionDetails: { loading: false, name: null },
        })
      }
    }
  }

  closeModal = () => {
    // resetting everything to initial state
    this.setState({
      selectedPermission: {},
      isPermissionDrawerOpen: false,
    })
  }

  modalButtons = [
    {
      text: 'Close',
      variant: 'secondary',
      onClick: this.closeModal,
      size: 'medium',
    },
  ]

  renderIntegrityChecks = ({ original }) => {
    return (
      <IntegrityCheck
        checkStatus={original.checkStatus}
        message={original.message}
        name={original.name}
        selected={original.name}
        onClick={permission => {
          this.toggleIntegrityCheckDrawer()
          this.setSelectedPermissionName(permission)
        }}
      />
    )
  }

  renderIcon = (title, icon) => (
    <div>
      <Tooltip title={title} position="bottom">
        <span title="">
          <img src={icon} className={`${classNamePrefix}-name-high-risk`} />
        </span>
      </Tooltip>
    </div>
  )

  renderFindingsIcon = (title, original) => (
    <div style={{ cursor: 'pointer' }}>
      <Tooltip title={title} position="right">
        <span>
          <img
            src={WarningIcon}
            onClick={() =>
              this.setState({
                findingDialog: {
                  isOpen: true,
                  name: original?.name,
                },
              })
            }
          />
        </span>
      </Tooltip>
    </div>
  )

  renderPermissionDrawerIcon = permission => {
    const { fetchingPermissionDetails } = this.state
    const isLoading =
      fetchingPermissionDetails.loading &&
      fetchingPermissionDetails.name === permission.name
    if (!showPermissionManageIcon(this.props.app.catalogAppName)) {
      return null
    }
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          cursor: isLoading ? 'not-allowed' : 'pointer',
          justifyContent: this.props.onlyViewPermissions ? 'center' : 'unset',
        }}
      >
        {isLoading ? (
          <Spinner />
        ) : (
          <Tooltip title="View Associated Permissions" position="left">
            <span
              title=""
              onClick={() => {
                if (permission.source === 'britive') {
                  this.setState({
                    selectedPermission: {
                      name: permission.name,
                      type: permission.type,
                      isManaged: true,
                    },
                    isPermissionDrawerOpen: true,
                  })
                } else {
                  this.toggleAssociatedPermissionsDrawer(permission)
                }
              }}
            >
              <img src={infoIcon} style={{ height: '18px' }} />
            </span>
          </Tooltip>
        )}
      </div>
    )
  }

  renderActionIcons = permission => {
    const { match, history, app } = this.props
    return (
      <ActionIconGroup>
        {app.catalogApplication.supportsProfilePermissionConstraints && (
          <TableActionIcon
            style={{
              cursor: !(
                permission.type !== 'group' &&
                permission.profilePermissionConstraintsSummaryDTO
                  ?.supportedConstraintsTypes?.length > 0
              )
                ? 'not-allowed'
                : 'pointer',
            }}
            isDisabled={
              !(
                permission.type !== 'group' &&
                permission.profilePermissionConstraintsSummaryDTO
                  ?.supportedConstraintsTypes?.length > 0
              )
            }
            onClick={() =>
              history.push(
                `${match.url}/${permission.name}/${permission.type}/constraints`
              )
            }
          >
            <FontAwesome name="sliders-h" size="lg" />
            <ActionIconLabel>Manage</ActionIconLabel>
          </TableActionIcon>
        )}
        <TableActionIcon onClick={() => this.deletePermission(permission)}>
          {this.state.deletingPermission[permission.name] ? (
            <Spinner />
          ) : (
            <span className="fa fa-trash fs:14" />
          )}
          <ActionIconLabel>Delete</ActionIconLabel>
        </TableActionIcon>
        {this.renderPermissionDrawerIcon(permission)}
      </ActionIconGroup>
    )
  }

  render() {
    const {
      profileId,
      app,
      thisAppManage,
      permissions,
      dispatch,
      requestAccess,
      onlyViewPermissions,
      fetchingPermissions,
      allPermissions,
      requestApprovals,
      history,
      match,
      featureFlags,
      requestDetails,
    } = this.props
    const {
      refresh,
      isIntegrityCheckDrawerOpen,
      selectedPermissionName,
      isAddPermissionModalOpen,
      isK8sAddPermissionModalOpen,
      viewConstraints,
      permissionForConstraint,
    } = this.state

    const showFindings =
      window?.location?.pathname?.includes('/my-approvals') ||
      window?.location?.pathname?.includes('/my-requests')
    const handleCreatePermissions = () => {
      history.push(`${match.url}/create-role`)
    }
    const addPermission = window.location.pathname.includes(
      '/details/permissions/create'
    )
    const permissionTypes = Object.keys(
      get(app, 'catalogApplication.supportedPermissionTypes', {})
    )
      .filter(p => p !== 'Group')
      .map(p => ({ label: startCase(p), value: p }))

    return (
      <Fragment>
        <div style={styles.buttonsWrapper}>
          {featureFlags?.accessRequest &&
            thisAppManage &&
            (addPermission || !requestAccess) &&
            app?.catalogApplication.supportManagedPermissions && (
              <Button
                color="primary"
                onClick={handleCreatePermissions}
                disabled={permissions.length > 0 && isAWS(app?.catalogAppName)}
              >
                Create Permission
              </Button>
            )}
          {thisAppManage && (addPermission || !requestAccess) && (
            <Button
              color="primary"
              onClick={this.onClickAddPermission}
              disabled={
                permissions.length > 0 &&
                (app.catalogAppName === 'AWS' ||
                  app.catalogAppName === 'AWS Standalone')
              }
            >
              {isKubernetes(app?.catalogAppName) || isOpenshift(app?.catalogAppName)
                ? 'Add'
                : 'Select'}{' '}
              Permission
            </Button>
          )}
        </div>
        {requestAccess && onlyViewPermissions ? (
          <TableWithUtils
            tableData={allPermissions}
            isLoading={fetchingPermissions}
            columns={[
              {
                sortable: true,
                headerName: 'Name',
                field: 'name',
              },
              {
                sortable: true,
                headerName: 'Description',
                field: 'description',
              },
              {
                sortable: true,
                headerName: 'Type',
                field: 'type',
              },
              {
                headerName: 'Action',
                sortable: false,
                renderColumn: data => this.renderPermissionDrawerIcon(data),
                width: 80,
              },
            ]}
          />
        ) : (
          <Table
            columns={[
              {
                Header: 'Name',
                accessor: 'name',
                minWidth: 160,
                Cell: ({ original }) => {
                  return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {original?.name}
                      &nbsp;
                      {original?.privileged &&
                        this.renderIcon('High Risk', highRisk)}
                      &nbsp;
                      {original?.source === 'britive' &&
                        this.renderIcon('Britive Managed Permission', ManagedRole)}
                      &nbsp;
                      {original?.name === requestDetails?.context?.showWarnings &&
                        showFindings &&
                        this.renderFindingsIcon('Findings', original)}
                    </div>
                  )
                },
              },
              { Header: 'Description', accessor: 'description', minWidth: 300 },
              {
                Header: 'Type',
                accessor: 'type',
                minWidth: 100,
                Cell: ({ value }) => startCase(value),
              },
              ...(app.catalogApplication.supportsProfilePermissionConstraints
                ? [
                    {
                      Header: 'Constraints',
                      accessor: 'profilePermissionConstraintsSummaryDTO',
                      Cell: ({ original }) => {
                        return (
                          <ActionIconGroup>
                            {original?.profilePermissionConstraintsSummaryDTO
                              ?.hasConstraints ? (
                              <TableActionIcon
                                id="appEnvironmentCount"
                                onClick={() =>
                                  this.setState(
                                    {
                                      permissionForConstraint: original,
                                    },
                                    () => {
                                      this.setState({
                                        viewConstraints: true,
                                      })
                                    }
                                  )
                                }
                              >
                                <SearchIconWrapper>
                                  <FontAwesome name="search" />
                                </SearchIconWrapper>
                              </TableActionIcon>
                            ) : null}
                          </ActionIconGroup>
                        )
                      },
                      width: 100,
                    },
                  ]
                : []),
              ...(!requestAccess
                ? [
                    {
                      Header: 'Integrity Check',
                      accessor: 'checkStatus',
                      Cell: this.renderIntegrityChecks,
                      width: 160,
                      sortable: false,
                    },
                  ]
                : []),
              ...(thisAppManage && (addPermission || !requestAccess)
                ? [
                    {
                      Header: 'Actions',
                      accessor: 'actions',
                      sortable: false,
                      Cell: data => this.renderActionIcons(data.original),
                      resizable: false,
                      minWidth: 80,
                    },
                  ]
                : []),

              ...(!addPermission && (requestApprovals || requestAccess)
                ? [
                    {
                      Header: 'Action',
                      accessor: 'actions',
                      sortable: false,
                      Cell: data => this.renderPermissionDrawerIcon(data.original),
                      resizable: false,
                      minWidth: 80,
                    },
                  ]
                : []),
            ]}
            identifier="name"
            dataUrl={
              requestAccess
                ? `/profile-requests/paps/${profileId}/permissions`
                : `/paps/${profileId}/permissions`
            }
            emptyTableMessage={`No permissions assigned. ${
              thisAppManage && !requestAccess
                ? 'Click Add Permissions to add new permissions to this profile'
                : ''
            }`}
            dropdownFilters={[
              {
                label: 'Type',
                value: 'type',
                options: permissionTypes,
              },
            ]}
            refresh={refresh}
          />
        )}
        <Drawer
          isOpen={isIntegrityCheckDrawerOpen}
          top={`${getHeaderSpacing()}px`}
          toggleDrawer={this.toggleIntegrityCheckDrawer}
        >
          <Heading>
            <Icon className="fas fa-unlock-alt" /> Integrity Checks for{' '}
            {selectedPermissionName}
          </Heading>
          <EnvironmentGroupsWrapper
            isHierarchical={get(app, 'catalogApplication.requiresHierarchicalModel')}
            papId={profileId}
            permission={selectedPermissionName}
          />
        </Drawer>

        {isAddPermissionModalOpen && (
          <ModalPopup
            width={935}
            title="Select Permissions"
            onCancel={this.addPermissionCloseModal}
          >
            <AddPermissionTable
              addPermission={this.addPermission}
              closeModal={this.addPermissionCloseModal}
              profileId={profileId}
              permissionTypes={permissionTypes}
              requestAccess={requestAccess}
              renderPermissionDrawerIcon={this.renderPermissionDrawerIcon}
              isAws={isAWS(app.catalogAppName)}
            />
            <div style={styles.addModalDoneButton}>
              <Button onClick={this.addPermissionCloseModal} color="primary">
                Done
              </Button>
            </div>
          </ModalPopup>
        )}

        {viewConstraints && permissionForConstraint && (
          <ModalPopup
            width={750}
            title={
              <>
                <Typography variant="heading7">
                  {permissionForConstraint.name}
                </Typography>
                <Typography variant="pageTitle">Permission Constraints</Typography>
              </>
            }
            buttons={[
              {
                text: 'Close',
                variant: 'secondary',
                onClick: () => this.setState({ viewConstraints: false }),
                size: 'large',
              },
            ]}
            onCancel={() => this.setState({ viewConstraints: false })}
          >
            <ConstraintPanelContainer
              profileId={profileId}
              permissionName={permissionForConstraint.name}
              permissionType={permissionForConstraint.type}
              permissionConstraintTypes={
                permissionForConstraint.profilePermissionConstraintsSummaryDTO
                  .supportedConstraintsTypes
              }
              isEditMode={false}
              dispatch={dispatch}
              viewOnly
            />
          </ModalPopup>
        )}

        {this.state.isPermissionDrawerOpen && (
          <>
            {this.state.selectedPermission?.isManaged ? (
              <ModalPopup
                width={790}
                title={`Policy: ${this.state.selectedPermission.name}`}
                onCancel={this.closeModal}
                buttons={this.modalButtons}
              >
                <PermissionsDetails
                  appId={app.appContainerId}
                  permissionId={this.state.selectedPermission.name}
                />
              </ModalPopup>
            ) : (
              <PermissionsDrawer
                environmentList={this.state.environmentList}
                appId={app.appContainerId}
                rootEnvironmentGroup={app.rootEnvironmentGroup}
                permission={this.state.selectedPermission}
                supportedPermissionTypes={
                  app.catalogApplication?.supportedPermissionTypes
                }
                hideEnvironmentSelector={
                  app.catalogApplication?.requiresHierarchicalModel
                }
                appName={app.catalogAppName}
                isAws={isAWS(app.catalogAppName)}
                onClose={() =>
                  this.setState({
                    isPermissionDrawerOpen: false,
                    environmentList: [],
                    selectedPermission: {},
                  })
                }
                forRequestBuilder={requestAccess}
              />
            )}
          </>
        )}

        {this.state.findingDialog.isOpen && (
          <DialogPopup
            type="general"
            width={640}
            height={360}
            title="Findings"
            onCancel={() =>
              this.setState({
                findingDialog: {
                  isOpen: false,
                  name: '',
                },
              })
            }
          >
            <FindingsComponent
              appId={app.appContainerId}
              name={this.state.findingDialog.name}
            />
          </DialogPopup>
        )}

        {isK8sAddPermissionModalOpen && (
          <AddPermissionForK8s
            onClose={this.addPermissionCloseModal}
            onAdd={this.addPermission}
            papId={profileId}
          />
        )}
      </Fragment>
    )
  }
}

PapPermissions.propTypes = {
  pap: PropTypes.object,
  permissions: PropTypes.array,
  onClickAddPermission: PropTypes.func,
  match: PropTypes.object,
  history: PropTypes.object,
  getAvailablePermissions: PropTypes.func,
}

const mapStateToProps = (state, ownProps) => {
  return {
    permissions: getSelectedPapPermissions(state),
    fetchingPermissions: getFetchingPapPermissions(state),
    pap: state?.paps?.pap,
    environmentData: getSelectedApplicationEnvironments(state),
    allPermissions: get(state, `paps.papPermissions[${ownProps.profileId}]`, []),
    featureFlags: state.features.featureFlags,
    requestDetails: state.userSecretsReducer?.myApprovalsDetails?.data,
  }
}

export default connect(mapStateToProps)(withRouter(PapPermissions))
