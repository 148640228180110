import { SET_FILTER_APPLICATION, CLEAR_FILTER } from 'actions'

export const filterKey = 'filtersValue'

export const filterReducer = (
  state = {
    profileName: [],
    environmentName: [],
    environmentId: [],
    applicationName: [],
  },
  action
) => {
  switch (action.type) {
    case SET_FILTER_APPLICATION: {
      const { newValue, columnName } = action.payload
      return {
        ...state,
        [columnName]: newValue,
      }
    }

    case CLEAR_FILTER: {
      return {
        ...state,
        profileName: [],
        environmentName: [],
        environmentId: [],
        applicationName: [],
      }
    }
    default:
      return { ...state }
  }
}
