import styled from 'styled-components'

export default styled.div`
  align-items: center;
  background: #fff;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  padding: 0 15px;

  > label {
    align-self: flex-start;
  }
`
