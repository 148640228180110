import React from 'react'
import Dialog from 'britive-design-system/core/components/dialog'
import startCase from 'lodash/startCase'

const DeleteConfirmationDialog = ({
  entity,
  parentEntity,
  onDelete,
  onCancel,
  deleteConfirmationDialog,
}) => {
  return (
    <Dialog
      type="alert"
      title={deleteConfirmationDialog?.title || `Remove ${startCase(entity)}?`}
      message={
        deleteConfirmationDialog?.message ||
        `The ${entity} will be removed from the ${parentEntity}.`
      }
      primaryButtonText={
        deleteConfirmationDialog?.primaryButtonText || 'Yes, Remove'
      }
      secondaryButtonText={deleteConfirmationDialog?.secondaryButtonText || 'No'}
      onSubmit={onDelete}
      onCancel={onCancel}
    />
  )
}

export default DeleteConfirmationDialog
