import React, { PureComponent } from 'react'
import styled from 'styled-components'
import {
  Modal,
  ModalHeader as ModalHeaderUnstyled,
  ModalBody,
  ModalFooter,
} from 'reactstrap'

import Button from 'britive-ui-components/core/components/Button'

import FormField, { RadioButtons } from 'components/form_fields'

import { ENTITY_TYPES } from './EnvironmentCreationModalContainer'
import * as text from 'translations/english_us'

const ModalHeader = styled(ModalHeaderUnstyled)`
  button.close {
    display: none;
  }
`

const styles = {
  error: {
    color: 'red',
  },
  submissionError: {
    color: 'red',
    marginLeft: 8,
  },
}

export default class EnvironmentCreationModal extends PureComponent {
  render() {
    const {
      errors,
      fields,
      handleSubmit,
      isModalOpen,
      isSubmitting,
      cancelForm,
      toggleModal,
      touched,
      updateField,
      createEnvironmentError,
    } = this.props

    return (
      <Modal centered isOpen={isModalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>
          Create Environment/Environment Group
        </ModalHeader>

        <ModalBody>
          <div style={styles.error}>
            {errors.type && touched.type && errors.type}
          </div>

          <label htmlFor="type">Entity Type</label>

          <RadioButtons
            name="type"
            onChange={updateField}
            value={fields.type}
            enumValues={Object.keys(ENTITY_TYPES)}
            enumNameMap={ENTITY_TYPES}
          />

          <span style={styles.error}>
            {errors.name && touched.name && errors.name}
          </span>

          <FormField
            id="name"
            name="name"
            hideLabel={false}
            label="Entity Name"
            placeholder="Entity Name"
            value={fields.name}
            onChange={updateField}
          />

          <span style={styles.error}>
            {errors.description && touched.description && errors.description}
          </span>

          <FormField
            id="description"
            name="description"
            hideLabel={false}
            label="Entity Description"
            placeholder="Entity Description"
            value={fields.description}
            onChange={updateField}
          />
        </ModalBody>

        <ModalFooter>
          <Button color="primary" onClick={cancelForm} disabled={isSubmitting}>
            {text.CANCEL}
          </Button>

          <Button color="primary" spinner={isSubmitting} onClick={handleSubmit}>
            {text.SAVE}
          </Button>
        </ModalFooter>

        <p style={styles.submissionError}>
          {createEnvironmentError && touched.description && createEnvironmentError}
        </p>
      </Modal>
    )
  }
}
