export const modalView = {
  consolidated: 'consolidated',
  users: 'users',
  tags: 'tags',
  membersOnly: 'members-only',
  selectApproversGroup: 'select-approvers-group',
}

export const approverGroupCondition = {
  all: 'All',
  any: 'Any',
}
export const backToTopId = 'assign-association-back-to-top'

export const deletedMember = 'deleted member'
