import React from 'react'

const CSS_CLASSNAME_PREFIX = 'my-resources-group-title'

const GroupTitle = ({ label, count, labelKey }) => {
  const title = `${label} (${count || 0})`

  return (
    <div className={CSS_CLASSNAME_PREFIX}>
      {title}
      {labelKey ? (
        <div className={`${CSS_CLASSNAME_PREFIX}-label-key`}>{labelKey}</div>
      ) : null}
    </div>
  )
}

export default GroupTitle
