import React, { Fragment, memo, useState } from 'react'
import { withRouter } from 'react-router-dom'
import {
  ActionIconLabel,
  ActionIconGroup,
} from 'britive-ui-components/core/components/ActionIcon'
import Spinner from 'britive-ui-components/core/components/Spinner'
import TableActionIcon from 'components/TableActionIcon'
import TableEllipsisCell from 'components/table/TableV2/TableEllipsisCell'
import Table from 'components/table/TableV2/Table'

import startCase from 'lodash/startCase'

function AddUsersTable({
  isAddingUser,
  isAddTimePeriodUserOpen,
  profileId,
  addUser,
  toggleTimePeriodModal,
}) {
  const [refresh, setRefresh] = useState(0)
  const renderActionIcons = ({ original: user }) => {
    const userIsBeingUpdated = !isAddTimePeriodUserOpen && isAddingUser[user.userId]
    const addUserToProfiles = async () => {
      await addUser({ id: user.userId })
      setRefresh(refresh + 1)
    }
    return (
      <ActionIconGroup>
        <TableActionIcon onClick={addUserToProfiles}>
          {userIsBeingUpdated ? (
            <Spinner />
          ) : (
            <Fragment>
              <span className="fa fa-plus-circle fs:14" />
              <ActionIconLabel>Add</ActionIconLabel>
            </Fragment>
          )}
        </TableActionIcon>

        <TableActionIcon
          onClick={() => !userIsBeingUpdated && toggleTimePeriodModal(user)}
        >
          <Fragment>
            <span className="fa fa-clock fs:14" />
            <ActionIconLabel>Add for specified time</ActionIconLabel>
          </Fragment>
        </TableActionIcon>
      </ActionIconGroup>
    )
  }

  return (
    <Table
      columns={[
        {
          Header: 'Name',
          accessor: 'name',
          minWidth: 240,
          Cell: ({ original: { name, email, type } }) => {
            const value = `${name} ${email && type === 'User' ? `(${email})` : ''}`
            return <TableEllipsisCell value={value} />
          },
        },
        {
          Header: 'Username',
          accessor: 'username',
          minWidth: 180,
        },
        {
          Header: 'Type',
          accessor: 'userType',
          minWidth: 140,
          Cell: ({ original: { userType } }) => startCase(userType),
        },
        {
          Header: 'Mapped Account',
          minWidth: 280,
          sortable: false,
          Cell: ({ original: { mappedAccounts } }) => mappedAccounts.join(', '),
        },
        {
          Header: 'Actions',
          accessor: 'actions',
          sortable: false,
          resizable: false,
          Cell: renderActionIcons,
          minWidth: 80,
        },
      ]}
      identifier="userId"
      dataUrl={`/paps/${profileId}/users`}
      params={{ query: 'available' }}
      emptyTableMessage={'No available identities were found'}
      dropdownFilters={[
        {
          label: 'Type',
          value: 'type',
          options: [
            { label: 'User', value: 'User' },
            { label: 'Service Identity', value: 'ServiceIdentity' },
          ],
        },
      ]}
      refresh={refresh}
      noUrlQueryParams
    />
  )
}

export default withRouter(memo(AddUsersTable))
