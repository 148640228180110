import axios from 'axios'
import Cookies from 'js-cookie'

import store from 'store'

import { accessDenied, authError, notAuthenticated } from 'action_creators/account'

import { getIsAuthenticated } from 'utils/authentication'

export function getURL() {
  const tenantUrl = Cookies.get('tenantUrl')
  const { REACT_APP_API_ENDPOINT } = process.env

  return `${tenantUrl}${REACT_APP_API_ENDPOINT}`
}

export const doFetch = async ({
  path,
  method,
  postBody = {},
  allow401,
  params = {},
}) => {
  // configure the request
  let fetchParams = {
    method: method,
    headers: {},
    withCredentials: true,
    xsrfCookieName: 'csrfToken',
    params,
  }

  // POST method with a post body gets added
  if (['post', 'patch', 'put'].includes(method.toLowerCase()) && !!postBody) {
    fetchParams['data'] = postBody
  }

  const { REACT_APP_API_ENDPOINT } = process.env

  const tenantUrl = Cookies.get('tenantUrl')

  let url = `${tenantUrl}${REACT_APP_API_ENDPOINT}`

  return await axios(url + path, fetchParams).catch(async error => {
    if (error?.response) {
      const { status, data } = error.response
      if (status === 401 && data.message && !allow401) {
        store.dispatch(notAuthenticated())
        store.dispatch(
          authError('You are no longer authenticated. Please login again.')
        )
      }
      // Error code PE-0028 implies the operation requires stepup verification.
      // Hence, no need to show access denied screen. Instead ask for MFA OTP wherever required.
      if (status === 403 && data?.errorCode !== 'PE-0028') {
        await store.dispatch(accessDenied())
      }
      // always return the error
      return Promise.reject(error)
    }
  })
}

// TODO: Implement if any logic needed before the api request
export async function preFetch(...args) {
  return doFetch(...args)
}

export async function preFetchV2(...args) {
  const isLoggedIn = getIsAuthenticated()

  if (!isLoggedIn) {
    store.dispatch(notAuthenticated())
    store.dispatch(authError('You are no longer authenticated. Please login again.'))
  }

  return doFetch(...args)
}

export default doFetch
