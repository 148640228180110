import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import debounce from 'lodash/debounce'
import styled from 'styled-components'
import startCase from 'lodash/startCase'

import ModalPopup from 'britive-design-system/core/components/modal-popup'
import Radio from 'britive-design-system/core/components/radio'
import Checkbox from 'britive-design-system/core/components/checkbox'
import Spinner from 'britive-design-system/core/components/spinner'
import Snackbar from 'britive-design-system/core/components/snackbar'
import Table from 'britive-design-system/core/components/table'
import Typography from 'britive-design-system/core/components/typography'
import Button from 'britive-design-system/core/components/button'

import { getPaginatedUsers } from 'action_creators/user'
import { fetchPaginatedTags } from 'action_creators/tag'
import listIcon from 'static/images/list-tree.svg'
import TagMembersModal from '../TagMembersModal'
import getTotalPageCount from 'utils/getTotalPageCount'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  .cell-with-icon {
    display: flex;
    justify-content: space-between;
    .cell-icon {
      cursor: pointer;
    }
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`

const RequestersModal = ({
  type,
  onCancel,
  onAdd,
  addError,
  memberRules = [],
  inclusivityOption,
}) => {
  const [selectedItems, setSelectedItems] = useState([])
  const [selectedTag, setSelectedTag] = useState(null)
  const [condition, setCondition] = useState(inclusivityOption ? 'Include' : null)

  const {
    paginatedTags = {},
    fetchingPaginatedTags,
    error: tagsError,
  } = useSelector(state => state.tags)
  const {
    paginatedUsers = {},
    fetchingPaginatedUsers,
    error: userError,
  } = useSelector(state => state.admin)

  const dispatch = useDispatch()

  const membersHelper = {
    users: {
      fetching: fetchingPaginatedUsers,
      fetched: fetchingPaginatedUsers,
      error: userError,
      fetchData: params =>
        dispatch(getPaginatedUsers(params, paginatedUsers?.searchText)),
      getData: () =>
        (paginatedUsers?.data || []).filter(
          user => !memberRules?.some(memberRule => memberRule.id === user.userId)
        ),
      paginatedData: paginatedUsers,
      filterData: (data, value) => {
        return data.filter(
          ({ name, username, email }) =>
            name.toLowerCase().includes(value.toLowerCase()) ||
            username.toLowerCase().includes(value.toLowerCase()) ||
            email.toLowerCase().includes(value.toLowerCase())
        )
      },
      prepareRequesters: item => ({
        id: item.userId,
        condition,
        name: item.username,
        memberType: 'User',
        firstName: item.firstName,
        lastName: item.lastName,
      }),
      columns: [
        {
          headerName: 'Name',
          field: 'name',
          width: 100,
          renderColumn: row => {
            const itemIndex = selectedItems.findIndex(
              item => item.userId === row.userId
            )
            return (
              <div style={{ display: 'flex' }}>
                <Checkbox
                  checked={itemIndex > -1}
                  onChange={e => {
                    if (e.target.checked) {
                      setSelectedItems([...selectedItems, row])
                    } else {
                      const clonedSelectedItems = [...selectedItems]
                      clonedSelectedItems.splice(itemIndex, 1)
                      setSelectedItems(clonedSelectedItems)
                    }
                  }}
                />
                &nbsp;
                {row.name}
              </div>
            )
          },
        },
        {
          headerName: 'Username',
          field: 'username',
          width: 60,
        },
        {
          headerName: 'Email',
          field: 'email',
          width: 80,
        },
      ],
    },
    tags: {
      fetching: fetchingPaginatedTags,
      fetched: fetchingPaginatedTags,
      error: tagsError,
      fetchData: params =>
        dispatch(fetchPaginatedTags(params, paginatedTags?.searchText)),
      getData: () =>
        (paginatedTags?.data || []).filter(
          tag => !memberRules?.some(memberRule => memberRule.id === tag.userTagId)
        ),
      paginatedData: paginatedTags,
      prepareRequesters: item => ({
        ...item,
        id: item.userTagId,
        condition,
        name: item.name,
        memberType: 'Tag',
      }),
      columns: [
        {
          headerName: 'Name',
          field: 'name',
          width: 80,
          renderColumn: row => {
            const itemIndex = selectedItems.findIndex(
              item => item.userTagId === row.userTagId
            )
            return (
              <div style={{ display: 'flex' }}>
                <Checkbox
                  checked={itemIndex > -1}
                  onChange={e => {
                    if (e.target.checked) {
                      setSelectedItems([...selectedItems, row])
                    } else {
                      const clonedSelectedItems = [...selectedItems]
                      clonedSelectedItems.splice(itemIndex, 1)
                      setSelectedItems(clonedSelectedItems)
                    }
                  }}
                />
                &nbsp;
                {row.name}
              </div>
            )
          },
        },
        {
          headerName: 'Description',
          field: 'description',
          width: 100,
        },
        {
          headerName: 'Members',
          field: 'userCount',
          renderColumn: row => (
            <div className="cell-with-icon">
              <span>{row?.userCount}</span>
              <span className="cell-icon" onClick={() => setSelectedTag(row)}>
                <img src={listIcon} />
              </span>
            </div>
          ),
          width: 40,
        },
      ],
    },
  }

  const {
    fetching,
    error,
    fetchData,
    getData,
    prepareRequesters,
    columns,
    paginatedData,
  } = membersHelper[type]

  useEffect(() => {
    if (isEmpty(paginatedData?.data) || paginatedData?.searchText) {
      fetchData({
        page: 0,
        size: 100,
      })
    }
  }, [])

  const data = useMemo(() => getData(), [
    paginatedUsers?.data,
    paginatedTags?.data,
    memberRules,
  ])

  const handleLoadMore = () => {
    fetchData({
      page: paginatedData?.page + 1,
      size: 100,
    })
  }

  const handleSearch = value => {
    fetchData({
      page: 0,
      size: 100,
      searchText: value?.length ? value : '',
    })
  }

  const buttons = [
    {
      text: 'Add',
      variant: 'primary',
      onClick: () => onAdd(selectedItems.map(prepareRequesters)),
      size: 'medium',
      disabled: !selectedItems.length,
    },
    {
      text: 'Close',
      variant: 'secondary',
      onClick: onCancel,
      size: 'medium',
    },
  ]

  const radioButtonProps = {
    label: 'Condition',
    name: 'condition',
    defaultValue: condition,
    options: [
      {
        label: 'Include',
        value: 'Include',
      },
      {
        label: 'Exclude',
        value: 'Exclude',
      },
    ],
    direction: 'horizontal',
    onChange: event => {
      event.preventDefault()
      setCondition(event.target.value)
    },
  }

  const tableProps = {
    rows: data,
    columns,
    resizableColumns: true,
    searchBar: {
      onSearch: debounce(handleSearch, 300),
      placeholder: 'Search',
    },
  }

  return (
    <ModalPopup
      width={720}
      title={`Select ${startCase(type)}`}
      buttons={buttons}
      onCancel={onCancel}
    >
      <Container>
        {fetching && <Spinner size={'medium'} message="Loading..." overlay />}
        {(error || addError) && (
          <Snackbar
            errorList={[addError || `Something went wrong while fetching ${type}!`]}
          />
        )}
        {
          <>
            {inclusivityOption && (
              <div>
                <Radio {...radioButtonProps} />
              </div>
            )}
            <Table {...tableProps} />
            {!data.length && !fetching && (
              <Typography variant="heading6">No {type} found!</Typography>
            )}
            {!fetching &&
              data?.length > 0 &&
              paginatedData?.page <
                getTotalPageCount(paginatedData?.count, paginatedData?.size) && (
                <ButtonContainer>
                  <Button variant="textOnly" size="medium" onClick={handleLoadMore}>
                    Load More
                  </Button>
                </ButtonContainer>
              )}
          </>
        }
        {selectedTag && (
          <TagMembersModal tag={selectedTag} onClose={() => setSelectedTag(null)} />
        )}
      </Container>
    </ModalPopup>
  )
}

export default RequestersModal
