import { preFetchV2 } from 'utils/do_fetch'
const TASKS_ENDPOINT = process.env.REACT_APP_API_TASKS_ENDPOINT

export function getService({ appId, type }) {
  const path = `${TASKS_ENDPOINT}/services?name=${type}&appId=${appId}`

  return preFetchV2({
    name: 'getService',
    path,
    method: 'get',
  })
}

export function getTasks({ serviceId }) {
  const path = `${TASKS_ENDPOINT}/services/${serviceId}/tasks`

  return preFetchV2({
    name: 'getTasks',
    path,
    method: 'get',
  })
}

export function editServiceStatus({ serviceId, status }) {
  const action = status ? 'enable' : 'disable'
  const path = `${TASKS_ENDPOINT}/services/${serviceId}/${action}d-statuses`
  const name = `${action}Service`

  return preFetchV2({
    name,
    path,
    method: 'post',
  })
}

export function createTask({ serviceId, postBody }) {
  return preFetchV2({
    name: 'createTask',
    path: `${TASKS_ENDPOINT}/services/${serviceId}/tasks`,
    method: 'post',
    postBody,
  })
}

export function updateTask({ serviceId, postBody, taskId }) {
  return preFetchV2({
    name: 'createTask',
    path: `${TASKS_ENDPOINT}/services/${serviceId}/tasks/${taskId}`,
    method: 'patch',
    postBody,
  })
}

export function deleteTask({ serviceId, taskId }) {
  return preFetchV2({
    name: 'createTask',
    path: `${TASKS_ENDPOINT}/services/${serviceId}/tasks/${taskId}`,
    method: 'delete',
  })
}
