import clone from 'lodash/clone'

export const isSlack = medium => {
  return medium?.toLowerCase() === 'slack'
}

export const getUpdatedNotificationMediums = (
  existingNotificationsMediums = [],
  changedNotificationMedium,
  isNew
) => {
  if (!isNew && isSlack(changedNotificationMedium?.application)) {
    const slackMediumIndex = existingNotificationsMediums.findIndex(
      data => data.id === changedNotificationMedium.id
    )
    const updatedNotificationMediums = clone(existingNotificationsMediums)

    if (slackMediumIndex !== -1) {
      updatedNotificationMediums[slackMediumIndex] = changedNotificationMedium
    }

    return updatedNotificationMediums
  }

  return [...(existingNotificationsMediums || []), changedNotificationMedium]
}
