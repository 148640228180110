import React from 'react'
import { connect } from 'react-redux'
import AddSessionAttributes from './AddSessionAttributes'
import { withFormik } from 'formik'
import get from 'lodash/get'
import { compose } from 'redux'
import { createSessionAttribute, updateSessionAttribute } from 'action_creators/pap'
import toast from 'utils/toast'
import messages from 'constants/messages'

const AddSessionAttributesContainer = ({
  availableAttributes,
  selectedSessionAttribute,
  errors,
  handleChange,
  handleSubmit,
  isModalOpen,
  isSubmitting,
  toggleModal,
  touched,
  values,
  app,
}) => {
  return (
    <AddSessionAttributes
      app={app}
      availableAttributes={availableAttributes}
      selectedSessionAttribute={selectedSessionAttribute}
      errors={errors}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      isModalOpen={isModalOpen}
      isSubmitting={isSubmitting}
      toggleModal={toggleModal}
      touched={touched}
      values={values}
    />
  )
}

const enhancedForm = withFormik({
  mapPropsToValues: props => {
    const { selectedSessionAttribute } = props
    if (selectedSessionAttribute) {
      const {
        sessionAttributeType,
        attributeSchemaId,
        attributeValue,
        mappingName,
        transitive,
      } = Object.values(selectedSessionAttribute)[0]
      return {
        sessionAttributeType,
        attributeSchemaId,
        attributeValue,
        mappingName,
        transitive,
      }
    } else {
      return {
        sessionAttributeType: 'Identity',
        attributeSchemaId: null,
        attributeValue: null,
        mappingName: '',
        transitive: false,
      }
    }
  },

  validate: values => {
    const errors = {}
    if (values.sessionAttributeType === 'Identity' && !values.attributeSchemaId) {
      errors.attributeSchemaId = 'Please select an attribute'
    }
    if (values.sessionAttributeType === 'Static' && !values.attributeValue) {
      errors.attributeValue = 'Please enter an attribute value'
    }
    if (!values.mappingName) {
      errors.mappingName = 'Please enter an attribute mapping name'
    }
    return errors
  },
  handleSubmit: async (
    values,
    { resetForm, props, setSubmitting, setFieldError }
  ) => {
    const {
      dispatch,
      toggleModal,
      profileId,
      updateSessionAttributes,
      selectedSessionAttribute,
    } = props

    try {
      if (selectedSessionAttribute) {
        const id = Object.keys(selectedSessionAttribute)[0]
        const response = await dispatch(
          updateSessionAttribute({ ...values, id }, profileId)
        )
        if (response.value.status === 204) {
          updateSessionAttributes({ ...values, id })
        }
      } else {
        const response = await dispatch(createSessionAttribute(values, profileId))
        updateSessionAttributes(response.value.data)
        toast({
          title: messages.GENERIC_SAVE_SUCCESS,
          type: 'success',
          time: 'normal',
          id: response.value.data?.id,
        })
      }
    } catch (error) {
      setFieldError(
        'submission',
        get(error, 'response.data.message', 'Unknown problem with submission')
      )
      setSubmitting(false)

      return
    }

    setSubmitting(false)
    resetForm()
    toggleModal()
  },

  displayName: 'AddSessionAttributesForm',
})

const withConnect = connect()

export default compose(withConnect, enhancedForm)(AddSessionAttributesContainer)
