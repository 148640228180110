import React, { useEffect, useMemo, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import {
  getCheckedOutProfiles,
  fetchSharedPapStatus,
  fetchSharedPapStatusV1,
  extendExpiration,
  cancelExtension,
} from 'action_creators/access'
import { FEATURE_FLAGS } from './../../reducers/feature_flag'
import { getFeatureFlagById } from './../../selectors/feature_flag'
import PAPModal from './PAPModal'
import toast from 'utils/toast'
import isEmpty from 'lodash/isEmpty'
import { getIsAuthenticated } from 'utils/authentication'

const PAPUpdaterContainer = ({
  dispatch,
  userApps,
  fetchedUserApps,
  fetchingCheckedOutProfiles,
  extendingExpiration,
  extensionQueue,
  profileV1,
}) => {
  const [profileToBeExtended, setProfileToBeExtended] = useState(null)
  const timeoutRef = useRef(null)
  useEffect(() => {
    fetchedUserApps && getProfileDetails()
  }, [fetchedUserApps])
  const supportsSharedAccounts = useMemo(
    () =>
      Object.values(userApps).some(tenantApp => tenantApp.supportsSharedAccounts),
    [userApps]
  )

  useEffect(() => {
    if (isEmpty(profileToBeExtended) && extensionQueue.length > 0) {
      setProfileToBeExtended(extensionQueue[0])
    }
  }, [extensionQueue, profileToBeExtended])

  const getProfileDetails = async () => {
    if (!getIsAuthenticated()) {
      clearTimeout(timeoutRef.current)
      return
    }

    if (profileV1) {
      !fetchingCheckedOutProfiles && (await dispatch(getCheckedOutProfiles(true)))
      supportsSharedAccounts && (await dispatch(fetchSharedPapStatusV1()))
    } else {
      supportsSharedAccounts && (await dispatch(fetchSharedPapStatus()))
    }
    timeoutRef.current = setTimeout(getProfileDetails, 60 * 1000)
  }

  const extendProfileAccess = async () => {
    const { transactionId, papName } = profileToBeExtended
    try {
      await dispatch(extendExpiration(transactionId))
      setProfileToBeExtended(null)
    } catch (e) {
      toast({
        title: `Profile extension for '${papName}' has failed.`,
        type: 'error',
        time: 'normal',
        id: transactionId,
        description: e.response.data?.message,
      })
    }
  }

  const cancelAccessExtension = () => {
    dispatch(cancelExtension(profileToBeExtended.transactionId))
    setProfileToBeExtended(null)
  }

  if (isEmpty(profileToBeExtended)) {
    return null
  }

  return (
    <>
      <Helmet defer={false}>
        <title>Extend PAP {profileToBeExtended.papName}</title>
      </Helmet>

      <PAPModal
        profile={profileToBeExtended}
        cancelAccessExtension={cancelAccessExtension}
        extendProfileAccess={extendProfileAccess}
        extendingExpiration={extendingExpiration}
      />
    </>
  )
}

const mapStateToProps = state => {
  const profileV1 = getFeatureFlagById({
    id: FEATURE_FLAGS.profileV1,
    state,
  })
  const userAppPaps = profileV1 ? state.userAppPapsV1 : state.userAppPaps
  return {
    userApps: userAppPaps.userApps,
    fetchedUserApps: userAppPaps.fetchedUserApps,
    fetchingCheckedOutProfiles: userAppPaps.fetchingCheckedOutProfiles,
    extendingExpiration: userAppPaps.extendingExpiration,
    extensionQueue: userAppPaps.extensionQueue,
    profileV1,
  }
}

export default connect(mapStateToProps)(PAPUpdaterContainer)
