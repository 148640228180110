import React, { useState } from 'react'
import Typography from 'britive-design-system/core/components/typography'
import SimpleSidebar from 'britive-design-system/core/components/simple-sidebar'
import Tooltip from 'britive-design-system/core/components/tooltip'
import { classNamePrefix } from './constants'
import details from 'static/images/details.svg'
import { ApproversList } from './ApproversList'
import { convertTimeToCurrentTimezone, getSecretStatus } from '../../utils'
import './index.scss'
import useMaxTableHeight from 'hooks/useMaxTableHeight'

export const RequestDetails = ({
  requestId,
  status,
  requester,
  appName,
  approverGroups,
  justification,
  hideRequester,
  iconUrl,
  expirationTimeForApproveRequest,
}) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true)

  const sidebarHeight = useMaxTableHeight({
    heights: [144],
  })

  const showToolTip = name => (name.length > 17 ? name : null)

  const renderRequester = () => (
    <div
      className={`${classNamePrefix}-content-requester`}
      data-testid={`${classNamePrefix}-content-requester`}
    >
      <Typography variant="heading6">Requester</Typography>
      <Tooltip title={showToolTip(requester)} position="top">
        <div>
          <Typography variant="label1">{requester}</Typography>
        </div>
      </Tooltip>
    </div>
  )

  return (
    <SimpleSidebar
      isOpen={isSidebarOpen}
      onToggle={() => setIsSidebarOpen(isOpen => !isOpen)}
      width={328}
      height={sidebarHeight}
    >
      <div className={`${classNamePrefix}-container`}>
        <div
          className={`${classNamePrefix}-title`}
          data-testid={`${classNamePrefix}-title`}
        >
          <img src={details} className={`${classNamePrefix}-title-icon`} />
          <Typography variant="heading5">Detail</Typography>
        </div>

        <div className={`${classNamePrefix}-content-status-wrapper`}>
          <div
            className={`${classNamePrefix}-content-status`}
            data-testid={`${classNamePrefix}-content-status`}
          >
            <Typography variant="heading4">{getSecretStatus(status)}</Typography>
          </div>
          <div
            className={`${classNamePrefix}-content-application-requester-wrapper`}
          >
            <div
              className={`${classNamePrefix}-content-application`}
              data-testid={`${classNamePrefix}-content-application`}
            >
              <Typography variant="heading6">Application</Typography>
              <div
                className={`${classNamePrefix}-content-application-icon-name-wrapper`}
              >
                <div>
                  <img
                    src={iconUrl}
                    className={`${classNamePrefix}-content-application-icon`}
                  />
                  <Tooltip title={showToolTip(appName)} position="top">
                    <div>
                      <Typography variant="label1">{appName}</Typography>
                    </div>
                  </Tooltip>
                </div>
              </div>
            </div>
            {!hideRequester && renderRequester()}
          </div>
        </div>
        <div className={`${classNamePrefix}-field`}>
          <Typography variant="heading6">Request Validity</Typography>
          <Typography variant="label1">
            {convertTimeToCurrentTimezone(expirationTimeForApproveRequest) || 'None'}
          </Typography>
        </div>
        <div className={`${classNamePrefix}-field`}>
          <Typography variant="heading6">Approvers</Typography>
          <ApproversList approverGroups={approverGroups} requestId={requestId} />
        </div>
        <div className={`${classNamePrefix}-field`}>
          <Typography variant="heading6">Justification</Typography>
          <Typography variant="label1">{justification}</Typography>
        </div>
      </div>
    </SimpleSidebar>
  )
}
