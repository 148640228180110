import moment from 'moment'
import {
  secretModuleConstants,
  translatedStrings,
} from './my-approvals/list/constants'

export const getSecretStatus = status => {
  switch (status?.toLowerCase()) {
    case secretModuleConstants.pendingText:
      return translatedStrings.approvalPendingText

    case secretModuleConstants.approvalRequiredText:
      return translatedStrings.approvalRequiredText

    case secretModuleConstants.denyText:
      return translatedStrings.approvalDeniedText

    case secretModuleConstants.allowText:
      return translatedStrings.availableText

    case secretModuleConstants.approvedText:
      return translatedStrings.approvedStatus

    case secretModuleConstants.rejectedText:
      return translatedStrings.rejectedStatus

    case secretModuleConstants.timeOutText:
      return translatedStrings.timeOutStatus

    case secretModuleConstants.withdrawnText:
      return translatedStrings.withdrawnText

    case secretModuleConstants.noActionRequired:
      return translatedStrings.noActionRequired

    case secretModuleConstants.cancelledText:
      return translatedStrings.cancelledText

    default:
      return translatedStrings.noneText
  }
}

export const getConsumerType = (consumer, type) => {
  switch (consumer) {
    case secretModuleConstants.resourceprofile:
      return translatedStrings.profileCheckout
    case secretModuleConstants.papService:
      return translatedStrings.profileCheckout
    case secretModuleConstants.secretManager:
      return translatedStrings.viewSecret
    case secretModuleConstants.accessBuilder:
      switch (type) {
        case secretModuleConstants.newRequest:
          return translatedStrings.createProfile
        case secretModuleConstants.existingRequest:
          return translatedStrings.profileAccess
        default:
          return translatedStrings.accessBuilder
      }
    default:
      return translatedStrings.noneText
  }
}

export const getActionLabel = action => {
  if (action) {
    if (action === secretModuleConstants.secretReadActionName) {
      return translatedStrings.viewSecret
    }

    if (action === secretModuleConstants.profileAccessActionName) {
      return translatedStrings.accessProfile
    }
    if (action === secretModuleConstants.resourceProfileCheckout) {
      return translatedStrings.accessResource
    }
    return action
  }
  return translatedStrings.noneText
}

export const convertTimeToCurrentTimezone = UTCString => {
  return moment(UTCString).format('MMMM DD, YYYY hh:mm A')
}

export const sortApprovalsAndRequestByDate = dates => {
  return dates.sort((date1, date2) => {
    let tempDate1 = new Date(date1?.createdAt)
    let tempDate2 = new Date(date2?.createdAt)
    return tempDate1 < tempDate2 ? 1 : tempDate1 > tempDate2 ? -1 : 0
  })
}

export const isAccessBuilderRequest = consumer => {
  return consumer === secretModuleConstants.accessBuilder
}

export const getABRequestsHeaderTitle = (requestData = {}) => {
  const { consumer, context } = requestData
  return `${getConsumerType(consumer, context?.type)} ${context?.appName}:${
    context?.profileName
  }`
}
