import get from 'lodash/get'

import {
  DELETE_USER,
  FETCH_CUSTOM_ATTRIBUTES_FOR_USER,
  UPDATE_USER_STATUS,
} from 'action_creators/user'
import { FETCH_TENANT_APP_RESOURCES } from 'action_creators/tenant_app'

import * as actions from 'actions'

import user from 'classes/user.js'
import tenantApp, {
  tenantAppPermissions,
  tenantAppAccounts,
  tenantAppResources,
} from 'classes/tenant_app'
import catalogApp from 'classes/catalog_app'
import pap from 'classes/pap'

export default function reducer(
  state = {
    fetchingUsers: false,
    fetchedUsers: false,
    fetchingPaginatedUsers: false,
    paginatedUsers: {},
    fetchingAddUser: false,
    fetchedAddUser: false,
    fetchingAllTenantApps: false,
    fetchedAllTenantApps: false,
    fetchingAllCatalogApps: false,
    fetchedAllCatalogApps: false,
    fetchingAllPaps: false,
    deletingUsers: false,
    fetchedAllPaps: false,
    error: null,
    addUserError: null,
    users: {
      User: [],
      ServiceIdentity: [],
    },
    roles: [],
    fetchingRoles: false,
    apps: [],
    catalogApps: [],
    paps: {},
    papsForSelectedApp: [],
    fetchingUpdateUserPaps: false,
    fetchedUpdateUserPaps: false,
    selectedApp: {},
    fetchingSelectedApp: false,
    fetchedSelectedApp: false,
    selectedAppPermissions: {},
    fetchingSelectedAppPermissions: {},
    fetchedSelectedAppPermissions: false,
    selectedAppAccounts: {},
    fetchingSelectedAppAccounts: {},
    fetchedSelectedAppAccounts: false,
    selectedAppResources: {},
    fetchingSelectedAppResources: {},
    updatingUsers: {},
    fetchingCustomAttributesForUser: false,
    fetchedCustomAttributesForUser: false,
    userAttributes: [],
    allowedScopesForAccessBuilder: {},
  },
  action
) {
  switch (action.type) {
    case `${actions.GET_USERS}_PENDING`: {
      return {
        ...state,
        fetchingUsers: true,
      }
    }

    case `${actions.GET_USERS}_FULFILLED`: {
      const usersList = action.payload
        ? action.payload.data.map(u => new user(u))
        : []
      const { type } = action.meta
      return {
        ...state,
        fetchingUsers: false,
        fetchedUsers: true,
        users: {
          ...state.users,
          [type]: usersList,
        },
      }
    }

    case `${actions.GET_USERS}_REJECTED`: {
      return {
        ...state,
        fetchingUsers: null,
        fetchedUsers: null,
        error: action.payload,
      }
    }

    case `${actions.GET_SELECTED_USERS}_PENDING`: {
      return {
        ...state,
        fetchingUsers: true,
      }
    }

    case `${actions.GET_SELECTED_USERS}_FULFILLED`: {
      const { type } = action.meta
      return {
        ...state,
        fetchingUsers: false,
        fetchedUsers: true,
        users: {
          ...state.users,
          [type]: action.payload.data,
        },
      }
    }

    case `${actions.GET_SELECTED_USERS}_REJECTED`: {
      return {
        ...state,
        fetchingUsers: null,
        fetchedUsers: null,
        error: action.payload,
      }
    }

    case `${actions.GET_PAGINATED_USERS}_PENDING`: {
      return {
        ...state,
        fetchingPaginatedUsers: true,
      }
    }

    case `${actions.GET_PAGINATED_USERS}_FULFILLED`: {
      const { data, count, page, size } = action.payload.data
      return {
        ...state,
        fetchingPaginatedUsers: false,
        paginatedUsers: {
          data,
          count,
          page,
          size,
          searchText: action?.meta?.searchText,
        },
      }
    }

    case `${actions.GET_PAGINATED_USERS_LOAD_MORE}_PENDING`: {
      return {
        ...state,
        fetchingPaginatedUsers: true,
      }
    }

    case `${actions.GET_PAGINATED_USERS_LOAD_MORE}_FULFILLED`: {
      const { data, count, page, size } = action.payload.data
      return {
        ...state,
        fetchingPaginatedUsers: false,
        paginatedUsers: {
          data: [...state.paginatedUsers.data, ...data],
          count,
          page,
          size,
          searchText: action?.meta?.searchText,
        },
      }
    }

    case `${actions.GET_PAGINATED_USERS}_REJECTED`: {
      return {
        ...state,
        fetchingPaginatedUsers: false,
        error: action.payload,
      }
    }

    case `${actions.GET_PAGINATED_USERS_LOAD_MORE}_REJECTED`: {
      return {
        ...state,
        fetchingPaginatedUsers: false,
        error: action.payload,
      }
    }

    case `${actions.GET_ROLES}_PENDING`: {
      return {
        ...state,
        fetchingRoles: true,
      }
    }

    case `${actions.GET_ROLES}_FULFILLED`: {
      return {
        ...state,
        fetchingRoles: false,
        roles: action.payload.data,
      }
    }

    case `${actions.GET_ROLES}_REJECTED`: {
      return {
        ...state,
        fetchingRoles: false,
        error: action.payload,
      }
    }

    case `${actions.ADD_USER}_PENDING`: {
      return {
        ...state,
        fetchingAddUser: true,
        fetchedAddUser: false,
      }
    }

    case `${actions.ADD_USER}_FULFILLED`: {
      const newUser = new user(action.payload ? action.payload.data : {})
      const { type } = action.meta
      return {
        ...state,
        fetchingAddUser: false,
        fetchedAddUser: true,
        users: {
          ...state.users,
          [type]: state.users[type].concat(newUser),
        },
      }
    }

    case `${actions.ADD_USER}_REJECTED`: {
      const addUserError =
        get(action.payload, 'response.data.message') ||
        get(action.payload, 'response.data')

      return {
        ...state,
        fetchingAddUser: null,
        fetchedAddUser: null,
        addUserError,
      }
    }

    case `${actions.GET_ALL_APPS}_PENDING`: {
      return {
        ...state,
        fetchingAllTenantApps: true,
      }
    }

    case `${actions.GET_ALL_APPS}_FULFILLED`: {
      const appList = action.payload
        ? action.payload.data.map(u => new tenantApp(u))
        : []
      return {
        ...state,
        fetchingAllTenantApps: false,
        fetchedAllTenantApps: true,
        apps: appList,
      }
    }

    case `${actions.GET_ALL_APPS}_REJECTED`: {
      return {
        ...state,
        fetchingAllTenantApps: null,
        fetchedAllTenantApps: null,
        error: action.payload,
      }
    }

    case `${actions.GET_TENANT_APP}_PENDING`: {
      return {
        ...state,
        fetchingSelectedApp: true,
      }
    }

    case `${actions.GET_TENANT_APP}_FULFILLED`: {
      return {
        ...state,
        fetchingSelectedApp: false,
        fetchedSelectedApp: true,
        selectedApp: new tenantApp(action.payload ? action.payload.data : {}),
      }
    }

    case `${actions.GET_TENANT_APP}_REJECTED`: {
      return {
        ...state,
        fetchingSelectedApp: null,
        fetchedSelectedApp: null,
        error: action.payload,
      }
    }

    case `${actions.GET_TENANT_APP_PERMISSIONS}_PENDING`: {
      const { envId } = action.meta
      return {
        ...state,
        fetchingSelectedAppPermissions: {
          ...state.fetchingSelectedAppPermissions,
          [envId]: true,
        },
      }
    }

    case `${actions.GET_TENANT_APP_PERMISSIONS}_FULFILLED`: {
      const { envId } = action.meta
      const selectedPermissions = new tenantAppPermissions(
        action.payload ? action.payload.data : {}
      )
      return {
        ...state,
        fetchingSelectedAppPermissions: {
          ...state.fetchingSelectedAppPermissions,
          [envId]: false,
        },
        fetchedSelectedAppPermissions: true,
        selectedAppPermissions: selectedPermissions,
      }
    }

    case `${actions.GET_TENANT_APP_PERMISSIONS}_REJECTED`: {
      const { envId } = action.meta
      return {
        ...state,
        fetchingSelectedAppPermissions: {
          ...state.fetchingSelectedAppPermissions,
          [envId]: false,
        },
        fetchedSelectedAppPermissions: true,
        error: action.payload,
      }
    }

    /*case `${actions.TOGGLE_PRIVILEGED}_PENDING`: {
      return {
        ...state,
      }
    }

    case `${actions.TOGGLE_PRIVILEGED}_FULFILLED`: {
      let updatedAppPermissions = {
        ...state.selectedAppPermissions,
      }
      const { permissionId } = action.meta
      updatedAppPermissions[permissionId].privilegedCustom = !updatedAppPermissions[
        permissionId
      ].privilegedCustom

      return {
        ...state,
        selectedAppPermissions: updatedAppPermissions,
      }
    }

    case `${actions.TOGGLE_PRIVILEGED}_REJECTED`: {
      return {
        ...state,
        error: action.payload,
      }
    }*/

    case `${actions.MAP_ACCOUNT_TO_USER}_PENDING`: {
      return {
        ...state,
        updatingUsers: {
          ...state.updatingUsers,
          [action.meta.userId]: true,
        },
      }
    }

    case `${actions.MAP_ACCOUNT_TO_USER}_FULFILLED`: {
      const { accountId } = action.payload.data
      let updatedAppAccounts = {
        ...state.selectedAppAccounts,
      }

      updatedAppAccounts[accountId] = action.payload.data

      return {
        ...state,
        selectedAppAccounts: updatedAppAccounts,
        updatingUsers: {
          ...state.updatingUsers,
          [action.meta.userId]: false,
        },
      }
    }

    case `${actions.MAP_ACCOUNT_TO_USER}_REJECTED`: {
      return {
        ...state,
        error: action.payload,
        updatingUsers: {
          ...state.updatingUsers,
          [action.meta.userId]: false,
        },
      }
    }

    case `${actions.UNREGISTER_PERMISSIONS}`: {
      let updatedAppPermissions = {
        ...state.selectedAppPermissions,
      }

      updatedAppPermissions[action.payload.data.appPermissionId] =
        action.payload.data

      return {
        ...state,
        selectedAppPermissions: updatedAppPermissions,
      }
    }

    case `${actions.UNMAP_ACCOUNT_FROM_USER}_PENDING`: {
      return {
        ...state,
        updatingUsers: {
          ...state.updatingUsers,
          [action.meta.userId]: true,
        },
      }
    }

    case `${actions.UNMAP_ACCOUNT_FROM_USER}_FULFILLED`: {
      const { payload } = action

      if (payload.data.paps && payload.data.paps.length > 0) {
        return {
          ...state,
        }
      }

      const { accountId } = payload.data

      let updatedAppAccounts = {
        ...state.selectedAppAccounts,
      }

      updatedAppAccounts[accountId] = payload.data

      return {
        ...state,
        selectedAppAccounts: updatedAppAccounts,
        updatingUsers: {
          ...state.updatingUsers,
          [action.meta.userId]: false,
        },
      }
    }

    case `${actions.UNMAP_ACCOUNT_FROM_USER}_REJECTED`: {
      return {
        ...state,
        updatingUsers: {
          ...state.updatingUsers,
          [action.meta.userId]: false,
        },
        error: action.payload,
      }
    }

    case `${actions.GET_TENANT_APP_ACCOUNTS}_PENDING`: {
      const { envId } = action.meta

      return {
        ...state,
        fetchingSelectedAppAccounts: {
          ...state.fetchingSelectedAppAccounts,
          [envId]: true,
        },
      }
    }

    case `${actions.GET_TENANT_APP_ACCOUNTS}_FULFILLED`: {
      const { envId } = action.meta

      const selectedAccounts = new tenantAppAccounts(
        action.payload ? action.payload.data : {}
      )
      return {
        ...state,
        fetchingSelectedAppAccounts: {
          ...state.fetchingSelectedAppAccounts,
          [envId]: false,
        },
        fetchedSelectedAppAccounts: true,
        selectedAppAccounts: selectedAccounts,
      }
    }

    case `${actions.GET_TENANT_APP_ACCOUNTS}_REJECTED`: {
      const { envId } = action.meta
      return {
        ...state,
        fetchingSelectedAppAccounts: {
          ...state.fetchingSelectedAppAccounts,
          [envId]: false,
        },
        fetchedSelectedAppAccounts: null,
        error: action.payload,
      }
    }

    case `${actions.UPDATE_TENANT_APP_ACCOUNTS}_PENDING`: {
      return {
        ...state,
      }
    }

    case `${actions.UPDATE_TENANT_APP_ACCOUNTS}_FULFILLED`: {
      let updatedAppAccounts = {
        ...state.selectedAppAccounts,
      }
      action.payload.data.forEach(account => {
        updatedAppAccounts[account.accountId] = account
      })

      return {
        ...state,
        selectedAppAccounts: updatedAppAccounts,
      }
    }

    case `${actions.UPDATE_TENANT_APP_ACCOUNTS}_REJECTED`: {
      return {
        ...state,
        error: action.payload,
      }
    }

    case `${actions.UPDATE_TENANT_APP}_PENDING`: {
      return {
        ...state,
        updatingTentantApp: true,
      }
    }

    case `${actions.UPDATE_TENANT_APP}_FULFILLED`: {
      const updatedTenantApp = new tenantApp(
        action.payload ? action.payload.data : {}
      )
      return {
        ...state,
        updatingTentantApp: false,
        selectedApp: updatedTenantApp,
        apps: state.apps.map(app => {
          if (app.tenantAppId === updatedTenantApp.tenantAppId) {
            return updatedTenantApp
          }
          return app
        }),
      }
    }

    case `${actions.UPDATE_TENANT_APP}_REJECTED`: {
      return {
        ...state,
        updatingTentantApp: null,
        error: action.payload,
      }
    }

    case `${actions.CREATE_TENANT_APP}_PENDING`: {
      return {
        ...state,
        registeringApp: true,
        addNewTenantAppBusy: true,
      }
    }

    case `${actions.CREATE_TENANT_APP}_FULFILLED`: {
      const newTenantApp = new tenantApp(action.payload ? action.payload.data : {})

      return {
        ...state,
        registeringApp: false,
        registeredApp: true,
        apps: state.apps.concat(newTenantApp),
        selectedApp: newTenantApp,
      }
    }

    case `${actions.CREATE_TENANT_APP}_REJECTED`: {
      return {
        ...state,
        registeringApp: null,
        registeredApp: null,
        error: action.payload,
      }
    }

    case `${actions.GET_ALL_PAPS}_PENDING`: {
      return {
        ...state,
        fetchingAllPaps: true,
      }
    }

    case `${actions.GET_ALL_PAPS}_FULFILLED`: {
      const papListGrouping = {}
      Object.keys(action.payload.data).map(u => {
        return (papListGrouping[u] = action.payload.data[u].map(
          rawPap => new pap(rawPap)
        ))
      })

      return {
        ...state,
        fetchingAllPaps: false,
        fetchedAllPaps: true,
        paps: papListGrouping,
      }
    }

    case `${actions.GET_ALL_PAPS}_REJECTED`: {
      return {
        ...state,
        fetchingAllPaps: null,
        fetchedAllPaps: null,
        error: action.payload,
      }
    }

    case actions.GET_PAPS_FOR_APP: {
      let result = [action.payload.applicationId].reduce(
        (r, k) => r.concat(state.paps[k]),
        []
      )
      return {
        ...state,
        papsForSelectedApp: result,
      }
    }

    case `${actions.UPDATE_PAPS}_PENDING`: {
      return {
        ...state,
        fetchingUpdateUserPaps: true,
      }
    }

    case `${actions.UPDATE_PAPS}_FULFILLED`: {
      const { data } = action.payload
      const updatedUser = new user(data)
      const { type } = updatedUser

      const updatedUsers = state.users[type].map(user => {
        if (user.userId === data.userId) {
          return { ...user, ...updatedUser }
        }
        return user
      })

      return {
        ...state,
        fetchingUpdateUserPaps: false,
        fetchedUpdateUserPaps: true,
        users: {
          ...state.users,
          [type]: updatedUsers,
        },
      }
    }

    case `${actions.UPDATE_PAPS}_REJECTED`: {
      return {
        ...state,
        fetchingUpdateUserPaps: null,
        fetchedUpdateUserPaps: null,
        error: action.payload,
      }
    }

    case `${actions.GET_ALL_CATALOG_APPS}_PENDING`: {
      return {
        ...state,
        fetchingAllCatalogApps: true,
      }
    }

    case `${actions.GET_ALL_CATALOG_APPS}_FULFILLED`: {
      const appList = action.payload.data.map(u => new catalogApp(u))
      return {
        ...state,
        fetchingAllCatalogApps: false,
        fetchedAllCatalogApps: true,
        catalogApps: appList,
      }
    }

    case `${actions.GET_ALL_CATALOG_APPS}_REJECTED`: {
      return {
        ...state,
        fetchingAllCatalogApps: null,
        fetchedAllCatalogApps: null,
        error: action.payload,
      }
    }

    case `${actions.REGISTER_CATALOG_APP}_PENDING`: {
      return {
        ...state,
        registeringApp: true,
        addNewTenantAppBusy: true,
      }
    }

    case `${actions.REGISTER_CATALOG_APP}_FULFILLED`: {
      const newCatalogApp = new catalogApp(action.payload ? action.payload.data : {})

      return {
        ...state,
        registeringApp: false,
        registeredApp: true,
        apps: state.apps.concat(newCatalogApp),
      }
    }

    case `${actions.REGISTER_CATALOG_APP}_REJECTED`: {
      return {
        ...state,
        registeringApp: null,
        registeredApp: null,
        error: action.payload,
      }
    }

    case `${DELETE_USER}_PENDING`: {
      return {
        ...state,
        deletingUser: true,
      }
    }

    case `${DELETE_USER}_FULFILLED`: {
      const { userId, type } = action.meta
      const oldUsers = [...state.users[type]]
      const users = oldUsers.filter(user => user.userId !== userId)

      return {
        ...state,
        deletingUsers: false,
        users: {
          ...state.users,
          [type]: users,
        },
      }
    }

    case `${DELETE_USER}_REJECTED`: {
      return {
        ...state,
        deletingUsers: false,
        error: action.payload,
      }
    }

    case `${UPDATE_USER_STATUS}_PENDING`: {
      return {
        ...state,
        updatingUserStatus: true,
      }
    }

    case `${UPDATE_USER_STATUS}_FULFILLED`: {
      const { userId, type } = action.meta
      const users = [...state.users[type]]
      const index = users.findIndex(user => user.userId === userId)

      if (index >= 0) {
        users[index] = {
          ...users[index],
          status: action.payload.data.status,
          statusText: action.payload.data.statusText,
        }
      }

      return {
        ...state,
        updatingUserStatus: false,
        users: {
          ...state.users,
          [type]: users,
        },
      }
    }

    case `${UPDATE_USER_STATUS}_REJECTED`: {
      return {
        ...state,
        updatingUserStatus: false,
        error: action.payload,
      }
    }

    case `${FETCH_TENANT_APP_RESOURCES}_PENDING`: {
      const { envId } = action.meta

      return {
        ...state,
        fetchingSelectedAppResources: {
          ...state.fetchingSelectedAppResources,
          [envId]: true,
        },
      }
    }

    case `${FETCH_TENANT_APP_RESOURCES}_FULFILLED`: {
      const { envId } = action.meta
      const selectedAppResources = new tenantAppResources(
        action.payload ? action.payload.data : {}
      )

      return {
        ...state,
        fetchingSelectedAppResources: {
          ...state.fetchingSelectedAppResources,
          [envId]: false,
        },
        selectedAppResources,
      }
    }

    case `${FETCH_TENANT_APP_RESOURCES}_REJECTED`: {
      const { envId } = action.meta
      return {
        ...state,
        fetchingSelectedAppResources: {
          ...state.fetchingSelectedAppResources,
          [envId]: false,
        },
        error: action.payload,
      }
    }

    case `${FETCH_CUSTOM_ATTRIBUTES_FOR_USER}_PENDING`: {
      return {
        ...state,
        fetchingCustomAttributesForUser: true,
        fetchedCustomAttributesForUser: false,
      }
    }

    case `${FETCH_CUSTOM_ATTRIBUTES_FOR_USER}_FULFILLED`: {
      return {
        ...state,
        fetchingCustomAttributesForUser: false,
        fetchedCustomAttributesForUser: true,
        userAttributes: action.payload.data,
      }
    }

    case `${FETCH_CUSTOM_ATTRIBUTES_FOR_USER}_REJECTED`: {
      return {
        ...state,
        fetchingCustomAttributesForUser: false,
        fetchedCustomAttributesForUser: false,
        error: action.payload,
      }
    }

    default:
      return { ...state }
  }
}
