import React, { Fragment, memo } from 'react'
import { withRouter } from 'react-router-dom'
import get from 'lodash/get'

import Spinner from 'britive-ui-components/core/components/Spinner'
import Table from 'components/table/TableV2/Table'

import ScopeCheckbox from './ScopeCheckbox'
import FormButtons from './FormButtons'
import useMaxTableHeight from 'hooks/useMaxTableHeight'

const styles = {
  buttons: {
    marginTop: 4,
  },
  tableWrapper: {
    display: 'block',
  },
}

function Resources(props) {
  const maxTableHeight = useMaxTableHeight({
    heights: [300],
    minHeight: 100,
  })
  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
      width: 300,
    },
    {
      Header: 'Path',
      accessor: 'path',
      minWidth: 300,
    },
    {
      Header: 'Type',
      accessor: 'type',
      minWidth: 300,
    },
  ]

  function renderCheckbox({ original: data }) {
    const { isEditing, papScope, updateScope, isRootGroupSelected } = props

    if (!data || !papScope) {
      return null
    }

    const parentId = get(data, 'parentId')
    const nativeId = get(data, 'nativeId')

    const parentSelected = data.type !== 'Resource Group' && papScope[parentId]
    const entity = { entityId: nativeId }

    return (
      <ScopeCheckbox
        isEditing={isEditing}
        parentSelected={parentSelected}
        isDisabled={isRootGroupSelected}
        data={data}
        updateScope={updateScope}
        papScope={papScope}
        entity={entity}
      />
    )
  }

  const {
    isFetching,
    isEditing,
    isSaving,
    cancelEdit,
    saveScope,
    toggleEditState,
    updateScope,
  } = props

  return (
    <Fragment>
      {isFetching ? (
        <Fragment>
          <Spinner size="2x" /> Loading Resources
        </Fragment>
      ) : (
        <Fragment>
          <div style={styles.tableWrapper}>
            <FormButtons
              isEditing={isEditing}
              isSaving={isSaving}
              cancelEdit={cancelEdit}
              saveScope={saveScope}
              toggleEditState={toggleEditState}
              updateScope={updateScope}
            />

            <Table
              columns={columns}
              identifier="appPermissionId"
              dataUrl={`/paps/${props.papId}/resources`}
              emptyTableMessage={'No Resources were found.'}
              showCheckBox
              margin={40}
              maxHeight={maxTableHeight}
              initialColumn={{
                InitialColumnComponent: props => renderCheckbox(props),
                showInitialColumn: true,
                initialColumnWidth: 24,
              }}
            />
          </div>
        </Fragment>
      )}
    </Fragment>
  )
}

export default memo(withRouter(Resources))
