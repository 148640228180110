import React, { memo, useEffect } from 'react'
import { connect } from 'react-redux'

import Resources from './Resources'

import {
  getSelectedApplicationRootId,
  getSelectedApplicationRoot,
} from 'selectors/application_root'
import {
  getSelectedPapId,
  getSelectedPapScope,
  getSelectedPapResourceScope,
} from 'routes/admin/state/selectors'
import { getSelectedApplicationRootGroupId } from 'selectors/environment'

const DATA_IDENTIFIER = 'id'

function ResourcesContainer(props) {
  useEffect(() => {
    props.setResourceFormScope({ ...props.papResourceScope })
  }, [props.papResourceScope])

  const updateScope = ({ entityId }) => {
    const { papResourceFormScope } = props
    const newScope = { ...papResourceFormScope }

    if (newScope[entityId]) {
      delete newScope[entityId]
    } else {
      const formattedEntity = {
        type: 'ApplicationResource',
        value: entityId,
      }

      newScope[entityId] = formattedEntity
    }

    props.setResourceFormScope(newScope)
  }

  const {
    app,
    cancelEdit,
    isRootGroupSelected,
    isSaving,
    papId,
    papResourceFormScope,
    maxHeight,
    updatingScope,
    toggleEditState,
    saveScope,
  } = props
  const supportsResources = app.catalogApplication.supportsResources

  return (
    <Resources
      dataIdentifier={DATA_IDENTIFIER}
      cancelEdit={cancelEdit}
      isEditing={props.isEditing}
      maxHeight={maxHeight}
      isRootGroupSelected={isRootGroupSelected}
      isSaving={isSaving}
      papId={papId}
      papScope={papResourceFormScope}
      saveScope={saveScope}
      supportsResources={supportsResources}
      toggleEditState={toggleEditState}
      updateScope={updateScope}
      updatingScope={updatingScope}
    />
  )
}

const mapStateToProps = state => {
  return {
    appId: getSelectedApplicationRootId(state),
    app: getSelectedApplicationRoot(state),
    rootGroupId: getSelectedApplicationRootGroupId(state),
    papResourceScope: getSelectedPapResourceScope(state),
    papId: getSelectedPapId(state),
    papScope: getSelectedPapScope(state),
  }
}

export default connect(mapStateToProps)(memo(ResourcesContainer))
