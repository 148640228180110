import React, { Fragment, memo, useEffect, useState } from 'react'
import styled from 'styled-components'
import get from 'lodash/get'
import startCase from 'lodash/startCase'
import isEmpty from 'lodash/isEmpty'
import ReactTooltip from 'react-tooltip'

import Button from 'britive-ui-components/core/components/Button'

import ScanStatusTooltip from './ScanStatusTooltip'
import ScanStatusDrawer from '../ScanStatusDrawer'
import ScanModal from '../ScanModal'
import PageLoader from 'components/PageLoader'
import TableEllipsisCell from 'components/table/TableV2/TableEllipsisCell'
import { NoBackgroundTooltip as Tooltip } from 'components/Tooltips'

import * as routing from 'services/routing'

import { formatDateLongWithYear } from 'utils/format_date'
import pluralize from 'pluralize'
import Table from 'components/table/TableV2/Table'
import { preFetch } from 'utils/do_fetch'
import { IoCopyOutline } from 'react-icons/io5'

const Wrapper = styled.div`
  padding: 8px;
  position: relative;
`

const ScanButtonWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: -44px;
  z-index: 5;
  button {
    margin-left: 10px;
  }
`

const styles = {
  summaryCell: {
    display: 'flex',
    alignItems: 'center',
  },
  summaryText: {
    cursor: 'pointer',
    overflow: 'hidden',
    flexGrow: 1,
    textOverflow: 'ellipsis',
  },
  detailsIconWrapper: {
    padding: '0 15px',
    display: 'flex',
  },
  detailsIcon: {
    cursor: 'pointer',
  },
  copyIcon: {
    cursor: 'pointer',
    marginLeft: '15px',
    display: 'flex',
  },
  copiedText: {
    marginLeft: '5px',
    padding: '0px 4px',
    backgroundColor: 'var(--brand)',
    color: '#FFFFFF',
  },
}

const Result = styled.div`
  display: flex;
  &:hover #child {
    display: flex;
  }
  ${props => (props.isError ? 'cursor: pointer;' : '')}
`
const CopyIcon = styled.span`
  display: none;
`

function ScanDate({ value }) {
  if (!value) {
    return null
  }

  return <TableEllipsisCell value={formatDateLongWithYear({ dateTime: value })} />
}

function ScanResult({ original: data }) {
  const [isCopied, setIsCopied] = useState(false)
  const closeHandler = () => {
    setTimeout(() => {
      setIsCopied(false)
    }, 1000)
  }

  if (!data) {
    return null
  }
  const { scanError, status, taskId, message } = data
  const error = message || 'An unknown error has occurred'

  return (
    <Result isError={!!scanError}>
      <TableEllipsisCell
        value={status}
        elementType={'span'}
        data-for={`result-${taskId}`}
        data-event="mouseenter"
        data-event-off="mouseleave"
        data-tip
      />
      <CopyIcon id="child">
        {status === 'Error' && (
          <IoCopyOutline
            size={16}
            style={styles.copyIcon}
            onClick={() => {
              navigator.clipboard.writeText(error)
              setIsCopied(true)
            }}
          />
        )}
        {isCopied && (
          <div style={styles.copiedText} onClick={closeHandler()}>
            Copied
          </div>
        )}
      </CopyIcon>
      <ScanStatusTooltip id={`result-${taskId}`} scanResult={status} error={error} />
    </Result>
  )
}

function ScanStatus(props) {
  useEffect(() => {
    ReactTooltip.rebuild()
  }, [])

  const {
    app,
    isFetchingHistory,
    isScanModalOpen,
    drawerData,
    dropdownFilters,
    currentActionMode,
    toggleScanModal,
    toggleDrawer,
    scanOrganization,
    scanningOrg,
    scanHierarchical,
    scanningEnv,
    setScanningEnv,
    setListData,
    refresh,
    setRefresh,
    thisAppManage,
  } = props

  const supportsScanning = get(app, 'catalogApplication.supportsEnvironmentScanning')
  const isHierarchical = get(app, 'catalogApplication.requiresHierarchicalModel')
  const [loading, setLoading] = useState(false)
  const [latestScans, setLatestScans] = useState(null)
  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
      minWidth: 300,
      fixed: 'left',
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      Cell: renderActionIcons,
      resizable: false,
      fixed: 'left',
      sortable: false,
      width: 64,
    },
    {
      Header: 'Scan Type',
      accessor: 'scanType',
      Cell: ({ original: { scanType } }) => (scanType ? scanType : 'Environment'),
      minWidth: 200,
    },
    {
      Header: 'Scan Date',
      accessor: 'timestamp',
      Cell: ScanDate,
      minWidth: 200,
    },
    { Header: 'Scanned By', accessor: 'scannedBy', minWidth: 150 },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ScanResult,
      resizable: false,
      minWidth: 150,
    },
    {
      Header: 'Summary',
      accessor: 'scanDataSummary',
      Cell: renderSummary,
      minWidth: 640,
    },
  ]

  async function getLatestScans() {
    try {
      const response = await preFetch({
        path: `/apps/${app.appContainerId}/scans/env-status/history?view=latest`,
        method: 'get',
      })
      setLatestScans(response.data.map(d => d.taskId))
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  function renderActionIcons({ original: data }) {
    const { appContainerId: appId } = app
    const { scanType, orgScanDataSummary, scanDataSummary, taskId } = data
    if (
      !isEmpty(scanDataSummary) &&
      !isEmpty(latestScans) &&
      latestScans.includes(taskId)
    ) {
      const envId = scanType === 'Organization' ? 'org' : scanDataSummary.envId
      const iconRef = React.createRef()

      const orgScanSummary = isEmpty(orgScanDataSummary)
        ? scanDataSummary.orgScanSummary
        : orgScanDataSummary

      const handleClick = () =>
        scanType === 'Organization'
          ? routing.appOrgScanDetails({ appId, orgScanDataSummary: orgScanSummary })
          : routing.appScanDetails({ appId, envId })

      return (
        <span style={styles.detailsIconWrapper}>
          <span
            onClick={handleClick}
            className="fa fa-list fs:18"
            ref={iconRef}
            data-tip
            style={styles.detailsIcon}
            onMouseEnter={() => ReactTooltip.show(iconRef.current)}
            onMouseLeave={() => ReactTooltip.hide(iconRef.current)}
          />
        </span>
      )
    }
    return null
  }

  function renderSummary({ original: data, value: summaryData }) {
    const { toggleDrawer } = props
    const { taskId, orgScanDataSummary, scanType } = data
    if (isEmpty(summaryData)) {
      return null
    }
    const getScanSummaryData = () => {
      const {
        newAccountsDetected,
        newPermissionsDetected,
        newGroupsDetected,
      } = summaryData
      return (
        <Fragment>
          New Accounts: {newAccountsDetected}, New Permissions:{' '}
          {newPermissionsDetected}, New Groups: {newGroupsDetected}...
        </Fragment>
      )
    }
    const orgScanSummary = isEmpty(orgScanDataSummary)
      ? summaryData.orgScanSummary
      : orgScanDataSummary

    const getOrgSummaryData = () =>
      orgScanSummary
        ? Object.keys(orgScanSummary)
            .map(
              o =>
                `New ${startCase(pluralize(o))}: ${orgScanSummary[o].added.length}`
            )
            .join(', ')
            .concat(!isEmpty(orgScanSummary) ? '...' : '')
        : null
    return (
      <TableEllipsisCell
        onClick={() =>
          toggleDrawer({
            ...summaryData,
            scanType,
            taskId,
            orgScanDataSummary: orgScanSummary,
          })
        }
        style={styles.summaryCell}
      >
        <span style={styles.summaryText}>
          {scanType === 'Environment' || !scanType
            ? getScanSummaryData()
            : getOrgSummaryData()}
        </span>
      </TableEllipsisCell>
    )
  }

  if (isFetchingHistory) {
    return <PageLoader text="Loading Scan History" />
  }

  return (
    <Wrapper>
      {thisAppManage && (
        <ScanButtonWrapper>
          {!isHierarchical ? (
            <Fragment>
              {supportsScanning && (
                <Button
                  color="primary"
                  onClick={scanOrganization}
                  spinner={scanningOrg}
                >
                  Scan Organization
                </Button>
              )}
              <Button color="primary" onClick={toggleScanModal}>
                Scan Environments
              </Button>
            </Fragment>
          ) : (
            <Button
              color="primary"
              onClick={scanHierarchical}
              spinner={scanningEnv || scanningOrg}
            >
              Scan
            </Button>
          )}
        </ScanButtonWrapper>
      )}
      <Table
        setListData={setListData}
        spinner={loading}
        columns={columns}
        identifier="taskId"
        dataUrl={`/apps/${app.appContainerId}/scans/env-status/history`}
        preDataFetch={getLatestScans}
        emptyTableMessage={'No Scans were found.'}
        refresh={refresh}
        showRefreshButton
        currentActionMode={currentActionMode}
        dropdownFilters={dropdownFilters}
      />

      <Tooltip place="bottom" effect="solid" marginY={'0'}>
        Show Details
      </Tooltip>

      {drawerData && (
        <ScanStatusDrawer drawerData={drawerData} toggleDrawer={toggleDrawer} />
      )}

      {(scanningEnv || isScanModalOpen) && (
        <ScanModal
          toggleModal={isScanModalOpen ? toggleScanModal : null}
          refreshScanData={() => setRefresh(refresh + 1)}
          scanningEnv={scanningEnv}
          setScanningEnv={setScanningEnv}
          isScanModalOpen={isScanModalOpen}
        />
      )}
    </Wrapper>
  )
}

export default memo(ScanStatus)
