export const AUTHENTICATE = 'AUTHENTICATE'
export const USER_TENANT_APPS = 'USER_TENANT_APPS'
export const USER_TENANT_APP_PAPS = 'USER_TENANT_APPS_PAPS'
export const GET_USER_APPS = 'GET_USER_APPS'
export const GET_USER_APPS_V1 = 'GET_USER_APPS_V1'
export const GET_USER_ENVIRONMENTS = 'GET_USER_ENVIRONMENTS'
export const GET_USER_ENVIRONMENTS_V1 = 'GET_USER_ENVIRONMENTS_V1'
export const GET_USER_PROFILES = 'GET_USER_PROFILES'
export const GET_USER_PROFILES_V1 = 'GET_USER_PROFILES_V1'
export const GET_ALL_PROFILES = 'GET_ALL_PROFILES'
export const GET_ALL_PROFILES_V1 = 'GET_ALL_PROFILES_V1'
export const GET_FREQUENTLY_USED_PROFILES = 'GET_FREQUENTLY_USED_PROFILES'
export const GET_FREQUENTLY_USED_PROFILES_V1 = 'GET_FREQUENTLY_USED_PROFILES_V1'
export const GET_FAVORITE_PROFILES = 'GET_FAVORITE_PROFILES'
export const GET_FAVORITE_PROFILES_V1 = 'GET_FAVORITE_PROFILES_V1'
export const GET_CHECKED_OUT_PROFILES = 'GET_CHECKED_OUT_PROFILES'
export const GET_APP_ACCESS_STATUS = 'GET_APP_ACCESS_STATUS'
export const ACCESS_CHECK_OUT_PAP = 'ACCESS_CHECK_OUT_PAP'
export const ACCESS_CHECK_IN_PAP = 'ACCESS_CHECK_IN_PAP'
export const ACCESS_CHECK_OUT_SUBMIT = 'ACCESS_CHECK_OUT_SUBMIT'
export const ACCESS_CHECK_IN_SUBMIT = 'ACCESS_CHECK_IN_SUBMIT'
export const MARK_FAVORITE = 'MARK_FAVORITE'
export const DELETE_FAVORITE = 'DELETE_FAVORITE'
export const MARK_FAVORITE_V1 = 'MARK_FAVORITE_V1'
export const DELETE_FAVORITE_V1 = 'DELETE_FAVORITE_V1'
export const NOT_AUTHENTICATED = 'NOT_AUTHENTICATED'
export const ACCESS_DENIED = 'ACCESS_DENIED'
export const GET_USER = 'GET_USER'
export const GET_USER_MFA_STATUS = 'GET_USER_MFA_STATUS'
export const REGISTER_USER_MFA = 'REGISTER_USER_MFA'
export const VALIDATE_USER_MFA = 'VALIDATE_USER_MFA'
export const GET_USERS = 'GET_USERS'
export const GET_SELECTED_USERS = 'GET_SELECTED_USERS'
export const GET_ROLES = 'GET_ROLES'
export const ADD_USER = 'ADD_USER'
export const UPDATE_USER = 'UPDATE_USER'
export const ADD_PROVIDER_SERVICE_IDENTITY = 'ADD_PROVIDER_SERVICE_IDENTITY'
export const GET_IDENTITY_PROVIDERS = 'GET_IDENTITY_PROVIDERS'
export const GET_OIDC_IDENTITY_PROVIDERS = 'GET_OIDC_IDENTITY_PROVIDERS'
export const GET_IDENTITY_PROVIDER = 'GET_IDENTITY_PROVIDER'
export const GET_SCIM_PROVIDERS = 'GET_SCIM_PROVIDERS'
export const GET_SSO_PROVIDERS = 'GET_SSO_PROVIDERS'
export const ADD_IDENTITY_PROVIDER = 'ADD_IDENTITY_PROVIDER'
export const UPDATE_IDENTITY_PROVIDER = 'UPDATE_IDENTITY_PROVIDER'
export const DELETE_IDENTITY_PROVIDER = 'DELETE_IDENTITY_PROVIDER'
export const UPLOAD_SAML_METADATA = 'UPLOAD_SAML_METADATA'
export const DOWNLOAD_SIGNING_CERTIFICATE = 'DOWNLOAD_SIGNING_CERTIFICATE'
export const GET_SCIM_TOKEN = 'GET_SCIM_TOKEN'
export const CREATE_SCIM_TOKEN = 'CREATE_SCIM_TOKEN'
export const UPDATE_SCIM_TOKEN = 'UPDATE_SCIM_TOKEN'
export const REVOKE_SCIM_TOKEN = 'REVOKE_SCIM_TOKEN'
export const GET_SCIM_ATTRIBUTES = 'GET_SCIM_ATTRIBUTES'
export const UPDATE_SCIM_USER_MAPPING = 'UPDATE_SCIM_USER_MAPPING'
export const GET_CUSTOM_ATTRIBUTES = 'GET_CUSTOM_ATTRIBUTES'
export const GET_CUSTOM_ATTRIBUTE = 'GET_CUSTOM_ATTRIBUTE'
export const DELETE_CUSTOM_ATTRIBUTE = 'DELETE_CUSTOM_ATTRIBUTE'
export const GET_CUSTOM_ATTRIBUTE_TYPES = 'GET_CUSTOM_ATTRIBUTE_TYPES'
export const ADD_CUSTOM_ATTRIBUTE = 'ADD_CUSTOM_ATTRIBUTE'
export const UPDATE_CUSTOM_ATTRIBUTE = 'UPDATE_CUSTOM_ATTRIBUTE'
export const UPDATE_PAPS = 'UPDATE_PAPS'
export const GET_ALL_APPS = 'GET_ALL_APPS'
export const GET_TENANT_APP = 'GET_TENANT_APP'
export const CREATE_TENANT_PAP = 'CREATE_TENANT_PAP'
export const GET_ALL_PAPS = 'GET_ALL_PAPS'
export const LOGOUT = 'LOGOUT'
export const CONFIG = 'CONFIG'
export const GET_PAP_CONFIG = 'GET_PAP_CONFIG'
export const GET_ALL_CATALOG_APPS = 'GET_ALL_CATALOG_APPS'
export const REGISTER_CATALOG_APP = 'REGISTER_CATALOG_APP'
export const GET_PAPS_FOR_APP = 'GET_PAPS_FOR_APP'
export const UPDATE_USERS_OF_PAP = 'UPDATE_USERS_OF_PAP'
export const UPDATE_TENANT_APP = 'UPDATE_TENANT_APP'
export const CREATE_TENANT_APP = 'CREATE_TENANT_APP'
export const TEST_TENANT_APP = 'TEST_TENANT_APP'
export const SCAN_TENANT_APP = 'SCAN_TENANT_APP'
export const GET_TENANT_APP_PERMISSIONS = 'GET_TENANT_APP_PERMISSIONS'
export const GET_TENANT_APP_ACCOUNTS = 'GET_TENANT_APP_ACCOUNTS'
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const UPDATE_TENANT_APP_ACCOUNTS = 'UPDATE_TENANT_APP_ACCOUNTS'
export const TOGGLE_PRIVILEGED = 'TOGGLE_PRIVILEGED'
export const MAP_ACCOUNT_TO_USER = 'MAP_ACCOUNT_TO_USER'
export const UNMAP_ACCOUNT_FROM_USER = 'UNMAP_ACCOUNT_FROM_USER'
export const UPDATE_NAVIGATION = 'UPDATE_NAVIGATION'
export const EXPAND_NAVIGATION = 'EXPAND_NAVIGATION'
export const GET_AVAILABLE_PAP_PERMISSIONS = 'GET_AVAILABLE_PAP_PERMISSIONS'
export const GET_TENANT_FROM_URL = 'GET_TENANT_FROM_URL'
export const UNREGISTER_PERMISSIONS = 'UNREGISTER_PERMISSIONS'
export const GET_PAPS = 'GET_PAPS'
export const AUTHENTICATE_REJECTED = 'AUTHENTICATE_REJECTED'
export const NOTIFICATION_CONNECTED = 'NOTIFICATION_CONNECTED'
export const ALL_NOTIFICATIONS = 'ALL_NOTIFICATIONS'
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION'
export const REMOVE_NOTIFICATIONS = 'REMOVE_NOTIFICATIONS'
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION'
export const DELETE_ALL_NOTIFICATIONS = 'DELETE_ALL_NOTIFICATIONS'
export const CHECK_OUT_APPROVAL_EXPIRED = 'CHECK_OUT_APPROVAL_EXPIRED'
export const CHECKED_OUT_NOTIFICATION = 'CHECKED_OUT_NOTIFICATION'
export const CHECK_OUT_REJECTED_NOTIFICATION = 'CHECK_OUT_REJECTED_NOTIFICATION'
export const CHECK_OUT_FAILED_NOTIFICATION = 'CHECK_OUT_FAILED_NOTIFICATION'
export const CHECKED_IN_NOTIFICATION = 'CHECKED_IN_NOTIFICATION'
export const CHECK_IN_FAILED_NOTIFICATION = 'CHECK_IN_FAILED_NOTIFICATION'
export const GET_POLICIES = 'GET_POLICIES'
export const CHANGE_PAP_POLICY_STATUS = 'CHANGE_PAP_POLICY_STATUS'
export const DELETE_PAP_POLICY = 'DELETE_PAP_POLICY'
export const EXTEND_EXPIRATION = 'EXTEND_EXPIRATION'
export const REQUEST_APPROVED_NOTIFICATION = 'REQUEST_APPROVED_NOTIFICATION'
export const REQUEST_CANCELLED_NOTIFICATION = 'REQUEST_CANCELLED_NOTIFICATION'
export const APPROVAL_REQUEST_SUBMIT = 'APPROVAL_REQUEST_SUBMIT'
export const GET_PERMISSION_CONSTRAINT_TYPES = 'GET_PERMISSION_CONSTRAINT_TYPES'
export const GET_PERMISSION_CONSTRAINTS = 'GET_PERMISSION_CONSTRAINTS'
export const DELETE_PERMISSION_CONSTRAINTS = 'DELETE_PERMISSION_CONSTRAINTS'
export const ADD_PERMISSION_CONSTRAINTS = 'ADD_PERMISSION_CONSTRAINTS'
export const VALIDATE_PERMISSION_CONSTRAINTS = 'VALIDATE_PERMISSION_CONSTRAINTS'
export const ADD_PERMISSION_CONSTRAINTS_INITIAL =
  'ADD_PERMISSION_CONSTRAINTS_INITIAL'
export const SET_FILTER_APPLICATION = 'SET_FILTER_APPLICATION'
export const CLEAR_FILTER = 'CLEAR_FILTER'
export const SET_OPEN = 'SET_OPEN'
export const ACCESS_REQUEST_SUBMIT = 'ACCESS_REQUEST_SUBMIT'
export const SET_FILTER_COUNT = 'SET_FILTER_COUNT'
export const CACHE_PAP_POLICY_DATA = 'CACHE_PAP_POLICY_DATA'
export const ACCESS_REQUEST_SUBMIT_PROFILE = 'ACCESS_REQUEST_SUBMIT_PROFILE'
export const ACCESS_REQUEST_DELETE_PROFILE = 'ACCESS_REQUEST_DELETE_PROFILE'
export const GET_PAGINATED_USERS = 'GET_PAGINATED_USERS'
export const GET_PAGINATED_USERS_LOAD_MORE = 'GET_PAGINATED_USERS_LOAD_MORE'
export const GET_PAGINATED_TAGS = 'GET_PAGINATED_USERS'
export const GET_PAGINATED_TAGS_LOAD_MORE = 'GET_PAGINATED_USERS_LOAD_MORE'
export const GET_MFA_SETTINGS = 'GET_MFA_SETTINGS'
export const UPDATE_MFA_SETTINGS = 'UPDATE_MFA_SETTINGS'
export const AUTHENTICATE_STEP_UP_MFA = 'AUTHENTICATE_STEP_UP_MFA'
export const GET_BROKERS = 'GET_BROKERS'
export const GET_BROKERS_BY_POOL = 'GET_BROKERS_BY_POOL'
export const GET_BROKER_POOLS = 'GET_BROKER_POOLS'
export const REMOVE_CACHED_BROKER_POOLS = 'REMOVE_CACHED_BROKER_POOLS'
export const CREATE_BROKER_POOLS = 'CREATE_BROKER_POOLS'
export const DELETE_BROKER_POOL = 'CREATE_BROKER_POOL'
export const GET_RESOURCE_LABELS = 'GET_RESOURCE_LABELS'
export const GET_RESOURCE_LABEL = 'GET_RESOURCE_LABEL'
export const CREATE_RESOURCE_LABEL = 'CREATE_RESOURCE_LABEL'
export const DELETE_RESOURCE_LABEL = 'DELETE_RESOURCE_LABEL'
export const REMOVE_RESOURCE_LABELS = 'REMOVE_RESOURCE_LABELS'
export const GET_RESOURCE_TYPES = 'GET_RESOURCE_TYPES'
export const GET_RESOURCE_TYPES_LOAD_MORE = 'GET_RESOURCE_TYPES_LOAD_MORE'
export const GET_RESOURCE_TYPE = 'GET_RESOURCE_TYPE'
export const UPDATE_RESOURCE_TYPE = 'UPDATE_RESOURCE_TYPE'
export const PARTIALLY_UPDATE_RESOURCE_TYPE = 'PARTIALLY_UPDATE_RESOURCE_TYPE'
export const CREATE_RESOURCE_TYPE = 'CREATE_RESOURCE_TYPE'
export const DELETE_RESOURCE_TYPE = 'DELETE_RESOURCE_TYPE'
export const REMOVE_RESOURCE_TYPES = 'REMOVE_RESOURCE_TYPES'
export const CREATE_BROKER_POOL_TOKEN = 'CREATE_BROKER_POOL_TOKEN'
export const UPDATE_BROKER_POOL_TOKEN = 'UPDATE_BROKER_POOL_TOKEN'
export const DELETE_BROKER_POOL_TOKEN = 'DELETE_BROKER_POOL_TOKEN'
export const GET_BROKER_POOL_TOKEN = 'GET_BROKER_POOL_TOKEN'
export const GET_BROKER_POOL_LABELS = 'GET_BROKER_POOL_LABELS'
export const ADD_BROKER_POOL_LABELS = 'ADD_BROKER_POOL_LABELS'
export const UPDATE_BROKER_POOL_LABELS = 'UPDATE_BROKER_POOL_LABELS'
export const DELETE_BROKER_POOL_LABEL = 'DELETE_BROKER_POOL_LABEL'
export const PROFILE_MANAGEMENT_GET_PROFILES = 'PROFILE_MANAGEMENT_GET_PROFILES'
export const PROFILE_MANAGEMENT_GET_PROFILES_LOAD_MORE =
  'PROFILE_MANAGEMENT_GET_PROFILES_LOAD_MORE'
export const PROFILE_MANAGEMENT_DELETE_PROFILE = 'PROFILE_MANAGEMENT_DELETE_PROFILE'
export const PROFILE_MANAGEMENT_TOGGLE_PROFILE = 'PROFILE_MANAGEMENT_TOGGLE_PROFILE'
export const PROFILE_MANAGEMENT_CREATE_PROFILE = 'PROFILE_MANAGEMENT_CREATE_PROFILE'
export const PROFILE_MANAGEMENT_GET_POLICIES = 'PROFILE_MANAGEMENT_GET_POLICIES'
export const PROFILE_MANAGEMENT_DELETE_POLICY = 'PROFILE_MANAGEMENT_DELETE_POLICY'
export const PROFILE_MANAGEMENT_GET_ASSOCIATIONS =
  'PROFILE_MANAGEMENT_GET_ASSOCIATIONS'
export const GET_RESOURCES = 'GET_RESOURCES'
export const DELETE_RESOURCE = 'DELETE_RESOURCE'
export const CREATE_RESOURCE = 'CREATE_RESOURCE'
export const UPDATE_RESOURCE = 'UPDATE_RESOURCE'
export const GET_RESOURCE_BY_ID = 'GET_RESOURCE_BY_ID'
export const PROFILE_MANAGEMENT_ADD_ASSOCIATIONS =
  'PROFILE_MANAGEMENT_ADD_ASSOCIATIONS'
export const PROFILE_MANAGEMENT_PROFILE_DETAILS =
  'PROFILE_MANAGEMENT_PROFILE_DETAILS'
export const PROFILE_MANAGEMENT_UPDATE_PROFILE = 'PROFILE_MANAGEMENT_UPDATE_PROFILE'
export const PROFILE_MANAGEMENT_GET_PERMISSIONS =
  'PROFILE_MANAGEMENT_GET_PERMISSIONS'
export const PROFILE_MANAGEMENT_DELETE_PERMISSION =
  'PROFILE_MANAGEMENT_DELETE_PERMISSION'
export const GET_PERMISSIONS_BY_RESOURCE_TYPE = 'GET_PERMISSIONS_BY_RESOURCE_TYPE'
export const GET_PERMISSION_BY_VERSION = 'GET_PERMISSION_BY_VERSION'
export const GET_PERMISSION_PRE_SIGNED_URL = 'GET_PERMISSION_PRE_SIGNED_URL'
export const GET_PERMISSION_BY_ID = 'GET_PERMISSION_BY_ID'
export const CLEAR_PERMISSION_BY_ID = 'CLEAR_PERMISSION_BY_ID'
export const UPDATE_PERMISSION_BY_ID = 'UPDATE_PERMISSION_BY_ID'
export const REMOVE_CACHED_RESOURCE_BY_ID = 'REMOVE_CACHED_RESOURCE_BY_ID'
export const PROFILE_MANAGEMENT_GET_AVAILABLE_PERMISSIONS =
  'PROFILE_MANAGEMENT_GET_AVAILABLE_PERMISSIONS'
export const PROFILE_MANAGEMENT_UPDATE_PERMISSIONS_VARIABLES =
  'PROFILE_MANAGEMENT_UPDATE_PERMISSIONS_VARIABLES'
export const PROFILE_MANAGEMENT_UPDATE_PERMISSIONS_VALUES =
  'PROFILE_MANAGEMENT_UPDATE_PERMISSIONS_VALUES'
export const CREATE_RESOURCE_TYPE_PERMISSION = 'CREATE_RESOURCE_TYPE_PERMISSION'
export const DELETE_RESOURCE_TYPE_PERMISSION = 'DELETE_RESOURCE_TYPE_PERMISSION'
export const DELETE_RESOURCE_TYPE_PERMISSION_VERSION =
  'DELETE_RESOURCE_TYPE_PERMISSION_VERSION'
export const MY_RESOURCES_GET_PROFILES = 'MY_RESOURCES_GET_PROFILES'
export const MY_RESOURCES_CHECKOUT_PROFILE = 'MY_RESOURCES_CHECKOUT_PROFILE'
export const MY_RESOURCES_CHECKIN_PROFILE = 'MY_RESOURCES_CHECKIN_PROFILE'
export const MY_RESOURCES_GET_CREDENTIALS = 'MY_RESOURCES_GET_CREDENTIALS'
export const MY_RESOURCES_ADD_FAVORITE = 'MY_RESOURCES_ADD_FAVORITE'
export const MY_RESOURCES_DELETE_FAVORITE = 'MY_RESOURCES_DELETE_FAVORITE'
export const MY_RESOURCES_GET_AVAILABLE_TEMPLATES =
  'MY_RESOURCES_GET_AVAILABLE_TEMPLATES'
export const MY_RESOURCES_SUBMIT_CHECKOUT_REQUEST =
  'MY_RESOURCES_SUBMIT_CHECKOUT_REQUEST'
export const MY_RESOURCES_WITHDRAW_APPROVAL_REQUEST =
  'MY_RESOURCES_WITHDRAW_APPROVAL_REQUEST'
export const MY_RESOURCES_CHECKED_OUT_NOTIFICATION =
  'MY_RESOURCES_CHECKED_OUT_NOTIFICATION'
export const MY_RESOURCES_CHECKED_IN_NOTIFICATION =
  'MY_RESOURCES_CHECKED_IN_NOTIFICATION'
export const MY_RESOURCES_CHECK_OUT_FAILED_NOTIFICATION =
  'MY_RESOURCES_CHECK_OUT_FAILED_NOTIFICATION'
export const MY_RESOURCES_CHECK_IN_FAILED_NOTIFICATION =
  'MY_RESOURCES_CHECK_IN_FAILED_NOTIFICATION'
export const MY_RESOURCES_CHECK_OUT_REJECTED_NOTIFICATION =
  'MY_RESOURCES_CHECK_OUT_REJECTED_NOTIFICATION'
export const MY_RESOURCES_REQUEST_APPROVED_NOTIFICATION =
  'MY_RESOURCES_REQUEST_APPROVED_NOTIFICATION'
export const MY_RESOURCES_CHECK_OUT_APPROVAL_EXPIRED =
  'MY_RESOURCES_CHECK_OUT_APPROVAL_EXPIRED'
export const MY_RESOURCES_CHECK_OUT_TIMEOUT_NOTIFICATION =
  'MY_RESOURCES_CHECK_OUT_TIMEOUT_NOTIFICATION'
export const MY_RESOURCES_CHECK_IN_TIMEOUT_NOTIFICATION =
  'MY_RESOURCES_CHECK_IN_TIMEOUT_EXPIRED'
export const PROFILE_MANAGEMENT_GET_SYSTEM_DEFINED_VALUES =
  'PROFILE_MANAGEMENT_GET_SYSTEM_DEFINED_VALUES'
export const GET_RESOURCES_POLICIES = 'GET_RESOURCES_POLICIES'
export const DELETE_RESOURCE_POLICIES = 'DELETE_RESOURCE_POLICIES'
export const GET_BROKER_POOLS_BY_RESOURCE = 'GET_BROKER_POOLS_BY_RESOURCE'
export const ADD_POOLS_TO_RESOURCE = 'ADD_POOLS_TO_RESOURCE'
export const DELETE_POOL_FROM_RESOURCE = 'DELETE_POOL_FROM_RESOURCE'
export const FETCH_DOWNLOADABLE_BROKERS = 'FETCH_DOWNLOADABLE_BROKERS'
export const FETCH_AUDITLOG_WEBHOOK = 'FETCH_AUDITLOG_WEBHOOK'
export const FETCH_WEBHOOK_BY_NOTIFICATION_MEDIUM_ID =
  'FETCH_WEBHOOK_BY_NOTIFICATION_MEDIUM_ID'
export const DELETE_WEBHOOK_BY_NOTIFICATION_MEDIUM_ID =
  'DELETE_WEBHOOK_BY_NOTIFICATION_MEDIUM_ID'
export const UPDATE_WEBHOOK_BY_NOTIFICATION_MEDIUM_ID =
  'UPDATE_WEBHOOK_BY_NOTIFICATION_MEDIUM_ID'
export const REMOVE_SELECTED_WEBHOOK_DATA = 'REMOVE_SELECTED_WEBHOOK_DATA'
export const GET_RESPONSE_TEMPLATES = 'GET_RESPONSE_TEMPLATES'
export const GET_RESPONSE_TEMPLATE = 'GET_RESPONSE_TEMPLATE'
export const CREATE_RESPONSE_TEMPLATE = 'CREATE_RESPONSE_TEMPLATE'
export const UPDATE_RESPONSE_TEMPLATE = 'UPDATE_RESPONSE_TEMPLATE'
export const DELETE_RESPONSE_TEMPLATE = 'DELETE_RESPONSE_TEMPLATE'
export const FETCH_USERS_WITH_ACTIVE_SESSIONS = 'FETCH_USERS_WITH_ACTIVE_SESSIONS'
