import React, { Fragment } from 'react'
import ScopeSelector from 'components/ScopeSelector'
import FormField from 'components/form_fields'
import styled from 'styled-components'
import Button from 'britive-ui-components/core/components/Button'
import Spinner from 'britive-ui-components/core/components/Spinner'
import { CheckBoxBoolean } from 'components/form_fields'

const Wrapper = styled.div`
  background-color: #fff;
  box-shadow: 0 2px 8px 3px rgba(206, 206, 206, 0.21);
  padding: 10px 30px;
  min-height: 180px;
`

export default ({
  values,
  setValues,
  useBritiveIntegrationRole,
  onBritiveIntegrationRoleClick,
  editMode,
  setEditMode,
  handleSave,
  fetching,
  saving,
  error,
  defaultValues,
  visualCuesData,
  refreshApplication,
  isRefreshing,
  thisAppManage,
}) => (
  <Fragment>
    {fetching || !values ? (
      <Spinner size={'2x'} />
    ) : (
      <Fragment>
        <Wrapper>
          <h5>Configuration</h5>
          <FormField
            hideLabel={false}
            label={'Ingestion Account ID'}
            name={'ingestionAccountId'}
            type="text"
            className="col-5"
            value={values.ingestionAccountId}
            onChange={({ target: { value } }) =>
              setValues({
                ...values,
                ingestionAccountId: value,
              })
            }
            placeholder={'Ingestion Account ID'}
            disabled={!editMode}
          />
          <CheckBoxBoolean
            name="useBritiveIntegrationRole"
            label="Use Britive Integration Role"
            onChange={onBritiveIntegrationRoleClick}
            className="form-group form-check-property col-5"
            checked={useBritiveIntegrationRole}
            disabled={!editMode}
          />
          <FormField
            hideLabel={false}
            label="Ingestion Integration Role"
            name="ingestionIntegrationRole"
            type="text"
            className="col-5"
            value={
              useBritiveIntegrationRole
                ? values.appIntegrationRole
                : values.ingestionIntegrationRole || ''
            }
            onChange={({ target: { value } }) =>
              setValues({
                ...values,
                ingestionIntegrationRole: value,
              })
            }
            placeholder="Ingestion Integration Role"
            disabled={!editMode || useBritiveIntegrationRole}
            isFieldInvalid={
              editMode &&
              !useBritiveIntegrationRole &&
              error &&
              error.ingestionIntegrationRole
            }
          />
          {editMode &&
            !useBritiveIntegrationRole &&
            error &&
            error.ingestionIntegrationRole && (
              <div style={{ color: 'red', margin: '-15px 15px 10px' }}>
                {error.ingestionIntegrationRole}
              </div>
            )}
        </Wrapper>
        <Wrapper>
          <h5>Realtime Data</h5>
          <FormField
            hideLabel={false}
            label={'Queue URL'}
            name={'queueUrl'}
            type="text"
            className="col-5"
            value={values.queueUrl}
            onChange={({ target: { value } }) =>
              setValues({
                ...values,
                queueUrl: value,
              })
            }
            placeholder={'Queue URL'}
            disabled={!editMode}
          />
          <ScopeSelector
            scope={values.scope}
            historicalScope={values.historicalScope}
            defaultValues={defaultValues.scope}
            onChange={scope =>
              setValues({
                ...values,
                scope: {
                  ...scope,
                  ...(values.historicalScope ? values.historicalScope : {}),
                  ...defaultValues.scope,
                },
              })
            }
            disabled={!editMode}
            visualCuesData={visualCuesData}
            visualCueDataType="realtime"
            refreshApplication={refreshApplication}
            isRefreshing={isRefreshing}
          />
        </Wrapper>
        <Wrapper>
          <h5>Historical Data</h5>
          <FormField
            hideLabel={false}
            label={'Cloud Trail S3 Bucket With Region'}
            name={'historicalLocationId'}
            type="text"
            className="col-5"
            value={
              values.historicalLocationId
                ? values.historicalLocationId
                : 'https://s3.console.aws.amazon.com/s3/buckets/{bucket-name}?region={region}'
            }
            onChange={({ target: { value } }) =>
              setValues({
                ...values,
                historicalLocationId: value,
              })
            }
            disabled={!editMode}
          />
          <FormField
            hideLabel={false}
            label={'Cloud Trail S3 Object Prefix'}
            name={'historicalObjectPrefix'}
            type="text"
            className="col-5"
            value={values.historicalObjectPrefix}
            onChange={({ target: { value } }) =>
              setValues({
                ...values,
                historicalObjectPrefix: value,
              })
            }
            placeholder={'Cloud Trail S3 Object Prefix'}
            disabled={!editMode}
          />
          <ScopeSelector
            label={"(Once an option is selected and saved, you can't unselect it)"}
            scope={values.historicalScope}
            defaultValues={defaultValues.historicalScope}
            onChange={historicalScope =>
              setValues({
                ...values,
                historicalScope: {
                  ...historicalScope,
                  ...defaultValues.historicalScope,
                },
                scope: { ...historicalScope, ...defaultValues.scope },
              })
            }
            disabled={!editMode}
            visualCuesData={visualCuesData}
            visualCueDataType="historical"
            refreshApplication={refreshApplication}
            isRefreshing={isRefreshing}
          />
        </Wrapper>
        {editMode && error && error.save && (
          <div style={{ color: 'red', margin: '10px 15px -15px' }}>{error.save}</div>
        )}
        {thisAppManage &&
          (editMode ? (
            <Fragment>
              <Button
                disabled={saving}
                onClick={() => setEditMode(false)}
                style={{ marginTop: 15, marginLeft: 15 }}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                spinner={saving}
                onClick={handleSave}
                style={{ marginTop: 15, marginLeft: 15 }}
              >
                Save
              </Button>
            </Fragment>
          ) : (
            <Button
              color="primary"
              disabled={false}
              onClick={() => setEditMode(true)}
              style={{ marginTop: 15, marginLeft: 15 }}
            >
              Edit
            </Button>
          ))}
      </Fragment>
    )}
  </Fragment>
)
