import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'

import SessionActivity from './SessionActivity'

import { selectApplicationRootId } from 'action_creators/application_root'
import { fetchSessionActivity } from 'action_creators/activity'

import {
  getSelectedSessionActivity,
  getSessionActivityFetchingStatus,
  getSessionActivityMetaInfo,
} from 'selectors/activity'

import { getSelectedApplicationRoot } from 'selectors/application_root'

const ACTIVITY_SIZE = 20
const ACTIVITY_SORT = 'desc'

export class SessionActivityContainer extends PureComponent {
  componentDidMount = () => {
    const { dispatch, match, appId, app, setBreadcrumbTrail } = this.props
    const { catalogAppDisplayName: appName } = app
    const { transactionId } = match.params
    const fetchParams = { size: ACTIVITY_SIZE, page: 0, sort: ACTIVITY_SORT }

    if (appId) {
      dispatch(selectApplicationRootId(appId))
      setBreadcrumbTrail([
        { title: `${appName} Activity`, path: 'activity' },
        { title: `${transactionId} Activity` },
      ])
      dispatch(fetchSessionActivity(appId, transactionId, fetchParams, true))
    }
  }

  fetchMoreActivites = () => {
    const { dispatch, match, appId, activityMetaInfo } = this.props
    const { transactionId } = match.params
    const page = get(activityMetaInfo, 'page')
    const nextPage = page + 1
    const fetchParams = {
      size: ACTIVITY_SIZE,
      page: nextPage,
      sort: ACTIVITY_SORT,
    }

    dispatch(fetchSessionActivity(appId, transactionId, fetchParams))
  }

  render() {
    const {
      appId,
      sessionActivity,
      isFetchingActivity,
      activityMetaInfo,
      appHeaderHeight,
    } = this.props
    const { match } = this.props
    const { transactionId } = match.params
    const { nextPageURL } = activityMetaInfo

    return (
      <SessionActivity
        appHeaderHeight={appHeaderHeight}
        sessionActivity={sessionActivity}
        isFetchingActivity={isFetchingActivity}
        transactionId={transactionId}
        applicationId={appId}
        nextPageURL={nextPageURL}
        fetchMoreActivites={this.fetchMoreActivites}
      />
    )
  }
}

const mapStateToProps = (state, { match }) => {
  const transactionId = match.params.transactionId

  return {
    app: getSelectedApplicationRoot(state),
    sessionActivity: getSelectedSessionActivity({ state, transactionId }),
    isFetchingActivity: getSessionActivityFetchingStatus({
      state,
      transactionId,
    }),
    activityMetaInfo: getSessionActivityMetaInfo({ state, transactionId }),
  }
}

export default connect(mapStateToProps)(SessionActivityContainer)
