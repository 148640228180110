import * as actions from 'actions'

const initialState = {
  loading: false,
  error: null,
}

export function profileToggleReducer(state = initialState, action) {
  switch (action.type) {
    case `${actions.PROFILE_MANAGEMENT_TOGGLE_PROFILE}_PENDING`: {
      return {
        ...state,
        loading: true,
        error: null,
      }
    }

    case `${actions.PROFILE_MANAGEMENT_TOGGLE_PROFILE}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        error: null,
      }
    }

    case `${actions.PROFILE_MANAGEMENT_TOGGLE_PROFILE}_REJECTED`: {
      return {
        ...state,
        loading: false,
        error: 'Unable to the toggle Profile.',
      }
    }

    default:
      return { ...state }
  }
}
