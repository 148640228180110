import React, { Fragment, memo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'
import Spinner from 'britive-ui-components/core/components/Spinner'
import styled from 'styled-components'

import EnvironmentGroup from './EnvironmentGroup'
import EntityIcon from './EntityIcon'

import useContainsSearchInput from 'hooks/useContainsSearchInput'
import FormField from '../form_fields'

const SearchWrapper = styled.div`
  background: #fff;
  position: sticky;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  justify-content: center;
  display: flex;

  .form-group {
    margin: 0.5rem 0;
  }
`

const Wrapper = styled.div`
  background: #fff;
  margin: 0 8px 20px 0;
  padding: 0 10px;
  height: fit-content;
  width: ${props => props.width || '100%'};
  overflow-y: auto;

  ${props => (props.minHeight ? `min-height: ${props.minHeight};` : '')}
  ${props => (props.maxHeight ? `max-height: ${props.maxHeight};` : '')}
  ${props => (props.minWidth ? `min-width: ${props.minWidth};` : '')}
  ${props => (props.maxWidth ? `max-width: ${props.maxWidth};` : '')}

  .rootGroupItem {
    background: #fff;
    box-sizing: content-box;
    display: flex;
    height: 28px;
    padding-left: 2px;
    align-items: center;
    ${props => (props.width ? `width: ${props.width}px;` : '')}

    &.selected {
      background: #f7f7f7;
    }

    &.showDivider {
      border-bottom: 1px solid var(--silver);
    }
  }
`

const RootGroup = styled.div`
  ${props =>
    props.enableHover
      ? `
    &:hover {
      cursor: pointer;
      background: #f7f7f7;
    }
  `
      : ''}
`

const NameContainer = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  overflow: hidden;
`

const CollapseButton = styled.div`
  position: relative;
  height: 12px;
  margin-right: 4px;
  width: 12px;

  > i {
    position: absolute;
    cursor: pointer;
    line-height: 1px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`

const HideWrapper = styled.div`
  ${props => (props.isCollapsed ? 'display: none;' : '')}
`

const styles = {
  emptyList: {
    marginLeft: 8,
  },
  spinnerWrapper: {
    marginLeft: 8,
  },
}

function RootGroupName({ name }) {
  return (
    <div
      title={name}
      className="nameWrapper"
      style={{
        width: 400,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      }}
    >
      <span>{name}</span>
    </div>
  )
}

// TODO: Don't love this sub root implementation

function EnvironmentGroups({
  checkmarkDict,
  customRenderers,
  environmentData,
  environmentGroups,
  environmentScanResults,
  environmentId,
  environments,
  isCalcingSearchTagDict,
  isFetchingEnvironments,
  isSearchable,
  isSelectable,
  maxHeight,
  maxWidth,
  minHeight,
  minWidth,
  onClicks,
  rootName,
  roots,
  searchInput,
  searchTagDict,
  selectedEntityId,
  setSearchInput,
  width,
  applicationRoot,
  label,
  visualCuesData,
  visualCueDataType,
  isRefreshing,
  refreshApplication,
}) {
  const [isCollapsed, setIsCollapsed] = useState({})

  const {
    selfContains: rootContains,
    childrenContain: rootChildrenContain,
    calcContainsSearchInput,
  } = useContainsSearchInput()

  const {
    selfContains: subRootContains,
    childrenContain: subRootChildrenContain,
    calcContainsSearchInput: calcSecondContainsSearchInput,
  } = useContainsSearchInput()

  const rootGroup = roots.root || {}
  const subRoot = roots.subRoot
  const collapsibleRoot = subRoot || rootGroup
  const checkmarkKeys = Object.keys(checkmarkDict)
  const isRootSelected = !!checkmarkDict[rootGroup.id]

  const isNonRootSelected =
    checkmarkKeys.length && !checkmarkKeys.some(key => key === rootGroup.id)

  useEffect(() => {
    if (!isFetchingEnvironments && !isCalcingSearchTagDict && isSearchable) {
      calcContainsSearchInput({
        searchInput: searchInput.toLowerCase(),
        searchTagDict,
        id: rootGroup.id,
        name: rootName.toLowerCase(),
      })

      if (subRoot) {
        calcSecondContainsSearchInput({
          searchInput: searchInput.toLowerCase(),
          searchTagDict,
          id: subRoot.id,
          name: subRoot.name.toLowerCase(),
        })
      }
    }
  }, [
    isSearchable,
    isFetchingEnvironments,
    isCalcingSearchTagDict,
    searchTagDict,
    searchInput,
  ])

  const doesRootContainSearch = () => {
    let containsSearch = rootContains || rootChildrenContain

    if (subRoot) {
      containsSearch = containsSearch || subRootContains || subRootChildrenContain
    }

    return containsSearch
  }

  const renderGroup = ({ id, name, isCollapsible, isDisabled }) => {
    const rootGroupClass = classNames({
      rootGroupItem: true,
      showDivider: !isCollapsible,
      selected: selectedEntityId === id,
    })

    const collapseButtonClass = classNames({
      'fa fa-plus fs:10': isCollapsed[id] && isCollapsible,
      'fa fa-minus fs:10': !isCollapsed[id] && isCollapsible,
    })

    const rootCheckboxRenderer = get(customRenderers, 'rootGroupCheckbox')
    const rootActionsRenderer = get(customRenderers, 'rootGroupActions')
    const NameRenderer = get(customRenderers, 'rootGroupName') || RootGroupName
    const entityObject = { entityType: 'EnvironmentGroup', entityId: id }
    const onClick = get(onClicks, 'rootGroup')

    return (
      <RootGroup className={rootGroupClass} enableHover={onClick}>
        <CollapseButton onClick={() => toggleCollapse(id)}>
          <i className={collapseButtonClass} />
        </CollapseButton>

        <NameContainer
          onClick={
            onClick
              ? e => {
                  e.stopPropagation()
                  onClick(entityObject)
                }
              : undefined
          }
        >
          {rootCheckboxRenderer &&
            rootCheckboxRenderer(entityObject, false, isDisabled)}

          <EntityIcon entities={environmentGroups} id={id} />

          <NameRenderer name={name} group={entityObject} />
        </NameContainer>

        {rootActionsRenderer &&
          rootActionsRenderer(entityObject, environmentGroups, isCollapsed[id])}
      </RootGroup>
    )
  }

  const renderRootGroups = () => {
    return (
      <Fragment>
        {(!isSearchable || rootContains || rootChildrenContain) &&
          renderGroup({
            id: rootGroup.id,
            name: rootName,
            isCollapsible: !subRoot,
            isDisabled: subRoot && isNonRootSelected,
          })}

        {subRoot &&
          (!isSearchable || subRootContains || subRootChildrenContain) &&
          renderGroup({
            id: subRoot.id,
            name: subRoot.name,
            isCollapsible: true,
            isDisabled: isRootSelected,
          })}
      </Fragment>
    )
  }

  const toggleCollapse = id => {
    setIsCollapsed({
      ...isCollapsed,
      [id]: !isCollapsed[id],
    })
  }

  if (isFetchingEnvironments || isCalcingSearchTagDict) {
    return (
      <Wrapper
        width={width}
        maxWidth={maxWidth}
        minWidth={minWidth}
        minHeight={minHeight}
        maxHeight={maxHeight}
        isSearchable={isSearchable}
      >
        <div style={styles.spinnerWrapper}>
          <Spinner size="2x" />
        </div>
      </Wrapper>
    )
  }

  const parentSelected = checkmarkDict[collapsibleRoot.id]

  return (
    <React.Fragment>
      {isSearchable && (
        <SearchWrapper>
          <FormField
            hideLabel={!isSelectable}
            label={label ? 'Select Environments ' + label : 'Select Environments'}
            name={'queueUrl'}
            type="text"
            disabled={isEmpty(searchTagDict)}
            className="col-5"
            value={searchInput}
            onChange={e => setSearchInput(e.target.value)}
            placeholder={'Search Environments'}
            visualCueDataType={visualCueDataType}
            isRefreshing={isRefreshing}
            refreshApplication={refreshApplication}
          />
        </SearchWrapper>
      )}

      <Wrapper
        width={width}
        maxWidth={maxWidth}
        minWidth={minWidth}
        minHeight={isSearchable ? `calc(${minHeight} - 39px)` : minHeight}
        maxHeight={maxHeight}
        isSearchable={isSearchable}
      >
        {!isSearchable || doesRootContainSearch() ? (
          renderRootGroups()
        ) : (
          <span style={styles.emptyList}>No matches</span>
        )}

        <HideWrapper isCollapsed={isCollapsed[collapsibleRoot.id]}>
          {rootGroup.id && environmentGroups && (
            <EnvironmentGroup
              environmentData={environmentData}
              checkmarkDict={checkmarkDict}
              customRenderers={customRenderers}
              environmentGroup={collapsibleRoot}
              environmentGroups={environmentGroups}
              environmentScanResults={environmentScanResults}
              environmentId={environmentId}
              environments={environments}
              isCollapsed={isCollapsed[collapsibleRoot.id]}
              isRoot
              isSearchable={isSearchable}
              level={0}
              onClicks={onClicks}
              parentContains={subRoot ? subRootContains : rootContains}
              parentSelected={parentSelected}
              parentDisabled={subRoot && isRootSelected}
              searchInput={searchInput}
              searchTagDict={searchTagDict}
              selectedEntityId={selectedEntityId}
              applicationRoot={applicationRoot}
              visualCuesData={visualCuesData}
              visualCueDataType={visualCueDataType}
            />
          )}
        </HideWrapper>
      </Wrapper>
    </React.Fragment>
  )
}

EnvironmentGroups.propTypes = {
  applicationRoot: PropTypes.object,
  applicationTemplate: PropTypes.object,
  environmentGroups: PropTypes.object,
  environmentScanResults: PropTypes.object,
  environmentId: PropTypes.string,
  fields: PropTypes.object,
  applicationEnvironmentData: PropTypes.object,
  environments: PropTypes.object,
  rootGroupId: PropTypes.string,
  visualCuesData: PropTypes.array,
  visualCueDataType: PropTypes.string,
  refreshApplication: PropTypes.func,
  isRefreshing: PropTypes.bool,
}

export default memo(EnvironmentGroups)
