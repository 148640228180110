import React from 'react'
import PropTypes from 'prop-types'
import AdminAppsContainer from '../../admin/tenant-apps/AppsContainer'

const ApplicationList = ({ history, match }) => {
  return <AdminAppsContainer requestAccess={true} history={history} match={match} />
}

ApplicationList.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
}

export default ApplicationList
