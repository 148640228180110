import React, { useEffect } from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from 'britive-design-system/core/components/spinner'
import { fetchApplicationRoot } from 'action_creators/application_root'
import ProfileDetails from '../../../../admin/applications/Application/Profiles/details'
import { classNamePrefix } from '../constants'

const ProfileDetailsContainer = ({ match, appId, requestedPolicy }) => {
  const dispatch = useDispatch()
  const { applicationRoots, fetchingApplicationRoot } = useSelector(
    state => state.applicationRoot
  )

  useEffect(() => {
    if (!applicationRoots[appId]) dispatch(fetchApplicationRoot(appId, true))
  }, [])
  return (
    <div className={`${classNamePrefix}-profile-details-container`}>
      {fetchingApplicationRoot && (
        <Spinner size="medium" overlayOnContainer={true} />
      )}
      <Switch>
        <Route
          exact
          path={`${match.url}`}
          render={() => {
            return <div>Profile Detail</div>
          }}
        />
        <Route
          exact
          path={`${match.url}/applications/:appId/profiles/:profileId/details/:tab`}
          render={props => {
            return (
              <ProfileDetails
                {...props}
                appHeaderHeight={0}
                appId={appId}
                thisAppManage={false}
                requestAccess={true}
                requestApprovals={true}
                setBreadcrumbTrail={() => {}}
                requestedPolicy={requestedPolicy}
              />
            )
          }}
        />
      </Switch>
    </div>
  )
}

export default withRouter(ProfileDetailsContainer)
