import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { preFetch } from 'utils/do_fetch'

import SettingsForm from './SettingsForm'
// import EnvironmentScanModal from '../EnvironmentScanModal'

import {
  selectEnvironmentId,
  selectEnvironmentGroupId,
} from 'action_creators/environment'

import { scanEnvironment } from 'action_creators/environment'

import { getEnvironmentsToScan } from 'routes/admin/state/selectors'
import {
  getSelectedApplicationEnvironmentScanResults,
  getIsScanningAppEnvironments,
  getSelectedApplicationEnvironments,
  getSelectedEnvironmentId,
} from 'selectors/environment'
import { getSelectedApplicationRoot } from 'selectors/application_root'

import { APP_PROPERTIES_HEADER_ID, APP_PROPERTIES_TABS_ID } from '../../constants'

import * as routing from 'services/routing'
import toast from 'utils/toast'

export const APPLICATION_ROOT_FORM = 'APPLICATION_ROOT_FORM'
export const ENVIRONMENT_LIST = 'ENVIRONMENT_LIST'
export const ENVIRONMENT_DATA = 'ENVIRONMENT_DATA'
export const ENVIRONMENT_FORM = 'ENVIRONMENT_FORM'
export const ENVIRONMENT_GROUP_FORM = 'ENVIRONMENT_GROUP_FORM'

class SettingsFormContainer extends PureComponent {
  state = {
    formMode: APPLICATION_ROOT_FORM,
    rotatingKeyPair: false,
  }

  componentDidUpdate(prevProps) {
    const { activeTab } = this.props

    if (
      prevProps.activeTab !== activeTab &&
      this.props.activeTab === 'Application'
    ) {
      this.updateFormMode(APPLICATION_ROOT_FORM)
    }
  }

  isNextDisabled = () => {
    const { isApplicationRoot, testPassed, scanInProgress, scanPassed } = this.props

    return isApplicationRoot
      ? !testPassed || scanInProgress || !scanPassed
      : !testPassed
  }

  saveClickHandler = () => {
    const {
      createApp,
      createNewMode,
      saveUpdates,
      saveBtnAction,
      selectedApplicationRoot,
    } = this.props

    return createNewMode
      ? createApp()
      : saveUpdates(selectedApplicationRoot.appContainerId, saveBtnAction)
  }

  nextClickHandler = () => {
    const { isApplicationRoot, appId } = this.props

    if (isApplicationRoot) {
      routing.appScanHistory({ appId })
    } else {
      routing.appData({ appId })
    }
  }

  scanClickHandler = () => {
    const { environmentsToScan, dispatch, applicationRoot } = this.props
    const { appContainerId } = applicationRoot

    Object.keys(environmentsToScan).forEach(async envId => {
      await dispatch(scanEnvironment(appContainerId, envId))
    })
  }

  toggleModal = () => {
    const isModalOpen = !this.state.isModalOpen
    this.setState({ isModalOpen })
  }

  listAllClickHandler = () => {
    this.updateFormMode(ENVIRONMENT_LIST)
  }

  updateFormMode = formMode => {
    // TODO: this state just needs to be moved
    // into the redux state
    // TODO: need to make this not tricky
    if (this.props.updateFormMode) {
      this.props.updateFormMode(formMode)
    } else {
      this.setState({ formMode })
    }
  }

  selectEnvironment = async (envId, mode) => {
    const { dispatch } = this.props

    await dispatch(selectEnvironmentId(envId))
    await dispatch(selectEnvironmentGroupId(null))
    this.updateFormMode(mode)
  }

  // TODO: can prob replace both of these selects
  // with just a current form entity id
  selectEnvironmentGroup = async (groupId, mode) => {
    const { dispatch } = this.props

    await dispatch(selectEnvironmentGroupId(groupId))
    await dispatch(selectEnvironmentId(null))

    this.updateFormMode(mode)
  }

  initiateKeyPairRotation = async envId => {
    const { applicationRoot } = this.props
    const { appContainerId } = applicationRoot

    try {
      this.setState({ rotatingKeyPair: true })
      const response = await preFetch({
        path: `/apps/${appContainerId}/environments/${envId}/keys`,
        method: 'post',
      })

      if (!response) {
        throw new Error()
      }

      toast({
        title: `Key Pair rotaion completed successfully.`,
        type: 'success',
        time: 'normal',
        id: `rotate-key-pair-success`,
      })
    } catch {
      toast({
        title: `Failed to rotate the Key Pair.`,
        type: 'error',
        time: 'normal',
        id: `rotate-key-pair-failure`,
      })
    } finally {
      this.setState({ rotatingKeyPair: false })
    }
  }

  render() {
    // alot of these props can be moved to this container
    // and not need to be passed all the way down
    const {
      appPropertiesHeaderHeight,
      appPropertiesTabsHeight,
      catalogApp,
      createNewMode,
      dropdownOpen,
      environmentsToScan,
      environmentData,
      environmentScanResults,
      fields,
      isApplicationRoot,
      isScanningAppEnvironments,
      maxHeight,
      propertyTypes,
      subPropertiesMap,
      saveBtnAction,
      saving,
      scanInProgress,
      scanPassed,
      section,
      selectedEntityId,
      selectedApplicationRoot,
      selectedApplicationRootGroupId,
      selectedEnvironmentId,
      testInProgress,
      testPassed,
      updateFormMode,

      // actions
      createApp,
      handleChange,
      onCancel,
      saveUpdates,
      scanApplicationRoot,
      toggle,
      updateSaveBtnAction,
      thisAppManage,
    } = this.props

    const { formMode, rotatingKeyPair } = this.state
    const { supportsEnvironmentScanning } = catalogApp

    return (
      <SettingsForm
        appPropertiesHeaderHeight={appPropertiesHeaderHeight}
        appPropertiesTabsHeight={appPropertiesTabsHeight}
        catalogApp={catalogApp}
        createApp={createApp}
        createNewMode={createNewMode}
        dropdownOpen={dropdownOpen}
        environmentData={environmentData}
        environmentsToScan={environmentsToScan}
        environmentScanResults={environmentScanResults}
        fields={fields}
        formMode={formMode}
        handleChange={handleChange}
        isApplicationRoot={isApplicationRoot}
        isNextDisabled={this.isNextDisabled()}
        isScanningAppEnvironments={isScanningAppEnvironments}
        maxHeight={maxHeight}
        nextClickHandler={this.nextClickHandler}
        onCancel={onCancel}
        onListAllClick={this.listAllClickHandler}
        propertyTypes={propertyTypes}
        subPropertiesMap={subPropertiesMap}
        saveBtnAction={saveBtnAction}
        saveClickHandler={this.saveClickHandler}
        saveUpdates={saveUpdates}
        saving={saving}
        scanApplicationRoot={supportsEnvironmentScanning && scanApplicationRoot}
        scanClickHandler={this.scanClickHandler}
        scanInProgress={scanInProgress}
        scanPassed={scanPassed}
        section={section}
        selectEnvironment={this.selectEnvironment}
        selectEnvironmentGroup={this.selectEnvironmentGroup}
        selectedApplicationRoot={selectedApplicationRoot}
        selectedApplicationRootGroupId={selectedApplicationRootGroupId}
        selectedEnvironmentId={selectedEnvironmentId}
        selectedEntityId={selectedEntityId}
        testInProgress={testInProgress}
        testPassed={testPassed}
        toggle={toggle}
        updateFormMode={updateFormMode || this.updateFormMode}
        updateSaveBtnAction={updateSaveBtnAction}
        thisAppManage={thisAppManage}
        initiateKeyPairRotation={this.initiateKeyPairRotation}
        rotatingKeyPair={rotatingKeyPair}
      />
    )
  }
}

SettingsFormContainer.propTypes = {
  createNewMode: PropTypes.bool,
  createApp: PropTypes.func,
  dropdownOpen: PropTypes.bool,
  fields: PropTypes.object,
  handleChange: PropTypes.func,
  isApplicationRoot: PropTypes.bool,
  onCancel: PropTypes.func,
  propertyTypes: PropTypes.object,
  saveBtnAction: PropTypes.string,
  saveUpdates: PropTypes.func,
  saving: PropTypes.bool,
  scanInProgress: PropTypes.bool,
  section: PropTypes.object,
  selectedApplicationRoot: PropTypes.object,
  subPropertiesMap: PropTypes.object,
  scanApplicationRoot: PropTypes.func,
  scanPassed: PropTypes.bool,
  testInProgress: PropTypes.bool,
  testPassed: PropTypes.bool,
  toggle: PropTypes.func,
  updateFormMode: PropTypes.func,
  updateSaveBtnAction: PropTypes.func,
}

const mapStateToProps = state => {
  const applicationRoot = getSelectedApplicationRoot(state)
  const appId = get(applicationRoot, 'appContainerId')

  return {
    appId,
    environmentsToScan: getEnvironmentsToScan(state),
    environmentData: getSelectedApplicationEnvironments(state),
    environmentScanResults: getSelectedApplicationEnvironmentScanResults(state),
    appPropertiesHeaderHeight:
      state.elementRef.elementInfo[APP_PROPERTIES_HEADER_ID],
    appPropertiesTabsHeight: state.elementRef.elementInfo[APP_PROPERTIES_TABS_ID],
    applicationRoot,
    isScanningAppEnvironments: getIsScanningAppEnvironments({ state, appId }),
    selectedEnvironmentId: getSelectedEnvironmentId(state),
  }
}

export default connect(mapStateToProps)(SettingsFormContainer)
