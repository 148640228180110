import React, { Component } from 'react'
import sortBy from 'sort-by'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'

import TableView from 'components/table/TableV2/TableView'

class Table extends Component {
  state = {
    data: [],
    searchDict: {},
  }

  componentDidMount() {
    this.setState({
      data: this.props.data,
    })

    this.createSearchDict()
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.sortBy !== this.props.sortBy) {
      this.setState({
        data: this.state.data.sort(sortBy(this.props.sortBy)),
      })
    }

    if (prevProps.data !== this.props.data) {
      this.createSearchDict()
    }

    // TODO: have to run search and filter everytime something changes
    // clean this up where you don't have to
    // probably just need to set different state for search and filter data and data
    if (
      prevProps.data !== this.props.data ||
      prevProps.searchTerm !== this.props.searchTerm ||
      prevProps.category !== this.props.category ||
      prevProps.filterBy !== this.props.filterBy ||
      prevProps.filterByKey !== this.props.filterByKey ||
      prevProps.filterByRegistered !== this.props.filterByRegistered ||
      prevProps.filterByRegistrable !== this.props.filterByRegistrable ||
      prevProps.filterByPrivileged !== this.props.filterByPrivileged ||
      prevProps.filterByExternal !== this.props.filterByExternal ||
      prevProps.filterByMappedAttributes !== this.props.filterByMappedAttributes ||
      prevProps.filterByInUse !== this.props.filterByInUse ||
      prevProps.filterByPermissions !== this.props.filterByPermissions
    ) {
      this.searchAndFilter()
    }
  }

  searchAndFilter = debounce(() => {
    this.searchTable()
    this.filterData()
    this.props.highlightText && this.props.highlightText()
  }, 250)

  createSearchDict = () => {
    const { data, searchKeys, identifier, customSearchTags } = this.props
    const searchDict = {}

    if (searchKeys) {
      data.forEach(el => {
        let term = ''

        searchKeys.forEach(key => {
          const customSearchTag = customSearchTags && customSearchTags[key]
          const dataValue = el[key] || ''

          if (customSearchTag) {
            term += ` ${customSearchTag(dataValue).toLowerCase()}`
          } else {
            term += ` ${dataValue.toLowerCase()}`
          }
        })

        searchDict[el[identifier]] = term
      })
    }

    this.setState({ searchDict })
  }

  changeSortBy(sortBy) {
    this.setState({
      sortBy,
    })
  }

  handleChange = event => {
    const { name } = event.target

    this.setState(
      {
        checkboxState: {
          ...this.state.checkboxState,
          [name]: !this.state.checkboxState[name],
        },
      },
      () => {
        const checkBoxState = this.state.checkboxState[name]
        this.state.data.map(el => {
          return el[this.props.keyValue] === name
            ? this.props.updateCB(el, checkBoxState)
            : el
        })
      }
    )
  }

  sortData() {
    return this.props.sortBy !== 'sortBy'
      ? this.state.data.sort(sortBy(this.props.sortBy))
      : this.state.data
  }

  searchTable() {
    const { data, identifier } = this.props
    const { searchDict } = this.state
    const searchTerm = this.props.searchTerm.toLowerCase()

    const searchResults = data.filter(el => {
      const searchDictText = searchDict[el[identifier]]
      return searchDictText && searchDictText.includes(searchTerm)
    })

    this.props.searchTerm !== ''
      ? this.setState({ data: searchResults })
      : this.setState({ data: this.props.data })
  }

  // TODO: make this less long. bad structures
  filterData = () => {
    const { filterBy, filterCategory: category } = this.props
    const { data } = this.state

    let showData

    if (category) {
      showData =
        filterBy !== 'all' && filterBy !== 'filterBy' && category !== 'all'
          ? data.filter(d => d[category].toString() === filterBy)
          : data
    } else {
      showData =
        filterBy !== 'all' && filterBy !== 'filterBy'
          ? data
              .filter(d => {
                return d[this.props.filterByKey].toLowerCase() === filterBy
              })
              .map(filteredData => {
                return filteredData
              })
          : data.map(d => {
              return d
            })
    }

    showData = this.props.filterByRegistered
      ? showData.filter(data => data.status === 'Registered')
      : showData

    showData = this.props.filterByRegistrable
      ? showData.filter(data => data.registrable)
      : showData

    showData = this.props.filterByPrivileged
      ? showData.filter(data => data.privileged)
      : showData

    showData = this.props.filterByExternal
      ? showData.filter(data => data.external)
      : showData

    showData = this.props.filterByMappedAttributes
      ? showData.filter(data => data.attributeName)
      : showData

    showData = this.props.filterByInUse
      ? showData.filter(
          data =>
            data.accounts.length > 0 ||
            data.groups.length > 0 ||
            data.permissions.length > 0
        )
      : showData

    showData = this.props.filterByPermissions
      ? showData.filter(data => data.permissions.length > 0)
      : showData

    this.setState({ data: showData })
    this.props.setDataOverride && this.props.setDataOverride(showData)
  }

  render() {
    const { container } = styles
    const { data } = this.state
    return (
      <div style={container} className="btv-table">
        <TableView {...this.props} data={data} />
      </div>
    )
  }
}

Table.propTypes = {
  sortBy: PropTypes.string,
  filterBy: PropTypes.string,
  searchTerm: PropTypes.string,
  showCheckbox: PropTypes.bool,
  columns: PropTypes.array,
  updateCB: PropTypes.func,
  data: PropTypes.array,
  selectedCB: PropTypes.array,
  keyValue: PropTypes.string,
  keyValue1: PropTypes.any,
  keyValue2: PropTypes.any,
  actions: PropTypes.func,
  filterByKey: PropTypes.string,
  emptyTableMessage: PropTypes.string,
  accounts: PropTypes.func,
  permissions: PropTypes.func,
  filterByRegistered: PropTypes.bool,
  filterCategory: PropTypes.string,
  selectedCategoryData: PropTypes.string,
  filterByInUse: PropTypes.bool,
  filterByPermissions: PropTypes.bool,
  expanded: PropTypes.any,
}

const styles = {
  container: {
    flex: 1,
    boxShadow: `0 2px 12px 2px #cddfed`,
    backgroundColor: '#FFF',
  },
  tableHeader: {
    fontSize: 14,
    fontWeight: 'bold',
    padding: '0 4px',
  },
  inactiveShade: {
    color: '#C6C6C6',
  },
}

export default Table
