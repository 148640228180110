import React, { memo, useEffect, useMemo, useState } from 'react'

import get from 'lodash/get'
import startCase from 'lodash/startCase'
import OrgScanDetails from './OrgScanDetails'
import { appScanHistory } from 'services/routing'
import { getSelectedApplicationRoot } from 'selectors/application_root'
import { connect } from 'react-redux'

function OrgScanDetailsContainer(props) {
  const [data, setData] = useState([])

  useEffect(() => {
    const { app, setBreadcrumbTrail } = props

    const { catalogAppDisplayName: appName } = app

    setBreadcrumbTrail([
      { title: `${appName} Scan Summary`, path: 'scans' },
      { title: `Organization Last Scan Details` },
    ])
  }, [])

  useEffect(() => {
    let orgScanDataSummary = get(props, 'location.state.orgScanDataSummary')
    if (orgScanDataSummary) {
      const data = []
      Object.keys(orgScanDataSummary).forEach(o => {
        delete orgScanDataSummary[o].collected
        Object.keys(orgScanDataSummary[o]).forEach(k => {
          orgScanDataSummary[o][k].forEach(a => {
            data.push({
              name: a,
              type: startCase(o),
              status: startCase(k),
            })
          })
        })
      })
      setData(data)
    } else {
      appScanHistory({ appId: props.match.params.appId })
    }
  }, [props])

  const filterDataSet = useMemo(() => {
    const typeValues = {}
    const statusValues = {}
    data.forEach(({ type, status }) => {
      !typeValues[type] && (typeValues[type] = { label: type, value: type })
      !statusValues[status] &&
        (statusValues[status] = { label: status, value: status })
    })

    return {
      type: Object.values(typeValues),
      status: Object.values(statusValues),
    }
  }, [data])

  return <OrgScanDetails filterDataSet={filterDataSet} data={data} />
}
const mapStateToProps = state => {
  return {
    app: getSelectedApplicationRoot(state),
  }
}

export default connect(mapStateToProps)(memo(OrgScanDetailsContainer))
