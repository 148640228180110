import React from 'react'

const styles = {
  checkbox: {
    cursor: 'pointer',
  },
}

const width = { width: 24 }

export default function TableCheckboxCell({
  isPlaceholder,
  onClick,
  checked = false,
  disabled,
}) {
  return (
    <div style={width}>
      {!isPlaceholder && (
        <input
          disabled={disabled}
          checked={checked}
          type="checkbox"
          onChange={onClick}
          onClick={e => e.stopPropagation()}
          style={styles.checkbox}
        />
      )}
    </div>
  )
}
