import * as actions from 'actions'
import { fetchIfLoggedIn } from '../utils'
import Axios from 'axios'

export const getResourceLabels = (params = {}) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.GET_RESOURCE_LABELS,
      method: 'get',
      path: '/resource-manager/labels',
      params,
    })
  )
}

export const getResourceLabel = resourceLabelId => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.GET_RESOURCE_LABEL,
      method: 'get',
      path: `/resource-manager/labels/${resourceLabelId}`,
    })
  )
}

export const createResourceLabel = resourceLabel => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.CREATE_RESOURCE_LABEL,
      method: 'post',
      path: '/resource-manager/labels',
      postBody: resourceLabel,
    })
  )
}

export const editResourceLabel = resourceLabel => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.CREATE_RESOURCE_LABEL,
      method: 'put',
      path: `/resource-manager/labels/${resourceLabel?.keyId}`,
      postBody: resourceLabel,
    })
  )
}

export const deleteResourceLabel = resourceLabelId => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.DELETE_RESOURCE_LABEL,
      method: 'delete',
      path: `/resource-manager/labels/${resourceLabelId}`,
    })
  )
}

export const removeCachedResourceLabels = () => {
  return {
    type: actions.REMOVE_RESOURCE_LABELS,
  }
}

export const getResourceTypes = (params = {}, oldSearchTerm) => dispatch => {
  const apiParams = {
    ...params,
    page: params.page,
    size: params.size,
  }

  let shouldAppend = params?.page !== 0 && params?.page !== undefined
  if (!params.searchText && oldSearchTerm?.length && params.page !== 0) {
    apiParams.searchText = oldSearchTerm
  } else if (params.searchText && !oldSearchTerm) {
    apiParams.searchText = params.searchText
    shouldAppend = false
  } else if (params.searchText) {
    apiParams.searchText = params.searchText
    shouldAppend = false
  }

  return dispatch(
    fetchIfLoggedIn({
      actionType: shouldAppend
        ? actions.GET_RESOURCE_TYPES_LOAD_MORE
        : actions.GET_RESOURCE_TYPES,
      method: 'get',
      path: '/resource-manager/resource-types',
      meta: { searchText: params.searchText },
      params: apiParams,
    })
  )
}

export const getPermissionsByResourceType = (
  resourceTypeId,
  params = {}
) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.GET_PERMISSIONS_BY_RESOURCE_TYPE,
      method: 'get',
      path: `/resource-manager/resource-types/${resourceTypeId}/permissions`,
      params,
    })
  )
}

export const getPermissionById = permissionId => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.GET_PERMISSION_BY_ID,
      method: 'get',
      path: `/resource-manager/permissions/${permissionId}`,
    })
  )
}

export const clearPermissionById = () => {
  return {
    type: actions.CLEAR_PERMISSION_BY_ID,
  }
}

export const updatePermissionById = (permissionId, postBody) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.UPDATE_PERMISSION_BY_ID,
      method: 'put',
      postBody,
      path: `/resource-manager/permissions/${permissionId}`,
    })
  )
}

export const getPermissionByVersion = (permissionId, version) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.GET_PERMISSION_BY_VERSION,
      method: 'get',
      path: `/resource-manager/permissions/${permissionId}/${version}`,
    })
  )
}

export const getPermissionPreSignedUrl = permissionId => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.GET_PERMISSION_PRE_SIGNED_URL,
      method: 'get',
      path: `/resource-manager/permissions/get-urls/${permissionId}`,
    })
  )
}

export const uploadPermissionFile = async (uploadUrl, fileToUpload) => {
  await Axios.put(uploadUrl, fileToUpload, {
    headers: {
      'content-type': fileToUpload?.type || 'application/octet-stream',
    },
  })
}

export const downloadPermissionFile = async s3fileUrl => {
  const response = await Axios.get(s3fileUrl, { responseType: 'blob' })
  return response
}

export const createResourceType = resourceType => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.CREATE_RESOURCE_TYPE,
      method: 'post',
      path: '/resource-manager/resource-types',
      postBody: resourceType,
    })
  )
}

export const getResourceType = resourceTypeId => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.GET_RESOURCE_TYPE,
      method: 'get',
      path: `/resource-manager/resource-types/${resourceTypeId}`,
    })
  )
}

export const deleteResourceType = resourceTypeId => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.DELETE_RESOURCE_TYPE,
      method: 'delete',
      path: `/resource-manager/resource-types/${resourceTypeId}`,
    })
  )
}

export const updateResourceType = (resourceTypeId, postBody) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.UPDATE_RESOURCE_TYPE,
      method: 'put',
      postBody,
      path: `/resource-manager/resource-types/${resourceTypeId}`,
    })
  )
}

export const createResource = postBody => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.CREATE_RESOURCE,
      method: 'post',
      postBody,
      path: `/resource-manager/resources`,
    })
  )
}

export const updateResource = (resourceId, postBody) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.UPDATE_RESOURCE,
      method: 'put',
      postBody,
      path: `/resource-manager/resources/${resourceId}`,
    })
  )
}

export const deleteResource = resourceId => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.DELETE_RESOURCE,
      method: 'delete',
      path: `/resource-manager/resources/${resourceId}`,
      meta: { resourceId },
    })
  )
}

export const getResources = (params = {}) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.GET_RESOURCES,
      method: 'get',
      path: `/resource-manager/resources`,
      params,
    })
  )
}

export const getResourceById = resourceId => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.GET_RESOURCE_BY_ID,
      method: 'get',
      meta: { resourceId },
      path: `/resource-manager/resources/${resourceId}`,
    })
  )
}

export const getPoolsByResource = resourceId => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.GET_BROKER_POOLS_BY_RESOURCE,
      method: 'get',
      path: `/resource-manager/resources/${resourceId}/broker-pools`,
    })
  )
}

export const addPoolsToResource = (resourceId, postBody) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.ADD_POOLS_TO_RESOURCE,
      method: 'post',
      postBody,
      path: `/resource-manager/resources/${resourceId}/broker-pools`,
    })
  )
}

export const deletePoolFromResource = (resourceId, poolId) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.DELETE_POOL_FROM_RESOURCE,
      method: 'delete',
      path: `/resource-manager/resources/${resourceId}/broker-pools/${poolId}`,
    })
  )
}

export const removedCachedResourceById = () => {
  return {
    type: actions.REMOVE_CACHED_RESOURCE_BY_ID,
  }
}

export const createResourceTypePermission = postBody => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.CREATE_RESOURCE_TYPE_PERMISSION,
      method: 'post',
      postBody,
      path: `/resource-manager/permissions`,
    })
  )
}

export const deleteResourceTypePermission = permissionId => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.DELETE_RESOURCE_TYPE_PERMISSION,
      method: 'delete',
      path: `/resource-manager/permissions/${permissionId}`,
    })
  )
}

export const deleteResourceTypePermissionVersion = (
  permissionId,
  version
) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.DELETE_RESOURCE_TYPE_PERMISSION_VERSION,
      method: 'delete',
      path: `/resource-manager/permissions/${permissionId}/${version}`,
    })
  )
}

export const getResourcesPolicies = (params = {}) => dispatch => {
  const apiParams = {}

  if (params.searchText) {
    apiParams.filter = `name co ${params.searchText}`
  }

  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.GET_RESOURCES_POLICIES,
      method: 'get',
      path: `/resource-manager/policies`,
      meta: { searchText: params.searchText },
      params: apiParams,
    })
  )
}

export const deleteResourcePolicy = policyId => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.DELETE_RESOURCE_POLICIES,
      method: 'delete',
      path: `/resource-manager/policies/${policyId}`,
    })
  )
}
