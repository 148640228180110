import { combineReducers } from 'redux'
import list from './list'
import template from './template'

const responseTemplates = combineReducers({
  list,
  template,
})

export default responseTemplates
