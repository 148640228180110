export const MINIMUM_LENGTH = 'MinimumLength'
export const REQUIRE_UPPERCASE = 'RequireUppercase'
export const REQUIRE_LOWERCASE = 'RequireLowercase'
export const REQUIRE_NUMBERS = 'RequireNumbers'
export const REQUIRE_SYMBOLS = 'RequireSymbols'
export const PASSWORD_CONFIRM_MATCH = 'PasswordConfirmMatch'
export const DIFFERENT_FROM_LAST_PASSWORD = 'DifferentFromLastPassword'

export const UPPER_CASE_TEST = /[A-Z]/
export const LOWER_CASE_TEST = /[a-z]/
export const NUMBER_TEST = /\d/
export const SYMBOL_TEST = /(_|[\^$*.[\]{}()?\-"!@#%&/\\,><':;|_~`])/
