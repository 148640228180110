import React from 'react'
import styled from 'styled-components'

import { BasicSelect } from 'components/Selects'
import CustomOption from './CustomOption'
import FieldWrapper from '../FieldWrapper'

const SelectWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;

  > label {
    margin-right: 8px;
    margin-bottom: 0;
  }

  > .detail {
    margin-left: 8px;
  }
`

const Select = styled(BasicSelect)`
  min-height: unset;
  width: 300px;
`

function UserAttributeMapping(props) {
  const getOptionLabel = value => {
    if (value.name === '') {
      return 'No mapping'
    }

    return value.name
  }

  const { editable, enumValues, value } = props

  return (
    <FieldWrapper>
      <label htmlFor={props.name}>{props.displayName}</label>

      <SelectWrapper>
        <label>User: </label>

        <Select
          options={enumValues}
          components={{
            Option: CustomOption,
          }}
          isDisabled={!editable}
          value={!value || !value.length ? { name: '', description: '' } : value[0]}
          getOptionLabel={getOptionLabel}
          onChange={value => {
            const inputValue = !value.name ? [] : [value]

            props.onChange({
              target: { value: inputValue },
              isNotPropertyType: true,
              valueKey: 'userAccountMappings',
            })
          }}
          placeholder="Select Attribute"
          isSearchable={false}
        />

        <span className="detail">maps to the account name in this application</span>
      </SelectWrapper>
    </FieldWrapper>
  )
}

export default UserAttributeMapping
