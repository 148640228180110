import { combineReducers } from 'redux'
import myRequestReducer from '../../reducers/my-requests'
import {
  myApprovalsList,
  usersList,
  myApprovalsDetails,
  approversList,
} from '../../reducers/my-approvals'

const myApprovalsReducer = combineReducers({
  myApprovalsList,
  usersList,
  myApprovalsDetails,
  myRequestReducer,
  approversList,
})

export default myApprovalsReducer
