import React, { Fragment, memo, useState } from 'react'
import { Modal, ModalFooter, ModalHeader, ModalBody } from 'reactstrap'
import {
  ActionIconLabel,
  ActionIconGroup,
} from 'britive-ui-components/core/components/ActionIcon'
import Button from 'britive-ui-components/core/components/Button'
import Spinner from 'britive-ui-components/core/components/Spinner'
import TableActionIcon from 'components/TableActionIcon'
import Table from 'components/table/TableV2/Table'

const styles = {
  addModal: {
    maxWidth: 1600,
    width: '80%',
  },
  addModalDoneButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
}

function AddTagModal({
  addTag,
  isAddingTagToPap,
  isAddTimePeriodTagOpen,
  isOpen,
  papId,
  toggleAddTagModal,
  toggleAddTimePeriodModal,
}) {
  const [refresh, setRefresh] = useState(0)
  const renderActionIcons = ({ original: tag }) => {
    const tagIsBeingAdded =
      !isAddTimePeriodTagOpen && isAddingTagToPap[tag.userTagId]

    const addTagToProfiles = async () => {
      await addTag({ papId, id: tag.userTagId, tag })
      setRefresh(refresh + 1)
    }
    return (
      <ActionIconGroup>
        <Fragment>
          <TableActionIcon onClick={addTagToProfiles}>
            {tagIsBeingAdded ? (
              <Spinner />
            ) : (
              <Fragment>
                <span className="fa fa-plus-circle fs:14" />
                <ActionIconLabel>Add</ActionIconLabel>
              </Fragment>
            )}
          </TableActionIcon>

          <TableActionIcon
            onClick={() => !tagIsBeingAdded && toggleAddTimePeriodModal(tag)}
          >
            <span className="fa fa-clock fs:14" />
            <ActionIconLabel>Add for specified time</ActionIconLabel>
          </TableActionIcon>
        </Fragment>
      </ActionIconGroup>
    )
  }

  return (
    <Modal isOpen={isOpen} centered size="lg" style={styles.addModal}>
      <ModalHeader toggle={toggleAddTagModal}>Add Tags</ModalHeader>

      <ModalBody>
        <Table
          columns={[
            { Header: 'Tag', accessor: 'name' },
            { Header: 'Description', accessor: 'description' },
            { Header: 'Members', accessor: 'userCount' },
            {
              Header: 'Actions',
              accessor: 'actions',
              sortable: false,
              resizable: false,
              Cell: renderActionIcons,
            },
          ]}
          identifier="userTagId"
          dataUrl={`/paps/${papId}/user-tags`}
          params={{ query: 'available' }}
          emptyTableMessage={'No available tags were found.'}
          refresh={refresh}
          noUrlQueryParams
        />
      </ModalBody>

      <ModalFooter>
        <div style={styles.addModalDoneButton}>
          <Button onClick={toggleAddTagModal} color="primary">
            Done
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default memo(AddTagModal)
