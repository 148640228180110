import { PROFILE_STATUS } from 'constants/myProfile'

export default function statusFormatter(
  statusText,
  hasMultiCheckoutRestriction = false,
  isCheckinAvailable
) {
  if (hasMultiCheckoutRestriction && !isCheckinAvailable) {
    return PROFILE_STATUS.RESTRICTED
  }
  switch (statusText) {
    case 'checkedOut':
      return PROFILE_STATUS.CHECKED_OUT
    case 'checkOutSubmitted':
      return PROFILE_STATUS.CHECK_OUT_SUBMITTED
    case 'requestRejected': //replaced => 'checkOutRejected' to 'requestRejected'
      return PROFILE_STATUS.APPROVAL_REQUIRED
    case 'checkOutFailed':
      return PROFILE_STATUS.CHECK_OUT_FAILED
    case 'checkInSubmitted':
      return PROFILE_STATUS.CHECK_IN_SUBMITTED
    case 'checkInFailed':
      return PROFILE_STATUS.CHECK_IN_FAILED
    case 'Pending':
      return PROFILE_STATUS.APPROVAL_PENDING
    case 'Allow':
      return PROFILE_STATUS.AVAILABLE
    case 'ApprovalRequired':
      return PROFILE_STATUS.APPROVAL_REQUIRED
    case 'requestExpired': //replaced => 'checkOutApprovalExpired' to 'requestExpired'
      return PROFILE_STATUS.APPROVAL_REQUIRED
    case 'requestApproved': //new case added here
      return PROFILE_STATUS.AVAILABLE
    case 'available': //new case added here
      return PROFILE_STATUS.AVAILABLE
    case 'checkOutTimeOut':
      return PROFILE_STATUS.CHECK_OUT_TIMED_OUT
    case 'checkInTimeOut':
      return PROFILE_STATUS.CHECK_IN_TIMED_OUT
    default:
      return PROFILE_STATUS.UNKNOWN
  }
}
