import React, { memo } from 'react'
import { Modal, ModalFooter, ModalHeader, ModalBody } from 'reactstrap'

import Button from 'britive-ui-components/core/components/Button'

const styles = {
  buttonWrapper: {
    textAlign: 'right',
  },
  cancelButton: {
    marginRight: 8,
  },
}

const PAPModal = ({
  cancelAccessExtension,
  extendProfileAccess,
  profile,
  extendingExpiration,
}) => {
  return (
    <Modal isOpen size="lg">
      <ModalHeader>PAP Expiration</ModalHeader>

      <ModalBody>
        <p>
          The profile {profile.papName} in the application{' '}
          {profile.catalogAppDisplayName} for {profile.accessType} access is about to
          expire. Would you like to extend?
        </p>
      </ModalBody>

      <ModalFooter>
        <div style={styles.buttonWrapper}>
          <Button
            disabled={extendingExpiration}
            onClick={cancelAccessExtension}
            style={styles.cancelButton}
          >
            Cancel
          </Button>

          <Button
            spinner={extendingExpiration}
            type="submit"
            color="primary"
            onClick={extendProfileAccess}
          >
            Extend
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default memo(PAPModal)
