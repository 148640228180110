import * as actions from 'actions'
import get from 'lodash/get'
import orderBy from 'lodash/orderBy'
import {
  FETCH_SHARED_PAP_STATUS,
  ADD_TO_EXTENSION_QUEUE,
  CANCEL_EXTENSION,
  SHOW_APPROVAL_MODAL,
  HIDE_PPROVAL_MODAL,
  FETCH_APPROVERS,
  ADD_INTERNAL_TIMER,
  GET_MY_ACCESS_ASSOCIATIONS,
} from 'action_creators/access'

import { createStandaloneToast } from '@chakra-ui/react'

import ACCESS_TYPE from 'constants/accessTypes'
const { CONSOLE, PROGRAMMATIC } = ACCESS_TYPE

const updateProfilesStatus = (state, id, status, approvalValidityTime) => {
  const {
    allProfiles,
    appAccessStatus,
    favoriteProfiles,
    frequentlyUsedProfiles,
  } = state
  const allProfilesObject = { ...allProfiles }
  const appAccessStatusObj = { ...appAccessStatus }
  const favoriteProfilesObject = { ...favoriteProfiles }
  const frequentlyUsedProfilesObject = { ...frequentlyUsedProfiles }
  const now = Date.now()

  Object.keys(allProfilesObject)
    .filter(ele => ele.startsWith(id))
    .forEach(ele => {
      if (appAccessStatusObj[ele]?.status !== 'checkedOut') {
        allProfilesObject[ele].status = status
        if (appAccessStatusObj[ele]?.status) appAccessStatusObj[ele].status = status
      } else if (
        allProfilesObject[ele].approvalValidityTime &&
        new Date(allProfilesObject[ele].approvalValidityTime).valueOf() < now
      ) {
        allProfilesObject[ele].status = status
      }
      if (approvalValidityTime) {
        allProfilesObject[ele].approvalValidityTime = approvalValidityTime
      }
    })
  Object.keys(favoriteProfilesObject)
    .filter(ele => ele.startsWith(id))
    .forEach(ele => {
      if (appAccessStatusObj[ele]?.status !== 'checkedOut') {
        favoriteProfilesObject[ele].status = status
      } else if (
        favoriteProfilesObject[ele].approvalValidityTime &&
        new Date(favoriteProfilesObject[ele].approvalValidityTime).valueOf() < now
      ) {
        favoriteProfilesObject[ele].status = status
      }
      if (approvalValidityTime) {
        favoriteProfilesObject[ele].approvalValidityTime = approvalValidityTime
      }
    })
  Object.keys(frequentlyUsedProfilesObject)
    .filter(ele => ele.startsWith(id))
    .forEach(ele => {
      if (appAccessStatusObj[ele]?.status !== 'checkedOut') {
        frequentlyUsedProfilesObject[ele].status = status
      } else if (
        frequentlyUsedProfilesObject[ele].approvalValidityTime &&
        new Date(frequentlyUsedProfilesObject[ele].approvalValidityTime).valueOf() <
          now
      ) {
        frequentlyUsedProfilesObject[ele].status = status
      }
      if (approvalValidityTime) {
        frequentlyUsedProfilesObject[ele].approvalValidityTime = approvalValidityTime
      }
    })

  return {
    allProfiles: allProfilesObject,
    favoriteProfiles: favoriteProfilesObject,
    frequentlyUsedProfiles: frequentlyUsedProfilesObject,
    appAccessStatus: appAccessStatusObj,
  }
}

export default function reducer(
  state = {
    fetching: {},
    fetched: false,
    error: null,
    tenantAppPaps: {},
    notifications: [],
    papUpdaterAccessStatuses: {},
    fetchingAccessExpiration: false,
    fetchedAccessExpiration: false,

    //user apps
    userApps: {},
    fetchingUserApps: false,
    fetchedUserApps: false,

    appAccessStatus: {},
    fetchingAppAccessStatus: false,

    userEnvironments: {},
    fetchingUserEnvironments: false,

    userProfiles: {},
    fetchingUserProfiles: false,

    allProfiles: {},
    fetchingAllProfiles: true,
    allProfilesCount: null,
    allProfilesCountDense: null,

    favoriteProfiles: {},
    fetchingFavoriteProfiles: false,
    favoriteProfilesCount: null,

    frequentlyUsedProfiles: {},
    fetchingFrequentlyUsedProfiles: false,
    frequentlyUsedProfilesCount: null,
    frequentlyUsedEnvProfilesCount: null,

    checkedOutProfiles: {},
    fetchingCheckedOutProfiles: false,
    checkedOutProfilesCount: null,
    checkedOutEnvProfilesCount: null,

    fetchingSharedPapStatus: true,
    isChangingFavoriteProfiles: new Set(),

    isCheckingOutProfiles: new Set(),
    isCheckingInProfiles: new Set(),
    timeoutToast: {},

    sharedPapStatus: {},
    extensionQueue: [],
    extendingExpiration: false,
    extensionCancelledProfiles: [],

    fetchingApprovers: false,
    approversDetails: null,

    myAccessProfileAssociations: {
      loading: false,
      error: null,
      data: {},
    },
  },
  action
) {
  switch (action.type) {
    /**
     * USER TENANT APP PAPS
     */
    case `${actions.USER_TENANT_APP_PAPS}_PENDING`: {
      const { appId, envId } = action.meta
      const fetching = { ...state.fetching }

      const oldAppFetching = fetching[appId]
      const newAppFetching = oldAppFetching ? { ...oldAppFetching } : {}
      newAppFetching[envId] = true
      fetching[appId] = newAppFetching

      return {
        ...state,
        fetching,
      }
    }

    case `${actions.USER_TENANT_APP_PAPS}_FULFILLED`: {
      const { appId, envId } = action.meta
      const tenantAppPaps = { ...state.tenantAppPaps }
      const oldAppPaps = tenantAppPaps[appId]
      const newAppPaps = oldAppPaps ? { ...oldAppPaps } : {}
      const newEnvApps = {}

      action.payload.data.forEach(pap => {
        const { appContainerId, papId } = pap

        if (appContainerId === appId) {
          newEnvApps[papId] = pap
        }
      })

      newAppPaps[envId] = newEnvApps

      tenantAppPaps[appId] = newAppPaps

      const fetching = { ...state.fetching }

      const oldAppFetching = fetching[appId]
      const newAppFetching = oldAppFetching ? { ...oldAppFetching } : {}
      newAppFetching[envId] = false
      fetching[appId] = newAppFetching

      return {
        ...state,
        fetching,
        fetched: true,
        tenantAppPaps,
      }
    }

    case `${actions.USER_TENANT_APP_PAPS}_REJECTED`: {
      const { appId, envId } = action.meta
      const fetching = { ...state.fetching }

      const oldAppFetching = fetching[appId]
      const newAppFetching = oldAppFetching ? { ...oldAppFetching } : {}
      newAppFetching[envId] = false
      fetching[appId] = newAppFetching

      return {
        ...state,
        fetching,
        fetched: null,
        error: action.payload,
      }
    }

    /**
     * CHECK OUT
     */

    case `${actions.ACCESS_CHECK_OUT_SUBMIT}_PENDING`: {
      const { environmentId, papId, appContainerId, accessType } = action.meta
      const id = `${appContainerId}-${environmentId}-${papId}-${accessType}`
      return {
        ...state,
        isCheckingOutProfiles: new Set(state.isCheckingOutProfiles).add(id),
      }
    }

    case `${actions.ACCESS_CHECK_OUT_SUBMIT}_FULFILLED`: {
      const { environmentId, papId, appContainerId, accessType } = action.meta
      const id = `${appContainerId}-${environmentId}-${papId}-${accessType}`
      const isCheckingOutProfiles = new Set(state.isCheckingOutProfiles)
      isCheckingOutProfiles.delete(id)
      const { appAccessStatus } = state
      const updatedProfile = action.payload.data
      const appAccessStatusObj = { ...appAccessStatus, [id]: updatedProfile }
      const timeoutToast = { ...state.timeoutToast }
      clearTimeout(timeoutToast[id])
      const standAloneToast = createStandaloneToast()
      standAloneToast.close(id)
      delete timeoutToast[id]
      return {
        ...state,
        isCheckingOutProfiles,
        timeoutToast,
        appAccessStatus: appAccessStatusObj,
      }
    }

    case `${actions.ACCESS_CHECK_OUT_SUBMIT}_REJECTED`: {
      const { environmentId, papId, appContainerId, accessType } = action.meta
      const id = `${appContainerId}-${environmentId}-${papId}-${accessType}`
      const isCheckingOutProfiles = new Set(state.isCheckingOutProfiles)
      isCheckingOutProfiles.delete(id)
      const timeoutToast = { ...state.timeoutToast }
      clearTimeout(timeoutToast[id])
      const standAloneToast = createStandaloneToast()
      standAloneToast.close(id)
      delete timeoutToast[id]
      return {
        ...state,
        isCheckingOutProfiles,
        timeoutToast,
      }
    }

    /**
     *  Approval Submit
     */

    case `${actions.APPROVAL_REQUEST_SUBMIT}_PENDING`: {
      const { environmentId, papId, appContainerId, accessType } = action.meta
      const id = `${appContainerId}-${environmentId}-${papId}-${accessType}`
      return {
        ...state,
        isCheckingOutProfiles: new Set(state.isCheckingOutProfiles).add(id),
      }
    }

    case `${actions.APPROVAL_REQUEST_SUBMIT}_FULFILLED`: {
      const { environmentId, papId, appContainerId, accessType } = action.meta
      const id = `${appContainerId}-${environmentId}-${papId}-${accessType}`
      const isCheckingOutProfiles = new Set(state.isCheckingOutProfiles)
      isCheckingOutProfiles.delete(id)
      const timeoutToast = { ...state.timeoutToast }
      clearTimeout(timeoutToast[id])
      const standAloneToast = createStandaloneToast()
      standAloneToast.close(id)
      delete timeoutToast[id]

      const { data } = action.payload
      const statusId = `${appContainerId}-${environmentId}-${papId}`
      const profileState = updateProfilesStatus(state, statusId, data.status)
      return {
        ...state,
        isCheckingOutProfiles,
        timeoutToast,
        ...profileState,
      }
    }

    case `${actions.APPROVAL_REQUEST_SUBMIT}_REJECTED`: {
      const { environmentId, papId, appContainerId, accessType } = action.meta
      const id = `${appContainerId}-${environmentId}-${papId}-${accessType}`
      const isCheckingOutProfiles = new Set(state.isCheckingOutProfiles)
      isCheckingOutProfiles.delete(id)
      const timeoutToast = { ...state.timeoutToast }
      clearTimeout(timeoutToast[id])
      const standAloneToast = createStandaloneToast()
      standAloneToast.close(id)
      delete timeoutToast[id]
      return {
        ...state,
        isCheckingOutProfiles,
        timeoutToast,
      }
    }
    case `${actions.ACCESS_CHECK_IN_SUBMIT}_PENDING`: {
      const { transactionId } = action.meta
      return {
        ...state,
        isCheckingInProfiles: new Set(state.isCheckingInProfiles).add(transactionId),
      }
    }

    case `${actions.ACCESS_CHECK_IN_SUBMIT}_FULFILLED`: {
      const {
        environmentId,
        papId,
        appContainerId,
        accessType,
        transactionId,
      } = action.meta
      const id = `${appContainerId}-${environmentId}-${papId}-${accessType}`
      const { appAccessStatus, checkedOutProfiles } = state
      const updatedProfile = action.payload.data
      const appAccessStatusObj = { ...appAccessStatus, [id]: updatedProfile }
      const checkedOutProfilesObj = { ...checkedOutProfiles }
      delete checkedOutProfilesObj[id]

      const checkedOutEnvProfilesSet = Object.values(checkedOutProfilesObj).reduce(
        (_set, profile) => {
          const { environmentId, papId } = profile
          _set.add(`${environmentId}-${papId}`)
          return _set
        },
        new Set()
      )

      const isCheckingInProfiles = new Set(state.isCheckingInProfiles)
      isCheckingInProfiles.delete(transactionId)
      return {
        ...state,
        isCheckingInProfiles,
        appAccessStatus: appAccessStatusObj,
        checkedOutProfiles: checkedOutProfilesObj,
        checkedOutProfilesCount: Object.keys(checkedOutProfilesObj).length,
        checkedOutEnvProfilesCount: checkedOutEnvProfilesSet.size,
      }
    }

    case `${actions.ACCESS_CHECK_IN_SUBMIT}_REJECTED`: {
      const { transactionId } = action.meta
      const isCheckingInProfiles = new Set(state.isCheckingInProfiles)
      isCheckingInProfiles.delete(transactionId)
      return {
        ...state,
        isCheckingInProfiles,
      }
    }

    case `${actions.GET_APP_ACCESS_STATUS}_PENDING`: {
      return {
        ...state,
        fetchingAppAccessStatus: true,
      }
    }

    case `${actions.GET_APP_ACCESS_STATUS}_FULFILLED`: {
      const appAccessStatusList = get(action, 'payload.data', [])
      const { checkedOutProfiles } = state
      const appAccessStatusObj = {}
      const checkedOutProfilesObj = { ...checkedOutProfiles }
      const checkedOutEnvProfilesSet = new Set()
      appAccessStatusList.forEach(profile => {
        const { appContainerId, environmentId, papId, accessType, status } = profile
        const id = `${appContainerId}-${environmentId}-${papId}-${accessType}`
        appAccessStatusObj[id] = profile
        // Note: showing those profiles in Checked-Out tap whose status is checkedOut OR checkInFailed
        if (status === 'checkedOut' || status === 'checkInFailed') {
          checkedOutProfilesObj[id] = profile
          checkedOutEnvProfilesSet.add(`${environmentId}-${papId}`)
        }
      })
      return {
        ...state,
        checkedOutProfiles: checkedOutProfilesObj,
        checkedOutProfilesCount: Object.keys(checkedOutProfilesObj).length,
        checkedOutEnvProfilesCount: checkedOutEnvProfilesSet.size,
        fetchingAppAccessStatus: false,
        appAccessStatus: appAccessStatusObj,
      }
    }

    case `${actions.GET_APP_ACCESS_STATUS}_REJECTED`: {
      return {
        ...state,
        fetchingAppAccessStatus: false,
        error: action.payload,
      }
    }

    case `${FETCH_SHARED_PAP_STATUS}_PENDING`: {
      return {
        ...state,
        fetchingSharedPapStatus: true,
      }
    }

    case `${FETCH_SHARED_PAP_STATUS}_FULFILLED`: {
      const sharedPapStatusList = get(action, 'payload.data', [])
      const sharedPapStatus = {}
      sharedPapStatusList.forEach(status => {
        const { appContainerId, environmentId } = status
        sharedPapStatus[`${appContainerId}-${environmentId}`] = status
      })
      return {
        ...state,
        sharedPapStatus,
        fetchingSharedPapStatus: false,
      }
    }

    case `${FETCH_SHARED_PAP_STATUS}_REJECTED`: {
      return {
        ...state,
        fetchingSharedPapStatus: false,
        error: action.payload,
      }
    }

    case `${actions.GET_USER_APPS}_PENDING`: {
      return {
        ...state,
        error: null,
        fetchingUserApps: true,
        fetchedUserApps: false,
      }
    }

    case `${actions.GET_USER_APPS}_FULFILLED`: {
      const userAppList = action.payload.data
      const userApps = {}
      userAppList.forEach(userApp => {
        userApps[userApp.appContainerId] = userApp
      })
      return {
        ...state,
        userApps,
        fetchingUserApps: false,
        fetchedUserApps: true,
      }
    }

    case `${actions.GET_USER_APPS}_REJECTED`: {
      return {
        ...state,
        error: action.payload,
        fetchingUserApps: false,
        fetchedUserApps: false,
      }
    }

    case `${actions.GET_USER_ENVIRONMENTS}_PENDING`: {
      return {
        ...state,
        error: null,
        fetchingUserEnvironments: true,
      }
    }

    case `${actions.GET_USER_ENVIRONMENTS}_FULFILLED`: {
      const userEnvironmentList = get(action, 'payload.data', [])
      const userEnvironments = {}
      userEnvironmentList.forEach(userEnvironment => {
        userEnvironments[userEnvironment.environmentId] = userEnvironment
      })
      return {
        ...state,
        userEnvironments,
        fetchingUserEnvironments: false,
      }
    }

    case `${actions.GET_USER_ENVIRONMENTS}_REJECTED`: {
      return {
        ...state,
        error: action.payload,
        fetchingUserEnvironments: false,
      }
    }

    case `${actions.GET_USER_PROFILES}_PENDING`: {
      return {
        ...state,
        error: null,
        fetchingUserProfiles: true,
      }
    }

    case `${actions.GET_USER_PROFILES}_FULFILLED`: {
      const userProfileList = get(action, 'payload.data', [])
      const userProfiles = {}
      userProfileList.forEach(
        userProfile => (userProfiles[userProfile.papId] = userProfile)
      )
      return {
        ...state,
        userProfiles,
        fetchingUserProfiles: false,
      }
    }

    case `${actions.GET_USER_PROFILES}_REJECTED`: {
      return {
        ...state,
        error: action.payload,
        fetchingUserProfiles: false,
      }
    }

    case `${actions.GET_ALL_PROFILES}_PENDING`: {
      return {
        ...state,
        error: null,
        fetchingAllProfiles: true,
      }
    }

    case `${actions.GET_ALL_PROFILES}_FULFILLED`: {
      const allProfileList = get(action, 'payload.data', [])
      const allProfiles = {}
      const distinctProfiles = new Set()
      const distinctUserApps = new Set()
      const distinctUserEnvironments = new Set()
      const distinctUserProfiles = new Set()
      const distinctEnvProfiles = new Set()
      allProfileList.forEach(allProfile => {
        const { appContainerId, environmentId, papId, accessType } = allProfile
        const id = `${appContainerId}-${environmentId}-${papId}-${accessType}`
        //deleting unwanted property frequency
        delete allProfile.frequency
        allProfiles[id] = { ...allProfile, id }
        distinctProfiles.add(`${appContainerId}-${environmentId}-${papId}`)
        distinctUserApps.add(appContainerId)
        distinctUserEnvironments.add(environmentId)
        distinctUserProfiles.add(papId)
        distinctProfiles.add(`${appContainerId}-${environmentId}-${papId}`)
        distinctEnvProfiles.add(`${environmentId}-${papId}`)
      })

      const { userApps, userEnvironments, userProfiles } = { ...state }
      Object.keys(userApps).forEach(userAppId => {
        if (!distinctUserApps.has(userAppId)) {
          delete userApps[userAppId]
        }
      })
      Object.keys(userEnvironments).forEach(userEnvironmentId => {
        if (!distinctUserEnvironments.has(userEnvironmentId)) {
          delete userEnvironments[userEnvironmentId]
        }
      })
      Object.keys(userProfiles).forEach(userProfileId => {
        if (!distinctUserProfiles.has(userProfileId)) {
          delete userProfiles[userProfileId]
        }
      })
      return {
        ...state,
        allProfiles,
        userApps,
        userEnvironments,
        userProfiles,
        fetchingAllProfiles: false,
        allProfilesCount: distinctProfiles.size,
        allEnvProfilesCount: distinctEnvProfiles.size,
      }
    }

    case `${actions.GET_ALL_PROFILES}_REJECTED`: {
      return {
        ...state,
        error: action.payload,
        fetchingAllProfiles: false,
      }
    }

    case `${actions.GET_FREQUENTLY_USED_PROFILES}_PENDING`: {
      return {
        ...state,
        error: null,
        fetchingFrequentlyUsedProfiles: !action.meta.postCheckout,
      }
    }

    case `${actions.GET_FREQUENTLY_USED_PROFILES}_FULFILLED`: {
      const frequentlyUsedProfileList = get(action, 'payload.data', [])
      const frequentlyUsedProfiles = {}
      const frequentlyUsedEnvProfileList = new Set()

      orderBy(frequentlyUsedProfileList, [
        'papId',
        'environmentId',
        'appContainerId',
      ]).forEach(frequentlyUsedProfile => {
        const {
          appContainerId,
          environmentId,
          papId,
          accessType,
        } = frequentlyUsedProfile
        const id = `${appContainerId}-${environmentId}-${papId}-${accessType}`
        frequentlyUsedProfiles[id] = { ...frequentlyUsedProfile, id }
        frequentlyUsedEnvProfileList.add(`${environmentId}-${papId}`)
      })
      return {
        ...state,
        frequentlyUsedProfiles,
        fetchingFrequentlyUsedProfiles: false,
        frequentlyUsedProfilesCount: frequentlyUsedProfileList.length,
        frequentlyUsedEnvProfilesCount: frequentlyUsedEnvProfileList.size,
      }
    }

    case `${actions.GET_FREQUENTLY_USED_PROFILES}_REJECTED`: {
      return {
        ...state,
        error: action.payload,
        fetchingFrequentlyUsedProfiles: false,
      }
    }

    case `${actions.GET_FAVORITE_PROFILES}_PENDING`: {
      return {
        ...state,
        error: null,
        fetchingFavoriteProfiles: true,
      }
    }

    case `${actions.GET_FAVORITE_PROFILES}_FULFILLED`: {
      const favoriteProfileList = get(action, 'payload.data', [])
      const favoriteProfiles = {}
      const favoriteEnvProfilesSet = new Set()
      favoriteProfileList.forEach(favoriteProfile => {
        const { appContainerId, environmentId, papId, accessType } = favoriteProfile
        const id = `${appContainerId}-${environmentId}-${papId}-${accessType}`
        favoriteProfiles[id] = { ...favoriteProfile, id }
        favoriteEnvProfilesSet.add(`${environmentId}-${papId}`)
      })

      return {
        ...state,
        favoriteProfiles,
        fetchingFavoriteProfiles: false,
        favoriteProfilesCount: favoriteProfileList.length,
      }
    }

    case `${actions.GET_FAVORITE_PROFILES}_REJECTED`: {
      return {
        ...state,
        error: action.payload,
        fetchingFavoriteProfiles: false,
      }
    }

    case `${actions.MARK_FAVORITE}_PENDING`:
    case `${actions.DELETE_FAVORITE}_PENDING`: {
      const { environmentId, papId, appContainerId, accessType } = action.meta
      return {
        ...state,
        isChangingFavoriteProfiles: new Set(state.isChangingFavoriteProfiles).add(
          `${appContainerId}-${environmentId}-${papId}-${accessType}`
        ),
        //fetchingFavoriteProfiles: true,
      }
    }

    case `${actions.MARK_FAVORITE}_FULFILLED`: {
      const { appContainerId, environmentId, papId, accessType } = action.meta
      const { favoriteId, userId } = action.payload.data
      const id = `${appContainerId}-${environmentId}-${papId}-${accessType}`
      const favoriteProfiles = { ...state.favoriteProfiles }
      const isChangingFavoriteProfiles = new Set(state.isChangingFavoriteProfiles)
      isChangingFavoriteProfiles.delete(id)
      favoriteProfiles[id] = {
        ...state.allProfiles[id],
        favoriteId,
        userId,
      }
      return {
        ...state,
        favoriteProfilesCount: Object.keys(favoriteProfiles).length,
        isChangingFavoriteProfiles,
        favoriteProfiles,
        fetchingFavoriteProfiles: false,
      }
    }

    case `${actions.DELETE_FAVORITE}_FULFILLED`: {
      const { appContainerId, environmentId, papId, accessType } = action.meta
      const id = `${appContainerId}-${environmentId}-${papId}-${accessType}`
      const favoriteProfiles = { ...state.favoriteProfiles }
      const isChangingFavoriteProfiles = new Set(state.isChangingFavoriteProfiles)
      delete favoriteProfiles[id]
      isChangingFavoriteProfiles.delete(id)
      return {
        ...state,
        favoriteProfilesCount: Object.keys(favoriteProfiles).length,
        isChangingFavoriteProfiles,
        favoriteProfiles,
        fetchingFavoriteProfiles: false,
      }
    }

    case `${actions.MARK_FAVORITE}_REJECTED`:
    case `${actions.DELETE_FAVORITE}_REJECTED`: {
      const { environmentId, papId, appContainerId, accessType } = action.meta
      const isChangingFavoriteProfiles = new Set(state.isChangingFavoriteProfiles)
      isChangingFavoriteProfiles.delete(
        `${appContainerId}-${environmentId}-${papId}-${accessType}`
      )
      return {
        ...state,
        isChangingFavoriteProfiles,
        fetchingFavoriteProfiles: false,
      }
    }

    case `${actions.EXTEND_EXPIRATION}_PENDING`: {
      return {
        ...state,
        extendingExpiration: true,
      }
    }

    case `${actions.EXTEND_EXPIRATION}_FULFILLED`: {
      const { extensionQueue } = state
      const newExtensionQueue = [...extensionQueue]
      newExtensionQueue.shift()
      return {
        ...state,
        extensionQueue: newExtensionQueue,
        extendingExpiration: false,
      }
    }

    case `${actions.EXTEND_EXPIRATION}_REJECTED`: {
      return {
        ...state,
        extendingExpiration: false,
      }
    }

    case `${FETCH_APPROVERS}_PENDING`: {
      return {
        ...state,
        fetchingApprovers: true,
      }
    }

    case `${FETCH_APPROVERS}_FULFILLED`: {
      return {
        ...state,
        approversDetails: action.payload.data,
        fetchingApprovers: false,
      }
    }

    case `${FETCH_APPROVERS}_REJECTED`: {
      return {
        ...state,
        fetchingApprovers: false,
      }
    }

    // Request Expired notification
    case `${actions.CHECK_OUT_APPROVAL_EXPIRED}`: {
      const { appContainerId, environmentId, papId, status } = action.payload
      const id = `${appContainerId}-${environmentId}-${papId}`
      const profileState = updateProfilesStatus(state, id, status)

      return {
        ...state,
        ...profileState,
      }
    }

    case `${actions.CHECKED_OUT_NOTIFICATION}`: {
      const { appContainerId, environmentId, papId, accessType } = action.payload
      const {
        frequentlyUsedProfiles,
        checkedOutProfiles,
        frequentlyUsedProfilesCount,
        appAccessStatus,
        allProfiles,
      } = state
      const id = `${appContainerId}-${environmentId}-${papId}-${accessType}`
      const updatedProfile = action.payload

      const frequentlyUsedProfilesObj = { ...frequentlyUsedProfiles }
      if (frequentlyUsedProfilesCount < 10 && !frequentlyUsedProfiles[id]) {
        frequentlyUsedProfilesObj[id] = {
          ...allProfiles[id],
          checkedOut: updatedProfile.checkedOut,
        }
      }

      const checkedOutProfilesObj = { ...checkedOutProfiles, [id]: updatedProfile }

      const appAccessStatusObj = { ...appAccessStatus, [id]: updatedProfile }

      const checkedOutEnvProfilesSet = Object.values(checkedOutProfilesObj).reduce(
        (_set, profile) => {
          const { environmentId, papId } = profile
          _set.add(`${environmentId}-${papId}`)
          return _set
        },
        new Set()
      )

      return {
        ...state,
        frequentlyUsedProfiles: frequentlyUsedProfilesObj,
        frequentlyUsedProfilesCount: Object.keys(frequentlyUsedProfilesObj).length,
        checkedOutProfiles: checkedOutProfilesObj,
        appAccessStatus: appAccessStatusObj,
        checkedOutProfilesCount: Object.keys(checkedOutProfilesObj).length,
        checkedOutEnvProfilesCount: checkedOutEnvProfilesSet.size,
      }
    }

    // Request Rejected notification
    case `${actions.CHECK_OUT_REJECTED_NOTIFICATION}`: {
      const { appContainerId, environmentId, papId, status } = action.payload
      const id = `${appContainerId}-${environmentId}-${papId}`
      const profileState = updateProfilesStatus(state, id, status)

      return {
        ...state,
        ...profileState,
      }
    }

    // New Case added here for Request Approved notification
    case `${actions.REQUEST_APPROVED_NOTIFICATION}`: {
      const {
        appContainerId,
        environmentId,
        papId,
        status,
        approvalValidityTime,
      } = action.payload
      const id = `${appContainerId}-${environmentId}-${papId}`
      const profileState = updateProfilesStatus(
        state,
        id,
        status,
        approvalValidityTime
      )

      return {
        ...state,
        ...profileState,
      }
    }

    // New Case added here for Request Cancelled notification
    case `${actions.REQUEST_CANCELLED_NOTIFICATION}_FULFILLED`: {
      const { environmentId, papId, appContainerId } = action.meta
      const id = `${appContainerId}-${environmentId}-${papId}`
      const profileState = updateProfilesStatus(state, id, 'ApprovalRequired')

      return {
        ...state,
        ...profileState,
      }
    }

    case `${actions.CHECK_OUT_FAILED_NOTIFICATION}`: {
      const { appContainerId, environmentId, papId, accessType } = action.payload
      const { appAccessStatus } = state
      const id = `${appContainerId}-${environmentId}-${papId}-${accessType}`
      const appAccessStatusObj = { ...appAccessStatus }
      delete appAccessStatusObj[id]

      return {
        ...state,
        appAccessStatus: appAccessStatusObj,
      }
    }

    case `${actions.CHECKED_IN_NOTIFICATION}`: {
      const { appContainerId, environmentId, papId, accessType } = action.payload
      const {
        appAccessStatus,
        favoriteProfiles,
        allProfiles,
        checkedOutProfiles,
        frequentlyUsedProfiles,
      } = state

      const id = `${appContainerId}-${environmentId}-${papId}-${accessType}`
      const updatedProfile = action.payload

      const favoriteProfilesObj = {
        ...favoriteProfiles,
      }
      if (favoriteProfilesObj[id]) {
        favoriteProfilesObj[id] = {
          ...favoriteProfilesObj[id],
          checkedOut: updatedProfile.checkedOut,
        }
      }

      const allProfilesObj = {
        ...allProfiles,
        [id]: {
          ...allProfiles[id],
          checkedOut: updatedProfile.checkedOut,
        },
      }

      const frequentlyUsedProfilesObj = {
        ...frequentlyUsedProfiles,
      }
      if (frequentlyUsedProfilesObj[id]) {
        frequentlyUsedProfilesObj[id] = {
          ...frequentlyUsedProfilesObj[id],
          checkedOut: updatedProfile.checkedOut,
        }
      }

      const checkedOutProfilesObj = { ...checkedOutProfiles }
      delete checkedOutProfilesObj[id]

      const checkedOutEnvProfilesSet = Object.values(checkedOutProfilesObj).reduce(
        (_set, profile) => {
          const { environmentId, papId } = profile
          _set.add(`${environmentId}-${papId}`)
          return _set
        },
        new Set()
      )

      const appAccessStatusObj = { ...appAccessStatus }
      delete appAccessStatusObj[id]

      return {
        ...state,
        appAccessStatus: appAccessStatusObj,
        allProfiles: allProfilesObj,
        favoriteProfiles: favoriteProfilesObj,
        frequentlyUsedProfiles: frequentlyUsedProfilesObj,
        checkedOutProfiles: checkedOutProfilesObj,
        checkedOutProfilesCount: Object.keys(checkedOutProfilesObj).length,
        checkedOutEnvProfilesCount: checkedOutEnvProfilesSet.size,
      }
    }

    case `${actions.CHECK_IN_FAILED_NOTIFICATION}`: {
      const { appContainerId, environmentId, papId, accessType } = action.payload
      const { appAccessStatus, checkedOutProfiles } = state
      const id = `${appContainerId}-${environmentId}-${papId}-${accessType}`
      const updatedProfile = action.payload
      const appAccessStatusObj = { ...appAccessStatus, [id]: updatedProfile }
      // Note: showing those profiles in Checked-Out tap whose status is checkedOut OR checkInFailed
      const checkedOutProfilesObj = { ...checkedOutProfiles, [id]: updatedProfile }

      return {
        ...state,
        appAccessStatus: appAccessStatusObj,
        checkedOutProfiles: checkedOutProfilesObj,
        checkedOutProfilesCount: Object.keys(checkedOutProfilesObj).length,
      }
    }
    case `${actions.ACCESS_REQUEST_SUBMIT}_FULFILLED`: {
      const { environmentId, papId, appContainerId, accessType } = action.meta
      const { status } = action.payload?.data
      const isCheckingOutProfiles = new Set(state.isCheckingOutProfiles)
      const timeoutToast = { ...state.timeoutToast }
      ;(accessType === null ? [null, CONSOLE, PROGRAMMATIC] : [accessType]).forEach(
        type => {
          const id = `${appContainerId}-${environmentId}-${papId}-${type}`
          isCheckingOutProfiles.delete(id)
          clearTimeout(timeoutToast[id])
          const standAloneToast = createStandaloneToast()
          standAloneToast.close(id)
          delete timeoutToast[id]
        }
      )
      const statusId = `${appContainerId}-${environmentId}-${papId}`

      const profileState = updateProfilesStatus(state, statusId, status)
      return {
        ...state,
        isCheckingOutProfiles,
        timeoutToast,
        ...profileState,
      }
    }

    case `${SHOW_APPROVAL_MODAL}`: {
      const { appContainerId, environmentId, papId, accessType } = action.payload
      const id = `${appContainerId}-${environmentId}-${papId}-${accessType}`

      return {
        ...state,
        isCheckingOutProfiles: new Set(state.isCheckingOutProfiles).add(id),
        approvalModalPayload: action.payload,
      }
    }

    case `${HIDE_PPROVAL_MODAL}`: {
      const { appContainerId, environmentId, papId, accessType } = action.payload
      const id = `${appContainerId}-${environmentId}-${papId}-${accessType}`
      const isCheckingOutProfiles = new Set(state.isCheckingOutProfiles)
      isCheckingOutProfiles.delete(id)

      return {
        ...state,
        isCheckingOutProfiles,
        approvalModalPayload: null,
      }
    }

    case `${ADD_TO_EXTENSION_QUEUE}`: {
      const { extensionQueue, extensionCancelledProfiles } = state
      const profileToBeExtended = action.payload
      if (
        !(
          extensionQueue.some(
            profile => profile.transactionId === profileToBeExtended.transactionId
          ) || extensionCancelledProfiles.includes(profileToBeExtended.transactionId)
        )
      ) {
        return {
          ...state,
          extensionQueue: [...state.extensionQueue, action.payload],
        }
      } else {
        return {
          ...state,
        }
      }
    }

    case `${CANCEL_EXTENSION}`: {
      const { extensionQueue, extensionCancelledProfiles } = state
      const newExtensionQueue = [...extensionQueue]
      newExtensionQueue.shift()
      const updatedExtensionCancelledProfiles = [
        ...extensionCancelledProfiles,
        action.payload,
      ]

      return {
        ...state,
        extensionQueue: newExtensionQueue,
        extensionCancelledProfiles: updatedExtensionCancelledProfiles,
      }
    }

    // New Case added here for Internal timer for Approval validity
    case `${ADD_INTERNAL_TIMER}`: {
      const { environmentId, papId, appContainerId, status } = action.payload
      const id = `${appContainerId}-${environmentId}-${papId}`
      const profileState = updateProfilesStatus(state, id, status)

      return {
        ...state,
        ...profileState,
      }
    }

    case `${GET_MY_ACCESS_ASSOCIATIONS}_PENDING`: {
      return {
        ...state,
        myAccessProfileAssociations: {
          loading: true,
          error: null,
          data: {},
        },
      }
    }

    case `${GET_MY_ACCESS_ASSOCIATIONS}_FULFILLED`: {
      return {
        ...state,
        myAccessProfileAssociations: {
          loading: false,
          error: null,
          data: {
            profileId: action.meta,
            ...action.payload.data,
          },
        },
      }
    }

    case `${GET_MY_ACCESS_ASSOCIATIONS}_REJECTED`: {
      return {
        ...state,
        myAccessProfileAssociations: {
          loading: false,
          error: action.payload,
          data: {},
        },
      }
    }

    default:
      return { ...state }
  }
}
