import get from 'lodash/get'

import * as actions from 'actions'
import { FORGOT_PASSWORD, LOGOUT, MFA_SETUP } from 'constants/cognitoAuthentication'

import { fetchIfLoggedIn, fetchWithoutAuth } from '../utils.js'
import { doFetch } from 'utils/do_fetch'
import { history } from 'store'

export const FETCH_PASSWORD_POLICY = 'FETCH_PASSWORD_POLICY'
export const RESET_PASSWORD_ACTION = 'RESET_PASSWORD'
export const SEND_ONE_TIME_PASSWORD_EMAIL = 'SEND_ONE_TIME_PASSWORD_EMAIL'
export const LOGOUT_USER = 'LOGOUT_USER'
export const UPDATE_AUTHENTICATION_INFO = 'UPDATE_AUTHENTICATION_INFO'
export const UPDATE_CHALLENGE_PARAMETERS = 'UPDATE_CHALLENGE_PARAMETERS'
export const SET_IS_INITIAL_AUTHING = 'SET_IS_INITIAL_AUTHING'
export const GET_IDENTITY_PROVIDERS_LIST = 'GET_IDENTITY_PROVIDERS_LIST'
export const GET_IDENTITY_PROVIDER_TYPE = 'GET_IDENTITY_PROVIDER_TYPE'

export function updateAuthenticationInfo(response) {
  const challenge = get(
    response,
    'data.authenticationResult.challengeParameters.challenge'
  )
  if (challenge !== MFA_SETUP) {
    delete response.data.authenticationResult.accessToken
    delete response.data.authenticationResult.refreshToken
  }

  return {
    type: UPDATE_AUTHENTICATION_INFO,
    payload: response,
  }
}

export function notAuthed() {
  return {
    type: UPDATE_AUTHENTICATION_INFO,
    payload: {},
  }
}

export function authError(message) {
  return {
    type: actions.AUTHENTICATE_REJECTED,
    payload: message,
  }
}

export const authenticateUser = ({
  authParameters,
  method = 'POST',
}) => async dispatch => {
  const postBody = { authParameters }

  const response = await doFetch({
    path: '/auth',
    method,
    postBody,
    allow401: true,
  })

  if (response) {
    await dispatch(updateAuthenticationInfo(response))
  } else {
    dispatch(notAuthenticated())
  }

  return response
}

export const updateChallengeParameters = challengeParameters => ({
  type: UPDATE_CHALLENGE_PARAMETERS,
  payload: challengeParameters,
})

export const logout = type => async dispatch => {
  const postBody = {
    authParameters: {
      challenge: LOGOUT,
    },
  }

  try {
    return await dispatch(
      fetchIfLoggedIn({
        actionType: LOGOUT_USER,
        method: 'DELETE',
        path: `/auth${type ? `?type=${type}` : ''}`,
        postBody,
        allow401: true,
      })
    )
  } catch (e) {
    console.log(e)
  }
}

export function getCurrentTenant(tenant) {
  return {
    type: actions.GET_TENANT_FROM_URL,
    tenant,
  }
}

export const fetchPasswordPolicy = () => dispatch =>
  dispatch(
    fetchWithoutAuth({
      actionType: FETCH_PASSWORD_POLICY,
      method: 'get',
      path: `/auth/password-policy`,
    })
  )

export const sendOneTimePasswordEmail = email => async dispatch => {
  const postBody = {
    authParameters: {
      username: email,
      challenge: FORGOT_PASSWORD,
    },
  }

  return await dispatch(
    fetchWithoutAuth({
      actionType: SEND_ONE_TIME_PASSWORD_EMAIL,
      method: 'post',
      path: `/auth`,
      postBody,
    })
  )
}

export const updatePassword = authParameters => async dispatch => {
  const postBody = {
    authParameters,
  }

  return await dispatch(
    fetchWithoutAuth({
      actionType: RESET_PASSWORD_ACTION,
      method: 'post',
      path: `/auth`,
      postBody,
      allow401: true,
    })
  )
}

export const fetchIdentityProvidersList = () => dispatch => {
  return dispatch(
    fetchWithoutAuth({
      actionType: GET_IDENTITY_PROVIDERS_LIST,
      method: 'get',
      path: '/auth/idp-list',
    })
  )
}

export const getIdentityProviderType = () => dispatch => {
  return dispatch(
    fetchWithoutAuth({
      actionType: GET_IDENTITY_PROVIDER_TYPE,
      method: 'get',
      path: '/auth/idp-type',
    })
  )
}

export const setIsInitialAuthing = bool => ({
  type: SET_IS_INITIAL_AUTHING,
  payload: bool,
})

export function notAuthenticated() {
  return {
    type: actions.NOT_AUTHENTICATED,
  }
}

export function accessDenied() {
  const accessDeniedPath = '/access-denied'
  history.location.pathname !== accessDeniedPath && history.push(accessDeniedPath)
  return {
    type: actions.ACCESS_DENIED,
  }
}
