import doFetch from 'utils/do_fetch'
import { active, PAGE_TOKEN } from './constants'

export const getQueryParamsFromUrl = (apiUrl, param) => {
  const url = new URL(apiUrl)
  const urlParams = new URLSearchParams(url.search)
  return urlParams.get(param)
}

export const fetchMyApprovalsList = async (payload = {}) => {
  const params = {}
  if (payload.search) {
    params.filter = `status%20eq%20${payload.search}`
  }
  if (payload.next) {
    params.pageToken = getQueryParamsFromUrl(payload.next, PAGE_TOKEN)
  }

  let queryParams = 'requestType=myApprovals'

  const listData = await doFetch({
    path: `/v1/approvals/?${queryParams}`,
    method: 'get',
    params,
  })

  return listData
}

export const approveRejectRequest = async payload => {
  const response = await doFetch({
    path: `/v1/approvals/${payload.approvalId}?approveRequest=${payload.value}`,
    method: 'patch',
    postBody: { approverComment: payload.commandText },
  })
  return response
}

export const fetchUsers = async () => {
  const response = await doFetch({
    path: '/users?type=User',
    method: 'get',
  })

  return (response?.data || []).filter(
    user => user?.status?.toLowerCase() === active
  )
}
