import isEmpty from 'lodash/isEmpty'

export default (members = []) => {
  const users = []
  const tags = []

  if (isEmpty(members)) {
    return {
      users,
      tags,
    }
  }

  members.forEach(member => {
    const data = {
      id: member.id,
      name: member.name,
    }

    const isUser = member.memberType?.toLocaleLowerCase() === 'user'
    isUser ? users.push(data) : tags.push(data)
  })

  return {
    users,
    tags,
  }
}
