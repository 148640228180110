import get from 'lodash/get'
import * as actions from 'actions'

const initialState = {
  loading: false,
  error: null,
  data: [],
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case `${actions.GET_BROKER_POOL_LABELS}_PENDING`: {
      return {
        ...state,
        loading: true,
        error: null,
      }
    }

    case `${actions.GET_BROKER_POOL_LABELS}_FULFILLED`: {
      const data = get(action, 'payload.data')

      return {
        ...state,
        loading: false,
        error: null,
        data,
      }
    }

    case `${actions.GET_BROKER_POOL_LABELS}_REJECTED`: {
      return {
        ...state,
        loading: false,
        error: 'Unable to fetch the Pool Labels.',
      }
    }

    default:
      return { ...state }
  }
}
