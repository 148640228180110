import { MyApprovalsDetailsDataTypes } from '../../routes/my-requests-my-approvals/my-approvals/view/constants'
import {
  MyApprovalsDataTypes,
  UsersType,
} from '../../routes/my-requests-my-approvals/my-approvals/list/constants'

const initialState = {
  loading: false,
  error: null,
  result: [],
  pagination: {
    next: '',
    prev: '',
  },
  searchTerm: '',
}

export const myApprovalsList = (state = initialState, action) => {
  switch (action.type) {
    case MyApprovalsDataTypes.MY_APPROVALS_LIST_LOADING:
      return {
        ...state,
        loading: true,
      }

    case MyApprovalsDataTypes.MY_APPROVALS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        result: [...action.payload?.result],
        pagination: {
          ...action.payload?.pagination,
        },
        error: null,
      }

    case MyApprovalsDataTypes.MY_APPROVALS_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case MyApprovalsDataTypes.MY_APPROVALS_LIST_SEARCH_TERM_UPDATE:
      return {
        ...state,
        searchTerm: action.payload,
      }
    case MyApprovalsDataTypes.MY_APPROVALS_LIST_LOAD_MORE_LOADING:
      return {
        ...state,
        loading: true,
      }
    case MyApprovalsDataTypes.MY_APPROVALS_LIST_LOAD_MORE_SUCCESS:
      return {
        ...state,
        error: null,
        result: [...state.result, ...action.payload?.result],
        pagination: {
          ...action.payload?.pagination,
        },
        loading: false,
      }
    case MyApprovalsDataTypes.MY_APPROVALS_LIST_LOAD_MORE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

const usersInitialState = {
  loading: false,
  error: null,
  data: {},
}

export const usersList = (state = usersInitialState, action) => {
  switch (action.type) {
    case UsersType.APPROVALS_USERS_LOADING:
      return {
        ...state,
        loading: true,
        data: {},
      }

    case UsersType.APPROVALS_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: { result: action.payload },
      }

    case UsersType.APPROVALS_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        data: {},
      }
    default:
      return state
  }
}

const detailsInitialState = {
  loading: false,
  error: null,
  data: {},
}

export const myApprovalsDetails = (state = detailsInitialState, action) => {
  switch (action.type) {
    case MyApprovalsDetailsDataTypes.MY_APPROVALS_DETAILS_LOADING:
      return {
        ...state,
        loading: true,
      }

    case MyApprovalsDetailsDataTypes.MY_APPROVALS_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: { ...action.payload },
        error: null,
      }

    case MyApprovalsDetailsDataTypes.MY_APPROVALS_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }
    default:
      return state
  }
}

const approverListInitialState = {
  loading: false,
  error: null,
  data: [],
}

export const approversList = (state = approverListInitialState, action) => {
  switch (action.type) {
    case MyApprovalsDetailsDataTypes.MY_APPROVALS_APPROVERS_LIST_LOADING:
      return {
        ...state,
        loading: true,
      }

    case MyApprovalsDetailsDataTypes.MY_APPROVALS_APPROVERS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      }

    case MyApprovalsDetailsDataTypes.MY_APPROVALS_APPROVERS_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        error: 'Unable to fetch the users.',
      }
    default:
      return state
  }
}
