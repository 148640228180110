import React from 'react'
import { Route, Switch } from 'react-router-dom'

import ManagedPermissionsList from './index'
import ManagedPermissionsDetails from './Details'

function ManagedPermissions({ match, setBreadcrumbTrail, app }) {
  return (
    <Switch>
      <Route
        exact
        path={`${match.url}/managed-permissions/:id/details`}
        render={() => (
          <ManagedPermissionsDetails
            setBreadcrumbTrail={setBreadcrumbTrail}
            app={app}
          />
        )}
      />

      <Route
        exact
        path={`${match.url}/managed-permissions`}
        render={() => (
          <ManagedPermissionsList
            setBreadcrumbTrail={setBreadcrumbTrail}
            app={app}
          />
        )}
      />
    </Switch>
  )
}

export default ManagedPermissions
