import React, { memo } from 'react'
import styled from 'styled-components'

import Spinner from 'britive-ui-components/core/components/Spinner'
import { Tabs } from 'britive-ui-components/core/components/Tabs'
import Drawer from 'components/Drawer'
import EnvironmentGroupsWrapper from '../components/EnvironmentGroupsWrapper'
import TagUsers from './TagUsers'
import PapProfile from './../components/pap-profile'
import PapAssociations from './PapAssociations'
import PapPermissions from './PapPermissions'
import PapUsers from './PapUsers'
import PapTags from './PapTags'
import PapPolicy from './PapPolicy'

import useMaxTableHeight from 'hooks/useMaxTableHeight'

import { getHeaderSpacing } from 'utils/elementMeasurement'
import PapSessionAttributes from './PapSessionAttributes'
import isOpenshift from 'utils/application/isOpenshift'
import { isAWSIDC } from '../components/pap-profile/utils'

const Wrapper = styled.div`
  padding: 8px;
`

const TabsWrapper = styled.div`
  .tab-content {
    padding: 0 !important;
  }
`

function AdminDetails({
  activeTab,
  appHeaderHeight,
  drawerData,
  drawerRefCallback,
  fetchPap,
  isDrawerOpen,
  onCancel,
  selectTab,
  selectedApp: app,
  selectedEnvironmentData,
  toggleDrawer,
  setBreadcrumbTrail,
  profileId,
  thisAppManage,
  profileV1,
  requestAccess,
  onSelectPolicy,
  selectedPolicy,
  requestedPolicy,
  requestApprovals,
}) {
  const maxHeight = useMaxTableHeight({
    heights: [appHeaderHeight, 56],
    minHeight: 100,
  })

  const renderDrawerContent = () => {
    switch (drawerData.type) {
      case 'TagUsers':
        return <TagUsers drawerData={drawerData} />
      case 'EnvironmentList':
        return (
          <EnvironmentGroupsWrapper
            environmentData={selectedEnvironmentData}
            integrityChecks={drawerData.integrityChecks || []}
            scope={drawerData.scope}
            id={drawerData.drawerId}
            title={drawerData.title}
            showIntegrityCheckStatus={drawerData.showIntegrityCheckStatus}
          />
        )
      default:
        return null
    }
  }

  if (!app) {
    return <Spinner size="2x" />
  }

  return (
    <Wrapper>
      <TabsWrapper>
        <Tabs activeTab={activeTab} onSelectTab={tab => selectTab(tab)}>
          {[
            <div key="profile" label="Profile">
              <PapProfile
                profileId={profileId}
                app={app}
                maxHeight={maxHeight}
                createMode={false}
                onCancel={onCancel}
                setBreadcrumbTrail={setBreadcrumbTrail}
                thisAppManage={thisAppManage}
                requestAccess={requestAccess}
              />
            </div>,
            <div key="permissions" label="Permissions">
              <PapPermissions
                profileId={profileId}
                app={app}
                maxHeight={maxHeight}
                environments={selectedEnvironmentData}
                toggleDrawer={toggleDrawer}
                thisAppManage={thisAppManage}
                requestAccess={requestAccess}
                requestApprovals={requestApprovals}
              />
            </div>,
            ...(!isAWSIDC(app?.catalogAppName)
              ? [
                  <div key="associations" label="Associations">
                    <PapAssociations
                      maxHeight={maxHeight}
                      thisAppManage={thisAppManage}
                      requestAccess={requestAccess}
                    />
                  </div>,
                ]
              : []),
            ...(profileV1
              ? [
                  <div key="users" label="Identities">
                    <PapUsers
                      app={app}
                      profileId={profileId}
                      toggleDrawer={toggleDrawer}
                      fetchPap={fetchPap}
                      maxHeight={maxHeight}
                      thisAppManage={thisAppManage}
                    />
                  </div>,
                  <div key="tags" label="Tags">
                    <PapTags
                      app={app}
                      profileId={profileId}
                      toggleDrawer={toggleDrawer}
                      maxHeight={maxHeight}
                      thisAppManage={thisAppManage}
                    />
                  </div>,
                ]
              : [
                  <div key="policies" label="Policies">
                    <PapPolicy
                      app={app}
                      profileId={profileId}
                      toggleDrawer={toggleDrawer}
                      maxHeight={maxHeight}
                      thisAppManage={thisAppManage}
                      requestAccess={requestAccess}
                      onSelectPolicy={onSelectPolicy}
                      selectedPolicy={selectedPolicy}
                      requestedPolicy={requestedPolicy}
                    />
                  </div>,
                ]),
            ...(app.catalogApplication.supportsSessionAttributes &&
            !requestAccess &&
            !isOpenshift(app?.catalogAppName)
              ? [
                  <div key="session-attributes" label="Session Attributes">
                    <PapSessionAttributes
                      app={app}
                      profileId={profileId}
                      maxHeight={maxHeight}
                      thisAppManage={thisAppManage}
                    />
                  </div>,
                ]
              : []),
          ]}
        </Tabs>
      </TabsWrapper>

      <Drawer
        innerRef={drawerRefCallback}
        isOpen={isDrawerOpen}
        top={`${getHeaderSpacing()}px`}
        toggleDrawer={toggleDrawer}
        id={drawerData.id}
      >
        {renderDrawerContent()}
      </Drawer>
    </Wrapper>
  )
}

export default memo(AdminDetails)
