import get from 'lodash/get'

import {
  CREATE_APPLICATION_ROOT,
  DELETE_APPLICATION_ROOT,
  FETCH_APPLICATION_ROOT,
  FETCH_ALL_APPLICATION_ROOTS,
  SELECT_APPLICATION_ROOT_ID,
  SELECT_APPLICATION_TEMPLATE,
  SCAN_APPLICATION_ROOT,
  TEST_APPLICATION_ROOT,
  UPDATE_APPLICATION_ROOT,
  UPDATE_APPLICATION_ROOT_STATUS,
} from 'action_creators/application_root'

export const applicationRootReducerKey = 'applicationRoot'

const initialState = {
  applicationRoots: {},
  selectedApplicationTemplate: null,
  creatingApplicationRoot: false,
  error: null,
  fetchingAllApplicationRoots: false,
  fetchingApplicationRoot: false,
  scanningApplicationRoot: false,
  selectedApplicationRootId: null,
  testingApplicationRoot: false,
  updatingApplicationRoot: false,
  updatingApplicationRootStatus: false,
  deletingApplicationRoot: false,
}

// TODO: I do not like this redux promise pattern
// So many action types.
// also may want to separate data reducers from ui reducers
// not sure about this yet
// try something else out (thunk, sagas, etc.)
// Putting a bunch of state in this reducer because
// of time constraints
// TODO: will need to separate out into multiple reducers

export function applicationRootReducer(state = initialState, action) {
  switch (action.type) {
    case `${CREATE_APPLICATION_ROOT}_PENDING`: {
      return {
        ...state,
        creatingApplicationRoot: true,
        error: null,
      }
    }

    case `${CREATE_APPLICATION_ROOT}_FULFILLED`: {
      const applicationRoots = {
        ...state.applicationRoots,
        [action.payload.data.appContainerId]: action.payload.data,
      }

      return {
        ...state,
        creatingApplicationRoot: false,
        applicationRoots,
      }
    }

    case `${CREATE_APPLICATION_ROOT}_REJECTED`: {
      return {
        ...state,
        creatingApplicationRoot: false,
        error: action.payload,
      }
    }

    case `${UPDATE_APPLICATION_ROOT}_PENDING`: {
      return {
        ...state,
        updatingApplicationRoot: true,
        error: null,
      }
    }

    case `${UPDATE_APPLICATION_ROOT}_FULFILLED`: {
      const applicationRoots = {
        ...state.applicationRoots,
        [action.payload.data.appContainerId]: action.payload.data,
      }

      return {
        ...state,
        updatingApplicationRoot: false,
        applicationRoots,
      }
    }

    case `${UPDATE_APPLICATION_ROOT}_REJECTED`: {
      return {
        ...state,
        updatingApplicationRoot: false,
        error: action.payload,
      }
    }

    case `${FETCH_APPLICATION_ROOT}_PENDING`: {
      return {
        ...state,
        fetchingApplicationRoot: true,
        error: null,
      }
    }

    case `${FETCH_APPLICATION_ROOT}_FULFILLED`: {
      const response = action.payload

      const applicationRoots = {
        ...state.applicationRoots,
        [response.data.appContainerId]: response.data,
      }

      return {
        ...state,
        fetchingApplicationRoot: false,
        applicationRoots,
      }
    }

    case `${FETCH_APPLICATION_ROOT}_REJECTED`: {
      return {
        ...state,
        fetchingApplicationRoot: false,
        error: action.payload,
      }
    }

    case `${FETCH_ALL_APPLICATION_ROOTS}_PENDING`: {
      return {
        ...state,
        fetchingAllApplicationRoots: true,
        error: null,
      }
    }

    case `${FETCH_ALL_APPLICATION_ROOTS}_FULFILLED`: {
      const applicationRoots = {
        ...state.applicationRoots,
      }

      // TODO: populate environment groups here
      // possibly don't need to fetch env groups later on
      get(action, 'payload.data', []).forEach(root => {
        applicationRoots[root.appContainerId] = root
      })

      return {
        ...state,
        fetchingAllApplicationRoots: false,
        applicationRoots,
      }
    }

    case `${FETCH_ALL_APPLICATION_ROOTS}_REJECTED`: {
      return {
        ...state,
        fetchingAllApplicationRoots: false,
        error: action.payload,
      }
    }

    case `${TEST_APPLICATION_ROOT}_PENDING`: {
      return {
        ...state,
        testingApplicationRoot: true,
        error: null,
      }
    }

    case `${TEST_APPLICATION_ROOT}_FULFILLED`: {
      return {
        ...state,
        testingApplicationRoot: false,
      }
    }

    case `${TEST_APPLICATION_ROOT}_REJECTED`: {
      return {
        ...state,
        testingApplicationRoot: false,
        error: action.payload,
      }
    }

    case `${SCAN_APPLICATION_ROOT}_PENDING`: {
      return {
        ...state,
        scanningApplicationRoot: true,
        error: null,
      }
    }

    case `${SCAN_APPLICATION_ROOT}_FULFILLED`:
      return {
        ...state,
        scanningApplicationRoot: false,
      }

    case `${SCAN_APPLICATION_ROOT}_REJECTED`:
      return {
        ...state,
        scanningApplicationRoot: false,
        error: action.payload,
      }

    // TODO: Move to admin ui reducer
    case SELECT_APPLICATION_ROOT_ID:
      return {
        ...state,
        selectedApplicationRootId: action.payload,
      }
    //
    // TODO: Move to admin ui reducer
    case SELECT_APPLICATION_TEMPLATE:
      return {
        ...state,
        selectedApplicationTemplate: action.payload,
      }

    case `${UPDATE_APPLICATION_ROOT_STATUS}_PENDING`: {
      return {
        ...state,
        updatingApplicationRootStatus: true,
        error: null,
      }
    }

    case `${UPDATE_APPLICATION_ROOT_STATUS}_FULFILLED`: {
      const applicationRoots = {
        ...state.applicationRoots,
        [action.payload.data.appContainerId]: action.payload.data,
      }

      return {
        ...state,
        updatingApplicationRootStatus: false,
        applicationRoots,
      }
    }

    case `${UPDATE_APPLICATION_ROOT_STATUS}_REJECTED`: {
      return {
        ...state,
        updatingApplicationRootStatus: false,
        error: action.payload,
      }
    }

    case `${DELETE_APPLICATION_ROOT}_PENDING`: {
      return {
        ...state,
        deletingApplicationRoot: true,
        error: null,
      }
    }

    case `${DELETE_APPLICATION_ROOT}_FULFILLED`: {
      const { appId } = action.meta

      const applicationRoots = { ...state.applicationRoots }

      delete applicationRoots[appId]

      return {
        ...state,
        deletingApplicationRoot: false,
        applicationRoots,
      }
    }

    case `${DELETE_APPLICATION_ROOT}_REJECTED`: {
      return {
        ...state,
        deletingApplicationRoot: false,
        error: action.payload,
      }
    }

    default:
      return { ...state }
  }
}
