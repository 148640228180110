import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Spinner from 'britive-design-system/core/components/spinner'

import logout from 'utils/logout'

export default connect()(
  withRouter(({ dispatch, history }) => {
    useEffect(() => {
      logout(dispatch, history)
    }, [])
    return <Spinner message="Logging out" size="medium" overlay />
  })
)
