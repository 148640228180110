import { FETCH_NOTIFICATION_MEDIUMS } from 'action_creators/notification-mediums'

export default function reducer(
  state = {
    notificationMediums: {
      loading: false,
      data: [],
      error: null,
    },
    validateChannels: {
      loading: false,
      data: {},
      error: null,
    },
  },
  action
) {
  switch (action.type) {
    case `${FETCH_NOTIFICATION_MEDIUMS}_PENDING`: {
      return {
        ...state,
        notificationMediums: {
          ...state.notificationMediums,
          error: null,
          loading: true,
        },
      }
    }

    case `${FETCH_NOTIFICATION_MEDIUMS}_FULFILLED`: {
      return {
        ...state,
        notificationMediums: {
          ...state.notificationMediums,
          loading: false,
          data: action.payload.data.result,
        },
      }
    }

    case `${FETCH_NOTIFICATION_MEDIUMS}_REJECTED`: {
      return {
        ...state,
        notificationMediums: {
          ...state.notificationMediums,
          loading: false,
          data: {},
          error:
            action.payload?.response?.data?.message ||
            'Notification mediums could not be fetched.',
        },
      }
    }

    default:
      return { ...state }
  }
}
