import React, { useState } from 'react'
import DataTable from 'secret-manager-ui/core/components/data-table/DataTable'
import { BiMinusCircle } from 'react-icons/bi'
import isEmpty from 'lodash/isEmpty'
import Tooltip from 'britive-design-system/core/components/tooltip'
import highRisk from 'static/images/high-risk.svg'
import ModalPopup from 'britive-design-system/core/components/modal-popup'
import { PermissionDefinition } from '../../Profiles/components/permission-drawer/PermissionDefinition'
import infoIcon from 'static/images/infoIcon.svg'
import arrowLeft from 'static/images/arrow-left.svg'
import editIcon from 'static/images/edit-icon.svg'

const SelectedPoliciesList = ({
  selectedPolicies,
  removePolicy,
  editInlinePolicy,
}) => {
  const styles = {
    tableContainer: {
      width: '40%',
      maxHeight: '280px',
      overflow: 'auto',
    },
  }

  const [isPolicyDetailModalOpen, setIsPolicyDetailsModalOpen] = useState({
    isOpen: false,
    permissionDefinition: '',
    name: '',
    isPrivileged: false,
    type: '',
    description: '',
  })

  const closeModal = () => {
    // resetting everything to initial state
    setIsPolicyDetailsModalOpen({
      isOpen: false,
      permissionDefinition: '',
      name: '',
      isPrivileged: false,
      type: '',
      description: '',
    })
  }

  const modalButtons = [
    {
      text: <img src={arrowLeft} style={{ height: '18px' }} />,
      variant: 'secondary',
      onClick: closeModal,
      size: 'medium',
    },
    {
      text: 'Close',
      variant: 'secondary',
      onClick: closeModal,
      size: 'medium',
    },
  ]

  const renderHighRiskIcon = () => (
    <div>
      <Tooltip title="High Risk" position="left">
        <span title="">
          <img src={highRisk} style={{ height: '18px' }} />
        </span>
      </Tooltip>
    </div>
  )
  const columns = [
    {
      headerName: 'Policies',
      field: 'name',
      sortable: true,
      width: 100,
      renderColumn: row => {
        return (
          <div style={{ display: 'flex', alignItems: 'center', padding: '15px 0' }}>
            {row?.name}
            &nbsp;
            {row?.privileged && renderHighRiskIcon()}
          </div>
        )
      },
    },
    {
      headerName: 'Type',
      field: 'type',
      sortable: true,
      width: 70,
      renderColumn: row =>
        row?.type?.toLowerCase()?.includes('inline') ? 'Inline Policy' : 'Policy',
    },
    {
      headerName: 'Action',
      width: 50,
      renderColumn: row => {
        return (
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <div style={{ cursor: 'pointer' }}>
              <BiMinusCircle
                size="24"
                data-testid={`remove-${row.name}`}
                onClick={() => removePolicy(row.name)}
              />
            </div>

            <div style={{ cursor: 'pointer' }}>
              <Tooltip title="View Details" position="left">
                <span
                  onClick={() =>
                    setIsPolicyDetailsModalOpen({
                      isOpen: true,
                      permissionDefinition: row?.permissionDefinition,
                      name: row?.name,
                      isPrivileged: row?.isPrivileged,
                      type: row?.type,
                      description: row?.description,
                    })
                  }
                  data-testid={`info-${row?.name}`}
                >
                  <img src={infoIcon} style={{ height: '23px' }} />
                </span>
              </Tooltip>
            </div>

            {row?.type?.toLowerCase()?.includes('inline') && (
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => editInlinePolicy(row)}
              >
                <img src={editIcon} style={{ height: '23px' }} />
              </div>
            )}
          </div>
        )
      },
    },
  ]
  return (
    <>
      {!isEmpty(selectedPolicies) && (
        <div style={styles.tableContainer}>
          <DataTable rows={selectedPolicies} columns={columns} inLineSort={true} />
        </div>
      )}

      {isPolicyDetailModalOpen.isOpen && (
        <ModalPopup
          width={790}
          title={`Policy: ${isPolicyDetailModalOpen.name}`}
          onCancel={closeModal}
          buttons={modalButtons}
        >
          <PermissionDefinition permission={isPolicyDetailModalOpen} />
        </ModalPopup>
      )}
    </>
  )
}

export default SelectedPoliciesList
