import * as actions from 'actions'

const initialState = {
  loading: false,
  error: null,
  success: false,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case `${actions.CREATE_BROKER_POOLS}_PENDING`: {
      return {
        loading: true,
        error: null,
        success: false,
      }
    }

    case `${actions.CREATE_BROKER_POOLS}_FULFILLED`: {
      return {
        loading: false,
        error: null,
        success: true,
      }
    }

    case `${actions.CREATE_BROKER_POOLS}_REJECTED`: {
      return {
        loading: false,
        error: 'Failed to create a new broker pool.',
        success: false,
      }
    }

    default:
      return { ...state }
  }
}
