import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import DataTable from '../../components/data-table/DataTable'
import BackToTop from 'britive-design-system/core/components/backToTop'
import DialogPopup from 'britive-design-system/core/components/dialog'
import {
  classes,
  myRequestsTableTestId,
  backToTopId,
  adminRequestsListString,
} from '../constants'
import './RequestsList.scss'
import { isEmpty } from 'lodash'
import InfoIcon from 'static/images/infoIcon.svg'
import CancelIcon from 'static/images/cancelIcon.svg'
import CancelIconDisabled from 'static/images/cancelIconDisabled.svg'
import MyResourcesIcon from 'static/images/my-resources.svg'
import { RequestModal } from '../request-modal/RequestModal'
import Tooltip from 'britive-design-system/core/components/tooltip'
import {
  convertTimeToCurrentTimezone,
  getConsumerType,
  sortApprovalsAndRequestByDate,
} from '../../utils'
import { secretModuleConstants } from 'routes/my-requests-my-approvals/my-approvals/list/constants'

const RequestsList = ({
  getMyRequestsListData,
  myRequestsListState,
  updateRequestsListSearchTerm,
  getMyRequestsListDataLoadMore,
  translatedStrings,
  getApprovalStatus,
  getActionLabel,
  tableColumns,
  requestsListString,
  withdrawRequest,
  match,
}) => {
  const history = useHistory()
  const [myRequestsList, setMyRequestsList] = useState([])
  const [openRequest, setOpenRequest] = useState(null)
  const [openWithdrawDialog, setOpenWithdrawDialog] = useState(false)
  const [toBeWithdrawnRequestId, setToBeWithdrawnRequestId] = useState(null)

  const { loading, result, pagination } = myRequestsListState
  const tableContainer = useRef(null)

  useEffect(() => {
    getMyRequestsListData()
    updateRequestsListSearchTerm('')
  }, [])

  useEffect(() => {
    if (myRequestsListState?.searchTerm) {
      getMyRequestsListData()
    }
  }, [myRequestsListState?.searchTerm])

  useEffect(() => {
    if (!isEmpty(result)) {
      const requests =
        result &&
        result.map(request => ({
          ...request,
          requestId: request?.requestId || translatedStrings.noneValue,
          createdAt:
            convertTimeToCurrentTimezone(request?.createdAt) ||
            translatedStrings.noneValue,
          userId: request?.userId || translatedStrings.noneValue,
          action: getActionLabel(request?.action) || translatedStrings.noneValue,
          resource:
            request?.resourceName ||
            request?.resourceName ||
            translatedStrings.noneValue,
          status: getApprovalStatus(request?.status) || translatedStrings.noneValue,
          justification: request?.justification || translatedStrings.noneValue,
          type: request?.consumer || translatedStrings.noneValue,
        }))
      setMyRequestsList(sortApprovalsAndRequestByDate(requests))
    }
  }, [result])

  const columns = [
    {
      field: 'trackingId',
      headerName: tableColumns.RequestId,
      width: 80,
      sortable: true,
    },
    {
      field: 'createdAt',
      headerName: tableColumns.requestedOn,
      width: 120,
      sortable: true,
      isDate: true,
    },
    {
      field: 'type',
      headerName: tableColumns.type,
      sortable: true,
      width: 100,
      renderColumn: row => {
        const { type } = row
        return <span>{getConsumerType(type, row.context?.type)}</span>
      },
    },
    {
      field: 'resource',
      headerName: tableColumns.resourceName,
      width: 160,
      sortable: true,
      renderColumn: row => {
        const { context, resourceName, consumer } = row
        const iconUrl = context?.iconUrl
        return (
          <div className={classes.iconNameWrapper}>
            {iconUrl ? (
              <img className={classes.applicationIcon} src={iconUrl} />
            ) : consumer === secretModuleConstants.secretManager ? (
              <i className="fas fa-key" />
            ) : consumer === secretModuleConstants.resourceprofile ? (
              <img className={classes.applicationIcon} src={MyResourcesIcon} />
            ) : (
              ''
            )}
            <span>{resourceName}</span>
          </div>
        )
      },
    },
    {
      field: 'justification',
      headerName: tableColumns.justification,
      width: 240,
    },
    {
      field: 'status',
      headerName: tableColumns.approvalStatus,
      width: 100,
      sortable: true,
    },
    {
      width: 80,
      renderColumn: row => {
        const { status, requestId } = row
        return (
          <div className={classes.actionItems}>
            <Tooltip title={translatedStrings.viewRequestTooltip} position="left">
              <div>
                <img
                  className={classes.infoIcon}
                  src={InfoIcon}
                  alt="info"
                  onClick={() => {
                    history.push(`${match.url}/view/${row.requestId}`)
                  }}
                />
              </div>
            </Tooltip>
            <Tooltip
              title={
                status === translatedStrings.approvalPendingText
                  ? translatedStrings.withdrawRequest
                  : status
              }
              position="left"
            >
              <div>
                <img
                  className={
                    status === translatedStrings.approvalPendingText
                      ? classes.cancelIcon
                      : classes.cancelIconDisabled
                  }
                  src={
                    status === translatedStrings.approvalPendingText
                      ? CancelIcon
                      : CancelIconDisabled
                  }
                  alt="cancel"
                  onClick={() => {
                    if (status === translatedStrings.approvalPendingText) {
                      setToBeWithdrawnRequestId(requestId)
                      setOpenWithdrawDialog(true)
                    }
                  }}
                />
              </div>
            </Tooltip>
          </div>
        )
      },
      headerName: tableColumns.action,
    },
  ]

  const loadMoreHandler = () => {
    getMyRequestsListDataLoadMore()
  }

  const findHeight = () =>
    requestsListString === adminRequestsListString
      ? 'calc(100vh - 200px)'
      : 'calc(100vh - 173px)'
  return (
    <>
      <div
        className={classes.listContainer}
        style={{ height: findHeight() }}
        ref={tableContainer}
      >
        <div data-testid={myRequestsTableTestId} className={classes.tableContainer}>
          <DataTable
            inLineSort={true}
            rows={myRequestsList}
            columns={columns}
            loading={loading}
            loadingMessage={translatedStrings.loadingMessage}
            loadMoreHandler={loadMoreHandler}
            loadMore={!loading && Boolean(pagination?.next)}
          />
        </div>
      </div>
      <BackToTop id={backToTopId} parentRef={tableContainer} />
      {openRequest && Object.keys(openRequest).length && (
        <RequestModal
          consumer={openRequest?.type}
          requestDetails={openRequest}
          onModalClose={() => setOpenRequest(null)}
          onSubmit={() => {}}
          setOpenWithdrawDialog={setOpenWithdrawDialog}
          setToBeWithdrawnRequestId={setToBeWithdrawnRequestId}
          translatedStrings={translatedStrings}
        />
      )}
      {openWithdrawDialog && (
        <DialogPopup
          type="alert"
          title={translatedStrings.withdrawRequest}
          message={translatedStrings.withdrawRequestMessage}
          primaryButtonText={translatedStrings.withdrawPrimaryBtn}
          secondaryButtonText={translatedStrings.noBtn}
          onSubmit={() => {
            withdrawRequest(toBeWithdrawnRequestId)
            setOpenWithdrawDialog(false)
            openRequest && setOpenRequest(null)
          }}
          onCancel={() => {
            setOpenWithdrawDialog(false)
            setToBeWithdrawnRequestId(null)
          }}
        />
      )}
    </>
  )
}

export default RequestsList
