import { fetchIfLoggedIn } from './utils.js'

export const FETCH_PAPS_FOR_ENTITY = 'FETCH_PAPS_FOR_ENTITY'
export const FETCH_PAPS_FOR_APP = 'FETCH_PAPS_FOR_APP'
export const CREATE_PAP = 'CREATE_PAP'
export const UPDATE_PAP = 'UPDATE_PAP'
export const UPDATE_PERMISSION = 'UPDATE_PERMISSION'
export const FETCH_PAP = 'FETCH_PAP'
export const FETCH_PAP_ADDITIONAL_SETTINGS = 'FETCH_PAP_ADDITIONAL_SETTINGS'
export const UPDATE_PAP_ADDITIONAL_SETTINGS = 'UPDATE_PAP_ADDITIONAL_SETTINGS'
export const DELETE_PAP = 'DELETE_PAP'
export const FETCH_PAP_PERMISSIONS = 'FETCH_PAP_PERMISSIONS'
export const FETCH_PAP_USERS = 'FETCH_PAP_USERS'
export const FETCH_SCOPES_FOR_PAP = 'FETCH_SCOPES_FOR_PAP'
export const UPDATE_PAP_SCOPE = 'UPDATE_PAP_SCOPE'
export const UPDATE_PAP_RESOURCES_SCOPE = 'UPDATE_PAP_RESOURCES_SCOPE'
export const UPDATE_PAP_STATUS = 'UPDATE_PAP_STATUS'
export const FETCH_ASSIGNED_TAGS_FOR_PAP = 'FETCH_ASSIGNED_TAGS_FOR_PAP'
export const FETCH_AVAILABLE_TAGS_FOR_PAP = 'FETCH_AVAILABLE_TAGS_FOR_PAP'
export const ADD_TAG_TO_PAP = 'ADD_TAG_TO_PAP'
export const EDIT_TAG_OF_PAP = 'EDIT_TAG_OF_PAP'
export const REMOVE_TAG_FROM_PAP = 'REMOVE_TAG_FROM_PAP'
export const ADD_USER_TO_PAP = 'ADD_USER_TO_PAP'
export const EDIT_USER_OF_PAP = 'EDIT_USER_OF_PAP'
export const REMOVE_USER_FROM_PAP = 'REMOVE_USER_FROM_PAP'
export const FETCH_SESSION_ATTRIBUTES = 'FETCH_SESSION_ATTRIBUTES'
export const CREATE_SESSION_ATTRIBUTES = 'CREATE_SESSION_ATTRIBUTES'
export const UPDATE_SESSION_ATTRIBUTES = 'UPDATE_SESSION_ATTRIBUTES'
export const DELETE_SESSION_ATTRIBUTES = 'DELETE_SESSION_ATTRIBUTES'
export const RESET_PAP_PERMISSIONS_DATA = 'RESET_PAP_PERMISSIONS_DATA'
export const FETCH_PERMISSION_FINDINGS = 'FETCH_PERMISSION_FINDINGS'

export const VALIDATE_CLONED_PROFILE_DATA = 'VALIDATE_CLONED_PROFILE_DATA'

export const validateProfileData = (papId, requestAccess) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: VALIDATE_CLONED_PROFILE_DATA,
      method: 'get',
      path: requestAccess
        ? `/profile-requests/paps/${papId}/validate`
        : `/paps/${papId}/validate`,
    })
  )
}

export const fetchPapsForApp = appId => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: FETCH_PAPS_FOR_APP,
      method: 'get',
      path: `/apps/${appId}/paps?view=summary`,
    })
  )
}

export const fetchPap = (papId, summary, requestAccess) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: FETCH_PAP,
      method: 'get',
      path: requestAccess
        ? `/profile-requests/paps/${papId}${summary ? '?view=summary' : ''}`
        : `/paps/${papId}${summary ? '?view=summary' : ''}`,
    })
  )
}

export const fetchPapAdditionalSettings = (papId, requestAccess) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: FETCH_PAP_ADDITIONAL_SETTINGS,
      method: 'get',
      path: requestAccess
        ? `/profile-requests/paps/${papId}/additional-settings`
        : `/paps/${papId}/additional-settings`,
    })
  )
}

export const fetchPapPermissions = (appId, papId, requestAccess) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: FETCH_PAP_PERMISSIONS,
      meta: { papId },
      method: 'get',
      path: requestAccess
        ? `/profile-requests/paps/${papId}/permissions`
        : `/paps/${papId}/permissions`,
    })
  )
}

export const fetchPapUsers = (appId, papId) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: FETCH_PAP_USERS,
      meta: { papId },
      method: 'get',
      path: `/paps/${papId}/users`,
    })
  )
}

export const createPap = (
  appContainerId,
  scope,
  papProperties,
  requestAccess,
  cloneProfilePayload,
  ignoreErrors = true
) => async dispatch => {
  const {
    expirationDuration,
    extensionDuration,
    notificationPriorToExpiration,
    ...rest
  } = papProperties

  const postBody = {
    appContainerId,
    expirationDuration: parseInt(expirationDuration),
    extensionDuration: extensionDuration * 60000,
    notificationPriorToExpiration: notificationPriorToExpiration * 60000,
    scope,
    ...rest,
  }

  return dispatch(
    fetchIfLoggedIn({
      actionType: CREATE_PAP,
      meta: { appContainerId },
      method: 'post',
      path:
        `${requestAccess ? '/profile-requests' : ''}` +
        `/apps/${appContainerId}/paps/${cloneProfilePayload || ''}`,
      postBody,
      params: { ignoreErrors },
    })
  )
}

export const deletePap = (appId, papId) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: DELETE_PAP,
      meta: { appId, papId },
      method: 'delete',
      path: `/apps/${appId}/paps/${papId}`,
    })
  )
}

export const enablePap = (appId, papId) => dispatch => {
  return dispatch(updatePapStatus(appId, papId, 'enable'))
}

export const disablePap = (appId, papId) => dispatch => {
  return dispatch(updatePapStatus(appId, papId, 'disable'))
}

const updatePapStatus = (appId, papId, status) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: UPDATE_PAP_STATUS,
      method: 'post',
      path: `/apps/${appId}/paps/${papId}/${status}d-statuses`,
    })
  )
}

export const updatePapScope = (
  appId,
  papId,
  scope,
  requestAccess
) => async dispatch => {
  const postBody = []

  Object.values(scope).forEach(({ type, value }) => {
    const formattedScope = { type, value }
    postBody.push(formattedScope)
  })

  return dispatch(
    fetchIfLoggedIn({
      actionType: UPDATE_PAP_SCOPE,
      meta: { appId, papId, scope },
      method: 'post',
      path: requestAccess
        ? `/profile-requests/paps/${papId}/scopes`
        : `/paps/${papId}/scopes`,
      postBody,
    })
  )
}

export const updatePap = (
  appContainerId,
  papId,
  papProperties,
  requestAccess
) => async dispatch => {
  const {
    expirationDuration,
    extensionDuration,
    notificationPriorToExpiration,
    ...rest
  } = papProperties

  const postBody = {
    appContainerId,
    expirationDuration: parseInt(expirationDuration),
    extensionDuration: extensionDuration * 60000,
    notificationPriorToExpiration: notificationPriorToExpiration * 60000,
    ...rest,
  }

  return dispatch(
    fetchIfLoggedIn({
      actionType: UPDATE_PAP,
      method: 'patch',
      path: requestAccess ? `/profile-requests/paps/${papId}` : `/paps/${papId}`,
      postBody,
    })
  )
}

export const updateAdditionalSettings = (
  papId,
  additionalSettings,
  requestAccess
) => async dispatch => {
  const postBody = {
    ...additionalSettings,
  }

  return dispatch(
    fetchIfLoggedIn({
      actionType: UPDATE_PAP_ADDITIONAL_SETTINGS,
      method: 'put',
      path: requestAccess
        ? `/profile-requests/paps/${papId}/additional-settings`
        : `/paps/${papId}/additional-settings`,
      postBody,
    })
  )
}

export const updatePapResourcesScope = ({ appId, papId, scope }) => dispatch => {
  const postBody = []

  Object.values(scope).forEach(({ type, value }) => {
    const formattedScope = { type, value }
    postBody.push(formattedScope)
  })

  return dispatch(
    fetchIfLoggedIn({
      actionType: UPDATE_PAP_RESOURCES_SCOPE,
      meta: { appId, papId },
      method: 'post',
      path: `/paps/${papId}/resources/scopes`,
      postBody,
    })
  )
}

export const updatePermission = (
  appId,
  papId,
  permission,
  op,
  requestAccess
) => async dispatch => {
  const postBody = { op, permission }

  return dispatch(
    fetchIfLoggedIn({
      actionType: UPDATE_PERMISSION,
      meta: { op, papId, permission },
      method: 'post',
      path: requestAccess
        ? `/profile-requests/paps/${papId}/permissions`
        : `/paps/${papId}/permissions`,
      postBody,
    })
  )
}

export const fetchAvailableTagsForPap = ({ papId }) => async dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: FETCH_AVAILABLE_TAGS_FOR_PAP,
      meta: { papId },
      method: 'get',
      path: `/paps/${papId}/user-tags?filter=available`,
    })
  )
}

export const fetchAssignedTagsForPap = ({ papId }) => async dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: FETCH_ASSIGNED_TAGS_FOR_PAP,
      meta: { papId },
      method: 'get',
      path: `/paps/${papId}/user-tags`,
    })
  )
}

export const fetchScopesForPap = ({ papId, requestAccess }) => async dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: FETCH_SCOPES_FOR_PAP,
      meta: { papId },
      method: 'get',
      path: requestAccess
        ? `/profile-requests/paps/${papId}/scopes`
        : `/paps/${papId}/scopes`,
    })
  )
}

export const addTagToPap = ({
  start,
  end,
  papId,
  tagId,
  forceAdd,
}) => async dispatch => {
  const postBody = {}

  if (start) {
    postBody.start = start.toISOString().split('.')[0] + 'Z'
  }

  if (end) {
    postBody.end = end.toISOString().split('.')[0] + 'Z'
  }

  let path = `/paps/${papId}/user-tags/${tagId}`

  if (forceAdd) {
    path += `?replaceExistingUser=true`
  }

  return dispatch(
    fetchIfLoggedIn({
      actionType: ADD_TAG_TO_PAP,
      meta: { papId, tagId },
      method: 'post',
      path,
      postBody,
    })
  )
}

export const editTagOfPap = ({ start, end, papId, tagId }) => async dispatch => {
  const postBody = {}

  if (start) {
    postBody.start = start.toISOString().split('.')[0] + 'Z'
  }

  if (end) {
    postBody.end = end.toISOString().split('.')[0] + 'Z'
  }

  return dispatch(
    fetchIfLoggedIn({
      actionType: EDIT_TAG_OF_PAP,
      meta: { papId, tagId },
      method: 'patch',
      path: `/paps/${papId}/user-tags/${tagId}`,
      postBody,
    })
  )
}

export const removeTagFromPap = ({ papId, tagId }) => async dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: REMOVE_TAG_FROM_PAP,
      meta: { papId, tagId },
      method: 'delete',
      path: `/paps/${papId}/user-tags/${tagId}`,
    })
  )
}

export const fetchSessionAttributes = papId => async dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: FETCH_SESSION_ATTRIBUTES,
      method: 'get',
      path: `/paps/${papId}/session-attributes`,
    })
  )
}

export const createSessionAttribute = (values, papId) => async dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: CREATE_SESSION_ATTRIBUTES,
      method: 'post',
      path: `/paps/${papId}/session-attributes`,
      postBody: { ...values },
    })
  )
}

export const updateSessionAttribute = (values, papId) => async dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: UPDATE_SESSION_ATTRIBUTES,
      method: 'put',
      path: `/paps/${papId}/session-attributes`,
      postBody: { ...values },
    })
  )
}

export const deleteSessionAttribute = (id, papId) => async dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: DELETE_SESSION_ATTRIBUTES,
      method: 'delete',
      path: `/paps/${papId}/session-attributes/${id}`,
    })
  )
}

export const addUserToPap = ({ papId, userId, start, end }) => async dispatch => {
  return dispatch(
    papApi({
      papId,
      userId,
      start,
      end,
      method: 'post',
      actionType: ADD_USER_TO_PAP,
    })
  )
}

export const editUserOfPap = ({ papId, userId, start, end }) => async dispatch => {
  return dispatch(
    papApi({
      papId,
      userId,
      start,
      end,
      method: 'patch',
      actionType: EDIT_USER_OF_PAP,
    })
  )
}

export const removeUserFromPap = ({ papId, userId }) => async dispatch => {
  return dispatch(
    papApi({ papId, userId, method: 'delete', actionType: REMOVE_USER_FROM_PAP })
  )
}

const papApi = ({
  papId,
  userId,
  start,
  end,
  method,
  actionType,
}) => async dispatch => {
  const path = `/paps/${papId}/users/${userId}`
  const postBody = {}

  if (start) {
    postBody.start = start.toISOString().split('.')[0] + 'Z'
  }

  if (end) {
    postBody.end = end.toISOString().split('.')[0] + 'Z'
  }

  return dispatch(
    fetchIfLoggedIn({
      actionType,
      meta: { papId, userId },
      method,
      postBody,
      path,
    })
  )
}

export const resetPapPermission = papId => {
  return {
    type: RESET_PAP_PERMISSIONS_DATA,
    payload: {
      papId,
    },
  }
}

export const fetchPermissionFindings = (appId, name) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: FETCH_PERMISSION_FINDINGS,
      method: 'get',
      path: `/profile-requests/apps/${appId}/britive-managed/permissions/${name}/findings`,
    })
  )
}
