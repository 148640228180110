import React, { useState } from 'react'

import Button from 'britive-design-system/core/components/button'
import Textfield from 'britive-design-system/core/components/textfield'

import PasswordPolicyCheck from 'components/PasswordPolicyCheck'
import { NEW_PASSWORD_REQUIRED } from 'constants/cognitoAuthentication'
import Form from './Form'

const ChangePasswordForm = ({
  fields,
  handleChange,
  errorMessage,
  changePassword,
}) => {
  const [isPasswordValid, setIsPasswordValid] = useState(false)

  const formValid = () => {
    return isPasswordValid && fields.currentPassword
  }

  const updatePasswordValidity = value => {
    setIsPasswordValid(value)
  }
  return (
    <Form
      className="login-form"
      onSubmit={e => {
        changePassword(e, NEW_PASSWORD_REQUIRED)
      }}
      errorMessage={errorMessage}
      title="Change your password"
    >
      <Textfield
        id="currentPassword"
        name="currentPassword"
        placeholder="Current password"
        label="Current password"
        hideLabel={false}
        value={fields.currentPassword}
        type="password"
        onChange={handleChange}
      />
      <Textfield
        id="newPassword"
        autoComplete="off"
        name="newPassword"
        placeholder="New password"
        label="New password"
        hideLabel={false}
        value={fields.newPassword}
        type="password"
        onChange={handleChange}
      />
      <Textfield
        id="confirmPassword"
        name="confirmPassword"
        placeholder="Verify new password"
        label="Verify new password"
        hideLabel={false}
        type="password"
        value={fields.confirmPassword}
        onChange={handleChange}
      />
      <PasswordPolicyCheck
        newPassword={fields.newPassword}
        confirmPassword={fields.confirmPassword}
        currentPassword={fields.currentPassword}
        afterPolicyCheckUpdate={updatePasswordValidity}
        checkAgainstLastPassword
        checkAgainstConfirmPassword
      />
      <Button disabled={!formValid()} size="large">
        Change Password
      </Button>
    </Form>
  )
}

export default ChangePasswordForm
