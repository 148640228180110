export const SELECT_PAPS_MANAGER_ENTITY = 'SELECT_PAPS_MANAGER_ENTITY'
export const SELECT_PAP = 'SELECT_PAP'
export const TOGGLE_ENVIRONMENT_TO_SCAN = 'TOGGLE_ENVIRONMENT_TO_SCAN'
export const CLEAR_ENVIRONMENTS_TO_SCAN = 'CLEAR_ENVIRONMENTS_TO_SCAN'
export const SELECT_ALL_ENVIRONTMENTS_TO_SCAN = 'SELECT_ALL_ENVIRONTMENTS_TO_SCAN'

export const selectPapsManagerEntity = entity => ({
  type: SELECT_PAPS_MANAGER_ENTITY,
  payload: entity,
})

export const selectPap = id => ({
  type: SELECT_PAP,
  payload: id,
})

export const toggleEnvironmentToScan = envId => ({
  type: TOGGLE_ENVIRONMENT_TO_SCAN,
  payload: envId,
})

export const clearEnvironmentsToScan = () => ({
  type: CLEAR_ENVIRONMENTS_TO_SCAN,
})

export const selectAllEnvironmentsToScan = environments => ({
  type: SELECT_ALL_ENVIRONTMENTS_TO_SCAN,
  payload: environments,
})
