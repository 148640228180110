import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import toast from 'utils/toast'
import {
  MY_RESOURCES_CHECKED_OUT_NOTIFICATION,
  MY_RESOURCES_CHECKED_IN_NOTIFICATION,
  MY_RESOURCES_CHECK_OUT_FAILED_NOTIFICATION,
  MY_RESOURCES_CHECK_IN_FAILED_NOTIFICATION,
  MY_RESOURCES_CHECK_OUT_REJECTED_NOTIFICATION,
  MY_RESOURCES_REQUEST_APPROVED_NOTIFICATION,
  MY_RESOURCES_CHECK_OUT_APPROVAL_EXPIRED,
  MY_RESOURCES_CHECK_OUT_TIMEOUT_NOTIFICATION,
  MY_RESOURCES_CHECK_IN_TIMEOUT_NOTIFICATION,
} from 'actions'

const onCheckedOutNotification = async (dispatch, getState, notification = {}) => {
  if (isEmpty(notification)) {
    return
  }

  const profiles = get(getState(), 'serverAccess.myResources.profiles.data', [])
  const currentProfile = profiles.find(
    profile => profile.resourceId === notification.resourceId
  )

  await dispatch({
    type: MY_RESOURCES_CHECKED_OUT_NOTIFICATION,
    payload: {
      ...notification,
      expirationDuration: notification?.expirationTime,
    },
  })

  toast({
    title: `Resource ${currentProfile?.resourceName} has been checked out successfully.`,
    type: 'success',
    time: 'normal',
    id: `resource-checkedout-${notification.resourceId}`,
  })
}

const onCheckedInNotification = async (dispatch, getState, notification = {}) => {
  if (isEmpty(notification)) {
    return
  }

  const profiles = get(getState(), 'serverAccess.myResources.profiles.data', [])
  const currentProfile = profiles.find(
    profile => profile.resourceId === notification.resourceId
  )

  await dispatch({
    type: MY_RESOURCES_CHECKED_IN_NOTIFICATION,
    payload: {
      ...notification,
      status: 'available',
    },
  })

  toast({
    title: `Resource ${currentProfile?.resourceName} has been checked in successfully.`,
    type: 'success',
    time: 'normal',
    id: `resource-checkedin-${notification.resourceId}`,
  })
}

const onCheckOutFailedNotification = async (
  dispatch,
  getState,
  notification = {}
) => {
  if (isEmpty(notification)) {
    return
  }

  const profiles = get(getState(), 'serverAccess.myResources.profiles.data', [])
  const currentProfile = profiles.find(
    profile => profile.resourceId === notification.resourceId
  )

  const { errorMsg = '' } = notification

  await dispatch({
    type: MY_RESOURCES_CHECK_OUT_FAILED_NOTIFICATION,
    payload: notification,
  })

  toast({
    title: `Checkout for ${currentProfile?.resourceName} has failed.`,
    type: 'error',
    time: errorMsg?.length ? 'long' : 'normal',
    id: `resource-checkout-failed-${notification.resourceId}`,
    ...(errorMsg?.length && { description: errorMsg }),
  })
}

const onCheckInFailedNotification = async (
  dispatch,
  getState,
  notification = {}
) => {
  if (isEmpty(notification)) {
    return
  }

  const profiles = get(getState(), 'serverAccess.myResources.profiles.data', [])
  const currentProfile = profiles.find(
    profile => profile.resourceId === notification.resourceId
  )

  const { errorMsg = '' } = notification

  await dispatch({
    type: MY_RESOURCES_CHECK_IN_FAILED_NOTIFICATION,
    payload: notification,
  })

  toast({
    title: `Checkin for ${currentProfile?.resourceName} has failed.`,
    type: 'error',
    time: errorMsg?.length ? 'long' : 'normal',
    id: `resource-checkin-failed-${notification.resourceId}`,
    ...(errorMsg?.length && { description: errorMsg }),
  })
}

const onCheckOutRejectedNotification = async (
  dispatch,
  getState,
  notification = {}
) => {
  if (isEmpty(notification)) {
    return
  }

  const profiles = get(getState(), 'serverAccess.myResources.profiles.data', [])
  const currentProfile = profiles.find(
    profile => profile.resourceId === notification.resourceId
  )

  await dispatch({
    type: MY_RESOURCES_CHECK_OUT_REJECTED_NOTIFICATION,
    payload: notification,
  })

  toast({
    title: `The checkout request for '${currentProfile?.resourceName}' has been rejected.`,
    type: 'error',
    time: 'normal',
    id: `${currentProfile?.resourceName}-rejected-${MY_RESOURCES_CHECK_OUT_REJECTED_NOTIFICATION}`,
  })
}

const onCheckOutApprovalExpired = async (dispatch, getState, notification = {}) => {
  if (isEmpty(notification)) {
    return
  }

  const profiles = get(getState(), 'serverAccess.myResources.profiles.data', [])
  const currentProfile = profiles.find(
    profile => profile.resourceId === notification.resourceId
  )

  await dispatch({
    type: MY_RESOURCES_CHECK_OUT_APPROVAL_EXPIRED,
    payload: notification,
  })
  toast({
    title: `The Checkout approval for profile '${currentProfile?.resourceName}' has expired.`,
    type: 'success',
    time: 'normal',
    id: `${currentProfile?.resourceName}-approval-expired-${MY_RESOURCES_CHECK_OUT_APPROVAL_EXPIRED}`,
  })
}

const onRequestApprovedNotification = async (
  dispatch,
  getState,
  notification = {}
) => {
  if (isEmpty(notification)) {
    return
  }

  const profiles = get(getState(), 'serverAccess.myResources.profiles.data', [])
  const currentProfile = profiles.find(
    profile => profile.resourceId === notification.resourceId
  )

  await dispatch({
    type: MY_RESOURCES_REQUEST_APPROVED_NOTIFICATION,
    payload: notification,
  })
  toast({
    title: `Profile request for '${currentProfile?.resourceName}' has been approved.`,
    type: 'success',
    time: 'normal',
    id: `${currentProfile?.resourceName}-request-approved-${MY_RESOURCES_REQUEST_APPROVED_NOTIFICATION}`,
  })
}

export const onCheckOutTimeOutNotification = async (
  dispatch,
  getState,
  notification = {}
) => {
  if (isEmpty(notification)) {
    return
  }

  const { resourceId, errorMsg = '' } = notification

  const profiles = get(getState(), 'serverAccess.myResources.profiles.data', [])
  const currentProfile = profiles.find(profile => profile.resourceId === resourceId)

  await dispatch({
    type: MY_RESOURCES_CHECK_OUT_TIMEOUT_NOTIFICATION,
    payload: notification,
  })

  toast({
    title: `Checkout for ${currentProfile?.resourceName} has timed out.`,
    type: 'error',
    time: errorMsg?.length ? 'long' : 'normal',
    id: `resource-checkout-failed-timeout-${resourceId}`,
    ...(errorMsg?.length && { description: errorMsg }),
  })
}

export const onCheckInTimeOutNotification = async (
  dispatch,
  getState,
  notification = {}
) => {
  if (isEmpty(notification)) {
    return
  }

  const { resourceId, errorMsg = '' } = notification

  const profiles = get(getState(), 'serverAccess.myResources.profiles.data', [])
  const currentProfile = profiles.find(profile => profile.resourceId === resourceId)

  await dispatch({
    type: MY_RESOURCES_CHECK_IN_TIMEOUT_NOTIFICATION,
    payload: notification,
  })

  toast({
    title: `Checkin for ${currentProfile?.resourceName} has timed out.`,
    type: 'error',
    time: errorMsg?.length ? 'long' : 'normal',
    id: `resource-checkin-failed-timeout-${resourceId}`,
    ...(errorMsg?.length && { description: errorMsg }),
  })
}

export default {
  onCheckedOutNotification,
  onCheckedInNotification,
  onCheckOutFailedNotification,
  onCheckInFailedNotification,
  onCheckOutRejectedNotification,
  onCheckOutApprovalExpired,
  onRequestApprovedNotification,
  onCheckOutTimeOutNotification,
  onCheckInTimeOutNotification,
}
