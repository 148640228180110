import React from 'react'
import { useHistory } from 'react-router-dom'
import Button from 'britive-design-system/core/components/button'
import { classes } from './constants'
import './index.scss'

export const ProfileToolbar = ({ appId, profileListFilter = '' }) => {
  const history = useHistory()

  const handleDoneClick = () => {
    history.push({
      pathname: `/admin/applications/${appId}/profiles`,
      search: profileListFilter,
      state: {
        persistedFilters: true,
      },
    })
  }

  return (
    <div className={classes.container}>
      <Button variant="primary" onClick={handleDoneClick}>
        Done
      </Button>
    </div>
  )
}
