import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { get, isEmpty } from 'lodash'
import ApprovalModal from 'secret-manager-ui/core/common-widgets/approval-modal'
import Spinner from 'britive-design-system/core/components/spinner'
import { fetchApprovers } from 'action_creators/access'
import { submitProfileApprovalRequest } from 'action_creators/serverAccess/myResources'
import toast from 'utils/toast'
import { getApprovalDuration } from 'utils/format_date'

const ResourcesApprovalModal = ({
  profileId,
  resourceId,
  resourceName,
  onClose,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const dispatch = useDispatch()
  const approvers = useSelector(state => state.userAppPaps)

  useEffect(() => {
    dispatch(
      fetchApprovers(profileId, 'resourceprofile', 'resource.profile.checkout')
    )
  }, [])

  const requestCheckoutApproval = async values => {
    try {
      setIsSubmitting(true)
      await dispatch(submitProfileApprovalRequest(profileId, resourceId, values))
      onClose()
      toast({
        title: `'${resourceName}' has been submitted for approval.`,
        type: 'success',
        time: 'normal',
      })
    } catch (error) {
      toast({
        title: get(
          error,
          'response.data.message',
          'Something went wrong while trying to submit for approval'
        ),
        type: 'error',
        time: 'normal',
      })
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <>
      {(approvers?.fetchingApprovers || isSubmitting) && (
        <Spinner size="medium" message={``} overlay />
      )}
      {!approvers?.fetchingApprovers && !isEmpty(approvers?.approversDetails) && (
        <ApprovalModal
          entityName={resourceName}
          approversDetails={approvers?.approversDetails}
          onSubmit={requestCheckoutApproval}
          onModalClose={onClose}
          action="Access Profile"
          entityType="profile"
          approvalInfoText={
            <span>
              Approval Validity will expire in&nbsp;
              {<b>{getApprovalDuration(approvers?.approversDetails)}</b>} from the
              approval&nbsp; date/time.
            </span>
          }
        />
      )}
    </>
  )
}

export default ResourcesApprovalModal
