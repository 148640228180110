import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import AppActivity from './AppActivity'

import { selectApplicationRootId } from 'action_creators/application_root'
import { fetchSessions } from 'action_creators/activity'

import { getSelectedApplicationRoot } from 'selectors/application_root'
import {
  getSelectedApplicationSessions,
  getSessionFetchingStatus,
} from 'selectors/activity'

import * as routing from 'services/routing'

export class AppActivityContainer extends PureComponent {
  constructor(props) {
    super(props)

    const to = new Date()
    const from = new Date()
    from.setDate(to.getDate() - 7)

    this.state = {
      fields: {
        to,
        from,
        size: 20,
        searchText: '',
      },
      advancedFilter: [],
      dropdownStatus: {},
      isFilterModalOpen: false,
      areFiltersDirty: false,
      datesAreDirty: false,
    }
  }

  componentDidMount = () => {
    const { dispatch, match, setBreadcrumbTrail, app } = this.props
    const { catalogAppDisplayName: appName } = app
    const { appId } = match.params

    if (appId) {
      dispatch(selectApplicationRootId(appId))
      dispatch(fetchSessions(appId))
      setBreadcrumbTrail([{ title: `${appName} Activity` }])
    }
  }

  goToSessionActivity = transactionId => {
    const { match } = this.props
    const { appId } = match.params
    routing.appSessionActivity({ appId, transactionId })
  }

  render() {
    const { app, appHeaderHeight, sessions, isFetchingSessions } = this.props

    return (
      <AppActivity
        app={app}
        appHeaderHeight={appHeaderHeight}
        isFetchingSessions={isFetchingSessions}
        sessions={sessions}
        goToSessionActivity={this.goToSessionActivity}
      />
    )
  }
}

const mapStateToProps = state => ({
  app: getSelectedApplicationRoot(state),
  sessions: getSelectedApplicationSessions(state),
  isFetchingSessions: getSessionFetchingStatus(state),
})

export default connect(mapStateToProps)(AppActivityContainer)
