import * as actions from 'actions'
import { fetchIfLoggedIn } from '../../utils'

export const getMyResourcesProfiles = (params = {}) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.MY_RESOURCES_GET_PROFILES,
      method: 'get',
      params,
      path: '/resource-manager/my-resources',
      meta: { params },
    })
  )
}

export const checkoutMyResourcesProfile = (profileId, resourceId) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.MY_RESOURCES_CHECKOUT_PROFILE,
      method: 'post',
      path: `/resource-manager/my-resources/profiles/${profileId}/resources/${resourceId}/checkout`,
    })
  )
}

export const checkinMyResourcesProfile = transactionId => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.MY_RESOURCES_CHECKIN_PROFILE,
      method: 'post',
      path: `/resource-manager/my-resources/${transactionId}/check-in`,
    })
  )
}

export const getMyResourcesProfileCreds = (
  transactionId,
  profileId,
  resourceId,
  templateName
) => dispatch => {
  const params = {}
  if (templateName) {
    params.templateName = templateName
  }

  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.MY_RESOURCES_GET_CREDENTIALS,
      method: 'post',
      meta: {
        profileId,
        resourceId,
      },
      path: `/resource-manager/my-resources/${transactionId}/credentials`,
      params,
    })
  )
}

export const addMyResourcesFavorite = (profileId, resourceId) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.MY_RESOURCES_ADD_FAVORITE,
      method: 'post',
      postBody: {
        profileId,
        resourceId,
      },
      path: `/resource-manager/my-resources/favorites`,
    })
  )
}

export const deleteMyResourcesFavorite = (
  favoriteId,
  profileId,
  resourceId
) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.MY_RESOURCES_DELETE_FAVORITE,
      method: 'delete',
      meta: {
        profileId,
        resourceId,
      },
      path: `/resource-manager/my-resources/favorites/${favoriteId}`,
    })
  )
}

export const submitProfileApprovalRequest = (
  profileId,
  resourceId,
  postBody
) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.MY_RESOURCES_SUBMIT_CHECKOUT_REQUEST,
      method: 'post',
      postBody,
      meta: {
        profileId,
        resourceId,
      },
      path: `/resource-manager/my-resources/profiles/${profileId}/resources/${resourceId}/approvalRequest`,
    })
  )
}

export const withdrawApprovalRequest = (profileId, resourceId) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.MY_RESOURCES_WITHDRAW_APPROVAL_REQUEST,
      meta: {
        profileId,
        resourceId,
      },
      method: 'delete',
      path: `/v1/approvals/consumer/resourceprofile/resource?resourceId=${profileId}/${resourceId}`,
    })
  )
}

export const getAvailableTemplates = transactionId => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.MY_RESOURCES_GET_AVAILABLE_TEMPLATES,
      method: 'get',
      path: `/resource-manager/my-resources/${transactionId}/templates?isConsoleEnabled=true`,
    })
  )
}
