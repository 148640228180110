import { fetchIfLoggedIn } from './utils.js'

export const FETCH_ACCESS_KEYS = 'FETCH_ACCESS_KEYS'
export const FETCH_ACCESS_URL = 'FETCH_ACCESS_URL'
export const DELETE_ACCESS_KEYS = 'DELETE_ACCESS_KEYS'

export const fetchAccessKeys = transactionId => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: FETCH_ACCESS_KEYS,
      method: 'get',
      meta: { transactionId },
      path: `/access/${transactionId}/tokens`,
    })
  )
}

export const fetchAccessURL = (transactionId, isSPInitiatedApp) => dispatch => {
  const spInitiatedSSO = sessionStorage.getItem('spInitiatedSSO')
  return dispatch(
    fetchIfLoggedIn({
      actionType: FETCH_ACCESS_URL,
      method: 'get',
      meta: { transactionId },
      path: `/access/${transactionId}/url${
        spInitiatedSSO && isSPInitiatedApp ? `?spInitiatedSSO=${spInitiatedSSO}` : ''
      }`,
    })
  )
}

export const deleteAccessKeys = transactionId => ({
  type: DELETE_ACCESS_KEYS,
  payload: transactionId,
})
