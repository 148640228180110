import {
  CREATE_TAG,
  FETCH_TAGS,
  FETCH_SELECTED_TAGS,
  FETCH_PAGINATED_TAGS,
  FETCH_PAGINATED_TAGS_LOAD_MORE,
  FETCH_TAG,
  FETCH_TAG_ASSIGNED_USERS,
  FETCH_TAG_AVAILABLE_USERS,
  ADD_TAG_USER,
  REMOVE_TAG_USER,
  UPDATE_TAG,
  ENABLE_TAG,
  DISABLE_TAG,
  DELETE_TAG,
  INVALIDATE_TAG_ASSIGNED_USERS,
} from 'action_creators/tag'

export const tagsReducerKey = 'tags'

const initialState = {
  fetchingTags: false,
  fetchedTags: false,
  fetchingPaginatedTags: false,
  paginatedTags: [],
  fetchingTag: false,
  creatingTag: false,
  updatingTag: false,
  deletingTag: false,
  fetchingTagAvailableUsers: false,
  fetchingTagAssignedUsers: false,
  addingUser: {},
  removingUser: {},
  tags: [],
  tagsObject: {},
  tagAssignedUsers: {},
  tagAvailableUsers: {},
  error: null,
}

export function tagsReducer(state = initialState, action) {
  switch (action.type) {
    case `${FETCH_TAGS}_PENDING`: {
      return {
        ...state,
        fetchingTags: true,
        fetchedTags: false,
      }
    }

    case `${FETCH_TAGS}_FULFILLED`: {
      const tags = action.payload.data

      return {
        ...state,
        fetchingTags: false,
        fetchedTags: true,
        tags,
      }
    }

    case `${FETCH_TAGS}_REJECTED`: {
      return {
        ...state,
        fetchingTags: false,
        fetchedTags: false,
        error: action.payload,
      }
    }

    case `${FETCH_SELECTED_TAGS}_PENDING`: {
      return {
        ...state,
        fetchingTags: true,
        fetchedTags: false,
      }
    }

    case `${FETCH_SELECTED_TAGS}_FULFILLED`: {
      const tags = action.payload.data

      return {
        ...state,
        fetchingTags: false,
        fetchedTags: true,
        tags,
      }
    }

    case `${FETCH_SELECTED_TAGS}_REJECTED`: {
      return {
        ...state,
        fetchingTags: false,
        fetchedTags: false,
        error: action.payload,
      }
    }

    case `${FETCH_PAGINATED_TAGS}_PENDING`: {
      return {
        ...state,
        fetchingPaginatedTags: true,
      }
    }

    case `${FETCH_PAGINATED_TAGS}_FULFILLED`: {
      const { data, count, page, size } = action.payload.data
      return {
        ...state,
        fetchingPaginatedTags: false,
        paginatedTags: {
          data,
          count,
          page,
          size,
          searchText: action?.meta?.searchText,
        },
      }
    }

    case `${FETCH_PAGINATED_TAGS_LOAD_MORE}_PENDING`: {
      return {
        ...state,
        fetchingPaginatedTags: true,
      }
    }

    case `${FETCH_PAGINATED_TAGS_LOAD_MORE}_FULFILLED`: {
      const { data, count, page, size } = action.payload.data
      return {
        ...state,
        fetchingPaginatedTags: false,
        paginatedTags: {
          data: [...state.paginatedTags.data, ...data],
          count,
          page,
          size,
          searchText: action?.meta?.searchText,
        },
      }
    }

    case `${FETCH_PAGINATED_TAGS}_REJECTED`: {
      return {
        ...state,
        fetchingPaginatedTags: false,
        error: action.payload,
      }
    }

    case `${FETCH_PAGINATED_TAGS_LOAD_MORE}_REJECTED`: {
      return {
        ...state,
        fetchingPaginatedTags: false,
        error: action.payload,
      }
    }

    case `${CREATE_TAG}_PENDING`: {
      return {
        ...state,
        creatingTag: true,
      }
    }

    case `${CREATE_TAG}_FULFILLED`: {
      const newTag = action.payload.data
      const newTags = [...state.tags, newTag]

      return {
        ...state,
        creatingTag: false,
        tags: newTags,
      }
    }

    case `${CREATE_TAG}_REJECTED`: {
      return {
        ...state,
        creatingTag: false,
        error: action.payload,
      }
    }

    case `${FETCH_TAG}_PENDING`: {
      return {
        ...state,
        fetchingTag: true,
      }
    }

    case `${FETCH_TAG}_FULFILLED`: {
      const fetchedTag = action.payload.data
      const tagsObject = {
        ...state.tagsObject,
        [fetchedTag.userTagId]: fetchedTag,
      }

      return {
        ...state,
        fetchingTag: false,
        tagsObject,
      }
    }

    case `${FETCH_TAG}_REJECTED`: {
      return {
        ...state,
        fetchingTag: false,
        error: action.payload,
      }
    }

    case `${ENABLE_TAG}_PENDING`:
    case `${DISABLE_TAG}_PENDING`:
    case `${UPDATE_TAG}_PENDING`: {
      return {
        ...state,
        updatingTag: true,
      }
    }

    case `${ENABLE_TAG}_FULFILLED`:
    case `${DISABLE_TAG}_FULFILLED`:
    case `${UPDATE_TAG}_FULFILLED`: {
      const fetchedTag = action.payload.data
      const oldTags = state.tags.filter(
        tag => fetchedTag.userTagId !== tag.userTagId
      )

      const updatedTags = [...oldTags, fetchedTag]

      return {
        ...state,
        updatingTag: false,
        tags: updatedTags,
      }
    }

    case `${ENABLE_TAG}_REJECTED`:
    case `${DISABLE_TAG}_REJECTED`:
    case `${UPDATE_TAG}_REJECTED`: {
      return {
        ...state,
        updatingTag: false,
        error: action.payload,
      }
    }

    case `${FETCH_TAG_ASSIGNED_USERS}_PENDING`: {
      return {
        ...state,
        fetchingTagAssignedUsers: true,
      }
    }

    case `${FETCH_TAG_ASSIGNED_USERS}_FULFILLED`: {
      const fetchedUsers = action.payload.data

      const tagAssignedUsers = {
        ...state.tagAssignedUsers,
        [action.meta.tagId]: fetchedUsers,
      }

      return {
        ...state,
        fetchingTagAssignedUsers: false,
        tagAssignedUsers,
      }
    }

    case `${FETCH_TAG_ASSIGNED_USERS}_REJECTED`: {
      return {
        ...state,
        fetchingTagAssignedUsers: false,
        error: action.payload,
      }
    }

    case `${INVALIDATE_TAG_ASSIGNED_USERS}`: {
      return {
        ...state,
        tagAssignedUsers: {},
      }
    }

    case `${FETCH_TAG_AVAILABLE_USERS}_PENDING`: {
      return {
        ...state,
        fetchingTagAvailableUsers: true,
      }
    }

    case `${FETCH_TAG_AVAILABLE_USERS}_FULFILLED`: {
      const fetchedUsers = action.payload.data

      const tagAvailableUsers = {
        ...state.tagAvailableUsers,
        [action.meta.tagId]: fetchedUsers,
      }

      return {
        ...state,
        fetchingTagAvailableUsers: false,
        tagAvailableUsers,
      }
    }

    case `${FETCH_TAG_AVAILABLE_USERS}_REJECTED`: {
      return {
        ...state,
        fetchingTagAvailableUsers: false,
        error: action.payload,
      }
    }

    case `${ADD_TAG_USER}_PENDING`: {
      return {
        ...state,
        addingUser: { ...state.addingUser, [action.meta.userId]: true },
      }
    }

    case `${ADD_TAG_USER}_FULFILLED`: {
      return {
        ...state,
        addingUser: { ...state.addingUser, [action.meta.userId]: false },
      }
    }

    case `${ADD_TAG_USER}_REJECTED`: {
      return {
        ...state,
        addingUser: { ...state.addingUser, [action.meta.userId]: false },
        error: action.payload,
      }
    }

    case `${REMOVE_TAG_USER}_PENDING`: {
      return {
        ...state,
        removingUser: { ...state.removingUser, [action.meta.userId]: true },
      }
    }

    case `${REMOVE_TAG_USER}_FULFILLED`: {
      return {
        ...state,
        removingUser: { ...state.removingUser, [action.meta.userId]: false },
      }
    }

    case `${REMOVE_TAG_USER}_REJECTED`: {
      return {
        ...state,
        removingUser: { ...state.removingUser, [action.meta.userId]: false },
        error: action.payload,
      }
    }

    case `${DELETE_TAG}_PENDING`: {
      return {
        ...state,
        deletingTag: true,
      }
    }

    case `${DELETE_TAG}_FULFILLED`: {
      const updatedTags = state.tags.filter(
        tag => action.meta.tagId !== tag.userTagId
      )

      return {
        ...state,
        deletingTag: false,
        tags: updatedTags,
      }
    }

    case `${DELETE_TAG}_REJECTED`: {
      return {
        ...state,
        deletingTag: false,
        error: action.payload,
      }
    }

    default:
      return { ...state }
  }
}
