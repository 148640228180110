import React, { useState } from 'react'
import { isEmpty } from 'lodash'
import Typography from 'britive-design-system/core/components/typography'
import { CiWarning } from 'react-icons/ci'
import { MdErrorOutline } from 'react-icons/md'
import { HiOutlineLightBulb } from 'react-icons/hi'
import { FiShield } from 'react-icons/fi'
import DialogPopup from 'britive-design-system/core/components/dialog'

const styles = {
  label: {
    display: 'flex',
    alignItems: 'center',
  },
  findingsContainer: {
    height: '200px',
    overflow: 'scroll',
  },
  spanContainer: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}

const FindingsComponent = ({ findings }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [data, setData] = useState({
    title: '',
    messages: [],
  })

  const securityWarnings = []
  const warnings = []
  const errors = []
  const suggestions = []

  !isEmpty(findings) &&
    findings?.forEach(finding => {
      const { type, messages } = finding
      switch (type) {
        case 'SECURITY_WARNING':
          securityWarnings.push(...messages)
          break
        case 'WARNING':
          warnings.push(...messages)
          break
        case 'ERROR':
          errors.push(...messages)
          break
        case 'SUGGESTION':
          suggestions.push(...messages)
          break
        default:
          break
      }
    })

  const renderFindings = data => {
    return (
      <div style={styles.findingsContainer}>
        <ul style={{ listStyleType: 'disc' }}>
          {data?.messages?.map((message, index) => (
            <li key={index}>
              <Typography variant="caption1">{message}</Typography>
            </li>
          ))}
        </ul>
      </div>
    )
  }

  const showFindings = (data, title) => {
    setData({
      title: title,
      messages: data,
    })
    setIsOpen(true)
  }

  const renderComma = (arr1, arr2, arr3) => {
    if (!isEmpty(arr1) || !isEmpty(arr2) || !isEmpty(arr3)) {
      return ','
    }
  }

  return (
    <div>
      <div style={styles.label}>
        <Typography variant="heading6">
          {'Policy definition has '}
          {!isEmpty(errors) && (
            <span onClick={() => showFindings(errors, 'Error(s)')}>
              <MdErrorOutline size="18px" color="#e15c5c" />
              <span style={styles.spanContainer}>
                {' Error(s): '}
                {errors.length}
                {renderComma(warnings, securityWarnings, suggestions)}
              </span>{' '}
            </span>
          )}
          {!isEmpty(warnings) && (
            <span onClick={() => showFindings(warnings, 'Warning(s)')}>
              <CiWarning size="18px" color="#f1c21b" />
              <span style={styles.spanContainer}>
                {' Warning(s): '}
                {warnings.length}
                {renderComma(securityWarnings, suggestions)}
              </span>{' '}
            </span>
          )}
          {!isEmpty(securityWarnings) && (
            <span
              onClick={() => showFindings(securityWarnings, 'Security Warning(s)')}
            >
              <FiShield size="18px" color="#e15c5c" />
              <span style={styles.spanContainer}>
                {' Security Warning(s): '}
                {securityWarnings.length}
                {renderComma(suggestions)}
              </span>{' '}
            </span>
          )}
          {!isEmpty(suggestions) && (
            <span onClick={() => showFindings(suggestions, 'Suggestion(s)')}>
              <HiOutlineLightBulb size="18px" />
              <span style={styles.spanContainer}>
                {' Suggestion(s): '}
                {suggestions.length}
              </span>
            </span>
          )}
        </Typography>
      </div>
      {isOpen && (
        <DialogPopup
          type="general"
          width={550}
          height={300}
          title={data.title}
          onCancel={() => {
            setIsOpen(false)
            setData({
              title: '',
              messages: [],
            })
          }}
        >
          {renderFindings(data)}
        </DialogPopup>
      )}
    </div>
  )
}

export default FindingsComponent
