import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { withRouter } from 'react-router-dom'
import { Container, Row, Col, Modal, ModalBody, ModalFooter } from 'reactstrap'
import Cookies from 'js-cookie'

import Button from 'britive-ui-components/core/components/Button'
import PasswordPolicyCheck from 'components/PasswordPolicyCheck'
import FormField from 'components/form_fields'
import ErrorMessage from 'components/error_message'

import {
  setIsInitialAuthing,
  updateAuthenticationInfo,
} from 'action_creators/account'

import doFetch from 'utils/do_fetch'

import {
  NEW_PASSWORD_REQUIRED,
  SOFTWARE_TOKEN_MFA,
  MFA_SETUP,
} from 'constants/cognitoAuthentication'

import '../index.css'
import { handleCLIRedirect } from 'utils/handle-cli-redirect'

const FIELD_DICT = {
  passwordCurrent: 'currentPassword',
  passwordNew: 'newPassword',
  passwordVerify: 'confirmPassword',
}

const styles = {
  container: {
    backgroundColor: '#FFFFFF',
  },
  containerHeader: {
    backgroundColor: 'var(--brand)',
    color: '#FFFFFF',
    fontWeight: 'bold',
    height: 30,
  },
  editButton: {
    backgroundColor: '#FFFFFF',
    height: 30,
    color: 'var(--brand)',
    width: 100,
    marginBottom: 10,
  },
  detailContainer: {
    marginBottom: 10,
    padding: 20,
    height: 60,
  },
}

class UserDetails extends Component {
  state = {
    fields: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    formValid: null,
    enableButton: false,
    currentPasswordMatch: null,
    requesting: false,
    requested: true,
    errorMsg: '',
    modal: false,
    hasEnteredPassword: false,
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    })
  }

  updateEnableButton = bool => {
    this.setState({ enableButton: bool })
  }

  handleChange = event => {
    const { name, value } = event.target
    const { hasEnteredPassword } = this.state
    const formattedName = FIELD_DICT[name] || name

    if (!hasEnteredPassword) {
      this.setState({ hasEnteredPassword: true })
    }

    this.setState({
      fields: {
        ...this.state.fields,
        [formattedName]: value,
      },
    })
  }

  // TODO: all this stuff needs to be somewhere else
  async changePassword() {
    // TODO: there is change password stuff all over the place
    // make this a service or a hook or something

    const {
      account,
      challenge,
      challengeParameters,
      location,
      history,
      dispatch,
      user,
    } = this.props

    try {
      let path
      let method
      const headers = {}
      let data
      let onSuccess

      const forcedReset = challenge === NEW_PASSWORD_REQUIRED

      const fromCli = sessionStorage.getItem('cli')
      if (forcedReset) {
        path = `/auth`
        method = 'post'
        onSuccess = async response => {
          await dispatch(updateAuthenticationInfo(response))
          const authResult = get(response, 'data.authenticationResult', {})
          const newChallenge = get(authResult, 'challengeParameters.challenge')
          if (![SOFTWARE_TOKEN_MFA, MFA_SETUP].includes(newChallenge)) {
            Cookies.set('signInMethod', 'login')

            if (handleCLIRedirect(response, history)) {
              window.location.assign(get(location, 'state.from.pathname', '/'))
            }

            dispatch(setIsInitialAuthing(false))
          }
        }

        data = {
          authParameters: {
            password: this.state.fields.newPassword,
            session: challengeParameters.session,
            username: account.username,
            currentPassword: this.state.fields.currentPassword,
            challenge,
            ...(fromCli && { cliToken: fromCli, type: 'CLI' }),
          },
        }
      } else {
        path = `/auth`
        method = 'post'
        onSuccess = this.passwordChangeSuccess

        data = {
          authParameters: {
            currentPassword: this.state.fields.currentPassword,
            password: this.state.fields.newPassword,
            username: user.username,
            challenge: 'CHANGE_PASSWORD',
          },
        }
      }

      dispatch(setIsInitialAuthing(true))

      const response = await doFetch({
        method,
        headers,
        path,
        postBody: data,
        allow401: true,
      })
      const authenticationResult = get(response, 'data.authenticationResult')
      const userId = get(authenticationResult, 'userId')
      const success = get(authenticationResult, 'success')
      const mfaAuth =
        get(authenticationResult, 'challengeParameters.challenge') ===
        SOFTWARE_TOKEN_MFA

      const isSuccessful = forcedReset
        ? userId || success || mfaAuth
        : authenticationResult

      if (isSuccessful) {
        return onSuccess(response)
      } else {
        dispatch(setIsInitialAuthing(false))

        return this.setState({
          errorMsg: response.data.message,
          requesting: false,
        })
      }
    } catch (e) {
      const message =
        get(e, 'response.data.message') || 'Unexpected error. Please try again.'

      dispatch(setIsInitialAuthing(false))

      this.setState({
        errorMsg: message,
        requesting: false,
      })
    }
  }

  passwordChangeSuccess = () => {
    const { dispatch } = this.props

    dispatch(setIsInitialAuthing(false))

    this.setState({
      requesting: false,
      requested: true,
      errorMsg: '',
      modal: true,
      hasEnteredPassword: false,
      fields: {
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
      },
    })
  }

  onChangePasswordClick = () => {
    // hate dames code
    const {
      fields: { newPassword, confirmPassword },
    } = this.state

    if (newPassword === confirmPassword) {
      this.setState({
        errorMsg: '',
        formValid: true,
        requesting: true,
        requested: false,
      })

      this.changePassword()
    } else {
      this.setState({ errorMsg: '', formValid: false })
    }
  }

  render() {
    const { containerHeader, container, detailContainer } = styles
    const { challenge } = this.props
    const { fields, errorMsg, enableButton, hasEnteredPassword } = this.state
    return (
      <div className="">
        <Row>
          <Col style={container} md={12}>
            {!this.props.hideHeader ? (
              <Row style={containerHeader}>
                <Col style={{ padding: 5 }} md={9}>
                  Change Password
                </Col>
              </Row>
            ) : null}
            <Row>
              <Container>
                {errorMsg === '' ? null : (
                  <Row style={detailContainer}>
                    <ErrorMessage text={errorMsg} />
                    <p />
                  </Row>
                )}

                <Row style={detailContainer}>
                  <Col>Enter Current Password</Col>
                  <Col>
                    <FormField
                      type="password"
                      id="currentPassword"
                      name="passwordCurrent"
                      placeholder="Current Password"
                      value={fields.currentPassword}
                      hideLabel={true}
                      onChange={this.handleChange}
                    />
                  </Col>
                </Row>

                <Row style={detailContainer}>
                  <Col>Enter New Password</Col>
                  <Col>
                    <FormField
                      type="password"
                      id="newPassword"
                      name="passwordNew"
                      placeholder="New Password"
                      value={fields.newPassword}
                      hideLabel={true}
                      onChange={this.handleChange}
                    />
                  </Col>
                </Row>
                <Row style={detailContainer}>
                  <Col>Repeat New Password</Col>
                  <Col>
                    <FormField
                      type="password"
                      id="confirmPassword"
                      name="passwordVerify"
                      placeholder="Repeat New Password"
                      value={fields.confirmPassword}
                      hideLabel={true}
                      onChange={this.handleChange}
                    />
                  </Col>
                </Row>

                {(challenge === NEW_PASSWORD_REQUIRED || hasEnteredPassword) && (
                  <Row>
                    <Col>
                      <PasswordPolicyCheck
                        newPassword={fields.newPassword}
                        confirmPassword={fields.confirmPassword}
                        currentPassword={fields.currentPassword}
                        afterPolicyCheckUpdate={this.updateEnableButton}
                        checkAgainstLastPassword
                        checkAgainstConfirmPassword
                      />
                    </Col>
                  </Row>
                )}

                <Row
                  style={{
                    marginBottom: 10,
                    padding: '16px 20px',
                  }}
                >
                  {this.state.requesting ? (
                    <Button disabled={true} spinner={true} color="primary">
                      Changing Password
                    </Button>
                  ) : (
                    <Button
                      color="primary"
                      className="usersAdminSubmitAction"
                      disabled={!enableButton}
                      onClick={this.onChangePasswordClick}
                    >
                      Change Password
                    </Button>
                  )}
                </Row>
              </Container>
            </Row>
          </Col>
        </Row>

        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalBody>
            Your password was changed successfully. You may now sign in with your new
            password.
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.toggle}>
              Okay
            </Button>{' '}
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}

UserDetails.propTypes = {
  auth: PropTypes.func,
  user: PropTypes.object,
  account: PropTypes.object,
  hideHeader: PropTypes.bool,
  togglePasswordChanged: PropTypes.func,
}

function mapStateToProps(state) {
  return {
    user: state.user,
    account: state.account,
    challenge: get(state.account, 'challengeParameters.challenge'),
    challengeParameters: get(state.account, 'challengeParameters'),
  }
}

const withConnect = connect(mapStateToProps)

export default compose(withConnect, withRouter)(UserDetails)
