import React, { memo, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import get from 'lodash/get'
import Tooltip from 'react-tooltip'

import { Tabs } from 'britive-ui-components/core/components/Tabs'
import Spinner from 'britive-ui-components/core/components/Spinner'

import EnvironmentGroupsWrapper from './EnvironmentGroupsWrapper'

import useOnScrollListener from 'hooks/useOnScrollListener'
import UserIntegrityCheck from '../UserIntegrityCheck'
import TagsIntegrityCheck from '../TagsIntegrityCheck'

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  .tabs {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .tabs .tab-list {
    position: sticky;
    top: ${props => props.titleHeight || 27}px;
    z-index: 1;
    background: #fff;
  }

  .tab-content-container {
    flex-grow: 1;
    overflow: scroll;

    .tab-content {
      display: table !important;
      height: 1px !important;
    }
  }
`

const Title = styled.div`
  color: var(--mine-shaft);
  position: sticky;
  font-weight: 500;
  font-size: 18px;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  z-index: 2;
`

function ProfileEnvironmentGroupsWrapper(props) {
  const [activeTab, setActiveTab] = useState('Permissions')
  const [isChanging, setIsChanging] = useState(false)
  const [titleHeight, setTitleHeight] = useState(null)

  const titleEl = useRef()
  const wrapperRef = useRef()

  useOnScrollListener({ elRef: wrapperRef, onScroll: () => Tooltip.hide() })

  useEffect(() => {
    if (titleEl.current) {
      setTitleHeight(get(titleEl.current, 'offsetHeight'))
    }

    wrapperRef.current = document.querySelector(
      '#profileGroupWrapper .tab-content-container'
    )
  }, [])

  const selectTab = tab => {
    setActiveTab(tab)
    setIsChanging(true)

    setTimeout(() => setIsChanging(false), 200)
  }

  const renderTabContent = () => {
    const { id, isHierarchical, requiresAccountMapping, showUsers, papId } = props

    const tabs = [
      <div key="permissions" label="Permissions">
        <EnvironmentGroupsWrapper
          isHierarchical={isHierarchical}
          id={id}
          papId={papId}
        />
      </div>,
    ]

    if (showUsers) {
      tabs.push(
        <div key="users" label="Users">
          {isChanging ? <Spinner /> : <UserIntegrityCheck papId={papId} />}
        </div>
      )
    }
    tabs.push(
      <div key="tags" label="Tags">
        <TagsIntegrityCheck
          papId={papId}
          requiresAccountMapping={requiresAccountMapping}
        />
      </div>
    )

    return tabs
  }

  const {
    environmentData,
    permissionIntegrityChecks,
    scope,
    id,
    title,
    useTabs,
    papId,
  } = props

  return (
    <Wrapper id="profileGroupWrapper" titleHeight={titleHeight}>
      <Title ref={titleEl}>{title}</Title>

      {useTabs ? (
        <Tabs activeTab={activeTab} onSelectTab={tab => selectTab(tab)}>
          {renderTabContent()}
        </Tabs>
      ) : (
        <EnvironmentGroupsWrapper
          environmentData={environmentData}
          integrityChecks={permissionIntegrityChecks}
          scope={scope}
          id={id}
          showIntegrityCheckStatus={false}
          papId={papId}
        />
      )}
    </Wrapper>
  )
}

export default memo(ProfileEnvironmentGroupsWrapper)
