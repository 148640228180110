import React, { useState, useEffect } from 'react'
import { Row, Col, Container } from 'reactstrap'
import QRCode from 'react-qr-code'
import Button from 'britive-ui-components/core/components/Button'
import Tooltip from 'britive-design-system/core/components/tooltip'
import ModalPopup from 'britive-design-system/core/components/modal-popup'
import Typography from 'britive-design-system/core/components/typography'
import Textfield from 'britive-design-system/core/components/textfield'
import DialogPopup from 'britive-design-system/core/components/dialog'
import Snackbar from 'britive-design-system/core/components/snackbar'
import infoIcon from 'static/images/infoIcon.svg'
import closeIcon from 'static/images/close.svg'
import checkIcon from 'static/images/check.svg'
import './RegisterVerificationDevice.scss'
import { cancelRegistrationWarning, mfaStatus } from '../constants'

const RegisterVerificationDevice = ({
  registrationStatus,
  mfaRegister,
  mfaValidate,
  onRegister,
  onVerify,
  onCancel,
}) => {
  const [isRegistrationModalOpen, setIsRegistrationModalOpen] = useState(false)
  const [showAlphaNumericCode, setShowAlphaNumericCode] = useState(false)
  const [askConfirmationToCancel, setAskConfirmationToCancel] = useState(false)
  const [mfaOtp, setMfaOtp] = useState('')

  useEffect(() => {
    if (mfaValidate?.success) {
      setIsRegistrationModalOpen(false)
    }
  }, [JSON.stringify(mfaValidate)])

  const resetState = () => {
    setMfaOtp('')
  }

  const toggleRegistrationModal = () => {
    setIsRegistrationModalOpen(!isRegistrationModalOpen)
  }

  const toggleAlphanumericCodeModal = () => {
    setShowAlphaNumericCode(!showAlphaNumericCode)
  }

  const toggleAskConfirmationToCancel = () => {
    setAskConfirmationToCancel(!askConfirmationToCancel)
  }

  // Checking if the otp contains only numeric values.
  const isValidOtp = () => /^\d+$/.test(mfaOtp)

  return (
    <>
      <Row>
        <Col className="register-verification-device-container" md={12}>
          <Row className="container-header">
            <Col style={{ padding: 5 }} md={9}>
              MFA Devices
            </Col>
          </Row>
          <Row>
            <Container>
              <Row className="detail-container">
                <Col>
                  <div>
                    <Tooltip
                      title={`For accessing Profiles that requires verification.`}
                      position="top"
                    >
                      <img src={infoIcon} className="info-icon" />
                    </Tooltip>
                    Step-up Verification
                    <img
                      className="status-icon"
                      src={
                        registrationStatus === mfaStatus.registered
                          ? checkIcon
                          : closeIcon
                      }
                    />
                  </div>
                </Col>
                <Col>
                  {registrationStatus === mfaStatus.registered &&
                    'Device Registered'}
                  {registrationStatus !== mfaStatus.registered && (
                    <Button
                      color="primary"
                      onClick={() => {
                        onRegister()
                        toggleRegistrationModal()
                      }}
                    >
                      Register
                    </Button>
                  )}
                </Col>
              </Row>
            </Container>
          </Row>
        </Col>
      </Row>
      {isRegistrationModalOpen && !mfaRegister.fetching && (
        <ModalPopup
          width={650}
          title={'Register your verification device'}
          buttons={[
            {
              text: 'Verify',
              variant: 'primary',
              size: 'large',
              disabled: mfaOtp?.length < 4 || !isValidOtp(),
              onClick: () => onVerify(mfaOtp),
            },
            {
              text: 'Cancel',
              variant: 'secondary',
              size: 'large',
              onClick: toggleAskConfirmationToCancel,
            },
          ]}
          onCancel={toggleAskConfirmationToCancel}
        >
          <div className="user-mfa-registration-modal">
            {mfaValidate.error?.length && (
              <>
                <Snackbar errorList={[mfaValidate.error]} />
                <br />
              </>
            )}
            <Typography variant="heading6">
              {`Please use any Authenticator app to register your device using the QR code shown.`}
            </Typography>
            <br />
            <div className="qr-code-container">
              <div data-testid="qr-code" className="qr-code">
                <QRCode value={mfaRegister.data?.additionalDetails?.uri} />
              </div>
              <br />
              <div
                className="no-qr-code-option"
                data-testid="no-qr-code-option"
                onClick={toggleAlphanumericCodeModal}
              >
                <span>Unable to scan the QR code?</span>
              </div>
              <br />
              <Textfield
                label={''}
                value={mfaOtp}
                onChange={e => setMfaOtp(e.target.value)}
                error={mfaOtp?.length ? !isValidOtp() : false}
                errorMsg={mfaOtp?.length && !isValidOtp() ? 'Invalid OTP' : ''}
              />
            </div>
            <br />
            <Typography variant="heading6">
              {`After completing the device registration, please enter the OTP displayed on the Authenticator app and click on the "Verify" button.`}
            </Typography>
          </div>
        </ModalPopup>
      )}
      {showAlphaNumericCode && (
        <DialogPopup
          type="general"
          title={'Please use the code below'}
          message={mfaRegister?.data?.additionalDetails?.key}
          primaryButtonText={'Done'}
          onSubmit={toggleAlphanumericCodeModal}
          onCancel={toggleAlphanumericCodeModal}
        />
      )}
      {askConfirmationToCancel && (
        <DialogPopup
          type="alert"
          title={'Are you sure?'}
          message={cancelRegistrationWarning}
          primaryButtonText={'Yes, Cancel'}
          secondaryButtonText={'No'}
          onCancel={toggleAskConfirmationToCancel}
          onSubmit={() => {
            onCancel()
            toggleAskConfirmationToCancel()
            toggleRegistrationModal()
            resetState()
          }}
        />
      )}
    </>
  )
}

export default RegisterVerificationDevice
