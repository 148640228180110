import React, { memo } from 'react'
import { connect } from 'react-redux'

import Groups from './Groups'

import { getSelectedApplicationRootId } from 'selectors/application_root'

function GroupsContainer({ selectedApp, appId, envId }) {
  const dropdownFilter = [
    {
      label: 'Scan Status',
      value: 'scanStatus',
      options: [
        { label: 'New', value: 'New' },
        { label: 'Updated', value: 'Updated' },
        { label: 'Deleted', value: 'Deleted' },
      ],
    },
  ]

  return (
    <Groups
      dropdownFilter={dropdownFilter}
      selectedAppId={appId}
      entityId={envId}
      selectedApp={selectedApp}
    />
  )
}

const mapStateToProps = state => {
  return {
    appId: getSelectedApplicationRootId(state),
  }
}

export default connect(mapStateToProps)(memo(GroupsContainer))
