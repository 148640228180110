import io from 'socket.io-client'
import Cookies from 'js-cookie'
import toast from 'utils/toast'
const { REACT_APP_API_ENDPOINT } = process.env

const getURL = () => Cookies.get('tenantUrl')

export const connectToSocket = ({ path, eventsCallback, onConnected }) => {
  const MAX_RECONNECTION_ATTEMPTS = 10
  const socket = io(getURL(), {
    transports: ['websocket'],
    secure: true,
    path: `${REACT_APP_API_ENDPOINT}${path}`,
    reconnection: true,
    reconnectionAttempts: MAX_RECONNECTION_ATTEMPTS,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
  })
  socket.on('connected', args => {
    onConnected(args)
    eventsCallback.forEach(({ event, callback }) => {
      socket.on(event, args => {
        callback(args)
      })
    })
    socket.on('reconnect_attempt', args => {
      if (args === MAX_RECONNECTION_ATTEMPTS) {
        toast({
          title: `Could not connect to websocket server. Please refresh and try again in some time.`,
          type: 'error',
          time: 'normal',
        })
      }
    })
  })
  return socket
}

export const disconnectFromSocket = socket => {
  socket?.disconnect()
}
