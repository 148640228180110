import React from 'react'
import { useDispatch } from 'react-redux'
import capitalize from 'lodash/capitalize'
import Linkify from 'linkify-react'
import Tooltip from 'britive-design-system/core/components/tooltip'
import { openSystemAnnouncement } from './action'
import { getMessageTypeImage, classes } from './constant'
import '../index.scss'

const Announcement = ({ systemAnnouncement }) => {
  const dispatch = useDispatch()
  const announcementBackground = `${systemAnnouncement?.data?.messageType}Background`
  const handleCloseAnnouncement = () => {
    dispatch(openSystemAnnouncement(false))
  }

  return (
    <>
      <div className={`announcement-container ${classes[announcementBackground]}`}>
        <div className="announcement-msg">
          <Tooltip
            title={capitalize(systemAnnouncement?.data?.messageType)}
            position="bottom"
          >
            <img src={getMessageTypeImage[systemAnnouncement?.data?.messageType]} />
          </Tooltip>
          <div>
            <Linkify
              options={{
                target: '_blank',
              }}
            >
              <div>{systemAnnouncement?.data?.message}</div>
            </Linkify>
          </div>
        </div>
        <span onClick={handleCloseAnnouncement}>
          <i className="fa fa-times close-announcement" />
        </span>
      </div>
    </>
  )
}

export default Announcement
