import queryString from 'query-string'
import camelCase from 'lodash/camelCase'

import {
  FETCH_FEATURE_FLAGS,
  TOGGLE_FEATURE_FLAG,
} from 'action_creators/feature_flag'

export const FEATURE_FLAGS = {
  tableComponentV2: 'tableComponentV2',
  registerPermissionsToggle: 'registerPermissionsToggle',
  activityMonitoring: 'activityMonitoring',
  dashboard: 'dashboard',
  ada: 'ada',
  secretmanager: 'secretmanager',
  asyncReports: 'asyncReports',
  firewallSettings: 'firewall-settings',
  serverAccess: 'serverAccess',
  oldInfra: 'oldInfra',
  cognitoLocalUsers: 'cognito_local_users',
  killSessionEnabled: 'kill-session-enabled',
}

/**
 * Reducer to track feature flag state.
 * The expected shape of actions
 * emitted from URL query params:
 *
 * dispatch({
 *  type: 'TOGGLE_FEATURE_FLAG',
 *  payload: {
 *    id: '<unique feature name>',
 *    enabled: <bool (required)>,
 *  }
 * }))
 *
 * Any dispatched action with type='TOGGLE_FEATURE_FLAG'
 * is saved to reducer state. The payload must be an object and must
 * contain both 'id' and 'enabled' keys. *
 *
 * Components that depend on features can connect
 * to the redux store and get feature flag data they
 * care about using the getFeatureFlagById selector
 * which will return a bool for if its enabled.
 * If there is no feature in state when getFeatureFlagById
 * is called, then false will be returned by default
 */

/**
 * Define feature flags.
 **/
let initialState = {
  fetchingFeatureFlags: true,
  fetchedFeatureFlags: false,
  featureFlags: {
    [FEATURE_FLAGS.tableComponentV2]: false,
    [FEATURE_FLAGS.registerPermissionsToggle]: true,
  },
}

/**
 * Enable features via query params, e.g., &FeatureName=true
 */

const WHITE_LIST = [
  FEATURE_FLAGS.tableComponentV2,
  FEATURE_FLAGS.registerPermissionsToggle,
]

const parsed = queryString.parse(window.location.search)

Object.keys(parsed).forEach(feature => {
  if (WHITE_LIST.includes(feature)) {
    initialState[feature].enabled = parsed[feature]
  }
})

/**
 * Feature flag reducer.
 */
export const featureFlagReducerKey = 'features'

export const featureFlagReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_FEATURE_FLAG: {
      const { id, enabled } = action.payload
      if (id && enabled !== undefined) {
        // Add feature flag to state (updates if already existing)
        return { ...state, [id]: !!enabled }
      } else {
        console.log('FEATURE action requires id and data fields in payload', action)
        return state
      }
    }

    case `${FETCH_FEATURE_FLAGS}_PENDING`: {
      return {
        ...state,
        fetchingFeatureFlags: true,
      }
    }

    case `${FETCH_FEATURE_FLAGS}_FULFILLED`: {
      const featureFlags = { ...state.featureFlags }

      action.payload.data.forEach(({ name, enabled }) => {
        featureFlags[camelCase(name)] = enabled
      })

      return {
        ...state,
        fetchingFeatureFlags: false,
        fetchedFeatureFlags: true,
        featureFlags,
      }
    }

    case `${FETCH_FEATURE_FLAGS}_REJECTED`: {
      return {
        ...state,
        fetchingFeatureFlags: false,
        fetchedFeatureFlags: true,
        error: action.payload,
      }
    }

    default:
      return state
  }
}
