import React, { useEffect, useState } from 'react'
import FontAwesome from 'react-fontawesome'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import get from 'lodash/get'
import cloneDeep from 'lodash/cloneDeep'
import {
  ActionIconGroup,
  ActionIconLabel,
} from 'britive-ui-components/core/components/ActionIcon'
import ModalPopup from 'britive-design-system/core/components/modal-popup'
import Tooltip from 'britive-design-system/core/components/tooltip'
import DialogPopup from 'britive-design-system/core/components/dialog'
import TableActionIcon from 'components/TableActionIcon'
import Table from 'components/table/TableV2/Table'
import Associations from 'components/Associations'
import TableEllipsisCell from 'components/table/TableV2/TableEllipsisCell'
import highRisk from 'static/images/high-risk.svg'
import { getSelectedApplicationRoot } from 'selectors/application_root'
import {
  getApplicationEnvironments,
  getSelectedApplicationEnvironmentGroups,
  getSelectedApplicationRootGroupId,
  getSelectedApplicationEnvironments,
} from 'selectors/environment'
import toast from 'utils/toast'
import doFetch from 'utils/do_fetch'

import Paps from '../Profiles/PapsContainer'
import warningIcon from 'static/images/check-yellow.svg'
import { isEmpty } from 'lodash'
import ScanDetailsDrawer from '../Scanning/ScanDetails/ScanDetailsDrawer'

const SearchIconWrapper = styled.span`
  ${({ isDisabled }) =>
    isDisabled
      ? `opacity: 0.6;
  cursor: not-allowed;
  `
      : ''}
  color: #067fdb;
`

const ManagedPermissions = ({ setBreadcrumbTrail, app }) => {
  const [selectedEntity, setSelectedEntity] = useState(null)
  const [permissionToBeDeleted, setPermissionToBeDeleted] = useState(null)
  const [deletingPermission, setDeletingPermission] = useState(false)
  const [refresh, setRefresh] = useState(0)
  const history = useHistory()
  const [scanDetailsDrawerOpened, setScanDetailsDrawerOpened] = useState(false)
  const [selectedPermission, setSelectedPermission] = useState(null)

  const { appContainerId, catalogAppDisplayName } = app
  useEffect(() => {
    setBreadcrumbTrail([{ title: `${catalogAppDisplayName} Managed Permissions` }])
  }, [])

  const environmentData = useSelector(state =>
    getSelectedApplicationEnvironments(state)
  )
  const environments = useSelector(state => getApplicationEnvironments(state))
  const applicationRoot = useSelector(state => getSelectedApplicationRoot(state))
  const rootGroupId = useSelector(state => getSelectedApplicationRootGroupId(state))
  const environmentGroups = useSelector(state =>
    getSelectedApplicationEnvironmentGroups(state)
  )

  const getRootNodeName = () => {
    let rootName = get(applicationRoot, 'catalogAppDisplayName')

    if (get(applicationRoot, 'catalogApplication.requiresHierarchicalModel')) {
      rootName = get(environmentGroups, [rootGroupId], {}).name || rootName
    }

    return rootName
  }

  const getEnvironmentData = () => {
    let provisionedEnvironmentData = cloneDeep(environmentData)
    selectedEntity.entity.provisionedEnvironments?.forEach(element => {
      provisionedEnvironmentData[element].provisioned = true
    })

    return provisionedEnvironmentData
  }

  const deletePermission = async () => {
    try {
      setDeletingPermission(true)
      await doFetch({
        path: `/apps/${appContainerId}/britive-managed/permissions/${permissionToBeDeleted.id}`,
        method: 'delete',
      })
      setSelectedEntity(null)
      setPermissionToBeDeleted(null)
      toast({
        title: 'Permission deleted successfully!',
        type: 'success',
        time: 'normal',
      })
      setRefresh(refresh + 1)
    } catch (error) {
      toast({
        title: 'Permission could not be deleted! Please try again.',
        type: 'error',
        time: 'normal',
      })
    } finally {
      setDeletingPermission(false)
    }
  }

  const renderActionIcons = ({ original: { profileCount, id, name } }) => {
    return (
      <ActionIconGroup>
        <TableActionIcon
          isDisabled={profileCount > 0}
          onClick={() => {
            setPermissionToBeDeleted({
              id,
              name,
            })
          }}
        >
          <span className="fa fa-trash fs:14" />
          <ActionIconLabel>Delete</ActionIconLabel>
        </TableActionIcon>
        <TableActionIcon
          onClick={() =>
            history.push(
              `/admin/applications/${appContainerId}/managed-permissions/${id}/details`
            )
          }
        >
          <FontAwesome name="sliders-h" size="lg" />
          <ActionIconLabel>Manage</ActionIconLabel>
        </TableActionIcon>
      </ActionIconGroup>
    )
  }

  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
      sortable: true,
      minWidth: 200,
      Cell: ({ original: { name, isPrivileged } }) => (
        <>
          <TableEllipsisCell>{name}</TableEllipsisCell>
          &nbsp;&nbsp;
          {isPrivileged && (
            <Tooltip title="High Risk" position="left">
              <span>
                <img src={highRisk} style={{ height: '18px' }} />
              </span>
            </Tooltip>
          )}
        </>
      ),
    },
    {
      Header: 'Description',
      accessor: 'description',
      sortable: false,
      minWidth: 360,
    },
    {
      Header: 'Profile Count',
      accessor: 'profileCount',
      sortable: false,
      minWidth: 100,
      Cell: ({ original: { profileCount, name } }) => (
        <ActionIconGroup>
          <TableActionIcon
            id="appEnvironmentCount"
            isDisabled={profileCount === 0}
            onClick={() =>
              setSelectedEntity({
                name: 'Profiles',
                entity: {
                  name,
                },
              })
            }
          >
            <Tooltip
              title={profileCount === 0 ? 'No profiles' : 'View profiles'}
              position="left"
            >
              <SearchIconWrapper isDisabled={profileCount === 0}>
                <FontAwesome name="search" />
                &nbsp;
                {profileCount}
              </SearchIconWrapper>
            </Tooltip>
          </TableActionIcon>
        </ActionIconGroup>
      ),
    },
    {
      Header: 'Association Count',
      accessor: 'associatedEnvironments',
      minWidth: 120,
      sortable: false,
      Cell: ({
        original: {
          associatedEnvironments,
          name,
          provisionedEnvironments,
          outOfSyncEnvironments,
        },
      }) => (
        <ActionIconGroup>
          <TableActionIcon
            id="appEnvironmentCount"
            isDisabled={associatedEnvironments?.length === 0}
            onClick={() =>
              setSelectedEntity({
                name: 'Associations',
                entity: {
                  name,
                  environments: associatedEnvironments,
                  provisionedEnvironments,
                  outOfSyncEnvironments: outOfSyncEnvironments,
                },
              })
            }
          >
            <Tooltip
              title={
                associatedEnvironments?.length === 0
                  ? 'No assosiations'
                  : 'View associations'
              }
              position="left"
            >
              <SearchIconWrapper isDisabled={associatedEnvironments?.length === 0}>
                <FontAwesome name="search" />
                &nbsp;
                {associatedEnvironments?.length}
              </SearchIconWrapper>
            </Tooltip>
          </TableActionIcon>
          {!isEmpty(outOfSyncEnvironments) && (
            <TableActionIcon>
              <Tooltip title={'Permission mismatch'} position="left">
                <img src={warningIcon} />
              </Tooltip>
            </TableActionIcon>
          )}
        </ActionIconGroup>
      ),
    },
    {
      Header: 'Actions',
      sortable: false,
      resizable: false,
      minWidth: 120,
      Cell: renderActionIcons,
    },
  ]

  const membersModalActionButtons = [
    {
      text: 'Close',
      variant: 'secondary',
      onClick: () => {
        setSelectedEntity(null)
      },
      size: 'medium',
    },
  ]

  const clickHandler = environmentId => {
    setSelectedPermission({
      hasDefinitionsChanged: true,
      appPermissionId: selectedEntity.entity.name,
      environmentId: environmentId,
      name: selectedEntity.entity.name,
    })
    setScanDetailsDrawerOpened(true)
  }

  return (
    <>
      <Table
        columns={columns}
        identifier="appPermissionId"
        dataUrl={`/apps/${appContainerId}/britive-managed/permissions`}
        emptyTableMessage={'No Permissions were found.'}
        margin={40}
        refresh={refresh}
      />
      {selectedEntity && (
        <ModalPopup
          width={900}
          title={`${selectedEntity?.name} of ${selectedEntity.entity.name}`}
          buttons={membersModalActionButtons}
          onCancel={() => {
            setSelectedEntity(null)
          }}
        >
          {selectedEntity?.name === 'Associations' && (
            <Associations
              environmentGroups={environmentGroups}
              environments={environments}
              environmentData={getEnvironmentData()}
              rightIconConfig={{
                property: 'provisioned',
                tooltip: 'Provisioned',
                onClick: environmentId => clickHandler(environmentId),
              }}
              defaultDirectory={applicationRoot?.appContainerId}
              applicationRoot={{
                id: rootGroupId,
                name: getRootNodeName(),
                appIcon: applicationRoot?.iconUrl,
              }}
              checkedNodes={selectedEntity.entity.environments}
              outOfSyncEnvironments={selectedEntity?.entity?.outOfSyncEnvironments}
              hideCheckboxes
              hideDropdownFilter
              hideUnselectedAssociationType
              appName={catalogAppDisplayName}
            />
          )}
          {selectedEntity?.name === 'Profiles' && (
            <Paps selectedPermission={selectedEntity.entity.name} />
          )}
        </ModalPopup>
      )}
      {permissionToBeDeleted && (
        <DialogPopup
          type="alert"
          title={`Delete ${permissionToBeDeleted.name}?`}
          message="Are you sure you want to delete this permission?"
          primaryButtonText="Yes, Delete"
          secondaryButtonText="No"
          onSubmit={deletePermission}
          onCancel={() => setPermissionToBeDeleted(null)}
          primaryButtonDisabled={deletingPermission}
        />
      )}
      <ScanDetailsDrawer
        isOpen={selectedPermission && scanDetailsDrawerOpened}
        toggleDrawer={() => setScanDetailsDrawerOpened(!scanDetailsDrawerOpened)}
        data={selectedPermission}
        selectedAppId={appContainerId}
        entityId={selectedPermission?.environmentId}
      />
    </>
  )
}

export default ManagedPermissions
