import { fetchIfLoggedIn } from '../utils.js'
import * as actions from 'actions'

export const getIdentityProviders = () => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.GET_IDENTITY_PROVIDERS,
      method: 'get',
      path: '/identity-providers',
    })
  )
}

export const getOIDCIdentityProviders = () => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.GET_OIDC_IDENTITY_PROVIDERS,
      method: 'get',
      path: '/workload/identity-providers',
    })
  )
}

export const getIdentityProvider = (id, isWorkloadProvider = false) => dispatch => {
  const path = isWorkloadProvider
    ? `/workload/identity-providers/${id}`
    : `/identity-providers/${id}`
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.GET_IDENTITY_PROVIDER,
      method: 'get',
      path,
    })
  )
}

export const uploadSAMLMetadata = (id, formData) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.UPLOAD_SAML_METADATA,
      method: 'patch',
      path: `/identity-providers/${id}/saml-metadata`,
      postBody: formData,
    })
  )
}

export const getSCIMProviders = () => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.GET_SCIM_PROVIDERS,
      method: 'get',
      path: '/identity-providers/scim-providers',
    })
  )
}

export const getSSOProviders = () => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.GET_SSO_PROVIDERS,
      method: 'get',
      path: '/identity-providers/sso-providers',
    })
  )
}

export const createIdentityProvider = (
  fields,
  isWorkloadProvider = false
) => dispatch => {
  const postBody = {
    ...fields,
  }
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.ADD_IDENTITY_PROVIDER,
      method: 'post',
      path: isWorkloadProvider
        ? '/workload/identity-providers'
        : '/identity-providers',
      postBody,
    })
  )
}

export const updateIdentityProvider = (
  fields,
  id,
  workloadProvider = null
) => dispatch => {
  const postBody = { ...fields }
  let path = `/identity-providers/${id}`

  if (workloadProvider) {
    path = '/workload/identity-providers'
    postBody.idpType = workloadProvider
  }

  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.UPDATE_IDENTITY_PROVIDER,
      method: workloadProvider ? 'put' : 'patch',
      path,
      postBody,
    })
  )
}

export const updateIdentityProviderMFA = (id, values) => dispatch => {
  const postBody = values
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.UPDATE_IDENTITY_PROVIDER,
      method: 'patch',
      path: `/identity-providers/${id}/mfa`,
      postBody,
    })
  )
}
export const updateIdentityProviderRootMFA = (id, mfaEnabled) => dispatch => {
  const postBody = { mfaEnabled }
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.UPDATE_IDENTITY_PROVIDER,
      method: 'patch',
      path: `/identity-providers/${id}/mfa/root-user`,
      postBody,
    })
  )
}

export const deleteIdentityProvider = (id, isWorkloadProvider) => dispatch => {
  let path = `/identity-providers/${id}`

  if (isWorkloadProvider) {
    path = `/workload/identity-providers/${id}`
  }

  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.DELETE_IDENTITY_PROVIDER,
      meta: { id, isWorkloadProvider },
      method: 'delete',
      path,
    })
  )
}

export const downloadSigningCertificate = () => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.DOWNLOAD_SIGNING_CERTIFICATE,
      method: 'get',
      path: `/identity-providers/signing-certificate`,
    })
  )
}

export const createSCIMToken = (id, tokenExpirationDays) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.CREATE_SCIM_TOKEN,
      method: 'post',
      path: `/identity-providers/${id}/scim-token`,
      postBody: { tokenExpirationDays },
    })
  )
}

export const updateSCIMToken = (id, tokenExpirationDays) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.UPDATE_SCIM_TOKEN,
      method: 'patch',
      path: `/identity-providers/${id}/scim-token`,
      postBody: { tokenExpirationDays },
    })
  )
}

export const fetchSCIMToken = id => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.UPDATE_SCIM_TOKEN,
      method: 'get',
      path: `/identity-providers/${id}/scim-token`,
    })
  )
}

export const revokeSCIMToken = id => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.REVOKE_SCIM_TOKEN,
      method: 'delete',
      path: `/identity-providers/${id}/scim-token`,
    })
  )
}

export const updateScimAttributeMappings = (
  scimAttributeMappings,
  id
) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.UPDATE_SCIM_USER_MAPPING,
      method: 'patch',
      path: `/identity-providers/${id}/scim-attribute-mappings`,
      postBody: scimAttributeMappings,
    })
  )
}

export const getSCIMAttributes = () => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.GET_SCIM_ATTRIBUTES,
      method: 'get',
      path: '/identity-providers/scim-attributes',
    })
  )
}
