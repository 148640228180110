import React, { memo, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'

import Paps from './Paps'

import useCheckboxState from 'hooks/useCheckboxState'
import useMaxTableHeight from 'hooks/useMaxTableHeight'

import { selectApplicationRootId } from 'action_creators/application_root'
import { selectPap } from 'routes/admin/state/actions'

import { getSelectedApplicationRoot } from 'selectors/application_root'
import {
  getSelectedApplicationRootGroupId,
  getSelectedApplicationEnvironments,
} from 'selectors/environment'
import { cachePapPolicyData } from 'action_creators/papPolicy'

import * as routing from 'services/routing'

const DATA_IDENTIFIER = 'papId'

function PapsContainer({
  appHeaderHeight,
  match,
  fetching,
  fetchingEnvironments,
  dispatch,
  selectedApplicationRoot: selectedApp,
  selectedEnvironmentData,
  selectedApplicationRootGroupId,
  setBreadcrumbTrail,
  thisAppManage,
  requestAccess,
  appContainerId,
  selectedPermission,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [papId, setPapId] = useState(null)
  const [dataList, setDataList] = useState([])
  const [currentActionMode, setCurrentActionMode] = useState(null)

  const maxTableHeight = useMaxTableHeight({
    heights: [appHeaderHeight, 8, 36],
    minHeight: 100,
  })

  const {
    toggleAllCheckboxes,
    toggleCheckbox,
    checkItemsStatus,
    checkedItems,
    clearCheckedItems,
    setDataOverride,
  } = useCheckboxState(DATA_IDENTIFIER, dataList)

  useEffect(() => {
    async function setupData() {
      const appId = appContainerId || match?.params.appId

      if (appId) {
        await dispatch(selectApplicationRootId(appId))
      }
    }

    setupData()
  }, [match?.params.appId, appContainerId])

  useEffect(() => {
    const { catalogAppDisplayName: appName } = selectedApp
    setBreadcrumbTrail && setBreadcrumbTrail([{ title: `${appName} Profiles` }])
  }, [])

  useEffect(() => {
    /**
     * Invalidating the Policy data cache everytime the Profile tab is loaded.
     * This is done to fix PAB-13016
     */
    dispatch(cachePapPolicyData(null))
  }, [])

  const toggleDrawer = data => {
    if (!isDrawerOpen) {
      setIsDrawerOpen(true)
      setPapId(data)
    } else {
      setIsDrawerOpen(false)
    }
  }

  const dropdownFilters = [
    {
      value: requestAccess ? 'papStatus' : 'status',
      label: 'Status',
      options: [
        { label: 'Active', value: 'active' },
        requestAccess
          ? { label: 'Draft', value: 'draft' }
          : { label: 'Inactive', value: 'inactive' },
      ],
    },
  ]

  if (!requestAccess) {
    dropdownFilters.push({
      value: 'origin',
      label: 'Origin',
      options: [
        { label: 'Admin', value: 'Admin' },
        { label: 'User', value: 'User' },
      ],
    })
  }

  const selectActionMode = currentActionMode => {
    setCurrentActionMode(currentActionMode)
  }

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  const showDetails = (selectedPap, state, mode) => {
    const appId = match.params.appId
    const { papId } = selectedPap

    if (papId) {
      dispatch(selectPap(papId))
      if (requestAccess) {
        routing.requestAccessProfileDetails({
          appId,
          profileId: papId,
          tab: mode === 'request' ? 'policies' : 'profile',
          mode: mode,
        })
      } else {
        routing.profileDetails({ appId, profileId: papId, tab: 'profile', state })
      }
    }
  }

  const goToCreatePapPage = () => {
    const { appContainerId: appId } = selectedApp

    if (requestAccess) {
      routing.requestAccessCreateProfile({ appId })
    } else {
      routing.createProfile({ appId })
    }
  }

  return (
    <Paps
      app={selectedApp}
      dataList={dataList}
      setDataList={setDataList}
      areAllPapsSelected={checkItemsStatus('every')}
      areAnyPapsSelected={checkItemsStatus('some')}
      checkedPaps={checkedItems}
      clearCheckedItems={clearCheckedItems}
      currentActionMode={currentActionMode}
      dataIdentifier={DATA_IDENTIFIER}
      environmentData={selectedEnvironmentData}
      fetching={fetching}
      fetchingEnvironments={fetchingEnvironments}
      dropdownFilters={dropdownFilters}
      goToCreatePapPage={goToCreatePapPage}
      isDrawerOpen={isDrawerOpen}
      isModalOpen={isModalOpen}
      maxHeight={maxTableHeight}
      papId={papId}
      rootGroupId={selectedApplicationRootGroupId}
      selectActionMode={selectActionMode}
      setDataOverride={setDataOverride}
      showDetails={showDetails}
      toggleAllPapCheckboxes={toggleAllCheckboxes}
      toggleDrawer={toggleDrawer}
      toggleModal={toggleModal}
      togglePapCheckbox={toggleCheckbox}
      thisAppManage={thisAppManage}
      requestAccess={requestAccess}
      selectedPermission={selectedPermission}
      match={match}
    />
  )
}

const mapStateToProps = state => {
  return {
    fetchingEnvironments: state.environment.fetchingEnvironments,
    selectedApplicationRoot: getSelectedApplicationRoot(state),
    selectedApplicationRootGroupId: getSelectedApplicationRootGroupId(state),
    selectedEnvironmentData: getSelectedApplicationEnvironments(state),
  }
}

const withConnect = connect(mapStateToProps)

export default compose(withConnect)(memo(PapsContainer))
