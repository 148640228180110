import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from 'britive-design-system/core/components/spinner'
import Typography from 'britive-design-system/core/components/typography'
import DataTable from 'secret-manager-ui/core/components/data-table/DataTable'
import Tooltip from 'britive-design-system/core/components/tooltip'
import highRisk from 'static/images/high-risk.svg'
import infoIcon from 'static/images/infoIcon.svg'
import arrowLeft from 'static/images/arrow-left.svg'
import ModalPopup from 'britive-design-system/core/components/modal-popup'
import { PermissionDefinition } from '../../Profiles/components/permission-drawer/PermissionDefinition'
import { getPermissionsDetails } from 'action_creators/managed_role'
import { classes } from './constants'

import './index.scss'

const PermissionsDetails = ({ appId, permissionId }) => {
  const dispatch = useDispatch()
  const profileRequest =
    window?.location?.pathname?.includes('/request-access') ||
    window?.location?.pathname?.includes('/my-requests') ||
    window?.location?.pathname?.includes('/my-approvals')

  const { fetchingPermissionsDetails, permissionsDetails = {} } = useSelector(
    state => state?.managedRoleReducer
  )

  const [isPolicyDetailModalOpen, setIsPolicyDetailsModalOpen] = useState({
    isOpen: false,
    permissionDefinition: '',
    name: '',
    isPrivileged: false,
    type: '',
    description: '',
  })

  useEffect(() => {
    dispatch(getPermissionsDetails(appId, permissionId, profileRequest))
  }, [])

  const renderHighRiskIcon = () => (
    <div>
      <Tooltip title="High Risk" position="left">
        <span title="">
          <img src={highRisk} style={{ height: '18px' }} />
        </span>
      </Tooltip>
    </div>
  )
  const columns = [
    {
      headerName: 'Policies',
      field: 'name',
      width: 60,
      renderColumn: ({ name, isPrivileged }) => {
        return (
          <div className={classes.iconContainer}>
            {name}
            &nbsp;
            {isPrivileged && renderHighRiskIcon()}
          </div>
        )
      },
    },
    {
      headerName: 'Type',
      field: 'type',
      width: 30,
      renderColumn: ({ type }) =>
        type?.toLowerCase()?.includes('inline') ? 'Inline Policy' : 'Policy',
    },
    {
      headerName: 'Action',
      width: 10,
      renderColumn: ({
        permissionDefinition,
        name,
        isPrivileged,
        type,
        description,
      }) => {
        return (
          <>
            <div>
              <Tooltip title="View Details" position="left">
                <span
                  onClick={() =>
                    setIsPolicyDetailsModalOpen({
                      isOpen: true,
                      permissionDefinition: permissionDefinition,
                      name: name,
                      isPrivileged: isPrivileged,
                      type: type,
                      description: description,
                    })
                  }
                  data-testid={`info-${name}`}
                >
                  <img src={infoIcon} style={{ height: '21px' }} />
                </span>
              </Tooltip>
            </div>
          </>
        )
      },
    },
  ]

  const tagsColumns = [
    {
      headerName: 'Key',
      field: 'key',
      width: 60,
    },
    {
      headerName: 'Value',
      field: 'value',
      width: 40,
    },
  ]

  const closeModal = () => {
    // resetting everything to initial state
    setIsPolicyDetailsModalOpen({
      isOpen: false,
      permissionDefinition: '',
      name: '',
      isPrivileged: false,
      type: '',
      description: '',
    })
  }

  const modalButtons = [
    {
      text: <img src={arrowLeft} style={{ height: '18px' }} />,
      variant: 'secondary',
      onClick: closeModal,
      size: 'medium',
    },
    {
      text: 'Close',
      variant: 'secondary',
      onClick: closeModal,
      size: 'medium',
    },
  ]

  return (
    <div className={classes.mainContainer}>
      {fetchingPermissionsDetails && (
        <Spinner size={'medium'} message={'Loading...'} overlay />
      )}

      <div className={classes.outerLabel}>
        <Typography variant="label2"> Name </Typography>
        <div className={classes.innerLabel}>
          <Typography variant="label1">
            {permissionsDetails?.name || 'None'}
          </Typography>
        </div>
      </div>

      <div className={classes.outerLabel}>
        <Typography variant="label2"> Description </Typography>
        <div className={classes.innerLabel}>
          <Typography variant="label1">
            {permissionsDetails?.description || 'None'}
          </Typography>
        </div>
      </div>

      <div className={classes.outerLabel}>
        <Typography variant="label2"> Permissions </Typography>
        <div className={classes.innerLabel}>
          <div className={classes.tableContainer}>
            <DataTable
              rows={permissionsDetails?.childPermissions}
              columns={columns}
            />
          </div>
        </div>
      </div>

      <div className={classes.outerLabel}>
        <Typography variant="label2"> Tags </Typography>
        <div className={classes.innerLabel}>
          <div className={classes.tableContainer}>
            <DataTable rows={permissionsDetails?.tags} columns={tagsColumns} />
          </div>
        </div>
      </div>

      {isPolicyDetailModalOpen.isOpen && (
        <ModalPopup
          width={790}
          title={`Policy: ${isPolicyDetailModalOpen.name}`}
          onCancel={closeModal}
          buttons={modalButtons}
        >
          <PermissionDefinition permission={isPolicyDetailModalOpen} />
        </ModalPopup>
      )}
    </div>
  )
}

export default PermissionsDetails
