import React, { Fragment, useEffect, useState } from 'react'
import styled from 'styled-components'
import Spinner from 'britive-ui-components/core/components/Spinner'

import { preFetch } from 'utils/do_fetch'

const User = styled.li`
  padding: 5px;
  margin-left: -20px;
  list-style: none;

  &:before {
    content: '\f007';
    font-family: 'Font Awesome 5 Free';
    display: inline-block;
    margin-left: -1.5em;
    width: 1.5em;
    font-size: 12px;
    font-weight: 900;
    color: var(--medium-purple);
  }
`

const Tags = styled.li`
  padding: 5px;
  margin-left: -20px;
  list-style: none;

  &:before {
    content: '\f02b';
    font-family: 'Font Awesome 5 Free';
    display: inline-block;
    margin-left: -1.5em;
    width: 1.5em;
    font-size: 12px;
    font-weight: 900;
    color: var(--royal-blue);
  }
`

const Heading = styled.h6`
  border-bottom: 1px solid var(--brand);
  padding: 5px 0;
  margin-right: 10px;
`

const Icon = styled.i`
  color: var(--royal-blue);
`

export default ({ papId, requiresAccountMapping }) => {
  const [fetchingTagsUnmappedUsers, setFetchingTagsUnmappedUsers] = useState(false)
  const [fetchingTagsNoUser, setFetchingTagsNoUser] = useState(false)
  const [tagsUnmappedUsers, setTagsUnmappedUsers] = useState(null)
  const [tagsNoUser, setTagsNoUser] = useState(null)
  const [errorTagsUnmappedUsers, setErrorTagsUnmappedUsers] = useState(null)
  const [errorTagsNoUser, setErrorTagsNoUser] = useState(null)
  useEffect(() => {
    const fetchTags = async () => {
      if (papId) {
        setFetchingTagsUnmappedUsers(true)
        try {
          const response = await preFetch({
            path: `/paps/${papId}/tags/checks`,
            method: 'get',
          })
          setTagsUnmappedUsers(
            response.data.filter(
              d => d.checks.length && d.checks.some(c => c.checkStatus !== 'Success')
            )
          )
        } catch (error) {
          setErrorTagsUnmappedUsers(error.response.data.message)
        } finally {
          setFetchingTagsUnmappedUsers(false)
        }
      }
    }
    const fetchUserTags = async () => {
      if (papId) {
        setFetchingTagsNoUser(true)
        try {
          const response = await preFetch({
            path: `/paps/${papId}/user-tags`,
            method: 'get',
          })
          setTagsNoUser(response.data.filter(d => d.userCount === 0))
        } catch (error) {
          setErrorTagsNoUser(error.response.data.message)
        } finally {
          setFetchingTagsNoUser(false)
        }
      }
    }
    requiresAccountMapping && fetchTags()
    fetchUserTags()
  }, [papId])
  return (
    <Fragment>
      {requiresAccountMapping &&
        (errorTagsUnmappedUsers ? (
          errorTagsUnmappedUsers
        ) : (
          <Fragment>
            <Heading style={{ marginTop: 10, marginBottom: 10 }}>
              <Icon className="fas fa-tags" /> Tags containing users without mapped
              application accounts
            </Heading>
            {fetchingTagsUnmappedUsers || !tagsUnmappedUsers ? (
              <Spinner />
            ) : (
              <Fragment>
                {tagsUnmappedUsers.length ? (
                  <ul>
                    {tagsUnmappedUsers.map(tag => (
                      <Fragment key={tag.userTagName}>
                        <Tags>{tag.userTagName}</Tags>
                        <ul>
                          {tag.checks.map(
                            user =>
                              user.checkStatus !== 'Success' && (
                                <User key={user.username}>
                                  {user.firstName} {user.lastName} ({user.username})
                                </User>
                              )
                          )}
                        </ul>
                      </Fragment>
                    ))}{' '}
                  </ul>
                ) : (
                  'No tags found'
                )}
              </Fragment>
            )}
          </Fragment>
        ))}
      {errorTagsNoUser ? (
        errorTagsNoUser
      ) : (
        <Fragment>
          <Heading style={{ marginTop: 10 }}>
            <Icon className="fas fa-tags" /> Tags without users
          </Heading>
          {fetchingTagsNoUser || !tagsNoUser ? (
            <Spinner />
          ) : (
            <Fragment>
              {tagsNoUser.length ? (
                <ul>
                  {tagsNoUser.map(tag => (
                    <Fragment key={tag.name}>
                      <Tags>{tag.name}</Tags>
                    </Fragment>
                  ))}{' '}
                </ul>
              ) : (
                'No tags found'
              )}
            </Fragment>
          )}
        </Fragment>
      )}
    </Fragment>
  )
}
