import { useState } from 'react'

// Hook
export default function useContainsSearchInput() {
  const [selfContains, setSelfContains] = useState(false)
  const [childrenContain, setChildrenContain] = useState(false)

  const calcContainsSearchInput = ({ searchTagDict, searchInput, id, name }) => {
    const currentSearchTags = searchTagDict[id]
    let selfContains = true
    let childrenContain = true

    if (currentSearchTags) {
      selfContains = false
      childrenContain = false

      if (name.includes(searchInput)) {
        selfContains = true
      }

      currentSearchTags.forEach(tag => {
        if (tag && tag.toLowerCase().includes(searchInput)) {
          childrenContain = true
        }
      })
    }

    setSelfContains(selfContains)
    setChildrenContain(childrenContain)
  }

  return {
    selfContains,
    childrenContain,
    calcContainsSearchInput,
  }
}
