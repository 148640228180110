import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import admin from './admin'
import account from './account'
import config from './config'
import user from './user'
import userMfa from './user/userMfa'
import userApps from './user_apps'
import userAppPapsV1 from './user_app_paps_v1'
import userAppPaps from './user_app_paps'
import navigation from './navigation'
import { auditLogReducerKey, auditLogReducer } from './audit_log'
import { auditLogWebhookReducer } from './manage_webhooks'
import { activeSessions } from './active-sessions/session_users'
import { environmentReducerKey, environmentReducer } from './environment'
import { elementRefReducerKey, elementRefReducer } from './element_ref'
import { messageModalReducerKey, messageModalReducer } from './message_modal'
import { notificationReducerKey, notificationReducer } from './notification'
import { scheduledTaskReducerKey, scheduledTaskReducer } from './scheduled_task'
import { papsReducerKey, papsReducer } from './pap'
import { reportReducerKey, reportReducer } from './report'
import { featureFlagReducerKey, featureFlagReducer } from './feature_flag'
import { privilegesReducerKey, privilegesReducer } from './privileges'
import { accessKeyReducerKey, accessKeyReducer } from './access_key'
import { apiKeyReducerKey, apiKeyReducer } from './api_key'
import { activityReducerKey, activityReducer } from './activity'
import { papPoliciesReducerKey, papPoliciesReducer } from './papPolicy'
import userSecretsReducer from '../routes/my-requests-my-approvals/myApprovalsRequests-reducer'
import { systemAnnouncementReducer } from '../components/app_chrome/header/announcement/reducer'
import serverAccess from './serverAccess'
import mfaSettings from './mfa-settings'
import {
  samlConfigurationReducerKey,
  samlConfigurationReducer,
} from './saml_configuration'
import {
  permissionsConstraintsReducerKey,
  permissionsConstraintsReducer,
} from './permissions_constraints'

import { tagsReducerKey, tagsReducer } from './tag'
import {
  identityProvidersReducerKey,
  identityProvidersReducer,
} from './identity_providers'

import {
  customAttributesReducerKey,
  customAttributesReducer,
} from './custom_attributes'

import {
  pushNotificationReducerKey,
  pushNotificationReducer,
} from './pushNotification'

import { filterKey, filterReducer } from './filter'
import { filterCountKey, filterCountReducer } from './filtercount'
import { dropdownKey, dropdownReducer } from './dropdown'

// ui reducers
import { adminUIReducerKey, adminUIReducer } from 'routes/admin/state'
import accessRequestReducer from './access-request'
import notificationMediumsReducer from './notification-mediums'
import managedRoleReducer from './managed_role'

import { LOGOUT_USER } from 'action_creators/account'
import * as actions from 'actions'

import {
  applicationRootReducerKey,
  applicationRootReducer,
} from './application_root'

import { clearAuth } from 'utils/authentication'
import ipRestrictionsReducer from './ip_restriction'

const createRootReducer = history => {
  return combineReducers({
    router: connectRouter(history),
    account,
    // this admin reducer has ui state and data all mixed together
    admin,
    config,
    navigation,
    user,
    userMfa,
    userAppPapsV1,
    userAppPaps,
    userApps,
    userSecretsReducer,
    [permissionsConstraintsReducerKey]: permissionsConstraintsReducer,
    [accessKeyReducerKey]: accessKeyReducer,
    [activityReducerKey]: activityReducer,
    // TODO: testing out ui reducers. may need to remove
    [adminUIReducerKey]: adminUIReducer,
    [apiKeyReducerKey]: apiKeyReducer,
    [applicationRootReducerKey]: applicationRootReducer,
    [auditLogReducerKey]: auditLogReducer,
    [elementRefReducerKey]: elementRefReducer,
    [messageModalReducerKey]: messageModalReducer,
    [environmentReducerKey]: environmentReducer,
    [featureFlagReducerKey]: featureFlagReducer,
    [privilegesReducerKey]: privilegesReducer,
    [notificationReducerKey]: notificationReducer,
    [scheduledTaskReducerKey]: scheduledTaskReducer,
    [papsReducerKey]: papsReducer,
    [reportReducerKey]: reportReducer,
    [samlConfigurationReducerKey]: samlConfigurationReducer,
    [tagsReducerKey]: tagsReducer,
    [identityProvidersReducerKey]: identityProvidersReducer,
    [customAttributesReducerKey]: customAttributesReducer,
    [pushNotificationReducerKey]: pushNotificationReducer,
    [papPoliciesReducerKey]: papPoliciesReducer,
    [filterKey]: filterReducer,
    [dropdownKey]: dropdownReducer,
    [filterCountKey]: filterCountReducer,
    accessRequestReducer,
    auditLogWebhookReducer,
    activeSessions,
    notificationMediumsReducer,
    managedRoleReducer,
    systemAnnouncementReducer,
    mfaSettings,
    ipRestrictionsReducer,
    serverAccess,
  })
}

const rootReducer = history => (state, action) => {
  switch (action.type) {
    case `${LOGOUT_USER}_REJECTED`:
    case `${LOGOUT_USER}_FULFILLED`:
    case `${actions.NOT_AUTHENTICATED}`:
      clearAuth()
      Object.values(state.userAppPapsV1.timeoutToast).map(timeout =>
        clearTimeout(timeout)
      )
      Object.values(state.userAppPaps.timeoutToast).map(timeout =>
        clearTimeout(timeout)
      )
      state = undefined
      break
    default: {
      break
    }
  }

  return createRootReducer(history)(state, action)
}

export default rootReducer
