import React, { useState } from 'react'
import PropTypes from 'prop-types'
import startCase from 'lodash/startCase'
import isEmpty from 'lodash/isEmpty'

import Table from 'britive-design-system/core/components/table'
import Button from 'britive-design-system/core/components/button'
import Tooltip from 'britive-design-system/core/components/tooltip'
import Typography from 'britive-design-system/core/components/typography'
import NotificationMediumSelector from 'components/NotificationMediumsSelector/NotificationMediumModal'

import deleteIcon from 'static/images/delete-icon.svg'
import edit from 'static/images/edit-icon.svg'
import './index.scss'
import useTableSort from 'hooks/useTableSort'
import classNames from 'classnames'
import deleteIconDisabled from 'static/images/delete-icon-disabled.svg'
import { isSlack } from './utils'

const NotificationMediums = ({
  notificationMediums = [],
  onAdd,
  onDelete,
  isEditMode = true,
  noDataText = null,
  disableEditing = false,
  disabled,
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [error, setError] = useState(null)
  const [currentEditingMedium, setCurrentEditingMedium] = useState({})

  const NotificationTable = () => {
    const [sortHandler, getSortedData] = useTableSort()

    const tableProps = {
      rows: getSortedData(notificationMediums),
      sortHandler,
      resizableColumns: true,
      emptyTableMessage: 'No Notification Medium Added',
      columns: [
        {
          field: 'name',
          width: '240px',
          headerName: 'Name',
          sortable: true,
        },
        {
          field: 'description',
          headerName: 'Description',
          width: '240px',
          sortable: true,
        },
        {
          field: 'application',
          width: '200px',
          headerName: 'Application',
          sortable: true,
          renderColumn: row => startCase(row.application),
        },
        {
          field: 'channels',
          width: '300px',
          headerName: 'Channel(s)',
          sortable: true,
          renderColumn: row => {
            return (
              !isEmpty(row?.channels) && (
                <Typography variant="label1"> {row.channels.toString()} </Typography>
              )
            )
          },
        },
      ],
    }

    if (isEditMode) {
      tableProps.columns.push({
        headerName: 'Actions',
        width: '120px',
        renderColumn: row => {
          const deleteIconClasses = {
            'disabled-icon': disableEditing,
            'action-icons-container': true,
          }
          return (
            <div className={classNames({ ...deleteIconClasses })}>
              <Tooltip title={'Delete'} position="left">
                <div
                  className="action-icon"
                  onClick={() => {
                    onDelete(row.id)
                  }}
                >
                  <img src={disableEditing ? deleteIconDisabled : deleteIcon} />
                </div>
              </Tooltip>
              {isSlack(row?.application) && (
                <Tooltip title={'Edit'} position="left">
                  <div
                    className="action-icon"
                    onClick={() => {
                      setCurrentEditingMedium({
                        ...row,
                        type: row?.application,
                      })
                      setIsDrawerOpen(true)
                    }}
                  >
                    <div>
                      <img src={edit} className="action-icon-edit" />
                    </div>
                  </div>
                </Tooltip>
              )}
            </div>
          )
        },
      })
    }
    return notificationMediums?.length && !disabled ? (
      <Table {...tableProps} />
    ) : (
      noDataText && <Typography variant="label1">{noDataText}</Typography>
    )
  }

  const closeDrawer = () => {
    setIsDrawerOpen(false)
    setError(null)
    setCurrentEditingMedium({})
  }

  const handleAdd = async notification => {
    setError(null)
    try {
      const isNew = isEmpty(currentEditingMedium)
      await onAdd(
        {
          ...notification,
          application: notification.type,
        },
        isNew
      )
      closeDrawer()
    } catch (error) {
      setError(error?.response?.data?.message)
    }
  }

  return (
    <>
      <div className="section-container">
        {isEditMode && (
          <Button
            size={'large'}
            onClick={() => {
              setIsDrawerOpen(true)
            }}
            disabled={disableEditing || disabled}
          >
            Add Notification
          </Button>
        )}
        <div className="table-container">
          <NotificationTable />
        </div>
      </div>

      {isDrawerOpen && (
        <NotificationMediumSelector
          onCancel={closeDrawer}
          onAdd={handleAdd}
          error={error}
          selectedNotificationMediums={notificationMediums}
          currentEditingMedium={currentEditingMedium}
        />
      )}
    </>
  )
}

NotificationMediums.prototype = {
  notificationMediums: PropTypes.arrayOf(PropTypes.shape({})),
  onAdd: PropTypes.func,
  onDelete: PropTypes.func,
  isEditMode: PropTypes.bool,
  noDataText: PropTypes.string,
}

export default NotificationMediums
