import React, { Component } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import Mark from 'mark.js'

import Table from './tableV2'
import TableHeader from './tableHeaderV2'

// the way this was setup was not as a reusable component
// for different use cases. pretty bad

class TableSection extends Component {
  state = {
    outerSort: 'sortBy',
    outerFilter: 'filterBy',
    outerSearch: '',
    filterByRegistered: false,
    filterByRegistrable: false,
    filterByInUse: false,
    filterByPermissions: false,
    filterByPrivileged: false,
    filterByExternal: false,
    filterByMappedAttributes: false,
    filterCategory: 'filterCategory',
    selectedCategoryData: null,
    searchResultsInfo: {
      searchCount: 0,
      isNavigationOpen: false,
    },
  }

  tableRef = React.createRef()
  markInstance = React.createRef()

  componentDidMount() {
    this.setupHighlightInstance()
  }

  componentDidUpdate(prevProps, prevState) {
    const { initialColumn } = this.props
    const clearInitialColumnStatus = get(initialColumn, 'clearInitialColumnStatus')

    if (
      prevState.outerSearch !== this.state.outerSearch ||
      prevState.outerFilter !== this.state.outerFilter
    ) {
      clearInitialColumnStatus && clearInitialColumnStatus()
    }
  }

  setupHighlightInstance = () => {
    const tableEl = this.tableRef.current

    if (tableEl) {
      const tableRows = tableEl.getElementsByClassName('rt-tbody')[0]
      const markInstance = new Mark(tableRows, {
        acrossElements: true,
        caseSensitive: false,
      })
      this.markInstance.current = markInstance
    }
  }

  highlightText = () => {
    const { outerSearch } = this.state
    this.markInstance.current.unmark({
      done: () => {
        this.markInstance.current.mark(outerSearch, {
          done: this.afterMarkHandler,
          separateWordSearch: false,
          exclude: [
            '.action-icon-label',
            '.__react_component_tooltip',
            '.privileged-toggle *',
            '.iconWrapper *',
            '.excludeSearch *',
          ],
        })
      },
    })
  }

  afterMarkHandler = searchCount => {
    this.updateSearchResultsInfo({
      searchCount,
      isNavigationOpen: !!this.state.outerSearch,
    })
  }

  updateSearchResultsInfo = newSearchResultsInfo => {
    const { searchResultsInfo } = this.state

    this.setState({
      searchResultsInfo: {
        ...searchResultsInfo,
        ...newSearchResultsInfo,
      },
    })
  }

  changeOuterTableSort = sortBy => {
    this.setState({ outerSort: sortBy })
  }

  changeOuterTableFilter = filterBy => {
    this.setState({ outerFilter: filterBy })
  }

  changeOuterSearch = async e => {
    this.setState({ outerSearch: e.target.value })
  }

  changeFilterByRegistered = value => {
    this.setState({ filterByRegistered: value })
  }

  changeFilterByRegistrable = value => {
    this.setState({ filterByRegistrable: value })
  }

  changeFilterByInUse = value => {
    this.setState({ filterByInUse: value })
  }

  changeFilterCategory = value => {
    this.setState({ filterCategory: value })
  }

  changeFilterByPermissions = value => {
    this.setState({ filterByPermissions: value })
  }

  changeFilterByPrivileged = value => {
    this.setState({ filterByPrivileged: value })
  }

  changeFilterByExternal = value => {
    this.setState({ filterByExternal: value })
  }

  changeFilterByMappedAttributes = value => {
    this.setState({ filterByMappedAttributes: value })
  }

  render() {
    const {
      allowFilterByInUse,
      allowFilterByPermissions,
      allowFilterByRegistered,
      allowFilterByRegistrable,
      allowFilterByPrivileged,
      allowFilterByExternal,
      allowFilterByMappedAttributes,
      buttonAction,
      buttonColor,
      buttonTitle,
      columns,
      compoundFilterCategories,
      customSearchTags,
      data,
      defaultSorted,
      defaultSortMethod,
      disableHover,
      emptyTableMessage,
      filter,
      filterByKey,
      filterByOptions,
      filterDataSet,
      hasBorderBottom,
      hideHeader,
      initialColumn,
      isRefreshing,
      marginTop,
      refreshHandler,
      showButton,
      sortByOptions,
      useCompoundFilter,
      highlightSearchResults,
      identifier,
      maxHeight,
      onScroll,
      resizable,
      rowOnClickHandler,
      rowHasAction,
      searchKeys,
      selectedId,
      setDataOverride,
      showPagination,
      sortable,
      expanded,
      DropdownComponent,
    } = this.props

    return (
      <div
        className="my-access-v2"
        style={{ marginTop: marginTop === undefined ? 20 : marginTop }}
      >
        {/* this sucks */}
        <TableHeader
          allowFilterByInUse={allowFilterByInUse}
          allowFilterByPermissions={allowFilterByPermissions}
          allowFilterByRegistered={allowFilterByRegistered}
          allowFilterByRegistrable={allowFilterByRegistrable}
          allowFilterByPrivileged={allowFilterByPrivileged}
          allowFilterByExternal={allowFilterByExternal}
          allowFilterByMappedAttributes={allowFilterByMappedAttributes}
          buttonAction={buttonAction}
          buttonColor={buttonColor}
          buttonTitle={buttonTitle}
          changeFilterByPermissions={this.changeFilterByPermissions}
          changeFilterCategory={this.changeFilterCategory}
          compoundFilterCategories={compoundFilterCategories}
          filter={filter}
          filterByInUse={this.changeFilterByInUse}
          filterByOptions={filterByOptions}
          filterByRegistered={this.changeFilterByRegistered}
          filterByRegistrable={this.changeFilterByRegistrable}
          filterByPrivileged={this.changeFilterByPrivileged}
          filterByExternal={this.changeFilterByExternal}
          filterByMappedAttributes={this.changeFilterByMappedAttributes}
          filterDataSet={filterDataSet}
          filterTable={this.changeOuterTableFilter}
          changeOuterSearch={this.changeOuterSearch}
          isRefreshing={isRefreshing}
          refreshHandler={refreshHandler}
          searchKeys={searchKeys}
          searchTerm={this.state.outerSearch}
          searchResultsInfo={this.state.searchResultsInfo}
          showButton={showButton}
          sortByOptions={sortByOptions}
          sortTable={this.changeOuterTableSort}
          updateSearchResultsInfo={this.updateSearchResultsInfo}
          tableRef={this.tableRef}
          useCompoundFilter={useCompoundFilter}
        />

        <Table
          columns={columns}
          customSearchTags={customSearchTags}
          data={data}
          defaultSorted={defaultSorted}
          defaultSortMethod={defaultSortMethod}
          disableHover={disableHover}
          emptyTableMessage={emptyTableMessage}
          filterBy={this.state.outerFilter}
          filterByInUse={this.state.filterByInUse}
          filterByKey={filterByKey}
          filterByPermissions={this.state.filterByPermissions}
          filterByRegistered={this.state.filterByRegistered}
          filterByRegistrable={this.state.filterByRegistrable}
          filterByPrivileged={this.state.filterByPrivileged}
          filterByExternal={this.state.filterByExternal}
          filterByMappedAttributes={this.state.filterByMappedAttributes}
          filterCategory={this.state.filterCategory}
          innerRef={this.tableRef}
          hasBorderBottom={hasBorderBottom}
          hideHeader={hideHeader}
          highlightText={highlightSearchResults ? this.highlightText : undefined}
          identifier={identifier}
          initialColumn={initialColumn}
          maxHeight={maxHeight}
          onScroll={onScroll}
          resizable={resizable}
          rowOnClickHandler={rowOnClickHandler}
          rowHasAction={rowHasAction}
          searchTerm={this.state.outerSearch}
          searchKeys={searchKeys}
          selectedCategoryData={this.state.selectedCategoryData}
          selectedId={selectedId}
          setDataOverride={setDataOverride}
          showPagination={showPagination}
          sortBy={this.state.outerSort}
          sortable={sortable}
          expanded={expanded}
          DropdownComponent={DropdownComponent}
        />
      </div>
    )
  }
}

TableSection.propTypes = {
  actions: PropTypes.func,
  data: PropTypes.array,
  columns: PropTypes.array,
  sortBy: PropTypes.string,
  filterByKey: PropTypes.string,
  selectedData: PropTypes.array,
  buttonTitle: PropTypes.string,
  sortByOptions: PropTypes.array,
  buttonAction: PropTypes.func,
  buttonColor: PropTypes.string,
  filterByOptions: PropTypes.array,
  showButton: PropTypes.bool,
  emptyTableMessage: PropTypes.string,
  allowFilterByRegistered: PropTypes.bool,
  allowFilterByPermissions: PropTypes.bool,
  allowFilterByPrivileged: PropTypes.bool,
  allowFilterByExternal: PropTypes.bool,
  allowFilterByMappedAttributes: PropTypes.bool,
  allowFilterByInUse: PropTypes.bool,
  useCompoundFilter: PropTypes.bool,
  compoundFilterCategories: PropTypes.array,
  filterDataSet: PropTypes.object,
  filter: PropTypes.bool,
}

export default TableSection
