import React, { memo, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import { preFetch } from 'utils/do_fetch'
import { updateMessageModal } from 'action_creators/message_modal'

import Application from './Application'
import PageLoader from 'components/PageLoader'

import useApplicationLoader from 'data_loaders/hooks/useApplicationLoader'

import {
  selectEnvironmentId,
  selectEnvironmentGroupId,
  thisAppManageAction,
} from 'action_creators/environment'
import {
  getSelectedApplicationRoot,
  getSelectedApplicationTemplate,
} from 'selectors/application_root'

import { FEATURE_FLAGS } from 'reducers/feature_flag'

import {
  getApplicationEnvironments,
  getFetchingEnvironments,
  getSelectedApplicationEnvironments,
  getSelectedApplicationEnvironmentScanResults,
  getSelectedApplicationRootGroupId,
  getSelectedApplicationEnvironmentGroups,
  getSelectedEnvironmentId,
  getSelectedEnvironmentGroupId,
} from 'selectors/environment'

import { getFeatureFlagById } from 'selectors/feature_flag'
import { fetchApplicationRoot } from 'action_creators/application_root'
import { withRouter } from 'react-router-dom'

const featureFlagsTAB_LIST = ['overview', 'properties', 'data', 'profiles', 'scans']

const TREE_SELECT_PATHS = ['overview', 'properties', 'data']

function ApplicationContainer(props) {
  const TAB_LIST = [
    'overview',
    'properties',
    'data',
    'profiles',
    'scans',
    'access-request-settings',
  ]

  if (
    props?.featureFlags?.accessRequest &&
    props?.app?.catalogApplication.supportManagedPermissions
  ) {
    TAB_LIST.push('managed-permissions')
  }

  const { appId } = props.match.params
  const [userSelectedEntity, setUserSelectedEntity] = useState(null)
  const requestAccess = props?.requestAccess

  const path = props.history.location.pathname.split('/')[4]
  const { isLoaded } = useApplicationLoader({ appId, path, requestAccess })

  const [breadcrumbTrail, setBreadcrumbTrail] = useState([])
  const [isRefreshing, setIsRefreshing] = useState(false)
  const [thisAppManage, setThisAppManage] = useState(false)

  useEffect(() => {
    const { dispatch } = props
    const validateAppManage = async () => {
      try {
        const response = await preFetch({
          postBody: { consumer: 'apps', resource: appId, action: 'apps.app.manage' },
          path: '/users/evaluate-action',
          method: 'post',
        })
        setThisAppManage(response.data.authorized)
        dispatch(thisAppManageAction(response.data.authorized))
      } catch (error) {
        const errorMessage = get(error, 'response.data.message', 'Unknown error')
        dispatch(
          updateMessageModal({
            body: errorMessage,
            afterCloseAction: refreshApplication(),
          })
        )
      }
    }
    requestAccess ? setThisAppManage(true) : validateAppManage()
  }, [appId])

  useEffect(() => {
    const { dispatch, environments, isHierarchical, rootGroupId } = props
    if (userSelectedEntity !== null) {
      processEntitySelection(userSelectedEntity)
    } else {
      if (isHierarchical) {
        dispatch(selectEnvironmentGroupId(rootGroupId))
      } else {
        const firstEnvId = Object.keys(environments)[0]
        dispatch(selectEnvironmentId(firstEnvId))
      }
    }
  }, [props.environments])

  useEffect(() => {
    const {
      dispatch,
      isHierarchical,
      location: { pathname },
      rootGroupId,
    } = props

    const pathArray = pathname.split('/')

    if (isHierarchical && ['properties', 'overview'].includes(pathArray[4])) {
      dispatch(selectEnvironmentGroupId(rootGroupId))
      dispatch(selectEnvironmentId(null))
    }
    return () => {
      dispatch(selectEnvironmentId(null))
      dispatch(selectEnvironmentGroupId(null))
    }
  }, [props.isHierarchical, props.location.pathname])

  const selectEntity = ({ entityId }) => {
    !isHierarchical && setUserSelectedEntity(entityId)
    processEntitySelection(entityId)
  }
  const processEntitySelection = entityId => {
    const {
      dispatch,
      environments,
      environmentGroups,
      rootGroupId,
      isHierarchical,
    } = props

    switch (true) {
      case !!environments[entityId]:
        dispatch(selectEnvironmentId(entityId))
        dispatch(selectEnvironmentGroupId(null))
        break
      case !!environmentGroups[entityId] &&
        (entityId !== rootGroupId || isHierarchical):
        dispatch(selectEnvironmentGroupId(entityId))
        dispatch(selectEnvironmentId(null))
        break
      default:
        dispatch(selectEnvironmentId(null))
        dispatch(selectEnvironmentGroupId(null))
    }
  }

  const entityType = useMemo(() => {
    const { environmentId, environmentGroupId } = props

    switch (true) {
      case !!environmentId:
        return 'environment'
      case !!environmentGroupId:
        return 'environmentGroup'
      default:
        return 'application'
    }
  }, [props.environmentId, props.environmentGroupId])

  const selectedEntity = useMemo(() => {
    const {
      app,
      applicationTemplate,
      environments,
      environmentData,
      environmentGroups,
      environmentId,
      environmentGroupId,
      rootGroupId,
      isHierarchical,
    } = props

    switch (true) {
      case isEmpty(environmentGroups) &&
        isEmpty(environments) &&
        isEmpty(environmentData): {
        const rootName =
          get(app, 'catalogAppDisplayName') ||
          get(applicationTemplate, 'catalogAppName')

        return {
          value: get(app, 'appContainerId'),
          label: rootName,
        }
      }
      case !!environmentId: {
        let childEnvironment = find(environments, { id: environmentId })

        if (childEnvironment) {
          const { id, name } = childEnvironment
          const label = get(environmentData, [id, 'catalogAppDisplayName'], name)

          return {
            value: environmentId,
            label,
          }
        }
        break
      }
      case !!environmentGroupId && !isHierarchical: {
        let childGroup = find(environmentGroups, { id: environmentGroupId })

        return {
          value: environmentGroupId,
          label: get(childGroup, 'name'),
        }
      }
      case !!environmentGroupId && isHierarchical: {
        let root = find(environmentGroups, { parentId: '' })

        if (environmentGroupId === rootGroupId) {
          return {
            value: rootGroupId,
            label: get(root, 'name'),
          }
        } else {
          let group = find(environmentGroups, { id: environmentGroupId })

          return {
            value: environmentGroupId,
            label: get(group, 'name'),
          }
        }
      }
      default: {
        const rootName =
          get(app, 'catalogAppDisplayName') ||
          get(applicationTemplate, 'catalogAppName')

        return {
          value: get(app, 'appContainerId'),
          label: rootName,
        }
      }
    }
  }, [
    props.environmentId,
    props.environmentGroupId,
    props.app,
    props.environments,
    props.environmentData,
    props.environmentGroups,
  ])

  const showTreeSelect = useMemo(() => {
    const {
      location: { pathname },
      isHierarchical,
    } = props

    const pathArray = pathname.split('/')
    const showTreeSelectPath = TREE_SELECT_PATHS.includes(pathArray[4])

    if (isHierarchical && pathArray[4] === 'data') {
      return true
    }

    return !isHierarchical && showTreeSelectPath
  }, [props.isHierarchical, props.location.pathname])

  if (!isLoaded) {
    return <PageLoader text="Loading Application" />
  }

  const {
    app,
    activitiesFeatureEnabled,
    environmentId,
    environmentGroupId,
    isHierarchical,
    match,
    supportsScanning,
    environmentScanResults,
    environmentData,
    environments,
    environmentGroups,
    applicationTemplate,
    rootGroupId,
    dispatch,
  } = props

  const { iconUrl: appIcon, catalogAppDisplayName: name, activitiesEnabled } = app

  const selectedEntityId =
    environmentId || environmentGroupId || get(app, 'appContainerId')

  const refreshApplication = async () => {
    if (!isRefreshing) {
      setIsRefreshing(true)
      await dispatch(fetchApplicationRoot(appId, requestAccess))
      setIsRefreshing(false)
    }
  }

  return (
    <Application
      activitiesFeatureEnabled={activitiesFeatureEnabled}
      activitiesForAppEnabled={activitiesEnabled}
      app={app}
      appIcon={appIcon}
      appName={name}
      breadcrumbTrail={breadcrumbTrail}
      entityType={entityType}
      environments={environments}
      environmentGroups={environmentGroups}
      environmentScanResults={environmentScanResults}
      environmentData={environmentData}
      applicationTemplate={applicationTemplate}
      isHierarchical={isHierarchical}
      isLoaded={isLoaded}
      match={match}
      rootGroupId={rootGroupId}
      selectEntity={selectEntity}
      selectedEntity={selectedEntity}
      selectedEntityId={selectedEntityId}
      setBreadcrumbTrail={setBreadcrumbTrail}
      showTreeSelect={showTreeSelect}
      supportsScanning={supportsScanning}
      tabsList={props?.featureFlags?.accessRequest ? TAB_LIST : featureFlagsTAB_LIST}
      refreshApplication={refreshApplication}
      isRefreshing={isRefreshing}
      thisAppManage={thisAppManage}
      requestAccess={requestAccess}
    />
  )
}

const mapStateToProps = state => {
  const app = getSelectedApplicationRoot(state)
  let isHierarchical = false
  let supportsScanning = false

  if (app) {
    const { catalogApplication } = app
    isHierarchical = get(catalogApplication, 'requiresHierarchicalModel')
    supportsScanning = get(catalogApplication, 'supportsEnvironmentScanning')
  }

  return {
    app,
    isHierarchical,
    supportsScanning,
    location: state.router.location,
    rootGroupId: getSelectedApplicationRootGroupId(state),
    environments: getApplicationEnvironments(state),
    environmentId: getSelectedEnvironmentId(state),
    environmentGroupId: getSelectedEnvironmentGroupId(state),
    applicationTemplate: getSelectedApplicationTemplate(state),
    isFetchingEnvironments: getFetchingEnvironments(state),
    environmentGroups: getSelectedApplicationEnvironmentGroups(state),
    environmentData: getSelectedApplicationEnvironments(state),
    environmentScanResults: getSelectedApplicationEnvironmentScanResults(state),
    activitiesFeatureEnabled: getFeatureFlagById({
      id: FEATURE_FLAGS.activityMonitoring,
      state,
    }),
    featureFlags: state.features.featureFlags,
  }
}

export default connect(mapStateToProps)(withRouter(memo(ApplicationContainer)))
