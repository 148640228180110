import { BackgroundTooltip as Tooltip } from 'components/Tooltips'
import React, { memo } from 'react'
import { Fragment } from 'react'
import styled from 'styled-components'

const StyledPill = styled.div`
  min-width: 12px;
  height: 12px;
  border-radius: 100%;
  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : 'red'};
  font-size: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props =>
    props.backgroundColor && props.backgroundColor === 'yellow' ? 'black' : 'white'};
  margin-left: 1em;
`
function VisualCuesPills({ id, label, backgroundColor, showTooltip, tooltipText }) {
  return (
    <Fragment>
      <StyledPill
        data-tip
        data-for={showTooltip && tooltipText && tooltipText.length > 0 ? id : ''}
        backgroundColor={backgroundColor}
      >
        {label}
      </StyledPill>
      <Tooltip id={id} effect="solid">
        {tooltipText}
      </Tooltip>
    </Fragment>
  )
}

export default memo(VisualCuesPills)
