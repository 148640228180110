import { fetchIfLoggedIn } from './utils.js'

export const FETCH_FEATURE_FLAGS = 'FETCH_FEATURE_FLAGS'
export const TOGGLE_FEATURE_FLAG = 'TOGGLE_FEATURE_FLAG'

export const fetchFeatureFlags = () => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: FETCH_FEATURE_FLAGS,
      method: 'get',
      path: `/features`,
    })
  )
}
