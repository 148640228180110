import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withFormik } from 'formik'
import get from 'lodash/get'

import EnvironmentCreationModal from './EnvironmentCreationModal'

import {
  createEnvironmentEntity,
  fetchEnvironmentGroups,
  fetchEnvironments,
} from 'action_creators/environment'

import { fetchApplicationRoot } from 'action_creators/application_root'
import {
  selectEnvironmentGroupId,
  selectEnvironmentId,
} from 'action_creators/environment'

const GROUP = 'group'
const ENVIRONMENT = 'environment'

export const ENTITY_TYPES = {
  [GROUP]: 'Environment Group',
  [ENVIRONMENT]: 'Environment',
}

export class EnvironmentCreationModalContainer extends PureComponent {
  cancelForm = () => {
    const { resetForm, toggleModal } = this.props
    toggleModal()
    resetForm()
  }

  submitForm = args => {
    const { handleSubmit } = this.props
    handleSubmit(args)
  }

  render() {
    const {
      values,
      errors,
      handleChange,
      isSubmitting,
      isModalOpen,
      toggleModal,
      touched,
      createEnvironmentError,
    } = this.props

    return (
      <EnvironmentCreationModal
        errors={errors}
        fields={values}
        createEntity={this.createEntity}
        updateField={handleChange}
        handleSubmit={this.submitForm}
        isSubmitting={isSubmitting}
        touched={touched}
        isModalOpen={isModalOpen}
        cancelForm={this.cancelForm}
        toggleModal={toggleModal}
        createEnvironmentError={createEnvironmentError}
      />
    )
  }
}

const enhancedForm = withFormik({
  mapPropsToValues: () => ({ name: '', description: '', type: undefined }),

  validate: values => {
    const errors = {}
    if (!values.name) {
      errors.name = 'Name is a required field'
    }

    if (!values.type) {
      errors.type = 'Please select entity type'
    }

    return errors
  },

  handleSubmit: async (values, { resetForm, setSubmitting, props }) => {
    const { appId, dispatch, toggleModal, entityId: oldEntityId } = props
    let entityId = oldEntityId

    try {
      if (!entityId) {
        const response = await dispatch(
          createEnvironmentEntity(appId, '', { name: 'root', type: 'group' })
        )
        entityId = response.value.data.id
      }

      const response = await dispatch(
        createEnvironmentEntity(appId, entityId, values)
      )
      const id = get(response, 'action.payload.data.id')
      await dispatch(fetchApplicationRoot(appId))
      if (values.type === 'environment') {
        await dispatch(fetchEnvironments(appId))
        dispatch(selectEnvironmentId(id))
      } else {
        await dispatch(fetchEnvironmentGroups(appId))
        dispatch(selectEnvironmentGroupId(id))
      }
    } catch (error) {
      console.log(error)

      // formik submit is not async aka doesn't return a promise
      // watch the error through state
      setSubmitting(false)
      return
    }

    setSubmitting(false)
    toggleModal()
    resetForm()
  },

  displayName: 'EnvironmentCreationForm',
})

const mapStateToProps = state => ({
  createEnvironmentError: state.environment.createEnvironmentError,
})

const withConnect = connect(mapStateToProps)

export default compose(withConnect, enhancedForm)(EnvironmentCreationModalContainer)
