import React, { memo, useState } from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'

import Groups from './Groups'

import { FEATURE_FLAGS } from 'reducers/feature_flag'

import { getFeatureFlagById } from 'selectors/feature_flag'

function GroupsContainer(props) {
  const [selectedPermission, setSelectedPermission] = useState(null)
  const [isPermissionsDrawerOpen, setIsPermissionsDrawerOpen] = useState(null)
  const [isAssignedDrawerOpen, setIsAssignedDrawerOpen] = useState(null)

  function togglePermissionsDrawer({ permission = null }) {
    if (permission?.permissions?.length) {
      setSelectedPermission(permission)
      setIsAssignedDrawerOpen(false)
    }
    setIsPermissionsDrawerOpen(!!permission?.permissions?.length)
  }

  function toggleAssignedDrawer({ permission = null }) {
    setSelectedPermission(permission)
    setIsPermissionsDrawerOpen(false)
    setIsAssignedDrawerOpen(!!permission)
  }

  const {
    selectEntity,
    selectedApp,
    selectedAppId,
    selectedEnvId,
    selectedEnvironmentGroupId,
    selectedEnvironment,
  } = props

  const requiresHierarchicalModel = get(
    selectedApp,
    'catalogApplication.requiresHierarchicalModel'
  )

  const filterDataSet = {
    scanStatus: [
      { label: 'New', value: 'New' },
      { label: 'Updated', value: 'Updated' },
      { label: 'Unchanged', value: 'Unchanged' },
      { label: 'Deleted', value: 'Deleted' },
    ],
  }

  return (
    <Groups
      filterDataSet={filterDataSet}
      isAssignedDrawerOpen={isAssignedDrawerOpen}
      isPermissionsDrawerOpen={isPermissionsDrawerOpen}
      requiresHierarchicalModel={requiresHierarchicalModel}
      selectEntity={selectEntity}
      selectedApp={selectedApp}
      selectedAppId={selectedAppId}
      selectedEnvId={selectedEnvId}
      selectedEnvironment={selectedEnvironment}
      selectedEnvironmentGroupId={selectedEnvironmentGroupId}
      selectedPermission={selectedPermission}
      toggleAssignedDrawer={toggleAssignedDrawer}
      togglePermissionsDrawer={togglePermissionsDrawer}
    />
  )
}

const mapStateToProps = state => ({
  registerPermissionsToggle: getFeatureFlagById({
    id: FEATURE_FLAGS.registerPermissionsToggle,
    state,
  }),
})

export default connect(mapStateToProps)(memo(GroupsContainer))
