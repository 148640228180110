import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Button from 'britive-design-system/core/components/button'
import PermissionsDetails from './details'

const ManagedPermissionsDetails = ({ app }) => {
  const history = useHistory()
  const { id } = useParams()

  return (
    <div>
      <div style={{ marginLeft: '8px' }}>
        <Button size="medium" variant="secondary" onClick={() => history.goBack()}>
          Close
        </Button>
      </div>
      <PermissionsDetails appId={app.appContainerId} permissionId={id} />
    </div>
  )
}

export default ManagedPermissionsDetails
