import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import Typography from 'britive-design-system/core/components/typography'
import Spinner from 'britive-design-system/core/components/spinner'

import { fetchPermissionFindings } from 'action_creators/pap'

import './index.scss'

const FindingsComponent = ({ appId, name }) => {
  const dispatch = useDispatch()
  const { isFetchingFindings, permissionFindings } = useSelector(state => state.paps)

  useEffect(() => {
    dispatch(fetchPermissionFindings(appId, name))
  }, [])

  const capitalizeFirstLetterOfWords = inputString => {
    const capitalizedString = inputString
      .toLowerCase()
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
    return capitalizedString + '(s)'
  }

  const renderFindings = () => {
    return (
      <div className="findings-container">
        {permissionFindings?.map(arr => (
          <>
            <Typography variant="heading6">
              {capitalizeFirstLetterOfWords(arr.type)}
            </Typography>
            <ul>
              {arr.messages.map((message, messageIndex) => (
                <li key={messageIndex}>
                  <Typography variant="caption1">{message}</Typography>
                </li>
              ))}
            </ul>
          </>
        ))}
      </div>
    )
  }

  return (
    <>
      {isFetchingFindings && (
        <Spinner size={'medium'} message={'Loading...'} overlay />
      )}
      {!isEmpty(permissionFindings) && renderFindings()}
    </>
  )
}

export default FindingsComponent
