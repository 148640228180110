import React, { Component } from 'react'
import sortBy from 'sort-by'
import PropTypes from 'prop-types'

import TableView from 'components/table/TableV2/TableView'

class Table extends Component {
  state = {
    data: [],
  }

  isUnmounted = false

  async componentDidUpdate(prevProps) {
    const { data, tableName, searchTerm } = this.props
    // TODO: have to run search and filter everytime something changes
    // clean this up where you don't have to
    // probably just need to set different state for search and filter data and data
    if (tableName !== 'my-access') {
      if (
        prevProps.data !== data ||
        prevProps.searchTerm !== searchTerm ||
        prevProps.category !== this.props.category ||
        prevProps.filterBy !== this.props.filterBy ||
        prevProps.filterByKey !== this.props.filterByKey ||
        prevProps.filterByRegistered !== this.props.filterByRegistered ||
        prevProps.filterByInUse !== this.props.filterByInUse ||
        prevProps.filterByMapped !== this.props.filterByMapped ||
        prevProps.filterByPermissions !== this.props.filterByPermissions
      ) {
        await this.searchTable()
        this.filterData()
      }
    } else {
      if (data !== this.state.data) {
        this.setState({ data })
      }
    }

    if (prevProps.sortBy !== this.props.sortBy) {
      this.setState(({ data }) => ({
        data: data.sort(sortBy(this.props.sortBy)),
      }))
    }
  }

  componentDidMount() {
    this.setState({
      data: this.props.data,
    })
  }

  componentWillUnmount() {
    this.isUnmounted = true
  }

  changeSortBy(sortBy) {
    this.setState({
      sortBy,
    })
  }

  handleChange = event => {
    const { name } = event.target

    this.setState(
      {
        checkboxState: {
          ...this.state.checkboxState,
          [name]: !this.state.checkboxState[name],
        },
      },
      () => {
        const checkBoxState = this.state.checkboxState[name]
        this.state.data.map(el => {
          return el[this.props.keyValue] === name
            ? this.props.updateCB(el, checkBoxState)
            : el
        })
      }
    )
  }

  sortData() {
    return this.props.sortBy !== 'sortBy'
      ? this.state.data.sort(sortBy(this.props.sortBy))
      : this.state.data
  }

  searchTable() {
    const { data, keyValue, keyValue1, keyValue2, manual } = this.props
    // why is this done like this?
    if (manual) {
      this.setState({ data: this.props.data })
    } else {
      const searchResults = data.filter(el => {
        if (typeof keyValue1 === 'object') {
          if (el[keyValue1.primaryKey][keyValue1.secondaryKey]) {
            return (
              el[keyValue]
                .toLowerCase()
                .includes(this.props.searchTerm.toLowerCase()) ||
              el[keyValue1.primaryKey][keyValue1.secondaryKey]
                .toLowerCase()
                .includes(this.props.searchTerm.toLowerCase())
            )
          } else {
            return el[keyValue]
              .toLowerCase()
              .includes(this.props.searchTerm.toLowerCase())
          }
        }

        if (typeof keyValue2 === 'string') {
          const value = el[keyValue] || ''
          const value1 = el[keyValue1] || ''
          const value2 = el[keyValue2] || ''

          return (
            value.toLowerCase().includes(this.props.searchTerm.toLowerCase()) ||
            value1.toLowerCase().includes(this.props.searchTerm.toLowerCase()) ||
            value2.toLowerCase().includes(this.props.searchTerm.toLowerCase())
          )
        }

        if (typeof keyValue1 === 'string') {
          const value = el[keyValue] || ''
          const value1 = el[keyValue1] || ''

          if (value1) {
            return (
              value.toLowerCase().includes(this.props.searchTerm.toLowerCase()) ||
              value1.toLowerCase().includes(this.props.searchTerm.toLowerCase())
            )
          } else {
            return value.toLowerCase().includes(this.props.searchTerm.toLowerCase())
          }
        }

        if (!keyValue1) {
          const value = el[keyValue] || ''
          return value.toLowerCase().includes(this.props.searchTerm.toLowerCase())
        }

        return null
      })

      if (!this.isUnmounted) {
        this.props.searchTerm !== ''
          ? this.setState({
              data: searchResults,
            })
          : this.setState({ data: this.props.data })
      }
    }
  }

  filterData = () => {
    const { filterBy, filterCategory: category, manual } = this.props
    const { data } = this.state
    let showData

    if (manual) {
      showData = data
    } else {
      if (category) {
        showData =
          filterBy !== 'all' && filterBy !== 'filterBy' && category !== 'all'
            ? data.filter(d => d[category].toString() === filterBy)
            : data
      } else {
        showData =
          filterBy !== 'all' && filterBy !== 'filterBy'
            ? data
                .filter(d => {
                  return d[this.props.filterByKey].toLowerCase() === filterBy
                })
                .map(filteredData => {
                  return filteredData
                })
            : data
      }
    }

    showData = this.props.filterByRegistered
      ? showData.filter(data => data.status === 'Registered')
      : showData

    showData = this.props.filterByInUse
      ? showData.filter(data => data.accounts.length > 0)
      : showData

    showData = this.props.filterByMapped
      ? showData.filter(data => data.mappedUsers.length > 0)
      : showData

    showData = this.props.filterByPermissions
      ? showData.filter(data => data.permissions.length > 0)
      : showData

    if (!this.isUnmounted) {
      this.setState({ data: showData })
      this.props.setDataOverride && this.props.setDataOverride(showData)
    }
  }

  render() {
    const { container } = styles
    const { data } = this.state
    const ismyaccess = window.location.href.indexOf('my-access') > -1

    return (
      <div style={container} className={ismyaccess ? 'btv-table' : ''}>
        <TableView {...this.props} data={data} />
      </div>
    )
  }
}

Table.propTypes = {
  sortBy: PropTypes.string,
  filterBy: PropTypes.string,
  searchTerm: PropTypes.string,
  showCheckbox: PropTypes.bool,
  columns: PropTypes.array,
  updateCB: PropTypes.func,
  data: PropTypes.array,
  selectedCB: PropTypes.array,
  keyValue: PropTypes.string,
  keyValue1: PropTypes.any,
  keyValue2: PropTypes.any,
  actions: PropTypes.func,
  filterByKey: PropTypes.string,
  emptyTableMessage: PropTypes.node,
  accounts: PropTypes.func,
  permissions: PropTypes.func,
  filterByRegistered: PropTypes.bool,
  filterCategory: PropTypes.string,
  selectedCategoryData: PropTypes.string,
  filterByInUse: PropTypes.bool,
  filterByMapped: PropTypes.bool,
  filterByPermissions: PropTypes.bool,
}

const styles = {
  container: {
    flex: 1,
    boxShadow: `0 2px 12px 2px #cddfed`,
    backgroundColor: '#FFF',
  },
  tableHeader: {
    fontSize: 14,
    fontWeight: 'bold',
    padding: '0 4px',
  },
  inactiveShade: {
    color: '#C6C6C6',
  },
}

export default Table
