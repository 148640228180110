import camelCase from 'lodash/camelCase'

import { notAuthenticated } from 'action_creators/account'

import { preFetch, doFetch } from 'utils/do_fetch'
import { getIsAuthenticated } from 'utils/authentication'

export const fetchIfLoggedIn = ({
  actionType,
  meta,
  method,
  path,
  postBody,
  params = {},
}) => dispatch => {
  const isLoggedIn = getIsAuthenticated()

  if (isLoggedIn) {
    const response = preFetch({
      name: camelCase(actionType),
      path,
      method,
      postBody,
      params,
    })

    return dispatch({
      type: actionType,
      meta,
      payload: response,
    })
  }

  return dispatch(notAuthenticated())
}

export const fetchWithoutAuth = ({
  actionType,
  meta,
  method,
  path,
  postBody,
  allow401,
}) => dispatch => {
  const response = doFetch({
    name: camelCase(actionType),
    path,
    method,
    postBody,
    allow401,
  })

  return dispatch({
    type: actionType,
    meta,
    payload: response,
  })
}
