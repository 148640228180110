import React, { memo, useState, Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import get from 'lodash/get'

import PapTags from './PapTags'

import { addTagToPap, editTagOfPap, removeTagFromPap } from 'action_creators/pap'

const USER_ASSIGNED_DIRECTLY_TO_PAP_EXCEPTION_CODE = 'E1008'

function PapTagsContainer({
  app,
  dispatch,
  toggleDrawer,
  maxHeight,
  profileId,
  thisAppManage,
}) {
  const [selectedTag, setSelectedTag] = useState(null)

  const [isAddingTagToPap, setIsAddingTagToPap] = useState({})
  const [isRemovingTagFromPap, setIsRemovingTagFromPap] = useState({})

  const [isAddTagModalOpen, setIsAddTagModalOpen] = useState(false)
  const [isAddTimePeriodTagOpen, setIsAddTimePeriodTagOpen] = useState(false)
  const [isEditTimePeriodTagOpen, setIsEditTimePeriodTagOpen] = useState(false)
  const [isConflictMessageOpen, setIsConflictMessageOpen] = useState(false)

  const [conflictModalProperties, setConflictModalProperties] = useState({
    message: null,
    actions: {},
    type: null,
  })

  const [refresh, setRefresh] = useState(0)
  async function addTag({ id, start, end, forceAdd, item: tag }) {
    setSelectedTag(tag)
    setIsAddingTagToPap({ ...isAddingTagToPap, [id]: true })
    try {
      await dispatch(
        addTagToPap({ papId: profileId, tagId: id, start, end, forceAdd })
      )
      setIsAddingTagToPap({ ...isAddingTagToPap, [id]: false })
      setRefresh(refresh + 1)
    } catch (error) {
      if (!forceAdd) {
        handleTagConflict({ error, id, tag, start, end })
      }
    }
  }

  function handleTagConflict({ error, id, tag, start, end }) {
    setIsAddingTagToPap({ ...isAddingTagToPap, [id]: false })
    const details = get(error, 'response.data.details')
    const { profileApplicationNames, usernameSet, tagName } = details
    let errorMessage = (
      <Fragment>
        <p>
          User <b>{usernameSet[0]}</b> is directly assigned to the following
          profile(s):
        </p>
        {Object.keys(profileApplicationNames).map(profile => (
          <p key={profile}>
            Profile <b>{profile}</b> in Application{' '}
            <b>{profileApplicationNames[profile]}</b>
          </p>
        ))}
        <p>
          where the tag <b>{tagName}</b> is active
        </p>
      </Fragment>
    )

    const code = get(error, 'response.data.errorCode')

    const closeProperties = {
      message: null,
      actions: {},
      type: null,
      isOpen: false,
    }

    const closeAction = () => updateConflictMessageModal(closeProperties)
    let actions = { ok: closeAction }

    if (code === USER_ASSIGNED_DIRECTLY_TO_PAP_EXCEPTION_CODE) {
      const message = (
        <Fragment>
          {errorMessage}
          <p>Do you want to force remove common user associations?</p>
        </Fragment>
      )
      actions = {
        ok: async () => {
          await addTag({ id, start, end, forceAdd: true, tag })
          closeAction()
        },
        cancel: closeAction,
        close: closeAction,
      }
      updateConflictMessageModal({
        isOpen: true,
        message,
        type: 'Tag',
        actions,
      })
    }
  }

  async function editTag({ id, start, end }) {
    setIsAddingTagToPap({ ...isAddingTagToPap, [id]: true })
    try {
      await dispatch(editTagOfPap({ papId: profileId, tagId: id, start, end }))
      setIsAddingTagToPap({ ...isAddingTagToPap, [id]: false })
      setRefresh(refresh + 1)
    } catch (error) {
      setIsAddingTagToPap({ ...isAddingTagToPap, [id]: false })
      throw error
    }
  }

  async function removeTag({ tagId }) {
    setIsRemovingTagFromPap({ ...isRemovingTagFromPap, [tagId]: true })
    await dispatch(removeTagFromPap({ papId: profileId, tagId }))
    setIsRemovingTagFromPap({ ...isRemovingTagFromPap, [tagId]: false })
    setRefresh(refresh + 1)
  }

  function toggleAddTagModal() {
    setIsAddTagModalOpen(!isAddTagModalOpen)
    isAddTagModalOpen && setRefresh(refresh + 1)
  }

  function toggleAddTimePeriodModal(tag) {
    setIsAddTimePeriodTagOpen(!isAddTimePeriodTagOpen)
    setSelectedTag(tag)
  }

  function toggleEditTimePeriodModal(tag) {
    setIsEditTimePeriodTagOpen(!isEditTimePeriodTagOpen)
    setSelectedTag(tag)
  }

  function updateConflictMessageModal({ message, actions, type, isOpen }) {
    setConflictModalProperties({
      message,
      actions,
      type,
    })

    setIsConflictMessageOpen(isOpen)
  }

  return (
    <PapTags
      addTag={addTag}
      app={app}
      conflictModalProperties={conflictModalProperties}
      editTag={editTag}
      maxHeight={maxHeight}
      isAddTagModalOpen={isAddTagModalOpen}
      isAddTimePeriodTagOpen={isAddTimePeriodTagOpen}
      isAddingTagToPap={isAddingTagToPap}
      isConflictMessageOpen={isConflictMessageOpen}
      isEditTimePeriodTagOpen={isEditTimePeriodTagOpen}
      isRemovingTagFromPap={isRemovingTagFromPap}
      papId={profileId}
      removeTag={removeTag}
      selectedTag={selectedTag}
      toggleAddTagModal={toggleAddTagModal}
      toggleAddTimePeriodModal={toggleAddTimePeriodModal}
      toggleDrawer={toggleDrawer}
      toggleEditTimePeriodModal={toggleEditTimePeriodModal}
      refresh={refresh}
      thisAppManage={thisAppManage}
    />
  )
}

export default connect()(withRouter(memo(PapTagsContainer)))
