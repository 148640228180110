import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Tooltip from 'react-tooltip'
import startCase from 'lodash/startCase'
import kebabCase from 'lodash/kebabCase'
import isEmpty from 'lodash/isEmpty'

import AdminDetails from './Details'

import { fetchEnvironments } from 'action_creators/environment'
import { selectApplicationRootId } from 'action_creators/application_root'
import { selectPap } from 'routes/admin/state/actions'

import { getSelectedApplicationEnvironments } from 'selectors/environment'

import { FEATURE_FLAGS } from 'reducers/feature_flag'
import { getFeatureFlagById } from 'selectors/feature_flag'

import * as routing from 'services/routing'
import toast from 'utils/toast'
import ProfileHeaderButtons from './ProfileHeaderButtons'
import { ProfileToolbar } from './ProfileToolbar'

class AdminDetailsContainer extends Component {
  state = {
    activeTab: '',
    isDrawerOpen: false,
    drawerData: {},
    step: 1,
    selectPolicy: [],
    profileListFilter: {},
  }

  drawerRef = React.createRef()

  async componentDidMount() {
    const {
      appId,
      dispatch,
      match,
      setBreadcrumbTrail,
      selectedApp,
      requestAccess,
      location: locationData,
    } = this.props
    const { catalogAppDisplayName: appName } = selectedApp || {}
    const { profileId, tab } = match.params

    setBreadcrumbTrail([
      { title: `${appName} Profiles`, path: 'profiles' },
      { title: `Profile Details` },
    ])

    dispatch(selectApplicationRootId(appId))
    dispatch(selectPap(profileId))
    await dispatch(fetchEnvironments(appId, requestAccess))

    this.setState({ activeTab: startCase(tab) })
    const notification = this.props.location?.notification
    notification && toast(notification)

    this.setState({ profileListFilter: locationData?.state })
  }

  componentDidUpdate(prevProps) {
    const { selectedApp, pap = {}, history, requestAccess } = this.props
    if (
      !requestAccess &&
      !isEmpty(selectedApp) &&
      !isEmpty(pap) &&
      selectedApp.catalogAppDisplayName !== pap.catalogAppDisplayName
    ) {
      history?.push(`/admin/applications/${selectedApp.appContainerId}/profiles`)
    }

    if (this.props.match.params.tab !== prevProps.match.params.tab) {
      this.setState({ activeTab: startCase(this.props.match.params.tab) })
    }
  }

  componentWillUnmount() {
    if (this.drawerRef.current) {
      this.drawerRef.current.removeEventListener('scroll', this.hideTooltip)
    }
  }

  drawerRefCallback = node => {
    if (node) {
      node.addEventListener('scroll', this.hideTooltip)
      this.drawerRef.current = node
    } else if (this.drawerRef.current) {
      this.drawerRef.current.removeEventListener('scroll', this.hideTooltip)
      this.drawerRef.current = node
    }
  }

  hideTooltip = () => {
    Tooltip.hide()
  }

  toggleDrawer = async data => {
    if (!this.state.isDrawerOpen) {
      this.setState({ isDrawerOpen: true, drawerData: data })
    } else {
      await this.setState({ isDrawerOpen: false })

      if (data.drawerId && data.drawerId !== this.state.drawerData.drawerId) {
        setTimeout(
          () =>
            this.setState({
              drawerData: data,
              isDrawerOpen: true,
            }),
          500
        )
      }
    }
  }

  selectTab = tab => {
    const { appId, match, location: locationData } = this.props
    const { profileId } = match.params
    const tabUrl = kebabCase(tab)

    this.setState({ activeTab: tab, isDrawerOpen: false, drawerData: {} })
    if (this?.props?.requestAccess && !this.props.requestApprovals) {
      const mode = window.location.pathname.split('/').pop()
      routing.requestAccessProfileDetails({
        appId,
        profileId,
        tab: tabUrl,
        mode:
          mode === 'request'
            ? 'request'
            : mode === 'create'
            ? 'create'
            : mode === 'draft'
            ? 'draft'
            : false,
      })
    } else if (this.props.requestApprovals) {
      const [, source, , requestId] = window.location.pathname.split('/')
      routing.approvalRequestDetails({
        source,
        requestId,
        appId,
        profileId,
        tab: tabUrl,
      })
    } else {
      routing.profileDetails({
        appId,
        profileId,
        tab: tabUrl,
        state: locationData?.state,
      })
    }
  }

  onRequest = (tab, mode) => {
    const { appId, match } = this.props
    const { profileId } = match.params
    const tabUrl = kebabCase(tab)

    this.setState({ activeTab: tab, isDrawerOpen: false, drawerData: {} })
    routing.requestAccessProfileDetails({
      appId,
      profileId,
      tab: tabUrl,
      mode: mode,
    })
  }

  onStepperChange = num => {
    this.setState({ step: num })
  }

  onSelectPolicy = data => {
    this.setState({ selectPolicy: data })
  }

  render() {
    const {
      selectedApp,
      appHeaderHeight,
      selectedEnvironmentData,
      setBreadcrumbTrail,
      match,
      thisAppManage,
      profileV1,
      requestAccess,
      appId,
      dispatch,
      requestApprovals = false,
    } = this.props

    const { activeTab, isDrawerOpen, drawerData, profileListFilter } = this.state

    return (
      <>
        {!requestAccess && !requestApprovals && (
          <ProfileToolbar
            appId={appId}
            profileListFilter={profileListFilter?.searchParams}
          />
        )}
        {requestAccess && !requestApprovals && (
          <ProfileHeaderButtons
            onRequest={this.onRequest}
            appId={appId}
            papId={match.params.profileId}
            onStepperChange={this.onStepperChange}
            step={this.state.step}
            selectedPolicy={this.state.selectPolicy}
            dispatch={dispatch}
          />
        )}
        {this.state.step !== 2 && (
          <AdminDetails
            activeTab={activeTab}
            appHeaderHeight={appHeaderHeight}
            drawerData={drawerData}
            drawerRefCallback={this.drawerRefCallback}
            isDrawerOpen={isDrawerOpen}
            onCancel={this.onCancel}
            selectTab={this.selectTab}
            selectedApp={selectedApp}
            selectedEnvironmentData={selectedEnvironmentData}
            toggleDrawer={this.toggleDrawer}
            setBreadcrumbTrail={setBreadcrumbTrail}
            profileId={match.params.profileId}
            thisAppManage={thisAppManage}
            profileV1={profileV1}
            requestAccess={requestAccess}
            requestApprovals={requestApprovals}
            onSelectPolicy={this.onSelectPolicy}
            selectedPolicy={this.state.selectPolicy}
            requestedPolicy={this.props.requestedPolicy}
          />
        )}
      </>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    selectedApp: state.applicationRoot.applicationRoots[props.appId],
    selectedEnvironmentData: getSelectedApplicationEnvironments(state),
    profileV1: getFeatureFlagById({
      id: FEATURE_FLAGS.profileV1,
      state,
    }),
    pap: state.paps?.pap,
  }
}

const withConnect = connect(mapStateToProps)

export default compose(withConnect)(AdminDetailsContainer)
