import { useState } from 'react'
import isEmpty from 'lodash/isEmpty'

// Hook
export default function useCheckboxState(dataIdentifier, listItems) {
  const [checkedItems, setCheckedItems] = useState({})
  const [dataOverride, setDataOverride] = useState(null)

  function toggleCheckbox(itemId, name) {
    const newCheckedItems = { ...checkedItems }
    if (checkedItems[itemId]) {
      delete newCheckedItems[itemId]
    } else {
      newCheckedItems[itemId] = name
    }
    setCheckedItems(newCheckedItems)
  }

  function toggleAllCheckboxes(nameField) {
    const newCheckedItems = { ...checkedItems }
    const newListItems = dataOverride || listItems

    if (!checkItemsStatus('every')) {
      newListItems.every(
        item => (newCheckedItems[item[dataIdentifier]] = item[nameField])
      )
    } else {
      newListItems.every(item => delete newCheckedItems[item[dataIdentifier]])
    }

    setCheckedItems(newCheckedItems)
  }

  function clearCheckedItems() {
    setCheckedItems({})
  }

  function checkItemsStatus(iterator) {
    const newListItems = dataOverride || listItems

    if (iterator === 'some') {
      return !isEmpty(checkedItems)
    }
    return (
      newListItems.length &&
      newListItems[iterator](item => !!checkedItems[item[dataIdentifier]])
    )
  }

  return {
    checkedItems,
    dataOverride,
    checkItemsStatus,
    toggleAllCheckboxes,
    toggleCheckbox,
    clearCheckedItems,
    setCheckedItems,
    setDataOverride,
  }
}
