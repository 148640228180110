import React, { Fragment, memo } from 'react'
import styled from 'styled-components'
import Spinner from 'britive-ui-components/core/components/Spinner'
import TableView from 'components/table/TableV2/TableView'
import TableDropdownCell from 'components/table/TableV2/TableDropdownCell'
import TableEllipsisCell from 'components/table/TableV2/TableEllipsisCell'
import ActivityDropdown from './ActivityDropdown'

import useMaxTableHeight from 'hooks/useMaxTableHeight'

import { formatDateLongWithYear } from 'utils/format_date'

const COLUMNS = [
  {
    expander: true,
    Expander: props => <TableDropdownCell {...props} />,
  },
  { Header: 'Time', accessor: 'eventTime', Cell: FormattedTime, minWidth: 150 },
  { Header: 'Event Name', accessor: 'eventName', minWidth: 150 },
  { Header: 'Event Source', accessor: 'eventSource', minWidth: 150 },
  { Header: 'Event Type', accessor: 'eventType', minWidth: 150 },
  { Header: 'Source IP Address', accessor: 'sourceIPAddress' },
]

const Wrapper = styled.div`
  padding: 8px;
`

const styles = {
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: 10,
  },
  flex: { display: 'flex' },
  showMoreWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    width: '100%',
    left: 0,
    background: '#fff',
    height: 28,
  },
  showMore: {
    cursor: 'pointer',
    textDecoration: 'underline',
    marginRight: 2,
  },
}

function FormattedTime({ value: eventTime }) {
  if (!eventTime) {
    return null
  }

  return (
    <TableEllipsisCell value={formatDateLongWithYear({ dateTime: eventTime })} />
  )
}

function SessionActivity(props) {
  const maxTableHeight = useMaxTableHeight({
    heights: [
      // TODO: shortcut magic numbers here
      // need to do this programmatically
      props.appHeaderHeight,
      16,
    ],
    minHeight: 100,
  })

  const getTableColumns = () => {
    const { nextPageURL } = props
    const newColumns = [...COLUMNS]

    if (nextPageURL) {
      const lastColumn = newColumns[newColumns.length - 1]

      newColumns[newColumns.length - 1] = {
        ...lastColumn,
        Footer: renderShowMoreRow,
      }
    }

    return newColumns
  }

  const renderShowMoreRow = () => {
    const { isFetchingActivity, fetchMoreActivites } = props
    return (
      <div style={styles.showMoreWrapper}>
        <span onClick={fetchMoreActivites} style={styles.showMore}>
          Show More
        </span>
        <span>{isFetchingActivity && <Spinner />}</span>
      </div>
    )
  }

  const { isFetchingActivity, sessionActivity } = props

  if (!sessionActivity) {
    return null
  }

  return (
    <Wrapper>
      {isFetchingActivity && !sessionActivity.length ? (
        <Fragment>
          <Spinner size="2x" />
          <span>Loading Activity</span>
        </Fragment>
      ) : (
        <div>
          <TableView
            columns={getTableColumns()}
            data={sessionActivity}
            maxHeight={`${maxTableHeight}px`}
            emptyTableMessage="No Activity for This Session"
            defaultSorted={[{ id: 'eventTime', desc: false }]}
            DropdownComponent={ActivityDropdown}
          />
        </div>
      )}
    </Wrapper>
  )
}

export default memo(SessionActivity)
