import React from 'react'
import { useSelector } from 'react-redux'
import get from 'lodash/get'
import Associations from 'components/Associations'
import { getSelectedApplicationRoot } from 'selectors/application_root'
import { getCheckedNodes } from '../utils'
import {
  getApplicationEnvironments,
  getSelectedApplicationEnvironmentGroups,
  getSelectedApplicationRootGroupId,
  getSelectedApplicationEnvironments,
} from 'selectors/environment'

export const AssociationsTree = ({
  onChange,
  associations,
  hideCheckboxes = false,
  hideDropdownFilter = false,
  hideUnselectedAssociationType = false,
}) => {
  // Data from the redux.
  const environmentData = useSelector(state =>
    getSelectedApplicationEnvironments(state)
  )
  const environments = useSelector(state => getApplicationEnvironments(state))
  const applicationRoot = useSelector(state => getSelectedApplicationRoot(state))
  const rootGroupId = useSelector(state => getSelectedApplicationRootGroupId(state))
  const environmentGroups = useSelector(state =>
    getSelectedApplicationEnvironmentGroups(state)
  )

  const getRootNodeName = () => {
    let rootName = get(applicationRoot, 'catalogAppDisplayName')

    if (get(applicationRoot, 'catalogApplication.requiresHierarchicalModel')) {
      rootName = get(environmentGroups, [rootGroupId], {}).name || rootName
    }

    return rootName
  }

  const handleAssociationSelection = checkedNodes => {
    const newAssociations = []
    checkedNodes.forEach(itemKey => {
      if (environments[itemKey]) {
        newAssociations.push({
          type: 0,
          id: itemKey,
        })
      } else if (environmentGroups[itemKey]) {
        newAssociations.push({
          type: 1,
          id: itemKey,
        })
      }
    })

    onChange(newAssociations)
  }

  return (
    <Associations
      environmentGroups={environmentGroups}
      environments={environments}
      environmentData={environmentData}
      defaultDirectory={applicationRoot?.appContainerId}
      applicationRoot={{
        id: rootGroupId,
        name: getRootNodeName(),
        appIcon: applicationRoot?.iconUrl,
      }}
      checkedNodes={getCheckedNodes(associations)}
      onChange={handleAssociationSelection}
      hideCheckboxes={hideCheckboxes}
      hideDropdownFilter={hideDropdownFilter}
      hideUnselectedAssociationType={hideUnselectedAssociationType}
      appName={get(applicationRoot, 'catalogAppName')}
    />
  )
}
