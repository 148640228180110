import React, { memo, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'

import ScheduledScanning from './ScheduledScanning'

import { updateMessageModal } from 'action_creators/message_modal'

import {
  getSelectedApplicationEnvironments,
  getApplicationEnvironments,
  getSelectedApplicationEnvironmentGroups,
  getSelectedApplicationRootGroupId,
} from 'selectors/environment'

import * as api from 'services/api'

import { convertUTCDataToLocalData } from './utils'

import { HOURLY, DAILY, WEEKLY, MONTHLY } from './ScanDrawer'

const ENVIRONMENT_SCANNER = 'environmentScanner'

const INTERVAL_RANKING_DICT = {
  [HOURLY]: 1,
  [DAILY]: 2,
  [WEEKLY]: 3,
  [MONTHLY]: 4,
}

function ScheduledScanningContainer({
  appRoot,
  createNewMode,
  dispatch,
  environments,
  environmentGroups,
  environmentData,
  fields,
  rootGroupId,
  thisAppManage,
}) {
  const [isScanDataLoaded, setIsScanDataLoaded] = useState(false)
  const [scheduledScans, setScheduledScans] = useState([])
  const [scannerData, setScannerData] = useState(null)
  const [isScanEnabled, setIsScanEnabled] = useState(false)
  const [isEditable, setIsEditable] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [isScanDrawerOpen, setIsScanDrawerOpen] = useState(false)
  const [selectedScanData, setSelectedScanData] = useState(null)
  const [deletingStatus, setDeletingStatus] = useState({})

  useEffect(() => {
    async function getScanner() {
      const appId = get(appRoot, 'appContainerId')

      if (!appId) {
        setIsScanDataLoaded(true)
        return
      }

      try {
        const serviceResponse = await api.getService({
          appId,
          type: ENVIRONMENT_SCANNER,
        })

        setScannerData(serviceResponse.data)
        setIsScanEnabled(serviceResponse.data.enabled)
        const serviceId = serviceResponse.data.taskServiceId

        const tasksResponse = await api.getTasks({ serviceId })
        const formmattedValues = tasksResponse.data.map(datum => {
          const convertDate = convertUTCDataToLocalData(datum)
          const frequencyInterval = get(convertDate, 'frequencyInterval.value', 0)

          return {
            ...datum,
            sortData: {
              frequencyInterval: frequencyInterval === 7 ? 0 : frequencyInterval,
              frequencyType: INTERVAL_RANKING_DICT[convertDate.frequencyType],
              startTime: convertDate.startTime,
            },
          }
        })
        setScheduledScans(formmattedValues)
      } catch (error) {
        console.log(error)
      }

      setIsScanDataLoaded(true)
    }

    getScanner()
  }, [appRoot])

  const formattedScans = useMemo(() => {
    const formmattedValues = scheduledScans.map(scan => {
      const convertDate = convertUTCDataToLocalData(scan)
      const frequencyInterval = get(convertDate, 'frequencyInterval.value', 0)

      return {
        ...scan,
        sortData: {
          frequencyInterval: frequencyInterval === 7 ? 0 : frequencyInterval,
          frequencyType: INTERVAL_RANKING_DICT[convertDate.frequencyType],
          startTime: convertDate.startTime,
        },
      }
    })

    return formmattedValues
  }, [scheduledScans])

  function onChange() {
    setIsScanEnabled(!isScanEnabled)
  }

  function onCancel() {
    setIsScanEnabled(scannerData.enabled)
    toggleEditMode()
  }

  function toggleEditMode() {
    setIsEditable(!isEditable)
  }

  function toggleScanDrawer(data = {}) {
    if (
      !appRoot.catalogApplication.requiresHierarchicalModel &&
      !get(appRoot, 'rootEnvironmentGroup')
    ) {
      dispatch(
        updateMessageModal({
          header: 'Scheduled Scans',
          body: 'There are no environments to scan.',
        })
      )

      return
    }
    setIsScanDrawerOpen(!isScanDrawerOpen)
    setSelectedScanData(data.scanData || null)
  }

  function updateScheduledScan({ isNewScan, data }) {
    let oldScans = [...scheduledScans]

    if (!isNewScan) {
      oldScans = scheduledScans.filter(scan => scan.taskId !== data.taskId)
    }

    setScheduledScans([...oldScans, data])
  }

  async function deleteScan({ scanId }) {
    const { taskServiceId: serviceId } = scannerData

    try {
      setDeletingStatus({ ...deletingStatus, [scanId]: true })
      await api.deleteTask({ serviceId, taskId: scanId })
      const updatedScans = [...scheduledScans]
      setScheduledScans(updatedScans.filter(scan => scan.taskId !== scanId))
    } catch (error) {
      console.log(error)
    }

    setDeletingStatus({ ...deletingStatus, [scanId]: false })
  }

  async function onSubmit() {
    const { taskServiceId: serviceId } = scannerData
    let response

    try {
      setIsSaving(true)
      response = await api.editServiceStatus({
        serviceId,
        status: isScanEnabled,
      })
      setScannerData(response.data)
    } catch (error) {
      console.log(error)
    }

    setIsEditable(false)
    setIsSaving(false)
    return response
  }

  return (
    <ScheduledScanning
      updateScheduledScan={updateScheduledScan}
      app={appRoot}
      createNewMode={createNewMode}
      deleteScan={deleteScan}
      deletingStatus={deletingStatus}
      editable={isEditable}
      environmentGroups={environmentGroups}
      environments={environments}
      environmentData={environmentData}
      fields={fields}
      isLoading={!isScanDataLoaded}
      isScanDrawerOpen={isScanDrawerOpen}
      isScanEnabled={isScanEnabled}
      onCancel={onCancel}
      onChange={onChange}
      onSubmit={onSubmit}
      saving={isSaving}
      scannerData={scannerData}
      scheduledScans={formattedScans}
      selectedScanData={selectedScanData}
      toggleEditMode={toggleEditMode}
      toggleScanDrawer={toggleScanDrawer}
      rootGroupId={rootGroupId}
      thisAppManage={thisAppManage}
    />
  )
}

const mapStateToProps = state => ({
  environmentGroups: getSelectedApplicationEnvironmentGroups(state),
  environments: getApplicationEnvironments(state),
  environmentData: getSelectedApplicationEnvironments(state),
  rootGroupId: getSelectedApplicationRootGroupId(state),
})

export default connect(mapStateToProps)(memo(ScheduledScanningContainer))
