import get from 'lodash/get'
import * as actions from 'actions'

const initialState = {
  loading: false,
  error: null,
  data: [],
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case `${actions.MY_RESOURCES_GET_AVAILABLE_TEMPLATES}_PENDING`: {
      return {
        data: [],
        loading: true,
        error: null,
      }
    }

    case `${actions.MY_RESOURCES_GET_AVAILABLE_TEMPLATES}_FULFILLED`: {
      const response = get(action, 'payload.data', [])

      return {
        loading: false,
        error: null,
        data: response,
      }
    }

    case `${actions.MY_RESOURCES_GET_AVAILABLE_TEMPLATES}_REJECTED`: {
      return {
        data: [],
        loading: false,
        error: 'Unable to fetch the response templates.',
      }
    }

    default:
      return { ...state }
  }
}
