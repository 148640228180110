import React, { useState, useEffect } from 'react'
import Textfield from 'britive-design-system/core/components/textfield'
import Button from 'britive-design-system/core/components/button'

import { isEmpty } from 'lodash'
import toast from 'utils/toast'
import { isDuplicatePropertyInList, getUpdatedList } from '../utils'

const CreateTag = ({ addHandler, tagsList, updatedTags }) => {
  const [key, setKey] = useState('')
  const [value, setValue] = useState('')

  useEffect(() => {
    if (!isEmpty(updatedTags)) {
      const { key, value } = updatedTags
      setKey(key)
      setValue(value)
    }
  }, [JSON.stringify(updatedTags)])

  const addOrUpdateTag = () => {
    const newTag = { key, value }

    if (isDuplicatePropertyInList(tagsList, 'key', newTag?.key, updatedTags)) {
      toast({
        title: 'Duplicate key found. Cannot add/update tag.',
        type: 'error',
        time: 'normal',
      })
    } else {
      if (isEmpty(updatedTags)) {
        addHandler([...tagsList, newTag])
        toast({
          title: 'Tag added successfully.',
          type: 'success',
          time: 'normal',
        })
      } else {
        // Update the existing tag
        const updatedTagsList = getUpdatedList(
          tagsList,
          'key',
          updatedTags?.key,
          newTag
        )
        addHandler(updatedTagsList)
        toast({
          title: 'Tag updated successfully.',
          type: 'success',
          time: 'normal',
        })
      }
      setKey('')
      setValue('')
    }
  }

  const isAddBtnDisabled = () => {
    return isEmpty(key) || key?.length > 128 || value?.length > 128
  }

  return (
    <div>
      <Textfield
        width={270}
        label={'Key'}
        value={key}
        onChange={e => setKey(e.target.value)}
        error={key?.length > 128}
        errorMsg="Key must be between 1 and 128 characters"
      />
      <br />
      <Textfield
        width={270}
        label={'Value'}
        value={value}
        onChange={e => setValue(e.target.value)}
        error={value?.length > 128}
        errorMsg="Value must be between 1 and 128 characters"
      />
      <br />
      <Button
        size="medium"
        variant="primary"
        onClick={() => addOrUpdateTag()}
        disabled={isAddBtnDisabled()}
      >
        {isEmpty(updatedTags) ? 'Add' : 'Update'}
      </Button>
    </div>
  )
}

export default CreateTag
