import React from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import Spinner from 'britive-ui-components/core/components/Spinner'
import Activity from './AppActivityContainer'
import SessionActivity from './components/SessionActivity'

import { FEATURE_FLAGS } from 'reducers/feature_flag'

import { getFeatureFlagById } from 'selectors/feature_flag'

function ActivityRouter(props) {
  const {
    featuresLoaded,
    match,
    activitiesEnabled,
    setBreadcrumbTrail,
    appHeaderHeight,
  } = props

  if (!featuresLoaded) {
    return <Spinner size="2x" />
  }

  return (
    <Switch>
      <Route
        path={`${match.url}/activity/:transactionId`}
        render={props =>
          activitiesEnabled ? (
            <SessionActivity
              {...props}
              appHeaderHeight={appHeaderHeight}
              appId={match.params.appId}
              setBreadcrumbTrail={setBreadcrumbTrail}
            />
          ) : (
            <Redirect to={`${match.url}/overview`} />
          )
        }
      />

      <Route
        exact
        path={`${match.url}/activity`}
        render={props =>
          activitiesEnabled ? (
            <Activity
              {...props}
              appHeaderHeight={appHeaderHeight}
              match={match}
              setBreadcrumbTrail={setBreadcrumbTrail}
            />
          ) : (
            <Redirect to={`${match.url}/overview`} />
          )
        }
      />
    </Switch>
  )
}

const mapStateToProps = state => ({
  activitiesEnabled: getFeatureFlagById({
    id: FEATURE_FLAGS.activityMonitoring,
    state,
  }),
  featuresLoaded: state.features.fetchedFeatureFlags,
})

export default connect(mapStateToProps)(ActivityRouter)
