import Cookies from 'js-cookie'
import { createStandaloneToast } from '@chakra-ui/react'

export function getIsAuthenticated() {
  return !!Cookies.get('signInMethod')
}

export function clearAuth() {
  const standAloneToast = createStandaloneToast()
  standAloneToast.closeAll()
  Cookies.remove('signInMethod')
}
