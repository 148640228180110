import { SystemAnnouncementDataTypes } from './constant'

const systemAnnouncementInitialState = {
  data: [],
  loading: false,
  open: false,
}

export const systemAnnouncementReducer = (
  state = systemAnnouncementInitialState,
  action
) => {
  switch (action.type) {
    case SystemAnnouncementDataTypes.OPEN_SYSTEM_ANNOUNCEMENT:
      return {
        ...state,
        open: action.payload,
      }

    case SystemAnnouncementDataTypes.SYSTEM_ANNOUNCEMENT_REQUEST:
      return {
        ...state,
      }

    case SystemAnnouncementDataTypes.SYSTEM_ANNOUNCEMENT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }

    default:
      return state
  }
}
