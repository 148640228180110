import { fetchIfLoggedIn } from '../utils.js'
import * as actions from 'actions'

export const getCustomAttributes = filter => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.GET_CUSTOM_ATTRIBUTES,
      method: 'get',
      path: `/users/attributes${filter ? `?filter=${filter}` : ''}`,
    })
  )
}

export const getCustomAttribute = id => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.GET_CUSTOM_ATTRIBUTE,
      method: 'get',
      path: `/users/attributes/${id}`,
    })
  )
}

export const deleteCustomAttribute = id => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.DELETE_CUSTOM_ATTRIBUTE,
      meta: { id },
      method: 'delete',
      path: `/users/attributes/${id}`,
    })
  )
}

export const getCustomAttributeTypes = () => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.GET_CUSTOM_ATTRIBUTE_TYPES,
      method: 'get',
      path: '/users/attributes/data-types',
    })
  )
}

export const createCustomAttribute = fields => dispatch => {
  const postBody = {
    ...fields,
  }
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.ADD_CUSTOM_ATTRIBUTE,
      method: 'post',
      path: `/users/attributes`,
      postBody,
    })
  )
}

export const updateCustomAttribute = (fields, id) => dispatch => {
  const postBody = { ...fields }

  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.UPDATE_CUSTOM_ATTRIBUTE,
      method: 'patch',
      path: `/users/attributes/${id}`,
      postBody,
    })
  )
}
