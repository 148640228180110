import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  Pagination,
  PaginationItem,
  PaginationLink,
  Button,
  ButtonGroup,
} from 'reactstrap'

const TablePagination = styled.div`
  &.Table__pagination {
    background: #f3f3f3;
    padding: 5px;
    display: flex;
    justify-content: space-between;

    & > .pagination-container {
      & > .pagination {
        margin: 0;
        & > .page-item {
          & > .page-link {
            color: var(--brand);
            padding: 8px 10px;
            width: 40px;
            border: none;
            background: transparent;
          }
          &.active > .page-link {
            background: var(--brand);
            color: var(--white);
          }
        }
      }
    }
  }

  & .btn {
    border-radius: 1px;
    margin: 0 10px;
    background: var(--periwinkle-gray);
    color: var(--brand);
    height: 34px;
    border: none;

    &.btn-primary.active,
    &.btn-primary:active {
      color: var(--white);
      background: var(--brand);
      border: none;
    }
  }
`

const PaginationComponent = props => {
  const [visiblePages, setVisiblePages] = useState([])

  useEffect(() => {
    setVisiblePages(getVisiblePages(props.page + 1, props.pages))
  }, [props.pages])

  const getVisiblePages = (page, total) => {
    let pages = []
    if (total <= 20) {
      for (let i = 1; i <= total; i++) {
        pages.push(i)
      }
    } else {
      pages = [...visiblePages]
      if (page < pages[0]) {
        pages.unshift(page)
        pages.pop()
      } else if (page > pages[pages.length - 1]) {
        pages.push(page)
        pages.shift()
      }
    }
    return pages
  }

  const changePage = page => {
    const activePage = props.page + 1
    if (page === activePage) {
      return
    }
    if (!visiblePages.includes(page)) {
      setVisiblePages(getVisiblePages(page, props.pages))
    }
    props.onPageChange(page - 1)
  }

  const activePage = props.page + 1

  return (
    <TablePagination className="Table__pagination">
      <Pagination className="pagination-container">
        <PaginationItem
          disabled={activePage === 1}
          onClick={() => {
            if (activePage === 1) return
            changePage(activePage - 1)
          }}
        >
          <PaginationLink>{'‹'}</PaginationLink>
        </PaginationItem>
        {visiblePages.map((page, index) => {
          return (
            <PaginationItem
              key={`${page}${index}`}
              active={activePage === page}
              disabled={page === -1}
              onClick={() => (page === -1 ? undefined : changePage(page))}
            >
              <PaginationLink>{page === -1 ? '...' : page}</PaginationLink>
            </PaginationItem>
          )
        })}
        <PaginationItem
          disabled={activePage === props.pages}
          onClick={() => {
            if (activePage === props.pages) return
            changePage(activePage + 1)
          }}
        >
          <PaginationLink>{'›'}</PaginationLink>
        </PaginationItem>
      </Pagination>
      <ButtonGroup>
        <span style={{ padding: 6 }}>Rows per page: </span>
        <Button
          color="primary"
          onClick={() => props.onPageSizeChange(20)}
          active={props.pageSize === 20}
        >
          20
        </Button>{' '}
        <Button
          color="primary"
          onClick={() => props.onPageSizeChange(50)}
          active={props.pageSize === 50}
        >
          50
        </Button>{' '}
        <Button
          color="primary"
          onClick={() => props.onPageSizeChange(100)}
          active={props.pageSize === 100}
        >
          100
        </Button>
      </ButtonGroup>
    </TablePagination>
  )
}

export default PaginationComponent
