import React from 'react'

import PageLoader from 'components/PageLoader'

const Admin = React.lazy(() => import('./Router'))

const AdminLoader = ({ match }) => {
  return (
    <React.Suspense fallback={<PageLoader text="" />}>
      <Admin match={match} />
    </React.Suspense>
  )
}

export default AdminLoader
