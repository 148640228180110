import React, { Fragment, memo, useRef } from 'react'
import ReactDOM from 'react-dom'
import { Modal, ModalHeader, ModalBody as UnstyledBody } from 'reactstrap'
import styled from 'styled-components'

import Button from 'britive-ui-components/core/components/Button'
import Spinner from 'britive-ui-components/core/components/Spinner'

import PageLoader from 'components/PageLoader'
import TableSection from 'components/table/tableSectionV2'
import TableEllipsisCell from 'components/table/TableV2/TableEllipsisCell'
import TableCheckboxCell from 'components/table/TableV2/TableCheckboxCell'

import { formatDateLongWithYear } from 'utils/format_date'
import useMaxTableHeight from 'hooks/useMaxTableHeight'
import { BackgroundTooltip as Tooltip } from 'components/Tooltips'

const ModalBody = styled(UnstyledBody)`
  padding-top: 0;
`

const styles = {
  scanButton: {
    marginLeft: 8,
  },
  icon: {
    marginLeft: 4,
    marginRight: 10,
    display: 'inline-block',
    textAlign: 'center',
    fontStyle: 'normal',
    fontWeight: 600,
    cursor: 'pointer',
  },
}

const customSearchTags = {
  scanTimestamp: timestamp => {
    return formatDateLongWithYear({ dateTime: timestamp })
  },
}

function ScanDate({ value }) {
  if (!value) {
    return null
  }

  return <TableEllipsisCell value={formatDateLongWithYear({ dateTime: value })} />
}

function ScanModal(props) {
  const headerRef = useRef(null)
  const bodyRef = useRef(null)

  const maxTableHeight = useMaxTableHeight({
    heightNodes: [headerRef],
  })

  function renderCheckbox(isHeader, { original: data }) {
    const {
      areAllEnvironmentsSelected,
      toggleAllCheckboxes,
      checkedItems,
      toggleCheckbox,
      isScanning,
    } = props

    const checked = isHeader ? areAllEnvironmentsSelected : checkedItems[data.id]
    const onClick = () =>
      isHeader ? toggleAllCheckboxes('name') : toggleCheckbox(data.id, data.name)

    return (
      <TableCheckboxCell
        disabled={isScanning}
        data={data}
        onClick={onClick}
        checked={checked}
      />
    )
  }

  function renderButton() {
    const {
      areAnyEnvironmentsSelected,
      isScanning,
      scanClickHandler,
      selectedCount,
    } = props
    const bodyEl = bodyRef.current
    const tableHeader = bodyEl && bodyEl.querySelector('.row > div > div')

    if (!bodyEl || !tableHeader) {
      return null
    }

    return ReactDOM.createPortal(
      <Button
        disabled={!areAnyEnvironmentsSelected}
        spinner={isScanning}
        color="primary"
        onClick={scanClickHandler}
        style={styles.scanButton}
      >
        Scan Selected ({selectedCount})
      </Button>,
      tableHeader
    )
  }

  const renderName = ({ original }) => {
    const { isScanning, checkedItems } = props

    return (
      <Fragment>
        <TableEllipsisCell style={styles.changeCell}>
          <span style={styles.changeText}>{original.name}</span>
        </TableEllipsisCell>
        {isScanning &&
          checkedItems[original.id] &&
          (original.scanSubmitted ? (
            <div
              data-for={`${original.name}`}
              data-tip="Submitted"
              style={{ ...styles.icon, color: '#ffc107' }}
            >
              <i className="far fa-check-circle" />
            </div>
          ) : (
            <div
              data-for={`${original.name}`}
              data-tip="Submitting"
              style={styles.icon}
            >
              <Spinner size="sm" />
            </div>
          ))}
        <Tooltip
          effect="solid"
          place="top"
          getContent={content => content}
          id={`${original.name}`}
        />
      </Fragment>
    )
  }

  const {
    toggleModal,
    fetching,
    clearCheckedItems,
    environments,
    setDataOverride,
  } = props

  return (
    <Modal size="lg" isOpen>
      <div ref={headerRef}>
        <ModalHeader toggle={toggleModal}>Select Environments</ModalHeader>
      </div>

      <div ref={bodyRef}>
        <ModalBody>
          {renderButton()}

          {fetching ? (
            <PageLoader text={`Loading Environments`} />
          ) : (
            <TableSection
              columns={[
                {
                  Header: 'Environment',
                  accessor: 'name',
                  fixed: 'left',
                  minWidth: 200,
                  Cell: renderName,
                },
                { Header: 'Description', accessor: 'description', minWidth: 200 },
                {
                  Header: 'Last Scanned',
                  accessor: 'scanTimestamp',
                  Cell: ScanDate,
                  minWidth: 200,
                },
              ]}
              initialColumn={{
                clearInitialColumnStatus: clearCheckedItems,
                InitialColumnComponent: props => renderCheckbox(false, props),
                InitialColumnHeaderComponent: props => renderCheckbox(true, props),
                showInitialColumn: true,
                initialColumnWidth: 24,
                fixed: 'left',
              }}
              data={environments}
              identifier="id"
              searchKeys={['name', 'description']}
              customSearchTags={customSearchTags}
              emptyTableMessage="No environments were found."
              maxHeight={`${maxTableHeight}px`}
              marginTop={4}
              sortable
              highlightSearchResults
              defaultSorted={[{ id: 'name', desc: false }]}
              setDataOverride={setDataOverride}
              showPagination
            />
          )}
        </ModalBody>
      </div>
    </Modal>
  )
}

export default memo(ScanModal)
