import { combineReducers } from 'redux'
import { FETCH_FIELDS, FETCH_RULES } from 'action_creators/ip_restriction'

const fieldsInitialState = {
  loading: false,
  data: {},
  error: null,
}

export const ipFields = (state = fieldsInitialState, action) => {
  switch (action.type) {
    case `${FETCH_FIELDS}_PENDING`: {
      return {
        ...state,
        data: {},
        error: null,
        loading: true,
      }
    }
    case `${FETCH_FIELDS}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        data: action.payload.data?.fields,
      }
    }

    case `${FETCH_FIELDS}_REJECTED`: {
      const response = action.payload?.response
      return {
        ...state,
        loading: false,
        data: {},
        error: response?.data?.message,
      }
    }

    default:
      return { ...state }
  }
}

const rulesInitialState = {
  loading: false,
  data: {},
  error: null,
}

export const ipRules = (state = rulesInitialState, action) => {
  switch (action.type) {
    case `${FETCH_RULES}_PENDING`: {
      return {
        ...state,
        error: null,
        loading: true,
      }
    }
    case `${FETCH_RULES}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      }
    }

    case `${FETCH_RULES}_REJECTED`: {
      const response = action.payload?.response
      return {
        ...state,
        loading: false,
        error: response?.data?.message,
      }
    }

    default:
      return { ...state }
  }
}

const ipRestrictionsReducer = combineReducers({
  ipFields,
  ipRules,
})

export default ipRestrictionsReducer
