import get from 'lodash/get'

import {
  FETCH_NOTIFICATIONS,
  FETCH_NOTIFICATION,
  FETCH_NOTIFICATION_RULES,
  CREATE_NOTIFICATION,
  UPDATE_NOTIFICATION,
  ENABLE_NOTIFICATION,
  REMOVE_NOTIFICATION,
  DISABLE_NOTIFICATION,
} from 'action_creators/notification'

export const notificationReducerKey = 'notification'

const initialState = {
  notifications: {},
  notificationRules: [],
  fetchingNotifications: false,
  fetchingNotification: false,
  creatingNotification: false,
  updatingNotification: false,
  fetchingNotificationRules: false,
  error: null,
}

// TODO: I do not like this redux promise pattern
// So many action types.
// also may want to separate data reducers from ui reducers
// not sure about this yet
// try something else out (thunk, sagas, etc.)
export function notificationReducer(state = initialState, action) {
  switch (action.type) {
    case `${FETCH_NOTIFICATIONS}_PENDING`:
      return {
        ...state,
        fetchingNotifications: true,
        creatingNotification: true,
      }

    case `${FETCH_NOTIFICATIONS}_FULFILLED`: {
      const notifications = {}
      get(action, 'payload.data', []).forEach(notification => {
        const id = notification.notificationId
        notifications[id] = notification
      })

      return {
        ...state,
        notifications,
        fetchingNotifications: false,
      }
    }

    case `${FETCH_NOTIFICATIONS}_REJECTED`:
      return {
        ...state,
        fetchingNotifications: false,
        error: action.payload,
      }

    case `${CREATE_NOTIFICATION}_PENDING`:
      return {
        ...state,
        creatingNotification: true,
      }

    case `${CREATE_NOTIFICATION}_FULFILLED`: {
      const notification = action.payload.data

      const notifications = {
        ...state.notifications,
        [notification.notificationId]: notification,
      }

      return {
        ...state,
        notifications,
        creatingNotification: false,
      }
    }

    case `${CREATE_NOTIFICATION}_REJECTED`:
      return {
        ...state,
        creatingNotification: false,
        error: action.payload,
      }

    case `${FETCH_NOTIFICATION}_PENDING`:
      return {
        ...state,
        fetchingNotification: true,
      }

    case `${FETCH_NOTIFICATION}_FULFILLED`: {
      const notification = action.payload.data

      const notifications = {
        ...state.notifications,
        [notification.notificationId]: notification,
      }

      return {
        ...state,
        notifications,
        fetchingNotification: false,
      }
    }

    case `${FETCH_NOTIFICATION}_REJECTED`:
      return {
        ...state,
        fetchingNotification: false,
        error: action.payload,
      }

    case `${UPDATE_NOTIFICATION}_PENDING`:
      return {
        ...state,
        updatingNotification: true,
      }

    case `${UPDATE_NOTIFICATION}_FULFILLED`: {
      const notification = action.payload.data

      const notifications = {
        ...state.notifications,
        [notification.notificationId]: notification,
      }

      return {
        ...state,
        notifications,
        updatingNotification: false,
      }
    }

    case `${UPDATE_NOTIFICATION}_REJECTED`:
      return {
        ...state,
        updatingNotification: false,
        error: action.payload,
      }

    case `${FETCH_NOTIFICATION_RULES}_PENDING`:
      return {
        ...state,
        fetchingNotificationRules: true,
      }

    case `${FETCH_NOTIFICATION_RULES}_FULFILLED`: {
      const notificationRules = action.payload.data

      return {
        ...state,
        notificationRules,
        fetchingNotificationRules: false,
      }
    }

    case `${FETCH_NOTIFICATION_RULES}_REJECTED`:
      return {
        ...state,
        fetchingNotificationRules: false,
        error: action.payload,
      }

    case `${REMOVE_NOTIFICATION}_PENDING`:
    case `${ENABLE_NOTIFICATION}_PENDING`:
    case `${DISABLE_NOTIFICATION}_PENDING`:
      return {
        ...state,
        updatingNotification: true,
      }

    case `${ENABLE_NOTIFICATION}_FULFILLED`:
    case `${DISABLE_NOTIFICATION}_FULFILLED`: {
      const notifications = { ...state.notifications }
      notifications[action.meta.notificationId] = action.payload.data

      return {
        ...state,
        notifications,
        updatingNotification: false,
      }
    }

    case `${REMOVE_NOTIFICATION}_FULFILLED`: {
      const notifications = { ...state.notifications }
      delete notifications[action.meta.notificationId]

      return {
        ...state,
        notifications,
        updatingNotification: false,
      }
    }

    case `${REMOVE_NOTIFICATION}_REJECTED`:
    case `${ENABLE_NOTIFICATION}_REJECTED`:
    case `${DISABLE_NOTIFICATION}_REJECTED`:
      return {
        ...state,
        updatingNotification: false,
        error: action.payload,
      }

    default:
      return { ...state }
  }
}
