import { compose, createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import promise from 'redux-promise-middleware'
import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import createSagaMiddleware from 'redux-saga'
import rootSaga from './root-saga'

import rootReducer from '../reducers'

export const history = createBrowserHistory()

const sagaMiddleware = createSagaMiddleware()

// redux middleware
const middleware = [routerMiddleware(history), promise(), thunk, sagaMiddleware]

const appliedMiddleware = applyMiddleware(...middleware)
let composeEnhancer = compose

// if in development allow redux dev tools to be used
if (process.env.NODE_ENV === 'development') {
  composeEnhancer = composeWithDevTools({})
}

const store = createStore(
  rootReducer(history),
  {},
  composeEnhancer(appliedMiddleware)
)

sagaMiddleware.run(rootSaga)

export default store
