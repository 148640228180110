import React, { memo, useRef } from 'react'
import styled from 'styled-components'

import { Tabs } from 'britive-ui-components/core/components/Tabs'
import Permissions from './Permissions'
import Groups from './Groups'
import Accounts from './Accounts'
import { NoBackgroundTooltip as Tooltip } from 'components/Tooltips'

import { PERMISSIONS, GROUPS, ACCOUNTS } from './ScanDetailsContainer'
import isBritiveApp from 'utils/application/isBritiveApp'

const Wrapper = styled.div`
  position: relative;
  padding: 8px;

  .tab-content {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  > .tabs .tab-list {
    margin-bottom: 0;
  }
`

function ScanDetails({
  activeTab,
  envId,
  selectTab,
  setDrawerDetails,
  drawerDetails,
  selectedApp,
}) {
  const tabsRef = useRef(null)

  const ScanDetailTabs = [
    ...(!['SalesForce', 'Snowflake', 'Snowflake Standalone'].includes(
      selectedApp.catalogAppName
    )
      ? [
          <div label={GROUPS} key={GROUPS}>
            <Groups
              envId={envId}
              setDrawerDetails={setDrawerDetails}
              drawerDetails={drawerDetails}
              selectedApp={selectedApp}
            />
          </div>,
        ]
      : []),
    <div label={ACCOUNTS} key={ACCOUNTS}>
      <Accounts
        envId={envId}
        setDrawerDetails={setDrawerDetails}
        drawerDetails={drawerDetails}
        selectedApp={selectedApp}
      />
    </div>,
  ]

  if (!isBritiveApp(selectedApp.catalogAppName)) {
    ScanDetailTabs.unshift(
      <div label={PERMISSIONS} key={PERMISSIONS}>
        <Permissions
          envId={envId}
          setDrawerDetails={setDrawerDetails}
          drawerDetails={drawerDetails}
          selectedApp={selectedApp}
        />
      </div>
    )
  }

  return (
    <Wrapper>
      <Tabs activeTab={activeTab} onSelectTab={selectTab} ref={tabsRef}>
        {ScanDetailTabs}
      </Tabs>

      <Tooltip place="bottom" effect="solid" marginY={'0'}>
        View Changes
      </Tooltip>
    </Wrapper>
  )
}

export default memo(ScanDetails)
