import React from 'react'
import styled from 'styled-components'

const AddRuleStyled = styled.div`
  opacity: ${props => (props.disabled ? 0.3 : 1)};
  color: #fff;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  background: rgb(69, 210, 170);
  border-radius: 50%;
  height: 14px;
  width: 14px;
  line-height: 16px;
  text-align: center;
  margin-left: 8px;
`

const DeleteRuleStyled = styled.div`
  opacity: ${props => (props.disabled ? 0.3 : 1)};
  color: #fff;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  background: red;
  border-radius: 50%;
  height: 14px;
  width: 14px;
  line-height: 16px;
  text-align: center;
  margin-left: 8px;
  align-self: flex-end;
`

const AddSubtractStyled = styled.div`
  opacity: ${props => (props.disabled ? 0.3 : 1)};
  background: var(--dodger-blue);
  border-radius: 8px;
  color: #fff;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  height: 16px;
  line-height: 14px;
  margin-left: 8px;
  padding: 2px 4px;
  text-align: center;
`

export function AddRule({ onClick, disabled, ...rest }) {
  return (
    <AddRuleStyled
      disabled={disabled}
      {...rest}
      onClick={disabled ? undefined : onClick}
    >
      +
    </AddRuleStyled>
  )
}

export function DeleteRule({ onClick, disabled, ...rest }) {
  return (
    <DeleteRuleStyled
      disabled={disabled}
      {...rest}
      onClick={disabled ? undefined : onClick}
    >
      -
    </DeleteRuleStyled>
  )
}

export function AddSubtractItem({ onClick, disabled, ...rest }) {
  return (
    <AddSubtractStyled
      disabled={disabled}
      {...rest}
      onClick={disabled ? undefined : onClick}
    >
      +/-
    </AddSubtractStyled>
  )
}
