import { fetchIfLoggedIn } from '../utils.js'
import * as actions from 'actions'

export const getPapPolicies = (papId, requestAccess) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.GET_POLICIES,
      method: 'get',
      path: requestAccess
        ? `/profile-requests/paps/${papId}/policies`
        : `/paps/${papId}/policies`,
    })
  )
}

export const changePolicyStatus = (papId, papPolicyId, isActive) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.CHANGE_PAP_POLICY_STATUS,
      method: 'patch',
      meta: { id: papPolicyId, isActive },
      path: `/paps/${papId}/policies/${papPolicyId}`,
      postBody: { isActive },
    })
  )
}

export const deletePapPolicy = (papId, papPolicyId, requestAccess) => dispatch => {
  return dispatch(
    fetchIfLoggedIn({
      actionType: actions.DELETE_PAP_POLICY,
      method: 'delete',
      meta: { id: papPolicyId },
      path: requestAccess
        ? `/profile-requests/paps/${papId}/policies/${papPolicyId}`
        : `/paps/${papId}/policies/${papPolicyId}`,
    })
  )
}

export const cachePapPolicyData = payload => dispatch => {
  return dispatch({
    type: `${actions.CACHE_PAP_POLICY_DATA}_FULFILLED`,
    payload,
  })
}
