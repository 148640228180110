import React from 'react'

import Typography from 'britive-design-system/core/components/typography'
import Snackbar from 'britive-design-system/core/components/snackbar'

const Form = ({ className, children, title, errorMessage, onSubmit }) => {
  return (
    <>
      <Typography variant="pageTitle">{title}</Typography>
      {errorMessage ? <Snackbar errorList={[errorMessage]} /> : <br />}
      <form className={className} onSubmit={onSubmit}>
        {children}
      </form>
    </>
  )
}

export default Form
