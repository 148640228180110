import React, { Fragment, memo, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import get from 'lodash/get'
import startCase from 'lodash/startCase'
import FontAwesome from 'react-fontawesome'
import ReactTooltip from 'react-tooltip'

import TableEllipsisCell from 'components/table/TableV2/TableEllipsisCell'
import ScanStatusCell from '../ScanStatusCell'
import PermissionsDrawer from '../PermissionsDrawer'
import AssignedDrawer from '../AssignedDrawer'
import AssignedList from '../AssignedList'
import { BackgroundTooltip as Tooltip } from 'components/Tooltips'

import { unregisterTenantAppPermissions } from 'action_creators/tenant_app'

import { FEATURE_FLAGS } from 'reducers/feature_flag'

import { getFeatureFlagById } from 'selectors/feature_flag'

import { formatDateLongWithYear } from 'utils/format_date'
import Table from 'components/table/TableV2/Table'
import Spinner from 'britive-ui-components/core/components/Spinner'
import ManagedRole from 'static/images/britive.png'

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;

  .associatedWrapper {
    display: flex;
    height: 14px;
  }

  .fa-list {
    &.disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
    cursor: pointer;
    margin-left: 8px;
  }
`

const styles = {
  tableWrapper: {
    display: 'block',
    marginTop: -32,
    marginBottom: -20,
  },
  goToParentIcon: {
    cursor: 'pointer',
    marginLeft: 8,
  },
  goToParentWrapper: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
  },
  goToParentLabel: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    flexGrow: 1,
    whiteSpace: 'nowrap',
  },
  icon: {
    marginLeft: 4,
    marginRight: 4,
    width: 20,
    display: 'inline-block',
    height: 20,
    fontSize: 12,
    textAlign: 'center',
    color: '#9b3eec',
    fontStyle: 'normal',
    fontWeight: 600,
    cursor: 'pointer',
  },
}

function AdminTenantAppDataPermissions(props) {
  const [isAssignedDrawerOpen, setIsAssignedDrawerOpen] = useState(false)
  const [isRoleDrawerOpen, setIsRoleDrawerOpen] = useState(false)
  const [selectedPermission, setSelectedPermission] = useState(null)

  const toggleAssociatedPermissionsDrawer = ({ permission = null }) => {
    if (permission?.permissionDefinition) {
      setSelectedPermission(permission)
      setIsAssignedDrawerOpen(false)
    }
    setIsRoleDrawerOpen(!!permission?.permissionDefinition)
  }

  function toggleAssignedDrawer({ permission = null }) {
    setSelectedPermission(permission)
    setIsRoleDrawerOpen(false)
    setIsAssignedDrawerOpen(!isAssignedDrawerOpen)
  }

  const renderActionIcons = permission => {
    const { appPermissionId } = permission
    const myRef = React.createRef()

    return (
      <ActionsWrapper className={`registerToggleWrapper${appPermissionId}`}>
        <div
          data-tip={`${
            permission.permissionDefinition ? 'View' : 'No'
          } Associated Permissions`}
          onClick={() => toggleAssociatedPermissionsDrawer({ permission })}
          name="list"
          ref={myRef}
          className="associatedWrapper"
          onMouseEnter={() => ReactTooltip.show(myRef.current)}
          onMouseLeave={() => ReactTooltip.hide(myRef.current)}
        >
          <FontAwesome
            name="list"
            className={`${permission.permissionDefinition ? '' : ' disabled'}`}
          />
        </div>
      </ActionsWrapper>
    )
  }

  const getParentName = data => {
    const { selectedApp } = props
    const parentId = data.scopeRegistrationId

    if (!parentId) {
      return null
    }

    const environments = get(selectedApp, 'rootEnvironmentGroup.environments', [])
    let parentData = environments.find(env => env.id === parentId)

    if (!parentData) {
      const groups = get(selectedApp, 'rootEnvironmentGroup.environmentGroups', [])
      parentData = groups.find(group => group.nativeId === parentId)
    }

    return parentData.name
  }

  const renderParentColumn = ({ original: data }) => {
    const { selectEntity } = props
    const parentId = data.scopeRegistrationId

    if (!parentId) {
      return null
    }

    const parentName = getParentName(data)

    return (
      <div style={styles.goToParentWrapper}>
        <div title={parentName} style={styles.goToParentLabel}>
          {parentName}
        </div>

        <FontAwesome
          onClick={() => selectEntity({ entityId: parentId })}
          name="external-link-alt"
          style={styles.goToParentIcon}
        />
      </div>
    )
  }

  const classNamePrefix = 'policy-list'

  const renderName = ({ original: permissionData }) => {
    const { name, privileged, source } = permissionData
    return (
      <Fragment>
        <TableEllipsisCell>
          <span>{name}</span>
        </TableEllipsisCell>
        {privileged && (
          <span>
            <div
              data-for={`${name}-privileged`}
              data-tip="High Risk"
              style={styles.icon}
            >
              <i className="fas fa-bolt" />
            </div>
          </span>
        )}
        {source === 'britive' && (
          <div
            data-for={`${name}-source`}
            data-tip="Britive Managed Permission"
            style={styles.icon}
          >
            <img src={ManagedRole} className={`${classNamePrefix}-name-high-risk`} />
          </div>
        )}
        <Tooltip
          effect="solid"
          place="left"
          getContent={content => content}
          id={`${name}-privileged`}
        />
        <Tooltip
          effect="solid"
          place="left"
          getContent={content => content}
          id={`${name}-source`}
        />
      </Fragment>
    )
  }

  const columns = useMemo(() => {
    const { selectedApp } = props
    const requiresHierarchicalModel = get(
      selectedApp,
      'catalogApplication.requiresHierarchicalModel'
    )

    const columns = [
      {
        Header: 'Name',
        accessor: 'name',
        fixed: 'left',
        Cell: renderName,
        minWidth: 200,
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        Cell: data => renderActionIcons(data.original),
        resizable: false,
        fixed: 'left',
        sortable: false,
        width: 64,
      },
      {
        Header: 'Type',
        accessor: 'type',
        minWidth: 150,
        Cell: ({ value }) => startCase(value),
      },
      {
        Header: 'Description',
        accessor: 'description',
        minWidth: 150,
      },
      {
        Header: 'Assigned',
        accessor: 'accounts',
        Cell: cellProps => (
          <AssignedList
            {...cellProps}
            onClick={() => {
              toggleAssignedDrawer({ permission: cellProps.original })
            }}
          />
        ),
        width: 150,
        sortable: false,
      },
      {
        Header: 'Created',
        accessor: 'created',
        Cell: ({ value }) => (
          <TableEllipsisCell value={formatDateLongWithYear({ dateTime: value })} />
        ),
        minWidth: 200,
      },
      {
        Header: 'Last Updated',
        accessor: 'modified',
        Cell: ({ value }) => (
          <TableEllipsisCell value={formatDateLongWithYear({ dateTime: value })} />
        ),
        minWidth: 200,
      },
      {
        Header: 'Scan Status',
        accessor: 'scanStatus',
        Cell: ScanStatusCell,
        minWidth: 150,
      },
    ]

    if (requiresHierarchicalModel) {
      columns.splice(2, 0, {
        id: 'parent',
        Header: 'Parent',
        Cell: renderParentColumn,
        accessor: ({ scopeRegistrationId }) => ({
          scopeRegistrationId,
        }),
        sortMethod: (a, b) => {
          const a_parentId = getParentName(a)
          const b_parentId = getParentName(b)
          if (a_parentId === b_parentId) {
            return 0
          }
          return a_parentId > b_parentId ? 1 : -1
        },
        minWidth: 200,
        fixed: 'left',
      })
    }

    return columns
  }, [])

  const {
    selectedApp,
    selectedEnvId,
    selectedAppId,
    selectedEnvironmentGroupId,
  } = props

  const requiresHierarchicalModel = get(
    selectedApp,
    'catalogApplication.requiresHierarchicalModel'
  )

  const permissionTypes = Object.keys(
    get(selectedApp, 'catalogApplication.supportedPermissionTypes', {})
  )
    .filter(p => p !== 'Group')
    .map(p => ({ label: startCase(p), value: p }))

  const entityId = selectedEnvId || selectedEnvironmentGroupId
  const isAws = selectedApp.catalogAppName?.toLowerCase().includes('aws')
  const filterData = [
    {
      name: 'assigned',
      value: true,
      displayText: 'Assigned',
    },
    {
      name: 'privileged',
      value: true,
      displayText: 'High Risk',
    },
  ]

  return entityId ? (
    <div className="data_permission_v2">
      <Table
        columns={columns}
        identifier="appPermissionId"
        dataUrl={`/apps/${selectedAppId}/environments/${entityId}/permissions`}
        emptyTableMessage={'No Permissions were found.'}
        params={{ includeMembers: true }}
        dropdownFilters={[
          {
            label: 'Scan Status',
            value: 'scanStatus',
            options: [
              { label: 'New', value: 'New' },
              { label: 'Updated', value: 'Updated' },
              { label: 'Unchanged', value: 'Unchanged' },
              { label: 'Deleted', value: 'Deleted' },
            ],
          },
          { label: 'Type', value: 'type', options: permissionTypes },
        ]}
        checkBoxFilters={
          isAws
            ? [
                {
                  name: 'managed',
                  value: true,
                  displayText: 'Britive Managed',
                },
                ...filterData,
              ]
            : [...filterData]
        }
        margin={40}
      />

      <PermissionsDrawer
        isOpen={isRoleDrawerOpen}
        rolePermission={selectedPermission}
        appId={selectedApp.appContainerId}
        entityId={entityId}
        toggleDrawer={permission =>
          toggleAssociatedPermissionsDrawer({ permission })
        }
        appName={selectedApp.catalogAppName}
        isAws={isAws}
      />

      <AssignedDrawer
        isOpen={isAssignedDrawerOpen}
        isHierarchical={requiresHierarchicalModel}
        toggleDrawer={permission => toggleAssignedDrawer({ permission })}
        name={get(selectedPermission, 'name')}
        tabs={
          isAssignedDrawerOpen
            ? [
                {
                  name: 'Groups',
                  dataUrl: `/apps/${selectedAppId}/environments/${entityId}/permissions/${selectedPermission.appPermissionId}/groups`,
                  nameIdentifier: 'groupName',
                },
                {
                  name: 'Accounts',
                  dataUrl: `/apps/${selectedAppId}/environments/${entityId}/permissions/${selectedPermission.appPermissionId}/accounts`,
                  nameIdentifier: 'accountName',
                },
              ]
            : []
        }
      />
    </div>
  ) : (
    <Spinner size="2x" />
  )
}

const mapStateToProps = state => ({
  registerPermissionsToggle: getFeatureFlagById({
    id: FEATURE_FLAGS.registerPermissionsToggle,
    state,
  }),
})

export default connect(mapStateToProps, { unregisterTenantAppPermissions })(
  memo(AdminTenantAppDataPermissions)
)
