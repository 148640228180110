import React from 'react'
import ReactSelect from 'react-select'

const styles = {
  basicSelect: {
    control: base => ({
      ...base,
      minHeight: 24,
    }),
    option: base => ({
      ...base,
      height: 21,
      padding: '0 12px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      cursor: 'pointer',
    }),
    menu: base => ({
      ...base,
      zIndex: 4,
    }),
    dropdownIndicator: base => ({
      ...base,
      padding: 4,
    }),
    clearIndicator: base => ({
      ...base,
      padding: 4,
    }),
    valueContainer: base => ({
      ...base,
      padding: '0px 3px',
    }),
    input: base => ({
      ...base,
      margin: 0,
      padding: 0,
    }),
  },
}

export function BasicSelect({ styles: inheritedStyles, ...rest }) {
  return (
    <ReactSelect {...rest} styles={{ ...styles.basicSelect, ...inheritedStyles }} />
  )
}
