import React, { memo, useEffect, useState } from 'react'
import { Tabs } from 'britive-ui-components/core/components/Tabs'
import Tooltip from 'britive-design-system/core/components/tooltip'
import Button from 'britive-design-system/core/components/button'

import Drawer from 'components/Drawer'
import TableSection from 'components/table/tableSectionV2'

import useMaxTableHeight from 'hooks/useMaxTableHeight'
import { getHeaderSpacing } from 'utils/elementMeasurement'
import { isAWSIDC } from '../../Profiles/components/pap-profile/utils'

import infoIcon from 'static/images/infoIcon.svg'

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '16px',
  },
  groupWrapper: {
    padding: 4,
  },
  title: {
    position: 'sticky',
    zIndex: 4,
    top: 0,
    background: '#fff',
    width: '100%',
  },
  groupPermissionList: {
    paddingLeft: 24,
  },
  pre: {
    background: '#f6f6f6',
    padding: '16px',
    borderRadius: '4px',
    border: '1px solid #cfcfcf',
    maxHeight: 'calc(100vh - 208px)',
    whiteSpace: 'pre-wrap',
    minWidth: '60%',
  },
}

function PermissionsDrawer({
  rolePermission,
  appId,
  entityId,
  toggleDrawer,
  isOpen,
  isHierarchical,
  appName,
  isAws,
}) {
  const [permissions, setPermissions] = useState(null)
  const [selectedPermission, setSelectedPermission] = useState(null)

  const maxTableHeight = useMaxTableHeight({
    heights: [116],
    minHeight: 100,
  })

  useEffect(() => {
    if (
      !!rolePermission &&
      !!appId &&
      !!entityId &&
      rolePermission.type !== 'group'
    ) {
      setPermissions(JSON.stringify(rolePermission.permissionDefinition, null, 2))
    } else {
      setPermissions(null)
    }
  }, [rolePermission, appId, entityId])

  function renderContent() {
    if (rolePermission.type === 'group' || isAWSIDC(appName)) {
      const columns = [
        {
          Header: 'Permission',
          accessor: 'name',
          minWidth: 100,
        },
        {
          Header: 'Type',
          accessor: 'type',
          minWidth: 60,
        },
      ]
      let types = new Set()
      let typesArr = []

      let permissionProperty = 'permissions'

      if (isAWSIDC(appName)) {
        permissionProperty = 'permissionDefinition'
        columns.push({
          Header: 'Policy Definition',
          accessor: 'policy',
          width: 160,
          Cell: ({ value, row }) => (
            <div>
              <Tooltip title="View Details" position="left">
                <span
                  onClick={() =>
                    setSelectedPermission({
                      name: row.name,
                      permissionDefinition: value,
                    })
                  }
                  data-testid={`info-${row.name}`}
                >
                  <img src={infoIcon} style={{ height: '21px' }} />
                </span>
              </Tooltip>
            </div>
          ),
        })
      }

      rolePermission[permissionProperty].forEach(permission => {
        types.add(permission.type)
      })

      types.forEach(type => {
        typesArr.push({ label: type, value: type })
      })

      if (isHierarchical) {
        columns.splice(1, 0, {
          Header: 'Scope',
          accessor: 'inheritedScope',
          minWidth: 200,
        })
      }

      return (
        <div style={styles.groupWrapper}>
          <TableSection
            useCompoundFilter={true}
            compoundFilterCategories={[{ label: 'Type', value: 'type' }]}
            filterDataSet={{ type: typesArr }}
            columns={columns}
            data={rolePermission[[permissionProperty]]}
            marginTop={0}
            identifier="permissionId"
            emptyTableMessage="No Permissions were found."
            maxHeight={`${maxTableHeight}px`}
            sortable
            defaultSorted={[{ id: 'nativeName', desc: false }]}
          />
        </div>
      )
    }

    if (permissions) {
      return (
        <pre style={styles.pre} id="json">
          {permissions}
        </pre>
      )
    }

    return null
  }

  if (!isOpen) {
    return null
  }

  return (
    <Drawer
      isOpen={isOpen}
      top={`${getHeaderSpacing()}px`}
      id="id"
      width="50%"
      disableXScroll
      toggleDrawer={() => toggleDrawer(null)}
      zIndex={6}
    >
      {isAws && rolePermission.type !== 'group' ? (
        <Tabs>
          <div label="Permission Definition" key="permission-definition">
            <h5 style={styles.title}>
              Permissions associated with {rolePermission.name}
            </h5>

            {renderContent()}
          </div>
          <div label="Tags" key="tags">
            <TableSection
              resizable={true}
              sortable
              data={
                rolePermission?.applicationPermissionDefinitionBeans?.[0]?.tags || []
              }
              columns={[
                {
                  Header: 'Key',
                  accessor: 'key',
                },
                {
                  Header: 'Value',
                  accessor: 'value',
                },
              ]}
              defaultPageSize={10}
              emptyTableMessage="No Tags added."
            />
          </div>
        </Tabs>
      ) : (
        <>
          <h5 style={styles.title}>
            Permissions associated with {rolePermission.name}
          </h5>

          {renderContent()}
        </>
      )}
      {selectedPermission && (
        <Drawer
          isOpen={selectedPermission}
          top={`${getHeaderSpacing()}px`}
          id="permission-definition"
          width="50%"
          disableXScroll
          toggleDrawer={() => setSelectedPermission(null)}
          zIndex={6}
        >
          <div style={styles.wrapper}>
            <Button variant="secondary" onClick={() => setSelectedPermission(null)}>
              Back
            </Button>
            <h5 style={styles.title}>
              Permissions definition with {selectedPermission.name}
            </h5>
            <pre style={styles.pre} id="json">
              {JSON.stringify(selectedPermission.permissionDefinition, null, 2)}
            </pre>
          </div>
        </Drawer>
      )}
    </Drawer>
  )
}

export default memo(PermissionsDrawer)
