import React from 'react'
import PropTypes from 'prop-types'

const ErrorMessage = ({ text }) => {
  return text ? <p className="text-danger">{text}</p> : null
}

ErrorMessage.propTypes = {
  text: PropTypes.string,
}

export default ErrorMessage
