export const INPUT_STRING = 'java.lang.String'
export const INPUT_PASSWORD = 'com.britive.pab.api.Secret'
export const INPUT_TIME_DURATION = 'java.time.Duration'
export const RADIO = 'java.lang.Enum'
export const BOOLEAN = 'java.lang.Boolean'
export const INTEGER = 'java.lang.Integer'
export const FILE = 'java.io.File'
export const SECRET_FILE = 'com.britive.pab.api.SecretFile'
export const USER_ATTRIBUTE_SCHEMA = 'java.util.List<UserAttributeSchema>'

export const APP_PROPERTIES_HEADER_ID = 'appPropertiesHeader'
export const APP_PROPERTIES_TABS_ID = 'appPropertiesTabs'
