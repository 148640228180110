import React, { useEffect, useState } from 'react'
import Table from 'britive-design-system/core/components/table'
import Button from 'britive-design-system/core/components/button'
import Typography from 'britive-design-system/core/components/typography'
import Spinner from 'britive-design-system/core/components/spinner'
import './DataTable.scss'
import { isEmpty, orderBy } from 'lodash'
import {
  nextDataButtonText,
  noDataText,
  spinnerSize,
  textOnlyBtn,
  typographyCaption,
  mediumBtn,
  ascendingOrder,
  ascOrder,
  descendingOrder,
  descOrder,
  NA_TEXT,
  unsortedOrder,
  classes,
} from './constants'
import { getConsumerType } from '../../utils'

const DataTable = ({
  loadMore,
  loadMoreHandler,
  searchBar,
  rows,
  columns,
  loading,
  loadingMessage,
  sortHandler,
  inLineSort = false,
}) => {
  const [sortOrder, setSortOrder] = useState(null)
  const [sortField, setSortField] = useState(null)
  const [tableRows, setTableRows] = useState(null)

  useEffect(() => {
    setTableRows(rows)
  }, [rows])

  useEffect(() => {
    if (
      inLineSort &&
      !isEmpty(rows) &&
      [ascendingOrder, descendingOrder].includes(sortOrder) &&
      !isEmpty(sortField)
    ) {
      inLineSortHandler()
    }
  }, [rows, sortOrder, sortField])

  const inLineSortHandler = () => {
    if (!isEmpty(rows)) {
      setTableRows(tableRows =>
        orderBy(
          tableRows,
          [
            data => {
              const column = columns.find(column => column?.field === sortField)
              if (column?.isNumeric) {
                if (data?.[sortField] === NA_TEXT || data?.[sortField] === '') {
                  return Number.MAX_SAFE_INTEGER
                }
                return Number(data?.[sortField])
              }
              if (column?.isDate) {
                if (data?.[sortField]?.trim() === '') {
                  return new Date(0)
                }
                return new Date(data?.[sortField])
              }
              if (column?.field === 'type') {
                return getConsumerType(data?.consumer, data.context?.type)
              }
              if (data?.[sortField]?.trim() === '') {
                return null
              }
              return data?.[sortField]?.toLowerCase()
            },
          ],
          [sortOrder === ascendingOrder ? ascOrder : descOrder]
        )
      )
    }
  }

  const localSortHandler = (sortOrder, fieldName) => {
    setSortOrder(sortOrder === unsortedOrder ? null : sortOrder)
    setSortField(sortOrder === unsortedOrder ? null : fieldName)
  }

  const props = {
    loadMoreHandler,
    rows: tableRows,
    columns,
    loading,
    resizableColumns: true,
    sortHandler: inLineSort ? localSortHandler : sortHandler,
    searchBar,
  }

  return (
    <div className={classes.dataTableWrapper}>
      {loading && (
        <Spinner size={spinnerSize} message={loadingMessage || ''} overlay={true} />
      )}
      {<Table {...props} />}
      {((!loading && rows && rows.length === 0) || loadMore) && (
        <div
          className={`${classes.dataTableBottomPanel} ${
            loadMore ? classes.noBorder : ''
          }`}
        >
          {loadMore && (
            <Button
              variant={textOnlyBtn}
              size={mediumBtn}
              onClick={e => loadMoreHandler(e)}
            >
              {nextDataButtonText}
            </Button>
          )}
          {!loading && rows && rows.length === 0 && (
            <Typography variant={typographyCaption}>{noDataText}</Typography>
          )}
        </div>
      )}
    </div>
  )
}

export default DataTable
