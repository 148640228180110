import React from 'react'
import styled from 'styled-components'

import BritiveLogoImage from 'static/images/britive_logo.png'

const Logo = styled.img`
  height: ${props => props.height || 24}px;
  margin-bottom: ${props => props.mb || 0}px;
`

export default function BritiveLogo({ mb, height }) {
  return <Logo height={height} mb={mb} src={BritiveLogoImage} />
}
