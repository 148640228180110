import { put, takeLatest, call, all, fork, select } from 'redux-saga/effects'
import toast from 'utils/toast/index.js'
import {
  MyApprovalsDataTypes,
  ApproveRequestDataTypes,
  RejectRequestDataTypes,
  UsersType,
  translatedStrings,
} from './constants'
import { fetchMyApprovalsList, fetchUsers, approveRejectRequest } from './service'
import {
  failureMyApprovalsListLoadMore,
  loadingMyApprovalsListLoadMore,
  successMyApprovalsListLoadMore,
  loadingUsersList,
  successUsersList,
  failureUsersList,
} from '../../../../action_creators/my-approvals'

function* myApprovalsListWatcher() {
  yield takeLatest(
    MyApprovalsDataTypes.MY_APPROVALS_LIST_REQUEST,
    myApprovalsListWorker
  )
}

export function* myApprovalsListWorker({ payload = {} }) {
  const state = yield select()
  const searchTerm = state => state?.userSecretsReducer?.myApprovalsList?.searchTerm
  payload.search = searchTerm(state)

  try {
    yield put({ type: MyApprovalsDataTypes.MY_APPROVALS_LIST_LOADING })

    const response = yield call(fetchMyApprovalsList, payload)
    const { data } = response

    yield put({
      type: MyApprovalsDataTypes.MY_APPROVALS_LIST_SUCCESS,
      payload: data,
    })
  } catch ({ response }) {
    const reason = (response && response?.data && response?.data?.message) || ''
    yield put({
      type: MyApprovalsDataTypes.MY_APPROVALS_LIST_FAILURE,
      payload: reason,
    })
  }
}

function* myApprovalsListLoadMoreWatcher() {
  yield takeLatest(
    MyApprovalsDataTypes.MY_APPROVALS_LIST_LOAD_MORE_REQUEST,
    myApprovalsListLoadMoreWorker
  )
}

export function* myApprovalsListLoadMoreWorker({ payload = {} }) {
  const state = yield select()
  const searchTerm = state => state?.userSecretsReducer?.myApprovalsList?.searchTerm
  const nextPageToken = state =>
    state?.userSecretsReducer?.myApprovalsList?.pagination?.next
  payload.search = searchTerm(state)
  payload.next = nextPageToken(state)

  try {
    yield put(loadingMyApprovalsListLoadMore())

    const response = yield call(fetchMyApprovalsList, payload)
    const { data } = response

    yield put(successMyApprovalsListLoadMore(data))
  } catch ({ response }) {
    const reason = response?.data?.message ?? ''
    yield put(failureMyApprovalsListLoadMore(reason))
  }
}

function* approveRequestWatcher() {
  yield takeLatest(
    ApproveRequestDataTypes.APPROVE_REQUEST_REQUEST,
    approveRequestWorker
  )
}

export function* approveRequestWorker({ payload = {} }) {
  try {
    yield call(approveRejectRequest, payload)

    toast({
      title: translatedStrings.requestApproved,
      type: 'success',
      time: 'normal',
      id: translatedStrings.requestApproved,
    })
    // fetch approvals list on approve request success
    yield put({ type: MyApprovalsDataTypes.MY_APPROVALS_LIST_REQUEST })
  } catch ({ response }) {
    const reason = (response && response?.data && response?.data?.message) || ''
    toast({
      title: `${translatedStrings.requestNotApproved} ${reason}.`,
      type: 'error',
      time: 'normal',
      id: reason,
    })
  }
}

function* rejectRequestWatcher() {
  yield takeLatest(
    RejectRequestDataTypes.REJECT_REQUEST_REQUEST,
    rejectRequestWorker
  )
}

export function* rejectRequestWorker({ payload = {} }) {
  try {
    yield call(approveRejectRequest, payload)

    toast({
      title: translatedStrings.requestRejected,
      type: 'success',
      time: 'normal',
      id: translatedStrings.requestRejected,
    })
    // fetch approvals list on reject request success
    yield put({ type: MyApprovalsDataTypes.MY_APPROVALS_LIST_REQUEST })
  } catch ({ response }) {
    const reason = (response && response?.data && response?.data?.message) || ''
    toast({
      title: `${translatedStrings.requestNotRejected} ${reason}.`,
      type: 'error',
      time: 'normal',
      id: reason,
    })
  }
}

function* usersDataWatcher() {
  yield takeLatest(UsersType.APPROVALS_USERS_REQUEST, usersDataWorker)
}

function* usersDataWorker() {
  try {
    yield put(loadingUsersList())
    const response = yield call(fetchUsers)
    yield put(successUsersList(response))
  } catch ({ response }) {
    const reason = response?.data?.message || ''
    toast({
      title: reason,
      type: 'error',
      time: 'normal',
      id: reason,
    })
    yield put(failureUsersList(reason))
  }
}

export default all([
  fork(myApprovalsListWatcher),
  fork(approveRequestWatcher),
  fork(rejectRequestWatcher),
  fork(myApprovalsListLoadMoreWatcher),
  fork(usersDataWatcher),
])
