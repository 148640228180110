import * as actions from 'actions'

const createProfileInitialState = {
  loading: false,
  error: null,
}

export function createProfileReducer(state = createProfileInitialState, action) {
  switch (action.type) {
    case `${actions.PROFILE_MANAGEMENT_CREATE_PROFILE}_PENDING`: {
      return {
        ...state,
        loading: true,
        error: null,
      }
    }

    case `${actions.PROFILE_MANAGEMENT_CREATE_PROFILE}_FULFILLED`: {
      return {
        ...state,
        loading: false,
        error: null,
      }
    }

    case `${actions.PROFILE_MANAGEMENT_CREATE_PROFILE}_REJECTED`: {
      return {
        ...state,
        loading: false,
        error: 'Unable to the create Profile.',
      }
    }

    default:
      return { ...state }
  }
}
