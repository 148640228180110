import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import styled from 'styled-components'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import ReactTooltip from 'react-tooltip'

import Button from 'britive-ui-components/core/components/Button'
import { Tabs } from 'britive-ui-components/core/components/Tabs'

import Accounts from './components/accounts'
import Groups from './components/Groups'
import Permissions from './components/permissions'
import Resources from './components/Resources'
import { NoBackgroundTooltip as Tooltip } from 'components/Tooltips'
import PageLoader from 'components/PageLoader'

import {
  getTenantAppPermissions,
  getTenantAppAccounts,
  updateTenantAppAccounts,
  mapTenantAppAccountToUser,
  unmapTenantAppAccountFromUser,
} from 'action_creators/tenant_app'

import { selectApplicationRootId } from 'action_creators/application_root'

import {
  fetchEnvironmentScanReport,
  fetchEnvironments,
  scanEnvironment,
  selectEnvironmentId,
} from 'action_creators/environment'

import {
  getSelectedApplicationRoot,
  getSelectedApplicationRootId,
} from 'selectors/application_root'

import {
  getSelectedEnvironmentProperties,
  getSelectedEnvironmentData,
  getSelectedEnvironmentId,
  getSelectedEnvironmentGroupId,
  getEnvironmentScanResults,
} from 'selectors/environment'

import * as text from 'translations/english_us'
import isBritiveApp from 'utils/application/isBritiveApp'

const Wrapper = styled.div`
  padding: 8px;
  position: relative;

  .tab-content {
    padding: 10px 0 !important;
  }
`

const styles = {
  pageLoader: {
    marginLeft: 8,
  },
  scanButtonWrapper: {
    justifyContent: 'flex-end',
  },
  scanButton: {
    position: 'absolute',
    top: 8,
    right: 8,
    zIndex: 5,
  },
}

class AdminTenantAppData extends PureComponent {
  state = {
    selectedAppId: null,
    scanModalResultsOpen: false,
    scanModalsResultSuccess: null,
    scanModalResultMessage: null,
    scanInProgress: false,
    accountInUseBy: [],
    selectedAccountId: null,
    userId: null,
  }

  componentDidMount() {
    const {
      fetchScanReport,
      selectedAppId: applicationId,
      selectedApp,
      selectedEnvId: environmentId,
      selectedEnvironmentGroupId: envGroupId,
      setBreadcrumbTrail,
    } = this.props

    const { catalogAppDisplayName: appName } = selectedApp

    setBreadcrumbTrail([{ title: `${appName} Data` }])

    const entityId = environmentId || envGroupId || selectedApp.appContainerId
    const isEnvironment = !!environmentId
    const isHierarchical = selectedApp.catalogApplication.requiresHierarchicalModel

    if (!isEnvironment && !isHierarchical) {
      return
    }

    if (isEnvironment && !isHierarchical) {
      fetchScanReport(applicationId, entityId)
    }
  }

  componentDidUpdate(prevProps) {
    const {
      fetchScanReport,
      selectedApp,
      selectedAppId: applicationId,
      selectedEnvId: environmentId,
      selectedEnvironmentGroupId: envGroupId,
    } = this.props

    const newEnvId = environmentId !== prevProps.selectedEnvId && environmentId
    const newGroupId =
      envGroupId !== prevProps.selectedEnvironmentGroupId && envGroupId
    const entityId = newEnvId ? environmentId : envGroupId
    const isHierarchical = selectedApp.catalogApplication.requiresHierarchicalModel
    const isEnvironment = !!environmentId

    if (!isEnvironment && !isHierarchical) {
      return
    }

    if ((newEnvId || newGroupId) && entityId) {
      // if current entity is an environment and non-hierarchical
      if (newEnvId && environmentId && !isHierarchical) {
        fetchScanReport(applicationId, entityId)
      }
    }
  }

  hideTooltip = () => {
    ReactTooltip.hide()
  }

  toggleModal = () => {
    this.setState({
      scanModalResultsOpen: false, // this modal can only be closed
    })
  }

  onClickScanApp = async () => {
    const {
      scanApp,
      selectedAppId,
      selectedApp,
      selectedEnvId,
      fetchScanReport,
      fetchEnvironments,
      environmentScanResults,
    } = this.props

    this.setState({
      scanInProgress: true,
    })

    try {
      const scanStatus = get(
        environmentScanResults,
        [selectedAppId, selectedEnvId],
        {}
      )
      const scanInProgress = ['InProgress', 'New'].includes(scanStatus.status)

      const action = scanInProgress ? fetchScanReport : scanApp
      const response = await action(selectedAppId, selectedEnvId)

      if (response.value.data) {
        const statusString = scanInProgress ? '[0].status' : '.status'
        const status = get(response, `value.data${statusString}`)

        if (status === 'Error') {
          throw new Error('error scanning')
        }

        this.setState({
          scanModalResultsOpen: true,
          scanModalsResultSuccess: true,
          scanModalResultMessage: status,
          scanInProgress: false,
        })

        if (status === 'Success') {
          const requiresHierarchicalModel =
            selectedApp.catalogApplication.requiresHierarchicalModel

          if (!requiresHierarchicalModel) {
            await fetchEnvironments(selectedAppId)
          }
        }
      }
    } catch (error) {
      const message = get(
        error,
        'response.data.message',
        'No error message available. Try again'
      )

      this.setState({
        scanModalResultsOpen: true,
        scanModalsResultSuccess: false,
        scanModalResultMessage: message,
        scanInProgress: false,
      })
    }
  }

  isDataStillFetching = props => {
    const {
      fetchingPermissions,
      fetchingResources,
      fetching,
      selectedEnvId,
      selectedEnvironmentGroupId,
      selectedAppId,
    } = props

    const selectedEntityId =
      selectedEnvId || selectedEnvironmentGroupId || selectedAppId

    return !!(
      fetchingPermissions[selectedEntityId] ||
      fetchingResources[selectedEntityId] ||
      fetching
    )
  }

  isDataAvailable = props => {
    const {
      fetchedAccounts,
      fetchedPermissions,
      selectedAppAccounts,
      selectedAppPermissions,
    } = props

    return !(
      fetchedAccounts &&
      fetchedPermissions &&
      isEmpty(selectedAppAccounts) &&
      isEmpty(selectedAppPermissions)
    )
  }

  updateAppAccounts = ({ operation, accountIds, forceUnmapUser }) => {
    const { selectedAppId, selectedEnvId, selectedEnvironmentGroupId } = this.props
    const envId = selectedEnvId || selectedEnvironmentGroupId

    return this.props.updateAppAccounts({
      appId: selectedAppId,
      envId,
      operation,
      accountIds,
      forceUnmapUser,
    })
  }

  mapUserToAccount = async ({ accountId, envId, userId }) => {
    const { selectedAppId } = this.props

    return this.props.mapUserToAccount({
      appId: selectedAppId,
      envId,
      accountId,
      userId,
    })
  }

  unmapUserFromAccount = ({ accountId, envId, userId, forceCheckinPaps }) => {
    const { selectedAppId } = this.props

    return this.props.unmapUserFromAccount({
      appId: selectedAppId,
      envId,
      accountId,
      userId,
      forceCheckinPaps,
    })
  }

  isEnvironment = () => {
    const { selectedApp, selectedEnvId } = this.props

    const environments = get(selectedApp, 'rootEnvironmentGroup.environments', [])
    const entityId = selectedEnvId
    return environments.some(env => env.id === entityId)
  }

  isGroupTabRequired = appName => {
    const excludedApps = ['snowflake', 'salesforce']
    // The group tab is not required for the Snowflake and Salesforce. See PAB-11096 & PAB-11382
    return !excludedApps.includes(appName?.toLowerCase())
  }

  getTabs = () => {
    const {
      environmentData,
      environmentProperties,
      selectedApp,
      selectedAppId,
      selectedEnvId,
      selectedEnvironmentGroupId,
      selectEntity,
      thisAppManage,
    } = this.props

    if (!environmentData && !environmentProperties) {
      return null
    }

    let formattedEnvironment = environmentData
    const requiresHierarchicalModel =
      selectedApp.catalogApplication.requiresHierarchicalModel

    if (requiresHierarchicalModel) {
      formattedEnvironment = {
        ...environmentData,
        catalogApplication: selectedApp.catalogApplication,
      }
    }

    const tabs = [
      <div label="Accounts" key="accounts">
        <Accounts
          users={this.props.users}
          mapUserToAccount={this.mapUserToAccount}
          unmapUserFromAccount={this.unmapUserFromAccount}
          selectedEnvironment={formattedEnvironment}
          selectedApp={selectedApp}
          selectedAppId={selectedAppId}
          selectedEnvId={selectedEnvId}
          selectedEnvironmentGroupId={selectedEnvironmentGroupId}
          thisAppManage={thisAppManage}
        />
        ,
      </div>,
    ]

    if (!isBritiveApp(selectedApp?.catalogApplication?.name)) {
      tabs.unshift(
        <div label="Permissions" key="permissions">
          <Permissions
            selectedEnvironment={formattedEnvironment}
            selectedApp={selectedApp}
            selectedAppId={selectedAppId}
            selectedEnvId={selectedEnvId}
            selectedEnvironmentGroupId={selectedEnvironmentGroupId}
            selectEntity={selectEntity}
          />
        </div>
      )
    }

    if (this.isGroupTabRequired(selectedApp.catalogAppName)) {
      tabs.splice(
        1,
        0,
        <div label="Groups" key="groups">
          <Groups
            selectedEnvironment={formattedEnvironment}
            selectedApp={selectedApp}
            selectedAppId={selectedAppId}
            selectedEnvId={selectedEnvId}
            selectedEnvironmentGroupId={selectedEnvironmentGroupId}
            selectEntity={selectEntity}
          />
        </div>
      )
    }

    if (selectedApp.catalogApplication.supportsResources && this.isEnvironment()) {
      tabs.push(
        <div label="Resources" key="resources">
          <Resources selectedAppId={selectedAppId} selectedEnvId={selectedEnvId} />
        </div>
      )
    }

    return tabs
  }

  render() {
    const {
      entityType,
      environmentData,
      environmentProperties,
      selectedApp,
      thisAppManage,
    } = this.props

    const supportsScanning = get(
      selectedApp,
      'catalogApplication.supportsEnvironmentScanning'
    )

    const isHierarchical = get(
      selectedApp,
      'catalogApplication.requiresHierarchicalModel'
    )

    if (!environmentData && !environmentProperties) {
      return null
    }

    if (!selectedApp.rootEnvironmentGroup) {
      return (
        <Wrapper>
          {supportsScanning
            ? 'Please configure and scan application.'
            : 'Please create and configure an environment'}
        </Wrapper>
      )
    }

    if (
      (!isHierarchical && supportsScanning && entityType === 'application') ||
      (!supportsScanning && ['application', 'environmentGroup'].includes(entityType))
    ) {
      return <Wrapper>Please select an environment.</Wrapper>
    }

    const requiresHierarchicalModel =
      selectedApp.catalogApplication.requiresHierarchicalModel

    return (
      <Fragment>
        {this.isDataStillFetching(this.props) ? (
          <PageLoader text={text.LOADING} style={styles.pageLoader} />
        ) : (
          <Wrapper>
            {thisAppManage && !requiresHierarchicalModel && (
              <div style={styles.scanButtonWrapper}>
                <Button
                  color="primary"
                  onClick={this.onClickScanApp}
                  spinner={this.state.scanInProgress}
                  style={styles.scanButton}
                >
                  {text.SCAN}
                </Button>
              </div>
            )}

            {this.isDataAvailable(this.props) ? (
              <Tabs>{this.getTabs()}</Tabs>
            ) : (
              <div className="pt-4">
                <p>
                  Environment data is unavailable.
                  {thisAppManage &&
                    ' Please scan the environment to obtain the data.'}
                </p>
              </div>
            )}
          </Wrapper>
        )}

        <Tooltip
          place="bottom"
          effect="solid"
          marginY={'0'}
          getContent={content => content}
        />

        <Modal isOpen={!!this.state.scanModalResultsOpen}>
          <ModalHeader>
            {this.state.scanModalsResultSuccess
              ? this.state.scanModalResultMessage === 'Success'
                ? text.SUCCESS
                : 'In Progress'
              : text.SCAN_FAILURE}
          </ModalHeader>
          <ModalBody>
            <div className={this.state.scanModalsResultSuccess ? '' : 'text-danger'}>
              <p>
                <strong>
                  {text.ENVIRONMENT} {environmentData.catalogAppDisplayName}
                </strong>
              </p>

              {this.state.scanModalsResultSuccess ? (
                <p>
                  {this.state.scanModalResultMessage === 'Success'
                    ? 'Scan is successful'
                    : 'Scan is in progress'}
                </p>
              ) : (
                <Fragment>
                  <p>{text.SCAN_UNSUCCESSFUL}</p>{' '}
                  <p>Message: {this.state.scanModalResultMessage}</p>
                </Fragment>
              )}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.toggleModal}>
              {text.OK}
            </Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    )
  }
}

AdminTenantAppData.propTypes = {
  environmentData: PropTypes.object,
  environmentProperties: PropTypes.object,
  fetched: PropTypes.bool,
  fetchedAccounts: PropTypes.bool,
  fetchedPermissions: PropTypes.bool,
  fetching: PropTypes.bool,
  fetchingAccounts: PropTypes.object,
  fetchingPermissions: PropTypes.object,
  getAppAccounts: PropTypes.func,
  getAppPermissions: PropTypes.func,
  location: PropTypes.object,
  mapAppAccountToUser: PropTypes.func,
  match: PropTypes.object,
  saving: PropTypes.bool,
  scanApp: PropTypes.func,
  selectAppId: PropTypes.func,
  selectEnvId: PropTypes.func,
  selectedApp: PropTypes.object,
  selectedAppAccounts: PropTypes.object,
  selectedAppId: PropTypes.string,
  selectedAppPermissions: PropTypes.object,
  selectedEnvId: PropTypes.string,
  testApp: PropTypes.func,
  unmapUserFromAccount: PropTypes.func,
  updateAppAccounts: PropTypes.func,
  updateAppPermissions: PropTypes.func,
  users: PropTypes.array,
}

const mapStateToProps = state => {
  return {
    fetching: state.admin.fetchingSelectedApp,
    fetched: state.admin.fetchedSelectedApp,
    selectedAppAccounts: state.admin.selectedAppAccounts,
    selectedAppPermissions: state.admin.selectedAppPermissions,
    selectedAppResources: state.admin.selectedAppResources,
    scanning: state.admin.scanningTentantApp,
    fetchingPermissions: state.admin.fetchingSelectedAppPermissions,
    fetchingAccounts: state.admin.fetchingSelectedAppAccounts,
    fetchingResources: state.admin.fetchingSelectedAppResources,
    fetchedPermissions: state.admin.fetchedSelectedAppPermissions,
    fetchedAccounts: state.admin.fetchedSelectedAppAccounts,
    users: state.admin.users,
    environmentData: getSelectedEnvironmentData(state),
    environmentProperties: getSelectedEnvironmentProperties(state),
    selectedAppId: getSelectedApplicationRootId(state),
    selectedApp: getSelectedApplicationRoot(state),
    selectedEnvId: getSelectedEnvironmentId(state),
    selectedEnvironmentGroupId: getSelectedEnvironmentGroupId(state),
    environmentScanResults: getEnvironmentScanResults(state),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    scanApp: (appId, envId) => {
      return dispatch(scanEnvironment(appId, envId))
    },
    fetchScanReport: (appId, envId) => {
      return dispatch(fetchEnvironmentScanReport({ appId, envId }))
    },
    fetchEnvironments: appId => {
      return dispatch(fetchEnvironments(appId))
    },
    getAppAccounts: (appId, envId) => {
      dispatch(getTenantAppAccounts(appId, envId))
    },
    getAppPermissions: ({ appId, envId, type }) => {
      dispatch(getTenantAppPermissions({ appId, envId, type }))
    },
    selectAppId: appId => {
      dispatch(selectApplicationRootId(appId))
    },
    selectEnvId: envId => {
      dispatch(selectEnvironmentId(envId))
    },
    updateAppAccounts: ({ appId, envId, operation, accountIds, forceUnmapUser }) => {
      return dispatch(
        updateTenantAppAccounts({
          appId,
          envId,
          operation,
          accountIds,
          forceUnmapUser,
        })
      )
    },
    mapUserToAccount: ({ appId, envId, accountId, userId }) => {
      // this action name is backwards and confusing
      return dispatch(mapTenantAppAccountToUser({ appId, envId, accountId, userId }))
    },
    unmapUserFromAccount: ({
      appId,
      envId,
      accountId,
      userId,
      forceCheckinPaps,
    }) => {
      // this action name is backwards and confusing
      return dispatch(
        unmapTenantAppAccountFromUser({
          appId,
          envId,
          accountId,
          userId,
          forceCheckinPaps,
        })
      )
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminTenantAppData)
