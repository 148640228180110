import isEmpty from 'lodash/isEmpty'
import getUsersAndTagsFromMembers from 'utils/getUsersAndTagsFromMembers'

export const getUsersAndTagsFromApproversGroup = (approversGroup = []) => {
  const users = []
  const tags = []

  if (isEmpty(approversGroup)) {
    return {
      users,
      tags,
    }
  }

  const members = []

  approversGroup.forEach(group => {
    if (!isEmpty(group?.members)) {
      members.push(...(group.members || []))
    }
  })

  return getUsersAndTagsFromMembers(members)
}
