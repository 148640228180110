import {
  CLOSE_MESSAGE_MODAL,
  UPDATE_MESSAGE_MODAL,
} from 'action_creators/message_modal'
import { history } from 'store'

export const messageModalReducerKey = 'messageModal'

const initialState = {
  isOpen: false,
  body: null,
  header: null,
  afterCloseAction: null,
}

export function messageModalReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_MESSAGE_MODAL: {
      const { body, header, afterCloseAction } = action.payload
      return {
        ...state,
        isOpen: history.location.pathname !== '/access-denied',
        body,
        header,
        afterCloseAction,
      }
    }

    case CLOSE_MESSAGE_MODAL: {
      return {
        ...state,
        isOpen: false,
        body: null,
        header: null,
        afterCloseAction: null,
      }
    }

    default:
      return { ...state }
  }
}
