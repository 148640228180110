import React, { Fragment, memo, useState } from 'react'
import { NavLink } from 'react-router-dom'

import styled from 'styled-components'
import startCase from 'lodash/startCase'
import pluralize from 'pluralize'

import Card from '../Card'
import PageLoader from 'components/PageLoader'
import Toggle from 'components/toggle'

import { formatDateLongWithYear } from 'utils/format_date'
import { Col, Container, Row } from 'reactstrap'
import SummaryPie from './SummaryPie'
import SummaryBar from './SummaryBar'
const ErrorWrapper = styled(Card)`
  width: 100%;
`

const StatsWrapper = styled(Col)`
  .each-stat {
    align-items: center;
    justify-content: center;
    border-radius: 1px;
    box-shadow: 0 0 4px 1px var(--periwinkle-gray);
    border-top: 2px solid var(--brand);
    padding: 10px;
    flex-direction: column;
    margin: 10px 0;

    .titleNumber {
      color: ${props => props.color};
      font-size: 48px;
      font-weight: 400;
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;

      &.bar {
        width: 50%;
        position: absolute;
        height: 80%;
      }
    }

    .titleLabel {
      text-align: center;
      height: 2em;
    }
  }
`

const ToggleContainer = styled.div`
  width: fit-content;
  display: flex;
  padding: 2px 10px;
  height: 24px;
  box-shadow: 0 0 2px 1px var(--brand-light);
  margin-top: -2px;
  margin-left: 20px;
  border-radius: 15px;

  .label {
    font-weight: 500;
    padding: 0 10px;
  }

  .toggle {
    width: fit-content;
    margin: 1px;
  }
`
const Divider = styled.div`
  border-top: 2px solid var(--periwinkle-gray);
  margin: 10px;
  width: calc(100% - 20px);
`

const colors = ['#F44336', '#2196F3', '#FFC107', '#4CAF50', '#9C27B0']

function SummaryStats(props) {
  const { summaryData, appId, environmentId, isError } = props
  const [showPie, setShowPie] = useState(true)

  if (isError) {
    return (
      <Container>
        <ErrorWrapper>Error Loading Summary Stats</ErrorWrapper>
      </Container>
    )
  }

  if (!summaryData) {
    return (
      <Container>
        <PageLoader text="Loading Summary Stats" />
      </Container>
    )
  }

  const { lastScanDate, accounts, permissions, scanSummary } = summaryData

  let formattedPermission = [
    {
      type: 'accounts',
      ...accounts,
    },
    ...permissions,
  ]

  return (
    <Fragment>
      <Divider />
      <Container fluid={true}>
        <Row>
          <Col style={{ display: 'flex' }}>
            <h5 style={{ width: 'fit-content' }}>
              Environment Summary
              {lastScanDate
                ? ` as of ${formatDateLongWithYear({ dateTime: lastScanDate })}`
                : ' (last scan date is not available)'}
            </h5>
            <ToggleContainer>
              Chart Type:<span className="label">Pie</span>
              <Toggle
                className="toggle"
                on={showPie}
                onChar={'P'}
                offChar={'B'}
                onClick={() => setShowPie(!showPie)}
              />
              <span className="label">Bar</span>
            </ToggleContainer>
          </Col>
        </Row>
        <Row>
          {formattedPermission.map(permission => (
            <StatsWrapper
              key={permission.type}
              style={{ width: `${100 / formattedPermission.length}%` }}
            >
              <div className="each-stat">
                <h6 className="titleLabel">
                  {startCase(pluralize(permission.type))}
                </h6>
                {showPie ? (
                  <SummaryPie permission={permission} colors={colors} />
                ) : (
                  <SummaryBar permission={permission} colors={colors} />
                )}
              </div>
            </StatsWrapper>
          ))}
        </Row>
      </Container>
      <Divider />
      <Container fluid={true}>
        <Row>
          <Col>
            <h5>
              Changes
              {lastScanDate
                ? ` since ${formatDateLongWithYear({ dateTime: lastScanDate })}`
                : ' (last scan date is not available)'}
            </h5>
          </Col>
        </Row>
        <Row>
          {Object.keys(scanSummary).map((scanSummaryKey, index) => (
            <StatsWrapper key={scanSummaryKey} color={colors[index]}>
              <div className="each-stat">
                <div className="titleNumber" style={{ textAlign: 'center' }}>
                  {scanSummary[scanSummaryKey]}
                </div>
                <h6 className="titleLabel">{startCase(scanSummaryKey)}</h6>
              </div>
            </StatsWrapper>
          ))}
        </Row>
        <Row>
          <Col>
            <NavLink
              to={{
                pathname: `/admin/applications/${appId}/scans/${environmentId}`,
                state: { fromDashboard: true },
              }}
            >
              Explore
            </NavLink>
          </Col>
        </Row>
      </Container>
      <Divider />
    </Fragment>
  )
}

export default memo(SummaryStats)
