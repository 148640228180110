import * as actions from 'actions'
import isEmpty from 'lodash/isEmpty'

const initialState = {
  resourcesListLoading: false,
  paginatedResourcesList: {},
  resourceDeleting: false,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case `${actions.GET_RESOURCES}_PENDING`: {
      return {
        ...state,
        resourcesListLoading: true,
      }
    }

    case `${actions.GET_RESOURCES}_FULFILLED`: {
      const { data = [], count, page } = action.payload.data
      let updatedData = []

      if (page === 0) {
        updatedData = data
      } else {
        updatedData = [...(state.paginatedResourcesList?.data || []), ...data]
      }

      return {
        ...state,
        resourcesListLoading: false,
        paginatedResourcesList: {
          data: updatedData,
          hasMoreData: updatedData.length < count,
        },
      }
    }

    case `${actions.GET_RESOURCES}_REJECTED`: {
      return {
        ...state,
        resourcesListLoading: false,
      }
    }

    case `${actions.DELETE_RESOURCE}_PENDING`: {
      return {
        ...state,
        resourceDeleting: true,
      }
    }

    case `${actions.DELETE_RESOURCE}_FULFILLED`: {
      const { resourceId } = action.meta

      const updatedData = !isEmpty(state.paginatedResourcesList?.data)
        ? state.paginatedResourcesList.data.filter(item => item.id !== resourceId)
        : []

      return {
        ...state,
        resourceDeleting: false,
        paginatedResourcesList: {
          ...state.paginatedResourcesList,
          data: updatedData,
        },
      }
    }

    case `${actions.DELETE_RESOURCE}_REJECTED`: {
      return {
        ...state,
        resourceDeleting: false,
      }
    }

    default:
      return { ...state }
  }
}
