import React, { memo } from 'react'
import { Modal, ModalBody, ModalFooter } from 'reactstrap'

import Button from 'britive-ui-components/core/components/Button'

const styles = {
  modalBody: {
    fontSize: '1.25rem',
    fontWeight: '500',
  },
}

function ForceActionModal({
  errorMessage,
  isOpen,
  isForceActionInProgress,
  onContinueClickHandler,
  onCancelClickHandler,
}) {
  return (
    <Modal isOpen={isOpen}>
      <ModalBody>
        <p style={styles.modalBody}>{errorMessage} Continue?</p>
      </ModalBody>

      <ModalFooter>
        <Button color="secondary" onClick={onCancelClickHandler}>
          Cancel
        </Button>

        <Button
          spinner={isForceActionInProgress}
          color="primary"
          onClick={onContinueClickHandler}
        >
          OK
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default memo(ForceActionModal)
