export const modalView = {
  consolidated: 'consolidated',
  users: 'users',
  tags: 'tags',
  membersOnly: 'members-only',
  selectApproversGroup: 'select-approvers-group',
}
export const approverGroupCondition = {
  all: 'All',
  any: 'Any',
}
